import { Checkbox } from "@material-ui/core";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Card, Row } from "reactstrap";
import axios from "../../../axios";
import "./Request.css";
import Button from "@mui/material/Button";
import MaterialTable from "material-table";
import PatchedPagination from "../../../PatchedPagination";

function RequestRework({ rowData }) {
  const [get_usernotes, setGet_usernotes] = useState([]);
  const [usernotes, setUserNotes] = useState("");
  const [usercheckbox, setUsercheckbox] = useState("");
  const [notedata, setNotedata] = useState([]);

  let rolcodes = JSON.parse(sessionStorage.getItem("EmpDetails"));
  let main_rolcode = localStorage.getItem("Rolecode") == "undefined" ? "" : JSON.parse(localStorage.getItem("Rolecode"));


  function Inputdatarefresh() {
    setUserNotes("")
    setUsercheckbox()
  }


  function Refresh() {
    axios
      .get("agilesaas_itrequest_details?ITRequestId=eq." + rowData.itrequestid)
      .then((res) => {
        setGet_usernotes(res.data);
      });

    axios
      .get(
        `agilesaas_itrequest_details?select=UpdateNotes&ITRequestId=eq.${rowData.itrequestid}`
      )
      .then((res) => setNotedata(res.data));
  }

  useEffect(() => {
    Refresh();
  }, []);

  let mainote = get_usernotes.map((e) => e);


  const notificationData = {
    CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
    CreatedBy: rowData.requestedby,
    NotifyTo: 500001,
    AudienceType: "Individual",
    Priority: "High",
    Subject: "Asset request note Update",
    Description:
      "Note updated by" +
      " " +
      rowData.requestedbyfn +
      " " +
      rowData.requestedbyln +
      " For Request Item ID " +
      rowData.itrequestid,
    IsSeen: "N",
    Status: "New",
  };
  function reset() {
    setUserNotes("")
  }

  function UserNotesUpdate(e) {
    e.preventDefault();

    let updateNote = {
      UpdateNotes: [
        {
          NoteSno: mainote[0].UpdateNotes[mainote[0].UpdateNotes.length - 1]
            .NoteSno
            ? Number(
              mainote[0].UpdateNotes[mainote[0].UpdateNotes.length - 1]
                .NoteSno
            ) + 1
            : 1,
          DateTime: moment().format("YYYY-MM-DD"),
          NoteUpdatedBy: rowData.requestedby,
          NoteUpdatedByFN: main_rolcode,
          NotesDetails: usernotes,
        },
      ],
    };

    mainote[0].IsReworkRequired = usercheckbox == true ? "Y" : null;
    mainote[0].IsRequestFulfilled = null;

    if (usernotes !== "") {
      mainote[0].UpdateNotes.push(updateNote.UpdateNotes[0]);
      axios
        .patch(
          "agilesaas_itrequest_details?ITRequestId=eq." + rowData.itrequestid,
          mainote
        )
        .then((res) => {
          console.log(res);
          alert("Note Has been Updated");
          Refresh();
          Inputdatarefresh()
        })
        .then((_res) => {
          axios
            .post("notification?NotifyTo=eq." + 500001, notificationData)
            .then((resf) => console.log(resf))
            .catch((error) => console.log(error));
        });
    } else {
      alert("Note Not Update");
    }
  }
  return (
    <Card>
      <form onSubmit={UserNotesUpdate} className="">
        <div className="d-flex align-items-center">
          <div>
            <div className="d-flex flex-column align-items-start mr-2">
              {/* <label>Update Note </label> */}
              <textarea
                value={usernotes}
                placeholder=" Enter your notes here..."
                onChange={(e) => setUserNotes(e.target.value)}
                className="Itrequest_Note_text SprintFormFields fontdesign"
              ></textarea>
            </div>
          </div>
          <div>
            {main_rolcode === "ITEMP" ? (
              <div className="d-flex align-items-center ">
                <label className="pr-3">Rework Required</label>
                <Checkbox
                  type="checkbox"
                  onChange={(e) => setUsercheckbox(e.target.checked)}
                  value={usercheckbox}
                  name="IsReworkRequired"
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="py-3 d-flex justify-content-end ">
          <Button style={{
            fontSize: 14,
            padding: 9,
            width: 100,
            height: 40,
            borderRadius: 8,
            marginRight: 14,
            border: "2px solid #5A88F1",
            backgroundColor: "#fff",
            color: "#5250E5",
            textAlign: "center",
            textTransform: "none",
          }} onClick={reset} >
            {" "}
            Reset{" "}
          </Button>

          <Button style={{
            fontSize: 14,
            padding: 9,
            width: 100,
            height: 40,
            borderRadius: 8,
            // marginRight: 14,
            border: "2px solid #5250E5",
            backgroundColor: "#5250E5",
            color: "#fff",
            textAlign: "center",
            textTransform: "none",
          }} size="sm" onClick={UserNotesUpdate}>
            {" "}
            Submit{" "}
          </Button>
        </div>
      </form>

      <TableNote notedata={notedata} Refresh={Refresh} />
    </Card>
  );
}

export default RequestRework;

function TableNote({ notedata, Refresh }) {
  useEffect(() => {
    Refresh();
  }, []);

  let data = notedata.filter((e) => e);

  let fulldata = data.map((e) => e.UpdateNotes);

  const Columns = [
    { title: "ID", field: "NoteSno" },
    { title: "Date", field: "DateTime", defaultSort: "asc" },
    { title: "Empid", field: "NoteUpdatedBy" },
    { title: "Updated By", field: "NoteUpdatedByFN" },
    { title: "Notes", field: "NotesDetails" },
  ];


  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      ></link>
      <MaterialTable
        title={<label style={{ fontSize: "16px" }}>Notes</label>}
        columns={Columns.map((e) => e)}
        data={
          fulldata[0] &&
          fulldata[0].map((e) => {
            return {
              ...e,
              DateTime: e.DateTime
                ? moment(e.DateTime, "YYYY-MM-DD").format("DD MMM YYYY")
                : null,
            };
          })
        }
        components={{
          Pagination: PatchedPagination,
        }}
        options={{
          actionsColumnIndex: -1,
          exportAllData: true,
          exportButton: true,
          columnResizable: true,
          filtering: false,
          sorting: true,
          paging: true,
          pageSize: 5,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 30],

          headerStyle: {
            whiteSpace: 'nowrap',
            fontWeight: "700",
            fontSize: "medium",
            // backgroundColor: "#eef2f7",
            color: "black",
            padding: "12px ",
            textAlign: "left",
          },
          cellStyle: {
            textAlign: "left",
            fontSize: "medium",
            padding: "21px",
            // color: "#6c757d",
            // padding: "15px 2px",
          },
          //  rowStyle: rowdata=>{

          //   if((rowdata.tableData.id)%2 != 0){
          //     return { backgroundColor:"#cddef085"}
          //   }

          // }
        }}
      />
    </div>
  );
}
