import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { IconButton, Tooltip, Grow } from "@material-ui/core";
import NorthWestOutlinedIcon from "@mui/icons-material/NorthWestOutlined";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MapIcon from "@mui/icons-material/Map";

import '@fortawesome/fontawesome-free/css/all.css';

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { ISMANAGERNOTES } from "../../redux/CommonInitialsate";
const useStyles = makeStyles((theme) => ({
  speedDial: {
    "&.MuiSpeedDial-fab": {
      borderRadius: "100%",
    },
    "& .MuiSpeedDial-actions": {
      pointerEvents: "auto",
    },

    "&:hover .MuiSpeedDial-actions": {
      visibility: "visible", // Ensure action buttons are always visible on hover
    },
    "&:hover .MuiSpeedDial-icon": {
      opacity: 1, // Ensure the icon remains fully visible on hover
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));

const MinimizeMaximizeButton = ({ hide }) => {
  const {
    IsMinimised,
    IsCreatekb,
    Ismanagernotes,
    Issprintdetails,
    IsReport,
    Isplansprint,
    IsRefinement
  } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const onminClick = (actionType) => {
    switch (actionType) {
      case "managernotes":
        if (Ismanagernotes) {
          const windowElement = document.getElementById("Minimized");
          if (windowElement) {
            windowElement.style.display = "block";
          }
        }
        break;
      case "createkb":
        if (IsCreatekb) {
          const createkb = document.getElementById("createtask");
          if (createkb) {
            createkb.style.display = "block";
          }
        }
        break;
      case "sprintdetails":
        if (Issprintdetails) {
          const sprindet = document.getElementById("sprintdetails");
          if (sprindet) {
            sprindet.style.display = "block";
          }
        }
        break;
      case "reportdet":
        if (IsReport) {
          const report = document.getElementById("reportv");
          if (report) {
            report.style.display = "block";
          }
        }
        break;
      case "Plansprint":
        if (Isplansprint) {
          const planre = document.getElementById("planrelease");
          if (planre) {
            planre.style.display = "block";
          }
        }
        break;
      case "Refinement":
        if (IsRefinement) {
          const planre = document.getElementById("refinement");
          if (planre) {
            planre.style.display = "block";
          }
        }
        break;
      default:
        break;
    }
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = () => {
    setOpen(true);
  };
  const actions = [
    {
      icon: <DescriptionOutlinedIcon />,
      name: "Sprint Notes",
      actionType: "managernotes",
    },
    {
      icon: <AddCircleOutlineOutlinedIcon />,
      name: "Create",
      actionType: "createkb",
    },
    { icon: <MapIcon />, name: "Sprint details", actionType: "sprintdetails" },
    {
      icon: <i class="fa fa-bar-chart"></i>,
      name: "Sprint Report",
      actionType: "reportdet",
    },
    { icon: <MapIcon />, name: "Plan Sprint", actionType: "Plansprint" }
  ];

  return (
    <>
      {IsMinimised && (Ismanagernotes || IsCreatekb || Issprintdetails || IsReport || Isplansprint || IsRefinement) && (
        // <Grow in={true}>
        <SpeedDial

          ariaLabel="Sprint notes"
          icon={<NorthWestOutlinedIcon style={{ color: "#198ae3" }} />}
          onClose={handleClose}
          onOpen={handleOpen2}
          open={open}
          direction="up"
          FabProps={{
            className: classes.speedDial,
            style: {
              width: '50px',
              height: '50px',
            },
          }}
          style={{ position: 'fixed', right: 100, bottom: 28, zIndex: 500 }}
        >

          {Ismanagernotes && (
            <SpeedDialAction
              key="Sprint Notes"
              icon={<DescriptionOutlinedIcon />}
              tooltipTitle="Sprint Notes"
              onClick={() => onminClick("managernotes")}
            />
          )}
          {IsCreatekb && (
            <SpeedDialAction
              key="Create"
              icon={<AddCircleOutlineOutlinedIcon />}
              tooltipTitle="Create"
              onClick={() => onminClick("createkb")}
            />
          )}
          {Issprintdetails && (
            <SpeedDialAction
              key="Sprint details"
              icon={<MapIcon />}
              tooltipTitle="Sprint details"
              onClick={() => onminClick("sprintdetails")}
            />
          )}
          {IsReport && (
            <SpeedDialAction
              key="Sprint Reports"
              // icon={<MapIcon />}
              icon={<i className="fas fa-bar-chart"></i>}

              tooltipTitle="Sprint Reports"
              onClick={() => onminClick("reportdet")}
            />
          )}
          {Isplansprint && (
            <SpeedDialAction
              key="Plan Sprint"

              icon={<i className="fas fa-map"></i>}
              tooltipTitle="Plan Sprint"
              onClick={() => onminClick("Plansprint")}
            />
          )}
          {IsRefinement && (
            <SpeedDialAction
              key="Refinement"
              icon={<i className="fa fa-retweet"></i>}
              tooltipTitle="Refinement"
              onClick={() => onminClick("Refinement")}
            />
          )}
        </SpeedDial>
        // </Grow>
      )}
    </>
  );
};

export default MinimizeMaximizeButton;
