import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "react-select";
import "./EditDetails.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "../../../axios";
import CloseIcon from "@material-ui/icons/Close";
import { Col, Row } from "reactstrap";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Avatar,
  Slider,
  Select as MUISelect,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
  Tooltip,
} from "@material-ui/core";
// import '../../KanbanBoard/KanbanDashboard.css'
import MaterialIcon, { colorPalette } from "material-icons-react";
import moment from "moment";
import Storypoints from "../Storypoints";
import OriginalEstimate from "./OriginalEstimate";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import UATchecklist from "../UATchecklist";
import UATtester from "../UATtester";

import Anne from "../../../assets/images/anne.jpg";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Unassigned from "../../../assets/images/avatar.png";
import Admin from "../../../assets/images/Admin.png";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import getImage from "../../../getImage";
import AvatarImage from "./AvatarImage";
import { useDispatch, useSelector } from "react-redux";
import { AlternateEmail } from "@material-ui/icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HANDLE_ON_CHANGE } from "../../../redux/CommonInitialsate";
// import { DeleteForever } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";

toast.configure();

function ProgressDetailsUpdate(
  {
    // KanbanBoardData,
    // KBRefresh,
    // Assetoptions,
    // SelectedCardId,
    // setSelectedCardId,
    // assignedTo,
    // setAssignedTo,
    // setAssignedId,
    // createdBy,
    // epic,
    // priority,
    // setPriority,
    // originalEstimate,
    // setCheckChange,
    // epicIssueId,
    // Data,
    // stageName,
    // setStageName,
    // setStageCode,
    // releaseLabel,
    // setReleaseLabel,
    // setReleaseValue,
    // description,
    // setDescription,
    // setReleaseUpdate,
    // Bfopen,
    // setBfopen,
    // selectedCard,
    // setselectedcard,
    // IssueId,
    // setIssueId,
    // openCreateEst,
    // setOpenCreateEst,
    // uatDropdown,
    // UatId,
    // setUatId,
    // UatTo,
    // setUatTo,
    // createdId,
    // UATtesterD,
    // setUATtesterD,
    // UATchecklistD,
    // setUATchecklistD,
    // progressdata,
    // setProgressData,
    // projectRole,
    // SEpic,
    // setSEpic,
    // checkChange,
    // cmodule,
    // setCmodule,
  }
) {
  const {
    allImage,
    KanbanBoardData,
    testersImage,
    releases,
    selectedCard1,
    projectCode,
    projectModules,
    SelectedCardId,
    progressdata,
    UATtesterD,
    releaseLabel,
    SEpic,
    UatTo,
    projectRole,
    priority1,
    assignedTo,
    checkChange,
    uatDropdown,
    resData,
    cmodule,
    stageName,
    UATchecklistD,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const Data =
    KanbanBoardData.filter((x) => x.IssueId == SelectedCardId)?.length != 0
      ? KanbanBoardData.filter((x) => x.IssueId == SelectedCardId)
      : KanbanBoardData.filter((x) => x.IssueId == SelectedCardId);
  const createdBy = Data?.length != 0 ? Data?.[0]?.CreatedByDetails : null;
  const originalEstimate =
    Data?.length != 0
      ? Data?.[0]?.OriginalEstimate == null
        ? 0
        : Data?.[0]?.OriginalEstimate
      : null;
  const createdId = Data?.length != 0 ? Data?.[0]?.CreatedBy : null;
  const role =
    localStorage.getItem("Rolecode") == undefined
      ? ""
      : localStorage.getItem("Rolecode");
  const [panel1, setPanel1] = useState(true);
  const [panel2, setPanel2] = useState(true);
  const [stageDetails, setStageDetails] = useState([]);
  const [effortCapture, setEffortCapture] = useState([]);
  const [testename, setTestername] = useState([]);
  const [isloading, setisloading] = useState(true);
  let arr = JSON.parse(localStorage.getItem("Empdetails"));
  let clientdetails = JSON.parse(localStorage.getItem("clientdetails"));
  const userorg = clientdetails?.[0]?.OrgDetails;

  const issueType = Data?.[0] != null ? Data?.[0].IssueType : null;
  const epic =
    issueType == "Epic" || issueType == "SubTask"
      ? null
      : Data?.length != 0
        ? Data?.[0]?.LinkToEpic?.[0]?.Title
        : null;
  const assignedNameUat =
    Data.length != 0
      ? Data?.[0]?.AssignedToUATDetails?.FN +
      " " +
      Data?.[0].AssignedToUATDetails?.LN
      : null;
  const assignedName =
    Data.length != 0
      ? Data?.[0].AssignedToDetails?.FN + " " + Data?.[0].AssignedToDetails?.LN
      : null;
  const currentStage = Data.length != 0 ? Data?.[0]?.CurrentStage?.[0] : null;
  const prior = Data.length != 0 ? Data?.[0]?.Priority : null;
  const projectId = Data.length != 0 ? Data?.[0]?.ProjectId : null;
  const est = Data.length != 0 ? Data?.[0]?.OriginalEstimate : null;
  const skipEst =
    Data.length != 0 ? Data?.[0]?.ExceptiontoSkipSPEstimation : null;
  const openEst =
    est == null &&
    issueType == "Story" &&
    skipEst != "Y" &&
    currentStage?.StageName == "Refined";
  const BTest =
    est == null &&
    issueType != "Story" &&
    issueType != "Epic" &&
    currentStage?.StageName == "Refined" &&
    skipEst != "Y";
  // const epicLinkData = KanbanBoardData?.filter((x) => x.IssueId == epicIssueId);
  let arr2 = JSON.parse(sessionStorage.getItem("Roledetails"));
  let empid = JSON.parse(localStorage.getItem("Empdetails"));
  const rvalue = Data.length != 0 ? Data?.[0]?.PlannedRelease : null;
  const assignedUatid = Data.length != 0 ? Data?.[0]?.AssignedToUAT : null;
  const assignedid = Data.length != 0 ? Data?.[0].AssignedTo : null;
  const idofcard = Data.length != 0 ? Data?.[0].IssueId : null;
  let crtid =
    empid != null
      ? empid?.[0]?.OnboardedBy === 800001
        ? 1
        : empid?.[0]?.OnboardedBy
      : "";
  const clientId = localStorage.getItem("clientid");
  // const [canestimate, setcanestimate] = useState(() => {
  //   if (assignedid ==) {
  //     return true
  //   } else {
  //     return false
  //   }
  // });

  const [count, setCount] = useState(originalEstimate);
  const [showCounter, setShowCounter] = useState(false);
  let rolecode = arr2.RoleCode;

  const increaseCounter = () => {
    setCount(count + 1);
    // setShowCounter(true);
  };
  // const canestimate = () => {
  //   if(assignedid== arr[0].ClientUserId) {
  //     return true
  //   }else {
  //     return false
  //   }
  // }

  const decreaseCounter = () => {
    setCount(count - 1);
    // setShowCounter(true);
  };

  const muiTheme = createMuiTheme({
    overrides: {
      MuiSlider: {
        thumb: {
          color: "blue",
        },
        track: {
          color: "blue",
        },
        rail: {
          color: "blue",
        },
      },
    },
  });
  const muiTheme1 = createMuiTheme({
    overrides: {
      MuiSlider: {
        thumb: {
          color: "#FEDD00",
        },
        track: {
          color: "#FEDD00",
        },
        rail: {
          color: "#FEDD00",
        },
      },
    },
  });
  const PriorityOption = [
    {
      label: "Highest",
      value: "Highest",
      icon: (
        <KeyboardDoubleArrowUpIcon
          className="filter-icons-1"
          fontSize="small"
        />
      ),
    },
    {
      label: "High",
      value: "High",
      icon: <KeyboardArrowUpIcon className="filter-icons-1" fontSize="small" />,

      //   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FAD02C" class="bi bi-chevron-up" viewBox="0 0 16 16">
      //   <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
      // </svg>)
    },
    {
      label: "Medium",
      value: "Medium",
      icon: <DragHandleIcon className="filter-icons" fontSize="small" />,
    },
    {
      label: "Low",
      value: "Low",
      icon: (
        <KeyboardArrowDownIcon className="filter-icons-2" fontSize="small" />
      ),
    },
    {
      label: "Lowest",
      value: "Lowest",
      icon: (
        <KeyboardDoubleArrowDownIcon
          className="filter-icons-2"
          fontSize="small"
        />
      ),
    },
  ];
  const releasesDropdown = releases
    .filter(
      (x) => x.IsReleaseFrozen != "Y" && x.ProjectId == projectId
      // && moment().diff(moment(x.SprintEndDate), "days") < 1
    )
    .map((x) => ({
      label: x.ReleaseName,
      value: x.ReleaseId,
    }));
  const epicoption = KanbanBoardData.filter(
    (e) => e.ProjectId === projectId && e.IssueType === "Epic"
  ).map((f) => {
    return {
      label: f.IssueTitle,
      value: f.IssueTitle,
      active: f.IsActive,
      patchdata: [
        {
          IssueId: f.IssueId,
          IssueType: f.IssueType,
          Title: f.IssueTitle,
          CurrentStage: f.CurrentStage?.[0]?.StageCode,
          Priority: f.Priority,
        },
      ],
    };
  });
  //goto
  function handleRelease(item) {
    // setReleaseLabel(item.label);
    dispatch(HANDLE_ON_CHANGE({ name: "releaseLabel", value: item.label }));
    // setReleaseValue(item.value);
    dispatch(HANDLE_ON_CHANGE({ name: "releaseValue", value: item.value }));
    const check = releases?.filter((x) => x.ReleaseId == item.value)?.[0]
      .ReleaseIssues;
    let ckl = check.filter((e) => e.IssueId != SelectedCardId);
    ckl.push({
      IssueId: SelectedCardId,
      IssueTitle: Data?.[0] != null ? Data?.[0].IssueTitle : null,
    });

    // setReleaseUpdate(ckl);
  }

  useEffect(() => {
    if (originalEstimate == 0) {
      axios
        .get(`agilesaas_issue_details?IssueId=eq.${idofcard}`)
        .then((res) => {
          let data = res.data;
          if (data.length != 0) {
            let est =
              data?.[0].OriginalEstimate == null
                ? 0
                : data?.[0].OriginalEstimate;

            setCount(est);
            setisloading(false);
          }
        });
    }
    axios.get("agile_issue_stages").then((res) => {
      setStageDetails(res.data?.[0].StageDetails);
    });
    axios
      .get(
        "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery&IssueId=eq." +
        SelectedCardId +
        "&order=UpdatedDate.desc&limit=1"
      )
      .then((res) => {
        let data = res.data;
        if (data.length != 0) {
          setEffortCapture(data?.[0]);
        }
      });
    refreshProgressDetails();
  }, []);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused ? "2px solid rgb(82, 80, 229)" : "none",
      borderRadius: 8,
      // height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const customStylesStage = {
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      outline: "none",
    }),
    control: (base) => ({
      ...base,
      fontSize: 12,
      fontWeight: "bold",
      backgroundColor: "rgb(0, 82, 204)",
      outline: "none",
    }),
  };

  const stagedropdownstyle = {
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      outline: "none",
    }),
    control: (base) => ({
      ...base,
      fontSize: 12,
      fontWeight: "bold",
      backgroundColor: "rgb(0, 82, 204)",
      outline: "none",
    }),
  };

  function refreshProgressDetails() {
    // setUatTo(issueType == "Epic" ? null : assignedNameUat);
    dispatch(
      HANDLE_ON_CHANGE({
        name: "UatTo",
        value: issueType == "Epic" ? null : assignedNameUat,
      })
    );
    // setAssignedTo(issueType == "Epic" ? null : assignedName);
    dispatch(
      HANDLE_ON_CHANGE({
        name: "assignedTo",
        value: issueType == "Epic" ? null : assignedName,
      })
    );
    // setAssignedId(Data.length != 0 ? Data[0].AssignedTo : null);
    dispatch(
      HANDLE_ON_CHANGE({
        name: "assignedId",
        value: Data.length != 0 ? Data?.[0]?.AssignedTo : null,
      })
    );
    // setPriority(prior);
    dispatch(HANDLE_ON_CHANGE({ name: "priority1", value: prior }));
    // setStageName(currentStage.StageName);
    dispatch(
      HANDLE_ON_CHANGE({ name: "stageName", value: currentStage?.StageName })
    );
    // setStageCode(currentStage.StageCode);
    dispatch(
      HANDLE_ON_CHANGE({ name: "stageCode", value: currentStage?.StageCode })
    );
    // setReleaseValue(Data[0] != null ? Data[0].PlannedRelease : null);
    dispatch(
      HANDLE_ON_CHANGE({
        name: "releaseValue",
        value: Data?.[0] != null ? Data?.[0]?.PlannedRelease : null,
      })
    );
    const relea = Data?.[0] != null ? Data?.[0]?.PlannedRelease : null;

    let data = releases.filter(
      (x) => x.ProjectId == projectId && x.ReleaseId == relea
    );
    // setReleaseLabel(
    //   data.length != 0
    //     ? data.filter((x) => x.ReleaseId == relea)[0].ReleaseName
    //     : null
    // );
    dispatch(
      HANDLE_ON_CHANGE({
        name: "releaseLabel",
        value:
          data.length != 0
            ? data.filter((x) => x.ReleaseId == relea)?.[0]?.ReleaseName
            : null,
      })
    );
  }

  const stageDetailsDropdown = stageDetails.map((x) => ({
    label: x.StageName,
    value: x.StageCode,
  }));
  let UatOptions =
    uatDropdown?.filter((e) => e.projectcode == projectCode)?.length != 0
      ? uatDropdown
        ?.filter((e) => e.projectcode == projectCode)
        ?.map((x) => {
          return {
            value: x.teammemberid,
            label: x.firstname + " " + x.lastname,
            firstname: x.firstname,
            lastname: x.lastname,
            icon: (
              <Avatar
                alt={x.firstname + " " + x.lastname}
                src={
                  testersImage?.filter(
                    (e) => x.teammemberid == e.teammemberid
                  )?.[0]?.image
                }
              />
            ),
            // <AvatarImage

            //   clientId={clientId}
            //   AssignedTo={x.teammemberid}
            //   FN={x.firstname}
            //   LN={x.lastname} />
            // <Avatar className="progressName">{x.firstname.charAt(0) + x.lastname.charAt(0)}</Avatar>
          };
        })
      : [];

  useEffect(() => {
    axios
      .get(
        "rpc/fun_mgrprojects?managerid=" +
        arr?.[0]?.ClientUserId +
        "&empid=eq." +
        arr?.[0]?.ClientUserId
      )
      .then((res) => {
        setTestername(res.data);
      });
  }, []);

  const saveestimation = () => {
    let _id = Data.length != 0 ? Data?.[0]?.IssueId : null;
    let _title =
      selectedCard1?.IssueTitle == "" ? "" : " " + selectedCard1.IssueTitle;
    let asig =
      selectedCard1?.AssignedToDetails?.FN +
      " " +
      selectedCard1?.AssignedToDetails?.LN;
    // console.log(_title, "_title");
    let historydata = {
      IssueId: selectedCard1?.IssueId,
      ChangedBy: arr?.[0]?.ClientUserId,
      ChangedByDetails: {
        FN: arr?.[0]?.UserFirstname,
        LN: arr?.[0]?.UserLastname,
      },
      ChangedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
      UpdatedField: "Original Estimate",
      UpdatedFrom: 0,
      UpdatedTo: count,
      UpdateSequence: 1,
      IsActive: "Y",
      Status: null,
      ClientId: selectedCard1?.ClientId,
      ProjectId: projectCode,
    };

    if (idofcard) {
      if (count <= 0) {
        alert("estimation date should be greater");
      } else {
        let requrireddata = {
          ClientUserId: arr?.[0]?.ClientUserId,
          ClientId: clientId,
          ClientDetails: {
            Company: userorg?.Company,
            ContactName: userorg?.ContactName,
            ContactDesignation: userorg?.ContactDesignation,
            ContactEmail: userorg?.ContactEmail,
            ContactPhone: userorg?.ContactPhone,
          },
          DepartmentDetails: arr?.[0]?.DepartmentDetails,
          DepartmentCode: null,
          TentantId: "",
          FromSenderId: "no-reply@yozytech.com",
          emailnotify: false,
          webnotify: true,
          ToEmailIds: {
            emailids: arr?.[0]?.ClientDetails.ContactEmail,
          },
          CcEmailIds: {
            emailids: ["agileteam@yozytech.com"],
          },
          BccEmailIds: "",
          EmailType: "",
          ProductDetails: {
            ProdId: "11004",
            ProdName: "KANBAN",
            Domain: "KANBAN.yozytech.com",
          },
          EmailSubject: "Estimation",
          IsSuccess: "Y",
          IsPartiallyFailed: " ",
          SendRetryHistory: "",
          IsActive: " ",
          Status: "",
          EmailTemplateName: "",
          EmailTemplateVariables: {
            xxasignbyxx: "",
            xxassigndatexx: "",
            xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
            xxprjdisxx: "",
            xxprjnamexx: "",
            xxstoryNamexx: "",
            xxprjpercenxx: "",
            xxprjstartdatexx: "",
            xxprjenddataexx: "",
            xxteamnamexx: "",
            xxsprintxx: "",
            xxmanagernamexx: userorg?.ContactName,
            xxcompanyname: userorg?.Company,
            xxmanagerdesigxx: "Scrum Master",
            xxgmailxx: userorg?.ContactEmail,
            xxphnoxx: userorg?.ContactPhone,
            webappdescri: `Estimated ${count} days for ${_title} - (${idofcard}) by ${asig} `,
          },
        };
        axios({
          url: "https://api.devozy.ai/api/agile/email",
          method: "post",
          data: requrireddata,
          headers: {
            origin: "https://kanban.yozytech.com",
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            console.log(res.data, "mailsend");
          })
          .catch((err) => {
            console.log(err);
          });
        axios
          .patch(`agilesaas_issue_details?IssueId=eq.${idofcard}`, {
            OriginalEstimate: count,
          })
          .then((res) => {
            axios.post("agilesaas_issue_history", historydata);
            // setTestername(res.data);
            setShowCounter(false);
            // KBRefresh();
            dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
          })
          .catch((e) => {
            console.log(e);
            alert("something went worng");
          });
      }
    } else {
      alert("card id notfound");
    }
  };

  const Finaltester =
    testename &&
    testename
      .filter((e) => e.projectcode == 300001 && e.projectrole == 508)
      .map((m) => m.employeeroleinproject);

  // const firstname = Finaltester && Finaltester[0] === "UAT Tester" && assignedTo != undefined ? assignedTo.split(" ").length == 3 ?
  //   assignedTo[0] + assignedTo.split(" ")[2][0] : assignedTo[0] + assignedTo.split(" ")[1][0] : null

  //For Timetracking slider
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));
  const sdata = KanbanBoardData?.find(
    (x) => x.IssueId == selectedCard1?.IssueId
  );
  let ragstatus =
    sdata?.AllProgress?.[sdata?.AllProgress?.length - 1]?.RiskofDelivery;
  // progressdata?.filter((e) => e.IssueId == SelectedCardId)?.[0]
  //   ?.RiskofDelivery;

  // console.log(
  //   progressdata,
  //   progressdata?.filter((e) => e.IssueId == SelectedCardId),
  //   selectedCard1,
  //   "ppppppppppppppppp"
  // );
  const getmodules =
    projectModules?.length > 0
      ? projectModules?.filter((x) => x.ProjectId == projectCode)?.[0]
        ?.ProjectModules
      : [];
  const moduleOptions =
    getmodules?.length > 0
      ? getmodules?.map((e) => ({
        label: e.FullName,
        value: e,
      }))
      : [];

  let Assetoptions = resData
    .filter((x) => x.projectcode === projectCode)
    .map((x) => {
      return {
        value: x.teammemberid,
        label: x.firstname + " " + x.lastname,
        firstname: x.firstname,
        lastname: x.lastname,
        icon: (
          <Avatar
            className="progressName"
            src={
              allImage?.length > 0
                ? allImage?.filter((e) => e.teammemberid == x.teammemberid)?.[0]
                  ?.image
                : Unassigned
            }
          // sx={{ width: 10, height: 10 }}
          />
        ),
        // <Avatar className="progressName">{x?.firstname?.charAt(0) + x?.lastname?.charAt(0)}</Avatar>
      };
    });
  const estcheck =
    (projectRole == "DEV" &&
      selectedCard1?.AssignedTo == arr?.[0]?.ClientUserId) ||
    projectRole == "SM";
  return (
    <div className="DetailsUpdate">
      <UATtester
      // UATtesterD={UATtesterD}
      // setUATtesterD={setUATtesterD}
      // selectedCard={selectedCard}
      />
      <UATchecklist
      // UATchecklistD={UATchecklistD}
      // setUATchecklistD={setUATchecklistD}
      // selectedCard={selectedCard}
      />
      <Storypoints
      // projectRole={projectRole}
      // Bfopen={Bfopen}
      // setBfopen={setBfopen}
      // IssueId={SelectedCardId}
      />
      <OriginalEstimate
      // openCreateEst={openCreateEst}
      // setOpenCreateEst={setOpenCreateEst}
      // IssueId={SelectedCardId}
      // selectedCard={selectedCard}
      />

      <Row noGutters>
        <Col md={9}>
          <Select
            options={stageDetailsDropdown}
            components={{ IndicatorSeparator: () => null }}
            styles={stagedropdownstyle}
            // className="stageDropdown"
            value={stageDetailsDropdown.filter(
              (option) => option.label == stageName
            )}
            onClick={() => {
              // setBfopen(openEst);
              dispatch(HANDLE_ON_CHANGE({ name: "Bfopen", value: openEst }));
            }}
            getOptionLabel={(e) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {e.icon}
                <span style={{ marginLeft: 10 }}>{e.label}</span>
              </div>
            )}
            onChange={(e) => {
              const refineStory = openEst && e.label == "In Development";
              const refineTask = BTest && e.label == "In Development";
              // setBfopen(refineStory);
              dispatch(
                HANDLE_ON_CHANGE({ name: "Bfopen", value: refineStory })
              );
              // setOpenCreateEst(refineTask);
              dispatch(
                HANDLE_ON_CHANGE({ name: "openCreateEst", value: refineTask })
              );
              // setselectedcard(Data[0]);
              dispatch(
                HANDLE_ON_CHANGE({ name: "selectedCard", value: Data?.[0] })
              );
              // setIssueId(SelectedCardId);
              dispatch(
                HANDLE_ON_CHANGE({ name: "IssueId", value: SelectedCardId })
              );
              if (
                currentStage.StageName == "In Development" &&
                e.label == "User Acceptace Testing"
              ) {
                // setUATtesterD(true);
                dispatch(HANDLE_ON_CHANGE({ name: "UATtesterD", value: true }));
              }
              if (
                currentStage.StageName == "User Acceptace Testing" &&
                e.label == "Done" &&
                (projectRole == "SM" ||
                  (Finaltester?.length != 0 &&
                    Finaltester?.[0] === "UAT Tester"))
              ) {
                // setUATchecklistD(true);
                dispatch(
                  HANDLE_ON_CHANGE({ name: "UATchecklistD", value: true })
                );
              }

              if (
                (currentStage.StageName == "Backlog" &&
                  (e.label == "Refined" ||
                    e.label == "Backlog" ||
                    e.label == "Blocked") &&
                  projectRole == "SM") ||
                (currentStage.StageName == "Refined" &&
                  !refineStory &&
                  !refineTask &&
                  (e.label == "In Development" ||
                    e.label == "Refined" ||
                    e.label == "Backlog" ||
                    (e.label == "Blocked" && projectRole == "SM"))) ||
                (currentStage.StageName == "In Development" &&
                  !UATtesterD &&
                  (e.label == "User Acceptace Testing" ||
                    e.label == "Refined" ||
                    e.label == "Blocked" ||
                    e.label == "In Development")) ||
                (currentStage.StageName == "User Acceptace Testing" &&
                  (projectRole == "SM" ||
                    (Finaltester && Finaltester?.[0] === "UAT Tester")) &&
                  !UATchecklistD &&
                  (e.label == "Done" ||
                    e.label == "In Development" ||
                    e.label == "User Acceptace Testing")) ||
                ((projectRole == "SM" ||
                  (Finaltester && Finaltester?.[0] === "UAT Tester")) &&
                  currentStage.StageName == "Done" &&
                  UATchecklist &&
                  (e.label == "User Acceptace Testing" || e.label == "Done")) ||
                (est != null &&
                  currentStage.StageName == "Refined" &&
                  e.label == "In Development")

                //( BTest==false && UatId!=null
                //&& UATtesterD==false && UATchecklistD==false
                //)a
              ) {
                // setStageName(e.label);
                dispatch(
                  HANDLE_ON_CHANGE({ name: "stageName", value: e.label })
                );
                // setStageCode(e.value);
                dispatch(
                  HANDLE_ON_CHANGE({ name: "stageCode", value: e.value })
                );
                // setCheckChange(currentStage?.StageCode != e.value);
                dispatch(
                  HANDLE_ON_CHANGE({
                    name: "checkChange",
                    value: currentStage?.StageCode != e.value,
                  })
                );
              }
            }}
          />
        </Col>

        <Col
          className="d-flex justify-content-center align-items-center"
          md={3}
        >
          <div className="d-flex">
            {selectedCard1?.IssueType != "Epic" &&
              (projectRole == "SM" ||
                projectRole == "TL" ||
                selectedCard1?.CreatedBy == arr?.[0]?.ClientUserId) &&
              (selectedCard1?.CurrentStage?.[0]?.StageCode == "RFD" ||
                selectedCard1?.CurrentStage?.[0]?.StageCode == "BLG" ||
                selectedCard1?.CurrentStage?.[0]?.StageCode == "DEV" ||
                selectedCard1?.CurrentStage?.[0]?.StageCode == "UAT") && (
                <span className="bg-white rounded">
                  <Tooltip arrow placement="top" title={"clone"}>
                    <IconButton
                      className="shadow-sm border"
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 5,
                        padding: 3,
                      }}
                      onClick={(x) => {
                        x.stopPropagation();
                        dispatch(
                          HANDLE_ON_CHANGE({
                            name: "clonedialog",
                            value: true,
                          })
                        );

                        dispatch(
                          HANDLE_ON_CHANGE({
                            name: "clonedata",
                            value: selectedCard1,
                          })
                        );
                      }}
                    >
                      <ContentCopyIcon
                        style={{
                          fontSize: "20px",
                          marginBottom: 1,
                          color: "#4bade8",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              )}
            {selectedCard1?.IssueType != "Epic" &&
              (projectRole == "SM" ||
                selectedCard1?.CreatedBy == arr?.[0]?.ClientUserId) &&
              (selectedCard1?.CurrentStage?.[0]?.StageCode == "RFD" ||
                selectedCard1?.CurrentStage?.[0]?.StageCode == "BLG") && (
                <span className="bg-white rounded ml-2">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      KanbanBoardData?.filter(
                        (n) =>
                          n.ParentIssue?.[0]?.IssueId == selectedCard1?.IssueId
                      )?.length > 0
                        ? "Unlink or remove the subtask(s) linked to this ticket"
                        : "Delete"
                    }
                  >
                    <IconButton
                      className="shadow-sm border"
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 5,
                        padding: 3,
                      }}
                      onClick={(x) => {
                        x.stopPropagation();
                        if (
                          KanbanBoardData?.filter(
                            (n) =>
                              n.ParentIssue?.[0]?.IssueId ==
                              selectedCard1?.IssueId
                          )?.length > 0
                        ) {
                        } else {
                          dispatch(
                            HANDLE_ON_CHANGE({
                              name: "deletedialog",
                              value: true,
                            })
                          );
                        }
                      }}
                    >
                      <DeleteIcon
                        style={{
                          fontSize: "20px",
                          marginBottom: 1,
                          color: "#F22C3D",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              )}
          </div>
        </Col>
      </Row>
      {/* <Select
        options={stageDetailsDropdown}
        components={{ IndicatorSeparator: () => null }}
        styles={customStylesStage}
        className="stageDropdown"
        value={stageDetailsDropdown.filter(
          (option) => option.label == stageName
        )}
        onClick={() => {
          // setBfopen(openEst);
          dispatch(HANDLE_ON_CHANGE({ name: "Bfopen", value: openEst }));
        }}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {e.icon}
            <span style={{ marginLeft: 10 }}>{e.label}</span>
          </div>
        )}
        onChange={(e) => {
          const refineStory = openEst && e.label == "In Development";
          const refineTask = BTest && e.label == "In Development";
          // setBfopen(refineStory);
          dispatch(HANDLE_ON_CHANGE({ name: "Bfopen", value: refineStory }));
          // setOpenCreateEst(refineTask);
          dispatch(
            HANDLE_ON_CHANGE({ name: "openCreateEst", value: refineTask })
          );
          // setselectedcard(Data[0]);
          dispatch(
            HANDLE_ON_CHANGE({ name: "selectedCard", value: Data?.[0] })
          );
          // setIssueId(SelectedCardId);
          dispatch(
            HANDLE_ON_CHANGE({ name: "IssueId", value: SelectedCardId })
          );
          if (
            currentStage.StageName == "In Development" &&
            e.label == "User Acceptace Testing"
          ) {
            // setUATtesterD(true);
            dispatch(HANDLE_ON_CHANGE({ name: "UATtesterD", value: true }));
          }
          if (
            currentStage.StageName == "User Acceptace Testing" &&
            e.label == "Done" &&
            (projectRole == "SM" ||
              (Finaltester?.length != 0 && Finaltester?.[0] === "UAT Tester"))
          ) {
            // setUATchecklistD(true);
            dispatch(HANDLE_ON_CHANGE({ name: "UATchecklistD", value: true }));
          }

          if (
            (currentStage.StageName == "Backlog" &&
              (e.label == "Refined" ||
                e.label == "Backlog" ||
                e.label == "Blocked") &&
              projectRole == "SM") ||
            (currentStage.StageName == "Refined" &&
              !refineStory &&
              !refineTask &&
              (e.label == "In Development" ||
                e.label == "Refined" ||
                e.label == "Backlog" ||
                (e.label == "Blocked" && projectRole == "SM"))) ||
            (currentStage.StageName == "In Development" &&
              !UATtesterD &&
              (e.label == "User Acceptace Testing" ||
                e.label == "Refined" ||
                e.label == "Blocked" ||
                e.label == "In Development")) ||
            (currentStage.StageName == "User Acceptace Testing" &&
              (projectRole == "SM" ||
                (Finaltester && Finaltester?.[0] === "UAT Tester")) &&
              !UATchecklistD &&
              (e.label == "Done" ||
                e.label == "In Development" ||
                e.label == "User Acceptace Testing")) ||
            ((projectRole == "SM" ||
              (Finaltester && Finaltester?.[0] === "UAT Tester")) &&
              currentStage.StageName == "Done" &&
              UATchecklist &&
              (e.label == "User Acceptace Testing" || e.label == "Done")) ||
            (est != null &&
              currentStage.StageName == "Refined" &&
              e.label == "In Development")

            //( BTest==false && UatId!=null
            //&& UATtesterD==false && UATchecklistD==false
            //)a
          ) {
            // setStageName(e.label);
            dispatch(HANDLE_ON_CHANGE({ name: "stageName", value: e.label }));
            // setStageCode(e.value);
            dispatch(HANDLE_ON_CHANGE({ name: "stageCode", value: e.value }));
            // setCheckChange(currentStage?.StageCode != e.value);
            dispatch(
              HANDLE_ON_CHANGE({
                name: "checkChange",
                value: currentStage?.StageCode != e.value,
              })
            );
          }
        }}
      /> */}

      <ExpansionPanel expanded={panel1}>
        <ExpansionPanelSummary
          className="expansionTitle fontdesign mt-3"
          expandIcon={<ExpandMoreIcon />}
          onClick={() => {
            setPanel1(!panel1);
          }}
        >
          Details
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className="details-info">
          <table responsive className="details-table">
            {issueType != "Epic" ? (
              <>
                <tr>
                  <td className="row_style headerLabel fontdesign">Assignee</td>
                  <td className="row_style">
                    {/* <Row> */}
                    {Finaltester.length != 0 &&
                      Finaltester?.[0] == "UAT Tester" ? (
                      <div className="ml-2 d-flex align-items-center justify-content-between ">
                        {/* <Avatar className="progressName">{firstname}</Avatar> */}
                        <span className="ml-2 fontdesign">{assignedTo}</span>
                      </div>
                    ) : (projectRole != "SM" && projectRole != "TL") ||
                      selectedCard1?.CurrentStage?.[0]?.StageCode === "UAT" ||
                      selectedCard1?.CurrentStage?.[0]?.StageCode === "DONE" ? (
                      <div className="ml-2 d-flex align-items-center ">
                        <Avatar
                          alt=""
                          src={
                            allImage?.length > 0
                              ? allImage?.filter(
                                (x) => x.teammemberid == assignedid
                              )?.[0]?.image
                              : Unassigned
                          }
                        />
                        <span>{assignedTo}</span>
                      </div>
                    ) : (
                      <Select
                        placeholder="select Assignee"
                        // className="row_style"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        styles={customStyles}
                        maxMenuHeight={120}
                        // Assetoptions.filter(
                        //   (x) => x.value != arr?.[0]?.ClientUserId
                        // )
                        options={Assetoptions}
                        value={Assetoptions.filter(
                          (option) => option.label === assignedTo
                        )}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "20",
                            }}
                          >
                            {/* {e.icon} */}
                            <div className="assignee-avatar">
                              {/* <AvatarImage
                                clientId={clientId}
                                AssignedTo={e.value}
                                FN={e.firstname}
                                LN={e.lastname} /> */}
                              <Avatar
                                alt=""
                                src={
                                  allImage?.length > 0
                                    ? allImage?.filter(
                                      (x) => x.teammemberid == e.value
                                    )?.[0]?.image
                                    : Unassigned
                                }
                              />
                            </div>
                            <span style={{ marginLeft: 10 }}>{e.label}</span>
                          </div>
                        )}
                        onChange={(e) => {
                          // setAssignedTo(e.label);
                          dispatch(
                            HANDLE_ON_CHANGE({
                              name: "assignedTo",
                              value: e.label,
                            })
                          );
                          // setAssignedId(e.value);
                          dispatch(
                            HANDLE_ON_CHANGE({
                              name: "assignedId",
                              value: e.value,
                            })
                          );
                          // setCheckChange(assignedid != e.value);
                          dispatch(
                            HANDLE_ON_CHANGE({
                              name: "checkChange",
                              value: e.value,
                            })
                          );
                        }}
                      />
                    )}

                    {/* </Row> */}
                  </td>
                </tr>

                <tr>
                  <td className="row_style headerLabel fontdesign">
                    Assigned To UAT
                  </td>
                  <td className="row_style">
                    {/* <Row> */}
                    <Select
                      placeholder="Select Assignee"
                      // disabled={
                      //   selectedCard1?.CurrentStage?.[0]?.StageCode === "DONE"
                      // }
                      // className="row_style"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      styles={customStyles}
                      maxMenuHeight={120}
                      isDisabled={
                        selectedCard1?.CurrentStage?.[0]?.StageCode === "DONE"
                      }
                      options={UatOptions}
                      value={UatOptions.filter(
                        (option) => option.label == UatTo
                      )}
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {e.icon}

                          <span style={{ marginLeft: 10 }}>{e.label}</span>
                        </div>
                      )}
                      onChange={(e) => {
                        // setUatTo(e.label);
                        dispatch(
                          HANDLE_ON_CHANGE({ name: "UatTo", value: e.label })
                        );
                        // setUatId(e.value);
                        dispatch(
                          HANDLE_ON_CHANGE({ name: "UatId", value: e.value })
                        );
                        // setCheckChange(assignedNameUat != e.value);
                        dispatch(
                          HANDLE_ON_CHANGE({
                            name: "checkChange",
                            value: assignedNameUat != e.value,
                          })
                        );
                      }}
                    />
                    {/* </Row> */}
                  </td>
                </tr>
              </>
            ) : null}

            {issueType != "Epic" ? (
              <tr>
                <td className="row_style headerLabel fontdesign">Sprint</td>
                <td className="row_style">
                  {/* {console.log(releaseLabel, rvalue, "rvalue")} */}
                  <Select
                    isDisabled={
                      projectRole != "SM" ||
                      selectedCard1?.CurrentStage?.[0]?.StageCode === "DONE"
                    }
                    placeholder={
                      projectRole != "SM" ? "No Sprint" : "Select Release"
                    }
                    className="release_style"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    styles={customStyles}
                    value={releasesDropdown.filter(
                      (option) => option.label == releaseLabel
                    )}
                    // defaultValue={Data[0] != null ? Data[0].PlannedRelease : null}
                    maxMenuHeight={110}
                    options={releasesDropdown}
                    onChange={(item) => {
                      handleRelease(item);
                      // setCheckChange(rvalue != item.value)
                    }}
                  />
                  {/* </Row> */}
                </td>
              </tr>
            ) : null}

            {issueType != "SubTask" && issueType != "Epic" ? (
              <tr>
                <td className="row_style headerLabel fontdesign">Epic Link</td>
                <td className="row_style">
                  {/* <Row> */}
                  <Select
                    isDisabled={
                      projectRole != "SM" ||
                      selectedCard1?.CurrentStage?.[0]?.StageCode === "DONE"
                    }
                    placeholder={
                      projectRole != "SM" ? "No EPic" : "Select Epic"
                    }
                    className="release_style"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    styles={customStyles}
                    value={epicoption.filter(
                      (e) => e.value === (SEpic === null ? epic : SEpic?.value)
                    )}
                    // defaultValue={Data[0] != null ? Data[0].PlannedRelease : null}
                    maxMenuHeight={110}
                    options={epicoption}
                    onChange={(item) => {
                      if (item.active == "Y") {
                        // setSEpic(item);
                        dispatch(
                          HANDLE_ON_CHANGE({ name: "SEpic", value: item })
                        );
                        // setCheckChange(item.label === epic ? false : true);
                        dispatch(
                          HANDLE_ON_CHANGE({
                            name: "checkChange",
                            value: item.label === epic ? false : true,
                          })
                        );
                      } else {
                        toast.error("Not able to link to the closed epic");
                      }
                      // console.log(item, { "LinkToEpic": item.patchdata }, "select EPIC1.0")
                      // axios.patch("agilesaas_issue_details?IssueId=eq." + SelectedCardId, { "LinkToEpic": item.patchdata }).then((res) => ).catch((err) => console.log(err))
                      // KBRefresh()
                    }}
                  />
                </td>
              </tr>
            ) : null}


            <tr>
              <td className="row_style headerLabel fontdesign">Modules</td>
              <td className="row_style">
                {/* <Row> */}
                {/* {console.log(
                  moduleOptions?.filter(
                    (e) =>
                      e.label ===
                      (selectedCard1?.ModuleLinked
                        ? selectedCard1?.ModuleLinked?.FullName
                        : cmodule?.FullName)
                  )
                )} */}
                {/* {console.log(
                  moduleOptions?.filter(
                    (e) =>
                      e.label ===
                      (selectedCard1?.ModuleLinked &&
                        (cmodule == null || cmodule == "")
                        ? selectedCard1?.ModuleLinked?.FullName
                        : cmodule?.FullName)
                  ),
                  "check module",
                  cmodule
                )} */}
                <Select
                  isDisabled={
                    projectRole != "SM" ||
                    selectedCard1?.CurrentStage?.[0]?.StageCode === "DONE"
                  }
                  placeholder={
                    projectRole != "SM" ? "No Modules" : "Select Module"
                  }
                  className="release_style"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={customStyles}
                  value={moduleOptions?.filter(
                    (e) =>
                      e.label ===
                      (selectedCard1?.ModuleLinked &&
                        (cmodule == null || cmodule == "")
                        ? selectedCard1?.ModuleLinked?.FullName
                        : cmodule?.FullName)
                  )}
                  // defaultValue={Data[0] != null ? Data[0].PlannedRelease : null}
                  maxMenuHeight={110}
                  options={moduleOptions}
                  onChange={(item) => {
                    // if (item.active == "Y") {
                    //   setSEpic(item);
                    // setCmodule(item?.value);
                    dispatch(
                      HANDLE_ON_CHANGE({ name: "cmodule", value: item?.value })
                    );
                    // setCheckChange(
                    //   item.label === selectedCard1?.ModuleLinked?.FullName
                    //     ? false
                    //     : true
                    // );
                    dispatch(
                      HANDLE_ON_CHANGE({
                        name: "checkChange",
                        value:
                          item.label === selectedCard1?.ModuleLinked?.FullName
                            ? false
                            : true,
                      })
                    );
                    // }
                    // else {
                    //   toast.error("Not able to link to the closed epic");
                    // }
                    // console.log(item, { "LinkToEpic": item.patchdata }, "select EPIC1.0")
                    // axios.patch("agilesaas_issue_details?IssueId=eq." + SelectedCardId, { "LinkToEpic": item.patchdata }).then((res) => ).catch((err) => console.log(err))
                    // KBRefresh()
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="row_style headerLabel fontdesign">Priority</td>
              <td className="row_style">
                {/* <Row> */}
                <Select
                  placeholder="Select Priority"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  isDisabled={
                    selectedCard1?.CurrentStage?.[0]?.StageCode === "DONE" ||
                    projectRole == "DEV"
                  }
                  value={PriorityOption.filter(
                    (option) => option.label == priority1
                  )}
                  options={PriorityOption}
                  maxMenuHeight={120}
                  styles={customStyles}
                  onChange={(e) => {
                    // setPriority(e.value);
                    dispatch(
                      HANDLE_ON_CHANGE({ name: "priority1", value: e.value })
                    );
                    // setCheckChange(prior != e.label);
                    dispatch(
                      HANDLE_ON_CHANGE({
                        name: "checkChange",
                        value: prior != e.label,
                      })
                    );
                  }}
                  getOptionLabel={(e) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {e.icon}
                      <span style={{ marginLeft: 15 }}>{e.value}</span>
                    </div>
                  )}
                />
                {/* </Row> */}
              </td>
            </tr>
            <tr style={{ height: showCounter ? "100px" : "auto" }}>
              <td className="row_style headerLabel fontdesign">
                Original Estimate
              </td>
              {/* {console.log(count > 0 && showCounter, "sss")}
              {console.log(originalEstimate, "ss")}
              {console.log(originalEstimate, "ss")}

              {console.log(
                projectRole != "DEV",
                projectRole != "SM",
                Data,
                "check data"
              )} */}
              {originalEstimate > 0 ||
                (count > 0 && showCounter == false) ? (
                <td className="row_style">
                  {/* <Row> */}
                  <label className="inputLabel">
                    {originalEstimate > 0
                      ? originalEstimate + " Days"
                      : count + " Days"}
                  </label>

                  {/* <label className="inputLabel">

                        {originalEstimate + " Days"}
                      </label> */}
                  {/* </Row> */}
                </td>
              ) : showCounter == false ? (
                <button
                  className="btn brn-primary p-2"
                  disabled={isloading || !estcheck}
                  style={{
                    backgroundColor: "rgb(82, 80, 229)",
                    color: "white",
                  }}
                  onClick={() => {
                    setShowCounter(true);
                  }}
                >
                  Estimate now
                </button>
              ) : null}
              {showCounter ? (
                <div>
                  {/* <button onClick={increaseCounter}>+</button> */}

                  {/* {showCounter && (
                        <>
                          <span>{count}</span>
                          <button onClick={decreaseCounter}>-</button>
                        </>
                      )} */}

                  {showCounter && (
                    <div style={{ padding: "10px" }}>
                      <TextField
                        style={{ width: "140px" }}
                        type="number"
                        value={count}
                        onChange={(e) => {
                          setCount(e.target.value);
                        }}
                        label="Enter no of days"
                      />

                      <br></br>
                      <div
                        className="d-flex mt-1  "
                        style={{ marginLeft: "-5%", gap: "4px" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            backgroundColor: "rgb(82, 80, 229)",
                            color: "white",
                            textTransform: "capitalize",
                          }}
                          onClick={saveestimation}
                        >
                          Apply
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            textTransform: "capitalize",
                          }}
                          size="small"
                          onClick={() => {
                            setShowCounter(false);
                            setCount(0);
                          }}
                        >
                          Discard
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </tr>
            {issueType == "Epic" ? null : (
              <>
                <tr>
                  <td className="row_style headerLabel fontdesign">
                    Risk Of Delivery
                  </td>
                  <td className="row_style">
                    {/* <Row> */}
                    <span
                      className="progress_update_rag"
                      style={
                        ragstatus == "G"
                          ? { background: "Green", color: "white" }
                          : ragstatus === "R"
                            ? { background: "Red", color: "white" }
                            : ragstatus == "Y" || ragstatus == "A"
                              ? { background: "Yellow", color: "black" }
                              : null
                      }
                    >
                      {ragstatus}
                    </span>
                    {/* </Row> */}
                  </td>
                </tr>



                <tr>
                  <td className="row_style headerLabel fontdesign">
                    Time Tracking
                  </td>
                  <td className="row_style">
                    {/* <Row> */}
                    <Box sx={{ width: 100 }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          effortCapture.EffortRemaining == undefined
                            ? 0
                            : effortCapture.EffortRemaining < 0
                              ? 100
                              : ((effortCapture.EffortPending -
                                effortCapture.EffortRemaining) /
                                originalEstimate) *
                              100
                        }
                      />
                      {/* <ThemeProvider theme={effortCapture.EffortRemaining < 0 ? muiTheme1 : muiTheme}>
                      <Slider
                        min={parseInt(effortCapture.EffortPending)} max={parseInt(originalEstimate)} defaultValue={parseInt(effortCapture.EffortPending) - parseInt(effortCapture.EffortRemaining)}
                        value={effortCapture.EffortRemaining < 0 ? 100 : (effortCapture.EffortPending - effortCapture.EffortRemaining) / (originalEstimate) * 100}
                      min={effortCapture.EffortPending - effortCapture.EffortRemaining}
                       max={originalEstimate}
                      valueLabelDisplay="auto"
                                  valueLabelFormat={"logged"}
                      getAriaValueText={"remaining"}
                      />
                    </ThemeProvider> */}
                    </Box>
                    {/* </Row> */}
                  </td>
                </tr>

                {effortCapture.length != 0 ? (
                  <tr>
                    <td className="row_style headerLabel"></td>
                    <td className="row_style">
                      <label className="styleFont fontdesign">
                        {effortCapture.EffortPending -
                          effortCapture.EffortRemaining}
                        D logged
                      </label>
                      <label className="styleFont1 fontdesign">
                        {effortCapture.EffortRemaining < 0
                          ? 0
                          : effortCapture.EffortRemaining}
                        D Remaining
                      </label>
                    </td>
                  </tr>
                ) : null}
                {/* <tr><td>Remaining Effort</td><td><input type="text" disabled={true} value={" "}/></td></tr> */}
              </>
            )}



          </table>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={panel2}>
        <ExpansionPanelSummary
          className="expansionTitle fontdesign"
          expandIcon={<ExpandMoreIcon />}
          onClick={() => {
            setPanel2(!panel2);
          }}
        >
          More Fields
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="details-info">
          <table className="details-table">
            <tr>
              <td className="row_style headerLabel fontdesign">Created By</td>
              <td className="row_style">
                {/* <Row>
                  <Avatar className="progressNameCreated fontdesign">{createdBy?.split(" ")[0]?.charAt(0) + createdBy?.split(" ")[1]?.charAt(0)}</Avatar>
                  <label className="inputLabel fontdesign">{createdBy}</label>
                </Row> */}

                <div
                  style={{ display: "flex", alignItems: "center", width: "20" }}
                >
                  <div className="assignee-avatar">
                    <Avatar
                      alt=""
                      src={
                        allImage?.length > 0
                          ? allImage?.filter(
                            (x) => x.teammemberid == createdId
                          )?.[0]?.image
                          : Unassigned
                      }
                    />
                  </div>
                  <span style={{ marginLeft: 10 }}>{createdBy}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td className="row_style headerLabel fontdesign">Created Date</td>
              <td className="row_style">
                <label className="inputLabel fontdesign">
                  {moment(Data?.[0]?.CreatedDate).format("DD MMM YYYY")}
                </label>
              </td>
            </tr>
          </table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default ProgressDetailsUpdate;
