import moment from 'moment';
import axios from './axios';

export default async function createcategory(categoryname, id) {
    const cleanedText = categoryname.replace(/[^A-Za-z0-9]+/g, '');
    const uppercaseText = cleanedText.toUpperCase();
    const categorycode = uppercaseText.slice(0, 6);
    let clientid = localStorage.getItem("clientid") == "undefined" ? "" : JSON.parse(localStorage.getItem("clientid"));
    let empid = JSON.parse(localStorage.getItem("Empdetails"));
    const postdata =
    {
        "CategoryCode": categorycode,
        "CategoryName": categoryname,
        "SubCategoryDetails": [
            {
                "SubCategoryCode": categorycode,
                "SubCategoryName": categoryname
            }],
        "CreatedDate": moment().format("YYYY-MM-DD"),
        "ClientId": clientid,
        "CreatedByDetails": {
            "ClientUserId": empid?.[0]?.ClientUserId,
            "UserFirstname": empid?.[0]?.UserFirstname,
            "UserLastname": empid?.[0]?.UserLastname
        }
    }

    try {
        if (id) {
            const response = await axios.patch(`agilesaas_incident_category_master?IncidentCategoryId=eq.${id}`, {
                "CategoryCode": categorycode,
                "CategoryName": categoryname,
            })
            console.log(response);
        }
        else {
            const response = await axios.post(`agilesaas_incident_category_master`, postdata)
            console.log(response);
        }
    } catch (error) {
        console.error(error);
    }
}
