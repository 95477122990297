// ProblemDetail.js
import React, { useEffect, useState, useRef } from "react";
import Imageviewer from "../KanbanBoard/ProgressUpdate/Imageviewer";
import MaterialTable from "material-table";
import Toolbar, { modules, formats } from "../KanbanBoard/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import systemlogo from "./../../../src/assets/images/loder.png";
// assets/images/loder.png
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import Button from "@mui/material/Button";
import Activity from "./Problemactivity";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import axios from "../../axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Row, Col, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProblemChatloader from "./ProblemChatloader";
import Unassigned from "../../assets/images/avatar.png";

const ProblemDetail = ({
  onClose,
  data,
  createchange,
  setcreatechange,
  setopenp,
  openp,
  statedropdown,
  updatefunc,
  updatadata,
  patchdata,
  prioritydropdown,
  assigntomefun,
  ClientId,
  reloadfunc,
  reload,
}) => {
  const {
    allImage,
    userImage,
    IncidentId,
    statusoption,
    PriorityOption,
    AssignedGroupOption,
  } = useSelector((state) => state.user);
  let arruser = JSON.parse(localStorage.getItem("Empdetails"));
  console.log(openp, "openp")
  const [erroropen, seterroropen] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const [Formopen, setFormopen] = useState(false);
  const [workaround, setwrokaround] = useState("");
  const [detailloading, setdetailloading] = useState(false);
  const [editworkaround, seteditwrokaround] = useState(true);
  const [workarounddate, setworkarounddate] = useState(new Date());
  const [rootcause, setrootcause] = useState("");
  const [editrootcause, seteditrootcause] = useState(true);
  const [Rootcauseidentified, setrootcauseidentified] = useState(null);
  const [edittitle, setedittitle] = useState(true);
  const [rootcausedate, setrootcausedate] = useState(new Date());
  const [rootreferenceno, setrootreferenceno] = useState("");

  const columns = [
    {
      title: "S.No",
      field: "sno",
      headerStyle: { fontWeight: "bold", width: "10%" },
    },
    {
      title: "Incident",
      field: "incident",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Priority",
      field: "Priority",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Raised Date",
      field: "raisedDate",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Raised By",
      field: "raisedBy",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Status",
      field: "status",
      headerStyle: { fontWeight: "bold", width: "10%" },
    },
  ];
  const [imageviewopen, setImageviewopen] = useState(false);
  const [open, setOpen] = useState(false);
  const [imagedata, setImagedata] = useState();
  const [PatchData, setPatchData] = useState([]);
  const [Commonts, setCommonts] = useState("");
  const [SerialNo, setSerialNo] = useState("");
  const [quillFocus, setQuillFocus] = useState(false);
  const [editnotesid, seteditnotesid] = useState(null);
  const [editcomment, seteditcomment] = useState(false);
  const [openprogress, setopenprogress] = useState(false);
  const [messageinfo, setmessageinfo] = useState("");
  const [incidentedit, setincidentedit] = useState(true);
  const [allincidents, setallincidents] = useState([]);
  const [defaultlinkedincident, setdefaultlinkedincident] = useState([]);
  const [linkedincident, setlinkedincident] = useState([]);
  const [assignmentoption, setassignmentoption] = useState([]);
  const [Assignedtooption, setAssignedtooption] = useState([]);
  const [lindedoptionsapidata, setlindedoptionsapidata] = useState([]);
  const [removedincident, allremovedincident] = useState([]);
  const [workaroundgroupdata, setworkaroundgroupdata] = useState(null);
  const [workarounduserdata, setworkarounduserdata] = useState(null);
  const [rootcausegroupdata, setrootcausegroupdata] = useState(null);
  const [rootcauseuserdata, setrootcauseuserdata] = useState(null);
  const [roocauseassigntoo, setroocauseassigntoo] = useState([]);
  const [title, settitle] = useState(null);
  const [descrition, setdescrition] = useState(null);
  const [defaulttoo, setdefaulttoo] = useState([]);
  // console.log(data, "lof");
  const quillRef = useRef();

  function assigntoooptiongen(id) {
    let e = assignmentoption.find((x) => x.value == id);

    if (e) {
      setworkaroundgroupdata({
        Groupid: e.value,
        GroupName: e.label,
      });

      setAssignedtooption(
        e.users?.map((user) => ({
          value: user.UserId,
          label: user.Firstname + " " + user.Lastname,
        })) || []
      );
    } else {
      // Handle the case when no match is found
      console.error(`No matching data found for id: ${id}`);
    }
  }
  function defaultassigntoo(id, update) {
    let e = assignmentoption.find((x) => x.value == id);

    if (e) {
      if (update) {
        let patchdata = {
          AssignmentGroup: {
            Groupid: e.value,
            GroupName: e.label,
          },
        };
        updatefunc(data.ProblemId, patchdata);
      }

      setdefaulttoo(
        e.users?.map((user) => ({
          value: user.UserId,
          label: user.Firstname + " " + user.Lastname,
        })) || []
      );
    } else {
      // Handle the case when no match is found
      console.error(`No matching data found for id: ${id}`);
    }
  }
  function assigntoooptiongenroocause(id) {
    let e = assignmentoption.find((x) => x.value == id);

    if (e) {
      setrootcausegroupdata({
        Groupid: e.value,
        GroupName: e.label,
      });

      setroocauseassigntoo(
        e.users?.map((user) => ({
          value: user.UserId,
          label: user.Firstname + " " + user.Lastname,
        })) || []
      );
    } else {
      // Handle the case when no match is found
      console.error(`No matching data found for id: ${id}`);
    }
  }

  useEffect(() => {
    setrootcauseidentified(data.IsRootCauseIdentifed)
    setdetailloading(true);
    settitle(title ? title : data.ShortDescription);
    setdescrition(descrition ? descrition : data.LongDescription);
    setwrokaround(
      workaround
        ? workaround
        : data.WorkAround == null
          ? ""
          : data.WorkAround.WorkAround
    );

    setlinkedincident(data.LinkedIncidents);

    if (data.WorkAround) {
      // Use the ApplyUntil date if it is present
      setworkarounddate(new Date(data.WorkAround?.ApplyUntill));
    }

    if (assignmentoption.length == 0) {
      axios
        .get("subscription_user_group_mapping?ClientId=eq." + ClientId)
        .then((response) => {
          let userGroupMappings = response.data;
          const resultArray = userGroupMappings.reduce(
            (acc, userGroupMapping) => {
              userGroupMapping.GroupDetails.forEach((groupDetail) => {
                const groupId = groupDetail.GroupId;
                const groupName = groupDetail.GroupName;

                // Check if the group already exists in the result array
                const existingGroupIndex = acc.findIndex(
                  (group) => group.GroupId === groupId
                );

                if (existingGroupIndex !== -1) {
                  // Add the user to the existing group
                  acc[existingGroupIndex].users.push({
                    Firstname: userGroupMapping.UserDetails.Firstname,
                    Lastname: userGroupMapping.UserDetails.Lastname,
                    UserId: userGroupMapping.UserId,
                  });
                } else {
                  // Create a new group with the user
                  acc.push({
                    GroupId: groupId,
                    GroupName: groupName,
                    users: [
                      {
                        Firstname: userGroupMapping.UserDetails.Firstname,
                        Lastname: userGroupMapping.UserDetails.Lastname,
                        UserId: userGroupMapping.UserId,
                      },
                    ],
                  });
                }
              });

              return acc;
            },
            []
          );
          const assignmentooption = resultArray.map((e) => {
            return {
              label: e.GroupName,
              value: e.GroupId,
              users: e.users,
            };
          });
          setassignmentoption(assignmentooption);
        })
        .catch((error) => {
          console.log(error);
          setdetailloading(false);
        });
    }

    let succulength = linkedincident.map((x) => x.IncidentId).join(",");
    let url =
      linkedincident.length == 0
        ? `agilesaas_incident_details?ClientId=eq.${ClientId}&isLinkedtoproblem->>islinked=eq.false`
        : `agilesaas_incident_details?ClientId=eq.${ClientId}&or=(isLinkedtoproblem->>islinked.eq.false,IncidentId.in.(${succulength})))`;
    axios

      .get(url)
      .then((res) => {
        if (res.data.length > 0) {
          // setallincidents(res.data);
          let options = res.data.map((incident, index) => ({
            sno: index + 1,
            IncidentId: incident.IncidentId,
            id: incident.IncidentId,
            label: `${incident.IncidentTitle}-${incident.IncidentId}`,
            incident: `${incident.IncidentTitle}-${incident.IncidentId}`,
            value: incident.IncidentId,
            status: incident?.CurrentState?.StateName,
            Priority: incident.Priority?.PriortyName,
            raisedBy:
              incident?.CreatedByDetails?.FN +
              "" +
              incident?.CreatedByDetails?.LN,
            raisedDate: moment(incident.CreatedDate).format("YYYY-MM-DD"),
          }));
          setlindedoptionsapidata(options);
          let selectdata = options.filter((item) =>
            linkedincident.some((d) => d.IncidentId == item.id)
          );
          setdetailloading(false);
          // setlinkedincident(selectdata);
          setdefaultlinkedincident(selectdata);
        }
      })
      .catch((error) => {
        console.log(error);
        setdetailloading(false);
      });

    let newWorkaround =
      data.WorkAround !== null
        ? data.WorkAround.AppliedGroup !== null
          ? data.WorkAround.AppliedGroup
          : {
            Groupid: null,
            GroupName: null,
          }
        : {
          Groupid: null,
          GroupName: null,
        };

    let workarounduserinfo =
      data.WorkAround !== null
        ? data.WorkAround.AppliedBy !== null
          ? data.WorkAround.AppliedBy
          : {
            userid: null,
            name: null,
          }
        : {
          userid: null,
          name: null,
        };

    setworkarounduserdata(workarounduserinfo);

    setworkaroundgroupdata(newWorkaround);

    assigntoooptiongen(newWorkaround.Groupid);
    setrootreferenceno(
      rootreferenceno
        ? rootreferenceno
        : data.ActualRootCause?.ReleatedTicketReferences
          ? data.ActualRootCause?.ReleatedTicketReferences
          : ""
    );
    setrootcause(
      rootcause
        ? rootcause
        : data.ActualRootCause == null
          ? ""
          : data.ActualRootCause.RootCause
    );
    if (data.ActualRootCause) {
      // Use the ApplyUntil date if it is present
      setrootcausedate(new Date(data.ActualRootCause?.IdentifiedDate));
    }
    let roocausegrop =
      data.ActualRootCause !== null
        ? data.ActualRootCause.IdentifiedGroup !== null
          ? data.ActualRootCause.IdentifiedGroup
          : {
            Groupid: null,
            GroupName: null,
          }
        : {
          Groupid: null,
          GroupName: null,
        };
    let roocauseuserinfo =
      data.ActualRootCause !== null
        ? data.ActualRootCause.Identifiedby !== null
          ? data.ActualRootCause.Identifiedby
          : {
            userid: null,
            name: null,
          }
        : {
          userid: null,
          name: null,
        };
    assigntoooptiongenroocause(roocausegrop.Groupid);
    defaultassigntoo(data.AssignmentGroup.Groupid);
    setrootcausegroupdata(roocausegrop);
    setrootcauseuserdata(roocauseuserinfo);
  }, [
    quillRef,
    Formopen,
    ClientId,
    data,
    assignmentoption.length,
    editworkaround,
    editrootcause,
    edittitle,
  ]);
  const useStyles = makeStyles({
    datePickerInput: {
      width: "100%",

      border: "none",
      "&:MuiInputBase-root": {
        border: "none",
        padding: "2%",
      }, // Remove the default border
      "&:focus": {
        outline: "none", // Optionally, remove the focus outline as well
      },
    },
    root: {
      "& .MuiBox-root": {
        paddingTop: 2,
        margin: 1,
      },
      "& .MuiAppBar-root": {
        // boxShadow: "none", // Remove the box shadow from the tab header
        paddingLeft: 0,
        margin: 0,
      },
      "& .MuiTabs-root": {
        display: "flex",
        justifyContent: "flex-start",
      },
    },
  });
  const classes = useStyles();
  function getprobemdetail(id) {
    axios
      .get(`agilesaas_problem_details?ProblemId=eq.${id}`)
      .then((res) => {
        if (res.data.length > 0) {
          updatadata(id, res.data[0]);
        } else {

          seterrormessage("something wentwrong ")
          seterroropen(true)
        }
      })
      .catch((e) => {
        // alert("something wentwrong");
        seterrormessage("something wentwrong ")
        seterroropen(true)
      });
  }
  const handlecomment = (props) => {
    // e.stopPropagation();
    // e.preventDefault();
    setCommonts(props);
    // console.log(props);
    let arr = JSON.parse(localStorage.getItem("Empdetails"));
    const updatedNotes = [];
    if (SerialNo === "") {
      const PushData = {
        NoteSno: updatedNotes.length + 1,
        DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        NoteUpdatedBy: arr[0].ClientUserId,
        NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
        NotesDetails: props,
      };
      updatedNotes.push(PushData);
      console.log(updatedNotes);
    } else {
      const index = updatedNotes.findIndex((note) => note.NoteSno === SerialNo);
      updatedNotes[index].NotesDetails = props;
      updatedNotes[index].DateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      console.log(updatedNotes);
    }
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: "auto",
      width: "auto",
      // width: 230,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    // Add more options as needed
  ];

  const handlecancel = () => {
    setCommonts("");
    setPatchData([]);
    setSerialNo("");
    setFormopen(false);
    // Refreshdata()
    // setNotesLoading(true)
  };

  const handleQuillFocus = () => {
    setQuillFocus(true);
  };
  const modifyImageTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const images = tempDiv.querySelectorAll("img");
    images.forEach((image) => {
      image.style.width = "20%"; // Set the width to your desired value
      image.style.height = "20%"; // Set the height to your desired value
    });

    return tempDiv.innerHTML;
  };
  function timeAgo(dateString) {
    const currentDate = new Date();
    const givenDate = new Date(dateString);
    console.log(dateString);
    const timeDifference = currentDate - givenDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);
    console.log(timeDifference);

    if (seconds < 60) {
      return seconds + " sec ago";
    } else if (minutes < 60) {
      return minutes + " min ago";
    } else if (hours < 24) {
      return hours + " hours ago";
    } else if (days < 30) {
      return days + " days ago";
    } else if (months < 12) {
      return months + " months ago";
    } else {
      return years + " years ago";
    }
  }
  const handleQuillBlur = () => {
    setQuillFocus(false);
  };
  const Activityt = () => (
    <div className="container mb-3 mt-3">
      <div className="header d-flex">
        <h5 style={{ fontWeight: "600" }}>Activity</h5>
      </div>
      <div className="mt-3 flex">
        <Avatar alt="" src={userImage} sx={{ width: 35, height: 35 }} />
        <div className="w-auto" style={{ flex: 1 }}>
          {!Formopen ? (
            <input
              className="form-control rounded ml-2"
              style={{
                background: "#fff",
                border: "1px solid rgb(0, 0, 0 ,30%) ",
                color: "black",
                height: "35px",
                borderRadius: "5px",
              }}
              placeholder="Add a Comment"
              onClick={() => setFormopen(true)}
            // disabled={ADDIGNID != Empid[0].ClientUserId}
            />
          ) : null}
          {Formopen ? (
            <div>
              <div className="d-flex flex-column ml-2">
                <Toolbar />
                <ReactQuill
                  ref={quillRef}
                  // style={{ width: "600px" }}
                  // className="quill"

                  // theme="snow"
                  onFocus={handleQuillFocus}
                  onBlur={handleQuillBlur}
                  value={Commonts}
                  onChange={handlecomment}
                  modules={modules}
                  formats={formats}
                />
              </div>
            </div>
          ) : null}
          <div className="mt-3 ml-2 d-flex justify-space-between">
            <Button
              onClick={() => {
                handlecancel();
              }}
              style={{
                fontSize: 12,
                padding: 9,
                width: 70,
                height: 28,
                borderRadius: 5,
                marginRight: 14,
                border: "2px solid #198ae3 ",
                backgroundColor: "#fff",
                color: "#198ae3 ",
                textAlign: "center",
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                fontSize: 12,
                padding: 9,
                width: 70,
                height: 28,
                borderRadius: 5,
                backgroundColor: "#198ae3 ",
                color: "#fff",
                border: "0px solid #198ae3 ",
                textAlign: "center",
                textTransform: "none",
                marginRight: 24,
              }}
              onClick={() => {
                console.log("save");
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  function handleClickOpen(e) {
    setImageviewopen(
      e?.match(/<img .*?>/g) == null || e?.match(/<img .*?>/g).length == 0
        ? false
        : true
    );
    setOpen(true);
    setImagedata(e);
  }

  function deletemessage(messageid, problemid) {
    getprobemdetail(problemid);
    let messagelist = data.ProblemNotes.filter((x) => {
      if (x.Message_id != messageid) {
        return x;
      }
    });
    patchdata(problemid, { ProblemNotes: messagelist });
    // alert("deleted sucessfylly");
  }

  const areObjectsEqual = (obj1, obj2) =>
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(
      (key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
    );

  const arraysAreEqual = (arr1, arr2) =>
    arr1.length === arr2.length &&
    arr1.every((item1) =>
      arr2.some(
        (item2) => item1.id === item2.id && areObjectsEqual(item1, item2)
      )
    );

  const Notes = ({ setopenactivity, setdataforcomments }) => {
    return data.ProblemNotes?.length > 0 ? (
      data.ProblemNotes?.sort(
        (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
      )?.map((e, index) => (
        <div className="container mt-3 pb-2">
          <div className="d-flex justify-space-between mt-3">
            <Avatar
              alt=""
              src={
                e.NoteUpdatedBy?.id == "sys"
                  ? systemlogo
                  : allImage?.length > 0 && allImage?.filter(
                    (x) => x.teammemberid == e.NoteUpdatedBy?.id
                  )?.[0]?.image != undefined ? allImage?.filter(
                    (x) => x.teammemberid == e.NoteUpdatedBy?.id
                  )?.[0]?.image : Unassigned
              }
              sx={{ width: 30, height: 30 }}
            />
            <label
              className="ml-2 mt-2"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              {e.NoteUpdatedBy.FN + " " + e.NoteUpdatedBy.LN}
            </label>
            <label
              className="ml-2"
              style={{ fontSize: "10px", color: "grey", marginTop: "10px" }}
            >
              {timeAgo(e.DateTime)}
            </label>
          </div>
          <div className="mt-2">
            {/* <p>{e.NotesDetails}</p> */}
            {1 == 2 ? (
              <div>
                <ProblemChatloader />
                <ProblemChatloader />
                <ProblemChatloader />
              </div>
            ) : (
              <div
                onClick={() => {
                  handleClickOpen(e.NotesDetails);
                }}
                dangerouslySetInnerHTML={{
                  __html: modifyImageTags(e.NotesDetails),
                }}
              ></div>
            )}
            {e.action == true ? (
              <div className="d-flex mt-3">
                {e.NoteUpdatedBy.id === arruser[0].ClientUserId ? (
                  <span
                    className="text-info font10"
                    role="button"
                    onClick={() => {
                      setCommonts(e.NotesDetails);
                      seteditcomment(true);
                      setFormopen(true);
                      seteditnotesid(e.Message_id);
                    }}
                  >
                    Edit
                  </span>
                ) : (
                  <span className="text-info font10" role="button">
                    Edit
                  </span>
                )}
                {e.NoteUpdatedBy.id === arruser[0].ClientUserId ? (
                  <span
                    className="text-grey ml-2 font10"
                    role="button"
                    onClick={() => {
                      deletemessage(e.Message_id, data.ProblemId);
                    }}
                  >
                    Delete
                  </span>
                ) : (
                  <span className="text-grey ml-2 font10" role="none">
                    Delete
                  </span>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ))
    ) : (
      <></>
    );
  };
  function defaultworkaround() {
    if (data && data.Workaround && data.Workaround.ApplyUntil) {
      // Use the ApplyUntil date if it is present
      setworkarounddate(new Date(data.Workaround.ApplyUntil));
    } else {
      // Use the current date if either Workaround or ApplyUntil is null
      setworkarounddate(new Date());
    }
    if (data.WorkAround?.WorkAround) {
      setwrokaround(data.WorkAround.WorkAround);
    } else {
      setwrokaround("");
    }

    // getprobemdetail(data.ProblemId);
  }
  function defaultrootcause() {
    if (data && data.ActualRootCause && data.ActualRootCause.IdentifiedDate) {
      // Use the ApplyUntil date if it is present
      setrootcausedate(new Date(data.ActualRootCause.IdentifiedDate));
    } else {
      // Use the current date if either Workaround or ApplyUntil is null
      setrootcausedate(new Date());
    }
    if (data.ActualRootCause?.RootCause) {
      setrootcause(data.ActualRootCause?.RootCause);
    } else {
      setrootcause("");
    }
    if (data.ActualRootCause?.ReleatedTicketReferences) {
      setrootreferenceno(data.ActualRootCause?.ReleatedTicketReferences);
    } else {
      setrootreferenceno("");
    }

    // getprobemdetail(data.ProblemId);
  }
  function updateincidents() {
    const isNotEqual = !arraysAreEqual(defaultlinkedincident, linkedincident);
    let removedincodent = defaultlinkedincident.filter((defaultItem) => {
      return !linkedincident.some(
        (modifiedItem) => modifiedItem.id == defaultItem.id
      );
    });
    // lindedoptionsapidata.filter((item) =>
    //                         linkedincident.some((d) => d.IncidentId == item.id)
    //                       )

    let succulength = linkedincident.map((x) => x.id).join(",");

    let remdata = removedincodent.map((x) => x.id).join(",");

    if (isNotEqual) {
      let pactdataofprob = linkedincident.map((item) => ({
        IncidentId: item.id,
      }));
      let inceupdatepatch = {
        islinked: true,
      };
      let incedelpatch = {
        islinked: false,
      };

      axios
        .patch(`agilesaas_problem_details?ProblemId=eq.${data.ProblemId}`, {
          LinkedIncidents: pactdataofprob,
        })
        .then((res) => {
          if (linkedincident.length > 0) {
            axios
              .patch(
                `agilesaas_incident_details?ClientId=eq.${ClientId}&IncidentId=in.(${succulength})`,
                {
                  isLinkedtoproblem: inceupdatepatch,
                }
              )
              .then((res) => {
                setdetailloading(false);
              })
              .catch((e) => {
                seterrormessage("something wentwrong when updating incidents")
                seterroropen(true)
                // alert("something wentwrong when updating incidents");
              });
          }

          if (removedincodent.length > 0) {
            axios
              .patch(
                `agilesaas_incident_details?ClientId=eq.${ClientId}&IncidentId=in.(${remdata})`,
                {
                  isLinkedtoproblem: incedelpatch,
                }
              )
              .then((res) => { })
              .catch((e) => {
                seterrormessage("something wentwrong when updating incidents")
                seterroropen(true)
                // alert("something wentwrong when updating incidents");
              });
          }

          setdetailloading(false);
          getprobemdetail(data.ProblemId);
          setincidentedit(true);
        })
        .catch((e) => {
          seterrormessage("something wentwrong")
          seterroropen(true)
          // alert("something wentwrong");
        });
    }
  }
  let statecolor =
    data?.ProblemStages.label == "Created"
      ? "NewBadgeInc"
      : data?.ProblemStages.label == "Analyzed"
        ? "AckBadgeInc"
        : data?.ProblemStages.label == "Error Known"
          ? "UserBadgeInc"
          : data?.ProblemStages.label == "Root Cause identified"
            ? "ResolveBadgeInc"
            : data?.ProblemStages.label == "Closed"
              ? "ResolveBadgeInc"
              : "NewBadgeInc";
  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        style={{ backgroundColor: "green", color: "white" }}
        severity="success"
        {...props}
      />
    );
  });
  function saveworkaround() {
    if (workaround.length > 3) {
      let patchdataofworkaround = {
        WorkAround: {
          WorkAround: workaround,
          AppliedBy: workarounduserdata,
          AppliedGroup: workaroundgroupdata,
          ApplyUntill: moment(workarounddate).format("YYYY-MM-DD"),
        },
      };
      axios
        .patch(
          `agilesaas_problem_details?ProblemId=eq.${data.ProblemId}`,
          patchdataofworkaround
        )
        .then((res) => {
          setdetailloading(false);
          getprobemdetail(data.ProblemId);
          seteditwrokaround(true);
        })
        .catch((e) => {
          seterrormessage("updated failed")
          seterroropen(true)
          // alert("updated failed");
        });
    } else {
      seterrormessage("Please enter valid workaround")
      seterroropen(true)
    }
  }
  function savetittle() {
    if (title.length > 3 && descrition.length > 3) {
      let patchdataofworkaround = {
        ShortDescription: title,
        LongDescription: descrition,
      };
      axios
        .patch(
          `agilesaas_problem_details?ProblemId=eq.${data.ProblemId}`,
          patchdataofworkaround
        )
        .then((res) => {
          getprobemdetail(data.ProblemId);
          setedittitle(true);
          setdetailloading(false);
        })
        .catch((e) => {
          seterrormessage("update failed")
          seterroropen(true)
        });
    } else {
      seterrormessage("Please enter a valid title or description")
      seterroropen(true)
    }
  }
  // xx
  function saverootcause() {
    if (rootcause.length > 3) {
      let patchdataofworkaround = {
        IsRootCauseIdentifed: Rootcauseidentified,
        ActualRootCause: {
          RootCause: rootcause,
          Identifiedby: rootcauseuserdata,
          IdentifiedGroup: rootcausegroupdata,
          IdentifiedDate: moment(rootcausedate).format("YYYY-MM-DD"),
          ReleatedTicketReferences: rootreferenceno,
        },
      };

      axios
        .patch(
          `agilesaas_problem_details?ProblemId=eq.${data.ProblemId}`,
          patchdataofworkaround
        )
        .then((res) => {
          getprobemdetail(data.ProblemId);
          seteditrootcause(true);
          setdetailloading(false);
        })
        .catch((e) => {
          seterrormessage("update failed")
          seterroropen(true)
        });
    } else {
      seterrormessage("Please enter a valid rootcause")
      seterroropen(true)
    }
  }

  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>
              <b>Problem Details</b> #{data.ProblemRefNumber}
            </p>
            <IconButton
              onClick={() => {
                // reloadfunc(!reload)(
                setdetailloading(false);
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <hr />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Left Section (9 columns) */}
            <Grid item xs={8}>
              {edittitle ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "self-start",
                  }}
                >
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h2>{data.ShortDescription}</h2>
                      <span
                        className={`badge ${statecolor}`}
                        style={{ fontWeight: "bold" }}
                      >
                        {data.ProblemStages.label}
                      </span>
                    </div>

                    <p>{data.LongDescription}</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    {edittitle ? (
                      <button
                        style={{
                          // width: 100,
                          height: 36,
                          borderRadius: 5,
                          background: "#5250E5",
                          color: "#fff",
                          padding: ".5rem .7rem",
                        }}
                        onClick={() => {
                          setedittitle(false);
                        }}
                      >
                        Edit
                      </button>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group controlId="title">
                        <Form.Label className="SprintlabelStyle">
                          Title <label className="text-danger"> *</label>
                        </Form.Label>
                        <Form.Control
                          value={title}
                          className={"SprintFormFields"}
                          type="text"
                          onChange={(e) => settitle(e.target.value)}
                          placeholder="Enter problem title"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group controlId="description">
                        <Form.Label className="SprintlabelStyle">
                          Description <label className="text-danger"> *</label>
                        </Form.Label>
                        <Form.Control
                          className={"SprintFormFields"}
                          as="textarea"
                          value={descrition}
                          rows={3}
                          onChange={(e) => setdescrition(e.target.value)}
                          placeholder="Enter description"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {edittitle == false ? (
                    <div className="m-1">
                      <button
                        style={{
                          // width: 100,
                          height: 36,
                          borderRadius: 5,
                          border: "1px solid #5250E5",
                          background: "#fff",
                          color: "#5250E5",
                          borderColor: "5250E5",
                          padding: ".5rem .7rem",
                        }}
                        onClick={() => {
                          // xx
                          // defaultworkaround();
                          settitle(data.ShortDescription);
                          setdescrition(data.LongDescription);
                          setedittitle(true);

                          //  setlinkedincident(defaultlinkedincident)
                        }}
                      >
                        cancel
                      </button>{" "}
                      <button
                        style={{
                          // width: 100,
                          height: 36,
                          borderRadius: 5,

                          background: "#5250E5",
                          color: "#fff",
                          padding: ".5rem .7rem",
                        }}
                        onClick={() => {
                          savetittle();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  ) : null}
                </div>
              )}

              <hr />
              <Box>
                <Tabs
                  defaultActiveKey="Releatedincidents"
                  id="justify-tab-example"
                  className="horizontal-tabs mt-3 mb-4"
                  variant="tabs"
                >
                  <Tab
                    eventKey="Releatedincidents"
                    title="Releated incidents"
                    className="headings"
                    defaultActiveKey="Releatedincidents"
                  >
                    <div style={{ padding: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        {incidentedit ? (
                          <button
                            style={{
                              // width: 100,
                              height: 36,
                              borderRadius: 5,
                              background: "#5250E5",
                              color: "#fff",
                              padding: ".5rem .7rem",
                            }}
                            onClick={() => {
                              setincidentedit(false);
                            }}
                          >
                            Edit
                          </button>
                        ) : null}
                      </div>
                      <Row className="mb-4">
                        <Col className="col-md-9">
                          <Form.Group controlId="description">
                            <Form.Label className="SprintlabelStyle">
                              link incidents{" "}
                              <label className="text-danger"> *</label>
                            </Form.Label>
                            <Select
                              isMulti
                              options={lindedoptionsapidata}
                              placeholder="Search and select options..."
                              isSearchable
                              value={lindedoptionsapidata.filter((item) =>
                                linkedincident.some(
                                  (d) => d.IncidentId == item.id
                                )
                              )}
                              isDisabled={incidentedit}
                              closeMenuOnSelect={false}
                              onChange={(selectedincident) => {
                                console.log(
                                  lindedoptionsapidata.filter((item) =>
                                    selectedincident.some(
                                      (d) => d.id == item.id
                                    )
                                  ),
                                  "test",
                                  lindedoptionsapidata
                                );
                                setlinkedincident(selectedincident);
                                console.log(selectedincident, "linked ic");
                              }}

                            // components={{ Option: CheckboxOption }}
                            />
                            {incidentedit == false ? (
                              <div className="m-1">
                                <button
                                  style={{
                                    // width: 100,
                                    height: 36,
                                    borderRadius: 5,
                                    background: "#fff",
                                    color: "#5250E5",
                                    borderColor: "5250E5",
                                    padding: ".5rem .7rem",
                                  }}
                                  onClick={() => {
                                    setincidentedit(true);
                                    setlinkedincident(defaultlinkedincident);
                                  }}
                                >
                                  cancel
                                </button>{" "}
                                <button
                                  style={{
                                    // width: 100,
                                    height: 36,
                                    borderRadius: 5,
                                    background: "#5250E5",
                                    color: "#fff",
                                    padding: ".5rem .7rem",
                                  }}
                                  onClick={() => {
                                    updateincidents();
                                  }}
                                >
                                  Save
                                </button>
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </Row>
                      {linkedincident?.length > 0 ? (
                        <MaterialTable
                          style={{ zIndex: 0 }}
                          title={"Incident info"}
                          columns={columns}
                          data={lindedoptionsapidata.filter((item) =>
                            linkedincident.some((d) => d.IncidentId == item.id)
                          )}
                          options={{
                            // pageSize: 10, // Set the initial page size (you can change this value)
                            // pageSizeOptions: [10, 20, 30], // Set the available page size options
                            search: false, // Enable/disable search functionality
                            sorting: false, // Enable/disable column sorting
                          }}
                        />
                      ) : null}
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Workaround"
                    title="Work around"
                    className="headings ml-2"
                  >
                    <div
                      style={{
                        padding: "10px",
                        // paddingBottom: "10%",
                      }}
                    >
                      {/* First Row */}
                      {/* <div
                        style={{
                          display: "flex",
                          marginBottom: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          id="known"
                          type="checkbox"
                          // checked={isknownerror}
                          // onChange={() => setisknownerror(!isknownerror)}
                          style={{
                            marginRight: "5px",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <label
                          for="known"
                          style={{
                            marginLeft: "5px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginBottom: 0,
                          }}
                        >
                          Is Known Error
                        </label>
                      </div> */}

                      {/* Second Row */}

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        {editworkaround ? (
                          <button
                            style={{
                              // width: 100,
                              height: 36,
                              borderRadius: 5,
                              background: "#5250E5",
                              color: "#fff",
                              padding: ".5rem .7rem",
                            }}
                            onClick={() => {
                              seteditwrokaround(false);
                            }}
                          >
                            Edit
                          </button>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="descriptionWorkaround">
                            <Form.Label className="SprintlabelStyle">
                              Work around
                            </Form.Label>
                            <Form.Control
                              // defaultValue={workaround}
                              value={workaround}
                              onChange={(e) => setwrokaround(e.target.value)}
                              disabled={editworkaround}
                              // className={
                              //   !wrokaroundvalid
                              //     ? "SprintFormFields"
                              //     : "SprintFormFields error"
                              // }
                              as="textarea"
                              rows={3}
                              placeholder="Enter description"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Third Row */}
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="nameGroup">
                            <Form.Label className="SprintlabelStyle">
                              Applied group
                            </Form.Label>
                            {console.log(
                              workaroundgroupdata,
                              "workaroundgroupdata"
                            )}
                            {console.log(assignmentoption, "assignmentoption")}
                            <Select
                              isDisabled={editworkaround}
                              // styles={
                              //   !assignvlueerror
                              //     ? customStyles
                              //     : customStyleserror
                              // }
                              options={assignmentoption}
                              value={assignmentoption?.filter(
                                (e) => e.value == workaroundgroupdata.Groupid
                              )}
                              onChange={(e) => {
                                console.log(e.users, "users");
                                console.log(
                                  assignmentoption,
                                  "assignmentoption"
                                );
                                assigntoooptiongen(e.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="subGroup">
                            <Form.Label className="SprintlabelStyle">
                              Applied By
                            </Form.Label>
                            <Select
                              isDisabled={editworkaround}
                              // styles={
                              //   !assigntoerror
                              //     ? customStyles
                              //     : customStyleserror
                              // }
                              options={Assignedtooption}
                              value={Assignedtooption?.filter(
                                (e) => e.value == workarounduserdata.userid
                              )}
                              onChange={(e) => {
                                setworkarounduserdata({
                                  userid: e.value,
                                  name: e.label,
                                });
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          style={{ paddingBottom: 0, marginBottom: 0 }}
                        >
                          <Form.Label className="SprintlabelStyle">
                            Apply Until
                          </Form.Label>
                          <div className="test" style={{ width: "50%" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                InputProps={{
                                  style: {
                                    outlineColor: "red",
                                  },
                                }}
                                classes={{
                                  root: classes.datePickerInput, // Apply the custom styles to the root element
                                }}
                                disabled={editworkaround}
                                className="datepickerDesign"
                                value={workarounddate}
                                onChange={(date) => setworkarounddate(date)}
                                format="dd-MM-yyyy"
                                views={["year", "month", "date"]}
                                // disablePast
                                required
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </Form.Group>
                        <col></col>
                      </Row>
                      {editworkaround == false ? (
                        <div className="m-1">
                          <button
                            style={{
                              // width: 100,
                              height: 36,
                              borderRadius: 5,
                              border: "1px solid #5250E5",
                              background: "#fff",
                              color: "#5250E5",
                              borderColor: "5250E5",
                              padding: ".5rem .7rem",
                            }}
                            onClick={() => {
                              defaultworkaround();
                              seteditwrokaround(true);

                              //  setlinkedincident(defaultlinkedincident)
                            }}
                          >
                            cancel
                          </button>{" "}
                          <button
                            style={{
                              // width: 100,
                              height: 36,
                              borderRadius: 5,

                              background: "#5250E5",
                              color: "#fff",
                              padding: ".5rem .7rem",
                            }}
                            onClick={() => {
                              saveworkaround();
                            }}
                          >
                            Save
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Rootcause"
                    title="Root cause"
                    className="headings ml-2"
                  >
                    <div style={{ padding: "10px" }}>
                      {/* First Row */}

                      {/* Second Row */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        {editrootcause ? (

                          <button
                            style={{
                              // width: 100,
                              height: 36,
                              borderRadius: 5,
                              background: "#5250E5",
                              color: "#fff",
                              padding: ".5rem .7rem",
                            }}
                            onClick={() => {
                              seteditrootcause(false);
                            }}
                          >
                            Edit
                          </button>
                        ) : null}

                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          id="rootcause"
                          type="checkbox"
                          checked={Rootcauseidentified == "N" ? false : true}
                          onChange={() =>
                            setrootcauseidentified(Rootcauseidentified == "N" ? "Y" : Rootcauseidentified == "Y" ? "N" : Rootcauseidentified)
                          }
                          style={{
                            marginRight: "5px",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <label
                          for="rootcause"
                          className="SprintlabelStyle"
                        >
                          Is Root Cause identifed
                        </label>
                      </div>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="descriptionrootcause">
                            <Form.Label className="SprintlabelStyle">
                              Root cause
                            </Form.Label>
                            <Form.Control
                              value={rootcause}
                              disabled={editrootcause}
                              onChange={(e) => setrootcause(e.target.value)}
                              className={"SprintFormFields"}
                              as="textarea"
                              rows={3}
                              placeholder="Enter description"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="nameGroup">
                            <Form.Label className="SprintlabelStyle">
                              Identified group
                            </Form.Label>
                            <Select
                              isDisabled={editrootcause}
                              styles={customStyles}
                              options={assignmentoption}
                              value={assignmentoption?.filter(
                                (e) => e.value == rootcausegroupdata.Groupid
                              )}
                              onChange={(e) => {
                                assigntoooptiongenroocause(e.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="subGroup">
                            <Form.Label className="SprintlabelStyle">
                              Identified By
                            </Form.Label>
                            <Select
                              isDisabled={editrootcause}
                              styles={customStyles}
                              options={roocauseassigntoo}
                              value={roocauseassigntoo?.filter(
                                (e) => e.value == rootcauseuserdata.userid
                              )}
                              onChange={(e) => {
                                setrootcauseuserdata({
                                  userid: e.value,
                                  name: e.label,
                                });
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* Third Row */}
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="title">
                          <Form.Label className="SprintlabelStyle">
                            Releated Ticket References
                          </Form.Label>
                          <Form.Control
                            className="SprintFormFields"
                            type="text"
                            value={rootreferenceno}
                            onChange={(e) => {
                              setrootreferenceno(e.target.value);
                            }}
                            disabled={editrootcause}
                            placeholder="Enter reference id"
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          style={{ paddingBottom: 0, marginBottom: 0 }}
                        >
                          <Form.Label className="SprintlabelStyle">
                            Identified Date
                          </Form.Label>
                          <div className="test">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                InputProps={{
                                  style: {
                                    outlineColor: "red",
                                  },
                                }}
                                classes={{
                                  root: classes.datePickerInput, // Apply the custom styles to the root element
                                }}
                                disabled={editrootcause}
                                className="datepickerDesign"
                                value={rootcausedate}
                                onChange={(date) => setrootcausedate(date)}
                                format="dd-MM-yyyy"
                                views={["year", "month", "date"]}
                                required
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </Form.Group>
                      </Row>
                      {editrootcause == false ? (
                        <div className="m-1">
                          <button
                            style={{
                              // width: 100,
                              height: 36,
                              borderRadius: 5,
                              border: "1px solid #5250E5",
                              background: "#fff",
                              color: "#5250E5",
                              borderColor: "5250E5",
                              padding: ".5rem .7rem",
                            }}
                            onClick={() => {
                              // defaultworkaround();
                              // seteditwrokaround(true);
                              defaultrootcause();
                              seteditrootcause(true);
                              //  setlinkedincident(defaultlinkedincident)
                            }}
                          >
                            cancel
                          </button>{" "}
                          <button
                            style={{
                              // width: 100,
                              height: 36,
                              borderRadius: 5,

                              background: "#5250E5",
                              color: "#fff",
                              padding: ".5rem .7rem",
                            }}
                            onClick={() => {
                              saverootcause();
                              //  updateincidents()
                              // saveworkaround();
                            }}
                          >
                            Save
                          </button>




                        </div>
                      ) : null}
                      {data.IsRootCauseIdentifed == "Y" && editrootcause == true ?
                        <button
                          className="ml-1"
                          style={{
                            // width: 100,
                            height: 36,
                            borderRadius: 5,
                            background: "#5250E5",
                            color: "#fff",
                            padding: ".5rem .7rem",
                          }}
                          disabled={data.ActualRootCause.ReleatedTicketReferences !== ""}
                          onClick={() => {
                            setopenp(false)
                            setcreatechange(true);



                          }}
                        >
                          {data.ActualRootCause.ReleatedTicketReferences == "" ? "Create change" : "Change Created"}
                        </button> : null}
                    </div>
                  </Tab>
                </Tabs>
              </Box>
              <hr />
              {/* Your details content */}
              <Box>
                <Activity
                  errorshow={seterroropen}
                  errortext={seterrormessage}
                  pdata={data}
                  problemidofdata={data.ProblemId}
                  Formopen={Formopen}
                  isedit={editcomment}
                  idofedittomessgae={seteditnotesid}
                  idofeditmessgae={editnotesid}
                  editfun={seteditcomment}
                  openquilfun={setFormopen}
                  quilcomment={setCommonts}
                  commentsdata={Commonts}
                  updataofcard={patchdata}
                  updaterparticularproblem={getprobemdetail}
                  snackmessageopen={setopenprogress}
                  snackmessage={setmessageinfo}
                />

                <hr />
                {false ? (
                  <div>
                    <ProblemChatloader />
                    <ProblemChatloader />
                    <ProblemChatloader />
                  </div>
                ) : (
                  <Notes />
                )}
              </Box>
            </Grid>

            {/* Right Section (3 columns) */}
            <Grid item xs={4}>
              {/* Dropdown using react-select */}
              <Box>
                <div style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}>
                  <div style={{ backgroundColor: "#fff", padding: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4 style={{ fontWeight: "bold" }}>
                        {data.ProblemStages.label == "Closed"
                          ? "Closed"
                          : "Open"}
                      </h4>
                      <p>
                        <span className="SprintlabelStyle">Priority:</span>{" "}
                        <span>
                          <b>{data.Priority.value}</b>
                        </span>
                      </p>
                    </div>
                    <span
                      className={`badge mt-2 ${statecolor}`}
                      style={{ fontWeight: "bold" }}
                    >
                      {data.ProblemStages.label}
                    </span>
                    <hr />

                    <h4 style={{ fontWeight: "bold" }}>Ticket details</h4>
                    <table
                      class="borderless-table"
                      style={{ borderSpacing: 10 }}
                    >
                      <tr>
                        <td className="SprintlabelStyle">Created by</td>
                        <td style={{ fontSize: "12px" }}>
                          {data.CreatedBy.FN
                            ? data.CreatedBy?.FN
                            : "" + " " + data.CreatedBy.LN
                              ? data.CreatedBy.LN
                              : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="SprintlabelStyle">Created at</td>
                        <td style={{ fontSize: "12px" }}>
                          {moment(data.createdDateTime).format(
                            "MMMM D, YYYY h:mm A"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="SprintlabelStyle">Category</td>
                        <td style={{ fontSize: "12px" }}>
                          {data.Category.label}
                        </td>
                      </tr>
                      <tr>
                        <td className="SprintlabelStyle">Sub-category</td>
                        <td style={{ fontSize: "12px" }}>
                          {data.Subcategory.label}
                        </td>
                      </tr>

                      <tr>
                        <td className="SprintlabelStyle">Assignment Group</td>
                        <td style={{ fontSize: "12px" }}>
                          {data.AssignmentGroup?.GroupName}
                        </td>
                      </tr>
                      <tr>
                        {data.AssignedTo == null ? (
                          <td>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                assigntomefun(data.ProblemId);
                              }}
                              style={{
                                // width: 100,
                                height: 36,
                                borderRadius: 5,
                                background: "#5250E5",
                                color: "#fff",
                                padding: ".5rem .7rem",
                              }}
                            >
                              assign to me
                            </button>
                          </td>
                        ) : (
                          <>
                            <td className="SprintlabelStyle">Assigned to</td>
                            <td style={{ fontSize: "12px" }}>
                              {data.AssignedTo?.name}
                            </td>
                          </>
                        )}
                      </tr>
                    </table>
                    <hr />
                    {/* Content for the second column goes here */}
                    <div
                      className="d-flex"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 style={{ fontWeight: "bold" }}>Properties</h4>
                    </div>
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Status:</label>
                      <Select
                        styles={customStyles}
                        value={data.ProblemStages}
                        onChange={(selecteddata) => {
                          updatefunc(data.ProblemId, {
                            ProblemStages: selecteddata,
                          });
                        }}
                        options={statedropdown}
                      />
                    </div>
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Priority:</label>
                      <Select
                        styles={customStyles}
                        onChange={(selecteddata) => {
                          updatefunc(data.ProblemId, {
                            Priority: selecteddata,
                          });
                        }}
                        value={data.Priority}
                        options={prioritydropdown}
                      />
                    </div>
                    <div className="mt-3">
                      <label className="SprintlabelStyle">
                        Assigned Group:
                      </label>
                      <Select
                        styles={customStyles}
                        options={assignmentoption}
                        value={assignmentoption.filter(
                          (x) => x.value == data.AssignmentGroup.Groupid
                        )}
                        onChange={(e) => {
                          defaultassigntoo(e.value, true);
                        }}
                      />
                    </div>
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Assigned To:</label>
                      <Select
                        styles={customStyles}
                        options={defaulttoo}
                        value={defaulttoo.filter(
                          (x) => x.value == data.AssignedTo?.userid
                        )}
                        onChange={(selecteddata) => {
                          updatefunc(data.ProblemId, {
                            AssignedTo: {
                              userid: selecteddata.value,
                              name: selecteddata.label,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        {imageviewopen ? (
          <Imageviewer imagedata={imagedata} open={open} setOpen={setOpen} />
        ) : null}
      </Dialog>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openprogress}
          autoHideDuration={1000}
          onClose={() => {
            setopenprogress(false);
          }}
        >
          <Alert
            onClose={() => {
              setopenprogress(false);
            }}
            severity="success"
            sx={{ width: "100%", color: "green !important" }}
          >
            {messageinfo}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={erroropen}
          autoHideDuration={2000}
          onClose={() => {
            seterroropen(false);
          }}
        >
          <Alert
            onClose={() => {
              seterroropen(false);
            }}
            severity="error"
            sx={{
              width: "100%",
              backgroundColor: "red !important",
              color: "white !important",
            }}
          >
            {errormessage}
          </Alert>
        </Snackbar>
      </div>


    </>
  );
};

export default ProblemDetail;
