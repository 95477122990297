import React, { useState } from "react";
import ProgressEditForm from "./ProgressEditForm";
import axios from "../../../axios";
import { styled } from "@material-ui/styles";
import moment from "moment";
import { Avatar, Button } from "@material-ui/core";
import Imageviewer from "./Imageviewer";
import { useDispatch, useSelector } from 'react-redux';
import updatemoment from "../updatemoment";
import Unassigned from "../../../assets/images/avatar.png";

function Progressdataform({
  KanbanBoardData,
  apidata,
  Reupdatedata,
  FirstName,
  LastName,
  fname,
  lname,
  date,
  originalestimatedata,
  FINALEUSER_DATA,
  // SelectedCardId,
  // KBRefresh,
  // progressdata,
  // setProgressData
}) {
  const [editformOpen, setEditformOpen] = useState(false);
  const [progressEditdata, setProgressEditedata] = useState([]);
  const [loading, setLoading] = useState(true);
  //
  const [imageviewopen, setImageviewopen] = useState(false);
  const [open, setOpen] = useState(false);
  const [imagedata, setImagedata] = useState();

  const { allImage } = useSelector((state) => state.user)

  const BootstrapButton = styled(Button)({
    textTransform: "none",
    fontSize: 13,
    padding: "5px 10px",
    margin: 10,
    width: 45,
    border: "0px solid",
  });

  const PreviosUpdateData = apidata.sort((b, c) =>
    b.IssueProgressId > c.IssueProgressId ? 1 : -1
  );


  const BeforeData = apidata[apidata.length - 2];

  function handleClickOpen(e) {
    console.log(e?.match(/<img .*?>/g), "image check")
    setImageviewopen(e?.match(/<img .*?>/g) == null || e?.match(/<img .*?>/g).length == 0 ? false : true);
    setOpen(true);
    setImagedata(e);
  }

  function EditDatas(e) {
    axios.get("agilesaas_issue_progress?IssueProgressId=eq." + e).then((res) => {
      setProgressEditedata(res.data);
      setLoading(false);
      setEditformOpen(true);
    });
  }

  let Empid = JSON.parse(localStorage.getItem("Empdetails"))?.[0]?.ClientUserId;
  // console.log(Empid)

  const modifyImageTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const images = tempDiv.querySelectorAll('img');
    images.forEach(image => {
      image.style.width = '100%'; // Set the width to your desired value
      image.style.height = '100%'; // Set the height to your desired value
    });

    return tempDiv.innerHTML;
  };
  return (
    <div>
      {!editformOpen ? (
        <div>
          {apidata &&
            apidata
              .sort((a, b) => (b.IssueProgressId > a.IssueProgressId ? 1 : -1))
              .map((e, i) => {
                console.log(e, "progressdata")
                return (
                  <div key={i}>
                    <div className="d-flex mt-3 w-100">
                      <Avatar
                        className="progressName mr-2"
                        src={allImage?.length > 0 ? allImage?.filter((x) => x.teammemberid == e.UpdatedBy)?.[0]?.image : Unassigned}
                      // sx={{ width: 10, height: 10 }} 
                      />
                      {/* {FirstName + LastName}
                      </Avatar> */}
                      <div className="mt-1">
                        <div className="d-flex align-items-center">
                          <p className="Progress_update_user_name fontdesign" style={{ color: 'black' }}>
                            {e.UpdatedByDetails.FN +
                              " " +
                              e.UpdatedByDetails.LN}
                          </p>

                          <p className="Progress_update_user_Date fontdesign" style={{ color: 'black' }}>
                            {moment(
                              e.UpdatedDate,
                              "YYYY-MM-DDTHH:mm:ss"
                            ).format("DD MMM yyyy") +
                              " " +
                              "(" +
                              updatemoment(e.UpdatedDate) +
                              ")"}
                          </p>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <span className="progress_update_edit_data ">
                              <div
                                onClick={() => handleClickOpen(e.Notes)}
                                className="mt-1"
                                style={{ color: 'black', fontWeight: '500' }}
                                dangerouslySetInnerHTML={{
                                  __html: e.Notes ? modifyImageTags(e.Notes) : '',
                                }}
                              ></div>
                            </span>
                          </div>
                          <div className="d-flex align-items-center ">
                            <p className="fontdesign" style={{ color: 'black' }}>Percentage Progressed :</p>
                            <span className="progress_update_edit_data fontdesign" style={{ color: 'black', fontWeight: '500' }}>
                              {e.PercentageProgressed}
                            </span>
                          </div>
                          <div className="d-flex align-items-center ">
                            <p className="fontdesign" style={{ color: 'black' }}> Hours To Work :</p>
                            <span className="progress_update_edit_data fontdesign" style={{ color: 'black', fontWeight: '500' }}>
                              {e.HoursPerDayToComplete == 0
                                ? "NA"
                                : "You have to work" +
                                " " +
                                e.HoursPerDayToComplete +
                                " " +
                                "hours  per day to complete this project "}
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <p className="fontdesign" style={{ color: 'black' }}> Risk of Delivery :</p>
                            {e.EffortRemaining <= 0 &&
                              e.PercentageProgressed < 100 && e.RiskofDelivery == "R" ? (
                              <span className="mb-1 ml-1 fontdesign" style={{ color: 'black', fontWeight: '500' }}>
                                Over Due in {e.EffortRemaining} Days
                                <span
                                  style={{
                                    marginLeft: 2,
                                    color: "white",
                                    borderRadius: 20,
                                    padding: 5,
                                    background: "Red",

                                  }}
                                  className="fontdesign"
                                >
                                  {e.RiskofDelivery}
                                </span>
                              </span>
                            ) : (
                              <span
                                className="progress_update_edit_data_code fontdesign"
                                style={
                                  e.RiskofDelivery == "G"
                                    ? { background: "Green", color: "white" }
                                    : e.RiskofDelivery == "R"
                                      ? { background: "Red", color: "white" }
                                      : e.RiskofDelivery == "A"
                                        ? { background: "Yellow", color: "black" }
                                        : null
                                }
                              >
                                {e.RiskofDelivery}
                              </span>
                            )}
                          </div>
                          <div className="d-flex align-items-center">
                            <p className="fontdesign" style={{ color: 'black' }}>Effort Remaining :</p>
                            <span className="progress_update_edit_data fontdesign" style={{ color: 'black', fontWeight: '500' }}>
                              {e.EffortRemaining}
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <p className="fontdesign" style={{ color: 'black' }}>Effort Burnt :</p>
                            <span className="progress_update_edit_data fontdesign" style={{ color: 'black', fontWeight: '500' }}>
                              {e.EffortBurnt}
                            </span>
                          </div>
                          {/* {console.log(FINALEUSER_DATA[0].AssignedTo, Empid, i == 0)} */}
                          {FINALEUSER_DATA?.[0]?.AssignedTo == Empid && i == 0 ? (
                            <Button
                              style={{ fontSize: 14, padding: 9, width: 70, height: 30, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center', textTransform: 'none' }}
                              onClick={() => {
                                EditDatas(e.IssueProgressId);
                              }}
                            >
                              Edit
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      ) : (
        <div>
          <ProgressEditForm
            editformOpen={editformOpen}
            progressEditdata={progressEditdata}
            loading={loading}
            setLoading={setLoading}
            setEditformOpen={setEditformOpen}
            KanbanBoardData={KanbanBoardData}
            fname={fname}
            lname={lname}
            apidata={apidata}
            date={date}
            BeforeData={BeforeData}
            // SelectedCardId={SelectedCardId}
            originalestimatedata={originalestimatedata}
            PreviosUpdateData={PreviosUpdateData}
            Reupdatedata={Reupdatedata}
          // KBRefresh={KBRefresh}
          // progressdata={progressdata}
          // setProgressData={setProgressData}
          />
        </div>
      )}

      {imageviewopen ? (
        <Imageviewer
          imagedata={imagedata}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
    </div>
  );
}

export default Progressdataform;
