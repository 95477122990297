import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, CardBody, Col, Card, Row } from "reactstrap";
import Avatar from "@mui/material/Avatar";
import Form from 'react-bootstrap/Form';
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";
import Toolbar, { modules, formats } from "../KanbanBoard/Toolbar";
import "react-quill/dist/quill.snow.css";
import "./DetailsNotes.css"
import moment from "moment";
import Select from "react-select";
import Imageviewer from "../KanbanBoard/ProgressUpdate/Imageviewer";
import axios from "../../axios";
import Skeleton from '@mui/material/Skeleton';
import Tooltip from "@mui/material/Tooltip";
import { create } from "@mui/material/styles/createTransitions";
import Utils from "../LoginPage/utlis"
import Unassigned from "../../assets/images/avatar.png";


export default function DetailsNotes(
    { IncidentDetails, SLA_Details, Refreshdata, NotesLoading,
        setNotesLoading, DropLoading, setDropLoading, Sla_Mater,
        DeniedAccess, Userid, assignmentoption, incidentnewId }
) {
    let arr = JSON.parse(localStorage.getItem("Empdetails"));
    // useeffect

    // Style
    const customStylesd = {
        control: (base, state) => ({
            ...base,
            background: "#FFF",
            border: state.isFocused
                ? "2px solid rgb(82, 80, 229)"
                : "1px solid rgba(0, 0, 0, 0.10)",
            borderRadius: 8,
            height: 8,
            fontSize: 12,
            // background: "#f3f3ff",
            // match with the menu
            // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrittes the different states of border
            // borderColor: state.isFocused ? "yellow" : "green",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                // borderColor: state.isFocused ? "red" : "blue"
            },
        }),
        menu: (base) => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
        }),
    };
    const lineStyle = {
        borderTop: "1px solid rgb(0, 0, 0 ,20%) ", // Customize the color and style
        margin: '7px 0', // Add margin above and below the line
    };
    const mainlineStyle = {
        borderTop: "1px solid rgb(0, 0, 0 ,20%) ", // Customize the color and style
        margin: '7px 0', // Add margin above and below the line
        width: '90%',       // Set the width of the line
        margin: '0 auto',
    };
    const LoadingOption = [{ label: "Loading...", value: "...Loading" }]
    // Declarations 
    let CurrentState =
        IncidentDetails.CurrentState.StateCode == "New" ? (
            <span
                className="badge mt-2 NewBadgeInc"
                style={{ fontWeight: "bold" }}
            >
                New
            </span>
        ) : IncidentDetails.CurrentState.StateCode == "Acknowledged" ? (
            <span
                className="badge mt-2 AckBadgeInc"
                style={{ fontWeight: "bold" }}
            >
                Acknowledged
            </span>
        ) : IncidentDetails.CurrentState.StateCode == "Work in progress" ? (
            <>
                <span
                    className="badge mt-2 WorkBadgeInc"
                    style={{ fontWeight: "bold" }}
                >
                    Work in Progress
                </span>
            </>
        ) : IncidentDetails.CurrentState.StateCode == "User Verfication" ? (
            <span
                className="badge mt-2 UserBadgeInc"
                style={{ fontWeight: "bold" }}
            >
                User Acceptance
            </span>
        ) : IncidentDetails.CurrentState.StateCode == "User verified" ? (
            <span
                className="badge mt-2 UserBadgeInc"
                style={{ fontWeight: "bold" }}
            >
                User verified
            </span>
        ) : IncidentDetails.CurrentState.StateCode == "Resolved" ? (
            <span
                className="badge mt-2 ResolveBadgeInc"
                style={{ fontWeight: "bold" }}
            >
                Resolved
            </span>
        ) : (
            ""
        )
    const { allImage, userImage, IncidentId, statusoption, PriorityOption, AssignedGroupOption } = useSelector((state) => state.user)
    const statusoption1 = [
        { value: "Work in progress", label: "Rework" },
        { value: "User verified", label: "User verified" },
    ]
    console.log(AssignedGroupOption, "AssignedGroupOption")
    const AssignedGrp = AssignedGroupOption?.filter((e) => e.value === IncidentDetails?.AssignedGroup?.GroupCode)
    console.log(IncidentDetails, "GODOFWAR", AssignedGroupOption?.filter((e) => e.value === IncidentDetails?.AssignedGroup?.GroupCode))
    const AssignedToOption = AssignedGrp[0]?.users?.map((e) => { return { value: e.UserId, label: e.Firstname + " " + e.Lastname, FN: e.Firstname, LN: e.Lastname } })
    AssignedToOption.push({ value: null, label: "unassigned", FN: null, LN: null })
    const [Formopen, setFormopen] = useState(false)
    const [imageviewopen, setImageviewopen] = useState(false);
    const [open, setOpen] = useState(false);
    const [imagedata, setImagedata] = useState();
    const [PatchData, setPatchData] = useState([])
    const [Commonts, setCommonts] = useState("")
    const [SerialNo, setSerialNo] = useState("")
    // Functions 
    function timeAgo(dateString) {
        const currentDate = new Date();
        const givenDate = new Date(dateString);
        console.log(dateString)
        const timeDifference = currentDate - givenDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);
        console.log(timeDifference)


        if (seconds < 60) {
            return seconds + " sec ago";
        } else if (minutes < 60) {
            return minutes + " min ago";
        } else if (hours < 24) {
            return hours + " hours ago";
        } else if (days < 30) {
            return days + " days ago";
        } else if (months < 12) {
            return months + " months ago";
        } else {
            return years + " years ago";
        }
    }
    const modifyImageTags = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        const images = tempDiv.querySelectorAll('img');
        images.forEach(image => {
            image.style.width = '20%'; // Set the width to your desired value
            image.style.height = '20%'; // Set the height to your desired value
        });

        return tempDiv.innerHTML;
    };
    const modifydivTags = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        return tempDiv.innerHTML;
    };
    function handleClickOpen(e) {
        setImageviewopen(e?.match(/<img .*?>/g) == null || e?.match(/<img .*?>/g).length == 0 ? false : true);
        setOpen(true);
        setImagedata(e);
    }
    const handlecomment = (props) => {
        setCommonts(props)
        let arr = JSON.parse(localStorage.getItem("Empdetails"));
        const updatedNotes = [...IncidentDetails.UpdateNotes];
        if (SerialNo === "") {
            const PushData = {
                NoteSno: updatedNotes.length + 1,
                DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                NoteUpdatedBy: arr[0].ClientUserId,
                NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
                NotesDetails: props
            }
            updatedNotes.push(PushData)
            setPatchData(updatedNotes)
        }
        else {
            const index = updatedNotes.findIndex(note => note.NoteSno === SerialNo);
            updatedNotes[index].NotesDetails = props;
            updatedNotes[index].DateTime = moment().format("YYYY-MM-DD HH:mm:ss");
            setPatchData(updatedNotes)

        }
    }
    const handlesubmit = (data) => {
        if (data === "Save") {
            if (PatchData.length > 0 && Commonts !== "") {

                axios
                    .patch("agilesaas_incident_details?IncidentId=eq." + IncidentId, {
                        "UpdateNotes": PatchData
                    }).then((e) => {
                        alert("Notes Updated Successfully");
                        handlecancel()
                    }).catch((E) => console.log(E))
            }
        }
        else if (data === "Reason") {
            axios
                .patch("agilesaas_incident_sla_details?IncidentId=eq." + IncidentId, {
                    "OutlierReason": PatchData[PatchData.length - 1].NotesDetails,
                    "IsOutlierRequested": "Y"
                }).then((e) => {
                    alert("Outlier Reason Updated Successfully");
                    handlecancel()
                }).catch((E) => console.log(E))
        }
    }
    const handleRemoveNote = (noteSno) => {
        // Filter out the note with the specified NoteSno
        const updatedNotes = [...IncidentDetails.UpdateNotes];
        const filteredNotes = updatedNotes.filter((note) => note.NoteSno !== noteSno);
        const DelfilteredNotes = updatedNotes.filter((note) => note.NoteSno === noteSno);
        // Set the filtered array to the state
        if (DelfilteredNotes[0].NoteUpdatedBy === Userid) {
            axios
                .patch("agilesaas_incident_details?IncidentId=eq." + IncidentId, {
                    "UpdateNotes": filteredNotes
                }).then((e) => {
                    alert("Note Deleted Successfully");
                    handlecancel()
                }).catch((E) => console.log(E))
        }

    };
    const handledropdown = (e, type) => {
        console.log(e, "eeehandlwe")
        let patchdata = null
        let Sla_Patch_Data = null
        const holidaysArray = [
            new Date('2023-01-01'),
            new Date('2023-04-14'), // Good Friday
            new Date('2023-07-04'), // Independence Day
            new Date('2023-12-25'), // Christmas Day
            new Date('2023-05-29'), // Memorial Day
            new Date('2023-09-04'), // Labor Day
            new Date('2023-11-23'), // Thanksgiving Day
            new Date('2023-02-14'), // Valentine's Day
            new Date('2023-06-18'), // Father's Day
            new Date('2023-10-31')  // Halloween
        ];
        // Replace 'holidaysArray' with an array of holiday dates (if any)      
        if (IncidentDetails.CurrentState.StateCode !== e.value) {
            switch (type) {
                case "AssignedTo":
                    const AssingedToDetails = {
                        "FN": e.FN,
                        "LN": e.LN
                    }
                    let PatchAssignedTo = {
                        "AssignedTo": e.value,
                        "AssignedToDetails": AssingedToDetails, // Assuming AssingedDetails is a variable
                        "CurrentState": {
                            "StateCode": "New",
                            "StateName": "New"
                        },
                        "CreatedDate": moment().format("YYYY-MM-DD HH:mm:ss"),
                    };
                    let SLAPatchAssigned = {
                        "IsAcknowledged": null,
                        "AcknowledgedByDetails": e.value,
                        "AcknowledgementDate": null,
                        "AcknowledgementTimeTaken": null,
                        "PauseSLAClock": null,
                        "PauseSLAClockDetails": null,
                        "IsResolved": null,
                        "ResolvedByDetails": null,
                        "ResolvedDate": null,
                        "ResolutionTimeTaken": null,
                        "IsSLAMet": null,
                        "SLABreachDetails": null,
                        "IsOutlierApproved": null,
                        "OutlierReason": null,
                        "SLAHolidayApplicableReason": null,
                        "IsActive": null,
                        "Status": null,
                        "IsOutlierRequested": null,
                        StatusHistory: null
                    }
                    if (e.value !== IncidentDetails.AssignedTo) {
                        axios
                            .patch("agilesaas_incident_sla_details?IncidentId=eq." + IncidentId, SLAPatchAssigned).then((e) => {
                                Refreshdata()
                            }).catch((E) => console.log(E))
                        axios
                            .patch("agilesaas_incident_details?IncidentId=eq." + IncidentId, PatchAssignedTo).then((e) => {
                                Refreshdata()
                            }).catch((E) => console.log(E))
                    }

                    break;
                case "AssignedGroup":
                    let AssignedGroup = {
                        "GroupCode": e.value,
                        "GroupName": e.label
                    }
                    const AssingedDetails = {
                        "FN": null,
                        "LN": null
                    }
                    let PatchAssigned = {
                        "AssignedTo": null,
                        "AssignedToDetails": AssingedDetails, // Assuming AssingedDetails is a variable
                        "AssignedGroup": AssignedGroup,
                    };
                    if (e.value !== IncidentDetails.AssignedGroup.GroupCode) {
                        axios
                            .patch("agilesaas_incident_details?IncidentId=eq." + IncidentId, PatchAssigned).then((e) => {
                                Refreshdata()
                            }).catch((E) => console.log(E))
                    }
                    break;
                case "status":
                    if (e.value === "Acknowledged") {
                        const createdDate = new Date(moment(IncidentDetails.CreatedDate));
                        const acknowledgementDate = new Date(); // Replace this with your actual acknowledgement date
                        const acknowledgementTimeTaken = Utils.calculateBusinessMinutes(createdDate, acknowledgementDate, 9, 18, holidaysArray);
                        // The rest of your code remains unchanged
                        const selectedSLA = Sla_Mater.find((sla) => sla.Priority === IncidentDetails.Priority.PriortyCode);
                        const isAcknowledged = acknowledgementTimeTaken <= selectedSLA.AcknowledgementSLA;
                        const SLA_Breach = isAcknowledged ? "N" : "Y"

                        const statushistory = [...SLA_Details.StatusHistory];
                        statushistory.push({
                            Stage: "Acknowledged",
                            stagemoveddate: moment().format("YYYY-MM-DD HH:mm:ss"),
                            stagemovedby: arr[0].UserFirstname + " " + arr[0].UserLastname,
                        });

                        const Sla_Patch_Data = {
                            AcknowledgedByDetails: Userid,
                            IsAcknowledged: isAcknowledged ? 'Y' : 'N',
                            AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                            AcknowledgementTimeTaken: acknowledgementTimeTaken,
                            SLABreachDetails: SLA_Breach === "Y" ? acknowledgementTimeTaken - selectedSLA.AcknowledgementSLA : null,
                            StatusHistory: statushistory,
                            // AcknowledgedByDetails:
                        };
                        if (SLA_Details.AcknowledgementTimeTaken === null) {
                            axios
                                .patch("agilesaas_incident_sla_details?IncidentId=eq." + IncidentId, Sla_Patch_Data).then((e) => {
                                    Refreshdata()
                                }).catch((E) => console.log(E))
                        }
                    }
                    else if (e.value === "User verified") {
                        patchdata = {
                            CurrentState: {
                                "StateCode": e.value,
                                "StateName": e.value
                            }
                        }
                        const statushistory = [...SLA_Details.StatusHistory];
                        statushistory.push({
                            Stage: e.value,
                            stagemoveddate: moment().format("YYYY-MM-DD HH:mm:ss"),
                            stagemovedby: arr[0].UserFirstname + " " + arr[0].UserLastname,
                        });
                        let Userv = {
                            StatusHistory: statushistory,
                        }
                        axios
                            .patch("agilesaas_incident_sla_details?IncidentId=eq." + IncidentId, Userv).then((e) => {
                                Refreshdata()
                            }).catch((E) => console.log(E))
                    }
                    else if (e.value === "User Verfication") {
                        patchdata = {
                            CurrentState: {
                                "StateCode": e.value,
                                "StateName": e.value
                            }
                        }
                        const statushistory = [...SLA_Details.StatusHistory];
                        statushistory.push({
                            Stage: e.value,
                            stagemoveddate: moment().format("YYYY-MM-DD HH:mm:ss"),
                            stagemovedby: arr[0].UserFirstname + " " + arr[0].UserLastname,
                        });
                        const Sla_User_Patch_Data = {
                            "PauseSLAClockDate": moment().format("YYYY-MM-DD HH:mm:ss"),
                            "PauseSLAClock": "Y",
                            StatusHistory: statushistory,
                        };
                        axios
                            .patch("agilesaas_incident_sla_details?IncidentId=eq." + e.IncidentId, Sla_User_Patch_Data).then((e) => {

                            }).catch((E) => console.log(E))
                    }
                    else if (e.value === "Resolved") {
                        const ResolvedDate = SLA_Details.PauseSLAClockDate ? new Date(SLA_Details.PauseSLAClockDate) : new Date();
                        const createdDate = new Date(SLA_Details.AcknowledgementDate);
                        const resolutionTimeTaken = Utils.calculateBusinessMinutes(createdDate, ResolvedDate, 9, 18, holidaysArray);
                        const selectedSLA = Sla_Mater.find((sla) => sla.Priority === IncidentDetails.Priority.PriortyCode);
                        const isResolved = resolutionTimeTaken <= selectedSLA.ResolutionSLA;
                        const statushistory = [...SLA_Details.StatusHistory];
                        statushistory.push({
                            Stage: "Resolved",
                            stagemoveddate: moment().format("YYYY-MM-DD HH:mm:ss"),
                            stagemovedby: arr[0].UserFirstname + " " + arr[0].UserLastname,
                        });
                        const Sla_Patch_Data = {
                            ResolvedByDetails: Userid,
                            IsSLAMet: isResolved ? 'Y' : 'N',
                            ResolvedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                            ResolutionTimeTaken: resolutionTimeTaken,
                            IsResolved: 'Y',
                            StatusHistory: statushistory,
                        };
                        axios
                            .patch("agilesaas_incident_sla_details?IncidentId=eq." + IncidentId, Sla_Patch_Data).then((e) => {
                                Refreshdata()
                            }).catch((E) => console.log(E))
                    }
                    patchdata = {
                        CurrentState: {
                            "StateCode": e.value,
                            "StateName": e.value
                        }
                    }
                    break;
                case "Priority":
                    patchdata = {
                        Priority: {
                            "PriortyCode": e.value,
                            "PriortyName": e.code
                        }
                    }
                    break;
                default:
                    patchdata = null
                    Sla_Patch_Data = null
                    break;

            }
            if (patchdata !== null) {
                axios
                    .patch("agilesaas_incident_details?IncidentId=eq." + IncidentId, patchdata).then((e) => {
                        Refreshdata()
                        setDropLoading(true)
                    }).catch((E) => console.log(E))
            }
        }
    }
    const convertMinutesToTime = (totalMinutes) => {
        const minutesInHour = 60;
        const minutesInDay = 24 * minutesInHour;
        const minutesInMonth = 30 * minutesInDay; // Assuming 30 days in a month
        const minutesInYear = 365 * minutesInDay; // Assuming 365 days in a year

        const years = Math.floor(totalMinutes / minutesInYear);
        const remainingMinutesAfterYears = totalMinutes % minutesInYear;

        const months = Math.floor(remainingMinutesAfterYears / minutesInMonth);
        const remainingMinutesAfterMonths = totalMinutes % minutesInMonth;

        const days = Math.floor(remainingMinutesAfterMonths / minutesInDay);
        const remainingMinutesAfterDays = totalMinutes % minutesInDay;

        const hours = Math.floor(remainingMinutesAfterDays / minutesInHour);
        const remainingMinutes = totalMinutes % minutesInHour;

        return { years, months, days, hours, remainingMinutes };
    };
    const handlesubmitreason = (type) => {
        axios
            .patch("agilesaas_incident_sla_details?IncidentId=eq." + IncidentId, {
                "IsOutlierApproved": type,
                "IsSLAMet": type
            }).then((e) => {
                handlecancel()
            }).catch((E) => console.log(E))
    }
    const handlecancel = () => {

        setCommonts("")
        setPatchData([])
        setSerialNo("")
        setFormopen(false)
        Refreshdata()
        setNotesLoading(true)
    }
    // Components
    const Header = () => {
        const { years, months, days, hours, remainingMinutes } = convertMinutesToTime(SLA_Details?.AcknowledgementTimeTaken);
        return (
            <div className="container">
                <div className="header d-flex" >
                    <h3 style={{ fontWeight: "600" }}><span className="mdi mdi-email-outline mr-1"></span>{IncidentDetails.IncidentTitle}</h3>
                    {DropLoading ? <span
                        className="badge mt-2"
                        style={{ fontWeight: "bold", color: "#808080", background: "transparent" }}
                    >
                        Loading...
                    </span> : CurrentState}
                </div>
                <div className="mt-1">
                    <p></p>
                    <div
                        onClick={() => handleClickOpen(IncidentDetails.Description)}
                        className="mt-1"
                        style={{ color: 'black', fontWeight: '500' }}
                        dangerouslySetInnerHTML={{
                            __html: IncidentDetails.Description ? modifyImageTags(IncidentDetails.Description) : '',
                        }}
                    ></div>
                </div>
                {/* {SLA_Details.AcknowledgementTimeTaken !== null ?
                    <div className="mt-3" style={{ fontSize: "10px" }}>
                        <span
                            className={SLA_Details.isAcknowledged === "Y" ? "badge bg-success text-success" : "badge bg-transparent text-muted"}

                        >
                            {days + " days " + hours + "hrs " + remainingMinutes + " mins"}
                        </span>

                    </div>
                    : <></>} */}
            </div>
        )
    }

    const Activity = () => (
        <></>
    )
    const OuterlierReason = () => (
        <>{SLA_Details.IsOutlierRequested === "Y" && SLA_Details.IsOutlierApproved === null && DeniedAccess ?
            <div className="container mt-3 ml-3">
                <div className="header d-flex" >
                    <h5 style={{ fontWeight: "600" }}>Outlier Reason</h5>
                </div>
                <div className="">

                    <div
                        dangerouslySetInnerHTML={{
                            __html: modifydivTags(SLA_Details.OutlierReason),
                        }}
                    ></div>

                    <div className="d-flex mt-3">
                        <span className="text-info font10" role="button" onClick={() => handlesubmitreason("Y")}>Approve</span>
                        <span className="text-info font10 ml-3" role="button" onClick={() => handlesubmitreason("N")}>Reject</span>
                    </div>
                </div>
            </div> : <></>}
        </>
    )
    const Notes = () => {
        return IncidentDetails.UpdateNotes.length > 0 ? IncidentDetails.UpdateNotes?.filter((r) => r.NoteSno !== 1)?.sort((a, b) => b.NoteSno - a.NoteSno)?.map((e, index) => (
            <div className="container mt-3">
                <div className="d-flex justify-space-between mt-3">
                    <Avatar
                        alt=""
                        src={
                            allImage?.length > 0 &&
                                allImage?.filter(
                                    (x) =>
                                        x.teammemberid == e.NoteUpdatedBy
                                )?.[0]?.image != undefined ? allImage?.filter(
                                    (x) =>
                                        x.teammemberid == e.NoteUpdatedBy
                                )?.[0]?.image : Unassigned
                        }
                        sx={{ width: 30, height: 30 }}
                    />
                    <label className="ml-2 mt-2" style={{ fontSize: "14px", fontWeight: "600" }}>{e.NoteUpdatedByFN}</label>
                    <label className="ml-2 mt-3" style={{ fontSize: "10px", color: "grey" }}>{timeAgo(e.DateTime)}</label>
                </div>
                <div className="mt-2">
                    {/* <p>{e.NotesDetails}</p> */}
                    {NotesLoading ? <div class="col-6" role="status">
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </div> :
                        <div
                            onClick={() => handleClickOpen(e.NotesDetails)}
                            dangerouslySetInnerHTML={{
                                __html: modifyImageTags(e.NotesDetails),
                            }}
                        ></div>
                    }
                    <div className="d-flex mt-3">
                        <span className="text-info font10" role="button" onClick={() => {
                            setFormopen(true)
                            setSerialNo(e.NoteSno)
                            handlecomment(e.NotesDetails)
                        }}>Edit</span>
                        {e.NoteUpdatedBy === Userid ?
                            <span className="text-grey ml-2 font10" role="button"
                                onClick={() => handleRemoveNote(e.NoteSno)}>Delete</span>
                            : <span className="text-grey ml-2 font10" role="none"
                            >Delete</span>}
                    </div>
                </div>
            </div>

        )) : (<></>)
    }
    const Sidebar = () => (
        <Card style={
            IncidentDetails.CurrentState.StateCode === "Resolved" ? {
                opacity: "0.5",
                border: "1px solid rgb(0, 0, 0 ,20%) ",
                pointerEvents: "none"
            } :
                { border: "1px solid rgb(0, 0, 0 ,20%) " }}>
            <div className="container">
                <div className="row mt-3">
                    <div className="col-12 mt-3">
                        <div className="flex justify-between">
                            <span className="font20blackbold">{IncidentDetails.CurrentState.StateCode === "Resolved" ? "Closed" : "Open"}</span>
                            <span className="font8blackbold">Priority {IncidentDetails.Priority.PriortyCode}</span>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6">
                        {DropLoading ? <span
                            className="badge mt-2"
                            style={{ fontWeight: "bold", color: "#808080", background: "transparent" }}
                        >
                            Loading...
                        </span> : CurrentState}
                    </div>

                </div>
                <hr style={lineStyle} />
                {console.log(SLA_Details.IsSLAMet, "SLA_Details")}

                <div className="row mt-3">
                    <div className="col-12 mt-3">
                        <div className="flex">
                            <span className="font14blackbold">Ticket details</span>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">

                    <div className="col-5">
                        <span className="font12grey">Created by</span>
                    </div>
                    <div className="col-7">
                        <span className="font12blackbold flex">
                            <Avatar
                                alt=""
                                src={
                                    allImage?.length > 0 && allImage?.filter(
                                        (x) =>
                                            x.teammemberid == IncidentDetails.CreatedBy
                                    )?.[0]?.image != undefined ? allImage?.filter(
                                        (x) =>
                                            x.teammemberid == IncidentDetails.CreatedBy
                                    )?.[0]?.image : Unassigned
                                }
                                className="mr-2"
                                sx={{ width: 20, height: 20 }}

                            />
                            {IncidentDetails.CreatedByDetails.FN + " " + IncidentDetails.CreatedByDetails.LN}</span>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-5">
                        <span className="font12grey">Created at</span>
                    </div>
                    <div className="col-7">
                        <span className="font12blackbold">{moment(IncidentDetails.CreatedDate).format("DD MMM YYYY hh:mm A")}</span>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-5">
                        <span className="font12grey">Category</span>
                    </div>
                    <div className="col-7">
                        <span className="font12blackbold">{IncidentDetails.CategoryCode.CategoryName}</span>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-5">
                        <span className="font12grey">Sub-Category</span>
                    </div>
                    <div className="col-7">
                        <span className="font12blackbold">{IncidentDetails.SubCategoryCode.SubCategoryName}</span>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-5">
                        <span className="font12grey">Assigned To</span>
                    </div>
                    <div className="col-7">
                        <span className="font12blackbold">{IncidentDetails.AssignedToDetails.FN + " " + IncidentDetails.AssignedToDetails.LN}</span>
                    </div>
                </div>
                <hr style={lineStyle} />
                {/* properties */}
                <div className="row mt-1">
                    <div className="col-12 mt-1">
                        <div className="flex">
                            <span className="font14blackbold">Properties</span>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-12 mt-1">
                        <label className="font11blackbold">Status Change</label>
                        <Select
                            className=""
                            styles={customStylesd}
                            onChange={(e) => handledropdown(e, "status")}
                            placeholder="select"
                            isDisabled={(DeniedAccess && IncidentDetails.CurrentState.StateCode !== "User Verfication") || (IncidentDetails.AssignedTo === null) || (!DeniedAccess && IncidentDetails.CurrentState.StateCode === "User Verfication")}
                            options={DropLoading ? LoadingOption : IncidentDetails.CurrentState.StateCode === "User Verfication" ? statusoption1 : statusoption}
                            value={DropLoading ? { label: "Loading...", value: "...Loading" } : statusoption.filter((e) => e.label === IncidentDetails.CurrentState.StateCode)}
                        ></Select>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-12 mt-1">
                        <label className="font11blackbold">Priority</label>
                        <Select
                            className=""
                            styles={customStylesd}
                            isDisabled={DeniedAccess}
                            value={DropLoading ? { label: "Loading...", value: "...Loading" }
                                : PriorityOption.filter((e) => e.value === IncidentDetails.Priority.PriortyCode)}
                            onChange={(e) => handledropdown(e, "Priority")}
                            placeholder="select"
                            options={PriorityOption}

                        ></Select>

                    </div>
                </div>
                {/* assignmentoption */}
                <div className="row mt-1 mb-3">
                    <div className="col-12 mt-1">
                        <label className="font11blackbold">Assigned Group</label>
                        <Select
                            className=""
                            styles={customStylesd}
                            isDisabled={DeniedAccess}
                            placeholder="select"
                            options={AssignedGroupOption}
                            value={DropLoading ? { label: "Loading...", value: "...Loading" }
                                : AssignedGroupOption?.filter((e) => e.value === IncidentDetails?.AssignedGroup?.GroupCode)}
                            onChange={(e) => handledropdown(e, "AssignedGroup")}
                        ></Select>
                    </div>
                </div>
                <div className="row mt-1 mb-3">
                    <div className="col-12 mt-1">
                        <label className="font11blackbold">Assigned To</label>
                        <Select
                            className=""
                            styles={customStylesd}
                            isDisabled={DeniedAccess}
                            placeholder="select"
                            options={AssignedToOption}
                            onChange={(e) => handledropdown(e, "AssignedTo")}
                            value={AssignedToOption?.filter((e) => (e.value === IncidentDetails.AssignedTo))}
                        ></Select>
                    </div>
                </div>
            </div>
        </Card>)
    return (
        <div>
            <Row>
                <Col lg={9} md={7} xl={9}>
                    <Header />
                    <hr style={mainlineStyle} className="mt-5 mb-3" />
                    <div className="container mb-3 mt-3">
                        <div className="header d-flex" >
                            <h5 style={{ fontWeight: "600" }}>Activity</h5>
                        </div>
                        <div className="mt-3 flex" >
                            <Avatar
                                alt=""
                                src={
                                    userImage
                                }
                                sx={{ width: 35, height: 35 }}

                            />
                            <div className="w-auto">
                                {!Formopen ? (
                                    <input
                                        className="form-control rounded ml-2"
                                        style={{ background: '#fff', border: "1px solid rgb(0, 0, 0 ,30%) ", width: "600px", color: 'black', height: "35px", borderRadius: "5px" }}
                                        placeholder={(SLA_Details?.IsSLAMet === "N" && SLA_Details?.OutlierReason === null) && DeniedAccess === false ?
                                            "Add a Outlier Reason" : "Add a Comment"}
                                        onClick={() => setFormopen(true)}
                                    // disabled={ADDIGNID != Empid[0].ClientUserId}                            
                                    />
                                ) : null}
                                {Formopen ? (
                                    <div>
                                        <div className="d-flex flex-column ml-2">

                                            <Toolbar />
                                            <ReactQuill
                                                // style={{ width: "600px" }}
                                                className="quill"
                                                theme="snow"
                                                value={Commonts}
                                                onChange={(e) => handlecomment(e)}
                                                modules={modules}
                                                formats={formats}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                <div className="mt-3 ml-2 d-flex justify-space-between">
                                    <Button
                                        onClick={() => {
                                            handlecancel()
                                        }}
                                        style={{ fontSize: 12, padding: 9, width: 70, height: 28, borderRadius: 5, marginRight: 14, border: '2px solid #198ae3 ', backgroundColor: '#fff', color: '#198ae3 ', textAlign: 'center', textTransform: 'none' }}
                                    >Cancel</Button>
                                    <Button
                                        style={{ fontSize: 12, padding: 9, width: 70, height: 28, borderRadius: 5, backgroundColor: '#198ae3 ', color: '#fff', border: '0px solid #198ae3 ', textAlign: 'center', textTransform: 'none', marginRight: 24 }}
                                        onClick={() => handlesubmit((SLA_Details?.IsSLAMet === "N" && SLA_Details?.OutlierReason === null) && DeniedAccess === false ?
                                            "Reason" : "Save")}
                                    >
                                        {(SLA_Details?.IsSLAMet === "N" && SLA_Details?.OutlierReason === null) && DeniedAccess === false ?
                                            "Reason" : "Save"}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={mainlineStyle} />
                    <OuterlierReason />
                    <hr style={mainlineStyle} className="mt-3 mb-5" />
                    <Notes />
                </Col>
                <Col lg={3} md={5} xl={3} className="mb-5">
                    <Sidebar />
                </Col>
            </Row>
            {imageviewopen ? (
                <Imageviewer
                    imagedata={imagedata}
                    open={open}
                    setOpen={setOpen}
                />
            ) : null}
        </div>
    )
}