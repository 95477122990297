import { Input, Table, TextField } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import axios from "../../axios";
import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { Slide, toast } from "react-toastify";
import MaterialTable from "material-table";
import PatchedPagination from "../../PatchedPagination";
import ReactImageFallback from "react-image-fallback";
import incidetimg from "../../assets/images/Incident.png";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ComputerIcon from '@mui/icons-material/ComputerOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import Button from "@mui/material/Button";
// import "react-toastify/dist/ReactToastify.css";
import { NavLink, Link, useLocation } from "react-router-dom";
import { INDETAILSUPDATE } from "../../redux/CommonInitialsate";
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import Avatar from "@mui/material/Avatar";
import Problem from "./Problem";
import DetailsNotes from "./DetailsNotes";
import Toolbar, { modules, formats } from "../KanbanBoard/Toolbar";
import ReactQuill from "react-quill";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import MyIncidentNotes from "./MyIncidentNotes";
import IncidentCard from "./IncidentCard";
import {
  Box,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "react-bootstrap";
import { Container, CardBody, Col, Card, Row } from "reactstrap";
import moment from "moment";
import "./RaiseATicket.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Unassigned from "../../assets/images/avatar.png";




toast.configure();

function RaiseATicket({ useeffect2, setuseeffect2, setopenmodel, openmodel }) {

  const empdetails = JSON.parse(localStorage.getItem("Empdetails"));
  const onbaord =
    empdetails[0].OnboardedBy == "800001" ? 1 : empdetails[0].OnboardedBy;
  const clientid = JSON.parse(localStorage.getItem("clientid"));
  const clientdetails = JSON.parse(
    localStorage.getItem("Orgsubscriptiondetails")
  );
  let smrole = localStorage.getItem("ProjectRole");

  let role =
    sessionStorage.getItem("Roledetails") == "undefined"
      ? ""
      : JSON.parse(sessionStorage.getItem("Roledetails"));
  // let Assignedtooption = [];
  const dispatch = useDispatch()
  // const contextval = useContext(UserContext);

  function Refreshdata() {
    axios
      .get("agilesaas_incident_details?CreatedBy=eq." + arr[0].ClientUserId)
      .then((response) => {
        console.log(response.data, "incidentdata")
        settabledata(response.data);
        setNotesLoading(false)
        setDropLoading(false)
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_sla_details")
      .then((response) => {
        setsla(response.data);
        // console.log(response.data);
      })
      .catch((error) => console.log(error));
  }
  let Impactoption = [
    { value: "1", label: "High" },
    { value: "2", label: "Medium" },
    { value: "3", label: "Low" },
  ];
  let urgencyoption = [
    // Urgency, Impact : 1-High, 2-Medium, 3-Low
    { value: "1", label: "High" },
    { value: "2", label: "Medium" },
    { value: "3", label: "Low" },
  ];
  let PriorityOption = [
    // 1 - System down, 2 - Wide impact ,3 - Moderate impact , 4 - Low impact
    { value: "P1", label: "P1-System down", code: "System down" },
    { value: "P2", label: "P2-Wide impact", code: "Wide impact" },
    { value: "P3", label: "P3-Moderate impact", code: "Moderate impact" },
    { value: "P4", label: "P4-Low impact", code: "Low impact" }
  ];
  const [Sla_Mater, setSla_Master] = useState([])
  const defaultCategoryValue = { value: "INQORHELP", label: "Inquiry / Help" };
  const [NotesLoading, setNotesLoading] = useState(false)
  const [DropLoading, setDropLoading] = useState(false)
  const [Acktime, setAcktime] = useState([]);
  const [checked1, setChecked1] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleClick1 = () => setChecked1(!checked1);
  const [Assignedtooption, setAssignedtooption] = useState([])
  const [sla, setsla] = useState([]);
  const [view, setView] = useState("cardview");
  const [tabledata, settabledata] = useState([]);
  const [RaiseFor, setRaiseFor] = useState("Y");
  const [HandleSomeValue, setHandleSomeValue] = useState("");
  const [Emptabledata, setEmptabledata] = useState([]);
  const [category, setcategory] = useState([]);
  const [categoryValue, setcategoryValue] = useState([]);
  const [SubCategoryOP, setSubCategoryOP] = useState([]);
  const [categoryId, setcategoryId] = useState('Inquiry / Help');
  const [categoryId1, setcategoryId1] = useState("INQORHELP");
  const [handleerror, sethandleerror] = useState("");
  const [Subvalue, setSubvalue] = useState("");
  const [SIvalue, setSIvalue] = useState("");
  const [Ivalue, setIvalue] = useState("2");
  const [Pvalue, setPvalue] = useState("P2");
  const [Uvalue, setUvalue] = useState("2");
  const [Subvaluecode, setSubvaluecode] = useState("");
  const [SIvaluecode, setSIvaluecode] = useState("");
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const [Ivaluecode, setIvaluecode] = useState("Medium");
  const [Pvaluecode, setPvaluecode] = useState("Wide impact");
  const [Uvaluecode, setUvaluecode] = useState("Medium");
  const [GroupCode, setGroupCode] = useState("0")
  const [Titlevalue, setTitlevalue] = useState("");
  const [Desvalue, setDesvalue] = useState("");
  const [Refresh, setRefresh] = useState(false);
  const [StateId, setStateId] = useState("");
  const [StateData, setStateData] = useState([]);
  const [open, setopen] = useState(false);
  const [incidentnewId, setincidentnewId] = useState("");
  const [approveid, setapproveid] = useState("");
  const [approvedailog, setapprovedailog] = useState(false);
  const [AprroveOutlierReason, setAprroveOutlierReason] = useState("");
  const [AssignId, setAssignId] = useState("");
  const [StatusUp, setStatusUp] = useState("");
  const [mapping, setmapping] = useState([]);
  const [assign, setassign] = useState("");
  const [assigngrp, setasssigngrp] = useState("");
  const [assigngrplabel, setassigngrplabel] = useState("");
  const [incident, setincident] = useState(false);
  const [Problemopen, setproblem] = useState(false);
  const [tickproblem, settickproblme] = useState(false)
  const [Upload, setupload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [impacts, setImpacts] = useState([]);
  const [Prior, setPrior] = useState([]);
  const [statusfilter, setstatusfilter] = useState("");
  const [priiortyfilter, SetPriortiryf] = useState("");
  const [cateoryff, Setcategoryff] = useState("");
  const [Lookup, Setlookup] = useState([]);
  const [assignmentoption, setassignmentoption] = useState([]);
  const [Assignemt, SetAssignmet] = useState([]);
  const [linkedtoproblem, setlinkedtoproblem] = useState([]);
  const [linkedincidentp, setlinkedincidentp] = useState([]);
  let arr = JSON.parse(localStorage.getItem("Empdetails"));
  let roledata = mapping?.map((e) => e?.projectrole?.RoleName)[0];

  let subcategoryoption;
  let raisingforvalid = "";
  let categoryvalid = "";
  let subcategoryvalid = "";
  let serviceimpactvalid = "";
  const viewoption = [
    { label: "CardView", value: "cardview" },
    { label: "TabelView", value: "tabelview" },
  ];
  const priorchange = (e, data, i) => {
    let pss = e.target.value;

    setPrior(pss);

    let patchdatapro = {
      Priority: {
        PriortyName: pss,
        PriortyCode:
          pss == "P1"
            ? "System down"
            : pss == "P2"
              ? "Wide impact"
              : pss == "P3"
                ? "Moderate impact"
                : null,
      },
    };
    axios
      .patch(
        "agilesaas_incident_details?IncidentId=eq." + data[0].IncidentId,
        patchdatapro
      )
      .then((response) => {
        // setRefresh(true);
      })
      .catch((error) => console.log(error));
    Refreshdata();
  };

  const { allImage, IncidentId, projectDetails, projectCode } = useSelector((state) => state.user);
  const prjshcode = projectDetails?.filter((e) => e.projectcode == projectCode)

  const reseteverything = () => {
    setcategoryId('Inquiry / Help');
    setcategoryId1("INQORHELP");
    setHandleSomeValue("");
    sethandleerror("");
    setSubvalue("");
    setSIvalue("");
    setIvalue("2");
    setPvalue("P2");
    setUvalue("2");
    setSelectedFile(null);
    setSubvaluecode("");
    setSIvaluecode("");
    setIvaluecode("Medium");
    setPvaluecode("Wide impact");
    setUvaluecode("Medium");
    setTitlevalue("");
    setDesvalue("");
    setassign("");
    setasssigngrp("");
  };

  const resetfile = () => {
    setSelectedFile(null);
  };
  const onChange = () => {
    setincident(true);
  };
  const togglePopup = () => {
    setupload(!Upload);
  };
  const handleclose = () => {
    setincident(false);
    setproblem(false);
  };
  const handleaprroveclose = () => {
    setapprovedailog(false);
    setapproveid("");
    setAprroveOutlierReason("");
    reseteverything();
    setRefresh(true);
  };
  const handleaprrove = (e) => {
    let data = sla.filter((g) => g.IncidentId == e);
    let dataack = sla.filter((t) => t.IncidentId == e);

    let mygettime = dataack.map((x) => {
      return {
        AcknowledgementTimeTaken: x.SLABreachDetails,
        ResolvedTimeTaken: x.ResolutionTimeTaken,
        PauseSLAClockDetails:
          x.PauseSLAClockDetails == null
            ? ""
            : x.PauseSLAClockDetails[1].PauseSLAClock == ""
              ? ""
              : x.PauseSLAClockDetails[1].PauseSLAClock,
      };
    });
    setAcktime(mygettime);
    setAprroveOutlierReason(
      data == ""
        ? ""
        : data[0].OutlierReason == null
          ? "Yet not Update"
          : data[0].OutlierReason
    );
    console.log();
    setapproveid(e);
    setapprovedailog(true);
  };
  const handleApprovesubmit = (e) => {
    if (checked1 == false) {
      alert("Please Check the Approved");
    } else {
      let patchdtasla = {
        IsOutlierApproved: "Y",
      };
      let M2notificationData = {
        CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
        CreatedBy: arr[0].ClientUserId, //current Login
        NotifyTo: 500001, //buddy
        AudienceType: "Individual",
        Priority: "High",
        Subject: "Outlier Approved",
        Description:
          arr[0].UserFirstname +
          " " +
          arr[0].UserLastname +
          ", Accept your reason",
        IsSeen: "N",
        Status: "New",
      };
      axios
        .post("notification?NotifyTo=eq." + 500001, M2notificationData)
        .then((res) => console.log(res))
        .catch((error) => console.log(error));
      axios
        .patch(
          "agilesaas_incident_sla_details?IncidentId=eq." + approveid,
          patchdtasla
        )
        .then((response) => {
          alert("Approved Successfully");
          handleaprroveclose();
        })
        .catch((error) => console.log(error));
    }
  };
  if (useeffect2) {
    // contextval.setwindows("itsm");
    axios
      .get("company_sla_master")
      .then((response) => {
        setSla_Master(response.data)
        // console.log(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_sla_details")
      .then((response) => {
        setsla(response.data);
        // console.log(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_details")
      .then((response) => {
        let mysetdata;
        mysetdata = response.data.sort(
          (firstItem, secondItem) =>
            firstItem.IncidentId - secondItem.IncidentId
        );
        let nextva = mysetdata[mysetdata.length - 1];
        let incidentnewId1 = response.data.length + 1;
        let maxId = 0;
        response.data?.forEach(item => {
          if (item.IncidentId > maxId) {
            maxId = item.IncidentId;
          }
        });
        setincidentnewId(maxId + 1);
        // console.log(incidentnewId)
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_category_master?ClientId=eq." + clientid)
      .then((response) => {
        console.log(response.data, "reponse");
        setcategory(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_details?CreatedBy=eq." + arr[0].ClientUserId)
      .then((response) => {

        settabledata(response.data);
        let incidentdet = response.data?.filter((s) => s.ClientId == clientid && s.CurrentState.StateCode == "New" && s.isLinkedtoproblem == null || s.isLinkedtoproblem?.islinked == false)

        setlinkedtoproblem(incidentdet?.map((incident, index) => ({

          sno: index + 1,
          id: incident.IncidentId,
          label: `${incident.IncidentTitle}-${incident.IncidentId}`,
          incident: `${incident.IncidentTitle}-${incident.IncidentId}`,
          value: incident.IncidentId,
          status: incident?.CurrentState?.StateName,
          Priority: incident.Priority?.PriortyName,
          raisedBy:
            incident?.CreatedByDetails?.FN +
            "" +
            incident?.CreatedByDetails?.LN,
          raisedDate: moment(incident.CreatedDate).format("YYYY-MM-DD"),
          refnumber: incident.IncidentReference
        })))
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_itsmassignmentgroup_master")
      .then((response) => {
        SetAssignmet(response.data);
        console.log(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("subscription_user_group_mapping?ClientId=eq." + clientid)
      .then((response) => {
        let userGroupMappings = response.data
        const resultArray = userGroupMappings.reduce((acc, userGroupMapping) => {
          userGroupMapping.GroupDetails.forEach((groupDetail) => {
            const groupId = groupDetail.GroupId;
            const groupName = groupDetail.GroupName;

            // Check if the group already exists in the result array
            const existingGroupIndex = acc.findIndex((group) => group.GroupId === groupId);

            if (existingGroupIndex !== -1) {
              // Add the user to the existing group
              acc[existingGroupIndex].users.push({
                Firstname: userGroupMapping.UserDetails.Firstname,
                Lastname: userGroupMapping.UserDetails.Lastname,
                UserId: userGroupMapping.UserId,
              });
            } else {
              // Create a new group with the user
              acc.push({
                GroupId: groupId,
                GroupName: groupName,
                users: [{
                  Firstname: userGroupMapping.UserDetails.Firstname,
                  Lastname: userGroupMapping.UserDetails.Lastname,
                  UserId: userGroupMapping.UserId,
                }],
              });
            }
          });

          return acc;
        }, []);
        const assignmentooption = resultArray.map((e) => {
          console.log(e.users, "daddy1")
          return {
            label: e.GroupName,
            value: e.GroupId,
            users: e.users
          }
        })
        setassignmentoption(assignmentooption)
        console.log(resultArray, "daddy");
      })
      .catch((error) => console.log(error));
    setuseeffect2(false)
  }
  if (Refresh) {
    axios
      .get("agilesaas_incident_sla_details")
      .then((response) => {
        setsla(response.data);
        // console.log(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_details")
      .then((response) => {
        let mysetdata;
        mysetdata = response.data.sort(
          (firstItem, secondItem) =>
            firstItem.IncidentId - secondItem.IncidentId
        );
        let nextva = mysetdata[mysetdata.length - 1];
        console.log(nextva.IncidentId + 1, "Sort", mysetdata, nextva);
        let incidentnewId1 = response.data.length + 1;
        let maxId = 0;
        response.data?.forEach(item => {
          if (item.IncidentId > maxId) {
            maxId = item.IncidentId;
          }
        });
        setincidentnewId(maxId + 1);
        // console.log(incidentnewId)
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_category_master?ClientId=eq." + clientid)
      .then((response) => {
        setcategory(response.data);
      })
      .catch((error) => console.log(error));

    setRefresh(false);
  }
  let categoryoption =
    category == ""
      ? ""
      : category.map((e) => {
        return {
          value: e.CategoryCode,
          label: e.CategoryName,
          id: e.IncidentCategoryId,
        };
      });
  console.log(categoryoption, "categ")
  // let groupoption =
  // Assignemt == ""
  //   ? ""
  //   : Assignemt.map((e) => {
  //       return {
  //         value: e.AssignmentGroupDetails[0].GroupCode,
  //         label: e.AssignmentGroupDetails[0].GroupName,
  //         id:  e.AssignmentGroupDetails[0].GroupId,
  //       };
  //     });
  // Assuming your API response is stored in a variable called Assignemt
  const assignmentGroup = Assignemt[0]; // Assuming there's only one group, adjust accordingly if needed

  // let groupoption = [];

  // if (assignmentGroup && assignmentGroup.AssignmentGroupDetails) {
  //   groupoption = assignmentGroup.AssignmentGroupDetails.map((group) => ({
  //     value: group?.GroupCode,
  //     label: group?.GroupName,
  //     id: group?.GroupId,
  //     // description: group.GroupDescription,
  //   }));
  // }

  // console.log(groupoption);

  // console.log(groupoption, "groupoption");
  const handleClickOpen1 = (data, z) => {
    let ndata = data.UpdateNotes;
    let ve = data.verified;

    setStatusUp(ve);
    setAssignId("Raise an Incident");
    setStateData(ndata);
    setStateId(z);
    setopen(true);
  };
  const handlecategory = (e) => {
    setSubvalue("");
    sethandleerror("");
    setcategoryId(e.label);
    console.log(e.id, "e.id")
    console.log(e.label, "elabel", e.value, "evalue");
    setcategoryId1(e.value);
    let subsample = category.filter((r) => r.IncidentCategoryId == e.id);
    let assignmetsamp = Lookup;

    let assigmentlogic = assignmetsamp == "" ? "" : assignmetsamp.map((e) => e.AssignmentGroup)

    let Subcat =
      subsample == "" ? "" : subsample.map((t) => t.SubCategoryDetails);
    subcategoryoption =
      Subcat == ""
        ? ""
        : Subcat[0].map((u) => {
          return {
            value: u.SubCategoryCode,
            label: u.SubCategoryName,
          };
        });
    setSubCategoryOP(subcategoryoption);

    // setcategoryValue(e.id);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file ? file.name : "");
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    setSelectedFile(event.dataTransfer.files[0]);
  };
  const handleUpload = () => {
    // Logic to upload the file
    if (selectedFile) {
      toast.success(`File uploaded: ${selectedFile}`);

      // Perform the file upload operation here
      console.log("Uploading file:", selectedFile);
    } else {
      console.log("No file selected");
    }
  };

  const handlesubmit = () => {

    sethandleerror("");
    if (RaiseFor == "") {
      sethandleerror("Please Select Raising For");
    } else if (Emptabledata == "" && RaiseFor == "Someone") {
      sethandleerror("Please type proper empid and click 'Enter");
    } else if (categoryId == "") {
      sethandleerror("Please select any category");
    } else if (Subvalue == "") {
      sethandleerror("Please select any sub category");
    } else if (Pvalue == "") {
      sethandleerror("Please select any Priority");
    } else if (Uvalue == "") {
      sethandleerror("Please select any Urgency");
    } else if (Ivalue == "") {
      sethandleerror(" Please select any Impact");
      // } else if (SIvalue == "") {
      //   sethandleerror("Please select any Service Impact");
    } else if (Titlevalue == "") {
      sethandleerror("Please give title");
    } else if (Desvalue == "") {
      sethandleerror("Please give description");
    } else {
      let postdata = {
        CreatedBy: arr[0].ClientUserId,
        IncidentId: incidentnewId,
        ClientId: clientid,
        ClientDetails: {
          Company: clientdetails[0].ClientDetails.Company,
          ContactName: clientdetails[0].ClientDetails.ContactName,
          ContactDesignation: clientdetails[0].ClientDetails.ContactDesignation,
          ContactEmail: clientdetails[0].ClientDetails.ContactEmail,
          ContactPhone: clientdetails[0].ClientDetails.ContactPhone,
        },
        CreatedByDetails: {
          FN: arr[0].UserFirstname,
          LN: arr[0].UserLastname,
        },
        CreatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        CategoryCode: {
          CategoryCode: categoryId1,
          CategoryName: categoryId,
        },
        SubCategoryCode: {
          SubCategoryCode: Subvalue,
          SubCategoryName: Subvaluecode,
        },
        ServiceAffected: {
          ServiceCode: SIvalue,
          ServiceName: SIvaluecode,
        },
        IncidentTitle: Titlevalue,
        Description: Desvalue,
        Impact: {
          ImpactCode: Ivalue,
          ImpactName: Ivaluecode,
        },
        Urgency: {
          UrgencyCode: Uvalue,
          UrgencyName: Uvaluecode,
        },
        Priority: {
          PriortyCode: Pvalue,
          PriortyName: Pvaluecode,
        },
        UpdateNotes: [
          {
            NoteSno: 1,
            DateTime: null,
            NoteUpdatedBy: null,
            NoteUpdatedByFN: null,
            NotesDetails: null,
          },
        ],
        AssignedTo: assign?.value,
        AssignedToDetails: {
          FN: assign?.FN,
          LN: assign?.LN,
        },
        AssignedGroup: {
          GroupCode: assigngrp,
          GroupName: assigngrplabel,
        },
        CurrentState: {
          StateCode: "New",
          StateName: "New",
        },
        IsSelfRequest: RaiseFor == "Self" ? "Y" : "N",
        RaisingOnBehalfDetails:
          RaiseFor == "Someone" ? mapping[0]?.teammemberid : null,
        IncidentReference: "INC" + arr[0].ClientId + "I" + incidentnewId,
        IsActive: "Y",
        isLinkedtoproblem: {
          islinked: false,
        }
      };
      let patchdatai = {
        IncidentId: incidentnewId,
        IncidentPriority: Pvalue,
        IsSLAApplicable: "Y",
        IsSLAHolidayApplicable: "Y",
        ClientId: clientid,
        ClientDetails: {
          Company: clientdetails[0].ClientDetails.Company,
          ContactName: clientdetails[0].ClientDetails.ContactName,
          ContactDesignation: clientdetails[0].ClientDetails.ContactDesignation,
          ContactEmail: clientdetails[0].ClientDetails.ContactEmail,
          ContactPhone: clientdetails[0].ClientDetails.ContactPhone,
        },
        "StatusHistory": [],

      };


      reseteverything();
      setRefresh(true);
      axios.post(`agilesaas_incident_details`, postdata).then((res) => {
        // setLoading(false);
        toast.success(`Successfully Submitted`, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          draggable: true,
        });
        console.log(res.data, "post ");
      });



      axios.post(`agilesaas_incident_sla_details`, patchdatai).then((res) => {
        toast.success(`Sla Update Successfully`, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          draggable: true,
        });
        reseteverything();
        setRefresh(true);
        Refreshdata();
      });

      handleclose();
      console.log(postdata, tabledata.length, patchdatai, "tabledata");
    }
  };
  // let apidata=
  // const UPDATED_NOTES_APIDATA =
  //     !loading && apidata.length != 0
  //         ? apidata
  //             .sort((a, b) =>
  //                 a.IncidentId > b.IncidentId
  //                     ? 1
  //                     : a.IncidentId < b.IncidentId
  //                         ? -1
  //                         : null
  //             )
  //             .map((v) => [
  //                 v.IncidentId,

  //             ])
  //         : "";
  const handledesc = (value) => {
    setDesvalue(value);
  };
  const handleSomeOne = (e) => {
    if (e.key === "Enter") {
      if (HandleSomeValue == "") {
        alert("Enter the Valid Emailid ");
      } else if (HandleSomeValue != "") {
        axios
          .get(
            "subscription_client_users?OfficialEmail=eq." +
            HandleSomeValue +
            "&ClientId=eq." +
            clientid
          )
          .then((response) => {
            setEmptabledata(response.data);
            sethandleerror("");
            if (response.data.length == 0) {
              alert("Enter Verified Email-Id");
            }
            axios
              .get(
                `rpc/fun_agilesaasmanagerteamprojdetails?managerid=` +
                response.data[0]?.OnboardedBy +
                `&clientid=` +
                clientid +
                `&teammemberid=eq.` +
                response.data[0]?.ClientUserId
              )
              .then((response) => setmapping(response.data));
          })
          .catch((error) => console.log(error), setEmptabledata(""));
      }
    }
  };
  let RaisingOption = [
    { value: "Self", label: "Self" },
    { value: "Someone", label: "Someone" },
  ];

  let ServiceImpactOption = [
    // Service Impacted - YozyME, Internet / WIFI, Zoho Account, Jira, Git
    { value: "YozyME", label: "YozyME" },
    { value: "Internet / WIFI", label: "Internet / WIFI" },
    { value: "Zoho Account", label: "Zoho Account" },
    { value: "Jira", label: "Jira" },
    { value: "Git", label: "Git" },
  ];

  let Assignedgrpoption = [
    { value: "IT Service Management", label: "IT Service Management" },
  ];

  const Columns = [
    { title: "Title", field: "IncidentTitle" },
    { title: "Status", field: "data1" },
    // { title: "Resolved", field: "data2" },
    { title: "Id", field: "IncidentId", defaultSort: "desc" },

    // { title: "Category", field: "CategoryCode" },
    // { title: "Sub Category", field: "SubCategoryCode" },
    // { title: "Raising On Behalf", field: "RaisingOnBehalfDetails" },
    // { title: "Assigned To", field: "AssignedToDetails" },

    // { title: "Created Time", field: "Time" },
    { title: "Priority", field: "Priority" },
    {
      title: "Current State", field: "CurrentState1",
      render: (e) => (
        <label className={
          e.CurrentState1 === "New" ? 'text-danger' :
            e.CurrentState1 === "Acknowledged" ? 'text-info1' :
              e.CurrentState1 === "Work in progress" ? 'text-warning' :
                e.CurrentState1 === "User Verification" ? 'text-warning' :
                  e.CurrentState1 === "Resolved" ? 'text-success' :
                    ''
        }>
          <strong>
            {e.CurrentState1 === "New" ? "New" :
              e.CurrentState1 === "Acknowledged" ? "Acknowledged" :
                e.CurrentState1 === "Work in progress" ? "Work in progress" :
                  e.CurrentState1 === "User Verification" ? "User Verification" :
                    e.CurrentState1 === "Resolved" ? "Resolved" :
                      ""}
          </strong>
        </label>
      ),
    },


    { title: "Outlier Approve", field: "OutlierReason" },
    { title: "Created At", field: "CreatedDate" },
    { title: "", field: "bug" },
  ];
  const impactchange = (e, data, i) => {
    let nn = e.target.value;

    setImpacts(nn);

    let patchdata = {
      Impact: {
        ImpactCode:
          nn == "High" ? "1" : nn == "Medium" ? "2" : nn == "Low" ? "3" : null,
        ImpactName: nn,
      },
    };

    axios
      .patch(
        "agilesaas_incident_details?IncidentId=eq." + data[0].IncidentId,
        patchdata
      )
      .then((response) => {
        console.log("🚀 ~ file: MyIncidentQueue.js:1770 ~ .then ~ c̥:");
        // setRefresh(true);
      })
      .catch((error) => console.log(error));
    Refreshdata()
  };
  let temp = {};
  temp =
    tabledata == null
      ? ""
      : tabledata.map((e, i) => {
        let data = sla.filter((g) => g.IncidentId == e.IncidentId);

        return {
          ...e,

          Time: moment(e.CreatedDate).format("DD-MMM-YYYY,HH:mm A"),
          verified: e.IsUserVerified,
          Acktime: data[0]?.AcknowledgementTimeTaken,
          ResTime: data[0]?.ResolutionTimeTaken,
          StatusHistory: data[0]?.StatusHistory,
          AcknowledgementDate: data[0]?.AcknowledgementDate,
          PauseSLAClockDetails: data[0]?.PauseSLAClockDetails,
          RefrenceNumber: e.IncidentReference,
          Acknowledgement:
            data == "" ? (
              ""
            ) : data[0].IsAcknowledged == "Y" ? (
              "A"
            ) : data[0].IsAcknowledged == "N" ? (
              "N"
            ) : (
              "M"
            )
          ,
          linkedwithproblem: e.isLinkedtoproblem?.islinked,
          Resoloved: data === "" ? (
            ""
          ) : data[0]?.IsSLAMet == "Y" ? (
            "Y"
          ) : data[0]?.IsSLAMet == "N" ? (
            "N"
          ) : (
            "M"
          ),
          data1: (
            <>
              {data == "" ? (
                ""
              ) : data[0].IsSLAMet == "Y" ? (
                <span
                  class="badge rounded-pill bagdgreen text-white mr-1  ml-3"
                  style={{ color: "#000000", fontWeight: "bold" }}
                >
                  <Tooltip title="Acknowledge" placement="top">
                    A
                  </Tooltip>
                </span>
              ) : data[0].IsSLAMet == "N" ? (
                <span
                  class="badge rounded-pill text-white bagdred mr-1  ml-3"
                  style={{ color: "#F76666", fontWeight: "bold" }}
                >
                  <Tooltip title="Acknowledge" placement="top" style={{ color: "#F76666" }}>
                    A
                  </Tooltip>
                </span>
              ) : (
                <span
                  class="badge rounded-pill bgyl mr-1  ml-3"
                  style={{ color: "#000000", fontWeight: "bold" }}
                >
                  <Tooltip title="Acknowledge" placement="top">
                    A
                  </Tooltip>
                </span>
              )}
              {data == "" ? (
                ""
              ) : data[0]?.IsOutlierRequested == "N" ? (
                <span
                  class="badge rounded-pill bagdgreen  ml-3"
                  style={{ color: "#000000", fontWeight: "bold" }}
                >
                  <Tooltip title="Resolved" placement="top">
                    R
                  </Tooltip>
                </span>
              ) : data[0]?.IsOutlierRequested == "Y" ? (
                <span
                  class="badge rounded-pill text-white bagdred  ml-3"
                  style={{ color: "#F76666", fontWeight: "bold" }}
                >
                  <Tooltip title="Resolved" placement="top" style={{ color: "#F76666" }}>
                    R
                  </Tooltip>
                </span>
              ) : (
                <span
                  class="badge rounded-pill bgyl  ml-3"
                  style={{ color: "#000000", fontWeight: "bold" }}
                >
                  <Tooltip title="Resolved" placement="top">
                    R
                  </Tooltip>
                </span>
              )}
            </>
          ),
          data2:
            data == "" ? (
              ""
            ) : data[0]?.IsOutlierRequested == "N" ? (
              <span class="badge rounded-pill bagdgreen  ml-3">R</span>
            ) : data[0]?.IsOutlierRequested == "Y" ? (
              <span class="badge rounded-pill text-white bagdred  ml-3" style={{ color: "#F76666" }}>R</span>
            ) : (
              <span
                class="badge rounded-pill bgyl  ml-3"
                style={{ color: "#000000", fontWeight: "bold" }}
              >
                R
              </span>
            ),
          OutlierReason:
            data == "" ? (
              ""
            ) : data[0].IsOutlierApproved == "Y" ? (
              <button
                className="badge text-success"
                style={{
                  fontSize: 9,
                  fontWeight: 600,
                  width: 60,
                  borderRadius: 20,
                  backgroundColor: " #e6ffe6",
                  // padding: 2,
                }}
              >
                Approved
              </button>
            ) : data[0].OutlierReason != null ? (
              <button
                className="Card_Button_Color_Approve"
                style={{
                  fontSize: 9,
                  fontWeight: 600,
                  width: 60,
                  borderRadius: 20,
                  padding: 2,
                }}
                onClick={() => handleaprrove(e.IncidentId)}
              >
                Approve
              </button>
            ) : (
              ""
            ),
          Impactname: e?.Impact?.ImpactName,
          PriortyCode: e?.Priority?.PriortyName,
          CreatedByDetails:
            e.CreatedByDetails.FN + " " + e.CreatedByDetails.LN,
          AssignedToDetails:
            e.AssignedToDetails.FN + " " + e.AssignedToDetails.LN,
          RaisingOnBehalfDetails:
            e.IsSelfRequest == "Y" ? "Self" : e.RaisingOnBehalfDetails,
          Priority: e.Priority.PriortyCode,

          CategoryCode: e.CategoryCode.CategoryName,
          SubCategoryCode: e.SubCategoryCode.SubCategoryName,
          datavalid: e.CurrentState.StateCode,
          src: allImage?.length > 0 && allImage?.filter((x) => x.teammemberid == e.CreatedBy)?.[0]
            ?.image != undefined ? allImage?.filter((x) => x.teammemberid == e.CreatedBy)?.[0]
            ?.image : Unassigned,
          avatarimage: (
            <Avatar alt="" className="CardAvartor" src={
              allImage?.length > 0 && allImage?.filter((x) => x.teammemberid == e.CreatedBy)?.[0]
                ?.image != undefined ? allImage?.filter((x) => x.teammemberid == e.CreatedBy)?.[0]
                ?.image : Unassigned
            } sx={{ width: 35, height: 35 }} />
          ),
          Prioritydrop: (
            <div className="d-flex justify-space-between">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-1 ml-2">
                <path id="Vector" d="M8.44935 0H12.3499C12.7075 0 13 0.283317 13 0.629593C13 0.975869 12.7075 1.25919 12.3499 1.25919H8.44935C8.0918 1.25919 7.79925 0.975869 7.79925 0.629593C7.79925 0.283317 8.0918 0 8.44935 0ZM8.44935 3.46276H12.3499C12.7075 3.46276 13 3.74608 13 4.09235C13 4.43863 12.7075 4.72195 12.3499 4.72195H8.44935C8.0918 4.72195 7.79925 4.43863 7.79925 4.09235C7.79925 3.74608 8.0918 3.46276 8.44935 3.46276ZM8.44935 6.92552H12.3499C12.7075 6.92552 13 7.20884 13 7.55511C13 7.90139 12.7075 8.18471 12.3499 8.18471H8.44935C8.0918 8.18471 7.79925 7.90139 7.79925 7.55511C7.79925 7.20884 8.0918 6.92552 8.44935 6.92552ZM5.10137 8.90874L6.26503 7.78177C6.39505 7.65585 6.39505 7.46067 6.26503 7.33476L5.10137 6.20779C5.05655 6.16311 4.99893 6.13246 4.93595 6.11981C4.87297 6.10715 4.80752 6.11306 4.74804 6.13678C4.68857 6.1605 4.63779 6.20093 4.60228 6.25287C4.56676 6.3048 4.54813 6.36585 4.54879 6.42814V8.68838C4.54879 8.9654 4.89984 9.10391 5.10137 8.90874ZM4.54879 6.92552H4.35376C2.82604 6.92552 1.46084 5.84892 1.31132 4.37567C1.27135 3.98174 1.31688 3.58408 1.44499 3.2082C1.57309 2.83232 1.78095 2.48655 2.05521 2.19307C2.32947 1.8996 2.66407 1.66491 3.03752 1.50408C3.41097 1.34325 3.81503 1.25983 4.22374 1.25919H5.84897C6.20652 1.25919 6.49907 0.975869 6.49907 0.629593C6.49907 0.283317 6.20652 0 5.84897 0H4.22374C1.71438 0 -0.300909 2.14062 0.0371396 4.6338C0.310179 6.69887 2.22795 8.18471 4.37326 8.18471H4.54879" fill="black" />

              </svg>

              <select
                key={i}
                className="fontdesign ssls ml-1"
                title="Priority"
                defaultValue={e.Priority.PriortyCode}
                value={Prior[i]}
                disabled={e.CurrentState.StateCode === "Resolved"}
                onChange={(e, i) => {
                  e.stopPropagation();
                  priorchange(e, data, i)
                }}
              >
                <option value="P1" label="Priority 1">P1</option>
                <option value="P2" label="Priority 2">P2</option>
                <option value="P3" label="Priority 3">P3</option>
              </select>
            </div>
          ),
          LabelP: e.Priority.PriortyCode,
          bug: smrole == "SM" && e.CurrentState.StateCode == "User verified" || data[0]?.IsSLAMet === "Y" ? <div className="mt-1 ml-1">
            <button className="ml-2 mt-2"
              onClick={() => {

                createbug(e);
              }}
              disabled={e.IsBugTaskCreated == "Y"}
              hidden={e.IsBugTaskCreated == "Y"}
              style={{

                height: 30,
                borderRadius: 5,
                background: "rgb(246, 202, 204)",
                padding: ".3rem .5rem",
                marginBottom: "5px",
                fontWeight: "bold",
                color: "rgb(208, 34, 36)",

              }}
            >
              Create as Bug
            </button>
            {/* } */}

          </div> : null,
          notesdrop: (

            <button
              variant="outline-secondary"
              size="sm"
              fontSize="small"
              // style={{ fontSize: "17px",alignContent:"start" }}
              className="btn btn-text-dark  btn-sm fontdesign mt-1 "
              style={{ textAlign: "left" }}
              title="Notes"
              onClick={() => {
                //gotonotes
                dispatch(INDETAILSUPDATE(e.IncidentId));
                setopen(true)
              }}
            >
              <SpeakerNotesOutlinedIcon
                className="Card_Button_Color_Approve  "
                style={{
                  color: "black",
                  fontSize: "16px",

                }}
              ></SpeakerNotesOutlinedIcon>{" "}Notes
            </button>
          ),
          Impactdrop: (
            <div className="d-flex justify-space-between">
              <span className="mdi mdi-checkbox-blank-circle ml-3" style={{ color: "#45DD81", fontSize: "9px" }}></span>
              <select
                className="fontdesign ssls ml-2 mb-2"
                defaultValue={e?.Impact?.ImpactName}
                value={impacts[i]}
                disabled={e.CurrentState.StateCode == "Resolved"}
                onChange={(e, i) => {
                  e.stopPropagation();
                  impactchange(e, data)
                }}
                title="Impact"
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
          ),
          CurrentState:
            data != "" ? (
              data[0].IsResolved == "Y" &&
                data[0].IsOutlierApproved == "Y" ? (
                <span className="badge bg-success1 text-success ml-3">
                  Completed
                </span>
              ) : data[0]?.IsOutlierRequested == "Y" &&
                data[0].IsResolved == "Y" ? (
                <span
                  className="badge bg-success1 text-success  ml-3"
                  style={{ fontWeight: "bold" }}
                >
                  Resolved
                </span>
              ) : data[0].IsResolved == "Y" &&
                data[0]?.IsOutlierRequested == "Y" &&
                data[0]?.IsOutlierApproved == "Y" ? (
                <span className="badge bg-success1 text-success ml-3">
                  Completed
                </span>
              ) : data[0].IsResolved == "Y" &&
                data[0]?.IsOutlierRequested == "N" ? (
                <span className="badge bg-success1 text-success ml-3">
                  Completed
                </span>
              ) : e.CurrentState.StateCode == "New" ? (
                <span
                  className="badge bg-dangerl text-danger ml-3"
                  style={{ fontWeight: "bold" }}
                >
                  New
                </span>
              ) : e.CurrentState.StateCode == "Acknowledged" ? (
                <span
                  className="badge bg-info1 text-info ml-3"
                  style={{ fontWeight: "bold" }}
                >
                  Ack
                </span>
              ) : e.CurrentState.StateCode == "Work in progress" ? (
                <>
                  <span
                    className="badge bg-info1 text-info ml-3"
                    style={{ fontWeight: "bold" }}
                  >
                    Work in Progress
                  </span>
                </>
              ) : e.CurrentState.StateCode == "User Verfication" ? (
                <span
                  className="badge bg-warning1 text-warning ml-3"
                  style={{ fontWeight: "bold" }}
                >
                  User Acceptance
                </span>
              ) : e.CurrentState.StateCode == "Resolved" ? (
                <span
                  className="badge bg-success text-white ml-3"
                  style={{ fontWeight: "bold" }}
                >
                  Resolved
                </span>
              ) : (
                ""
              )
            ) : e.CurrentState.StateCode == "New" ? (
              <span
                className="badge bg-danger text-white"
                style={{ fontWeight: "bold" }}
              >
                New
              </span>
            ) : e.CurrentState.StateCode == "Acknowledged" ? (
              <span
                className="badge bg-info1 text-secondary"
                style={{ fontWeight: "bold" }}
              >
                Ack
              </span>
            ) : e.CurrentState.StateCode == "Work in progress" ? (
              <>
                <span
                  className="badge bg-info1 text-secondary"
                  style={{ fontWeight: "bold" }}
                >
                  Work in Progress
                </span>
              </>
            ) : e.CurrentState.StateCode == "User Verfication" ? (
              <span
                className="badge bg-warning1 text-warning"
                style={{ fontWeight: "bold" }}
              >
                User Acceptance
              </span>
            ) : e.CurrentState.StateCode == "Resolved" ? (
              <span
                className="badge bg-success text-white"
                style={{ fontWeight: "bold" }}
              >
                Resolved
              </span>
            ) : (
              ""
            ),
          CurrentState1:
            data != "" ?
              e.CurrentState.StateCode == "New" ? "New" : e.CurrentState.StateCode == "Acknowledged" ? "Acknowledged" :
                e.CurrentState.StateCode == "Work in progress" ? "Work in progress"
                  : e.CurrentState.StateCode == "User Verfication" ? "User Verfication" :
                    e.CurrentState.StateCode == "User verified" ? "User verified" :
                      e.CurrentState.StateCode == "Resolved" ? "Resolved"
                        : "New" : "New",
          CreatedDate: e.CurrentState.StateCode == "Resolved" ? moment(data[0].ResolvedDate).format("hh:mm A DD-MMM-YYYY ") : moment(e.CreatedDate).format("hh:mm A DD-MMM-YYYY "),
        };
      });
  const handleOptionChange = (event) => {
    setRaiseFor(event.target.value);
    setEmptabledata("");
    sethandleerror("");
  };
  function handlepriofilter(item) {
    SetPriortiryf(item.value);
  }
  function clear() {
    SetPriortiryf("");
    setstatusfilter("");
    Setcategoryff("");
  }
  const columnsp = [
    {
      title: "S.No",
      field: "sno",
      headerStyle: { fontWeight: "bold", width: "10%" },
    },
    {
      title: "Incident",
      field: "incident",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Priority",
      field: "Priority",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Raised Date",
      field: "raisedDate",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Raised By",
      field: "raisedBy",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Status",
      field: "status",
      headerStyle: { fontWeight: "bold", width: "10%" },
    },
  ];
  const customStylesd = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  let statusoption = [
    // 1 - System down, 2 - Wide impact ,3 - Moderate impact , 4 - Low impact
    { value: "New", label: "New" },
    { value: "Acknowledge", label: "Acknowledge" },
    { value: "Resolved", label: "Resolved" },
    { value: "User Verfication", label: "User Verfication" },
  ];
  function categoryfilter(item) {
    Setcategoryff(item.label);
  }
  const customStylesmenu = {
    menu: (provided) => ({
      ...provided,
      // Set your desired max height
    }),
  };

  function handlestatusfilter(item) {
    setstatusfilter(item.label);
  }
  const handleClickCard = (index, e) => {
    setActiveCardIndex(index);
  };
  const lineStyle = {
    borderTop: "1px solid rgb(0, 0, 0 ,20%) ", // Customize the color and style    
    width: "100%",
    margin: "0 0",
    overflow: "none"

  };
  const handlechangelinked = (selectedValues) => {
    setlinkedincidentp(selectedValues);
  };
  const check =
    priiortyfilter != ""
      ? temp.filter((e) => e.Priority === priiortyfilter)
      : statusfilter != ""
        ? temp.filter((e) => e.datavalid === statusfilter)
        : cateoryff != ""
          ? temp.filter((e) => e.CategoryCode === cateoryff)
          : priiortyfilter != "" && statusfilter != "" && cateoryff != ""
            ? temp.filter(
              (e) =>
                e.datavalid === statusfilter &&
                e.Priority === priiortyfilter &&
                e.CategoryCode === cateoryff
            )
            : temp;


  function createpp() {
    settickproblme(true);

    // setopenmodel(true)
    setproblem(false)
  }
  function createbug(e) {
    // setButtonClicked(true);
    let postData = {
      ProjectId: projectCode,
      ProjectShortCode: prjshcode[0]?.projectshortcode,
      IssueType: "Bug",
      IssueTitle: `${e.IncidentTitle} - ${e.IncidentReference} `,
      Description: `Created from Incident with Refnumber ${e.IncidentReference} \n ${e.Description}`,
      Priority: "Highest",
      PlannedRelease: null,
      EstimateUnit: null,
      EstimateUnitDesc: null,
      CurrentStage: [
        {
          StageCode: "BLG",
          StageName: "Backlog",
          DateMoved: moment().format("YYYY-MM-DD"),
        },
      ],
      LinkToEpic: null,
      LinkToIssues: null,
      CreatedBy: arr[0]?.ClientUserId,
      CreatedByDetails:
        role?.RoleName == "Undefined" ? "Kanban Admin" : role?.RoleName,
      CreatedDate: moment().format("YYYY-MM-DD"),
      AssignedTo: null,
      AssignedToDetails: {
        FN: null,
        LN: null,
      },
      AssignedDate: moment().format("YYYY-MM-DD"),
      Attachment: null,
      IsActive: "Y",
      ExceptiontoSkipSPEstimation: "N",
      ProjectDetails: [{ ProjName: prjshcode[0]?.projectname, Client: clientid }],
      AssignedToUAT: null,
      AssignedToUATDetails: {
        FN: null,
        LN: null,
      },
      ClientId: clientid,
      AttachmentReference: null,
    };


    axios.post("agilesaas_issue_details", postData)
      .then((ress) => {

        axios.get("agilesaas_issue_details?ClientId=eq." + clientid)
          .then((response) => {
            const allRecords = response.data;

            allRecords.sort((a, b) => b.IssueId - a.IssueId);

            const lastPostedRecord = allRecords[0];

            let patchincidentbug = {
              "BugTaskDetails": {
                Project: prjshcode[0]?.projectname,
                TicketReference: lastPostedRecord.IssueId,
              },
              "IsBugTaskCreated": "Y"
            }
            axios.patch("agilesaas_incident_details?IncidentId=eq." + e.IncidentId, patchincidentbug).then((res) => {

              toast.success("Created as a bug");
              Refreshdata();
            }).catch((E) => {
              console.log("errorpatch", E)
            })

          });




      })
      .catch((error) => {
        // Handle errors here
        console.log("error")
      })

  }
  return (
    <div style={{ backgroundColor: "#F5F7FB" }}>
      {/* <MyIncidentNotes
        open={open}
        setopen={setopen}
        StateId={StateId}
        Refreshdata={Refreshdata}
        setStateId={setStateId}
        StateData={StateData}
        setStateData={setStateData}
        Refresh={Refresh}
        setRefresh={setRefresh}
        AssignId={AssignId}
        setAssignId={AssignId}
        StatusUp={StatusUp}
        setStatusUp={setStatusUp}
        view={view}
      /> */}
      {/* goto notes */}
      <Dialog
        open={open}
        onClose={() => {
          setopen(false)
        }}
        maxWidth="lg"
        fullWidth={true}

      >
        <DialogTitle >
          <div className="row">
            <div className="col d-flex justify-content-start"><label className="text-black fontdesign" style={{ fontWeight: "bold" }}>
              {"Details page "}
              {/* <span className="text-muted" style={{ fontSize: "12px" }}></span> */}
            </label></div>
            <div className="col d-flex justify-content-end" role="button" onClick={() => setopen(false)}><CloseIcon /></div>
          </div>
        </DialogTitle>
        <hr style={lineStyle} />
        <DialogContent className="mt-3">
          <DetailsNotes
            IncidentDetails={tabledata?.filter((e) => e.IncidentId === IncidentId)[0]}
            SLA_Details={sla?.filter((e) => e.IncidentId === IncidentId)[0]}
            Refreshdata={Refreshdata}
            NotesLoading={NotesLoading}
            setNotesLoading={setNotesLoading}
            DropLoading={DropLoading}
            setDropLoading={setDropLoading}
            Sla_Mater={Sla_Mater}
            DeniedAccess={true}
            Userid={arr[0].ClientUserId}

          />
        </DialogContent>
      </Dialog>
      <div className="row mt-3">
        <div className="col-lg-9 col-md-7 col-sm-8">
          <button
            variant="outline-secondary"
            size="sm"
            className="btn btn-outline-dark btn-sm fontdesign mt-3 ml-4"
            style={{
              fontWeight: "500",
              background: "transparent",
              color: "black",
              fontSize: "16px",
              width: "161px",
              height: "36px",
              border: "1px solid rgb(0, 0, 0 ,20%)",
              borderRadius: "3px ",
            }}
            onClick={() => setincident(true)}
          >
            <ControlPointIcon fontSize="small" /> Raise Incident
          </button>
          <button
            variant="outline-secondary"
            size="sm"
            className="btn btn-outline-dark btn-sm fontdesign mt-3 ml-4"
            style={{
              fontWeight: "500",
              background: "transparent",
              color: "black",
              fontSize: "16px",
              width: "181px",
              height: "36px",
              border: "1px solid rgb(0, 0, 0 ,20%)",
              borderRadius: "3px ",
            }}
            onClick={() => setproblem(true)}
          >
            <ControlPointIcon fontSize="small" /> Convert problem
          </button>
        </div>
        <div className="col-lg-3 col-md-5 col-sm-4">
          <button
            variant="outline-secondary"
            size="sm"
            style={{
              fontWeight: "500",
              color: view !== "cardview " ? "blue" : "black",
              background: "transparent",
              borderRadius: "4px",
            }}
            className={view !== "cardview " ? "btn btn-outline-info btn-sm sidebutton mt-3 " : "btn btn-outline-dark btn-sm sidebutton mt-3"}

            onClick={() => setView("cardview")}
          >
            <ComputerIcon className="sidebtnicon" /> Card View
          </button>
          <button
            variant="outline-secondary"
            size="sm"
            className={view !== "tabelview " ? "btn btn-outline-info btn-sm sidebutton mt-3 ml-3" : "btn btn-outline-dark btn-sm sidebutton mt-3  ml-3"}
            onClick={() => setView("tabelview")}
            style={{
              color: view !== "tabelview " ? "blue" : "black",
              fontWeight: "500",
              background: "transparent",
              borderRadius: "4px",
            }}
          >
            <TableChartOutlinedIcon className="sidebtnicon" /> Table VIew
          </button>
        </div>
      </div>
      <div className="ml-4 mt-2">
        {assignmentoption?.length === 1 ?
          <label style={{ fontWeight: "600" }}>Belonging To : {assignmentoption[0].label}</label> : <label style={{ fontWeight: "600" }}>Belonging To : <select
            onChange={(f) => {
              // gooto
              setGroupCode(f.target.value)
            }}
          >
            {assignmentoption?.map((e) => <option value={e.value}>{e.label}</option>)}
            <option value="0">all</option>
          </select></label>
        }
      </div>
      <Modal
        // dialogClassName="incidentbox"
        show={incident}
        onHide={handleclose}
        size="xl"
        maxWidth="940px"
        // Set size to "xl" for extra-large, or you can experiment with other sizes like "lg", "sm", etc.
        fullWidth={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="TitlelabelStyle">
            <label style={{ color: "#2593B8", fontWeight: "bold" }}>
              {" "}
              Raise an Incident
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex ml-2 mr-2">
            <div className="col-5">
              <div className="d-flex justify-content-center">
                <ReactImageFallback
                  src={incidetimg}
                  initialImage="loader.gif"
                  alt="cool image should be here"
                  style={{
                    height: "200px", // Adjust the height to your desired size
                    width: "200px",
                    // borderRadius: "50%",
                    fontSize: "10px",
                  }}
                />
              </div>
              <div className="mt-3">
                <b>Hardware Failures: </b>
                Malfunctions in servers, routers, or switches, leading to service interruptions.
              </div>
              <div className="mt-3">
                <b>Software Errors: </b>
                ugs, crashes, or errors in software applications hindering user tasks.
              </div>
              <div className="mt-3">
                <b>Network Outages: </b>
                Loss of network connectivity or bandwidth issues causing service disruptions.
              </div>
            </div>
            <div className="col-7">
              <div>
                <label>Raising For</label>
                <input
                  type="radio"
                  value="Y"
                  className="ml-2 mt-2"
                  style={{ transform: "scale(1.2)" }}
                  checked={RaiseFor === "Y"}
                  onChange={handleOptionChange}
                />
                <label className="ml-2 "> Self</label>
                <input
                  type="radio"
                  value="N"
                  style={{ transform: "scale(1.2)" }}
                  className="ml-2 mt-2 "
                  checked={RaiseFor === "N"}
                  onChange={handleOptionChange}
                />
                <label className="ml-2">Someone</label>
                <button
                  size="sm"
                  className="ml-3"
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    color: "#5250E5",
                  }}
                  onClick={togglePopup}
                >
                  <CloudUploadIcon /> Upload Documents
                </button>
              </div>
              <div>
                {Upload && (
                  <div className="popup">
                    <div
                      style={{ alignItems: "center" }}
                      className={`drop-zone ${dragging ? "dragging" : ""}`}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                    >
                      <label className="file-input-label">
                        <span className="ml-3">
                          Browse
                        </span>
                        <input
                          type="file"
                          accept=".docx, .png, .svg, .gif, .pdf"
                          className="file-input"
                          onChange={handleFileChange}
                        />
                      </label>
                      <p style={{ fontSize: "10px" }}>
                        <span style={{ color: "#5a89f2" }}>
                          Click to Upload{" "}
                        </span>
                        or Drag and Drop
                      </p>

                      <span className="file-name ">
                        {selectedFile || "No file chosen"}
                      </span>

                      <label className="mt-0" style={{ fontSize: "12px" }}>
                        Acceptable .jpg,.png,.svg,
                      </label>
                      <hr></hr>
                      <div>
                        <Button
                          style={{
                            fontSize: 14,
                            padding: 9,
                            width: 60,
                            height: 20,
                            borderRadius: 8,
                            marginTop: 12,
                            marginRight: 14,
                            border: "2px solid #5A88F1",
                            backgroundColor: "#fff",
                            color: "#5250E5",
                            textAlign: "center",
                            textTransform: "none",
                          }}
                          onClick={resetfile}
                        >
                          Reset
                        </Button>
                        <Button
                          style={{
                            fontSize: 14,
                            padding: 9,
                            width: 60,
                            height: 20,
                            marginTop: 12,
                            borderRadius: 8,
                            marginRight: 14,
                            border: "2px solid #5250E5",
                            backgroundColor: "#5250E5",
                            color: "#fff",
                            textAlign: "center",
                            textTransform: "none",
                          }}
                          onClick={handleUpload}
                        >
                          Upload
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {RaiseFor == "N" ? (
                  <>
                    <div>
                      {" "}
                      <label
                        className="labelStyle "
                      >
                        Raising On Behalf{" "}
                        <label className="text-danger"> *</label>
                      </label>
                      <br></br>
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Type email"
                        className="ml-3 SprintFormFields"
                        label=""
                        name=""
                        onChange={(e) => {
                          setHandleSomeValue(e.target.value);
                          setEmptabledata("");
                        }}
                        onKeyDown={handleSomeOne}
                        // className="TextFieldRAT"
                        size="small"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-2 ml-3">
                {Emptabledata != "" &&
                  Emptabledata.map((f) => {
                    return (
                      <Row>
                        <label>
                          {f.UserFirstname +
                            " " +
                            f.UserLastname +
                            " " +
                            roledata}
                        </label>

                        {/* <Col xl={3} xs={3} sm={3} md={3} xxl={3} lg={3}><label  className='RATfontwieght text'>Emp Level</label></Col>
                                        <Col xl={3} xs={3} sm={3} md={3} xxl={3} lg={3}><label  className='RATfontwieght text'>{f.emplevel}</label></Col> */}

                        <Col xl={3} xs={3} sm={3} md={3} xxl={3} lg={3}>
                          <label className="RATfontwieght text"></label>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
              <div>
                <label className=" labelStyle ">
                  Short Description{" "}
                  <label className="text-danger"> *</label>{" "}
                </label>
                <br></br>
                <TextField
                  type="text"
                  variant="outlined"
                  className="mytypeRat "
                  label=""
                  name=""
                  // className="TextFieldRAT"
                  size="small"
                  value={Titlevalue}
                  onChange={(t) => setTitlevalue(t.target.value)}
                />
              </div>
              <div>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <div>
                      <label className="labelStyle lla">
                        {" "}
                        Category <label className="text-danger"> *</label>{" "}
                      </label>
                      <Select
                        label="Category"
                        value={
                          Array.isArray(categoryoption)
                            ? categoryoption.filter((E) => E.value === categoryId1)
                            : defaultCategoryValue
                        }
                        options={categoryoption}
                        styles={customStyles}
                        onChange={handlecategory}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div>
                      {" "}
                      <label className=" mt-2 labelStyle lla">
                        Sub Category <label className="text-danger"> *</label>
                      </label>
                      <Select
                        // id="SubCategory"
                        label="SubCategory"
                        options={SubCategoryOP}
                        styles={customStyles}
                        value={Subvalue == "" ? "" : Subvalue.value}
                        onChange={(e) => {
                          sethandleerror("");
                          setSubvaluecode(e.label);
                          setSubvalue(e.value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div>
                      <label className=" labelStyle ">
                        Assigned Group{" "}
                        <label className="text-danger"> *</label>{" "}
                      </label>
                      <Select
                        label="Assigned To"
                        value={assignmentoption?.filter(
                          (e) => e.value == assigngrp
                        )}
                        options={assignmentoption}
                        styles={customStyles}
                        onChange={(e) => {
                          setassigngrplabel(e.label)
                          setasssigngrp(e.value)
                          console.log(e.Users, "daddy1")
                          setAssignedtooption(e.users?.map((e) => { return { value: e.UserId, label: e.Firstname + " " + e.Lastname, FN: e.Firstname, LN: e.Lastname } }))
                        }}
                        inputProps={{ readOnly: true }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div>
                      <label className="mt-2 labelStyle lla">
                        Assigned To
                      </label>
                      <Select
                        label="Assigned To"
                        className="mt-2"
                        style={{ marginLeft: "46px" }}
                        // components={{ IndicatorSeparator: () => null }}
                        // default={Assignedtooption}
                        // value={Assignedtooption?.filter(
                        //   (e) => e.value == assign
                        // )}
                        options={Assignedtooption}
                        styles={customStyles}
                        onChange={(e) => setassign(e)}
                        inputProps={{ readOnly: true }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div>
                      <label className="labelStyle lla ">
                        {" "}
                        Urgency <label className="text-danger"> *</label>{" "}
                      </label>
                      <Select
                        id="Urgency"
                        label="Urgency"
                        options={urgencyoption}
                        styles={customStyles}
                        value={urgencyoption?.filter((e) => e.value == Uvalue)}
                        onChange={(e) => {
                          sethandleerror("");
                          setUvalue(e.value);
                          setUvaluecode(e.label);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div>
                      <label className="mt-2 labelStyle lla ">
                        Priority <label className="text-danger"> *</label>{" "}
                      </label>
                      <Select
                        label="Priority"
                        // className="react-select-default SelctRAT "
                        options={PriorityOption}
                        styles={customStyles}
                        value={PriorityOption?.filter((e) => e.value == Pvalue)}
                        onChange={(e) => {
                          sethandleerror("");
                          setPvalue(e.value);
                          setPvaluecode(e.code);
                        }}
                      // defaultValue={PriorityOption.find((option) => option.value === '')}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              {/* Description */}
              <div>
                <label className="labelStyle lla">
                  Description <label className="text-danger"> *</label>{" "}
                </label>

                <br></br>
                <Toolbar />
                <ReactQuill
                  className="quillrelease w-100 "
                  theme="snow"
                  placeholder="Enter Description"
                  value={Desvalue}
                  onChange={(e) => handledesc(e)}
                  modules={modules}
                  formats={formats}
                // style={{ width: 'calc(100% - 60px)' }}
                />
              </div>
              <div className="error errorfont">
                {" "}
                {handleerror == "" ? "" : handleerror}{" "}
              </div>
              {/* button */}
              <div className="mt-3 d-flex justify-content-end space-between">
                <Button
                  style={{
                    fontSize: 14,
                    padding: 9,
                    width: 100,
                    height: 40,
                    borderRadius: 8,
                    marginRight: 14,
                    border: "2px solid #5A88F1",
                    backgroundColor: "#fff",
                    color: "#5250E5",
                    textAlign: "center",
                    textTransform: "none",
                  }}
                  onClick={reseteverything}
                >
                  Reset
                </Button>
                <Button
                  style={{
                    fontSize: 14,
                    padding: 9,
                    width: 100,
                    height: 40,
                    borderRadius: 8,
                    // marginRight: 14,
                    border: "2px solid #5250E5",
                    backgroundColor: "#5250E5",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "none",
                  }}
                  onClick={handlesubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="mainbox"
        // dialogClassName="incidentbox"
        show={Problemopen}
        onHide={handleclose}
        size="xl"
        maxWidth="940px"
        // Set size to "xl" for extra-large, or you can experiment with other sizes like "lg", "sm", etc.
        fullWidth={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="TitlelabelStyle">
            <label style={{ color: "#2593B8", fontWeight: "bold" }}>
              {" "}
              Convert Problem
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-4">
            <Col className="col-md-6">
              <Form.Group controlId="description">
                <Form.Label className="SprintlabelStyle">
                  Select incidents{" "}
                  <label className="text-danger"> *</label>
                </Form.Label>
                <Select
                  isMulti
                  options={linkedtoproblem}
                  value={linkedincidentp}
                  onChange={handlechangelinked}
                  placeholder="Search and select options..."
                  isSearchable
                  closeMenuOnSelect={false}
                  styles={customStylesmenu}
                // components={{ Option: CheckboxOption }}
                />
              </Form.Group>
              {/* {linkincidenterror ? (
                        <p className="text-danger">please link incident</p>
                      ) : null} */}
            </Col>
          </Row>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          ></link>
          {linkedincidentp.length > 0 ? (

            <MaterialTable
              style={{ zIndex: 0 }}
              title={"Incident list"}
              columns={columnsp}
              data={linkedincidentp}
              options={{
                // pageSize: 10, // Set the initial page size (you can change this value)
                // pageSizeOptions: [10, 20, 30], // Set the available page size options
                search: false, // Enable/disable search functionality
                sorting: false, // Enable/disable column sorting
                headerStyle: {
                  whiteSpace: "nowrap",
                  fontWeight: "700",
                  fontSize: "medium",
                  // backgroundColor: "#eef2f7",
                  color: "black",
                  padding: "12px ",
                  textAlign: "left",
                },
                cellStyle: {
                  textAlign: "left",
                  fontSize: "medium",
                  padding: "21px",
                  color: "#6c757d",
                  // padding: "15px 2px",
                },
              }}

            />
          ) : (
            <div style={{ paddingBottom: "100px" }}></div>
          )}

        </Modal.Body>
        <div className="mt-3 d-flex justify-content-end space-between mb-3 mr-2">
          {linkedincidentp.length > 0 ? <Button
            style={{
              fontSize: 14,
              padding: 9,
              width: 150,
              height: 40,
              borderRadius: 8,
              // marginRight: 14,
              border: "2px solid #5250E5",
              backgroundColor: "#5250E5",
              color: "#fff",
              textAlign: "center",
              textTransform: "none",
            }}
            onClick={createpp}
          >
            Create Problem
          </Button> : null}
        </div>
      </Modal>

      {/* <Row className="d-flex justify-content-end">
        <span className="labelStyle">Layout :</span>
        <Select
          className="fontdesign viewselect mt-2 mr-5 ml-2"
          styles={customStylesd}
          onChange={(e) => setView(e.value)}
cardview tabelview
          options={viewoption}
          value={viewoption?.filter((e) => e.value == view)}
        ></Select>
      </Row> */}
      <div>

        <div className="row mt-3">
          <div className="col-9">
            {view == "cardview" ? (
              check === "" ? (
                ""
              ) : check && check.length === 0 ? (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 15 }}>
                  <SentimentDissatisfiedOutlinedIcon style={{ fontSize: "5rem" }} />
                  <h2>No Problems Found</h2>
                  <p>We couldn't find any problems matching your filter, or something went wrong.</p>
                  <button style={{
                    width: 100,
                    height: 36,
                    borderRadius: 5,
                    background: "#5250E5",
                    color: "#fff",
                  }} onClick={Refreshdata}>Try Again</button>
                </div>
              ) : (
                check
                  .sort((m, l) => (m.IncidentId < l.IncidentId ? 1 : -1))
                  .filter((e) => e.CurrentState1 != "Resolved" && GroupCode === "0" ? e.AssignedGroup.GroupCode !== null : e.AssignedGroup.GroupCode === GroupCode)
                  .map((e, item) => {
                    // const isActiveCard = activeCardIndex === item;
                    return (
                      <div className=" ml-2" key={item}>


                        <IncidentCard e={e} item={item} tabledata={tabledata?.filter((E) => E.IsActive == "Y")} setopen={setopen} AssingedIncident={false} Refreshdata={Refreshdata} />
                        {/* /devozy */}
                        {/* <Card
                          className={
                            item === 0 ? "inactive-card shadow-sm mt-1 ml-3" :
                              "inactive-card shadow-sm mt-3 ml-3"
                          }
                          onClick={() => handleClickCard(item, e)}
                          style={{ border: "1px solid rgb(0, 0, 0 ,20%)", borderLeft: activeCardIndex === item ? "3px solid blue" : "1px solid rgb(0, 0, 0 ,20%)" }}
                        >
                          <Row style={{ marginTop: "-8px" }}>
                            <Col
                              className=" d-flex justify-content-space between "
                              sm={10}
                            >
                              {e?.avatarimage}

                              <div className="ml-3">
                                <label
                                  className="fontdesign  mt-3"
                                  style={{ fontSize: "12px" }}
                                >
                                  {e?.CurrentState}
                                </label>
                                <label
                                  className="fontdesign ml-2 mt-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  <span
                                    className="fontdesign"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {" "}
                                    {" "}
                                  </span>{" "}
                                  {e.data1}
                                </label>
                                <br></br>
                                <label className="fontdesign mt-1 ml-3 title">
                                  {e.IncidentTitle}
                                </label>
                                <br></br>
                                <span
                                  className="fontdesign mt-1 ml-3"
                                  style={{ fontSize: "14px" }}
                                >
                                  <label>{e.CreatedByDetails}</label>{" "}
                                  <label className="ml-3">
                                    (
                                    {e.RaisingOnBehalfDetails == null
                                      ? "Self"
                                      : "Someone:" +
                                      "On Behalf : " +
                                      e.RaisingOnBehalfDetails}
                                    )
                                  </label>{" "}
                                  <label className="ml-3">{e.Priority}</label>
                                </span>
                                <div>
                                  <span
                                    className="fontdesign  d-flex justify-content-space-between"
                                    style={{ fontSize: "12px", fontWeight: "600" }}
                                  >
                                    <label className="ml-3 mt-1">
                                      <label>Created : {e.Time}</label>
                                      <CircleIcon
                                        className="ml-2"
                                        style={{ fontSize: "5px" }}
                                      ></CircleIcon>
                                      <label className="ml-2">
                                        Assigned To :{" "}
                                        {e.AssignedGroup.GroupCode}{" "}
                                      </label>
                                      <CircleIcon
                                        className="ml-2"
                                        style={{ fontSize: "5px" }}
                                      ></CircleIcon>

                                      <label className="ml-2">
                                        {" "}
                                        Category :
                                        {tabledata
                                          .filter(
                                            (d) => d.IncidentId == e.IncidentId
                                          )
                                          ?.map(
                                            (E) => E.CategoryCode.CategoryName
                                          )}
                                      </label>
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col
                              style={{
                                width: "120px",
                                marginTop: "30px",
                                // marginRight: "60px",
                                color: "black"
                              }}
                            >
                              <div className="mt-1">{e.Impactdrop}</div>
                              <div className="mt-1 ml-2">{e.StateDrop}</div>

                              <div className="mt-1 ml-2">{e.Prioritydrop}</div>
                              {e.notesdrop}
                            </Col>
                            <div></div>
                          </Row>
                        </Card> */}
                      </div>
                    );
                  })
              )
            ) : null}
          </div>
          <div className="col-3 ">
            {" "}
            {view == "cardview" ? (
              <div className="mt-1 mr-2">
                <Card style={{ border: "1px solid rgb(0, 0, 0 ,20%) " }}>
                  <Row className="mt-1">
                    <Col className="d-flex justify-content-start">
                      <span className="mt-2 ml-3" style={{ fontWeight: "bold" }}>
                        Filters
                      </span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={clear}
                        style={{
                          fontSize: 14,
                          padding: 9,
                          width: 100,
                          height: 40,
                          borderRadius: 8,
                          marginRight: 14,
                          border: "none",
                          outline: "none",
                          marginLeft: "35px",
                          backgroundColor: "#fff",
                          color: "#5250E5",
                          textAlign: "right",
                          textTransform: "none",
                        }}
                      >
                        {" "}
                        Clear{" "}
                      </Button>
                    </Col>
                  </Row>

                  <div style={{ alignItems: "center" }}>
                    <label
                      className="mt-3 ml-3 fontdesign"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Priority
                    </label>
                    <Select
                      className="fontdesign  mt-1 ml-3 mr-3"
                      // styles={customStylesd}           
                      options={PriorityOption}
                      placeholder="Any"
                      onChange={(item) => {
                        handlepriofilter(item);
                      }}
                      value={PriorityOption?.filter(
                        (e) => e.value === priiortyfilter
                      )}
                    ></Select>
                    <label
                      className="mt-3 ml-3 fontdesign"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Status
                    </label>
                    <Select
                      className="fontdesign  mt-1 ml-3 mr-3"
                      // styles={customStylesd}           
                      placeholder="Any"
                      options={statusoption}
                      onChange={(item) => {
                        handlestatusfilter(item);
                      }}
                      value={statusoption?.filter(
                        (e) => e.label === statusfilter
                      )}
                    ></Select>

                    <label
                      className="mt-3 ml-3 fontdesign"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Category
                    </label>
                    <Select
                      className="fontdesign  mt-1 ml-3 mr-3 mb-3"
                      // styles={customStylesd}           
                      options={categoryoption}
                      onChange={(item) => {
                        categoryfilter(item);
                      }}
                      value={
                        categoryoption &&
                        categoryoption?.filter((e) => e.label === cateoryff)
                      }
                      placeholder="Any"
                    ></Select>
                  </div>
                </Card>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {
        view == "tabelview" ? (
          <div className="mt-5" style={{ padding: "1px" }}>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            ></link>
            <MaterialTable
              title={"Incident Raised By Me"}
              columns={Columns.map((e) => e)}
              data={temp === "" ? "" : temp.map((e) => e)}
              components={{
                Pagination: PatchedPagination,
              }}
              detailPanel={(rowdata) => {
                console.log(rowdata, "rowdata")
                return (
                  <div
                    style={{
                      fontSize: 13,
                      color: "white",
                      // backgroundColor: "#ffff",
                    }}
                  >
                    <Table
                      class="table table-bordered"
                      style={{
                        fontSize: "10px",
                        fontFamily: "DM Sans, sans-serif;",
                        backgroundColor: "#ebedf2",
                      }}
                    >
                      <thead
                        className="tableHeader"
                        style={{
                          fontSize: "10px",
                          fontFamily: "DM Sans, sans-serif;",
                        }}
                      >
                        {/* <th>Title</th> */}
                        <th style={{ fontWeight: "bold" }}>Description</th>
                        <th style={{ fontWeight: "bold" }}>
                          RaisingOnBehalfDetails
                        </th>
                        {/* <th>CreatedDate</th> */}
                        <th style={{ fontWeight: "bold" }}>CategoryCode</th>
                        <th style={{ fontWeight: "bold" }}>SubCategoryCode</th>
                        <th style={{ fontWeight: "bold" }}> AssignedToDetails</th>

                        {/* AssignedToDetails: e.AssignedToDetails.FN + " " + e.AssignedToDetails.LN,
            CategoryCode.CategoryName, */}
                        {/* <th>Urgency</th>
                                                <th>Impact</th> */}
                      </thead>

                      <tr>
                        {/* <td>{rowdata.IncidentTitle}</td> */}
                        <td> {rowdata.Description}</td>
                        <td>
                          {rowdata.IsSelfRequest == "Y"
                            ? "Self"
                            : rowdata.RaisingOnBehalfDetails}
                        </td>
                        {/* <td>{moment(rowdata.CreatedDate).format('DD-MM-YYYY HH:mm:ss')}</td> */}
                        <td> {rowdata.CategoryCode}</td>
                        <td> {rowdata.SubCategoryCode}</td>
                        {/* <td>{rowdata.ServiceAffected.ServiceName}</td> */}
                        <td>{rowdata.AssignedToDetails}</td>

                        {/* <td>{rowdata.Impact.ImpactName}</td> */}
                      </tr>
                    </Table>
                  </div>
                );
              }}
              options={{
                actionsColumnIndex: -1,
                exportAllData: true,
                exportButton: true,
                columnResizable: true,
                filtering: false,
                sorting: true,
                paging: true,
                pageSize: 20, // make initial page size
                emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                pageSizeOptions: [6, 12, 20, 50], // rows selection options

                // rowStyle: rowData => ({
                //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                // }),
                headerStyle: {
                  whiteSpace: "nowrap",
                  fontWeight: "700",
                  fontSize: "medium",
                  // backgroundColor: "#eef2f7",
                  color: "black",
                  padding: "12px ",
                  textAlign: "left",
                },
                cellStyle: {
                  textAlign: "left",
                  fontSize: "medium",
                  padding: "21px",
                  color: "#6c757d",
                  // padding: "15px 2px",
                },
                // headerStyle: {
                //   padding: "2px",
                //   fontWeight: "700",
                //   fontSize: "small",
                //   backgroundColor: "#107aeb85",
                //   color: "#03256c",
                // },
                // cellStyle: {
                //   fontSize: "12px",
                //   padding: "2px",
                //   color: "#03256c",
                // },
                rowStyle: (rowdata) => {
                  if (rowdata.tableData.id % 2 != 0) {
                    // return { backgroundColor: "#f6f7fb" };
                  }
                },
              }}
              actions={[
                (rowData) => ({
                  icon: () => (
                    <SpeakerNotesOutlinedIcon
                      className="Card_Button_Color_Approve1"
                      style={{
                        color: "rgb(25, 138, 227)",
                      }}
                    >
                      {" "}
                      Notes{" "}
                    </SpeakerNotesOutlinedIcon>
                  ),
                  tooltip: "Notes",
                  //   disabled: rowData.isrequestfulfilled == "Y",
                  onClick: (e, data) => {
                    console.log(data, "data");
                    // if (data.datavalid != "Resolved") {
                    handleClickOpen1(data, data.IncidentId);
                    // }
                  },
                }),
              ]}
            />

            {/* <Modal
          // style={{width:"1000px"}}
          ClassName="uploadbox"
          show={Upload}
          // size="sm"
          onHide={handleclose}
        >
          <Modal.Header closeButton>
          
          </Modal.Header>
          <Modal.Body>
            <Container>
             
            </Container>
          </Modal.Body>
        </Modal> */}
          </div>
        ) : null
      }
      {tickproblem == true || openmodel == true ?
        <Problem tickproblem={tickproblem} settickproblme={settickproblme} linkedincidentp={linkedincidentp} setproblem={setproblem} Problemopen={Problemopen} setlinkedincidentp={setlinkedincidentp} /> : null
      }
    </div >
  );
}

export default RaiseATicket;
