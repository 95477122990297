import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "./DetailPanel.css";
import { Slider, Avatar, Dialog, Tooltip, IconButton } from "@material-ui/core";
import Button from "@mui/material/Button";
import { styled } from "@material-ui/styles";
import EditIcon from "@material-ui/icons/Edit";
import AttachFileIcon from "@material-ui/icons/AttachFile";
// import AddBoxIcon from "@material-ui/icons/AddBox";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "../../../axios";
import { Row } from "reactstrap";
import Bookmark from "@material-ui/icons/Bookmark";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import AlbumRounded from "@material-ui/icons/AlbumRounded";
import MaterialIcon, { colorPalette } from "material-icons-react";
import moment from "moment";
import Select from "react-select";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Imageviewer from "./Imageviewer";
import { Button as Button1 } from "react-bootstrap";
import Anne from "../../../assets/images/anne.jpg";
import { useDispatch, useSelector } from "react-redux";
import Unassigned from "../../../assets/images/avatar.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import getFile from "../../../getFile";
import getImage from "../../../getImage";
import FilePreview from "./FilePreview";
import {
  HANDLE_ON_CHANGE,
  KANBANBOARDDATAUPDATE,
} from "../../../redux/CommonInitialsate";
// import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import PropTypes from "prop-types";
import Mailandnotification from "../../../Mailandnotification";
import slacknotify from "../../../slacknotify";
import { DeleteForever } from "@material-ui/icons";
import LinkOffIcon from '@material-ui/icons/LinkOff';
import DeleteIcon from '@material-ui/icons/Delete';

toast.configure();

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 14px)",
    right: "calc(50% + 14px)",
  },
  active: {
    "& $line": {
      borderColor: "#45DD81",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#45DD81",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
    // paddingLeft: 35, // Adjust left padding as needed
    // paddingRight: 20,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#45DD81",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}
QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // height: "110px",
    // display: "flex",
    // justifyContent: "center", // Center the Stepper horizontally
    // alignItems: "center", // Center the Stepper vertically
    // border: "1px solid blue",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// function getStepContent(step) {
//   switch (step) {
//     case 0:
//       return "Select campaign settings...";
//     case 1:
//       return "What is an ad group anyways?";
//     case 2:
//       return "This is the bit I really care about!";
//     default:
//       return "Unknown step";
//   }
// }
function DetailsPanel(
  {
    // EmpDropDownData,
    // selectedFile,
    // setSelectedFile,
    // KanbanBoardData,
    // SelectedCardId,
    // KBRefresh,
    // Data,
    // desc,
    // setSelectedCardId,
    // setAssignedTo,
    // setPriority,
    // setCheckChange,
    // setStageName,
    // setStageCode,
    // setReleaseLabel,
    // setReleaseValue,
    // releases,
    // setAssignedId,
    // description,
    // setDescription,
    // setUatTo,
    // setUatId,
    // displaySubTask,
    // subTaskApi,
    // createdId,
    // projectRole,
    // attachmentReference,
    // setRefresh,
  }
) {
  // console.log(selectedFile, "selectedfile");
  const {
    allImage,
    KanbanBoardData,
    projectCode,
    releases,
    filesContent,
    settingDetails,
    selectedCard1,
    projectRoleName,
    SelectedCardId,
    displaySubTask,
    EmpDropDownData,
    projectRole,
    description,
    selectedFile,
    projectName,
    channelname,slacktoken
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const Data =
    KanbanBoardData.filter((x) => x.IssueId == SelectedCardId)?.length != 0
      ? KanbanBoardData.filter((x) => x.IssueId == SelectedCardId)
      : KanbanBoardData.filter((x) => x.IssueId == SelectedCardId);
  const attachmentReference =
    Data?.length != 0 ? Data?.[0]?.AttachmentReference : null;
  const createdId = Data?.length != 0 ? Data?.[0]?.CreatedBy : null;
  const desc = Data?.length != 0 ? Data?.[0]?.Description : null;
  let clientid = JSON.parse(localStorage.getItem("clientid"));
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", "normal", "large"] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["image"],
      ["clean"],
    ],
  };
  // console.log(displaySubTask, "displaysubtask")
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "image",
  ];

  const BootstrapButton = styled(Button)({
    textTransform: "none",
    fontSize: 13,
    padding: "6px 12px",
    marginLeft: 20,
    marginTop: 40,
    marginBottom: 10,
    border: "0px solid",
  });
  const BootstrapBtn = styled(Button)({
    textTransform: "none",
    fontSize: 13,
    padding: "5px 10px",
    margin: 5,
    border: "0px solid",
  });

  const Story = Data;
  const type = Story.length != 0 ? Story[0]?.IssueType : null;
  const assignedEmpId = Story.length != 0 ? Story[0]?.AssignedTo : null;
  const issueTitle = Story.length != 0 ? Story[0]?.IssueTitle : null;
  const issueId = Story.length != 0 ? Story[0]?.IssueId : null;
  const shortCode = Story.length != 0 ? Story[0]?.ProjectShortCode : null;
  const projectId = Story.length != 0 ? Story[0]?.ProjectId : null;

  const [edit, setEdit] = useState(false);
  const [subTask, setSubTask] = useState(false);
  const [subTaskTitle, setSubTaskTitle] = useState("");
  const [epicSubTask, setEpicSubTask] = useState("Task");
  const [nameApi, setNameApi] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  let arr = JSON.parse(sessionStorage.getItem("Roledetails"));
  let emplogin = JSON.parse(localStorage.getItem("Empdetails"));
  let login = emplogin != null ? emplogin : null;
  let slack = localStorage.getItem("Slack")
  let managerid = JSON.parse(localStorage.getItem("Empdetails"))?.[0]
    ?.ClientUserId;
  // console.log(managerid);
  let rolecodeaccess = arr.RoleCode;
  let createby = managerid;
  function findDiff(str1, str2) {
    let diff = "";
    str2.split("").forEach(function (val, i) {
      if (val != str1.charAt(i)) diff += val;
    });
    return diff;
  }

  const myRef = useRef(null);

  const handleChange = (value) => {
    // setDescription(value);
    dispatch(HANDLE_ON_CHANGE({ name: "description", value: value }));
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      width: 120,
      margin: 0,
      border: "2px solid #DCDCDC",
      fontSize: 13,
      height: 40,
      backgroundColor: "white",
      // outline: 'none'
    }),
  };
  const [checkSubmit, setSubmitCheck] = useState(false);
  const subTaskOption = [
    {
      label: "Task",
      value: "Task",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="skyblue"
          class="bi bi-check-square-fill"
          viewBox="0 0 16 16"
        >
          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
        </svg>
      ),
    },
    {
      label: "Story",
      value: "Story",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#00cc00"
          class="bi bi-bookmark-fill"
          viewBox="0 0 16 16"
        >
          <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
        </svg>
      ),
    },
    {
      label: "Bug",
      value: "Bug",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#cc0000"
          class="bi bi-record-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </svg>
      ),
    },
  ];

  const [imageviewopen, setImageviewopen] = useState(false);
  const [open, setOpen] = useState(false);
  const [imagedata, setImagedata] = useState();
  const [hisdata, sethisdata] = useState();
  function handleClickOpen(e) {
    // console.log(e?.match(/<img .*?>/g), "image check");
    setImageviewopen(true);
    setOpen(true);
    setImagedata(e);
  }
  // function subTaskApi() {

  // }
  useEffect(() => {
    // subTaskApi();
    axios
      .get(
        `agilesaas_issue_details?ParentIssue->0->>IssueId=eq.${SelectedCardId}&order=CreatedDate.desc&IsActive=eq.Y`
      )
      .then((res) => {
        // setDisplaySubTask(res.data);
        dispatch(HANDLE_ON_CHANGE({ name: "displaySubTask", value: res.data }));
      });
    axios
      .get(
        "agilesaas_issue_details?ProjectId=eq." +
        projectId +
        "&CreatedBy=eq." +
        createby +
        "&IsActive=eq.Y"
      )
      .then((res) => {
        setNameApi(res.data);
      })
      .catch((e) => {
        alert(e);
      });
    axios
      .get(
        `agilesaas_issue_history?IssueId=eq.${SelectedCardId}&UpdatedField=eq.Kanban Changes&select=ChangedDate,UpdatedTo&order=ChangedDate.asc`
      )
      .then((res) => {
        // console.log(res.data, "mydata");
        sethisdata(res.data);
      })
      .catch((e) => { });
    // console.log("useEffect");
    // setDescription(desc);
    dispatch(HANDLE_ON_CHANGE({ name: "description", value: desc }));
  }, []);
  // console.log(hisdata, "todat data");
  // console.log(selectedCard1.CreatedDate);
  // // ChangedDate;
  // // UpdatedTo;
  // console.log(filesContent, "filescontent");
  function getSteps() {
    return [
      `Created at (${selectedCard1?.CreatedDate
        ? moment(selectedCard1?.CreatedDate).format("DD MMM")
        : ""
      })`,
      `RFD ${hisdata?.filter((e) => e.UpdatedTo == "RFD")?.[0]
        ? ` ( ${moment(
          hisdata?.filter((e) => e.UpdatedTo == "RFD")?.[0]?.ChangedDate ===
            "undefined"
            ? selectedCard1?.CreatedDate
            : selectedCard1?.CreatedDate
        ).format("DD MMM")})`
        : hisdata?.filter((e) => e.UpdatedTo == "DEV").length > 0 &&
          hisdata?.filter((e) => e.UpdatedTo == "RFD").length == 0
          ? ` ( ${moment(selectedCard1?.CreatedDate).format("DD MMM")})`
          : ""
      }`,
      `DEV ${hisdata?.filter((e) => e.UpdatedTo == "DEV")?.[0]
        ? ` ( ${moment(
          hisdata?.filter((e) => e.UpdatedTo == "DEV")?.[0]?.ChangedDate
        ).format("DD MMM")})`
        : ""
      }`,
      `UAT ${hisdata?.filter((e) => e.UpdatedTo == "UAT")?.[0]
        ? ` ( ${moment(
          hisdata?.filter((e) => e.UpdatedTo == "UAT")?.[0]?.ChangedDate
        ).format("DD MMM")})`
        : ""
      }`,
      `DONE ${hisdata?.filter((e) => e.UpdatedTo == "DONE")?.[0]
        ? ` ( ${moment(
          hisdata?.filter((e) => e.UpdatedTo == "DONE")?.[0]?.ChangedDate
        ).format("DD MMM")} )`
        : ""
      }`,
    ];
  }
  useEffect(() => {
    // Determine the active step based on the presence of data in hisdata
    if (hisdata) {
      // if (selectedCard1?.CreatedDate) {
      //   setActiveStep(0); // Set the first step as active
      // }
      // Determine the active step based on the presence of data in hisdata
      // if (hisdata.some((entry) => entry.UpdatedTo === "RFD")) {
      //   setActiveStep(2);
      // } else if (hisdata.some((entry) => entry.UpdatedTo === "DEV")) {
      //   setActiveStep(3);
      // } else if (hisdata.some((entry) => entry.UpdatedTo === "UAT")) {
      //   setActiveStep(4);
      // } else if (hisdata.some((entry) => entry.UpdatedTo === "DONE")) {
      //   setActiveStep(5);
      // }

      if (hisdata.some((entry) => entry.UpdatedTo === "DONE")) {
        setActiveStep(4); // DONE is found, set step 4
      } else if (hisdata.some((entry) => entry.UpdatedTo === "UAT")) {
        setActiveStep(3); // UAT is found, set step 3
      } else if (hisdata.some((entry) => entry.UpdatedTo === "DEV")) {
        setActiveStep(2); // DEV is found, set step 2
      } else if (hisdata.some((entry) => entry.UpdatedTo === "RFD")) {
        setActiveStep(1); // RFD is found, set step 1
      } else {
        setActiveStep(0);
      }
    }
  }, [hisdata]);
  // console.log(hisdata);
  console.log(
    EmpDropDownData?.filter((x) => x.teammemberid == selectedCard1?.CreatedBy)
  );

  function onSave() {
    let notificationData = {
      CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
      CreatedBy: managerid,
      NotifyTo: assignedEmpId,
      AudienceType: "Individual",
      Priority: "High",
      Subject: "Description is updated",
      Description:
        issueTitle +
        " description is updated by " +
        arr.firstname +
        " " +
        arr.lastname,
      IsSeen: "N",
      Status: "New",
    };
    setSubmitCheck(true);
    axios
      .patch("agilesaas_issue_details?IssueId=eq." + SelectedCardId, {
        Description: description,
      })
      .then((res) => {
        setSubmitCheck(false);
        if (type != "Epic") {
          Mailandnotification(
            selectedCard1,
            selectedCard1?.AssignedTo == emplogin?.[0]?.ClientUserId
              ? emplogin?.[0]?.ClientDetails?.ContactEmail
              : EmpDropDownData?.filter(
                (x) => x.teammemberid == selectedCard1?.AssignedTo
              )?.[0]?.email,
            [emplogin?.[0]?.OfficialEmail],
            true,
            false,
            "Agile",
            "Description is updated",
            `Description is updated by ${emplogin?.[0]?.UserFirstname + " " + emplogin?.[0]?.UserLastname
            }`
          );
          if(slack && slack == "Y"){
            slacknotify(
           channelname,
           `Description is updated by ${emplogin?.[0]?.UserFirstname + " " + emplogin?.[0]?.UserLastname
           }`,
           slacktoken
            )
          }
          // axios
          //   .post("notification?NotifyTo=eq." + managerid, notificationData)
          //   .then((res) => console.log(res))
          //   .catch((error) => console.log(error));
        }
        let diff = findDiff(desc, description);
        axios.post("agilesaas_issue_history", {
          IssueId: issueId,
          ChangedBy: login?.[0]?.ClientUserId,
          ChangedByDetails: {
            FN: login?.[0]?.UserFirstname,
            LN: login?.[0]?.UserLastname,
          },
          ChangedDate: moment()
            .utcOffset("+05:30")
            .format("YYYY-MM-DDTHH:mm:ss"),
          UpdatedField: "Description",
          UpdatedFrom: desc,
          UpdatedTo: description,
          UpdateSequence: 1,
          IsActive: "Y",
          Status: null,
          ClientId: clientid,
          ProjectId: projectCode,
        });
        toast.success(
          `Changes  Applied Successfully (Issue ID: ${SelectedCardId})`,
          {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          }
        );
        // KBRefresh();
        dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
      });
    setEdit(false);
  }

  let totalIssue =
    projectRole == "SM"
      ? KanbanBoardData.filter(
        (x) =>
          x.IssueType != "Epic" &&
          x.IssueType != "SubTask" &&
          x.LinkToEpic?.[0]?.IssueId == Story[0]?.IssueId
      ).length
      : 0;
  let doneIssue =
    projectRole == "SM"
      ? KanbanBoardData.filter(
        (x) =>
          x.IssueType != "Epic" &&
          x.IssueType != "SubTask" &&
          x.LinkToEpic?.[0]?.IssueId == Story[0]?.IssueId &&
          x.CurrentStage[0]?.StageName == "Done"
      ).length
      : 0;
  const regex = /(<([^>]+)>)/gi;
  console.log(projectName, [
    {
      ProjName: Data?.[0]?.ProjectDetails?.[0]?.ProjName,
      Client: Data?.[0]?.ProjectDetails?.[0]?.Client,
    },
  ], selectedCard1, "ppppppppppp")
  function createSubTask() {
    let subTaskData = {
      ProjectId: Data?.[0]?.ProjectId,
      ProjectShortCode: Data?.[0]?.ProjectShortCode,
      IssueType: type == "Epic" ? epicSubTask : "SubTask",
      IssueTitle: subTaskTitle,
      Description: "",
      Priority: "Medium",
      PlannedRelease: null,
      EstimateUnit: "MD",
      EstimateUnitDesc: "Man Days",
      CurrentStage: [
        {
          StageCode: "BLG",
          StageName: "Backlog",
          DateMoved: moment().format("YYYY-MM-DD"),
        },
      ],
      LinkToEpic:
        type == "Epic"
          ? [
            {
              IssueId: Data?.[0]?.IssueId,
              IssueType: epicSubTask,
              Title: Data?.[0]?.IssueTitle,
              CurrentStage: Data?.[0]?.CurrentStage?.[0]?.StageName,
              Priority: Data?.[0]?.Priority,
            },
          ]
          : null,
      LinkToIssues: type == "Epic" ? [] : null,
      ParentIssue:
        type == "Epic"
          ? []
          : [
            {
              IssueId: Data?.[0]?.IssueId,
              Title: Data?.[0]?.IssueTitle,
              CurrentStage: Data?.[0]?.CurrentStage?.[0]?.StageCode,
              Priority: Data?.[0]?.Priority,
            },
          ],
      CreatedBy: managerid,
      CreatedByDetails: login?.[0]?.UserFirstname + " " + login?.[0]?.UserLastname,
      // arr.RoleName == "Undefined" ? "Scrum Master" : arr.RoleName,
      //  { FN: arr.firstname, LN: arr.lastname },
      CreatedDate: moment().format("YYYY-MM-DD"),
      AssignedTo: null,
      AssignedToDetails: { FN: "Un", LN: "Assigned" },
      AssignedDate: moment().format("YYYY-MM-DD"),
      Attachment: null,
      IsActive: "Y",
      ExceptiontoSkipSPEstimation: null,
      ProjectDetails: [
        {
          ProjName: projectName
        },
      ],
      AssignedToUAT: null,
      AssignedToUATDetails: {
        FN: null,
        LN: null,
      },
      IsActive: "Y",
      ClientId: clientid
    };
    if (subTaskTitle != "") {
      axios
        .post("agilesaas_issue_details", subTaskData)
        .then((res) => {
          toast.success(
            type != "Epic"
              ? `SubTask Created Successfully`
              : type == "Epic" && epicSubTask == "Task"
                ? "Task Created Successfully"
                : type == "Epic" && epicSubTask == "Story"
                  ? "Story Created Successfully"
                  : type == "Epic" && epicSubTask == "Bug"
                    ? "Bug Created Successfully"
                    : null,
            {
              transition: Slide,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              draggable: true,
            }
          );
          // const api2 =
          //   arr.RoleCode == "SM"
          //     ? `agilesaas_issue_details?or=(CreatedBy.eq.${managerid},AssignedTo.eq.${managerid},CreatedBy.eq.${managerid})`
          //     : // agilesaas_issue_details?or=(AssignedTo.eq.${empid[0]?.ClientUserId},CreatedBy.eq.${empid[0]?.OnboardedBy},CreatedBy.eq.${empid[0]?.OnboardedBy})
          //     rolecode == "QA"
          //     ? `agilesaas_issue_details?or=(AssignedTo.eq.${managerid},AssignedToUAT.eq.${managerid},CreatedBy.eq.${managerid})`
          //     : `agilesaas_issue_details?AssignedTo=eq.${managerid}`;
          // axios.get(api2).then((response) => {
          //   if (response.data.length != 0) {
          //     // setKanbanBoardData(response.data);
          //     dispatch(KANBANBOARDDATAUPDATE(response.data));
          //   }
          // });
          // KBRefresh();
          dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
          // setRefresh(true);
          dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
          setSubTask(false);
        })
        .catch((e) => {
          alert(e);
        });
    }
  }
  function relatedOnClick(x) {
    if (
      projectRole == "SM" ||
      (nameApi.length != 0 &&
        nameApi.filter((x1) => x1.IssueId == x.IssueId)?.[0]?.AssignedTo ==
        managerid)
    ) {
      // setSelectedCardId(x.IssueId);
      dispatch(HANDLE_ON_CHANGE({ name: "SelectedCardId", value: x.IssueId }));
      // setCheckChange(false);
      dispatch(HANDLE_ON_CHANGE({ name: "checkChange", value: false }));
      // setDescription(
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]?.Description
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "description",
          value: KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]
            ?.Description,
        })
      );
      // setAssignedTo(
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.AssignedToDetails.FN +
      //   " " +
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.AssignedToDetails.LN
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "assignedTo",
          value:
            KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)?.[0]
              ?.AssignedToDetails.FN +
            " " +
            KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]
              ?.AssignedToDetails.LN,
        })
      );
      // setAssignedId(
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]?.AssignedTo
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "assignedId",
          value: KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.AssignedTo,
        })
      );
      // setUatTo(
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.AssignedToUATDetails.FN +
      //   " " +
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.AssignedToUATDetails.LN
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "UatTo",
          value:
            KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)?.[0]
              ?.AssignedToUATDetails.FN +
            " " +
            KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)?.[0]
              ?.AssignedToUATDetails.LN,
        })
      );
      // setUatId(
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.AssignedToUAT
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "UatId",
          value: KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.AssignedToUAT,
        })
      );
      // setPriority(
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]?.Priority
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "priority1",
          value: KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.Priority,
        })
      );
      // setStageName(
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.CurrentStage[0].StageName
      // );?
      dispatch(
        HANDLE_ON_CHANGE({
          name: "stageName",
          value: KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.CurrentStage?.[0].StageName,
        })
      );
      // setStageCode(
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.CurrentStage[0].StageCode
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "stageCode",
          value: KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.CurrentStage?.[0].StageCode,
        })
      );
      // setReleaseValue(
      //   KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.PlannedRelease
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "releaseValue",
          value: KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.PlannedRelease,
        })
      );
      let relea = KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)?.[0]
        ?.PlannedRelease;
      let data = releases.filter(
        (f) => f.ProjectId == x.ProjectId && f.ReleaseId == relea
      );
      // setReleaseLabel(
      //   data.length != 0
      //     ? data.filter((C) => C.ReleaseId == relea)[0]?.ReleaseName
      //     : null
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "releaseLabel",
          value:
            data.length != 0
              ? data.filter((C) => C.ReleaseId == relea)?.[0]?.ReleaseName
              : null,
        })
      );
    } else {
      toast.error(`This subtask is not assigned to you`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    }
  }
  function subTaskOnclick(x) {
    console.log(
      displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]?.AssignedTo ==
      managerid,
      createdId == managerid,
      projectRole,
      "test check"
    );
    if (
      displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]?.AssignedTo ==
      managerid ||
      createdId == managerid || projectRole != "DEV"
    ) {
      // setSelectedCardId(x.IssueId);
      dispatch(HANDLE_ON_CHANGE({ name: "SelectedCardId", value: x.IssueId }));
      // setCheckChange(false);
      dispatch(HANDLE_ON_CHANGE({ name: "checkChange", value: false }));
      // setUatTo(
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.AssignedToUATDetails?.FN +
      //   " " +
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.AssignedToUATDetails?.LN
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "UatTo",
          value:
            displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]
              ?.AssignedToUATDetails?.FN +
            " " +
            displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]
              ?.AssignedToUATDetails?.LN,
        })
      );
      // setUatId(
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]?.AssignedToUAT
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "UatId",
          value: displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.AssignedToUAT,
        })
      );
      // setDescription(
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]?.Description
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "description",
          value: displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.Description,
        })
      );
      // setAssignedTo(
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.AssignedToDetails?.FN +
      //   " " +
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.AssignedToDetails?.LN
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "description",
          value:
            displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]
              ?.AssignedToDetails?.FN +
            " " +
            displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]
              ?.AssignedToDetails?.LN,
        })
      );
      // setAssignedId(
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]?.AssignedTo
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "assignedId",
          value: displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.AssignedTo,
        })
      );
      // setPriority(
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]?.Priority
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "priority1",
          value: displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.Priority,
        })
      );
      // setStageName(
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.CurrentStage[0]?.StageName
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "stageName",
          value: displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.CurrentStage?.[0]?.StageName,
        })
      );
      // setStageCode(
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.CurrentStage[0]?.StageCode
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "stageCode",
          value: displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.CurrentStage?.[0]?.StageCode,
        })
      );
      // setReleaseValue(
      //   displaySubTask.filter((x1) => x1.IssueId == x.IssueId)[0]
      //     ?.PlannedRelease
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "releaseValue",
          value: displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]
            ?.PlannedRelease,
        })
      );
      let relea = displaySubTask.filter((x1) => x1.IssueId == x.IssueId)?.[0]
        ?.PlannedRelease;
      let data = releases.filter(
        (f) => f.ProjectId == x.ProjectId && f.ReleaseId == relea
      );
      // setReleaseLabel(
      //   data.length != 0
      //     ? data.filter((D) => D.ReleaseId == relea)[0]?.ReleaseName
      //     : null
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "releaseLabel",
          value:
            data.length != 0
              ? data.filter((D) => D.ReleaseId == relea)?.[0]?.ReleaseName
              : null,
        })
      );
    } else {
      toast.error(`This subtask is not assigned to you`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    }
  }
  const returnPriority = (x) => {
    return (
      <label className="priorityImage">
        {x.Priority === "Highest" ? (
          <MaterialIcon icon="keyboard_double_arrow_up" color="red" />
        ) : x.Priority === "High" ? (
          <MaterialIcon
            icon="keyboard_arrow_up"
            color={colorPalette.amber._200}
          />
        ) : x.Priority === "Medium" ? (
          <MaterialIcon icon="drag_handle" color="blue" />
        ) : x.Priority === "Low" ? (
          <MaterialIcon icon="keyboard_arrow_down" color="#7bb92f" />
        ) : x.Priority === "Lowest" ? (
          <MaterialIcon icon="keyboard_double_arrow_down" color="#7bb92f" />
        ) : (
          ""
        )}
      </label>
    );
  };
  const returnStage = (x) => {
    // console.log(x?.CurrentStage, "check x")
    return (
      <label
        className={
          x.CurrentStage?.[0]?.StageName == "Backlog"
            ? "stage_style backlogRefined "
            : x.CurrentStage?.[0]?.StageName == "Refined"
              ? "stage_style backlogRefined"
              : x.CurrentStage?.[0]?.StageName == "In Development"
                ? "stage_style inDevelopmentUAT"
                : x.CurrentStage?.[0]?.StageName == "User Acceptance Testing"
                  ? "stage_style inDevelopmentUAT"
                  : x.CurrentStage?.[0]?.StageName == "Done"
                    ? "stage_style donedetailpanel"
                    : ""
        }
      >
        {x.CurrentStage?.[0].StageName}
      </label>
    );
  };
  const returnProjectCode = (x) => {
    return (
      <label className="projectType">
        {x.IssueType === "Story" ? (
          <>
            <Bookmark
              className="titleIcon"
              style={{ backgroundColor: "#00cc00" }}
            />
            &nbsp; W
          </>
        ) : x.IssueType === "Task" ? (
          <>
            <CheckBoxRoundedIcon
              className="titleIcon"
              style={{ fontSize: "10px", backgroundColor: "rgb(25, 138, 227)" }}
            />{" "}
            &nbsp;
            <label className="textColor" style={{ color: "rgb(25, 138, 227)" }}>
              {" " + x.ProjectShortCode + " - " + x.IssueId + " "}
            </label>
          </>
        ) : x.IssueType === "Bug" ? (
          <>
            <AlbumRounded
              className="titleIcon"
              style={{ backgroundColor: "red" }}
            />
            &nbsp;
            <label className="textColor" style={{ color: "rgb(25, 138, 227)" }}>
              {" " + x.ProjectShortCode + " - " + x.IssueId + " "}
            </label>
          </>
        ) : x.IssueType === "SubTask" ? (
          <>
            <CheckBoxRoundedIcon
              className="titleIcon"
              style={{ fontSize: "10px", backgroundColor: "rgb(25, 138, 227)" }}
            />{" "}
            &nbsp;
            <label className="textColor" style={{ color: "rgb(25, 138, 227)" }}>
              {" " +
                x.ProjectShortCode +
                " - " +
                x.ParentIssue?.[0]?.IssueId +
                " "}
            </label>
          </>
        ) : null}
      </label>
    );
  };
  const relatedToIssueAvatar = (x) => {
    let filteredId = KanbanBoardData.filter(
      (x1) => x1.IssueId == x.IssueId
    )?.[0]?.AssignedTo;
    // console.log(x, "releatedissue", filteredId);
    if (projectRole == "SM") {
      return (
        // <Avatar className="Name">
        //   {" "}
        //   {KanbanBoardData.filter((x1) => x1.IssueId == x.IssueId)[0] ==
        //     undefined
        //     ? null
        //     : KanbanBoardData.filter(
        //       (x1) => x1.IssueId == x.IssueId
        //     )[0].AssignedToDetails.FN.charAt(0) +
        //     KanbanBoardData.filter(
        //       (x1) => x1.IssueId == x.IssueId
        //     )[0].AssignedToDetails.LN.charAt(0)}
        // </Avatar>
        // allImage?.filter((x) => x.teammemberid == e.UpdatedBy)?.[0]?.image
        <Avatar
          className="Name"
          alt=""
          src={
            allImage?.length > 0
              ? allImage?.filter((x) => x.teammemberid == filteredId)?.[0]
                ?.image
              : Unassigned
          }
        />
      );
    } else if (projectRole != "SM" && nameApi.length != 0) {
      return (
        // <Avatar className="Name">
        //   {nameApi.filter((x1) => x1.IssueId == x.IssueId)[0] == undefined
        //     ? null
        //     : nameApi
        //       .filter((x1) => x1.IssueId == x.IssueId)[0]
        //       .AssignedToDetails.FN.charAt(0) +
        //     nameApi
        //       .filter((x1) => x1.IssueId == x.IssueId)[0]
        //       .AssignedToDetails.LN.charAt(0)}
        // </Avatar>
        <Avatar
          className="Name"
          alt=""
          src={
            allImage?.length > 0
              ? allImage?.filter((x) => x.teammemberid == filteredId)?.[0]
                ?.image
              : Unassigned
          }
        />
      );
    } else {
      return <Avatar className="Name" alt="" src={Unassigned} />;
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    const maxSizeInBytes =
      settingDetails?.value == undefined
        ? 5 * 1024 * 1024
        : parseInt(settingDetails?.value); // 999KB in bytes
    if (file && file.size > maxSizeInBytes) {
      alert(
        "File size exceeds the limit of 5MB. Please select a smaller file."
      );
    } else {
      // setSelectedFile(file);
      dispatch(HANDLE_ON_CHANGE({ name: "selectedFile", value: file }));
    }
    // const maxSizeInBytes = 5 * 1024 * 1024; // 999KB in bytes
    // if (file && file.size > maxSizeInBytes) {
    //   alert(
    //     "File size exceeds the limit of 5MB. Please select a smaller file."
    //   );
    // } else {
    //   setSelectedFile(file);
    // }
  };
  const resetfile = () => {
    // setSelectedFile(null);
    dispatch(HANDLE_ON_CHANGE({ name: "selectedFile", value: null }));
  };
  const handleDownload = (content, filename) => {
    const blob = new Blob([content]);
    const downloadUrl = URL?.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };

  const handleDownloadAll = async () => {
    for (const file of filesContent) {
      await handleDownload(file.content, file.filename);
    }
  };

  // const handleOpenPopup = (content, filename) => {
  //   const fileUrl = URL.createObjectURL(content);
  //   const fileWindow = window.open(fileUrl, '_blank');
  //   if (!fileWindow) {
  //     alert('Popup blocked! Please allow popups for this site to open the file.');
  //   }
  // };

  // const exampleContent = new Blob(['File content here'], { type: 'text/plain' });
  // const exampleFilename = 'example.txt';
  const classes = useStyles();
  // const [activeStep, setActiveStep] = React.useState(3);
  const steps = getSteps();
  return (
    <div>
      <div className={classes.root}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
          style={{ padding: 0, paddingBottom: 8 }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={QontoStepIcon}
                completed={index <= activeStep}
              // style={{ padding: 0, border: "1px solid red" }}
              >
                {/* {label} */}
                <Typography
                  variant="caption"
                  style={{
                    fontSize: "0.6rem",
                    // border: "1px solid red",
                    margin: 0,
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <label htmlFor="upload-photo">
        {/* <input
          style={{ display: "none" }}
          id="upload-photo"
          type="file"
          name="upload-photo"
          onChange={(e) => {
            if (Array.from(e.target.files).length > 5) {
              alert(`Cannot upload files more than ` + 5);
            }
          }}
          multiple
          accept="image/png, image/jpeg, .doc, .pdf, .xlsx"
        /> */}
        <Button
          disabled={Story?.[0]?.CurrentStage?.[0]?.StageCode === "DONE"}
          variant="contained"
          startIcon={
            selectedFile ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <AttachFileIcon className="attach-icon" />
            )
          }
          className="fontdesign attach-task-btn file-input-label-create"
          style={{ color: "black" }}
        >
          {selectedFile ? "Selected" : "Attach"}

          <input
            type="file"
            accept=".docx, .xlsx, .xls, .pdf, .png, .jpg, .jpeg, .mp3, .wav, .ogg, .mp4, .m4a, .csv"
            className="file-input"
            onChange={handleFileChange}
          />
        </Button>
      </label>

      {Story?.[0]?.IssueType != "Epic" &&
        Story?.[0]?.IssueType != "SubTask" &&
        Story?.[0]?.IssueType != "Bug" &&
        projectRole == "SM" ? (
        <Button
          disabled={Story?.[0]?.CurrentStage?.[0]?.StageCode === "DONE"}
          className="fontdesign attach-task-btn"
          variant="contained"
          style={{ color: "black" }}
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            setSubTask(true);
            // executeScroll()
          }}
        >
          Create subtask
        </Button>
      ) : Story?.[0]?.IssueType == "Epic" && projectRole == "SM" ? (
        <Button
          disabled={Story?.[0]?.CurrentStage?.[0]?.StageCode === "DONE"}
          className="fontdesign attach-task-btn"
          variant="contained"
          style={{ color: "black" }}
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            setSubTask(true);
            // executeScroll()
          }}
        >
          Create Issue In Epic
        </Button>
      ) : null}

      <div className="ml-1">
        {selectedFile ? (
          <label style={{ color: "green" }}>
            <CheckCircleIcon style={{ color: "green", fontSize: 16 }} />
            &nbsp;&nbsp;{selectedFile?.name}
          </label>
        ) : (
          ""
        )}
        {/* {selectedFile ? (
          <button
            style={{
              fontSize: 14,
              // padding: 4,
              // width: 60,
              // height: 20,
              borderRadius: 8,
              marginLeft: 4,
              // marginTop: 12,
              marginRight: 14,
              border: "none",
              backgroundColor: "#fff",
              color: "red",
              // textAlign: "center",
              // textTransform: "none",
            }}
            onClick={resetfile}
          >
            <i class="fa fa-times-circle"></i>
          </button>
        ) : (
          ""
        )} */}
        {/* {console.log(selectedFile, "selectedfile")} */}
        <br />
        {selectedFile?.size > 5 * 1024 * 1024 ? (
          <label>Note : Maximum allowed file size is 5MB</label>
        ) : null}
      </div>

      <div className="description-section">
        <Row>
          <p className="descStyle fontdesign" style={{ color: "black" }}>
            Description
          </p>
          {edit ? null : (
            <Button
              disabled={Story?.[0]?.CurrentStage?.[0]?.StageCode === "DONE"}
              onClick={() => {
                setEdit(true);
              }}
              startIcon={<EditIcon />}
            ></Button>
          )}
        </Row>
        {edit ? (
          <ReactQuill
            theme={"snow"}
            // toolbar={false}
            value={description}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            className={"quillWidth1"}
          />
        ) : (
          <div
            className="quillWidth"
            style={{ color: "black", backgroundColor: "#f7f7f7" }}
            dangerouslySetInnerHTML={{
              __html:
                description == null
                  ? null
                  : description.replace(/<img .*?>/g, ""),
            }}
          ></div>
        )}

        <div>
          {edit ? (
            <div style={{ padding: "5px 0" }}>
              <Button
                className="buttonDesignSave fontdesign"
                variant="contained"
                disabled={checkSubmit}
                onClick={() => {
                  onSave();
                }}
              >
                {checkSubmit ? (
                  <span
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
                  ></span>
                ) : (
                  "Save"
                )}
              </Button>
              <Button
                variant="contained"
                className="buttonDesignCancel fontdesign"
                onClick={() => {
                  setEdit(false);
                  // setDescription(desc);
                  dispatch(
                    HANDLE_ON_CHANGE({ name: "description", value: desc })
                  );
                }}
              >
                Cancel
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      {/* <label htmlFor="upload-photo">
        <input
          style={{ display: "none" }}
          id="upload-photo"
          type="file"
          name="upload-photo"
          onChange={(e) => {
            if (Array.from(e.target.files).length > 5) {
              alert(`Cannot upload files more than ` + 5);
            }
          }}
          multiple
          accept="image/png, image/jpeg, .doc, .pdf, .xlsx"
        />
        <BootstrapBtn variant="contained" startIcon={<AttachFileIcon className="attach-icon" />} className="fontdesign">
          Attach
        </BootstrapBtn>
      </label>

      {Story[0].IssueType != "Epic" &&
        Story[0].IssueType != "SubTask" &&
        rolecodeaccess == "SM" ? (
        <BootstrapBtn
          className="fontdesign"
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            setSubTask(true);
            // executeScroll()
          }}
        >
          Create subtask
        </BootstrapBtn>
      ) : Story[0].IssueType == "Epic" && rolecodeaccess == "SM" ? (
        <BootstrapBtn
          className="fontdesign"
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            setSubTask(true);
            // executeScroll()
          }}
        >
          Create Issue In Epic
        </BootstrapBtn>
      ) : null} */}
      {/* {console.log(
        description?.match(/<img .*?>/g),
        "description?.match(/<img .*?>/g)?.length"
      )} */}
      {description?.match(/<img .*?>/g)?.length == 0 ||
        description?.match(/<img .*?>/g) == null ? (
        ""
      ) : (
        <>
          <div>
            <label
              className="cardTitle fontdesign"
              style={{ color: "black", marginTop: "2%" }}
            >
              Attachments
            </label>
          </div>

          <div
            onClick={() => handleClickOpen(description)}
            className="imageStyle"
            style={{
              display: "flex",
              flexDirection: "row",
              height: 70,
              width: 100,
              gap: 5,
              marginBottom: "2%",
            }}
            dangerouslySetInnerHTML={{
              __html:
                description == null ? null : description.match(/<img .*?>/g),
            }}
          ></div>
        </>
      )}

      <Row>
        {subTask &&
          Story?.[0]?.IssueType != "Epic" &&
          Story?.[0]?.IssueType != "SubTask" ? (
          <div className="subTaskStyle">
            <label className="subTaskTitle">SubTask</label>
            <input
              type="textInput"
              className="subTask SprintFormFields"
              placeholder="What needs to be done?"
              spellCheck={true}
              onChange={(e) => {
                setSubTaskTitle(e.target.value);
              }}
            />
            <div style={{ marginTop: 10 }}>
              <Button
                className="fontdesign attach-task-btn bg-info text-white border-0"
                variant="contained"
                disabled={subTaskTitle == ""}
                onClick={() => {
                  createSubTask();
                }}
              >
                Create
              </Button>
              <Button
                variant="contained"
                className="fontdesign attach-task-btn"
                onClick={() => {
                  setSubTask(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : subTask && Story?.[0]?.IssueType == "Epic" ? (
          <div className="subTaskStyle">
            <div className="epicSubTaskStyle">
              <Select
                placeholder="Select Task"
                // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                value={subTaskOption.filter(
                  (option) => option.label == epicSubTask
                )}
                options={subTaskOption}
                maxMenuHeight={120}
                styles={customStyles}
                onChange={(e) => {
                  setEpicSubTask(e.value);
                }}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {e.icon}
                    <span style={{ marginLeft: 15 }}>{e.value}</span>
                  </div>
                )}
              />
              <input
                type="textInput"
                className="epicSubTask"
                placeholder="What needs to be done?"
                spellCheck={true}
                onChange={(e) => {
                  setSubTaskTitle(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                variant="contained"
                className="fontdesign attach-task-btn"
                disabled={subTaskTitle == ""}
                onClick={() => {
                  createSubTask();
                }}
              >
                Create
              </Button>
              <Button
                className="fontdesign attach-task-btn"
                variant="contained"
                onClick={() => {
                  setSubTask(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : null}
      </Row>

      {Story?.[0]?.IssueType == "Epic" &&
        KanbanBoardData.filter(
          (x) =>
            x.IssueType != "Epic" &&
            x.IssueType != "SubTask" &&
            x.LinkToEpic?.[0].IssueId == Story?.[0].IssueId
        ).length != 0 ? (
        <>
          <div>
            <label className="cardTitle fontdesign" style={{ color: "black" }}>
              Issues in this epic
            </label>
          </div>
          <div className="issueInEpic fontdesign">
            <Slider
              className="sliderWidth"
              value={(doneIssue / totalIssue) * 100}
            />
            {((doneIssue / totalIssue) * 100).toFixed(0)}% Done
          </div>
          <div className="issueCardStyle ">
            {KanbanBoardData.filter(
              (x) =>
                x.IssueType != "Epic" &&
                x.IssueType != "SubTask" &&
                x.LinkToEpic?.[0]?.IssueId == Story?.[0]?.IssueId
            )?.map((x) => (
              <div
                className="epic_list_style"
                onClick={() => {
                  relatedOnClick(x);
                }}
              >
                {returnPriority(x)}
                {returnProjectCode(x)}
                <label className="card_title_style fontdesign">
                  {" " + x.IssueTitle + " "}
                </label>

                {/* <Avatar
                  className={
                    x?.AssignedToDetails?.FN == "Un" &&
                      x?.AssignedToDetails?.LN == "Assigned"
                      ? "UnAssigned"
                      : "Name"
                  }
                >
                  {x?.AssignedToDetails?.FN?.charAt(0) +
                    x?.AssignedToDetails?.LN?.charAt(0)}
                </Avatar> */}
                <Avatar
                  className="Name"
                  alt=""
                  src={
                    allImage?.length > 0
                      ? allImage?.filter(
                        (e) => e.teammemberid == x.AssignedTo
                      )?.[0]?.image
                      : Unassigned
                  }
                />
                {returnStage(x)}
              </div>
            ))}
          </div>
        </>
      ) : null}

      {Story?.[0]?.IssueType != "Epic" &&
        Story?.[0]?.IssueType != "SubTask" &&
        Story?.[0]?.LinkToIssues != null &&
        Story?.[0]?.LinkToIssues.length != 0 ? (
        <>
          <div>
            <label className="cardTitle fontdesign" style={{ color: "black" }}>
              Related To This Issue
            </label>
          </div>
          <div className="issueCardStyle">
            {Story?.[0]?.LinkToIssues?.map((x) => (
              <div
                className="epic_list_style"
                onClick={() => {
                  relatedOnClick(x);
                }}
              >
                <label className="projectType fontdesign">
                  {x.IssueType === "Story" ? (
                    <>
                      <Bookmark
                        className="titleIcon"
                        style={{ backgroundColor: "#00cc00" }}
                      />
                      &nbsp;
                      <label
                        className={
                          x.CurrentStage?.[0]?.StageName == "Done fontdesign"
                            ? "issueTypeDone textColor fontdesign"
                            : "textColor fontdesign"
                        }
                      >
                        {" " + shortCode + " - " + x.IssueId + " "}
                      </label>
                    </>
                  ) : x.IssueType === "Task" ? (
                    <>
                      <CheckBoxRoundedIcon
                        style={{ fontSize: "10px", backgroundColor: "skyblue" }}
                      />{" "}
                      &nbsp;
                      <label className="textColor fontdesign">
                        {" " + shortCode + " - " + x.IssueId + " "}
                      </label>
                    </>
                  ) : x.IssueType === "Bug" ? (
                    <>
                      <AlbumRounded
                        className="titleIcon"
                        style={{ backgroundColor: "red" }}
                      />
                      &nbsp;
                      <label className="textColor fontdesign">
                        {" " + shortCode + " - " + x.IssueId + " "}
                      </label>
                    </>
                  ) : null}
                </label>
                <label className="card_title_style fontdesign">
                  {" " + x.Title + " "}
                </label>
                {returnPriority(x)}

                {relatedToIssueAvatar(x)}
                <label
                  className={
                    x.CurrentStage == "Backlog fontdesign"
                      ? "stage_style backlogRefined fontdesign"
                      : x.CurrentStage == "RFD"
                        ? "stage_style backlogRefined fontdesign"
                        : x.CurrentStage == "DEV"
                          ? "stage_style inDevelopmentUAT fontdesign"
                          : x.CurrentStage == "UAT"
                            ? "stage_style inDevelopmentUAT fontdesign"
                            : "stage_style done fontdesign"
                  }
                >
                  {x.CurrentStage == "BLG"
                    ? "Backlog"
                    : x.CurrentStage == "RFD"
                      ? "Refined"
                      : x.CurrentStage == "DEV"
                        ? "In Development"
                        : x.CurrentStage == "UAT"
                          ? "User Acceptance Testing"
                          : "Done"}
                </label>
              </div>
            ))}
          </div>
        </>
      ) : null}

      {Story?.[0]?.IssueType != "Epic" &&
        Story?.[0]?.IssueType != "SubTask" &&
        displaySubTask.length != 0 ? (
        <>
          <div>
            <label className="cardTitle fontdesign" style={{ color: "black" }}>
              SubTasks
            </label>
          </div>
          <div className="issueCardStyle">
            {displaySubTask?.map((x) => (
              <div
                className="epic_list_style"
                onClick={() => {
                  subTaskOnclick(x);
                }}
              >
                <label className="projectType fontdesign">
                  {" "}
                  <>
                    <AddToPhotosIcon
                      className="titleIcon"
                      style={{ backgroundColor: "skyblue" }}
                    />{" "}
                    &nbsp;
                    <label className="textColor">
                      {" " + x.ProjectShortCode + " - " + x.IssueId + " "}
                    </label>
                  </>
                </label>
                <label className="card_title_style fontdesign">
                  {" " + x.IssueTitle + " "}
                </label>
                {returnPriority(x)}
                <Avatar
                  className="Name"
                  alt=""
                  src={
                    allImage?.length > 0
                      ? allImage?.filter(
                        (e) => e.teammemberid == x.AssignedTo
                      )?.[0]?.image
                      : Unassigned
                  }
                />
                {returnStage(x)}
                {

                  (projectRole == "SM" || x?.CreatedBy == emplogin?.[0]?.ClientUserId) &&
                  <>
                    <Tooltip
                      arrow
                      placement="top"
                      title={(x?.CurrentStage?.[0]?.StageCode !== "DONE") ? "Unlink a subtask" : "Substask in done, not able to delete it"}
                    >
                      <IconButton
                        // className="shadow-sm"
                        style={{ borderRadius: 5, padding: 3, marginRight: 10 }}
                        className="d-flex justify-content-center align-items-center"
                        // disabled={KanbanBoardData?.filter((n) => n.LinkToEpic?.[0]?.IssueId == e?.IssueId)?.length > 0 || KanbanBoardData?.filter((n) => n.ParentIssue?.[0]?.IssueId == e?.IssueId)?.length > 0}
                        onClick={(m) => {
                          m.stopPropagation();
                          if ((x?.CurrentStage?.[0]?.StageCode !== "DONE")) {
                            dispatch(HANDLE_ON_CHANGE({ name: "deletedialog", value: true }));
                            dispatch(HANDLE_ON_CHANGE({ name: "deletedata", value: x }));
                            dispatch(HANDLE_ON_CHANGE({ name: "deletetype", value: "unlink" }));
                          }
                        }}
                      >
                        <LinkOffIcon style={{ fontSize: 20, marginBottom: 1, color: '#0F85F2' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      arrow
                      placement="top"
                      title={(x?.CurrentStage?.[0]?.StageCode == "RFD" || x?.CurrentStage?.[0]?.StageCode == "BLG") ?
                        "Delete" : "Subtask is in " + x?.CurrentStage?.[0]?.StageName + ', please move it to refined to delete.'}
                    >
                      <IconButton
                        // className="shadow-sm"
                        style={{ borderRadius: 5, padding: 3 }}
                        className="d-flex justify-content-center align-items-center"
                        // disabled={KanbanBoardData?.filter((n) => n.LinkToEpic?.[0]?.IssueId == e?.IssueId)?.length > 0 || KanbanBoardData?.filter((n) => n.ParentIssue?.[0]?.IssueId == e?.IssueId)?.length > 0}
                        onClick={(m) => {
                          m.stopPropagation();
                          if ((x?.CurrentStage?.[0]?.StageCode == "RFD" || x?.CurrentStage?.[0]?.StageCode == "BLG")) {
                            dispatch(HANDLE_ON_CHANGE({ name: "deletedialog", value: true }));
                            dispatch(HANDLE_ON_CHANGE({ name: "deletedata", value: x }));
                            dispatch(HANDLE_ON_CHANGE({ name: "deletetype", value: "delete" }));

                          }
                        }}
                      >
                        <DeleteIcon style={{ fontSize: '20px', marginBottom: 1, color: '#F22C3D' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              </div>
            ))}
          </div>
        </>
      ) : null}
      {filesContent?.length > 0 && attachmentReference?.length > 0 ? (
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <label
              style={{
                fontWeight: "bold",
                color: "black",
                marginRight: "auto",
              }}
            >
              Uploaded files ( {filesContent?.length} )
            </label>
            {filesContent?.length > 1 ? (
              <button
                onClick={handleDownloadAll}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgb(82, 80, 229)",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "4px 8px",
                }}
              >
                <i
                  className="fa fa-download"
                  style={{ marginRight: "8px" }}
                ></i>
                Download All
              </button>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: filesContent?.length > 7 ? "scroll" : "hidden",
            }}
          >
            {filesContent?.map((file, index) => (
              <div key={index}>
                <FilePreview
                  content={file.content}
                  filename={file.filename}
                  fileType={
                    file?.filename?.split(".")?.[
                    file?.filename?.split(".")?.length - 1
                    ]
                  }
                />
                <span
                  onClick={() => handleDownload(file.content, file.filename)}
                  style={{ cursor: "pointer", marginLeft: 10 }}
                >
                  {file?.filename?.split("_")?.[2]?.length > 11
                    ? file?.filename?.split("_")?.[2]?.substring(0, 11) + "..."
                    : file?.filename?.split("_")?.[2]}
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {imageviewopen ? (
        <Imageviewer open={open} imagedata={imagedata} setOpen={setOpen} />
      ) : null}
    </div>
  );
}

export default DetailsPanel;
