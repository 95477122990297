import {
  Avatar,
  Box,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { currentSprintData, fetchStoryCount } from "./ManagerNotesslice";
import DevepolerSummary from "./DevepolerSummary";
import { Col, Row } from "react-bootstrap";
import axios from "../../axios";
import FilterListIcon from '@mui/icons-material/FilterList';
import Diversity3Icon from "@mui/icons-material/Diversity3";
import Unassigned from "../../assets/images/avatar.png";
import { toast, Slide } from "react-toastify";

toast.configure();

const ManagerNoteProgress = ({ setEnvCode, sprintdetails, reledata, Setreledata, assignedmem, setassignedmem }) => {
  const dispatch = useDispatch();
  const {
    ReleaseId,
    fetchStoryCountalldata,
    fetchStoryCountloading,
    fetchStoryCountisSuccess,
    currentsprintdata,
  } = useSelector((state) => state.note);

  const { projectCode, allImage } = useSelector((state) => state.user);
  const [publicholiday, setPublicholiday] = useState([]);
  const [leaveData, setLeaveData] = useState([]);

  const clientid = localStorage.getItem("clientid");
  useEffect(() => {
    let userdata = { ReleaseId, projectCode };
    dispatch(fetchStoryCount(userdata));
    dispatch(currentSprintData(projectCode));
  }, []);
  useEffect(() => {
    if (currentsprintdata?.length > 0) {
      axios.get("agilesaas_releases?ReleaseName=eq." + currentsprintdata?.[0]?.plannedrelease)
        .then((res) => {
          console.log(res.data);
          Setreledata(res.data);
        })
        .catch((error) => console.log(error));
    }
  }, [currentsprintdata]);
  // console.log(currentsprintdata);
  // console.log(reledata);

  let fullstoryData =
    !fetchStoryCountloading && fetchStoryCountisSuccess
      ? fetchStoryCountalldata?.[0]
      : null;

  let actualpercentage =
    !fetchStoryCountloading && fetchStoryCountisSuccess
      ? (fullstoryData?.done / fullstoryData?.all) * 100
      : 0;
  let UATDEVCOUNT = parseInt(
    parseInt(fullstoryData?.dev) + parseInt(fullstoryData?.uat)
  ).toFixed(0);
  const differences1 = (specialcover, data, startDate, endDate) => {
    const sdate = new Date(startDate);
    const edate = new Date(endDate);
    const finaldata = data?.map((leave) => {
      const removeDates =
        publicholiday.length == 0
          ? []
          : publicholiday?.map((e) =>
            moment(e.HolidayDate).format("DD-MM-YYYY")
          );
      const leaveStartDate = new Date(leave.LeaveStartDate);
      const leaveEndDate = new Date(leave.LeaveEndDate);
      const isSaturday =
        specialcover?.filter((s) => s.Day == "Saturday")?.length > 0; // Set to true to include Saturday
      const isSunday =
        specialcover?.filter((s) => s.Day == "Sunday")?.length > 0; // Set to true to include Sunday
      const isWeekend = (date) => {
        const day = date.getDay();
        return (!isSaturday && day === 6) || (!isSunday && day === 0);
      };

      const dateToString = (date) => {
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, "0");
        const day = `${date.getDate()}`.padStart(2, "0");
        return `${day}-${month}-${year}`;
      };
      if (leaveStartDate <= edate && leaveEndDate >= sdate) {
        let totalDays = 0;
        for (
          let date = new Date(leaveStartDate);
          date <= leaveEndDate;
          date.setDate(date.getDate() + 1)
        ) {
          const dateString = dateToString(date);
          if (
            !removeDates.includes(dateString) &&
            !isWeekend(date) &&
            date >= sdate &&
            date <= edate
          ) {
            totalDays++;
          }
        }
        return totalDays;
      }
      return 0;
    });
    return finaldata?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  };
  // console.log(reledata);
  const calculateTotalEstimates = (
    dataArray,
    totalPoints,
    teamleavesconsidered
  ) => {
    // console.log(dataArray, "dataArray");
    const estimateByAssignedTo = dataArray?.reduce(
      (accumulator, currentValue) => {
        const { assignedto, originalestimate } = currentValue;

        accumulator[assignedto] =
          (accumulator[assignedto] || 0) + originalestimate;
        return accumulator;
      },
      {}
    );

    return Object.entries(estimateByAssignedTo).map(([assignedTo, total]) => ({
      AssignedTo: parseInt(assignedTo),
      Estimate:
        totalPoints -
        (total +
          // differences1(
          //   specialcover,
          //   leaveData.filter((x) => x.UserId == parseInt(assignedTo)),
          //   startDate,
          //   endDate
          // )
          (teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned == undefined ? 0 :
            teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned)
        ),
      Userpoints:
        total +
        // differences1(
        //   specialcover,
        //   leaveData.filter((x) => x.UserId == parseInt(assignedTo)),
        //   startDate,
        //   endDate
        // )
        (teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned == undefined ? 0 :
          teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned)
      ,
      leave:
        // differences1(
        //   specialcover,
        //   leaveData.filter((x) => x.UserId == parseInt(assignedTo)),
        //   startDate,
        //   endDate
        // )
        (teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned == undefined ? 0 :
          teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned),
    }));
  };

  const scover = reledata?.[0]?.SpecialCover;
  const totalPoints = reledata?.[0]?.TotalDaysInSprint;

  const releaseData = {
    TotalDaysInSprint: totalPoints,
    data: calculateTotalEstimates(
      currentsprintdata,
      totalPoints,
      currentsprintdata?.[0]?.teamleavesconsidered
    ),
    startDate: currentsprintdata?.[0]?.sprintstartdate,
    endDate: currentsprintdata?.[0]?.sprintenddate,
    SpecialCover: scover,
    teamleavesconsidered: currentsprintdata?.[0]?.teamleavesconsidered
  };
  function clearsummary() {
    setassignedmem("")
  }
  return (
    <div>
      <div>
        {/* <div className="text-success">
                    <b>Version - {ReleaseId ?? "currently no sprint running"}</b>
                </div> */}

        {/* <div className="sprint-title"> */}
        <Row className="ml-0">
          <Col lg={3} md={3}>
            <div>
              <h4 style={{ color: "#0046FF" }}>Project </h4>
              <span
                style={{
                  fontWeight: "bold",
                  whiteSpace: "pre-wrap",
                  color: "#435585",
                  fontSize: "23px",
                }}
                sx={{ width: 15, height: 15 }}
              >
                {currentsprintdata?.[0]?.projectdetails[0]?.ProjName}
              </span>
            </div>
          </Col>

          <Col lg={3} md={3}>
            <h4 style={{ color: "#0046FF" }}>Version </h4>
            <span
              style={{
                fontWeight: "bold",
                whiteSpace: "nowrap",
                fontSize: "23px",
              }}
              sx={{ width: 15, height: 15 }}
            >
              {currentsprintdata?.[0]?.releasename}
            </span>
          </Col>
          <Col lg={6} md={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10, alignItems: 'center' }}>
            <Avatar
              // style={{
              //   position: "relative",
              //   width: "50px",
              //   height: "50px",
              //   // left: "100px", // Adjust this value based on the width of the Col
              //   top: "5px", // Adjust this value based on the positioning within the Col
              //   zIndex: "1",
              // }}
              alt="image"
              src={
                allImage?.length > 0 ? allImage?.filter(
                  (x) => x.teammemberid == currentsprintdata?.[0]?.createdby
                )?.[0]?.image : Unassigned
              }
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <span
                  size="small"
                  style={{
                    // background: "white",
                    color: "black",
                    fontWeight: "bold",
                    // padding: "5px", // Adjusted padding
                    // height: "max-content", // Changed from "max-content"
                    // position: "absolute",
                    // top: 0, // Adjusted top position
                    // left: 0, // Adjusted left position
                    // width: "130px",
                    // display: "flex", // Added display: flex
                    // flexDirection: "column",
                    whiteSpace: 'nowrap'
                  }}
                // style={{ fontWeight: 'bold' }}
                >
                  {currentsprintdata?.[0]?.createdbydetails.FN +
                    " " +
                    currentsprintdata?.[0]?.createdbydetails.LN}
                  {/* {console.log(currentsprintdata?.[0]?.createdbydetails?.Role)} */}

                </span>
              </div>
              <div>
                <span style={{ fontWeight: "normal" }}>
                  {currentsprintdata?.[0]?.createdbydetails?.Role}
                </span>
              </div>
            </div>
          </Col>
        </Row>
        {/* </div> */}

        {ReleaseId && !sprintdetails ? (
          <div>
            <div className="sprint-cards">
              <Grid container spacing={5}>
                <Grid item lg={3} md={3} xs={6}>
                  <div
                    className="sprint-card-info-1"
                    style={{ cursor: "pointer" }}
                  >
                    <h2 className="sprint-card-info-title">
                      {fullstoryData?.all ?? 0}
                    </h2>
                    <p className="sprint-card-info-description">TOTAL ISSUES</p>
                  </div>
                </Grid>
                <Grid item lg={3} md={3} xs={6}>
                  <div
                    className="sprint-card-info-2"
                    onClick={() => {
                      if (fullstoryData?.done == 0) {
                        toast.error(`No issues were found in the Done stage`, {
                          transition: Slide,
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 3000,
                          draggable: true,
                        });
                      }
                      else { setEnvCode("DONE") }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {console.log(fullstoryData?.done, "ffffffffffffffff")}
                    <h2 className="sprint-card-info-title">
                      {fullstoryData?.done ?? 0}
                    </h2>
                    <p className="sprint-card-info-description">COMPLETED</p>
                  </div>
                </Grid>
                <Grid item lg={3} md={3} xs={6}>
                  <div
                    className="sprint-card-info-3"
                    onClick={() => {
                      if (UATDEVCOUNT == "NaN") {
                        toast.error(`No issues were found in In Progress stage`, {
                          transition: Slide,
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 3000,
                          draggable: true,
                        });
                      }
                      else { setEnvCode("DEV,UAT") }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <h2 className="sprint-card-info-title">
                      {UATDEVCOUNT === "NaN" ? 0 : UATDEVCOUNT}
                    </h2>
                    <p className="sprint-card-info-description">IN PROGRESS</p>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  xs={6}
                  onClick={() => {
                    if (fullstoryData?.rfd == 0) {
                      toast.error(`No issues were found in Refined stage`, {
                        transition: Slide,
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                        draggable: true,
                      });
                    }
                    else { setEnvCode("RFD") }
                  }}
                >
                  <div
                    className="sprint-card-info-4"
                    style={{ cursor: "pointer" }}
                  >
                    <h2 className="sprint-card-info-title">
                      {fullstoryData?.rfd ?? 0}
                    </h2>
                    <p className="sprint-card-info-description">REFINED</p>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className="sprint-progress">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Box sx={{ width: "100%", mr: 1 }}>
                  <LinearProgress
                    className="progress-bar"
                    variant="determinate"
                    value={
                      // parseInt(actualpercentage)
                      Math.round(fullstoryData?.issue_progressCount)
                    }
                  />
                </Box>
                <Box>
                  <Typography variant="body2" color="#000">
                    {fullstoryData?.issue_progressCount?.toFixed(0) ?? 10}%
                  </Typography>
                </Box>
              </Box>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="sprint-period">
                  <p>
                    Sprint Duration ({fullstoryData?.sprintweek} weeks) From:{" "}
                    {moment(fullstoryData?.stateDate).format("DD/MM/YYYY")} To:{" "}
                    {moment(fullstoryData?.endDate).format("DD/MM/YYYY")}
                  </p>
                </div>
                {/* <Typography variant="body2" color="#000" align="right" fontSize="12px">Release Due : {duedate < 0 ? -duedate + " " + "Days Over Due" : remainingDays + " " + "Days Left"}</Typography> */}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* <div className="my-1">
        <DevepolerSummary data={currentsprintdata} points={releaseData} />
      </div> */}
      {
        !sprintdetails &&
        <div
          className="widget-bar"
          style={{
            whiteSpace: "nowrap",
            overflowY: "hidden",
            overflowX: "auto",
          }}
        >

          <div className="widget-row mb-3">

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label className="ml-3" style={{ fontWeight: "bold" }}>Choose a user to apply a filter</label>
              <div>
                <button className="text-info" style={{ border: "1px solid #198ae3", fontSize: "11px" }} onClick={clearsummary}><FilterListIcon fontSize="small" /> Clear Filter </button>
              </div>
            </div>
            <DevepolerSummary data={currentsprintdata} points={releaseData} assignedmem={assignedmem} setassignedmem={setassignedmem} />
          </div>
        </div>
      }


    </div>
  );
};

export default ManagerNoteProgress;
