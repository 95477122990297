import moment from "moment";
import React, { useEffect, useState } from "react";
import PersonIcon from '@material-ui/icons/Person';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Avatar from '@mui/material/Avatar';
import Admin from '../../assets/images/Admin.png';
import { Col, Row } from "reactstrap";
import Alen from '../../assets/images/Alen.png';
import Rajan from '../../assets/images/Rajan.png';
import Suren from '../../assets/images/Suren.png';
import Aravindh from '../../assets/images/aravindh.png';
import Kandhasamy from '../../assets/images/Kandhasamy.png';
import Unassigned from '../../assets/images/avatar.png'
import PlanChip from "./PlanChip";
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import axios from "../../axios";
import Axios from "axios";
import { USERIMAGEUPDATE } from "../../redux/CommonInitialsate";

function Balancedays() {
  const [enddate, setEnddate] = useState([]);
  let tenantid = JSON.parse(localStorage.getItem("userdata"));
  let empDetails = JSON.parse(localStorage.getItem("Empdetails"));
  let email = tenantid?.login
  // let UserRole = JSON.parse(localStorage.getItem("userdata"));
  let roleid = sessionStorage.getItem("Roledetails") == "undefined" ? "" : JSON.parse(sessionStorage.getItem("Roledetails"))
  let data = JSON.parse(localStorage.getItem("userdata"));
  let token = data?.token
  // let clientdetails = JSON.parse(localStorage.getItem("clientdetails"));
  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == 11004)?.[0]?.RoleCode
  const getProdRoleName = productRole?.filter((e) => e.ProdId == 11004)?.[0]?.RoleName
  let rolecode = localStorage.getItem("Rolecode") == "undefined" ? "" : JSON.parse(localStorage.getItem("Rolecode"))
  let id = rolecode == "DEV" || getProdRole != "KA" ? JSON.parse(localStorage.getItem("Orgsubscriptiondetails"))?.[0]?.ClientId : JSON.parse(localStorage.getItem("clientid"))
  const { projectRole, projectRoleName, userImage } = useSelector((state) => state.user)
  const fileInputRef = useRef(null);
  const cid = localStorage.getItem("clientid")

  const dispatch = useDispatch()

  console.log(getProdRoleName, projectRoleName, projectRole, "project roles")

  // Function to handle local file selection
  const handleFileChange = (event) => {
    console.log(empDetails, "empDetails")
    const file = event.target.files[0];
    const localAvatarURL = URL.createObjectURL(file);
    dispatch(USERIMAGEUPDATE(localAvatarURL))
    const FormData = require('form-data');
    // const newFileName = `${cid}-${empDetails?.[0]?.ClientUserId}.png`;

    const newFileName = `${cid}-${empDetails?.[0]?.ClientUserId}_${empDetails?.[0]?.UserFirstname}_${empDetails?.[0]?.UserLastname}.png`;
    // Create a new File object with the desired name
    const renamedFile = new File([file], newFileName, { type: file.type })
    let data = new FormData();
    data.append('file', renamedFile);
    data.append('ClientId', cid);
    data.append('ClientUserId', empDetails?.[0]?.ClientUserId);
    axios.post('api/agile/filesystem/EmployeeImage', data)
      // let config = {
      //   method: 'post',
      //   maxBodyLength: Infinity,
      //   url: 'http://localhost:2002/api/agile/filesystem/EmployeeImage',
      //   data: data
      // };

      // axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to trigger the file input dialog when the avatar is clicked
  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  // const [storedData, setStoredData] = useState(getLocalStorageItem('ProjectRoleName5'));

  // useEffect(() => {
  //   // Update the state whenever localStorage changes
  //   const handleStorageChange = (e) => {
  //     if (e.key === 'ProjectRoleName5') {
  //       setStoredData(getLocalStorageItem('ProjectRoleName5'));
  //     }
  //   };

  //   // Listen for changes in localStorage
  //   window.addEventListener('storage', handleStorageChange);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, [localStorage.getItem("ProjectRoleName5")]);
  // console.log(storedData, "storedData")



  useEffect(() => {
    Axios({
      method: "GET",
      url:
        "https://api.devozy.ai/subscription_client_license_details?ClientId=eq." + id + "&IsActive=eq.Y",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.data.filter((e) => e.ProductDetails.ProdId == "11006").length != 0) {
          setEnddate(res.data);
        }
        else { setEnddate(res.data.filter((e) => e.ProductDetails.ProdId == "11004")) }

      })
      .catch((er) => {
        console.error(er, "errorapi")
      });
  }, []);

  let time = enddate?.[0]?.ValidTill

  let date = moment(enddate?.[0]?.ValidTill).format("DD-MMM-YYYY")
  const userrole = projectRole == undefined || projectRoleName === projectRole ? getProdRoleName : projectRoleName
  return (
    <div style={{ width: '100%', justifySelf: 'center', alignSelf: 'center' }}>
      <Row style={{ padding: '5%', justifySelf: 'center', alignSelf: 'center' }} noGutters>
        <Col lg={3} style={{ alignContent: 'center', justifyContent: 'center', marginTop: '4%' }}>
          <div>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
            <Avatar
              src={userImage}
              alt={empDetails?.[0]?.UserFirstname + " " + empDetails?.[0]?.UserLastname}
              onClick={handleAvatarClick}
              sx={{ width: 60, height: 60 }}
            />
          </div>
        </Col>
        <Col lg={9} style={{ alignContent: 'center', justifyContent: 'left', marginTop: '4%', paddingLeft: 5 }}>
          <div style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <label style={{ fontSize: 12, fontWeight: '600' }}>
              {empDetails?.[0]?.UserFirstname + " " + empDetails?.[0]?.UserLastname}
            </label>
          </div>
          <label style={{ fontSize: 12, fontWeight: '500' }}>{email}</label>
        </Col>
      </Row>
      <div style={{ padding: '1%' }}> <PersonIcon style={{ fontSize: 12 }}></PersonIcon>&nbsp;
        <label style={{ fontSize: 12, fontWeight: '600' }}>Logged as &nbsp;:&nbsp;</label><label style={{ fontSize: 12 }}>
          {userrole != undefined ? userrole : empDetails?.[0]?.IsOrgAdmin == "Y" ? "Admin" : "User"}
          {/* {roleid?.AccessRoleID === 2
          ? "Developer"
          : roleid?.AccessRoleID === 3
            ? "Tester"

            : "admin"} */}
        </label></div>
      <div style={{ padding: '1%' }}><EventAvailableIcon style={{ fontSize: 12 }}></EventAvailableIcon>&nbsp;
        <label style={{ fontSize: 12, fontWeight: '600' }}> Subscription &nbsp;:&nbsp;</label><label style={{ fontSize: 12 }}>{enddate != null ? <PlanChip plan={enddate?.[0]?.PlanDetails?.PlanName} /> : null}</label></div>
      <div style={{ padding: '1%', whiteSpace: 'nowrap' }}>
        <ScheduleIcon style={{ fontSize: 12 }}></ScheduleIcon>&nbsp;
        <label style={{ fontSize: 12, fontWeight: '600' }}>End by &nbsp;: &nbsp;</label><label style={{ fontSize: 12 }}>{(moment(date).format("DD MMM YYYY"))} (
          {moment(time, "YYYY-MM-DDTHH:mm:ss").fromNow()})</label>
      </div>
      {/* <div className="mr-2">
        <span style={{ fontSize: "12px" }} >
          {" "}
          <PersonIcon style={{ fontSize: "11px" }}></PersonIcon>
          Logged in as :{" "}
          {roleid?.AccessRoleID == 2
            ? "Developer"
            : roleid?.AccessRoleID === 3
              ? "Tester"

              : "admin"}{" "}
          role
        </span>
      </div>

      <div className="my-2 ">
        <span style={{ fontSize: "12px" }}>
          {" "}
          <EventAvailableIcon style={{ fontSize: "12px" }}></EventAvailableIcon>
          Subscription : {enddate != null ? enddate?.map((e) => e.PlanDetails?.PlanName) : null}
        </span>
      </div>
      <div>
        <span style={{ fontSize: "12px" }}>
          {" "}
          <ScheduleIcon style={{ fontSize: "12px" }}></ScheduleIcon>
          End by : {(date)} (
          {moment(time, "YYYY-MM-DDTHH:mm:ss").fromNow()})
        </span>
      </div> */}

      {/* <div>
        {enddate != null
          ? enddate.PlanDetails.PlanName +
            " subscription end by " +
            moment(enddate.ValidTill).format("DD-MMM-yyyy")
          : null}
      </div>
      <br />
      <div>
        {enddate != null ? (
          <p>
            Due {moment(enddate.ValidTill, "YYYY-MM-DDTHH:mm:ss").fromNow()}
          </p>
        ) : null}
      </div> */}

    </div >
  );
}

export default Balancedays;

// f.ValidTill;


