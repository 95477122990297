import { Card, Checkbox, TextField } from "@material-ui/core";
import axios from "../../axios";
import React, { useEffect, useState, useContext } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import MaterialTable from "material-table";
import PatchedPagination from "../../PatchedPagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Select from "react-select";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import "./Team.css";
import { useRef } from "react";
// import validator from "validator";
import Addnewproject from "./project/Addnewproject";
import { Col, Row, Container, CardBody, Table } from "reactstrap";
import Button from "react-bootstrap/Button";
import { Avatar, Button as Button1, Divider, Tooltip } from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
// import Editproject from "./EditProject";
// import ProjectAllocation from "./ProjectAllocation";
import ReportTeamallocate from "./project/ReportTeamallocate";
import TeamAllocation from "./project/Allocationteam";
import moment from "moment";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { toast, Slide } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import UserContext from "../../index";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import Unassigned from "../../assets/images/avatar.png";

const Addnewteam = ({ inviteuser, setinviteuser }) => {
  let id = JSON.parse(localStorage.getItem("clientid"));
  // let rolecode = JSON.parse(localStorage.getItem("Rolecode"));
  let userdetails = JSON.parse(localStorage.getItem("clientdetails"));
  let empdetails = JSON.parse(localStorage.getItem("Empdetails"));
  let email = JSON.parse(localStorage.getItem("userdata"));
  let createdby = JSON.parse(localStorage.getItem("loginmemeberid"));
  let orgplancap =
    localStorage.getItem("Orgsubscriptiondetails") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Orgsubscriptiondetails"));
  let userlimit =
    orgplancap == null ? "" : orgplancap.map((e) => e.PlanCap.Users);
  const [firstname, setfirstname] = useState("");
  const [secondname, setsecondname] = useState("");
  const [Teamdetails, setTeamdetails] = useState([]);
  const [Teamallocate, setteamallocate] = useState([]);
  const [prjallocation, setprjallocation] = useState(false);
  const [Email, setEmail] = useState([""]);
  const [phone, setphone] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [allocationopen, setallocationopen] = useState(false);
  const [error, seterror] = useState("");
  const [row, setrow] = useState("");
  const [names, setnames] = useState("");
  const [lastnameerror, setlastnameerror] = useState("");
  const [mailerror, setmailerror] = useState("");
  const [phoneerror, setphoneerror] = useState("");
  const [createby, setcreatedby] = useState("");
  const [editopen, setedit] = useState(false);
  const [Teamopen, setTeamopen] = useState(false);
  const [Reportallocate, setReportallocate] = useState(false);
  const [openProCreate, setopenProCreate] = useState(
    inviteuser == true ? true : false
  );
  const [another, setanother] = useState(false);
  const [subteam, setsubteam] = useState([]);
  const [allocationtabel, setallocationtabel] = useState([]);
  const userorg = userdetails[0]?.OrgDetails;
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [errors, setErrors] = useState({});
  const [checkSubmit, setSubmitCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [focused, setFocused] = useState(false);
  const userdet = userdetails[0]?.UserDetails;
  let orgmail = empdetails?.[0]?.OfficialEmail?.split("@")[1];
  const {
    allImage,
    projectdetails,
    projectRole,
    projectRoleName,
    projectDetails,
  } = useSelector((state) => state.user);
  const contextval = useContext(UserContext);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  console.log(id);
  let managerid =
    localStorage.getItem("managerid") == "undefined"
      ? 0
      : JSON.parse(localStorage.getItem("managerid"));
  const multiplesm =
    localStorage.getItem("multiplesm") == "fullaccess_to_all_sms";
  const getuser = multiplesm
    ? `agilesaas_project_mapping?MappedBy=eq.${managerid}&IsCurrent=eq.Y`
    : `agilesaas_project_mapping?MappedBy=eq.${empdetails?.[0].ClientUserId}&IsCurrent=eq.Y`;
  const getproject = (a) =>
    multiplesm
      ? `rpc/fun_agilesaasotherprojectmembers?teammemberid=${empdetails?.[0]?.ClientUserId}`
      : `rpc/fun_agilesaassmprojandteamdetails?managerid=${a}&companyid=eq.${id}`;
  useEffect(() => {
    contextval.setwindows("team");
    axios.get("subscription_client_users?ClientId=eq." + id).then((res) => {
      setsubteam(res.data);
      console.log(res.data);
    });
    axios
      .get(getuser)
      .then((res) => {
        setIsLoading(false);
        setallocationtabel(res.data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
    axios.get(`subscription_client_users?ClientId=eq.` + id).then((res) => {
      let a = res.data?.find(
        (e) => e?.OfficialEmail == email?.login
      )?.ClientUserId;

      axios.get(getproject(a)).then((response) => {
        setteamallocate(response.data);

        // let c =  response.data?.map((e)=>e.teammemberid)
        let c = response.data.map((e) => {
          let fullname = res?.data?.find(
            (a) =>
              a?.teammemberid == e?.TeamMemberId &&
              e?.iscurrent != "N" &&
              e?.allocpercentage != 0 &&
              e?.projectname != null
          );

          return {
            ...e,
            projectpercentage: e?.projectname + " " + e?.allocpercentage,
          };
        });

        // setTeamdetails(c)
        // const check = res.data?.map((f)=>f.TeamMemberId === c  && f.iscurrent != "N")?.map((k)=>k.projectname+" "+k.allocpercentage );
        // let project =
        setcreatedby(a);
        setTeamdetails(
          res.data.map((e) => {
            return { ...e, selectOption: false };
          })
        );
      });
    });
  }, []);
  const check = Teamdetails?.find((e) => e.Email === Email)
    ? "email exist"
    : null;

  // const projecttable = projectdetails?.filter((e) => e);
  const handlename = (e) => {
    let text = e.target.value;

    setfirstname(text);
  };

  function Refresh() {
    axios.get("subscription_client_users?ClientId=eq." + id).then((res) => {
      setsubteam(res.data);
    });
    axios.get(`subscription_client_users?ClientId=eq.` + id).then((res) => {
      setTeamdetails(
        res.data.map((e) => {
          return { ...e, selectOption: false };
        })
      );

      let a = res.data?.find(
        (e) => e?.OfficialEmail == email?.login
      )?.ClientUserId;

      axios
        .get(
          `rpc/fun_agilesaasmanagerteamprojdetails?managerid=` +
            a +
            `&clientid=` +
            id
        )
        .then((response) => {
          setteamallocate(response.data);

          // let c =  response.data?.map((e)=>e.teammemberid)
          let c = response.data.map((e) => {
            let fullname = res?.data?.find(
              (a) =>
                a?.ClientUserId === e?.TeamMemberId &&
                e?.iscurrent != "N" &&
                e?.allocpercentage != 0 &&
                e?.projectname != null
            );

            return {
              ...e,
              projectpercentage: e?.projectname + " " + e?.allocpercentage,
            };
          });

          // setTeamdetails(c)
          // const check = res.data?.map((f)=>f.TeamMemberId === c  && f.iscurrent != "N")?.map((k)=>k.projectname+" "+k.allocpercentage );
          // let project =
          setcreatedby(a);
          setTeamdetails(
            res.data.map((e) => {
              return { ...e, selectOption: false };
            })
          );
        });
    });
    setfirstname("");
    setsecondname("");
    setEmail([""]);
    setphone("");
    setanother(false);

    // handleDateChange(new Date())
    // handleDateChange2(new Date())
  }

  const handleReport = (e) => {
    setReportallocate(true);
    setrow(e);
  };
  const handleEditing = (e) => {
    setedit(true);
    setrow(e);
    setfirstname(e?.Firstname);
    setsecondname(e?.Lastname);
    setEmail(e?.Email);
    setphone(e?.Phone);
  };
  const handleallocation = () => {
    setallocationopen(true);
  };
  function ProjectClose() {
    setopenProCreate(false);
    if (inviteuser !== undefined) {
      setinviteuser(false);
    }
    setSubmitCheck(false);
    setanother(false);
  }
  const validateForm = () => {
    let gmailEmails = Email?.some((email) => email.includes(`@${orgmail}`));
    console.log(Email, "length", Email.length, "ll");
    const newErrors = {};
    // if (firstname.trim() == "") {
    //   newErrors.firstname = "Enter the first name";
    // } else if (secondname.trim() == "") {
    //   newErrors.secondname = "Enter the Last name";
    // } else
    if (Email.length == 0) {
      newErrors.email = "Email is required";
    } else if (Email.length == 11) {
      alert("Maximum 10 numbers can allow");
    } else if (gmailEmails == false) {
      newErrors.email = "Enter your organization Mail-id";
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      newErrors.email = "Email is invalid";
    } else if (!editopen) {
      if (Teamdetails?.find((e) => e.OfficialEmail === Email)) {
        newErrors.email = "Already existing Mail Id";
      }
    }
    //  else if (phone === "") {
    //   newErrors.phone = "Enter the phone number";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  const sendInvitationEmail = async (emailData) => {
    try {
      const emailResponse = await axios.post("/api/agile/email", emailData);
      console.log(emailResponse.data, "Email sent");
    } catch (emailError) {
      console.error("Error sending email:", emailError);
    }
  };
  const createUserAndSendEmail = async () => {
    for (let i = 0; i < Email.length; i++) {
      let postsubscription = {
        orgadmin: true,
        CurrentPlanId: 11006,
        User_Details: {
          ClientId: id,
          ClientDetails: {
            Company: userorg?.Company,
            ContactName: userorg?.ContactName,
            ContactDesignation: userorg?.ContactDesignation,
            ContactEmail: userorg?.ContactEmail,
            ContactPhone: userorg?.ContactPhone,
          },
          ApprovedDate:
            empdetails?.[0]?.IsOrgAdmin == "Y"
              ? moment().format("YYYY-MM-DD HH:mm:ss")
              : null,
          OnboardedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          OnboardedByDetails: {
            OnboardedName:
              empdetails?.[0]?.UserFirstname +
              " " +
              empdetails?.[0]?.UserLastname,
          },
          OnboardedBy: empdetails?.[0]?.ClientUserId,
          ApprovedByDetails:
            empdetails?.[0]?.IsOrgAdmin == "Y"
              ? // {
                //   "AdminId": id,
                //   "AdminEmail": empdetails?.[0]?.OfficialEmail,
                // }
                null
              : null,
          IsApproved: empdetails?.[0]?.IsOrgAdmin == "Y" ? "Y" : null,
          DepartmentDetails: "Common",
          UserFirstname: firstname == "" ? "FN" : firstname,
          UserLastname: secondname == "" ? "LN" : secondname,
          OfficialEmail: Email[i].toLowerCase(),
          IsEmailVerified: "Y",
          Phone: phone == "" ? userorg?.ContactPhone : phone,
          DateCreated: moment().format("YYYY-MM-DD HH:MM:ss"),
          IsOrgAdmin: "N",
          IsActive: null,
        },
        User_Role: [
          {
            ClientId: id,
            UserDetails: {
              Firstname: firstname == "" ? "FN" : firstname,
              Lastname: secondname == "" ? "LN" : secondname,
              Email: Email[i],
            },
            RoleDetails: {
              RoleCode: "KU",
              RoleName: "Kanban User",
            },
            ProductDetails: {
              ProdId: "11004",
              ProdName: "KANBAN",
              Domain: "kanban.yozytech.com",
            },
            LoginEnabled: "Y",
            OrgDetails: {
              Company: userorg?.Company,
              ContactName: userorg?.ContactName,
              ContactDesignation: userorg?.ContactDesignation,
              ContactEmail: userorg?.ContactEmail,
              ContactPhone: userorg?.ContactPhone,
            },
          },
        ],
      };

      try {
        const subscriptionResponse = await axios.post(
          "/api/v1/subscription/usercreation",
          postsubscription
        );

        if (subscriptionResponse.status === 201) {
          let emaildata = {
            ClientUserId: empdetails?.[0]?.ClientUserId,
            ClientId: id,
            ClientDetails: {
              Company: userorg?.Company,
              ContactName: userorg?.ContactName,
              ContactDesignation: userorg?.ContactDesignation,
              ContactEmail: userorg?.ContactEmail,
              ContactPhone: userorg?.ContactPhone,
            },
            DepartmentDetails: empdetails?.[0]?.DepartmentDetails,
            DepartmentCode: "",
            TentantId: "",
            FromSenderId: "no-reply@yozytech.com",
            ToEmailIds: {
              emailids: Email[i].toLowerCase(),
            },
            CcEmailIds: {
              emailids: ["agileteam@yozytech.com"],
            },
            BccEmailIds: "",
            EmailType: "UserInvitation",
            ProductDetails: {
              ProdId: "11000",
              ProdName: "Subscription",
              Domain: "Subscription.yozytech.com",
            },
            EmailSubject: "User invite notification",
            IsSuccess: "",
            IsPartiallyFailed: " ",
            SendRetryHistory: "",
            IsActive: " ",
            Status: "",
            emailnotify: true,
            webnotify: false,
            EmailTemplateName: "UserInvitation",
            EmailTemplateVariables: {
              xxasignbyxx: "",
              xxassigndatexx: "",
              xxprjdisxx: Email[i],
              xxprjnamexx:
                firstname == "" && secondname == ""
                  ? "User"
                  : firstname + " " + secondname,
              xxstoryNamexx: "",
              xxprjpercenxx: "",
              xxprjstartdatexx: firstname == "" ? "0" : firstname,
              xxprjenddataexx: secondname == "" ? "0" : secondname,
              xxteamnamexx:
                empdetails?.[0]?.UserFirstname +
                " " +
                empdetails?.[0]?.UserLastname,
              xxsprintxx: "",
              xxmanagernamexx: userorg?.ContactName,
              xxcompanyname: userorg?.Company,
              xxmanagerdesigxx: "Scrum Master",
              xxgmailxx: userorg?.ContactEmail,
              xxphnoxx: userorg?.ContactPhone,
            },
          };
          if (another == true) {
            toast.success(
              empdetails?.[0]?.IsOrgAdmin == "Y"
                ? `User Created SuccessFully`
                : `User Created SuccessFully Wait For the Approval from Admin`,
              {
                position: toast.TOP_RIGHT,
                autoClose: 3000,
                draggable: true,
              }
            );
            // setopenProCreate(false);
            Refresh();
            // refreshstages()
            setopenProCreate(true);
          } else {
            toast.success(
              empdetails?.[0]?.IsOrgAdmin == "Y"
                ? `User Created SuccessFully`
                : `User Created SuccessFully Wait For the Approval from Admin`,
              {
                position: toast.TOP_RIGHT,
                autoClose: 3000,
                draggable: true,
              }
            );
            ProjectClose();
            // setopenProCreate(true);
            Refresh();
          }

          await sendInvitationEmail(emaildata);
        }

        Refresh();
        // ProjectClose()
      } catch (subscriptionError) {
        console.error("Error creating user:", subscriptionError);
      }
    }

    // Additional code after the loop if needed
    setSubmitCheck(false);
  };

  function submit(e) {
    e.preventDefault();

    if (userlimit <= Teamdetails.length) {
      toast.error(
        `Team creation limit reached. Please upgrade to higher plan for Proceed Furthur...`,
        {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 4000,
          draggable: true,
        }
      );
      Refresh();
      handleClose();
      ProjectClose();
    } else if (Email.length != 1 && firstname != "" && secondname != "") {
      alert("For Bulk User creation remove Firstname and Lastname");
    } else {
      if (validateForm()) {
        // setSubmitCheck(true);
        createUserAndSendEmail();

        // axios
        //   .post(`/api/v1/subscription/usercreation`, postsubscription)
        //   .then((res) => {
        //     setSubmitCheck(false)
        //     // axios
        //     //   .get("subscription_client_users?ClientId=eq." + id)
        //     //   .then((res) => {
        //     //     let iduser = res.data
        //     //       ?.map((e) => e.ClientUserId)
        //     //       .sort()
        //     //       .slice(-1)
        //     //       .toString();
        //     //     let postrolemap = {
        //     //       UserId: iduser,
        //     //       UserDetails: {
        //     //         Firstname: firstname,
        //     //         Lastname: secondname,
        //     //         Email: Email,
        //     //       },
        //     //       RoleDetails: {
        //     //         RoleCode: "KU",
        //     //         RoleName: "Kanban User",
        //     //       },
        //     //       ElevatedPrevilege: null,
        //     //       ProductDetails: {
        //     //         ProdId: "11004",
        //     //         ProdName: "KANBAN",
        //     //         Domain: "kanban.yozytech.com",
        //     //       },
        //     //       ClientId: id,
        //     //       OrgDetails: {
        //     //         Company: userorg?.Company,
        //     //         ContactName: userorg?.ContactName,
        //     //         ContactDesignation: userorg?.ContactDesignation,
        //     //         ContactEmail: userorg?.ContactEmail,
        //     //         ContactPhone: userorg?.ContactPhone,
        //     //       },
        //     //       LoginEnabled: "N",
        //     //     };
        //     //     axios
        //     //       .post(`subscription_user_role_mapping`, postrolemap)
        //     //       .then((res) => {
        //     //         Refresh();
        //     //         // handleClose()
        //     //         ProjectClose()
        //     //         // setTeamopen(true);
        //     //       });
        //     //   });
        //     if (another) {
        //       toast.success(
        //         empdetails?.[0]?.IsOrgAdmin == "Y" ?
        //           `User Created SuccessFully`
        //           : `User Created SuccessFully Wait For the Approval from Admin`,
        //         {
        //           position: toast.TOP_RIGHT,
        //           autoClose: 3000,
        //           draggable: true,
        //         }
        //       );
        //       setopenProCreate(false);
        //       Refresh();
        //       // refreshstages()
        //       setopenProCreate(true);
        //     } else {
        //       toast.success(
        //         empdetails?.[0]?.IsOrgAdmin == "Y" ?
        //           `User Created SuccessFully`
        //           : `User Created SuccessFully Wait For the Approval from Admin`,
        //         {
        //           position: toast.TOP_RIGHT,
        //           autoClose: 3000,
        //           draggable: true,
        //         }
        //       );
        //       ProjectClose();
        //       Refresh();
        //     }

        //     if (empdetails?.[0]?.IsOrgAdmin == "Y" && res.status == 201) {
        //       axios.post('/api/agile/email', emaildata)
        //         .then((e) => { console.log(e) })
        //     }
        //     Refresh();
        //     // ProjectClose()
        //   })
        //   .catch((e) => {
        //     console.log(e)
        //     setSubmitCheck(false)
        //   })
        Refresh();
      }
    }
  }
  function updateEdit(e) {
    e.preventDefault();
    let temp = {
      Firstname: firstname,
      Lastname: secondname,
      Email: Email,
      Phone: phone,
    };

    if (validateForm()) {
      // axios
      //   .patch(
      //     "agilesaas_team_master?TeamMemberId=eq." + row.TeamMemberId,
      //     temp
      //   )
      //   .then((res) => {
      //     toast.success(`Team Edited`, {
      //       transition: Slide,
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 3000,
      //       draggable: true,
      //     });
      //     Refresh();
      //   });
    }
  }

  // function remove(rowData) {
  //   alert("are you sure want to delete");
  //   axios
  //     .delete("agilesaas_project_master?ProjectId=eq." + rowData.ProjectId)
  //     .then((res) => {
  //       if (res.status === 204) {
  //         toast.success(`Deleted successfully`, {
  //           position: toast.TOP_RIGHT,
  //           autoClose: 3000,
  //           draggable: true,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  const uniqueTeamMembers = [];

  allocationtabel?.forEach((e) => {
    const existingMember = uniqueTeamMembers.find(
      (member) => member.TeamMemberId === e.TeamMemberId
    );

    if (!existingMember) {
      // If the team member is not in the uniqueTeamMembers array, add them
      let userdata = subteam?.filter(
        (r) => r.ClientUserId === e.TeamMemberId
      )?.[0];
      uniqueTeamMembers.push({
        TeamMemberId: e.TeamMemberId,

        // Name:
        Name: (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* {console.log(userdata, "Status")} */}
            {/* <Avatar
              style={{ width: 30, height: 30, marginRight: "10px" }}
              alt=""
              src={
                allImage?.filter(
                  (x) => x.teammemberid == userdata?.ClientUserId
                )?.[0]?.image
              }
            /> */}
            {userdata?.ClientUserId}
            {"-"}
            {/* {userdata.TeamMemberId} */}
            &nbsp;{userdata?.UserFirstname + " " + userdata?.UserLastname}
          </div>
        ),
        // subteam
        //   ?.filter((r) => r.ClientUserId === e.TeamMemberId)
        //   ?.map((f) => f?.UserFirstname + " " + f?.UserLastname),

        project: Teamallocate?.filter(
          (r) =>
            r.teammemberid === e.TeamMemberId &&
            r?.iscurrent !== "N" &&
            r?.allocpercentage !== 0 &&
            r?.projectname !== null
        )?.map((f) => (
          <div>
            {/* {console.log(f?.projectrole?.RoleName, "project role")} */}
            {f?.projectrole?.RoleName}
            {" - "}
            {`${f?.projectname}    (${f?.allocpercentage}%)`}
          </div>
        )),
        Email: subteam
          ?.filter((r) => r.ClientUserId === e.TeamMemberId)
          ?.map((f) => f?.OfficialEmail),
        Phone: subteam
          ?.filter((r) => r.ClientUserId === e.TeamMemberId)
          ?.map((f) => f.Phone),
        // Status: e.IsActive,
        status:
          (userdata?.IsApproved === "Y" && userdata?.IsActive == null) ||
          (userdata?.IsApproved === "Y" && userdata?.IsActive === "N") ? (
            <span className="badge badge-success font-weight-bold rounded">
              Approved
            </span>
          ) : (userdata?.IsApproved === "N" && userdata?.IsActive == null) ||
            (userdata?.IsApproved === "N" && userdata?.IsActive === "N") ||
            (userdata?.IsApproved === "N" && userdata?.IsActive === "Y") ? (
            <span className="badge badge-danger font-weight-bold rounded">
              Rejected
            </span>
          ) : userdata?.IsApproved === "Y" && userdata?.IsActive === "Y" ? (
            <span className="badge badge-success font-weight-bold rounded">
              Active
            </span>
          ) : userdata?.IsApproved == null && userdata?.IsActive === "N" ? (
            <span className="badge badge-info font-weight-bold rounded">
              InActive
            </span>
          ) : userdata?.IsApproved == "Y" && userdata?.IsActive === null ? (
            <span className="badge badge-info text-white font-weight-bold rounded">
              waiting for account active
            </span>
          ) : (
            <span className="badge badge-warning text-white font-weight-bold rounded">
              Pending
            </span>
          ),
      });
    }
  });

  // uniqueTeamMembers now contains unique team members with their associated data

  const uniqueTeamMemberIds = [
    ...new Set(allocationtabel.filter((item) => item.TeamMemberId)),
  ];
  const uniqueObjects = allocationtabel.filter((item) =>
    uniqueTeamMemberIds.includes(item.TeamMemberId)
  );

  const handleSecondFriend = (rowDataId) => {
    setTeamdetails(
      Teamdetails.map((friend) =>
        // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
        friend.TeamMemberId === rowDataId
          ? { ...friend, selectOption: !friend.selectOption }
          : { ...friend }
      )
    );
  };

  function handleClose() {
    setedit(false);
    Refresh();
    if (inviteuser !== undefined) {
      setinviteuser(false);
    }
    setallocationopen(false);
    setTeamopen(false);
    setReportallocate(false);
  }
  const test = allocationtabel?.map((E) => E.TeamMemberId != E.TeamMemberId);
  // subteam?.map ((E)=>E.ClientUserId && Teamallocate?.filter(
  //   (r) =>
  //     r.teammemberid === E?.ClientUserId && r.allocpercentage != 0 ))

  function selectcustom(rowData) {
    return (
      <>
        <div onClick={() => handleSecondFriend(rowData.TeamMemberId)}>
          <div style={{ padding: "10px 05px 10px 15px", width: "45px" }}>
            <MoreVertIcon
              style={{ color: "#198ae3", fontSize: "20px", fontWeight: "bold" }}
            />
          </div>
          {rowData.selectOption && (
            <div
              style={{
                backgroundColor: "#ffffff",
                position: "absolute",
                width: "120px ",
                borderRadius: "3px",
              }}
            >
              <ul style={{ backgroundColor: "#ebedf2" }}>
                <div
                  style={{ padding: "7px 10px 3px 5%" }}
                  onClick={() => handleEditing(rowData)}
                >
                  <EditIcon style={{ fontSize: "medium" }} />
                  {"   "} Edit
                </div>
                <div
                  style={{ padding: "7px 10px 3px 5%" }}
                  onClick={() => handleReport(rowData)}
                >
                  <AssessmentIcon style={{ fontSize: "medium" }} />
                  {"    "}Report
                </div>
              </ul>
            </div>
          )}
        </div>
      </>
    );
  }
  console.log(uniqueTeamMembers, "Name");
  const Columns = [
    {
      title: "User ID & Name",
      field: "User ID & Name",
      render: (userdata) =>
        `${userdata.ClientUserId} - ${
          userdata.UserFirstname + " " + userdata?.UserLastname
        }`,
      // &nbsp;{userdata?.UserFirstname + " " + userdata?.UserLastname}
    },
    // {
    //   title: "Team  Id",
    //   field: "TeamMemberId",
    //   defaultSort: "desc",
    //   editable: false,
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   title: "Name",
    //   field: "Name",
    //   editable: false,
    //   cellStyle: { textAlign: "left", fontSize: "12px" },
    // },

    {
      title: "Project",
      field: "project",
      editable: false,
      cellStyle: { fontSize: "12px", textAlign: "left", width: "32%" },
    },

    { title: "E-Mail", field: "Email" },
    { title: "Phone Number", field: "Phone" },

    { title: "Status", field: "status" },
    // { title: "End Date", field: "" },
    // {
    //   title: "Action",
    //   field: "Action",
    //   render: (rowData) => <>{selectcustom(rowData)}</>,
    // },
  ];
  const Columns1 = [
    // {
    //   title: "User ID & Name",
    //   field: "TeamMemberId",
    //   // render: (userdata) =>
    //   //   `${userdata.TeamMemberId} - ${
    //   //     userdata.UserFirstname + " " + userdata?.UserLastname
    //   //   }`,
    //   // &nbsp;{userdata?.UserFirstname + " " + userdata?.UserLastname}
    // },
    // {
    //   title: "Team  Id",
    //   field: "TeamMemberId",
    //   defaultSort: "desc",
    //   editable: false,
    //   cellStyle: { fontSize: "12px" },
    // },
    {
      title: "User Id & Name",
      field: "Name",
      editable: false,
      cellStyle: { textAlign: "left", fontSize: "12px" },
    },

    {
      title: "Project",
      field: "project",
      editable: false,
      cellStyle: { fontSize: "12px", textAlign: "left", width: "32%" },
    },

    { title: "E-Mail", field: "Email" },
    { title: "Phone Number", field: "Phone" },

    { title: "Status", field: "status" },
    // { title: "End Date", field: "" },
    // {
    //   title: "Action",
    //   field: "Action",
    //   render: (rowData) => <>{selectcustom(rowData)}</>,
    // },
  ];
  const handleEmailChange = (index, event) => {
    const newEmails = [...Email];
    newEmails[index] = event.target.value;
    setEmail(newEmails);
  };

  const addEmailInput = () => {
    const newEmails = [...Email, ""]; // Add an additional email input
    setEmail(newEmails);
  };

  const removeEmailInput = (index) => {
    const newEmails = [...Email];
    newEmails.splice(index, 1); // Remove the specified email input
    setEmail(newEmails);
  };
  console.log(errors.email, "erroremail check");
  const allocateproject = (
    <Button
      variant="outline-success"
      size="sm"
      fontSize="small"
      disabled={projectRole == "TL" || projectdetails?.length == 0}
      className="action-icon fontdesign ml-2 "
      style={{ fontWeight: "normal", borderRadius: "8px" }}
      onClick={() => setprjallocation(true)}
      // disabled={projectdetails?.length == 0}
    >
      <GroupAddIcon fontSize="small" /> Project Allocation
    </Button>
  );

  return (
    <>
      {isLoading ? (
        <div
          className=" d-flex align-items-center"
          style={{ justifyContent: "center", height: 460 }}
        >
          <span
            className="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "rgb(82, 80, 229)" }}
          ></span>
        </div>
      ) : (
        <div style={{ backgroundColor: "#f6f7fa" }}>
          {inviteuser == true ? null : (
            <label className="ProjectlabelStyle1 ml-4 mt-2">
              {"Organization Members"}
            </label>
          )}
          {inviteuser == true ? null : (
            <Row>
              <Col className="d-flex justify-content-start ml-4">
                {empdetails?.[0]?.IsOrgAdmin == "Y" ? (
                  <Button
                    variant="outline-success"
                    size="sm"
                    fontSize="small"
                    className="action-icon fontdesign "
                    style={{ fontWeight: "normal", borderRadius: "8px" }}
                    onClick={() => setopenProCreate(true)}
                  >
                    <GroupAddIcon fontSize="small" /> Invite User
                  </Button>
                ) : null}{" "}
                {projectRole == "TL" ? (
                  <Tooltip
                    title="TL dosen't have the access to Allocate Project."
                    placement="top"
                  >
                    {allocateproject}
                  </Tooltip>
                ) : projectdetails?.length > 0 ? (
                  allocateproject
                ) : (
                  <Tooltip
                    title={"Please create project to allocate"}
                    placement="bottom"
                  >
                    {allocateproject}
                  </Tooltip>
                )}{" "}
              </Col>
            </Row>
          )}
          <br></br>
          {/* <Card className="project">
        <div
          className="header_style text-secondary"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "20px",
            marginTop: "50px",
            marginLeft: "30px",
            marginBottom: "20px",
          }}
        >
          Add a Team
        </div>
        <Table
          responsive
          striped
          bordered
          hover
          className="position-relative"
          style={{ marginBottom: "50px" }}
        >
          <thead id="report">
            <tr>
              <th className="text">First Name</th>
              <th className="text">Last Name</th>
              <th className="text">E-Mail</th>
              <th className="text">Phone Number</th>
            </tr>
          </thead>

          <tr style={{ marginBottom: "50px" }}>
            <td className="textdown">
              <TextField
                variant="outlined"
                className="mytextteam1"
                size="small"
                value={firstname}
                onChange={(e) => handlename(e)}
                type="text"
                error={error}
                autoComplete="off"
                required
                helperText={error}
                // disabled
              />
            </td>

            <td>
              <TextField
                variant="outlined"
                className="mytextteam1"
                size="small"
                value={secondname}
                onChange={(e) => setsecondname(e.target.value)}
                type="text"
                error={lastnameerror}
                autoComplete="off"
                required
                helperText={lastnameerror}
                // disabled
              />
            </td>

            <td>
              <TextField
                variant="outlined"
                className="mytextteam1"
                size="small"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="Email"
                error={mailerror}
                autoComplete="off"
                required
                helperText={mailerror}
                // disabled
              />
            </td>
            <td>
              <link
                rel="stylesheet"
                href="/css/react-phone-number-input/style.css"
              />
              <PhoneInput
                // international={false}
                // country='IND'
                // variant="outlined"
                // className="mobile"
                // regions={'europe'}
                defaultCountry="IN"
                placeholder="Enter Mobile number"
                maxMenuHeight={10}
                size="small"
                value={phone}
                onChange={setphone}
                // type="tel"
                error={phoneerror}
                autoComplete="off"
                // min={10}
                // max={10}
                required
                helperText={phoneerror}
                // disabled
              />
            </td>
          </tr>
        </Table>

        <td>
       
          <Button
            className="Card_Button_Color_Approve btn-greeng  justify-content-center"
            onClick={submit}
            style={{ marginLeft: "360px", position: "relative" }}
          >
            Add
          </Button>
          <Button
            className="Card_Button_Color_Approve btn-danger "
            onClick={Refresh}
            // style={{position:"relative"}}
          >
            Cancel
          </Button>
        </td>
        <br></br>
      </Card> */}
          <Divider />
          {inviteuser == true ? null : (
            <div style={{ marginTop: "20px" }}>
              <Tabs
                style={{
                  alignSelf: "center",
                  marginTop: "20px",
                  marginLeft: "24px",
                }}
                defaultActiveKey={
                  // this.state.ci ?
                  "first"
                  // : "second"
                }
                // className="mx-5"
              >
                <Tab
                  // disabled={this.state.ci}
                  eventKey="first"
                  title="Team Members"
                  style={{
                    alignSelf: "center",
                    marginTop: "20px",
                    // margin: "5%",
                    // width: `${windowSize.current[0] / 2}px`,
                    //  display: 'flex', flexDirection: 'row'
                  }}
                >
                  <div
                    style={{
                      alignSelf: "center",
                      // width: `${windowSize.current[0] / 2.2}px`,
                      //  display: 'flex', flexDirection: 'row'
                    }}
                  >
                    <div className="tabel">
                      <link
                        rel="stylesheet"
                        href="https://fonts.googleapis.com/icon?family=Material+Icons"
                      ></link>
                      <MaterialTable
                        title={"Team Details"}
                        columns={Columns1.map((e) => e)}
                        components={{
                          Pagination: PatchedPagination,
                        }}
                        data={uniqueTeamMembers}
                        // {allocationtabel?.map((e) => {

                        //   return {

                        //     ...e,

                        //     TeamMemberId: e?.TeamMemberId,
                        //     Name: subteam?.filter(
                        //       (r) =>
                        //         r.ClientUserId === e?.TeamMemberId

                        //     )?.map((f) => f?.UserFirstname + " " + f?.UserLastname,),
                        //     project: Teamallocate?.filter(
                        //       (r) =>
                        //         r.teammemberid === e?.TeamMemberId &&
                        //         r?.iscurrent != "N" &&
                        //         r?.allocpercentage != 0 &&
                        //         r?.projectname != null
                        //     )?.map((f) => f?.projectname + "-" + f?.allocpercentage + "%"),
                        //     Email:subteam?.filter(
                        //       (r) =>
                        //         r.ClientUserId === e?.TeamMemberId

                        //     )?.map((f) =>  f?.OfficialEmail ),
                        //     Phone: subteam?.filter(
                        //       (r) =>
                        //         r.ClientUserId === e?.TeamMemberId

                        //     )?.map((f) => f.Phone ),
                        //     // Status:e.IsActive,
                        //     // status:e.IsApproved == "Y" && e.IsActive == "Y" ? <span  className="badge badge-success text-white font-weight-bold rounded"> Approved </span> :e.IsApproved == "N" ? <span className="badge badge-danger font-weight-bold text-white rounded">Rejected</span> : e.IsActive == "N" ? <span className="badge badge-danger font-weight-bold text-white rounded">InActive</span> :  e.IsActive == "Y" ?  <span  className="badge badge-success1 text-success font-weight-bold rounded"> Active </span>: <span  className="badge badge-warning text-white font-weight-bold rounded" >Pending for Approval</span>

                        //     // createby: e.CreatedByDetails.Name,
                        //     // startdate: e?.StartDate,
                        //     // enddate: e?.EndDate,
                        //     // ProjectId:e?.ProjectId
                        //   };
                        // })}
                        // actions={[
                        //   {
                        //     icon: "assignment",
                        //     tooltip: "Report",
                        //     onClick: (event, rowData) => handleReport(rowData),
                        //   },
                        // ]}
                        // editable={{
                        //   onRowUpdate: (newData, oldData) =>
                        //     new Promise((resolve, reject) => {

                        //       // let projectCode12 =
                        //       //   projectsArr == null
                        //       //     ? ""
                        //       //     : projectsArr.find(
                        //       //         (pro) => pro.ProjectId === newData.ProjectCode
                        //       //       ).ProjectCode;
                        //       // console.log(projectCode12, "projectcode");
                        //       // let projectCode21 =
                        //       //   projectsArr == null
                        //       //     ? ""
                        //       //     : projectsArr.find(
                        //       //         (pro) => pro.ProjectId != newData.ProjectCode
                        //       //       ).ProjectCode;
                        //       // console.log(projectCode21, "projectcode2");
                        //       // setTimeout(() => {
                        //       let temp = {
                        //         Firstname: newData.Firstname,
                        //         Lastname: newData.Lasttname,
                        //         Email: newData.Email,
                        //         Phone: newData.Phone,
                        //       };
                        //       resolve();

                        //       // axios
                        //       //   .patch(
                        //       //     "agilesaas_team_master?TeamMemberId=eq." +
                        //       //     oldData.TeamMemberId,

                        //       //     temp
                        //       //   )
                        //       //   .then((res) => {
                        //       //     toast.success(`Team Edited`, {
                        //       //       transition: Slide,
                        //       //       position: toast.POSITION.TOP_RIGHT,
                        //       //       autoClose: 3000,
                        //       //       draggable: true,
                        //       //     });
                        //       //     Refresh();
                        //       //   });
                        //       // var postdata2 = {
                        //       //   AllocPercentage: 100 - newData.AllocPercentage,
                        //       // };
                        //       // axios
                        //       //   .patch(
                        //       //     "agilesaas_project_mapping?TeamMemberId=eq." +
                        //       //       valueOfTeamMemberId +
                        //       //       "&ProjectCode=eq." +
                        //       //       projectCode21,
                        //       //     postdata2
                        //       //   )
                        //       //   .then((res) => {
                        //       //     console.log(res, "ReallocResponse2");
                        //       //     if (res.status === 204) {
                        //       //       console.log(res, "ReallocResponse2");
                        //       //     }
                        //       //     loadData(valueOfTeamMemberId);
                        //       //   });

                        //       // }, 1000);
                        //     }),
                        // }}
                        options={{
                          actionsColumnIndex: -1,
                          exportAllData: true,
                          exportButton: true,
                          columnResizable: true,
                          filtering: false,
                          sorting: true,
                          paging: true,
                          pageSize: 10,
                          emptyRowsWhenPaging: false,
                          pageSizeOptions: [10, 20, 30, 40],

                          headerStyle: {
                            whiteSpace: "nowrap",
                            fontWeight: "700",
                            fontSize: "medium",
                            // backgroundColor: "#eef2f7",
                            color: "black",
                            // padding: "9px ",
                            textAlign: "left",
                            zIndex: "1",
                          },
                          cellStyle: {
                            textAlign: "left",
                            fontSize: "medium",
                            padding: "21px",
                            color: "#6c757d",
                          },
                          rowStyle: (rowdata) => {
                            if (rowdata.tableData.id % 2 != 0) {
                              // return { backgroundColor: "#cddef085" };
                            }
                          },
                        }}
                        onRowClick={(evt, selectedRow) =>
                          setSelectedRow(selectedRow.tableData.id)
                        }
                      />
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="second"
                  title="Invitees list"
                  style={{
                    alignSelf: "center",
                    marginTop: "20px",
                    // margin: "15%",
                    // width: `${windowSize.current[0] / 2}px`,
                    //  display: 'flex', flexDirection: 'row'
                  }}
                >
                  <div className="tabel">
                    <link
                      rel="stylesheet"
                      href="https://fonts.googleapis.com/icon?family=Material+Icons"
                    ></link>
                    <MaterialTable
                      title={"Invitation List"}
                      className="table-header"
                      columns={Columns.map((e) => e)}
                      components={{
                        Pagination: PatchedPagination,
                      }}
                      data={subteam?.map((e) => {
                        return {
                          ...e,
                          TeamMemberId: e.OrgEmployeeId
                            ? e.OrgEmployeeId
                            : e?.ClientUserId,
                          Name: e?.UserFirstname + " " + e?.UserLastname,

                          // RoleName: e.label,
                          // RoleGroupId: e.value,
                          // RoleCode: e.RoleCode,

                          project: Teamallocate?.filter(
                            (r) =>
                              r.teammemberid === e?.ClientUserId &&
                              r?.iscurrent != "N" &&
                              r?.allocpercentage != 0 &&
                              r?.projectname != null
                          )?.map((f) => (
                            // <div>
                            //   {f?.projectname + "-" + f?.allocpercentage + "%"}
                            // </div>
                            <div>
                              {f?.projectrole?.RoleName}
                              {" - "}
                              {`${f?.projectname}    (${f?.allocpercentage}%)`}
                            </div>
                          )),
                          Email: e?.OfficialEmail,
                          Phone: e.Phone,
                          Status: e.IsActive,
                          status:
                            (e.IsApproved === "Y" && e.IsActive == null) ||
                            (e.IsApproved === "Y" && e.IsActive === "N") ? (
                              <span className="badge badge-success font-weight-bold rounded">
                                Approved
                              </span>
                            ) : (e.IsApproved === "N" && e.IsActive == null) ||
                              (e.IsApproved === "N" && e.IsActive === "N") ||
                              (e.IsApproved === "N" && e.IsActive === "Y") ? (
                              <span className="badge badge-danger font-weight-bold rounded">
                                Rejected
                              </span>
                            ) : e.IsApproved === "Y" && e.IsActive === "Y" ? (
                              <span className="badge badge-success font-weight-bold rounded">
                                Active
                              </span>
                            ) : e.IsApproved == null && e.IsActive === "N" ? (
                              <span className="badge badge-info font-weight-bold rounded">
                                InActive
                              </span>
                            ) : e.IsApproved == "Y" && e.IsActive === null ? (
                              <span className="badge badge-info text-white font-weight-bold rounded">
                                waiting for account active
                              </span>
                            ) : (
                              <span className="badge badge-warning text-white font-weight-bold rounded">
                                Pending
                              </span>
                            ),
                          // e.IsApproved == "Y" && e.IsActive == "Y" ? <span
                          //   className="badge badge-success text-white font-weight-bold rounded"> Approved </span> : e.IsApproved == "N" ? <span
                          //     className="badge badge-danger font-weight-bold text-white rounded">Rejected</span> : e.IsActive == "N" ? <span
                          //       className="badge badge-danger font-weight-bold text-white rounded">InActive</span> : e.IsActive == "Y" ? <span
                          //         className="badge badge-success1 text-success font-weight-bold rounded"> Active </span> : e.IsApproved == null ? <span
                          //           className="badge badge-warning text-white font-weight-bold rounded">Pending for Approval</span> : e.IsActive == null
                          //   ? <span className="badge badge-info text-white font-weight-bold rounded">waiting for account active</span> : null

                          // createby: e.CreatedByDetails.Name,
                          // startdate: e?.StartDate,
                          // enddate: e?.EndDate,
                          // ProjectId:e?.ProjectId
                        };
                      })}
                      // actions={[
                      //   {
                      //     icon: "assignment",
                      //     tooltip: "Report",
                      //     onClick: (event, rowData) => handleReport(rowData),
                      //   },
                      // ]}
                      // editable={{
                      //   onRowUpdate: (newData, oldData) =>
                      //     new Promise((resolve, reject) => {

                      //       // let projectCode12 =
                      //       //   projectsArr == null
                      //       //     ? ""
                      //       //     : projectsArr.find(
                      //       //         (pro) => pro.ProjectId === newData.ProjectCode
                      //       //       ).ProjectCode;
                      //       // console.log(projectCode12, "projectcode");
                      //       // let projectCode21 =
                      //       //   projectsArr == null
                      //       //     ? ""
                      //       //     : projectsArr.find(
                      //       //         (pro) => pro.ProjectId != newData.ProjectCode
                      //       //       ).ProjectCode;
                      //       // console.log(projectCode21, "projectcode2");
                      //       // setTimeout(() => {
                      //       let temp = {
                      //         Firstname: newData.Firstname,
                      //         Lastname: newData.Lasttname,
                      //         Email: newData.Email,
                      //         Phone: newData.Phone,
                      //       };
                      //       resolve();

                      //       // axios
                      //       //   .patch(
                      //       //     "agilesaas_team_master?TeamMemberId=eq." +
                      //       //     oldData.TeamMemberId,

                      //       //     temp
                      //       //   )
                      //       //   .then((res) => {
                      //       //     toast.success(`Team Edited`, {
                      //       //       transition: Slide,
                      //       //       position: toast.POSITION.TOP_RIGHT,
                      //       //       autoClose: 3000,
                      //       //       draggable: true,
                      //       //     });
                      //       //     Refresh();
                      //       //   });
                      //       // var postdata2 = {
                      //       //   AllocPercentage: 100 - newData.AllocPercentage,
                      //       // };
                      //       // axios
                      //       //   .patch(
                      //       //     "agilesaas_project_mapping?TeamMemberId=eq." +
                      //       //       valueOfTeamMemberId +
                      //       //       "&ProjectCode=eq." +
                      //       //       projectCode21,
                      //       //     postdata2
                      //       //   )
                      //       //   .then((res) => {
                      //       //     console.log(res, "ReallocResponse2");
                      //       //     if (res.status === 204) {
                      //       //       console.log(res, "ReallocResponse2");
                      //       //     }
                      //       //     loadData(valueOfTeamMemberId);
                      //       //   });

                      //       // }, 1000);
                      //     }),
                      // }}
                      options={{
                        actionsColumnIndex: -1,
                        exportAllData: true,
                        exportButton: true,
                        columnResizable: true,
                        filtering: false,
                        sorting: true,
                        paging: true,
                        pageSize: 10,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [10, 20, 30, 40],

                        headerStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "700",
                          fontSize: "medium",
                          // backgroundColor: "#eef2f7",
                          color: "black",
                          // padding: "9px ",
                          textAlign: "left",
                          zIndex: "1",
                        },
                        cellStyle: {
                          textAlign: "left",
                          fontSize: "medium",
                          padding: "21px",
                          color: "#6c757d",
                        },
                        rowStyle: (rowdata) => {
                          if (rowdata.tableData.id % 2 != 0) {
                            // return { backgroundColor: "#cddef085" };
                          }
                        },
                      }}
                      onRowClick={(evt, selectedRow) =>
                        setSelectedRow(selectedRow.tableData.id)
                      }
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
          )}

          <Modal
            className="CreateProMadal"
            size="md"
            show={Teamopen}
            // show={true}
            onHide={() => {
              handleClose();
            }}
          >
            <TeamAllocation
              Email={Email}
              Teamdetails={Teamdetails}
              Refresh={Refresh}
              setallocationopen={setallocationopen}
              allocationopen={allocationopen}
              handleClose={handleClose}
              firstname={firstname}
              secondname={secondname}

              // projectdetails={projectdetails}
              // setProjectdetails={setProjectdetails}
            />
          </Modal>

          <Modal
            className="CreateProMadal"
            size="lg"
            show={Reportallocate}
            onHide={() => {
              handleClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title className="TitlelabelStyle">
                {" "}
                <label style={{ color: "#2593B8", fontWeight: "bold" }}>
                  Project Allocated Details{" "}
                </label>{" "}
                -{" "}
                <span className="text-danger">
                  {row?.Firstname + " " + row?.Lastname}
                </span>
              </Modal.Title>
            </Modal.Header>

            <Container>
              <ReportTeamallocate
                rowData={row}
                row={row}

                // projectdetails={projectdetails}
                // setProjectdetails={setProjectdetails}
              />
            </Container>
          </Modal>

          <Dialog
            // className="CreateProMadal"
            // size="md"
            // show={editopen || openProCreate}
            // onHide={() => (editopen ? handleClose() : ProjectClose())}
            open={editopen || openProCreate}
            onClose={() => (editopen ? handleClose() : ProjectClose())}
            className="CreateProMadal"
            // maxWidth="1500px"
            maxWidth="xs"
            fullWidth={true}
          >
            <Form onSubmit={editopen ? updateEdit : submit}>
              {
                userlimit <= Teamdetails.length ? (
                  ""
                ) : (
                  <DialogTitle>
                    <Row
                      className="mainbox"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col lg={10} md={10}>
                        <label style={{ color: "#2593B8", fontWeight: "bold" }}>
                          {editopen ? "Edit a Member" : "Invite User"}
                        </label>
                      </Col>
                      <IconButton
                        className="d-flex justify-content-end"
                        onClick={() =>
                          editopen ? handleClose() : ProjectClose()
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    </Row>
                  </DialogTitle>
                )
                // <Modal.Header closeButton>
                //   <Modal.Title className="TitlelabelStyle">
                //     {" "}
                //     <label style={{ color: "#2593B8", fontWeight: "bold" }}>
                //       {editopen ? "Edit a Member" : "Invite User"}
                //     </label>
                //   </Modal.Title>
                // </Modal.Header>
              }
              {userlimit <= Teamdetails.length ? (
                <DialogContent className="KanbanModal">
                  "Team creation limit reached. Please upgrade to higher plan
                  for Proceed Furthur..."
                </DialogContent>
              ) : (
                <DialogContent className="KanbanModal">
                  <Row className="mb-3 mt-0 ml-0 mr-0">
                    <label className="SprintlabelStyle">
                      First Name{" "}
                      <span className="text-muted" style={{ fontSize: "10px" }}>
                        (Optional)
                      </span>
                    </label>
                    <Input
                      className="SprintFormFields"
                      placeholder="Enter First Name"
                      value={firstname}
                      onChange={(e) => handlename(e)}
                      type="text"
                      invalid={errors.firstname !== undefined}
                    />
                    {errors.firstname && (
                      <FormFeedback>{errors.firstname}</FormFeedback>
                    )}
                  </Row>
                  <Row className="mb-3 mt-0 ml-0 mr-0">
                    <label className="SprintlabelStyle">
                      Last Name{" "}
                      <span className="text-muted" style={{ fontSize: "10px" }}>
                        (Optional)
                      </span>
                    </label>
                    <Input
                      className="SprintFormFields"
                      placeholder="Enter Last Name"
                      value={secondname}
                      onChange={(e) => {
                        // Use a regular expression to check if the input contains only letters
                        if (/^[A-Za-z]*$/.test(e.target.value)) {
                          setsecondname(e.target.value);
                        }
                      }}
                      type="text"
                      invalid={errors.secondname !== undefined}
                    />
                    {errors.secondname && (
                      <FormFeedback>{errors.secondname}</FormFeedback>
                    )}
                  </Row>
                  <Row
                    className="mb-3 mt-0 ml-0 mr-0"
                    style={{ width: "100%" }}
                  >
                    <label className="SprintlabelStyle">
                      E-Mail{" "}
                      <span className="text-muted" style={{ fontSize: "10px" }}>
                        (Click after entering to add multiple email ids)
                      </span>
                      <label className="text-danger">*</label>
                    </label>
                    <div
                      className={`email-input-container ${
                        errors.email ? "invalid" : ""
                      }`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "150px", // Set a maximum height
                        overflowY: "auto",
                      }}
                    >
                      <ReactMultiEmail
                        className="SprintFormFields1"
                        placeholder="Enter  Email"
                        Email={Email.map((email) => email.toLowerCase())}
                        onChange={(newEmails) => {
                          setEmail(newEmails);
                        }}
                        autoFocus={true}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              <div data-tag-item>{email}</div>
                              <span
                                data-tag-handle
                                onClick={() => removeEmail(index)}
                              >
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                      <span className="text-danger">
                        {errors.email}
                        {/* {errors.email && (
                        <FormFeedback>{errors.email}</FormFeedback>
                      )} */}
                      </span>
                    </div>
                    {/* <Input
                          className="SprintFormFields"
                          placeholder="Enter E-Mail"
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                          // type="email"
                          name="Email"
                          invalid={errors.email !== undefined}
                        /> */}
                    <div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* {Email.map((email, index) => (
          <div key={index} style={{ marginBottom: '5px' }}>
            <input
              type="email"
              value={Email}
              onChange={(event) => handleEmailChange(index, event)}
              placeholder="Enter email"
            />
            {Email.length > 1 && ( // Allow removing inputs if there is more than one
              <button onClick={() => removeEmailInput(index)}>Remove</button>
            )}
          </div>
        ))}
        <button onClick={addEmailInput}>Add Email</button>
      </div>
      <div> */}
                        {/* <strong>Emails:</strong>
        <ul>
          {Email.map((email, index) => (
            <li key={index}>{email}</li>
          ))}
        </ul> */}
                      </div>
                    </div>
                  </Row>
                  <Row className="mb-3 mt-0 ml-0 mr-0">
                    <label className="SprintlabelStyle">
                      Phone Number{" "}
                      <span className="text-muted" style={{ fontSize: "10px" }}>
                        (Optional)
                      </span>
                    </label>
                    {editopen ? (
                      <Input
                        // className="FormFields"
                        className="SprintFormFields"
                        placeholder="Enter Mobile number"
                        style={{
                          border:
                            errors.phone !== undefined
                              ? "1px solid red"
                              : "1px solid #ebedf2",
                        }}
                        value={phone}
                        onChange={(e) => setphone(e.target.value)}
                        invalid={errors.phone !== undefined}
                      />
                    ) : (
                      <PhoneInput
                        inputStyle={{ backgroundColor: "#ffff", width: "100%" }}
                        containerStyle={{ backgroundColor: "#f3f3ff" }}
                        searchStyle={{ backgroundColor: "#f3f3ff" }}
                        buttonStyle={{
                          backgroundColor: "#f3f3ff",
                          height: "35px",
                        }}
                        dropdownStyle={{ backgroundColor: "#f3f3ff" }}
                        style={{
                          border:
                            errors.phone !== undefined
                              ? "1px solid red"
                              : "none",
                        }}
                        // className="FormFields"
                        placeholder="Enter Mobile number"
                        value={phone}
                        onChange={setphone}
                        invalid={errors.phone !== undefined}
                      />
                    )}

                    {/* <PhoneInput
                  className="FormFields"
                  placeholder="Enter Mobile number"
                  value={phone}
                  onChange={setphone}
                  invalid={errors.phone !== undefined}
                /> */}
                    {errors.phone && (
                      <FormFeedback>{errors.phone}</FormFeedback>
                    )}
                  </Row>
                  {errors.phone !== undefined ? (
                    <label style={{ color: "red" }}>
                      Please enter phone number
                    </label>
                  ) : (
                    ""
                  )}
                </DialogContent>
              )}
              <DialogActions>
                {userlimit <= Teamdetails.length ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "right",
                    }}
                  >
                    <Button1
                      onClick={() =>
                        editopen ? handleClose() : ProjectClose()
                      }
                      style={{
                        fontSize: 14,
                        padding: 9,
                        width: 100,
                        height: 40,
                        borderRadius: 8,
                        backgroundColor: "#5250E5",
                        color: "#fff",
                        border: "0px solid #5A88F1",
                        textAlign: "center",
                        textTransform: "none",
                      }}
                    >
                      Ok
                    </Button1>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "right",
                    }}
                  >
                    <input
                      type="checkbox"
                      defaultValue={false}
                      value={another}
                      onClick={() => {
                        another == true ? setanother(false) : setanother(true);
                      }}
                    />
                    <label className="bottomLabel">Invite Another</label>
                    <Button1
                      onClick={() =>
                        editopen ? handleClose() : ProjectClose()
                      }
                      style={{
                        fontSize: 14,
                        padding: 9,
                        width: 100,
                        height: 40,
                        borderRadius: 8,
                        marginRight: 14,
                        border: "2px solid #5250E5",
                        backgroundColor: "#fff",
                        color: "#5250E5",
                        textAlign: "center",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button1>
                    <Button1
                      style={{
                        fontSize: 14,
                        padding: 9,
                        width: 100,
                        height: 40,
                        borderRadius: 8,
                        backgroundColor: "#5250E5",
                        color: "#fff",
                        border: "0px solid #5A88F1",
                        textAlign: "center",
                        textTransform: "none",
                      }}
                      disabled={checkSubmit}
                      type="submit"
                    >
                      {checkSubmit ? (
                        <span
                          className="spinner-border"
                          role="status"
                          aria-hidden="true"
                          style={{
                            color: "#fff",
                            width: "1.5rem",
                            height: "1.5rem",
                          }}
                        ></span>
                      ) : editopen ? (
                        "Edit"
                      ) : (
                        "Invite"
                      )}
                    </Button1>
                  </div>
                )}
              </DialogActions>
            </Form>
          </Dialog>
          {prjallocation == true ? (
            <Addnewproject
              prjallocation={prjallocation}
              setprjallocation={setprjallocation}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default Addnewteam;
