import React from "react";
import moment from "moment";

function updatemoment(e) {
  const eventDate = moment(e);
  const currentDate = moment();

  const minutesElapsed = currentDate.diff(eventDate, "minutes");
  const hoursElapsed = currentDate.diff(eventDate, "hours");
  const daysElapsed = currentDate.diff(eventDate, "days");
  const weeksElapsed = daysElapsed / 7;
  const yearsElapsed = currentDate.diff(eventDate, "years");

  let output = "Updated ";

  if (hoursElapsed < 24) {
    if (hoursElapsed != 0) {
      output += ` ${hoursElapsed} hour${hoursElapsed > 1 ? "s" : ""} and ${
        minutesElapsed % 60
      } minute${minutesElapsed % 60 !== 1 ? "s" : ""} ago`;
    } else {
      output += `${minutesElapsed % 60} minute${
        minutesElapsed % 60 !== 1 ? "s" : ""
      } ago`;
    }
  } else if (daysElapsed < 7) {
    output += `${daysElapsed} day${daysElapsed > 1 ? "s" : ""} ago`;
  } else if (weeksElapsed < 52) {
    output += `${Math.floor(weeksElapsed)} week${
      Math.floor(weeksElapsed) > 1 ? "s" : ""
    } ago`;
  } else {
    output += `${yearsElapsed} year${yearsElapsed > 1 ? "s" : ""} ago`;
  }

  return output;
}



export default updatemoment;
