import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import AntSwitch from "./Style";
import Axios from "axios";
import url from "../devEnj.json";
import DeployApp from "./DeployApp";
import './DeployApp.css'
import { toast } from "react-toastify";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiBox-root': {
      paddingLeft: 0,
      margin: 0
    },
    '& .MuiAppBar-root': {
      boxShadow: 'none', // Remove the box shadow from the tab header
      paddingLeft: 0,
      margin: 0
    },
    '& .MuiTabs-root': {
      display: 'flex', // Use flex layout
      justifyContent: 'flex-start',
    },
    // Rest of your styles...
  },
}));

export default function Deploy({ selectedData, setDeployPopup,
  // token 
}) {
  const { releases, projectCode } = useSelector((state) => state.user);
  const releasename = releases?.filter((s) => s.ProjectId === projectCode && s.Status == "In Progress")?.[0]?.ReleaseName
  console.log(releasename?.[0]?.ReleaseName, "rrrrrrrrrrrrrrrrrr")
  let actualversion =
    releasename
      ? releasename + "_" + moment().format("DDMMYYYYhhmmss")
      : selectedData?.projectdata?.projVersion?.split(".")[0] +
      "." +
      selectedData?.projectdata?.projVersion?.split(".")[1] +
      "." +
      (parseInt(selectedData?.projectdata?.projVersion?.split(".")[2]) + 1);
  // selectedData?.projectdata?.projVersion?.split(".")[0] +
  // "." +
  // selectedData?.projectdata?.projVersion?.split(".")[1] +
  // "." +
  // (parseInt(selectedData?.projectdata?.projVersion?.split(".")[2]) + 1);

  const [validVersion, setValidVersion] = useState(true)
  const [showBranch, setShowBranch] = useState(true)
  const [branchList, setBranchList] = useState([])
  const [tagList, setTagList] = useState([])
  const [branchName, setBranchName] = useState("")
  const [tagName, setTagName] = useState("")
  const [version, setVersion] = useState(actualversion)
  const [deployNote, setDeployNote] = useState("")
  const [versionControl, setVersionControl] = useState(true)
  const [cdDeployVersion, setCDDeployVersion] = useState(selectedData?.projectdata?.AllVersion?.[0]?.version)
  const [showDeployProgress, setShowDeployProgress] = useState(false)
  const [isProgressFinished, setIsProgressFinished] = useState(false)
  const [deployment, setDeploment] = useState(false)
  let email = JSON.parse(localStorage.getItem("userdata"));

  const cookies = new Cookies();

  useEffect(() => {
    Axios({
      url: `${url.server}/extraapi/getBranchTag?ProjectCode=${selectedData?.projectdata?.projCode}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "email": email?.login,
        "tenantId": localStorage.getItem("tenantid"),
        Authorization: "Bearer " + cookies.get("token"),
      },
      method: "get",
    })
      .then((res) => {
        // console.log(res, 'res')
        setTagList(res.data.tags)
        setBranchList(res.data.branches)
        setBranchName(res.data.branches[0])
        setTagName(res.data.tags[0])

      })
      .catch((err) => console.log(err));
  }, [])

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "BranchName": {
        setBranchName(e.target.value)
        break;
      }
      case "TagName": {
        setTagName(e.target.value)
        break;
      }
      case "ManualFeed": {
        setVersionControl(!versionControl)
        break;
      }
      case "Version": {
        setVersion(e.target.value)
        break;
      }
      case "deployNote": {
        setDeployNote(e.target.value)
        break;
      }
      case "CDDeployVersion": {
        setCDDeployVersion(e.target.value)
      }
      default:
        break;
    }
  };

  const showHideDeployModal = (show) => {
    if (show) { setShowDeployProgress(show) }
    else {
      setShowDeployProgress(show)
      setIsProgressFinished(false)
    }
  };

  const doSubsequentDep = (depObj) => {
    showHideDeployModal(true);
    // this.props.addProjectDone({ projCode: depObj.projectCode });
    Axios({
      url: `${url.server}/deploy/subsequent`,
      headers: {
        "email": email?.login,
        "tenantId": localStorage.getItem("tenantid"),
        Authorization: "Bearer " + cookies.get("token"),
      },
      method: "post",
      data: depObj,
    })
      .then((result) => {
        // setDeployment(true)
        console.log("subsequentDep");
      })
      .catch((err) => {
        console.log("Get error: " + err);
      });
  };

  const subsequentDeploy = (e, projectCode, projectType, envStage) => {
    e.preventDefault();
    // console.log(versionControl, 'versioncontrol')
    if (versionControl) {
      const Reg = /^[\w.\s-:]+$/.test(version);;
      if (Reg) {
        // console.log(Reg, e, projectCode, envStage, deployNote, showBranch ? branchName : tagName, 'if called ')
        setValidVersion(true)
        doSubsequentDep({
          "projectCode": projectCode,
          "environment": envStage.toLowerCase(),
          "deployNote": deployNote,
          "version": version,
          "BranchOrTag": showBranch ? branchName : tagName,
          "EnvType": projectType,
          "isCDdeployment": false,
          "ArtifactVersion": null,
          "ArtifactPath": "",
          "token": `Bearer ${cookies.get("token")}`
        });
      } else {
        setValidVersion(false)
      }
    } else {
      // console.log(e, projectCode, envStage, 'else called ')
      doSubsequentDep({
        projectCode: projectCode,
        environment: envStage.toLowerCase(),
        deployNote: deployNote,
        version: "auto",
        BranchOrTag: showBranch ? branchName : tagName,
      });
      // this.setState({ confirnmationAlert: false });
    }
  };



  const handleRadioOption = (e) => {
    setShowBranch(e)
  };
  const setProgressFinished = (finished) => {
    setIsProgressFinished(finished)
  };
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const radioStyle = {
    appearance: 'none',
    width: '14px',
    height: '14px',
    border: '2px solid #4263EB',
    borderRadius: '50%',
    outline: 'none',
    cursor: 'pointer',
  };

  const checkedRadioStyle = {
    ...radioStyle,
    backgroundColor: '#4263EB', // Change this color to your desired color for the selected radio button
    padding: 2
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FFF',
      border: state.isFocused ? '2px solid rgb(82, 80, 229)' : '1px solid rgba(0, 0, 0, 0.10)',
      borderRadius: 8,
      height: '38px !important',
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };
  const dynamicStyles = { height: 38 };

  const combinedStyles = { ...customStyles, ...dynamicStyles };
  const appBarStyle = {
    fontSize: "16px",
    textTransform: "capitalize",
    // fontWeight: "bold",
  };
  const isIncremental = true

  return (
    <>
      <Dialog
        open={showDeployProgress}
        // open={true}

        // onClose={() => {
        //   handleDeploy();
        // }}
        // maxWidth="1000px"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Box display="flex" alignItems="center">
              <Box
                flexGrow={1}
                fontSize="25px"
                fontWeight="bold"
                className="text-secondary"
              >
                Deployment Status
              </Box>
            </Box>
          </div>
        </DialogTitle>

        <DialogContent>
          <DeployApp
            setProgressFinished={setProgressFinished}
            isIncremental={isIncremental}
            // token={token}
            selectedData={selectedData}
            projCode={selectedData?.projectdata?.projCode}
            setShowDeployProgress={setShowDeployProgress}
            isci={true}
            deployment={deployment}
            setDeployPopup={setDeployPopup}
          />
        </DialogContent>

        <DialogActions>
          {isProgressFinished ? (

            <Button
              variant="primary m-2"
              onClick={(e) => {
                showHideDeployModal(false);
              }}
            >
              {" "}
              Close{" "}
            </Button>

          ) : null}
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <AppBar position="static" color="#fff" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab style={appBarStyle} label="Deploy Using CI" {...a11yProps(0)} />
            <Tab style={appBarStyle} label="Deploy Using CD" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0} dir={theme.direction} >
          <>
            <div className="py-1 ">

              <label style={{ marginRight: 15 }}>
                <input
                  type="radio"
                  value="option1"
                  checked={showBranch}
                  onChange={(e) => { handleRadioOption(true) }}
                  style={showBranch ? checkedRadioStyle : radioStyle}
                /> &nbsp;
                Branch
              </label>
              <label>
                <input
                  type="radio"
                  value="option2"
                  checked={!showBranch}
                  onChange={(e) => { handleRadioOption(false) }}
                  style={!showBranch ? checkedRadioStyle : radioStyle}
                />
                &nbsp;
                Tag
              </label>
            </div>

            {showBranch ? (
              <Row className="taskRow mt-1">
                <Col lg={12}>
                  <label className="SprintlabelStyle">
                    Branch
                  </label>
                  <select
                    className="form-control mt-0 form-control-lg"
                    name="BranchName"
                    value={branchName}
                    onChange={(e) => handleInputChange(e)}
                    style={combinedStyles}
                  >
                    <option value="" disabled>
                      Choose Branch
                    </option>
                    {branchList?.map(
                      (e) => (
                        <>

                          <option value={e}>
                            {e}
                          </option>
                        </>
                      )
                    )}
                  </select>

                </Col>
              </Row>)
              : (
                <Row className="taskRow mt-2">
                  <Col lg={12}>
                    <label className="SprintlabelStyle">
                      Tag
                    </label>
                    <select
                      style={combinedStyles}
                      className="form-control mt-0 form-control-lg"
                      name="TagName"
                      value={tagName}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="" disabled>
                        Choose Tags
                      </option>
                      {tagList?.map(
                        (e) => (
                          <>
                            <option value={e}>
                              {e}
                            </option>
                          </>
                        )
                      )}
                    </select>

                  </Col>
                </Row>
              )}
            <Row className="taskRow mt-1">
              <Col lg={12}>
                <label className="SprintlabelStyle">
                  Version
                </label>

                <input
                  type="text"
                  className="form-control mt-0 SprintFormFields"
                  id="validationProject"
                  value={version}
                  name="Version"
                  style={{ borderRadius: 8 }}
                  onChange={(e) => { handleInputChange(e); }}
                  required
                />
              </Col>
            </Row>
            {validVersion ? null : (
              <div>
                <p style={{ color: "red" }}>
                  Enter the Vaild Version
                </p>
              </div>
            )}
            <Row className="taskRow mt-1">
              <Col lg={12}>
                <label className="SprintlabelStyle">
                  Deploy note
                </label>

                <input
                  className="form-control mt-0 SprintFormFields"
                  placeholder="Enter deploy note"
                  name="deployNote"
                  value={deployNote}
                  style={{ borderRadius: 8 }}
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </Row>
          </>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <>
            <Row className="taskRow mt-1">
              <Col lg={12}>
                <label className="SprintlabelStyle">
                  Artifact Name
                </label>

                <select
                  style={combinedStyles}
                  className="form-control mt-0 form-control-lg"
                  name="CDDeployVersion"
                  value={cdDeployVersion}
                  onChange={(e) => handleInputChange(e)}
                >
                  {selectedData?.projectdata?.AllVersion.map(
                    (e) => (
                      <>
                        <option
                          value={e.version}
                        >
                          {e.version}
                        </option>
                      </>
                    )
                  )}
                </select>
              </Col>
            </Row>
            <Row className="taskRow mt-1">
              <Col lg={12}>
                <label className="SprintlabelStyle">
                  Version
                </label>

                <input
                  type="text"
                  className="form-control mt-0 SprintFormFields"
                  id="validationProject"
                  value={version}
                  name="Version"
                  style={{ borderRadius: 8 }}
                  onChange={(e) => { handleInputChange(e); }}
                  required
                />
              </Col>
            </Row>
            {validVersion ? null : (
              <div>
                <p style={{ color: "red" }}>
                  Enter the Vaild Version
                </p>
              </div>
            )}

            <Row className="taskRow mt-1">
              <Col lg={12}>
                <label className="SprintlabelStyle">
                  Deploy note
                </label>

                <input
                  className="form-control mt-0 SprintFormFields"
                  placeholder="Enter deploy note"
                  name="deployNote"
                  value={deployNote}
                  style={{ borderRadius: 8 }}
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </Row>
          </>
        </TabPanel>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
          <Button
            onClick={(evt) => {
              setDeployPopup(false)
            }}
            style={{ fontSize: 16, padding: 15, width: 100, height: 44, borderRadius: 8, marginRight: 14, border: '2px solid #5250E5', backgroundColor: '#fff', color: '#5250E5', textAlign: 'center', textTransform: 'none' }}
          >Cancel</Button>
          {/* {console.log(selectedData.projectdata, "sssssssssssssssssss")} */}
          <Button
            onClick={(e) => {
              e.stopPropagation();
              subsequentDeploy(
                e,
                selectedData.projectdata.projCode,
                selectedData.projectdata?.ProjectType,
                "stage"
              );
            }}
            style={{ fontSize: 16, padding: 15, width: 100, height: 44, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center', textTransform: 'none' }}
          >Deploy</Button>
        </div>
      </div>
    </>
  );
}
