import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import UserLayout from "./layouts/Users/User";
import "./App.scss";
import "./App.css";
import Login from "./layouts/Login/Login";
import UserContext from "./index";
import axios from "./axios";

import { useDispatch } from "react-redux";
import Popper from "@material-ui/core/Popper";
import {
  PROJECTDETAILSUPDATE,
  PROJECTMAPPINGDATAUPDATE,
} from "./redux/CommonInitialsate";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
function App() {
  const value1 = {
    Creatpopup: false,
    Projectvalue: null,
    window: null,
  };
  const dispatch = useDispatch();
  const iframeref = useRef(null);
  const [contextvalue, setcontextvalue] = useState(value1);
  const [windows, setwindows] = useState(null);
  const [project, setproject] = useState(null);
  const [User, setUser] = useState(false);
  const [tokenforsupport, setsupport] = useState("");
  // const [id, setId] = useState("popper");

  const cliid = localStorage.getItem("clientid");

  // const location = useLocation();
  // const currentPath = location?.pathname;
  // console.log(localStorage.getItem("location"));
  // useEffect(() => {
  //   const user = localStorage.getItem("userdata");
  //   if (user) {
  //     let { id, login, token } = JSON.parse(user);
  //     if (login) {
  //       setUser(login);
  //     }
  //   }
  //   axios
  //     .get(`subscription_org_settings?ClientId=eq.${cliid}`)
  //     .then((res) => {
  //       setsupport(
  //         Array.isArray(res.data?.[0]?.SettingDetails)
  //           ? res.data?.[0]?.SettingDetails.filter(
  //             (e) => e.settingcode === "ClientToken"
  //           )?.[0]?.value
  //           : res.data?.[0]?.SettingDetails?.settingcode === "ClientToken" &&
  //           res.data?.[0]?.SettingDetails?.value,
  //         "token"
  //       );

  //       // : res.data?.[0]?.SettingDetails?.value == );
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [cliid]);

  useEffect(() => {
    const user = localStorage.getItem("userdata");
    if (user) {
      let { id, login, token } = JSON.parse(user);
      if (login) {
        setUser(login);
      }
    }
    if (localStorage.getItem("location")) {
      axios
        .get(`subscription_org_settings?ClientId=eq.${cliid}`)
        .then((res) => {
          setsupport(
            Array.isArray(res.data?.[0]?.SettingDetails)
              ? res.data?.[0]?.SettingDetails?.filter(
                (e) => e.settingcode === "ClientToken"
              )?.[0]?.value
              : res.data?.[0]?.SettingDetails?.settingcode === "ClientToken" &&
              res.data?.[0]?.SettingDetails?.value
            // "token"
          );

          // : res.data?.[0]?.SettingDetails?.value == );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [cliid]);
  const userid = localStorage.getItem("userid") == undefined ? "" : JSON.parse(localStorage.getItem("userid"));
  const username = localStorage.getItem("username") == undefined ? "" : JSON.parse(localStorage.getItem("username"))
  const email = localStorage.getItem("email") == undefined ? "" : JSON.parse(localStorage.getItem("email"));
  const data = {
    userid: userid,
    username: username,
    email: email,
  };
  // console.log(tokenforsupport);
  setTimeout(() => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }, 10800000);
  // useEffect(() => {
  //   if (localStorage.getItem("location")) {
  //     const receivedmessage = (event) => {
  //       if (
  //         event.origin == "https://sonar.yozytech.com" ||
  //         event.origin == "http://localhost:3000"
  //       ) {
  //         const data = event.data;
  //         const parentdiv = document.getElementById("custome");
  //         parentdiv.style.height = data.height;
  //         parentdiv.style.width = data.width;
  //         console.log("passss");
  //       }
  //     };
  //     window.addEventListener("message", receivedmessage);
  //     return () => {
  //       window.removeEventListener("message", receivedmessage);
  //     };
  //   }
  // }, [tokenforsupport]);
  useEffect(() => {
    if (
      localStorage.getItem("location") &&
      tokenforsupport &&
      tokenforsupport !== undefined
    ) {
      const receivedmessage = (event) => {
        if (
          event.origin == "https://sonar.yozytech.com" ||
          event.origin == "http://localhost:3000"
        ) {
          const data = event.data;
          const parentdiv = document.getElementById("custome");
          parentdiv.style.height = data.height;
          parentdiv.style.width = data.width;
          // console.log("passss");
        }
      };
      window.addEventListener("message", receivedmessage);
      return () => {
        window.removeEventListener("message", receivedmessage);
      };
    }
  }, [tokenforsupport]);

  // console.log(tokenforsupport);

  // useEffect(() => {
  //   const sendtochild = () => {
  //     const userid = JSON.parse(localStorage.getItem("userid"));
  //     const username = JSON.parse(localStorage.getItem("username"));
  //     const email = JSON.parse(localStorage.getItem("email"));
  //     // console.log("passn");
  //     if (userid && iframeref.current) {
  //       iframeref.current.addEventListener("load", () => {
  //         iframeref.current.contentWindow.postMessage(
  //           { userid: userid, username: username, email: email },
  //           `https://sonar.yozytech.com?license=${tokenforsupport}`
  //           // `http://localhost:3000?license=${tokenforsupport}`
  //         );
  //       });
  //     }
  //   };
  //   if (localStorage.getItem("location")) {
  //     sendtochild();
  //   }
  // }, []);

  return (
    <UserContext.Provider
      value={{
        contextvalue,
        setcontextvalue,
        windows,
        setwindows,
        project,
        setproject,
      }}
    >
      {/* <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,300;9..40,400&family=Poppins:wght@100;400&family=Urbanist:wght@100&display=swap" rel="stylesheet" /> */}

      {User === false ? (
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/login"
              render={() => <Login token={User} setToken={setUser} />}
            />
            <Redirect from="/" to="/login" />
          </Switch>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Switch>
            <Route
              path="/user"
              render={(props) => <UserLayout token={User} {...props} />}
            />

            <Redirect
              path="/"
              to={
                localStorage.getItem("location")
                  ? localStorage.getItem("location")
                  : "/user/kanbanboard"
              }
            />
          </Switch>
        </BrowserRouter>
      )}
      {localStorage.getItem("location") && tokenforsupport && (
        <div
          id="custome"
          style={{
            inset: "auto 10px 10px auto",
            position: "fixed",
            zIndex: 998,
            minHeight: "85px",
            minWidth: "85px",
            // border: "1px solid black",
            // backgroundColor: "red",
          }}
        >
          <iframe
            ref={iframeref}
            // id="iframe"
            src={`https://sonar.yozytech.com?license=${tokenforsupport}&data=${encodeURIComponent(
              JSON.stringify(data)
            )}`}
            // src={`http://localhost:3000?license=${tokenforsupport}&data=${encodeURIComponent(
            //   JSON.stringify(data)
            // )}`}
            // src={`https://sonar.yozytech.com?license=${tokenforsupport}`}
            // src={`http://localhost:3000?license=${tokenforsupport}`}
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}
    </UserContext.Provider>
  );
}

export default App;
