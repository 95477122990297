
import axios from "./axios";


export default async function slacknotify(
    channelId,
    message,
    slacktoken
) {


    try {

      const response = await axios.post('https://api.devozy.ai/api/agile/slackservice', {
        channelId: channelId,
        message: message,
        slacktoken:slacktoken
      });

      if (response.data.success) {
        console.log('Message sent successfully!');
   
      } else {
        console.log(`Error sending message: ${response.data.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      
    }

 
}
