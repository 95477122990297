import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Card, Row, Col, Table } from "reactstrap";
import { Button } from "@mui/material";
import Select from "react-select";
import { toast, Slide } from "react-toastify";
import "./Agileceremonies.css";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, FormControl, Form, Modal, Nav, Tab } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import CeremoniesCalendar from "./CeremoniesCalendar";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { HANDLE_ON_CHANGE } from "../../redux/CommonInitialsate";

function AgileCeremonies({
  // setmeeeting,
  // projectCode,
  // projectName,
  // ceremoniesPopup,
  // setCeremoniesPopup,
}) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingDate, setMeetingDate] = useState(new Date());
  const [meetingTime, setMeetingTime] = useState(new Date());
  const [meetingDuration, setMeetingDuration] = useState("");
  const [meetingProject, setProject] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [checkSubmit, setSubmitCheck] = useState(false)
  const { projectRole, projectName, projectCode
  } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == "11004")[0]
    ?.RoleCode;
  let id = JSON.parse(localStorage.getItem("clientid"));
  let frommail = JSON.parse(localStorage.getItem("userdata"));
  let userdetails = JSON.parse(localStorage.getItem("clientdetails"));

  let host = userdetails[0]?.OrgDetails.ContactName;
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const options = [
    { value: "15", label: "15 Min" },
    { value: "30", label: "30 Min" },
    { value: "45", label: "45 Min" },
  ];
  const handleChanged = (selectedOption) => {
    setMeetingDuration(selectedOption ? selectedOption.value : null);
  };
  const handleClosed = () => {
    // setmeeeting(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'meeting', value: false }))
    setMeetingTitle("");
    setMeetingDate("");
    setMeetingTime("");
    setMeetingDuration("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let a =
      moment(meetingDate).format("YYYY-MM-DDT") +
      "" +
      moment(meetingTime).format("HH:mm:ss");

    let postdata = {
      ClientId: id,
      ClientDetails: {
        companyname: userdetails[0]?.OrgDetails.Company,
        email: userdetails[0]?.OrgDetails.ContactEmail,
        phone: userdetails[0]?.OrgDetails.ContactPhone,
      },
      Title: meetingTitle,
      Date: moment(meetingDate).format("YYYY-MM-DD"),
      ScheduledStartTime: a,
      ScheduledEndTime: moment(a)
        .add(meetingDuration, "minutes")
        .format("YYYY-MM-DDTHH:mm:ss"),
      Duration: meetingDuration,
      MeetingDetails: {
        host: host,
        meetinglink: null,
        vendor: null,
        meetingkey: null,
      },
      CreatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      CreatedByDetails: {
        companyname: userdetails[0]?.OrgDetails.Company,
        email: userdetails[0]?.OrgDetails.ContactEmail,
        phone: userdetails[0]?.OrgDetails.ContactPhone,
      },
      IsActive: null,
      Status: null,
      ProjectDetails: {
        ProjectCode: projectCode,
        ProjectName: projectName,
      },
      ProjectId: projectCode
    };
    // console.log(postdata, "final");
    setSubmitCheck(true)
    axios
      .post("agilesaas_ceremonies", postdata)
      .catch((err) => console.log(err))
      .then((response) => {
        setSubmitCheck(false)
        toast.success(`Meeting Scheduled Successfully`, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          draggable: true,
        });
        handleClosed();
      });
  };

  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const [activeTab, setActiveTab] = useState(projectRole == "SM" ||
    projectRole == "ITMGR2" ||
    projectRole == "QA" ||
    getProdRole == "KA" ? "tab1" : "tab2");

  const handleTabSelect = (tab) => {
    // setCeremoniesPopup(tab == "tab1" ? true : false);
    dispatch(HANDLE_ON_CHANGE({ name: 'ceremoniesPopup', value: tab == "tab1" ? true : false }))
    setActiveTab(tab);
  };
  return (
    <div>
      <Row className="mb-2 d-flex justify-content-center pr-3 ml-1">
        {projectRole == "SM" ||
          projectRole == "ITMGR2" ||
          projectRole == "QA" ||
          getProdRole == "KA" ? <Button
            // className="SprintlabelStyle"
            className="shadow-sm"
            style={{
              fontSize: 14,
              padding: 9,
              width: 150,
              height: 40,
              borderRadius: 8,
              marginRight: 14,
              border: activeTab == "tab1" ? "2px solid #5A88F1" : "none",

              backgroundColor: "#fff",
              color: "#000",
              textAlign: "center",
              textTransform: "none",
              fontWeight: 550,
            }}
            onClick={() => handleTabSelect("tab1")}
          >
          Schedule Meeting
        </Button> : null}
        <Button
          className="shadow-sm"
          style={{
            fontSize: 14,
            padding: 9,
            width: 150,
            height: 40,
            borderRadius: 8,
            backgroundColor: "#fff",
            color: "#000",
            border: activeTab == "tab2" ? "2px solid #5A88F1" : "none",
            textAlign: "center",
            textTransform: "none",
            fontWeight: 550,
          }}
          onClick={() => handleTabSelect("tab2")}
        >
          Calendar
        </Button>
      </Row>
      {activeTab == "tab1" ? (
        <div>
          <Form onSubmit={handleSubmit}>
            <Row className="mt-2 ">
              <Col>
                <Form.Group controlId="meetingTitle">
                  <Form.Label className="SprintlabelStyle">
                    Meeting Title
                  </Form.Label>
                  <Form.Control
                    className="SprintFormFields"
                    type="text"
                    value={meetingTitle}
                    required
                    placeholder="Daily Scrum Meeting"
                    onChange={(e) => setMeetingTitle(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="">
                <Form.Group style={{ paddingBottom: 0, marginBottom: 0 }}>
                  <Form.Label className="SprintlabelStyle">
                    Meeting Date
                  </Form.Label>
                  <div className="test mb-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        placeholder="dd/mm/yyy"
                        inputVariant="standard"
                        value={meetingDate}
                        onChange={(date) => setMeetingDate(date)}
                        format="dd/MM/yyyy"
                        views={["year", "month", "date"]}
                        disablePast
                        shouldDisableDate={disableWeekends}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group style={{ paddingBottom: 0, marginBottom: 0 }}>
                  <Form.Label className="SprintlabelStyle">
                    Meeting Time
                  </Form.Label>
                  <div className="test mb-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        placeholder="10:00AM"
                        format="HH:mm"
                        mask="__:__"
                        value={meetingTime}
                        inputVariant="standard"
                        onChange={(time) => setMeetingTime(time)}
                        variant="dialog"
                        defaultValue={meetingTime}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Form.Group controlId="meetingProject">
                  <Form.Label className="SprintlabelStyle">Project</Form.Label>
                  <Form.Control
                    className="SprintFormFields"
                    type="text"
                    value={projectName}
                    disabled
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="meetingDuration">
                  <label htmlFor="dropdown" className="SprintlabelStyle">
                    Duration
                  </label>
                  <Select
                    id="dropdown"
                    styles={customStyles}
                    value={
                      meetingDuration
                        ? options.find(
                          (option) => option.value === meetingDuration
                        )
                        : null
                    }
                    onChange={handleChanged}
                    options={options}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Form.Group controlId="meetingLink">
                  <Form.Label className="SprintlabelStyle">
                    Meeting Link
                  </Form.Label>
                  <Form.Control
                    className="SprintFormFields"
                    type="text"
                    value={meetingLink}
                    onChange={(e) => setMeetingLink(e.target.value)}
                    size="sm"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="meetingLink">
                  <Form.Label className="SprintlabelStyle">Host</Form.Label>
                  <Form.Control
                    className="SprintFormFields"
                    type="text"
                    value={host}
                    disabled
                    size="sm"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 d-flex justify-content-end pr-3">
              <Button
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 100,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 14,
                  border: "2px solid #5250E5",
                  backgroundColor: "#fff",
                  color: "#5250E5",
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={handleClosed}
              >
                Cancel
              </Button>
              <Button
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 140,
                  height: 40,
                  borderRadius: 8,
                  backgroundColor: "#5250E5",
                  color: "#fff",
                  border: "0px solid #5A88F1",
                  textAlign: "center",
                  textTransform: "none",
                }}
                type="sumbit"
                disabled={checkSubmit}
              >
                {
                  checkSubmit ?
                    <span
                      className="spinner-border"
                      role="status"
                      aria-hidden="true"
                      style={{ color: '#fff', width: '1.5rem', height: '1.5rem' }}
                    ></span>
                    :
                    'Schedule Meeting'
                }
              </Button>
              {/* <Col className=" d-flex justify-content-end border" sm={8}>
              
            </Col>
            <Col className="  ml-1 border">
              
            </Col> */}
            </Row>
          </Form>
        </div>
      ) : (
        <div>
          <CeremoniesCalendar />
        </div>
      )}

      {/* <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
        <Nav variant="tabs" className="d-flex justify-content-center ">
          <Nav.Item className="">
            <Nav.Link eventKey="tab1">Schedule Meeting</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="tab2" className="">
              Calendar
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="tab1">
            <div className=" d-flex align-items-center justify-content-end pl-5">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={8}>
                    <Form.Group controlId="meetingTitle">
                      <Form.Label>Meeting Title</Form.Label>
                      <Form.Control
                        type="text"
                        value={meetingTitle}
                        required
                        placeholder="Daily Scrum Meeting"
                        onChange={(e) => setMeetingTitle(e.target.value)}
                        size="sm"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="" sm={3}>
                    {" "}
                    <Form.Group controlId="meetingDate">
                      <Form.Label>Meeting Date</Form.Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          placeholder="dd/mm/yyy"
                          inputVariant="outlined"
                          value={meetingDate}
                          onChange={(date) => setMeetingDate(date)}
                          format="dd/MM/yyyy"
                          views={["year", "month", "date"]}
                          disablePast
                          shouldDisableDate={disableWeekends}
                          required
                        />
                      </MuiPickersUtilsProvider>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="meetingTime">
                      <Form.Label>Meeting Time</Form.Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          placeholder="10:00AM"
                          format="HH:mm"
                          mask="__:__"
                          value={meetingTime}
                          inputVariant="outlined"
                          onChange={(time) => setMeetingTime(time)}
                          variant="dialog"
                          defaultValue={meetingTime}
                          required
                        />
                      </MuiPickersUtilsProvider>
                    </Form.Group>
                  </Col>
                  <Col sm={2}>
                    <Form.Group controlId="meetingDuration">
                      <label htmlFor="dropdown">Duration</label>
                      <Select
                        id="dropdown"
                        value={
                          meetingDuration
                            ? options.find(
                              (option) => option.value === meetingDuration
                            )
                            : null
                        }
                        onChange={handleChanged}
                        options={options}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Form.Group controlId="meetingProject">
                      <Form.Label>Project</Form.Label>
                      <Form.Control
                        type="text"
                        value={projectName}
                        disabled
                        size="sm"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="meetingHost">
                      <Form.Label>Host</Form.Label>
                      <Form.Control
                        type="text"
                        value={host}
                        disabled
                        size="sm"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group controlId="meetingLink">
                      <Form.Label>Meeting Link</Form.Label>
                      <Form.Control
                        type="text"
                        value={meetingLink}
                        onChange={(e) => setMeetingLink(e.target.value)}
                        size="sm"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className=" d-flex justify-content-end " sm={7}>
                    <Button className="" size="sm" type="sumbit">
                      Schedule Meeting
                    </Button>
                  </Col>
                  <Col className=" d-flex justify-content-end ml-1" sm={1}>
                    <Button
                      className="btn-danger"
                      size="sm"
                      onClick={handleClosed}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="tab2">
            <CeremoniesCalendar />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container> */}
    </div>
  );
}
export default AgileCeremonies;
