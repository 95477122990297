import { Box } from '@material-ui/core'
import React from 'react'
import Privacy from './Privacy'

const TwoFactor = ({token}) => {
  return (
    <Box margin={3}>

      <Privacy Empid={token}/>


    </Box>
  )
}

export default TwoFactor