import axios from "../../../axios";
import React, { useEffect, useState } from "react";
import { Row,Col } from "reactstrap";
import "./AccessRequestForm.css";
import moment from "moment";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import { Card, TextField } from "@material-ui/core";
import ReactImageFallback from "react-image-fallback";
import Select from "react-select";
import access from "../../../assets/images/access request image.png";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
function AccessRequestForm({
  RefreshDatas,
  setGeneric,
  setAccess,
  setAssetsform,
}) {
  // Emp details
  const empdetails = JSON.parse(localStorage.getItem("Empdetails"));
  const onbaord =
    empdetails[0].OnboardedBy == "800001" ? 1 : empdetails[0].OnboardedBy;
  const ID = empdetails[0].ClientUserId;
  const clientid = JSON.parse(localStorage.getItem("clientid"));
  const clientdetails = JSON.parse(
    localStorage.getItem("Orgsubscriptiondetails")
  );

  // Form state

  const [Rising, setRising] = useState("Y");
  const [Application_Name, setApplication_Names] = useState("");
  const [Behalf_details, setBehalf_details] = useState("");
  const [Access_Level, setAccessLevel] = useState("");
  const [Role, setRole] = useState("");
  const [reasonType, setReasontype] = useState("");

  // Api state
  const [ResonMaster, setResonMaster] = useState([]);
  const [Application_names, setApplication_name] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [Access_Level_roledata, setAccessLevelRoledata] = useState([]);

  // validation state
  const [unclicked, setunclicked] = useState(false);
  const [risiingvalidation, setRisingvalidation] = useState(false);
  const [mapping, setmapping] = useState([]);

  // rows
  const [addrows, setAddrows] = useState([
    {
      Application_Name: "",
      reasonType: "",
      Access_Level: "",
      Role: "",
    },
  ]);
  // Addrows
  function Addrows() {
    let data = [...addrows];
    data.push({
      Application_Name: "",
      reasonType: "",
      Access_Level: "",
      Role: "",
    });

    setAddrows(data);
  }
  const DeleteRows = (index) => {
    let data = [...addrows];
    data.splice(index, 1);
    setAddrows(data);
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // background: "#f3f3ff",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  // Reset Form after submit data
  function ResetForm(e) {
    setRising("");
    setApplication_Names("");
    setBehalf_details("");
    setAccessLevel("");
    setRole("");
    setReasontype("");
    setRising("");
    setApplication_Names("");
    setBehalf_details("");
    setAccessLevel("");
    setRole("");
    setReasontype("");
    let data = [];
    data.push("");
    // data.pop()
    setAddrows(data);
    data = [];
    data.push({
      Application_Name: "",
      reasonType: "",
      Access_Level: "",
      Role: "",
    });
  }

  // Api calls
  useEffect(() => {
    function All_api_refresh_data() {
      axios
        .get("agilesaas_itrequest_reason_master")
        .then((res) => setResonMaster(res.data));

      axios
        .get("agilesaas_itrequest_itemtype_master")
        .then((res) => setApplication_name(res.data));

      axios
        .get("application_access_master")
        .then((res) => setAccessLevelRoledata(res.data));
    }

    All_api_refresh_data();
  }, []);

  // Emp details get
  function EmpDetailsGet(e) {
    if (Behalf_details == "") {
      setunclicked(false);
      return false;
    }
    if (Behalf_details != "") {
      setunclicked(true);
      axios
        .get(
          "subscription_client_users?OfficialEmail=eq." +
            Behalf_details +
            "&ClientId=eq." +
            clientid
        )
        .then((res) => {
          setEmployee(res.data);
          if (res.data.length == 0) {
            alert("Enter an Verified Email-Id");
          }
          axios
            .get(
              `rpc/fun_agilesaasmanagerteamprojdetails?managerid=` +
                res.data[0]?.OnboardedBy +
                `&clientid=` +
                clientid +
                `&teammemberid=eq.` +
                res.data[0]?.ClientUserId
            )
            .then((response) => setmapping(response.data));
        });
    }
    return true;
  }

  let roledata = mapping?.map((e) => e.projectrole.RoleName)[0];
  function AppName(e, i) {
    // i replace from e.target name
    // e.value replace from e.target.value
    let data = [...addrows];
    data.splice(i, 1, {
      Application_Name: e.value,
      reasonType: addrows[i].reasonType,
      Access_Level: addrows[i].Access_Level,
      Role: addrows[i].Role,
    });

    setAddrows(data);
  }

  function ReasonName(e, i) {
    // i replace from e.target name
    // e.value replace from e.target.value
    let data = [...addrows];
    data.splice(i, 1, {
      Application_Name: addrows[i].Application_Name,
      reasonType: e.value,
      Access_Level: addrows[i].Access_Level,
      Role: addrows[i].Role,
    });
    setAddrows(data);
  }

  function AccessLevelName(e, i) {
    let data = [...addrows];
    data.splice(i, 1, {
      Application_Name: addrows[i].Application_Name,
      reasonType: addrows[i].reasonType,
      Access_Level: e.value,
      Role: addrows[i].Role,
    });
    setAddrows(data);
  }

  function RoleName(e, i) {
    let data = [...addrows];
    data.splice(i, 1, {
      Application_Name: addrows[i].Application_Name,
      reasonType: addrows[i].reasonType,
      Access_Level: addrows[i].Access_Level,
      Role: e.value,
    });
    setAddrows(data);
  }
  const customStylesd = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const Table = (
    <label
      className=" d-flex justify-content-space between"
      style={{ fontSize: "14px" }}
    >
      {Employee &&
        Employee.map((e, i) => e.UserFirstname + " " + e.UserLastname + " " + roledata)}
    </label>
    // <table className="table mt-1">
    //   <thead>
    //     <tr>
    //       <th>First Name</th>
    //       <th>Last Name</th>
    //       {/* <th>Emp level </th> */}
    //       <th>Designation</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {Employee &&
    //       Employee.map((e, i) => {
    //         return (
    //           <tr key={i}>
    //             <td>{e.Firstname}</td>
    //             <td>{e.Lastname} </td>
    //             {/* <td>{e.emplevel}</td> */}
    //             <td>{roledata}</td>
    //           </tr>
    //         );
    //       })}
    //   </tbody>
    // </table>
  );

  const Final_Access_level = ResonMaster.filter(
    (e) => e.ReasonCategory == "AAR"
  ).map((e) => {
    return { value: e.Description, label: e.Description };
  });

  const Final_Application_level = Application_names.filter(
    (e) => e.ItemTypeCategory == "AAR"
  ).map((e) => {
    return { value: e.Description, label: e.Description };
  });
  function close() {
    setGeneric(false);
    setAccess(false);
    setAssetsform(false);
  }
  const handleOptionChange = (event) => {
    setRising(event.target.value);
  };

  function Access_submit(e) {
    e.preventDefault();

    if (Rising == "N") {
      if (unclicked === false) {
        alert("please click the fetch and verify user details");
        return false;
      }
      if (!EmpDetailsGet()) {
        return;
      }
    }

    if (Rising === "") {
      setRisingvalidation(true);
    }

    for (let element of addrows) {
      let AppID = Application_names.filter(
        (j) => j.Description == element.Application_Name
      ).map((c) => c.ITRequestItemTypeId);
      let reasonId = ResonMaster.filter(
        (f) => f.Description == element.reasonType
      ).map((a) => a.ITRequestReasonId);

      if (
        element.Application_Name != "" &&
        element.Access_Level != "" &&
        element.reasonType != "" &&
        element.Role != "" &&
        Rising !== ""
      ) {
        try {
          let notificationData = {
            CreatedDate: moment()
              .utcOffset("+05:30")
              .format("YYYY-MM-DDTHH:mm:ss"),
            CreatedBy: ID,
            NotifyTo: 500001,
            AudienceType: "Individual",
            Priority: "High",
            Subject: "Request for Access",
            Description: "Application Access Requested by  " + ID,
            IsSeen: "N",
            Status: "New",
          };

          const User_requestData = {
            RequestType: "AAR",
            RequestedBy: parseInt(ID),
            // ProjectId: 1,
            ClientId: clientid,
            ClientDetails: {
              Company: clientdetails[0].ClientDetails.Company,
              ContactName: clientdetails[0].ClientDetails.ContactName,
              ContactDesignation:
                clientdetails[0].ClientDetails.ContactDesignation,
              ContactEmail: clientdetails[0].ClientDetails.ContactEmail,
              ContactPhone: clientdetails[0].ClientDetails.ContactPhone,
            },
            AssignedGroup: "ITSM",
            AssignedTo: empdetails[0].ClientUserId,
            DepartmentCode: null,
            SubmittedDate: moment().format("yyyy-MM-DD"),
            ReasonCode: reasonId[0],
            ItemType: AppID[0],
            UserJustification: null,
            NewAssignee: null,
            ExpectedDate: null,
            ApprovalLevel1: 100005,
            Level1ApprovedDate: null,
            ApprovalLevel1Remarks: null,
            IsApprovedByLevel1: null,
            ApprovalLevel2: 600001,
            Level2ApprovedDate: null,
            ApprovalLevel2Remarks: null,
            IsApprovedByLevel2: null,
            IsOnhold: null,
            IsWithdrawn: null,
            WithdrawnDate: null,
            WithdrawnRemarks: null,
            Attachments: null,
            FulfillmentReference: null,
            IsActive: null,
            Status: null,
            UpdateNotes: [
              {
                NoteSno: null,
                DateTime: null,
                NoteUpdatedBy: null,
                NoteUpdatedByFN: null,
                NotesDetails: null,
              },
            ],
            ReferenceDetails: {
              ApplicationName: element.Application_Name,
              Reason: element.reasonType,
              Accesslevel: element.Access_Level,
              Role: element.Role,
            },
            NewAssigneeDetails: {
              EmpName: null,
              EmpLevel: null,
              EmpDesig: null,
            },
            IsSelfRequest: Rising,
            RaisingOnBehalfDetails:
              Rising === "N" ? parseInt(Employee[0].ClientUserId) : null,
            IsAcknowledged: null,
            SentForVerification: null,
            IsReworkRequired: null,
            IsRequestFulfilled: null,
            IsFullyApproved: null,
            IsRequireApproval: null,
          };
          ResetForm();
          RefreshDatas();

          axios
            .post(
              `agilesaas_itrequest_details?RequestedBy=eq.${ID}`,
              User_requestData
            )
            .then((_res) => {
              alert("Access Request Submitted");
              ResetForm();
              RefreshDatas();
            })
            .then((_res) => {
              // axios
              //   .post("notification?NotifyTo=eq." + 500001, notificationData)
              //   .then((res) => console.log(res))
              //   .catch((error) => console.log(error));
            });
          close();
          console.log(element);
        } catch (error) {
          console.log(error);
        }
      } else {
        alert("please select the field");
      }
    }
  }

  return (
    <div>
       <div className="row">
      <div className="mb-3">
        <label className="text-dark" style={{fontWeight:"bold"}}>Access to Application</label>
        </div>
        <Row>
          
      
        <ReactImageFallback
                                src={access}
                                
                                
                                
                           
                                initialImage="loader.gif"
                                alt="cool image should be here"
                               
                                 style={{ height: "200px", // Adjust the height to your desired size
                                 width: "200px", borderRadius: "50%" ,fontSize:"10px"}}
                              />
                              
                              <Col>
                              <div>
                              <label>
  <label className="labelStyle mr-1" style={{ fontWeight: "bold" }}>Access Request Purpose: </label>
  The request is made to seek permission for using specific applications or software within the organization.
</label>
                             
                              </div>
                              <div>
                              <label>
  <label className="labelStyle mr-1" style={{ fontWeight: "bold" }}>Access Necessity: </label>
  The request highlights the critical need for access, citing job-related tasks or project requirements.
</label>

                              
                              </div>
                              <div>
                              <label>
  <label className="labelStyle mr-1" style={{ fontWeight: "bold" }}>Initiation Request: </label>
  It prompts the recipient to provide necessary details to initiate the access granting process.
</label>
                          
                              </div>

                              </Col>
       </Row>
      
    <div className="mt-0">
      
      <form
        onSubmit={Access_submit}
        className="d-flex justify-content-start mt-5 "
      >
        <Row>
          <div className="AccessFormbox">
            <div className="d-flex justify-content-space-between">
              <div style={{marginTop:"250px"}}>
                <label>
                  <label
                    className="labelStyle ml-4"
                    style={{ display: "inline-block", marginTop: "11px" }}
                  >
                    Raising For
                  </label>{" "}
                  <input
                    type="radio"
                    value="Y"
                    className="ml-2 mt-3"
                    style={{ transform: "scale(1.3)" }}
                    checked={Rising === "Y"}
                    // defaultChecked={Rising === "Y"}
                    onChange={handleOptionChange}
                  />
                  <label className="ml-2 "> Self</label>
                </label>
                <label>
                  <input
                    type="radio"
                    value="N"
                    className="ml-2 mt-4 "
                    style={{ transform: "scale(1.3)" }}
                    checked={Rising === "N"}
                    onChange={handleOptionChange}
                  />
                  <label className="ml-2">Someone</label>
                </label>
              </div>
            </div>

            {risiingvalidation ? (
              <label className="text-danger">this field is required</label>
            ) : null}
          </div>
        </Row>

        <Row className="ml-4 mt-3">
          {Rising == "N" ? (
            <div className="ml-3 mt-2">
              <span className="labelStyle ">
                Raising On Behalf (provide Email#)
              </span>
              <div className="">
                <TextField
                  // type="text"
                  variant="outlined"
                  placeholder="Type email"
                  size="small"
                  required
                  value={Behalf_details}
                  onChange={(e) => setBehalf_details(e.target.value)}
                  className="SprintFormFields mt-1"
                />
                <Button
                  style={{
                    fontSize: 14,
                    padding: 9,
                    width: 60,
                    height: 20,
                    marginTop: 12,
                    borderRadius: 8,
                    marginLeft: 6,
                    marginRight: 14,
                    border: "2px solid #5250E5",
                    backgroundColor: "#5250E5",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "none",
                  }}
                  onClick={(e) => {
                    EmpDetailsGet(e);
                    setunclicked(true);
                  }}
                >
                  Fetch
                </Button>
                {Rising == "N" && Behalf_details !== "" ? Table : null}
              </div>
            </div>
          ) : null}
        </Row>
      </form>

      <Card className="mt-3">
        <div className="AccessrequestTable ">
          <table className="table ">
            <thead>
              <tr>
                <th className="labelStyle">Application Name</th>
                <th className="labelStyle">Reason Type</th>
                <th className="labelStyle">Access Level</th>
                <th className="labelStyle">Role</th>
                {/* <th className="text">Add</th> */}
                {/* <th className="text ml-5">Delete</th> */}
              </tr>
            </thead>
            <tbody>
              {addrows.map((e, i) => {
                let leveloption = Access_Level_roledata.filter(
                  (m) => m?.ApplicationKey == addrows[i]?.Application_Name
                ).map((z) =>
                  z.AccessTypesSupported.map((a) => {
                    return { value: a?.AccessName, label: a?.AccessName };
                  })
                );
                let roleoption = Access_Level_roledata.filter(
                  (r) => r.ApplicationKey == addrows[i].Application_Name
                ).map((t) =>
                  t.UserRolesSupported.map((a) => {
                    return { value: a.RoleName, label: a.RoleName };
                  })
                );

                return (
                  <tr key={i}>
                    <td>
                      <Select
                        id={i}
                        name={i}
                        required
                        styles={customStylesd}
                        maxMenuHeight={80}
                        value={Final_Application_level.filter(
                          (t) => t.value == e.Application_Name
                        )}
                        onChange={(w) => AppName(w, i)}
                        options={Final_Application_level}
                        className="react-select-default selectbbac"
                      />
                    </td>

                    <td>
                      <Select
                        id={i}
                        name={i}
                        required
                        styles={customStylesd}
                        maxMenuHeight={80}
                        value={Final_Access_level.filter(
                          (t) => t.value == e.reasonType
                        )}
                        options={Final_Access_level}
                        onChange={(q) => ReasonName(q, i)}
                        className="react-select-default selectbbac"
                      />
                    </td>

                    <td>
                      <Select
                        id={i}
                        name={i}
                        required
                        styles={customStylesd}
                        maxMenuHeight={80}
                        onChange={(v) => AccessLevelName(v, i)}
                        value={leveloption[0]?.filter(
                          (t) => t.value == e.Access_Level
                        )}
                        options={leveloption == "" ? "" : leveloption[0]}
                        className="react-select-default selectbbac"
                      />
                    </td>
                    <td>
                      <Select
                        id={i}
                        name={i}
                        maxMenuHeight={80}
                        styles={customStylesd}
                        onChange={(a) => RoleName(a, i)}
                        value={roleoption[0]?.filter((t) => t.value == e.Role)}
                        options={roleoption == "" ? "" : roleoption[0]}
                        className="react-select-default selectbbac"
                      />
                    </td>

                    <td>
                      <button
                        disabled={addrows.length === 1}
                        onClick={() => DeleteRows(i)}
                        className="btn btn-blueg "
                      >
                        <DeleteOutlinedIcon />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <button
            size="sm"
            onClick={() => Addrows()}
            className="btn btn-blueg justify-content-start "
          >
            <ControlPointIcon
              className="mr-1"
              style={{ color: "#039855", fontSize: "20px" }}
            />
            <label style={{ color: "#039855" }}> Add Addditonal row</label>
          </button>
        </div>
      </Card>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "right",
          marginTop: "15px",
        }}
      >
        <Button
          type="reset"
          style={{
            fontSize: 14,
            padding: 9,
            width: 100,
            height: 40,
            borderRadius: 8,
            marginRight: 14,
            border: "2px solid #5A88F1",
            backgroundColor: "#fff",
            color: "#5250E5",
            textAlign: "center",
            textTransform: "none",
          }}
          onClick={ResetForm}
        >
          Reset{" "}
        </Button>
        <Button
          type="submit"
          style={{
            fontSize: 14,
            padding: 9,
            width: 100,
            height: 40,
            borderRadius: 8,
            // marginRight: 14,
            border: "2px solid #5250E5",
            backgroundColor: "#5250E5",
            color: "#fff",
            textAlign: "center",
            textTransform: "none",
          }}
          onClick={(e) => Access_submit(e)}
        >
          Submit
        </Button>
      </div>
    </div>
    </div>
    </div>
  );
}

export default AccessRequestForm;
