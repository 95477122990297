import React, { useState } from 'react';
import './YourComponent.css'; // Make sure to import your CSS file
import Avatar from '@mui/material/Avatar';
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Popover from '@mui/material/Popover';
import Unassigned from "../../assets/images/avatar.png";

function Member({ index, name, avatar, position, quarterData, Lname }) {

    return (
        <div className="member mt-3">
            <>
                <img src={avatar ? avatar : Unassigned} alt={name} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                <h5 className='mt-3' >{name} </h5>
                {/* <p style={{fontSize : "8px"}}>{Lname}</p>                 */}
            </>
        </div >
    );
}

function TeamChart({ numQuarters, quartersData }) {
    const conicGradient = `conic-gradient(${generateConicStops(numQuarters, quartersData)})`;
    const quarterAngle = 360 / numQuarters;
    const [popoverStates, setPopoverStates] = useState(quartersData.map(() => false));

    const handlePopoverOpen = (index) => {
        const newPopoverStates = [...popoverStates];
        newPopoverStates[index] = true;
        setPopoverStates(newPopoverStates);
    };

    const handlePopoverClose = (index) => {
        const newPopoverStates = [...popoverStates];
        newPopoverStates[index] = false;
        setPopoverStates(newPopoverStates);
    };
    return (
        <div style={{ textAlign: 'center' }} >
            <div className="outer-dot" style={{ backgroundImage: conicGradient }}>
                {quartersData.map((quarterData, index) => {
                    const angle = quarterAngle * index + quarterAngle / (quartersData.length === 1 ? 2
                        : quartersData.length === 2 ? 1.2
                            : quartersData.length === 3 ? 1.2
                                : quartersData.length === 4 ? 1.5
                                    : quartersData.length === 5 || quartersData.length === 6 || quartersData.length === 7 ? 1.5
                                        : 2);
                    const position = `position-${index + 1}`;
                    const angle1 = quarterAngle * index + quarterAngle / (2);
                    const key = index;
                    return (
                        <>
                            {quarterData.members.slice(0, quartersData.length === 1 ? 10 : quartersData.length === 2 ? 4
                                : quartersData.length === 3 ? 4 : quartersData.length === 4 ? 2
                                    : quartersData.length === 5 || quartersData.length === 6
                                        || quartersData.length === 7 ? 1 : 0).map((member, index) => (

                                            <div
                                                className={`quarter ${position}`}
                                                key={index}
                                                // style={{ transform: `rotate(${index === 0 ? angle : index === 1 ? angle1 : index === 2 ? angle2 : angle3}deg)` }}
                                                // Rotate by 45 degrees to place members at the 45th degree

                                                style={{
                                                    transform: index === 0 && quartersData.length < 4 && quarterData.members.length === 1 ? `rotate(${angle1
                                                        }deg)` : quartersData.length === 1 ? `rotate(${index === 0 ? angle : index > 7 ? angle + (index * 40) + 90
                                                            : angle + (index * -30)}deg)`
                                                        : quartersData.length === 2 ? `rotate(${index === 0 ? angle
                                                            : angle + (index * -30)}deg)`
                                                            : quartersData.length === 3 ? `rotate(${index === 0 ? angle
                                                                : angle + (index * -20)}deg)`
                                                                : quartersData.length === 4 ? `rotate(${index === 0 ? angle
                                                                    : angle + (index * -23)}deg)`
                                                                    : quartersData.length === 5 || quartersData.length === 6 || quartersData.length === 7 ? `rotate(${index === 0 ? angle
                                                                        : angle + (index * -23)}deg)`
                                                                        : `rotate(${index === 0 ? angle
                                                                            : angle + (index * -23)}deg)`
                                                }}

                                            >
                                                <>
                                                    <Member
                                                        index={index}
                                                        name={member.name}
                                                        avatar={member.avatar}
                                                        position={position}
                                                        quarterData={quarterData}
                                                        Lname={member.Lname}
                                                    />
                                                    {quartersData.length <= 5 ?
                                                        <h4 className="title mt-5">{quartersData.length === 1 && index === 6 ||
                                                            quartersData.length === 2 && index === 2 ||
                                                            quartersData.length === 3 && index === 2 ||
                                                            quartersData.length === 4 && index === 1 ||
                                                            quartersData.length === 5 && index === 0 ? quarterData.title : quarterData.members.length === 1 ? quarterData.title : ""}</h4> :
                                                        <h5 className="para mt-5">{index === 0 ? quarterData.title : ""}</h5>}

                                                </>

                                            </div>))}


                            {(quartersData.length === 1 && quarterData.members.length > 10 ||
                                quartersData.length === 2 && quarterData.members.length > 5 ||
                                quartersData.length === 3 && quarterData.members.length > 4 ||
                                quartersData.length === 4 && quarterData.members.length > 2 ||
                                ((quartersData.length === 5 || quartersData.length === 6
                                    || quartersData.length === 7) && quarterData.members.length > 1)
                                || quartersData.length > 7 && quarterData.members.length > 0) ?
                                <div
                                    className={`quarter ${position}`}
                                    key={index}
                                    // style={{ transform: `rotate(${index === 0 ? angle : index === 1 ? angle1 : index === 2 ? angle2 : angle3}deg)` }}
                                    style={{
                                        transform: quartersData.length === 1 ? `rotate(${angle + (10 * -30)}deg)`
                                            : quartersData.length === 2 ? `rotate(${angle + (4 * -30)}deg)`
                                                : quartersData.length === 3 ? `rotate(${angle + (4 * -20)}deg)`
                                                    : quartersData.length === 4 ? `rotate(${angle + (2 * -23)}deg)`
                                                        : quartersData.length === 5
                                                            || quartersData.length === 6
                                                            || quartersData.length === 7
                                                            ? `rotate(${angle + (1 * -23)}deg)`
                                                            : `rotate(${angle + (0 * -23)}deg)`,
                                        maxWidth: "30px",
                                        zIndex: 4
                                    }}
                                    role='button'
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent event propagation
                                        if (popoverStates[index]) {
                                            handlePopoverClose(index);
                                        } else {
                                            handlePopoverOpen(index);
                                        }
                                    }}
                                >
                                    <div className="member mt-3"
                                    >
                                        <img
                                            src={"https://cdn4.iconfinder.com/data/icons/social-media-3/512/User_Group-512.png"}
                                            alt={"name"}
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%',
                                                cursor: "pointer",

                                            }}
                                        />
                                        {quartersData.length > 7 ? <h5 className="mt-5">{quarterData.title}</h5> :
                                            <h4 className='mt-2'>{"View"}</h4>}
                                    </div>
                                    <Popover
                                        id={index}
                                        open={popoverStates[index]} // Use the state to control popover visibility                                            
                                        // onClose={() =>// Close the popover                                      
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        className='scrollable-list-container'
                                    >
                                        {/* {quarterData.members.slice(quartersData.length === 3 ? 2 : quartersData.length > 4 ? 1 : 2, quarterData.members.length).map((member) => */}
                                        {quarterData.members.slice(0, quarterData.members.length).map((member) =>
                                        (<ListItem>
                                            <ListItemButton className="check-profile" sx={{ padding: 0 }} >
                                                <ListItemAvatar className="check-avatar">
                                                    <Avatar alt="" src={member.avatar} />
                                                </ListItemAvatar>
                                                <ListItemText className="fontdesign listitem">
                                                    {member.name + " " + member.Lname}
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>)
                                        )}
                                    </Popover>
                                </div > : <></>}
                        </>
                    );
                })}
                <div className="inner-circle" > <h1>Scrum Team</h1></div>
            </div>
        </div >
    );
}



function generateConicStops(numQuarters, quartersData) {
    const stops = [];
    for (let i = 0; i < numQuarters; i++) {
        const start = ((i / numQuarters) * 100);
        const end = (((i + 1) / numQuarters) * 100);
        const color = quartersData[i].color;
        if (numQuarters > 1) {
            stops.push(`${color} ${start}% ${end - 2}%`, `white ${end - 2}% ${end}% `)
        }
        else {
            stops.push(`${color} ${start}% ${end - 2}%`)
        }

    }
    return stops.join(', ');
}


export default TeamChart;
