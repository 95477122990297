import React, { useState } from 'react'
import { Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { Modal, Tab, Tabs } from "react-bootstrap";
import Axios from "axios";
import url from "../devEnj.json";
import Cookies from "universal-cookie";
import './DeployApp.css'
import { Button } from '@mui/material';

function Schedule({
    projectdata,
    projCode,
    ProjectSchData,
    // setProjectSchData,
    // showSchedule,
    // setState,
    // Refresh,
    schedulePopup,
    // pheath,
    // token,
    setSchedulePopup,
    projectValue,
    getProject
}) {
    console.log(ProjectSchData ? ProjectSchData.length : ProjectSchData, 'ProjectSchData')
    const initialState = {
        ProjectCode: projCode
            ? projCode
            : projectdata.projectCode,
        SchedulePeriod: "Daily",
        DailyOption: "Every",
        WeeklyWeekDays: [
            { id: 0, name: "Monday", value: "MO", isChecked: false },
            { id: 1, name: "Tuesday", value: "TU", isChecked: false },
            { id: 2, name: "Wednesday", value: "WE", isChecked: false },
            { id: 3, name: "Thursday", value: "TH", isChecked: false },
            { id: 4, name: "Friday", value: "FR", isChecked: false },
            { id: 5, name: "Saturaday", value: "SA", isChecked: false },
            { id: 6, name: "Sunday", value: "SU", isChecked: false },
        ],
        StartDate: new Date().setHours("00", "00", "00"),
        WeeklyEvery: "",
        IsEnd: true,
        EndBy: false,
        EndAfter: false,
        EndDate: new Date().setHours("00", "00", "00"),
        occurrence: "",
        UserMail: "",
        DailyEvery: "",
        VaildEndDate: true,
        vaildDailyEvery: true,
        vaildWeek: true,
        vaildoccurrence: true,
    };

    const [Schdata, setSchdata] = useState(initialState);
    const [edit, setEdit] = useState(false)
    let email = JSON.parse(localStorage.getItem("userdata"));
    let Userrole = JSON.stringify(localStorage.getItem('userRole'));

    const cookies = new Cookies();

    const handleChangeCheck = (name, value) => {
        let WeekDays = Schdata.WeeklyWeekDays;
        console.log(WeekDays, 'weekdays')
        WeekDays.forEach((Day) => {
            if (Day.name == name) {
                Day.isChecked = !Day.isChecked;
            }
        });
        setSchdata({ ...Schdata, WeeklyWeekDays: WeekDays });
    };
    const handleEndDate = (date) => {
        // Project end date should be greater than start date
        let startt = Schdata.StartDate;
        let differenceInDays =
            (date.getTime() - startt.getTime()) / (1000 * 3600 * 24);
        if (differenceInDays > 0) {
            setSchdata({
                ...Schdata,
                EndDate: date,
                VaildEndDate: true,
            });
        } else {
            setSchdata({
                ...Schdata,
                VaildEndDate: false,
            });
        }
    };
    const handleStartDate = (date) => {
        setSchdata({
            ...Schdata,
            StartDate: date,
        });
    };
    const handleChange = (e) => {
        console.log(e, 'test99')
        setSchdata({
            ...Schdata, ProjectCode: projCode
                ? projCode
                : projectdata.projectCode
        });
        if (e == "Daily") {
            setSchdata({ ...Schdata, SchedulePeriod: e });
        }
        else if (e == "Weekly") {
            setSchdata({ ...Schdata, SchedulePeriod: e });
        }
        else {
            switch (e.target.name) {
                case "occurrence":
                    if (e.target.value <= 0) {
                        setSchdata({ ...Schdata, vaildoccurrence: false });
                    } else {
                        setSchdata({ ...Schdata, occurrence: e.target.value });
                    }
                    break;
                case "WeeklyEvery":
                    if (e.target.value >= 5 || e.target.value <= 0) {
                        setSchdata({ ...Schdata, vaildDailyEvery: false });
                    } else {
                        setSchdata({
                            ...Schdata,
                            WeeklyEvery: e.target.value,
                            vaildDailyEvery: true,
                        });
                    }
                    break;
                case "DailyEvery":
                    if (e.target.value >= 5 || e.target.value <= 0) {
                        setSchdata({
                            ...Schdata,
                            DailyEvery: e.target.value,
                            vaildDailyEvery: false,
                        });
                    } else {
                        setSchdata({
                            ...Schdata,
                            DailyEvery: e.target.value,
                            vaildDailyEvery: true,
                        });
                    }
                    break;
                case "EndBy":
                    setSchdata({ ...Schdata, IsEnd: false, EndAfter: false, EndBy: true });
                    break;
                case "EndAfter":
                    setSchdata({ ...Schdata, IsEnd: false, EndAfter: true, EndBy: false });
                    break;
                case "IsEnd":
                    setSchdata({
                        ...Schdata,
                        IsEnd: !Schdata.IsEnd,
                        EndAfter: false,
                        EndBy: false,
                    });
                    break;
                case "Every":
                    setSchdata({ ...Schdata, DailyOption: e.target.value });
                    break;
                case "Weekdays":
                    setSchdata({ ...Schdata, DailyOption: e.target.value });
                    break;
                default:
                    break;
            }
        }
    };

    const AddSchedule = (e) => {
        console.log('schedule called')
        if (Schdata.SchedulePeriod == "Weekly") {
            let checked = Schdata.WeeklyWeekDays.some(function (e) {
                return e.isChecked;
            });
            if (Schdata.WeeklyEvery == "") {
                setSchdata({ ...Schdata, vaildDailyEvery: false });
                console.log('schedule called')
                toast.error("Date difference should be greater than 0 and less than 5");
            } else if (!checked) {
                setSchdata({ ...Schdata, vaildWeek: false });
                console.log('schedule called')
                toast.error("Any one Day should be checked");
            } else if (Schdata.EndAfter && Schdata.occurrence == "") {
                console.log('schedule called')
                setSchdata({ ...Schdata, vaildoccurrence: false });
                toast.error("Occurence value greater than one or required field");
            } else {
                // WeeklyEvery     
                setSchdata({
                    ...Schdata,
                    vaildWeek: true,
                    vaildDailyEvery: true,
                    vaildoccurrence: true,
                });
                console.log('schedule post')
                Axios({
                    url: `${url.server}/scheduled/add`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "email": email?.login,
                        "tenantId": localStorage.getItem("tenantId"),
                        Authorization: "Bearer " + cookies.get("token"),
                    },
                    method: "post",
                    data: {
                        ProjectCode: projectdata.projectCode
                            ? projectdata.projectCode
                            : projectdata.projCode,
                        SchedulePeriod: Schdata.SchedulePeriod,
                        DailyOption: Schdata.DailyOption,
                        WeeklyWeekDays: Schdata.WeeklyWeekDays,
                        StartDate: Schdata.StartDate,
                        WeeklyEvery: Schdata.WeeklyEvery,
                        IsEnd: Schdata.IsEnd,
                        EndBy: Schdata.EndBy,
                        EndAfter: Schdata.EndAfter,
                        EndDate: Schdata.EndDate,
                        occurrence: Schdata.occurrence,
                        UserMail: "",
                        DailyEvery: Schdata.DailyEvery,
                        VaildEndDate: true,
                        vaildDailyEvery: true,
                        vaildWeek: true,
                        vaildoccurrence: true
                    },
                })
                    .then((res) => {
                        setSchedulePopup(false)
                        getProject(projectValue)
                        toast.success("Scheduled successfully");

                    })
                    .catch((err) => console.log(err));
            }
        }
        if (Schdata.SchedulePeriod == "Daily") {
            if (Schdata.DailyOption == "Every") {
                if (Schdata.DailyEvery == "") {
                    setSchdata({ ...Schdata, vaildDailyEvery: false });
                    toast.error("Date difference should be greater than 0 and less than 5");
                } else if (Schdata.EndAfter && Schdata.occurrence == "") {
                    setSchdata({ ...Schdata, vaildoccurrence: false });
                    toast.error("Occurence value greater than one or required field");
                } else {
                    setSchdata({
                        ...Schdata,
                        vaildDailyEvery: true,
                        vaildoccurrence: true,
                    });
                    Axios({
                        url: `${url.server}/scheduled/add`,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "email": email?.login,
                            "tenantId": localStorage.getItem("tenantId"),
                            Authorization: "Bearer " + cookies.get("token"),
                        },
                        method: "post",
                        data: {
                            ProjectCode: projectdata.projectCode
                                ? projectdata.projectCode
                                : projectdata.projCode,
                            SchedulePeriod: Schdata.SchedulePeriod,
                            DailyOption: Schdata.DailyOption,
                            WeeklyWeekDays: Schdata.WeeklyWeekDays,
                            StartDate: Schdata.StartDate,
                            WeeklyEvery: Schdata.WeeklyEvery,
                            IsEnd: Schdata.IsEnd,
                            EndBy: Schdata.EndBy,
                            EndAfter: Schdata.EndAfter,
                            EndDate: Schdata.EndDate,
                            occurrence: Schdata.occurrence,
                            UserMail: "",
                            DailyEvery: Schdata.DailyEvery,
                        },
                    })
                        .then((res) => {


                            setSchedulePopup(false)
                            getProject(projectValue)
                            toast.success("Scheduled successfully");



                        })

                        .catch((err) => console.log(err));
                }
            } else {
                // Daily Every 
                Axios({
                    url: `${url.server}/scheduled/add`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "email": email?.login,
                        "tenantId": localStorage.getItem("tenantId"),
                        Authorization: "Bearer " + cookies.get("token"),
                    },
                    method: "post",
                    data: {
                        ProjectCode: projectdata.projectCode
                            ? projectdata.projectCode
                            : projectdata.projCode,
                        SchedulePeriod: Schdata.SchedulePeriod,
                        DailyOption: Schdata.DailyOption,
                        WeeklyWeekDays: Schdata.WeeklyWeekDays,
                        StartDate: Schdata.StartDate,
                        WeeklyEvery: Schdata.WeeklyEvery,
                        IsEnd: Schdata.IsEnd,
                        EndBy: Schdata.EndBy,
                        EndAfter: Schdata.EndAfter,
                        EndDate: Schdata.EndDate,
                        occurrence: Schdata.occurrence,
                        UserMail: "",
                        DailyEvery: Schdata.DailyEvery,
                    },
                })
                    .then((res) => {
                        setSchedulePopup(false)
                        getProject(projectValue)
                        toast.success("Scheduled successfully");

                    })
                    .catch((err) => console.log(err));
            }
        }
        setTimeout(() => {
            // Refresh();
        }, 1000);
    };
    const editHandle = (e) => {
        setEdit(true)
        setSchdata({
            ProjectCode: ProjectSchData.ProjectCode,
            SchedulePeriod: ProjectSchData.SchedulePeriod,
            DailyOption: ProjectSchData.DailyOption,
            WeeklyWeekDays: ProjectSchData.WeeklyWeekDays,
            StartDate: new Date(ProjectSchData.StartDate),
            WeeklyEvery: ProjectSchData.WeeklyEvery,
            IsEnd: ProjectSchData.IsEnd,
            EndBy: ProjectSchData.EndBy,
            EndAfter: ProjectSchData.EndAfter,
            EndDate: new Date(ProjectSchData.StartDate),
            occurrence: ProjectSchData.Occurrence,
            UserMail: ProjectSchData.UserMail,
            DailyEvery: ProjectSchData.DailyEvery,
            VaildEndDate: true,
            vaildDailyEvery: true,
            vaildWeek: true,
            vaildoccurrence: true,
        });

    };
    const DeleteSchedule = (e) => {
        Axios({
            url: `${url.server}/scheduled/deleteSchedule?ProjectCode=${ProjectSchData.ProjectCode}`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "email": email?.login,
                "tenantId": localStorage.getItem("tenantId"),
                Authorization: "Bearer " + cookies.get("token"),
            },
            method: "post",
        })
            .then((res) => {
                // setState();
                // Refresh();
                toast.success("Schedule deleted successfully");
                setSchedulePopup(false)
                getProject(projectValue)
            })
            .catch((err) => console.log(err));
    };

    // if (pheath != 1) {
    //     return (
    //         <Modal
    //             size={ProjectSchData == null ? "md" : "sm"}
    //             show={showSchedule}
    //             onHide={() => setState(false)}
    //         >
    //             <Modal.Header closeButton>
    //                 <Modal.Title>
    //                     Schedule Deployment (
    //                     {projectdata.projectName
    //                         ? projectdata.projectName
    //                         : projectdata.projName}
    //                     )
    //                 </Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body>
    //                 <div>
    //                     <p className="alert alert-danger inc-deploy mt-3 ">
    //                         Oops!! Seems to be some issue in connecting to the host / virtual
    //                         machine.You can't perform deployment or schedule for this project
    //                     </p>
    //                 </div>
    //             </Modal.Body>
    //         </Modal>
    //     );
    // } 
    // else {
    const radioStyle = {
        appearance: 'none',
        width: '14px',
        height: '14px',
        border: '2px solid #4263EB',
        padding: 2,
        borderRadius: '50%',
        outline: 'none',
        cursor: 'pointer',
        // boxShadow: '2px 2px 2px 2px #4263EB'
        // backgroundColor: '#4263EB',
    };

    const checkedRadioStyle = {
        ...radioStyle,
        backgroundColor: '#4263EB', // Change this color to your desired color for the selected radio button
        // boxShadow: '1px 1px 1px 1px red',
        // padding: ,
        border: '2px solid #4263EB',
    };
    return (



        <div>
            {ProjectSchData && ProjectSchData.length != 0 && !edit ? (
                <>
                    <div>
                        <div className="row">
                            <div className="col-lg-6">
                                <label>{ProjectSchData?.SchedulePeriod}</label>
                            </div>
                            {ProjectSchData?.SchedulePeriod == "Weekly" ? (
                                <>
                                    <div className="col-lg-6">
                                        <h4 style={{ fontWeight: "bold" }}>
                                            Week frequency
                                        </h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>{ProjectSchData?.WeeklyEvery}</label>
                                    </div>
                                    <div className="col-lg-6">
                                        <h4 style={{ fontWeight: "bold" }}>
                                            Days for Every Week
                                        </h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>
                                            {ProjectSchData?.WeeklyWeekDays.filter(
                                                (e) => e.isChecked
                                            ).map((e) => (
                                                <>{e.name + " "}</>
                                            ))}
                                        </label>
                                    </div>

                                    {ProjectSchData?.EndAfter ? (
                                        <>
                                            <div className="col-lg-6">
                                                <h4 style={{ fontWeight: "bold" }}>
                                                    Occurence{" "}
                                                </h4>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>{ProjectSchData?.Occurrence}</label>
                                            </div>{" "}
                                        </>
                                    ) : null}
                                    {ProjectSchData?.EndBy ? (
                                        <>
                                            <div className="col-lg-6">
                                                <h4 style={{ fontWeight: "bold" }}>End By </h4>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>
                                                    {
                                                        ProjectSchData?.EndDate
                                                    }
                                                </label>
                                            </div>{" "}
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <div className="col-lg-6">
                                        <h4 style={{ fontWeight: "bold" }}>Daily option</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>{ProjectSchData?.DailyOption}</label>
                                    </div>
                                    <div className="col-lg-6">
                                        <h4 style={{ fontWeight: "bold" }}>
                                            Days frequency
                                        </h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>
                                            {ProjectSchData?.DailyEvery != null
                                                ? ProjectSchData?.DailyEvery + " " + "days"
                                                : "Monday to Friday"}
                                        </label>
                                    </div>
                                    {ProjectSchData?.EndAfter ? (
                                        <>
                                            <div className="col-lg-6">
                                                <h4 style={{ fontWeight: "bold" }}>
                                                    Occurence{" "}
                                                </h4>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>{ProjectSchData?.Occurrence}</label>
                                            </div>{" "}
                                        </>
                                    ) : null}
                                    {ProjectSchData?.EndBy ? (
                                        <>
                                            <div className="col-lg-6">
                                                <h4 style={{ fontWeight: "bold" }}>End By </h4>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>
                                                    {
                                                        ProjectSchData?.EndDate
                                                    }
                                                </label>
                                            </div>{" "}
                                        </>
                                    ) : null}
                                    <div className="col-lg-6">
                                        <h4 style={{ fontWeight: "bold" }}>
                                            Next Deployment Date
                                        </h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>
                                            {
                                                ProjectSchData?.NextDeployment
                                            }
                                        </label>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                        <Button
                            onClick={() => editHandle()}
                            style={{ fontSize: 16, padding: 15, width: 100, height: 44, borderRadius: 8, marginRight: 14, border: '2px solid #5250E5', backgroundColor: '#fff', color: '#5250E5', textAlign: 'center' }}
                        >Edit Schedule</Button>
                        <Button
                            onClick={() => DeleteSchedule()}
                            style={{ fontSize: 16, padding: 15, width: 100, height: 44, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center' }}
                        >Delete Schedule</Button>
                    </div>
                </>
            ) : (
                <>
                    {console.log(Schdata.SchedulePeriod, 'schdata')}
                    <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', marginBottom: 20, marginTop: 20 }}>
                        <Button
                            onClick={(e) => handleChange("Daily")}
                            style={{ fontSize: 25, fontWeight: 'bold', padding: 15, width: '45%', height: 70, border: Schdata.SchedulePeriod === "Daily" ? '2px solid #5250E5' : "#fff", boxShadow: '0px 1px 4px 0px #dcdcdc', backgroundColor: '#fff', borderRadius: 8, color: 'black', textTransform: 'none' }}
                        >Daily</Button>
                        <Button
                            onClick={(e) => handleChange("Weekly")}
                            style={{ fontSize: 25, fontWeight: 'bold', padding: 15, width: '45%', height: 70, border: Schdata.SchedulePeriod === "Weekly" ? '2px solid #5250E5' : "#fff", boxShadow: '0px 1px 4px 0px #dcdcdc', backgroundColor: '#fff', borderRadius: 8, color: 'black', textTransform: 'none' }}
                        >Weekly</Button>
                    </div>
                    {Schdata.SchedulePeriod === "Daily" ? (
                        <div style={{ marginLeft: 20 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                                <label className="form-check-label" style={{ marginRight: 20, color: 'black' }}>
                                    <input
                                        type="radio"
                                        value="Every"
                                        onChange={(e) => handleChange(e)}
                                        name="Every"
                                        style={Schdata.DailyOption === "Every" ? checkedRadioStyle : radioStyle}
                                        checked={Schdata.DailyOption === "Every"}
                                        className="form-check-input"
                                    />
                                    Every Day
                                </label>
                                {Schdata.DailyOption == "Every" ?
                                    <><input
                                        style={{ borderBottomWidth: 2, borderColor: 'black', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, marginRight: 9 }}
                                        type="number"
                                        min={1}
                                        max={4}
                                        name="DailyEvery"
                                        onChange={(e) => handleChange(e)}
                                        value={Schdata.DailyEvery}
                                    />
                                        day's
                                    </>
                                    :
                                    null
                                }
                            </div>
                            <div >
                                <label className="form-check-label" style={{ color: 'black' }}>
                                    <input
                                        style={Schdata.DailyOption === "Every Weekday" ? checkedRadioStyle : radioStyle}
                                        type="radio"
                                        value="Every Weekday"
                                        name="Weekdays"
                                        onChange={(e) => handleChange(e)}
                                        checked={
                                            Schdata.DailyOption === "Every Weekday"
                                        }
                                        className="form-check-input"
                                    />
                                    Every Weekday
                                </label>
                            </div>
                        </div>
                    ) : null}
                    {Schdata.SchedulePeriod === "Weekly" ? (
                        <div >
                            <label style={{ marginRight: 20, color: 'black' }}>Every Week</label>
                            <input
                                style={{ borderBottomWidth: 2, borderColor: 'black', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, marginRight: 9 }}
                                type="number"
                                min={1}
                                max={4}
                                name="WeeklyEvery"
                                value={Schdata.WeeklyEvery}
                                onChange={(e) => handleChange(e)}
                            />{" "}
                            day's
                            {Schdata.vaildDailyEvery ? null : (
                                <div>
                                    <p style={{ color: "red" }}>
                                        Date difference should be greater than 0 and
                                        less than 5
                                    </p>
                                </div>
                            )}
                            <br />
                            <br />
                            {Schdata.WeeklyWeekDays.map((e) => (
                                <div
                                    style={{ display: "inline-flex" }}
                                >
                                    <label
                                        onClick={() => { handleChangeCheck(e.name, e.value) }}
                                        className="form-check-label"
                                        style={{ backgroundColor: '#fff', color: 'black', padding: 3, borderRadius: 8, width: 51, textAlign: 'center', marginRight: 5, border: e.isChecked ? '2px solid #4263EB' : '2px solid #dcdcdc', fontSize: 14 }}>
                                        {/* <Input
                                            type="checkbox"
                                            id={e.id}
                                            value={e.name}
                                            name="Weekdays"
                                            onChange={(e) => handleChangeCheck(e)}
                                            checked={e.isChecked}
                                            className="form-check-input"
                                        /> */}
                                        {/* <i className="input-helper"></i> */}
                                        {e.name == "Monday" ? "Mon" : e.name == "Tuesday" ? "Tue" : e.name == "Wednesday" ? "Wed" : e.name == "Thursday" ? "Thu" : e.name == "Friday" ? "Fri" : "Sat"}
                                    </label>
                                </div>
                            ))}
                            {Schdata.vaildWeek ? null : (
                                <div>
                                    <p style={{ color: "red" }}>
                                        Any one Day should be checked
                                    </p>
                                </div>
                            )}
                        </div>
                    ) : null}
                    <div style={{ marginTop: 20 }}>
                        <h5 style={{ fontWeight: '600', marginBottom: 15 }}>Range of Starting and Ending</h5>
                        <div style={{ marginLeft: 2, display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                            <label style={{ marginRight: 20, color: 'black' }}>Start Date</label>
                            <DatePicker
                                style={{ borderTopWidth: 0, backgroundColor: 'red' }}
                                locale="IN"
                                showTimeSelect
                                selected={Schdata.StartDate}
                                timeFormat="HH:mm"
                                timeIntervals={20}
                                timeCaption="time"
                                calendarContainer="true"
                                minDate={0}
                                minTime={0}
                                className='datepicker'
                                // MMMM d, yyyy h:mm aa dd/MM/yyyy h:mm aa
                                dateFormat="dd/MM/yyyy h:mm aa"
                                onChange={(e) => handleStartDate(e)}
                            />
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <label style={{ marginLeft: 2, marginBottom: 15, color: 'black' }}>
                                <Input
                                    style={Schdata.IsEnd ? checkedRadioStyle : radioStyle}
                                    type="radio"
                                    value="true"
                                    onChange={(e) => handleChange(e)}
                                    name="IsEnd"
                                    checked={Schdata.IsEnd}
                                    className="form-check-input"
                                />
                                No End Date
                            </label>
                            <div style={{ marginLeft: 2, display: 'flex', flexDirection: 'row', marginBottom: 9, width: '100%' }}>
                                <label style={{ marginRight: 7, color: 'black' }}>
                                    <Input
                                        type="radio"
                                        style={Schdata.EndAfter ? checkedRadioStyle : radioStyle}
                                        value="true"
                                        onChange={(e) => handleChange(e)}
                                        name="EndAfter"
                                        checked={Schdata.EndAfter}
                                        className="form-check-input"
                                    />
                                    End After
                                </label>
                                {Schdata.EndAfter ? (
                                    <input
                                        style={{ borderBottomWidth: 2, borderColor: 'black', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, marginRight: 9 }}
                                        type="number"
                                        name="occurrence"
                                        max="20"
                                        min="1"
                                        value={Schdata.occurrence}
                                        onChange={(e) => handleChange(e)}
                                    />

                                ) : null}
                                {Schdata.EndAfter ? <label>occurrences</label> : ""}
                            </div>


                            <div style={{ marginLeft: 2, display: 'flex', flexDirection: 'row', marginBottom: 9 }}>
                                <label style={{ marginRight: 7, color: 'black' }}>
                                    <Input
                                        type="radio"
                                        style={Schdata.EndBy ? checkedRadioStyle : radioStyle}
                                        value="true"
                                        onChange={(e) => handleChange(e)}
                                        name="EndBy"
                                        checked={Schdata.EndBy}
                                        className="form-check-input"
                                    />
                                    End By
                                </label>
                                {Schdata.EndBy ? (
                                    <>
                                        <DatePicker
                                            // className="form-control w-30"
                                            style={{ borderBottomWidth: 2, borderColor: 'black', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, marginRight: 9 }}
                                            selected={Schdata.EndDate}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(e) => handleEndDate(e)}
                                            className='datepicker'
                                        />
                                    </>
                                ) : null}
                            </div>

                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                        <Button
                            onClick={() => setSchedulePopup(false)}
                            style={{ fontSize: 16, padding: 15, width: 100, height: 44, borderRadius: 8, marginRight: 14, border: '2px solid #5250E5', backgroundColor: '#fff', color: '#5250E5', textAlign: 'center', textTransform: 'none' }}
                        >Cancel</Button>
                        <Button
                            onClick={AddSchedule}
                            style={{ fontSize: 16, padding: 15, width: 100, height: 44, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center', textTransform: 'none' }}
                        >{edit ? "Edit" : "Create"}</Button>
                    </div>
                </>
            )}
        </div>



    );
    // }

}

export default Schedule