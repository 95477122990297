import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";
import filesAxios from "../../filesAxios";

export const fetchauthById = createAsyncThunk(
  "twofactor/data",
  async (Empid, { rejectWithValue }) => {
    try {
      let data = await axios.get(`employee_twofactor_auth?EmpId=eq.${Empid}`);
      let responce = await data.data;
      return responce;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const Twofactor = createSlice({
  name: "twofactor",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    mainpage: false,
  },
  reducers: {
    CommonPostHandler: (state, action) => {
      filesAxios
        .post("twofactauth", action.payload)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return "not created";
        });
    },
  },
  extraReducers: {
    [fetchauthById.pending]: (state) => {
      state.loading = true;
    },
    [fetchauthById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.mainpage = payload.length > 0 ? true : false;
      state.isSuccess = true; 
    },
    [fetchauthById.rejected]: (state) => {
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export const { CommonPostHandler } = Twofactor.actions;

export default Twofactor.reducer;
