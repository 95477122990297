import axios from "axios";
import Cookies from "universal-cookie";

const instance = axios.create({
  baseURL: "https://api.devozy.ai/",
  headers: {
    "Content-Type": "application/json",
  },
});
const cookies = new Cookies();
if (cookies.get("token") == undefined) {
  localStorage.clear();
}

instance.defaults.headers.common["Authorization"] =
  "Bearer " + cookies.get("token");
export default instance;
