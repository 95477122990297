import moment from "moment";
import axios from "./axios";

export default async function Mailandnotification(
  e,
  tomailid,
  ccemailid,
  webnotify,
  emailnotify,
  projectname,
  EmailSubject,
  webappdescri
) {
  let empid = JSON.parse(localStorage.getItem("Empdetails"));
  let clientdetails = JSON.parse(localStorage.getItem("clientdetails"));
  const userorg = clientdetails[0]?.OrgDetails;
  let arr =
    sessionStorage.getItem("Roledetails") == "undefined"
      ? ""
      : JSON.parse(sessionStorage.getItem("Roledetails"));
  let requrireddata = {
    ClientUserId: empid?.[0]?.ClientUserId,
    ClientId: empid?.[0]?.ClientId,
    ClientDetails: {
      Company: userorg?.Company,
      ContactName: userorg?.ContactName,
      ContactDesignation: userorg?.ContactDesignation,
      ContactEmail: userorg?.ContactEmail,
      ContactPhone: userorg?.ContactPhone,
    },
    DepartmentDetails: empid?.[0]?.DepartmentDetails,
    DepartmentCode: null,
    TentantId: "",
    FromSenderId: "no-reply@yozytech.com",
    ToEmailIds: {
      emailids: tomailid,
    },
    CcEmailIds: {
      emailids: ['agileteam@yozytech.com'],
    },
    BccEmailIds: "",
    EmailType: "StoryAssignment",
    ProductDetails: {
      ProdId: "11004",
      ProdName: "KANBAN",
      Domain: "KANBAN.yozytech.com",
    },
    EmailSubject: EmailSubject,
    IsSuccess: "Y",
    IsPartiallyFailed: " ",
    SendRetryHistory: "",
    IsActive: " ",
    Status: "",
    EmailTemplateName: "StoryAssignment",
    emailnotify: emailnotify,
    webnotify: webnotify,
    EmailTemplateVariables: {
      xxasignbyxx: arr.RoleName,
      xxassigndatexx: moment().format("YYYY-MM-DD"),
      xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
      xxprjdisxx: e?.Description,
      xxprjnamexx: projectname,
      xxstoryNamexx: e.IssueTitle,
      xxprjpercenxx: "",
      xxprjstartdatexx: "",
      xxprjenddataexx: "",
      xxteamnamexx: "",
      xxsprintxx: "",
      xxmanagernamexx: userorg?.ContactName,
      xxcompanyname: userorg?.Company,
      xxmanagerdesigxx: "Scrum Master",
      xxgmailxx: userorg?.ContactEmail,
      xxphnoxx: userorg?.ContactPhone,
      webappdescri: webappdescri,
    },
  };
  console.log(requrireddata, "rrrrrrrrrrrrrrrrrrrr");
  try {
    axios({
      url: "https://api.devozy.ai/api/agile/email",
      method: "post",
      data: requrireddata,
      headers: {
        origin: "https://kanban.yozytech.com",
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error(error);
  }
}
