import { Card, Checkbox, TextField } from "@material-ui/core";

import axios from "../../../axios";
import React, { useEffect, useState } from "react";

import { Col, Row, Container, CardBody, Table } from "reactstrap";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { toast, Slide } from "react-toastify";
import moment from "moment";
import { Input } from "reactstrap";
import "./Editproject.css";
import { Button } from "@mui/material";
import ProjectModules from "./ProjectModules";
import { useSelector, useDispatch } from "react-redux";
import { HANDLE_ON_CHANGE } from "../../../redux/CommonInitialsate";

const Editproject = ({
  rowData,
  row,
  // setProjectdetails,
  // projectdetails,
  close,
  setedit,
  modules,
  setModules,
}) => {
  const { KanbanBoardData } = useSelector((state) => state.user);
  let userdetails =
    localStorage.getItem("clientdetails") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("clientdetails"));
  const userorg = userdetails?.[0]?.OrgDetails;
  const userdet = userdetails?.[0]?.UserDetails;
  let id =
    localStorage.getItem("clientid") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("clientid"));
  let rolecode =
    localStorage.getItem("Rolecode") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Rolecode"));

  const [projectname, setprojectname] = useState(rowData?.ProjectName);
  const [startdate, setstartdate] = useState(rowData?.StartDate);
  const [enddate, setenddate] = useState(rowData?.EndDate);
  const [error, seterror] = useState("");
  const [checkSubmit, setSubmitCheck] = useState(false);
  const dispatch = useDispatch();

  function submit() {
    // modules.forEach(module => {
    //   // Check if both Name and Type are filled
    //   if (module.Name && module.Type) {
    //     // Combine Name and Type into FullName
    //     module.FullName = module.FullName ? module.FullName : `${module.Name}_${module.Type}`;
    //   }
    // })
    let patchdata = {
      // ProjectShortCode: projectname.slice(0, 5),
      ProjectName: projectname,
      // ClientId: id,
      // ClientDetails: {
      //   Company: userorg?.Company,
      //   ContactName: userorg?.ContactName,
      //   ContactDesignation: userorg?.ContactDesignation,
      //   ContactEmail: userorg?.ContactEmail,
      //   ContactPhone: userorg?.ContactPhone,
      // },
      // CreatedDate: moment().format("YYYY-MM-DD"),
      // CreatedBy: userdetails[0].UserId,
      // CreatedByDetails: {
      //   Name: userorg?.ContactName,
      //   Role: rolecode,
      //   Email: userdet?.Email,
      //   Phone: userorg?.ContactPhone,
      // },
      StartDate: moment(startdate).format("YYYY-MM-DD"),
      EndDate: moment(enddate).format("YYYY-MM-DD"),
      ProjectModules: modules,
      // IsActive: "Y",
      // Status: null,
    };
    if (moment(startdate) >= moment(enddate)) {
      seterror("StartDate should be lower than EndDate");
    } else if (!modules.every((module) => module.Name && module.Type)) {
      toast.error(`Please enter all the module details `, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 8000,
        draggable: true,
      });
    } else if (
      modules.length > 0 &&
      [...new Set(modules?.map((item) => item.FullName))]?.length !=
      modules?.length
    ) {
      toast.error(`Please provide each module's full name as a unique name. `, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 8000,
        draggable: true,
      });
    } else if (moment(enddate)) {
      setSubmitCheck(true);
      axios
        .patch("agilesaas_project_master?ProjectId=eq." + rowData.id, patchdata)

        .then((res) => {
          axios
            .get(
              `agilesaas_incident_category_master?CategoryName=eq.${rowData?.ProjectName}`
            )
            .then((res) => {
              if (res.data.length > 0) {
                // const cid = res?.data?.[0]?.IncidentCategoryId
                // createcategory(projectnames, cid)
              }
            });
          if (res) {
            toast.success(`Edited successfully`, {
              position: toast.TOP_RIGHT,
              autoClose: 3000,
              draggable: true,
            });
            // seterror("");
            refresh();
            close();
            setSubmitCheck(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  function refresh() {
    axios.get(`agilesaas_project_master?ClientId=eq.` + id).then((res) => {
      // setProjectdetails(res.data);
      dispatch(
        HANDLE_ON_CHANGE({ name: "projectdetails", value: res.data })
      );
    });
  }
  const handleprojectname = (e) => {
    let text = e.target.value;

    setprojectname(text);
  };
  function cardclose() {
    close();
  }

  const checkname = KanbanBoardData?.filter(
    (x) => x.ProjectId == rowData?.id
  )?.length;

  return (
    <div>
      {/* <Table
        responsive
            striped
            bordered
            hover
            className="position-relative"
       >
         <thead  id="report">
              <tr>
                <th className="text">Project Name</th>
                <th className="text">Start Date</th>
                <th className="text">End Date</th>
              
              </tr>
            </thead>
    
          <tr>
         
              <td>
              <TextField
                      variant="outlined"
                      className="mytextRelease"
                      size="small"
                      style={{maxWidth:"150px"}}
                      value={projectname}
                    
                      onChange={(e)=>handleprojectname(e)}
                      type="text"
                      inputProps={{ autoComplete: "none" }}
                      // helperText={error}
                      // disabled
                    />
              </td>        
              <td>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  varient="dialog"
                  // format="dd/MM/yyyy"
                  margin="normal"
                  name="project start date"
                  value={startdate}
                  onChange={(date) => setstartdate(date)}
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  className=""
                  style={{ width: "170px" }}
                  error={error}
                  // shouldDisableDate={disableWeekends}
                  
                  helperText={error}
                  required
                />
              </MuiPickersUtilsProvider>
              </td>    
              <td>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  varient="dialog"
                  // format="dd/MM/yyyy"
                  margin="normal"
                  name="project start date"
                  value={enddate}
                  onChange={(date) => setenddate(date)}
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  className=""
                  style={{ width: "170px" }}
                  // shouldDisableDate={disableWeekends}
                  // disablePast
                  required
                />
              </MuiPickersUtilsProvider>
              </td>
            
          </tr>
          
         
                 

        </Table>
        <div style={{marginLeft:"500px"}}>
          <Button
                    className="Card_Button_Color_Approve btn-greeng " 
                    onClick={submit}
                    
                  >
                    Edit
                  </Button>
                  </div> */}
      <Row className="mb-3 mr-1 ml-1">
        <label className="SprintlabelStyle">
          Project Name
          <label className="createAlert">*</label>
        </label>
        <TextField
          variant="outlined"
          className="inpui"
          size="small"
          // className="FormFields"
          value={projectname}
          onChange={(e) => handleprojectname(e)}
          disabled={checkname > 0}
        />
      </Row>
      <ProjectModules
        modules={modules}
        setModules={setModules}
        projectname={projectname}
        projectid={rowData?.id}
      />
      <Row className="mb-3 ml-1 mr-1">
        <label className="SprintlabelStyle">
          Start Date
          <label className="createAlert">*</label>
        </label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            varient="dialog"
            // format="dd/MM/yyyy"

            name="project start date"
            value={startdate}
            onChange={(date) => setstartdate(date)}
            format="dd-MM-yyyy"
            views={["year", "month", "date"]}
            className=""
            style={{ width: "100%" }}
            error={error}
            // shouldDisableDate={disableWeekends}

            helperText={error}
            required
          />
        </MuiPickersUtilsProvider>
      </Row>
      <Row className="mb-3 ml-1 mr-1">
        <label className="SprintlabelStyle">
          End Date
          <label className="createAlert">*</label>
        </label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            varient="dialog"
            // format="dd/MM/yyyy"

            name="project start date"
            value={enddate}
            onChange={(date) => setenddate(date)}
            format="dd-MM-yyyy"
            views={["year", "month", "date"]}
            className=""
            style={{ width: "100%" }}
            // shouldDisableDate={disableWeekends}
            // disablePast
            required
          />
        </MuiPickersUtilsProvider>
      </Row>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "right",
        }}
      >
        <Button
          onClick={() => cardclose()}
          style={{
            fontSize: 14,
            padding: 9,
            width: 100,
            height: 40,
            borderRadius: 8,
            marginRight: 14,
            border: "2px solid #5250E5",
            backgroundColor: "#fff",
            color: "#5250E5",
            textAlign: "center",
            textTransform: "none",
          }}
        >
          Cancel
        </Button>
        <Button
          style={{
            fontSize: 14,
            padding: 9,
            width: 100,
            height: 40,
            borderRadius: 8,
            backgroundColor: "#5250E5",
            color: "#fff",
            border: "0px solid #5A88F1",
            textAlign: "center",
            textTransform: "none",
          }}
          disabled={checkSubmit}
          onClick={() => submit()}
        >
          {checkSubmit ? (
            <span
              className="spinner-border"
              role="status"
              aria-hidden="true"
              style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
            ></span>
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </div>
  );
};
export default Editproject;
