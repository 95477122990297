
import * as React from "react";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import OfflineBoltRoundedIcon from "@material-ui/icons/OfflineBoltRounded";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { Bookmark } from '@mui/icons-material';
import '../../App.css'
import { Avatar } from '@mui/material';
import "./Showrelease.css";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useSelector } from 'react-redux'
import MaterialTable from 'material-table';
import Unassigned from "../../assets/images/avatar.png";
import PatchedPagination from '../../PatchedPagination';


export default function EpiccloseTable() {

    const { allImage, epicCloseData } = useSelector((state) => state.user)




    const Columns1 = [
        {
            title: 'Ticket reference ',
            field: "IssueType",
            // cellStyle: { width: "25%" },
        },
        {
            title: "Summary", field: "IssueTitle",
            // cellStyle: { width: "25%" },
        },

        {
            title: "Assignee", field: "Assignedid",
        },
        {
            title: "Stages", field: "Stage",
        },

    ];

    return (
        <MaterialTable
            title={""}
            columns={Columns1.map((e) => e)}
            components={{
                Pagination: PatchedPagination,
            }}
            data={epicCloseData?.linkedissues?.map((e) => {
                return {
                    ...e,
                    IssueType: <label style={{ whiteSpace: 'nowrap', fontSize: 14 }}>
                        {e.Priority === "Highest" ? (<span><KeyboardDoubleArrowUpIcon className="filter-icons-1" fontSize="small" /></span>)
                            : e.Priority === "High" ? (<span><KeyboardArrowUpIcon className="filter-icons-1" fontSize="small" /></span>)
                                : e.Priority === "Medium" ? (<span><DragHandleIcon className="filter-icons" fontSize="small" /></span>)
                                    : e.Priority === "Low" ? (<span><KeyboardArrowDownIcon className="filter-icons-2" fontSize="small" /></span>)
                                        : e.Priority === "Lowest" ? (<span><KeyboardDoubleArrowDownIcon className="filter-icons-2" fontSize="small" /></span>)
                                            : ("")}
                        {/* {e.Priority} */}
                        {/* </label>
                  <label style={{ width: '10%' }}> */}
                        {
                            e.IssueType == "Story" ? <Bookmark className="story fontdesign" style={{ fontSize: '16px' }} /> :
                                e.IssueType == 'Task' ? <CheckBoxRoundedIcon className="task fontdesign" style={{ fontSize: '16px' }} /> :
                                    e.IssueType == "Bug" ? <StopCircleIcon style={{ fontSize: '16px' }} className="bug fontdesign" /> :
                                        e.IssueType == "Epic" ? <OfflineBoltRoundedIcon style={{ fontSize: '16px' }} className="epic fontdesign" /> :
                                            e.IssueType == 'SubTask' ? <AddToPhotosIcon style={{ fontSize: '16px' }} className="kanbanicon task" /> :
                                                ""
                        }
                        <label style={{ color: '#198ae3', marginLeft: '8px', whiteSpace: 'pre-wrap' }} sx={{ width: 25, height: 25 }}>
                            {e.ProjectDetails?.[0]?.ProjName + " - " + e.IssueId}</label>
                        {/* {e.IssueType} */}
                    </label>,
                    IssueTitle: <label style={{ fontSize: 14, color: 'black' }}>{e.IssueTitle}</label>,
                    Assignedid: <div style={{ display: "flex", alignItems: "center" }}>
                        {/* {console.log(e.AssignedToDetails, "assignedto details")} */}
                        <Avatar
                            style={{ width: 30, height: 30 }}
                            alt="" src={allImage?.length > 0 && allImage?.filter((x) => x.teammemberid == e.AssignedTo)?.[0]?.image != undefined ? allImage?.filter((x) => x.teammemberid == e.AssignedTo)?.[0]?.image : Unassigned} />
                        &nbsp;
                        <label style={{ fontSize: 14, color: 'black' }}>{e.AssignedToDetails == "" || e.AssignedToDetails == "null null" ? "UnAssigned" : e?.AssignedToDetails?.FN + " " + e?.AssignedToDetails?.LN}</label></div>,
                    Stage: <label
                        style={{ fontSize: 14 }}
                        className={e.CurrentStage?.[0]?.StageCode == "Done" ? 'text-success' : e.CurrentStage?.[0]?.StageCode == "RFD" ? 'text-warning' : 'text-info'}>
                        {e.CurrentStage?.[0]?.StageName}</label>

                }
            })}
            options={{
                actionsColumnIndex: -1,
                exportAllData: true,
                exportButton: true,
                columnResizable: true,
                filtering: false,
                sorting: true,
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50],

                headerStyle: {
                    whiteSpace: "nowrap",
                    fontWeight: "700",
                    fontSize: "medium",
                    // backgroundColor: "#eef2f7",
                    color: "black",
                    // padding: "9px ",
                    textAlign: "left",
                },
                cellStyle: {
                    textAlign: "left",
                    fontSize: "medium",
                    padding: "21px",
                    color: "#6c757d",
                    whiteSpace: "nowrap",
                },
                rowStyle: (rowdata) => {
                    if (rowdata.tableData.id % 2 != 0) {
                        // return { backgroundColor: "#cddef085" };
                    }
                },
            }}
        // onRowClick={(evt, selectedRow) =>
        //     setSelectedRow(selectedRow.tableData.id)
        // }
        />
    );
}