import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast, Slide } from "react-toastify";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { Row, Col, Card } from "reactstrap";
import { TextField } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Select from "react-select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Form from "react-bootstrap/Form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import moment from "moment";
import axios from "../../axios";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import "./storypoints.css";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Bookmark from "@material-ui/icons/Bookmark";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { HANDLE_ON_CHANGE } from "../../redux/CommonInitialsate";
export default function Storyoption({
  // ShowOption,
  // ShowOptionID,
  // setOption,
  // projectRole,
  // Bfopen,
  // setBfopen,
  // IssueId,
  // projectId2,
  // selectedCard,
  // SelectedCardId,
  // setRefresh,
  // FilteredData,
  // Lane,
  // releases,
}) {
  // console.log(selectedCard?.ModuleLinked?.FullName, "sssssssssssssssssssssss");
  const [OptionId, setOptionId] = useState(null);
  const [checkSubmit, setSubmitCheck] = useState(false);
  const style = {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
  };
  const { projectCode, SelectedCardId, FilteredData, projectId2, projectRole, selectedCard, IssueId, releases1, ShowOption, ShowOptionID, Lane, projectName } = useSelector((state) => state.user);
  let arr = JSON.parse(localStorage.getItem("Empdetails"));
  let clientdetails = JSON.parse(localStorage.getItem("clientdetails"));
  const userorg = clientdetails?.[0]?.OrgDetails;
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    maxWidth: 400,
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.mode === "dark" ? "#3A4047" : "#ccc",
      cursor: "pointer",
    },
  }));
  const dispatch = useDispatch()
  const handlechangeoption = () => { };
  const handleFormClose = () => {
    let postdata = {
      CurrentStage: [
        {
          StageCode: Lane.Source,
          StageName: Lane.Source === "BLG" ? "Backlog" : "In Development",
          DateMoved: moment().format("YYYY-MM-DDThh:mm:ss"),
        },
      ],
    };
    axios
      .patch("agilesaas_issue_details?IssueId=eq." + ShowOptionID, postdata)
      .catch((err) => console.log(err))
      .then((res) => console.log(res));

    // setOption(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'ShowOption', value: false }))
    setOptionId(null);
    // setRefresh(true);
    dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
  };
  const handleClose = () => {
    // let postdata = {
    //     "CurrentStage": [
    //         {
    //             "StageCode": "BLG",
    //             "StageName": "Backlog",
    //             "DateMoved": moment().format("YYYY-MM-DDThh:mm:ss")
    //         }
    //     ],
    // }
    // axios
    //     .patch("agilesaas_issue_details?IssueId=eq." + ShowOptionID, postdata)
    //     .catch((err) => console.log(err))
    //     .then((res) => console.log(res))

    // setOption(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'ShowOption', value: false }))
    setOptionId(null);
    // setRefresh(true);
    dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
  };
  const handlesubmit = (option) => {
    if (option === "L") {
      let arr = JSON.parse(localStorage.getItem("Empdetails"));
      const checkUser = FilteredData.filter((x) => x.IssueId === IssueId)?.[0];
      const releasecheck = releases1?.filter(
        (s) =>
          s.ReleaseName === checkUser?.PlannedRelease &&
          moment(s.SprintStartDate).diff(moment(), "day") < 0
      )?.length >= 0;

      let clientid = JSON.parse(localStorage.getItem("clientid"));
      let posthistory = {
        IssueId: IssueId,
        ChangedBy: arr?.[0]?.ClientUserId,
        ChangedByDetails: {
          FN: arr?.[0]?.UserFirstname,
          LN: arr?.[0]?.UserLastname,
        },
        ChangedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
        UpdatedField: "Estimate  Later",
        UpdatedTo: "L",
        UpdateSequence: 1,
        IsActive: "Y",
        Status: null,
        ClientId: clientid,
        ProjectId: projectCode,
      };
      let updateAgile = {
        CurrentStage: [
          {
            StageCode: Lane.Target === "DEV" && releasecheck ? "DEV" : "RFD",
            StageName:
              Lane.Target === "DEV" && releasecheck
                ? "In Development"
                : "Refined",
            DateMoved: moment().format("YYYY-MM-DD"),
          },
        ],
        ExceptiontoSkipSPEstimation: "L",
      };

      axios
        .patch("agilesaas_issue_details?IssueId=eq." + IssueId, updateAgile)
        .catch((err) => console.log(err))
        .then((res) => {
          //close
          // setRefresh(true);
          dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
          axios
            .post("agilesaas_issue_history", posthistory)
            .then((response) => { })
            .catch((error) => console.log(error));
          axios.post("agilesaas_issue_history", {
            IssueId: IssueId,
            ChangedBy: arr?.[0]?.ClientUserId,
            ChangedByDetails: {
              FN: arr?.[0]?.UserFirstname,
              LN: arr?.[0]?.UserLastname,
            },
            ChangedDate: moment()
              .utcOffset("+05:30")
              .format("YYYY-MM-DDTHH:mm:ss"),
            UpdatedField: "Kanban Changes",
            UpdatedFrom: Lane.Source,
            UpdatedTo: Lane.Target,
            UpdateSequence: 1,
            IsActive: "Y",
            Status: null,
            ClientId: clientid,
            ProjectId: projectCode,
          });
          handleClose();
          toast.success(`Estimation later`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          });
          let _title = FilteredData?.filter((e) => e.IssueId == IssueId)
            ?.map((s) => s.IssueTitle)
            .toString();
          let asig = FilteredData?.filter((e) => e.IssueId == IssueId)
            ?.map(
              (s) => s?.AssignedToDetails?.FN + " " + s?.AssignedToDetails?.LN
            )
            .toString();
          let requrireddata = {
            ClientUserId: arr?.[0]?.ClientUserId,
            ClientId: clientid,
            ClientDetails: {
              Company: userorg?.Company,
              ContactName: userorg?.ContactName,
              ContactDesignation: userorg?.ContactDesignation,
              ContactEmail: userorg?.ContactEmail,
              ContactPhone: userorg?.ContactPhone,
            },
            DepartmentDetails: arr?.[0]?.DepartmentDetails,
            DepartmentCode: null,
            TentantId: "",
            FromSenderId: "no-reply@yozytech.com",
            emailnotify: false,
            webnotify: true,
            ToEmailIds: {
              emailids: arr?.[0]?.ClientDetails?.ContactEmail,
            },
            CcEmailIds: {
              emailids: ['agileteam@yozytech.com'],
            },
            BccEmailIds: "",
            EmailType: "",
            ProductDetails: {
              ProdId: "11004",
              ProdName: "KANBAN",
              Domain: "KANBAN.yozytech.com",
            },
            EmailSubject: "Estimation",
            IsSuccess: "Y",
            IsPartiallyFailed: " ",
            SendRetryHistory: "",
            IsActive: " ",
            Status: "",
            EmailTemplateName: "",
            EmailTemplateVariables: {
              xxasignbyxx: "",
              xxassigndatexx: "",
              xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
              xxprjdisxx: "",
              xxprjnamexx: "",
              xxstoryNamexx: "",
              xxprjpercenxx: "",
              xxprjstartdatexx: "",
              xxprjenddataexx: "",
              xxteamnamexx: "",
              xxsprintxx: "",
              xxmanagernamexx: userorg?.ContactName,
              xxcompanyname: userorg?.Company,
              xxmanagerdesigxx: "Scrum Master",
              xxgmailxx: userorg?.ContactEmail,
              xxphnoxx: userorg?.ContactPhone,
              webappdescri: `Estimation ${"later"} given for ${_title} - (${IssueId}) by ${asig} `,
            },
          };
          axios({
            url: "https://api.devozy.ai/api/agile/email",
            method: "post",
            data: requrireddata,
            headers: {
              origin: "https://kanban.yozytech.com",
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              console.log(res.data, "mailsend");
            })
            .catch((err) => {
              console.log(err);
            });
        });
    } else {
      setOptionId(option);
    }

    // let HisteryData = {
    //     IssueId: ShowOptionID,
    //     ChangedBy: login?.[0]?.ClientUserId,
    //     ChangedByDetails: {
    //         FN: login?.[0]?.UserFirstname,
    //         LN: login?.[0]?.UserLastname,
    //     },
    //     ChangedDate: moment().format("YYYY-MM-DDThh:mm:ss"),
    //     UpdatedField: "Update StoryPoint Option",
    //     UpdatedTo: option,
    //     UpdateSequence: "1",
    //     IsActive: "Y",
    //     Status: null,
    //     ClientId: clientId,
    //     ProjectId: projectCode
    // };
    // let postdata = {
    //     "CurrentStage": [
    //         {
    //             "StageCode": "RFD",
    //             "StageName": "Refined",
    //             "DateMoved": moment().format("YYYY-MM-DDThh:mm:ss")
    //         }
    //     ],
    //     "ExceptiontoSkipSPEstimation": option
    // }
    // console.log(postdata, HisteryData)
    // axios
    //     .patch("agilesaas_issue_details?IssueId=eq." + ShowOptionID, postdata)
    //     .catch((err) => console.log(err))
    //     .then((res) => {

    //         axios
    //             .post("agilesaas_issue_history", HisteryData).then((resq) => {
    //                 setOption(false);
    //                 setRefresh(true);
    //             })

    //     });
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      // height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  let optiondrop = [
    { label: "Skip Estimation", value: "Y" },
    { label: "Estimate like task", value: "E" },
    { label: "StoryPoint Estimation", value: "S" },
  ];
  const StoryPoint = () => {
    // function Storypoints({
    //     Bfopen,
    //     setBfopen,
    //     IssueId,
    //     selectedCard,
    //     setRefresh,
    //     EmpDropDownData,
    //     projectId2,
    //     FilteredData,
    //     projectRole
    //   }) {
    let empid = JSON.parse(localStorage.getItem("Empdetails"));
    let clientd = JSON.parse(localStorage.getItem("clientid"));
    let managerdetails =
      empid?.[0]?.OnboardedByDetails == null
        ? empid?.[0]?.UserFirstname + " " + empid?.[0]?.UserLastname
        : "Scrum Master";
    console.log(managerdetails, "managerdetails");
    let managerid =
      empid?.[0]?.OnboardedBy == null
        ? localStorage.getItem("managerid") == "undefined"
          ? ""
          : JSON.parse(localStorage.getItem("managerid"))
        : empid?.[0]?.OnboardedBy;
    let rolecode =
      sessionStorage.getItem("Roledetails") == "undefined"
        ? ""
        : JSON.parse(sessionStorage.getItem("Roledetails"));
    let crtid =
      empid != null
        ? empid?.[0]?.OnboardedBy === 800001
          ? 1
          : empid?.[0]?.OnboardedBy
        : "";

    const [allcissues, setallcisssues] = useState([]);

    const test = FilteredData?.filter(
      (e) => e.ProjectId === projectId2 && e.IssueId === IssueId
    );
    const [tasktitle, settasktitle] = useState("");
    let initialData = [
      {
        SPCode: 1,
        Module: selectedCard?.ModuleLinked,
        Techstack: "",
        title: "",
        Effort: 0,
      },
    ];
    const [rows, setRows] = useState(initialData);
    const [issuerows, setissuerows] = useState([
      {
        ProjectId: selectedCard?.ProjectId,
        ProjectShortCode: selectedCard?.ProjectShortCode,
        IssueType: "SubTask",
        IssueTitle: tasktitle,
        Description: "",
        Priority: "Medium",
        PlannedRelease: selectedCard?.PlannedRelease,
        EstimateUnit: "MD",
        EstimateUnitDesc: "Man Days",
        CurrentStage: [
          {
            StageCode: "BLG",
            StageName: "Backlog",
            DateMoved: moment().format("YYYY-MM-DD"),
          },
        ],
        LinkToEpic: [
          {
            IssueId: IssueId,
            IssueType: "SubTask",
            Title: selectedCard?.IssueTitle,
            CurrentStage: selectedCard?.CurrentStage?.StageName,
            Priority: selectedCard?.Priority,
          },
        ],

        LinkToIssues: null,
        ParentIssue: [
          {
            IssueId: IssueId,
            Title: selectedCard?.IssueTitle,
            CurrentStage: allcissues?.[0]?.CurrentStage?.[0]?.StageName,
            Priority: selectedCard?.Priority,
          },
        ],

        CreatedBy: managerid,
        CreatedByDetails: managerdetails,
        // "Scrum Master",
        // { FN: empid[0].Firstname, LN: empid[0].Lastname },
        CreatedDate: moment().format("YYYY-MM-DD"),
        AssignedTo: null,
        AssignedToDetails: { FN: "Un", LN: "Assigned" },
        AssignedDate: moment().format("YYYY-MM-DD"),
        Attachment: null,
        IsActive: "Y",
        ExceptiontoSkipSPEstimation: null,
        ProjectDetails: [
          {
            ProjName: projectName,
            Client: "",
          },
        ],
        AssignedToUAT: null,
        AssignedToUATDetails: {
          FN: null,
          LN: null,
        },
      },
    ]);
    console.log(issuerows, "issuerows");
    const [Contigency, setContigency] = useState(0);
    const [check, setcheck] = useState(true);

    const api2 =
      projectRole == "SM"
        ? `agilesaas_issue_details?or=(CreatedBy.eq.${empid?.[0]?.ClientUserId},AssignedTo.eq.${empid?.[0]?.ClientUserId},CreatedBy.eq.${empid?.[0]?.ClientUserId})&order=CreatedDate.desc&IsActive=eq.Y`
        : // `agilesaas_issue_details?or=(AssignedTo.eq.${empid[0]?.ClientUserId},CreatedBy.eq.${empid[0]?.OnboardedBy},CreatedBy.eq.${empid[0]?.OnboardedBy})`
        projectRole == "QA"
          ? `agilesaas_issue_details?or=(AssignedTo.eq.${empid?.[0]?.ClientUserId},AssignedToUAT.eq.${empid?.[0]?.ClientUserId},CreatedBy.eq.${empid?.[0]?.ClientUserId})&order=CreatedDate.desc&IsActive=eq.Y`
          : `agilesaas_issue_details?or=(AssignedTo.eq.${empid?.[0]?.ClientUserId})&order=CreatedDate.desc&IsActive=eq.Y`;

    useEffect(() => {
      async function FullData() {
        const _fullData = await axios.get(api2);
        let a = _fullData.data?.filter((x) => x.IssueId == IssueId);

        setallcisssues(a);
        return _fullData;
      }
      FullData();

      // axios
      //   .get("agilesaas_storypoint_master")
      //   .then((response) => {
      //     setSpMaster1(response.data);
      //     setSpMaster(response.data);
      //   })
      //   .catch((error) => console.log(error));
      // axios
      //   .get(
      //     "rpc/fun_agilesaasmanagerteamprojdetails?managerid=" +
      //       crtid +
      //       "&clientid=" +
      //       clientid +
      //       "&projectcode=eq." +
      //       1
      //   )
      //   .then((response) => {
      //     setcheck(response.data);
      //   })
      //   .catch((error) => console.log(error));
      // axios.get(api2).then((responses) => {
      //   console.log(responses.data, "responseapa2");

      //   setallcisssues(responses.data);
      // });
    }, []);

    const module = [
      {
        label: selectedCard?.ModuleLinked?.FullName,
        value: selectedCard?.ModuleLinked?.FullName,
      },
      // { label: "Backend", value: "Backend" },
      // { label: "Cloud computing", value: "Cloud Complutinf" },
    ];

    const techno = [
      { label: "ReactJS", value: "ReactJs" },
      { label: "Express", value: "Express" },
      { label: "React Native", value: "React Native" },
    ];

    const add =
      rows.length == 1
        ? rows?.[0]?.Effort
        : rows?.map((e) => parseInt(e.Effort)).reduce((n, m) => n + m);
    // const add = rows.reduce((a,b) => parseInt(a.TotalEstimatedEffort) + parseInt(b.TotalEstimatedEffort))

    const Total = parseFloat(Contigency) + parseFloat(add);

    // const handleclose = () => {
    //     setBfopen(false);
    //     setRefresh(true);
    //     setRows(initialData);
    // };

    function StoryPointClick() {
      let clientid = JSON.parse(localStorage.getItem("clientid"));
      const checkUser = FilteredData.filter((x) => x.IssueId === IssueId)?.[0];
      const releasecheck = releases1?.some(
        (s) =>
          s.ReleaseName === checkUser?.PlannedRelease &&
          moment(s.SprintStartDate).diff(moment(), "day") < 0
      );
      let len = 0;
      let issueData = [];
      if (rows.length != 0) {
        if ((check && Contigency != 0) || (!check && Contigency == 0)) {
          rows.map((e, i) => {
            if (e.title.length == "") {
              toast.error("Please enter title in row " + (i + 1));
            }
            // else if (e.Module == "") {
            //   toast.error("Please enter module in row " + (i + 1));
            // }
            // else if (e.Techstack == "") {
            //   toast.error("Please enter techstack in row " + (i + 1));
            // }
            else if (e.Effort == 0 || e.Effort == "") {
              toast.error("please enter valid effort for row " + (i + 1));
            } else {
              len++;
              let d = {
                ProjectId: selectedCard?.ProjectId,
                ProjectShortCode: selectedCard?.ProjectShortCode,
                IssueType: "SubTask",
                IssueTitle: e.title,
                Description: "",
                Priority: "Medium",
                PlannedRelease: selectedCard?.PlannedRelease,
                ActualRelease: null,
                OriginalEstimate: e.Effort,
                EstimateUnit: "MD",
                EstimateUnitDesc: "Man Days",
                CurrentStage: [
                  {
                    StageCode: Lane.Target === "DEV" ? "DEV" : "RFD",
                    StageName:
                      Lane.Target === "DEV" ? "In Development" : "Refined",
                    DateMoved: moment().format("YYYY-MM-DD"),
                  },
                ],
                ParentIssue: [
                  {
                    IssueId: IssueId,
                    Title: selectedCard?.IssueTitle,
                    CurrentStage: selectedCard?.CurrentStage?.[0]?.StageName,
                    Priority: selectedCard?.Priority,
                  },
                ],
                LinkToEpic: null,
                LinkToIssues: null,
                CreatedBy: managerid,
                CreatedByDetails: managerdetails,
                CreatedDate: moment().format("YYYY-MM-DD"),
                AssignedTo: selectedCard?.AssignedTo ? selectedCard?.AssignedTo : null,
                AssignedToDetails: selectedCard?.AssignedTo ? selectedCard?.AssignedToDetails : { FN: 'null', LN: 'null' },
                AssignedDate: moment().format("YYYY-MM-DD"),
                DueInDays: null,
                Attachment: null,
                IsActive: "Y",
                Status: null,
                ProjectDetails: [
                  {
                    ProjName: projectName,
                  },
                ],
                ExceptiontoSkipSPEstimation: null,
                AssignedToUAT: null,
                AssignedToUATDetails: {
                  FN: null,
                  LN: null,
                },
                ClientId: clientd,
                ModuleLinked: selectedCard?.ModuleLinked,
              };
              issueData.push(d);

              if (rows.length == len) {
                setSubmitCheck(true);
                let final = {
                  IssueId: IssueId,
                  StoryPointId: null,
                  UpdatedBy: empid?.[0]?.TeamMemberId,
                  UpdatedByDetails: {
                    FN: empid?.[0]?.Firstname,
                    LN: empid?.[0]?.Lastname,
                  },
                  UpdatedDate: moment().format("YYYY-MM-DD"),
                  SPEstimationDetails: rows,
                  AutoEstimatedEffort: null,
                  Contigency: null,
                  TotalEstimatedEffort: parseFloat(add),
                  EstimationRemarks:
                    "added 2 hours buffer to take care additional UI changes",
                  IsActive: "Y",
                  Status: null,
                };
                let ttitle = rows
                  ?.filter((e, i) => e.title === issueData[i]?.IssueTitle)
                  .map((k) => {
                    return {
                      effort: k.Effort,
                      issuetitle: k.title,
                    };
                  });
                let postissue = {
                  OriginalEstimate: Total,
                  // CurrentStage: [
                  //   {
                  //     StageCode:
                  //       Lane.Target === "DEV" && releasecheck ? "DEV" : "RFD",
                  //     StageName:
                  //       Lane.Target === "DEV" && releasecheck
                  //         ? "In Development"
                  //         : "Refined",
                  //     DateMoved: moment().format("YYYY-MM-DD"),
                  //   },
                  // ],
                  CurrentStage: [
                    {
                      StageCode: Lane.Target === "DEV" ? "DEV" : "RFD",
                      StageName:
                        Lane.Target === "DEV" ? "In Development" : "Refined",
                      DateMoved: moment().format("YYYY-MM-DD"),
                    },
                  ]
                };
                axios
                  .post("agilesaas_issue_details", issueData)
                  .then((response) => {
                    axios
                      .get(
                        `agilesaas_issue_details?ParentIssue->0->>IssueId=eq.${selectedCard?.IssueId}&order=CreatedDate.desc&IsActive=eq.Y`
                      )
                      .then((res) => {
                        if (res.data.length > 0) {
                          let estimatetask =
                            res.data?.length < 0
                              ? ""
                              : res.data?.map((r) => {
                                const _estimate = ttitle?.find(
                                  (f) => f.issuetitle === r.IssueTitle
                                )?.effort;
                                return {
                                  IssueId: r.IssueId,
                                  StoryPointId: null,
                                  UpdatedBy: empid?.[0].TeamMemberId,
                                  UpdatedByDetails: {
                                    FN: empid?.[0]?.Firstname,
                                    LN: empid?.[0]?.Lastname,
                                  },
                                  UpdatedDate: moment().format("YYYY-MM-DD"),
                                  SPEstimationDetails: null,
                                  AutoEstimatedEffort: null,
                                  Contigency: null,
                                  TotalEstimatedEffort: _estimate,
                                  EstimationRemarks:
                                    "added 2 hours buffer to take care additional UI changes",
                                  IsActive: "Y",
                                  Status: null,
                                };
                              });
                          axios
                            .post(
                              "agilesaas_storypoint_estimation",
                              estimatetask
                            )
                            .then((response) => {
                              let _d = estimatetask.map((f) => {
                                return {
                                  id: f.IssueId,
                                  effort: f.TotalEstimatedEffort,
                                };
                              });
                              for (const i of _d) {
                                let patchest = {
                                  OriginalEstimate: i.effort,
                                };
                                axios
                                  .patch(
                                    "agilesaas_issue_details?IssueId=eq." +
                                    i.id,
                                    patchest
                                  )
                                  .then((res) => { })
                                  .catch((err) => console.log("err", err));
                              }
                            })
                            .catch((error) => console.log(error));
                        }
                      });
                  })
                  .catch((err) => console.log(err, "task bug"));
                axios
                  .patch(
                    "agilesaas_issue_details?IssueId=eq." +
                    selectedCard.IssueId,
                    postissue
                  )
                  .then((response) => { })
                  .catch((err) => console.log(err, "task bug"));

                axios
                  .post("agilesaas_storypoint_estimation ", final)
                  .then((response) => {
                    let _title = FilteredData?.filter(
                      (e) => e.IssueId == IssueId
                    )
                      ?.map((s) => s.IssueTitle)
                      .toString();
                    let asig = FilteredData?.filter((e) => e.IssueId == IssueId)
                      ?.map(
                        (s) =>
                          s?.AssignedToDetails?.FN +
                          " " +
                          s?.AssignedToDetails?.LN
                      )
                      .toString();
                    let requrireddata = {
                      ClientUserId: arr?.[0]?.ClientUserId,
                      ClientId: clientid,
                      ClientDetails: {
                        Company: userorg?.Company,
                        ContactName: userorg?.ContactName,
                        ContactDesignation: userorg?.ContactDesignation,
                        ContactEmail: userorg?.ContactEmail,
                        ContactPhone: userorg?.ContactPhone,
                      },
                      DepartmentDetails: arr?.[0]?.DepartmentDetails,
                      DepartmentCode: null,
                      TentantId: "",
                      FromSenderId: "no-reply@yozytech.com",
                      emailnotify: false,
                      webnotify: true,
                      ToEmailIds: {
                        emailids: arr?.[0]?.ClientDetails.ContactEmail,
                      },
                      CcEmailIds: {
                        emailids: ['agileteam@yozytech.com'],
                      },
                      BccEmailIds: "",
                      EmailType: "",
                      ProductDetails: {
                        ProdId: "11004",
                        ProdName: "KANBAN",
                        Domain: "KANBAN.yozytech.com",
                      },
                      EmailSubject: "Estimation",
                      IsSuccess: "Y",
                      IsPartiallyFailed: " ",
                      SendRetryHistory: "",
                      IsActive: " ",
                      Status: "",
                      EmailTemplateName: "",
                      EmailTemplateVariables: {
                        xxasignbyxx: "",
                        xxassigndatexx: "",
                        xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
                        xxprjdisxx: "",
                        xxprjnamexx: "",
                        xxstoryNamexx: "",
                        xxprjpercenxx: "",
                        xxprjstartdatexx: "",
                        xxprjenddataexx: "",
                        xxteamnamexx: "",
                        xxsprintxx: "",
                        xxmanagernamexx: userorg?.ContactName,
                        xxcompanyname: userorg?.Company,
                        xxmanagerdesigxx: "Scrum Master",
                        xxgmailxx: userorg?.ContactEmail,
                        xxphnoxx: userorg?.ContactPhone,
                        webappdescri: `Estimated ${Total} days for ${_title} - (${IssueId}) by ${asig} `,
                      },
                    };
                    axios({
                      url: "https://api.devozy.ai/api/agile/email",
                      method: "post",
                      data: requrireddata,
                      headers: {
                        origin: "https://kanban.yozytech.com",
                        "Content-Type": "application/json",
                      },
                    })
                      .then((res) => {
                        console.log(res.data, "mailsend");
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                    toast.success(
                      "Original Estimation " +
                      Total +
                      " " +
                      "Days Updated Successfully",
                      {
                        transition: Slide,
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                        draggable: true,
                      }
                    );
                    setSubmitCheck(false);
                    handleClose();
                  })
                  .catch((err) => console.log(err));
              }
            }
          });
        } else {
          toast.error("Please enter contigency");
        }
        // actual estimate for story
      }
    }

    const handleDeleteRows = (i) => {
      let data = [...rows];
      data.splice(i, 1);
      setRows(data);
    };

    const tecchange = (i, item) => {
      let data = [...rows];
      data.splice(i, 1, {
        SPCode: i + 1,
        Module: rows[i].Module,
        Techstack: item.value,
        title: rows[i].title,
        Effort: rows[i].Effort,
      });
      setRows(data);
    };

    const handlechangedes = (i, value) => {
      let data = [...rows];
      data.splice(i, 1, {
        SPCode: i + 1,
        Module: rows[i].Module,
        Techstack: rows[i].Techstack,
        title: value,
        Effort: rows[i].Effort,
      });
      setRows(data);
    };

    const modulechange = (i, item) => {
      let data = [...rows];
      data.splice(i, 1, {
        SPCode: i + 1,
        Module: item.value,
        Techstack: rows[i].Techstack,
        title: rows[i].title,
        Effort: rows[i].Effort,
      });
      setRows(data);
    };

    const effortchange = (i, value) => {
      let data = [...rows];
      data.splice(i, 1, {
        SPCode: i + 1,
        Module: rows[i].Module,
        Techstack: rows[i].Techstack,
        title: rows[i].title,
        Effort: parseInt(value),
      });
      setRows(data);
    };

    const handleAddRows = (e) => {
      e.preventDefault();
      let adddata = [
        ...rows,
        {
          SPCode: "",
          Module: selectedCard?.ModuleLinked,
          Techstack: "",
          title: "",
          Effort: 0,
        },
      ];

      setRows(adddata);
    };

    return (
      // <Dialog
      //     fullWidth={true}
      //     maxWidth={"md"}
      //     open={Bfopen && selectedCard?.OriginalEstimate == null}
      // >
      //     <DialogTitle
      //         style={{
      //             display: "flex",
      //             alignItems: "center",
      //             justifyContent: "space-between",
      //             padding: "10px 15px",
      //         }}
      //     >
      //         <Typography
      //             style={{
      //                 fontFamily: "DM Sans, sans-serif;",
      //                 fontSize: 16,
      //                 fontWeight: 700,
      //             }}
      //         >
      //             <CheckCircleOutlineIcon color="success" className="check-icon" />

      //         </Typography>
      //         <Typography onClick={handleclose} style={{ cursor: "pointer" }}>
      //             <CloseIcon />
      //         </Typography>
      //     </DialogTitle>

      //     <Divider />

      //     <DialogContent style={{ padding: "10px 24px" }}>
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 9,
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontFamily: "DM Sans, sans-serif;",
              fontSize: 16,
              fontWeight: "600",
              textAlign: "left",
              margin: 0,
            }}
          >
            {""}
          </Typography>
          <Typography
            variant="h6"
            style={{
              textAlign: "left",
              margin: 0,
              fontFamily: "DM Sans, sans-serif",
              fontSize: 16,
              fontWeight: 800,
            }}
          >
            Total: {Total} Days<span> ( 1 day = 1 story point ) </span>
          </Typography>
        </div>
        <Card variant="outlined" style={{ margin: "0 0" }}>
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Story Point Description
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Module
              </Typography>
            </Grid>
            {/* <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Tech Stack
              </Typography>
            </Grid> */}
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Estimation
              </Typography>
            </Grid>
          </Grid>

          <Divider />

          <form
            // onSubmit={handleSubmit}
            style={{ padding: "10px 0" }}
          >
            {rows?.map((e, i) => (
              <div className="form-inline" key={i}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={4} md={4} sm={6} xs={6}>
                    <input
                      id={i}
                      name={i}
                      className="form-control"
                      type="text"
                      onChange={(event) =>
                        handlechangedes(i, event.target.value)
                      }
                      value={e.title}
                      placeholder="Enter Description"
                      style={{
                        borderRadius: 5,
                        height: 40,
                        margin: "10px 0",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={6}>
                    {console.log(rows?.[i]?.Module, "ccccccccccccc")}
                    {
                      rows?.[i]?.Module ?
                        <Select
                          id={i}
                          name={i}
                          options={module}
                          isDisabled
                          // value="module"
                          onChange={(item) => modulechange(i, item)}
                          value={module.filter(
                            (option) => option.value === rows[i].Module?.FullName
                          )}
                          className="Select-Dropdown"
                        // formatOptionLabel={option => (
                        //   <div className="select-options">
                        //     <span>{option.label}</span>
                        //     <img src={option.image} alt="dropdown-image" />
                        //   </div>
                        // )}
                        />
                        :
                        <label > -- </label>
                    }
                  </Grid>
                  {/* <Grid item lg={3} md={3} sm={6} xs={6}>
                    <Select
                      id={i}
                      name={i}
                      options={techno}
                      onChange={(item) => tecchange(i, item)}
                      value={techno.filter(
                        (option) => option.value === rows[i].Techstack
                      )}
                    // formatOptionLabel={option => (
                    //   <div className="select-options">
                    //     <span>{option.label}</span>
                    //     <img src={option.image} alt="dropdown-image" />
                    //   </div>
                    // )}
                    />
                  </Grid> */}
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      className="form-control"
                      id={i}
                      name={i}
                      type="number"
                      value={rows[i].Effort}
                      onChange={(event) => effortchange(i, event.target.value)}
                      style={{
                        borderRadius: 5,
                        height: 40,
                        margin: "10px 0",
                        width: "100%",
                      }}
                    />
                    {/* {
                                  i ? */}
                    {console.log(rows.length, "length")}
                    {rows.length <= 1 ? (
                      ""
                    ) : (
                      <Typography
                        onClick={() => {
                          if (rows.length == 0) {
                            console.log("");
                          } else {
                            handleDeleteRows(i);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <DeleteForeverIcon color="error" />
                      </Typography>
                    )}

                    {/* : <Typography onClick={(i) => handleDeleteRows(i)} style={{ cursor: 'pointer' }}><DeleteForeverIcon color='error' /></Typography>
                                } */}
                  </Grid>
                </Grid>
              </div>
            ))}
            <div className="button-section">
              <Button
                type="text"
                size="small"
                onClick={(e) => handleAddRows(e)}
                style={{
                  color: "green",
                  textTransform: "none",
                  backgroundColor: "#fff",
                  border: "none",
                }}
              >
                <AddCircleOutlineIcon style={{ fontSize: 14 }} />
                Add additional tab
              </Button>
            </div>
          </form>
        </Card>
        <div style={{ display: "flex", flexDirection: "row", width: "25%" }}>
          <Form.Group
            as={Col}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <FormControlLabel
              size="small"
              control={
                <Switch
                  defaultChecked
                  checked={check}
                  onClick={() => {
                    setcheck(!check);
                    setContigency(0);
                  }}
                />
              }
              label="Contigency"
              className="plansprint-form-switch"
            />
          </Form.Group>
          {check ? (
            <input
              // className="form-control"
              type="number"
              onChange={(event) => setContigency(event.target.value)}
              value={Contigency}
              placeholder="Enter Contigency"
              style={{
                borderRadius: 5,
                height: 36,
                width: 140,
                margin: "10px 0",
                alignSelf: "baseline",
                borderWidth: 1,
                borderColor: "grey",
                padding: 5,
                fontSize: 14,
              }}
            />
          ) : null}
        </div>
        <div className="d-flex justify-content-end">
          <Button
            style={{
              fontSize: 14,
              padding: 9,
              width: 100,
              height: 40,
              borderRadius: 8,
              backgroundColor: "#5250E5",
              color: "#fff",
              border: "0px solid #5A88F1",
              textAlign: "center",
              textTransform: "none",
            }}
            disabled={checkSubmit}
            onClick={() => {
              StoryPointClick();
            }}
          >
            {checkSubmit ? (
              <span
                className="spinner-border"
                role="status"
                aria-hidden="true"
                style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
              ></span>
            ) : (
              "Create"
            )}
          </Button>
        </div>
      </>
    );
    //   }
  };
  const checkUser = FilteredData.filter((x) => x.IssueId === IssueId)?.[0];
  const releasecheck = releases1?.some(
    (s) =>
      s.ReleaseName === checkUser?.PlannedRelease &&
      moment(s.SprintStartDate).diff(moment(), "day") < 0
  );
  console.log(Lane, [
    {
      StageCode: Lane.Target === "DEV" ? "DEV" : "RFD",
      StageName:
        Lane.Target === "DEV"
          ? "In Development"
          : "Refined",
      DateMoved: moment().format("YYYY-MM-DD"),
    },
  ], moment("20-06-2024").diff(moment(), "day") < 0,
    [
      {
        StageCode: Lane.Target === "DEV" ? "DEV" : "RFD",
        StageName:
          Lane.Target === "DEV"
            ? "In Development"
            : "Refined",
        DateMoved: moment().format("YYYY-MM-DD"),
      },
    ], "laneeeeeeeeeeee")
  const OrginalEstimate = () => {
    const dialogStyle = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      border: "1px solid #ccc",
      textAlign: "center",
      padding: "20px",
    };
    // function OriginalEstimate({ openCreateEst, setOpenCreateEst, IssueId, setRefresh }) {
    const [OriginalEst, setOriginalEst] = useState();

    let arr = JSON.parse(localStorage.getItem("Empdetails"));

    let clientid = JSON.parse(localStorage.getItem("clientid"));
    const { projectCode, projectRole } = useSelector((state) => state.user);

    let OriginalEstsubmit = () => {
      const checkUser = FilteredData.filter((x) => x.IssueId === IssueId)?.[0];
      const releasecheck = releases1?.filter(
        (s) =>
          s.ReleaseName === checkUser?.PlannedRelease &&
          moment(s.SprintStartDate).diff(moment(), "day") < 0
      )?.length >= 0;
      let Sppostdata1 = {
        IssueId: IssueId, // issue id
        UpdatedBy: arr?.[0]?.ClientUserId, // current login id
        UpdatedByDetails: {
          FN: arr?.[0]?.UserFirstname,
          LN: arr?.[0]?.UserLastname,
        },
        UpdatedDate: moment().format("YYYY-MM-DD"), //current date
        SPEstimationDetails: [
          {
            SPCode: "INPUTFORM",
            SPValueSelected: null,
            Effort: null,
          },
          {
            SPCode: "FROMVALIDATION",
            SPValueSelected: null,
            Effort: null,
          },
          {
            SPCode: "STYLING",
            SPValueSelected: null,
            Effort: null,
          },
          {
            SPCode: "CRUD",
            SPValueSelected: null,
            Effort: null,
          },
          {
            SPCode: "APIINTE",
            SPValueSelected: null,
            Effort: null,
          },
          {
            SPCode: "TESTING",
            SPValueSelected: null,
            Effort: null,
          },
        ],
        AutoEstimatedEffort: null,
        Contigency: null,
        TotalEstimatedEffort: OriginalEst.OriginalEstimate, //original estimated value
        EstimationRemarks:
          "added 2 hours buffer to take care additional UI changes",
        IsActive: "Y",
        Status: null,
      };

      let posthistory = {
        IssueId: IssueId,
        ChangedBy: arr?.[0]?.ClientUserId,
        ChangedByDetails: {
          FN: arr?.[0]?.UserFirstname,
          LN: arr?.[0]?.UserLastname,
        },
        ChangedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
        UpdatedField: "Original-Estimate",
        UpdatedTo: OriginalEst.OriginalEstimate,
        UpdateSequence: 1,
        IsActive: "Y",
        Status: null,
        ClientId: clientid,
        ProjectId: projectCode,
      };
      // CurrentStage: [
      //   {
      //     StageCode: Lane.Target === "DEV" && releasecheck ? "DEV" : "RFD",
      //     StageName:
      //       Lane.Target === "DEV" && releasecheck
      //         ? "In Development"
      //         : "Refined",
      //     DateMoved: moment().format("YYYY-MM-DD"),
      //   },
      // ]
      let updateAgile = {
        CurrentStage: [
          {
            StageCode: Lane.Target === "DEV" && releasecheck ? "DEV" : "RFD",
            StageName:
              Lane.Target === "DEV" && releasecheck
                ? "In Development"
                : "Refined",
            DateMoved: moment().format("YYYY-MM-DD"),
          },
        ],
        OriginalEstimate: OriginalEst.OriginalEstimate,
      };

      axios
        .patch("agilesaas_issue_details?IssueId=eq." + IssueId, updateAgile)
        .catch((err) => console.log(err))
        .then((res) => {
          //close
          // setRefresh(true);
          dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
          axios
            .post("agilesaas_issue_history", posthistory)
            .then((response) => { })
            .catch((error) => console.log(error));
          axios.post("agilesaas_issue_history", {
            IssueId: IssueId,
            ChangedBy: arr?.[0]?.ClientUserId,
            ChangedByDetails: {
              FN: arr?.[0]?.UserFirstname,
              LN: arr?.[0]?.UserLastname,
            },
            ChangedDate: moment()
              .utcOffset("+05:30")
              .format("YYYY-MM-DDTHH:mm:ss"),
            UpdatedField: "Kanban Changes",
            UpdatedFrom: Lane.Source,
            UpdatedTo: Lane.Target,
            UpdateSequence: 1,
            IsActive: "Y",
            Status: null,
            ClientId: clientid,
            ProjectId: projectCode,
          });
          axios
            .post("agilesaas_storypoint_estimation ", Sppostdata1)
            .catch((err) => console.log(err))
            .then((res) => {
              // console.log(res);
            });
          handleClose();
          let _title = FilteredData?.filter((e) => e.IssueId == IssueId)
            ?.map((s) => s.IssueTitle)
            .toString();
          let asig = FilteredData?.filter((e) => e.IssueId == IssueId)
            ?.map(
              (s) => s?.AssignedToDetails?.FN + " " + s?.AssignedToDetails?.LN
            )
            .toString();
          let requrireddata = {
            ClientUserId: arr?.[0]?.ClientUserId,
            ClientId: clientid,
            ClientDetails: {
              Company: userorg?.Company,
              ContactName: userorg?.ContactName,
              ContactDesignation: userorg?.ContactDesignation,
              ContactEmail: userorg?.ContactEmail,
              ContactPhone: userorg?.ContactPhone,
            },
            DepartmentDetails: arr?.[0]?.DepartmentDetails,
            DepartmentCode: null,
            TentantId: "",
            FromSenderId: "no-reply@yozytech.com",
            emailnotify: false,
            webnotify: true,
            ToEmailIds: {
              emailids: arr?.[0].ClientDetails.ContactEmail,
            },
            CcEmailIds: {
              emailids: ['agileteam@yozytech.com'],
            },
            BccEmailIds: "",
            EmailType: "",
            ProductDetails: {
              ProdId: "11004",
              ProdName: "KANBAN",
              Domain: "KANBAN.yozytech.com",
            },
            EmailSubject: "Estimation",
            IsSuccess: "Y",
            IsPartiallyFailed: " ",
            SendRetryHistory: "",
            IsActive: " ",
            Status: "",
            EmailTemplateName: "",
            EmailTemplateVariables: {
              xxasignbyxx: "",
              xxassigndatexx: "",
              xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
              xxprjdisxx: "",
              xxprjnamexx: "",
              xxstoryNamexx: "",
              xxprjpercenxx: "",
              xxprjstartdatexx: "",
              xxprjenddataexx: "",
              xxteamnamexx: "",
              xxsprintxx: "",
              xxmanagernamexx: userorg?.ContactName,
              xxcompanyname: userorg?.Company,
              xxmanagerdesigxx: "Scrum Master",
              xxgmailxx: userorg?.ContactEmail,
              xxphnoxx: userorg?.ContactPhone,
              webappdescri: `Estimated ${OriginalEst.OriginalEstimate} days for ${_title} - (${IssueId}) by ${asig} `,
            },
          };
          axios({
            url: "https://api.devozy.ai/api/agile/email",
            method: "post",
            data: requrireddata,
            headers: {
              origin: "https://kanban.yozytech.com",
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              console.log(res.data, "mailsend");
            })
            .catch((err) => {
              console.log(err);
            });
          toast.success(`Original Estimation Submited`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          });
        });
    };
    return (
      <div style={dialogStyle}>
        <div
          className=""
          style={{
            fontFamily: "DM Sans, sans-serif;",
            fontSize: 16,
            fontWeight: 700,
          }}
        >
          Please enter Orginal Estimate{" "}
        </div>
        <div>
          {/* <Row className="">
                        <Col>
                            <Typography
                                style={{
                                    fontFamily: "DM Sans, sans-serif;",
                                    fontSize: 16,
                                    fontWeight: 700,
                                }}
                            >
                                Please Enter Original-Estimate
                            </Typography>
                        </Col>
                        <Col></Col>
                    </Row> */}
          <Row className="mt-3">
            <Col>
              <TextField
                variant="outlined"
                label="Original-Estimate"
                name="OriginalEstimate"
                size="small"
                className="pb-3"
                onChange={(e) =>
                  setOriginalEst({ [e.target.name]: e.target.value })
                }
              />
            </Col>
            <Col>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className="Card_Button_Color_Approve btn-greeng"
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 100,
                  height: 40,
                  borderRadius: 8,
                  backgroundColor: "#5250E5",
                  color: "#fff",
                  border: "0px solid #5A88F1",
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={() => {
                  OriginalEstsubmit();
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
    //   }
  };

  const useStyles = makeStyles((theme) => ({
    largeDot: {
      width: "10px",
      height: "10px",
    },
    largeConnector: {
      // height: '100px', // Adjust this as needed
      [theme.breakpoints.up("lg")]: {
        height: "30px", // Adjust for larger screens
      },
      [theme.breakpoints.up("md")]: {
        height: "130px",
      },
      [theme.breakpoints.up("sm")]: {
        height: "150px",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Dialog fullWidth={true} maxWidth={"md"} open={ShowOption}>
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 15px",
        }}
      >
        <Typography
          style={{
            fontFamily: "DM Sans, sans-serif;",
            fontSize: 16,
            fontWeight: 700,
          }}
        >
          {/* {OptionId !== null ?
                    <Typography onClick={() => { setOptionId(null) }} style={{ cursor: "pointer" }}>
                        < ArrowBackIcon />
                    </Typography> : ""} */}
          {OptionId !== null ? (
            <ArrowBackIcon
              onClick={() => {
                setOptionId(null);
              }}
            />
          ) : (
            ""
          )}
          <CheckCircleOutlineIcon color="success" className="check-icon" />
          {console.log(selectedCard, SelectedCardId, "jeba")}
          {selectedCard?.IssueTitle +
            "-" +
            selectedCard?.ProjectShortCode +
            "-" +
            IssueId}
        </Typography>
        <Typography onClick={handleFormClose} style={{ cursor: "pointer" }}>
          <CloseIcon />
        </Typography>
      </DialogTitle>

      <DialogContent style={{ padding: "10px 24px" }}>
        <div className="mt-3">
          {/* <Tabs
                    defaultActiveKey="S"
                    id="uncontrolled-tab-example"
                    className=""
                    justify
                >
                    <Tab eventKey="S" title="Add SPs and Estimate">
                        <StoryPoint />
                    </Tab>
                    <Tab eventKey="E" title="Just Estimate Story">
                        <OrginalEstimate />
                    </Tab>                 
                    <Tab eventKey="L" title={"Estimate Later "}>
                        {"Estimate Later"}
                    </Tab>
                </Tabs> */}

          {OptionId === null ? (
            <div>
              <Row>
                <Col className="d-flex jsutify-content-start ml-4">
                  <Typography
                    style={{
                      fontFamily: "DM Sans, sans-serif;",
                      fontSize: 16,
                      fontWeight: 700,
                    }}
                  >
                    Estimate the Story using one of the below options:
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col lg={5} sm={6} md={5}>
                  <div className="d-flex justify-content-start ">
                    {" "}
                    <List
                      sx={style}
                      component="nav"
                      aria-label="mailbox folders"
                    >
                      <ListItem
                        button
                      // onClick={() => handlesubmit("S")}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <ScheduleSendIcon
                              className="fontdesign"
                              fontSize="small"
                            />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Scrum / Planning  poker Estimation"
                          secondary="coming soon"
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem button onClick={() => handlesubmit("S")}>
                        <ListItemAvatar>
                          <Avatar>
                            <Bookmark className="fontdesign" fontSize="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Break Sub-tasks and Estimate"
                          secondary="Break the story into smaller sub-tasks and estimate them. Easier to assign to multiple team members."
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem button onClick={() => handlesubmit("E")}>
                        <ListItemAvatar>
                          <Avatar>
                            <CheckBoxRoundedIcon
                              className="fontdesign"
                              fontSize="small"
                            />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Simple Estimation"
                          secondary="Estimate the store as a whole like a single task."
                        />
                      </ListItem>
                      {Lane?.Source === "BLG" && Lane?.Target === "RFD" ? (
                        <>
                          <Divider variant="inset" component="li" />
                          <ListItem button onClick={() => handlesubmit("L")}>
                            <ListItemAvatar>
                              <Avatar>
                                <span className="mdi mdi-clock"></span>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Estimate Later"
                              secondary="Skip now and estimate later"
                            />
                          </ListItem>
                        </>
                      ) : (
                        <></>
                      )}
                    </List>
                  </div>
                </Col>
                <Col lg={4} sm={6} md={4}>
                  <div className="d-flex justify-content-end mt-2">
                    <Timeline position="right" style={{ height: "100%" }}>
                      <TimelineItem style={{ minHeight: "310px" }}>
                        <TimelineOppositeContent color="textSecondary">
                          FROM
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot
                            color="primary"
                            variant="filled"
                            className={classes.largeDot}
                          />
                          <TimelineConnector
                            className={classes.largeConnector}
                          />
                        </TimelineSeparator>

                        <TimelineContent className="fontdesign">
                          {Lane?.Source}
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent color="textSecondary">
                          TO
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot
                            color="success"
                            className={classes.largeDot}
                          />
                        </TimelineSeparator>
                        <TimelineContent>{Lane?.Target}</TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </div>
                </Col>
              </Row>
            </div>
          ) : OptionId === "S" ? (
            <div style={{ height: "320px" }}>
              {" "}
              <StoryPoint />{" "}
            </div>
          ) : OptionId === "E" ? (
            <div style={{ height: "320px" }}>
              {" "}
              <OrginalEstimate />{" "}
            </div>
          ) : (
            <></>
          )}
        </div>
      </DialogContent>

      {/* <DialogActions
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "10px 15px",
            }}
        >
            <Button
                onClick={() => {
                    handleClose();
                }}
                style={{
                    fontSize: 14,
                    padding: 9,
                    width: 100,
                    height: 40,
                    borderRadius: 8,
                    marginRight: 14,
                    border: "2px solid #5A88F1",
                    backgroundColor: "#fff",
                    color: "#5250E5",
                    textAlign: "center",
                    textTransform: "none",
                }}
            >
                Close
            </Button>
            <Button
                style={{
                    fontSize: 14,
                    padding: 9,
                    width: 100,
                    height: 40,
                    borderRadius: 8,
                    backgroundColor: "#5250E5",
                    color: "#fff",
                    border: "0px solid #5A88F1",
                    textAlign: "center",
                    textTransform: "none",
                }}
            >
                Create
            </Button>
        </DialogActions> */}
    </Dialog>
  );
}
