import {
  Avatar,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import MinimizeIcon from "@mui/icons-material/Minimize";
import MaximizeIcon from "@mui/icons-material/Maximize";
import { Restore } from "@mui/icons-material";
import { Col } from "reactstrap";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { currentSprintData, fetchUserById } from "./ManagerNotesslice";
import ManagerNotesupdate from "./ManagerNotesupdate";
import { MINIMIZED, ISMANAGERNOTES } from "../../redux/CommonInitialsate";
import MinimizeMaximizeButton from "./MinimizeMaximizeButton";
import ManagerNoteProgress from "./ManagerNoteProgress";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import Imageviewer from "./ProgressUpdate/Imageviewer";
import { Row } from "react-bootstrap";
import SprintDeploymentDetails from "./SprintDeploymentDetails";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "../../axios";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import Unassigned from "../../assets/images/avatar.png";

const cheerio = require("cheerio");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ManagerNotes = ({ open, setManageNoteopen, FilteredData }) => {
  const dispatch = useDispatch();

  const { allImage, projectCode, IsMinimised } = useSelector(
    (state) => state.user
  );
  const [opened, setOpen] = useState(false);
  const [imagedata, setimagedata] = useState(null);
  const [Env, setEnvCode] = useState("DEV,UAT");
  const [devozyProjects, setDevozyProjects] = useState([]);
  const [reledata, Setreledata] = useState([]);
  const [isMaximized, setIsMaximized] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [assignedmem, setassignedmem] = useState("");
  const { data, isSuccess, message, loading, ReleaseId, currentsprintdata } =
    useSelector((state) => state.note);

  useEffect(() => {
    axios
      .get(
        `agilesaas_devozy_project_mapping?AgileProjId=eq.${projectCode}&select=DevozyProjectDetails`
      )
      .then((res) => {
        console.log(res.data, "mapping data");
        setDevozyProjects(res.data?.[0]?.DevozyProjectDetails);
      })
      .catch((err) => {
        console.log(err);
      });
    const clientId = JSON.parse(localStorage.getItem("clientid"));
    let userdata = {
      clientId: clientId,
      projectCode: projectCode,
      env: Env,
    };

    dispatch(fetchUserById(userdata));
  }, [Env]);

  let color = {
    A: {
      color: "black",
      backgroundColor: "yellow",
    },
    R: {
      color: "white",
      backgroundColor: "red",
    },
    G: {
      color: "white",
      backgroundColor: "green",
    },
  };

  function FilterCheerio(a) {
    const $ = cheerio.load(a);
    $("img")?.remove();
    return $?.text();
  }

  function ImageView(e) {
    setOpen(true);
    setimagedata(e);
  }

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const appBarStyle = {
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "bold",
    gap: "4px",
  };
  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
    setIsMinimized(false);
    setManageNoteopen(true);
  };

  const toggleMinimize = () => {
    const windowElement = document.getElementById("Minimized");

    // Define event listeners for the buttons

    windowElement.style.display = "none";
    // setIsMinimized(!isMinimized);
    dispatch(MINIMIZED(true));
    dispatch(ISMANAGERNOTES(true));
    setManageNoteopen(true);

    // setIsMaximized(false);
  };
  const handleOpen = () => {
    setManageNoteopen(true);
    setIsMinimized(false);
  };
  const isElementCalled = document.getElementById("Minimized") !== null;
  return (
    <div>
      {/* <IconButton onClick={handleOpen}>
      {isMinimized ? (
        <>
          <MinimizeIcon />
          Minimize
        </>
      ) : (
        <>
          <MaximizeIcon />
          Maximize
        </>
      )}
    </IconButton> */}

      <Dialog
        id="Minimized"
        open={open}
        className="mainbox"
        maxWidth={"lg"}
        fullWidth={true}

      // fullWidth={!isMaximized && !isMinimized}
      // maxWidth={isMaximized ? false : 'lg'}
      // style={{
      //   visibility: isMinimized  ? 'hidden' : open ? 'visible' : 'visible',
      //   height: isMinimized  || !open ? 'auto' : '100%',
      //   minHeight: isMinimized || !open ? '200px' : 'initial',

      // }}
      >
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Col lg={10} md={10}>
              <label
                className="text-secondary fontdesign"
                style={{ fontWeight: "bold" }}
              >
                Sprint progress and notes
              </label>
            </Col>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton onClick={toggleMinimize}>
                {isMinimized ? (
                  <SouthEastOutlinedIcon />
                ) : (
                  <SouthEastOutlinedIcon />
                )}
              </IconButton>
              <IconButton
                onClick={() => {
                  setManageNoteopen(false);
                  dispatch(ISMANAGERNOTES(false));
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <>
            {loading ? (
              <div
                className=" d-flex align-items-center"
                style={{ justifyContent: "center", height: 460 }}
              >
                <span
                  className="spinner-border"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "rgb(82, 80, 229)" }}
                ></span>
              </div>
            ) : (
              <div>
                <AppBar
                  position="static"
                  color="#fff"
                  // style={appBarStyle}
                  className="border-bottom"
                  elevation={0}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="info"
                    // variant="fullWidth"
                    aria-label="full width tabs example"
                  // className=" ml-4 "
                  >
                    <Tab
                      className="mdi mdi-near-me"
                      style={appBarStyle}
                      label={"Sprint progress and notes"}
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="mdi mdi-cloud-check"
                      style={appBarStyle}
                      label={"Deployment Details"}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </AppBar>

                <TabPanel value={value} index={0} dir={theme.direction}>
                  <div>
                    <div>
                      {data.length !== 0 && isSuccess && !loading ? (
                        <ManagerNoteProgress
                          setEnvCode={setEnvCode}
                          sprintdetails={false}
                          reledata={reledata}
                          Setreledata={Setreledata}
                          assignedmem={assignedmem}
                          setassignedmem={setassignedmem}
                        />
                      ) : null}
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div>
                          {!isSuccess && !loading
                            ? "currently no sprint running for this project"
                            : null}
                        </div>

                        {!loading &&
                          isSuccess &&
                          data?.map(([issueId, issueData]) => {
                            const filteredData =
                              assignedmem !== ""
                                ? issueData.filter(
                                  (item) => item?.UpdatedBy === assignedmem
                                )
                                : issueData;

                            const images =
                              filteredData?.[0]?.Notes?.match(/<img .*?>/g);
                            const extractedImageSrcArray = images?.map(
                              (image) => {
                                const srcMatch =
                                  image?.match(/src=['"](.*?)['"]/);
                                return { src: srcMatch ? srcMatch[1] : null };
                              }
                            );

                            function getComparisonResult(issueData) {
                              const mutableIssueData = [...issueData];

                              mutableIssueData.sort(
                                (a, b) =>
                                  new Date(b?.UpdatedDate) -
                                  new Date(a?.UpdatedDate)
                              );

                              const [lastUpdate, secondLastUpdate] =
                                mutableIssueData.slice(0, 2);

                              const lastDate = new Date(
                                lastUpdate?.UpdatedDate
                              );
                              const previousDate = new Date(
                                secondLastUpdate?.UpdatedDate
                              );
                              let daysDifference = Math.round(
                                (lastDate - previousDate) /
                                (1000 * 60 * 60 * 24)
                              );

                              if (isNaN(daysDifference)) {
                                daysDifference = 0;
                              }

                              const lastPercentage =
                                lastUpdate?.PercentageProgressed || 0;
                              const previousPercentage =
                                secondLastUpdate?.PercentageProgressed || 0;
                              let percentageIncrease =
                                lastPercentage - previousPercentage;

                              percentageIncrease = Math.max(
                                percentageIncrease,
                                0
                              );
                              if (isNaN(percentageIncrease)) {
                                percentageIncrease = 0;
                              }

                              const comparisonResult =
                                daysDifference == 0
                                  ? `Percentage up +${percentageIncrease}% initial update`
                                  : `Percentage up +${percentageIncrease}% in ${daysDifference} days since last update.`;

                              return {
                                percentageIncrease: percentageIncrease,
                                comparisonResult: comparisonResult,
                              };
                            }

                            const result = getComparisonResult(filteredData);
                            const percentageIncrease =
                              result.percentageIncrease;
                            const comparisonResult = result.comparisonResult;

                            return (
                              <div key={issueId}>
                                {filteredData?.length > 0 && (
                                  <Card className="m-2 p-2">
                                    <div
                                      className="d-flex align-items-center"
                                      style={{ gap: "5px" }}
                                    >
                                      <Avatar
                                        sx={{ width: 25, height: 25 }}
                                        alt=""
                                        src={
                                          allImage?.length > 0 ? allImage?.filter(
                                            (x) =>
                                              x?.teammemberid ==
                                              filteredData[0]?.UpdatedBy
                                          )?.[0]?.image : Unassigned
                                        }
                                      />

                                      <p className="text-primary">
                                        {filteredData[0]?.UpdatedByDetails?.FN +
                                          " " +
                                          filteredData[0]?.UpdatedByDetails?.LN}
                                      </p>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <div>
                                          <b>
                                            {`${filteredData[0]?.title}
                                                                    -(${issueId ??
                                              "N/A"
                                              })`}
                                          </b>
                                        </div>
                                        <p>
                                          {moment(
                                            filteredData[0]?.UpdatedDate
                                          ).format("DD MMMM YYYY  h:mm:ss a")}
                                        </p>
                                      </div>
                                      <p>
                                        {filteredData[0]?.EffortRemaining
                                          ? filteredData[0]?.EffortRemaining +
                                          "days left"
                                          : null}
                                      </p>
                                    </div>

                                    <div className="d-flex justify-content-between flex-wrap">
                                      <div style={{ fontSize: "14px" }}>
                                        {filteredData[0]?.Notes !==
                                          (null || undefined)
                                          ? FilterCheerio(
                                            filteredData[0]?.Notes
                                          )
                                          : "recently moved to dev"}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        padding: "2px 0px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>
                                          {extractedImageSrcArray?.length >
                                            0 ? (
                                            filteredData[0].Notes !==
                                              (null || undefined) ? (
                                              <div
                                                onClick={() =>
                                                  ImageView(
                                                    filteredData[0].Notes
                                                  )
                                                }
                                                role="button"
                                              >
                                                <CropOriginalIcon />
                                              </div>
                                            ) : null
                                          ) : null}
                                        </p>
                                        <p className="text-dark">
                                          {filteredData[0]?.StageCode}
                                        </p>

                                        {filteredData[0]
                                          ?.PercentageProgressed != null ? (
                                          <p
                                            className="font-weight-bold"
                                            style={{ fontSize: "15px" }}
                                          >
                                            {filteredData[0]?.StageCode == "DEV"
                                              ? comparisonResult
                                              : null}
                                          </p>
                                        ) : null}
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div>
                                          {filteredData[0]?.RiskofDelivery ? (
                                            <Avatar
                                              sizes="sm"
                                              style={
                                                color[
                                                filteredData[0]
                                                  ?.RiskofDelivery
                                                ]
                                              }
                                            >
                                              {filteredData[0]?.RiskofDelivery}
                                            </Avatar>
                                          ) : null}
                                        </div>

                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          {filteredData[0]
                                            ?.PercentageProgressed ? (
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              <CircularProgressWithLabel
                                                value={
                                                  filteredData[0]
                                                    ?.PercentageProgressed
                                                }
                                              />
                                              {filteredData[0]?.StageCode ===
                                                "DEV" ? (
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    top: "90%",
                                                    right: 0,
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  +{percentageIncrease}%
                                                </div>
                                              ) : null}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                )}
                              </div>
                            );
                          })}
                      </div>
                      <div className="col-6">
                        <div>
                          {!isSuccess && !loading ? null : (
                            <ManagerNotesupdate
                            // SelectedCardId={ReleaseId}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <div className="sprint-wrapper">
                    {/* <Divider /> */}
                    {console.log(reledata?.[0])}
                    {data.length !== 0 && isSuccess && !loading ? (
                      <ManagerNoteProgress
                        setEnvCode={setEnvCode}
                        sprintdetails={true}
                        reledata={reledata}
                        Setreledata={Setreledata}
                      />
                    ) : null}
                    {/* {dropdownOption?.length > 0 ? dropdownRow() : ""} */}
                    <SprintDeploymentDetails
                      devozyProjects={devozyProjects}
                      startDate={reledata?.[0]?.SprintStartDate}
                      endDate={reledata?.[0]?.SprintEndDate}
                      currentSprint={true}
                    />
                  </div>
                </TabPanel>
              </div>
            )}

            <Imageviewer
              open={opened}
              setOpen={setOpen}
              imagedata={imagedata}
            />
          </>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default ManagerNotes;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

// totalprogresscount / totalcard = .toFixed()
