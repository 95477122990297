import React from 'react'
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useDispatch, useSelector } from 'react-redux';
import { HANDLE_ON_CHANGE } from './redux/CommonInitialsate';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Commonsnackbar() {

    const { snackmessage, snackmessagetype, snackopen } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleclosesnackbar = () => {
        // setOpen(false)
        dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: false }));
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackopen}
            autoHideDuration={1000}
            onClose={handleclosesnackbar}
        >
            <Alert
                onClose={handleclosesnackbar}
                sx={{
                    width: "100%",
                    backgroundColor: snackmessagetype == "success" ? "green !important" : "#f96868 !important",
                    color: "#fff !important",
                }}
                severity={snackmessagetype}
            >
                {snackmessage}
            </Alert>
        </Snackbar>
    )
}

export default Commonsnackbar