import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Row } from "reactstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import moment from "moment";
import axios from "../../axios";
import PatchedPagination from "../../PatchedPagination";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MaterialTable from "material-table";
toast.configure();

const LeaveManagementSystem = () => {
  let userdetails = JSON.parse(localStorage.getItem("Empdetails"));
  let userdetailsinfo = JSON.parse(localStorage.getItem("clientdetails"));
  const todayDate = moment().format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [reason, setReason] = useState("");
  const [leaveDuration, setLeaveDuration] = useState(null);
  const [validationreson, setvalidationreson] = useState(false);
  const [leavelist, setleavelist] = useState([]);
  const { projectCode, KanbanBoardData } = useSelector((state) => state.user);
  const managerid =
    localStorage.getItem("managerid") == undefined
      ? 0
      : localStorage.getItem("managerid");
  const updateLeaveDuration = () => {
    const duration = moment(endDate).diff(moment(startDate), "days") + 1;
    setLeaveDuration(duration);
  };
  const [releasedata, setReleaseData] = useState([]);

  const differences1 = (
    specialcover,
    data,
    startDate,
    endDate,
    publicHoliday
  ) => {
    const sdate = new Date(startDate);
    const edate = new Date(endDate);
    const finaldata = data?.map((leave) => {
      const removeDates =
        publicHoliday?.length == 0
          ? []
          : publicHoliday?.map((e) => moment(e.Date).format("DD-MM-YYYY"));
      const leaveStartDate = new Date(leave.LeaveStartDate);
      const leaveEndDate = new Date(leave.LeaveEndDate);
      const isSaturday =
        specialcover?.filter((s) => s.Day == "Saturday")?.length > 0; // Set to true to include Saturday
      const isSunday =
        specialcover?.filter((s) => s.Day == "Sunday")?.length > 0; // Set to true to include Sunday
      const isWeekend = (date) => {
        const day = date.getDay();
        return (!isSaturday && day === 6) || (!isSunday && day === 0);
      };

      const dateToString = (date) => {
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, "0");
        const day = `${date.getDate()}`.padStart(2, "0");
        return `${day}-${month}-${year}`;
      };
      if (leaveStartDate <= edate && leaveEndDate >= sdate) {
        let totalDays = 0;
        for (
          let date = new Date(leaveStartDate);
          date <= leaveEndDate;
          date.setDate(date.getDate() + 1)
        ) {
          const dateString = dateToString(date);
          if (
            !removeDates?.includes(dateString) &&
            !isWeekend(date) &&
            date >= sdate &&
            date <= edate
          ) {
            totalDays++;
          }
        }
        return totalDays;
      }
      return 0;
    });
    return finaldata?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  };

  useEffect(() => {
    const startdate = moment(startDate).format("YYYY-MM-DD");
    const enddate = moment(endDate).format("YYYY-MM-DD");
    axios
      .get(
        `agilesaas_releases?ProjectId=eq.${projectCode}&CreatedBy=eq.${managerid}&SprintStartDate=lte.(${enddate})&&SprintEndDate=gte.(${startdate})`
      )
      .then((res) => {
        console.log(res.data, "release data");
        const data = res.data;
        const outdata = data.map((a) => {
          const finduser =
            a?.TeamLeavesConsidered == null
              ? []
              : a?.TeamLeavesConsidered.filter(
                (x) => x.UserId == userdetails?.[0]?.ClientUserId
              );
          console.log(finduser, "finduser", userdetails);
          if (finduser.length > 0) {
            const finaldata = differences1(
              a?.SpecialCover,
              [{ LeaveStartDate: startDate, LeaveEndDate: endDate }],
              a?.SprintStartDate,
              a?.SprintEndDate,
              a?.PublicHolidays
            );
            const fdata = a?.TeamLeavesConsidered?.map(user => {
              if (user.UserId === userdetails?.[0]?.ClientUserId) {
                return { ...user, Leavesplanned: finduser?.[0]?.Leavesplanned + finaldata, Availabledays: finduser?.[0]?.Availabledays - finaldata };
              }
              return user;
            });
            return { ...a, TeamLeavesConsidered: fdata, Totalavailabledays: a?.Totalavailabledays - finaldata };
            //console.log(fdata, finaldata, "finaldata", tavailabledays);
          }
        });
        console.log(outdata, "outdata")
        setReleaseData(outdata);
      });
    if (userdetails != null && userdetailsinfo != null) {
      axios
        .get(
          `agilesaas_holidaysandleaves?ClientId=eq.${userdetails[0].ClientId}&&UserId=eq.${userdetails[0].ClientUserId}`
        )
        .then((res) => {
          setleavelist(res.data);

          console.log(userdetails[0].ClientId);
        })
        .catch((e) => {
          alert("something went wrong");
        });

      // Calculate leave duration when startDate or endDate changes
    }
    updateLeaveDuration();
  }, [startDate, endDate, userdetails ? userdetails[0].ClientId : []]);
function refresh(){
  if (userdetails != null && userdetailsinfo != null) {
    axios
      .get(
        `agilesaas_holidaysandleaves?ClientId=eq.${userdetails[0].ClientId}&&UserId=eq.${userdetails[0].ClientUserId}`
      )
      .then((res) => {
        setleavelist(res.data);

        console.log(userdetails[0].ClientId);
      })
      .catch((e) => {
        alert("something went wrong");
      });

    // Calculate leave duration when startDate or endDate changes
  }
}
  const useStyles = makeStyles({
    datePickerInput: {
      marginTop: "2%",
      width: "100%",
      border: "none",
      "&:MuiInputBase-root": {
        border: "none",
        padding: "2%",
      }, // Remove the default border
      "&:focus": {
        outline: "none", // Optionally, remove the focus outline as well
      },
    },
    root: {
      "& .MuiBox-root": {
        paddingTop: 2,
        margin: 1,
      },
      "& .MuiAppBar-root": {
        // boxShadow: "none", // Remove the box shadow from the tab header
        paddingLeft: 0,
        margin: 0,
      },
      "& .MuiTabs-root": {
        display: "flex", // Use flex layout
        justifyContent: "flex-start",
      },
    },
  });
  const classes = useStyles();
  function isLeaveAlreadyApplied(leaveList, startDate, endDate) {
    for (let i = 0; i < leaveList.length; i++) {
      const leave = leaveList[i];
      const leaveStartDate = moment(leave.LeaveStartDate);
      const leaveEndDate = moment(leave.LeaveEndDate);
      startDate = moment(startDate);
      endDate = moment(endDate);

      // Check if the given dates overlap with any existing leave
      if (
        (startDate.isSameOrAfter(leaveStartDate) &&
          startDate.isSameOrBefore(leaveEndDate)) ||
        (endDate.isSameOrAfter(leaveStartDate) &&
          endDate.isSameOrBefore(leaveEndDate)) ||
        (startDate.isSameOrBefore(leaveStartDate) &&
          endDate.isSameOrAfter(leaveEndDate))
      ) {
        return true; // Leave is already applied during the specified period
      }
    }

    return false; // Leave is not applied during the specified period
  }
  const submitleave = () => {
    if (userdetails != null) {
      if (endDate >= startDate) {
        if (reason.trim().length > 3) {
          const leaveAlreadyApplied = isLeaveAlreadyApplied(
            leavelist,
            startDate,
            endDate
          );
          if (leaveAlreadyApplied) {
            alert("Leave is already applied during the specified period.");
          } else {
            let data = {
              ClientId: userdetails[0].ClientId,
              ClientDetails: userdetails[0].ClientDetails,
              ProjectId: moment().year(),
              ProjectDetails: null,
              LeaveStartDate: startDate,
              LeaveEndDate: endDate,
              NumberOfDays: leaveDuration,
              SubmittedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              LeaveNotes: {
                note1: reason,
              },
              IsCancelled: null,
              IsActive: "Y",
              Status: null,
              UserId: userdetails[0].ClientUserId,
              UserDetails: userdetailsinfo[0]?.UserDetails,
            };

            axios
              .post("agilesaas_holidaysandleaves", data)
              .then((res) => {
                if (releasedata.length > 0) {
                  releasedata.map((o) => {
                    axios.patch(`agilesaas_releases?ReleaseId=eq.${o.ReleaseId}`, {
                      TeamLeavesConsidered: o.TeamLeavesConsidered,
                      Totalavailabledays: o.Totalavailabledays
                    })
                      .then((res) => console.log("successfully update"))
                      .catch((e) => console.log(e))
                  })
                }
                refresh()
                setReason("");
                setStartDate(todayDate);
                setEndDate(todayDate);
                // alert("Submitted successfully");
                toast.success(`Submitted successfully`, {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1500,
                  draggable: true,
                });
              })
              .catch((e) => {
                alert("something went wrong");
              });
          }
        } else {
          setvalidationreson(true);
          setTimeout(() => {
            setvalidationreson(false);
          }, 4000);
        }
      } else {
        console.log(startDate);
        console.log(endDate);
        alert("End date should be greater or equal to start date");
      }
    } else {
      alert("not submited");
      console.log(userdetails);
    }
  };
  const Columns = [
       
    {
      title: "UserId",
      field: "UserId",
      headerStyle: { fontWeight: "bold", width: "10%" },
      cellStyle: { width: "10%", textAlign: "left" },
    },
    {
      title: "Name",
      field: "Name",
      headerStyle: { fontWeight: "bold", width: "5%" },
      cellStyle: { width: "20%", textAlign: "left" },
    },
    {
      title: "Leave Start date ",
      field: "LeaveStartDate",
      headerStyle: { fontWeight: "bold", width: "10%" },
      cellStyle: { width: "10%", textAlign: "left" },
    },
    {
      title: "Leave End Date",
      field: "LeaveEndDate",
      headerStyle: { fontWeight: "bold", width: "20%" },
      cellStyle: { width: "10%", textAlign: "left" },
    },
    {
      title: "No of days",
      field: "NumberOfDays",
      headerStyle: { fontWeight: "bold", width: "15%" },
      cellStyle: { width: "15%", textAlign: "left" },
    },
    {
        title: "Submitted Date",
        field: "SubmittedDate",
        headerStyle: { fontWeight: "bold", width: "15%" },
        cellStyle: { width: "15%", textAlign: "left" },
      },
    {
      title: "Summary",
      field: "leavenotes",
      headerStyle: { fontWeight: "bold", width: "15%" },
      cellStyle: { width: "15%", textAlign: "left" },
    },
    
   
  ];
  console.log(releasedata, "releasedata")

  return (
    <div style={{ backgroundColor: "rgb(246, 247, 250)" }}>
      <label className="ProjectlabelStyle1 ml-4 mt-2 pt-2">
        Leave submission form
      </label>

      <div
        className="container  "
        style={{
          paddingTop: "5%",
          paddingBottom: "5%",
          width: "90%",
          marginBottom: "10px",
          backgroundColor: "rgb(255, 255, 255)",
        }}
      >
        <Form className="pt-4">
          <Row>
            <Form.Group as={Col} style={{ paddingBottom: 0, marginBottom: 0 }}>
              <Form.Label className="SprintlabelStyle">
                Start Date
                <label className="createAlert">*</label>
              </Form.Label>
              <div className="test">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    InputProps={{
                      style: {
                        outlineColor: "red",
                      },
                    }}
                    classes={{
                      root: classes.datePickerInput, // Apply the custom styles to the root element
                    }}
                    value={startDate}
                    className="datepickerDesign"
                    onChange={(date) => {
                      setStartDate(moment(date).format("YYYY-MM-DD"));
                    }}
                    format="dd-MM-yyyy"
                    views={["year", "month", "date"]}
                    // disablePast
                    required
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Form.Group>
            <Form.Group as={Col} style={{ paddingBottom: 0, marginBottom: 0 }}>
              <Form.Label className="SprintlabelStyle">
                End Date
                <label className="createAlert">*</label>
              </Form.Label>
              <div className="test">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    InputProps={{
                      style: {
                        outlineColor: "red",
                      },
                    }}
                    classes={{
                      root: classes.datePickerInput, // Apply the custom styles to the root element
                    }}
                    className="datepickerDesign"
                    value={endDate}
                    onChange={(date) => {
                      setEndDate(moment(date).format("YYYY-MM-DD"));
                    }}
                    format="dd-MM-yyyy"
                    views={["year", "month", "date"]}
                    // disablePast
                    required
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              className="mt-3"
              as={Col}
              style={{ paddingBottom: 0, marginBottom: 0 }}
            >
              <Form.Label className="SprintlabelStyle">
                No of days : {leaveDuration}
              </Form.Label>
            </Form.Group>
          </Row>
          <Row className="mb-3 mt-3">
            <Form.Group as={Col} style={{ paddingBottom: 0, marginBottom: 0 }}>
              <Form.Label className="SprintlabelStyle">
                Reason
                <label className="createAlert">*</label>
              </Form.Label>
              <Form.Control
                className={
                  validationreson == false
                    ? "SprintFormFields"
                    : "SprintFormFields error"
                }
                as="textarea"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                rows={3}
                style={{
                  width: "99.5%",
                }}
              />
              {validationreson ? (
                <p className="text-danger">reason cannot be empty</p>
              ) : (
                ""
              )}
            </Form.Group>
          </Row>
          <Row>
            <Button
              style={{
                marginLeft: 20,
                fontSize: 14,
                padding: 9,
                width: 100,
                height: 40,
                borderRadius: 8,
                backgroundColor: "#5250E5",
                color: "#fff",
                border: "0px solid #5A88F1",
                textAlign: "center",
                textTransform: "none",
              }}
              onClick={submitleave}
            >
              Submit
            </Button>
          </Row>
        </Form>

      </div>
      <div className="tabel">
         <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      ></link>
      <MaterialTable
        title={"My  Leaves"}
        columns={Columns.map((e) => e)}
        data={leavelist?.sort((a, b) => b.LeaveHolidayId - a.LeaveHolidayId).map((e, i) => {
            return {
                ...e,
                Name:e.UserDetails.Firstname+" "+e.UserDetails.Lastname,
                    LeaveStartDate:moment(e.LeaveStartDate).format("DD MMM YYYY"),
                    LeaveEndDate:moment(e.LeaveEndDate).format("DD MMM YYYY"),
                    SubmittedDate:moment(e.SubmittedDate).format("DD MMM YYYY"),
                    leavenotes:e.LeaveNotes.note1
            
            }
        })}
        components={{
          Pagination: PatchedPagination,
        }}
        options={{
          search: true,
          actionsColumnIndex: -1,
          exportAllData: true,
          exportButton: true,
          columnResizable: true,
          filtering: false,
          sorting: true,
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 30, 40],

          headerStyle: {
            whiteSpace: 'nowrap',
            // backgroundColor: "#8FD6E1",
            // color: "#03256C",
            width: "1000px",
            // textAlign: "center",

            padding: "2px",
            fontWeight: "700",
            fontSize: "small",
            // backgroundColor: "#eef2f7",
            color: "black",
          },
          cellStyle: {
            textAlign: "left",
            fontSize: "12px",
            padding: "2px",
            color: "#6c757d",
          },
          rowStyle: (rowdata) => {
            if (rowdata.tableData.id % 2 != 0) {
              // return { backgroundColor: "#cddef085" };
            }
          },
        }}
      />
    </div>
    </div>
  );
};

export default LeaveManagementSystem;
