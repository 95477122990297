import React, { useState, useEffect } from "react";
import axios from "../../axios";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import Bookmark from "@material-ui/icons/Bookmark";
import { toast, Slide } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import {
  Avatar,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import Button from "@mui/material/Button";

import "./UATtester.css";
import moment from "moment";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, CardContent } from "@mui/material";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Unassigned from "../../assets/images/avatar.png";
import { HANDLE_ON_CHANGE } from "../../redux/CommonInitialsate";

function UATtester({
  // UATtesterD,
  // setUATtesterD,
  // IssueId,
  // selectedCard,
  // setRefresh,
  // uattesterassign,
  // setuattesterassign,
  // projectRole,
}) {
  const { testersImage, allImage, projectRole, selectedCard, IssueId, UATtesterD, uattesterassign } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  let rolecodedata =
    localStorage.getItem("Rolecode") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Rolecode"));
  let clientd =
    rolecodedata == "DEV" && projectRole == "DEV"
      ? JSON.parse(localStorage.getItem("clientid"))
      : JSON.parse(localStorage.getItem("clientid"));
  // let arr = JSON.parse(sessionStorage.getItem("Roledetails"));
  let emplogin = JSON.parse(localStorage.getItem("Empdetails"));
  let login = emplogin != null ? emplogin : null;
  let crtid = emplogin[0]?.ClientUserId;
  // emplogin != null ? emplogin[0]?.OnboardedBy === 800001 ? 1 : emplogin[0]?.OnboardedBy : "";
  const { projectCode } = useSelector((state) => state.user);
  let managerid =
    localStorage.getItem("managerid") == "undefined"
      ? 0
      : JSON.parse(localStorage.getItem("managerid"));
  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == "11004")[0]
    ?.RoleCode;
  // const UATDrop =
  //   projectRole == "SM"
  //     ? // ? `rpc/fun_mgrprojects?managerid=${arr.empid}&projectrole=eq.508`
  //     `rpc/fun_agilesaasmanagerteamprojdetails?managerid= ${crtid}&clientid=${clientd}
  //     &projectrole->>RoleGroupId=eq.5`
  //     : `rpc/fun_agilesaasmanagerteamprojdetails?managerid=${crtid}&clientid=${clientd}
  //   &projectrole->>RoleGroupId=eq.5`;
  const UATDrop =
    getProdRole == "KA" || projectRole == "SM"
      ? // ? `rpc/fun_mgrprojects?managerid=${arr.empid}&projectrole=eq.508`
      `rpc/fun_agilesaasmanagerteamprojdetails?managerid=${crtid}&clientid=${clientd}&projectrole->>RoleGroupId=eq.5`
      : `rpc/fun_agilesaasmanagerteamprojdetails?managerid=${managerid}&clientid=${clientd}&projectrole->>RoleGroupId=eq.5`;
  // : `rpc/fun_mgrprojects?managerid=${arr.level1managereid}&projectrole=eq.508`;
  // console.log(selectedCard, "selectedcard");

  const [uatteserdetails, setuatteserdetails] = useState([]);
  const [selectuat, setselectuat] = useState(selectedCard?.AssignedToUAT);
  const [testernamef, settesternamef] = useState("");
  const [testernamel, settesternamel] = useState("");
  const [checkSubmit, setSubmitCheck] = useState(false);
  // console.log(selectuat, "selectuat", selectedCard?.AssignedToUAT);
  // if (uattesterassign == true) {
  //   axios
  //     .get(UATDrop
  //     )
  //     .then((res) => {
  //       setuatteserdetails(res.data?.filter((e) => e.projectcode == selectedCard?.ProjectId));
  //       setuattesterassign(false);
  //     })
  //     .catch((error) => console.log(error), setuattesterassign(false));
  // }
  useEffect(() => {
    axios
      .get(
        UATDrop
        // "rpc/fun_agilesaassmprojandteamdetails?managerid=" +
        // arr.AccessRoleID +
        // "&projectrole->>RoleGroupId=eq.14"
      )
      .then((res) => {
        setuatteserdetails(
          // res.data
          res.data.filter((e) => e.projectcode == selectedCard?.ProjectId)
        );
      });
  }, [selectedCard?.ProjectId]);
  let testoption =
    uatteserdetails == ""
      ? ""
      : uatteserdetails?.map((e) => {
        return {
          value: e.teammemberid,
          label: e.firstname + " " + e.lastname,
          Fn: e.firstname,
          Ln: e.lastname,
          icon: (
            <Avatar
              alt={e.firstname + " " + e.lastname}
              src={
                allImage?.length > 0 ? allImage?.filter(
                  (x) => x.teammemberid == e.teammemberid
                )?.[0]?.image : Unassigned
              }
            />
          ),
          // <Avatar className="progressName">{e.firstname.charAt(0) + e.lastname.charAt(0)}</Avatar>
        };
      });

  const handleClose = () => {
    // console.log(
    //   selectedCard?.AssignedToUAT == null,
    //   selectedCard?.AssignedToUAT == "",
    //   selectedCard?.AssignedToUAT
    // );
    // if (selectuat == "" || selectuat == null) {
    //   toast.error("Please select tester");
    // }
    // else {
    dispatch(HANDLE_ON_CHANGE({ name: 'UATtesterD', value: false }))
    // setUATtesterD(false);
    setselectuat("");
    // setRefresh(true);
    dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
    // }
  };
  const handleChangetester = (e) => {
    console.log(e.value);
    setselectuat(e.value);
    settesternamef(e.Fn);
    settesternamel(e.Ln);
  };

  const prevent = () => {
    let patchdatapr = {
      CurrentStage: [
        {
          StageCode: "DEV",
          StageName: "In Development",
          DateMoved: moment().format("YYYY-MM-DD"),
        },
      ],
    };
    axios
      .patch("agilesaas_issue_details?IssueId=eq." + IssueId, patchdatapr)
      .then((res) => {
        handleClose();
      });
  };
  const submitester = () => {
    if (selectuat == "" || selectuat == null) {
      toast.error("Please select tester");
    } else {
      let patchdata = {
        CurrentStage: [
          {
            StageCode: "UAT",
            StageName: "User Acceptace Testing",
            DateMoved: moment().format("YYYY-MM-DD"),
          },
        ],
        AssignedToUAT: selectuat,
        AssignedToUATDetails: {
          FN: testernamef,
          LN: testernamel,
        },
      };

      let postdata = {
        IssueId: IssueId,
        ChangedBy: login?.[0]?.ClientUserId,
        ChangedByDetails: {
          FN: login?.[0]?.UserFirstname,
          LN: login?.[0]?.UserLastname,
        },
        ChangedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
        UpdatedField: "Assigned a Tester",
        // UpdatedFrom: ,
        UpdatedTo: testernamef + " " + testernamel,
        UpdateSequence: "1",
        IsActive: "Y",
        Status: null,
        ClientId: clientd,
        ProjectId: projectCode,
      };
      setSubmitCheck(true);
      axios.post("agilesaas_issue_history", postdata).then((res) => {
        console.log(res);
      });
      axios
        .patch("agilesaas_issue_details?IssueId=eq." + IssueId, patchdata)
        .then((res) => {
          toast.success(`Tester Assigned Successfully`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          });
          setSubmitCheck(false);
          handleClose();
        });
    }
  };
  // console.log(selectedCard, selectuat);
  const check = selectedCard?.AssignedToUAT;
  let assignedValue = check ? check : selectuat;
  // console.log(assignedValue);
  return (
    <React.Fragment>
      {/* <Dialog open={UATtesterD} onClose={() => { }}>
        <DialogTitle className="backbg">
          <Card className="UATtesterCard1">
            <Row>
              <Col lg={10} md={10} sm={10}>
                <div className="header_style">Assign a tester</div>
              </Col>
              <Col lg={2} md={2} sm={2}>
                <IconButton onClick={prevent}>
                  <CloseIcon />
                </IconButton>
              </Col>
            </Row>

            <table className="UATBGCOLOR">
              <th colSpan="2">
                <label className="UATtitle">
                  <Bookmark
                    className="kanbanicon1 mt-2"
                    style={{ backgroundColor: "#00cc00" }}
                  />
                  {selectedCard.IssueTitle == ""
                    ? ""
                    : " " + selectedCard.IssueTitle}
                </label>
              </th>
              <td className="" colSpan="2">
                <span class="badge badge-info ">DEV</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
                <span class="badge badge-success bdgefont">UAT</span>
              </td>
              <tr>
                <td className="UATttd">
                  <label className="mt-2">Tester</label>
                </td>
                <td className="UATttd">
                  {" "}
                  <Select
                    className="UATselect mt-2"
                    options={testoption}
                    onChange={handleChangetester}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <center>
                    <Button
                      className="mt-5 ml-5 Card_Button_Color_Approve btn-greeng"
                      onClick={submitester}
                    >
                      Confirm
                    </Button>
                  </center>
                </td>
              </tr>
            </table>
          </Card>
        </DialogTitle>

        <DialogActions></DialogActions>
      </Dialog> */}
      <Dialog fullWidth={true} maxWidth={"xs"} open={UATtesterD}>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box>
              <Typography
                style={{
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: 16,
                  fontWeight: 800,
                  textAlign: "left",
                }}
              >
                Assign a Tester
              </Typography>
            </Box>
            <Box>
              <Typography
                onClick={() => handleClose()}
                style={{ cursor: "pointer", textAlign: "right" }}
              >
                <CloseIcon />
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <Divider />

        <DialogContent style={{ padding: "25px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontFamily: "DM Sans, sans-serif",
                fontSize: 16,
                fontWeight: 700,
                color: "#6e6e6e",
              }}
            >
              <CheckCircleOutlineIcon color="success" className="check-icon" />
              {selectedCard?.IssueTitle == ""
                ? ""
                : " " + selectedCard?.IssueTitle}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                size="small"
                style={{ backgroundColor: "#1a89e1", borderRadius: "20px" }}
              >
                Dev
              </Button>
              <KeyboardDoubleArrowRightIcon />
              <Button
                variant="contained"
                size="small"
                style={{ backgroundColor: "#5AE6A9", borderRadius: "20px" }}
              >
                UAT
              </Button>
            </div>
          </div>

          <Box style={{ margin: "15px 0" }}>
            <CardContent style={{ paddingBottom: 0 }}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item lg={4} md={4} sm={2} xs={12}>
                  <Form.Label>
                    <Typography
                      variant="h6"
                      style={{
                        fontFamily: "DM Sans, sans-serif",
                        fontSize: 18,
                        fontWeight: 700,
                        color: "#000",
                      }}
                    >
                      Tester
                    </Typography>
                  </Form.Label>
                </Grid>
                <Grid item lg={8} md={8} sm={10} xs={12}>
                  <Select
                    maxMenuHeight={120}
                    options={testoption.length == 0 ? [] : testoption}
                    onChange={handleChangetester}
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {e.icon}
                        <span style={{ marginLeft: 10 }}>{e.label}</span>
                      </div>
                    )}
                    value={
                      testoption.length == 0
                        ? ""
                        : testoption?.filter((e) => e.value == assignedValue)
                    }
                  // value={selectuat == '' && selectedCard.AssignedToUAT != null && testoption.length != 0 ?
                  //   testoption?.filter(
                  //     (option) => option.value == selectedCard.AssignedToUAT
                  //   ) : testoption.length == 0 ? "" : testoption?.filter(
                  //     (option) => option.value == selectuat
                  //   )}
                  // formatOptionLabel={tester => (
                  //   <div className="select-options">
                  //     <span>{tester.label}</span>
                  //     <img src={tester.image} alt="dropdown-image" />
                  //   </div>
                  // )}
                  />
                  {/* {console.log(testoption)} */}
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "10%",
                }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  style={{
                    fontSize: 14,
                    padding: 9,
                    width: 100,
                    height: 40,
                    borderRadius: 8,
                    backgroundColor: "#5250E5",
                    color: "#fff",
                    border: "0px solid #5A88F1",
                    textAlign: "center",
                    textTransform: "none",
                    marginRight: 24,
                    alignContent: "center",
                  }}
                  onClick={submitester}
                  disabled={checkSubmit}
                >
                  {checkSubmit ? (
                    <span
                      className="spinner-border"
                      role="status"
                      aria-hidden="true"
                      style={{
                        color: "#fff",
                        width: "1.5rem",
                        height: "1.5rem",
                      }}
                    ></span>
                  ) : (
                    "Confirm"
                  )}
                </Button>
              </div>
            </CardContent>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default UATtester;
