import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { Button, Col, Row, Container } from "reactstrap";
import AccessRequestForm from "./AccessRequest/AccessRequestForm";
import GenericRequestForm from "./GenericRequest/GenericRequestForm";
import RequestFormnew from "./requests/RequestFormnew";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ITRequestTable from "./requests/RequestTable";
import { Modal } from "react-bootstrap";
import "./Something.css";
import Select from "react-select";


function RequestSomething() {

  const [assetsform, setAssetsform] = useState(false);
  const [access, setAccess] = useState(false);
  const [generic, setGeneric] = useState(false);
  const [view, setView] = useState("cardview");

  const [refreshdata, setRefreshdata] = useState([]);

  let ID = JSON.parse(localStorage.getItem("Empdetails"));
  let ID1 = ID[0].ClientUserId;
  let sessionvariable = sessionStorage.getItem("Roledetails") === "undefined" ? "" : JSON.parse(sessionStorage.getItem("Roledetails"));
  let isseparationinitiated = sessionvariable?.IsDelegationAllowed;

  function RefreshDatas() {
    axios
      .get("rpc/fun_agilesaasitrequestsbymember?teammemberid=" + ID1)
      .then((res) => setRefreshdata(res.data))
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    RefreshDatas();
  }, []);

  const Change = () => {
    setAssetsform(true);
    setAccess(false);
    setGeneric(false);
  };

  const Access = () => {
    setAccess(true);
    setGeneric(false);
    setAssetsform(false);
  };
  const customStylesd = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const viewoption = [
    { label: "CardView", value: "cardview" },
    { label: "TabelView", value: "tabelview" },
  ];

  const Generic = () => {
    setGeneric(true);
    setAccess(false);
    setAssetsform(false);
  };
  const handleclose = () => {
    setGeneric(false);
    setAccess(false);
    setAssetsform(false);
  };


  return (
    <div style={{ backgroundColor: "#F5F7FB"}}>
  
       
        <Col className="d-flex justify-content-start">
          <button
            variant="outline-secondary"
            size="sm"
            fontSize="small"
            
            className="btn btn-outline-info btn-sm fontdesign mr-3 mt-3"
            style={{ fontWeight:"normal",width:"160px",borderRadius:"8px" }}
            onClick={Change}
          >
            <ControlPointIcon fontSize="small" />  Asset Request
          </button>

          <button
            variant="outline-secondary"
            size="sm"
            fontSize="small"
            className="btn btn-outline-success btn-sm fontdesign mr-3 mt-3"
            style={{  fontWeight:"normal",width:"160px",borderRadius:"8px" }}
            onClick={Access}
          >
            <ControlPointIcon fontSize="small" />  Access Request
          </button>

          <button
            variant="outline-secondary"
            size="sm"
            fontSize="small"
            className="btn btn-outline-warning btn-sm fontdesign mt-3"
            style={{  fontWeight:"normal",width:"160px",borderRadius:"8px"}}
            onClick={Generic}
          >
            <ControlPointIcon fontSize="small" /> Generic Request
          </button>
        </Col>
        <Row className="d-flex justify-content-end" >
              <span className="labelStyle  mt-3">Layout :</span>
              <Select
                className="fontdesign viewselect mt-2 mr-5 ml-2"
                styles={customStylesd}
                onChange={(e) => setView(e.value)}
              
                options={viewoption}
                value={viewoption?.filter((e) => e.value == view)}
              ></Select>
            </Row>

      <Modal
        // style={{width:"1000px"}}
        dialogClassName="assetbox"
        show={assetsform}
        onHide={handleclose}
      >
        <Modal.Header >
          <Modal.Title className="TitlelabelStyle">
          <label style={{color:"#2593B8",fontWeight:"bold"}}>    New Asset Request</label>
          </Modal.Title>
          <button type="button" className="close customCloseIconStyle" onClick={handleclose}>
          &times;
        </button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {assetsform === false ? null : isseparationinitiated === "Y" ? (
              <h3 className="d-flex justify-content-center text-danger">
                You are not aligible to Request Asset
              </h3>
            ) : (
              <RequestFormnew
                RefreshDatas={RefreshDatas}
                ID={ID1}
                setGeneric={setGeneric}
                setAccess={setAccess}
                setAssetsform={setAssetsform}
              />
            )}
          </Container>
        </Modal.Body>
      </Modal>

      <Modal
        // style={{width:"1000px"}}
        dialogClassName="acessbox"
        show={access}
        onHide={handleclose}
      >
        <Modal.Header >
          <Modal.Title >
          <label style={{color:"#2593B8",fontWeight:"bold"}}>   New Access Request</label>
          
          </Modal.Title>
          <button type="button" className="close customCloseIconStyle" onClick={handleclose}>
          &times;
        </button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {access === false ? null : isseparationinitiated === "Y" ? (
              <h3 className="d-flex justify-content-center text-danger">
                You are not aligible to Request Access
              </h3>
            ) : (
              <>
                <AccessRequestForm RefreshDatas={RefreshDatas}    setGeneric={setGeneric}
                setAccess={setAccess}
                setAssetsform={setAssetsform} />
              </>
            )}
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        // style={{width:"1000px"}}
        dialogClassName="handleclose"
        show={generic}
        onHide={handleclose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="TitlelabelStyle">
          <label style={{color:"#2593B8",fontWeight:"bold"}}>  New Generic Request</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {generic ? (
              <>
                <GenericRequestForm RefreshDatas={RefreshDatas}    setGeneric={setGeneric}
                setAccess={setAccess}
                setAssetsform={setAssetsform}/>
              </>
            ) : null}
          </Container>
        </Modal.Body>
      </Modal>

      <div className="mt-2">
        <ITRequestTable
          itrequest_user={refreshdata}
          RefreshData={RefreshDatas}
          view={view}
          setView= {setView}
        />
      </div>
    </div>
  );
}

export default RequestSomething;
