import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Col, Row, Table, Container } from "reactstrap";
import Select from "react-select";
import MaterialTable from "material-table";
import CircleIcon from "@mui/icons-material/Circle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Card } from "reactstrap";
import ReactImageFallback from "react-image-fallback";
import { Modal } from "react-bootstrap";
import moment from "moment";
import PatchedPagination from "../../../PatchedPagination";
import React, { useState } from "react";
import RequestRework from "./RequestRework";
import "./Requesttabel.css";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from 'react-redux'
import Anne from "../../../assets/images/anne.jpg";
import Derie from "../../../assets/images/Admin.png";
import Shankar from "../../../assets/images/aravindh.png";
import Aravind from "../../../assets/images/Kandhasamy.png";
import avatar from "../../../assets/images/faces/sri1.jpg";
import Unassigned from "../../../assets/images/avatar.png";

const ITRequestTable = ({ itrequest_user, RefreshData, view }) => {
  const [open, setOpen] = useState(false);
  const [maindata, setMaindata] = useState("");
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const [priiortyfilter, SetPriortiryf] = useState("");
  const [Catoryfilter, setCateoryfilter] = useState("");
  const [DateF, SetDateF] = useState(null);

  const Columns = [
    { title: "Title", field: "title" },

    { title: "ID", field: "itrequestid", defaultSort: "desc" },
    { title: "Request", field: "itemtypetype" },
    { title: "Request Type", field: "reasondescription" },
    { title: "Item Type", field: "typedescription" },

    // { title: "Expected date", field: "expecteddate" },
    { title: "Status", field: "status" },
    { title: "Date", field: "submitteddate" },
  ];
  const customStylesd = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const handleClickOpen = (e) => {
    setOpen(true);
    setMaindata(e);
  };
  const handleClickCard = (index, e) => {
    // setcardid(e);

    setActiveCardIndex(index);
  };
  const dispatch = useDispatch()
  const { allImage } = useSelector((state) => state.user)

  const handleClose = () => {
    setOpen(false);
    RefreshData();
  };
  let Categoryoption = [
    { value: "Asset", label: "Asset Request" },
    { value: "Application", label: "Access Request" },
    { value: "Common", label: "Generic Request" },
  ];
  function datefilter(item) {
    SetDateF(item.value);
  }
  let dateoption = [
    {
      value: moment().subtract(1, "months").startOf("month"),
      label: "OnemonthBefore",
    },
    {
      value: moment().subtract(1, "days").startOf("day"),
      label: "Yesterday",
    },

    { value: moment(), label: "Today" },
  ];

  function clear() {
    SetDateF(null);
    setCateoryfilter("");
  }
  function handlecategory(item) {
    setCateoryfilter(item.value);
  }

  const check =
    Catoryfilter != ""
      ? itrequest_user?.filter((e) => e.itemtypetype == Catoryfilter)
      : DateF != null
        ? itrequest_user?.filter((e) => e.submitteddate == DateF)
        : Catoryfilter != "" && DateF != null ?
          itrequest_user?.filter((e) => e.submitteddate == DateF && e.itemtypetype == Catoryfilter)
          : itrequest_user;

  return (
    <div>
      <Modal
        className="CreateProMadal"
        // size="500px"
        show={open}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fontdesign">
            <label
              className="modeltitle fontdesign"
              style={{ fontSize: "18px" }}
            >
              {" "}
              User Note - {maindata.itrequestid}
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="KanbanModal">
          {/* <Container> */}

          <RequestRework rowData={maindata} />

          {/* </Container> */}
        </Modal.Body>
      </Modal>

      {/* <Dialog
        open={open}
        // className="mainbox"
        onClose={handleClose}
        maxWidth="800px"
      >
        <DialogTitle>
         
          <Row>
            <Col lg={11} md={11} sm={11}>
              <div className="header_style secondary">
                User Note 

              </div>
              <hr></hr>
              <div style={{fontSize:"18px"}}>
              Request Id -{maindata.itrequestid}{" "}
              </div>
            </Col>
            <Col lg={1} md={1} sm={1}>

              <Button className="head" onClick={handleClose}>
                X
              </Button>
            </Col>
          </Row>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <RequestRework rowData={maindata} />
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog> */}
      <div className="row">
        <div className="col-9">
          {view == "cardview" ? (
            check === "" ? (
              ""
            ) : check && check.length === 0 ? (
              <div className="ml-2 mt-2 text-danger">No Data Found</div>
            ) : (
              check
                .sort((m, l) => (m.itrequestid < l.itrequestid ? 1 : -1))
                .map((e, item) => {
                  const isActiveCard = activeCardIndex === item;

                  return (
                    <div className=" ml-2 mt-2" key={item}>
                      <Card
                        className={` ${isActiveCard
                          ? "active-card shadow-sm"
                          : "inactive-card shadow-sm"
                          }`}
                        // style={{

                        //   padding: "15px",
                        //   border: "1px solid 	  #bfbfbf",

                        // }}
                        onClick={() => handleClickCard(item, e)}
                      // className="mt-3 ml-1"
                      // style={{
                      //   backgroundColor: "white",
                      //   padding: "15px",
                      //   border: "1px solid 	  #bfbfbf",
                      //   // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                      //   // outlineColor: "black",
                      //   width: "80%",
                      // }}
                      >
                        <Row style={{ marginTop: "-10px" }}>
                          <Col>
                            <Row className="d-flex justify-content-space between ">
                              <ReactImageFallback
                                src={allImage?.length > 0 && allImage?.filter((x) => x.teammemberid == e.requestedby)?.[0]?.image != undefined ? allImage?.filter((x) => x.teammemberid == e.requestedby)?.[0]?.image : Unassigned}

                                // src={
                                //   e.requestedbyfn === "Kandhasamy" &&
                                //   e.requestedbyln === "A"
                                //     ? Aravind
                                //     : e.requestedbyfn === "Derie" &&
                                //       e.requestedbyln === "Frank"
                                //     ? Derie
                                //     : e.requestedbyfn === "aravind" &&
                                //       e.requestedbyln === "subramani"
                                //     ? Shankar
                                //     : avatar
                                // }
                                // fallbackImage={require("assets/img/default-avatar.png").default}
                                initialImage="loader.gif"
                                alt="cool image should be here"
                                className="NameShowavatar"
                              //  style={{ height: "60px", width: "60px", borderRadius: "50%" ,marginLeft:"25px",fontSize:"10px"}}
                              />

                              <div>
                                <label
                                  className="fontdesign ml-3 mt-3"
                                  style={{ fontSize: "12px" }}
                                >
                                  {e.reasondescription}
                                </label>
                                <br></br>
                                <label className="fontdesign  mt-1 ml-3  title">
                                  {e.itemtypetype}
                                </label>
                                <br></br>

                                <span
                                  className="fontdesign mt-1 ml-3"
                                  style={{ fontSize: "14px" }}
                                >
                                  <label>
                                    {e.requestedbyfn + " " + e.requestedbyln}
                                  </label>{" "}
                                  <label className="ml-3">
                                    (
                                    {e.raisingonbehalfdetails == null
                                      ? "Self"
                                      : "Someone:" +
                                      "On Behalf : " +
                                      e.raisingonbehalfdetails}
                                    )
                                  </label>{" "}
                                  <label className="ml-3">
                                    {" "}
                                    {e.requesttype}
                                  </label>
                                  <label className="ml-3">
                                    Status:{" "}
                                    {e.isacknowledged !== "Y" &&
                                      e.sentforverification != "Y" &&
                                      e.isrequireapproval != "Y" &&
                                      e.isreworkrequired != "Y" &&
                                      e.isfullyapproved != "Y" &&
                                      e.isrequestfulfilled != "Y"
                                      ? "Assigned"
                                      : e.isacknowledged === "Y" &&
                                        e.sentforverification != "Y" &&
                                        e.isrequireapproval != "Y" &&
                                        e.isreworkrequired != "Y" &&
                                        e.isfullyapproved != "Y" &&
                                        e.isrequestfulfilled != "Y"
                                        ? "Work In Progress"
                                        : e.isacknowledged === "Y" &&
                                          e.sentforverification === "Y" &&
                                          e.isrequireapproval != "Y" &&
                                          e.isreworkrequired != "Y" &&
                                          e.isfullyapproved != "Y" &&
                                          e.isrequestfulfilled != "Y"
                                          ? "Send For Verification"
                                          : e.isacknowledged === "Y" &&
                                            e.sentforverification !== "Y" &&
                                            e.isrequireapproval === "Y" &&
                                            e.isreworkrequired != "Y" &&
                                            e.isfullyapproved != "Y" &&
                                            e.isrequestfulfilled != "Y"
                                            ? "In approvel"
                                            : e.isacknowledged === "Y" &&
                                              e.sentforverification === "Y" &&
                                              e.isrequireapproval === "Y" &&
                                              e.isreworkrequired != "Y" &&
                                              e.isfullyapproved === "Y" &&
                                              e.isrequestfulfilled != "Y"
                                              ? "Approved"
                                              : e.isacknowledged === "Y" &&
                                                e.sentforverification === "Y" &&
                                                e.isrequireapproval === "Y" &&
                                                e.isreworkrequired === "Y" &&
                                                e.isfullyapproved === "Y" &&
                                                e.isrequestfulfilled != "Y"
                                                ? "Rework"
                                                : e.isacknowledged === "Y" &&
                                                  e.sentforverification === "Y" &&
                                                  e.isrequireapproval === "Y" &&
                                                  e.isreworkrequired === "Y" &&
                                                  e.isfullyapproved === "Y" &&
                                                  e.isrequestfulfilled === "Y"
                                                  ? "Completed"
                                                  : e.isacknowledged === "Y" &&
                                                    e.sentforverification === "Y" &&
                                                    e.isrequireapproval === "Y" &&
                                                    e.isreworkrequired != "Y" &&
                                                    e.isfullyapproved === "Y" &&
                                                    e.isrequestfulfilled === "Y"
                                                    ? "Completed"
                                                    : "Assigned"}
                                  </label>
                                </span>
                                <div className="">
                                  <span
                                    className="fontdesign  d-flex justify-content-space-between"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <label className="ml-3 mt-1">
                                      <label>
                                        Created :{" "}
                                        {e.submitteddate !== null
                                          ? moment(
                                            e.submitteddate,
                                            "YYYY-MM-DD"
                                          ).format("DD MMM YYYY")
                                          : null}
                                      </label>
                                      <CircleIcon
                                        className="ml-2"
                                        style={{ fontSize: "5px" }}
                                      ></CircleIcon>
                                      <label className="ml-2">
                                        Assigned To : {e.assignedgroup}{" "}
                                      </label>
                                      <CircleIcon
                                        className="ml-2"
                                        style={{ fontSize: "5px" }}
                                      ></CircleIcon>

                                      <label className="ml-2">
                                        {" "}
                                        Item Type : {e.typedescription}
                                      </label>
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </Row>
                          </Col>
                          <div>
                            <Col
                              style={{
                                width: "120px",
                                marginTop: "20px",
                                marginRight: "60px",
                              }}
                            >
                              <div className="mt-1">
                                {/* <div
                                    style={{ padding: "7px 1px 3px 5%" }}
                                    onClick={() => Complete(e, "Report")}
                                    hidden={
                                      (e.isacknowledged === "Y" &&
                                        e.sentforverification === "Y" &&
                                        e.isrequireapproval === "Y" &&
                                        e.isreworkrequired != "Y" &&
                                        e.isfullyapproved === "Y" &&
                                        e.isrequestfulfilled === "Y") ||
                                      (e.isacknowledged === "Y" &&
                                        e.sentforverification === "Y" &&
                                        e.isrequireapproval === "Y" &&
                                        e.isreworkrequired === "Y" &&
                                        e.isfullyapproved === "Y" &&
                                        e.isrequestfulfilled === "Y")
                                    }
                                  >
                                    <DoneOutlineIcon
                                      style={{ fontSize: "small" }}
                                    />
                                    <label style={{ fontSize: "small" }}>
                                      {" "}
                                      {"   "}Complete
                                    </label>
                                  </div> */}

                                {/* <span
          className="fontdesign ml-3 "
          style={{ fontSize: "12px", marginTop: "50px" }}
        >
          <ShowChartIcon
            style={{ fontSize: "14px", borderRadius: "20px" }}
          />
          Status
        
        </span> */}
                              </div>

                              {/* <div className="">
                                  <div
                                    style={{ padding: "7px 10px 3px 5%" }}
                                    // onClick={() => Complete(e, "Report")}
                                    hidden={
                                      (e.isacknowledged === "Y" &&
                                        e.sentforverification === "Y" &&
                                        e.isrequireapproval === "Y" &&
                                        e.isreworkrequired != "Y" &&
                                        e.isfullyapproved === "Y" &&
                                        e.isrequestfulfilled !== "Y") ||
                                      (e.isacknowledged === "Y" &&
                                        e.sentforverification === "Y" &&
                                        e.isrequireapproval === "Y" &&
                                        e.isreworkrequired === "Y" &&
                                        e.isfullyapproved === "Y" &&
                                        e.isrequestfulfilled !== "Y")
                                    }
                                  >
                                    <RestartAltIcon
                                      style={{ fontSize: "small" }}
                                    />
                                    <label style={{ fontSize: "small" }}>
                                      {" "}
                                      {"   "}Reopen
                                    </label>
                                  </div>
                                </div> */}
                              <div className="">
                                <div
                                  style={{ padding: "7px 10px 3px 5%" }}
                                  onClick={() => handleClickOpen(e)}
                                  disabled={
                                    (e.isacknowledged === "Y" &&
                                      e.sentforverification === "Y" &&
                                      e.isrequireapproval === "Y" &&
                                      e.isreworkrequired != "Y" &&
                                      e.isfullyapproved === "Y" &&
                                      e.isrequestfulfilled === "Y") ||
                                    (e.isacknowledged === "Y" &&
                                      e.sentforverification === "Y" &&
                                      e.isrequireapproval === "Y" &&
                                      e.isreworkrequired === "Y" &&
                                      e.isfullyapproved === "Y" &&
                                      e.isrequestfulfilled === "Y")
                                  }
                                >
                                  <SpeakerNotesOutlinedIcon
                                    className="Card_Button_Color_Approve  "
                                    style={{
                                      color: "rgb(25, 138, 227)",
                                      fontSize: "20px",
                                    }}
                                  />
                                  {/* <label style={{ fontSize: "small" }}>  {"   "} Notes</label> */}
                                </div>
                              </div>
                              {/* <span
                className="fontdesign ml-1"
                style={{ fontSize: "12px" }}
              >
                Notes
              </span> */}

                              {/* <button
                              variant="outline-secondary"
                              size="sm"
                              fontSize="small"
                              // style={{ fontSize: "17px",alignContent:"start" }}
                              className="btn  btn-sm fontdesign mt-1 "
                              style={{ textAlign: "left", marginLeft: "-10px" }}
                              title="Notes"
                              onClick={(e, data122) => {
                                handleClickOpen1(data122, data122.IncidentId);
                              }}
                            >
                              <SpeakerNotesOutlinedIcon
                                className="Card_Button_Color_Approve  "
                                style={{
                                  color: "rgb(25, 138, 227)",
                                  fontSize: "20px",
                                }}
                              ></SpeakerNotesOutlinedIcon>
                            </button> */}
                            </Col>
                          </div>
                        </Row>
                      </Card>
                    </div>
                  );
                })
            )
          ) : null}
        </div>
        <div className="col-3 ">
          {" "}
          {view == "cardview" ? (
            <div className="mt-2 mr-2">
              <Card className="shadow-sm  m-1">
                <span className="mt-2 ml-3" style={{ fontWeight: "bold" }}>
                  Filters
                  <Button
                    onClick={clear}
                    style={{
                      fontSize: 14,
                      padding: 9,
                      width: 100,
                      height: 40,
                      borderRadius: 8,
                      marginRight: 14,
                      border: "none",
                      outline: "none",
                      marginLeft: "35px",

                      backgroundColor: "#fff",
                      color: "#5250E5",
                      textAlign: "right",
                      textTransform: "none",
                    }}
                  >
                    {" "}
                    Clear{" "}
                  </Button>
                </span>

                <div style={{ alignItems: "center" }}>
                  <label
                    className="mt-3 ml-3 fontdesign"
                    style={{ fontSize: "14px" }}
                  >
                    Created
                  </label>
                  <Select
                    className="fontdesign filterselect mt-2 ml-3"
                    placeholder="Any"
                    styles={customStylesd}
                    options={dateoption}
                    onChange={(item) => {
                      datefilter(item);
                    }}
                    value={
                      DateF ? dateoption?.find((e) => e.value === DateF) : null
                    }
                  ></Select>
                  <label
                    className="mt-3 ml-3 fontdesign"
                    style={{ fontSize: "14px" }}
                  >
                    Category
                  </label>
                  <Select
                    className="fontdesign filterselect mt-2 ml-3 mb-5"
                    options={Categoryoption}
                    styles={customStylesd}
                    onChange={(item) => {
                      handlecategory(item);
                    }}
                    value={Categoryoption?.filter(
                      (e) => e.value === Catoryfilter
                    )}
                    placeholder="Any"
                  ></Select>
                </div>
              </Card>
            </div>
          ) : null}
        </div>
      </div>

      {view == "tabelview" ? (
        <div>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          ></link>
          <MaterialTable
            title={"My Request"}
            columns={Columns.map((e) => e)}
            data={
              itrequest_user === ""
                ? ""
                : itrequest_user
                  .sort((m, l) => (m.itrequestid > l.itrequestid ? 1 : -1))
                  .map((e) => {
                    return {
                      ...e,
                      title: e.referencedetails.RequestTittle
                        ? e.referencedetails.RequestTittle
                        : "-",
                      status:
                        e.isacknowledged !== "Y" &&
                          e.sentforverification != "Y" &&
                          e.isrequireapproval != "Y" &&
                          e.isreworkrequired != "Y" &&
                          e.isfullyapproved != "Y" &&
                          e.isrequestfulfilled != "Y"
                          ? "Assigned"
                          : e.isacknowledged === "Y" &&
                            e.sentforverification != "Y" &&
                            e.isrequireapproval != "Y" &&
                            e.isreworkrequired != "Y" &&
                            e.isfullyapproved != "Y" &&
                            e.isrequestfulfilled != "Y"
                            ? "Work In Progress"
                            : e.isacknowledged === "Y" &&
                              e.sentforverification === "Y" &&
                              e.isrequireapproval != "Y" &&
                              e.isreworkrequired != "Y" &&
                              e.isfullyapproved != "Y" &&
                              e.isrequestfulfilled != "Y"
                              ? "Send For Verification"
                              : e.isacknowledged === "Y" &&
                                e.sentforverification !== "Y" &&
                                e.isrequireapproval === "Y" &&
                                e.isreworkrequired != "Y" &&
                                e.isfullyapproved != "Y" &&
                                e.isrequestfulfilled != "Y"
                                ? "In approvel"
                                : e.isacknowledged === "Y" &&
                                  e.sentforverification === "Y" &&
                                  e.isrequireapproval === "Y" &&
                                  e.isreworkrequired != "Y" &&
                                  e.isfullyapproved === "Y" &&
                                  e.isrequestfulfilled != "Y"
                                  ? "Approved"
                                  : e.isacknowledged === "Y" &&
                                    e.sentforverification === "Y" &&
                                    e.isrequireapproval === "Y" &&
                                    e.isreworkrequired === "Y" &&
                                    e.isfullyapproved === "Y" &&
                                    e.isrequestfulfilled != "Y"
                                    ? "Rework"
                                    : e.isacknowledged === "Y" &&
                                      e.sentforverification === "Y" &&
                                      e.isrequireapproval === "Y" &&
                                      e.isreworkrequired === "Y" &&
                                      e.isfullyapproved === "Y" &&
                                      e.isrequestfulfilled === "Y"
                                      ? "Completed"
                                      : e.isacknowledged === "Y" &&
                                        e.sentforverification === "Y" &&
                                        e.isrequireapproval === "Y" &&
                                        e.isreworkrequired != "Y" &&
                                        e.isfullyapproved === "Y" &&
                                        e.isrequestfulfilled === "Y"
                                        ? "Completed"
                                        : "Assigned",

                      ItemType:
                        e.ItemType == 2101
                          ? "Laptop"
                          : e.ItemType == 2102
                            ? "Dongle"
                            : e.ItemType == 2103
                              ? "Support Phone"
                              : null,

                      submitteddate:
                        e.submitteddate !== null
                          ? moment(e.submitteddate, "YYYY-MM-DD").format(
                            "DD MMM YYYY"
                          )
                          : null,
                    };
                  })
            }
            detailPanel={(rowdata) => {
              return (
                <div
                  style={{
                    fontSize: 13,
                    color: "white",
                    backgroundColor: "#dad9d9",
                  }}
                >
                  <Table class="table table-bordered">
                    <thead
                      className="tableHeader"
                      style={{
                        backgroundColor: "#E1E4EA",
                        fontSize: "12px",
                        fontFamily: "DM Sans, sans-serif;",
                      }}
                    >
                      <th> Raising Request For</th>

                      {rowdata.isselfrequest == "N" ? (
                        <th> Request user ID </th>
                      ) : null}

                      {rowdata.itemtypecategory == "ASR" ? (
                        <th> New Owner ID </th>
                      ) : rowdata.itemtypecategory == "AAR" ? (
                        <th> Access Level </th>
                      ) : rowdata.itemtypecategory == "GSR" ? (
                        <th> Request Tittle </th>
                      ) : null}

                      {rowdata.itemtypecategory == "ASR" ? (
                        <th>New Owner Name </th>
                      ) : rowdata.itemtypecategory == "AAR" ? (
                        <th> Role </th>
                      ) : null}

                      {rowdata.itemtypecategory == "ASR" ? (
                        <th>Expected date</th>
                      ) : null}
                    </thead>
                    <tr style={{ backgroundColor: "#E1E4EA" }}>
                      <td style={{ backgroundColor: "#E1E4EA" }}>
                        {rowdata && rowdata.isselfrequest === "Y"
                          ? "Self"
                          : rowdata.isselfrequest === "N"
                            ? "SomeOne"
                            : null}
                      </td>

                      {rowdata.isselfrequest == "N"
                        ? rowdata && <td> {rowdata.raisingonbehalfdetails}</td>
                        : null}

                      <td style={{ backgroundColor: "#6c757" }}>
                        {rowdata.itemtypecategory == "ASR"
                          ? rowdata.newassignee
                          : rowdata.itemtypecategory == "AAR"
                            ? rowdata.referencedetails.Accesslevel
                            : rowdata.itemtypecategory == "GSR"
                              ? rowdata.referencedetails.RequestTittle
                              : null}
                      </td>

                      {rowdata.itemtypecategory == "ASR" ? (
                        <td style={{ backgroundColor: "#6c757" }}>
                          {rowdata.newassigneedetails.EmpName}
                        </td>
                      ) : rowdata.itemtypecategory == "AAR" ? (
                        <td style={{ backgroundColor: "#6c757" }}>
                          {" "}
                          {rowdata.referencedetails.Role}
                        </td>
                      ) : null}

                      {rowdata.itemtypecategory == "ASR" ? (
                        <td style={{ backgroundColor: "#6c757" }}>
                          {" "}
                          {rowdata.expecteddate !== null
                            ? moment(rowdata.expecteddate, "YYYY-MM-DD").format(
                              "DD MMM YYYY"
                            )
                            : null}
                        </td>
                      ) : null}
                    </tr>
                  </Table>
                </div>
              );
            }}
            components={{
              Pagination: PatchedPagination,
            }}
            options={{
              actionsColumnIndex: -1,
              exportAllData: true,
              exportButton: true,
              columnResizable: true,
              filtering: false,
              sorting: true,
              paging: true,
              pageSize: 5,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 30],
              headerStyle: {
                whiteSpace: "nowrap",
                fontWeight: "700",
                fontSize: "medium",
                // backgroundColor: "#e0ebeb",
                color: "black",
                padding: "12px ",
                textAlign: "left",
              },
              cellStyle: {
                textAlign: "left",
                fontSize: "medium",
                padding: "21px",
                color: "#6c757d",
                // padding: "15px 2px",
              },
              // headerStyle: {
              //   padding: "2px",
              //   fontWeight: "700",
              //   fontSize: "small",
              //   backgroundColor: '#107aeb85',
              //   color: '#03256c',
              // },
              // cellStyle: {
              //   textAlign: "left",
              //   fontSize: "12px",
              //   padding: "2px",
              //   color: '#03256c',

              // },
              // rowStyle: rowdata => {

              //   if ((rowdata.tableData.id) % 2 != 0) {
              //     return { backgroundColor: "#f6f7fb" };
              //   }

              // }
            }}
            actions={[
              (rowData) => ({
                icon: () => (
                  <SpeakerNotesOutlinedIcon
                    className="Card_Button_Color_Approve1 "
                    style={{
                      color: "rgb(25, 138, 227)",
                    }}
                  >
                    {" "}
                    Notes{" "}
                  </SpeakerNotesOutlinedIcon>
                ),
                tooltip: "Notes",
                disabled: rowData.isrequestfulfilled == "Y",
                onClick: (_e, data) => {
                  handleClickOpen(data);
                },
              }),
            ]}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ITRequestTable;
