import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation, Redirect } from "react-router-dom";
// import ListIcon from "@material-ui/icons/List";
// import PostAddIcon from "@material-ui/icons/PostAdd";
// import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import axios from "../../axios";
import Cookies from "universal-cookie";
// import { TroubleshootRounded } from "@mui/icons-material";
// import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector, useDispatch } from "react-redux";
// import { Button } from "reactstrap";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import { Tooltip } from "@mui/material";
import utils from "../LoginPage/utlis";
// import styled from "styled-components";
import {
  CodeSandboxOutlined,
  ProjectOutlined,
  CloudOutlined,
  SafetyOutlined,
  ControlOutlined,
  SettingOutlined,
  IdcardOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { Button, Menu } from "antd";
import { Divider } from "@material-ui/core";
import "./sidebar.css";
function SidebarComponent(props) {
  const location = useLocation();
  const { IsSidebarCollapse } = useSelector((state) => state.user);
  const { routes } = props;

  const [openSubmenus, setOpenSubmenus] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  // const [mitem, setMitem] = useState("");
  // const [smenu, setSmenu] = useState("");
  // const [cmenu, setCmenu] = useState("");

  // const toggleSubmenu = (menuId, menuName) => {
  //   // setSelectedMenu(menuName)
  //   setOpenSubmenus((prevOpenSubmenus) => {
  //     const updatedSubmenus = { ...prevOpenSubmenus };

  //     // Close other open submenus
  //     Object.keys(updatedSubmenus).forEach((key) => {
  //       if (key !== menuId) {
  //         updatedSubmenus[key] = false;
  //       }
  //     });

  //     // Toggle the clicked submenu
  //     updatedSubmenus[menuId] = !updatedSubmenus[menuId];

  //     return updatedSubmenus;
  //   });
  // };

  //Logo api
  const cookies = new Cookies();
  let userdata = JSON.parse(localStorage.getItem("userdata"));
  let tenantid = JSON.parse(localStorage.getItem("userdata"));
  let email = tenantid?.login;
  let data = JSON.parse(localStorage.getItem("userdata"));
  // let userid = JSON.parse(localStorage.getItem("clientdetails"))?.[0]?.UserId;
  // let empid = JSON.parse(localStorage.getItem("Empdetails"))?.[0]?.ClientUserId;
  // const rolecode = localStorage.getItem("Rolecode") == undefined ? "" : JSON.parse(localStorage.getItem("Rolecode"))
  // console.log(localStorage.getItem("Rolecode"), "rolecode");
  let Token = data?.token;
  const [logo, setlogo] = useState([]);
  // const [menudata, setMenuData] = useState("");
  // const [productrole, setProductRole] = useState([]);
  // const [activemenuid, setactive] = useState(0);
  // console.log(email);
  const [orgdata, setOrgdata] = useState([]);

  useEffect(() => {
    const name = localStorage.getItem("Menuname");
    const openSub = JSON.parse(localStorage.getItem("openSubmenu"));
    if (name != null) {
      setSelectedMenu(name);
      setOpenSubmenus(openSub);
    } else {
      setSelectedMenu("Kanban Board");
      setOpenSubmenus({ 1: true });
    }

    axios({
      method: "GET",
      url:
        "https://api.devozy.ai/subscription_user_role_mapping?ProductDetails->>ProdId=eq.11004&UserDetails->>Email=eq." +
        userdata?.login +
        "&LoginEnabled=eq.Y",
    }).then((response) => {
      let clientid = response.data?.map((e) => e.ClientId);

      axios({
        method: "GET",
        url:
          "https://api.devozy.ai/subscription_client_license_details?ClientId=eq." +
          clientid?.[0] +
          "&ProductDetails->>ProdId=in.(11004,11006)&IsActive=eq.Y",
        headers: {
          Authorization: "Bearer " + Token,
        },
      })
        .then((res) => {
          let name = res.data?.map((e) => e.ClientDetails);

          localStorage.setItem(
            "Orgsubscriptiondetails",
            JSON.stringify(res.data)
          );
          setlogo(name);
        })

        .catch((er) => console.error(er, "errorapi"));
    });
    axios({
      method: "GET",
      url: `https://subscriptionapi.yozytech.com/rpc/fun_orgsubscriptiondetails?useridentification=${userdata?.login}`,
    }).then((response) => {
      const data = response.data;
      setOrgdata(data);
    });
  }, []);

  const logohead = logo
    ? logo[0]?.Company?.split(" ")[0].split("")
    : "Devozy.ai".split("");
  const handleCollapsedChange = () => {
    props.setsidebarOpened(!props.sidebarOpened);
  };
  // backgroundColor: active ? '#545454' : undefined,
  // borderLeft: "2px solid #45dd81",
  // borderRadius: "5px"
  // const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  // const history = useHistory();
  const signout = async () => {
    // alert('sign out')
    localStorage.clear();
    sessionStorage.clear();
    const cookies = new Cookies();
    cookies.remove("token");
    let allCookies = cookies.getAll();
    for (let key in allCookies) {
      cookies.remove(key, { path: "/", domain: ".devozy.ai" });
      // cookies.remove(key, { path: "/", domain: "localhost" });
    }
    // window.location.replace('/login')
    // history.push('/login');
    // history.push('/login');
    // window.location.href = "/login"
    window.location.reload();
  };
  const cpanelcookie = "Bearer " + cookies.get("token");

  // console.log(cpanelcookie, Token);
  // console.log(props.routes, "pppppppppppppppppppppppppppppppppp");

  const items =
    props.routes?.length > 0 &&
    props.routes
      .filter((m) => m.ParentMenuId == null)
      ?.map((e) => ({
        key: e.MenuId,
        label: e.Path.includes("https://") ? (
          e.MenuName
        ) : (
          <Link
            style={{ textDecoration: "none" }}
            to={e.Path.includes("https://") ? "/" : e.Path}
          >
            <span style={{ fontSize: "0.9rem" }}>{e.MenuName}</span>
          </Link>
        ),

        icon: (
          // // <span>
          // //   {e.IconDetails == "fa fa-connectdevelop" ? (
          // //     <img
          // //       src={ConnectedViewsvg}
          // //       // style={{
          // //       //   height: IsSidebarCollapse ? "50px" : "30px",
          // //       //   width: IsSidebarCollapse ? "50px" : "30px",
          // //       // }}
          // //     ></img>
          // //   ) : (
          // //   )}
          // // </span>
          // <i className={e.IconDetails} />
          <span>
            {e.IconDetails === "fa fa-connectdevelop" ? (
              <CodeSandboxOutlined
                style={{ fontSize: IsSidebarCollapse ? "22px" : "20px" }}
              />
            ) : e.IconDetails === "fa fa-tasks" ? (
              <ProjectOutlined
                style={{ fontSize: IsSidebarCollapse ? "22px" : "20px" }}
              />
            ) : e.IconDetails === "fa fa-skyatlas" ? (
              <AllInclusiveIcon
                style={{ fontSize: IsSidebarCollapse ? "22px" : "20px" }}
              />
            ) : e.IconDetails === "fa fa-handshake-o" ? (
              <ControlOutlined
                style={{ fontSize: IsSidebarCollapse ? "22px" : "20px" }}
              />
            ) : e.IconDetails === " fa fa-cloud" ? (
              <CloudOutlined
                style={{ fontSize: IsSidebarCollapse ? "22px" : "20px" }}
              />
            ) : e.IconDetails === "fa fa-cog" ? (
              <SettingOutlined
                style={{ fontSize: IsSidebarCollapse ? "22px" : "20px" }}
              />
            ) : e.IconDetails === "fa fa-id-card" ? (
              <IdcardOutlined
                style={{ fontSize: IsSidebarCollapse ? "22px" : "20px" }}
              />
            ) : e.IconDetails === "fa fa-sign-out" ? (
              <LogoutOutlined
                style={{ fontSize: IsSidebarCollapse ? "22px" : "20px" }}
              />
            ) : (
              <i className={e.IconDetails} />
            )}
          </span>
        ),
        onClick: (o) => {
          if (
            props.routes?.filter((x) => e.MenuId == x.ParentMenuId)?.length == 0
          ) {
            if (e.MenuId === 62) {
              signout();
            } else if (e.Path.includes("https://")) {
              utils.setCookie("token_org", cpanelcookie, 1 / 12);
              utils.setCookie("Org_id", JSON.stringify(email));
              utils.setCookie("Org_details", JSON.stringify(orgdata));
              utils.setCookie("TypeOfUser", JSON.stringify("OrgAdmin"));
              utils.setCookie("Role", JSON.stringify(3));
              // window.open("http://localhost:3000");
              window.open(e.Path);
            }
          }
        },
        children:
          props.routes.filter((x) => e.MenuId === x.ParentMenuId)?.length > 0
            ? props.routes
                .filter((x) => e.MenuId === x.ParentMenuId)
                .map((s) => ({
                  key: s.MenuId,
                  label: s.Path.includes("https://") ? (
                    <a
                      href={s.Path}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      {s.MenuName}
                    </a>
                  ) : (
                    <Link style={{ textDecoration: "none" }} to={s.Path}>
                      {s.MenuName}
                    </Link>
                  ),
                  // icon: <i className={s.IconDetails} />,
                }))
            : null,
      }));
  //

  // const [collapsed, setCollapsed] = useState(false);
  // const toggleCollapsed = () => {
  //   setCollapsed(!collapsed);
  // };
  // const Sidebar = styled.div`
  //   transition: width 0.8s ease-in-out;
  // `;
  return (
    <div>
      {IsSidebarCollapse ? (
        <div className="d-flex justify-content-center mt-3 fixed-height">
          {logohead?.slice(0)?.map((e, i) => (
            <>
              <label
                // style={{ fontSize: "10px" }}
                key={i + 1}
                // className={
                //   (i + 1) % 2 === 0 && (i + 1) % 4 === 0
                //     ? "text-danger logo_size_mini"
                //     : (i + 1) % 3 === 0
                //     ? "text-warning logo_size_mini"
                //     : (i + 1) % 2 === 0
                //     ? "text-success logo_size_mini"
                //     : "text-info logo_size_mini"
                // }

                className={
                  (i + 1) % 2 === 0 && (i + 1) % 4 === 0
                    ? "text-danger logo_large"
                    : (i + 1) % 3 === 0
                    ? "text-warning logo_large"
                    : (i + 1) % 2 === 0
                    ? "text-success logo_large"
                    : "text-info logo_large"
                }
              >
                {" "}
                {e.toUpperCase()}
              </label>
            </>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center mt-3 fixed-height">
          {logohead?.map((e, i) => (
            <>
              <h1
                key={i + 1}
                // className={
                //   (i + 1) % 2 === 0 && (i + 1) % 4 === 0
                //     ? "text-danger logo_size_mini1"
                //     : (i + 1) % 3 === 0
                //     ? "text-warning logo_size_mini1"
                //     : (i + 1) % 2 === 0
                //     ? "text-success logo_size_mini1"
                //     : "text-info logo_size_mini1"
                // }

                className={
                  (i + 1) % 2 === 0 && (i + 1) % 4 === 0
                    ? "text-danger logo_large"
                    : (i + 1) % 3 === 0
                    ? "text-warning logo_large"
                    : (i + 1) % 2 === 0
                    ? "text-success logo_large"
                    : "text-info logo_large"
                }
              >
                {" "}
                {e.toUpperCase()}
              </h1>
            </>
          ))}
        </div>
      )}

      {/* <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{
          marginBottom: 16,
          // marginLeft: 100,
        }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button> */}
      {/* <Sidebar style={{ width: IsSidebarCollapse ? "80px" : "210px" }}> */}
      <div
      //  className={`mysidebar ${IsSidebarCollapse ? "80px" : "180px"}`}>
      >
        <Menu
          // style={{ transition: " width 0.4s" }}
          // className={`mysidebar ${IsSidebarCollapse ? "collapsed" : ""}`}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          theme="dark"
          inlineCollapsed={IsSidebarCollapse}
          items={items}
        />
        {/* </Sidebar> */}
      </div>
    </div>
  );
}

export default SidebarComponent;
