import React, { useReducer } from "react";
import Main from "./StoreContext";
import Reducer from "./reducer";
import intialState from "./intialState.js"
import StateFunction from "./StateFunction";
import { createContext } from "react";

function State(props) {
  const [state, dispatch] = useReducer(Reducer, intialState);

  return (
    <Main.Provider
      value={{
        state: state,
        dispatch: dispatch,
        StateFunction: StateFunction
      }}
    >
      {props.children}
    </Main.Provider>
  );
}

export default State;