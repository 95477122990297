import React, { useState } from "react";
import axios from "../../axios";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Avatar,
} from "@material-ui/core";
import MaterialIcon, { colorPalette } from "material-icons-react";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import Bookmark from "@material-ui/icons/Bookmark";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import AlbumRounded from "@material-ui/icons/AlbumRounded";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { Col, Row } from "reactstrap";
import "./ShowReleaseProgress.css";
import { useDispatch, useSelector } from "react-redux";
import Mainprocesstab from "./ProgressUpdate/Mainprocesstab";
import moment from "moment";
function ShowReleaseProgress({
  ShowreleasePopup,
  setShowreleasePopup,
  Issuepopdetails,
  // KanbanBoardData,
  // mydatashow,
  SelectedCardId,
  ShowRefresh,
  setShowRefresh,
  // projectRole
}) {
  const { projectMappingData, percentage, allImage, projectRole, projectCode, historyrelease, KanbanBoardData, mydatashow } = useSelector(
    (state) => state.user
  );
  const [myshowissuetable, setmyshowissuetable] = useState([]);
  const [openAll, setOpenAll] = useState(false);
  const [progress, setProgress] = useState(true);
  const [uat, setUat] = useState(false);
  const [History, setHistory] = useState(false);
  const [notes, setNotes] = useState(false);
  let progressdetailsshow = mydatashow.length == 0 ? [] : mydatashow?.filter(
    (r) => r.IssueIdS === SelectedCardId
  );

  const handleClose = () => {
    setShowreleasePopup(false);
    setShowRefresh(false);
    setOpenAll(false);
    setProgress(true);
    setUat(false);
    setHistory(false);
    setNotes(false);
    setmyshowissuetable([]);
  };

  if (ShowRefresh) {
    axios
      .get("agilesaas_issue_details?IssueId=eq." + SelectedCardId + "&order=CreatedDate.desc&IsActive=eq.Y")
      .then((response) => {
        let data = response.data;
        setmyshowissuetable(data);


        setShowRefresh(false);
      })
      .catch((error) => console.log(error));
  }

  let stage = Issuepopdetails.Stage == "" ? "" : Issuepopdetails.Stage;
  stage = { value: stage, label: stage };
  const customStylesStage = {
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      outline: "none",
    }),
    control: (base) => ({
      ...base,
      fontSize: 12,
      fontWeight: "bold",
      backgroundColor: "rgb(0, 82, 204)",
      outline: "none",
    }),
  };

  function Notes() {
    setOpenAll(false);
    setProgress(false);
    setUat(false);
    setHistory(false);
    setNotes(true);
  }
  function All_progress() {
    setOpenAll(true);
    setProgress(false);
    setUat(false);
    setHistory(false);
    setNotes(false);
  }

  function Progress_second() {
    setOpenAll(false);
    setProgress(true);
    setUat(false);
    setHistory(false);
    setNotes(false);
  }

  function Uat() {
    setUat(true);
    setOpenAll(false);
    setProgress(false);
    setHistory(false);
    setNotes(false);
  }

  function HistoryOpen() {
    setHistory(true);
    setUat(false);
    setOpenAll(false);
    setProgress(false);
    setNotes(false);
  }
  console.log(myshowissuetable?.map((e) => e.OriginalEstimate), "originalestimate")
  return (
    <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 ">
      <Dialog
        open={ShowreleasePopup}
        onClose={() => {
          handleClose();
        }}
        maxWidth="xxl"
        scroll="false"
      >
        <DialogContent>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              xl={12}
              xxl={12}
              className="colmyshowpopupxl "
            >
              <label className="">
                {Issuepopdetails.IssueId + " " + "/" + " "}
                {Issuepopdetails.IssueType === "Story" ? (
                  <>
                    <Bookmark
                      className="titleIcon"
                      style={{ backgroundColor: "#00cc00" }}
                    />{" "}
                  </>
                ) : Issuepopdetails.IssueType === "Task" ? (
                  <>
                    <CheckBoxRoundedIcon
                      className="titleIcon"
                      style={{ fontSize: "10px", backgroundColor: "skyblue" }}
                    />{" "}
                  </>
                ) : Issuepopdetails.IssueType === "Bug" ? (
                  <>
                    <AlbumRounded
                      className="titleIcon"
                      style={{ backgroundColor: "#cc0000" }}
                    />
                  </>
                ) : Issuepopdetails.IssueType === "SubTask" ? (
                  <>
                    <AddToPhotosIcon
                      className="titleIcon"
                      style={{ backgroundColor: "skyblue" }}
                    />{" "}
                  </>
                ) : null}
                {Issuepopdetails.IssueType == ""}
                {Issuepopdetails.IssueId}
              </label>
            </Col>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              xl={12}
              xxl={12}
              className="d-flex flex-row-reverse bd-highlight "
            >
              <p>
                {" "}
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </p>
            </Col>
          </Row>
          <div className="myshowpopbg">
            <Row className="">
              <Col xs={8} md={8} sm={8} lg={8} xl={8} xxl={8}>
                <div className="colmyshowpopup">
                  <div className="">
                    <tr>
                      <label className="showpoplabelH">
                        {Issuepopdetails.IssueTitle}
                      </label>
                    </tr>
                    <tr>
                      <label className="showpoplabel">Description</label>

                      <div className="">
                        {myshowissuetable == "" ? (
                          ""
                        ) : (
                          <>
                            <div
                              className="showpoplabel1"
                              dangerouslySetInnerHTML={{
                                __html:
                                  myshowissuetable[0].Description == null
                                    ? null
                                    : myshowissuetable[0].Description.replace(
                                      /<img .*?>/g,
                                      ""
                                    ),
                              }}
                            ></div>
                            <div
                              className="imageStyleshow"
                              dangerouslySetInnerHTML={{
                                __html:
                                  myshowissuetable[0].Description == null
                                    ? null
                                    : myshowissuetable[0].Description.match(
                                      /<img .*?>/g,
                                      ""
                                    ),
                              }}
                            ></div>
                          </>
                        )}
                      </div>
                    </tr>
                  </div>
                  <div className="mt-4 ml-2">
                    <div className="d-flex">
                      <button
                        type="button"
                        onClick={All_progress}
                        className={
                          openAll ? "Progress_button_change" : "Progress_button"
                        }
                      >
                        Story Point & Estimation
                      </button>
                      <button
                        type="button"
                        onClick={Progress_second}
                        className={
                          progress
                            ? "Progress_button_change"
                            : "Progress_button"
                        }
                      >
                        Progress Update
                      </button>
                      <button
                        type="button"
                        onClick={Notes}
                        className={
                          notes ? "Progress_button_change" : "Progress_button"
                        }
                      >
                        Notes
                      </button>
                      <button
                        type="button"
                        onClick={HistoryOpen}
                        className={
                          History ? "Progress_button_change" : "Progress_button"
                        }
                      >
                        History
                      </button>

                      <button
                        type="button"
                        onClick={Uat}
                        className={
                          uat ? "Progress_button_change" : "Progress_button"
                        }
                      >
                        UAT Closure
                      </button>
                    </div>
                  </div>
                  <Row>
                    <div className=" ml-3">
                      <Mainprocesstab
                        projectRole={projectRole}
                        SelectedCardId={SelectedCardId}
                        openAll={openAll}
                        progress={progress}
                        Uat={uat}
                        History={History}
                        KanbanBoardData={KanbanBoardData}
                        notes={notes}
                      />
                    </div>
                  </Row>
                </div>
              </Col>
              <Col
                xs={4}
                md={4}
                sm={4}
                lg={4}
                xl={4}
                xxl={4}
                className="colmyshowpopup1"
              >
                <div>
                  <table className="">
                    <tr>
                      <td className="">
                        <Card className="">
                          <div className="myshowpopbg">
                            <Select
                              styles={customStylesStage}
                              className="stageDropdown"
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              value={stage}
                              inputProps={{ readOnly: true }}
                              options={stage}
                              disabled
                            />
                            <table className="mt-2 ml-2">
                              {myshowissuetable == ""
                                ? ""
                                : myshowissuetable.map((e) => {
                                  let firstname = e.AssignedToDetails.FN;
                                  let Lastname = e.AssignedToDetails.LN;
                                  let firstnameUat =
                                    e.AssignedToUATDetails.FN;
                                  let LastnameUat = e.AssignedToUATDetails.LN;


                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          <label className="row_style headerLabel">
                                            Assigned To
                                          </label>
                                        </td>
                                        <td>
                                          <>
                                            <td>
                                              <Avatar className="NameShow12">
                                                {firstname == ""
                                                  ? "UA"
                                                  : firstname[0] +
                                                  Lastname[0]}
                                              </Avatar>
                                            </td>
                                            <td>
                                              <label className="row_style headerLabel">
                                                {firstname == ""
                                                  ? "UnAssigned"
                                                  : firstname +
                                                  " " +
                                                  Lastname}
                                              </label>
                                            </td>
                                          </>
                                          {/* <label className="row_style headerLabel">{firstname+ Lastname}</label></td> */}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label className="row_style headerLabel">
                                            Assigned To Uat
                                          </label>
                                        </td>
                                        <td>
                                          <>
                                            <td>
                                              <Avatar className="NameShow12">
                                                {firstnameUat == ""
                                                  ? "UA"
                                                  : firstnameUat[0] +
                                                  LastnameUat[0]}
                                              </Avatar>
                                            </td>
                                            <td>
                                              <label className="row_style headerLabel">
                                                {firstnameUat == ""
                                                  ? "UnAssigned"
                                                  : firstnameUat +
                                                  " " +
                                                  LastnameUat}
                                              </label>
                                            </td>
                                          </>
                                          {/* <label className="row_style headerLabel">{firstname+ Lastname}</label></td> */}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label className="row_style headerLabel">
                                            Assigned Date
                                          </label>
                                        </td>
                                        <td>
                                          <label className="row_style headerLabel">
                                            {moment(e.AssignedDate).format(
                                              "YYYY-MMM-DD"
                                            )}
                                          </label>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label className="row_style headerLabel">
                                            Priority
                                          </label>
                                        </td>
                                        <td className="d-flex">
                                          {" "}
                                          <label className="priorityImage mycur d-flex">
                                            {e.Priority == "" ? (
                                              ""
                                            ) : e.Priority === "Highest" ? (
                                              <MaterialIcon
                                                icon="keyboard_double_arrow_up"
                                                color="red"
                                              />
                                            ) : e.Priority == "" ? (
                                              ""
                                            ) : e.Priority === "High" ? (
                                              <MaterialIcon
                                                icon="keyboard_arrow_up"
                                                color={
                                                  colorPalette.amber._200
                                                }
                                              />
                                            ) : e.Priority == "" ? (
                                              ""
                                            ) : e.Priority === "Medium" ? (
                                              <MaterialIcon
                                                icon="drag_handle"
                                                color="blue"
                                              />
                                            ) : e.Priority == "" ? (
                                              ""
                                            ) : e.Priority === "Low" ? (
                                              <MaterialIcon
                                                icon="keyboard_arrow_down"
                                                color="#7bb92f"
                                              />
                                            ) : e.Priority == "" ? (
                                              ""
                                            ) : e.Priority === "Lowest" ? (
                                              <MaterialIcon
                                                icon="keyboard_double_arrow_down"
                                                color="#7bb92f"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {" " + e.Priority == ""
                                              ? ""
                                              : e.Priority}
                                          </label>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <label className="row_style headerLabel">
                                            Original Estimate
                                          </label>
                                        </td>
                                        <td>
                                          <label className="row_style headerLabel">
                                            {e.OriginalEstimate +
                                              " " +
                                              "days"}
                                          </label>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              {progressdetailsshow == "g"
                                ? ""
                                : progressdetailsshow.map((g) => {
                                  {
                                    console.log(g.RiskofDelivery);
                                  }
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          <label className="row_style headerLabel">
                                            Risk Of Delivery
                                          </label>
                                        </td>
                                        <td>
                                          <label className="row_style headerLabel">
                                            <span
                                              className="progress_update_rag"
                                              style={
                                                g.RiskofDelivery == "G"
                                                  ? { background: "Green" }
                                                  : g.RiskofDelivery === "R"
                                                    ? { background: "Red" }
                                                    : g.RiskofDelivery == "Y"
                                                      ? { background: "Yellow" }
                                                      : null
                                              }
                                            >
                                              {g.RiskofDelivery}
                                            </span>
                                          </label>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label className="row_style headerLabel">
                                            Time Tracking
                                          </label>
                                        </td>
                                        <td>
                                          <label className="row_style headerLabel">
                                            {g.EffortBurnt +
                                              " " +
                                              "days logged"}
                                          </label>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label className="row_style headerLabel">
                                            Time Remaining
                                          </label>
                                        </td>
                                        <td>
                                          <label className="row_style headerLabel">
                                            {g.effortremaining < 0
                                              ? -g.effortremaining +
                                              " " +
                                              "days" +
                                              " " +
                                              "over due"
                                              : g.effortremaining +
                                              "days left"}
                                          </label>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label className="row_style headerLabel">
                                            Percentage
                                          </label>
                                        </td>
                                        <td>
                                          <label className="row_style headerLabel">
                                            {g.percentageprogressed + "%"}
                                          </label>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </table>
                          </div>
                        </Card>
                      </td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default ShowReleaseProgress;
