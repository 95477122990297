import React from "react";
import NotesProgressUpdate from "./NotesProgressUpdate";
import PogressTimeHistory from "./PogressTimeHistory";
import Progressmain from "./Progressmain";
import StoryPoint from "./StoryPoint";
import Uatclosure from "./Uatclosure";
import { useDispatch, useSelector } from "react-redux";


function Mainprocesstab({
  // openAll,
  // progress,
  // Uat,
  // History,
  // KanbanBoardData,
  // SelectedCardId,
  // notes,
  // KBRefresh,
  // setRefresh,
  // progressdata,
  // setProgressData,
  // projectRole
}) {
  const { allImage, KanbanBoardData, SelectedCardId, projectRole, progress, openAll, uat, History, notes } = useSelector((state) => state.user);

  return (
    <div className="mainprocesstab">
      {openAll ? (
        <StoryPoint
        // projectRole={projectRole}
        // SelectedCardId={SelectedCardId}
        // KanbanBoardData={KanbanBoardData}
        // KBRefresh={KBRefresh}
        />
      ) : progress ? (
        <Progressmain
        // KanbanBoardData={KanbanBoardData}
        // SelectedCardId={SelectedCardId}
        // KBRefresh={KBRefresh}
        // setRefresh={setRefresh}
        // progressdata={progressdata}
        // setProgressData={setProgressData}
        />
      ) : uat ? (
        <Uatclosure
        // SelectedCardId={SelectedCardId}
        // KanbanBoardData={KanbanBoardData}
        />
      ) : History ? (
        <PogressTimeHistory
        // SelectedCardId={SelectedCardId}
        // KanbanBoardData={KanbanBoardData}
        />
      ) : notes ? (
        <NotesProgressUpdate
        // SelectedCardId={SelectedCardId}
        // KanbanBoardData={KanbanBoardData}

        />
      ) : null}
    </div>
  );
}

export default Mainprocesstab;
