import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import axios from "../../axios";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, useSelector } from 'react-redux'
import NearMeIcon from '@mui/icons-material/NearMe';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import MapIcon from '@mui/icons-material/Map';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const localizer = momentLocalizer(moment);
const SprintCalendar = ({ type }) => {
    const [meetings, setMeetings] = useState([]);
    const [sprintdata, setSprintData] = useState([])
    const { projectCode } = useSelector((state) => state.user)
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        function workingDays(startDate, diff1, e) {
            // console.log(moment().diff(
            //     moment(e.SprintStartDate),
            //     "day"
            // ), moment(e.SprintStartDate), moment(e.SprintEndDate), "date check")
            console.log(moment().diff(
                moment(e.SprintStartDate),
                "day"
            ), e.SprintStartDate, "startdate", moment().diff(
                moment(e.SprintEndDate),
                "day"
            ), e.SprintEndDate, "enddate", e.ReleaseName)
            let checktype = e.IsReleaseFrozen == "Y" ? "Completed Sprint" : moment().diff(
                moment(e.SprintStartDate),
                "day"
            ) >= 0 && moment().diff(
                moment(e.SprintEndDate),
                "day"
            ) < 0 ? "Current Sprint" : moment().diff(
                moment(e.SprintStartDate),
                "day"
            ) >= 0 && moment().diff(
                moment(e.SprintEndDate),
                "day"
            ) >= 0 ? "Completed Sprint (To be released)" : "Planned Sprint"
            const weekdays = [];
            let current = startDate;
            let i = 0;
            while (i < diff1) {
                if (!isWeekEnd(current)) {
                    let checkdate = { ...e, "date": moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"), "sprinttype": checktype }
                    // console.log(checkdate, "checkdate")
                    weekdays.push(checkdate);
                }
                let currentObj = current;
                current = moment(currentObj, "YYYY-MM-DD")
                    .add(1, "days")
                    .format("YYYY-MM-DD");
                i++;
            }
            function isWeekEnd(date) {
                let dateObj = moment(date, "YYYY-MM-DD");

                if (dateObj.day() === 6 || dateObj.day() === 0) {
                    return true;
                }
                //   else {
                //     if (holidays.includes(date)) {
                //       return true;
                //     } else {
                //       return false;
                //     }
                //   }
            }

            return weekdays;
            // }
        }


        axios
            .get(`agilesaas_releases?ProjectId=eq.${projectCode}`)
            .then((response) => {
                let data = response.data
                setSprintData(response.data)

                const combinedMeetings = data.flatMap((e) => {

                    let temp = moment(e.SprintEndDate).diff(
                        moment(e.SprintStartDate),
                        "day"
                    );
                    const diff =
                        temp == 0
                            ? 1
                            : Number(
                                Math.ceil(
                                    (new Date(e.SprintEndDate) - new Date(e.SprintStartDate)) /
                                    (1000 * 60 * 60 * 24)
                                ) + 1
                            );
                    const excludeday = workingDays(e.SprintStartDate, diff, e);
                    return excludeday;
                });
                setIsLoading(false)
                setMeetings(combinedMeetings)
            })
            .catch((error) => {
                setIsLoading(false)
                // console.log(error)
            });
    }, []);

    const formattedMeetings =
        meetings &&
        meetings.map((e) => ({
            Title: e.ReleaseName,
            start: moment(e.date).toDate(),
            end: moment(e.date).toDate(),
            projectname: e?.ProjectDetails?.[0]?.ProjName,
            sprinttype: e.sprinttype
        }));

    const getEventStyle = (event, start, end, isSelected) => {
        let style = {
            backgroundColor: "#1773C3",
            color: "#fff",
            borderRadius: "7px",
            border: "none",
            display: "block",
            padding: "2px",
            marginBottom: "3px",
        };
        if (event.sprinttype === "Completed Sprint") {
            style.backgroundColor = "#44AB5Aaa";
        }
        else if (event.sprinttype === "Current Sprint") {
            style.backgroundColor = "#44AB5A";
        }
        else if (event.sprinttype === "Completed Sprint (To be released)") {
            style.backgroundColor = "#FBBF4A";
        }
        else if (event.sprinttype === "Planned Sprint") {
            style.backgroundColor = "#1773C3";
        }
        if (isSelected) {
            style.backgroundColor = "#00235B";
            style.color = "#fff";
        }
        return {
            style: style,
        };
    };
    const EventComponent = ({ event }) => {
        const eventStyle = {
            cursor: "pointer",
            textAlign: "center",
            fontSize: 10,
            padding: "0px",
        };
        return (
            <div style={eventStyle}>
                {/* {event.sprinttype}
                <br></br> */}
                {event.sprinttype === "Completed Sprint" ?
                    <TaskAltIcon fontSize="small" />
                    :
                    event.sprinttype === "Current Sprint" ?
                        <NearMeIcon fontSize="small" /> :
                        event.sprinttype === "Completed Sprint (To be released)" ?
                            <NewReleasesIcon fontSize="small" /> :
                            event.sprinttype === "Planned Sprint" ? <MapIcon fontSize="small" /> : <></>
                }
                {" "}
                {" " + event.Title}
            </div>
        );
    };

    return (
        <div className={isLoading ? " d-flex align-items-center" : ""} style={{ height: "500px", justifyContent: 'center' }}>
            {
                isLoading ?
                    <span
                        className="spinner-border"
                        role="status"
                        aria-hidden="true"
                        style={{ color: "rgb(82, 80, 229)" }}
                    ></span>
                    :
                    <Calendar
                        localizer={localizer}
                        eventPropGetter={getEventStyle}
                        events={
                            // formattedMeetings
                            // [
                            //     { title: "event 1", date: "2023-11-07" },
                            //     { title: "event 2", date: "2023-11-17" }
                            // ]
                            // [{
                            //     Title: 'Daily scrum meeting',
                            //     start: new Date('2023-11-25T04:30:00.000Z'),
                            //     end: new Date('2023-11-25T05:00:00.000Z'),
                            //     projectname: 'Subscription '
                            // },
                            // {
                            //     Title: 'Daily scrum meeting',
                            //     start: new Date('2023-11-26T04:30:00.000Z'),
                            //     end: new Date('2023-11-26T05:00:00.000Z'),
                            //     projectname: 'Subscription '
                            // }]
                            formattedMeetings
                        }
                        selectable
                        startAccessor="start"
                        endAccessor="end"
                        components={{
                            event: EventComponent,
                        }}
                    />
            }
        </div>
    );
};

export default SprintCalendar;
