import React from "react";
import axios from "../../axios";
import { Row, Col, Card, CardTitle } from "reactstrap";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { Button } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import OfflineBoltRoundedIcon from "@material-ui/icons/OfflineBoltRounded";
import Bookmark from "@material-ui/icons/Bookmark";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { useDispatch, useSelector } from "react-redux";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Tooltip from "@mui/material/Tooltip";
import { TextField } from "@mui/material";
import Unassigned from "../../assets/images/avatar.png";

function HistorySearch({
  // setHistorysearch 
}) {
  const { projectCode, allImage } = useSelector((state) => state.user);
  const [showClearIcon, setShowClearIcon] = useState(false);
  // const [searchText, setSearchText] = useState("");
  // const inputRef = useRef(null);
  const [columns, setColumns] = useState(1);
  // const [alldata, setAlldata] = useState([]);
  const [fulldata, setfulldata] = useState([]);

  const [expandedCards, setExpandedCards] = useState([]);

  const [loading, setLoading] = useState(false);
  // const [relese, setRelese] = useState();
  // const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [input, setInput] = useState("");
  const [message, setMessage] = useState("Search History issue");
  const fetchdata = (value) => {
    setLoading(true);
    axios
      .get(
        `agilesaas_issue_details?ProjectId=eq.${projectCode}&select=IssueId,IssueType,IssueTitle,Description,Priority,ActualRelease,OriginalEstimate,AssignedTo,ParentIssue,LinkToEpic,AssignedToDetails&CurrentStage->0->>StageName=in.(Done)&order=CreatedDate.desc&ActualRelease=neq.null&IssueTitle=ilike.*${value}*`
      )
      .then((res) => {
        setData(res.data);
        const id = res.data?.map((e) => e.IssueId).join(",");
        setMessage(
          res.data?.length == 0 ? "No issues found" : "Search history issues"
        );
        axios
          .get(
            `agilesaas_issue_history?ProjectId=eq.${projectCode}&UpdatedField=eq.Kanban Changes&UpdatedFrom=in.(RFD,DEV)&UpdatedTo=in.(DEV,UAT)&select=IssueId,ChangedDate,UpdatedFrom,UpdatedTo&IssueId=in.(${id})`
          )
          .then((res) => {
            setfulldata(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (input == "") {
      setData([]);
      setMessage("search history issue");
    }
  }, [input]);
  const handlechange = (value) => {
    setInput(value);
    // fetchdata(value);
  };
  const handleEnterKeyPress = (e) => {
    // console.log(e);
    if (e.key === "Enter" && e.target.value) {
      fetchdata(e.target.value);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setColumns(Number(selectedOption.value)); // Convert the selected value to a number
  };

  const handleShowMore = (issueId) => {
    setExpandedCards((prevExpandedCards) =>
      prevExpandedCards.includes(issueId)
        ? prevExpandedCards.filter((id) => id !== issueId)
        : [...prevExpandedCards, issueId]
    );
  };

  const mergedData = data?.map((issue) => {
    const startdate = fulldata?.filter(
      (change) =>
        change.IssueId === issue.IssueId &&
        change.UpdatedFrom == "RFD" &&
        change.UpdatedTo == "DEV"
    )?.[0]?.ChangedDate;
    const enddate = fulldata?.filter(
      (change) =>
        change.IssueId === issue.IssueId &&
        change.UpdatedFrom == "DEV" &&
        change.UpdatedTo == "UAT"
    )?.[0]?.ChangedDate;
    return {
      ...issue,
      startdate: startdate,
      enddate: enddate,
    };
  });

  const renderCards = () => {
    return loading ? (
      <div
        className=" d-flex align-items-center"
        style={{ justifyContent: "center", height: 460 }}
      >
        <span
          className="spinner-border"
          role="status"
          aria-hidden="true"
          style={{ color: "rgb(82, 80, 229)" }}
        ></span>
      </div>
    ) : mergedData?.length > 0 ? (
      <Row className="mt-3">
        {mergedData?.map((e) => (
          <Col key={e.IssueId} md={12 / columns}>
            <Card
              className=" rounded pr-2 pl-3 pt-3 pb-2 shadow-sm border mt-3"
              style={{
                width: "100%", // Full width of the parent container
                maxWidth: "700px",
                margin: "auto",
                // height: expandedCards.includes(e.IssueId) ? "auto" : "220px",
                // overflow: "hidden",
              }}
            >
              <Row>
                <Col className="">
                  <div className="d-flex ">
                    <Tooltip
                      title={e.IssueType}
                      arrow
                      placement="top"
                      offset={[0, -3]}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="cardIcon2 ">
                        {e.IssueType === "Story" ? (
                          <Bookmark
                            className="story fonticons"
                            style={{ fontSize: "13px" }}
                          />
                        ) : e.IssueType === "Task" ? (
                          <CheckBoxRoundedIcon
                            className="task fonticons"
                            style={{ fontSize: "13px" }}
                          />
                        ) : e.IssueType === "Bug" ? (
                          <StopCircleIcon
                            style={{ fontSize: "13px" }}
                            className="bug fonticons"
                          />
                        ) : e.IssueType === "Epic" ? (
                          <OfflineBoltRoundedIcon
                            style={{ fontSize: "13px" }}
                            className="epic fonticons"
                          />
                        ) : e.IssueType === "SubTask" ? (
                          <AddToPhotosIcon
                            // className="kanbanicon task"
                            className="task fonticons"
                            style={{ fontSize: "13px" }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </Tooltip>
                    <span
                      className="mt-1 rounded"
                      style={{
                        fontSize: "12px",
                        marginLeft: "2px",
                        // color: "#198ae3",

                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {e.IssueId} {"-"}
                    </span>

                    <Tooltip
                      title={e.IssueTitle}
                      arrow
                      placement="top"
                      offset={[0, -1]}
                      style={{ cursor: "pointer" }}
                    >
                      <lable
                        style={{
                          fontSize: "1rem",
                          marginLeft: "10px",
                          fontWeight: "bold",
                          overflow: "hidden",
                          // fontWeight: "550",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",

                          maxWidth: columns === 1 ? "400px" : "240px",
                          // : "180px", // You can adjust the max width as needed
                          display: "inline-block",
                        }}
                      >
                        {e.IssueTitle}
                      </lable>
                    </Tooltip>
                    <Tooltip
                      title={e.Priority}
                      arrow
                      placement="top"
                      offset={[0, -3]}
                    >
                      <lable className="ml-1">
                        {e.Priority === "Highest" ? (
                          <KeyboardDoubleArrowUpIcon
                            className="filter-icons-1"
                            fontSize="small"
                          />
                        ) : e.Priority === "High" ? (
                          <KeyboardArrowUpIcon
                            className="filter-icons-1"
                            fontSize="small"
                          />
                        ) : e.Priority === "Medium" ? (
                          <DragHandleIcon
                            className="filter-icons"
                            fontSize="small"
                          />
                        ) : e.Priority === "Low" ? (
                          <KeyboardArrowDownIcon
                            className="filter-icons-2"
                            fontSize="small"
                          />
                        ) : e.Priority === "Lowest" ? (
                          <KeyboardDoubleArrowDownIcon
                            className="filter-icons-2"
                            fontSize="small"
                          />
                        ) : (
                          ""
                        )}
                      </lable>
                    </Tooltip>
                  </div>
                </Col>

                <Col md={2} className=" d-flex justify-content-center  ">
                  {/* <label style={{ fontSize: "12px" }} className="mt-1">
                    {e.AssignedToDetails.FN}
                  </label> */}
                  <Tooltip
                    title={e.AssignedToDetails.FN}
                    arrow
                    placement="top"
                    offset={[0, -3]}
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      sx={{ width: 24, height: 24, marginLeft: "5px" }}
                      src={
                        allImage?.length > 0 ? allImage?.filter(
                          (x) => x.teammemberid == e.AssignedTo
                        )?.[0]?.image : Unassigned
                      }
                    ></Avatar>
                  </Tooltip>
                </Col>
              </Row>

              {/* <Row className="mt-1">

              </Row> */}
              <Row>
                <Col>
                  <label
                    className="mt-2"
                    style={{
                      fontSize: "12px",
                      color: "grey",
                      height: expandedCards.includes(e.IssueId)
                        ? "auto"
                        : "100px",
                      overflow: "hidden",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: e.Description.replace(/<img .*?>/g, ""),
                    }}
                  >
                    {/* {e.Description} */}
                    {/* {console.log(
                        e.Description.replace(/<img .*?>/g, "").length
                      )} */}
                    {/* {Description?.match(/<img .*?>/g)?.length} */}
                  </label>
                  {e.Description.replace(/<img .*?>/g, "").length > 150 && (
                    <span
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                      onClick={() => handleShowMore(e.IssueId)}
                    >
                      {expandedCards.includes(e.IssueId)
                        ? "Show Less"
                        : "Show More"}
                    </span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={columns === 1 ? 3 : columns === 2 ? 6 : 6}>
                  <label style={{ fontSize: "14px" }}>Sprint: </label>
                  <span
                    className="SprintlabelStyle rounded"
                    style={{ backgroundColor: "lightcyan" }}
                  >
                    {" "}
                    {e.ActualRelease}
                  </span>
                </Col>
                <Col
                  md={columns === 1 ? 3 : columns === 2 ? 6 : 6}
                  className="p-0"
                >
                  {/* <label style={{ fontSize: "1rem" }}>OriginalEstimate</label> */}
                  {/* <span class="mdi mdi-timer-sand"></span> */}
                  <label style={{ fontSize: "14px" }}>
                    OriginalEstimate:{""}
                  </label>
                  <span className="SprintlabelStyle ">
                    {"  "}
                    {e.OriginalEstimate}
                  </span>
                </Col>
                <Col
                  md={columns === 1 ? 3 : columns === 2 ? 6 : 12}
                  className=" p-0"
                >
                  <label
                    style={{
                      fontSize: "14px",
                      marginLeft: columns === 2 && "20px",
                    }}
                  >
                    {" "}
                    Startdate:
                  </label>
                  {/* <label style={{ fontSize: "1rem" }}>Startdate</label> */}
                  <span className="SprintlabelStyle">
                    {" "}
                    {moment(e.startdate).format("DD-MMM-YYYY")}
                  </span>
                </Col>
                <Col
                  md={columns === 1 ? 3 : columns === 2 ? 6 : 12}
                  className=" p-0"
                >
                  <label style={{ fontSize: "14px" }}>Enddate:</label>
                  {/* <label style={{ fontSize: "1rem" }}>Enddate</label> */}
                  <span className="SprintlabelStyle">
                    {" "}
                    {moment(e.enddate).format("DD-MMM-YYYY")}
                  </span>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    ) : (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "200px" }}
      >
        <label style={{ fontSize: "18px" }}>
          <span class="mdi mdi-alert-circle-outline"></span>
          {"  "}
          {/* {mergedData?.length > 0 ? "No" : "yes"} */}
          {message}
        </label>
      </div>
    );
  };
  // console.log(data);
  return (
    <div>
      <div>
        <div className="d-flex justify-content-center mt-2">
          <TextField
            id="filled-search"
            label="Search issue"
            type="text"
            variant="outlined"
            size="small"
            value={input}
            onChange={(e) => handlechange(e.target.value)}
            onKeyPress={handleEnterKeyPress}
          />
          {input && (
            <Button
              size="sm"
              className="ml-3 bg-info border-0"
              onClick={() => fetchdata(input)}
            >
              Search
            </Button>
          )}
        </div>
      </div>
      <div className="mt-2">{renderCards()}</div>
    </div>
  );
}

export default HistorySearch;
