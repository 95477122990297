import { Input, Table, TextField, Card } from "@material-ui/core";

import axios from "../../../axios";
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Slide, toast } from "react-toastify";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Pagination from "react-bootstrap/Pagination";
import ProblemManagement from "../ProblemManagement";
import Snackbar from "@mui/material/Snackbar";
import TablePagination from "@material-ui/core/TablePagination";
import Avatar from "@mui/material/Avatar";
import CircleIcon from "@mui/icons-material/Circle";
import MaterialTable from "material-table";
import PatchedPagination from "../../../PatchedPagination";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import { makeStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AppBar from "@material-ui/core/AppBar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Detailspagechange from "./Detailspagechange";
// import "react-toastify/dist/ReactToastify.css";
import SearchField from "react-search-field";
import Toolbar, { modules, formats } from "../../KanbanBoard/Toolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import ReactImageFallback from "react-image-fallback";
import moment from "moment";
import "../../ITServices/RaiseATicket.css";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import {
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { Refresh } from "@material-ui/icons";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Unassigned from "../../../assets/images/avatar.png";

function ChangeModule({
  Edit,
  setEdit,
  createchange,
  setcreatechange,
  data,
  shortdes,
  rollbacks,
}) {
  const clientid = JSON.parse(localStorage.getItem("clientid"));
  let arr = JSON.parse(localStorage.getItem("Empdetails"));
  const disableFutureDates = (date) => {
    return date > new Date(); // Disable dates that are after today
  };



  const [openc, setOpenc] = useState(false);
  const [changeid, setchangeid] = useState("");
  const [value, setValue] = useState(0);
  const [GroupCode, setGroupCode] = useState("0");
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedDate1, handleDateChange1] = useState(new Date());
  const [selectedDate2, handleDateChange2] = useState(new Date());
  const [category, setcategory] = useState([]);
  const [NotesLoading, setNotesLoading] = useState(false);
  const [SubCategoryOP, setSubCategoryOP] = useState([]);
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const [view, setView] = useState("cardview");
  const [assignmentoption, setassignmentoption] = useState([]);
  const [Assignedtooption, setAssignedtooption] = useState([]);
  const [Grpassigment, setgrpassign] = useState([]);
  const [Title, settitle] = useState(Edit ? shortdes : "");
  const [Type, Settype] = useState("standard");
  const [referencest, setreferencest] = useState("");
  const [categoryv, setcategoryv] = useState("");
  const [categorycode, setcategorycode] = useState("");
  const [subcategory, setsubcategory] = useState("");
  const [subcateorycode, setsubcategorycode] = useState("");
  const [assignmentgroup, setassignmentgroup] = useState("");
  const [assignvlue, setassignvalue] = useState("");
  const [assignto, setassignto] = useState("");
  const [assigntodet, setassignedtodet] = useState("");
  const [business, setbusiness] = useState("");
  const [Pvalue, setPvalue] = useState("P2");
  const [Pvaluecode, setPvaluecode] = useState("Wide impact");
  const [Ivalue, setIvalue] = useState("1");
  const [Ivaluecode, setIvaluecode] = useState("High");
  const [Desvalue, setDesvalue] = useState("");
  const [risk, setrisk] = useState("1");
  const [riskcode, setriskcode] = useState("High");
  const [Implementationplan, setImplementationplan] = useState("");
  const [messageopen, setmessageopen] = useState(false);
  const [riskdetails, setriskdetails] = useState("");
  const [certificated, setcertificated] = useState("");
  const [postchanger, setpostchanger] = useState("");
  const [communication, setcommunication] = useState("");
  const [raise, setraise] = useState("Self");
  const [HandleSomeValue, setHandleSomeValue] = useState("");
  const [Emptabledata, setEmptabledata] = useState([]);
  const [mapping, setmapping] = useState([]);
  const [rollbacksteps, setrollbacksteps] = useState(Edit ? rollbacks : "");
  const [changedetails, setchangedetails] = useState([]);
  const [statusfilter, setstatusfilter] = useState("");
  const [assignmentfilter, setassignmentfilter] = useState("");
  const [cateoryff, Setcategoryff] = useState("");
  const [certifiedcheck, setcertifiedcheck] = useState("N");
  const [rollbackcheck, setrollbackcheck] = useState("N");
  const [ifrollbackcheck, setifrollbackcheck] = useState("N");
  const [changecode, setchangecode] = useState("");
  const [retrodate, setretrodate] = useState(new Date());

  const handleretroDateChange = (date) => {
    setretrodate(date);
  };

  const [managernotes, setmanagertnotes] = useState(
    createchange && data.ActualRootCause
      ? `Created from problem: ${data.ProblemRefNumber}\n,Root cause identified: ${data.ActualRootCause.RootCause}\n,Identified by group: ${data.ActualRootCause.IdentifiedGroup.GroupName}`
      : ""
  );

  const [handleerror, sethandleerror] = useState("");
  const [opendetails, setopendetails] = useState(false);
  const [rollbacktestingdetails, setrollbacktestingdetails] = useState("");
  const [postrollbacktesting, setpostrollbacktesting] = useState("");
  const [statuscard, setstatuscard] = useState("");
  const [impactcard, setimpactcard] = useState("");
  const [riskcard, setriskcard] = useState("");
  const [page, setPage] = useState(1);

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const totalPages = Math.ceil(changedetails.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        style={{ backgroundColor: "green", color: "white" }}
        severity="success"
        {...props}
      />
    );
  });

  const timeOptions = [
    { value: 15, label: "15 minutes" },
    { value: 30, label: "30 minutes" },
    { value: 45, label: "45 minutes" },
    { value: 60, label: "1 hour" },
    { value: 120, label: "2 hours" },
    { value: 180, label: "3 hours" },
    { value: 240, label: "4 hours" },
    { value: 300, label: "5 hours" },
    { value: 360, label: "6 hours" },
    { value: 420, label: "7 hours" },
    { value: 480, label: "8 hours" },
    { value: 540, label: "9 hours" },
    { value: 600, label: "10 hours" },
  ];
  const [selectedTime, setSelectedTime] = useState(timeOptions[0]);
  let [idtofind, setidtofind] = useState("all");

  const lineStyle = {
    borderTop: "1px solid rgb(0, 0, 0 ,20%) ", // Customize the color and style
    width: "100%",
    margin: "0 0",
    overflow: "none",
  };
  useEffect(() => {
    // const lastSelectableDate = new Date();
    // lastSelectableDate.setDate(lastSelectableDate.getDate() - 1); // Set to yesterday
    // setretrodate(lastSelectableDate);
    axios
      .get("agilesaas_incident_category_master?ClientId=eq." + clientid)
      .then((response) => {
        setcategory(response.data);
      })
      .catch((error) => console.log(error));

    axios
      .get(
        "agilesaas_change_details?ClientId=eq." +
        clientid +
        "&RequestedBy->>id=eq." +
        arr[0]?.ClientUserId
      )
      .then((response) => {
        setchangedetails(response.data);
        let changecode = response.data
          ?.filter((e) => e.ChangeId)
          .sort()
          .slice(-1);
        let existingCode =
          response.data.length == 0
            ? "CHG0000000"
            : changecode[0]?.ChangeRefNumber;

        const numericPart = parseInt(existingCode.slice(4), 10) + 1;

        // Pad the numeric part with zeros to maintain the format
        const nextNumericPart = String(numericPart).padStart(6, "0");

        // Create the next code
        const nextCode = `${existingCode.slice(0, 4)}${nextNumericPart}`;
        setchangecode(nextCode);
      })
      .catch((error) => console.log(error));
    axios
      .get("subscription_user_group_mapping?ClientId=eq." + clientid)
      .then((response) => {
        setgrpassign(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("subscription_user_group_mapping?ClientId=eq." + clientid)
      .then((response) => {
        let userGroupMappings = response.data;
        const resultArray = userGroupMappings.reduce(
          (acc, userGroupMapping) => {
            userGroupMapping.GroupDetails.forEach((groupDetail) => {
              const groupId = groupDetail.GroupId;
              const groupName = groupDetail.GroupName;

              // Check if the group already exists in the result array
              const existingGroupIndex = acc.findIndex(
                (group) => group.GroupId === groupId
              );

              if (existingGroupIndex !== -1) {
                // Add the user to the existing group
                acc[existingGroupIndex].users.push({
                  Firstname: userGroupMapping.UserDetails.Firstname,
                  Lastname: userGroupMapping.UserDetails.Lastname,
                  UserId: userGroupMapping.UserId,
                });
              } else {
                // Create a new group with the user
                acc.push({
                  GroupId: groupId,
                  GroupName: groupName,
                  users: [
                    {
                      Firstname: userGroupMapping.UserDetails.Firstname,
                      Lastname: userGroupMapping.UserDetails.Lastname,
                      UserId: userGroupMapping.UserId,
                    },
                  ],
                });
              }
            });

            return acc;
          },
          []
        );
        const assignmentooption = resultArray.map((e) => {
          return {
            label: e.GroupName,
            value: e.GroupId,
            users: e.users,
          };
        });
        setassignmentoption(assignmentooption);
      })
      .catch((error) => console.log(error));
  }, []);
  function refresh() {
    axios
      .get(
        "agilesaas_change_details?ClientId=eq." +
        clientid +
        "&RequestedBy->>id=eq." +
        arr[0]?.ClientUserId
      )
      .then((response) => {
        setchangedetails(response.data);
        setNotesLoading(false);
      })
      .catch((error) => console.log(error));
  }
  const updatechangeid = (id, datatoupdate) => {
    // Find the index of the problem with the specified id
    const index = changedetails.findIndex((change) => change.ChangeId === id);

    // If the problem with the specified id is found
    if (index !== -1) {
      // Update the state with the new data
      setchangedetails((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], ...datatoupdate };
        // setchangedetails(newData.filter((x) => x.ChangeId === id));

        return newData;
      });
      // xx
      setmessageopen(true);
      console.log(`Problem with id ${id} updated successfully.`);
      return true;
    } else {
      console.log(`Problem with id ${id} not found.`);

      return false;
    }
  };
  const handleClickCard = (index, e) => {
    setchangeid(e.ChangeId);
    setopendetails(true);
    setActiveCardIndex(index);
  };
  function handleassignmentfilter(item) {
    setassignmentfilter(item.label);
  }
  function categoryfilter(item) {
    Setcategoryff(item.label);
  }

  function handlestatusfilter(item) {
    setstatusfilter(item.label);
  }
  function reseteverything(createchange, setcreatechange) {
    settitle("");
    setcategoryv("");
    setbusiness("")
    setmanagertnotes("");
    setreferencest("");
    setsubcategorycode("");
    setsubcategory("");
    setassignvalue("");
    setassignmentgroup("");
    setDesvalue("");
    setImplementationplan("");
    setriskdetails("");
    Settype("standard");
    setcertifiedcheck("N");
    setrollbacksteps("");
    setrollbackcheck("N");
    setrollbacktestingdetails("");
    setpostrollbacktesting("");
    setifrollbackcheck("N");
    sethandleerror("");
    // setcreatechange(createchange == true ? false : null);
    if (typeof createchange !== "undefined") {
      // Check if setcreatechange exists before calling it
      if (typeof setcreatechange === "function") {
        if (createchange == true) {
          setcreatechange(false);
        }
      } else {
        console.error("setcreatechange is not defined");
      }
    } else {
      console.error("createchange is not defined");
    }
  }
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [Implementationplan]);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeItemsPerPage = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setPage(1); // Reset to the first page when changing items per page
  };
  const customStylesstt = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: "auto",
      width: "auto",
      // width: 230,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const { allImage } = useSelector((state) => state.user);
  let stateoptions = [
    { value: "New", label: "New" },
    { value: "Approval", label: "Approval" },
    { value: "Scheduled", label: "Scheduled" },
    { value: "Implementation", label: "Implementation" },
    { value: "Completed", label: "Completed" },
    { value: "Closed", label: "Closed" },
    { value: "Cancelled", label: "Cancelled" },
  ];
  let RaisingOption = [
    { value: "Self", label: "Self" },
    { value: "Someone", label: "Someone" },
  ];

  const check =
    statusfilter != ""
      ? changedetails?.filter((E) => E.ChangeStages.StateCode == statusfilter)
      : cateoryff != ""
        ? changedetails?.filter((E) => E.Category.CategoryName == cateoryff)
        : assignmentfilter != ""
          ? changedetails?.filter(
            (E) => E.AssignmentGroup?.GroupName == assignmentfilter
          )
          : statusfilter != "" && cateoryff != ""
            ? changedetails?.filter(
              (E) =>
                E.CurrentState.StateName == statusfilter &&
                E.CategoryCode.CategoryName == cateoryff
            )
            : idtofind != "all"
              ? changedetails?.filter((e) => e.ChangeRefNumber == idtofind)
              : changedetails;
  const refernce = changedetails
    ?.filter((r) => r.ChangeType == "standard" && r.IsApproved == "Y")
    ?.map((e) => {
      {
        return {
          value: e.ChangeRefNumber,
          label: e.ChangeRefNumber + " " + `${e.ShortDescription}`,
        };
      }
    });
  const statuschange = (E, e, i) => {
    let states = E.target.value;

    setstatuscard(states);

    if (states == "Approval") {
      let waitapprove = {
        IsApproved: "N",
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + e.ChangeId,
          waitapprove
        )
        .then((response) => {
          console.log("approval");
          updatechangeid(e.ChangeId, waitapprove);
          toast.warning(`Waiting for Approval`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          });

          refresh();
          // setRefresh(true);
        })
        .catch((error) => console.log(error));
    } else if (states == " Scheduled") {
      let patchdatapro = {
        ChangeStages: {
          StateCode: states,
          StateName: states,
        },
        ChangeStartTime: moment().format("DD-MM-YYYY"),
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + e.ChangeId,
          patchdatapro
        )
        .then((response) => {
          console.log("stage1");
          updatechangeid(e.ChangeId, patchdatapro);
          // setRefresh(true);
        })
        .catch((error) => console.log(error));
    } else if (states == " Completed") {
      let patchdatapro1 = {
        ChangeStages: {
          StateCode: states,
          StateName: states,
        },
        ChangeEndTime: moment().format("DD-MM-YYYY"),
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + e.ChangeId,
          patchdatapro1
        )
        .then((response) => {
          updatechangeid(e.ChangeId, patchdatapro1);
          console.log("Stages2");
          // setRefresh(true);
        })
        .catch((error) => console.log(error));
    } else {
      let patchdatapro2 = {
        ChangeStages: {
          StateCode: states,
          StateName: states,
        },
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + e.ChangeId,
          patchdatapro2
        )
        .then((response) => {
          console.log("Stages3");
          updatechangeid(e.ChangeId, patchdatapro2);
          // setRefresh(true);
        })
        .catch((error) => console.log(error));
    }
    // setRefresh(true);
  };
  const impactchange = (E, e, i) => {
    let imp = E.target.value;

    setimpactcard(imp);

    let impactchanged = {
      Impact: imp,
    };
    axios
      .patch(
        "agilesaas_change_details?ChangeId=eq." + e.ChangeId,
        impactchanged
      )
      .then((response) => {
        console.log("impactchange");
        updatechangeid(e.ChangeId, impactchanged);
        // setRefresh(true);
      })
      .catch((error) => console.log(error));

    // setRefresh(true);
  };
  const riskchange = (E, e, i) => {
    let risk = E.target.value;

    setimpactcard(risk);

    let riskchanges = {
      Risk: risk,
    };
    axios
      .patch("agilesaas_change_details?ChangeId=eq." + e.ChangeId, riskchanges)
      .then((response) => {
        console.log("riskchanges");
        updatechangeid(e.ChangeId, riskchanges);
        // setRefresh(true);
      })
      .catch((error) => console.log(error));

    // setRefresh(true);
  };
  let temp = {};
  temp =
    check == null
      ? ""
      : check.map((e, i) => {
        return {
          ...e,
          Sno: e.ChangeId,
          title: e.ShortDescription,
          longdis: e.LongDescription,
          refnumber: e.ChangeRefNumber,

          Impactname: e?.Impact,
          Assignedgroup: e.AssignmentGroup?.GroupName,
          CurrentState1:
            temp != ""
              ? e.ChangeStages.StateCode == "New"
                ? "New"
                : e.ChangeStages.StateCode == "Approved"
                  ? "Approved"
                  : e.ChangeStages.StateCode == "Scheduled"
                    ? "Scheduled"
                    : e.ChangeStages.StateCode == "Implementation"
                      ? "Implementation"
                      : e.ChangeStages.StateCode == "Completed"
                        ? "Completed"
                        : e.ChangeStages.StateCode == "Closed"
                          ? "Closed"
                          : e.ChangeStages.StateCode == "Cancelled"
                            ? "Cancelled"
                            : "New"
              : "New",
          CurrentStatus:
            temp !== "" ? (
              e.ChangeStages.StateCode === "New" ? (
                <span className="text-danger bold-text">New</span>
              ) : e.ChangeStages.StateCode === "Approved" ? (
                <span className="text-info1"> Approved</span>
              ) : e.ChangeStages.StateCode === "Scheduled" ? (
                <span className="text-warning">Scheduled</span>
              ) : e.ChangeStages.StateCode === "Implementation" ? (
                <span className="text-warning">Implementation</span>
              ) : e.ChangeStages.StateCode === "Completed" ? (
                <span className="text-success">Completed</span>
              ) : e.ChangeStages.StateCode === "Closed" ? (
                <span className="text-success">Closed</span>
              ) : e.ChangeStages.StateCode === "Cancelled" ? (
                <span className="text-danger">Cancelled</span>
              ) : (
                <span className="text-danger bold-text">New</span>
              )
            ) : null,

          CreatedByDetails: e.RequestedBy.FN + " " + e.RequestedBy.LN,
          AssignedToDetails: e.AssignedTo.Name,

          CategoryCode: e.Category.CategoryName,
          SubCategoryCode: e.Subcategory.SubCategoryName,

          avatarimage: (
            <Avatar
              alt=""
              className="CardAvartor"
              src={
                allImage?.length > 0 && allImage?.filter(
                  (x) => x.teammemberid == e.RequestedBy.id
                )?.[0]?.image != undefined ? allImage?.filter(
                  (x) => x.teammemberid == e.RequestedBy.id
                )?.[0]?.image : Unassigned
              }
              sx={{ width: 35, height: 35 }}
            />
          ),

          Assignedto: (
            <div className="d-flex justify-space-between">
              {/* <span
                  className="mdi mdi-checkbox-blank-circle ml-3"
                  style={{ color: "#45DD81", fontSize: "9px" }}
                ></span> */}
              <select
                className="fontdesign ssls ml-2 mb-2"
                defaultValue={e?.Impact}
                maxWidth="40px"
                // value={impacts}
                // disabled={e.CurrentState.StateCode == "Resolved"}
                // onChange={(e, i) => impactchange(e, data)}
                title="Impact"
              >
                <option value="High">Kannan sk</option>
                <option value="Medium">Yozy Admin</option>
              </select>
            </div>
          ),

          riskdrop: (
            <div className="d-flex justify-space-between">
              {/* <span
                  // className="mdi mdi-checkbox-blank-circle ml-3"
                  // style={{ color: "#45DD81", fontSize: "9px" }}
                ></span> */}
              <select
                className="fontdesign ssls ml-2 mb-2"
                maxWidth="40px"
                key={i}
                defaultValue={e?.Risk}
                // value={riskcard[i]}

                disabled={e.ChangeStages.StateCode == "Completed"}
                onChange={(E, i) => {
                  riskchange(E, e, i);
                }}
              >
                <option value="1">High</option>
                <option value="2">Medium</option>
                <option value="3">Low</option>
              </select>
            </div>
          ),
          Impactdrop: (
            <div>
              {/* <span
                  className="mdi mdi-checkbox-blank-circle ml-3"
                  style={{ color: "#45DD81", fontSize: "9px" }}
                ></span> */}
              <select
                className="fontdesign ssls ml-2 mb-2 "
                key={i}
                defaultValue={e?.Impact}
                maxWidth="40px"
                // value={impactcard[i]}
                disabled={e.ChangeStages.StateCode == "Completed"}
                onChange={(E, i) => {
                  impactchange(E, e, i);
                }}
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
          ),
          statedrop: (
            <div>
              <select
                className="fontdesign ssls ml-2 mb-2"
                key={i}
                defaultValue={e.ChangeStages.StateCode}
                // value={statuscard[i]}
                maxWidth="40px"
                disabled={e.ChangeStages.StateCode == "Completed"}
                onChange={(E, i) => {
                  statuschange(E, e, i);
                }}
              >
                <option value="New">New</option>
                <option value="Approval">Approval</option>
                <option value="Scheduled">Scheduled</option>
                <option value="Implementation">Implementation</option>
                <option value="Completed">Completed</option>
                <option value="Closed">Closed</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>
          ),

          CreatedDate: moment(e.CreatedDateTime).format(
            "DD-MMM-YYYY,HH:mm A"
          ),
          notesdrop: (
            <button
              variant="outline-secondary"
              size="sm"
              fontSize="small"
              // style={{ fontSize: "17px",alignContent:"start" }}
              className="btn btn-text-dark  btn-sm fontdesign ml-3"
              // style={{ textAlign: "left" }}
              title="Notes"
              onClick={() => {
                setchangeid(e.ChangeId);
                // dispatch(INDETAILSUPDATE(e.IncidentId));
                setopendetails(true);
              }}
            >
              <SpeakerNotesOutlinedIcon
                className="Card_Button_Color_Approve  "
                style={{
                  color: "black",
                  fontSize: "16px",
                }}
              ></SpeakerNotesOutlinedIcon>{" "}
              <span className="ml-1"> Notes</span>
            </button>
          ),
        };
      });
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      width: 230,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const Columns = [
    { title: "S.No", field: "Sno", defaultSort: "desc" },
    { title: "Title", field: "title" },
    { title: "Refnumber", field: "refnumber" },

    { title: "Assignment group", field: "Assignedgroup" },

    { title: "Category", field: "CategoryCode" },
    { title: "SubCategory", field: "SubCategoryCode" },

    { title: "Impact", field: "Impact" },
    {
      title: "State",
      field: "CurrentState1",
      render: (e) => (
        <label
          className={
            e.CurrentState1 === "New"
              ? "text-danger"
              : e.CurrentState1 === "Approved"
                ? "text-info1"
                : e.CurrentState1 === "Scheduled"
                  ? "text-warning"
                  : e.CurrentState1 === "Implementation"
                    ? "text-warning"
                    : e.CurrentState1 === "Completed"
                      ? "text-success"
                      : e.CurrentState1 === "Closed"
                        ? "text-success"
                        : e.CurrentState1 === "Cancelled"
                          ? "text-danger"
                          : ""
          }
        >
          <strong>
            {e.CurrentState1 === "New"
              ? "New"
              : e.CurrentState1 === "Approved"
                ? "Approved"
                : e.CurrentState1 === "Scheduled"
                  ? "Scheduled"
                  : e.CurrentState1 === "Implementation"
                    ? "Implementation"
                    : e.CurrentState1 === "Completed"
                      ? "Completed"
                      : e.CurrentState1 === "Closed"
                        ? "Closed"
                        : e.CurrentState1 === "Cancelled"
                          ? "Cancelled"
                          : ""}
          </strong>
        </label>
      ),
    },
  ];
  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    if (newValue == 0) {
    } else if (newValue == 1) {
    } else if (newValue == 2) {
    }
  };
  let PriorityOption = [
    // 1 - System down, 2 - Wide impact ,3 - Moderate impact , 4 - Low impact
    { value: "P1", label: "P1-System down", code: "System down" },
    { value: "P2", label: "P2-Wide impact", code: "Wide impact" },
    { value: "P3", label: "P3-Moderate impact", code: "Moderate impact" },
    { value: "P4", label: "P4-Low impact", code: "Low impact" },
  ];
  let Impactoption = [
    // Urgency, Impact : 1-High, 2-Medium, 3-Low
    { value: "1", label: "High" },
    { value: "2", label: "Medium" },
    { value: "3", label: "Low" },
  ];
  let urgencyoption = [
    // Urgency, Impact : 1-High, 2-Medium, 3-Low
    { value: "1", label: "High" },
    { value: "2", label: "Medium" },
    { value: "3", label: "Low" },
  ];
  let changetype = [
    // Urgency, Impact : 1-High, 2-Medium, 3-Low
    { value: "standard", label: "Standard" },

    { value: "normal", label: "Normal" },
    { value: "emergency", label: "Emergency" },
    { value: "retrospec", label: "Retrospective" },
  ];

  let categoryoption =
    category == ""
      ? ""
      : category?.map((e) => {
        return {
          value: e.CategoryCode,
          label: e.CategoryName,
          id: e.IncidentCategoryId,
        };
      });
  const handlecategory = (e) => {
    setcategoryv(e.label);
    setcategorycode(e.value);
    let subsample = category.filter((r) => r.IncidentCategoryId == e.id);

    // const gropassign = assignedto.map((e) => {
    //   return {
    //     label: e.UserDetails.Firstname + " " + e.UserDetails.Lastname,
    //     value: e.UserId,
    //   };
    // });

    // SetAssignedTo(gropassign);

    let Subcat =
      subsample == "" ? "" : subsample.map((t) => t.SubCategoryDetails);
    let subcategoryoption =
      Subcat == ""
        ? ""
        : Subcat[0].map((u) => {
          return {
            value: u.SubCategoryCode,
            label: u.SubCategoryName,
          };
        });

    setSubCategoryOP(subcategoryoption);

    // setcategoryValue(e.id);
  };
  const useStyles = makeStyles({
    datePickerInput: {
      marginTop: "2%",
      width: "100%",
      border: "none",
      "&:MuiInputBase-root": {
        border: "none",
        padding: "2%",
      }, // Remove the default border
      "&:focus": {
        outline: "none", // Optionally, remove the focus outline as well
      },
    },
    root: {
      "& .MuiBox-root": {
        paddingTop: 2,
        margin: 1,
      },
      "& .MuiAppBar-root": {
        // boxShadow: "none", // Remove the box shadow from the tab header
        paddingLeft: 0,
        margin: 0,
      },
      "& .MuiTabs-root": {
        display: "flex", // Use flex layout
        justifyContent: "flex-start",
      },
    },
  });
  const classes = useStyles();
  function handleclose() {
    reseteverything();
    setOpenc(false);
    if (typeof createchange !== "undefined") {
      // Check if setcreatechange exists before calling it
      if (typeof setcreatechange === "function") {
        if (createchange == true) {
          setcreatechange(false);
        }
      } else {
        console.error("setcreatechange is not defined");
      }
    } else {
      console.error("createchange is not defined");
    }
    // setcreatechange(createchange == true ? false : null);
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const appBarStyle = {
    fontSize: "14px",
    textTransform: "capitalize",
    fontWeight: "bold",
  };
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  const customStylesd = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const handleButtonClick = (selectedView) => {
    // Set the view state to the clicked button
    setView(selectedView);
  };
  const handledesc = (value) => {
    setDesvalue(value);
  };
  const handletabdesc = (e, property) => {
    const value = e.target.value;
    switch (property) {
      case "implementationplan":
        setImplementationplan(value);
        break;
      case "riskdetails":
        setriskdetails(value);
        break;
      case "certificate":
        setcertificated(value);
      case "postchange":
        setpostchanger(value);
      case "communication":
        setcommunication(value);

      default:
        break;
    }
  };
  const handleSomeOne = (e) => {
    if (e.key === "Enter") {
      if (HandleSomeValue == "") {
        alert("Enter the Valid Emailid ");
      } else if (HandleSomeValue != "") {
        axios
          .get(
            "subscription_client_users?OfficialEmail=eq." +
            HandleSomeValue +
            "&ClientId=eq." +
            clientid
          )
          .then((response) => {
            // console.log(response.data, "response,l");
            setEmptabledata(response.data);
            // sethandleerror("");
            if (response.data.length == 0) {
              alert("Enter Verified Email-Id");
            }
            let defautonb = response.data[0]?.OnboardedBy;
            if (response.data[0]?.OnboardedBy == null) {
              defautonb = "800001";
            }
            axios
              .get(
                `rpc/fun_agilesaasmanagerteamprojdetails?managerid=` +
                defautonb +
                `&clientid=` +
                clientid +
                `&teammemberid=eq.` +
                response.data[0]?.ClientUserId
              )
              .then((response) => setmapping(response.data));
          })
          .catch((error) => console.log(error), setEmptabledata(""));
      }
    }
  };
  function clear() {
    setassignmentfilter("");
    setstatusfilter("");
    Setcategoryff("");
    setidtofind("all");
  }

  function submitdata() {

    const momentSelectedDate = moment(selectedDate);
    const momentSelectedDate1 = moment(selectedDate1);
    const momentSelectedDate2 = moment(selectedDate2);


    let validateq = momentSelectedDate.diff(momentSelectedDate1, "days") === 0
    let validatei = momentSelectedDate.diff(momentSelectedDate2, "days") === 0

    sethandleerror("");
    if (Title == "") {
      sethandleerror("Title Cannot be Empty");
    } else if (Emptabledata == "" && raise == "Someone") {
      sethandleerror("Please type proper empid and click 'Enter");
    } else if (categoryv == "") {
      sethandleerror("Please select any category");
    } else if (subcateorycode == "") {
      sethandleerror("Please select any sub category");
    } else if (Pvalue == "") {
      sethandleerror("Please select any Priority");
    } else if (assignmentgroup == "") {
      sethandleerror("Please select Assignment Group");
    } else if (Implementationplan == "") {
      sethandleerror("Implementation plan cannot be empty");
    } else if (riskdetails == "") {
      sethandleerror("Enter Risk Details impact ");
    } else if (certifiedcheck == "Y" && certificated == "") {
      sethandleerror("Enter Certification details");
    } else if (certifiedcheck == "Y" && postchanger == "") {
      sethandleerror("Enter post changer tester plan");
    } else if (rollbacksteps == "") {
      sethandleerror("Enter Rollback steps");
    } else if (rollbackcheck == "Y" && rollbacktestingdetails == "") {
      sethandleerror("Enter  rollback testing details");
    } else if (rollbackcheck == "Y" && postrollbacktesting == "") {
      sethandleerror("Enter  postrollback testing plan");
    } else if (validateq == true) {
      sethandleerror(
        "The selected end date should be higher than the start date in the schedule"
      );
    } else if (
      ifrollbackcheck == "Y" &&
      validatei == true
    ) {
      sethandleerror(
        "To facilitate a rollback, confirm that the selected end date is set after the start date. "
      );
    } else if (Desvalue == "") {
      sethandleerror("Please give description");
    } else if (managernotes == "") {
      sethandleerror("Please give change notes");
    } else {
      let anotherid = Emptabledata.map((f) => f.ClientUserId).toString();

      let pid =
        raise == "Self"
          ? "CHG" + arr[0].ClientUserId + "C"
          : "CHG" + anotherid + "C";

      let stateCode;
      let stateName;

      if (referencest !== "") {

        stateCode = "Scheduled";
        stateName = "Scheduled";
      }
      else if (Type == "retrospec") {
        stateCode = "Closed";
        stateName = "Closed";
      }
      else {

        stateCode = "New";
        stateName = "New";
      }

      let ChangeNotes = [
        {
          NoteSno: 1,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: arr[0].ClientUserId,
          NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
          NotesDetails: managernotes,
        }
      ];

      if (referencest !== "") {
        ChangeNotes.push({
          NoteSno: 2,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: arr[0].ClientUserId,
          NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
          NotesDetails: `change created using umbrella ${referencest}`,
        });
      }
      else if (Type == "retrospec") {
        ChangeNotes.push({
          NoteSno: 2,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: arr[0].ClientUserId,
          NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
          NotesDetails: `Retro change created for ${moment(retrodate).format("YYYY-MM-DD")}`,
        });
      }

      let postdata = {
        ChangeRefNumber: pid,
        ChangeType: Type,
        RequestedBy: {
          FN:
            raise == "Self"
              ? arr[0].UserFirstname
              : Emptabledata.map((f) => f.UserFirstname),
          LN:
            raise == "Self"
              ? arr[0].UserLastname
              : Emptabledata.map((f) => f.UserLastname),
          id:
            raise == "Self"
              ? arr[0].ClientUserId
              : Emptabledata.map((f) => f.ClientUserId),
        },
        CreatedDateTime: Type == "retrospec" ? moment(retrodate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
        Category: {
          CategoryCode: categorycode,
          CategoryName: categoryv,
        },
        Subcategory: {
          SubCategoryCode: subcategory,
          SubCategoryName: subcateorycode,
        },
        OtherBusinessServiceAffected: {
          BusinessCode: subcategory,
          Businessame: subcateorycode,
        },
        ShortDescription: Title,
        LongDescription: Desvalue,
        Impact: Ivaluecode,
        Risk: risk,
        Priority: {
          label: Pvalue,
          code: Pvaluecode,
        },
        AssignedTo: {
          Name: assigntodet,
          id: assignto,
        },
        AssignmentGroup: {
          GroupCode: assignvlue,
          GroupName: assignmentgroup,
        },
        ChangeStages: {
          StateCode: stateCode,
          StateName: stateName,
        },
        IsSuccess: "N",
        ChangeStartTime: moment().format("YYYY-MM-DD"),
        ChangeEndTime: moment().format("YYYY-MM-DD"),
        IsCertified: certifiedcheck,
        CertificationDetails: {
          details: certificated,
        },
        IsRolledBack: rollbackcheck,
        RollbackSteps: {
          Rollbacksteps: rollbacksteps,
          Rollbacktestingdetails: rollbacktestingdetails,
          postrollbacktesting: postrollbacktesting,
          rollbackinhours: selectedTime,
        },
        ExecutionSteps: {
          ExecutionSteps: Implementationplan,
          Riskdetails: riskdetails,
        },
        PostImplementationTesting: {
          PostImplementationTesting: postchanger,
        },
        IsApproved: referencest != "" ? "Y" : "",
        ApprovalDetails: {
          ApprovedbyFN: null,
          ApprovedbyLN: null,
        },
        Schedule: {
          StartdateandTime: selectedDate,
          EnddateandTime: selectedDate1,
          IsRolledBack: ifrollbackcheck,
          RollbackEndTime: selectedDate2,
        },
        ChangeNotes: ChangeNotes,
        ReasonForChange: null,
        IsOutageRequired: null,
        OutageTimeInMin: null,
        AttachmentDetails: null,
        ClientId: clientid,
      };


      axios
        .post(`agilesaas_change_details`, postdata)
        .then((res) => {
          axios
            .get(`agilesaas_change_details?ChangeRefNumber=eq.${pid}`)
            .then((res) => {
              console.log(pid + res.data[0].ChangeId, "changid");
              axios
                .patch(`agilesaas_change_details?ChangeRefNumber=eq.${pid}`, {
                  ChangeRefNumber: pid + res.data[0].ChangeId,
                })
                .then((dataofresponse) => {
                  // setOpenc(false);
                  toast.success(`Successfully Submitted`, {
                    transition: Slide,
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    draggable: true,
                  });
                  refresh();
                  reseteverything();
                  handleclose();
                })
                .catch((e) => {
                  alert("something wentwrong1");
                });
            })
            .catch((e) => {
              console.log(e, "errorupdate");
            });
        })
        .catch((error) => console.log(error, "Errorposting"));

      if (data?.IsRootCauseIdentifed === "Y") {
        axios
          .get(`agilesaas_change_details?ChangeRefNumber=eq.${pid}`)
          .then((res) => {
            const patchprobelm = {
              ActualRootCause: {
                RootCause: data.ActualRootCause.RootCause,
                Identifiedby: data.ActualRootCause.Identifiedby,
                IdentifiedGroup: data.ActualRootCause.IdentifiedGroup,
                IdentifiedDate: moment(
                  data.ActualRootCause.IdentifiedDate
                ).format("YYYY-MM-DD"),

                ReleatedTicketReferences: pid + res.data[0].ChangeId,
              },
            };
            axios
              .patch(
                `agilesaas_problem_details?ProblemId=eq.${data.ProblemId}`,
                patchprobelm
              )
              .then((res) => {
                toast.success(`Linked to Problem`, {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
              })
              .catch((e) => {
                alert("updated failed");
              });
          })
          .catch((e) => {
            console.log(e, "errorupdate");
          });
      }
    }
  }
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#33ff33",
        color: "33ff33",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#00cc00",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#33ff33",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#00cc00",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
    "& .QontoStepIcon-ErrorIcon": {
      color: "#F76666",
      // color: '#00cc00',
      zIndex: 1,
      fontSize: 18,
    },
  }));
  function handlecertified(event) {
    const isChecked = event.target.checked;

    if (isChecked) {
      setcertifiedcheck("Y");
    } else {
      setcertifiedcheck("N");
    }
  }
  function handlerollbacktested(event) {
    const isChecked = event.target.checked;

    if (isChecked) {
      setrollbackcheck("Y");
    } else {
      setrollbackcheck("N");
    }
  }
  function handleifrollbacktested(event) {
    const isChecked = event.target.checked;

    if (isChecked) {
      setifrollbackcheck("Y");
    } else {
      setifrollbackcheck("N");
    }
  }

  function QontoStepIcon(props) {
    const { active, completed, className, Acknowledgement, index, Resolved } =
      props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <CheckCircleIcon
            className={
              (Acknowledgement === "N" && index === 1) ||
                (Resolved === "N" && index === 4)
                ? "QontoStepIcon-ErrorIcon"
                : "QontoStepIcon-completedIcon"
            }
          />
        ) : (
          <RadioButtonCheckedIcon className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const handleTimeChange = (selectedOption) => {
    setSelectedTime(selectedOption);
  };
  const steps = [
    "New",
    "Approved",
    "Scheduled",
    "Implementation",
    "Completed",
    "Closed",
    "Cancelled",
  ];
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const handleTextChange = (event) => {
    event.preventDefault();
    setImplementationplan(event.target.value);
  };
  let roledata = mapping?.map((e) => e?.projectrole?.RoleName)[0];

  return (
    <div
      className=" mt-1 "
      style={{ backgroundColor: "#F5F7FB", minHeight: "80%" }}
    >
      <div className="row mt-3">
        <div className="col-md-6">
          <button
            variant="outline-secondary"
            size="sm"
            className="btn btn-outline-info btn-sm fontdesign mt-3 ml-4"
            style={{
              fontWeight: "normal",
              width: "160px",
              borderRadius: "8px",
            }}
            onClick={() => setOpenc(true)}
          >
            <ControlPointIcon fontSize="small" className="mr-1" />
            Change Request
          </button>
        </div>
        <div className="col-md-6 d-flex justify-content-end mt-3">
          <Button
            style={{
              fontSize: 14,
              padding: 9,
              width: 130,
              height: 40,
              borderRadius: 8,
              marginRight: 14,
              border: "1px solid #5A88F1",
              backgroundColor: "#fff",
              color: view === "cardview" ? "#5250E5" : "black",
              textAlign: "center",
              textTransform: "none",
            }}
            onClick={() => handleButtonClick("cardview")}
          >
            <ComputerOutlinedIcon /> Card View
          </Button>
          <Button
            style={{
              fontSize: 14,
              padding: 9,
              width: 130,
              height: 40,
              borderRadius: 8,
              marginRight: 14,
              border: "1px solid #5A88F1",
              backgroundColor: "#fff",
              color: view === "tabelview" ? "#5250E5" : "black",
              textAlign: "center",
              textTransform: "none",
            }}
            onClick={() => handleButtonClick("tabelview")}
          >
            <TableRowsOutlinedIcon /> Table View
          </Button>
        </div>
      </div>

      <Modal
        dialogClassName="changerequest"
        show={openc || createchange}
        onHide={handleclose}
      // size="medium"
      // fullWidth={true}
      >
        <Modal.Header closeButton>
          <Modal.Title className="TitlelabelStyle">
            <label
              style={{
                color: "#2593B8",
                fontWeight: "bold",
                marginLeft: "60px",
              }}
            >
              {" "}
              Change Request
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="container"
            style={{ marginLeft: "50px", marginRight: "30px" }}
          >
            {/* <Row style={{ display: "flex", justifyContent: "end" }}></Row> */}
            <div className="row ">
              <div className="ml-2">
                <button
                  className=""
                  style={{
                    fontSize: 12,
                    padding: 9,
                    width: 120,
                    height: 50,
                    borderRadius: 8,
                    // border: "1px solid #5250E5",
                    backgroundColor: "#5250E5",
                    color: "#fff",
                    textAlign: "center",
                    textTransform: "none",
                    clipPath:
                      "polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)",
                  }}
                >
                  New
                </button>
                <button
                  style={{
                    fontSize: 12,
                    padding: 9,
                    width: 120,
                    height: 50,
                    fontWeight: "bold",
                    // borderRadius: 8,
                    // border: "1px solid black",
                    backgroundColor: "#D9D9D9",
                    textAlign: "center",
                    textTransform: "none",
                    // boxShadow: "inset 0 0 0 3px black",
                    clipPath:
                      "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
                  }}
                >
                  <span className="ml-2"> Approval</span>
                </button>

                <button
                  style={{
                    fontSize: 12,
                    padding: 9,
                    width: 120,
                    height: 50,

                    fontWeight: "bold",
                    borderRadius: 8,
                    // border: "1px solid black",
                    backgroundColor: "#D9D9D9",
                    color: "#000",
                    textAlign: "center",
                    textTransform: "none",
                    clipPath:
                      "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
                  }}
                >
                  <span className="ml-3"> Scheduled</span>
                </button>
                <button
                  style={{
                    fontSize: 12,
                    padding: 9,
                    width: 120,
                    height: 50,

                    fontWeight: "bold",
                    borderRadius: 8,
                    // border: "1px solid black",
                    backgroundColor: "#D9D9D9",
                    color: "#000",
                    textAlign: "center",
                    textTransform: "none",
                    clipPath:
                      "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
                  }}
                >
                  <span style={{ marginLeft: "20px" }}> Implementation </span>
                </button>
                <button
                  style={{
                    fontSize: 12,
                    padding: 9,
                    width: 120,
                    height: 50,

                    fontWeight: "bold",
                    borderRadius: 8,
                    // border: "1px solid black",
                    backgroundColor: "#D9D9D9",
                    color: "#000",
                    textAlign: "center",
                    textTransform: "none",
                    clipPath:
                      "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
                  }}
                >
                  <span className="ml-3"> Completed</span>
                </button>
                <button
                  style={{
                    fontSize: 12,
                    padding: 9,
                    width: 120,
                    height: 50,

                    fontWeight: "bold",
                    borderRadius: 8,
                    // border: "1px solid black",
                    backgroundColor: "#D9D9D9",
                    color: "#000",
                    textAlign: "center",
                    textTransform: "none",
                    clipPath:
                      "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
                  }}
                >
                  Closed
                </button>
                <button
                  style={{
                    fontSize: 12,
                    padding: 9,
                    width: 120,
                    height: 50,
                    marginLeft: "5px",
                    borderRadius: 8,
                    fontWeight: "bold",
                    // border: "1px solid black",
                    backgroundColor: "#D9D9D9",
                    color: "#000",
                    textAlign: "center",
                    textTransform: "none",

                    clipPath:
                      "polygon(99% 0, 100% 50%, 100% 100%, 0% 100%, 26% 51%, 0% 0%)",
                  }}
                >
                  Cancelled
                </button>
              </div>

              <div>
                <div className="mt-4" style={{ marginLeft: "38px" }}>
                  <Row>
                    <div className="ml-3">
                      <label className="labelStyle">
                        Title <label className="text-danger"> *</label>{" "}
                      </label>
                      <br />

                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Write Request title"
                        className="mytypeRawt"
                        label=""
                        name=""
                        // className="TextFieldRAT"
                        size="small"
                        value={Title}
                        onChange={(t) => settitle(t.target.value)}
                      // error={titleError}
                      // helperText={titleError ? 'Title cannot be empty' : ''}
                      />
                    </div>
                  </Row>

                  <Row className="justify-content-between">
                    {/* <div className="col-md-4">
                      <label className="labelStyle lla mt-2">
                        Change <label className="text-danger"> *</label>{" "}
                      </label>
                      <br />
                      <TextField
                        type="text"
                        variant="outlined"
                        style={{ height: 38, width: 230 }}
                        label=""
                        name=""
                        size="small"
                        InputProps={{ readOnly: true }}
                        defaultValue={changecode}
                      />
                    </div> */}

                    <div className="col-md-4">
                      <label className="mt-2 labelStyle lla">
                        Type <label className="text-danger"> *</label>{" "}
                      </label>
                      <Select
                        label="SubCategory"
                        className="mt-1"
                        styles={customStyles}
                        options={changetype}
                        value={changetype?.filter((e) => e.value == Type)}
                        onChange={(e) => {
                          Settype(e.value);
                        }}
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="labelStyle lla mt-2">
                        Reference {" "}
                      </label>
                      <br />

                      <Select
                        label="Assigned To"
                        styles={customStyles}
                        className="mt-2"
                        options={Type == "standard" ? refernce : []}
                        value={refernce?.filter((e) => e.value == referencest)}
                        onChange={(e) => {
                          setreferencest(e.value);
                        }}
                      />
                      {/* <label className="mt-2 labelStyle lla">
                        State <label className="text-danger"> *</label>{" "}
                      </label>
                      <Select
                        label="Assigned To"
                        styles={customStyles}
                        options={stateoptions}
                        value={stateoptions?.filter((e) => e.value == state)}
                        onChange={(e) => {
                          setstate(e.value);
                        }}
                        isDisabled={true}
                      /> */}
                    </div>
                    <div className="col-md-4">
                      <label className="labelStyle lla mt-2">
                        Raised By <label className="text-danger"> *</label>{" "}
                      </label>
                      <br />

                      <Select
                        label="Assigned To"
                        styles={customStyles}
                        options={RaisingOption}
                        value={RaisingOption?.filter((e) => e.value == raise)}
                        onChange={(e) => {
                          setraise(e.value);
                        }}
                      />
                      {raise == "Someone" ? (
                        <>
                          <TextField
                            type="text"
                            variant="outlined"
                            placeholder="Type email"
                            className="mt-2"
                            style={{ height: 38, width: 230 }}
                            label=""
                            name=""
                            onChange={(e) => {
                              setHandleSomeValue(e.target.value);
                              setEmptabledata("");
                            }}
                            onKeyDown={handleSomeOne}
                            // className="TextFieldRAT"
                            size="small"
                          />
                          {Emptabledata != "" &&
                            Emptabledata.map((f) => {
                              return (
                                <Row className="ml-3">
                                  <label>
                                    {f.UserFirstname +
                                      " " +
                                      f.UserLastname +
                                      " " +
                                      roledata}
                                  </label>

                                  {/* <Col xl={3} xs={3} sm={3} md={3} xxl={3} lg={3}><label  className='RATfontwieght text'>Emp Level</label></Col>
                                                    <Col xl={3} xs={3} sm={3} md={3} xxl={3} lg={3}><label  className='RATfontwieght text'>{f.emplevel}</label></Col> */}

                                  <Col
                                    xl={3}
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    xxl={3}
                                    lg={3}
                                  >
                                    <label className="RATfontwieght text"></label>
                                  </Col>
                                </Row>
                              );
                            })}
                        </>
                      ) : null}
                    </div>
                  </Row>

                  <Row className="mt-4 justify-content-space-between ">
                    <div className="col-md-4">
                      <label className="mt-2 labelStyle lla  ">
                        Category <label className="text-danger"> *</label>{" "}
                      </label>
                      <Select
                        label="impact"
                        styles={customStyles}
                        options={categoryoption}
                        // value={Impactoption?.filter((e)=>e.value == Ivalue)}
                        onChange={handlecategory}
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="mt-2 labelStyle lla ">
                        Sub Category
                        <label className="text-danger"> *</label>{" "}
                      </label>
                      <Select
                        label="impact"
                        styles={customStyles}
                        options={SubCategoryOP}
                        value={SubCategoryOP?.filter(
                          (e) => e.value == subcategory
                        )}
                        onChange={(e) => {
                          setsubcategory(e.value);
                          setsubcategorycode(e.label);
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="labelStyle lla mt-2">
                        Assigned Group
                        <label className="text-danger"> *</label>{" "}
                      </label>
                      <br />
                      <Select
                        label="Assigned To"
                        styles={customStyles}
                        options={assignmentoption}
                        value={assignmentoption?.filter(
                          (e) => e.value == assignvlue
                        )}
                        onChange={(e) => {
                          setassignmentgroup(e.label);
                          setassignvalue(e.value);

                          setAssignedtooption(
                            e.users?.map((e) => {
                              return {
                                value: e.UserId,
                                label: e.Firstname + " " + e.Lastname,
                              };
                            })
                          );
                        }}
                        inputProps={{ readOnly: true }}
                      />
                    </div>
                  </Row>
                  <Row className="mt-4 justify-content-space-between ">
                    <div className="col-md-4">
                      <label className="mt-2 labelStyle lla  ">
                        Assigned To
                      </label>
                      <Select
                        label="impact"
                        styles={customStyles}
                        className="mt-2"
                        options={Assignedtooption}
                        value={Assignedtooption?.filter(
                          (e) => e.value == assignto
                        )}
                        onChange={(e) => {
                          setassignto(e.value);
                          setassignedtodet(e.label);
                        }}
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="mt-2 labelStyle lla ">
                        Business Services
                      </label>
                      <Select
                        label="impact"
                        styles={customStyles}
                        className="mt-2"
                        options={SubCategoryOP}
                        value={SubCategoryOP?.filter(
                          (e) => e.value == business
                        )}
                        onChange={(e) => {
                          setbusiness(e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="labelStyle lla mt-2">
                        {" "}
                        Risk <label className="text-danger"> *</label>{" "}
                      </label>
                      <br />
                      <Select
                        label="Risk"
                        styles={customStyles}
                        options={urgencyoption}
                        defaultValue={urgencyoption.find(
                          (option) => option.value === risk
                        )}
                        // value={urgencyoption?.filter((e) => e.value == risk)}
                        onChange={(e) => {
                          setrisk(e.value);
                          setriskcode(e.label);
                        }}
                      />
                    </div>
                  </Row>
                  <Row className="mt-4 justify-content-space-between ">
                    <div className="col-md-4">
                      <label className="mt-2 labelStyle lla  ">
                        Priority <label className="text-danger"> *</label>{" "}
                      </label>
                      <Select
                        label="impact"
                        styles={customStyles}
                        options={PriorityOption}
                        value={PriorityOption?.filter((e) => e.value == Pvalue)}
                        onChange={(e) => {
                          setPvalue(e.value);
                          setPvaluecode(e.code);
                        }}
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="mt-2 labelStyle lla ">
                        Impact <label className="text-danger"> *</label>{" "}
                      </label>
                      <Select
                        label="impact"
                        styles={customStyles}
                        options={Impactoption}
                        value={Impactoption?.filter((e) => e.value == Ivalue)}
                        onChange={(e) => {
                          setIvalue(e.value);
                          setIvaluecode(e.label);
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      {Type == "retrospec" ? (
                        <>
                          <label className="mt-2 labelStyle lla ">
                            Select Date <label className="text-danger"> *</label>{" "}
                          </label>

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              InputProps={{
                                style: {
                                  outlineColor: "red",
                                  width: "65%",
                                  height: "60px",

                                },
                              }}
                              classes={{
                                root: classes.datePickerInput, // Apply the custom styles to the root element
                              }}
                              // className="datepickerDesign"
                              value={retrodate}
                              onChange={(date) => handleretroDateChange(date)}
                              format="dd/MM/yyyy "
                              views={["year", "month", "date"]}
                              // shouldDisableDate={disableDatesBefore}
                              // disablePast
                              shouldDisableDate={date => disableFutureDates(date)}
                              required
                            />
                          </MuiPickersUtilsProvider>

                        </>
                      ) : null}
                    </div>

                  </Row>
                  <Row className="mt-4">
                    <div className="ml-3">
                      <label className="mt-2 labelStyle lla ">
                        Description
                      </label>
                      <>
                        <Toolbar />
                        <ReactQuill
                          className="quillrelease customQuill"
                          theme="snow"
                          placeholder="Enter Description"
                          value={Desvalue}
                          onChange={(e) => handledesc(e)}
                          modules={modules}
                          formats={formats}
                        // style={{ width: 'calc(100% - 60px)' }}
                        />
                      </>
                    </div>
                  </Row>
                </div>
                <Tabs
                  defaultActiveKey="implementationplan"
                  id="justify-tab-example"
                  className="horizontal-tabs mt-3 "
                  variant="tabs"
                  style={{ marginLeft: "-27px" }}
                >
                  <Tab
                    eventKey="implementationplan"
                    title="Implementation Plan "
                    className="headings"
                    defaultActiveKey="implementationplan"
                    style={{ marginLeft: "-27px" }}
                  >
                    <div className="ml-4">
                      <Row className="mt-1 ml-2 ">
                        <div style={{ width: "70%" }}>
                          <label className="labelStyle lla">
                            Implementation Steps{" "}
                            <label className="text-danger"> *</label>{" "}
                          </label>

                          <br />

                          <textarea
                            className="SprintFormFields "
                            value={Implementationplan}
                            onChange={(e) =>
                              setImplementationplan(e.target.value)
                            }
                            ref={inputRef}
                            style={{
                              width: "800px",
                              height: "60px",
                            }}
                          ></textarea>
                        </div>
                      </Row>
                      <Row className="mt-1 ml-2 ">
                        <div>
                          <label className="labelStyle lla">
                            Risk details impact{" "}
                            <label className="text-danger"> *</label>{" "}
                          </label>

                          <br></br>
                          <textarea
                            className="SprintFormFields "
                            value={riskdetails}
                            onChange={(e) => handletabdesc(e, "riskdetails")}
                            style={{
                              width: "800px",
                              height: "60px",
                            }}
                          ></textarea>
                        </div>
                      </Row>
                      <Row className="mt-1 ml-2">
                        <label>
                          <input
                            type="checkbox"
                            value="Y"
                            className="ml-2 mt-2"
                            style={{ transform: "scale(1.2)" }}
                            checked={certifiedcheck === "Y"}
                            onChange={handlecertified}
                          />
                          <label className="ml-2 "> Is Certified?</label>
                        </label>
                      </Row>

                      <Row className="mt-1  ml-2">
                        {certifiedcheck == "Y" ? (
                          <div>
                            <label className="labelStyle lla">
                              Certification details{" "}
                              <label className="text-danger"> *</label>{" "}
                            </label>

                            <br></br>
                            <textarea
                              className="SprintFormFields "
                              value={certificated}
                              onChange={(e) => setcertificated(e.target.value)}
                              style={{
                                width: "800px",
                                height: "60px",
                              }}
                            ></textarea>
                          </div>
                        ) : null}
                      </Row>
                      <Row className="mt-1 ml-2">
                        {certifiedcheck == "Y" ? (
                          <div>
                            <label className="labelStyle lla">
                              Post change testing plan{" "}
                              <label className="text-danger"> *</label>{" "}
                            </label>

                            <br></br>
                            <textarea
                              className="SprintFormFields "
                              value={postchanger}
                              onChange={(e) => setpostchanger(e.target.value)}
                              style={{
                                width: "800px",
                                height: "60px",
                              }}
                            ></textarea>
                          </div>
                        ) : null}
                      </Row>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="RollbackPlan"
                    title="Roll Back Plan "
                    className="headings ml-2"
                    style={{ marginLeft: "-27px" }}
                  >
                    <div className="ml-4">
                      <Row className="mt-1 ">
                        <div>
                          <label className="labelStyle lla">
                            Rollback Steps{" "}
                            <label className="text-danger"> *</label>{" "}
                          </label>

                          <br></br>
                          <textarea
                            className="SprintFormFields "
                            value={rollbacksteps}
                            onChange={(e) => setrollbacksteps(e.target.value)}
                            style={{
                              width: "800px",
                              height: "60px",
                            }}
                          />
                        </div>
                      </Row>
                      <Row className="mt-1">
                        <label>
                          <input
                            type="checkbox"
                            value="Y"
                            className="ml-2 mt-2"
                            style={{ transform: "scale(1.2)" }}
                            checked={rollbackcheck === "Y"}
                            onChange={handlerollbacktested}
                          />
                          <label className="ml-2 ">Is Rollback tested?</label>
                        </label>
                      </Row>
                      <Row className="mt-1  ">
                        {rollbackcheck == "Y" ? (
                          <div>
                            <label className="labelStyle lla">
                              Rollback testing details
                              <label className="text-danger"> *</label>{" "}
                            </label>

                            <br></br>
                            <textarea
                              className="SprintFormFields "
                              value={rollbacktestingdetails}
                              onChange={(e) =>
                                setrollbacktestingdetails(e.target.value)
                              }
                              style={{
                                width: "800px",
                                height: "60px",
                              }}
                            />
                          </div>
                        ) : null}
                      </Row>

                      <Row className="mt-1  ">
                        {rollbackcheck == "Y" ? (
                          <div>
                            <label className="labelStyle lla">
                              Post rollback testing plan{" "}
                              <label className="text-danger"> *</label>{" "}
                            </label>

                            <br></br>
                            <textarea
                              className="SprintFormFields "
                              value={postrollbacktesting}
                              onChange={(e) =>
                                setpostrollbacktesting(e.target.value)
                              }
                              style={{
                                width: "800px",
                                height: "60px",
                              }}
                            />
                          </div>
                        ) : null}
                      </Row>
                      <Row className="mt-1  ">
                        {rollbackcheck == "Y" ? (
                          <div>
                            <label className="labelStyle lla">
                              Rollback in hours{" "}
                              <label className="text-danger"> *</label>{" "}
                            </label>

                            <br></br>
                            <Select
                              id="timeSelect"
                              options={timeOptions}
                              value={selectedTime}
                              onChange={handleTimeChange}
                            />
                          </div>
                        ) : null}
                      </Row>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="schedule"
                    title="Schedule "
                    className="headings ml-2"
                    style={{ marginLeft: "-27px" }}
                  >
                    <div className="ml-4">
                      <Row className="mt-1">
                        <div style={{ width: "85%" }}>
                          <label className="labelStyle lla">
                            Start Date & Time{" "}
                            <label className="text-danger"> *</label>{" "}
                          </label>

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              InputProps={{
                                style: {
                                  outlineColor: "red",
                                  width: "100%",
                                  height: "60px",
                                  border: "1px solid #ced4da", // Add border for outline
                                  borderRadius: "4px", // Op
                                },
                              }}
                              classes={{
                                root: classes.datePickerInput, // Apply the custom styles to the root element
                              }}
                              className="datepickerDesign"
                              value={selectedDate}
                              onChange={(date) => handleDateChange(date)}
                              format="dd-MM-yyyy HH:mm"
                              views={[
                                "year",
                                "month",
                                "date",
                                "hours",
                                "minutes",
                              ]}
                              // shouldDisableDate={disableDatesBefore}
                              disablePast
                              required
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Row>

                      <Row className="mt-1 ">
                        <div style={{ width: "85%" }}>
                          <label className="labelStyle lla">
                            End Date & Time
                            <label className="text-danger"> *</label>{" "}
                          </label>

                          <br></br>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              InputProps={{
                                style: {
                                  outlineColor: "red",
                                  width: "100%",
                                  height: "60px",
                                  border: "1px solid #ced4da", // Add border for outline
                                  borderRadius: "4px",
                                },
                              }}
                              classes={{
                                root: classes.datePickerInput, // Apply the custom styles to the root element
                              }}
                              className="datepickerDesign"
                              value={selectedDate1}
                              onChange={(date) => handleDateChange1(date)}
                              format="dd-MM-yyyy HH:mm"
                              views={[
                                "year",
                                "month",
                                "date",
                                "hours",
                                "minutes",
                              ]}
                              // shouldDisableDate={disableDatesBefore}
                              disablePast
                              required
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Row>
                      <Row className="mt-1">
                        <label>
                          <input
                            type="checkbox"
                            value="Y"
                            className="ml-2 mt-2"
                            style={{ transform: "scale(1.2)" }}
                            checked={ifrollbackcheck === "Y"}
                            onChange={handleifrollbacktested}
                          />
                          <label className="ml-2 ">If Rollback</label>
                        </label>
                      </Row>

                      <Row className="mt-1">
                        {ifrollbackcheck == "Y" ? (
                          <div style={{ width: "85%" }}>
                            <label className="labelStyle lla">
                              End Date & time{" "}
                              <label className="text-danger"> *</label>{" "}
                            </label>

                            <br></br>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                InputProps={{
                                  style: {
                                    outlineColor: "red",
                                    width: "100%",
                                    height: "60px",
                                    border: "1px solid #ced4da", // Add border for outline
                                    borderRadius: "4px",
                                  },
                                }}
                                classes={{
                                  root: classes.datePickerInput, // Apply the custom styles to the root element
                                }}
                                className="datepickerDesign"
                                value={selectedDate2}
                                onChange={(date) => handleDateChange2(date)}
                                format="dd-MM-yyyy HH:mm"
                                views={[
                                  "year",
                                  "month",
                                  "date",
                                  "hours",
                                  "minutes",
                                ]}
                                // shouldDisableDate={disableDatesBefore}
                                disablePast
                                required
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        ) : null}
                      </Row>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="conflicts"
                    title="Conflicts "
                    className="headings ml-2"
                    style={{ marginLeft: "-27px" }}
                  >
                    <div className="ml-4">
                      <Row className="mt-1">
                        <div>
                          <label className="labelStyle lla">
                            Other changes conflicting the schedule{" "}
                          </label>
                          <div className="mt-3">
                            <link
                              rel="stylesheet"
                              href="https://fonts.googleapis.com/icon?family=Material+Icons"
                            ></link>
                            <MaterialTable
                              title={"Conflicts"}
                              columns={Columns.map((e) => e)}
                              // data={temp === "" ? "" : temp.map((e) => e)}
                              // detailPanel={(rowdata) => {
                              //   return (
                              //     <div className="mt-1" style={{ backgroundColor: "#ebedf2" }}>
                              //       <div
                              //         style={{
                              //           fontSize: 13,
                              //           color: "white",
                              //           backgroundColor: "#ebedf2",
                              //         }}
                              //       >

                              //       </div>

                              //     </div>
                              //   );
                              // }}
                              components={{
                                Pagination: PatchedPagination,
                              }}
                              options={{
                                actionsColumnIndex: -1,
                                exportAllData: true,
                                exportButton: true,
                                columnResizable: true,
                                filtering: false,
                                sorting: true,
                                paging: true,
                                pageSize: 20, // make initial page size
                                emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                                pageSizeOptions: [6, 12, 20, 50], // rows selection options

                                // rowStyle: rowData => ({
                                //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                // }),
                                headerStyle: {
                                  fontWeight: "700",
                                  fontSize: "medium",

                                  // backgroundColor: "#eef2f7",
                                  color: "black",
                                  width: "calc(250px)",
                                  padding: "12px ",
                                  textAlign: "left",
                                  whiteSpace: "nowrap",
                                },
                                cellStyle: {
                                  textAlign: "left",
                                  fontSize: "medium",
                                  padding: "21px",
                                  color: "#6c757d",
                                  // padding: "15px 2px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="notes"
                    title="Notes "
                    className="headings "
                    style={{ marginLeft: "-27px" }}
                  >
                    <div className="ml-4">
                      <Row className="mt-1 ml-2">
                        <div style={{ width: "85%" }}>
                          <label className="labelStyle lla">
                            Change Notes{" "}
                            <label className="text-danger"> *</label>{" "}
                          </label>
                          <textarea
                            className="SprintFormFields "
                            placeholder="Enter Notes"
                            value={managernotes}
                            // value={
                            //   createchange && data.ActualRootCause
                            //     ? `Created from problem: ${data.ProblemRefNumber}\nRoot cause identified: ${data.ActualRootCause.RootCause}\nIdentified by group: ${data.ActualRootCause.IdentifiedGroup.GroupName}`
                            //     : managernotes
                            // }

                            onChange={(e) => setmanagertnotes(e.target.value)}
                            style={{
                              width: "100%",
                              height: "80px",
                            }}
                          />
                        </div>
                      </Row>
                    </div>
                  </Tab>
                </Tabs>

                <div className="error errorfont" style={{ fontSize: "16px" }}>
                  {" "}
                  {handleerror == "" ? "" : handleerror}{" "}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              marginTop: "15px",
              width: "90%",
            }}
          >
            <Row>
              <Button
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 100,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 14,
                  border: "2px solid #5A88F1",
                  backgroundColor: "#fff",
                  color: "#5250E5",
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={reseteverything}
              >
                Reset
              </Button>
              <Button
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 100,
                  height: 40,
                  borderRadius: 8,
                  // marginRight: 14,
                  border: "2px solid #5250E5",
                  backgroundColor: "#5250E5",
                  color: "#fff",
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={submitdata}
              >
                Submit
              </Button>
            </Row>
          </div>
        </Modal.Body>
        <style></style>
      </Modal>
      <div className="ml-4 mt-3">
        {assignmentoption?.length === 1 ? (
          <label style={{ fontWeight: "600" }}>
            Belonging To : {assignmentoption[0].label}
          </label>
        ) : (
          <label style={{ fontWeight: "600" }}>
            Belonging To :{" "}
            <select
              onChange={(f) => {
                // gooto
                setGroupCode(f.target.value);
              }}
            >
              {assignmentoption?.map((e) => (
                <option value={e.value}>{e.label}</option>
              ))}
              <option value="0">all</option>
            </select>
          </label>
        )}
      </div>

      <div className="row">
        <div className="col-9">
          {view === "cardview" ? (
            changedetails.length == 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                <SentimentDissatisfiedOutlinedIcon
                  style={{ fontSize: "5rem" }}
                />
                <h2>No Changes Found</h2>
                <p>
                  We couldn't find any change request matching your filter, or
                  something went wrong.
                </p>
                <button
                  style={{
                    width: 100,
                    height: 36,
                    borderRadius: 5,
                    background: "#5250E5",
                    color: "#fff",
                  }}
                  onClick={refresh}
                >
                  Try Again
                </button>
              </div>
            ) : (
              temp
                .slice(startIndex, endIndex)
                .filter((e) =>
                  e.CurrentState1 != "Closed" &&
                    e.CurrentState1 != "Cancelled" &&
                    GroupCode === "0"
                    ? e.AssignmentGroup.GroupCode !== ""
                    : e.AssignmentGroup.GroupCode === GroupCode
                )
                .map((e, item) => {
                  const isActiveCard = activeCardIndex === item;
                  return (
                    <div className=" ml-2 mt-2 ">
                      <Card
                        className="mt-3"
                        style={{
                          background: "#F9FAFB",
                          border: "1px solid rgb(0, 0, 0 ,20%)",
                          borderLeft:
                            activeCardIndex === item
                              ? "3px solid blue"
                              : "1px solid rgb(0, 0, 0 ,20%)",
                        }}
                      >
                        <Row>
                          <Col lg={12} md={12} xl={12} sm={12}>
                            {/* <Stepper
  alternativeLabel
  activeStep={
    e.CurrentState1 === "Completed"
      ? 5
      : Math.min(steps.indexOf(e.CurrentState1), 1) // Ensure activeStep doesn't go beyond index 1
  }
  connector={<QontoConnector />}
  className="mt-3 d-flex justify-content-start"
>
  {steps.map((label, index) => {
    let shouldDisplayStep = true;

    // Check if the condition to stop rendering steps between index 1 and 2 is met
    if (e.Approved === "N" && index > 1) {
      shouldDisplayStep = false;
    }

    return shouldDisplayStep ? (
      <Step key={label}>
        <StepLabel
          StepIconComponent={(props) =>
            QontoStepIcon({
              ...props,
              Acknowledgement: e.Acknowledgement,
              index: index,
              Resolved: e.Resoloved,
            })
          }
        >
          <span
            style={{
              color:
                (e.Approved === "N" && index === 1) ||
                (e.Resoloved === "N" && index === 4)
                  ? "#F76666"
                  : "inherit",
            }}
          >
            {label}
          </span>
        </StepLabel>
      </Step>
    ) : null;
  })}
</Stepper> */}
                            {/* <Steppessr
                              alternativeLabel
                              activeStep={
                                e.CurrentState1 === "Completed"
                                  ? 5
                                  : steps.indexOf(e.CurrentState1)
                              }
                              connector={<QontoConnector />}
                              className="mt-3 d-flex justify-content-start"
                            >
                              {steps.map((label, index) => (
                                <Step key={label}>
                                  <StepLabel
                                    StepIconComponent={(props) =>
                                      QontoStepIcon({
                                        ...props,
                                        Acknowledgement: e.Acknowledgement,
                                        index: index,
                                        Resolved: e.Resoloved,
                                      })
                                    }
                                  >
                                    <span
                                      style={{
                                        color:
                                          (e.Approved === "N" && index === 1) ||
                                          (e.Resoloved === "N" &&
                                            index === 4) ||
                                          (e.CurrentState1 === "Scheduled" &&
                                            index === 6) ||
                                          (e.CurrentState1 === "Cancelled" &&
                                            index === steps.length - 1)
                                            ? "red"
                                            : "inherit",
                                      }}
                                    >
                                      {label}
                                    </span>
                                  </StepLabel>
                                </Step>
                                 
                              ))}
                               {e.Approved === "N" &&  (
                                    <Step key="custom-label">
                                      <StepLabel>
                                        Waiting For Approval
                                      </StepLabel>
                                    </Step>
                                  )}
                            </Steppessr> */}

                            <Stepper
                              alternativeLabel
                              activeStep={
                                e.IsApproved === "N"
                                  ? steps.indexOf("Approved") - 0.5 // Stop between "New" and "Approved" when Approved is "N"
                                  : e.CurrentState1 === "Completed"
                                    ? 5
                                    : steps.indexOf(e.CurrentState1)
                              }
                              connector={<QontoConnector />}
                              className="mt-3 d-flex justify-content-start"
                            >
                              {steps.map((label, index) => (
                                <Step key={label}>
                                  {index == 1 && e.IsApproved === "N" ? (
                                    <label
                                      className="mt-3"
                                      style={{
                                        position: "absolute",
                                        fontSize: "12px",
                                        bottom: 7,
                                        left: "calc(-50% + 16px)",
                                        right: "calc(50% + 16px)",
                                        top: 10,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color:
                                          (e.IsApproved === "N" &&
                                            index === 1) ||
                                            (e.Resoloved === "N" && index === 4)
                                            ? "#F76666"
                                            : (e.IsApproved === "N" &&
                                              index === 1) ||
                                              (e.Resoloved === "N" &&
                                                index === 4)
                                              ? "#00e600"
                                              : "inherit",
                                      }}
                                    >
                                      {index == 1 && e.IsApproved === "N" ? (
                                        <span className="text-danger">
                                          Waiting For Approval
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                  ) : null}
                                  <StepLabel
                                    StepIconComponent={(props) =>
                                      QontoStepIcon({
                                        ...props,
                                        Acknowledgement: e.Acknowledgement,
                                        index: index,
                                        Resolved: e.Resoloved,
                                      })
                                    }
                                  >
                                    <span
                                      style={{
                                        color:
                                          (e.Approved === "N" && index === 1) ||
                                            (e.Resoloved === "N" &&
                                              index === 4) ||
                                            (e.CurrentState1 === "Scheduled" &&
                                              index === 6) ||
                                            (e.CurrentState1 === "Cancelled" &&
                                              index === steps.length - 1)
                                            ? "red"
                                            : "inherit",
                                      }}
                                    >
                                      {label}
                                    </span>
                                  </StepLabel>
                                  {/* {console.log(e.Approved,"e.approved",e)} */}
                                  {/* Add a label between "New" and "Approved" when e.Approved is "N" */}
                                  {/* {index ==  1 && e.IsApproved === "N" && (
                                        <Step>
                                          <StepLabel className="text-danger">
                                         
                                              Waiting For Approval
                                  
                                          </StepLabel>
                                        </Step>
                                      )} */}
                                </Step>
                              ))}
                            </Stepper>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xl={1}
                            lg={2}
                            md={2}
                            sm={2}
                            className="d-flex jsutify-content-center"
                            onClick={() => {
                              handleClickCard(item, e);
                            }}
                          >
                            {e.avatarimage}
                          </Col>

                          <Col
                            lg={8}
                            md={8}
                            sm={8}
                            // lg={9} md={9} sm={9}
                            // className="d-flex jsutify-content-center"
                            onClick={() => handleClickCard(item, e)}
                          >
                            <div onClick={() => handleClickCard(item, e)}>
                              <label
                                className="fontdesign  mt-2"
                                style={{ fontSize: "12px" }}
                              >
                                {e?.CurrentState}
                              </label>

                              <h5
                                className="text-lg  ml-4 "
                                style={{ fontWeight: "600" }}
                              >
                                #{e.refnumber}:{e.title}
                              </h5>

                              <Row
                                className="fontdesign mt-1 ml-4"
                                style={{ fontSize: "12px", fontWeight: "600" }}
                              >
                                <label>
                                  Created :{" "}
                                  {moment(e.CreatedDate).format("DD-MMM-YYYY")}
                                </label>
                                <span className="ml-1">|</span>
                                <label className="ml-1">
                                  {" "}
                                  Created By :{e.CreatedByDetails}
                                </label>{" "}
                                <span className="ml-1">|</span>
                                <label className="ml-1">
                                  {" "}
                                  Category :{e.CategoryCode}
                                </label>
                                <span className="ml-1">|</span>
                                <label className="ml-1">
                                  {" "}
                                  Subcategory :
                                  <span style={{ fontWeight: "600" }}>
                                    {e.SubCategoryCode}
                                  </span>
                                </label>
                              </Row>
                              <div className="ml-2">
                                <span
                                  className="fontdesign  d-flex justify-content-space-between"
                                  style={{ fontSize: "12px" }}
                                >
                                  <label className="ml-3 mt-1">
                                    {/* <label>
                                    Created :{" "}
                                    <span style={{ fontWeight: "600" }}>
                                      {" "}
                                      {moment(e.CreatedDate).format(
                                        "DD-MMM-YYYY"
                                      )}
                                    </span>
                                  </label> */}
                                    {/* <CircleIcon
                                    className="ml-2"
                                    style={{ fontSize: "5px" }}
                                  ></CircleIcon> */}

                                    <label>
                                      {" "}
                                      Assigned Group :
                                      <span style={{ fontWeight: "600" }}>
                                        {e.Assignedgroup}
                                      </span>
                                    </label>
                                    {e.AssignedToDetails == "" ? null : (
                                      <>
                                        <CircleIcon
                                          className="ml-2"
                                          style={{ fontSize: "5px" }}
                                        ></CircleIcon>

                                        <label className="ml-2">
                                          Assigned To :
                                          <span style={{ fontWeight: "600" }}>
                                            {" "}
                                            {e.AssignedToDetails}{" "}
                                          </span>
                                        </label>
                                      </>
                                    )}
                                    {/* <CircleIcon
                                      className="ml-2"
                                      style={{ fontSize: "5px" }}
                                    ></CircleIcon> */}
                                  </label>
                                </span>
                              </div>
                            </div>
                          </Col>

                          <Col lg={3} md={3} sm={3}>
                            {/* <div className="mt-4"> */}
                            <Row className="mt-1">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    width: "100px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Impact:
                                </label>
                                {e.Impactdrop}
                              </div>
                            </Row>
                            <Row className="mt-1">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    width: "100px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Risk:
                                </label>
                                {e.riskdrop}
                              </div>
                            </Row>
                            <Row className="mt-1">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    width: "100px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  State:
                                </label>
                                {e.statedrop}
                              </div>
                            </Row>
                            {/* <Row className="mt-1">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    width: "100px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Notes:
                                </label>
                                {e.notesdrop}
                              </div>
                            </Row> */}

                            {/* <Row className="mt-1">
        <strong>Impact: </strong> {e.Impactdrop}
    </Row>
    <Row className="mt-1">
        <strong>Risk: </strong>  {e.riskdrop}
    </Row>
    <Row className="mt-1">
        <strong>State: </strong>    {e.statedrop}
    </Row>
    
    <Row className="mt-1">
    {e.notesdrop}
    </Row> */}
                            {/* <Row>
  <Col>
    <strong style={{ fontSize: "12px", marginRight: "5px" }}>
      Impact:
    </strong>
  </Col>
  <Col className="align-items-center">
    {e.Impactdrop}
  </Col>
</Row> */}

                            {/* <Row>
                                <Col>
                                  <strong style={{ fontSize: "12px" }}>
                                    Impact:
                                  </strong>
                                </Col>
                                <Col className=" align-items-center">
                                  {e.Impactdrop}
                                </Col>
                              </Row> */}

                            {/* <Row>
                              <Col>
                                <strong style={{ fontSize: "12px" }}>
                                  Impact:
                                </strong>
                              </Col>
                              <Col className=" align-items-center">
                                {e.Impactdrop}
                              </Col>
                            </Row> */}
                            {/* </div> */}
                            {/* 
                            <div className="mt-1">
                              <Row>
                                <Col>
                                  <strong style={{ fontSize: "12px" }}>
                                    Risk:
                                  </strong>
                                </Col>
                                <Col className="align-items-center">
                                  {e.riskdrop}
                                </Col>
                              </Row>
                            </div>

                            <div className="mt-1">
                              <Row>
                                <Col>
                                  <strong style={{ fontSize: "12px" }}>
                                    State:
                                  </strong>
                                </Col>
                                <Col className=" align-items-center">
                                  {e.statedrop}
                                </Col>
                              </Row>
                            </div>

                            <div className="mt-1">
                              <Row>
                                <Col>{e.notesdrop}</Col>
                              </Row>
                            </div> */}
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  );
                })
            )
          ) : null}
          {view == "cardview" && changedetails.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
                marginTop: 10,
              }}
            >
              <Pagination className="probpagination" style={{ color: "#000" }}>
                <Pagination.First
                  onClick={() => handleChangePage(1)}
                  disabled={page === 1}
                />
                <Pagination.Prev
                  onClick={() => handleChangePage(page - 1)}
                  disabled={page === 1}
                />

                {Array.from({ length: totalPages }, (_, i) => {
                  if (totalPages <= 7 || i + 1 === 1 || i + 1 === totalPages) {
                    return (
                      <Pagination.Item
                        key={i + 1}
                        active={i + 1 === page}
                        onClick={() => handleChangePage(i + 1)}
                      >
                        <span>{i + 1}</span>
                      </Pagination.Item>
                    );
                  } else if (
                    (i + 1 >= page - 1 && i + 1 <= page + 1) ||
                    (i + 1 === page - 2 && i + 1 > 2) ||
                    (i + 1 === page + 2 && i + 1 < totalPages - 1)
                  ) {
                    return (
                      <Pagination.Item
                        key={i + 1}
                        active={i + 1 === page}
                        onClick={() => handleChangePage(i + 1)}
                      >
                        <span>{i + 1}</span>
                      </Pagination.Item>
                    );
                  } else if (
                    (i + 1 === 2 && page > 4) ||
                    (i + 1 === totalPages - 1 && page < totalPages - 3)
                  ) {
                    return <Pagination.Ellipsis key={i + 1} disabled />;
                  }

                  return null;
                })}

                <Pagination.Next
                  onClick={() => handleChangePage(page + 1)}
                  disabled={page === totalPages}
                />
                <Pagination.Last
                  onClick={() => handleChangePage(totalPages)}
                  disabled={page === totalPages}
                />
              </Pagination>

              <select
                className="form-select mb-3 p-1 "
                value={itemsPerPage}
                onChange={handleChangeItemsPerPage}
              >
                <option value={5}>5 items per page</option>
                <option value={10}>10 items per page</option>
                <option value={25}>25 items per page</option>
              </select>
            </div>
          ) : null}
        </div>
        <div className="col-3 mt-2 ">
          {" "}
          {view == "cardview" ? (
            <div className="mt-2">
              <Card
                className="shadow-sm "
                style={{
                  height: "600px",
                  width: "260px",
                  border: "1px solid rgb(0, 0, 0 ,20%) ",
                  zIndex: "-1",
                  alignItems: "center",
                }}
              >
                <span className="mt-2 ml-3" style={{ fontWeight: "bold" }}>
                  Filters
                  <Button
                    onClick={clear}
                    style={{
                      fontSize: 14,
                      padding: 9,
                      width: 100,
                      height: 40,
                      borderRadius: 8,
                      marginRight: 14,
                      border: "none",
                      outline: "none",
                      marginLeft: "80px",

                      backgroundColor: "#fff",
                      color: "#5250E5",
                      textAlign: "right",
                      textTransform: "none",
                    }}
                  >
                    {" "}
                    Clear{" "}
                  </Button>
                </span>

                <div style={{ alignItems: "center" }}>
                  <label
                    className="mt-3 ml-5 fontdesign"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Refnumber
                  </label>

                  <input
                    placeholder="Change Refnumber"
                    style={{ width: "70%" }}
                    styles={customStylesd}
                    className="SprintFormFields ml-5"
                    value={idtofind == "all" ? "" : idtofind}
                    onChange={(sdata) => {
                      if (sdata.target.value == "") {
                        setidtofind("all");
                        if (page != 1) {
                          setPage(1);
                        }
                      } else {
                        if (page != 1) {
                          setPage(1);
                        }
                        setidtofind(sdata.target.value.toUpperCase());
                      }
                    }}
                  />

                  <label
                    className="mt-3 ml-5 fontdesign"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Assignment Group{" "}
                  </label>
                  <Select
                    className="fontdesign filterselect mt-2 ml-5"
                    options={assignmentoption}
                    styles={customStylesd}
                    placeholder="Any"
                    onChange={(item) => {
                      handleassignmentfilter(item);
                    }}
                    value={assignmentoption?.filter(
                      (e) => e.label === assignmentfilter
                    )}
                  ></Select>
                  <label
                    className="mt-3 ml-5 fontdesign"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Status
                  </label>
                  <Select
                    className="fontdesign filterselect mt-2 ml-5"
                    placeholder="Any"
                    options={stateoptions}
                    styles={customStylesd}
                    onChange={(item) => {
                      handlestatusfilter(item);
                    }}
                    value={stateoptions?.filter(
                      (e) => e.label === statusfilter
                    )}
                  ></Select>

                  <label
                    className="mt-3 ml-5 fontdesign"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Category
                  </label>
                  <Select
                    className="fontdesign filterselect mt-2 ml-5 mb-5"
                    options={categoryoption}
                    styles={customStylesd}
                    onChange={(item) => {
                      categoryfilter(item);
                    }}
                    value={
                      categoryoption &&
                      categoryoption?.filter((e) => e.label === cateoryff)
                    }
                    placeholder="Any"
                  ></Select>
                </div>
              </Card>
            </div>
          ) : null}
        </div>
      </div>
      {view == "tabelview" ? (
        <div className="mt-5 ml-5" style={{ padding: "1px" }}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          ></link>
          <MaterialTable
            title={"Change Raised By Me"}
            columns={Columns.map((e) => e)}
            data={temp === "" ? "" : temp.map((e) => e)}
            components={{
              Pagination: PatchedPagination,
            }}
            //         detailPanel={(rowdata) => {
            //           return (
            //             <div
            //               style={{
            //                 fontSize: 13,
            //                 color: "white",
            //                 // backgroundColor: "#ffff",
            //               }}
            //             >
            //               <Table
            //                 class="table table-bordered"
            //                 style={{
            //                   fontSize: "10px",
            //                   fontFamily: "DM Sans, sans-serif;",
            //                   backgroundColor: "#ebedf2",
            //                 }}
            //               >
            //                 <thead
            //                   className="tableHeader"
            //                   style={{
            //                     fontSize: "10px",
            //                     fontFamily: "DM Sans, sans-serif;",
            //                   }}
            //                 >
            //                   {/* <th>Title</th> */}
            //                   <th style={{ fontWeight: "bold" }}>Description</th>
            //                   <th style={{ fontWeight: "bold" }}>
            //                     RaisingOnBehalfDetails
            //                   </th>
            //                   {/* <th>CreatedDate</th> */}
            //                   <th style={{ fontWeight: "bold" }}>CategoryCode</th>
            //                   <th style={{ fontWeight: "bold" }}>SubCategoryCode</th>
            //                   <th style={{ fontWeight: "bold" }}>
            //                     {" "}
            //                     AssignedToDetails
            //                   </th>
            //                   {/* AssignedToDetails: e.AssignedToDetails.FN + " " + e.AssignedToDetails.LN,
            // CategoryCode.CategoryName, */}
            //                   {/* <th>Urgency</th>
            //                                     <th>Impact</th> */}
            //                 </thead>

            //                 <tr>
            //                   {/* <td>{rowdata.IncidentTitle}</td> */}
            //                   <td> {rowdata.longdis}</td>
            //             <td>
            //               {/* {rowdata.IsSelfRequest == "Y"
            //                 ? "Self"
            //                 : rowdata.RaisingOnBehalfDetails} */}
            //             </td>
            //                   {/* <td>{moment(rowdata.CreatedDate).format('DD-MM-YYYY HH:mm:ss')}</td> */}
            //                   {/* <td> {rowdata.CategoryCode}</td> */}
            //                   {/* <td> {rowdata.SubCategoryCode}</td> */}
            //                   {/* <td>{rowdata.ServiceAffected.ServiceName}</td> */}
            //                   {/* <td>{rowdata.AssignedToDetails}</td> */}

            //                   {/* <td>{rowdata.Impact.ImpactName}</td> */}
            //                 </tr>
            //               </Table>
            //             </div>
            //           );
            //         }}
            options={{
              actionsColumnIndex: -1,
              exportAllData: true,
              exportButton: true,
              columnResizable: true,
              filtering: false,
              sorting: true,
              paging: true,
              pageSize: 20, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [6, 12, 20, 50], // rows selection options

              // rowStyle: rowData => ({
              //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              // }),
              headerStyle: {
                whiteSpace: "nowrap",
                fontWeight: "700",
                fontSize: "medium",
                // backgroundColor: "#eef2f7",
                color: "black",
                padding: "12px ",
                textAlign: "left",
              },
              cellStyle: {
                textAlign: "left",
                fontSize: "medium",
                padding: "21px",
                color: "#6c757d",
                // padding: "15px 2px",
              },
              // headerStyle: {
              //   padding: "2px",
              //   fontWeight: "700",
              //   fontSize: "small",
              //   backgroundColor: "#107aeb85",
              //   color: "#03256c",
              // },
              // cellStyle: {
              //   fontSize: "12px",
              //   padding: "2px",
              //   color: "#03256c",
              // },
              rowStyle: (rowdata) => {
                if (rowdata.tableData.id % 2 != 0) {
                  // return { backgroundColor: "#f6f7fb" };
                }
              },
            }}
          // actions={[
          //   (rowData) => ({
          //     icon: () => (
          //       <SpeakerNotesOutlinedIcon
          //         className="Card_Button_Color_Approve1"
          //         style={{
          //           color: "rgb(25, 138, 227)",
          //         }}
          //       >
          //         {" "}
          //         Notes{" "}
          //       </SpeakerNotesOutlinedIcon>
          //     ),
          //     tooltip: "Notes",
          //     //   disabled: rowData.isrequestfulfilled == "Y",
          //     onClick: (e, data) => {
          //       console.log(data, "data");
          //       // if (data.datavalid != "Resolved") {
          //       handleClickOpen1(data, data.IncidentId);
          //       // }
          //     },
          //   }),
          // ]}
          />

          {/* <Modal
    // style={{width:"1000px"}}
    ClassName="uploadbox"
    show={Upload}
    // size="sm"
    onHide={handleclose}
  >
    <Modal.Header closeButton>
    
    </Modal.Header>
    <Modal.Body>
      <Container>
       
      </Container>
    </Modal.Body>
  </Modal> */}
        </div>
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={messageopen}
        autoHideDuration={1500}
        onClose={() => {
          setmessageopen(false);
        }}
      >
        <Alert
          onClose={() => {
            setmessageopen(false);
          }}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green !important",
            color: "white !important",
          }}
        >
          updated successfully!
        </Alert>
      </Snackbar>
      <Dialog
        open={opendetails}
        onClose={() => {
          setopendetails(false);
        }}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle>
          <div className="row">
            <div className="col d-flex justify-content-start">
              <label
                className="text-black fontdesign"
                style={{ fontWeight: "bold" }}
              >
                {"Details page"} - (
                {
                  changedetails?.filter((e) => e.ChangeId === changeid)[0]
                    ?.ChangeRefNumber
                }
                )
              </label>
            </div>
            <div
              className="col d-flex justify-content-end"
              role="button"
              onClick={() => setopendetails(false)}
            >
              <CloseIcon />
            </div>
          </div>
        </DialogTitle>
        <hr style={lineStyle} />
        <DialogContent className="mt-3">
          <Detailspagechange
            changedetails={changedetails?.filter(
              (e) => e.ChangeId === changeid
            )}
            stateoptions={stateoptions}
            assignmentoption={assignmentoption}
            Assignedtooption={Assignedtooption}
            setAssignedtooption={setAssignedtooption}
            refresh={refresh}
            NotesLoading={NotesLoading}
            setNotesLoading={setNotesLoading}
            openc={openc}
            setOpenc={setOpenc}
            setopendetails={setopendetails}
          />
        </DialogContent>
      </Dialog>
      <div>
        {createchange == true ? (
          <ProblemManagement openc={openc} setopenc={setOpenc} />
        ) : null}
      </div>
    </div>
  );
}

export default ChangeModule;
