import { Checkbox, TextField } from "@material-ui/core";
import axios from "../../axios";
import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Button1 from "@mui/material/Button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import "./project/addnewproject.css";
import {
  Col,
  Row,
  Container,
  CardBody,
  Table,
  Card,
  Progress,
} from "reactstrap";
import DateFnsUtils from "@date-io/date-fns";

import ProjectAllocation from "../KanbanBoard/project/ProjectAllocation";
import Allocationseamless from "../KanbanBoard/project/Allocationseamless";
// import ReportTeamallocate from "./ReportTeamallocate";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import moment from "moment";
import { toast, Slide } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";

import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import UserContext from "../../index";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Divider } from "@mui/material";
import styled from "@mui/material/styles/styled";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { HANDLE_ON_CHANGE, PROJECTCREATE } from "../../redux/CommonInitialsate";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import createcategory from "../../createcategory";
import ProjectModules from "./project/ProjectModules";

const Addnewuserproject = ({
  //KBRefresh,
  // setRefresh, Refresh
}) => {
  let id = JSON.parse(localStorage.getItem("clientid"));
  let initialValue = {};
  let rolecode =
    localStorage.getItem("Rolecode") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Rolecode"));
  let userdetails = JSON.parse(localStorage.getItem("clientdetails"));
  let createdby = JSON.parse(localStorage.getItem("Empdetails"));
  let projects = JSON.parse(localStorage.getItem("Orgsubscriptiondetails"));

  let empid = JSON.parse(localStorage.getItem("Empdetails"));
  let projectslength = projects?.map((e) => e.PlanCap?.Projects);

  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == 11004)[0]
    ?.RoleCode;
  const newDate = new Date();

  const [projectnames, setProjectName] = useState("");
  // const [projectdetails, setProjectdetails] = useState([]);

  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedDate2, handleDateChange2] = useState(
    newDate.setMonth(newDate.getMonth() + 6)
  );
  const [selectedRow, setSelectedRow] = useState(null);
  const [allocationopen, setallocationopen] = useState(false);
  const [Values, setValues] = useState(initialValue);
  const [error, seterror] = useState({});
  const [dateerror, setdateerror] = useState("");
  const [row, setrow] = useState("");
  const [editopen, setedit] = useState(false);
  const [seamlessopen, setseamlessopen] = useState(false);

  const [Report, setReport] = useState(false);
  // const [openProCreate, setopenProCreate] = useState(false);
  const [ProAllocateModal, setProAllocateModal] = useState(false);
  const [number, setNumber] = useState("");
  const [employee, setEmployee] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [checkSubmit, setSubmitCheck] = useState(false);
  const [modules, setModules] = useState([
    {
      Name: "",
      Type: "Monolith",
      FullName: "",
    },
  ]);
  const contextval = useContext(UserContext);
  const dispatch = useDispatch();
  const { openProCreate, projectdetails } = useSelector((state) => state.user);
  useEffect(() => {
    contextval.setwindows("project");
    axios
      .get(
        "subscription_user_role_mapping?ProductDetails->>ProdId=eq.11004&ClientId=eq." +
        id +
        "&LoginEnabled=eq.Y"
      )
      .then((res) => {
        let getdata = res.data;
        let filteredData = [];
        axios
          .get("subscription_client_users?ClientId=eq." + id + "&IsActive=eq.Y")
          .then((re) => {
            let userdata = re.data;
            getdata.map((e) => {
              let check = userdata.filter((x) => x.ClientUserId == e.UserId);
              if (check.length != 0) {
                filteredData.push(e);
              }
            });
          });
        setEmployee(filteredData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const userorg = userdetails[0]?.OrgDetails;
  const userdet = userdetails[0]?.UserDetails;
  const { projectRole, projectRoleName, projectDetails } = useSelector(
    (state) => state.user
  );
  //this for project details check according to plancap
  const lastcode = projectdetails
    ?.map((e) => e.ProjectId)
    .sort()
    .slice(-1)
    .toString();

  const check = projectdetails?.map((e) => e.ProjectId).length;

  useEffect(() => {
    axios.get(`agilesaas_project_master?ClientId=eq.` + id).then((res) => {
      // setProjectdetails(
      //   res.data.map((e) => {
      //     return { ...e, selectOption: false };
      //   })
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "projectdetails", value: res.data.map((e) => {
            return { ...e, selectOption: false };
          })
        })
      );

      // setNumber(res.data.map((e)=>e.ProjectShortCode, 10))
    });
  }, []);
  console.log(projectDetails.length, "projectdetailslengt");
  function ProjectClose() {
    const newDate = new Date();
    dispatch(PROJECTCREATE(false));
    setProjectName("");
    seterror({});
    handleDateChange(new Date());
    handleDateChange2(newDate.setMonth(newDate.getMonth() + 6));
    setdateerror("");
    setModules([
      {
        Name: "",
        Type: "Monolith",
        FullName: "",
      },
    ]);
  }
  function AllocateClose() {
    setProAllocateModal(false);
  }
  console.log(projectdetails, "projectdetails");
  const validateForm = () => {
    const newErrors = {};

    if (projectnames.trim() == "") {
      newErrors.projectnames = "Enter the Projectname";
    } else if (
      moment(selectedDate2).diff(moment(selectedDate), "days") == 0 ||
      moment(selectedDate2).diff(moment(selectedDate), "days") < 0
    ) {
      setdateerror("Please check Dates-StartDate should be lower than EndDate");
      // } else if (projectslength <= check) {
      //   toast.error(
      //     `You have exceeded the minimal plan please upgrade to higher plan Further... `,
      //     {
      //       transition: Slide,
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 8000,
      //       draggable: true,
      //     }
      //   );
    }

    seterror(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const projectccode = projectdetails?.find(
    (e) => e.ProjectName === projectnames
  )?.ProjectId;

  console.log(selectedDate2, "enddate6months");
  const handleprojectname = (e) => {
    let text = e.target.value;

    setProjectName(text);
    const updatedModules = modules.map((module) => ({
      ...module,
      Name: e.target.value.replace(/\s+/g, "").replace(/[^a-zA-Z0-9]/g, ""),
    }));
    setModules(updatedModules);
  };

  function Refresh() {
    axios.get(`agilesaas_project_master?ClientId=eq.` + id).then((res) => {
      // setProjectdetails(res.data);
      dispatch(
        HANDLE_ON_CHANGE({ name: "projectdetails", value: res.data })
      );
    });
    setProjectName("");
    // setseamlessopen(false);
    handleDateChange(new Date());
    handleDateChange2(newDate.setMonth(newDate.getMonth() + 6));
    setModules([
      {
        Name: "",
        Type: "Monolith",
        FullName: "",
      },
    ]);
  }

  const handleEditing = (e) => {
    setedit(true);
    setrow(e);
  };
  const handleallocation = () => {
    setallocationopen(true);
  };
  const handleReport = (e) => {
    setReport(true);
    setrow(e);
  };

  function submit(e) {
    modules.forEach((module) => {
      // Check if both Name and Type are filled
      if (module.Name && module.Type) {
        // Combine Name and Type into FullName
        module.FullName = module.FullName
          ? module.FullName
          : `${module.Name}_${module.Type}`;
      }
    });

    axios.get(`agilesaas_project_master?ClientId=eq.` + id).then((res) => {
      console.log(res.data.length, "lengtg");
      // if (res.data.length !== 0){
      let prjcode = res.data
        ?.filter((e) => e.ProjectId)
        .sort()
        .slice(-1);
      let code = prjcode[0]?.ProjectShortCode;

      console.log(prjcode, "projcode");
      const numericPart = parseInt(code?.slice(4), 10) + 1;

      // Pad the numeric part with zeros to maintain the format
      const nextNumericPart = String(numericPart).padStart(7, "0");

      // Create the next code

      setNumber(`${projectnames?.slice(0, 4)}${nextNumericPart}`);

      // }
      // else{
      //   // const prefix = projectnames.slice(0, 4);
      //   setNumber(`${projectnames.slice(0, 4)}${"000001"}`)
      // }

      console.log(number, "nextcode");

      const chectknm =
        res.data.length != 0
          ? `${projectnames?.slice(0, 4)}${nextNumericPart}`
          : `${projectnames.slice(0, 4)}${"000001"}`;

      //   const generatedString = `${prefix}${number.toString()}`;
      //   setNumber(number + 1);
      // console.log(generatedString,"generatedstringf")

      e.preventDefault();
      let postdata = {
        ProjectShortCode: chectknm,
        ProjectName: projectnames,
        ClientId: id,
        ClientDetails: {
          Company: userorg?.Company,
          ContactName: userorg?.ContactName,
          ContactDesignation: userorg?.ContactDesignation,
          ContactEmail: userorg?.ContactEmail,
          ContactPhone: userorg?.ContactPhone,
        },
        CreatedDate: moment().format("YYYY-MM-DD"),
        CreatedBy: empid[0]?.ClientUserId,
        CreatedByDetails: {
          Name: userorg?.ContactName,
          Role: rolecode,
          Email: userdet?.Email,
          Phone: userorg?.ContactPhone,
        },
        StartDate: moment(selectedDate).format("YYYY-MM-DD"),
        EndDate: moment(selectedDate2).format("YYYY-MM-DD"),
        IsActive: "Y",
        Status: null,
      };
      // console.log(postdata, "postdata")
      if (projectslength <= check) {
        toast.error(
          `You have exceeded the minimal plan please upgrade to higher plan Further... `,
          {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 8000,
            draggable: true,
          }
        );
      } else if (
        moment(selectedDate2).diff(moment(selectedDate), "days") == 0 ||
        moment(selectedDate2).diff(moment(selectedDate), "days") < 0
      ) {
        setdateerror(
          "Please check Dates-StartDate should be lower than EndDate"
        );
      } else {
        if (validateForm()) {
          setSubmitCheck(true);

          axios
            .post(`agilesaas_project_master`, postdata)
            .then((response) => {
              createcategory(projectnames);
              axios
                .get(`agilesaas_project_master?ClientId=eq.` + id)
                .then((res) => {
                  // Initialize variables to store the most recent project's details
                  let latestProjects = [];
                  let latestCreatedDate = ""; // Initialize as an empty string

                  // Loop through the projects
                  for (const project of res.data) {
                    // Compare the current project's creation date to the latest created date
                    if (project.CreatedDate > latestCreatedDate) {
                      // If this project has a later creation date, reset the latest projects array
                      latestProjects = [project];
                      latestCreatedDate = project.CreatedDate;
                    } else if (project.CreatedDate === latestCreatedDate) {
                      // If this project has the same creation date as the latest, add it to the array
                      latestProjects.push(project.ProjectId);
                    }
                  }

                  // Find the project with the latest "ProjectId" among the latest projects
                  let lastUpdatedProject = latestProjects?.reduce(
                    (prev, current) => {
                      return prev.ProjectId > current.ProjectId
                        ? prev
                        : current;
                    }
                  );
                  let coeprj = lastUpdatedProject?.ProjectId;
                  let initialValue = {
                    TeamMemberId: empid[0]?.ClientUserId,
                    ProjectCode:
                      coeprj == undefined ? lastUpdatedProject : coeprj,
                    ProjectStartDate: new Date(),
                    ProjectEndDate: new Date(),
                    ManagerId: createdby[0]?.ClientUserId,
                    AllocPercentage: 100,
                    ProjectRole: {
                      RoleGroupId: "1",
                      RoleCode: "SM",
                      RoleName: "Scrum Master",
                    },
                    MappedBy: createdby[0]?.ClientUserId,
                    MappedByDetails: {
                      ContactName: userdetails[0]?.OrgDetails?.ContactName,
                      ContactDesignation: userdetails[0]?.RoleDetails?.RoleCode,
                      ContactEmail: userdetails[0]?.UserDetails?.Email,
                      ContactPhone: userdetails[0]?.OrgDetails?.ContactPhone,
                    },
                    IsCurrent: "Y",
                    Status: null,
                    ClientId: id,
                  };
                  axios
                    .post("agilesaas_project_mapping", initialValue)
                    .then((res) => {
                      toast.success(`Project created successfully! ✅
                                    View it in the Projects section.`, {
                        transition: Slide,
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                        draggable: true,
                      });

                      ProjectClose();
                      // KBRefresh();
                      dispatch(HANDLE_ON_CHANGE({ name: 'kbrefreshstate', value: true }))
                      // setRefresh(true);
                      dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
                    });
                  window.location.reload();
                });
              Refresh();
              // KBRefresh();
              dispatch(HANDLE_ON_CHANGE({ name: 'kbrefreshstate', value: true }))
              ProjectClose();
              // setRefresh(true);
              dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
              setSubmitCheck(false);
              // handleClose();
            })
            .catch((err) => console.log(err));

          // KBRefresh();
          dispatch(HANDLE_ON_CHANGE({ name: 'kbrefreshstate', value: true }))

          //   dispatch(PROJECTCREATE(false))
          // setseamlessopen(true);
        }
      }
    });
  }

  function handleClose() {
    setedit(false);
    Refresh();
    setallocationopen(false);
    setReport(false);
    setseamlessopen(false);
    setModules([
      {
        Name: "",
        Type: "Monolith",
        FullName: "",
      },
    ]);
    // setRefresh(true);
  }
  const handleSecondFriend = (rowDataProjectId) => {
    // setProjectdetails(
    //   projectdetails.map((friend) =>
    //     // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
    //     friend.ProjectId === rowDataProjectId
    //       ? { ...friend, selectOption: !friend.selectOption }
    //       : { ...friend }
    //   )
    // );
    dispatch(
      HANDLE_ON_CHANGE({
        name: "projectdetails", value: projectdetails.map((friend) =>
          // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
          friend.ProjectId === rowDataProjectId
            ? { ...friend, selectOption: !friend.selectOption }
            : { ...friend }
        )
      })
    );
    setSelectedId(selectedId == rowDataProjectId ? "" : rowDataProjectId);
  };

  function selectcustom(rowData) {
    return (
      <>
        <div onClick={() => handleSecondFriend(rowData.id)}>
          <div
            style={{
              // backgroundColor: "#eef2f7",
              padding: "10px 05px 10px 15px",
              width: "45px",
            }}
          >
            <MoreVertIcon
              style={{ color: "#198ae3", fontSize: "20px", fontWeight: "bold" }}
            />
          </div>
          {selectedId == rowData.id && (
            <div
              style={{
                backgroundColor: "#ffffff",
                position: "relative",
                width: "120px ",
                borderRadius: "3px",
              }}
            >
              <ul style={{ backgroundColor: "#ebedf2" }}>
                <div
                  style={{ padding: "7px 10px 3px 5%" }}
                  onClick={() => handleEditing(rowData)}
                >
                  <EditIcon style={{ fontSize: "medium" }} />
                  {"   "} Edit
                </div>
                <div
                  style={{ padding: "7px 10px 3px 5%" }}
                  onClick={() => handleReport(rowData, "Report")}
                >
                  <AssessmentIcon style={{ fontSize: "medium" }} />
                  {"    "}Report
                </div>
              </ul>
            </div>
          )}
        </div>
      </>
    );
  }

  const theme = createTheme({
    palette: {
      grey: {
        200: "#your-light-grey-color",
        800: "#your-dark-grey-color",
      },
      // Other palette colors...
    },
    // Other theme settings...
  });
  const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? `#1a90ff` : `#308fe8`,
    },
  }));
  function projectcrt() {
    dispatch(PROJECTCREATE(true));
  }

  const Columns = [
    {
      title: "Project Id",
      field: "ProjectId",
      cellStyle: { textAlign: "left" },
    },

    { title: "Projects Name", field: "projectname" },
    { title: "Created By", field: "id" },

    {
      title: "Progress",
      field: "Progress",
      render: (rowData) => (
        <ThemeProvider theme={theme} value={rowData.Progress}>
          {/* Your components using the styled component */}
          <BorderLinearProgress
            value={rowData.Progress}
            variant="determinate"
          />
        </ThemeProvider>
      ),
    },
    {
      title: "Status",
      field: "Status",
      render: (rowData) => (
        <>
          <FiberManualRecordIcon
            style={{ color: "#198ae3", fontSize: "medium" }}
          />
          Progress
        </>
      ),
    },
    { title: "Start Date", field: "startdate" },
    { title: "End Date", field: "enddate" },
    {
      title: "Action",
      field: "Action",
      render: (rowData) => <>{selectcustom(rowData)}</>,
    },
  ];

  return (
    <div style={{ backgroundColor: "#f6f7fa" }}>
      <Dialog
        open={allocationopen}
        onClose={() => {
          handleClose();
        }}
        className="mainbox"
        // maxWidth="800px"
        maxWidth="lg"
      // fullWidth={true}

      // fullScreen={true}
      >
        <DialogTitle>
          <Row
            className=""
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Col lg={0} md={8}>
              <label
                className="headerallocate text-secondary"
                style={{ fontWeight: "bold" }}
              >
                Project Allocation
              </label>
            </Col>
            <IconButton
              className="d-flex justify-content-end"
              onClick={() => {
                handleClose(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Row>
        </DialogTitle>

        <DialogContent>
          <ProjectAllocation
            projectdetails={projectdetails}
            employee={employee}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog
        open={seamlessopen}
        // open={true}
        onClose={() => {
          handleClose();
        }}
        className="mainbox"
        // maxWidth="1500px"
        // maxWidth="1500px"
        maxWidth="lg"
      >
        <DialogTitle>
          <Row
            className=""
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Col lg={10} md={10}>
              <label
                className="headerdialog "
                style={{ fontWeight: "bold", color: "rgb(37, 147, 184)" }}
              >
                Allocate Project -{" "}
                <span className="text-danger">{projectnames}</span>
              </label>
            </Col>
            <IconButton
              className="d-flex justify-content-end"
              onClick={() => {
                handleClose(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Row>
        </DialogTitle>

        <DialogContent>
          <Allocationseamless
            // projectdetails={projectdetails}
            // setProjectdetails={setProjectdetails}
            Refresh={Refresh}
            Projectname={projectnames}
            setseamlessopen={setseamlessopen}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog
        open={openProCreate}
        //  open={true}
        onClose={() => ProjectClose()}
        className="mainbox"
        // maxWidth="1500px"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>
          <Row
            className="mainbox"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Col lg={10} md={10}>
              <label style={{ color: "#2593B8", fontWeight: "bold" }}>
                Create new project and module
              </label>
              <Row></Row>
            </Col>
            <IconButton
              className="d-flex justify-content-end"
              onClick={() => ProjectClose()}
            >
              <CloseIcon />
            </IconButton>
          </Row>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <span
            className=" text-dark mt-5"
            style={{ fontSize: "14px", color: "", fontStyle: "italic" }}
          >
            Note : To start use your kanban board, create stories, plan a sprint
            etc...
          </span>
          <Row className="mb-3 mt-3">
            <Col>
              <label className="SprintlabelStyle">Project Name</label>
              <Input
                placeholder="Enter Title"
                variant="outlined"
                className="inpui SprintFormFields"
                size="small"
                style={{ borderRadius: 14 }}
                value={projectnames}
                onChange={(e) => handleprojectname(e)}
                type="text"
                invalid={error.projectnames !== undefined}
              />
            </Col>
            {error.projectnames && (
              <FormFeedback>{error.projectnames}</FormFeedback>
            )}
          </Row>
          <ProjectModules
            modules={modules}
            setModules={setModules}
            projectname={projectnames}
          />
          <Row className="mb-3">
            <Col>
              <label className="SprintlabelStyle">Start Date</label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  varient="dialog"
                  // format="dd/MM/yyyy"
                  margin="groove"
                  name="project start date"
                  value={selectedDate}
                  onChange={(date) => handleDateChange(date)}
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  className=""
                  style={{ width: "100%" }}
                  autoComplete="off"
                  // shouldDisableDate={disableWeekends}
                  disablePast
                  invalid={error.selectedDate !== undefined}
                  error={dateerror}
                  helperText={dateerror}
                  required
                />
              </MuiPickersUtilsProvider>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <label className="SprintlabelStyle">End Date</label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {console.log(DateFnsUtils)}
                <KeyboardDatePicker
                  varient="dialog"
                  // format="dd/MM/yyyy"
                  margin="inline"
                  name="project start date"
                  value={selectedDate2}
                  onChange={(date) => handleDateChange2(date)}
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  className=""
                  style={{ width: "100%" }}
                  autoComplete="off"
                  // disabled
                  required
                />
              </MuiPickersUtilsProvider>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <Button1
              onClick={() => ProjectClose()}
              style={{
                fontSize: 14,
                padding: 9,
                width: 100,
                height: 40,
                borderRadius: 8,
                marginRight: 14,
                border: "2px solid #5250E5",
                backgroundColor: "#fff",
                color: "#5250E5",
                textAlign: "center",
                textTransform: "none",
              }}
            >
              Cancel
            </Button1>
            <Button1
              type="submit"
              disabled={checkSubmit}
              onClick={(e) => submit(e)}
              style={{
                fontSize: 14,
                padding: 9,
                width: 100,
                height: 40,
                borderRadius: 8,
                backgroundColor: "#5250E5",
                color: "#fff",
                border: "0px solid #5A88F1",
                textAlign: "center",
                textTransform: "none",
              }}
            >
              {checkSubmit ? (
                <span
                  className="spinner-border"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
                ></span>
              ) : (
                "Submit"
              )}
            </Button1>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Addnewuserproject;
