import React, { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import ReplayIcon from "@mui/icons-material/Replay";
import Tabs from "react-bootstrap/Tabs";
import TablePagination from "@material-ui/core/TablePagination";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Pagination from "react-bootstrap/Pagination";
import Problem from "./Problem";
import Button from "@mui/material/Button";
import MaterialTable from "material-table";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import MyIncidentQueue from "./MyIncidentQueue";
import ChangeModule from "./changemodule/Changemodule";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "../../axios";
import Divider from "@material-ui/core/Divider";
import { useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import SkeletonCard from "./CardSkelton";
import { Avatar, Checkbox } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import NotfoundProblemcard from "./NotfoundProblemcard";
import "./problemcard.css";
import moment from "moment";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import ProblemDetail from "./ProblemDetail";
import Unassigned from "../../assets/images/avatar.png";

const ProblemManagement = () => {
  const { allImage, projectDetails, projectCode } = useSelector(
    (state) => state.user
  );

  let role =
    sessionStorage.getItem("Roledetails") == "undefined"
      ? ""
      : JSON.parse(sessionStorage.getItem("Roledetails"));
  let smrole = localStorage.getItem("ProjectRole");

  const clientid = JSON.parse(localStorage.getItem("clientid"));

  const prjshcode = projectDetails?.filter((e) => e.projectcode == projectCode);
  let Empdetailsofuser = JSON.parse(localStorage.getItem("Empdetails"));
  const [problemdata, setproblemdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [defauktlist, setdefauktlist] = useState(true);
  const [retry, setretry] = useState(false);
  const [createchange, setcreatechange] = useState(false);
  const [open, setOpen] = useState(false);
  const [assignmentoption, setassignmentoption] = useState([]);
  const [messageopen, setmessageopen] = useState(false);
  let [idtofind, setidtofind] = useState("all");

  const [impactFilter, setimpactFilter] = useState({
    value: "all",
    label: "All",
  });
  const [priorityFilter, setpriorityFilter] = useState({
    value: "all",
    label: "All",
  });
  const [categoryFilter, setcategoryFilter] = useState({
    value: "all",
    label: "All",
  });
  const [problemStagesFilter, setproblemStagesFilter] = useState({
    value: "all",
    label: "All",
  });

  const [page, setPage] = useState(1);
  const [assigntomeinprogress, setassigntomeinprogress] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const totalPages = Math.ceil(problemdata.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // const [totalPages, settotalPages] = useState(Math.ceil(problems.length / itemsPerPage));
  // const [categoryOptions, setcategoryOptions] = useState([]);

  // const [categoryOptions, setcategoryOptions] = useState([]);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeItemsPerPage = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setPage(1); // Reset to the first page when changing items per page
  };
  const [stateofproblem, setstateofproblem] = useState([
    { value: "created", label: "Created" },

    { value: "analyzing", label: "Analyzing" },
    { value: "errorknown", label: "Error Known" },
    { value: "RootCauseidentified", label: "Root Cause identified" },
    { value: "closed", label: "Closed" },
  ]);
  const [categoryOptions, setcategoryOptions] = useState([]);
  let PriorityOption = [
    // 1 - System down, 2 - Wide impact ,3 - Moderate impact , 4 - Low impact
    { value: "P1", label: "P1-System down", code: "System down" },
    { value: "P2", label: "P2-Wide impact", code: "Wide impact" },
    { value: "P3", label: "P3-Moderate impact", code: "Moderate impact" },
    { value: "P4", label: "P4-Low impact", code: "Low impact" },
  ];
  let Impactoption = [
    // Urgency, Impact : 1-High, 2-Medium, 3-Low
    { value: "1", label: "High" },
    { value: "2", label: "Medium" },
    { value: "3", label: "Low" },
  ];
  function generateUniqueID() {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000); // You can adjust the range as needed
    return `${timestamp}-${random}`;
  }
  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        style={{ backgroundColor: "green", color: "white" }}
        severity="success"
        {...props}
      />
    );
  });
  function applyFilters(
    data,
    impact,
    priority,
    category,
    problemStages,
    refno
  ) {
    return data.filter(
      (item) =>
        (impact.value === "all" || item.Impact.value === impact.value) &&
        (priority.value === "all" || item.Priority.value === priority.value) &&
        (category.value === "all" || item.Category.value === category.value) &&
        (problemStages.value === "all" ||
          item.ProblemStages.value === problemStages.value) &&
        (refno == "all" ||
          item.ProblemRefNumber.toLowerCase().includes(refno.toLowerCase()))
    );
  }

  useEffect(() => {
    setloading(true);

    if (categoryOptions.length == 0) {
      axios
        .get("agilesaas_incident_category_master?ClientId=eq." + clientid)
        .then((response) => {
          let catagory = response.data.map((x) => {
            return {
              label: x.CategoryName,
              value: x.IncidentCategoryId,
            };
          });
          setcategoryOptions([{ value: "all", label: "All" }, ...catagory]);
        })
        .catch((error) => console.log(error));
    }
    axios
      .get(
        "subscription_user_group_mapping?ClientId=eq." +
        Empdetailsofuser[0].ClientId
      )
      .then((response) => {
        let userGroupMappings = response.data;
        const resultArray = userGroupMappings.reduce(
          (acc, userGroupMapping) => {
            userGroupMapping.GroupDetails.forEach((groupDetail) => {
              const groupId = groupDetail.GroupId;
              const groupName = groupDetail.GroupName;

              // Check if the group already exists in the result array
              const existingGroupIndex = acc.findIndex(
                (group) => group.GroupId === groupId
              );

              if (existingGroupIndex !== -1) {
                // Add the user to the existing group
                acc[existingGroupIndex].users.push({
                  Firstname: userGroupMapping.UserDetails.Firstname,
                  Lastname: userGroupMapping.UserDetails.Lastname,
                  UserId: userGroupMapping.UserId,
                });
              } else {
                // Create a new group with the user
                acc.push({
                  GroupId: groupId,
                  GroupName: groupName,
                  users: [
                    {
                      Firstname: userGroupMapping.UserDetails.Firstname,
                      Lastname: userGroupMapping.UserDetails.Lastname,
                      UserId: userGroupMapping.UserId,
                    },
                  ],
                });
              }
            });

            return acc;
          },
          []
        );
        const assignmentooption = resultArray.map((e) => {
          return {
            label: e.GroupName,
            value: e.GroupId,
            users: e.users,
          };
        });
        setassignmentoption(assignmentooption);
      })
      .catch((error) => console.log(error));

    if (defauktlist == false) {
      axios
        .get(
          "subscription_user_group_mapping?ClientId=eq." +
          Empdetailsofuser[0].ClientId +
          "&UserId=eq." +
          Empdetailsofuser[0]?.ClientUserId
        )
        .then((response) => {
          // setgrpassign(response.data);
          console.log(response.data[0].GroupDetails, "grpassign");

          let Groupcheck = response.data[0].GroupDetails.map(
            (group) => group.GroupId
          ).toString();

          console.log(Groupcheck, "grpcheck");
          axios
            .get(
              "agilesaas_problem_details?ClientId=eq." +
              Empdetailsofuser[0].ClientId +
              "&AssignmentGroup->>Groupid=in.(" +
              Groupcheck +
              ")"
            )
            .then((prbdatares) => {
              console.log(prbdatares.data, "response");
              let filteredDatatobeshown = prbdatares.data.filter((item) => {
                return (
                  (item.AssignedTo &&
                    item.AssignedTo.userid ===
                    Empdetailsofuser[0]?.ClientUserId) ||
                  item.AssignedTo === null
                );
              });

              let filteredData = applyFilters(
                filteredDatatobeshown,
                impactFilter,
                priorityFilter,
                categoryFilter,
                problemStagesFilter,
                idtofind
              );

              setproblemdata(
                filteredData.sort((a, b) => a.ProblemId - b.ProblemId)
              );
              setloading(false);
            })
            .catch((error) => {
              console.log(error);
              setloading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setloading(false);
        });
    }

    if (defauktlist) {
      axios
        .get(
          `agilesaas_problem_details?ClientId=eq.${Empdetailsofuser[0].ClientId}&CreatedBy->>id=eq.${Empdetailsofuser[0].ClientUserId}`
        )
        .then((response) => {
          let filteredData = applyFilters(
            response.data,
            impactFilter,
            priorityFilter,
            categoryFilter,
            problemStagesFilter,
            idtofind
          );

          setproblemdata(
            filteredData.sort((a, b) => a.ProblemId - b.ProblemId)
          );
          setloading(false);
        })

        .catch((error) => {
          console.log(error);
          setloading(false);
        });
    }
  }, [
    impactFilter.value,
    priorityFilter.value,
    problemStagesFilter.value,
    categoryFilter.value,
    defauktlist,
    retry,
    idtofind,
  ]);
  function getprobemdetail(id) {
    axios
      .get(`agilesaas_problem_details?ProblemId=eq.${id}`)
      .then((res) => {
        if (res.data.length > 0) {
          updateProblemById(id, res.data[0]);
          return res.data;
        } else {
          alert("something wentwrong");
        }
      })
      .catch((e) => {
        alert("something wentwrong");
      });
  }
  const updateProblemById = (id, datatoupdate) => {
    // Find the index of the problem with the specified id
    const index = problemdata.findIndex((problem) => problem.ProblemId === id);

    // If the problem with the specified id is found
    if (index !== -1) {
      // Update the state with the new data
      setproblemdata((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], ...datatoupdate };
        setcarddata(newData.filter((x) => x.ProblemId === id));
        return newData;
      });
      // xx
      setmessageopen(true);
      console.log(`Problem with id ${id} updated successfully.`);
      return true;
    } else {
      console.log(`Problem with id ${id} not found.`);

      return false;
    }
  };
  function createasbug(e) {

    let postData = {
      ProjectId: projectCode,
      ProjectShortCode: prjshcode[0]?.projectshortcode,
      IssueType: "Bug",
      IssueTitle: `${e.ShortDescription} - ${e.ProblemRefNumber} `,
      Description: `Created from Problem ticket with Refnumber ${e.ProblemRefNumber}\n${e.LongDescription}`,

      Priority: "Highest",
      PlannedRelease: null,
      EstimateUnit: null,
      EstimateUnitDesc: null,
      CurrentStage: [
        {
          StageCode: "BLG",
          StageName: "Backlog",
          DateMoved: moment().format("YYYY-MM-DD"),
        },
      ],
      LinkToEpic: null,
      LinkToIssues: null,
      CreatedBy: Empdetailsofuser[0]?.ClientUserId,
      CreatedByDetails:
        role.RoleName == "Undefined" ? "Kanban Admin" : role.RoleName,
      CreatedDate: moment().format("YYYY-MM-DD"),
      AssignedTo: null,
      AssignedToDetails: {
        FN: null,
        LN: null,
      },
      AssignedDate: moment().format("YYYY-MM-DD"),
      Attachment: null,
      IsActive: "Y",
      ExceptiontoSkipSPEstimation: "N",
      ProjectDetails: [
        { ProjName: prjshcode[0]?.projectname, Client: clientid },
      ],
      AssignedToUAT: null,
      AssignedToUATDetails: {
        FN: null,
        LN: null,
      },
      ClientId: clientid,
      AttachmentReference: null,
    };

    axios
      .post("agilesaas_issue_details", postData)
      .then((ress) => {
        axios.get("agilesaas_issue_details?ClientId=eq." + clientid)
          .then((response) => {
            const allRecords = response.data;

            allRecords.sort((a, b) => b.IssueId - a.IssueId);

            const lastPostedRecord = allRecords[0];

            let patchincidentbug = {
              "BugTaskDetails": {
                Project: prjshcode[0]?.projectname,
                TicketReference: lastPostedRecord.IssueId,
              },
              "IsBugTaskCreated": "Y"
            }
            axios.patch("agilesaas_problem_details?ProblemId=eq." + e.ProblemId, patchincidentbug).then((res) => {
              updateProblemById(e.ProblemId, {
                "BugTaskDetails": {
                  Project: prjshcode[0]?.projectname,
                  TicketReference: lastPostedRecord.IssueId,
                },
                "IsBugTaskCreated": "Y"
              });


            }).catch((E) => {
              console.log("errorpatch", E)
            })

          });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function cardassgntome(id) {
    setassigntomeinprogress(true);
    let fname = Empdetailsofuser[0]?.UserFirstname
      ? Empdetailsofuser[0]?.UserFirstname
      : "";
    let lname = Empdetailsofuser[0]?.UserLastname
      ? Empdetailsofuser[0]?.UserLastname
      : "";
    let name = fname + " " + lname;
    let userid = Empdetailsofuser[0]?.ClientUserId;
    axios
      .get(`agilesaas_problem_details?ProblemId=eq.${id}`)
      .then((res) => {
        if (res.data.length > 0) {
          updateProblemById(id, res.data[0]);
          let isassigntoupdated =
            res.data[0]?.AssignedTo == null ? true : false;
          let messagelist = res.data[0]?.ProblemNotes
            ? res.data[0]?.ProblemNotes
            : [];
          let message = {
            Message_id: `${generateUniqueID()}-sys`,
            DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            NoteUpdatedBy: {
              id: Empdetailsofuser[0].ClientUserId,
              FN: Empdetailsofuser[0].UserFirstname,
              LN: Empdetailsofuser[0].UserLastname,
            },
            NotesDetails: `problem has been assigned to ${name}`,
          };
          let pmdata = [message, ...messagelist];
          console.log(isassigntoupdated, "isassigntoupdated");
          if (isassigntoupdated) {
            let cardassignpatchdata =
              res.data[0].ProblemStages.value == "created"
                ? {
                  AssignedTo: { userid: userid, name: name },
                  ProblemStages: { value: "analyzing", label: "Analyzing" },
                  ProblemNotes: pmdata,
                }
                : {
                  AssignedTo: { userid: userid, name: name },

                  ProblemNotes: pmdata,
                };
            axios
              .patch(
                `agilesaas_problem_details?ProblemId=eq.${id}`,
                cardassignpatchdata
              )
              .then((response) => {
                updateProblemById(id, cardassignpatchdata);
                // setproblemdata(response.data);
                // console.log(response.data);
                setassigntomeinprogress(false);
              })

              .catch((error) => {
                alert("unable to assign the card");
                setassigntomeinprogress(false);
              });
          } else {
            alert("this card has been already assigned");
            setassigntomeinprogress(false);
          }
        } else {
          alert("something wentwrong");
          setassigntomeinprogress(false);
        }
      })
      .catch((e) => {
        alert("something wentwrong");
        setassigntomeinprogress(false);
      });
  }

  function imapctchange(id, patchinfo) {
    axios
      .patch(`agilesaas_problem_details?ProblemId=eq.${id}`, patchinfo)
      .then((response) => {
        updateProblemById(id, patchinfo);
      })

      .catch((error) => alert("unable to update the card"));
  }
  // const columns = [
  //   {
  //     title: "ProblemRefNo",
  //     field: "refno",
  //     headerStyle: { fontWeight: "bold", width: "5%" },
  //   },
  //   {
  //     title: "Tittle",
  //     field: "tittle",
  //     headerStyle: { fontWeight: "bold", width: "20%" },
  //   },
  //   {
  //     title: "Priority",
  //     field: "priority",
  //     headerStyle: { fontWeight: "bold", width: "5%" },
  //   },
  //   {
  //     title: "Impact",
  //     field: "impact",
  //     headerStyle: { fontWeight: "bold", width: "20%" },
  //   },
  //   {
  //     title: "Stage",
  //     field: "stage",
  //     headerStyle: { fontWeight: "bold", width: "15%" },
  //   },
  //   {
  //     title: "Raised By",
  //     field: "raisedBy",
  //     headerStyle: { fontWeight: "bold", width: "15%" },
  //   },
  //   {
  //     title: "Raised To",
  //     field: "raisedto",
  //     headerStyle: { fontWeight: "bold", width: "15%" },
  //   },
  //   {
  //     title: "Created Date",
  //     field: "createddate",
  //     headerStyle: { fontWeight: "bold", width: "15%" },
  //   },
  // ];

  const columns = [
    {
      title: "ProblemRefNo",
      field: "refno",
      headerStyle: { fontWeight: "bold", width: "10%" },
      cellStyle: { width: "10%", textAlign: "left" },
    },
    {
      title: "Title",
      field: "title",
      headerStyle: { fontWeight: "bold", width: "5%" },
      cellStyle: { width: "20%", textAlign: "left" },
    },
    {
      title: "Priority",
      field: "priority",
      headerStyle: { fontWeight: "bold", width: "10%" },
      cellStyle: { width: "10%", textAlign: "left" },
    },
    {
      title: "Impact",
      field: "impact",
      headerStyle: { fontWeight: "bold", width: "20%" },
      cellStyle: { width: "10%", textAlign: "left" },
    },

    {
      title: "Stage",
      field: "stage",
      render: (e) => (
        <label
          className={
            e.stage === "Created"
              ? "text-danger"
              : e.stage === "Analyzing"
                ? "text-info"
                : e.stage === "Error Known"
                  ? "text-warning"
                  : e.stage === "Root Cause identified"
                    ? "text-warning"
                    : e.stage === "Closed"
                      ? "text-success"
                      : ""
          }
        >
          <strong>
            {e.stage === "Created"
              ? "Created"
              : e.stage === "Analyzing"
                ? "Analyzing"
                : e.stage === "Error Known"
                  ? "Error Known"
                  : e.stage === "Root Cause identified"
                    ? "Root Cause identified"
                    : e.stage === "Closed"
                      ? "Closed"
                      : ""}
          </strong>
        </label>
      ),
      headerStyle: { fontWeight: "bold", width: "15%" },
      cellStyle: { width: "15%", textAlign: "left" },
    },
    {
      title: "Raised By",
      field: "raisedBy",
      headerStyle: { fontWeight: "bold", width: "15%" },
      cellStyle: { width: "15%", textAlign: "left" },
    },
    {
      title: "Raised To",
      field: "raisedto",
      headerStyle: { fontWeight: "bold", width: "15%" },
      cellStyle: { width: "10%", textAlign: "left" },
    },
    {
      title: "Created Date",
      field: "createddate",
      headerStyle: { fontWeight: "bold", width: "15%" },
      cellStyle: { width: "15%", textAlign: "left" },
    },
    {
      title: "",
      field: "bug",
      // headerStyle: { fontWeight: "bold", width: "100%" },
      cellStyle: { width: "100%", height: "50%", textAlign: "left" },
    },

  ];

  // Rest of your code...

  const dummyData = problemdata.map((item) => {
    return {
      refno: "#" + item.ProblemRefNumber,
      title: item.ShortDescription,
      priority: item.Priority.value,
      impact: item.Impact.label,
      stage: item.ProblemStages.label,
      raisedBy: item.CreatedBy.FN + " " + item.CreatedBy.LN,
      raisedto: item.AssignedTo ? item.AssignedTo.name : "not assigned",
      createddate: moment(item.CreatedDateTime).format("YYYY-MM-DD"),
      id: item.ProblemId,
      bug: item?.IsRootCauseIdentifed == "Y" || item.ProblemStages.label == "Closed " ? (
        <button
          // disabled={assigntomeinprogress}
          onClick={() => {
            createasbug(item);
          }}
          hidden={item?.IsBugTaskCreated == "Y"}
          style={{
            // width: 100,
            height: 50,
            borderRadius: 5,
            background: "rgb(246, 202, 204)",
            padding: ".3rem .5rem",
            marginBottom: "5px",
            fontWeight: "bold",
            color: "rgb(208, 34, 36)",
          }}
        >
          Create as bug
        </button>
      ) : null
    };
  });
  const steps = [
    "Created",
    "Analyzing",
    "Error Known",
    "Root Cause identified",
    "Closed",
  ];

  const [carddata, setcarddata] = useState([]);
  const handleOpen = (e, data) => {
    setcarddata(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [defaultview, setdefaultview] = useState("card");
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#33ff33",
        color: "33ff33",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#00cc00",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#33ff33",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#00cc00",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
    "& .QontoStepIcon-ErrorIcon": {
      color: "#F76666",
      // color: '#00cc00',
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-warning": {
      color: "#f7da66",
      // color: '#00cc00',
      zIndex: 1,
      fontSize: 18,
    },
  }));
  function QontoStepIcon(props) {
    const {
      active,
      completed,
      className,
      currentstage,
      isworkroundfilled,
      isrootcausefilled,
      index,
      Resolved,
      label,
      isclosed,
    } = props;
    // "Error Known",
    // "Root Cause identified",
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {console.log("isclosed", isclosed)}
        {isclosed >= 2 && label == "Error Known" && !isworkroundfilled ? (
          <WarningAmberIcon className="QontoStepIcon-warning" />
        ) : isclosed >= 3 &&
          label == "Root Cause identified" &&
          !isrootcausefilled ? (
          <WarningAmberIcon className="QontoStepIcon-warning" />
        ) : completed ? (
          <CheckCircleIcon className={"QontoStepIcon-completedIcon"} />
        ) : currentstage == "Root Cause identified" && isrootcausefilled ? (
          <RadioButtonCheckedIcon className="QontoStepIcon-circle " />
        ) : (
          <RadioButtonCheckedIcon className="QontoStepIcon-circle " />
        )}
      </QontoStepIconRoot>
    );
  }
  const options = [
    { value: "user1", label: "User 1" },
    { value: "user2", label: "User 2" },
    // Add more options for other fields
  ];
  const clearFilters = () => {
    setimpactFilter({ value: "all", label: "All" });
    setpriorityFilter({ value: "all", label: "All" });
    setcategoryFilter({ value: "all", label: "All" });
    setproblemStagesFilter({ value: "all", label: "All" });
    setidtofind("all");
  };
  const handleTabSelect = (selectedTab) => {
    setPage(1);
    // Perform API call here based on the selected tab
    if (selectedTab == "MyProblems") {
      setdefauktlist(true);
    } else {
      setdefauktlist(false);
    }

    // Example API call (replace with your actual API call)
    // fetch('your-api-endpoint')
    //   .then(response => response.json())
    //   .then(data => console.log(data))
    //   .catch(error => console.error('Error:', error));
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: "auto",
      width: "auto",
      // width: 230,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  let e = {
    CurrentState1: "Resolved",
    Acknowledgement: "Y",
  };

  return (
    <>
      <Tabs
        defaultActiveKey="MyProblems"
        id="justify-tab-example"
        className="horizontal-tabs mt-3"
        variant="tabs"
        onSelect={handleTabSelect}
      >
        <Tab
          eventKey="MyProblems"
          title="My Problems"
          className="headings"
          defaultActiveKey="MyProblems"
        >
          <Problem updatedata={retry} updatadatafunc={setretry} />

          <div
            className=" mt-2 pb-100 mb-4 m-2"
            style={{ background: "rgb(245, 247, 251)" }}
          >
            <div className=" d-flex justify-content-end mt-1 mb-4">
              <Button
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 130,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 14,
                  border: "1px solid #5A88F1",
                  backgroundColor: "#fff",
                  color: defaultview === "card" ? "#5250E5" : "black",
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={() => setdefaultview("card")}
              >
                <ComputerOutlinedIcon /> Card View
              </Button>
              <Button
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 130,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 14,
                  border: "1px solid #5A88F1",
                  backgroundColor: "#fff",
                  color: defaultview === "tabel" ? "#5250E5" : "black",
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={() => {
                  setdefaultview("tabel");
                  clearFilters();
                }}
              >
                <TableRowsOutlinedIcon /> Table View
              </Button>
            </div>
            {defaultview == "card" ? (
              <div className="row m-1">
                {/* First Column (8 units) */}
                <div className="col-md-9 " style={{ minHeight: "100vh" }}>
                  {loading ? (
                    <>
                      <div
                        className={
                          "shadow-sm mt-1 mb-2 ml-3 problemcardcontainer"
                        }
                      >
                        <SkeletonCard />
                      </div>
                      <div
                        className={
                          " shadow-sm mt-1 mb-2 ml-3 problemcardcontainer"
                        }
                      >
                        <SkeletonCard />
                      </div>
                      <div
                        className={
                          " shadow-sm mt-1 mb-2 ml-3 problemcardcontainer"
                        }
                      >
                        <SkeletonCard />
                      </div>
                    </>
                  ) : null}

                  {problemdata.length > 0 && !loading ? (
                    problemdata
                      .slice(startIndex, endIndex)
                      ?.filter((R) => R?.ProblemStages.value != "closed")
                      .map((problemitem) => (
                        <div
                          key={problemitem.ProblemId}
                          className={
                            "inactive-card shadow-sm mt-1 mb-2 ml-3 problemcardcontainer"
                          }
                          onClick={(e) =>
                            handleOpen(
                              e,
                              problemdata.filter(
                                (x) => x.ProblemId == problemitem.ProblemId
                              )
                            )
                          }
                          // style={{
                          //   marginBottom: "10px",
                          //   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                          //   backgroundColor: "#ffffff",
                          //   padding: "10px",
                          // }}
                          style={{
                            background: "#F9FAFB",
                            border: "1px solid rgb(0, 0, 0 ,20%)",
                          }}
                        >
                          <div>
                            <div>
                              <Stepper
                                alternativeLabel
                                activeStep={steps.indexOf(
                                  problemitem?.ProblemStages.label
                                )}
                                connector={<QontoConnector />}
                                className="mt-3 d-flex justify-content-start"
                              >
                                {steps.map((label, index) => (
                                  <Step key={label}>
                                    <StepLabel
                                      StepIconComponent={(props) =>
                                        QontoStepIcon({
                                          ...props,
                                          currentstage:
                                            problemitem?.ProblemStages.label,
                                          index: index,
                                          isworkroundfilled:
                                            problemitem.WorkAround,
                                          isrootcausefilled:
                                            problemitem.ActualRootCause,
                                          label: label,
                                          isclosed: steps.indexOf(
                                            problemitem?.ProblemStages.label
                                          ),
                                        })
                                      }
                                    >
                                      <span>{label}</span>
                                    </StepLabel>
                                  </Step>
                                ))}
                              </Stepper>
                            </div>
                            <div className="probleminfo">
                              <div className="image">
                                <Avatar
                                  className=" mr-1"
                                  alt=""
                                  style={{
                                    width: "80px", // set the desired width
                                    height: "80px", // set the desired height
                                  }}
                                  src={
                                    allImage?.length > 0 && allImage?.filter(
                                      (x) =>
                                        x.teammemberid ==
                                        problemitem?.CreatedBy?.id
                                    )?.[0]?.image != undefined ? allImage?.filter(
                                      (x) =>
                                        x.teammemberid ==
                                        problemitem?.CreatedBy?.id
                                    )?.[0]?.image : Unassigned
                                  }
                                />
                              </div>
                              <div className="content">
                                <h4>
                                  <span className="muted">
                                    {"#" + problemitem?.ProblemRefNumber + ": "}
                                  </span>
                                  {problemitem?.ShortDescription}
                                </h4>
                                <div className="infos">
                                  <span>
                                    {problemitem?.CreatedBy?.FN +
                                      " " +
                                      problemitem?.CreatedBy?.LN}
                                  </span>{" "}
                                  <Divider orientation="vertical" flexItem />
                                  <span>{problemitem?.Priority.value}</span>
                                  <Divider orientation="vertical" flexItem />
                                  <span>
                                    <span
                                      className="mdi mdi-checkbox-blank-circle mr-1"
                                      style={{
                                        color:
                                          problemitem?.Impact.value == 3
                                            ? "#45DD81"
                                            : problemitem?.Impact.value == 2
                                              ? "#ddce45"
                                              : "#dd4545",
                                        fontSize: "9px",
                                      }}
                                    ></span>
                                    {problemitem?.Impact.label}
                                  </span>
                                  <Divider orientation="vertical" flexItem />
                                  <span> {problemitem?.Priority.code} </span>
                                </div>
                                <div className="infos2">
                                  <p className="infoitem">
                                    Created at:
                                    <span>
                                      {moment(
                                        problemitem?.CreatedDateTime
                                      ).format("DD-MMM-YYYY,h:mm A")}
                                    </span>
                                  </p>
                                  <Divider orientation="vertical" flexItem />
                                  <p className="infoitem">
                                    Category:
                                    <span>{problemitem.Category?.label}</span>
                                  </p>
                                  <Divider orientation="vertical" flexItem />
                                  <p className="infoitem">
                                    subcategory:
                                    <span className="">
                                      {problemitem.Subcategory?.label}
                                    </span>
                                  </p>
                                </div>
                                <div className="infos2">
                                  <p className="infoitem ">
                                    {problemitem.AssignedTo != null ? (
                                      <>
                                        assigned to:
                                        <span>
                                          {problemitem?.AssignedTo?.name}
                                        </span>
                                      </>
                                    ) : (
                                      <button
                                        disabled={assigntomeinprogress}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          cardassgntome(problemitem.ProblemId);
                                        }}
                                        style={{
                                          width: 100,
                                          height: 36,
                                          borderRadius: 5,
                                          background: "#5250E5",
                                          color: "#fff",
                                        }}
                                      >
                                        assign to me
                                      </button>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="filters"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <div className="filter2" style={{ flex: 1 }}>
                                  {/* <label className="SprintlabelStyle">
                                    Impact:
                                  </label>
                                  <Select
                                    className="customselect"
                                    styles={customStyles}
                                    options={Impactoption}
                                    value={
                                      problemitem.Impact?.value
                                        ? [problemitem.Impact]
                                        : []
                                    }
                                    onChange={(selecteddata) => {
                                      imapctchange(problemitem.ProblemId, {
                                        Impact: selecteddata,
                                      });
                                    }}
                                  /> */}

                                  <div
                                    className="d-flex justify-space-between "
                                    style={{ flex: 1 }}
                                  >
                                    <span
                                      className="mdi mdi-checkbox-blank-circle ml-3"
                                      style={{
                                        color:
                                          problemitem?.Impact.value == 3
                                            ? "#45DD81"
                                            : problemitem?.Impact.value == 2
                                              ? "#ddce45"
                                              : "#dd4545",
                                        fontSize: "9px",
                                      }}
                                    ></span>
                                    <select
                                      style={{ flex: 1 }}
                                      className="fontdesign ssls ml-2 mb-2"
                                      // defaultValue={e?.Impact?.ImpactName}
                                      defaultValue={
                                        problemitem.Impact?.value
                                          ? problemitem.Impact?.value
                                          : ""
                                      }
                                      onChange={(selecteddata) => {
                                        let dataofimp = Impactoption.filter(
                                          (x) =>
                                            x.value == selecteddata.target.value
                                        );

                                        imapctchange(problemitem.ProblemId, {
                                          Impact:
                                            dataofimp.length > 0
                                              ? dataofimp[0]
                                              : {},
                                        });
                                      }}
                                      // value={impacts[i]}
                                      // disabled={e.CurrentState.StateCode == "Resolved"}
                                      // onChange={(e, i) => impactchange(e, data)}
                                      title="Impact"
                                    >
                                      {Impactoption.map((x) => (
                                        <option value={x.value}>
                                          {x.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="filter2" style={{ flex: 1 }}>
                                  {/* <label className="SprintlabelStyle">
                                    State:
                                  </label>
                                  <Select
                                    className="customselect"
                                    styles={customStyles}
                                    options={stateofproblem}
                                    value={problemitem.ProblemStages}
                                    onChange={(selecteddata) => {
                                      imapctchange(problemitem.ProblemId, {
                                        ProblemStages: selecteddata,
                                      });
                                    }}
                                  /> */}
                                  <div
                                    className="d-flex justify-space-between "
                                    style={{ flex: 1 }}
                                  >
                                    <svg
                                      width="13"
                                      height="9"
                                      viewBox="0 0 13 9"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="mt-1 ml-3"
                                    >
                                      <path
                                        id="Vector"
                                        d="M8.44935 0H12.3499C12.7075 0 13 0.283317 13 0.629593C13 0.975869 12.7075 1.25919 12.3499 1.25919H8.44935C8.0918 1.25919 7.79925 0.975869 7.79925 0.629593C7.79925 0.283317 8.0918 0 8.44935 0ZM8.44935 3.46276H12.3499C12.7075 3.46276 13 3.74608 13 4.09235C13 4.43863 12.7075 4.72195 12.3499 4.72195H8.44935C8.0918 4.72195 7.79925 4.43863 7.79925 4.09235C7.79925 3.74608 8.0918 3.46276 8.44935 3.46276ZM8.44935 6.92552H12.3499C12.7075 6.92552 13 7.20884 13 7.55511C13 7.90139 12.7075 8.18471 12.3499 8.18471H8.44935C8.0918 8.18471 7.79925 7.90139 7.79925 7.55511C7.79925 7.20884 8.0918 6.92552 8.44935 6.92552ZM5.10137 8.90874L6.26503 7.78177C6.39505 7.65585 6.39505 7.46067 6.26503 7.33476L5.10137 6.20779C5.05655 6.16311 4.99893 6.13246 4.93595 6.11981C4.87297 6.10715 4.80752 6.11306 4.74804 6.13678C4.68857 6.1605 4.63779 6.20093 4.60228 6.25287C4.56676 6.3048 4.54813 6.36585 4.54879 6.42814V8.68838C4.54879 8.9654 4.89984 9.10391 5.10137 8.90874ZM4.54879 6.92552H4.35376C2.82604 6.92552 1.46084 5.84892 1.31132 4.37567C1.27135 3.98174 1.31688 3.58408 1.44499 3.2082C1.57309 2.83232 1.78095 2.48655 2.05521 2.19307C2.32947 1.8996 2.66407 1.66491 3.03752 1.50408C3.41097 1.34325 3.81503 1.25983 4.22374 1.25919H5.84897C6.20652 1.25919 6.49907 0.975869 6.49907 0.629593C6.49907 0.283317 6.20652 0 5.84897 0H4.22374C1.71438 0 -0.300909 2.14062 0.0371396 4.6338C0.310179 6.69887 2.22795 8.18471 4.37326 8.18471H4.54879"
                                        fill="black"
                                      />
                                    </svg>
                                    <select
                                      className="fontdesign ssls ml-1"
                                      title="Status"
                                      value={problemitem.ProblemStages.value}
                                      onChange={(selecteddata) => {
                                        let dataofimp = stateofproblem.filter(
                                          (x) =>
                                            x.value == selecteddata.target.value
                                        );

                                        imapctchange(problemitem.ProblemId, {
                                          ProblemStages:
                                            dataofimp.length > 0
                                              ? dataofimp[0]
                                              : {},
                                        });
                                      }}
                                    >
                                      {stateofproblem.map((x) => (
                                        <option value={x.value}>
                                          {x.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                {/* <div className="filter2">
                                  <button
                                    variant="outline-secondary"
                                    size="sm"
                                    fontSize="small"
                                    // style={{ fontSize: "17px",alignContent:"start" }}
                                    className="btn btn-text-dark  btn-sm fontdesign mt-1 "
                                    style={{ textAlign: "left" }}
                                    title="Notes"
                                    onClick={(e) =>
                                      handleOpen(
                                        e,
                                        problemdata.filter(
                                          (x) =>
                                            x.ProblemId == problemitem.ProblemId
                                        )
                                      )
                                    }
                                  >
                                    <SpeakerNotesOutlinedIcon
                                      className="Card_Button_Color_Approve  "
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                      }}
                                    ></SpeakerNotesOutlinedIcon>{" "}
                                    Notes
                                  </button>
                                </div> */}
                                {smrole == "SM" &&
                                  problemitem?.IsRootCauseIdentifed == "Y" ? (
                                  <div className="filter2 mt-2">
                                    <button
                                      // disabled={assigntomeinprogress}
                                      hidden={problemitem?.IsBugTaskCreated == "Y"}
                                      onClick={() => {
                                        createasbug(problemitem);
                                      }}
                                      style={{
                                        // width: 100,
                                        height: 30,
                                        borderRadius: 5,
                                        background: "rgb(246, 202, 204)",
                                        padding: ".3rem .5rem",
                                        marginBottom: "5px",
                                        fontWeight: "bold",
                                        color: "rgb(208, 34, 36)",
                                      }}
                                    >
                                      Create as bug
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                  ) : !loading && problemdata.length == 0 ? (
                    <NotfoundProblemcard
                      tryagain={setretry}
                      tryagainvalue={retry}
                    />
                  ) : null}
                  {problemdata.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 10,
                      }}
                    >
                      <Pagination
                        className="probpagination"
                        style={{ color: "#000" }}
                      >
                        <Pagination.First
                          onClick={() => handleChangePage(1)}
                          disabled={page === 1}
                        />
                        <Pagination.Prev
                          onClick={() => handleChangePage(page - 1)}
                          disabled={page === 1}
                        />

                        {Array.from({ length: totalPages }, (_, i) => {
                          if (
                            totalPages <= 7 ||
                            i + 1 === 1 ||
                            i + 1 === totalPages
                          ) {
                            return (
                              <Pagination.Item
                                key={i + 1}
                                active={i + 1 === page}
                                onClick={() => handleChangePage(i + 1)}
                              >
                                <span>{i + 1}</span>
                              </Pagination.Item>
                            );
                          } else if (
                            (i + 1 >= page - 1 && i + 1 <= page + 1) ||
                            (i + 1 === page - 2 && i + 1 > 2) ||
                            (i + 1 === page + 2 && i + 1 < totalPages - 1)
                          ) {
                            return (
                              <Pagination.Item
                                key={i + 1}
                                active={i + 1 === page}
                                onClick={() => handleChangePage(i + 1)}
                              >
                                <span>{i + 1}</span>
                              </Pagination.Item>
                            );
                          } else if (
                            (i + 1 === 2 && page > 4) ||
                            (i + 1 === totalPages - 1 && page < totalPages - 3)
                          ) {
                            return <Pagination.Ellipsis key={i + 1} disabled />;
                          }

                          return null;
                        })}

                        <Pagination.Next
                          onClick={() => handleChangePage(page + 1)}
                          disabled={page === totalPages}
                        />
                        <Pagination.Last
                          onClick={() => handleChangePage(totalPages)}
                          disabled={page === totalPages}
                        />
                      </Pagination>

                      <select
                        className="form-select mb-3 p-1 "
                        value={itemsPerPage}
                        onChange={handleChangeItemsPerPage}
                      >
                        <option value={5}>5 items per page</option>
                        <option value={10}>10 items per page</option>
                        <option value={25}>25 items per page</option>
                      </select>
                    </div>
                  ) : null}
                </div>

                {/* Second Column (4 units) */}
                <div
                  className="col-md-3 p-2 mb-2 "
                  style={{
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    height: "auto",
                    maxWidth: "350px",
                    borderRadius: "5px",
                  }}
                >
                  {/* xx */}
                  <div
                    style={{
                      backgroundColor: "#fff",
                      padding: "10px",
                      height: "100%",
                    }}
                  >
                    <Button
                      style={{
                        fontSize: 14,
                        padding: 9,
                        // width: 130,
                        height: 40,
                        borderRadius: 8,
                        marginRight: 14,
                        border: "1px solid #5A88F1",
                        backgroundColor: "#5A88F1",
                        color: "#fff",
                        textAlign: "center",
                        textTransform: "none",
                      }}
                      onClick={() => setretry(!retry)}
                    >
                      Fetch Latest Problems <ReplayIcon />
                    </Button>
                    <hr></hr>
                    {/* Content for the second column goes here */}
                    <div
                      className="d-flex"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 style={{ fontWeight: "bold" }}>Filter</h4>
                      <span
                        style={{ color: "rgb(82, 80, 229)", cursor: "pointer" }}
                        onClick={clearFilters}
                      >
                        Clear Filter
                      </span>
                    </div>

                    {/* React Select for Assigned To */}
                    {/* <div className="mt-3">
                      <label className="SprintlabelStyle">Assigned To:</label>
                      <Select styles={customStyles} options={options} />
                    </div> */}

                    {/* React Select for Priority */}
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Refnumber:</label>
                      <br></br>
                      <input
                        placeholder="Problem Refnumber"
                        style={{ width: "100%" }}
                        className="SprintFormFields"
                        value={idtofind == "all" ? "" : idtofind}
                        onChange={(sdata) => {
                          if (sdata.target.value == "") {
                            setidtofind("all");
                            if (page != 1) {
                              setPage(1);
                            }
                          } else {
                            if (page != 1) {
                              setPage(1);
                            }
                            setidtofind(sdata.target.value.toUpperCase());
                          }
                        }}
                      />
                    </div>
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Priority:</label>
                      <Select
                        styles={customStyles}
                        value={priorityFilter}
                        onChange={(sdata) => {
                          setpriorityFilter(sdata);
                        }}
                        options={[
                          { value: "all", label: "All" },
                          ...PriorityOption,
                        ]}
                      />
                    </div>

                    {/* React Select for Status */}
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Impact:</label>
                      <Select
                        value={impactFilter}
                        styles={customStyles}
                        onChange={(sdata) => {
                          console.log("setimpactFilter", sdata);
                          setimpactFilter(sdata);
                        }}
                        options={[
                          { value: "all", label: "All" },
                          ...Impactoption,
                        ]}
                      />
                    </div>

                    {/* React Select for State */}
                    <div className="mt-3">
                      <label className="SprintlabelStyle">State:</label>
                      <Select
                        styles={customStyles}
                        value={problemStagesFilter}
                        onChange={(sdata) => {
                          setproblemStagesFilter(sdata);
                        }}
                        options={[
                          { value: "all", label: "All" },
                          ...stateofproblem,
                        ]}
                      />
                    </div>

                    {/* React Select for Category */}
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Category:</label>
                      <Select
                        styles={customStyles}
                        value={categoryFilter}
                        onChange={(sdata) => {
                          setcategoryFilter(sdata);
                        }}
                        options={categoryOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : problemdata.length > 0 ? (
              <MaterialTable
                title={"Problem list"}
                columns={columns}
                data={dummyData}
                options={{
                  search: true,
                  sorting: true,
                  rowStyle: (rowData) => ({
                    padding: "10px",
                  }),
                }}
                onRowClick={(event, rowData) => {
                  handleOpen(
                    e,
                    problemdata.filter((x) => x.ProblemId == rowData.id)
                  );
                }}
              />
            ) : (
              <NotfoundProblemcard tryagain={setretry} tryagainvalue={retry} />
            )}
          </div>
        </Tab>
        <Tab
          eventKey="Assigned Problems"
          title="Assigned Problems"
          className="headings ml-2 mt-2"
        >
          <div
            className=" mt-2 pb-100 mb-4 pt-2 "
            style={{ background: "rgb(245, 247, 251)" }}
          >
            <div className="ml-4 mt-2">
              {assignmentoption?.length == 0 ? (
                <label style={{ fontWeight: "600", color: "#ef4444" }}>
                  You Dont Have Acess To Any Group
                </label>
              ) : assignmentoption?.length === 1 ? (
                <label style={{ fontWeight: "600" }}>
                  You Belonging To : {assignmentoption[0].label}
                </label>
              ) : (
                <label style={{ fontWeight: "600" }}>
                  You Belonging To :{" "}
                  <select>
                    <option value="0">{`All Following ${assignmentoption.length} Groups`}</option>
                    {assignmentoption?.map((e) => (
                      <option value={e.value}>{e.label}</option>
                    ))}
                  </select>
                </label>
              )}
            </div>
            <div className=" d-flex justify-content-end mt-1 mb-4">
              <Button
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 130,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 14,
                  border: "1px solid #5A88F1",
                  backgroundColor: "#fff",
                  color: defaultview === "card" ? "#5250E5" : "black",
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={() => setdefaultview("card")}
              >
                <ComputerOutlinedIcon /> Card View
              </Button>
              <Button
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 130,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 14,
                  border: "1px solid #5A88F1",
                  backgroundColor: "#fff",
                  color: defaultview === "tabel" ? "#5250E5" : "black",
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={() => {
                  setdefaultview("tabel");
                  clearFilters();
                }}
              >
                <TableRowsOutlinedIcon /> Table View
              </Button>
            </div>
            {defaultview == "card" ? (
              <div className="row m-1">
                {/* First Column (8 units) */}
                <div className="col-md-9 ">
                  {loading ? (
                    <>
                      <div
                        className={
                          "inactive-card shadow-sm mt-1 mb-2  problemcardcontainer"
                        }
                      >
                        <SkeletonCard />
                      </div>
                      <div
                        className={
                          "inactive-card shadow-sm mt-1 mb-2  problemcardcontainer"
                        }
                      >
                        <SkeletonCard />
                      </div>
                      <div
                        className={
                          "inactive-card shadow-sm mt-1 mb-2  problemcardcontainer"
                        }
                      >
                        <SkeletonCard />
                      </div>
                    </>
                  ) : null}

                  {problemdata.length > 0 && !loading ? (
                    problemdata
                      .slice(startIndex, endIndex)
                      ?.filter((R) => R?.ProblemStages.value != "closed")
                      .map((problemitem) => (
                        <div
                          key={problemitem.ProblemId}
                          className={
                            "inactive-card shadow-sm mt-1 mb-2  problemcardcontainer"
                          }
                          onClick={(e) =>
                            handleOpen(
                              e,
                              problemdata.filter(
                                (x) => x.ProblemId == problemitem.ProblemId
                              )
                            )
                          }
                          // style={{
                          //   marginBottom: "10px",
                          //   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                          //   backgroundColor: "#ffffff",
                          //   padding: "10px",
                          // }}
                          style={{
                            background: "#F9FAFB",
                            border: "1px solid rgb(0, 0, 0 ,20%)",
                          }}
                        >
                          <div>
                            <div>
                              <Stepper
                                alternativeLabel
                                activeStep={steps.indexOf(
                                  problemitem?.ProblemStages.label
                                )}
                                connector={<QontoConnector />}
                                className="mt-3 d-flex justify-content-start"
                              >
                                {steps.map((label, index) => (
                                  <Step key={label}>
                                    <StepLabel
                                      StepIconComponent={(props) =>
                                        QontoStepIcon({
                                          ...props,
                                          currentstage:
                                            problemitem?.ProblemStages.label,
                                          index: index,
                                          isworkroundfilled:
                                            problemitem.WorkAround,
                                          isrootcausefilled:
                                            problemitem.ActualRootCause,
                                          label: label,
                                          isclosed: steps.indexOf(
                                            problemitem?.ProblemStages.label
                                          ),
                                        })
                                      }
                                    >
                                      <span>{label}</span>
                                    </StepLabel>
                                  </Step>
                                ))}
                              </Stepper>
                            </div>

                            <div className="probleminfo">
                              <div className="image">
                                <Avatar
                                  className=" mr-1"
                                  alt=""
                                  style={{
                                    width: "80px", // set the desired width
                                    height: "80px", // set the desired height
                                  }}
                                  src={
                                    allImage?.length > 0 && allImage?.filter(
                                      (x) =>
                                        x.teammemberid ==
                                        problemitem?.CreatedBy?.id
                                    )?.[0]?.image != undefined ? allImage?.filter(
                                      (x) =>
                                        x.teammemberid ==
                                        problemitem?.CreatedBy?.id
                                    )?.[0]?.image : Unassigned
                                  }
                                />
                              </div>
                              <div className="content">
                                <h4>
                                  <span className="muted">
                                    {"#" + problemitem?.ProblemRefNumber + ": "}
                                  </span>
                                  {problemitem?.ShortDescription}
                                </h4>

                                <div className="infos">
                                  <span>
                                    {problemitem?.CreatedBy?.FN +
                                      " " +
                                      problemitem?.CreatedBy?.LN}
                                  </span>{" "}
                                  <Divider orientation="vertical" flexItem />
                                  <span>{problemitem?.Priority.value}</span>
                                  <Divider orientation="vertical" flexItem />
                                  <span>
                                    <span
                                      className="mdi mdi-checkbox-blank-circle mr-1"
                                      style={{
                                        color:
                                          problemitem?.Impact.value == 3
                                            ? "#45DD81"
                                            : problemitem?.Impact.value == 2
                                              ? "#ddce45"
                                              : "#dd4545",
                                        fontSize: "9px",
                                      }}
                                    ></span>
                                    {problemitem?.Impact.label}
                                  </span>
                                  <Divider orientation="vertical" flexItem />
                                  <span> {problemitem?.Priority.code} </span>
                                </div>
                                <div className="infos2">
                                  <p className="infoitem">
                                    Created at:
                                    <span>
                                      {moment(
                                        problemitem?.CreatedDateTime
                                      ).format("DD-MMM-YYYY,h:mm A")}
                                    </span>
                                  </p>
                                  <Divider orientation="vertical" flexItem />
                                  <p className="infoitem">
                                    Category:
                                    <span>{problemitem.Category?.label}</span>
                                  </p>
                                  <Divider orientation="vertical" flexItem />
                                  <p className="infoitem">
                                    subcategory:
                                    <span className="">
                                      {problemitem.Subcategory?.label}
                                    </span>
                                  </p>
                                </div>
                                <div className="infos2">
                                  <p className="infoitem ">
                                    {problemitem.AssignedTo != null ? (
                                      <>
                                        assigned to:
                                        <span>
                                          {problemitem?.AssignedTo?.name}
                                        </span>
                                      </>
                                    ) : (
                                      <button
                                        // xx
                                        disabled={assigntomeinprogress}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          cardassgntome(problemitem.ProblemId);
                                        }}
                                        style={{
                                          width: 100,
                                          height: 36,
                                          borderRadius: 5,
                                          background: "#5250E5",
                                          color: "#fff",
                                        }}
                                      >
                                        assign to me
                                      </button>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="filters"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <div className="filter2" style={{ flex: 1 }}>
                                  {/* <label className="SprintlabelStyle">
                                    Impact:
                                  </label>
                                  <Select
                                    className="customselect"
                                    styles={customStyles}
                                    options={Impactoption}
                                    value={
                                      problemitem.Impact?.value
                                        ? [problemitem.Impact]
                                        : []
                                    }
                                    onChange={(selecteddata) => {
                                      imapctchange(problemitem.ProblemId, {
                                        Impact: selecteddata,
                                      });
                                    }}
                                  /> */}

                                  <div
                                    className="d-flex justify-space-between "
                                    style={{ flex: 1 }}
                                  >
                                    <span
                                      className="mdi mdi-checkbox-blank-circle ml-3"
                                      style={{
                                        color:
                                          problemitem?.Impact.value == 3
                                            ? "#45DD81"
                                            : problemitem?.Impact.value == 2
                                              ? "#ddce45"
                                              : "#dd4545",
                                        fontSize: "9px",
                                      }}
                                    ></span>
                                    <select
                                      style={{ flex: 1 }}
                                      className="fontdesign ssls ml-2 mb-2"
                                      // defaultValue={e?.Impact?.ImpactName}
                                      defaultValue={
                                        problemitem.Impact?.value
                                          ? problemitem.Impact?.value
                                          : ""
                                      }
                                      onChange={(selecteddata) => {
                                        let dataofimp = Impactoption.filter(
                                          (x) =>
                                            x.value == selecteddata.target.value
                                        );

                                        imapctchange(problemitem.ProblemId, {
                                          Impact:
                                            dataofimp.length > 0
                                              ? dataofimp[0]
                                              : {},
                                        });
                                      }}
                                      // value={impacts[i]}
                                      // disabled={e.CurrentState.StateCode == "Resolved"}
                                      // onChange={(e, i) => impactchange(e, data)}
                                      title="Impact"
                                    >
                                      {Impactoption.map((x) => (
                                        <option value={x.value}>
                                          {x.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="filter2" style={{ flex: 1 }}>
                                  {/* <label className="SprintlabelStyle">
                                    State:
                                  </label>
                                  <Select
                                    className="customselect"
                                    styles={customStyles}
                                    options={stateofproblem}
                                    value={problemitem.ProblemStages}
                                    onChange={(selecteddata) => {
                                      imapctchange(problemitem.ProblemId, {
                                        ProblemStages: selecteddata,
                                      });
                                    }}
                                  /> */}
                                  <div
                                    className="d-flex justify-space-between "
                                    style={{ flex: 1 }}
                                  >
                                    <svg
                                      width="13"
                                      height="9"
                                      viewBox="0 0 13 9"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="mt-1 ml-3"
                                    >
                                      <path
                                        id="Vector"
                                        d="M8.44935 0H12.3499C12.7075 0 13 0.283317 13 0.629593C13 0.975869 12.7075 1.25919 12.3499 1.25919H8.44935C8.0918 1.25919 7.79925 0.975869 7.79925 0.629593C7.79925 0.283317 8.0918 0 8.44935 0ZM8.44935 3.46276H12.3499C12.7075 3.46276 13 3.74608 13 4.09235C13 4.43863 12.7075 4.72195 12.3499 4.72195H8.44935C8.0918 4.72195 7.79925 4.43863 7.79925 4.09235C7.79925 3.74608 8.0918 3.46276 8.44935 3.46276ZM8.44935 6.92552H12.3499C12.7075 6.92552 13 7.20884 13 7.55511C13 7.90139 12.7075 8.18471 12.3499 8.18471H8.44935C8.0918 8.18471 7.79925 7.90139 7.79925 7.55511C7.79925 7.20884 8.0918 6.92552 8.44935 6.92552ZM5.10137 8.90874L6.26503 7.78177C6.39505 7.65585 6.39505 7.46067 6.26503 7.33476L5.10137 6.20779C5.05655 6.16311 4.99893 6.13246 4.93595 6.11981C4.87297 6.10715 4.80752 6.11306 4.74804 6.13678C4.68857 6.1605 4.63779 6.20093 4.60228 6.25287C4.56676 6.3048 4.54813 6.36585 4.54879 6.42814V8.68838C4.54879 8.9654 4.89984 9.10391 5.10137 8.90874ZM4.54879 6.92552H4.35376C2.82604 6.92552 1.46084 5.84892 1.31132 4.37567C1.27135 3.98174 1.31688 3.58408 1.44499 3.2082C1.57309 2.83232 1.78095 2.48655 2.05521 2.19307C2.32947 1.8996 2.66407 1.66491 3.03752 1.50408C3.41097 1.34325 3.81503 1.25983 4.22374 1.25919H5.84897C6.20652 1.25919 6.49907 0.975869 6.49907 0.629593C6.49907 0.283317 6.20652 0 5.84897 0H4.22374C1.71438 0 -0.300909 2.14062 0.0371396 4.6338C0.310179 6.69887 2.22795 8.18471 4.37326 8.18471H4.54879"
                                        fill="black"
                                      />
                                    </svg>
                                    <select
                                      className="fontdesign ssls ml-1"
                                      title="Status"
                                      value={problemitem.ProblemStages.value}
                                      onChange={(selecteddata) => {
                                        let dataofimp = stateofproblem.filter(
                                          (x) =>
                                            x.value == selecteddata.target.value
                                        );

                                        imapctchange(problemitem.ProblemId, {
                                          ProblemStages:
                                            dataofimp.length > 0
                                              ? dataofimp[0]
                                              : {},
                                        });
                                      }}
                                    >
                                      {stateofproblem.map((x) => (
                                        <option value={x.value}>
                                          {x.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                {/* <div className="filter2">
                                  <button
                                    variant="outline-secondary"
                                    size="sm"
                                    fontSize="small"
                                    // style={{ fontSize: "17px",alignContent:"start" }}
                                    className="btn btn-text-dark  btn-sm fontdesign mt-1 "
                                    style={{ textAlign: "left" }}
                                    title="Notes"
                                    onClick={(e) =>
                                      handleOpen(
                                        e,
                                        problemdata.filter(
                                          (x) =>
                                            x.ProblemId == problemitem.ProblemId
                                        )
                                      )
                                    }
                                  >
                                    <SpeakerNotesOutlinedIcon
                                      className="Card_Button_Color_Approve  "
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                      }}
                                    ></SpeakerNotesOutlinedIcon>{" "}
                                    Notes
                                  </button>
                                </div> */}
                                <div className="filter2 ml-2 mt-2">
                                  {problemitem?.IsRootCauseIdentifed == "Y" ? (
                                    <button
                                      // disabled={assigntomeinprogress}
                                      onClick={() => {
                                        createasbug(problemitem);
                                      }}
                                      hidden={problemitem?.IsBugTaskCreated == "Y"}
                                      style={{
                                        // width: 100,
                                        height: 30,
                                        borderRadius: 5,
                                        background: "rgb(246, 202, 204)",
                                        padding: ".3rem .5rem",
                                        marginBottom: "5px",
                                        fontWeight: "bold",
                                        color: "rgb(208, 34, 36)",
                                      }}
                                    >
                                      Create as bug
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                  ) : !loading && problemdata.length == 0 ? (
                    <NotfoundProblemcard
                      tryagain={setretry}
                      tryagainvalue={retry}
                    />
                  ) : null}

                  {problemdata.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 10,
                      }}
                    >
                      <Pagination
                        className="probpagination"
                        style={{ color: "#000" }}
                      >
                        <Pagination.First
                          onClick={() => handleChangePage(1)}
                          disabled={page === 1}
                        />
                        <Pagination.Prev
                          onClick={() => handleChangePage(page - 1)}
                          disabled={page === 1}
                        />

                        {Array.from({ length: totalPages }, (_, i) => {
                          if (
                            totalPages <= 7 ||
                            i + 1 === 1 ||
                            i + 1 === totalPages
                          ) {
                            return (
                              <Pagination.Item
                                key={i + 1}
                                active={i + 1 === page}
                                onClick={() => handleChangePage(i + 1)}
                              >
                                <span>{i + 1}</span>
                              </Pagination.Item>
                            );
                          } else if (
                            (i + 1 >= page - 1 && i + 1 <= page + 1) ||
                            (i + 1 === page - 2 && i + 1 > 2) ||
                            (i + 1 === page + 2 && i + 1 < totalPages - 1)
                          ) {
                            return (
                              <Pagination.Item
                                key={i + 1}
                                active={i + 1 === page}
                                onClick={() => handleChangePage(i + 1)}
                              >
                                <span>{i + 1}</span>
                              </Pagination.Item>
                            );
                          } else if (
                            (i + 1 === 2 && page > 4) ||
                            (i + 1 === totalPages - 1 && page < totalPages - 3)
                          ) {
                            return <Pagination.Ellipsis key={i + 1} disabled />;
                          }

                          return null;
                        })}

                        <Pagination.Next
                          onClick={() => handleChangePage(page + 1)}
                          disabled={page === totalPages}
                        />
                        <Pagination.Last
                          onClick={() => handleChangePage(totalPages)}
                          disabled={page === totalPages}
                        />
                      </Pagination>

                      <select
                        className="form-select mb-3 p-1 "
                        value={itemsPerPage}
                        onChange={handleChangeItemsPerPage}
                      >
                        <option value={5}>5 items per page</option>
                        <option value={10}>10 items per page</option>
                        <option value={25}>25 items per page</option>
                      </select>
                    </div>
                  ) : null}
                </div>

                {/* Second Column (4 units) */}
                <div
                  className="col-md-3 p-2 mb-2 "
                  style={{
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    height: "auto",
                    maxWidth: "350px",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#fff",
                      padding: "10px",
                      height: "100%",
                    }}
                  >
                    <Button
                      style={{
                        fontSize: 14,
                        padding: 9,
                        // width: 130,
                        height: 40,
                        borderRadius: 8,
                        marginRight: 14,
                        border: "1px solid #5A88F1",
                        backgroundColor: "#5A88F1",
                        color: "#fff",
                        textAlign: "center",
                        textTransform: "none",
                      }}
                      onClick={() => setretry(!retry)}
                    >
                      Fetch Latest Problems <ReplayIcon />
                    </Button>
                    <hr></hr>
                    <div
                      className="d-flex"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 style={{ fontWeight: "bold" }}>Filter</h4>
                      <span
                        style={{ color: "rgb(82, 80, 229)", cursor: "pointer" }}
                        onClick={clearFilters}
                      >
                        Clear Filter
                      </span>
                    </div>

                    {/* React Select for Assigned To */}
                    {/* <div className="mt-3">
                      <label className="SprintlabelStyle">Assigned To:</label>
                      <Select styles={customStyles} options={options} />
                    </div> */}

                    {/* React Select for Priority */}
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Refnumber:</label>
                      <br></br>
                      <input
                        placeholder="Problem Refnumber"
                        style={{ width: "100%" }}
                        className="SprintFormFields"
                        value={idtofind == "all" ? "" : idtofind}
                        onChange={(sdata) => {
                          if (sdata.target.value == "") {
                            setidtofind("all");
                            if (page != 1) {
                              setPage(1);
                            }
                          } else {
                            if (page != 1) {
                              setPage(1);
                            }
                            setidtofind(sdata.target.value.toUpperCase());
                          }
                        }}
                      />
                    </div>
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Priority:</label>
                      <Select
                        styles={customStyles}
                        value={priorityFilter}
                        onChange={(sdata) => {
                          setpriorityFilter(sdata);
                        }}
                        options={[
                          { value: "all", label: "All" },
                          ...PriorityOption,
                        ]}
                      />
                    </div>

                    {/* React Select for Status */}
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Impact:</label>
                      <Select
                        value={impactFilter}
                        styles={customStyles}
                        onChange={(sdata) => {
                          console.log("setimpactFilter", sdata);
                          setimpactFilter(sdata);
                        }}
                        options={[
                          { value: "all", label: "All" },
                          ...Impactoption,
                        ]}
                      />
                    </div>

                    {/* React Select for State */}
                    <div className="mt-3">
                      <label className="SprintlabelStyle">State:</label>
                      <Select
                        styles={customStyles}
                        value={problemStagesFilter}
                        onChange={(sdata) => {
                          setproblemStagesFilter(sdata);
                        }}
                        options={[
                          { value: "all", label: "All" },
                          ,
                          ...stateofproblem,
                        ]}
                      />
                    </div>

                    {/* React Select for Category */}
                    <div className="mt-3">
                      <label className="SprintlabelStyle">Category:</label>
                      <Select
                        styles={customStyles}
                        value={categoryFilter}
                        onChange={(sdata) => {
                          setcategoryFilter(sdata);
                        }}
                        options={categoryOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : problemdata.length > 0 ? (
              <MaterialTable
                title={"Problem list"}
                columns={columns}
                data={dummyData}
                options={{
                  search: true,
                  sorting: true,
                  rowStyle: (rowData) => ({
                    padding: "10px",
                  }),
                }}
                onRowClick={(event, rowData) => {
                  handleOpen(
                    e,
                    problemdata.filter((x) => x.ProblemId == rowData.id)
                  );
                }}
              />
            ) : (
              <NotfoundProblemcard tryagain={setretry} tryagainvalue={retry} />
            )}
          </div>
        </Tab>
      </Tabs>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={messageopen}
        autoHideDuration={1500}
        onClose={() => {
          setmessageopen(false);
        }}
      >
        <Alert
          onClose={() => {
            setmessageopen(false);
          }}
          severity="success"
          sx={
            open
              ? {
                width: "100%",
                backgroundColor: "green !important",
                color: "white !important",
              }
              : { width: "100%" }
          }
        >
          updated successfully!
        </Alert>
      </Snackbar>
      {open && (
        <ProblemDetail
          statedropdown={stateofproblem}
          updatefunc={imapctchange}
          openp={open}
          setopenp={setOpen}
          data={carddata[0]}
          updatadata={updateProblemById}
          patchdata={imapctchange}
          onClose={handleClose}
          prioritydropdown={PriorityOption}
          assigntomefun={cardassgntome}
          ClientId={Empdetailsofuser[0].ClientId}
          reload={retry}
          createchange={createchange}
          setcreatechange={setcreatechange}
          reloadfunc={setretry}
        />
      )}
      <div>
        {" "}
        {createchange == true ? (
          <ChangeModule
            createchange={createchange}
            setcreatechange={setcreatechange}
            data={carddata[0]}
          />
        ) : null}
      </div>
    </>
  );
};

export default ProblemManagement;
