import React from "react";
import { toast } from "react-toastify";
import MaterialTable from "material-table";
import moment from "moment";
import PatchedPagination from "../../../PatchedPagination";
import { blue } from "@material-ui/core/colors";
import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux'
import StopCircleIcon from "@mui/icons-material/StopCircle";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import Unassigned from "../../../assets/images/avatar.png";

toast.configure();

function VelocityReportTable({
  tabledata,
  releaseID,
  userID,
  releaselabel,
  agile_report,
  agile_relese,
  check,
}) {
  const { allImage } = useSelector((state) => state.user)
  const Columns = [
    // { title: "Employee Id", field: "employeeid" },
    {
      title: "Employee Name", field: "employeename",
      cellStyle: { whiteSpace: 'nowrap' }
    },
    {
      title: "Version",
      field: "version",
      hidden: userID == null && releaseID == null,
    },
    {
      title: "Sprint Start Date",
      field: "sprintstartdate",
      hidden: userID == null && releaseID == null,
    },
    {
      title: "Sprint End Date",
      field: "sprintenddate",
      hidden: userID == null && releaseID == null,
    },
    {
      title: "Sprint Weeks",
      field: "sprintinweeks",
      hidden: userID == null && releaseID == null,
    },
    {
      title: "Story commit vs complete",
      field: "committedstory",
      hidden: !check,
    },
    { title: "Task commit vs complete", field: "committedtask", hidden: check },
    { title: "Bug commit vs complete", field: "committedbug", hidden: check },
    { title: "Total commit vs complete", field: "committed" },
    {
      title: "Total Sprint",
      field: "totalsprint",
      hidden: userID != null || releaseID != null,
    },
    {
      title:
        userID == null && releaseID == null
          ? "Total Sprints worked, Velocity per sprint ( 2 weeks)"
          : userID != null && releaseID == null
            ? "Velocity Per Sprint"
            : "Velocity",
      field: "velocity",
    },

    // { title: "Total Completed", field: "completed", },
  ];
  const data = tabledata.filter((e) => e.completed != 0);
  return (
    <div>
      <div className="Table shadow">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        ></link>
        <MaterialTable
          title={"Report"}
          columns={Columns.map((e) => e)}
          data={
            data === ""
              ? ""
              : data.map((e) => {
                console.log(e, "check employee")
                // console.log(
                //   e.sprintenddate == "Invalid date",
                //   "sprintenddate",
                //   e.sprintinweeks == "null Weeks",
                //   "sprintinweeks"
                // );
                const getdata = [];
                let calculateWeeks = [];
                releaselabel.map((label) => {
                  const lengthcheck = agile_report.filter(
                    (x) =>
                      x.releasename == label && x.assignedto == e.employeeid
                  ).length;
                  // console.log(lengthcheck, "lengthcheck");
                  if (lengthcheck == 0) {
                  } else {
                    getdata.push(lengthcheck);
                    calculateWeeks.push(
                      agile_relese?.filter((e) => e.ReleaseName == label)[0]
                        ?.SprintInWeeks == undefined || null
                        ? 0
                        : agile_relese?.filter(
                          (e) => e.ReleaseName == label
                        )[0]?.SprintInWeeks
                    );
                  }
                });
                const initialValue = 0;
                const total = calculateWeeks.reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  initialValue
                );
                return {
                  ...e,
                  employeename: <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      style={{ width: 30, height: 30 }}
                      // sx={{ width: 25, height: 25 }}
                      alt="" src={allImage?.length > 0 ? allImage?.filter((x) => x.teammemberid == e.employeeid)?.[0]?.image : Unassigned} />
                    &nbsp;{e.employeename == "" || e.employeename == "null" ? "UnAssigned" : e.employeename}</div>,
                  version: <label style={{
                    padding: 0,
                    textTransform: "none",
                    color: "#132043",
                    margin: 0,
                    backgroundColor: "rgba(224, 244, 255, 0.8)",
                    paddingTop: 3,
                    paddingBottom: 2,
                    paddingLeft: 1,
                    paddingRight: 1,
                    fontSize: 11,
                  }}
                    className="planicon">{e.version}</label>,
                  committedstory: e.committedstory + " / " + e.completedstory,
                  committedtask: <label><CheckBoxRoundedIcon
                    className="task fontdesign"
                    style={{ fontSize: "16px" }}
                  />{" " + e.committedtask + " / " + e.completedtask}</label>,
                  committedbug: <label><StopCircleIcon
                    style={{ fontSize: "16px" }}
                    className="bug fontdesign"
                  />{" " + e.committedbug + " / " + e.completedbug}</label>,
                  committed: e.committed + " / " + e.completed,
                  sprintstartdate:
                    e.sprintstartdate == "Invalid date"
                      ? "-"
                      : e.sprintstartdate,
                  sprintenddate:
                    e.sprintenddate == "Invalid date" ? "-" : e.sprintenddate,
                  sprintinweeks:
                    e.sprintinweeks == "null"
                      ? "0 Weeks"
                      : e.sprintinweeks + "weeks",
                  totalsprint: getdata.length,
                  velocity:
                    userID == null && releaseID == null
                      ? Math.ceil((e.completed / total) * 2) + " Tickets "
                      : e.completed + " Tickets",
                };
              })
          }
          components={{
            Pagination: PatchedPagination,
          }}
          options={{
            actionsColumnIndex: -1,
            exportAllData: true,
            exportButton: true,
            columnResizable: true,
            filtering: false,
            sorting: false,
            paging: true,
            pageSize: 20, // make initial page size
            emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
            pageSizeOptions: [20, 30, 40, 50], // rows selection options

            headerStyle: {
              whiteSpace: "nowrap",
              fontWeight: "700",
              fontSize: "medium",
              // backgroundColor: "#eef2f7",
              color: "black",
              padding: "12px ",
              textAlign: "left",
            },
            cellStyle: {
              textAlign: "left",
              fontSize: "medium",
              padding: "21px",
              color: "#6c757d",
            },
            rowStyle: (rowData) => {
              // console.log(rowData)
              if (rowData.tableData.id % 2 != 0) {
                // return { backgroundColor: "#cddef085" };
              }
            },
          }}

        // actions={[
        //     rowData => ({
        //         icon: "backspace",
        //         tooltip: "Withdraw",
        //         disabled: rowData.isapproved == "Approved" || rowData.isapproved == "Rejected" || rowData.isapproved == "Withdrawn",
        //         // onClick: (event, rowData) => {
        //         //     withdrawn(rowData)
        //         // },
        //     }),
        //     rowData => ({
        //         icon: "message",
        //         tooltip: rowData.approverremarks,
        //         hidden: rowData.approverremarks == null,
        //     }),
        // ]}
        />
      </div>
    </div>
  );
}
export default VelocityReportTable;
