import React, { useState } from "react";
import "gantt-task-react/dist/index.css";
import { Avatar } from "@material-ui/core";


export function initTasks() {


    const currentDate = new Date()
    const tasks = [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
        name: "Firset Sprint",
        id: "ProjectSample",
        progress: 25,
        type: "project",
        hideChildren: true,
        // displayOrder: 1,
        // styles:{
        //     backgroundColor:"rgb(127, 39, 255,0.5)",
        //     progressColor:"rgba(159, 112, 253,0.5)",
        //     // fontsize:"20px"
        // }
        // styles:{
        //     // progressColor:"lightblue"
        //     backgroundColor:"#008080"
        // }
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 16),
        name: "Firset Sprint-2",
        id: "ProjectSample 2",
        progress: 100,
        type: "project",
        hideChildren: true,
        // displayOrder: 1,
        // styles:{
        //     backgroundColor:"rgb(127, 39, 255,0.5)",
        //     progressColor:"rgba(159, 112, 253,0.5)",
        //     // fontsize:"20px"
        // }
        // styles:{
        //     // progressColor:"lightblue"
        //     backgroundColor:"#008080"
        // }
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
        name: "First task",
        id: "Task 0",
        progress: 45,
        type: "task",
        project: "ProjectSample",
        // displayOrder: 2,
    //     styles:{  // Color of the task bar
    //         progressColor: "rgb(95, 189, 255)",
    //         backgroundColor: "#ADD8E6",
    //         backgroundSelectedColor:"rgba(150, 239, 255,0.5)",
    //         progressSelectedColor:"rgb(150, 239, 255)",
    // },
        assingne:"prasanna"
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
        name: "First task-2",
        id: "Task 0",
        progress: 100,
        type: "task",
        project: "ProjectSample 2",
        // displayOrder: 5,
    //     styles:{  // Color of the task bar
    //         progressColor: "rgb(95, 189, 255)",
    //         backgroundColor: "#ADD8E6",
    //         backgroundSelectedColor:"rgba(150, 239, 255,0.5)",
    //         progressSelectedColor:"rgb(150, 239, 255)",
    // },
        assingne:"prasanna"
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
        name: "Task2 ",
        id: "Task 1",
        progress: 25,
        dependencies: ["Task 0"],
        type: "task",
        project: "ProjectSample",
        // displayOrder: 3,
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
        name: "Task 3",
        id: "Task 2",
        progress: 10,
        dependencies: ["Task 1"],
        type: "task",
        project: "ProjectSample",
        // displayOrder: 4,
      },
    //   {
    //     start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
    //     end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
    //     name: "Developing",
    //     id: "Task 3",
    //     progress: 2,
    //     dependencies: ["Task 2"],
    //     type: "task",
    //     project: "ProjectSample",
    //     displayOrder: 5,
    //   },
    //   {
    //     start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
    //     end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
    //     name: "Review",
    //     id: "Task 4",
    //     type: "task",
    //     progress: 70,
    //     dependencies: ["Task 2"],
    //     project: "ProjectSample",
    //     displayOrder: 6,
    //   },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
        name: "Release",
        id: "Task 6",
        progress: currentDate.getMonth(),
        type: "milestone",
        dependencies: ["Task 4"],
        project: "ProjectSample",
        // displayOrder: 7,
        styles:{
            backgroundColor:"green"
        }
      },
    //   {
    //     start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
    //     end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
    //     name: "Party Time",
    //     id: "Task 9",
    //     progress: 0,
    //     isDisabled: true,
    //     type: "task",
    //   },
    ]
    return tasks
  }
  
  export function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId)
    let start = projectTasks[0].start
    let end = projectTasks[0].end
  
    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i]
      if (start.getTime() > task.start.getTime()) {
        start = task.start
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end
      }
    }
    return [start, end]
  }
  