import axios from "../../../axios";
import React, { useEffect, useState } from "react";
import { FormHelperText, IconButton } from "@material-ui/core";

import { Form, Button } from "reactstrap";

import Select from "react-select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import { toast, Slide } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./ProjectAllocation.css";
import "./Allocationteam.css";
import { TroubleshootSharp } from "@mui/icons-material";

toast.configure();

let managerid =
  // sessionStorage.getItem("Roledetails") == 'undefined' ? "" : JSON.parse(sessionStorage.getItem("Roledetails"));
  localStorage.getItem("Empdetails") == "undefined" ? "" :
    JSON.parse(localStorage.getItem("Empdetails"))?.[0]?.ClientUserId


const Allocationseamless = ({
  openAllocForm,
  setOpenAllocForm,
  Refresh,
  projectnames,
  setseamlessopen,
  employeeDetails,
  seamlessopen,
  setProjectName
  // handleClose,
}) => {
  // const projectnames =  "Agile Testing 27-01-2023"
  let clientdetails = JSON.parse(localStorage.getItem("clientdetails")) == undefined ? "" : JSON.parse(localStorage.getItem("clientdetails"));
  let email = JSON.parse(localStorage.getItem("userdata")) == undefined ? "" : JSON.parse(localStorage.getItem("userdata"));

  const [pcode, setpcode] = useState("");
  const [enddate, setenddate] = useState(new Date());
  const [checkSubmit, setSubmitCheck] = useState(false)
  const [Values, setValues] = useState([
    {
      // TeamMemberId: null,
      ProjectCode: pcode,
      ProjectStartDate: new Date(),
      ProjectEndDate: enddate,
      ManagerId: managerid,
      AllocPercentage: 100,
      ProjectRole: {
        RoleGroupId: "",
        RoleCode: "",
        RoleName: "",
      },
      MappedBy: managerid,
      MappedByDetails: {
        ContactName: clientdetails[0]?.OrgDetails?.ContactName,
        ContactDesignation: clientdetails[0]?.RoleDetails?.RoleCode,
        ContactEmail: clientdetails[0]?.UserDetails?.Email,
        ContactPhone: clientdetails[0]?.OrgDetails?.ContactPhone,
      },
      IsCurrent: "Y",
      Status: null,
    },
  ]);
  let initialValue = {
    TeamMemberId: "",
    ProjectCode: pcode,
    ProjectStartDate: Values?.ProjectStartDate,
    ProjectEndDate: Values?.ProjectEndDate,
    ManagerId: managerid,
    AllocPercentage: 100,
    ProjectRole: {
      RoleGroupId: "",
      RoleCode: "",
      RoleName: "",
    },
    MappedBy: managerid,
    MappedByDetails: {
      ContactName: clientdetails[0]?.OrgDetails?.ContactName,
      ContactDesignation: clientdetails[0]?.RoleDetails?.RoleCode,
      ContactEmail: clientdetails[0]?.UserDetails?.Email,
      ContactPhone: clientdetails[0]?.OrgDetails?.ContactPhone,
    },
    IsCurrent: "Y",
    Status: null,
  };
  const [employee, setEmployee] = useState(employeeDetails);
  const [test, setTest] = useState([]);
  const [projects, setProjects] = useState(null);

  const [managers, setManagers] = useState([]);

  const [Errors, setErrors] = useState({});

  const [visible, setVisible] = useState(false);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [deallocateddata, setdealloacteddata] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [empfilter, setempfilter] = useState(null);
  const [mapfill, setmapfill] = useState([]);
  const [validateerror, setvalidateerror] = useState("");

  // console.log(validateerror, "validateerooro");
  // console.log(initialValue,"initialvalues")

  const empmm = empfilter?.map((e) => e.TeamMemberId);
  const [getEmployees, setGetEmployees] = useState([])

  // console.log(employee?.filter((e)=>e.TeamMemberId != empmm)?.TeamMemberId,"empfilter")

  // let arr1 = JSON.parse(sessionStorage.getItem("EmpDetails"));
  // let rolecode = JSON.parse(localStorage.getItem("Rolecode"));

  let id = localStorage.getItem("clientid") == "undefined" ? "" : JSON.parse(localStorage.getItem("clientid"));
  let empid = localStorage.getItem("Empdetails") == "undefined" ? "" : JSON.parse(localStorage.getItem("Empdetails"));
  let crtid = empid?.[0]?.ClientUserId
  const [count, setCount] = useState(0)
  const [selectTeam, setSelectTeam] = useState(false)



  useEffect(() => {
    axios
      .get("agilesaas_project_master?ClientId=eq." + id + "&order=ProjectId.desc&limit=1")
      .then((prjres) => {
        let projectcode = prjres.data.length != 0 ? prjres.data?.[0]?.ProjectId : 0
        console.log(prjres.data)

        axios("agilesaas_project_mapping?ClientId=eq." + id)
          .then((res) => {
            const newDate = new Date()
            let data =
            {
              "TeamMemberId": "",
              "ProjectCode": projectcode,
              "ProjectStartDate": new Date(),
              "ProjectEndDate": new Date(newDate.setMonth(newDate.getMonth() + 6)),
              "ManagerId": crtid,
              "AllocPercentage": 100,
              "ProjectRole": {
                "RoleGroupId": "4",
                "RoleCode": "DEV",
                "RoleName": "Scrum Team-Developer",
              },
              "MappedBy": crtid,
              "MappedByDetails": {
                "ContactName": clientdetails[0]?.OrgDetails?.ContactName,
                "ContactDesignation":
                  clientdetails[0]?.RoleDetails?.RoleCode,
                "ContactEmail": clientdetails[0]?.UserDetails?.Email,
                "ContactPhone":
                  clientdetails[0]?.OrgDetails?.ContactPhone,
              },
              "IsCurrent": "Y",
              "Status": null,
              "ClientId": id,
              "Name": "",
              "Id": null
            }
            let empData = []
            employeeDetails.filter((c) => c.UserId != crtid).map((e) => {
              let fiData = res.data.filter((a) => a.TeamMemberId == e.UserId && a.AllocPercentage != 0).length
              console.log(e)

              let d = {
                ...data, "TeamMemberId": e.UserId, "Name": e?.UserDetails?.Firstname + " " + e?.UserDetails?.Lastname
              }
              if (fiData == 0) {
                empData.push(d)
              }
              console.log(empData)

            })
            setEmployee(empData)
            setProjectName(empData.length == 0 ? "" : prjres.data?.[0]?.ProjectName)
          })
      })

  }, [seamlessopen])
  console.log(employee)
  useEffect(() => {



    // axios
    //   .get("subscription_client_users?ClientId=eq." + id)
    //   .then((res) => {
    //     let c = res.data?.find((e) => e?.OfficialEmail == email?.login)?.ClientUserId;

    //     setTest(res.data);
    //     axios
    //       .get("agilesaas_project_master?ClientId=eq." + id + "&order=ProjectId.desc&limit=1")
    //       .then((res) => {
    //         let a = res.data;
    //         console.log(a[0].StartDate, "startdate")
    //         let code = a?.find(
    //           (e) => e.ProjectName === projectnames
    //         )?.ProjectId;
    //         let projectcode = res.data.length != 0 ? res.data?.[0]?.ProjectId : 0
    //         // let projectcode = res.data
    //         //   ?.map((e) => e.ProjectId)
    //         //   .sort()
    //         //   .slice(-1)
    //         //   .toString();
    //         // console.log(projectcode, "projectcode");
    //         setProjects(res.data);
    //         axios
    //           .get(
    //             `rpc/fun_agilesaasmanagerteamprojdetails?managerid=` +
    //             c +
    //             `&clientid=` +
    //             id
    //           )
    //           .then((res) => {
    //             console.log(res.data, "manager api")
    //             let a = [];
    //             res.data
    //               ?.filter((f) => f.iscurrent === null)
    //               ?.map((e) => {
    //                 let data = {
    //                   ProjectCode: projectcode,
    //                   ProjectStartDate: new Date(),
    //                   ProjectEndDate: Values.ProjectEndDate,
    //                   ManagerId: c,
    //                   AllocPercentage: 100,
    //                   ProjectRole: {
    //                     RoleGroupId: "4",
    //                     RoleCode: "DEV",
    //                     RoleName: "Scrum Team-Developer",
    //                   },
    //                   MappedBy: c,
    //                   MappedByDetails: {
    //                     ContactName: clientdetails[0]?.OrgDetails?.ContactName,
    //                     ContactDesignation:
    //                       clientdetails[0]?.RoleDetails?.RoleCode,
    //                     ContactEmail: clientdetails[0]?.UserDetails?.Email,
    //                     ContactPhone:
    //                       clientdetails[0]?.OrgDetails?.ContactPhone,
    //                   },
    //                   IsCurrent: "Y",
    //                   Status: null,
    //                   Id: null,
    //                   Name: e?.firstname + " " + e?.lastname,
    //                 };
    //                 let final = {
    //                   ...data,
    //                   TeamMemberId: e.teammemberid,
    //                 };
    //                 a.push(final);
    //               });
    //             console.log(a, "a")
    //             setEmployee(a);
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    axios
      .get("agilesaas_roles_master")
      .then((res) => setManagers(res.data))
      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .get("agilesaas_project_mapping")
    //   .then((res) => {
    //     setempfilter(res.data?.filter((e) => e.IsCurrent == "Y"));
    //     setSelectedEmployee(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err, "erorroror111");
    //   });
  }, []);

  // const loadData = (eid) => {
  //   axios
  //     .get(
  //       "agilesaas_project_mapping?TeamMemberId=eq." + selectedRow?.TeamMemberId
  //     )
  //     .then((res) => {
  //       setAllocatedEmployee(res.data.filter((e) => e.IsCurrent == "Y"));
  //       setSelectedEmployee(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err, "erorroror111");
  //     });
  //   axios
  //     .get(
  //       "agilesaas_project_mapping?TeamMemberId=eq." +
  //         selectedRow?.TeamMemberId +
  //         "&IsCurrent=eq.Y"
  //     )
  //     .then((res) => {
  //       setdealloacteddata(res.data.filter((e) => e));
  //       setSelectedEmployee(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err, "erorroror111");
  //     });
  // };
  console.log(employee, "empolyee")
  let manager = managers?.filter(
    (e) => e.RoleCode != "BO" && e.RoleCode != "PO"
  );
  let managerdropdown = manager?.map((man) => {
    return {
      RoleGroupId: man.RoleGroupId,
      value: man.RoleCode,
      label: man.RoleName,
    };
  });
  console.log(moment(Values.ProjectStartDate) >= moment(Values.ProjectEndDate), "testprojectststt")


  const resetForm = () => {
    setProjectName("")
    setValues(initialValue);
    setErrors({});
    setseamlessopen(false)
  };

  const handleInputChange = (dropdownname, e, index) => {
    console.log(e)
    const name = dropdownname;
    const value =
      dropdownname == "ProjectRole"
        ? e.value
        : dropdownname == "ProjectStartDate" || dropdownname == "ProjectEndDate"
          ? e
          : e.target.value;
    // : e.target.value || dropdownname == "ProjectEndDate"
    // ? e.value
    // : e.target.moment;
    const list = [...employee];
    let data = {
      RoleGroupId:
        name == "ProjectRole"
          ? manager?.filter((e) => e.RoleCode == value)[0].RoleGroupId
          : "4",
      RoleCode: value,
      RoleName:
        name == "ProjectRole"
          ? manager?.filter((e) => e.RoleCode == value)[0].RoleName
          : "Scrum Team-Developer",
    };

    list[index][name] = name == "ProjectRole" ? data : value || index;

    list.map((e, i) => {
      if (e.Id != null && name == "ProjectRole" && e.ProjectRole == null) {
        list[i][name] = data;
      } else if (
        e.Id != null &&
        moment(
          name == "ProjectStartDate"
            ? e.ProjectStartDate
            : e.ProjectEndDate
        ).diff(moment(new Date()).format("YYYY-MM-DD"), "day") == 0 &&
        (name == "ProjectStartDate" || name == "ProjectEndDate")
      ) {
        list[i][name] = value;
      }
    });
    setEmployee(list);
  };




  const addAllocationHandler = () => {
    let filterData = employee?.filter((e) => e.Id != null);
    // axios.get("agilesaas_project_master?ClientId=eq." + id + "&order=ProjectId.desc&limit=1").then((res) => {
    //   let prjcode = res.data.length != 0 ? res.data?.[0]?.ProjectId : 0
    //   console.log(filterData)
    const removedId = filterData.map((e) => {
      let da = { ...e };
      delete da.Id;
      delete da.Name;
      return da;
    });
    console.log(removedId, "removedId")

    if (removedId.length == 0) {
      setSelectTeam(true)
      // toast.error(`Please select Team member`, {
      //   transition: Slide,
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000,
      //   draggable: true,
      // });
    }

    else {
      setSelectTeam(false)
      employee.filter((e) => e.Id != null).map((x, i) => {
        console.log(x.ProjectStartDate, x.ProjectEndDate)
        console.log((parseInt(i) + 1), employee.filter((e) => e.Id != null).length)
        const checkdate = moment(x.ProjectStartDate).diff(moment(x.ProjectEndDate), 'days')
        if (checkdate > 0 || checkdate == 0) {
          alert("please enter valid date for " + (parseInt(i) + 1) + " row")
        }
        else if ((parseInt(i) + 1) == employee.filter((e) => e.Id != null).length) {
          setSubmitCheck(true)
          axios.post("agilesaas_project_mapping", removedId).then((res) => {
            toast.success(`Project Allocated`, {
              transition: Slide,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              draggable: true,
            });
            setProjectName("")
            setseamlessopen(false)
            setValues(initialValue);
            resetForm();
            Refresh();
            setSubmitCheck(false)
          })
        }
      })


    }
    // console.log(removedId, "removeid");

    // alert("hi");
    // e.preventDefault();

    // if (validate()) {
    //   if (
    //     selectedEmployee.find(
    //       (selected) => selected.ProjectCode == Values.ProjectCode
    //     )
    //   ) {
    // console.log(Values.projectcode)
    // axios
    //   .put(
    //     "agilesaas_project_mapping?TeamMemberId=eq." +
    //       selectedRow?.TeamMemberId +
    //       "&ProjectCode=eq." +
    //       Values.ProjectCode
    //   )
    //   .then((res) => {
    //     toast.success(`Project Allocated`, {
    //       transition: Slide,
    //       position: toast.POSITION.TOP_RIGHT,
    //       autoClose: 3000,
    //       draggable: true,
    //     });

    // console.log(Values.EmpId,"ii")
    // loadData(Values.TeamMemberId);
    // setVisible(true)
    // setValues(initialValue);

    // resetForm();
    // });
    // } else {

    // });
  };

  const convertToPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  const closeIconHandler = () => {
    setOpenAllocForm(false);
    setVisible(false);
    resetForm();
  };

  return (
    <>
      {employee?.length == 0 ?
        <label className="text-danger">No users to allocate</label> :
        <div style={{ textAlign: 'center', alignContent: 'center' }}>
          {selectTeam ? <label className="text-danger mb-3" >Please select team members to allocate</label> : ""}
          <table
            responsive
            striped
            bordered
            hover
            className="position-relative "
            style={{ marginBottom: "50px" }}
          >
            <thead >
              <tr style={{ marginBottom: "70px" }}>
                <th></th>
                <th className="textallseam ">Name<label className="createAlert">
                  *
                </label></th>
                <th className="textallseam " style={{ textAlign: "center" }}>
                  StartDate<label className="createAlert">
                    *
                  </label>
                </th>
                <th className="textallseam ml-5" style={{ textAlign: "center" }}>
                  EndDate<label className="createAlert">
                    *
                  </label>
                </th>
                <th style={{ textAlign: "center", fontWeight: "bold" }}>
                  ProjectRole<label className="createAlert">
                    *
                  </label>
                </th>
              </tr>
            </thead>
            <tbody className="textdown">
              {

                employee?.map((x, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <input
                          className="textteam mt-2"
                          name="Id"
                          type={"checkbox"}
                          style={{
                            alignItems: "center",
                            marginRight: "30px",
                            marginLeft: "20px",
                          }}
                          error={validateerror}
                          placeholder="Enter First Name"
                          value={x?.ClientUserId}
                          onChange={(e) => handleInputChange("Id", e, i)}
                        />
                      </td>
                      <td className="mt-2" >{x?.Name}</td>

                      <td className="ml-3">
                        {/* <input
                     
                      type={"date"}
                      style={{marginLeft:"10px"}}
                      name="ProjectStartDate"
                      placeholder="Enter Last Name"
                      value={x.ProjectStartDate}
                      onChange={(e) => handleInputChange("ProjectStartDate", e, i)}
                    /> */}
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            style={{ width: "170px" }}
                            disablePast
                            varient="dialog"
                            className="mytextteam ml-3"
                            format="dd/MM/yyyy"
                            // margin="normal"
                            views={["year", "month", "date"]}
                            // label="Allocation Start Date"
                            // className="select MuiFormControl-marginNormal"
                            value={x.ProjectStartDate}
                            error={Errors.ProjectStartDate}
                            name="ProjectStartDate"
                            onChange={(e) => {
                              // setstartdate(e)
                              // let de = moment(e)
                              handleInputChange("ProjectStartDate", e, i)
                            }
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </td>

                      <td>
                        {/* <input
                     
                      type={"date"}
                      name="ProjectEndDate"
                      style={{marginLeft:"10px"}}
                      //   defaultValue={}
                      placeholder="Enter Last Name"
                      value={x.ProjectEndDate}
                       onChange={(e) => handleInputChange("ProjectEndDate", e, i)}
                    /> */}
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            style={{ width: "170px" }}
                            disablePast
                            varient="dialog"
                            className="mytextteam ml-2"
                            format="dd/MM/yyyy"
                            name="ProjectEndDate"
                            // margin="normal"
                            views={["year", "month", "date"]}
                            // label="Allocation Start Date"
                            // className="select MuiFormControl-marginNormal"
                            // value={enddate}
                            value={x.ProjectEndDate}
                            error={Errors.ProjectEndDate}
                            onChange={(e) => {
                              // setenddate(e)
                              // let de = moment(e)
                              handleInputChange("ProjectEndDate", e, i)
                            }
                            }
                          // onChange={(e) => handleInputChange("ProjectEndDate", e, i)}
                          />
                        </MuiPickersUtilsProvider>
                      </td>
                      <td>
                        <Select
                          className="allteam "
                          name="ProjectRole"
                          // value={ x?.ProjectRole?.RoleName }
                          defaultValue={{ label: "Scrum Team-Developer", value: 4 }}
                          options={managerdropdown}
                          onChange={(e) => {
                            handleInputChange("ProjectRole", e, i);
                          }}
                        >
                          {/* <option value={""}>Choose Role</option> */}
                          {/* {managerdropdown?.map((e) => {
                        return (
                          <>

                            <option value={e.RoleCode}>{e.RoleName}</option>
                          </>
                        );
                      })} */}
                        </Select>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="d-flex justify-content-end">
            <br></br>
            <Button
              style={{ fontSize: 14, padding: 9, width: 100, height: 40, borderRadius: 8, marginRight: 14, border: '2px solid #5250E5', backgroundColor: '#fff', color: '#5250E5', textAlign: 'center', textTransform: 'none' }}
              onClick={resetForm}

            >
              Cancel
            </Button>
            <Button
              style={{ fontSize: 14, padding: 9, width: 120, height: 40, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center', textTransform: 'none' }}
              onClick={addAllocationHandler}
              disabled={checkSubmit}
            >
              {checkSubmit ?
                <span
                  className="spinner-border"
                  role="status"
                  aria-hidden="true"
                  style={{ color: '#fff', width: '1.5rem', height: '1.5rem' }}
                ></span> : 'Allocate 100%'}
            </Button>


          </div>
        </div>}
    </>
  );
};

export default Allocationseamless;
