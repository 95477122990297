import React, { useEffect, useState } from "react";
import PatchedPagination from "../../PatchedPagination";
import axios from "../../axios";
import MaterialTable from "material-table";
import { Avatar, Button as Button1, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import Unassigned from "../../assets/images/avatar.png";
import moment from "moment";

export const Employeeleave = () => {
  let userdetails = JSON.parse(localStorage.getItem("Empdetails"));
  let userdetailsinfo = JSON.parse(localStorage.getItem("clientdetails"));
  const [Empleaves, setleaves] = useState([]);
  const { allImage } = useSelector((state) => state.user);

  useEffect(() => {
    if (userdetails != null && userdetailsinfo != null) {
      axios
        .get(
          `agilesaas_holidaysandleaves?ClientId=eq.${userdetails[0].ClientId}&UserId=neq.${userdetails[0].ClientUserId}`
        )
        .then((res) => {
          setleaves(res.data);
        })
        .catch((e) => {
          alert("something went wrong");
        });
    }
  }, []);
  // const Columns = [
  //   {
  //     title: "UserId",
  //     field: "UserId",
  //     headerStyle: { fontWeight: "bold", width: "10%" },
  //     cellStyle: { width: "10%", textAlign: "left" },
  //   },
  //   // {
  //   //   title: "Name",
  //   //   field: "Name",
  //   //   headerStyle: { fontWeight: "bold", width: "5%" },
  //   //   cellStyle: { width: "20%", textAlign: "left" },
  //   // },
  //   {
  //     title: "Name",
  //     field: "Name",
  //     headerStyle: { fontWeight: "bold", width: "5%" },
  //     cellStyle: { width: "20%", textAlign: "left" },
  //     render: (rowData) => (
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <Avatar
  //           style={{ width: 30, height: 30, marginRight: "10px" }}
  //           alt={rowData.Name}
  //           src={
  //             allImage?.length > 0 &&
  //             allImage?.filter((x) => x.teammemberid == rowData?.UserId)?.[0]
  //               ?.image != undefined
  //               ? allImage?.filter(
  //                   (x) => x.teammemberid == rowData?.UserId
  //                 )?.[0]?.image
  //               : Unassigned
  //           } // Replace 'AvatarUrl' with the actual field name from your data
  //         />
  //         {rowData.Name}
  //         {console.log(rowData)}
  //       </div>
  //     ),
  //   },

  //   {
  //     title: "Leave Start date ",
  //     field: "LeaveStartDate",
  //     headerStyle: { fontWeight: "bold", width: "10%" },
  //     cellStyle: { width: "10%", textAlign: "left" },
  //   },
  //   {
  //     title: "Leave End Date",
  //     field: "LeaveEndDate",
  //     headerStyle: { fontWeight: "bold", width: "20%" },
  //     cellStyle: { width: "10%", textAlign: "left" },
  //   },
  //   {
  //     title: "No of days",
  //     field: "NumberOfDays",
  //     headerStyle: { fontWeight: "bold", width: "15%" },
  //     cellStyle: { width: "15%", textAlign: "left" },
  //   },
  //   {
  //     title: "Submitted Date",
  //     field: "SubmittedDate",
  //     headerStyle: { fontWeight: "bold", width: "15%" },
  //     cellStyle: { width: "15%", textAlign: "left" },
  //   },
  //   {
  //     title: "Summary",
  //     field: "leavenotes",
  //     headerStyle: { fontWeight: "bold", width: "15%" },
  //     cellStyle: { width: "15%", textAlign: "left" },
  //   },
  // ];
  const Columns = [
    {
      title: "UserId",
      field: "UserId",
      headerStyle: { fontWeight: "bold", width: "10%", zIndex: 1 },
      cellStyle: { width: "10%", textAlign: "left" },
    },
    {
      title: "Name",
      field: "Name",
      headerStyle: { fontWeight: "bold", width: "5%", zIndex: 1 },
      cellStyle: { width: "20%", textAlign: "left" },
      render: (rowData) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            style={{ width: 30, height: 30, marginRight: "10px" }}
            alt={rowData.Name}
            src={
              allImage?.length > 0 &&
              allImage?.filter((x) => x.teammemberid == rowData?.UserId)?.[0]
                ?.image != undefined
                ? allImage?.filter(
                    (x) => x.teammemberid == rowData?.UserId
                  )?.[0]?.image
                : Unassigned
            }
          />
          {rowData.Name}
          {/* {console.log(rowData)} */}
        </div>
      ),
    },
    {
      title: "Leave Start date",
      field: "LeaveStartDate",
      headerStyle: { fontWeight: "bold", width: "10%", zIndex: 1 },
      cellStyle: { width: "10%", textAlign: "left" },
    },
    {
      title: "Leave End Date",
      field: "LeaveEndDate",
      headerStyle: { fontWeight: "bold", width: "20%", zIndex: 1 },
      cellStyle: { width: "10%", textAlign: "left" },
    },
    {
      title: "No of days",
      field: "NumberOfDays",
      headerStyle: { fontWeight: "bold", width: "15%", zIndex: 1 },
      cellStyle: { width: "15%", textAlign: "left" },
    },
    {
      title: "Submitted Date",
      field: "SubmittedDate",
      headerStyle: { fontWeight: "bold", width: "15%", zIndex: 1 },
      cellStyle: { width: "15%", textAlign: "left" },
    },
    {
      title: "Summary",
      field: "leavenotes",
      headerStyle: { fontWeight: "bold", width: "15%", zIndex: 1 },
      cellStyle: { width: "15%", textAlign: "left" },
    },
  ];

  return (
    <div className="tabel mt-2" style={{ index: "1px" }}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      ></link>
      <MaterialTable
        title={"Team Leave Report"}
        columns={Columns.map((e) => e)}
        data={Empleaves?.map((e, i) => {
          return {
            ...e,
            Name: e.UserDetails.Firstname + " " + e.UserDetails.Lastname,
            LeaveStartDate: moment(e.LeaveStartDate).format("DD MMM YYYY"),
            LeaveEndDate: moment(e.LeaveEndDate).format("DD MMM YYYY"),
            SubmittedDate: moment(e.SubmittedDate).format("DD MMM YYYY"),
            leavenotes: e.LeaveNotes.note1,
          };
        })}
        components={{
          Pagination: PatchedPagination,
        }}
        options={{
          search: true,
          actionsColumnIndex: -1,
          exportAllData: true,
          exportButton: true,
          columnResizable: true,
          filtering: false,
          sorting: true,
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 30, 40],

          headerStyle: {
            whiteSpace: "nowrap",
            // backgroundColor: "#8FD6E1",
            // color: "#03256C",
            width: "1000px",
            // textAlign: "center",

            padding: "2px",
            fontWeight: "700",
            fontSize: "small",
            // backgroundColor: "#eef2f7",
            color: "black",
          },
          cellStyle: {
            textAlign: "left",
            fontSize: "12px",
            padding: "2px",
            color: "#6c757d",
          },
          rowStyle: (rowdata) => {
            if (rowdata.tableData.id % 2 != 0) {
              // return { backgroundColor: "#cddef085" };
            }
          },
        }}
      />
    </div>
  );
};
