import { Avatar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/styles";
import { Button } from "reactstrap";
import moment from "moment";
import axios from "../../axios";
import ReactQuill from "react-quill";
import Toolbar, { modules, formats } from "./Toolbar";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import updatemoment from "./updatemoment";
import Unassigned from "../../assets/images/avatar.png";


function ManagerNotesupdate({
  // SelectedCardId
}) {
  const date = moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss");
  let User_details = JSON.parse(localStorage.getItem("Empdetails"));
  let managerid = JSON.parse(localStorage.getItem("managerid"));

  const { allImage, IsMinimised, projectRole } = useSelector((state) => state.user);
  const { data, isSuccess, message, loading, ReleaseId, currentsprintdata } =
    useSelector((state) => state.note);

  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == "11004")[0]
    ?.RoleCode;
  const [notesupdate, setNotesUpdate] = useState("");
  const [updatednotedata, setUpdatednotedata] = useState([]);
  const [inputopen, setInputopen] = useState(false);

  const [releaseId, setReleaseId] = useState()
  const [edit, setEdit] = useState(false)
  const [editData, setEditData] = useState(null)

  const BootstrapButton = styled(Button)({
    textTransform: "none",
    fontSize: 13,
    padding: "6px 12px",
    margin: 10,
    border: "0px solid",
  });

  function Refresh_API_data() {
    axios
      .get("agilesaas_releases?ReleaseName=eq." + ReleaseId)
      .then((res) => {
        setUpdatednotedata(res.data[0]?.ManagerNotes);
        setReleaseId(res.data[0].ReleaseId)
      })
      .catch((e) => {
        setUpdatednotedata([])
        console.log(e);
      });
  }

  useEffect(() => {
    Refresh_API_data();
  }, []);

  function Submit_data(e) {
    e.preventDefault();
    try {
      if (notesupdate != "") {
        let r =
          updatednotedata == null || updatednotedata == undefined || updatednotedata.length === 0
            ? 1
            : parseInt(
              updatednotedata?.sort((a, b) => b?.noteNo - a?.noteNo)[0]?.noteNo +
              1
            );

        let data
        if (edit) {
          data = {
            ManagerNotes: updatednotedata.map((item) => {
              if (item.noteNo === editData) {
                return { ...item, notes: notesupdate };
              }
              return item
            })
          }
          setNotesUpdate("")
          setEdit(false)


        } else {
          data = {
            ManagerNotes: [
              ...(updatednotedata ? updatednotedata : []),
              {
                UpdatedBy: User_details[0].ClientUserId,
                UpdatedByDetails: {
                  FN: User_details[0].UserFirstname,
                  LN: User_details[0].UserLastname,
                },
                noteNo: r,
                managername: "Yozy Support",
                datetime: date,
                notes: notesupdate,
                isDeleted: false
              },
            ],
          };
        }
        axios
          .patch(`agilesaas_releases?ReleaseId=eq.${releaseId}`, data)
          .then((res) => {
            toast.success("Note has been submitted");
            setInputopen(false);
            Refresh_API_data();
            setNotesUpdate("");
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }




  function HandleDelete(id) {
    const updatedData = {
      ManagerNotes: updatednotedata.map((item) => {
        if (item.noteNo === id) {
          return { ...item, isDeleted: true };
        }
        return { ...item, isDeleted: false };
      })
    }

    axios
      .patch(`agilesaas_releases?ReleaseId=eq.${releaseId}`, updatedData)
      .then((res) => {
        toast.success("Note Deleted");
        setInputopen(false);
        Refresh_API_data();
        setNotesUpdate("");
      })
      .catch((e) => {
        console.log(e);
      });


  }

  function HanldeEdit(e) {
    setEdit(true)
    setInputopen(true)
    setNotesUpdate(e.notes)
    setEditData(e.noteNo)
  }

  return (
    <div>
      {projectRole == "SM" ||
        projectRole == "ITMGR2" ||
        projectRole == "QA" ||
        getProdRole == "KA" ? <><div className="mt-2 d-flex align-items-center">
          <Avatar
            className="progressName mr-2"
            src={
              allImage?.length > 0 ? allImage?.filter(
                (x) => x.teammemberid == User_details?.[0]?.ClientUserId
              )?.[0]?.image : Unassigned
            }
          />
          <span style={{ color: "black" }}>
            {User_details[0].UserFirstname + " " + User_details[0].UserLastname}
          </span>
        </div>
        <div className="mt-1 ">
          {!inputopen ? (
            <input
              className="form-control ml-4"
              style={{ width: "97%", border: "1px solid black" }}
              onClick={() => {
                setInputopen(true)
                setEdit(false)
              }}
              placeholder="Update your important notes & progress"
            />
          ) : null}

          {inputopen ? (
            <form onSubmit={Submit_data} className="d-flex flex-column ml-4">
              <div>
                <Toolbar />
                <ReactQuill
                  className="quill w-100"
                  theme="snow"
                  placeholder="Update your important notes & progress"
                  value={notesupdate || ""}
                  onChange={(e) => setNotesUpdate(e)}
                  modules={modules}
                  formats={formats}


                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <BootstrapButton
                  onClick={() => {
                    setInputopen(false);
                  }}
                  style={{
                    fontSize: 14,
                    padding: 9,
                    width: 100,
                    height: 40,
                    borderRadius: 8,
                    marginRight: 14,
                    border: "2px solid #5250E5",
                    backgroundColor: "#fff",
                    color: "#5250E5",
                    textAlign: "center",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </BootstrapButton>
                <BootstrapButton
                  type="submit"
                  style={{
                    fontSize: 14,
                    padding: 9,
                    width: 100,
                    height: 40,
                    borderRadius: 8,
                    backgroundColor: "#5250E5",
                    color: "#fff",
                    border: "0px solid #5A88F1",
                    textAlign: "center",
                    textTransform: "none",
                  }}
                >
                  {edit ? "Update" : "Submit"}
                </BootstrapButton>
              </div>
            </form>
          ) : null}
        </div> </> : null}

      <div>
        {updatednotedata?.length == 0 || updatednotedata == null
          ? ""
          : updatednotedata
            ?.sort((a, b) => (a.noteNo < b.noteNo ? 1 : -1))
            ?.filter(t => t.isDeleted != true)
            ?.map((e, i) => {
              return (
                <div key={i}>
                  <div className="mt-2 d-flex align-items-center mb-2">
                    <Avatar
                      className="progressName mr-2"
                      src={
                        allImage?.length > 0 ? allImage?.filter(
                          (x) =>
                            x.teammemberid == managerid
                        )?.[0]?.image : Unassigned
                      }
                    />
                    <span className="fontdesign" style={{ color: "black" }}>
                      {e.UpdatedByDetails.FN + " " + e.UpdatedByDetails.LN}
                    </span>
                    <span
                      className="ml-2 fontdesign"
                      style={{ color: "black" }}
                    >
                      {updatemoment(e.datetime)}
                    </span>
                  </div>
                  <div className="d-flex flex-column ml-4 progress_note_update_color">
                    <span
                      style={{ color: "black", paddingLeft: 7 }}
                      dangerouslySetInnerHTML={{
                        __html: e.notes,
                      }}
                    ></span>
                  </div>
                  {projectRole == "SM" ||
                    projectRole == "ITMGR2" ||
                    projectRole == "QA" ||
                    getProdRole == "KA" ? <div className="d-flex" style={{ gap: "10px" }}>
                    <div className="text-danger" onClick={() => HandleDelete(e.noteNo)}>Delete</div>
                    <div className="text-info" onClick={() => HanldeEdit(e)}>Edit</div>
                  </div>
                    : null}                </div>
              );
            })}
      </div>
    </div>
  );
}
export default ManagerNotesupdate;
