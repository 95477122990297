import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "../../axios";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import RaiseATicket from "./RaiseATicket";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MaterialTable from "material-table";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Checkbox } from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Unassigned from "../../assets/images/avatar.png";

const Problem = ({ updatedata, updatadatafunc, tickproblem, settickproblme, setlinkedincidentp, linkedincidentp, setproblem }) => {

  const { allImage } = useSelector((state) => state.user);
  let Empdetailsofuser = JSON.parse(localStorage.getItem("Empdetails"));
  const clientid = JSON.parse(localStorage.getItem("clientid"));
  const [openmodel, setopenmodel] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [refno, setrefno] = useState("");
  const [messageopen, setmessageopen] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const [erroropen, seterroropen] = useState(false);
  const [buttondisable, setbuttondisable] = useState(false);
  const [stateofproblem, setstateofproblem] = useState([
    { value: "created", label: "Created" },
    { value: "analyzing", label: "Analyzing" },
    { value: "errorknown", label: "Error Known" },
    { value: "RootCauseidentified", label: "Root Cause identified" },

    { value: "closed", label: "Closed" },
  ]);
  const [selectedstate, setselectedstate] = useState({
    value: "created",
    label: "Created",
  });
  const [validpassed, setvalidpassed] = useState(true);
  console.log(openmodel, "openmodel")
  const [selectedtype, setselectedtype] = useState(null);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [categorydata, setcategorydata] = useState([]);
  const [subcategoryOptions, setsubcategoryOptions] = useState([]);
  const [selectedsubcategory, setselectedsubcategory] = useState(null);
  const [selectedprioity, setselectedprioity] = useState(null);
  const [selectedimapct, setselectedimapct] = useState(null);
  const [selectedraise, setSelectedraise] = useState([
    { value: "self", label: "Self" },
  ]);
  const [selectedraiseother, setSelectedraiseother] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserdata, setSelectedUserdata] = useState(null);
  const [workaroundgroup, setworkaroundgroup] = useState(null);
  const [workaroundgroupvalue, setworkaroundgroupvalue] = useState(null);
  const [rootcauseuser, setrootcauseuser] = useState(null);
  const [rootcauseuservalue, setrootcauseuservalue] = useState(null);
  const [options, setOptions] = useState([]);
  const [workarounduser, setworkarounduser] = useState(null);
  const [workarounduservalue, setworkarounduservalue] = useState(null);
  const [rootcausegroup, setrootcausegroup] = useState(null);
  const [rootcausegroupvalue, setrootcausegroupvalue] = useState(null);
  const [isknownerror, setisknownerror] = useState(false);
  const [isRootcauseidentified, setisRootcauseidentified] = useState(false);

  const handleSearch = (inputValue) => {
    const filteredUsers = alluser.filter((user) =>
      user.OfficialEmail.toLowerCase().includes(inputValue.toLowerCase())
    );

    setOptions(
      filteredUsers.map((user) => ({
        value: user.ClientUserId,
        label: user.OfficialEmail,
      }))
    );
  };
  const [alluser, setalluser] = useState([]);
  // const [description, setdescription] = useState("");
  const [selectedSubgenus, setSelectedSubgenus] = useState(null);
  const [selectedNameGroup, setSelectedNameGroup] = useState(null);
  const [selectedSubGroup, setSelectedSubGroup] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [description, setDescription] = useState("");
  const [managernote, setmanagernote] = useState("");
  const [assignmentoption, setassignmentoption] = useState([]);
  const [Assignedtooption, setAssignedtooption] = useState([]);
  const [assignmentgroup, setassignmentgroup] = useState([]);
  const [assignto, setassignto] = useState("");
  const [assigntodet, setassignedtodet] = useState("");
  const [assignvlue, setassignvalue] = useState("");
  const [tittle, settittle] = useState("");
  const [knownerror, setknownerror] = useState("");
  const [wrokaround, setwrokaround] = useState("");

  const [linkincidenterror, setlinkincidenterror] = useState(false);

  //error state
  const [tittleerror, settittleerror] = useState(false);
  const [stateerror, setstateerror] = useState(false);
  const [typeerror, settypeerror] = useState(false);
  const [catagoryerror, setcatagoryerror] = useState(false);
  const [subcatagoryerror, setsubcatagoryerror] = useState(false);
  const [priorityerror, setpriorityerror] = useState(false);
  const [impacterror, setimpacterror] = useState(false);
  const [descriptionerror, setdescriptionerror] = useState(false);
  const [assignvlueerror, setassignvlueerror] = useState(false);
  const [assigntoerror, setassigntoerror] = useState(false);
  const [managernoterror, setmanagernoterror] = useState(false);
  const [knownerrorvalid, setknownerrorvalid] = useState(false);
  const [wrokaroundvalid, setwrokaroundvalid] = useState(false);
  const [linkedincident, setlinkedincident] = useState(tickproblem == true ? linkedincidentp : []);
  const [alllinkedincident, setaalinkedincident] = useState([]);

  const type = [
    { value: "standard", label: "Standard" },
    { value: "normal", label: "Normal" },
    { value: "emergency", label: "Emergency" },
  ];
  const [selectedDate2, handleDateChange] = useState(new Date());
  const [selectedDate3, handleDateChange3] = useState(new Date());

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleChangelinkedind = (selectedValues) => {
    setlinkedincident(selectedValues);
  };
  const clearform = () => {
    handleDateChange(new Date());
    handleDateChange3(new Date());
    setSelectedCategory(null);
    setrefno("");
    setstateofproblem([
      { value: "created", label: "Created" },
      { value: "analyzing", label: "Analyzing" },
      { value: "errorknown", label: "Error Known" },
      { value: "RootCauseidentified", label: "Root Cause identified" },
      { value: "closed", label: "Closed" },
    ]);
    setselectedstate({ value: "created", label: "Created" });
    setalluser([]);
    setselectedtype(null);
    setcategoryOptions([]);
    setcategorydata([]);
    setsubcategoryOptions([]);
    setselectedsubcategory(null);
    setselectedprioity(null);
    setselectedimapct(null);
    setSelectedraise([{ value: "self", label: "Self" }]);
    setSelectedraiseother(false);
    setSelectedUser(null);
    setSelectedUserdata(null);
    setworkaroundgroup(null);
    setworkaroundgroupvalue(null);
    setrootcauseuser(null);
    setrootcauseuservalue(null);
    setOptions([]);
    setworkarounduser(null);
    setworkarounduservalue(null);
    setrootcausegroup(null);
    setrootcausegroupvalue(null);
    setisknownerror(false);
    setisRootcauseidentified(false);
    setIsChecked(false);
    setDescription("");
    setmanagernote("");
    setassignmentoption([]);
    setAssignedtooption([]);
    setassignmentgroup([]);
    setassignto("");
    setassignedtodet("");
    setassignvalue("");
    settittle("");
    setknownerror("");
    setwrokaround("");
    setlinkincidenterror(false);
    settittleerror(false);
    setstateerror(false);
    settypeerror(false);
    setcatagoryerror(false);
    setsubcatagoryerror(false);
    setpriorityerror(false);
    setimpacterror(false);
    setdescriptionerror(false);
    setassignvlueerror(false);
    setassigntoerror(false);
    setmanagernoterror(false);
    setknownerrorvalid(false);
    setwrokaroundvalid(false);
    setlinkedincident([]);
    setaalinkedincident([]);
    setlindedoptionsapidata([]);
    setopenmodel(false);
    if (typeof settickproblme === 'function') {
      settickproblme(false)
      setlinkedincidentp([])
    }

  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        style={{ backgroundColor: "green", color: "white" }}
        severity="success"
        {...props}
      />
    );
  });

  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   return (
  //     <MuiAlert
  //       elevation={6}
  //       ref={ref}
  //       variant="filled"
  //       style={{ backgroundColor: "green", color: "white" }}
  //       severity="success"
  //       {...props}
  //     />
  //   );
  // });
  const genusOptions = [
    { value: "Genus 1", label: "Genus 1" },
    { value: "Genus 2", label: "Genus 2" },
    { value: "Genus 3", label: "Genus 3" },
  ];
  const raiseoption = [
    { value: "self", label: "Self" },
    { value: "someone", label: "Some one" },
  ];

  const subgenusOptions = [
    { value: "Subgenus 1", label: "Subgenus 1" },
    { value: "Subgenus 2", label: "Subgenus 2" },
    { value: "Subgenus 3", label: "Subgenus 3" },
  ];

  let PriorityOption = [
    // 1 - System down, 2 - Wide impact ,3 - Moderate impact , 4 - Low impact
    { value: "P1", label: "P1-System down", code: "System down" },
    { value: "P2", label: "P2-Wide impact", code: "Wide impact" },
    { value: "P3", label: "P3-Moderate impact", code: "Moderate impact" },
    { value: "P4", label: "P4-Low impact", code: "Low impact" },
  ];
  let Impactoption = [
    // Urgency, Impact : 1-High, 2-Medium, 3-Low
    { value: "1", label: "High" },
    { value: "2", label: "Medium" },
    { value: "3", label: "Low" },
  ];
  const nameGroupOptions = [
    { value: "Name Group 1", label: "Name Group 1" },
    { value: "Name Group 2", label: "Name Group 2" },
    { value: "Name Group 3", label: "Name Group 3" },
  ];

  const subGroupOptions = [
    { value: "Sub Group 1", label: "Sub Group 1" },
    { value: "Sub Group 2", label: "Sub Group 2" },
    { value: "Sub Group 3", label: "Sub Group 3" },
  ];
  const lindedoptions = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
    { value: 3, label: "Option 3" },
    // Add more options as needed
  ];
  const [lindedoptionsapidata, setlindedoptionsapidata] = useState([]);
  useEffect(() => {
    axios
      .get("agilesaas_incident_category_master?ClientId=eq." + clientid)
      .then((response) => {
        let catagory = response.data.map((x) => {
          return {
            label: x.CategoryName,
            value: x.IncidentCategoryId,
          };
        });
        setcategoryOptions(catagory);
        setcategorydata(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get(
        `agilesaas_incident_details?ClientId=eq.${clientid}&isLinkedtoproblem->>islinked=eq.false`
      )
      .then((response) => {
        // let catagory = response.data.map((x) => {
        //   return {
        //     label: x.CategoryName,
        //     value: x.IncidentCategoryId,
        //   };
        // });
        setaalinkedincident(response.data);

        setlindedoptionsapidata(
          response.data.map((incident, index) => ({
            sno: index + 1,
            id: incident.IncidentId,
            label: `${incident.IncidentTitle}-${incident.IncidentId}`,
            incident: `${incident.IncidentTitle}-${incident.IncidentId}`,
            value: incident.IncidentId,
            status: incident?.CurrentState?.StateName,
            Priority: incident.Priority?.PriortyName,
            raisedBy:
              incident?.CreatedByDetails?.FN +
              "" +
              incident?.CreatedByDetails?.LN,
            raisedDate: moment(incident.CreatedDate).format("YYYY-MM-DD"),
          }))
        );
        console.log(response.data);
        // setcategoryOptions(catagory);
        // setcategorydata(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("subscription_client_users?ClientId=eq." + clientid)
      .then((response) => {
        setalluser(response.data);
        setOptions(
          response.data.map((user) => ({
            value: user.ClientUserId,
            label: user.OfficialEmail,
          }))
        );
        // setSelectedUser(response.data.map((user) => ({ value: user.id, label: user.name })));
      })
      .catch((error) => console.log(error));

    axios
      .get("subscription_user_group_mapping?ClientId=eq." + clientid)
      .then((response) => {
        let userGroupMappings = response.data;
        const resultArray = userGroupMappings.reduce(
          (acc, userGroupMapping) => {
            userGroupMapping.GroupDetails.forEach((groupDetail) => {
              const groupId = groupDetail.GroupId;
              const groupName = groupDetail.GroupName;

              // Check if the group already exists in the result array
              const existingGroupIndex = acc.findIndex(
                (group) => group.GroupId === groupId
              );

              if (existingGroupIndex !== -1) {
                // Add the user to the existing group
                acc[existingGroupIndex].users.push({
                  Firstname: userGroupMapping.UserDetails.Firstname,
                  Lastname: userGroupMapping.UserDetails.Lastname,
                  UserId: userGroupMapping.UserId,
                });
              } else {
                // Create a new group with the user
                acc.push({
                  GroupId: groupId,
                  GroupName: groupName,
                  users: [
                    {
                      Firstname: userGroupMapping.UserDetails.Firstname,
                      Lastname: userGroupMapping.UserDetails.Lastname,
                      UserId: userGroupMapping.UserId,
                    },
                  ],
                });
              }
            });

            return acc;
          },
          []
        );
        const assignmentooption = resultArray.map((e) => {
          return {
            label: e.GroupName,
            value: e.GroupId,
            users: e.users,
          };
        });
        setassignmentoption(assignmentooption);
      })
      .catch((error) => console.log(error));
    // axios
    // .get("agilesaas_itsmassignmentgroup_lookup?ClientId=eq."+clientid)
    // .then((response) => {
    //   Setlookup(response.data);
    //   console.log(response.data);
    // })
    // .catch((error) => console.log(error));
    // axios
    // .get("subscription_user_group_mapping?ClientId=eq."+clientid)
    // .then((response) => {
    //   setgrpassign(response.data)
    //   console.log(response.data);
    // })
    // .catch((error) => console.log(error));
  }, [openmodel]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "2px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: "auto",
      // width: 230,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const customStyleserror = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused ? "2px solid rgb(82, 80, 229)" : "2px solid red",
      borderRadius: 8,
      height: "auto",
      animation: "shake 0.5s ease-in-out",
      // width: 230,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const useStyles = makeStyles({
    datePickerInput: {
      width: "100%",

      border: "none",
      "&:MuiInputBase-root": {
        border: "none",
        padding: "2%",
      }, // Remove the default border
      "&:focus": {
        outline: "none", // Optionally, remove the focus outline as well
      },
    },
    root: {
      "& .MuiBox-root": {
        paddingTop: 2,
        margin: 1,
      },
      "& .MuiAppBar-root": {
        // boxShadow: "none", // Remove the box shadow from the tab header
        paddingLeft: 0,
        margin: 0,
      },
      "& .MuiTabs-root": {
        display: "flex",
        justifyContent: "flex-start",
      },
    },
  });
  const classes = useStyles();
  const columns = [
    {
      title: "S.No",
      field: "sno",
      headerStyle: { fontWeight: "bold", width: "10%" },
    },
    {
      title: "Incident",
      field: "incident",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Priority",
      field: "Priority",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Raised Date",
      field: "raisedDate",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Raised By",
      field: "raisedBy",
      headerStyle: { fontWeight: "bold", width: "20%" },
    },
    {
      title: "Status",
      field: "status",
      headerStyle: { fontWeight: "bold", width: "10%" },
    },
  ];

  const CheckboxOption = ({ innerProps, label, isSelected }) => (
    <div {...innerProps}>
      <input type="checkbox" checked={isSelected} onChange={() => { }} />
      {label}
    </div>
  );
  function generateUniqueID() {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000); // You can adjust the range as needed
    return `${timestamp}-${random}`;
  }

  // Example of generating a unique ID
  let uniqueID = generateUniqueID();

  function validationsubmit() {
    // setTimeout(() => {
    //   settittleerror(false);
    //   setstateerror(false);
    //   settypeerror(false);
    //   setcatagoryerror(false);
    //   setsubcatagoryerror(false);
    //   setpriorityerror(false);
    //   setimpacterror(false);
    //   setdescriptionerror(false);
    //   setassignvlueerror(false);
    //   setassigntoerror(false);
    //   setlinkincidenterror(false);
    // }, 2000);
    if (tittle.length <= 3) {
      settittleerror(true);
    } else {
      settittleerror(false);
    }
    if (!selectedstate) {
      setstateerror(true);
    } else {
      setstateerror(false);
    }
    if (!selectedtype) {
      settypeerror(true);
    } else {
      settypeerror(false);
    }
    if (!selectedCategory) {
      setcatagoryerror(true);
    } else {
      setcatagoryerror(false);
    }
    if (!selectedsubcategory) {
      setsubcatagoryerror(true);
    } else {
      setsubcatagoryerror(false);
    }
    if (!selectedprioity) {
      setpriorityerror(true);
    } else {
      setpriorityerror(false);
    }

    if (!selectedimapct) {
      setimpacterror(true);
    } else {
      setimpacterror(false);
    }

    if (!description) {
      setdescriptionerror(true);
    } else {
      setdescriptionerror(false);
    }
    if (!assignvlue) {
      setassignvlueerror(true);
    } else {
      setassignvlueerror(false);
    }

    if (!assignto) {
      setassigntoerror(true);
    } else {
      setassigntoerror(false);
    }
    if (!managernote) {
      setmanagernoterror(true);
    } else {
      setmanagernoterror(false);
    }
    if (linkedincident.length < 0) {
      setlinkincidenterror(true);
    } else {
      setlinkincidenterror(false);
    }

    if (!wrokaround) {
      setwrokaroundvalid(true);
    } else {
      setwrokaroundvalid(false);
    }
    if (!knownerror) {
      setknownerrorvalid(true);
    } else {
      setknownerrorvalid(false);
    }
    // if(){}
    // selectedraise
    let selfdetail = {
      FN: Empdetailsofuser[0]?.UserFirstname,
      LN: Empdetailsofuser[0]?.UserLastname,
      id: Empdetailsofuser[0]?.ClientUserId,
    };
    let createddetail = selectedUser
      ? alluser
        .filter((x) => {
          if (x.OfficialEmail == selectedUser.label) {
            return x;
          }
        })
        .map((item) => {
          return {
            FN: item?.UserFirstname,
            LN: item?.UserLastname,
            id: item?.ClientUserId,
          };
        })
      : null;

    function isValidKnownError() {
      if (isknownerror) {
        if (wrokaround && workaroundgroupvalue) {
          // Additional checks for known er

          // Add conditions as needed
          return true;
        } else {
          return false;
        }
      }
      return true; // If isknownerror is false, skip additional checks
    }

    function isValidRootCauseIdentified() {
      if (isRootcauseidentified) {
        if (rootcausegroupvalue && knownerror) {
          // Additional checks for root cause identified
          // Add conditions as needed
          return true;
        } else {
          return false;
        }
      }
      return true; // If isRootcauseidentified is false, skip additional checks
    }

    function areBasicFieldsValid() {
      return (
        assignvlue &&
        selectedimapct &&
        selectedCategory &&
        selectedsubcategory &&
        selectedstate &&
        managernote &&
        tittle &&
        description &&
        selectedprioity &&
        !linkincidenterror
      );
    }

    function validateFields() {
      if (!areBasicFieldsValid()) {
        // alert("Please check the input fields");
        seterrormessage("Please check all input fields");
        seterroropen(true);
        return false;
      }

      if (isValidKnownError()) {
        // Additional checks for other cases if needed
      } else {
        // alert(" workaround details  or Applied group cannot be empty ");
        seterrormessage("workaround details  or Applied group cannot be empty");
        seterroropen(true);
        return false;
      }
      if (isValidRootCauseIdentified()) {
        // Additional checks for other cases if needed
        return true;
      } else {
        seterrormessage(
          "Rootcause details  or Identified group cannot be empty"
        );
        seterroropen(true);
        // alert("Rootcause details  or Identified group cannot be empty ");
        return false;
      }
    }

    if (validateFields()) {
      setbuttondisable(true);
      let linkincid = tickproblem == true ? linkedincidentp.map((item) => ({
        IncidentId: item.id,
        Increfnumber: item.refnumber
      })) : linkedincident.map((item) => ({
        IncidentId: item.id,
      }));
      let pid = !selectedraiseother
        ? "PRB" + selfdetail.id + "P"
        : "PRB" + createddetail[0].id + "P";
      let realdata = {
        ProblemRefNumber: pid,

        CreatedBy: !selectedraiseother ? selfdetail : createddetail[0],
        CreatedDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        Priority: selectedprioity,
        // assigntodet,
        // assignto,

        AssignmentGroup: { Groupid: assignvlue, GroupName: assignmentgroup },
        AssignedTo: assignto
          ? {
            userid: assignto,
            name: assigntodet,
          }
          : null,
        ShortDescription: tittle,
        LongDescription: description,
        LinkedIncidents: linkincid,
        LinkedChanges: null,
        IsKnownError: isknownerror ? "Y" : "N",
        IsRootCauseIdentifed: isRootcauseidentified ? "Y" : "N",
        ProblemNotes: assignto
          ? [
            {
              Message_id: `${generateUniqueID()}-${!selectedraiseother ? selfdetail.id : createddetail[0].id
                }`,
              DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
              NoteUpdatedBy: !selectedraiseother
                ? selfdetail
                : createddetail[0],
              NotesDetails: `problem has been assigned to ${assigntodet}`,
              action: false,
            },
            {
              Message_id: `${uniqueID}-${!selectedraiseother ? selfdetail.id : createddetail[0].id
                }`,
              DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
              NoteUpdatedBy: !selectedraiseother
                ? selfdetail
                : createddetail[0],
              NotesDetails: managernote,
              action: false,
            },
          ]
          : [
            {
              Message_id: `${uniqueID}-${!selectedraiseother ? selfdetail.id : createddetail[0].id
                }`,
              DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
              NoteUpdatedBy: !selectedraiseother
                ? selfdetail
                : createddetail[0],
              NotesDetails: managernote,
              action: true,
            },
          ],
        ProblemStages: selectedstate,
        Category: selectedCategory,
        Subcategory: selectedsubcategory,
        OtherBusinessServiceAffected: null,
        ResolutionComments: null,
        WorkAround: isknownerror
          ? {
            WorkAround: wrokaround,
            AppliedBy: {
              userid: workarounduservalue,
              name: workarounduser,
            },

            AppliedGroup: {
              Groupid: workaroundgroupvalue,
              GroupName: workaroundgroup,
            },
            ApplyUntill: moment(selectedDate2).format("YYYY-MM-DD"),
          }
          : null,
        Impact: selectedimapct,
        ActualRootCause: isRootcauseidentified
          ? {
            RootCause: knownerror,

            Identifiedby: {
              userid: rootcauseuservalue,
              name: rootcauseuser,
            },
            IdentifiedGroup: {
              Groupid: rootcausegroupvalue,
              GroupName: rootcausegroup,
            },
            ReleatedTicketReferences: refno,
            IdentifiedDate: moment(selectedDate3).format("YYYY-MM-DD"),
          }
          : null,
        ClientId: clientid,
      };
      console.log(realdata, "realdata");

      axios
        .post("agilesaas_problem_details", realdata)
        .then((response) => {
          axios
            .get(`agilesaas_problem_details?ProblemRefNumber=eq.${pid}`)
            .then((res) => {
              axios
                .patch(`agilesaas_problem_details?ProblemRefNumber=eq.${pid}`, {
                  ProblemRefNumber: pid + res.data[0].ProblemId,
                })
                .then((dataofresponse) => {
                  if (res.data[0].LinkedIncidents.length == 0) {
                    // alert("submitted successfully");

                    setmessageopen(true);
                    clearform();
                    updatadatafunc(!updatedata);
                    setopenmodel(false);
                    setbuttondisable(false);
                  } else {
                    let succulength = res.data[0].LinkedIncidents.map(
                      (x) => x.IncidentId
                    ).join(",");

                    axios
                      .patch(
                        `agilesaas_incident_details?IncidentId=in.(${succulength})`,
                        {
                          isLinkedtoproblem: {
                            islinked: true,
                          },
                        }
                      )
                      .then((res) => {
                        setmessageopen(true);
                        if (!typeof settickproblme === 'function') {
                          updatadatafunc(!updatedata);

                        }


                        clearform();
                        // if(tickproblem == false){
                        //   setlinkedincidentp([])

                        //  }
                        setopenmodel(false);
                        setbuttondisable(false);
                      })
                      .catch((e) => {
                        console.log(e, "Eee")
                        setbuttondisable(false);

                        seterrormessage("Unable to link incident");
                        seterroropen(true);
                        // alert("unable to link incident");
                      });
                  }
                })
                .catch((e) => {
                  // alert("something wentwrong");
                  setbuttondisable(false);

                  seterrormessage("something wentwrong");
                  seterroropen(true);
                });
            })
            .catch((e) => {
              setbuttondisable(false);

              seterrormessage("something wentwrong");
              seterroropen(true);
            });
        })
        .catch((e) => {
          setbuttondisable(false);

          seterrormessage("something wentwrong");
          seterroropen(true);
        });
    }

    // let data = {
    //   state: selectedstate,
    //   type: selectedtype,
    //   subcatagory: selectedsubcategory,
    //   priority: selectedprioity,
    //   impact: selectedimapct,
    //   catagory: selectedCategory,
    //   description: description,
    //   managernotes: managernote,
    //   aasignmentgroup: assignto,
    //   assignedto: assignvlue,
    //   tittle: tittle,
    //   problemid: problemid,
    //   workaround: {
    //     info: wrokaround,
    //     appliedby: selectedDate3,
    //     userinfo: selectedUser,
    //   },

    //   knownerro: {
    //     info: knownerror,
    //     appliedby: selectedDate2,
    //     userinfo: selectedUser,
    //   },
    // };

    // console.log(data,"data of")
  }
  const customStylesmenu = {
    menu: (provided) => ({
      ...provided,
      // Set your desired max height
    }),
  };

  function getuserinfo(user) {
    console.log(user);
    console.log(alluser);
    let userobject = alluser.filter((x) => {
      if (x.ClientUserId == user.value) {
        return x;
      }
    });

    console.log(userobject, "userobj");
    if (userobject.length > 0) {
      let mid =
        userobject[0].OnboardedBy != null
          ? userobject[0].OnboardedBy
          : "800001";
      axios
        .get(
          `rpc/fun_agilesaasmanagerteamprojdetails?managerid=${mid}&clientid=${userobject[0].ClientId}&teammemberid=eq.${user.value}`
        )
        .then((res) => {
          if (mid == "800001") {
            console.log("wser", userobject);
            setSelectedUserdata([
              {
                teammemberid: user.value,
                firstname: userobject[0].UserFirstname,
                lastname: userobject[0].UserLastname,
              },
            ]);
          } else {
            setSelectedUserdata(res.data);
          }
        })
        .catch((e) => {
          seterrormessage("something wentwrong");
          seterroropen(true);
        });
    }
  }
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Row className="ButtonGrp1 mr-4 ml-2">
        <Col style={{ display: "flex", justifyContent: "start" }}>
          <button
            variant="outline-secondary"
            size="sm"
            className="btn btn-outline-info btn-sm fontdesign mt-3"
            style={{
              fontWeight: "normal",
              width: "160px",
              borderRadius: "8px",
            }}
            onClick={() => setopenmodel(true)}
          >
            <ControlPointIcon fontSize="small" /> Raise a Problem
          </button>
        </Col>
      </Row>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openmodel || tickproblem}
        onClose={() => {
          // alert("hi")
          if (typeof settickproblme === 'function') {
            settickproblme(false)
            setlinkedincidentp([])
          }
          if (typeof setopenmodel === 'function') {
            setopenmodel(false);
            setlinkedincidentp([])
          }


          // setproblem(true)
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <b>Raise a Problem</b>

            <IconButton
              onClick={() => {
                clearform();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div
            className="steps "
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <div className="custom-svg-container ">
              <span
                style={{
                  position: "absolute",
                  top: "25%",
                  left: "25%",
                  color: selectedstate.label == "Created" ? "#FFF" : "#000",
                }}
              >
                Created
              </span>
              <svg
                width="137"
                height="52"
                viewBox="0 0 137 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 49.5V4V3C1 1.89543 1.89543 1 3 1H116.94C117.603 1 118.223 1.32846 118.595 1.87699L136.149 27.7452C136.652 28.4872 136.6 29.4735 136.022 30.1586L118.599 50.7904C118.219 51.2404 117.66 51.5 117.071 51.5H3C1.89543 51.5 1 50.6046 1 49.5Z"
                  fill={selectedstate.label == "Created" ? "#7FA8FF" : "white"}
                  stroke={
                    selectedstate.label == "Created" ? "#7FA8FF" : "#c7cedf"
                  }
                />
              </svg>
            </div>
            <div className="custom-svg-container">
              <span
                style={{
                  position: "absolute",
                  top: "25%",
                  left: "25%",
                  color: selectedstate.label == "Analyzing" ? "#FFF" : "#000",
                }}
              >
                Analyzing
              </span>
              <svg
                width="138"
                height="54"
                viewBox="0 0 138 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M115.46 1H6C3.23858 1 1 3.23858 1 6V6.58999C1 7.73123 1.39042 8.83814 2.1064 9.72685L13.4454 23.8013C14.8456 25.5393 14.9244 27.9948 13.6385 29.819L1.72851 46.714C1.25446 47.3865 1 48.1891 1 49.0119C1 51.2145 2.78555 53 4.98813 53H115.43C116.987 53 118.454 52.275 119.401 51.0389L135.651 29.8072C137.034 27.9993 137.022 25.485 135.621 23.6907L119.401 2.92244C118.453 1.70915 117 1 115.46 1Z"
                  fill={
                    selectedstate.label == "Analyzing" ? "#7FA8FF" : "white"
                  }
                  stroke={
                    selectedstate.label == "Analyzing" ? "#7FA8FF" : "#c7cedf"
                  }
                />
              </svg>
            </div>
            <div className="custom-svg-container">
              <span
                style={{
                  position: "absolute",
                  top: "15%",
                  left: "25%",
                  color: selectedstate.value == "errorknown" ? "#FFF" : "#000",
                }}
              >
                Error <br></br>Known
              </span>
              <svg
                width="138"
                height="54"
                viewBox="0 0 138 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M115.46 1H6C3.23858 1 1 3.23858 1 6V6.58999C1 7.73123 1.39042 8.83814 2.1064 9.72685L13.4454 23.8013C14.8456 25.5393 14.9244 27.9948 13.6385 29.819L1.72851 46.714C1.25446 47.3865 1 48.1891 1 49.0119C1 51.2145 2.78555 53 4.98813 53H115.43C116.987 53 118.454 52.275 119.401 51.0389L135.651 29.8072C137.034 27.9993 137.022 25.485 135.621 23.6907L119.401 2.92244C118.453 1.70915 117 1 115.46 1Z"
                  fill={
                    selectedstate.value == "errorknown" ? "#7FA8FF" : "white"
                  }
                  stroke={
                    selectedstate.value == "errorknown" ? "#7FA8FF" : "#c7cedf"
                  }
                />
              </svg>
            </div>
            <div className="custom-svg-container">
              <span
                style={{
                  position: "absolute",
                  top: "15%",
                  left: "25%",
                  color:
                    selectedstate.value == "RootCauseidentified"
                      ? "#FFF"
                      : "#000",
                }}
              >
                Root Cause <br></br>identified
              </span>
              <svg
                width="138"
                height="54"
                viewBox="0 0 138 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M115.46 1H6C3.23858 1 1 3.23858 1 6V6.58999C1 7.73123 1.39042 8.83814 2.1064 9.72685L13.4454 23.8013C14.8456 25.5393 14.9244 27.9948 13.6385 29.819L1.72851 46.714C1.25446 47.3865 1 48.1891 1 49.0119C1 51.2145 2.78555 53 4.98813 53H115.43C116.987 53 118.454 52.275 119.401 51.0389L135.651 29.8072C137.034 27.9993 137.022 25.485 135.621 23.6907L119.401 2.92244C118.453 1.70915 117 1 115.46 1Z"
                  fill={
                    selectedstate.value == "RootCauseidentified"
                      ? "#7FA8FF"
                      : "white"
                  }
                  stroke={
                    selectedstate.value == "RootCauseidentified"
                      ? "#7FA8FF"
                      : "#c7cedf"
                  }
                />
              </svg>
            </div>
            <div className="custom-svg-container">
              <span
                style={{
                  position: "absolute",
                  top: "25%",
                  left: "25%",
                  color: selectedstate.label == "Closed" ? "#FFF" : "#000",
                }}
              >
                Closed
              </span>
              <svg
                width="119"
                height="54"
                viewBox="0 0 119 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M112.9 1H6C3.23858 1 1 3.23858 1 6V6.58999C1 7.73123 1.39042 8.83814 2.1064 9.72685L13.4454 23.8013C14.8456 25.5393 14.9244 27.9948 13.6385 29.819L1.72851 46.714C1.25446 47.3865 1 48.1891 1 49.0119C1 51.2145 2.78555 53 4.98813 53H112.9C115.661 53 117.9 50.7614 117.9 48V6C117.9 3.23858 115.661 1 112.9 1Z"
                  fill={selectedstate.label == "Closed" ? "#7FA8FF" : "white"}
                  stroke={
                    selectedstate.label == "Closed" ? "#7FA8FF" : "#c7cedf"
                  }
                />
              </svg>
            </div>
          </div>

          <Container className="mt-4 mb-4">
            {/* First Row */}
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="title">
                  <Form.Label className="SprintlabelStyle">
                    Title <label className="text-danger"> *</label>
                  </Form.Label>
                  <Form.Control
                    value={tittle}
                    className={
                      !tittleerror
                        ? "SprintFormFields"
                        : "SprintFormFields error"
                    }
                    type="text"
                    onChange={(e) => settittle(e.target.value)}
                    placeholder="Enter problem title"
                  />
                  {tittleerror && (
                    <p className="text-danger">please enter valid title</p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* Second Row */}
            <Row>
              {/* <Col>
                <Form.Group controlId="name">
                  <Form.Label className="SprintlabelStyle">Problem</Form.Label>
                  <Form.Control
                    value={problemid}
                    disabled={true}
                    onChange={(e) => setproblemid(e.target.value)}
                    className={"SprintFormFields"}
                    type="text"
                    placeholder="Enter name"
                  />
                </Form.Group>
              </Col> */}
              {/* <Col>
                <Form.Group controlId="category">
                  <Form.Label className="SprintlabelStyle">State</Form.Label>
                  <Select
                    styles={!stateerror ? customStyles : customStyleserror}
                    options={stateofproblem}
                    value={selectedstate}
                    onChange={(selectedOption) =>
                      setselectedstate(selectedOption)
                    }
                  />
                </Form.Group>
                {stateerror && (
                  <p className="text-danger">please select the state</p>
                )}
              </Col> */}
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="genus">
                  <Form.Label className="SprintlabelStyle">
                    Raised By <label className="text-danger"> *</label>
                  </Form.Label>
                  <Select
                    styles={true ? customStyles : customStyleserror}
                    options={raiseoption}
                    value={selectedraise}
                    onChange={(selectedOption) => {
                      setSelectedraise(selectedOption);
                      if (selectedOption.value == "someone") {
                        setSelectedraiseother(true);
                      } else {
                        setSelectedraiseother(false);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="category">
                  <Form.Label className="SprintlabelStyle">
                    State <label className="text-danger"> *</label>
                  </Form.Label>
                  <Select
                    styles={!stateerror ? customStyles : customStyleserror}
                    options={stateofproblem}
                    value={selectedstate}
                    onChange={(selectedOption) => {
                      setselectedstate(selectedOption);
                      if (selectedOption.value == "errorknown") {
                        setisknownerror(true);
                        setisRootcauseidentified(false);
                      } else if (
                        selectedOption.value == "RootCauseidentified" ||
                        selectedOption.value == "closed"
                      ) {
                        setisknownerror(true);
                        setisRootcauseidentified(true);
                      } else {
                        setisknownerror(false);
                        setisRootcauseidentified(false);
                      }
                    }}
                  />
                </Form.Group>
                {stateerror && (
                  <p className="text-danger">please select the state</p>
                )}
              </Col>
              {/* <Col>
                <Form.Group controlId="subgenus">
                  <Form.Label className="SprintlabelStyle">Type</Form.Label>
                  <Select
                    styles={!typeerror ? customStyles : customStyleserror}
                    options={type}
                    value={selectedtype}
                    onChange={(selectedOption) =>
                      setselectedtype(selectedOption)
                    }
                  />
                </Form.Group>
                {typeerror && (
                  <p className="text-danger">please select the type</p>
                )}
              </Col> */}
            </Row>
            {selectedraiseother ? (
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="name">
                    <Form.Label className="SprintlabelStyle">
                      search for person
                    </Form.Label>

                    <Select
                      options={options}
                      onChange={(selectedOption) => {
                        getuserinfo(selectedOption);
                        setSelectedUser(selectedOption);
                      }}
                      value={selectedUser}
                      onInputChange={handleSearch}
                      isSearchable
                      placeholder="Search and select a user..."
                    />
                    {selectedUserdata && (
                      <div className="mt-1">
                        <h5 className="mb-1">
                          <b>Selected User</b>
                        </h5>
                        <div
                          className="cardofuser"
                          style={{
                            display: "flex",
                            padding: ".5rem",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                            maxWidth: "350px",
                            borderRadius: 5,
                          }}
                        >
                          {console.log(selectedUserdata[0], "selected user")}
                          <div className="image">
                            <Avatar
                              className=" mr-1"
                              alt=""
                              style={{
                                width: "80px", // set the desired width
                                height: "80px", // set the desired height
                              }}
                              src={
                                allImage?.length > 0 && allImage?.filter(
                                  (x) =>
                                    x.teammemberid ==
                                    selectedUserdata[0]?.teammemberid
                                )?.[0]?.image != undefined ? allImage?.filter(
                                  (x) =>
                                    x.teammemberid ==
                                    selectedUserdata[0]?.teammemberid
                                )?.[0]?.image : Unassigned
                              }
                            />
                          </div>
                          <div className="infoofuser">
                            <p
                              style={{
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                                marginBottom: "2px",
                              }}
                            >
                              {selectedUserdata[0].firstname
                                ? selectedUserdata[0].firstname
                                : " " + " " + selectedUserdata[0].lastname
                                  ? selectedUserdata[0].lastname
                                  : ""}
                            </p>
                            {selectedUserdata[0].projectrole?.RoleName ? (
                              <span
                                style={{
                                  backgroundColor: "#5250E5",
                                  color: "white",
                                  borderRadius: "1000px",
                                  fontSize: "12px",
                                  padding: ".1rem .3rem",
                                }}
                              >
                                {selectedUserdata[0].projectrole?.RoleName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                {/* <Col>
                <Form.Group controlId="subgenus">
                  <Form.Label className="SprintlabelStyle">Type</Form.Label>
                  <Select
                    styles={customStyles}
                    options={type}
                    value={selectedtype}
                    onChange={(selectedOption) =>
                      setselectedtype(selectedOption)
                    }
                  />
                </Form.Group>
              </Col> */}
              </Row>
            ) : null}

            {/* Fourth Row */}
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="nameGroup">
                  <Form.Label className="SprintlabelStyle">
                    Category <label className="text-danger"> *</label>
                  </Form.Label>
                  <Select
                    styles={!catagoryerror ? customStyles : customStyleserror}
                    options={categoryOptions}
                    value={selectedCategory}
                    onChange={(selectedOption) => {
                      setSelectedCategory(selectedOption);
                      console.log(selectedOption);
                      setselectedsubcategory(null);
                    }}
                  />
                  {catagoryerror && (
                    <p className="text-danger">please select the category</p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="subGroup">
                  <Form.Label className="SprintlabelStyle">
                    Sub category <label className="text-danger"> *</label>
                  </Form.Label>
                  <Select
                    styles={
                      !subcatagoryerror ? customStyles : customStyleserror
                    }
                    options={
                      selectedCategory != null
                        ? categorydata
                          .filter(
                            (x) =>
                              x.IncidentCategoryId == selectedCategory.value
                          )
                          .map((x) => x.SubCategoryDetails)[0]
                          .map((x) => {
                            return {
                              label: x.SubCategoryName,
                              value: x.SubCategoryCode,
                            };
                          })
                        : []
                    }
                    value={selectedsubcategory}
                    onChange={(selectedOption) =>
                      setselectedsubcategory(selectedOption)
                    }
                  />
                  {subcatagoryerror && (
                    <p className="text-danger">please select the subcategory</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="nameGroup">
                  <Form.Label className="SprintlabelStyle">
                    Assigment group <label className="text-danger"> *</label>
                  </Form.Label>
                  <Select
                    styles={!assignvlueerror ? customStyles : customStyleserror}
                    options={assignmentoption}
                    value={assignmentoption?.filter(
                      (e) => e.value == assignvlue
                    )}
                    onChange={(e) => {
                      setassignmentgroup(e.label);
                      setassignvalue(e.value);

                      setAssignedtooption(
                        e.users?.map((e) => {
                          return {
                            value: e.UserId,
                            label: e.Firstname + " " + e.Lastname,
                          };
                        })
                      );
                    }}
                  />
                  {assignvlueerror && (
                    <p className="text-danger">
                      please select assignment group
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="subGroup">
                  <Form.Label className="SprintlabelStyle">
                    Assigned to
                  </Form.Label>
                  <Select
                    styles={customStyles}
                    options={Assignedtooption}
                    value={Assignedtooption?.filter((e) => e.value == assignto)}
                    onChange={(e) => {
                      setassignto(e.value);
                      setassignedtodet(e.label);
                    }}
                  />
                  {/* {assigntoerror && (
                    <p className="text-danger">please select assignment user</p>
                  )} */}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="nameGroup">
                  <Form.Label className="SprintlabelStyle">
                    Priority <label className="text-danger"> *</label>
                  </Form.Label>
                  <Select
                    styles={!priorityerror ? customStyles : customStyleserror}
                    options={PriorityOption}
                    value={selectedprioity}
                    onChange={(selectedOption) =>
                      setselectedprioity(selectedOption)
                    }
                  />
                  {priorityerror && (
                    <p className="text-danger">please select the priority</p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="subGroup">
                  <Form.Label className="SprintlabelStyle">
                    Imapct <label className="text-danger"> *</label>
                  </Form.Label>
                  <Select
                    styles={!impacterror ? customStyles : customStyleserror}
                    className="SprintFormFieldsnew"
                    options={Impactoption}
                    value={selectedimapct}
                    onChange={(selectedOption) =>
                      setselectedimapct(selectedOption)
                    }
                  />
                  {impacterror && (
                    <p className="text-danger">please select the impact</p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* Fifth Row */}
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="description">
                  <Form.Label className="SprintlabelStyle">
                    Description <label className="text-danger"> *</label>
                  </Form.Label>
                  <Form.Control
                    className={
                      !descriptionerror
                        ? "SprintFormFields"
                        : "SprintFormFields error"
                    }
                    as="textarea"
                    value={description}
                    rows={3}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Enter description"
                  />
                  {descriptionerror && (
                    <p className="text-danger">
                      please enter valid desctiption
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Tabs
              defaultActiveKey="Releatedincidents"
              id="justify-tab-example"
              className="horizontal-tabs mt-3 mb-4"
              variant="tabs"
            >
              <Tab
                eventKey="Releatedincidents"
                title="Releated incidents"
                className="headings"
                defaultActiveKey="Releatedincidents"
              >
                <div style={{ padding: "10px" }}>
                  <Row className="mb-4">
                    <Col className="col-md-6">
                      <Form.Group controlId="description">
                        <Form.Label className="SprintlabelStyle">
                          link incidents{" "}
                          <label className="text-danger"> *</label>
                        </Form.Label>
                        <Select
                          isMulti
                          options={lindedoptionsapidata}
                          value={linkedincident}
                          onChange={handleChangelinkedind}
                          placeholder="Search and select options..."
                          isSearchable
                          closeMenuOnSelect={false}
                          styles={customStylesmenu}
                        // components={{ Option: CheckboxOption }}
                        />
                      </Form.Group>
                      {linkincidenterror ? (
                        <p className="text-danger">please link incident</p>
                      ) : null}
                    </Col>
                  </Row>
                  {linkedincident.length > 0 ? (
                    <MaterialTable
                      style={{ zIndex: 0 }}
                      title={"Incident list"}
                      columns={columns}
                      data={linkedincident}
                      options={{
                        // pageSize: 10, // Set the initial page size (you can change this value)
                        // pageSizeOptions: [10, 20, 30], // Set the available page size options
                        search: false, // Enable/disable search functionality
                        sorting: false, // Enable/disable column sorting
                      }}
                    />
                  ) : (
                    <div style={{ paddingBottom: "100px" }}></div>
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="Workaround"
                title={"Work around"}
                className="headings ml-2"
              >
                <div
                  style={{
                    padding: "10px",
                    // paddingBottom: "10%",
                  }}
                >
                  {/* First Row */}
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      id="known"
                      type="checkbox"
                      checked={isknownerror}
                      onChange={() => setisknownerror(!isknownerror)}
                      style={{
                        marginRight: "5px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <label
                      for="known"
                      style={{
                        marginLeft: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginBottom: 0,
                      }}
                    >
                      Is Known Error
                    </label>
                  </div>

                  {/* Second Row */}
                  {isknownerror && (
                    <>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="descriptionWorkaround">
                            <Form.Label className="SprintlabelStyle">
                              Work around
                            </Form.Label>
                            <Form.Control
                              value={wrokaround}
                              onChange={(e) => setwrokaround(e.target.value)}
                              className={
                                // !wrokaroundvalid
                                "SprintFormFields"
                                // : "SprintFormFields error"
                              }
                              as="textarea"
                              rows={3}
                              placeholder="Enter  Work around"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Third Row */}
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="nameGroup">
                            <Form.Label className="SprintlabelStyle">
                              Applied group
                            </Form.Label>
                            <Select
                              styles={
                                // !assignvlueerror
                                customStyles
                                // : customStyleserror
                              }
                              options={assignmentoption}
                              value={assignmentoption?.filter(
                                (e) => e.value == workaroundgroupvalue
                              )}
                              onChange={(e) => {
                                setworkaroundgroup(e.label);
                                setworkaroundgroupvalue(e.value);

                                setAssignedtooption(
                                  e.users?.map((e) => {
                                    return {
                                      value: e.UserId,
                                      label: e.Firstname + " " + e.Lastname,
                                    };
                                  })
                                );
                              }}
                            />
                            {/* {assignvlueerror && (
                              <p className="text-danger">
                                please select assignment group
                              </p>
                            )} */}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="subGroup">
                            <Form.Label className="SprintlabelStyle">
                              Applied By
                            </Form.Label>
                            <Select
                              styles={
                                // !assigntoerror
                                customStyles
                                // : customStyleserror
                              }
                              options={Assignedtooption}
                              value={Assignedtooption?.filter(
                                (e) => e.value == workarounduservalue
                              )}
                              onChange={(e) => {
                                setworkarounduservalue(e.value);
                                setworkarounduser(e.label);
                              }}
                            />
                            {/* {assigntoerror && (
                              <p className="text-danger">
                                please select assignment user
                              </p>
                            )} */}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          style={{ paddingBottom: 0, marginBottom: 0 }}
                        >
                          <Form.Label className="SprintlabelStyle">
                            Apply Until
                          </Form.Label>
                          <div className="test">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                InputProps={{
                                  style: {
                                    outlineColor: "red",
                                  },
                                }}
                                classes={{
                                  root: classes.datePickerInput, // Apply the custom styles to the root element
                                }}
                                className="datepickerDesign"
                                value={selectedDate2}
                                onChange={(date) => handleDateChange(date)}
                                format="dd-MM-yyyy"
                                views={["year", "month", "date"]}
                                disablePast
                                required
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </Form.Group>
                        <col></col>
                      </Row>
                    </>
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="Rootcause"
                title="Root cause"
                className="headings ml-2"
              >
                <div style={{ padding: "10px" }}>
                  {/* First Row */}
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      id="rootcause"
                      type="checkbox"
                      checked={isRootcauseidentified}
                      onChange={() =>
                        setisRootcauseidentified(!isRootcauseidentified)
                      }
                      style={{
                        marginRight: "5px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <label
                      for="rootcause"
                      style={{
                        marginLeft: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginBottom: 0,
                      }}
                    >
                      Is Root Cause identifed
                    </label>
                  </div>

                  {/* Second Row */}
                  {isRootcauseidentified && (
                    <>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="descriptionrootcause">
                            <Form.Label className="SprintlabelStyle">
                              Root cause
                            </Form.Label>
                            <Form.Control
                              value={knownerror}
                              onChange={(e) => setknownerror(e.target.value)}
                              className={
                                // !knownerrorvalid
                                "SprintFormFields"
                                // : "SprintFormFields error"
                              }
                              as="textarea"
                              rows={3}
                              placeholder="Enter Root cause"
                            />
                            {knownerrorvalid && (
                              <p className="text-danger">
                                please enter valid rootcause
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="nameGroup">
                            <Form.Label className="SprintlabelStyle">
                              Identified group
                            </Form.Label>
                            <Select
                              styles={
                                // !assignvlueerror
                                customStyles
                                // : customStyleserror
                              }
                              options={assignmentoption}
                              value={assignmentoption?.filter(
                                (e) => e.value == rootcausegroupvalue
                              )}
                              onChange={(e) => {
                                setrootcausegroup(e.label);
                                setrootcausegroupvalue(e.value);

                                setAssignedtooption(
                                  e.users?.map((e) => {
                                    return {
                                      value: e.UserId,
                                      label: e.Firstname + " " + e.Lastname,
                                    };
                                  })
                                );
                              }}
                            />
                            {/* {assignvlueerror && (
                              <p className="text-danger">
                                please select assignment group
                              </p>
                            )} */}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="subGroup">
                            <Form.Label className="SprintlabelStyle">
                              Identified By
                            </Form.Label>
                            <Select
                              styles={
                                // !assigntoerror
                                customStyles
                                // : customStyleserror
                              }
                              options={Assignedtooption}
                              value={Assignedtooption?.filter(
                                (e) => e.value == rootcauseuservalue
                              )}
                              onChange={(e) => {
                                setrootcauseuservalue(e.value);
                                setrootcauseuser(e.label);
                              }}
                            />
                            {/* {assigntoerror && (
                              <p className="text-danger">
                                please select assignment user
                              </p>
                            )} */}
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* Third Row */}
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="title">
                          <Form.Label className="SprintlabelStyle">
                            Releated Ticket References
                          </Form.Label>
                          <Form.Control
                            className="SprintFormFields"
                            type="text"
                            placeholder="Enter reference id"
                            value={refno}
                            onChange={(e) => {
                              setrefno(e.target.value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          style={{ paddingBottom: 0, marginBottom: 0 }}
                        >
                          <Form.Label className="SprintlabelStyle">
                            Identified Date
                          </Form.Label>
                          <div className="test">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                InputProps={{
                                  style: {
                                    outlineColor: "red",
                                  },
                                }}
                                classes={{
                                  root: classes.datePickerInput, // Apply the custom styles to the root element
                                }}
                                className="datepickerDesign"
                                value={selectedDate3}
                                onChange={(date) => handleDateChange3(date)}
                                format="dd-MM-yyyy"
                                views={["year", "month", "date"]}
                                disablePast
                                required
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </Form.Group>
                      </Row>
                    </>
                  )}
                </div>
              </Tab>
              <Tab eventKey="Notes" title="Notes" className="headings ml-2">
                <div style={{ padding: "10px" }}>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group controlId="description">
                        <Form.Label className="SprintlabelStyle">
                          problem notes{" "}
                          <label className="text-danger"> *</label>
                        </Form.Label>
                        <Form.Control
                          className={
                            !managernoterror
                              ? "SprintFormFields"
                              : "SprintFormFields error"
                          }
                          as="textarea"
                          rows={3}
                          value={managernote}
                          onChange={(e) => setmanagernote(e.target.value)}
                          placeholder="Enter problem notes"
                        />
                        {managernoterror && (
                          <p className="text-danger">
                            please enter problem notes
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </Container>
        </DialogContent>
        <DialogActions style={{ borderTop: "1px solid #3f3b3b17" }}>
          <button
            style={{
              width: 100,
              height: 36,
              borderRadius: 5,
              color: "#5250E5",
              background: "#fff",
              border: "1px solid #5250E5",
            }}
            onClick={() => {
              clearform();
            }}
          >
            Cancel
          </button>
          <button
            style={{
              width: 100,
              height: 36,
              borderRadius: 5,
              background: "#5250E5",
              color: "#fff",
              opacity: buttondisable ? ".9" : 1,
            }}
            disabled={buttondisable}
            onClick={() => validationsubmit()}
          >
            Submit{" "}
            {buttondisable ? <CircularProgress size={10} color="#fff" /> : null}
          </button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={messageopen}
        autoHideDuration={1500}
        onClose={() => {
          setbuttondisable(false);

          setmessageopen(false);
        }}
      >
        <Alert
          onClose={() => {
            setmessageopen(false);
          }}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green !important",
            color: "white !important",
          }}
        >
          Problem created successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={erroropen}
        autoHideDuration={2000}
        onClose={() => {
          seterroropen(false);
        }}
      >
        <Alert
          onClose={() => {
            seterroropen(false);
          }}
          severity="error"
          sx={{
            width: "100%",
            backgroundColor: "red !important",
            color: "white !important",
          }}
        >
          {errormessage}
        </Alert>
      </Snackbar>
      {
        openmodel == true ?
          <RaiseATicket openmodel={openmodel} setopenmodel={setopenmodel} /> : null
      }
    </div>

  );
};

export default Problem;
