import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import PatchedPagination from "../../../PatchedPagination";
import axios from "../../../axios";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";



const Report = ({ rowData }) => {
  const { openProCreate, KanbanBoardData, projectdetails } = useSelector((state) => state.user);
  const Columns = [
    { title: "Name", field: "empname" },
    // { title: "Project name", field: "projectname" },
    { title: "Start Date", field: "projectstartdate" },
    { title: "End Date", field: "projectenddate" },
    {
      title: "Allocated %",
      field: "AllocPercentage",
      cellStyle: { fontSize: "12px", padding: "0px 30px" },
    },
    { title: "Status", field: "iscurrent" },
    { title: "Role", field: "ProjectRole.RoleName" },
  ];


  const [projectdata, setProjectdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employee, setemployee] = useState([]);
  let id = JSON.parse(localStorage.getItem("clientid"));
  const ID = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`subscription_client_users?ClientId=eq.` + id)
      .then((emp) => {
        setemployee(emp.data);
        axios
          .get("agilesaas_project_mapping?ProjectCode=eq." + rowData.id)
          .then((res) => {
            let c = res.data.map((e) => {
              let fullname = emp?.data?.find(
                (a) => a?.ClientUserId == e?.TeamMemberId
              );
              return {
                ...e,

                name: fullname?.UserFirstname + " " + fullname?.UserLastname,
              };
            });


            setProjectdata(c);
            setLoading(true);
          });
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  const count = loading
    ? projectdata
      .filter((x) => x.IsCurrent === "Y")
      .map((x) => {
        let temp = 0;
        return (temp =
          temp + x.AllocPercentage <= 50
            ? 0.5
            : x.AllocPercentage >= 51
              ? 1
              : x.AllocPercentage);
      })
    : 0;

  function sum(obj) {
    var sum = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el]);
      }
    }
    return sum;
  }

  let startdate = projectdata?.map((r) => moment(r.ProjectStartDate).format("DD MMM YYYY"))

  var summed = sum(count);

  return (
    <div>
      <p> Employees currently active - {summed} FTEs</p>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      ></link>
      <MaterialTable
        title={""}
        columns={Columns.map((e) => e)}
        data={
          loading && employee.length == 0 ? (
            <>No Data </>
          ) :
            (

              projectdata.map((e, i) => {
                return {
                  ...e,
                  projectenddate:
                    e.projectenddate !== null
                      ? moment(e.ProjectEndDate).format("DD MMM YYYY")
                      : null,
                  projectstartdate:
                    e.projectstartdate !== null
                      ? moment(e.ProjectStartDate).format("DD MMM  YYYY")
                      : null,

                  projectname: projectdetails
                    ?.filter((e) => e.ProjectId === rowData.ProjectId)
                    ?.map((e) => e.ProjectName),
                  // empname: employee?.find(
                  //   (a) => a.TeamMemberId == e.TeamMemberId
                  // ),
                  empname: e.name,
                  allocpercentage:
                    e.IsCurrent === "N" ? "0" + "%" : e.IsCurrent + "%",
                  iscurrent:
                    e.IsCurrent === "Y"
                      ? "Active"
                      : e.IsCurrent === "N"
                        ? "Released"
                        : null,
                };
              })
            )
        }
        components={{
          Pagination: PatchedPagination,
        }}
        options={{
          search: true,
          actionsColumnIndex: -1,
          exportAllData: true,
          exportButton: true,
          columnResizable: true,
          filtering: false,
          sorting: true,
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 30, 40],

          headerStyle: {
            whiteSpace: 'nowrap',
            // backgroundColor: "#8FD6E1",
            // color: "#03256C",
            width: "1000px",
            // textAlign: "center",

            padding: "2px",
            fontWeight: "700",
            fontSize: "small",
            // backgroundColor: "#eef2f7",
            color: "black",
          },
          cellStyle: {
            textAlign: "left",
            fontSize: "12px",
            padding: "2px",
            color: "#6c757d",
          },
          rowStyle: (rowdata) => {
            if (rowdata.tableData.id % 2 != 0) {
              // return { backgroundColor: "#cddef085" };
            }
          },
        }}
      />
    </div>
  );
};

export default Report;
