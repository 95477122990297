import { Card, formatMs, TextField } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useFormik, Field } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import DateFnsUtils from "@date-io/date-fns";
import axios from "../../../axios";
import ReactImageFallback from "react-image-fallback";
import Asset from "../../../assets/images/asset request image.png"
import moment from "moment";
import Button from "@mui/material/Button";
import "./Request.css";
import Select from "react-select";
import * as yup from "yup";

import ITRequestTable from "./RequestTable";

function RequestFormnew({
  RefreshDatas,
  setGeneric,
  setAccess,
  setAssetsform,
}) {
  const teamid = JSON.parse(localStorage.getItem("Empdetails"));
  const onbaord = teamid[0].OnboardedBy == "800001" ? 1 : teamid[0].OnboardedBy;
  const ID = teamid[0].ClientUserId;
  const clientid = JSON.parse(localStorage.getItem("clientid"));
  const clientdetails = JSON.parse(
    localStorage.getItem("Orgsubscriptiondetails")
  );
  const role = localStorage.getItem("Rolecode") == "undefined" ? "" : JSON.parse(localStorage.getItem("Rolecode"));

  const [selectdate, setSelectdate] = useState("");
  const [date, setDate] = useState(new Date());
  const [itrequestemp_detais, setItrequestemp_details] = useState([]);
  const [data, setData] = useState("");
  const [assettypes, setAssetypes] = useState([]);
  const [serialnumber, setSerialnumber] = useState([]);
  const [assettype, setAssetype] = useState([]);
  const [description, setDescription] = useState([]);
  const [itEmp, setItemp] = useState([]);
  const [itrequest_user, setItrequestuser] = useState([]);
  const [errordata, setErrorData] = useState(false);

  const [reclickbehalf, setReclickbehalf] = useState(false);
  const [reclicknewonwer, setReclicknewowner] = useState(false);
  const [mapping, setmapping] = useState([]);
  let option = [
    { value: "Y", label: "Self" },
    { value: "N", label: "Someone" },
  ];

  function RefreshData() {
    axios
      .get("agilesaas_itrequest_reason_master")
      .then((res) => setAssetype(res.data));

    let ids =
      formik.values.ReasonCode !== "New" && formik.values.IsSelfRequest == "N"
        ? formik.values.request_For
        : ID;

    axios
      .get("rpc/fun_assetreport?empid=eq." + 100021)
      .then((res) => {
        setData(res.data);
        setAssetypes(res.data);
      })
      .catch((e) => setErrorData(e));

    axios
      .get("agilesaas_itrequest_itemtype_master")
      .then((res) => setDescription(res.data));

    axios
      .get(`rpc/fun_empitrequests?empid=${100021}`)
      .then((res) => setItrequestuser(res.data))
      .catch((e) => console.log(e));

    setReclickbehalf(false);
    setReclicknewowner(false);
    setItrequestemp_details([]);
    setSerialnumber([]);
    setItemp([]);
  }
  const customStylesd = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // background: "#f3f3ff",
      heigth: "150px",
      width: "470px",
      // marginLeft:"30px",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const customStyles1 = {
    control: (base, state) => ({
      ...base,
      // background: "#f3f3ff",
      // marginLeft:"30px",
      // width:"465px",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  function close() {
    setGeneric(false);
    setAccess(false);
    setAssetsform(false);
  }
  function restvalues() {
    formik.setValues({
      IsSelfRequest: "",
      ReasonCode: "",
      Asset_type: "",
      Justification: "",
      New_owner: "",
      request_For: "",
    });
  }
  function GetEmpDetails() {
    if (formik.values.New_owner === "") {
      setItemp([]);
      setSerialnumber([]);
      setReclicknewowner(false);
      return false;
    }
    if (formik.values.New_owner !== "") {
      setReclicknewowner(true);
      axios
        .get(
          "subscription_client_users?OfficialEmail=eq." +
            formik.values.New_owner +
            "&OnboardedBy=eq." +
            onbaord
        )
        .then((res) => {
          setItemp(res.data);
          setSerialnumber(res.data);
          axios
            .get(
              `rpc/fun_agilesaasmanagerteamprojdetails?managerid=` +
                res.data[0]?.OnboardedBy +
                `&clientid=` +
                clientid +
                `&teammemberid=eq.` +
                res.data[0]?.TeamMemberId
            )
            .then((response) => setmapping(response.data));
        });
      return true;
    }
  }
  let roledata = mapping?.map((e) => e.projectrole.RoleName)[0];
  function GetRequestEmpDetails() {
    if (formik.values.request_For == ID) {
      alert("Request User ID and Login user Id Should not be a same ");
      return false;
    }
    if (formik.values.request_For === "") {
      setItrequestemp_details(false);
      setReclickbehalf(false);
      return false;
    }

    if (formik.values.request_For !== "" && formik.values.request_For != ID) {
      setReclickbehalf(true);

      axios
        .get(
          "subscription_client_users?OfficialEmail=eq." +
            formik.values.request_For +
            "&OnboardedBy=eq." +
            onbaord
        )
        .then((res) => {
          setItrequestemp_details(res.data);
          axios
            .get(
              `rpc/fun_agilesaasmanagerteamprojdetails?managerid=` +
                res.data[0]?.OnboardedBy +
                `&clientid=` +
                clientid +
                `&teammemberid=eq.` +
                res.data[0].TeamMemberId
            )
            .then((response) => setmapping(response.data));
        })

        .catch((e) => console.log(e));

      return true;
    }
  }

  useEffect(() => {
    RefreshData();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let fullDate = `${year}-${month}-${day}`;
    setSelectdate(fullDate);

    axios
      .get(
        "subscription_client_users?OfficialEmail=eq." +
          formik.values.request_For +
          "&ClientId=eq." +
          clientid
      )
      .then((res) => {
        setItrequestemp_details(res.data);
        axios
          .get(
            `rpc/fun_agilesaasmanagerteamprojdetails?managerid=` +
              res.data[0]?.OnboardedBy +
              `&clientid=` +
              clientid +
              `&teammemberid=eq.` +
              res.data[0]?.ClientUserId
          )
          .then((response) => setmapping(response.data));
      })
      .catch((e) => console.log(e));
  }, [date]);

  const formik = useFormik({
    initialValues: {
      IsSelfRequest: "Y",
      ReasonCode: "",
      Asset_type: "",
      Justification: "",
      New_owner: "",
      request_For: "",
    },
    validationSchema: yup.object({
      IsSelfRequest: yup.string().required("This field is required"),
      Justification: yup.string().required("this field is required"),
      ReasonCode: yup.string().required("this field is required"),
      Asset_type: yup.string().required("this field is required"),
      request_For: yup.string().when("IsSelfRequest", {
        is: (IsSelfRequest) => IsSelfRequest === "N",
        then: yup.string().required("Field is required"),
      }),
      New_owner: yup.string().when("ReasonCode", {
        is: (ReasonCode) => ReasonCode == "Transfer",
        then: yup.string().required("Field is required"),
      }),
    }),

    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: (e, { resetForm }) => {
      if (formik.values.IsSelfRequest === "N") {
        if (reclickbehalf == false) {
          alert("please click the fetch and verify user details");
          return false;
        }
        if (!GetRequestEmpDetails()) {
          return;
        }
      }

      if (formik.values.ReasonCode == "Transfer") {
        if (reclicknewonwer == false) {
          alert("please click the fetch and verify user details");
          return false;
        }
        if (!GetEmpDetails()) {
          return;
        }
      }

      let aa = serialnumber.map((u) => {
        return [u.Firstname, u.emplevel, u.designation];
      });

      let Assetfulldetails = assettypes.map((y) => {
        return [y.assetid, y.assettype, y.serialnumber];
      });
      function clear() {
        //  formik.initialValues
        formik.setFieldValue("IsSelfRequest", "");
      }
      // e.Asset_type === "Laptop"
      //   ? 2101
      //   : e.Asset_type === "Dongle"
      //     ? 2102
      //     : e.Asset_type === "Support Phone"
      //       ? 2103
      //       : null

      // e.ReasonCode === "Replacement"
      //   ? 1103
      //   : e.ReasonCode === "New"
      //     ? 1101
      //     : e.ReasonCode === "Transfer"
      //       ? 1102
      //       : null,

      let assetID = description
        .filter((b) => b.Description == e.Asset_type)
        .map((h) => h.ITRequestItemTypeId);
      let reasonID = assettype
        .filter((l) => l.Description == e.ReasonCode)
        .map((c) => c.ITRequestReasonId);

      const User_requestData = {
        RequestType: "ASR",
        RequestedBy: parseInt(ID),
        ClientId: clientid,
        ClientDetails: {
          Company: clientdetails[0].ClientDetails.Company,
          ContactName: clientdetails[0].ClientDetails.ContactName,
          ContactDesignation: clientdetails[0].ClientDetails.ContactDesignation,
          ContactEmail: clientdetails[0].ClientDetails.ContactEmail,
          ContactPhone: clientdetails[0].ClientDetails.ContactPhone,
        },
        // ProjectId: parseInt(1),
        AssignedGroup: "ITSM",

        AssignedTo: teamid[0].OnboardedBy,
        DepartmentCode: null,
        SubmittedDate: moment().format("yyyy-MM-DD"),
        ReasonCode: reasonID[0],
        ItemType: assetID[0],
        UserJustification: e.Justification,
        NewAssignee: e.ReasonCode === "Transfer" ? parseInt(e.New_owner) : null,
        ExpectedDate: selectdate,
        IsRequireApproval: null,
        ApprovalLevel1: 100005,
        Level1ApprovedDate: null,
        ApprovalLevel1Remarks: null,
        IsApprovedByLevel1: null,
        ApprovalLevel2: 600001,
        Level2ApprovedDate: null,
        ApprovalLevel2Remarks: null,
        IsApprovedByLevel2: null,
        IsFullyApproved: null,
        IsOnhold: null,
        IsWithdrawn: null,
        WithdrawnDate: null,
        WithdrawnRemarks: null,
        Attachments: null,
        FulfillmentReference: null,
        IsRequestFulfilled: null,
        IsActive: null,
        Status: null,
        UpdateNotes: [
          {
            NoteSno: null,
            DateTime: null,
            NoteUpdatedBy: null,
            NoteUpdatedByFN: null,
            NotesDetails: null,
          },
        ],
        ReferenceDetails: {
          // AssetType: e.ReasonCode !== "New" ? Assetfulldetails[0][1] : null,
          // AssetId: e.ReasonCode !== "New" ? Assetfulldetails[0][0] : null,
          // AssetSerial: e.ReasonCode !== "New" ? Assetfulldetails[0][2] : null,
        },
        NewAssigneeDetails: {
          EmpName: e.ReasonCode === "Transfer" ? aa[0][0] : null,
          EmpLevel: e.ReasonCode === "Transfer" ? aa[0][1] : null,
          EmpDesig: e.ReasonCode === "Transfer" ? roledata : null,
        },
        IsSelfRequest: e.IsSelfRequest,
        RaisingOnBehalfDetails:
          e.IsSelfRequest === "N"
            ? parseInt(itrequestemp_detais[0]?.TeamMemberId)
            : null,
        IsAcknowledged: null,
        SentForVerification: null,
        IsReworkRequired: null,
      };

      // let notificationData = {
      //   CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
      //   CreatedBy: user_details.empid,
      //   NotifyTo: 500001,
      //   AudienceType: "Individual",
      //   Priority: "High",
      //   Subject: "Request for Asset",
      //   Description: "Requested for Asset by  " + user_details.empid,
      //   IsSeen: "N",
      //   Status: "New",
      // };

      axios
        .post(
          `agilesaas_itrequest_details?RequestedBy=eq.${ID}`,
          User_requestData
        )
        .then((_res) => {
          alert("Asset Request Submitted");

          RefreshData();
          RefreshDatas();
        })
        .then((_res) => {
          // axios
          //     .post("notification?NotifyTo=eq." + 500001, notificationData)
          //     .then((res) => console.log(res))
          //     .catch((error) => console.log(error));
        })
        .catch((er) => console.log(er));

      resetForm();

      restvalues();
      close();
    },
  });

  return (
    <div className="container">
      <div className="row">
      <div className="col-md-6">
       <div className="mb-2">
        <label className="text-dark" style={{fontWeight:"bold"}}>Assets Description </label>
        </div>
       
        <Row>
          
      
          <ReactImageFallback
                                  src={Asset}
                                  
                                  
                                  
                             
                                  initialImage="loader.gif"
                                  alt="cool image should be here"
                                 
                                   style={{ height: "200px", // Adjust the height to your desired size
                                   width: "200px", borderRadius: "50%" ,fontSize:"10px"}}
                                />
                                
                                <Col>
                                
                              
                              <div>
                              <label>
  <label className="labelStyle mr-1" style={{ fontWeight: "bold" }}>Formal Appeals: </label>
  Employees or users formally request IT assets.
  </label>

                              
                              </div>
                              <div>
                              <label>
  <label className="labelStyle mr-1" style={{ fontWeight: "bold" }}>Resource Types: </label>
  These requests can encompass IT-related resources, including hardware (e.g., computers, monitors, smartphones) and software (e.g., applications, licenses).
</label>
                          
                              </div>
  
                                </Col>
         </Row>
         </div>
         <div className="col-md-6">
      <Form
        className="backbg"
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Row>
          <div className="d-flex justify-content-space between ">
            <div>
              <label>
                <label
                  className="labelStyle"
                  style={{ display: "inline-block", marginTop: "11px" }}
                >
                  Raising For{" "}
                </label>
                <input
                  type="radio"
                  name="IsSelfRequest"
                  className="mt-3 ml-2 "
                  style={{ transform: "scale(1.3)" ,color:"red"}}
                  value="Y"
               
                  // checked={formik.values.IsSelfRequest === "Y"}
                  defaultChecked={formik.values.IsSelfRequest === "Y"}
                  onChange={(e) =>
                    formik.setFieldValue("IsSelfRequest", e.target.value)
                  }
                />
                <label className="ml-2"> Self</label>
              </label>
              <label>
                <input
                  type="radio"
                  name="IsSelfRequest"
                  className="mt-3 ml-2"
                  style={{ transform: "scale(1.3)" }}
                  // style={{ backgroundColor: "#f3f3ff" }}
                  value="N"
                  checked={formik.values.IsSelfRequest === "N"}
                  onChange={(e) =>
                    formik.setFieldValue("IsSelfRequest", e.target.value)
                  }
                />
                <label className="ml-2">Someone</label>
              </label>
            </div>
          </div>

          {/* <Select
                      name="IsSelfRequest"
                      className="designselect ml-2"
                      maxMenuHeight={300}
                      onChange={(e) =>
                        formik.setFieldValue("IsSelfRequest", e.value)
                      }
                      value={option?.filter(
                        (e) => e.value === formik.values.IsSelfRequest
                      )}
                      options={option}
                    /> */}

          {formik.errors.IsSelfRequest ? (
            <p className="text-danger"> {formik.errors.IsSelfRequest} </p>
          ) : null}

          {formik.values.IsSelfRequest === "N" ? (
          //   <Form.Group>
          //   <Form.Label className="SprintlabelStyle">
          //   Raising On Behalf
          //   </Form.Label>
          //   <Form.Control
          //     className="SprintFormFields"
          //     type="text"
          //     value={formik.values.request_For}
          //     required
          //     placeholder=""
          //     onChange={formik.handleChange}
          //     size="sm"
          //   />
          // </Form.Group>
            <div>
              <label className="labelStyle  ">Raising On Behalf </label>

              <label className="labelStyle ml-2 "> (provide Email#) </label>
              <br></br>

              <TextField
                // type="text"
                variant="outlined"
                placeholder="Type email"
                // className="Behalf"
                name="request_For"
                size="small"
                value={formik.values.request_For}
                onChange={formik.handleChange}
              />

              <Button
                onClick={() => {
                  GetRequestEmpDetails();
                  setReclickbehalf();
                }}
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 60,
                  height: 20,
                  marginTop: 12,
                  marginLeft: 6,
                  borderRadius: 8,
                  marginRight: 14,
                  border: "2px solid #5250E5",
                  backgroundColor: "#5250E5",
                  color: "#fff",
                  textAlign: "center",
                  textTransform: "none",
                }}
                size="small"
              >
                Fetch
              </Button>
              {formik.errors.request_For ? (
                <p className="text-danger mt-2">{formik.errors.request_For}</p>
              ) : null}
              {/* itrequest-btn */}
              <div>
                {formik.values.request_For === "" ? (
                  ""
                ) : itrequestemp_detais.length == 0 ? (
                  <p className="text-danger">Please Give a valid Email ID</p>
                ) : (
                  <label
                    className="d-flex d-flex justify-content-space between ml-3"
                    style={{ fontSize: "14px" }}
                  >
                    {" "}
                    {itrequestemp_detais &&
                      itrequestemp_detais.map((e, i) => {
                        return e.Firstname + " " + e.Lastname + " " + roledata;
                      })}
                  </label>

                  // <table
                  //   className="table  "
                  //   style={{ marginRight: "50px" }}
                  // >

                  //   <tbody>
                  //     {itrequestemp_detais &&
                  //       itrequestemp_detais.map((e, i) => {
                  //         return (
                  //           <tr key={i}>
                  //             <td>{e.Firstname}<></>{e.Lastname}</td>
                  //             {/* <td> </td> */}
                  //             {/* <td>{e.emplevel}</td> */}
                  //             <td>{roledata}</td>
                  //           </tr>
                  //         );
                  //       })}
                  //   </tbody>
                  // </table>
                )}
              </div>
            </div>
          ) : null}
        </Row>
        <Row>
          <div>
            <label className="labelStyle ">Reason </label>
            <Select
              className="designselect "
              name="ReasonCode"
              styles={customStylesd}
              style={{
                // backgroundColor: "#f3f3ff",
                fontSize: 12,
                // width: "465px",
              }}
              options={
                assettype &&
                assettype
                  .filter((e) => e.ReasonCategory == "ASR")
                  .map((e) => ({
                    value: e.Description,
                    label: e.Description,
                  }))
              }
              onChange={(e) => formik.setFieldValue("ReasonCode", e.value)}
              value={assettype
                .filter(
                  (e) =>
                    e.ReasonCategory === "ASR" &&
                    e.Description === formik.values.ReasonCode
                )
                .map((e) => ({
                  value: e.Description,
                  label: e.Description,
                }))}
            />
          </div>

          <div className="itrequest_box">
            {formik.errors.ReasonCode ? (
              <p className="text-danger"> {formik.errors.ReasonCode} </p>
            ) : null}
          </div>

          {formik.values.ReasonCode === "" ||
          formik.values.ReasonCode === "New" ||
          formik.values.ReasonCode === "Replacement" ? null : (
            <div>
              <label className="labelStyle "> New Owner </label>

              <label className="labelStyle ml-2"> (provide Email#) </label>

              <br></br>
              <TextField
                variant="outlined"
                placeholder="Type email"
                name="New_owner"
                // type="text"
                // className="Behalf"
                size="small"
                value={formik.values.New_owner}
                onChange={formik.handleChange}
              />
              <Button
                type="button"
                onClick={() => {
                  GetEmpDetails();
                  setReclicknewowner();
                }}
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 60,
                  height: 20,
                  marginTop: 12,
                  marginLeft: 6,
                  borderRadius: 8,
                  marginRight: 14,
                  border: "2px solid #5250E5",
                  backgroundColor: "#5250E5",
                  color: "#fff",
                  textAlign: "center",
                  textTransform: "none",
                }}
              >
                Fetch
              </Button>
              {formik.errors.New_owner ? (
                <p className="text-danger">{formik.errors.New_owner}</p>
              ) : null}

              {formik.values.New_owner === "" ? (
                ""
              ) : itEmp.length == 0 ? (
                <p className="text-danger"> Please Give a valid Email ID </p>
              ) : (
                <label
                  className="d-flex d-flex justify-content-space between"
                  style={{ fontSize: "14px" }}
                >
                  {" "}
                  {itEmp &&
                    itEmp.map((e, i) => {
                      return (
                        e.Firstname +
                        " " +
                        e.Lastname +
                        " " +
                        " " +
                        " " +
                        " " +
                        "  " +
                        roledata
                      );
                    })}
                </label>
                // <table className="table  itrequsttable ">
                //   <thead>
                //     <tr>
                //       <th style={{ fontSize: "12px" }}>First Name</th>
                //       <th style={{ fontSize: "12px" }}>Last Name</th>
                //       {/* <th>Emp level </th> */}
                //       <th style={{ fontSize: "12px" }}>Designation</th>
                //     </tr>
                //   </thead>
                //   <tbody>
                //     {itEmp &&
                //       itEmp.map((e, i) => {
                //         return (
                //           <tr key={i}>
                //             <td style={{ fontSize: "12px" }}>
                //               {e.Firstname}
                //             </td>
                //             <td style={{ fontSize: "12px" }}>
                //               {e.Lastname}{" "}
                //             </td>
                //             {/* <td>{e.emplevel}</td> */}
                //             <td style={{ fontSize: "12px" }}>{roledata}</td>
                //           </tr>
                //         );
                //       })}
                //   </tbody>
                // </table>
              )}
            </div>
          )}
        </Row>

        <Row className="mt-3">
          <div>
            <label className="labelStyle ">Asset Type </label>
            <Select
              name="Asset_type"
              styles={customStylesd}
              className="designselect1"
              onChange={(e) => formik.setFieldValue("Asset_type", e.value)}
              style={{ fontSize: 12 }}
              value={description
                .filter(
                  (e) =>
                    e.ItemTypeCategory === "ASR" &&
                    e.Description === formik.values.Asset_type
                )
                .map((e) => ({
                  value: e.Description,
                  label: e.Description,
                }))}
              options={
                description &&
                description
                  .filter((e) => e.ItemTypeCategory == "ASR")
                  .map((e) => ({
                    value: e.Description,
                    label: e.Description,
                  }))
              }
            />
            {formik.errors.Asset_type ? (
              <p className="text-danger"> {formik.errors.Asset_type} </p>
            ) : null}
          </div>
          {/* <div className="d-flex justify-content-space between"> */}

          <div>
            <label className="labelStyle mr-5 ml-5">Expected Date </label>
            <br></br>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{
                  width: "200px",
                  // backgroundColor: "#f3f3ff",
                  fontSize: 12,
                }}
                varient="dialog"
                className="ml-5 "
                format="dd/MM/yyyy"
                value={date}
                views={["year", "month", "date"]}
                onChange={(e) => setDate(e)}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Row>

        <Row className="mt-3">
          {/* </div> */}
          <div>
            <label className="labelStyle  ">Description </label>
            <br></br>

            {/* <label className="text"></label> */}

            <textarea
              name="Justification"
              style={{
                width: "470px",
                height: "100px",
                // backgroundColor: "#f3f3ff",
                border: "1px solid  hsl(0, 0%, 80%)",

                fontSize: 12,
              }}
              className="SprintFormFields"
              value={formik.values.Justification}
              onChange={formik.handleChange}
            ></textarea>
            {formik.errors.Justification ? (
              <p className="text-danger"> {formik.errors.Justification} </p>
            ) : null}
          </div>
        </Row>

        {/* {formik.values.ReasonCode === "Replacement" ||
              formik.values.ReasonCode === "Transfer" ? (
                // <>
                //   <div className="d-flex justify-content-between">
                //     <label> Asset ID </label>
                //   </div>

                //   <table className="table pt-3 itrequsttable">
                //     <tr>
                //       <th>Asset Id</th>
                //       <th>Assets Type</th>
                //       <th>Asset Serialnumber</th>
                //     </tr>
                //     {data &&
                //       data
                //         .filter((e) => e.assettype == formik.values.Asset_type)
                //         .map((e, i) => {
                //           return (
                //             <tr key={i}>
                //               <td>{e.assetid}</td>
                //               <td>{e.assettype}</td>
                //               <td>{e.serialnumber}</td>
                //             </tr>
                //           );
                //         })}
                //   </table>
                  // </>
              ) : null} */}
        {/* <hr style={{ width: "1200px" }}></hr> */}

        <div
          className="mt-4"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            width:"450px",
          }}
        >
          <Button
            style={{
              fontSize: 14,
              padding: 9,
              width: 100,
              height: 40,
              borderRadius: 8,
              marginRight: 14,
              border: "2px solid #5A88F1",
              backgroundColor: "#fff",
              color: "#5250E5",
              textAlign: "center",
              textTransform: "none",
            }}
            type="reset"
            onClick={restvalues}
          >
            Reset
          </Button>

          <Button
            style={{
              fontSize: 14,
              padding: 9,
              width: 100,
              height: 40,
              borderRadius: 8,
              // marginRight: 14,
              border: "2px solid #5250E5",
              backgroundColor: "#5250E5",
              color: "#fff",
              textAlign: "center",
              textTransform: "none",
            }}
            type="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
      </div>
      </div>
    </div>
  );
}

export default RequestFormnew;
