import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  shinyText: {
    background: "linear-gradient(90deg, transparent, #FFD700, transparent)",
    backgroundSize: "200% 100%",
    animation: "$shine 2s linear infinite",
    color: "#000",
  },
  silverShiny: {
    background: "linear-gradient(90deg, transparent, silver, transparent)",
    backgroundSize: "200% 100%",
    animation: "$shine 2s linear infinite",
    color: "#000",
  },
  "@keyframes shine": {
    "0%": {
      backgroundPosition: "200% 0",
    },
    "100%": {
      backgroundPosition: "-200% 0",
    },
  },
}));

function PlanChip({ plan, isSilverShiny }) {
  const classes = useStyles();

  return (
  <>
    { plan&&<Chip
      style={{ fontSize: "10px" }}
      className={plan?.includes("Enterprise") ? classes.shinyText : classes.silverShiny}
      size="small"
      label={
        <span>
          {plan?.includes("Enterprise") ? <span className="mdi mdi-crown"></span> : null}
          {plan}
        </span>
      }
    />
      }
      </>
  );
}

export default PlanChip;
