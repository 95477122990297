import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./imageview.css";
import { useEffect } from "react";
import { ReactImageCarouselViewer } from "react-image-carousel-viewer";
// import "./styles.css";
const Imageviewer = ({ open, setOpen, imagedata }) => {
  // let Carousel = []
  // useEffect(() => {
  //   if (imagedata) {

  //     images.forEach(image => {
  //       Carousel.push({ "src": image.src })
  //     });
  //   }
  //   else {
  //     Carousel = []
  //   }
  // }, [imagedata]);
  const handleClose = () => {
    setOpen(false);
  };
  const images = imagedata?.match(/<img .*?>/g);
  const extractedImageSrcArray = images?.map((image) => {
    const srcMatch = image?.match(/src=['"](.*?)['"]/);
    return { src: srcMatch ? srcMatch[1] : null };
  });
  return (
    <div className="imageviewrmainbox">
      <ReactImageCarouselViewer
        open={extractedImageSrcArray && open}
        onClose={handleClose}
        images={extractedImageSrcArray}
        disableScroll={true}
      />
      {/* <Dialog open={open} className="imageviewsize" fullWidth maxWidth="500px">
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center ">
            <Box flexGrow={1} fontSize="15px" className="fontdesign">
              Image preview
            </Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{ objectFit: "contain" }}
              className="d-flex justify-content-center"
              dangerouslySetInnerHTML={{
                __html:
                  imagedata == null
                    ? null
                    : imagedata == ""
                      ? ""
                      : imagedata.match(/<img .*?>/g),
              }}
            ></div>
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
    </div>
  );
};

export default Imageviewer;
