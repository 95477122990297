import React, { useState, useEffect } from "react";
import { Input, Table, TextField, Card, Typography } from "@material-ui/core";
import { Container, CardBody, Col, Row } from "reactstrap";
import axios from "../../axios";
import CircleIcon from "@mui/icons-material/Circle";
import Check from "@mui/icons-material/Check";
import AdjustIcon from "@mui/icons-material/Adjust";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./IncidentCard.css";
import { Slide, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { INDETAILSUPDATE } from "../../redux/CommonInitialsate";
import { useDispatch, useSelector } from "react-redux";
import { StepButton } from "@mui/material";
import moment from "moment";
import Utils from "../LoginPage/utlis";
export default function IncidentCard({
  e,
  item,
  tabledata,
  setopen,
  AssingedIncident,
  Sla_Mater,
  Refreshdata,
}) {
  console.log(e.AcknowledgementDate, "ets");
  console.log(e,"ejkh")
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const [buttonClicked, setButtonClicked] = useState(false);
  const dispatch = useDispatch();
  const { projectCode,projectDetails  } = useSelector((state) => state.user);

  const prjshcode = projectDetails?.filter((e)=>e.projectcode == projectCode)

  const isActiveCard = activeCardIndex === item;
  const handleClickCard = (index, e) => {
    dispatch(INDETAILSUPDATE(e.IncidentId));
    setopen(true);
    setActiveCardIndex(index);
  };
  let arr = JSON.parse(localStorage.getItem("Empdetails"));
  let role =
  sessionStorage.getItem("Roledetails") == "undefined"
    ? ""
    : JSON.parse(sessionStorage.getItem("Roledetails"));
    let smrole  = localStorage.getItem("ProjectRole");

    const clientid = JSON.parse(localStorage.getItem("clientid"));
  const Ack = e.StatusHistory && e.StatusHistory.find(entry => entry.Stage === "Acknowledged")
  let ACkdate  =  Ack && Ack.stagemoveddate
const WP = e.StatusHistory && e.StatusHistory.find(entry => entry.Stage === "Work in progress")
let Wpdate  =  WP ? moment(WP && WP.stagemoveddate).format(
  " hh:mm A DD-MMM-YYYY "
) : null

const UserV = e.StatusHistory && e.StatusHistory.find(entry => entry.Stage === "User Verfication")
let UserVdate =  UserV ? moment(UserV && UserV.stagemoveddate).format(
  " hh:mm A DD-MMM-YYYY "
) : null
const UEV = e.StatusHistory && e.StatusHistory.find(entry => entry.Stage === "User verified")
let UEVdate = UEV ? moment(UEV && UEV.stagemoveddate).format(
  "hh:mm A DD-MMM-YYYY "
) : null

  const steps = [
    "New",
    "Acknowledged",
    "Work in progress",
    "User Verfication",
    "User verified",
    "Resolved",
  ];
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    "& .label": {
      position: "absolute",
      top: -30,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
      textAlign: "center",
      color:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#33ff33",
        color: "33ff33",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#00cc00",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#33ff33",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#00cc00",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
    "& .QontoStepIcon-ErrorIcon": {
      color: "#F76666",
      // color: '#00cc00',
      zIndex: 1,
      fontSize: 18,
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className, Acknowledgement, index, Resolved } =
      props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <CheckCircleIcon
            className={
              (Acknowledgement === "N" && index === 1) ||
              (Resolved === "N" && index === 5)
                ? "QontoStepIcon-ErrorIcon"
                : "QontoStepIcon-completedIcon"
            }
          />
        ) : (
          <RadioButtonCheckedIcon className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
  const handleSteps = (label, id) => {
    console.log(label, id, "Shadow");
  };
  const handleAssignedToMe = (evt) => {
    // evt.stopPropagation()
    const statushistory = [...e.StatusHistory];
    const UpdateNotes = [...e.UpdateNotes];
    UpdateNotes.push({
      NoteSno: UpdateNotes.length + 1,
      DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      NoteUpdatedBy: arr[0].ClientUserId,
      NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
      NotesDetails: "We Acknowledged and We are working on that.",
    });
    statushistory.push({
      Stage: "Acknowledged",
      stagemoveddate: moment().format("YYYY-MM-DD HH:mm:ss"),
      stagemovedby: arr[0].UserFirstname + " " + arr[0].UserLastname,
    });
    const AssingedToDetails = {
      FN: arr[0].UserFirstname,
      LN: arr[0].UserLastname,
    };
    let PatchAssignedTo = {
      AssignedTo: arr[0].ClientUserId,
      AssignedToDetails: AssingedToDetails, // Assuming AssingedDetails is a variable
      CurrentState: {
        StateCode: "Acknowledged",
        StateName: "Acknowledged",
      },
      UpdateNotes: UpdateNotes,
    };
    const holidaysArray = [
      new Date("2023-01-01"),
      new Date("2023-04-14"), // Good Friday
      new Date("2023-07-04"), // Independence Day
      new Date("2023-12-25"), // Christmas Day
      new Date("2023-05-29"), // Memorial Day
      new Date("2023-09-04"), // Labor Day
      new Date("2023-11-23"), // Thanksgiving Day
      new Date("2023-02-14"), // Valentine's Day
      new Date("2023-06-18"), // Father's Day
      new Date("2023-10-31"), // Halloween
    ];
    const createdDate = new Date(
      moment(e.CreatedDate, "DD-MMM-YYYY HH:mm A").toDate()
    );
    const acknowledgementDate = new Date(); // Replace this with your actual acknowledgement date
    const acknowledgementTimeTaken = Utils.calculateBusinessMinutes(
      createdDate,
      acknowledgementDate,
      9,
      18,
      holidaysArray
    );
    // The rest of your code remains unchanged

    const selectedSLA = Sla_Mater.find((sla) => sla.Priority === e.LabelP);
    console.log(e.LabelP, Sla_Mater);
    const isAcknowledged =
      acknowledgementTimeTaken <= selectedSLA.AcknowledgementSLA;
    const SLA_Breach = isAcknowledged ? "N" : "Y";

    const Sla_Patch_Data = {
      AcknowledgedByDetails: arr[0].ClientUserId,
      IsAcknowledged: isAcknowledged ? "Y" : "N",
      AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      AcknowledgementTimeTaken: acknowledgementTimeTaken,
      SLABreachDetails:
        SLA_Breach === "Y"
          ? acknowledgementTimeTaken - selectedSLA.AcknowledgementSLA
          : null,
      StatusHistory: statushistory,
      // AcknowledgedByDetails:
    }; // Replace this with your actual acknowledgement date
    axios
      .patch(
        "agilesaas_incident_sla_details?IncidentId=eq." + e.IncidentId,
        Sla_Patch_Data
      )
      .then((e) => {
        Refreshdata();
      })
      .catch((E) => console.log(E));
    axios
      .patch(
        "agilesaas_incident_details?IncidentId=eq." + e.IncidentId,
        PatchAssignedTo
      )
      .then((e) => {
        Refreshdata();
      })
      .catch((E) => console.log(E));
  };
  const userhandle = (label) => {
    const UpdateNotes = [...e.UpdateNotes];
    switch (label) {
      case "User verified":
        UpdateNotes.push({
          NoteSno: UpdateNotes.length + 1,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: arr[0].ClientUserId,
          NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
          NotesDetails: "I signed to issue fixed.",
        });
        let PatchAssignedToUser = {
          CurrentState: {
            StateCode: "User verified",
            StateName: "User verified",
          },
          UpdateNotes: UpdateNotes,
        };
        axios
          .patch(
            "agilesaas_incident_details?IncidentId=eq." + e.IncidentId,
            PatchAssignedToUser
          )
          .then((e) => {
            Refreshdata();
          })
          .catch((E) => console.log(E));
        break;
    }
  };
  const handlestepper = (label, validate) => {
    const holidaysArray = [
      new Date("2023-01-01"),
      new Date("2023-04-14"), // Good Friday
      new Date("2023-07-04"), // Independence Day
      new Date("2023-12-25"), // Christmas Day
      new Date("2023-05-29"), // Memorial Day
      new Date("2023-09-04"), // Labor Day
      new Date("2023-11-23"), // Thanksgiving Day
      new Date("2023-02-14"), // Valentine's Day
      new Date("2023-06-18"), // Father's Day
      new Date("2023-10-31"), // Halloween
    ];
    const UpdateNotes = [...e.UpdateNotes];
    const statushistory = [...e.StatusHistory];
    const createdDate = new Date(
      moment(e.CreatedDate, "DD-MMM-YYYY HH:mm A").toDate()
    );
    const selectedSLA = Sla_Mater?.find((sla) => sla.Priority === e?.LabelP);
    switch (label) {
      case "Acknowledged":
        UpdateNotes.push({
          NoteSno: UpdateNotes.length + 1,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: arr[0].ClientUserId,
          NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
          NotesDetails: "We Acknowledged and We are working on that.",
        });
        let PatchAssignedTo = {
          CurrentState: {
            StateCode: "Acknowledged",
            StateName: "Acknowledged",
          },
          UpdateNotes: UpdateNotes,
        };

        const acknowledgementDate = new Date(); // Replace this with your actual acknowledgement date
        const acknowledgementTimeTaken = Utils.calculateBusinessMinutes(
          createdDate,
          acknowledgementDate,
          9,
          18,
          holidaysArray
        );
        // The rest of your code remains unchanged

        console.log(e.LabelP, Sla_Mater);
        const isAcknowledged =
          acknowledgementTimeTaken <= selectedSLA.AcknowledgementSLA;
        const SLA_Breach = isAcknowledged ? "N" : "Y";
    
        statushistory.push({
          Stage: "Acknowledged",
          stagemoveddate: moment().format("YYYY-MM-DD HH:mm:ss"),
          stagemovedby: arr[0].UserFirstname + " " + arr[0].UserLastname,
        });
        const Sla_Patch_Data = {
          AcknowledgedByDetails: arr[0].ClientUserId,
          IsAcknowledged: isAcknowledged ? "Y" : "N",
          // AcknowledgementDate: acknowledgementDate.toISOString(),
          AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          AcknowledgementTimeTaken: acknowledgementTimeTaken,
          SLABreachDetails:
            SLA_Breach === "Y"
              ? acknowledgementTimeTaken - selectedSLA.AcknowledgementSLA
              : null,
          StatusHistory: statushistory,
          // AcknowledgedByDetails:
        }; // Replace this with your actual acknowledgement date
        axios
          .patch(
            "agilesaas_incident_sla_details?IncidentId=eq." + e.IncidentId,
            Sla_Patch_Data
          )
          .then((e) => {
            Refreshdata();
          })
          .catch((E) => console.log(E));
        axios
          .patch(
            "agilesaas_incident_details?IncidentId=eq." + e.IncidentId,
            PatchAssignedTo
          )
          .then((e) => {
            Refreshdata();
          })
          .catch((E) => console.log(E));
        break;
      case "Work in progress":
        UpdateNotes.push({
          NoteSno: UpdateNotes.length + 1,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: arr[0].ClientUserId,
          NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
          NotesDetails: "We are still working on it. We will update soon",
        });
        let Workpatch = {
          CurrentState: {
            StateCode: "Work in progress",
            StateName: "Work in progress",
          },
          UpdateNotes: UpdateNotes,
        };
        let WIP ={
            StatusHistory: statushistory,

        }
       
        statushistory.push({
          Stage: "Work in progress",
          stagemoveddate: moment().format("YYYY-MM-DD HH:mm:ss"),
          stagemovedby: arr[0].UserFirstname + " " + arr[0].UserLastname,
        });
        axios.patch(
            "agilesaas_incident_sla_details?IncidentId=eq." + e.IncidentId,
            WIP
          )
          .then((e) => {
            Refreshdata();
          })
          .catch((E) => console.log(E));
        axios
          .patch(
            "agilesaas_incident_details?IncidentId=eq." + e.IncidentId,
            Workpatch
          )
          .then((e) => {
            Refreshdata();
          })
          .catch((E) => console.log(E));
        break;
      case "User Verfication":
        UpdateNotes.push({
          NoteSno: UpdateNotes.length + 1,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: arr[0].ClientUserId,
          NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
          NotesDetails: "We have completed. Please check and let me know",
        });
        let userpatch = {
          CurrentState: {
            StateCode: "User Verfication",
            StateName: "User Verfication",
          },
          UpdateNotes: UpdateNotes,
        };
         statushistory.push({
          Stage: "User Verfication",
          stagemoveddate: moment().format("YYYY-MM-DD HH:mm:ss"),
          stagemovedby: arr[0].UserFirstname + " " + arr[0].UserLastname,
        });
        const Sla_User_Patch_Data = {
          PauseSLAClockDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          PauseSLAClock: "Y",
          StatusHistory: statushistory,
        };
        axios
          .patch(
            "agilesaas_incident_sla_details?IncidentId=eq." + e.IncidentId,
            Sla_User_Patch_Data
          )
          .then((e) => {})
          .catch((E) => console.log(E));
        axios
          .patch(
            "agilesaas_incident_details?IncidentId=eq." + e.IncidentId,
            userpatch
          )
          .then((e) => {
            Refreshdata();
          })
          .catch((E) => console.log(E));
        break;
      case "Resolved":
        UpdateNotes.push({
          NoteSno: UpdateNotes.length + 1,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: arr[0].ClientUserId,
          NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
          NotesDetails: "We resolved the ticket successfuly",
        });
        statushistory.push({
            Stage: "User Verfication",
            stagemoveddate: moment().format("YYYY-MM-DD HH:mm:ss"),
            stagemovedby: arr[0].UserFirstname + " " + arr[0].UserLastname,
          });
        let Respatch = {
          CurrentState: {
            StateCode: "Resolved",
            StateName: "Resolved",
          },
          UpdateNotes: UpdateNotes,
        };
        axios
          .patch(
            "agilesaas_incident_details?IncidentId=eq." + e.IncidentId,
            Respatch
          )
          .then((e) => {
            Refreshdata();
          })
          .catch((E) => console.log(E));
        const ResolvedDate = e.PauseSLAClockDate
          ? new Date(e.PauseSLAClockDate)
          : new Date();
        // const Ackdate = new Date(moment(, 'DD-MMM-YYYY HH:mm A').format());
        const Ackdate = new Date(e.AcknowledgementDate);
        const resolutionTimeTaken = Utils.calculateBusinessMinutes(
          Ackdate,
          ResolvedDate,
          9,
          18,
          holidaysArray
        );
        console.log(
          resolutionTimeTaken,
          selectedSLA.ResolutionSLA,
          Ackdate,
          ResolvedDate,
          "resolutionTimeTaken"
        );
        const isResolved = resolutionTimeTaken <= selectedSLA.ResolutionSLA;
        const Sla_Res_Patch_Data = {
          ResolvedByDetails: arr[0].ClientUserId,
          IsSLAMet: isResolved ? "Y" : "N",
          ResolvedDate: ResolvedDate.toISOString(),
          ResolutionTimeTaken: resolutionTimeTaken,
          IsResolved: "Y",
          StatusHistory: statushistory,
        };
        axios
          .patch(
            "agilesaas_incident_sla_details?IncidentId=eq." + e.IncidentId,
            Sla_Res_Patch_Data
          )
          .then((e) => {
            Refreshdata();
          })
          .catch((E) => console.log(E));
        break;
      default:
        break;
    }
  };
  function createbug(e){
    setButtonClicked(true);
let postData = {
  ProjectId: projectCode,
  ProjectShortCode: prjshcode[0]?.projectshortcode,
  IssueType: "Bug",
  IssueTitle: `${e.IncidentTitle} - ${e.IncidentReference} `,
  Description: `Created from Incident with Refnumber ${e.IncidentReference} \n ${e.Description}`,
  Priority: "Highest",
  PlannedRelease:  null,
  EstimateUnit: null,
  EstimateUnitDesc:  null,
  CurrentStage: [
    {
      StageCode: "BLG",
      StageName: "Backlog",
      DateMoved: moment().format("YYYY-MM-DD"),
    },
  ],
  LinkToEpic:null,
  LinkToIssues: null,
  CreatedBy: arr[0]?.ClientUserId,
  CreatedByDetails:
    role?.RoleName == "Undefined" ? "Kanban Admin" : role?.RoleName,
  CreatedDate: moment().format("YYYY-MM-DD"),
  AssignedTo: null,
  AssignedToDetails:  {
    FN: null,
    LN: null,
  },
  AssignedDate: moment().format("YYYY-MM-DD") ,
  Attachment:  null,
  IsActive: "Y",
  ExceptiontoSkipSPEstimation:  "N",
  ProjectDetails: [{ ProjName: prjshcode[0]?.projectname, Client: clientid }],
  AssignedToUAT: null,
  AssignedToUATDetails: {
    FN: null,
    LN: null,
  },
  ClientId: clientid,
  AttachmentReference: null,
};


axios.post("agilesaas_issue_details", postData)
        .then((ress) => { 
       
          axios.get("agilesaas_issue_details?ClientId=eq."+clientid)
          .then((response) => {
            const allRecords = response.data; 

            allRecords.sort((a, b) => b.IssueId - a.IssueId);

            const lastPostedRecord = allRecords[0]; 

         let patchincidentbug ={ 
          "BugTaskDetails": {
            Project :prjshcode[0]?.projectname,
            TicketReference:lastPostedRecord.IssueId,
          },
          "IsBugTaskCreated": "Y"
        }
        axios.patch("agilesaas_incident_details?IncidentId=eq."+e.IncidentId,patchincidentbug).then((res)=>{

          toast.success("Created as a bug");
          Refreshdata();
        }).catch((E)=>{
          console.log("errorpatch",E)
        })

          });
            
         
         
        
        })
        .catch((error) => {
          // Handle errors here
          console.log("error")
        })
       
  }
  const convertMinutesToTime = (minutes) => {
    const years = Math.floor(minutes / (60 * 24 * 365));
    let remainingMinutes = minutes % (60 * 24 * 365);

    const days = Math.floor(remainingMinutes / (60 * 24));
    remainingMinutes %= 60 * 24;

    const hours = Math.floor(remainingMinutes / 60);
    remainingMinutes %= 60;

    const mins = remainingMinutes;

    return {
      years: years,
      days: days,
      hours: hours,
      mins: mins,
    };
  };
  const StepIndex = steps.indexOf(e.CurrentState1);
  const StepEnabled = steps[StepIndex + 1];
  const time = convertMinutesToTime(e.Acktime);
  const Rtime = convertMinutesToTime(e.ResTime);
  console.log(
    e.IncidentId,
    e.CurrentState1,
    steps.indexOf(e.CurrentState1),
    "steps.indexOf(e.CurrentState1)"
  );
  return (
    <Card
      className={
        item === 0
          ? "inactive-card shadow-sm mt-1 ml-3"
          : "inactive-card shadow-sm mt-3 ml-3"
      }
      style={
        e.CurrentState1 === "Resolved"
          ? {
              opacity: "0.5",
              border: "1px solid rgb(0, 0, 0 ,20%)",
            }
          : {
              background: "#F9FAFB",
              border: "1px solid rgb(0, 0, 0 ,20%)",
              borderLeft:
                activeCardIndex === item
                  ? "3px solid blue"
                  : "1px solid rgb(0, 0, 0 ,20%)",
            }
      }
    >
      {/* e.CurrentState1 === "Resolved" ? {
                    opacity: "0.5",
                    pointerEvents: "none"
                } */}
      <Row>
        <Col
          lg={12}
          md={12}
          xl={12}
          sm={12}
          style={
            e.CurrentState1 === "Resolved"
              ? {
                  pointerEvents: "none",
                }
              : {}
          }
        >
          <Stepper
            alternativeLabel
            Non-linear
            activeStep={
              e.CurrentState1 === "Resolved"
                ? 6
                : steps.indexOf(e.CurrentState1)
            }
            connector={<QontoConnector />}
            className="mt-3 d-flex justify-content-start"
          >
            {AssingedIncident
              ? steps.map((label, index) => (
                  <Step
                    key={label}
                    onClick={() =>
                      StepEnabled === label &&
                      e.AssignedTo !== null &&
                      index !== 4
                        ? handlestepper(label)
                        : ""
                    }
                    disabled={
                      StepEnabled === label || index === 4 ? false : true
                    }
                  >
                    {label === "Acknowledged" || label === "Resolved" ? (
                      <label
                        className="mt-3"
                        style={{
                          position: "absolute",
                          fontSize: "12px",
                          bottom: 7,
                          left: "calc(-50% + 16px)",
                          right: "calc(50% + 16px)",
                          textAlign: "center",
                          color:
                            (e.Acknowledgement === "N" && index === 1) ||
                            (e.Resoloved === "N" && index === 5)
                              ? "#F76666"
                              : (e.Acknowledgement === "Y" && index === 1) ||
                                (e.Resoloved === "Y" && index === 5)
                              ? "#00e600"
                              : "inherit",
                        }}
                      >
                        {label === "Acknowledged" && e.Acktime ? (
                          <span className="mdi mdi-timer">
                            {time.years > 0
                              ? time.years + " yrs"
                              : "" + time.days > 0
                              ? time.days + " days"
                              : "" + time.hours > 0
                              ? time.hours + " hrs"
                              : "" + time.mins > 0
                              ? time.mins + " mins"
                              : ""}
                          </span>
                        ) : label === "Resolved" && e.ResTime ? (
                          <span className="mdi mdi-timer">
                            {Rtime.years > 0
                              ? Rtime.years + " yrs"
                              : "" + Rtime.days > 0
                              ? Rtime.days + " days"
                              : "" + Rtime.hours > 0
                              ? Rtime.hours + " hrs"
                              : "" + Rtime.mins > 0
                              ? Rtime.mins + " mins"
                              : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                    ) : (
                      ""
                    )}
                    <StepLabel
                      role="button"
                      StepIconComponent={(props) =>
                        QontoStepIcon({
                          ...props,
                          Acknowledgement: e.Acknowledgement,
                          index: index,
                          Resolved: e.Resoloved,
                        })
                      }
                    >
                      {/* 33ff33 */}
                      <span
                        style={{
                          color:
                            (e.Acknowledgement === "N" && index === 1) ||
                            (e.Resoloved === "N" && index === 5)
                              ? "#F76666"
                              : (e.Acknowledgement === "Y" && index === 1) ||
                                (e.Resoloved === "Y" && index === 5)
                              ? "#00e600"
                              : "inherit",
                        }}
                      >
                        <span style={{ fontSize: "12px" }}>
                        {label}
                        <br></br>
                          {" "}

                         
                          {label =="New" ? e.CreatedDate :label === "Acknowledged" ? e.AcknowledgementDate == null ? null : moment(e.AcknowledgementDate).format(
                                " hh:mm A DD-MMM-YYYY "
                              )
                            : label == "Resolved" && e.Resoloved == "Y" 
                            ? moment(e.ResolvedDate).format(
                                "hh:mm A DD-MMM-YYYY "
                              )
                            : label == "Work in progress" ? Wpdate
                             : label == "User Verfication" ? UserVdate : label  == "User verified" ?UEVdate: null }                        </span>
                        
                        {/* {label === "Resolved" || label === "Acknowledged" ? label + "(" + "5hrs" + ")" : label} */}
                    
                      </span>
                    </StepLabel>
                  </Step>
                ))
              : steps.map((label, index) => (
                  <Step key={label}>
                    {label === "Acknowledged" || label === "Resolved" ? (
                      <label
                        className="mt-3"
                        style={{
                          position: "absolute",
                          fontSize: "12px",
                          bottom: 7,
                          left: "calc(-50% + 16px)",
                          right: "calc(50% + 16px)",
                          textAlign: "center",
                          color:
                            (e.Acknowledgement === "N" && index === 1) ||
                            (e.Resoloved === "N" && index === 5)
                              ? "#F76666"
                              : (e.Acknowledgement === "Y" && index === 1) ||
                                (e.Resoloved === "Y" && index === 5)
                              ? "#00e600"
                              : "inherit",
                        }}
                      >
                        {/* {moment(e.CreatedDate).format("DD-MMM-YYYY hh:mm A")} */}

                        {label === "Acknowledged" && e.Acktime ? (
                          <span className="mdi mdi-timer">
                            {time.years > 0
                              ? time.years + " yrs"
                              : "" + time.days > 0
                              ? time.days + " days"
                              : "" + time.hours > 0
                              ? time.hours + " hrs"
                              : "" + time.mins > 0
                              ? time.mins + " mins"
                              : ""}
                          </span>
                        ) : label === "Resolved" && e.ResTime ? (
                          <span className="mdi mdi-timer">
                            {Rtime.years > 0
                              ? Rtime.years + " yrs"
                              : "" + Rtime.days > 0
                              ? Rtime.days + " days"
                              : "" + Rtime.hours > 0
                              ? Rtime.hours + " hrs"
                              : "" + Rtime.mins > 0
                              ? Rtime.mins + " mins"
                              : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                    ) : (
                      ""
                    )}
                    <StepLabel
                      role="button"
                      StepIconComponent={(props) =>
                        QontoStepIcon({
                          ...props,
                          Acknowledgement: e.Acknowledgement,
                          index: index,
                          Resolved: e.Resoloved,
                        })
                      }
                    >
                      <span
                        style={{
                          color:
                            (e.Acknowledgement === "N" && index === 1) ||
                            (e.Resoloved === "N" && index === 5)
                              ? "#F76666"
                              : (e.Acknowledgement === "Y" && index === 1) ||
                                (e.Resoloved === "Y" && index === 5)
                              ? "#00e600"
                              : "inherit",
                        }}
                      >
                        <span style={{ fontSize: "12px" }}>
                        {label}
                        <br></br>
                       
                          {" "}
                       
                                                  {label =="New" ? e.CreatedDate :label === "Acknowledged" ? e.AcknowledgementDate == null ? null : moment(e.AcknowledgementDate).format(
                                                    " hh:mm A DD-MMM-YYYY"
                                                  )
                                                : label == "Resolved" && e.Resoloved == "Y" 
                                                ? moment(e.ResolvedDate).format(
                                                    " hh:mm A DD-MMM-YYYY"
                                                  )
                                                : label == "Work in progress" ? Wpdate
                                                 : label == "User Verfication" ? UserVdate : label  == "User verified" ?UEVdate: null }    
                        </span>
                       
                      </span>
                    </StepLabel>
                  </Step>
                ))}
          </Stepper>
        </Col>
      </Row>
      <Row style={{ marginTop: "-8px" }} className="">
        <Col
          xl={1}
          lg={2}
          md={2}
          sm={2}
          className="d-flex jsutify-content-center"
          onClick={() => {
            handleClickCard(item, e);
          }}
        >
          {e.avatarimage}
        </Col>
        <Col>
          <Row className="mt-3" style={{ margin: "2px" }}>
            <Col lg={9} md={9} sm={12}>
              <div className="" sty>
                <h5
                  className="mt-1 font-semibold text-lg"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    handleClickCard(item, e);
                  }}
                >
                  {e.IncidentReference + " - " + e.IncidentTitle} <span className="text-danger" style={{fontSize:"14px"}}>{e.linkedwithproblem == true ? "(Linked with problem)" :null }</span>
                </h5>
                {/* <div className="flex items-center" style={{ fontSize: "12px", fontWeight: "600" }}>
                                    {e.IncidentReference}
                                    <h5 className="mt-1 font-semibold text-lg" style={{ fontWeight: "600" }} onClick={() => {
                                        handleClickCard(item, e)
                                    }}> {e.IncidentTitle}</h5>
                                </div> */}
                <div
                  className="flex items-center text-sm text-gray-600"
                  onClick={() => {
                    handleClickCard(item, e);
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {e.RaisingOnBehalfDetails == null
                      ? "Self"
                      : "Someone:" + "On Behalf : " + e.RaisingOnBehalfDetails}
                  </span>
                  <span className="mx-2">|</span>
                  <span
                    className="bg-green-100 text-green-800 py-0.5 px-2 rounded-full"
                    style={{ fontWeight: "600" }}
                  >
                    {e.Priority}
                  </span>
                  <span className="mx-2">|</span>
                  <span
                    className="bg-yellow-100 text-yellow-800 py-0.5 px-2 rounded-full"
                    style={{ fontWeight: "600" }}
                  >
                    {e.Impactname}
                  </span>
                  <span className="mx-2">|</span>
                  <span style={{ fontWeight: "600" }}>{e.PriortyCode}</span>
                </div>
                <div className="CardFontIncident text-gray-500 mt-2">
                  Created at :{" "}
                  <span style={{ fontWeight: "600" }}>{e.CreatedDate}</span>
                  <span className="mx-2">|</span>
                  Category:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {tabledata
                      .filter((d) => d.IncidentId == e.IncidentId)
                      ?.map((E) => E.CategoryCode.CategoryName)}
                  </span>
                  <span className="mx-2">|</span>
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    {AssingedIncident ? (
                      e.AssignedToDetails ? (
                        e.AssignedTo === null ? (
                          <button
                            className="btn-outline btn-secondary text-white"
                            onClick={() => handleAssignedToMe()}
                          >
                            Assigned to me
                          </button>
                        ) : (
                          "Assigned To: " +
                          e.AssignedToDetails +
                          " (" +
                          e.AssignedGroup.GroupName +
                          ")"
                        )
                      ) : (
                        <button
                          className="btn-outline btn-secondary text-white"
                          onClick={() => handleAssignedToMe()}
                        >
                          Assigned to me
                        </button>
                      )
                    ) : e.AssignedToDetails ? (
                      e.AssignedTo === null ? (
                        e.AssignedGroup.GroupName
                      ) : (
                        "Assigned To: " +
                        e.AssignedToDetails +
                        " (" +
                        e.AssignedGroup.GroupName +
                        ")"
                      )
                    ) : (
                      e.AssignedGroup.GroupName
                    )}
                  </span>
                
                </div>
              </div>
            </Col>
            <Col lg={3} md={3} sm={3} className="">
              <div className="mt-1">{e.Impactdrop}</div>
              <div className="mt-1 ml-2">{e.Prioritydrop}</div>
              {/* <div className="mt-1 ml-1"> {e.notesdrop}</div> */}
              {smrole == "SM" && e.CurrentState1 == "User verified" ||  e.Resoloved === "Y" ?  <div className="mt-1 ml-1">
            {!buttonClicked && <button className="ml-2 mt-2"
                              onClick={() => {
                             
                                createbug(e);
                              }}
                              disabled={e.IsBugTaskCreated == "Y"}
                              hidden={e.IsBugTaskCreated == "Y"}
                              style={{
                         
                                height: 30,
                                          borderRadius: 5,
                                          background: "rgb(246, 202, 204)",
                                          padding: ".3rem .5rem",
                                          marginBottom:"5px",
                                          fontWeight:"bold",
                                          color: "rgb(208, 34, 36)",
                                
                              }}
                            >
                             Create as Bug
                            </button>}
                            </div> : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
