import React, { useEffect } from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
import { Line } from "react-chartjs-2";
import { useState } from "react";
import { green } from "@material-ui/core/colors";
import moment from "moment";
import axios from "../../../axios";
import { Style } from "@material-ui/icons";
import "chartjs-plugin-datalabels";

export default function SprintChart({
  data,
  userID,
  releaseID,
  percentage,
  chartdata,
  excludeday,
  projectname,
  versionname,
  stdate,
}) {
  const day = chartdata.map((data) => data.id);
  const formattedDates = excludeday.map((dateStr) => {
    const formattedDate = moment(dateStr, "YYYY/MM/DD").format("DD MMM");
    return formattedDate;
  });
  const sprintdate = formattedDates.map((e) => e);
  const combinedDatas = day.map((day, index) => {
    if (index === 0) {
      return `${day} `;
    }
    return `${day} (${formattedDates[index - 1]})`;
  });

  let version = projectname + " - " + versionname;
  // console.log(stdate);
  // Get the current date
  var currentDate = new Date();
  const currentDay = moment().format("DD MMM");
  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  var dayOfWeek = currentDate.getDay();
  const currentIndex = sprintdate?.indexOf(currentDay);
  const checkdate = moment().diff(moment(stdate), "days"); // Output the updated date in YYYY-MM-DD format
  // console.log(currentIndex);
  const finalIndex =
    dayOfWeek === 0
      ? checkdate - 2
      : dayOfWeek === 6
      ? checkdate - 1
      : currentIndex;

  // console.log(
  //   currentIndex,
  //   result,
  //   "currentIdex",
  //   final5,
  //   checkweekend.day() === 5,
  // );

  // const dataLabels = chartdata?.map((data, index) => {
  //   if (index === 0) {
  //     return `${data.plan}`;
  //   }
  //   return `${data.plan} (${formattedDates[index - 1]})`;
  // });
  // console.log(finalIndex, "abcd");

  return (
    <div>
      <div className="chart-container">
        <Line
          data={{
            labels: sprintdate,
            datasets: [
              {
                label: "Planned",
                data: chartdata?.map((data) => Math.round(data.plan)),
                fill: true,
                borderColor: "#9A89FF",
                backgroundColor: "transparent",
                borderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 3,
                pointHoverRadius: 4,
                hoverBackgroundColor: "black",
                tension: 0.7,
                lineTension: 0.5,
                datalabels: {
                  // display: "auto",
                  // align: "end",
                  // anchor: "end",
                  font: {
                    size: 10,
                  },
                  // offset: 5, // Offset the data label 10 pixels from the data point
                  // padding: 10, // Add 5 pixels of padding inside the data label
                  color: "white",
                  padding: 2,
                  // rotation: 45,
                  backgroundColor: "#9A89FF", // Set a background color
                  borderRadius: 10, // Set a border radius
                  borderWidth: 0.5, // Set border width
                  // borderColor: "rgb(0, 0, 0)",
                },
                // datalabels: {
                //   // display: "auto", // グラフの数値表示が重なったら、片方消すオプション
                //   align: "end",
                //   anchor: "end",
                //   // offset: 5,
                // },
              },
              {
                label: "Actual",
                data: percentage
                  ?.map((e) =>
                    // e.toFixed(0)
                    Math.round(e)
                  )
                  ?.slice(0, finalIndex + 1),
                backgroundColor: "rgba(0, 255, 202,0.4)",
                fill: true,
                borderColor: "#A9F4D0",
                borderWidth: 3,
                pointRadius: 3,
                pointHoverRadius: 4,
                hoverBackgroundColor: "black",
                tension: 0.7,
                lineTension: 0.5,
                datalabels: {
                  // display: "auto",
                  // align: "start",
                  // anchor: "start",
                  // offset: 5,
                  // backgroundColor: "#A9F4D0", // Set a background color
                  borderRadius: 10, // Set a border radius
                  borderWidth: 0.2, // Set border width
                  borderColor: "rgb(0, 0, 0)",
                  padding: 2,
                  color: "black",
                  font: {
                    size: 10,
                  },
                },
              },
            ],
          }}
          options={{
            legend: {
              display: true,
              position: "top",
              align: "center",
              labels: {
                fontSize: 12,
                fontStyle: "normal",
                boxWidth: 8,
                usePointStyle: true,
              },
            },
            title: {
              display: true,
              text: "BurnDown Report" + " - " + versionname,
              fontSize: 13,
              fontStyle: "bold",
              align: "end",
              padding: 4,
              fontColor: "#000",
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Days",
                    fontSize: 16,
                    fontStyle: "bold",
                    padding: 0,
                  },
                  ticks: {
                    fontSize: 11,
                    fontStyle: "normal",
                    beginAtZero: true,
                    padding: 0,
                  },
                  gridLines: {
                    display: true,
                    color: "transparent",
                    lineWidth: 0.2,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    zeroLineColor: "gray",
                    zeroLineWidth: 1,
                  },
                },
              ],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "StoryPoint",
                  },
                  gridLines: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.2)",
                    lineWidth: 0.2,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    zeroLineColor: "gray",
                    zeroLineWidth: 1,
                  },
                },
              ],
            },
            tooltips: {
              enabled: true,
              backgroundColor: "gray",
              titleFontColor: "#fff",
              titleFontSize: 14,
              titleFontStyle: "bold",
              bodyFontColor: "#fff",
              bodyFontSize: 12,
              bodyFontStyle: "normal",
              footerFontColor: "#fff",
              footerFontSize: 12,
              footerFontStyle: "normal",
              xPadding: 10,
              yPadding: 10,
              cornerRadius: 6,
            },
            // tooltips: {
            //   enabled: true,
            //   mode: "index",
            //   intersect: false,
            //   custom: function (tooltipModel) {
            //     // Customize the tooltip content here
            //     if (tooltipModel.body) {
            //       tooltipModel.body[0] = "Planned: " + tooltipModel.body[0];
            //       tooltipModel.body[1] = "Actual: " + tooltipModel.body[1];
            //     }
            //   },
            //   backgroundColor: "gray",
            //   titleFontColor: "#fff",
            //   titleFontSize: 14,
            //   titleFontStyle: "bold",
            //   bodyFontColor: "#fff",
            //   bodyFontSize: 12,
            //   bodyFontStyle: "normal",
            //   footerFontColor: "#fff",
            //   footerFontSize: 12,
            //   footerFontStyle: "normal",
            //   xPadding: 10,
            //   yPadding: 10,
            //   cornerRadius: 6,
            // },
          }}
        />
      </div>
    </div>
  );
}
