import { Dialog, Typography, DialogContent, DialogTitle, Box, DialogActions } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast, Slide } from "react-toastify";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { Row, Col, Card } from "reactstrap";
import { TextField } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Select from "react-select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Form from "react-bootstrap/Form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import moment from "moment";
import axios from "../../../axios";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import "./storypoint.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { HANDLE_ON_CHANGE } from "../../../redux/CommonInitialsate";
import { Switch as Switch1 } from 'antd';
import styled from "styled-components";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Bookmark from "@material-ui/icons/Bookmark";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { withStyles } from '@material-ui/core/styles';
import Cookies from "universal-cookie";
import Axios from "axios";

export default function StoryPointClick() {
    const { refinementstorypoint, projectCode, selectedCard, Lane, projectName, refinementselecteddata, storypointtype, optionSelected, KanbanBoardData } = useSelector((state) => state.user);
    console.log(optionSelected, "optionSelecteddddddddddddddddddddddddddddddddddddddddddddddddd", storypointtype)
    const CustomSwitch = styled(Switch)`
    &.ant-switch {
        background-color: #808080; /* Default background color */
    }

    &.ant-switch-checked {
        background-color: #1890ff; /* Checked state background color */
    }
`;
    console.log(refinementselecteddata?.story)
    console.log(KanbanBoardData?.[0])
    const [OptionId, setOptionId] = useState(refinementselecteddata?.linkeddata?.length > 0 ? "SP" : null);
    const dispatch = useDispatch()
    const [originalEst, setOriginalEst] = useState(refinementselecteddata?.story?.OriginalEstimate == null ? 0 : refinementselecteddata?.story?.OriginalEstimate)
    let empid = JSON.parse(localStorage.getItem("Empdetails"));
    let clientd = JSON.parse(localStorage.getItem("clientid"));
    let managerdetails =
        empid?.[0]?.OnboardedByDetails == null
            ? empid?.[0]?.UserFirstname + " " + empid?.[0]?.UserLastname
            : "Scrum Master";

    let managerid =
        empid?.[0]?.OnboardedBy == null
            ? localStorage.getItem("managerid") == "undefined"
                ? ""
                : JSON.parse(localStorage.getItem("managerid"))
            : empid?.[0]?.OnboardedBy;

    const [checkSubmit, setSubmitCheck] = useState(false);

    const [tasktitle, settasktitle] = useState("");
    let initialData = refinementselecteddata?.linkeddata?.length > 0 ? refinementselecteddata?.linkeddata?.map((e, index) => (
        {
            SPCode: index + 1,
            Module: e?.ModuleLinked,
            Techstack: "",
            title: e.IssueTitle,
            Effort: e.OriginalEstimate,
            AssignedTo: e.AssignedTo,
            AssignedToDetails: e.AssignedToDetails,
            PlannedRelease: e.PlannedRelease,
            changedfield: e.changedfield.length > 0 && e.changedfield != undefined ? e.changedfield : []
        }
    )) : [
        {
            SPCode: 1,
            Module: selectedCard?.ModuleLinked,
            Techstack: "",
            title: "",
            Effort: 0,
            AssignedTo: null,
            AssignedToDetails: null,
            PlannedRelease: refinementselecteddata?.story?.PlannedRelease,
            changedfield: []
        },
    ];
    const [rows, setRows] = useState(initialData);
    const [Contigency, setContigency] = useState(refinementselecteddata?.story?.OriginalEstimate ? refinementselecteddata?.story?.OriginalEstimate : 0);
    const [check, setcheck] = useState(true);

    useEffect(() => {
        setRows(initialData)
        setOptionId(refinementselecteddata?.linkeddata?.length > 0 ? "SP" : null)
    }, [refinementselecteddata?.linkeddata])
    const module = [
        {
            label: selectedCard?.ModuleLinked?.FullName,
            value: selectedCard?.ModuleLinked?.FullName,
        }
    ];

    const add =
        rows.length == 1
            ? rows?.[0]?.Effort
            : rows?.map((e) => parseInt(e.Effort)).reduce((n, m) => n + m);

    const Total = parseFloat(Contigency) + parseFloat(add);

    const style = {
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
    };

    const useStyles = makeStyles((theme) => ({
        largeDot: {
            width: "10px",
            height: "10px",
        },
        largeConnector: {
            // height: '100px', // Adjust this as needed
            [theme.breakpoints.up("lg")]: {
                height: "30px", // Adjust for larger screens
            },
            [theme.breakpoints.up("md")]: {
                height: "130px",
            },
            [theme.breakpoints.up("sm")]: {
                height: "150px",
            },
        },
    }));
    const classes = useStyles();

    const cookies = new Cookies();
    function StoryPointClick() {
        if (OptionId == "SE") {

            // const fdata = refinementselecteddata?.key == 1 ? data1 : refinementselecteddata?.key == 2 ? data2 : refinementselecteddata?.key == 3 ? data3 : data4
            // const gdata = fdata?.filter((l) => l?.ParentIssue?.[0]?.IssueId != refinementselecteddata?.story?.IssueId)
            // const updatedData = gdata.map((item) =>
            //     item.IssueId === refinementselecteddata?.story?.IssueId ? {
            //         ...item, OriginalEstimate: originalEst, changed: true, changedfield: item.changedfield && item.changedfield.length > 0
            //             ? [...item.changedfield, 'originalestimate']
            //             : ['originalestimate']
            //     } : item
            // );
            // console.log(updatedData, 'uuuuuuuuuuuuuuuuuuu')
            // refinementselecteddata?.key == 1 ? setData1(updatedData) : refinementselecteddata?.key == 2 ? setData2(updatedData) : refinementselecteddata?.key == 3 ? setData3(updatedData) : setData4(updatedData)
            setSubmitCheck(true)
            axios.patch(`agilesaas_issue_details?IssueId=eq.${refinementselecteddata?.story?.IssueId}`, {
                OriginalEstimate: originalEst
            })
                .then((res) => {
                    if (storypointtype == "release") {
                        const updatedData = optionSelected.map(item =>
                            item.IssueId === refinementselecteddata?.story?.IssueId ? { ...item, OriginalEstimate: originalEst } : item
                        );
                        dispatch(HANDLE_ON_CHANGE({ name: 'optionSelected', value: updatedData }))
                    }
                    axios.patch(`agilesaas_storypoint_estimation?IssueId=eq.${refinementselecteddata?.story?.IssueId}`, {
                        TotalEstimatedEffort: originalEst,
                        UpdatedBy: empid?.[0]?.ClientUserId,
                        UpdatedByDetails: {
                            FN: empid?.[0]?.UserFirstname,
                            LN: empid?.[0]?.UserLastname
                        },
                        UpdatedDate: moment(),
                    })
                        .then((res) => {
                            setSubmitCheck(false)
                            dispatch(HANDLE_ON_CHANGE({ name: "refinementstorypoint", value: false }));
                            dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
                            dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
                            dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Original estimation updated successfully" }));
                            dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "success" }));
                        })
                        .catch((e) => {
                            console.log(e)
                            setSubmitCheck(false)
                            dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
                            dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Something went wrong" }));
                            dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "severity" }));
                        })
                })
                .catch((e) => {
                    console.log(e)
                    dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
                    dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Something went wrong" }));
                    dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "severity" }));
                })

        }
        else {
            let len = 0;
            let issueData = [];
            if (rows.length != 0) {
                if ((check && Contigency != 0) || (!check && Contigency == 0)) {
                    // const fdata = refinementselecteddata?.key == 1 ? data1 : refinementselecteddata?.key == 2 ? data2 : refinementselecteddata?.key == 3 ? data3 : data4
                    // const gdata = fdata?.filter((l) => l?.ParentIssue?.[0]?.IssueId != refinementselecteddata?.story?.IssueId)
                    const fdata = []
                    rows.map((e, i) => {
                        console.log(e, 'eeeeeeeeeeeeeeeeeeeeeeeeeeeee')
                        if (e.title.length == "") {
                            toast.error("Please enter title in row " + (i + 1));
                        }
                        else if (e.Effort == 0 || e.Effort == "") {
                            toast.error("please enter valid effort for row " + (i + 1));
                        } else {
                            len++;
                            let d = {
                                // IssueId: i + '_' + refinementselecteddata?.story?.IssueId,
                                ProjectId: refinementselecteddata?.story?.ProjectId,
                                ProjectShortCode: refinementselecteddata?.story?.ProjectShortCode,
                                IssueType: "SubTask",
                                IssueTitle: e.title,
                                Description: "",
                                Priority: "Medium",
                                PlannedRelease: e.PlannedRelease,
                                OriginalEstimate: e.Effort,
                                EstimateUnit: "MD",
                                EstimateUnitDesc: "Man Days",
                                CurrentStage: [
                                    {
                                        StageCode: "RFD",
                                        StageName: "Refined",
                                        DateMoved: moment().format("YYYY-MM-DD"),
                                    },
                                ],
                                ParentIssue: [
                                    {
                                        IssueId: refinementselecteddata?.story?.IssueId,
                                        Title: refinementselecteddata?.story?.IssueTitle,
                                        CurrentStage: refinementselecteddata?.story?.CurrentStage?.[0]?.StageName,
                                        Priority: refinementselecteddata?.story?.Priority,
                                    },
                                ],
                                CreatedBy: managerid,
                                CreatedByDetails: managerdetails,
                                CreatedDate: moment().format("YYYY-MM-DD"),
                                AssignedTo: e.AssignedTo,
                                AssignedToDetails: e.AssignedToDetails,
                                AssignedDate: moment().format("YYYY-MM-DD"),
                                IsActive: "Y",
                                ProjectDetails: [
                                    {
                                        ProjName: projectName,
                                    },
                                ],
                                ClientId: clientd,
                                ModuleLinked: refinementselecteddata?.story?.ModuleLinked,
                                // changed: true,
                                // changedfield: e.changedfield && e.changedfield.length > 0
                                //     ? [...e.changedfield, 'originalestimate']
                                //     : ['originalestimate']
                            };
                            fdata.push(d)
                            if (fdata?.length == i + 1) {
                                setSubmitCheck(true)
                                Axios({
                                    url: `https://api.devozy.ai/agilesaas_issue_details`,
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + cookies.get("token"),
                                        Prefer: "return=representation",
                                    },
                                    method: "post",
                                    data: fdata,
                                })
                                    .then((spdata) => {
                                        if (storypointtype == "release") {
                                            const fdata = spdata.data.map((y) => {
                                                let fn =
                                                    y.AssignedToDetails.FN !== null ? y.AssignedToDetails.FN : "";
                                                let ln =
                                                    y.AssignedToDetails.LN !== null ? y.AssignedToDetails.LN : "";
                                                return {
                                                    label: y.IssueTitle + "-" + y.IssueId + "-" + ` ${fn}_${ln}`,
                                                    value: y.IssueTitle,
                                                    issueidr: y.IssueId,
                                                    AssignedTo: y.AssignedTo,
                                                    AssignedToDetails: y.AssignedToDetails,
                                                    Estimatedpoint: y.OriginalEstimate,
                                                    IssueTitle: y.IssueTitle,
                                                    IssueId: y.IssueId,
                                                    IssueType: y.IssueType,
                                                    OriginalEstimate: y.OriginalEstimate,
                                                    Priority: y.Priority,
                                                    ProjectShortCode: y.ProjectShortCode,
                                                    ParentIssue: y.ParentIssue
                                                }
                                            })
                                            const combineddata = [...optionSelected, ...fdata]
                                            dispatch(HANDLE_ON_CHANGE({ name: 'optionSelected', value: combineddata }))
                                        }
                                        // console.log(spdata.data)
                                        const historypost = spdata?.data?.map((h) => ({
                                            IssueId: h.IssueId,
                                            ChangedBy: empid?.[0]?.ClientUserId,
                                            ChangedByDetails: {
                                                FN: empid?.[0]?.UserFirstname,
                                                LN: empid?.[0]?.UserLastname
                                            },
                                            ChangedDate: moment(),
                                            UpdatedField: "Kanban Changes",
                                            UpdatedFrom: "",
                                            UpdatedTo: "RFD",
                                            IsActive: "Y",
                                            Status: null,
                                            ClientId: empid?.[0]?.ClientId,
                                            ProjectId: projectCode
                                        }))
                                        const fspdata = spdata?.data?.map((l) => ({
                                            IssueId: l.IssueId,
                                            UpdatedBy: empid?.[0]?.ClientUserId,
                                            UpdatedByDetails: {
                                                FN: empid?.[0]?.UserFirstname,
                                                LN: empid?.[0]?.UserLastname
                                            },
                                            UpdatedDate: moment(),
                                        }))
                                        axios.post('agilesaas_storypoint_estimation', fspdata)
                                            .then((spres) => {
                                                axios.post(`agilesaas_issue_history`, historypost)
                                                    .then((phis) => {
                                                        axios.patch(`agilesaas_issue_details?IssueId=eq.${refinementselecteddata?.story?.IssueId}`, {
                                                            OriginalEstimate: Total
                                                        })
                                                            .then((ogres) => {
                                                                axios.patch(`agilesaas_storypoint_estimation?IssueId=eq.${refinementselecteddata?.story?.IssueId}`, {
                                                                    TotalEstimatedEffort: Total,
                                                                    Contigency: Contigency,
                                                                    UpdatedBy: empid?.[0]?.ClientUserId,
                                                                    UpdatedByDetails: {
                                                                        FN: empid?.[0]?.UserFirstname,
                                                                        LN: empid?.[0]?.UserLastname
                                                                    },
                                                                    UpdatedDate: moment(),
                                                                })
                                                                    .then((sp) => {
                                                                        dispatch(HANDLE_ON_CHANGE({ name: "refinementstorypoint", value: false }));
                                                                        dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
                                                                        dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
                                                                        dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Original estimation updated successfully" }));
                                                                        dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "success" }));
                                                                    })
                                                                    .catch((e) => { console.log(e) })
                                                            })
                                                            .catch((e) => console.log(e))

                                                    })
                                                    .catch((e) => {
                                                        setSubmitCheck(false)
                                                        dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
                                                        dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Something went wrong" }));
                                                        dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "severity" }));
                                                    })
                                            })
                                            .catch((e) => {
                                                setSubmitCheck(false)
                                                dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
                                                dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Something went wrong" }));
                                                dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "severity" }));
                                            })

                                    })
                            }
                            console.log(rows?.length, i, rows?.length == i, 'checkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk', fdata)
                            // issueData.push(d);
                            // gdata.push(d)
                            // const updatedData = gdata.map(item => {
                            //     if (item.IssueId === refinementselecteddata?.story?.IssueId) {
                            //         const updatedChangedfield = item.changedfield && item.changedfield.length > 0
                            //             ? [...item.changedfield, 'originalestimate']
                            //             : ['originalestimate'];

                            //         return {
                            //             ...item,
                            //             OriginalEstimate: Total,
                            //             changed: true,
                            //             changedfield: updatedChangedfield
                            //         };
                            //     }
                            //     return item;
                            // });

                            // refinementselecteddata?.key == 1 ? setData1(updatedData) : refinementselecteddata?.key == 2 ? setData2(updatedData) : refinementselecteddata?.key == 3 ? setData3(updatedData) : setData4(updatedData)
                            // dispatch(HANDLE_ON_CHANGE({ name: "refinementstorypoint", value: false }));
                            // setOptionId(null)
                        }
                    });
                } else {
                    toast.error("Please enter contigency");
                }
            }
        }
    }

    const handleDeleteRows = (i) => {
        let data = [...rows];
        data.splice(i, 1);
        setRows(data);
    };

    const handlechangedes = (i, value) => {
        let data = [...rows];
        data.splice(i, 1, {
            SPCode: i + 1,
            Module: rows[i].Module,
            Techstack: rows[i].Techstack,
            title: value,
            Effort: rows[i].Effort,
            AssignedTo: rows[i].AssignedTo,
            AssignedToDetails: rows[i].AssignedToDetails,
            PlannedRelease: rows[i].PlannedRelease,
            changedfield: rows[i].changedfield
        });
        setRows(data);
    };

    const modulechange = (i, item) => {
        let data = [...rows];
        data.splice(i, 1, {
            SPCode: i + 1,
            Module: item.value,
            Techstack: rows[i].Techstack,
            title: rows[i].title,
            Effort: rows[i].Effort,
            AssignedTo: rows[i].AssignedTo,
            AssignedToDetails: rows[i].AssignedToDetails,
            PlannedRelease: rows[i].PlannedRelease,
            changedfield: rows[i].changedfield
        });
        setRows(data);
    };

    const effortchange = (i, value) => {
        let data = [...rows];
        data.splice(i, 1, {
            SPCode: i + 1,
            Module: rows[i].Module,
            Techstack: rows[i].Techstack,
            title: rows[i].title,
            Effort: parseInt(value),
            AssignedTo: rows[i].AssignedTo,
            AssignedToDetails: rows[i].AssignedToDetails,
            PlannedRelease: rows[i].PlannedRelease,
            changedfield: rows[i].changedfield
        });
        setRows(data);
    };

    const handleAddRows = (e) => {
        e.preventDefault();
        let adddata = [
            ...rows,
            {
                SPCode: "",
                Module: refinementselecteddata?.story?.ModuleLinked,
                Techstack: "",
                title: "",
                Effort: 0,
                AssignedTo: null,
                AssignedToDetails: null,
                PlannedRelease: 'New sprint',
                changedfield: []
            },
        ];

        setRows(adddata);
    };

    const handleClose = () => {
        dispatch(HANDLE_ON_CHANGE({ name: 'refinementstorypoint', value: false }))
        setOptionId(null)
        // dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
    };

    const handlechoose = (e) => {
        setOptionId(e)
    }

    const handleChange = (event) => {
        console.log('Switch checked:', event.target.checked);
        console.log(!check)
        const isChecked = event.target.checked;
        setcheck(isChecked);
        setContigency(0);
    };

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    return (
        <Dialog
            PaperProps={{
                style: {
                    height: '65vh', // Set the height here
                    width: '50vw',  // Set the width here
                    maxWidth: '50vw'
                },
            }}
            open={refinementstorypoint}
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <ArrowBackIcon
                            onClick={() => handlechoose(null)}
                            style={{ cursor: "pointer", marginRight: 8 }}
                        />
                        <Typography style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: "DM Sans, sans-serif",
                            fontSize: 16,
                            fontWeight: 700,
                        }}>
                            <CheckCircleOutlineIcon color="success" style={{ marginRight: 8 }} />
                            {refinementselecteddata?.story?.IssueTitle}
                        </Typography>
                    </Box>
                    <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                </Box>
            </DialogTitle>

            <Divider />

            <DialogContent style={{ padding: "10px 24px" }}>
                <>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 9,
                    }}>
                        <Typography>{" "}</Typography>

                        <Typography
                            variant="h6"
                            style={{
                                textAlign: "left",
                                margin: 0,
                                fontFamily: "DM Sans, sans-serif",
                                fontSize: 16,
                                fontWeight: 800,
                            }}
                        >
                            {OptionId == "SP" &&
                                <>Total: {Total} Days
                                    <span> {"( 1 day = 1 story point )"} </span></>
                            }
                        </Typography>


                    </div>
                    {
                        OptionId == null ?
                            <div>

                                <Row>
                                    <Col className="d-flex jsutify-content-start ml-4">
                                        <Typography
                                            style={{
                                                fontFamily: "DM Sans, sans-serif;",
                                                fontSize: 16,
                                                fontWeight: 700,
                                            }}
                                        >
                                            Estimate the Story using one of the below options:
                                        </Typography>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={5} sm={6} md={5}>
                                        <div className="d-flex justify-content-start ">
                                            {" "}
                                            <List
                                                sx={style}
                                                component="nav"
                                                aria-label="mailbox folders"
                                            >
                                                <ListItem
                                                    button
                                                // onClick={() => handlesubmit("S")}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <ScheduleSendIcon
                                                                className="fontdesign"
                                                                fontSize="small"
                                                            />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary="Scrum / Planning  poker Estimation"
                                                        secondary="coming soon"
                                                    />
                                                </ListItem>
                                                <Divider variant="inset" component="li" />
                                                <ListItem button onClick={() => handlechoose("SP")}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <Bookmark className="fontdesign" fontSize="small" />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary="Break Sub-tasks and Estimate"
                                                        secondary="Break the story into smaller sub-tasks and estimate them. Easier to assign to multiple team members."
                                                    />
                                                </ListItem>
                                                <Divider variant="inset" component="li" />
                                                <ListItem button onClick={() => handlechoose("SE")}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <CheckBoxRoundedIcon
                                                                className="fontdesign"
                                                                fontSize="small"
                                                            />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary="Simple Estimation"
                                                        secondary="Estimate the store as a whole like a single task."
                                                    />
                                                </ListItem>

                                            </List>
                                        </div>
                                    </Col>
                                    <Col lg={4} sm={6} md={4}>
                                        <div className="d-flex justify-content-end mt-2">
                                            <Timeline position="right" style={{ height: "100%" }}>
                                                <TimelineItem style={{ minHeight: "310px" }}>
                                                    <TimelineOppositeContent color="textSecondary">
                                                        FROM
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineDot
                                                            color="primary"
                                                            variant="filled"
                                                            className={classes.largeDot}
                                                        />
                                                        <TimelineConnector
                                                            className={classes.largeConnector}
                                                        />
                                                    </TimelineSeparator>

                                                    <TimelineContent className="fontdesign">
                                                        {Lane?.Source}
                                                    </TimelineContent>
                                                </TimelineItem>
                                                <TimelineItem>
                                                    <TimelineOppositeContent color="textSecondary">
                                                        TO
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineDot
                                                            color="success"
                                                            className={classes.largeDot}
                                                        />
                                                    </TimelineSeparator>
                                                    <TimelineContent>{Lane?.Target}</TimelineContent>
                                                </TimelineItem>
                                            </Timeline>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            :
                            OptionId == "SE" ?
                                // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                //     <TextField
                                //         variant="outlined"
                                //         label="Original-Estimate"
                                //         name="OriginalEstimate"
                                //         size="small"
                                //         onChange={(e) => setOriginalEst(e.target.value)}
                                //     />
                                // </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center', // Centers content horizontally
                                        alignItems: 'center',     // Centers content vertically
                                        flexDirection: 'column',  // Stacks children vertically
                                        height: '100%',          // Ensures full viewport height, adjust if necessary
                                        textAlign: 'center'      // Centers text horizontally
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "DM Sans, sans-serif",
                                            fontSize: 16,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Please enter Original Estimate
                                    </div>
                                    <div style={{ marginTop: '16px' }}> {/* Adjust margin for spacing */}
                                        <Row className="mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Col>
                                                <TextField
                                                    variant="outlined"
                                                    label="Original Estimate"
                                                    name="OriginalEstimate"
                                                    size="small"
                                                    className="pb-3"
                                                    value={originalEst}
                                                    onChange={(e) => setOriginalEst(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                :
                                OptionId == "SP" ?
                                    <div style={{ display: 'flex', flexDirection: 'column', height: '94%' }}>
                                        <Card variant="outlined" style={{ margin: 0 }}>
                                            <Grid container spacing={2}>
                                                <Grid item lg={4} md={4} sm={6} xs={6}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontFamily: "DM Sans, sans-serif",
                                                            fontSize: 16,
                                                            fontWeight: 500,
                                                            textAlign: "left",
                                                            margin: 0,
                                                        }}
                                                    >
                                                        Story Point Description
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={6} xs={6}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontFamily: "DM Sans, sans-serif",
                                                            fontSize: 16,
                                                            fontWeight: 500,
                                                            textAlign: "left",
                                                            margin: 0,
                                                        }}
                                                    >
                                                        Module
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={6} xs={6}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontFamily: "DM Sans, sans-serif",
                                                            fontSize: 16,
                                                            fontWeight: 500,
                                                            textAlign: "left",
                                                            margin: 0,
                                                        }}
                                                    >
                                                        Estimation
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Divider />

                                            <form style={{ padding: "10px 0", flexGrow: 1 }}>
                                                {rows?.map((e, i) => (
                                                    <div className="form-inline" key={i}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                                                <input
                                                                    id={i}
                                                                    name={i}
                                                                    className="form-control"
                                                                    type="text"
                                                                    onChange={(event) => handlechangedes(i, event.target.value)}
                                                                    value={e.title}
                                                                    placeholder="Enter Description"
                                                                    style={{
                                                                        borderRadius: 5,
                                                                        height: 40,
                                                                        margin: "10px 0",
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                                                {rows?.[i]?.Module ? (
                                                                    <Select
                                                                        id={i}
                                                                        name={i}
                                                                        options={module}
                                                                        isDisabled
                                                                        onChange={(item) => modulechange(i, item)}
                                                                        value={module.filter(
                                                                            (option) => option.value === rows[i].Module?.FullName
                                                                        )}
                                                                        className="Select-Dropdown"
                                                                    />
                                                                ) : (
                                                                    <label> -- </label>
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={4}
                                                                md={4}
                                                                sm={6}
                                                                xs={6}
                                                                style={{ display: "flex", alignItems: "center" }}
                                                            >
                                                                <input
                                                                    className="form-control"
                                                                    id={i}
                                                                    name={i}
                                                                    type="number"
                                                                    value={rows[i].Effort}
                                                                    onChange={(event) => effortchange(i, event.target.value)}
                                                                    style={{
                                                                        borderRadius: 5,
                                                                        height: 40,
                                                                        margin: "10px 0",
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                                {rows.length > 1 && (
                                                                    <Typography
                                                                        onClick={() => handleDeleteRows(i)}
                                                                        style={{ cursor: "pointer", marginLeft: 8 }}
                                                                    >
                                                                        <DeleteForeverIcon color="error" />
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}
                                                <div className="button-section">
                                                    <Button
                                                        type="button"
                                                        size="small"
                                                        onClick={(e) => handleAddRows(e)}
                                                        style={{
                                                            color: "green",
                                                            textTransform: "none",
                                                            backgroundColor: "#fff",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <AddCircleOutlineIcon style={{ fontSize: 14 }} />
                                                        Add additional tab
                                                    </Button>
                                                </div>
                                            </form>
                                        </Card>
                                        <div style={{ display: "flex", flexDirection: "row", width: "25%", marginTop: "auto", }}>
                                            <Form.Group
                                                as={Col}
                                                style={{ display: "flex", flexDirection: "column" }}
                                            >
                                                {/* <FormControlLabel
                                                    size="small"
                                                    control={ 
                                                        // <CustomSwitch
                                                        //     // defaultChecked 
                                                        //     // checked={check}
                                                        //     onChange={(checked) => {
                                                        //         setcheck(checked);
                                                        //         setContigency(0);
                                                        //     }}
                                                        // // onChange={onChange} 
                                                        // />
                                                        // <CustomSwitch
                                                        //     // className="mt-3 mb-3"
                                                        //     // checkedChildren="Planned"
                                                        //     // unCheckedChildren="Un Planned"
                                                        //     checked={check}
                                                        //     // backgroundColor=""
                                                        //     size="default"
                                                        //     onChange={(checked) => {
                                                        //         console.log(checked)
                                                        //         setcheck(checked)
                                                        //         setContigency(0)
                                                        //     }}
                                                        // />
                                                        // <CustomSwitch
                                                        //     checked={check}
                                                        //     // onChange={handleChange}
                                                        //     onClick={(checked) => handleChange(checked)}
                                                        // />*/}
                                                {/* <Switch
                                                // color="secondary"
                                                defaultChecked
                                                checked={check}
                                                onClick={() => {
                                                    setcheck(!check);
                                                    setContigency(0);
                                                }}

                                            /> */}
                                                {/* //     }
                                                //     label="Contigency"
                                                //     className="plansprint-form-switch"
                                                // /> */}
                                                <Form.Check
                                                    size="lg"
                                                    className="mt-3"
                                                    type="switch"
                                                    id="custom-switch"
                                                    onChange={handleChange}
                                                    checked={check}
                                                    label="Contingency"
                                                />
                                            </Form.Group>
                                            {check && (
                                                <input
                                                    type="number"
                                                    onChange={(event) => setContigency(event.target.value)}
                                                    value={Contigency}
                                                    placeholder="Enter Contigency"
                                                    style={{
                                                        borderRadius: 5,
                                                        height: 36,
                                                        width: 90,
                                                        margin: "10px 0",
                                                        // alignSelf: "center",
                                                        alignItems: 'center',
                                                        borderWidth: 1,
                                                        borderColor: "grey",
                                                        padding: 5,
                                                        fontSize: 14,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    :
                                    ""
                    }

                </>
            </DialogContent>
            {
                OptionId !== null &&
                <DialogActions>
                    <div className="d-flex justify-content-end">
                        <Button
                            style={{
                                fontSize: 14,
                                padding: 9,
                                width: 100,
                                height: 40,
                                borderRadius: 8,
                                backgroundColor: "#5250E5",
                                color: "#fff",
                                border: "0px solid #5A88F1",
                                textAlign: "center",
                                textTransform: "none",
                            }}
                            disabled={checkSubmit}
                            onClick={() => {
                                StoryPointClick();
                            }}
                        >Create</Button>
                    </div>
                </DialogActions>
            }

        </Dialog>
    );
    //   }
};