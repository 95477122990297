import React, { useEffect, useState } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ChangeModule from "./Changemodule";
import Assignedchange from "./Assignedchange";
import "./change.css";

export default function Changenew() {
  const defaultActiveKey = "Incident"; // Set the default active key

  // State to manage the active key
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  return (
    <Tabs
      defaultActiveKey="Incident"
      id="justify-tab-example"
      className="horizontal-tabs ml-4 mt-3"
      variant="tabs"
      onSelect={(key) => setActiveKey(key)}
    >
      <Tab
        eventKey="Incident"
        title="My Change Request"
        className="headings"
        defaultActiveKey="Incident"
      >
        <ChangeModule />
      </Tab>
      <Tab
        eventKey="MyIncident"
        title="Assigned Change"
        className="headings ml-2"
      >
        <Assignedchange />
      </Tab>
    </Tabs>
  );
}
