import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MaterialTable from "material-table";
import PatchedPagination from "../../PatchedPagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Card, Row, Tab } from "react-bootstrap";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#64c4e5',
    color: "#000",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function SprintDeploymentDetails({
  devozyProjects,
  startDate,
  endDate,
  currentSprint,
}) {
  const [isMouseOverOverlay, setisMouseOverOverlay] = useState(false);
  const [counter, setcounter] = useState(false);

  const [sprintdata, setSprintData] = useState([]);
  const [lastthreedays, setLastthreedays] = useState([]);
  const [loading, setLoading] = useState(true);

  let empid = JSON.parse(localStorage.getItem("Empdetails"));
  let clientid =
    localStorage.getItem("clientid") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("clientid"));
  const company = empid?.[0]?.ClientDetails?.Company?.replace(/ /g, "");
  let tenantid = `devozy_${company}_${clientid}`;

  const cookies = new Cookies();
  const projects = devozyProjects?.map((e) => e.ProjCode);

  useEffect(() => {
    setLoading(true);
    axios({
      url: `https://api.devozy.ai/api/v1/devozy/connectedview/sprintdata`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        tenantid: tenantid,
        Authorization: "Bearer " + cookies.get("token"),
      },
      method: "post",
      data: {
        projectids: projects,
        startDate: startDate,
        endDate: endDate,
      },
    })
      .then((res) => {
        setLoading(false);
        console.log(
          res.data?.filter(
            (e) => moment(endDate).diff(moment(e.CreatedAt), "day") < 3
          )
        );

        // console.log(endDate)
        // console.log(moment(endDate).diff(
        //     moment(startDate),
        //     "day"
        // ), "dayyyyyyyyyyyyyyyyyyyyyy")
        // setSprintData(res.data)
        const projectdata = devozyProjects.map((e) => ({
          projectcode: e?.ProjCode,
          projectname: e?.ProjDesc,
          data: res.data?.filter((x) => x.ProjectCode == e?.ProjCode),
        }));
        if (currentSprint) {
          setLastthreedays(res.data);
        } else {
          setLastthreedays(
            res.data?.filter(
              (e) => moment(endDate).diff(moment(e.CreatedAt), "day") < 3
            )
          );
        }
        // console.log(projectdata, "check data")
        if (res?.data?.length) {
          setSprintData(projectdata);
        } else {
          setSprintData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [startDate]);

  // console.log(lastthreedays?.length, "deployment details")
  const columns = [
    { title: "Project Name", field: "projectname" },
    { title: "Deployment Version", field: "DeploymentVersion" },
    { title: "Deployed By", field: "DeployedBy" },
    { title: "Date & Time", field: "DateTime" },
    { title: "Deployment Notes", field: "DeploymentNotes" },
  ];
  const columns1 = [
    { title: "Deployment Version", field: "DeploymentVersion" },
    { title: "Deployed By", field: "DeployedBy" },
    { title: "Date & Time", field: "DateTime" },
    { title: "Deployment Note", field: "DeploymentNote" },
  ];
  // console.log(lastthreedays?.length);
  return (
    <div>
      {loading ? (
        <div
          className=" d-flex align-items-center"
          style={{ justifyContent: "center", height: 460 }}
        >
          <span
            className="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "rgb(82, 80, 229)" }}
          ></span>
        </div>
      ) : (
        <div>
          {/* {console.log(lastthreedays, "sprintdata?.length")} */}
          {sprintdata?.length == 0 && (
            <div
              className=" d-flex align-items-center"
              style={{ justifyContent: "center", height: 460 }}
            >
              <label>No deployment data for this sprint</label>
            </div>
          )}

          {lastthreedays && lastthreedays?.length != 0 && (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <MaterialTable
                title={
                  <span
                    style={{
                      color: "#008cba",
                      fontWeight: "bolder",
                      fontSize: 18,
                    }}
                  >
                    {currentSprint
                      ? "Current Sprint Deployments"
                      : "Last 3 Days deployment of the sprint"}
                  </span>
                }
                columns={columns.map((e) => e)}
                components={{
                  Pagination: PatchedPagination,
                }}
                data={lastthreedays
                  ?.sort(
                    (a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt)
                  )
                  .map((x, index) => {
                    return {
                      ...x,
                      projectname: devozyProjects?.filter(
                        (a) => a.ProjCode == x.ProjectCode
                      )?.[0]?.ProjDesc,
                      DeploymentVersion: (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label>{`${x?.DeploymentVersion}`}</label>
                          <label>{x?.DeploymentCode}</label>
                        </div>
                      ),
                      DeployedBy: x?.CreatedBy?.split("@")?.[0],
                      DateTime: moment(x?.CreatedAt).format(
                        "DD MMM YYYY hh:mm A"
                      ),
                      DeploymentNote: x?.DeploymentNote
                        ? x?.DeploymentNote
                        : "-",
                      DeploymentNotes:
                        x.DeploymentNotes.length > 0 ? (
                          <>
                            <OverlayTrigger
                              placement="left"
                              trigger={"hover"}
                              delay={{ show: 50, hide: 3000 }}
                              show={counter === index && isMouseOverOverlay}
                              overlay={
                                <Card
                                  className="shadow-sm"
                                  style={{
                                    zIndex: 99999,
                                    width: "400px",
                                    height: "190px",
                                    overflowY: "auto",
                                  }}
                                  onMouseEnter={() => {
                                    setisMouseOverOverlay(true);
                                    setcounter(index);
                                  }}
                                  onMouseLeave={() => {
                                    setisMouseOverOverlay(false);
                                  }}
                                >
                                  <Card.Body
                                    style={{
                                      maxHeight: "350px",
                                      overflowY: "auto",
                                      border: "1px solid #E6E8EC",
                                    }}
                                  >
                                    {/* {console.log(
                                      devozyProjects?.filter(
                                        (a) => a.ProjCode == x.ProjectCode
                                      )?.[0]?.ProjDesc
                                    )} */}
                                    {/* {console.log(index)} */}
                                    {/* {console.log(x.DeploymentNotes)} */}
                                    {x.DeploymentNotes.length === 0 ? (
                                      "No content here"
                                    ) : x.DeploymentNotes?.filter(
                                      (z) =>
                                        z?.module?.FullName?.toLowerCase() ==
                                        devozyProjects
                                          ?.filter(
                                            (a) => a.ProjCode == x.ProjectCode
                                          )?.[0]
                                          ?.ProjDesc.toLowerCase()
                                    )?.length == 0 ? (
                                      <div className="d-flex justify-content-center">
                                        <span className="font-weight-bold">
                                          No Module linked to these issues
                                        </span>
                                      </div>
                                    ) : (
                                      x.DeploymentNotes?.filter(
                                        (z) =>
                                          z?.module?.FullName?.toLowerCase() ==
                                          devozyProjects
                                            ?.filter(
                                              (a) => a.ProjCode == x.ProjectCode
                                            )?.[0]
                                            ?.ProjDesc.toLowerCase()
                                      )?.map((e, index) => {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              marginBottom: "10px",
                                              fontSize: "8px",
                                              fontWeight: 800,
                                            }}
                                          // className="border"
                                          >
                                            <p
                                              style={{
                                                margin: "5px 0",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              <i
                                                className="mdi mdi-card-text-outline"
                                                style={{
                                                  marginRight: "3px",
                                                  color: "#007bff",
                                                }}
                                              ></i>

                                              <span
                                                style={{ color: "#007bff" }}
                                              >
                                                {e.issueid}
                                                {" - "}
                                              </span>
                                              <span>{e.issuetitle}</span>
                                            </p>
                                            {/* <p style={{ margin: "5px 0" }}>
                                              <i
                                                className="mdi mdi-format-title"
                                                style={{ marginRight: "5px" }}
                                              ></i>
                                              <span
                                                style={{ fontStyle: "bold" }}
                                              >
                                                {e.issuetitle}
                                              </span>
                                            </p> */}
                                          </div>
                                        );
                                      })
                                    )}
                                  </Card.Body>
                                </Card>
                              }
                            >
                              <p
                                className="text-muted"
                                onMouseEnter={() => {
                                  setisMouseOverOverlay(true);
                                  setcounter(index);
                                }}
                                onMouseLeave={() => {
                                  setisMouseOverOverlay(false);
                                }}
                              >
                                {x.DeploymentNotes.length === 0
                                  ? ""
                                  : x.DeploymentNotes[0].sprint}
                                {/* {console.log(x.DeploymentNotes[0].sprint)} */}
                                {/* //goto */}
                              </p>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <></>
                        ),
                    };
                  })}
                options={{
                  actionsColumnIndex: -1,
                  exportAllData: false,
                  exportButton: false,
                  columnResizable: true,
                  filtering: false,
                  sorting: false,
                  paging: false,
                  pageSize: 20,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions: [20, 30, 40, 50],
                  search: false,
                  headerStyle: {
                    whiteSpace: "nowrap",
                    fontWeight: "700",
                    fontSize: "medium",
                    // backgroundColor: "#eef2f7",
                    color: "black",
                    // padding: "9px ",
                    textAlign: "left",
                    zIndex: 1
                  },
                  cellStyle: {
                    textAlign: "left",
                    fontSize: "medium",
                    padding: "21px",
                    color: "#6c757d",
                    whiteSpace: "nowrap",
                  },
                  rowStyle: (rowdata) => {
                    if (rowdata.tableData.id % 2 != 0) {
                      // return { backgroundColor: "#cddef085" };
                    }
                  },
                }}
              />
            </div>
          )}
          {sprintdata &&
            sprintdata?.length != 0 &&
            sprintdata?.map((e, index) => (
              <div style={{ marginTop: 20, marginBottom: 20 }} key={index}>
                {e?.data?.length > 0 ? (
                  <div>
                    <MaterialTable
                      title={
                        <span
                          style={{
                            color: "#008cba",
                            fontWeight: "bolder",
                            fontSize: 18,
                          }}
                        >
                          {e?.projectname}
                        </span>
                      }
                      columns={columns1.map((e) => e)}
                      components={{
                        Pagination: PatchedPagination,
                      }}
                      data={e?.data
                        ?.sort(
                          (a, b) =>
                            new Date(b.CreatedAt) - new Date(a.CreatedAt)
                        )
                        .map((x, i) => {
                          return {
                            ...x,
                            DeploymentVersion: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label>{`${x?.DeploymentVersion}`}</label>
                                <label>{x?.DeploymentCode}</label>
                              </div>
                            ),
                            DeployedBy: x?.CreatedBy?.split("@")?.[0],
                            DateTime: moment(x?.CreatedAt).format(
                              "DD MMM YYYY hh:mm A"
                            ),
                            DeploymentNote: x?.DeploymentNote
                              ? x?.DeploymentNote
                              : "-",
                          };
                        })}
                      options={{
                        actionsColumnIndex: -1,
                        exportAllData: false,
                        exportButton: false,
                        columnResizable: true,
                        filtering: false,
                        sorting: false,
                        paging: false,
                        search: false,
                        // pageSize: 20,
                        emptyRowsWhenPaging: false,
                        // pageSizeOptions: [20, 30, 40, 50],

                        headerStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "700",
                          fontSize: "medium",
                          // backgroundColor: "#eef2f7",
                          color: "black",
                          // padding: "9px ",
                          textAlign: "left",
                        },
                        cellStyle: {
                          textAlign: "left",
                          fontSize: "medium",
                          padding: "21px",
                          color: "#6c757d",
                          whiteSpace: "nowrap",
                        },
                        rowStyle: (rowdata) => {
                          if (rowdata.tableData.id % 2 != 0) {
                            // return { backgroundColor: "#cddef085" };
                          }
                        },
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default SprintDeploymentDetails;
