import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Col, Row } from "reactstrap";
import { styled } from "@material-ui/styles";
import axios from "../../../axios";
import Toolbar, { modules, formats } from "../Toolbar";
import { toast } from "react-toastify"
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { HANDLE_ON_CHANGE } from "../../../redux/CommonInitialsate";


function ProgressEditForm({
  editformOpen,
  progressEditdata,
  loading,
  setEditformOpen,
  fname,
  lname,
  date,
  apidata,
  BeforeData,
  // SelectedCardId,
  originalestimatedata,
  Reupdatedata,
  // KBRefresh,
  setLoading,
  // progressdata,
  // setProgressData
}) {
  const { KanbanBoardData, projectDetails, settingDetails, projectModules, projectRoleName, projectRole, projectCode, SelectedCardId } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const ee = progressEditdata[0];


  const id = ee.IssueProgressId
  const [editcount, setEditcount] = useState(ee.EffortBurnt);
  const [editnotes, setEditnotes] = useState(ee.Notes);
  const [editpercentage, setEditpercentage] = useState(ee.PercentageProgressed);
  const [checksubmit, setCheckSubmit] = useState(false)

  const userId = ee.UpdatedBy


  const BootstrapButton = styled(Button)({
    textTransform: "none",
    fontSize: 13,
    padding: "6px 12px",
    margin: 10,
    border: "0px solid",
  });


  const UPDATED_NOTES_APIDATA =
    !loading && apidata
      ? apidata
        .sort((a, b) =>
          a.IssueProgressId > b.IssueProgressId
            ? 1
            : a.IssueProgressId < b.IssueProgressId
              ? -1
              : null
        )
        .map((v) => [
          v.EffortRemaining,
          v.EffortBurnt,
          v.PercentageProgressed,
        ])
      : "";


  const FINALE_OLD_DATA =
    UPDATED_NOTES_APIDATA.length == 0
      ? [0]
      : UPDATED_NOTES_APIDATA[UPDATED_NOTES_APIDATA.length - 1];





  const EffortPending = originalestimatedata[0].OriginalEstimate;



  let ms = BeforeData == undefined ? parseInt(EffortPending) - parseInt(editcount)
    : parseInt(BeforeData.EffortRemaining) - parseInt(editcount)
  console.log(editcount)
  function Submitdata(m) {
    m.preventDefault();
    if (editcount) {
      setCheckSubmit(true)




      let New_burnt =
        (UPDATED_NOTES_APIDATA.length !== 0 ?
          (UPDATED_NOTES_APIDATA.reduce((e, f) => e + f[1]), editcount)
          : editcount)



      let New_Edit_actual_percentage = (New_burnt * 100) / EffortPending

      let New_lag = New_Edit_actual_percentage - editpercentage

      let days = (New_Edit_actual_percentage * EffortPending) / 100

      let New_edit_remining_days = EffortPending - days

      let New_lag_in_days = ((New_lag * EffortPending) / 100);

      let New_edit_lag_Hours = New_lag_in_days * 8

      let New_actual_remining_hours = (EffortPending * 8) - (New_burnt * 8)

      let New_edit_total_catchup_hours = New_actual_remining_hours + New_edit_lag_Hours

      let New_edit_final_date = FINALE_OLD_DATA[0] < 0 ? 0 : New_edit_total_catchup_hours / New_edit_remining_days


      let risk_of_delivery =
        EffortPending < FINALE_OLD_DATA[0] || EffortPending < New_burnt || New_edit_final_date.toFixed(0) > 10 ? "R" :
          New_edit_final_date.toFixed(0) <= 8 && New_edit_final_date.toFixed(0) > 0 ? "G" :
            New_edit_final_date.toFixed(0) <= 10 && New_edit_final_date.toFixed(0) >= 8 ? "A" : "G"


      try {
        if (
          editcount !== "" &&
          editpercentage != "" &&
          editnotes != ""
        ) {
          let updatenote = {
            IssueId: SelectedCardId,
            UpdatedBy: userId,
            UpdatedByDetails: {
              FN: fname,
              LN: lname,
            },
            UpdatedDate: date,
            Notes: editnotes,
            UpdateSequence: 1,
            Attachement: null,
            EffortBurnt: editcount,
            EffortBurntUnit: "M",
            PercentageProgressed: editpercentage,
            RiskofDelivery: risk_of_delivery,
            HoursPerDayToComplete: New_edit_final_date.toFixed(0) == "Infinity" || New_edit_final_date.toFixed(0) == -0 || New_edit_final_date.toFixed(0) == "NaN" || New_edit_final_date.toFixed(0) < 0 ? 0 : New_edit_final_date.toFixed(0),
            IsActive: "Y",
            Status: null,
            EffortPending: EffortPending,
            EffortRemaining: ms,
          };

          axios
            .patch("agilesaas_issue_progress?IssueProgressId=eq." + id,
              updatenote
            )
            .then((res) => {
              // console.log(res.data);
              toast.success("Progress Edited")
              setEditformOpen(false);
              Reupdatedata()
              // KBRefresh()
              dispatch(HANDLE_ON_CHANGE({ name: 'kbrefreshstate', value: true }))
              axios.get(`agilesaas_issue_progress?select=RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed?ProjectId=eq.${projectCode}`).then((response) => {
                const latestEntries = {};
                response.data.forEach(entry => {
                  if (!latestEntries[entry.IssueId] || entry.UpdatedDate > latestEntries[entry.IssueId].UpdatedDate) {
                    latestEntries[entry.IssueId] = entry;
                  }
                });

                const finalData = Object.values(latestEntries);
                // setData(finalData);
                // setProgressData(finalData)
                dispatch(HANDLE_ON_CHANGE({ name: 'progressdata', value: finalData }))
                setCheckSubmit(false)
                // console.log(finalData, 'finalData')
                // console.log(response.data)
              })
                .catch((e) => { console.log(e, "error") })
            });

          // console.log(updatenote);
        }
      } catch (error) {
        console.log(error);
      }
    }
    else {
      dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
      dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Please enter effort burnt" }));
      dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "warning" }));
    }
  }

  const handleclose = () => {
    setLoading(false)
    setEditformOpen(false)
  }




  return (
    <div className="w-100">
      <div>
        {editformOpen && !loading ? (
          <div>
            {progressEditdata &&
              progressEditdata.map((e, i) => {
                return (
                  <div key={i} className="w-100">
                    <form onSubmit={Submitdata} className="mt-1">
                      <Toolbar />

                      <div className="d-flex flex-column">
                        <ReactQuill
                          className="quill"
                          theme="snow"
                          value={editnotes}
                          onChange={(z) => setEditnotes(z)}
                          modules={modules}
                          formats={formats}
                        />
                        <div className="d-flex flex-column mx-5">
                          <Row className="mt-2">
                            <Col xs={6} className="d-flex">
                              <label className="progress_update_lebel fontdesign">
                                Effort Pending
                              </label>
                              <p className="progress_update_count_box ml-5 fontdesign">
                                {EffortPending}
                              </p>
                            </Col>
                            <Col xs={6} className="d-flex">
                              <label className="progress_update_lebel fontdesign">
                                Effort Remaining
                              </label>
                              <p className="progress_update_count_box ml-5 fontdesign">
                                {e.EffortRemaining}
                              </p>
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col xs={6} className="d-flex">
                              <label className="progress_update_lebel fontdesign">
                                Work Percentage
                              </label>
                              <select
                                value={editpercentage}
                                onChange={(z) =>
                                  setEditpercentage(z.target.value)
                                }
                                className="progress_update_select text-center"
                              >
                                <option value="10">10%</option>
                                <option value="20">20%</option>
                                <option value="30">30%</option>
                                <option value="40">40%</option>
                                <option value="50">50%</option>
                                <option value="60">60%</option>
                                <option value="70">70%</option>
                                <option value="80">80%</option>
                                <option value="90">90%</option>
                                <option value="100">100%</option>
                              </select>
                            </Col>

                            <Col xs={6} className="d-flex">
                              <label className="progress_update_lebel fontdesign">
                                Effort Burnt
                              </label>
                              <div className="d-flex flex-column align-items-center">
                                <input
                                  type="number"
                                  className="progress_update_input text-center ml-4"
                                  value={editcount}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (!value.includes('.')) {
                                      setEditcount(value);
                                    }
                                  }}
                                />
                                <div className="ml-4 pt-1">
                                  <button
                                    type="button"
                                    onClick={() => setEditcount(parseInt(editcount) + 1)}
                                    className="progress_update_button Card_Button_Color_Approve btn-blueg"
                                  >
                                    +
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if (editcount <= 1) {
                                        // console.log(editcount);
                                      } else {
                                        setEditcount(parseInt(editcount) - 1);
                                      }
                                    }}
                                    className="progress_update_button Card_Button_Color_Approve btn-blueg"
                                  >
                                    -
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mt-1">


                        <Button
                          style={{ fontSize: 14, padding: 9, width: 100, height: 40, borderRadius: 8, marginRight: 14, border: '2px solid #5250E5', backgroundColor: '#fff', color: '#5250E5', textAlign: 'center', textTransform: 'none' }}
                          // type="submit"
                          onClick={() => { handleclose() }}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={checksubmit}
                          style={{ fontSize: 14, padding: 9, width: 100, height: 40, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center', textTransform: 'none' }}
                          type="submit"
                        >
                          {checksubmit ? (
                            <span
                              className="spinner-border"
                              role="status"
                              aria-hidden="true"
                              style={{
                                color: "#fff",
                                width: "1.5rem",
                                height: "1.5rem",
                              }}
                            ></span>
                          ) : (
                            "Save")
                          }
                        </Button>
                      </div>
                    </form>
                  </div>
                );
              })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ProgressEditForm;