import React from "react";
import Select from "react-select";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

function ProjectModules({ modules, setModules, projectid }) {
  const { KanbanBoardData } = useSelector((state) => state.user);
  const mdata = KanbanBoardData?.filter(
    (x) => x.ProjectId == projectid && x.ModuleLinked != null
  )?.map((x) => x.ModuleLinked);
  const fmodule = mdata
    ?.filter(
      (value, index, self) =>
        self.findIndex((v) => v.FullName === value.FullName) === index
    )
    ?.map((x) => x.FullName);
  console.log(modules, "mmmmmmmmmmmmmmmmmmmmmmmmmmmmm");
  const options = [
    { label: "Monolith", value: "Monolith" },
    { label: "Frontend", value: "Frontend" },
    { label: "Backend", value: "Backend" },
    { label: "API Service", value: "API Service" },
    { label: "Microservice", value: "Microservice" },
  ];

  const addmodules = () => {
    setModules([
      ...modules,
      {
        Name: "",
        Type: "",
        FullName: "",
      },
    ]);
  };

  const handleModuleName = (i, e) => {
    const newModules = [...modules];
    newModules[i].Name = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    newModules[i].FullName =
      e.target.value.replace(/[^a-zA-Z0-9]/g, "") + "_" + newModules[i]?.Type; // Update the Name property
    setModules(newModules); // Set the updated modules array to state
  };

  const handleModuleType = (i, e) => {
    const newModules = [...modules];
    newModules[i].Type = e.value; // Update the Name property
    newModules[i].FullName = newModules[i].Name + "_" + e.value; // Update the Name property
    setModules(newModules); // Set the updated modules array to state
  };

  const handleModuleFullName = (i, e) => {
    const newModules = [...modules];
    newModules[i].FullName = e.target.value; // Update the Name property
    setModules(newModules); // Set the updated modules array to state
  };

  const removeModule = (indexToRemove) => {
    setModules(modules.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div>
      <table style={{ width: "100%", gap: 10 }}>
        <thead>
          <tr>
            <th>
              <label className="SprintlabelStyle">Module Name</label>
            </th>
            <th>
              <label className="SprintlabelStyle">Module Type</label>
            </th>
            <th>
              <label className="SprintlabelStyle">Module FullName</label>
            </th>
            <ControlPointIcon
              style={{ color: "green" }}
              onClick={() => {
                addmodules();
              }}
            />
            {/* <th></th> */}
            {/* Add more headers as needed */}
          </tr>
        </thead>
        <tbody>
          {modules?.map((x, i) => (
            <tr key={x._id}>
              <td style={{ width: "30%", marginRight: 10 }}>
                <Input
                  disabled={fmodule?.includes?.(
                    x?.FullName ? x?.FullName : x?.Name + "_" + x?.Type
                  )}
                  placeholder="Enter Title"
                  variant="outlined"
                  className="inpui SprintFormFields"
                  size="small"
                  style={{ borderRadius: 14, height: 40 }}
                  value={x?.Name}
                  onChange={(e) => handleModuleName(i, e)}
                  type="text"
                  // invalid={error.modulenames !== undefined}
                />
              </td>
              <td style={{ width: "30%", marginRight: 10 }}>
                <Select
                  options={options}
                  value={options.filter((option) => option?.value === x?.Type)}
                  onChange={(e) => handleModuleType(i, e)}
                />
              </td>
              <td style={{ marginRight: 100 }}>
                <td style={{ width: "30%", marginRight: 10 }}>
                  <Input
                    placeholder="Enter Title"
                    variant="outlined"
                    className="inpui SprintFormFields"
                    size="small"
                    style={{ borderRadius: 14, height: 40 }}
                    value={x?.FullName}
                    onChange={(e) => handleModuleFullName(i, e)}
                    type="text"
                    // invalid={error.modulenames !== undefined}
                  />
                </td>
              </td>
              {console.log(
                modules?.map((s) => s.FullName),
                modules?.filter((s) => s.FullName == x.FullName)?.length,
                i
              )}
              <td>
                {modules.length == 1 || fmodule?.includes?.(x.FullName) ? (
                  ""
                ) : (
                  <RemoveCircleOutlineIcon
                    style={{ color: "red" }}
                    onClick={() => removeModule(i)}
                  />
                )}
              </td>
              {/* Add more columns as needed */}
            </tr>
          ))}
        </tbody>
      </table>
      <label className="mb-3" style={{ fontSize: 10 }}>
        Note : Module is optional, but good to create for end to end connected
        view of platform engineering.
      </label>
    </div>
  );
}

export default ProjectModules;
