import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BasicTable from "./datagrid";
import { Avatar } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Slide, toast } from "react-toastify";
import moment from "moment";
import axios from "../../axios";
import { Card } from "react-bootstrap";
import { CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { PERCENTAGE } from "../../redux/CommonInitialsate";
import Unassigned from "../../assets/images/avatar.png";
import DevepolerSummary from "./DevepolerSummary";
function Currentsprint({
  // projectCode,
  //  projectRole
}) {
  // const projectCode = 1;
  const { percentage, allImage, projectRole, projectCode } = useSelector((state) => state.user);

  let arr123 = JSON.parse(sessionStorage.getItem("Roledetails"));
  // let rolecodedata = JSON.parse(localStorage.getItem("Rolecode"))
  let empid = JSON.parse(localStorage.getItem("Empdetails"));
  let managerid = JSON.parse(localStorage.getItem("managerid"));
  let EMpid = empid[0]?.ClientUserId;
  let getidd = arr123?.RoleCode == "SM" || projectCode == "SM" ? EMpid : managerid;
  let clientid =
    projectRole == "DEV"
      ? JSON.parse(localStorage.getItem("Orgsubscriptiondetails"))?.[0]
        ?.ClientId
      : JSON.parse(localStorage.getItem("clientid"));
  let smproject =
    clientid == 1100001 ? "Derie Frank" : clientid == 29 ? "Anne Steffi" : "SM";

  // const api = "rpc/fun_agilesaassmprojandteamdetails?managerid=" + empid[0].ClientUserId;
  const [showreleaseboard, setshowreleaseboard] = useState([]);
  const [Valueshow, setValueshow] = useState([]);
  const [mydatashow, setmydatashow] = useState([]);
  const [issueV, setissueV] = useState("0");
  const [issueD, setissueD] = useState("0");
  const [issueP, setissueP] = useState("0");
  const [issueTD, setissueTD] = useState("0");
  const [issueVdata, setissueVdata] = useState([]);
  const [issueDdata, setissueDdata] = useState([]);
  const [issuePdata, setissuePdata] = useState([]);
  const [issueTDdata, setissueTDdata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sprintdata, setSprintData] = useState([]);
  const [percentagearr, setPercentagearr] = useState([]);
  const [displaydata, setDisplayData] = useState([]);
  const [perState, Setperstate] = useState(null);
  const [leaveData, setLeaveData] = useState([]);
  const [publicholiday, setPublicholiday] = useState([]);
  const [reledata, Setreledata] = useState([]);
  // const [projectDetails, setprojectDetails] = useState([]);

  // const { projectDetails } = useSelector((state) => state.user)

  useEffect(() => {
    axios
      .get("rpc/fun_agilesaascurrentsprint?projid=" + projectCode)
      .then(async (response) => {
        // console.log(response.data, "release response");
        if (response.data.length > 0) {
          axios
            .get(
              "agilesaas_releases?ReleaseName=eq." +
              response?.data?.[0]?.plannedrelease
            )
            .then((res) => {
              console.log(res.data);
              Setreledata(res.data);
            })
            .catch((error) => console.log(error));
        }
        setIsLoading(response.data.length > 0 ? true : false);
        // console.log(response.data, "resposne");
        if (response.data.length > 0) {
          // setprojectDetails
          setSprintData(response.data.filter((d) => d.createdby == getidd));

          const getdata = response.data.filter((d) => d.createdby == getidd);
          const fetchData = async () => {
            try {
              // const axiosRequests = getdata.map(async (e) => {
              //   const check = await axios.get(
              //     "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=eq." +
              //     e.issueid +
              //     "&order=UpdatedDate.desc"
              //   );
              //   // console.log(check.data, "get display data");
              //   return check.data.map((entry) => ({
              //     EffortPending: entry.EffortPending,
              //     EffortRemaining: entry.EffortRemaining,
              //     RiskofDelivery: entry.RiskofDelivery,
              //     UpdatedDate: moment(entry.UpdatedDate).format("DD-MMM-YYYY"),
              //     IssueId: entry.IssueId,
              //     UpdatedBy: entry.UpdatedBy,
              //     PercentageProgressed: entry.PercentageProgressed,
              //   }));
              // });

              // const result = await Promise.all(axiosRequests);
              // const flattenedData = result.flat(); // Flatten the array of arrays into a single array
              const issueIds = getdata?.map(e => e.issueid).join(',');
              const response = await axios.get(
                `agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=in.(${issueIds})&order=UpdatedDate.desc`
              );

              // Process the returned data
              const flattenedData = response.data.map(entry => ({
                EffortPending: entry.EffortPending,
                EffortRemaining: entry.EffortRemaining,
                RiskofDelivery: entry.RiskofDelivery,
                UpdatedDate: moment(entry.UpdatedDate).format("DD-MMM-YYYY"),
                IssueId: entry.IssueId,
                UpdatedBy: entry.UpdatedBy,
                PercentageProgressed: entry.PercentageProgressed,
              }));

              console.log(flattenedData, "flattenedData");
              return flattenedData;
            } catch (error) {
              console.error("Error fetching data:", error);
              throw error;
            }
          };

          // Usage:
          const fetchedData = await fetchData();
          getdep(
            response.data.filter((d) => d.createdby == getidd),
            fetchedData
          );
          setIsLoading(false);
          // console.log(fetchedData);
          // console.log(response.data, "check response");
          setshowreleaseboard(response.data);
          setmydatashow(
            response.data.filter(
              (e) => e.currentstage[0].StageName != "Backlog"
            )
          );
          setissueV(
            response.data?.filter(
              (f) => f.currentstage[0].StageName != "Backlog"
            ).length
          );
          setissueD(
            (response.data == ""
              ? ""
              : response.data?.filter(
                (f) => f.currentstage[0].StageName == "Done"
              )
            ).length
          );
          setissueP(
            (response.data == ""
              ? ""
              : response.data?.filter(
                (f) =>
                  f.currentstage[0].StageName == "In Development" ||
                  f.currentstage[0].StageName == "User Acceptace Testing"
              )
            ).length
          );
          setissueTD(
            (response.data == ""
              ? ""
              : response.data?.filter(
                (f) => f.currentstage[0].StageName == "Refined"
              )
            ).length
          );
          setissueVdata(response.data);
          setissueDdata(
            response.data == ""
              ? ""
              : response.data?.filter(
                (f) => f.currentstage[0].StageName == "Done"
              )
          );
          setissuePdata(
            response.data == ""
              ? ""
              : response.data?.filter(
                (f) =>
                  f.currentstage[0].StageName == "In Development" ||
                  f.currentstage[0].StageName == "User Acceptace Testing"
              )
          );
          setissueTDdata(
            response.data == ""
              ? ""
              : response.data.filter(
                (f) => f.currentstage[0].StageName == "Refined"
              )
          );
        }
      })

      .catch((error) => console.log(error, "Progress"));

    // axios
    //     .get(api)
    //     .then((response) => {
    //         let data123 = response.data;
    //         data123.map((item) => {
    //             let findItem = projectDetails.find(
    //                 (x) => x.projectcode === item.projectcode
    //             );
    //             if (!findItem) projectDetails.push(item);
    //         });
    //     })
    //     .catch((error) => console.log(error));

    // axios
    //   .get("agile_issue_details")
    //   .then((response) => {
    //     setIssueDetailsShow(response.data);
    //     setKanbanBoardData(response.data);
    //   })
    //   .catch((error) => console.log(error));
  }, [projectCode]);
  // console.log(projectCode, "project code");

  function getdep(data, fetchedData) {
    // console.log(data);
    if (data.length > 0) {
      updatesprintburntdown(data, fetchedData);
    } else {
      console.log("error");
    }
  }
  const dispatch = useDispatch();
  console.log(showreleaseboard, "showreleaseboard")
  let datee =
    showreleaseboard == "" ? "" : moment(showreleaseboard[0].sprintenddate);
  let given = datee;
  let duedate = moment(given).diff(moment(), "day");
  let endDate = moment(datee);
  let currentDate = moment(); // Get the current date

  // Format the current date in the DD/MM/YYYY format
  let currentDateString = currentDate.format("DD/MM/YYYY");
  currentDate = moment(currentDateString, "DD/MM/YYYY");

  // If the current date is a weekend, move to the next Monday
  if (currentDate.day() === 0) {
    currentDate.add(1, "day");
  } else if (currentDate.day() === 6) {
    currentDate.add(2, "day");
  }

  let totalDays = endDate.diff(currentDate, "days"); // Total days between current date and end date
  let totalWeeks = Math.floor(totalDays / 7); // Total weeks between the dates
  let remainingDays = totalDays - totalWeeks * 2; // Subtracting 2 days for each weekend in the total days

  const versionchangehadle = () => {
    setmydatashow(issueVdata == "" ? null : issueVdata);
  };
  const Donedata = () => {
    if (issueD == "0") {
      toast.error(`No issues in Completed`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    } else {
      setmydatashow(issueDdata == "" ? null : issueDdata);
    }
  };
  const progressdata = () => {
    if (issueP == "0") {
      toast.error(`No issues in Progress`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    } else {
      setmydatashow(issuePdata == "" ? null : issuePdata);
    }
  };
  const tododta = () => {
    if (issueTD == "0") {
      toast.error(`No issues in Refind`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    } else {
      setmydatashow(issueTDdata == "" ? null : issueTDdata);
    }
  };
  const showreleaseboard2 = showreleaseboard?.filter(
    (e) => e.currentstage[0].StageName != "Backlog"
  );
  function workingDays(startDate, diff1) {
    const weekdays = [];
    let current = startDate;
    let i = 0;
    while (i < diff1) {
      if (!isWeekEnd(current)) {
        weekdays.push(moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"));
      }
      let currentObj = current;
      current = moment(currentObj, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
      i++;
    }
    function isWeekEnd(date) {
      let dateObj = moment(date, "YYYY-MM-DD");

      if (dateObj.day() === 6 || dateObj.day() === 0) {
        return true;
      }
      //   else {
      //     if (holidays.includes(date)) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
    }

    return weekdays;
    // }
  }
  let temp = moment(sprintdata?.[0]?.sprintenddate).diff(
    moment(sprintdata?.[0]?.sprintstartdate),
    "day"
  );
  const diff =
    temp == 0
      ? 1
      : Number(
        Math.ceil(
          (new Date(sprintdata?.[0]?.sprintenddate) -
            new Date(sprintdata?.[0]?.sprintstartdate)) /
          (1000 * 60 * 60 * 24)
        ) + 1
      );
  const excludeday = workingDays(sprintdata?.[0]?.sprintstartdate, diff + 0);

  const updatesprintburntdown = (sprintdata, displaydata) => {
    let temp = moment(sprintdata?.[0]?.sprintenddate).diff(
      moment(sprintdata?.[0]?.sprintstartdate),
      "day"
    );
    const diff =
      temp == 0
        ? 1
        : Number(
          Math.ceil(
            (new Date(sprintdata?.[0]?.sprintenddate) -
              new Date(sprintdata?.[0]?.sprintstartdate)) /
            (1000 * 60 * 60 * 24)
          ) + 1
        );
    const getdays = workingDays(sprintdata?.[0]?.sprintstartdate, diff + 0);
    let addarr = [];
    let increment = 0;
    const datacheck = sprintdata;
    // console.log(datacheck);
    // console.log(getdays);

    datacheck.map((e, i) => {
      increment = i;
      const progressdata = displaydata
        .flat()
        .filter((e5) => e5.IssueId == e.issueid);
      // employeeid != null
      //   ? displaydata
      //       .flat()
      //       .filter(
      //         (e5) => e5.IssueId == e.issueid && e5.UpdatedBy == employeeid
      //       )
      //   :
      // console.log(displaydata);
      // console.log(datacheck);

      let percentdata = [];
      // console.log(progressdata, "progressdata");
      getdays.map((wdate, index) => {
        const filterprogress = progressdata.filter(
          (pr) => moment(pr.UpdatedDate).diff(moment(wdate), "day") == 0
        );
        const beforedate = progressdata.filter(
          (date) => moment(date.UpdatedDate).diff(moment(wdate), "day") < 0
        );
        // console.log(filterprogress, "filterprogress", wdate, "wdate");

        if (filterprogress.length > 0) {
          percentdata.push(
            filterprogress[0]?.PercentageProgressed == undefined
              ? 0
              : filterprogress[0]?.PercentageProgressed
          );
        } else {
          if (beforedate.length > 0) {
            percentdata.push(
              percentdata[percentdata.length - 1] == undefined
                ? 0
                : percentdata[percentdata.length - 1]
            );
          } else if (
            (filterprogress.length == 0 && beforedate.length == 0) ||
            filterprogress.length == 0
          ) {
            percentdata.push(0);
          }
        }
        // console.log(percentdata, wdate);
      });

      addarr.push(percentdata);
    });

    const sums = addarr.reduce((acc, arr) => {
      arr.forEach((value, index) => {
        acc[index] = (acc[index] || 0) + value;
      });
      return acc;
    }, []);
    let arrvalue = [];
    if (datacheck.length == increment + 1) {
      sums.map((sum, index) => {
        arrvalue.push(sum / addarr.length);
      });
    }
    Setperstate(arrvalue);
    // dispatch(PERCENTAGE(arrvalue));
  };

  var currentddate = new Date();

  var dayOfWeek = currentddate.getDay();
  const checkdate = moment().diff(
    moment(sprintdata?.[0]?.sprintstartdate),
    "days"
  ); // Output the updated date in YYYY-MM-DD format
  const finalIndex =
    dayOfWeek === 0
      ? checkdate - 2
      : dayOfWeek === 6
        ? checkdate - 1
        : checkdate;
  const progresspercentage = perState
    ?.map((e) =>
      // e.toFixed(0)
      Math.round(e)
    )
    ?.slice(0, finalIndex + 1);
  const percentagefinal = progresspercentage?.[progresspercentage?.length - 1];
  let rag = "--";
  // console.log(perState);
  // console.log(progresspercentage);
  // console.log(percentagefinal);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const differences1 = (specialcover, data, startDate, endDate) => {
    const sdate = new Date(startDate);
    const edate = new Date(endDate);
    const finaldata = data?.map((leave) => {
      const removeDates =
        publicholiday.length == 0
          ? []
          : publicholiday?.map((e) =>
            moment(e.HolidayDate).format("DD-MM-YYYY")
          );
      const leaveStartDate = new Date(leave.LeaveStartDate);
      const leaveEndDate = new Date(leave.LeaveEndDate);
      const isSaturday =
        specialcover?.filter((s) => s.Day == "Saturday")?.length > 0; // Set to true to include Saturday
      const isSunday =
        specialcover?.filter((s) => s.Day == "Sunday")?.length > 0; // Set to true to include Sunday
      const isWeekend = (date) => {
        const day = date.getDay();
        return (!isSaturday && day === 6) || (!isSunday && day === 0);
      };

      const dateToString = (date) => {
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, "0");
        const day = `${date.getDate()}`.padStart(2, "0");
        return `${day}-${month}-${year}`;
      };
      if (leaveStartDate <= edate && leaveEndDate >= sdate) {
        let totalDays = 0;
        for (
          let date = new Date(leaveStartDate);
          date <= leaveEndDate;
          date.setDate(date.getDate() + 1)
        ) {
          const dateString = dateToString(date);
          if (
            !removeDates.includes(dateString) &&
            !isWeekend(date) &&
            date >= sdate &&
            date <= edate
          ) {
            totalDays++;
          }
        }
        return totalDays;
      }
      return 0;
    });
    return finaldata?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  };
  // console.log(reledata);
  const calculateTotalEstimates = (
    dataArray,
    totalPoints,
    teamleavesconsidered
  ) => {
    // console.log(dataArray, "dataArray");
    const estimateByAssignedTo = dataArray?.reduce(
      (accumulator, currentValue) => {
        const { assignedto, originalestimate } = currentValue;

        accumulator[assignedto] =
          (accumulator[assignedto] || 0) + originalestimate;
        return accumulator;
      },
      {}
    );

    return Object.entries(estimateByAssignedTo).map(([assignedTo, total]) => ({
      AssignedTo: parseInt(assignedTo),
      Estimate:
        totalPoints -
        (total +
          // differences1(
          //   specialcover,
          //   leaveData.filter((x) => x.UserId == parseInt(assignedTo)),
          //   startDate,
          //   endDate
          // )
          (teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned == undefined ? 0 :
            teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned)
        ),
      Userpoints:
        total +
        // differences1(
        //   specialcover,
        //   leaveData.filter((x) => x.UserId == parseInt(assignedTo)),
        //   startDate,
        //   endDate
        // )
        (teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned == undefined ? 0 :
          teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned),
      leave:
        // differences1(
        //   specialcover,
        //   leaveData.filter((x) => x.UserId == parseInt(assignedTo)),
        //   startDate,
        //   endDate
        // )
        (teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned == undefined ? 0 :
          teamleavesconsidered?.filter((x) => x.UserId == parseInt(assignedTo))?.[0]?.Leavesplanned),
    }));
  };

  const totalPoints = reledata?.[0]?.TotalDaysInSprint;
  //e.TotalDaysInSprint == undefined ? 0 : e.TotalDaysInSprint;
  const scover = reledata?.[0]?.SpecialCover;
  //e.SpecialCover ? e.SpecialCover : [];
  const releaseData = {
    TotalDaysInSprint: totalPoints,
    data: calculateTotalEstimates(
      mydatashow,
      totalPoints,
      showreleaseboard2?.[0]?.teamleavesconsidered
    ),
    startDate: showreleaseboard2?.[0]?.sprintstartdate,
    endDate: showreleaseboard2?.[0]?.sprintenddate,
    SpecialCover: scover,
    teamleavesconsidered: showreleaseboard2?.[0]?.teamleavesconsidered
  };

  // console.log(releaseData, "releaseData");
  return (
    <>
      <div className="sprint-wrapper">
        {/* <div className="sprint-header">
                    <h1>Current Sprint</h1>
                    <CloseIcon />
                </div> */}
        <Divider />
        {isLoading ? (
          <div
            className=" d-flex align-items-center"
            style={{ justifyContent: "center", height: 460 }}
          >
            <span
              className="spinner-border"
              role="status"
              aria-hidden="true"
              style={{ color: "rgb(82, 80, 229)" }}
            ></span>
          </div>
        ) : showreleaseboard.length == 0 ? (
          <Card>
            <CardBody
              className="myShowreleasecolor mt-3 d-flex align-items-center"
              style={{ justifyContent: "center", height: 460 }}
            >
              <center ClassName="ml-3">
                <h4 className="text-danger ml-3 ">
                  No In-flight sprint for this project
                </h4>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
              </center>
            </CardBody>
          </Card>
        ) : (
          <div>
            <div className="sprint-body">
              <div className="sprint-title">
                <Row className="ml-0">
                  <Col>
                    <div>
                      <h4 style={{ color: "#0046FF" }}>Project </h4>
                      <span
                        style={{
                          fontWeight: "bold",
                          whiteSpace: "pre-wrap",
                          color: "#435585",
                          fontSize: "25px",
                        }}
                        sx={{ width: 15, height: 15 }}
                      >
                        {showreleaseboard2?.[0]?.projectdetails[0]?.ProjName}
                      </span>
                    </div>
                  </Col>

                  <Col className="ml-5">
                    <h4 style={{ color: "#0046FF" }}>Version </h4>
                    <span
                      style={{
                        fontWeight: "bold",
                        whiteSpace: "pre-wrap",
                        fontSize: "25px",
                      }}
                      sx={{ width: 15, height: 15 }}
                    >
                      {showreleaseboard2?.[0]?.releasename}
                    </span>
                  </Col>
                  <Col style={{ position: "relative", marginLeft: "550px" }}>
                    <span
                      size="small"
                      style={{
                        background: "white",
                        color: "black",
                        fontWeight: "bold",
                        padding: "5px", // Adjusted padding
                        height: "max-content", // Changed from "max-content"
                        position: "absolute",
                        top: 0, // Adjusted top position
                        left: 0, // Adjusted left position
                        width: "130px",
                        display: "flex", // Added display: flex
                        flexDirection: "column",
                      }}
                    >
                      {showreleaseboard?.[0]?.createdbydetails.FN +
                        " " +
                        showreleaseboard?.[0]?.createdbydetails.LN}
                      <label style={{ fontWeight: "normal", marginTop: "5px" }}>
                        {/* {arr123.RoleName} */}
                        Scrum Master
                      </label>
                    </span>
                    <Avatar
                      // sx={{ width: 35, height: 35 }}

                      style={{
                        position: "relative",
                        width: "50px",
                        height: "50px",
                        left: "100px", // Adjust this value based on the width of the Col
                        top: "5px", // Adjust this value based on the positioning within the Col
                        zIndex: "1",
                      }}
                      alt="image"
                      src={
                        allImage?.length > 0 && allImage?.filter(
                          (x) =>
                            x.teammemberid == showreleaseboard?.[0]?.createdby
                        )?.[0]?.image != undefined ? allImage?.filter(
                          (x) =>
                            x.teammemberid == showreleaseboard?.[0]?.createdby
                        )?.[0]?.image : Unassigned
                      }
                    />
                  </Col>
                  {/* <Button size="small" style={{ background: "#70cd95", color: '#fff', fontWeight: 'bold', padding: 5, cursor: 'default', marginLeft: "25px" }}>
                                                        In-Flight
                                                    </Button> */}

                  {/* <Button size="small" style={{ background: "#fec054", color: '#fff', fontWeight: 'bold', padding: 5, cursor: 'default',marginLeft:"500px" }}>Created By : {showreleaseboard2?.[0]?.createdbydetailsissue} </Button> */}
                </Row>

                {/* <div>
                            <Button variant="contained" onClick={handleShow}>
                                Plan sprint
                            </Button>
                        </div> */}
              </div>
              <div className="sprint-cards">
                <Grid container spacing={5}>
                  <Grid item lg={3} md={3} xs={6}>
                    <div
                      className="sprint-card-info-1"
                      onClick={versionchangehadle}
                      style={{ cursor: "pointer" }}
                    >
                      <h2 className="sprint-card-info-title">{issueV}</h2>
                      <p className="sprint-card-info-description">
                        TOTAL ISSUES
                      </p>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={3} xs={6}>
                    <div
                      className="sprint-card-info-2"
                      onClick={Donedata}
                      style={{ cursor: "pointer" }}
                    >
                      <h2 className="sprint-card-info-title">{issueD}</h2>
                      <p className="sprint-card-info-description">COMPLETED</p>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={3} xs={6}>
                    <div
                      className="sprint-card-info-3"
                      onClick={progressdata}
                      style={{ cursor: "pointer" }}
                    >
                      <h2 className="sprint-card-info-title">{issueP}</h2>
                      <p className="sprint-card-info-description">
                        IN PROGRESS
                      </p>
                    </div>
                  </Grid>
                  <Grid item lg={3} md={3} xs={6}>
                    <div
                      className="sprint-card-info-4"
                      onClick={tododta}
                      style={{ cursor: "pointer" }}
                    >
                      <h2 className="sprint-card-info-title">{issueTD}</h2>
                      <p className="sprint-card-info-description">REFINED</p>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className="sprint-progress">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Box sx={{ width: "100%", mr: 1 }}>
                    {/* <LinearProgress className="progress-bar" variant="determinate" value={100 / (issueV / issueD)} /> */}
                    <LinearProgress
                      className="progress-bar"
                      variant="determinate"
                      value={percentagefinal}
                    />
                  </Box>
                  <Box>
                    {/* <Typography variant="body2" color="#000">{(100 / (issueV / issueD))?.toFixed(0)}%</Typography> */}
                    <Typography variant="body2" color="#000">
                      {percentagefinal}%
                    </Typography>
                  </Box>
                </Box>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div className="sprint-period">
                    <p>
                      Sprint Duration ({showreleaseboard2?.[0]?.sprintinweeks}{" "}
                      weeks) From:{" "}
                      {moment(showreleaseboard2?.[0]?.sprintstartdate).format(
                        "DD/MM/YYYY"
                      )}{" "}
                      To:{" "}
                      {moment(showreleaseboard2?.[0]?.sprintenddate).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </div>
                  <Typography
                    variant="body2"
                    color="#000"
                    align="right"
                    fontSize="12px"
                  >
                    Release Due :{" "}
                    {duedate < 0
                      ? -duedate + " " + "Days Over Due"
                      : remainingDays + " " + "Days Left"}
                  </Typography>
                </div>
              </div>
            </div>
            <div
              className="widget-bar"
              style={{
                whiteSpace: "nowrap",
                overflowY: "hidden",
                overflowX: "auto",
              }}
            >
              <div className="widget-row mb-3">
                <h4
                  className="ml-3 mb-2"
                  style={{ fontFamily: "Nunito, sans-serif" }}
                >
                  {/* <Diversity3Icon className="ml-4" />
                  <label className="mb-2 ml-1">Team Summary</label> */}
                </h4>
                <DevepolerSummary data={mydatashow} points={releaseData} />
              </div>
            </div>

            <BasicTable data={mydatashow} />
          </div>
        )}
      </div>
    </>
  );
}

export default Currentsprint;
