import React, { useEffect, useRef, useState } from "react";
import "./KanbanDashboard.css";
import Board from "react-trello";
import axios from "../../axios.js";
import { Input } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Bookmark from "@material-ui/icons/Bookmark";
import { Modal, ToggleButtonGroup, ToggleButton, Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ErrorIcon from "@mui/icons-material/Error";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import OfflineBoltRoundedIcon from "@material-ui/icons/OfflineBoltRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import moment from "moment";
import agile from "../../assets/images/agile.png";
import "@fortawesome/fontawesome-free/css/all.css";
// import Modal from '@mui/material/Modal';
import Storyoption from "./Storyoption.js";
import Button from "@mui/material/Button";
import { Row, Col, Label } from "reactstrap";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import BlockIcon from "@mui/icons-material/Block";
import Storypoints from "./Storypoints.js";
import { OverlayTrigger, Popover } from "react-bootstrap";
import OriginalEstimate from "./ProgressUpdate/OriginalEstimate.js";
import ReactImageFallback from "react-image-fallback";
import UATtester from "./UATtester.js";
import UATchecklist from "./UATchecklist.js";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Card from "@mui/material/Card";
import Unassigned from "../../assets/images/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Select, { components } from "react-select";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { green, red } from "@mui/material/colors";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CloseIcon from "@material-ui/icons/Close";
import {
  HANDLE_ON_CHANGE,
  SELECTEDCARD,
} from "../../redux/CommonInitialsate.jsx";
import EpiccloseTable from "./EpiccloseTable.js";
import MinimizeMaximizeButton from "./MinimizeMaximizeButton.js";
import Mailandnotification from "../../Mailandnotification.js";
import slacknotify from "../../slacknotify.js";
import { Badge, Dropdown } from "react-bootstrap";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { TrendingUpRounded, X } from "@mui/icons-material";
import { DeleteForever } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Deletedialog from "./Dashboarddialog/Deletedialog.js";
import Cookies from "universal-cookie";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CircularProgressWithLabel(props) {
  // console.log(props, "props")
  return (
    <Box
      sx={{
        position: "relative",
        textAlign: "center",
        marginLeft: 1,
        // marginBottom: -7,
        // padding: 0,
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={25}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          color: props.warning
            ? "#FCD958"
            : props.label == 1 || props.label < 0
            ? "#FD6161"
            : "#45DD81",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={25}
        thickness={4}
        value={props.value}
      />
      {props.label == 0 ? (
        <DoneIcon
          fontSize="small"
          sx={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            justifyContent: "center",
            fontWeight: 700,
            color: "#45DD81",
          }}
        />
      ) : (
        <Typography
          sx={{
            position: "absolute",
            top: "43%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            justifyContent: "center",
            fontWeight: 700,
          }}
          fontSize={10} // Adjust font size as per your requirement
          component="div"
          color={
            props.warning
              ? "#FCD958"
              : props.label == 1 || props.label < 0
              ? "#FD6161"
              : "#45DD81"
          }
        >
          {`${Math.round(props.label)}`}
        </Typography>
      )}
    </Box>
  );
  // return (
  //   <Box sx={{ position: "relative", display: "inline-flex" }}>
  //     <CircularProgress
  //       variant="determinate"
  //       size={25} // Adjust size of the progress bar as per your requirement
  //       thickness={3} // Adjust thickness of the progress bar as per your requirement
  //       {...props}
  //     />
  //     <Box
  //       sx={{
  //         top: 0,
  //         left: 0,
  //         bottom: 0,
  //         right: 0,
  //         position: "absolute",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Typography
  //         variant="inherit" // Adjust font size as per your requirement
  //         component="div"
  //         color={props.value === 100 ? "red" : "green"}
  //         sx={{ fontWeight: 700 }}
  //       >
  //         {`${Math.round(props.label)}`}
  //       </Typography>
  //     </Box>
  //   </Box>
  // );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};
// function CircularProgressWithLabel1(props) {
//   return (
//     <Box sx={{ position: "relative", display: "inline-flex" }}>
//       <CircularProgress variant="determinate" {...props}   size={30} />
//       <Box
//         sx={{
//           top: 0,
//           left: 0,
//           bottom: 0,
//           right: 0,
//           position: "absolute",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//         // size={25}
//       >
//         <Typography variant="caption" component="div"    style={{ fontSize: 10 ,fontWeight:"bold",color:"black"}}>
//           {`${Math.round(props.value)}%`}
//         </Typography>
//       </Box>
//     </Box>
//   );
// }

// CircularProgressWithLabel1.propTypes = {
//   value: PropTypes.number.isRequired,
// };

toast.configure();
const cookies = new Cookies();
const Search = styled("div")(({ theme }) => ({
  // border: '1px solid black',

  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  overflow: "hidden",
  maxWidth: 100,
  // width: '100%',
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(3),
  //   width: 'auto',
  // },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  // padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#616670",
  fontSize: 11,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    // width: '100%',
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function KanbanDashboard(
  {
    // Assetoptions,
    // setProgressopen,
    // setSelectedCardId,
    // SelectedCardId,
    // FilteredData,
    // projectId2,
    // setRefresh,
    // setFilteredData,
    // Bfopen,
    // setBfopen,
    // optionsrelease,
    // IssueId,
    // setIssueId,
    // selectedCard,
    // setselectedcard,
    // openCreateEst,
    // setOpenCreateEst,
    // UATtesterD,
    // setUATtesterD,
    // releasefilter,
    // UATchecklistD,
    // setUATchecklistD,
    // priority,
    // epicLabel,
    // progressdata,
    // projectRole,
    // projectCodes,
    // projectName,
    // client,
    // KBRefresh,
    // releases,
    // searchText,
    // setReleases,
    // isMinimized,
    // EmpDropDownData,
    // handleOpen,
  }
) {
  const [ragstatus, setRagstatus] = useState([]);
  // const [uattesterassign, setuattesterassign] = useState(false);
  // const [ShowOption, setOption] = useState(false);
  // const [ShowOptionID, setOptionID] = useState(null);
  const [statex, setStatex] = useState("");
  const [statee, setStatee] = useState("");
  const [type, setType] = useState("");
  const [menuVisible, setMenuVisible] = useState(false);
  const [percentagefil, setpercentagefil] = useState("");
  const [assignfil, setassignfil] = useState("");
  const [epicfil, setepicfil] = useState("");
  const [releasefile, setreleasefil] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [percentagefilrfd, setpercentagefilrfd] = useState("");
  const [assignfilrfd, setassignfilrfd] = useState("");
  const [epicfilrfd, setepicfilrfd] = useState("");
  const [releasefilerfd, setreleasefilrfd] = useState("");
  const [sortOrderrfd, setSortOrderrfd] = useState("");
  const [rtbdialog, setRtbdialog] = useState(false);
  const [rtbdata, setRtbdata] = useState(false);
  const [Cloneopen, setCloneopen] = useState(false);
  const [assignedEmployee, setAssignedEmployee] = useState(null);
  const [assignedEmployeeN, setAssignedEmployeeN] = useState("null null");
  // const [enablesprint, setEnablesprint] = useState(false)
  const [stage, setStage] = useState("Refined");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const rolecode =
    localStorage.getItem("Rolecode") != undefined
      ? JSON.parse(localStorage.getItem("Rolecode"))
      : "";
  console.log(assignedEmployee, "assignedEmployee");
  // const [Lane, setLane] = useState({
  //   Source: null,
  //   Target: null,
  // });

  // let arr = JSON.parse(sessionStorage.getItem("Roledetails"));
  const issues = ragstatus?.map((e) => e.IssueId);
  let emplogin = JSON.parse(localStorage.getItem("Empdetails"));
  let login = emplogin != null ? emplogin : null;

  const clientId = localStorage.getItem("clientid");
  const dispatch = useDispatch();
  const {
    allImage,
    projectCode,
    KanbanBoardData,
    selectedCard1,
    searchText,
    Assetoptions,
    projectRole,
    projectName,
    progressopen,
    FilteredData,
    projectId2,
    selectedCard,
    progressdata,
    EmpDropDownData,
    priority,
    releasefilter,
    epicLabel,
    releases1,
    epicCloseData,
    uattesterassign,
    Lane,
    enablesprint,
    releasedata,
    Refresh,
    deletedata,
    clonedata,
    deletedialog,
    releases,
    clonedialog,
    resData,
    channelname,slacktoken
  } = useSelector((state) => state.user);
  let slack = localStorage.getItem("Slack")
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [dialog, setDialog] = useState(false);
  const [dialogmessage, setDialogMessage] = useState("");
  const [epicClose, setEpicClose] = useState(false);
  const [messagetype, setMessageType] = useState("success");

  // const [epicCloseData, setEpicCloseData] = useState([]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setDialog(false);
    setReleasePopover(null);
    setAssignPopover(null);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let temp = [];

  let check =
    priority === "All" && epicLabel === "All" && releasefilter == ""
      ? FilteredData?.filter((x) => x.ProjectId === projectId2)
      : FilteredData?.filter(
          (x) =>
            x.ProjectId === projectId2 &&
            (priority === "All" ? x.Priority != "" : x.Priority == priority) &&
            (epicLabel === "All"
              ? x.IssueType != ""
              : x.IssueType == epicLabel) &&
            (releasefilter === ""
              ? x.PlannedRelease != ""
              : x.PlannedRelease == releasefilter || x.IssueType == "Epic")
        );

  const priorities = [];
  const [currentsprintcard, setcurrentsprintcard] = useState([]);
  const hpriority = FilteredData?.filter((e) => e.Priority == "Highest");
  priorities.push(...hpriority);
  const h1priority = FilteredData?.filter((e) => e.Priority == "High");
  priorities.push(...h1priority);
  const medium = FilteredData?.filter((e) => e.Priority == "Medium");
  priorities.push(...medium);
  const low = FilteredData?.filter((e) => e.Priority == "Low");
  priorities.push(...low);
  const Lowest = FilteredData?.filter((e) => e.Priority == "Lowest");
  priorities.push(...Lowest);
  const filterpriority = priorities?.filter((e) => e);
  const check3 = check?.map((e) => e.IssueId);

  function isDateLessThanCurrent(dateString) {
    if (dateString == null) {
      return false;
    }
    const endMoment = moment(dateString, "YYYY-MM-DD");
    const currentDate = moment();
    const daysDifference = endMoment.diff(currentDate, "days");

    if (daysDifference < 0) {
      return true; // Given date is in the past
    } else if (daysDifference >= 0) {
      return false; // Given date is today or in the future
    }
  }

  useEffect(() => {
    axios
      .get(
        `/rpc/fun_agilesaasproductivityreport?projid=${projectCode}&currentstage->0->>StageCode=in.(UAT,DEV)`
      )
      .then((res) => {
        if (check != null) {
          let a = res.data.map((x) => ({
            issueid: x.issueid,
            sprintenddate: x.sprintenddate,
            currentstage: x.currentstage?.[0]?.StageCode,
            isovedue: isDateLessThanCurrent(x.sprintenddate),
            RiskOfDelivery: x.riskofdelivery,
            percentageprogressed: x.percentageprogressed,
          }));

          let newdata = check
            .map((item1) => ({
              ...item1,
              ...a.find((item2) => item2.issueid == item1.IssueId),
            }))
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.IssueId === value.IssueId)
            );

          // check = newdata;

          setcurrentsprintcard(newdata);
        } else {
          // console.log(check, "kl");
          setcurrentsprintcard(check);
        }
      })
      .catch((e) => {});
  }, [priority, releasefilter, epicLabel, FilteredData]);
  // console.log(moment().format("YYYY"), moment().format("YYYY") - 1)

  // console.log(leaveData, "leaveData");

  const [activePopover, setActivePopover] = useState(null);
  const [issueTitle, setIssueTitle] = useState("");
  const [submitcheck, setSubmitCheck] = useState(false);
  const [insidePopoverContent, setInsidePopoverContent] = useState(false);
  const [releasePopover, setReleasePopover] = useState(null);
  // const [releasePopoverContent, setReleasePopoverContent] = useState(false);
  const [assignPopover, setAssignPopover] = useState(null);
  // const [assignPopoverContent, setAssignPopoverContent] = useState(false);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const popoverRef = useRef(null);
  // const releaseRef = useRef(null);
  // const assignRef = useRef(null);
  const [searchuser, setSearchuser] = useState("");
  const [searchSprint, setSearchSprint] = useState("");
  const toggleMenu = (event) => {
    event.stopPropagation();
    setMenuVisible((prevVisible) => !prevVisible);
  };
  const handleCreateClick = (index, type) => {
    if (type == "create") {
      if (activePopover === index) {
        setActivePopover(null);
        setIssueTitle(""); // Close popover if clicking the same create button
      } else {
        setIssueTitle("");
        setActivePopover(index); // Open the clicked popover
      }
    } else if (type == "release") {
      axios
        .get(
          `agilesaas_releases?IsReleaseFrozen=eq.N&SprintEndDate=gte.${moment().format(
            "YYYY-MM-DD"
          )}&ProjectId=eq.${projectCode}&order=SprintStartDate.asc`
        )
        .then((res) => {
          // setReleases(res.data);
          dispatch(HANDLE_ON_CHANGE({ name: "releases", value: res.data }));
        });
      if (releasePopover === index) {
        setReleasePopover(null);
        setFilterSprint(releasesOption);
      } else {
        setReleasePopover(index);
        setSearchSprint(""); // Open the clicked popover
      }
    } else {
      if (assignPopover === index) {
        setAssignPopover(null);
        setFilteredUser(doptions);
      } else {
        setAssignPopover(index); // Open the clicked popover
        setSearchuser("");
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        (activePopover !== null ||
          assignPopover !== null ||
          releasePopover !== null) &&
        !popoverRef.current.contains(event.target) &&
        !insidePopoverContent
      ) {
        setActivePopover(null);
        setAssignPopover(null);
        setReleasePopover(null);
        setIssueTitle("");
        setFilteredUser(doptions);
        setFilterSprint(releasesOption);
      }
    };

    if (
      activePopover !== null ||
      assignPopover !== null ||
      releasePopover !== null
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    activePopover,
    insidePopoverContent,
    releasePopover,
    assignPopover,
    currentsprintcard.length,
  ]);

  let arr =
    sessionStorage.getItem("Roledetails") == "undefined"
      ? ""
      : JSON.parse(sessionStorage.getItem("Roledetails"));
  let empid = JSON.parse(localStorage.getItem("Empdetails"));

  const handleCreateTask = (event, e) => {
    event.stopPropagation();
    if (issueTitle) {
      const epic = [
        {
          IssueId: e.IssueId,
          IssueType: e.IssueType,
          Title: e.IssueTitle,
          CurrentStage: e?.CurrentStage?.[0]?.StageCode,
          Priority: e.Priority,
        },
      ];
      setSubmitCheck(true);
      let data = {
        ProjectId: projectCode,
        ProjectShortCode: projectName.slice(0, 3),
        IssueType: "Story",
        IssueTitle: issueTitle,
        Priority: "Medium",
        EstimateUnit: "MD",
        EstimateUnitDesc: "Man Days",
        CurrentStage: [
          {
            StageCode: "BLG",
            StageName: "Backlog",
            DateMoved: moment().format("YYYY-MM-DD"),
          },
        ],
        LinkToEpic: epic,
        CreatedBy: empid?.[0]?.ClientUserId,
        CreatedByDetails:
          arr.RoleName == "Undefined" ? "Kanban Admin" : arr.RoleName,
        CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
        IsActive: "Y",
        ProjectDetails: [{ ProjName: projectName }],
        ClientId: empid?.[0]?.ClientId,
        AssignedDate: moment()
          .utcOffset("+05:30")
          .format("YYYY-MM-DDTHH:mm:ss"),
        AssignedToDetails: {
          FN: "null",
          LN: "null",
        },
        AssignedToUATDetails: {
          FN: "null",
          LN: "null",
        },
      };
      axios.post("agilesaas_issue_details", data).then((res) => {
        setActivePopover(null);
        setSubmitCheck(false);
        setOpen(true);
        setMessage("Story created successfully");
        setMessageType("success");
        // KBRefresh();
        dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
      });
    }
  };

  const releasesOption =
    releasedata?.filter((item) => item.IsReleaseFrozen == "N")?.length == 0
      ? []
      : releasedata
          ?.filter((item) => item.IsReleaseFrozen == "N")
          ?.map((e) => {
            return {
              ...e,
              label: e.ReleaseName,
              value: e.ReleaseName,
            };
          });
  const releasesOption23 =
    releasedata?.filter(
      (item) => item.IsReleaseFrozen == "N" && item.Status == null
    )?.length == 0
      ? []
      : releasedata
          ?.filter((item) => item.IsReleaseFrozen == "N" && item.Status == null)
          ?.map((e) => {
            return {
              ...e,
              label: e.ReleaseName,
              value: e.ReleaseName,
            };
          });
  const defaultValue =
    releasesOption23.length > 0 ? releasesOption23[0].value : null;
  const [sprint, setSprint] = useState(null);
  const [issueTitleedit, setIssueTitleedit] = useState(
    clonedata.IssueTitle || ""
  );
  useEffect(() => {
    if (releasesOption23.length == 1) {
      setSprint(releasesOption23[0].value);
    }
  }, [releasesOption23]);
  let clientdetails = JSON.parse(localStorage.getItem("clientdetails"));
  const userorg = clientdetails?.[0]?.OrgDetails;

  // Assetoptions?.filter(
  //   (e) => e.EmpId != login?.[0]?.ClientUserId
  // )

  const doptions = Assetoptions?.map((x) => {
    return {
      ...x,
      value: x.EmpId,
      label: x.label,
      firstname: x.firstname,
      lastname: x.lastname,
    };
  });
  // console.log(Assetoptions, "Assetoptions");

  const handleDialog = (type1, estimate, x, e) => {
    setDialog(true);
    setDialogMessage(
      // type == "Assign" ?
      estimate == 0
        ? `${x.label} currently meets the required story point threshold. Do you still want to assign this issue?`
        : `${x.label} has more than enough story points. Do you still wish to assign this issue?`
    );
    // estimate ? "Card is not allocated. Are you sure to update sprint for this card?" :
    // "Card is not estimated. Are you sure to update sprint for this card?"

    setStatex(x);
    setStatee(e);
    setType(type1);
  };
  const [filteredUser, setFilteredUser] = useState(doptions);
  const filterData = (e) => {
    setSearchuser(e.target.value);
    const query = e.target.value.toLowerCase();
    // setSearchQuery(query);
    const filtered = doptions.filter((d) =>
      d.label.toLowerCase().includes(query)
    );
    setFilteredUser(filtered);
  };
  const reorderedUsers = (selectedUserId) =>
    doptions
      .filter((d) => d.label.toLowerCase().includes(searchuser.toLowerCase()))
      .slice()
      .sort((a, b) => {
        if (a.EmpId === selectedUserId) return -1;
        if (b.EmpId === selectedUserId) return 1;
        return 0;
      });

  const reorderedSprint = (release) =>
    releasesOption
      .filter((d) => d.label.toLowerCase().includes(searchSprint.toLowerCase()))
      .slice()
      .sort((a, b) => {
        if (a.ReleaseName === release) return -1;
        if (b.ReleaseName === release) return 1;
        return 0;
      });
  // function clone() {
  //   setCloneopen(true);
  // }

  function handleclone() {
    // setCloneopen(false);
    dispatch(
      HANDLE_ON_CHANGE({
        name: "clonedialog",
        value: false,
      })
    );
    setSprint(defaultValue);
    setStage("Refined");
    setAssignedEmployee(null);
    setAssignedEmployeeN("null null");
    setIssueTitleedit("");

    dispatch(
      HANDLE_ON_CHANGE({
        name: "clonedata",
        value: [],
      })
    );
  }
  const handleInputChange = (event) => {
    setIssueTitleedit(event.target.value);
  };
  function handleclonesubmit() {
    setIsSubmitting(true);
    const firstname =
      assignedEmployeeN == " " || undefined
        ? " "
        : assignedEmployeeN.split(" ").length == 3
        ? assignedEmployeeN.split(" ")[0] +
          " " +
          assignedEmployeeN.split(" ")[1]
        : assignedEmployeeN.split(" ")[0];
    console.log(firstname, "fn");
    let emplastname =
      clonedata.IssueType != "Epic" ? assignedEmployeeN.split(" ").pop() : null;
    let postData = {
      ProjectId: clonedata.ProjectId,
      ProjectShortCode: clonedata.ProjectShortCode,
      IssueType: clonedata.IssueType,
      IssueTitle: issueTitleedit,
      Description: clonedata.Description,
      Priority: clonedata.Priority,
      PlannedRelease: sprint,
      EstimateUnit: clonedata.IssueType != "Epic" ? "MD" : null,
      EstimateUnitDesc: clonedata.IssueType != "Epic" ? "Man Days" : null,

      CurrentStage: [
        {
          StageCode:
            stage == "Backlog" ? "BLG" : stage == "Refined" ? "RFD" : "BLG",
          StageName:
            stage == "Backlog"
              ? "Backlog"
              : stage == "Refined"
              ? "Refined"
              : "Refined",
          DateMoved: moment().format("YYYY-MM-DD"),
        },
      ],
      LinkToEpic: clonedata.IssueType != "Epic" ? clonedata.LinkToEpic : null,
      LinkToIssues:
        clonedata.IssueType != "Epic" ? clonedata.LinkToIssues : null,
      CreatedBy: clonedata.CreatedBy,
      CreatedByDetails: clonedata.CreatedByDetails,
      // arr.RoleName == "Undefined" ? "Kanban Admin" : arr.RoleName,
      CreatedDate: moment().format("YYYY-MM-DD"),
      AssignedTo: clonedata.IssueType != "Epic" ? assignedEmployee : null,
      AssignedToDetails: { FN: firstname, LN: emplastname },
      AssignedDate: moment().format("YYYY-MM-DD"),
      Attachment: clonedata.IssueType != "Epic" ? clonedata.Attachment : null,
      IsActive: "Y",
      ExceptiontoSkipSPEstimation: "N",
      ProjectDetails: [{ ProjName: projectName }],
      AssignedToUAT: null,
      AssignedToUATDetails: {
        FN: null,
        LN: null,
      },
      ClientId: clonedata.ClientId,
      ModuleLinked: clonedata.ModuleLinked,
      AttachmentReference: null,
    };
    console.log(postData, "postdata");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("token"),
      Prefer: "return=representation",
    };
    axios
      .post("agilesaas_issue_details", postData, { headers })
      .then((ress) => {
        const newData = [
          {
            IssueId: ress.data?.[0]?.IssueId,
            IssueTitle: ress.data?.[0]?.IssueTitle,
          },
        ];

        axios
          .get(`agilesaas_releases?ReleaseName=eq.${sprint}`)
          .then((response) => {
            const existingReleaseIssues = response.data[0]?.ReleaseIssues || [];
            const updatedReleaseIssues = [...existingReleaseIssues, ...newData];

            axios
              .patch(`agilesaas_releases?ReleaseName=eq.${sprint}`, {
                ReleaseIssues: updatedReleaseIssues,
              })
              .then((patchResponse) => {})
              .catch((patchError) => {
                console.error("Error updating ReleaseIssues:", patchError);
              });
            const historyDataArray = {
              IssueId: ress?.data?.[0]?.IssueId,
              ChangedBy: empid?.[0]?.ClientUserId,
              ChangedByDetails: {
                FN: empid?.[0]?.UserFirstname,
                LN: empid?.[0]?.UserLastname,
              },
              ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
              UpdatedField: "Issue created",
              UpdatedFrom: "",
              UpdatedTo:
                clonedata.IssueType != "Epic" && assignedEmployee
                  ? "Issue created by " +
                    empid?.[0]?.UserFirstname +
                    " " +
                    empid?.[0]?.UserLastname +
                    " and allocated to " +
                    firstname +
                    " " +
                    emplastname
                  : "Issue created by " +
                    empid?.[0]?.UserFirstname +
                    " " +
                    empid?.[0]?.UserLastname,
              UpdateSequence: null,
              IsActive: "Y",
              Status: null,
              ClientId: clientId,
              ProjectId: projectCode,
            };

            axios
              .post("agilesaas_issue_history", historyDataArray)
              .then((response) => {
                // toast.success("Stage updated successfully");
                // alert("Stage updated successfully");
                console.log("");
              })
              .catch((error) => {
                console.error("Error updating stage:");
              });
          })
          .catch((error) => {
            console.error("Error fetching existing data:", error); // Handle GET request error
          });
        toast.success(
          `Success! Issue ${clonedata.IssueId} has been cloned and added to the ${stage} `,
          {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          }
        );
        Mailandnotification(
          clonedata,
          EmpDropDownData?.filter(
            (x) => x.teammemberid == assignedEmployee
          )?.[0]?.email,
          [empid?.[0]?.OfficialEmail],
          true,
          true,
          projectName,
          `Issue created ,
        ${issueTitleedit} has been created by ${
            empid?.[0]?.UserFirstname + " " + empid?.[0]?.UserLastname
          } in project ${projectName}`
        );
        if(slack && slack == "Y"){
          slacknotify(
         channelname,
         `${issueTitleedit} is created and assigned to ${EmpDropDownData?.filter(
                    (x) => x.teammemberid == assignedEmployee
                  )?.[0]?.firstname + " "+ EmpDropDownData?.filter(
                    (x) => x.teammemberid == assignedEmployee
                  )?.[0]?.lastname } by ${
                    empid?.[0]?.UserFirstname + " " + empid?.[0]?.UserLastname
                  } in project ${projectName}`,
         slacktoken
          )
        }
        setIsSubmitting(false);
        dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
        handleclone();

      })
      .catch((e) => {
        console.log(e, "error");
        setIsSubmitting(false);
      });
  }
  const handleChangepll = (e) => {
    setSprint(e);
  };
  const handleStageChange = (value) => {
    setStage(value);
  };
  const CustomSingleValue = ({ data }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={agile}
        alt="Image"
        width="30"
        height="30"
        style={{ marginRight: "2px" }}
      />
      <span>{data.label}</span>
    </div>
  );

  const CustomOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={agile}
          alt="Image"
          width="30"
          height="30"
          style={{ marginRight: "2px" }}
        />
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  );

  const handleChangsde = (selectedOption) => {
    handleChangepll(selectedOption ? selectedOption.value : "");
  };

  const [filterSprint, setFilterSprint] = useState(releasesOption);
  const filterSprintData = (e) => {
    setSearchSprint(e.target.value);
    const query = e.target.value.toLowerCase();
    // setSearchQuery(query);
    const filtered = releasesOption.filter((d) =>
      d.label.toLowerCase().includes(query)
    );
    setFilterSprint(filtered);
  };
  const assignedUpdate = (x, e) => {
    const name = EmpDropDownData?.filter(
      (n) => n.teammemberid == x?.EmpId
    )?.[0];
    const getname = { FN: name.firstname, LN: name.lastname };
    if (x.value != e.AssignedTo) {
      let data = {
        AssignedTo: x.value,
        AssignedToDetails:
          x.firstname != undefined
            ? { FN: x.firstname, LN: x.lastname }
            : getname,
        AssignedDate: moment().format("YYYY-MM-DD"),
      };
      let requrireddata = {
        ClientUserId: empid?.[0]?.ClientUserId,
        ClientId: empid?.[0]?.ClientId,
        ClientDetails: {
          Company: userorg?.Company,
          ContactName: userorg?.ContactName,
          ContactDesignation: userorg?.ContactDesignation,
          ContactEmail: userorg?.ContactEmail,
          ContactPhone: userorg?.ContactPhone,
        },
        DepartmentDetails: empid?.[0]?.DepartmentDetails,
        DepartmentCode: null,
        TentantId: "",
        FromSenderId: "no-reply@yozytech.com",
        ToEmailIds: {
          emailids: [x?.mailid],
        },
        CcEmailIds: {
          emailids: ["agileteam@yozytech.com"],
        },
        BccEmailIds: "",
        EmailType: "StoryAssignment",
        ProductDetails: {
          ProdId: "11004",
          ProdName: "KANBAN",
          Domain: "KANBAN.yozytech.com",
        },
        EmailSubject: "Story Assignment Notification",
        IsSuccess: "Y",
        IsPartiallyFailed: " ",
        SendRetryHistory: "",
        IsActive: " ",
        Status: "",
        EmailTemplateName: "StoryAssignment",
        emailnotify: true,
        webnotify: true,
        EmailTemplateVariables: {
          xxasignbyxx: arr.RoleName,
          xxassigndatexx: moment().format("YYYY-MM-DD"),
          xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
          xxprjdisxx: e?.Description,
          xxprjnamexx: projectName,
          xxstoryNamexx: e.IssueTitle,
          xxprjpercenxx: "",
          xxprjstartdatexx: "",
          xxprjenddataexx: "",
          xxteamnamexx: "",
          xxsprintxx: "",
          xxmanagernamexx: userorg?.ContactName,
          xxcompanyname: userorg?.Company,
          xxmanagerdesigxx: "Scrum Master",
          xxgmailxx: userorg?.ContactEmail,
          xxphnoxx: userorg?.ContactPhone,
          webappdescri:
            "You have assigned to " +
            e.IssueTitle +
            "in project " +
            projectName,
        },
      };
      axios
        .patch(`agilesaas_issue_details?IssueId=eq.${e.IssueId}`, data)
        .then((e) => {
          // KBRefresh()
          // setRefresh(true);
          dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
          setAssignPopover(null);
          setFilteredUser(doptions);
          setOpen(true);
          setMessage(
            `Assignment Successful: Card assigned to ${
              x.firstname != undefined
                ? x.firstname + " " + x.lastname
                : getname?.FN + " " + getname?.LN
            }.`
          );
          setMessageType("success");
          axios({
            url: "https://api.devozy.ai/api/agile/email",
            method: "post",
            data: requrireddata,
            headers: {
              origin: "https://kanban.yozytech.com",
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              console.log(res.data, "mailsend");
            })
            .catch((err) => {
              console.log(err);
            })
            .catch((err) => console.log(err));
            if(slack && slack == "Y"){
              slacknotify(
             channelname,
             `Assignment Successful: Card assigned to ${
              x.firstname != undefined
                ? x.firstname + " " + x.lastname
                : getname?.FN + " " + getname?.LN
            }.`,
             slacktoken
              )
            }
        });
      axios.post("agilesaas_issue_history", {
        IssueId: e.IssueId,
        ChangedBy: clientId,
        ChangedByDetails: {
          FN: login?.[0]?.UserFirstname,
          LN: login?.[0]?.UserLastname,
        },
        ChangedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
        UpdatedField: "Assigned To",
        UpdatedFrom: e?.AssignedToDetails?.FN + " " + e?.AssignedToDetails?.LN,
        UpdatedTo: name.firstname + " " + name.lastname,
        UpdateSequence: 1,
        IsActive: "Y",
        Status: null,
        ClientId: clientId,
        ProjectId: projectCode,
      });
    } else {
      setAssignPopover(null);
      setFilteredUser(doptions);
    }
  };

  // const updateSprint = async (item, e) => {
  //   //current sprint
  //   // if (item.value != e.PlannedRelease) {
  //   //   // setEnablesprint(true)
  //   //   dispatch(HANDLE_ON_CHANGE({ name: "enableSprint", value: true }));
  //   //   if (e.PlannedRelease) {
  //   //     await axios
  //   //       .get(`agilesaas_releases?ReleaseName=eq.${e.PlannedRelease}`)
  //   //       .then(async (releresp) => {
  //   //         let ticket = {
  //   //           IssueId: e.IssueId,
  //   //           IssueTitle: e.IssueTitle,
  //   //         };
  //   //         let rdata = releresp?.data?.[0];
  //   //         let odata =
  //   //           moment(rdata?.SprintStartDate).diff(moment(), "day") < 0
  //   //             ? {
  //   //               RemovedTickets:
  //   //                 await rdata?.RemovedTickets?.length > 0
  //   //                   ? [...rdata?.RemovedTickets, ticket]
  //   //                   : [ticket],
  //   //               ReleaseIssues: await rdata?.ReleaseIssues.filter(
  //   //                 (x) => x.IssueId != e.IssueId
  //   //               ),
  //   //             }
  //   //             : {
  //   //               ReleaseIssues: await rdata?.ReleaseIssues.filter(
  //   //                 (x) => x.IssueId != e.IssueId
  //   //               ),
  //   //             };
  //   //         await axios
  //   //           .patch(
  //   //             "agilesaas_releases?ReleaseId=eq." + rdata.ReleaseId,
  //   //             odata
  //   //           )
  //   //           .then((res) => console.log(res))
  //   //           .catch((error) => console.log(error));
  //   //       });
  //   //   }
  //   //   if (moment(item.SprintStartDate).diff(moment(), "day") < 0) {
  //   //     let ticket = {
  //   //       IssueId: e.IssueId,
  //   //       IssueTitle: e.IssueTitle,
  //   //     };
  //   //     let ndata = {
  //   //       AddedTickets:
  //   //         await item?.AddedTickets?.length > 0
  //   //           ? [...item?.AddedTickets, ticket]
  //   //           : [ticket],
  //   //       ReleaseIssues:
  //   //         await item?.ReleaseIssues?.length > 0
  //   //           ? [...item?.ReleaseIssues, ticket]
  //   //           : [ticket],
  //   //     };
  //   //     await axios
  //   //       .patch("agilesaas_releases?ReleaseId=eq." + item.ReleaseId, ndata)
  //   //       .then(async (res) => {
  //   //         await axios.patch(`agilesaas_issue_details?IssueId=eq.${e.IssueId}`, {
  //   //           PlannedRelease: item.value,
  //   //         })
  //   //           .then(async (a) => {
  //   //             if (e?.AssignedTo) {
  //   //               Mailandnotification(
  //   //                 e,
  //   //                 EmpDropDownData?.filter(
  //   //                   (x) => x.teammemberid == e?.AssignedTo
  //   //                 )?.[0]?.email,
  //   //                 [empid?.[0]?.OfficialEmail],
  //   //                 true,
  //   //                 false,
  //   //                 projectName,
  //   //                 "Sprint Update",
  //   //                 e.IssueTitle + " is assigned to " + item.value + " sprint"
  //   //               );
  //   //             }
  //   //             await axios.post("agilesaas_issue_history", {
  //   //               IssueId: e.IssueId,
  //   //               ChangedBy: clientId,
  //   //               ChangedByDetails: {
  //   //                 FN: login?.[0]?.UserFirstname,
  //   //                 LN: login?.[0]?.UserLastname,
  //   //               },
  //   //               ChangedDate: moment()
  //   //                 .utcOffset("+05:30")
  //   //                 .format("YYYY-MM-DDTHH:mm:ss"),
  //   //               UpdatedField: "Releases",
  //   //               UpdatedFrom: e.PlannedRelease,
  //   //               UpdatedTo: item.value,
  //   //               UpdateSequence: 1,
  //   //               IsActive: "Y",
  //   //               Status: null,
  //   //               ClientId: clientId,
  //   //               ProjectId: projectCode,
  //   //             })
  //   //               .then((res) => { console.log(res) })
  //   //             // KBRefresh()
  //   //             // setRefresh(true);
  //   //             dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
  //   //             setReleasePopover(null);
  //   //             setFilterSprint(releasesOption);
  //   //             setOpen(true);
  //   //             // setEnablesprint(false)
  //   //             setMessage(`Successfully assigned sprint to the ${e.IssueTitle}`);
  //   //           })
  //   //           .catch((e) => console.log(e));
  //   //       })
  //   //       .catch((error) => console.log(error));
  //   //   } else {
  //   //     let ticket = {
  //   //       IssueId: e.IssueId,
  //   //       IssueTitle: e.IssueTitle,
  //   //     };
  //   //     let patchdata = {
  //   //       PlannedTicketsCount:
  //   //         await item.PlannedTicketsCount == null || item.PlannedTicketsCount == 0
  //   //           ? 1
  //   //           : parseInt(item.PlannedTicketsCount) + 1,
  //   //       ReleaseIssues:
  //   //         await item.ReleaseIssues?.length > 0
  //   //           ? [...item.ReleaseIssues, ticket]
  //   //           : [ticket],
  //   //     };
  //   //     await axios
  //   //       .patch("agilesaas_releases?ReleaseId=eq." + item.ReleaseId, patchdata)
  //   //       .then(async (res) => {
  //   //         await axios.patch(`agilesaas_issue_details?IssueId=eq.${e.IssueId}`, {
  //   //           PlannedRelease: item.value,
  //   //         })
  //   //           .then(async (a) => {
  //   //             if (e?.AssignedTo) {
  //   //               Mailandnotification(
  //   //                 e,
  //   //                 EmpDropDownData?.filter(
  //   //                   (x) => x.teammemberid == e?.AssignedTo
  //   //                 )?.[0]?.email,
  //   //                 [empid?.[0]?.OfficialEmail],
  //   //                 true,
  //   //                 false,
  //   //                 projectName,
  //   //                 "Sprint Update",
  //   //                 e.IssueTitle + " is assigned to " + item.value + " sprint"
  //   //               );
  //   //             }
  //   //             await axios.post("agilesaas_issue_history", {
  //   //               IssueId: e.IssueId,
  //   //               ChangedBy: clientId,
  //   //               ChangedByDetails: {
  //   //                 FN: login?.[0]?.UserFirstname,
  //   //                 LN: login?.[0]?.UserLastname,
  //   //               },
  //   //               ChangedDate: moment()
  //   //                 .utcOffset("+05:30")
  //   //                 .format("YYYY-MM-DDTHH:mm:ss"),
  //   //               UpdatedField: "Releases",
  //   //               UpdatedFrom: e.PlannedRelease,
  //   //               UpdatedTo: item.value,
  //   //               UpdateSequence: 1,
  //   //               IsActive: "Y",
  //   //               Status: null,
  //   //               ClientId: clientId,
  //   //               ProjectId: projectCode,
  //   //             })
  //   //               .then((res) => { console.log(res) })
  //   //             // KBRefresh()
  //   //             // setRefresh(true);
  //   //             dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
  //   //             setReleasePopover(null);
  //   //             setFilterSprint(releasesOption);
  //   //             setOpen(true);
  //   //             setMessage(`Successfully assigned sprint to the ${e.IssueTitle}`);
  //   //             // setEnablesprint(false)
  //   //           })
  //   //           .catch((e) => console.log(e));
  //   //       })
  //   //       .catch((error) => console.log(error));
  //   //   }

  //   // }
  //   if (e.PlannedRelease == null) {
  //     axios
  //       .get("agilesaas_releases?ReleaseId=eq." + item.value)
  //       .then((res) => {
  //         if (res.data?.length != 0) {
  //           // Compare the dates and return true if SprintStartDate is less than today's date, else return false
  //           let isSprintStartDateLessThanToday = moment(
  //             res?.data?.[0]?.SprintStartDate
  //           ).diff(moment(), "days");
  //           let oldrelease =
  //             res?.data?.[0].ReleaseIssues == null
  //               ? []
  //               : res?.data?.[0].ReleaseIssues;
  //           let odlticket =
  //             res?.data?.[0].AddedTickets == null
  //               ? []
  //               : res?.data?.[0].AddedTickets;
  //           let newaddedticket = [
  //             ...odlticket,
  //             {
  //               IssueId: e?.IssueId,
  //               IssueTitle: `${e.IssueTitle}`,
  //               DateAdded: moment(),
  //             },
  //           ];
  //           let odlactualticket =
  //             res?.data[0].ActualTickets == null
  //               ? []
  //               : res?.data[0].ActualTickets;
  //           let newpalnaddticket = [
  //             ...odlactualticket,
  //             {
  //               IssueId: e?.IssueId,
  //               IssueTitle: e?.IssueTitle,
  //             },
  //           ];

  //           let patchdata =
  //             isSprintStartDateLessThanToday > 0
  //               ? {
  //                 ActualTickets: newpalnaddticket,
  //                 PlannedTicketsCount: newpalnaddticket?.length,
  //                 ReleaseIssues: [
  //                   ...oldrelease,
  //                   {
  //                     IssueId: e?.IssueId,
  //                     IssueTitle: e?.IssueTitle,
  //                   },
  //                 ],
  //               }
  //               : {
  //                 AddedTickets: newaddedticket,
  //                 ReleaseIssues: [
  //                   ...oldrelease,
  //                   {
  //                     IssueId: e?.IssueId,
  //                     IssueTitle: e?.IssueTitle,
  //                   },
  //                 ],
  //               };
  //           axios
  //             .patch(
  //               "agilesaas_releases?ReleaseId=eq." + item.value,
  //               patchdata
  //             )
  //             .then((res) => {
  //               dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
  //               setReleasePopover(null);
  //               // setFilterSprint(releasesOption);
  //               setOpen(true);
  //               setMessage(`Successfully assigned sprint to the ${e.IssueTitle}`);
  //               // axios.patch(`agilesaas_issue_details?IssueId=eq.${e.IssueId}`, {
  //               //   PlannedRelease: item.value,
  //               // })
  //               //   .then(async (a) => {
  //               //     if (e?.AssignedTo) {
  //               //       Mailandnotification(
  //               //         e,
  //               //         EmpDropDownData?.filter(
  //               //           (x) => x.teammemberid == e?.AssignedTo
  //               //         )?.[0]?.email,
  //               //         [empid?.[0]?.OfficialEmail],
  //               //         true,
  //               //         false,
  //               //         projectName,
  //               //         "Sprint Update",
  //               //         e.IssueTitle + " is assigned to " + item.value + " sprint"
  //               //       );
  //               //     }
  //               //     await axios.post("agilesaas_issue_history", {
  //               //       IssueId: e.IssueId,
  //               //       ChangedBy: clientId,
  //               //       ChangedByDetails: {
  //               //         FN: login?.[0]?.UserFirstname,
  //               //         LN: login?.[0]?.UserLastname,
  //               //       },
  //               //       ChangedDate: moment()
  //               //         .utcOffset("+05:30")
  //               //         .format("YYYY-MM-DDTHH:mm:ss"),
  //               //       UpdatedField: "Releases",
  //               //       UpdatedFrom: e.PlannedRelease,
  //               //       UpdatedTo: item.value,
  //               //       UpdateSequence: 1,
  //               //       IsActive: "Y",
  //               //       Status: null,
  //               //       ClientId: clientId,
  //               //       ProjectId: projectCode,
  //               //     })
  //               //       .then((res) => { console.log(res) })
  //               //     // KBRefresh()
  //               //     // setRefresh(true);
  //               //     dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
  //               //     setReleasePopover(null);
  //               //     setFilterSprint(releasesOption);
  //               //     setOpen(true);
  //               //     setMessage(`Successfully assigned sprint to the ${e.IssueTitle}`);
  //               //     // setEnablesprint(false)
  //               //   })
  //             })
  //             .catch((error) => console.log(error));
  //         }
  //       });
  //   } else
  //     if (e.PlannedRelease != null && item.value != null) {
  //       axios
  //         .get("agilesaas_releases?ReleaseId=eq." + item.value)
  //         .then((res) => {
  //           if (res.data?.length != 0) {
  //             let isSprintStartDateLessThanToday = moment(
  //               res?.data?.[0]?.SprintStartDate
  //             ).diff(moment(), "days");
  //             let oldrelease =
  //               res?.data?.[0]?.ReleaseIssues == null
  //                 ? []
  //                 : res?.data?.[0]?.ReleaseIssues;
  //             let odlticket =
  //               res?.data?.[0]?.AddedTickets == null
  //                 ? []
  //                 : res?.data?.[0]?.AddedTickets;
  //             let newaddedticket = [
  //               ...odlticket,
  //               {
  //                 IssueId: e?.IssueId,
  //                 IssueTitle: e?.IssueTitle,
  //                 DateAdded: moment(),
  //               },
  //             ];
  //             let odlactualticket =
  //               res?.data?.[0]?.ActualTickets == null
  //                 ? []
  //                 : res?.data?.[0]?.ActualTickets;
  //             let newpalnaddticket = [
  //               ...odlactualticket,
  //               {
  //                 IssueId: e?.IssueId,
  //                 IssueTitle: e?.IssueTitle,
  //               },
  //             ];
  //             let patchdata =
  //               isSprintStartDateLessThanToday > 0
  //                 ? {
  //                   ActualTickets: newpalnaddticket,
  //                   PlannedTicketsCount: newpalnaddticket?.length,
  //                   ReleaseIssues: [
  //                     ...oldrelease,
  //                     {
  //                       IssueId: e?.IssueId,
  //                       IssueTitle: e?.IssueTitle,
  //                     },
  //                   ],
  //                 }
  //                 : {
  //                   AddedTickets: newaddedticket,
  //                   ReleaseIssues: [
  //                     ...oldrelease,
  //                     {
  //                       IssueId: e?.IssueId,
  //                       IssueTitle: e?.IssueTitle,
  //                     },
  //                   ],
  //                 };

  //             axios
  //               .patch(
  //                 "agilesaas_releases?ReleaseId=eq." + item.value,
  //                 patchdata
  //               )
  //               .then((res) => {
  //                 setReleasePopover(null);
  //                 // setFilterSprint(releasesOption);
  //                 setOpen(true);
  //                 setMessage(`Successfully assigned sprint to the ${e.IssueTitle}`);
  //               })
  //               .catch((error) => console.log(error));
  //           }
  //         });

  //       axios
  //         .get("agilesaas_releases?ReleaseName=eq." + e?.PlannedRelease)
  //         .then((res) => {
  //           let id = res.data?.[0]?.ReleaseId;
  //           if (res.data?.length != 0) {
  //             let finaldata = res.data?.[0]?.ReleaseIssues.filter(
  //               (x) => x.IssueId != e?.IssueId
  //             );
  //             let odlticket =
  //               res?.data?.[0]?.RemovedTickets == null
  //                 ? []
  //                 : res?.data?.[0]?.RemovedTickets;
  //             let newRemovedTickets = [
  //               ...odlticket,
  //               {
  //                 IssueId: e?.IssueId,
  //                 IssueTitle: e?.IssueTitle,
  //                 DateRemoved: moment(),
  //               },
  //             ];

  //             axios
  //               .patch("agilesaas_releases?ReleaseId=eq." + id, {
  //                 RemovedTickets: newRemovedTickets,
  //                 ReleaseIssues: finaldata,
  //               })
  //               .then((e) => {
  //                 console.log(e);
  //               });
  //           }
  //         })
  //         .catch((error) => console.log(error));
  //     }
  // };

  const patchrelease = (item, e) => {
    axios
      .patch(`agilesaas_issue_details?IssueId=eq.${e.IssueId}`, {
        PlannedRelease: item.value,
      })
      .then(async (a) => {
        if (e?.AssignedTo) {
          Mailandnotification(
            e,
            EmpDropDownData?.filter((x) => x.teammemberid == e?.AssignedTo)?.[0]
              ?.email,
            [empid?.[0]?.OfficialEmail],
            true,
            false,
            projectName,
            "Sprint Update",
            e.IssueTitle + " is assigned to " + item.value + " sprint"
          );
          if(slack && slack == "Y"){
            slacknotify(
           channelname,
           e.IssueTitle + " is assigned to " + item.value + " sprint",
           slacktoken
            )
          }
        }
        await axios
          .post("agilesaas_issue_history", {
            IssueId: e.IssueId,
            ChangedBy: clientId,
            ChangedByDetails: {
              FN: login?.[0]?.UserFirstname,
              LN: login?.[0]?.UserLastname,
            },
            ChangedDate: moment()
              .utcOffset("+05:30")
              .format("YYYY-MM-DDTHH:mm:ss"),
            UpdatedField: "Releases",
            UpdatedFrom: e.PlannedRelease,
            UpdatedTo: item.value,
            UpdateSequence: 1,
            IsActive: "Y",
            Status: null,
            ClientId: clientId,
            ProjectId: projectCode,
          })
          .then((res) => {
            console.log(res);
          });
        // KBRefresh()
        // setRefresh(true);
        dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
        setReleasePopover(null);
        setFilterSprint(releasesOption);
        setOpen(true);
        setMessage(`Successfully assigned sprint to the ${e.IssueTitle}`);
        setMessageType("success");
        // setEnablesprint(false)
      });
  };

  function removeAllOccurrencesFromArray(array, key, value) {
    let index;
    while ((index = array.findIndex((item) => item[key] === value)) !== -1) {
      array.splice(index, 1);
    }
    return array;
  }

  const updateSprint = (item, e) => {
    // console.log(e, "oooooooooooooooooooooooooooooooo")
    if (e?.PlannedRelease != item?.ReleaseName) {
      axios
        .get(
          `agilesaas_releases?ReleaseId=eq.${item?.ReleaseId}&select=ReleaseIssues`
        )
        .then((res) => {
          const data = res?.data?.[0]?.ReleaseIssues;
          let patchdata = data
            ? [...data, { IssueId: e.IssueId, IssueTitle: e?.IssueTitle }]
            : [{ IssueId: e.IssueId, IssueTitle: e?.IssueTitle }];
          if (e?.PlannedRelease == null) {
            axios
              .patch(`agilesaas_releases?ReleaseId=eq.${item?.ReleaseId}`, {
                ReleaseIssues: patchdata,
              })
              .then((res1) => {
                patchrelease(item, e);
              });
          } else if (e?.PlannedRelease) {
            axios
              .patch(`agilesaas_releases?ReleaseId=eq.${item?.ReleaseId}`, {
                ReleaseIssues: patchdata,
              })
              .then((res) => {
                axios
                  .get(
                    `agilesaas_releases?ReleaseName=eq.${e?.PlannedRelease}&select=ReleaseIssues,ReleaseId`
                  )
                  .then((response) => {
                    const data = response?.data?.[0];
                    axios
                      .patch(
                        `agilesaas_releases?ReleaseId=eq.${data?.ReleaseId}`,
                        {
                          ReleaseIssues: removeAllOccurrencesFromArray(
                            data?.ReleaseIssues,
                            "IssueId",
                            e?.IssueId
                          ),
                        }
                      )
                      .then((r1) => {
                        patchrelease(item, e);
                      });
                  });
              });
          }
        });
    }
    // console.log(item, "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", e, "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
  };

  // console.log(Assetoptions, "Assetoptions");

  const calculateTotalEstimates = (
    dataArray,
    totalPoints,
    TeamLeavesConsidered
  ) => {
    // console.log(dataArray, "dataArray");
    const estimateByAssignedTo = dataArray?.reduce(
      (accumulator, currentValue) => {
        const { AssignedTo, OriginalEstimate } = currentValue;

        accumulator[AssignedTo] =
          (accumulator[AssignedTo] || 0) + OriginalEstimate;
        return accumulator;
      },
      {}
    );
    console.log(estimateByAssignedTo, "eeeeeeeeeeeeeeeeeeeee");
    return Object.entries(estimateByAssignedTo).map(([assignedTo, total]) => ({
      AssignedTo: parseInt(assignedTo),
      Estimate:
        totalPoints -
        (total + TeamLeavesConsidered?.length > 0
          ? TeamLeavesConsidered?.filter(
              (x) => x.UserId == parseInt(assignedTo)
            )?.[0]?.Leavesplanned
          : 0),
      // differences1(
      //   specialcover,
      //   leaveData.filter((x) => x.UserId == parseInt(assignedTo)),
      //   startDate,
      //   endDate
      // )
      Userpoints:
        total +
        // differences1(
        //   specialcover,
        //   leaveData.filter((x) => x.UserId == parseInt(assignedTo)),
        //   startDate,
        //   endDate
        // )
        (TeamLeavesConsidered?.length > 0
          ? TeamLeavesConsidered?.filter(
              (x) => x.UserId == parseInt(assignedTo)
            )?.[0]?.Leavesplanned
          : 0),
      leave:
        // differences1(
        //   specialcover,
        //   leaveData.filter((x) => x.UserId == parseInt(assignedTo)),
        //   startDate,
        //   endDate
        // )
        TeamLeavesConsidered?.filter(
          (x) => x.UserId == parseInt(assignedTo)
        )?.[0]?.Leavesplanned,
    }));
  };
  const filteredReleases =
    releases?.filter((x) => x.Status == null && x.IsReleaseFrozen == "N")
      ?.length > 0
      ? releases?.filter((x) => x.Status == null && x.IsReleaseFrozen === "N")
      : "No planned sprint";

  const releaseData =
    releases.length == undefined || releases.length == 0
      ? []
      : releases.map((e) => {
          const filteredData = KanbanBoardData?.filter(
            (item) => item.PlannedRelease === e.ReleaseName
          );
          const totalPoints =
            e.TotalDaysInSprint == undefined ? 0 : e.TotalDaysInSprint;
          const scover = e.SpecialCover ? e.SpecialCover : [];
          console.log(
            filteredData,
            e.ReleaseName,
            totalPoints,
            scover,
            "cccccccccccccccccccccccccccccc",
            calculateTotalEstimates(
              filteredData,
              totalPoints,
              e.TeamLeavesConsidered
            )
          );
          return {
            TotalDaysInSprint: totalPoints,
            releasename: e.ReleaseName,
            data: calculateTotalEstimates(
              filteredData,
              totalPoints,
              e.TeamLeavesConsidered
            ),
            startDate: e.SprintStartDate,
            endDate: e.SprintEndDate,
            Type:
              moment(e.SprintStartDate).diff(moment(), "day") < 0
                ? "In-flight"
                : "Planned",
            SpecialCover: scover,
            TeamLeavesConsidered: e.TeamLeavesConsidered,
          };
        });

  // console.log(releaseData, "releaseData");
  const containerStylediv = {
    position: "relative",
  };
  const buttonStyleforuat = {
    position: "absolute",
    left: "-200%",
    top: 0, // Adjust top position as needed
  };

  temp =
    check === null
      ? null
      : currentsprintcard
          ?.filter(
            (d) =>
              d?.IssueTitle?.toLowerCase().includes(searchText) ||
              d?.IssueId?.toString().includes(searchText?.toString())
            // ||
            // d.answer.toLowerCase().includes(query)
          )
          ?.map((e, index) => {
            const canEdit =
              e?.IssueType != "Epic" &&
              (projectRole == "SM" ||
                projectRole == "TL" ||
                e?.CreatedBy == login?.[0]?.ClientUserId);
                
            const getLastUpdatedProgress = (progressArray) => {
              if (!progressArray || progressArray.length === 0) {
                // console.log("Progress array is empty or undefined.");
                return { PercentageProgressed: 0, RiskOfDelivery: null };
              }

              // Make a copy of the array before sorting
              const sortedProgress = [...progressArray].sort(
                (a, b) => new Date(b.UpdatedDate) - new Date(a.UpdatedDate)
              );

              // Get the latest progress entry
              const latestProgress = sortedProgress?.[0];

              // Handle missing properties with default values
              return {
                PercentageProgressed:
                  latestProgress.PercentageProgressed ??
                  latestProgress.PercentageProgressed ??
                  0,
                RiskOfDelivery:
                  latestProgress.RiskofDelivery ??
                  latestProgress.RiskOfDelivery ??
                  null,
              };
            };

            const latestProgress = getLastUpdatedProgress(e?.AllProgress || []);

            const progress =
              rolecode === "SM" || projectRole === "SM"
                ? latestProgress.PercentageProgressed !== undefined || null
                  ? latestProgress.PercentageProgressed
                  : null
                : e?.percentageprogressed !== undefined
                ? e?.percentageprogressed
                : null;

            let getrag =
              rolecode === "SM" || projectRole === "SM"
                ? latestProgress.RiskOfDelivery
                : e?.RiskOfDelivery;
            return {
              id: e.IssueId,
              title: null,
              assignedto:
                e.AssignedToDetails?.FN + " " + e.AssignedToDetails?.LN,
              percentageprogressed: progress,
              // getrag != 0 ? getrag[0]?.PercentageProgressed : null,
              createddate: e.CreatedDate,
              Epic:
                e.LinkToEpic && e.LinkToEpic.length > 0
                  ? e?.LinkToEpic?.[0]?.IssueId
                  : null,
              description: (
                // <Card className="fontdesign">
                <div className="" key={e}>
                  <div className="FisrtRow">
                    <div
                      className="Fisrtcol"
                      style={{ display: "flex", flexDirection: "row", gap: 0 }}
                    >
                      <OverlayTrigger
                        trigger={["hover", "hover"]}
                        placement="top"
                        delay={{ show: 250, hide: 50 }}
                        overlay={
                          <Popover>
                            <Popover.Content>{e?.IssueType}</Popover.Content>
                          </Popover>
                        }
                      >
                        <span className="cardIcon2 ">
                          {e.IssueType === "Story" ? (
                            <Bookmark
                              className="story fonticons"
                              style={{ fontSize: "13px" }}
                            />
                          ) : e.IssueType === "Task" ? (
                            <CheckBoxRoundedIcon
                              className="task fonticons"
                              style={{ fontSize: "13px" }}
                            />
                          ) : e.IssueType === "Bug" ? (
                            <StopCircleIcon
                              style={{ fontSize: "13px" }}
                              className="bug fonticons"
                            />
                          ) : e.IssueType === "Epic" ? (
                            <OfflineBoltRoundedIcon
                              style={{ fontSize: "13px" }}
                              className="epic fonticons"
                            />
                          ) : e.IssueType === "SubTask" ? (
                            <AddToPhotosIcon className="kanbanicon task" />
                          ) : (
                            ""
                          )}
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        trigger={["hover", "hover"]}
                        placement="top"
                        delay={{ show: 250, hide: 50 }}
                        overlay={
                          <Popover>
                            <Popover.Content>{e?.Priority}</Popover.Content>
                          </Popover>
                        }
                      >
                        <span>
                          {e.Priority === "Highest" ? (
                            <KeyboardDoubleArrowUpIcon
                              className=" filter-icons-1"
                              fontSize="small"
                            />
                          ) : e.Priority === "High" ? (
                            <KeyboardArrowUpIcon
                              className="filter-icons-1"
                              fontSize="small"
                            />
                          ) : e.Priority === "Medium" ? (
                            <DragHandleIcon
                              className="filter-icons"
                              fontSize="small"
                            />
                          ) : e.Priority === "Low" ? (
                            <KeyboardArrowDownIcon
                              className="filter-icons-2"
                              fontSize="small"
                            />
                          ) : e.Priority === "Lowest" ? (
                            <KeyboardDoubleArrowDownIcon
                              className="filter-icons-2"
                              fontSize="small"
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </OverlayTrigger>

                      {/* <span className="pl-1 ">
                    {e.PlannedRelease === null ? null : e.PlannedRelease !=
                      "" &&
                      e.CurrentStage[0].StageCode != "BLG" &&
                      e.IsPartOfCurrentRelease == "Y" ? (
                      <DirectionsRunIcon
                        className="kanbanicon22"
                        style={{ backgroundColor: "aquamarine" }}
                      />
                    ) : (
                      ""
                    )}
                  </span> */}
                      {/* {e.PlannedRelease != null &&
                    releasesOption.filter(
                      (option) => option?.label === e.PlannedRelease
                    ).length == 0 ? (
                    <span className="pl-1 ">
                      {e.PlannedRelease === null ? null : e.PlannedRelease !=
                        "" &&
                        e.CurrentStage[0].StageCode != "BLG" &&
                        e.IsPartOfCurrentRelease == "Y" ? (
                        <DirectionsRunIcon
                          className="kanbanicon22"
                          style={{ backgroundColor: "aquamarine" }}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  ) : ( */}
                      {/* <div onClick={(e) => e.stopPropagation()}> */}
                      {/* <Select
                        className="pl-0 m-0"
                        options={releasesOption}
                        placeholder="Assign Sprint"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(item) => {
                          //handleProject(item);
                          setReleaseValue({
                            index: index,
                            value: item.value,
                          });
                          //current sprint
                          if (item.value != e.PlannedRelease) {
                            if (e.PlannedRelease) {

                              axios
                                .get(
                                  `agilesaas_releases?ReleaseName=eq.${e.PlannedRelease}`
                                )
                                .then((releresp) => {
                                  //KBRefresh()
                                  let ticket = {
                                    IssueId: e.IssueId,
                                    IssueTitle: e.IssueTitle,
                                  };
                                  let rdata = releresp?.data?.[0];
                                  let odata = moment(item.SprintStartDate).diff(
                                    moment(),
                                    "day"
                                  ) < 0 ? {
                                    RemovedTickets: rdata?.RemovedTickets ? [...rdata?.RemovedTickets, ticket]
                                      : [ticket],
                                    ReleaseIssues:
                                      rdata?.ReleaseIssues.filter(
                                        (x) => x.IssueId != e.IssueId
                                      ),
                                  } : {
                                    ReleaseIssues:
                                      rdata?.ReleaseIssues.filter(
                                        (x) => x.IssueId != e.IssueId
                                      ),
                                  };
                                  // axios
                                  //   .patch(
                                  //     "agilesaas_releases?ReleaseId=eq." + rdata.ReleaseId,
                                  //     odata
                                  //   )
                                  //   .then((res) => console.log(res))
                                  //   .catch((error) => console.log(error));
                                });
                            }
                            if (
                              moment(item.SprintStartDate).diff(
                                moment(),
                                "day"
                              ) < 0
                            ) {
                              let ticket = {
                                IssueId: e.IssueId,
                                IssueTitle: e.IssueTitle,
                              };
                              let ndata = {
                                AddedTickets: item?.AddedTickets
                                  ? [...item?.AddedTickets, ticket]
                                  : [ticket],
                                ReleaseIssues: item?.ReleaseIssues ? [
                                  ...item?.ReleaseIssues,
                                  ticket,
                                ] : [ticket],
                              };
                              // axios
                              //   .patch(
                              //     "agilesaas_releases?ReleaseId=eq." + item.value,
                              //     ndata
                              //   )
                              //   .then((res) => console.log(res))
                              //   .catch((error) => console.log(error));
                            } else {
                              let ticket = {
                                IssueId: e.IssueId,
                                IssueTitle: e.IssueTitle,
                              };
                              let patchdata = {
                                PlannedTicketsCount:
                                  item.PlannedTicketsCount == null ||
                                    item.PlannedTicketsCount == 0
                                    ? 1
                                    : parseInt(item.PlannedTicketsCount) + 1,
                                ReleaseIssues: item.ReleaseIssues ? [
                                  ...item.ReleaseIssues,
                                  ticket,
                                ] : [ticket],
                              };
                              // axios
                              //   .patch(
                              //     "agilesaas_releases?ReleaseId=eq." + item.value,
                              //     patchdata
                              //   )
                              //   .then((res) => console.log(res))
                              //   .catch((error) => console.log(error));
                            }

                            // axios.patch(`agilesaas_issue_details?IssueId=eq.${e.IssueId}`, {
                            //   PlannedRelease: item.value
                            // }).then((e) => KBRefresh())
                            //yet to update agilesaas_releases api
                          }
                        }}
                        value={
                          releaseValue && releaseValue.index === index
                            ? releasesOption.filter(
                              (option) =>
                                option?.label === releaseValue.value
                            )
                            : releasesOption.filter(
                              (option) => option?.label === e.PlannedRelease
                            )
                        }
                        styles={customStyles}
                        maxMenuHeight={400}
                      /> */}
                      {/* </div>
                  )} */}
                      {/* {console.log(
                        e?.CurrentStage?.StageCode != "DONE",
                        "sample"
                      )} */}
                      <OverlayTrigger
                        ref={popoverRef}
                        show={
                          releasePopover === index &&
                          e?.CurrentStage?.[0]?.StageCode != "DONE"
                        }
                        trigger={["hover", "hover"]}
                        placement="top"
                        delay={{ show: 250, hide: 50 }}
                        overlay={
                          <Popover
                            style={{
                              // borderColor: 'grey',
                              border: "none",
                              boxShadow: "3px 3px 5px #888888",
                              // maxWidth: 250,
                            }}
                            // disabled={open}
                            onClick={(event) => event.stopPropagation()}
                          >
                            <Popover.Content
                              onMouseDown={() => setInsidePopoverContent(true)}
                              onMouseUp={() => setInsidePopoverContent(false)}
                              onMouseLeave={() =>
                                setInsidePopoverContent(false)
                              }
                              style={{
                                maxHeight: 200,
                                minHeight: 90,
                                overflowY:
                                  reorderedSprint(e.PlannedRelease)?.length < 4
                                    ? "hidden"
                                    : "scroll",
                              }}
                            >
                              <Search>
                                <SearchIconWrapper>
                                  <SearchIcon
                                    style={{ fontSize: 15, color: "#616670" }}
                                  />
                                </SearchIconWrapper>
                                <StyledInputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  onChange={(e) => {
                                    filterSprintData(e);
                                  }}
                                  value={searchSprint}
                                />
                              </Search>
                              <Divider />
                              {/* {console.log(releaseData, "another project")} */}
                              {releaseData.length == 0 ? (
                                <Button
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingTop: 9,
                                    width: "100%",
                                    // justifyContent: 'flex-start',
                                    textTransform: "none",
                                    color: "black",
                                    fontSize: 11,
                                    backgroundColor: "#fff",
                                    textAlign: "center",
                                  }}
                                >
                                  No sprint
                                </Button>
                              ) : (
                                reorderedSprint(e.PlannedRelease)?.map((x) => (
                                  <Button
                                    // disabl={enablesprint}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: 2,
                                      width: "100%",
                                      justifyContent: "flex-start",
                                      textTransform: "none",
                                      color: "black",
                                      fontSize: 11,
                                      backgroundColor:
                                        x.ReleaseName == e.PlannedRelease
                                          ? "#f3f3f3"
                                          : "#fff",
                                    }}
                                    onClick={() => {
                                      // if (e.AssignedTo == null || e.OriginalEstimate == null) {
                                      //   handleDialog("Release", e.AssignedTo == null, x, e)
                                      // }
                                      // else {
                                      updateSprint(x, e);
                                      // }
                                    }}
                                  >
                                    <span style={{ flexGrow: 1 }}>
                                      {/* Adjust the styles as needed */}
                                      {x.label}
                                    </span>
                                    <label
                                      style={{
                                        textAlign: "center",
                                        marginLeft: "auto",
                                        padding: 1,
                                        fontSize: 9,
                                        backgroundColor: "#DDE9FF",
                                        borderRadius: 5,
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        marginTop: 6,
                                        marginLeft: 2,
                                      }}
                                    >
                                      {moment(x.SprintStartDate).diff(
                                        moment(),
                                        "day"
                                      ) < 0
                                        ? "In-flight"
                                        : "Planned"}
                                    </label>
                                  </Button>
                                ))
                              )}
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        {/* Empty span (or any other trigger element) */}
                        {/* {console.log(Refresh)} */}
                        <span
                          // aria-disabled={enablesprint}
                          aria-disabled={enablesprint}
                          onClick={(e) => {
                            if (
                              (rolecode == "SM" || projectRole == "SM") &&
                              e?.CurrentStage?.[0]?.StageCode != "BLG"
                            ) {
                              e.stopPropagation();
                              handleCreateClick(index, "release");
                            }
                          }}
                        >
                          {/* {Refresh && "ccccccccccccccccccccccccccccc"} */}
                          <span>
                            {e.IssueType != "Epic" &&
                            e?.CurrentStage?.[0]?.StageCode != "BLG" ? (
                              e?.PlannedRelease === null ? (
                                <label>Assign sprint</label>
                              ) : e.PlannedRelease != "" &&
                                e?.CurrentStage?.[0].StageCode != "BLG" &&
                                e?.IsPartOfCurrentRelease == "Y" ? (
                                <DirectionsRunIcon
                                  className="kanbanicon22"
                                  style={{ backgroundColor: "aquamarine" }}
                                />
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </span>

                          {e.PlannedRelease ===
                          null ? null : e.PlannedRelease != "" ? (
                            // && e.CurrentStage[0].StageCode != "BLG"
                            // <Button
                            <span
                              //Button
                              style={{
                                padding: 0,
                                textTransform: "none",
                                color: "#132043",
                                margin: 0,
                                backgroundColor: "rgba(224, 244, 255, 0.8)",
                                paddingTop: 3,
                                paddingBottom: 1,
                                paddingLeft: 0.5,
                                paddingRight: 0.5,
                                fontSize: "5px",
                              }}
                              className="planicon"
                            >
                              {e.PlannedRelease}
                              {/* </Button> */}
                            </span>
                          ) : null}
                        </span>
                      </OverlayTrigger>

                      {/* <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="top"
                    delay={{ show: 250, hide: 50 }}
                    overlay={
                      <Popover>
                        <Popover.Content>{e?.Priority}</Popover.Content>
                      </Popover>
                    }
                  >
                    <span>
                      {e.PlannedRelease === null ? null : e.PlannedRelease !=
                        "" && e.CurrentStage[0].StageCode != "BLG" ? (
                        <Label className="planicon"
                          style={{
                            backgroundColor: "rgba(224, 244, 255, 0.8)",
                            // "rgba(197, 255, 248, 0.7)",
                            // "rgba(95, 189, 255, 0.3)",
                            // "rgb(238 244 255)", 
                            padding: 2, marginRight: 2,
                            // backgroundColor: "rgba(150, 239, 255, 0.5)"
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleCreateClick(index, "release")
                          }}
                        >{e.PlannedRelease}</Label>
                      ) : null}
                    </span>
                  </OverlayTrigger> */}

                      {/* {releasePopover === index && (
                    <OverlayTrigger
                      ref={releaseRef}
                      show={releasePopover === index}
                      trigger={["hover", "hover"]}
                      placement="top"
                      delay={{ show: 250, hide: 50 }}
                      overlay={
                        <Popover style={{
                          // borderColor: 'grey',
                          border: 'none',
                          boxShadow: '3px 3px 5px #888888'
                        }}
                          onClick={(event) => event.stopPropagation()}>
                          <Popover.Content
                            onMouseDown={() => setReleasePopoverContent(true)}
                            onMouseUp={() => setReleasePopoverContent(false)}
                            onMouseLeave={() => setReleasePopoverContent(false)}
                          >

                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <span></span>
                    </OverlayTrigger>
                  )} */}
                    </div>
                    <div className="DateCol">
                      <OverlayTrigger
                        trigger={["hover", "hover"]}
                        placement="top"
                        delay={{ show: 250, hide: 50 }}
                        overlay={
                          <Popover>
                            <Popover.Content>
                              {moment(e.CreatedDate).format("DD MMM YYYY")}
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Label className="CardDate">
                          {moment(e.CreatedDate).format("DD MMM YYYY")}
                        </Label>
                      </OverlayTrigger>
                    </div>
                  </div>

                  <div>
                    <Label
                      className="TitleName"
                      style={{ color: "black", margin: "10px 0" }}
                    >
                      {e.IssueTitle}
                      {/* {console.log(e, "issue details")} */}
                    </Label>
                  </div>
                  <div className="lastrow">
                    <div
                      ref={popoverRef}
                      style={{ display: "flex", gap: 2, alignItems: "center" }}
                    >
                      <span
                        className="iconspan"
                        // style={{ backgroundColor: '#435585', color: '#fff', padding: 2 }}
                      >
                        {e.ProjectShortCode}
                        {e.IssueId}
                      </span>

                      {/* {activePopover === index && ( */}
                      <OverlayTrigger
                        ref={popoverRef}
                        show={activePopover === index}
                        trigger={["hover", "hover"]}
                        placement="top"
                        delay={{ show: 250, hide: 50 }}
                        overlay={
                          <Popover
                            style={{
                              // borderColor: 'grey',
                              border: "none",
                              boxShadow: "3px 3px 5px #888888",
                            }}
                            onClick={(event) => event.stopPropagation()}
                          >
                            <Popover.Content
                              onMouseDown={() => setInsidePopoverContent(true)}
                              onMouseUp={() => setInsidePopoverContent(false)}
                              onMouseLeave={() =>
                                setInsidePopoverContent(false)
                              }
                            >
                              <label
                                style={{
                                  fontWeight: "700",
                                  fontSize: 16,
                                  color: "#4C4848",
                                }}
                              >
                                Create a story
                              </label>
                              <Divider />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  style={{
                                    fontWeight: "700",
                                    fontSize: 14,
                                    color: "grey",
                                  }}
                                >
                                  Title
                                </label>
                                <textarea
                                  rows="1"
                                  cols="50"
                                  placeholder="Enter title"
                                  className="SprintFormFields"
                                  // style={{ border: '2px solid grey', borderRadius: 5, padding: 2 }}
                                  onClick={(event) => event.stopPropagation()}
                                  value={issueTitle}
                                  onChange={(event) => {
                                    event.stopPropagation();
                                    setIssueTitle(event.target.value);
                                  }}
                                ></textarea>
                                <Button
                                  style={{
                                    alignSelf: "flex-end",
                                    width: 70,
                                    height: 25,
                                    backgroundColor: "#5A88F1",
                                    color: "#fff",
                                    marginTop: 10,
                                    textTransform: "none",
                                  }}
                                  // disabled={issueTitle == ""}
                                  onClick={(event) => {
                                    handleCreateTask(event, e);
                                  }}
                                >
                                  Create
                                </Button>
                              </div>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        {/* Empty span (or any other trigger element) */}
                        <span>
                          {e.IssueType == "Epic" &&
                          e?.CurrentStage?.[0]?.StageCode !== "DONE" ? (
                            <button
                              class="cBtn"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleCreateClick(index, "create");
                              }}
                              ref={popoverRef}
                            >
                              <div class="csign">
                                <AddIcon
                                  //CircleIcon
                                  // fontSize="small"
                                  // sx={{ fontSize: 12 }}
                                  // className="filtericon fontdesign"
                                  style={{ color: "#5250E5", fontSize: 14 }}
                                />
                              </div>

                              <div class="ctext">Create</div>
                            </button>
                          ) : (
                            ""
                          )}
                        </span>
                      </OverlayTrigger>
                      {/* )} */}

                      {e.CurrentStage?.[0]?.StageCode == "BLOCKED" ? (
                        <OverlayTrigger
                          trigger={["hover", "hover"]}
                          placement="top"
                          delay={{ show: 250, hide: 50 }}
                          overlay={
                            <Popover>
                              <Popover.Content style={{ color: "red" }}>
                                Blocked Ticket
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <BlockIcon
                            style={{ color: "#d02224", fontSize: "16px" }}
                          />
                        </OverlayTrigger>
                      ) : null}

                      {e?.isovedue == true ? (
                        // <span
                        //   className="customsmall"
                        //   style={{
                        //     display: "inline-block",
                        //     padding: "4px 8px",
                        //     marginLeft: "2px",
                        //     backgroundColor: "#f6cacc", // Red color for badge
                        //     color: "#d02224", // White text color
                        //     fontWeight: "bold",
                        //     borderRadius: "4px",
                        //   }}
                        // >
                        <OverlayTrigger
                          trigger={["hover", "hover"]}
                          placement="top"
                          delay={{ show: 250, hide: 50 }}
                          overlay={
                            <Popover>
                              <Popover.Content style={{ color: "red" }}>
                                Overdue
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <ErrorIcon
                            style={{ color: "#d02224", fontSize: "18px" }}
                          />
                        </OverlayTrigger>
                      ) : null}
                      {e?.CurrentStage?.[0]?.StageCode !== "DONE" &&
                      e?.AllProgress?.length !== 0 &&
                      progress !== null ? (
                        <span
                          // className="ml-1"
                          className="iconspan"
                          style={{
                            fontWeight: "bold",
                            display: "inline-block",
                            padding: "2px 2px",
                            marginLeft: "2px",
                            backgroundColor: "#ffff", // Red color for badge
                            // color: "black", // White text color
                            fontWeight: "bold",
                            borderRadius: "3px",
                            // fontSize: "11px",
                          }}
                        >
                          {`${progress}%`}
                        </span>
                      ) : null}
                    </div>

                    <OverlayTrigger
                      ref={popoverRef}
                      show={
                        assignPopover === index &&
                        e?.CurrentStage?.[0]?.StageCode != "DONE"
                      }
                      trigger={["hover", "hover"]}
                      placement="right"
                      delay={{ show: 250, hide: 50 }}
                      overlay={
                        <Popover
                          style={{
                            // borderColor: 'grey',
                            border: "none",
                            boxShadow: "3px 3px 5px #888888",
                            // maxWidth: 250,
                          }}
                          // disabled={open}
                          onClick={(event) => event.stopPropagation()}
                        >
                          {/* {console.log(
                          e.PlannedRelease
                            ? KanbanBoardData.filter(
                              (x) => x.PlannedRelease == e.PlannedRelease
                            )
                            : KanbanBoardData,
                          "KanbanBoardData"
                        )} */}
                          <Popover.Content
                            onMouseDown={() => setInsidePopoverContent(true)}
                            onMouseUp={() => setInsidePopoverContent(false)}
                            onMouseLeave={() => setInsidePopoverContent(false)}
                            style={{
                              maxHeight: 170,
                              overflowY:
                                reorderedUsers(e?.AssignedTo)?.length < 4
                                  ? "hidden"
                                  : "scroll",
                            }}
                          >
                            <Search>
                              <SearchIconWrapper>
                                <SearchIcon
                                  style={{ fontSize: 15, color: "#616670" }}
                                />
                              </SearchIconWrapper>
                              <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ "aria-label": "search" }}
                                onChange={(e) => {
                                  filterData(e);
                                }}
                                value={searchuser}
                              />
                            </Search>
                            <Divider className="mb-1" />
                            {reorderedUsers(e.AssignedTo)?.map((x) => {
                              const d = e.PlannedRelease
                                ? releaseData?.find(
                                    (x1) => x1.releasename == e.PlannedRelease
                                  )
                                : releaseData?.find(
                                    (x1) => x1.Type == "In-flight"
                                  );
                              // console.log(d, "dddddddddddddd")
                              const userdetail = d?.data?.filter(
                                (f) => f.AssignedTo == x.EmpId
                              )?.[0];

                              const finald =
                                userdetail?.Estimate == undefined
                                  ? 0
                                  : userdetail?.Estimate -
                                    userdetail?.Userpoints;
                              // console.log(userdetail, "userdetail", releases1?.length == 0
                              //   ? 0
                              //   : userdetail == undefined
                              //     ? d?.TotalDaysInSprint
                              //     : finald, releases1?.length == 0, userdetail == undefined, finald, userdetail?.Estimate)
                              // console.log(
                              //   finald,
                              //   "ddata",
                              //   d?.TotalDaysInSprint,
                              //   d?.TotalDaysInSprint > finald && finald > 0
                              // );
                              return (
                                <Button
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 2,
                                    width: "100%",
                                    justifyContent: "space-between",
                                    textTransform: "none",
                                    color: "black",
                                    fontSize: 11,
                                    backgroundColor:
                                      x.EmpId == e.AssignedTo
                                        ? "#f3f3f3"
                                        : "#fff",
                                  }}
                                  onClick={() => {
                                    // console.log(e, "checkuser")
                                    if (
                                      e?.CurrentStage?.[0]?.StageCode !=
                                        "UAT" &&
                                      e?.CurrentStage?.[0]?.StageCode != "DONE"
                                    ) {
                                      // console.log(userdetail?.Estimate, "userdetail")
                                      if (userdetail?.Estimate <= 0) {
                                        handleDialog(
                                          "Allocate",
                                          userdetail?.Estimate,
                                          x,
                                          e
                                        );
                                      } else {
                                        assignedUpdate(x, e);
                                      }
                                    }
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="assignee-avatard">
                                      <Avatar
                                        alt=""
                                        src={
                                          allImage?.length > 0
                                            ? allImage?.filter(
                                                (a) => a.teammemberid == x.EmpId
                                              )?.[0]?.image
                                            : Unassigned
                                        }
                                      />
                                    </div>
                                    <span
                                      style={{
                                        marginLeft: 15,
                                        alignSelf: "center",
                                      }}
                                    >
                                      {x.label}
                                    </span>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* <div
                                      style={{
                                        marginLeft: 20,
                                        marginRight: 2,
                                        justifySelf: "flex-end",
                                      }}
                                    >
                                      <CheckCircleOutlineIcon
                                        style={{
                                          color:
                                            d?.TotalDaysInSprint > finald &&
                                            finald >= 0
                                              ? "green"
                                              : "red",
                                        }}
                                      />
                                    </div>
                                    <span
                                      style={{
                                        marginRight: 2,
                                        color:
                                          d?.TotalDaysInSprint > finald &&
                                          finald >= 0
                                            ? "green"
                                            : "red",
                                        width: 20,
                                      }}
                                    >
                                      {finald}
                                    </span> */}
                                    {/* {console.log(releases1?.length == 0
                                    ? 0
                                    : userdetail == undefined
                                      ? 0
                                      : finald <= 0
                                        ? 100
                                        : (userdetail?.Userpoints /
                                          d?.TotalDaysInSprint) *
                                        100, "userdetailssssssssss")} */}
                                    <CircularProgressWithLabel
                                      value={
                                        releases1?.length == 0
                                          ? 0
                                          : userdetail == undefined
                                          ? 0
                                          : finald <= 0
                                          ? 100
                                          : (userdetail?.Userpoints /
                                              d?.TotalDaysInSprint) *
                                            100
                                      }
                                      label={
                                        releases1?.length == 0
                                          ? 0
                                          : userdetail == undefined
                                          ? d?.TotalDaysInSprint
                                          : finald
                                      }
                                      warning={finald == 2 || finald == 3}
                                      // danger={userdetail?.Estimate == 1}
                                      // style={{
                                      //   color:
                                      //     finald < 0 ? red[500] : green[500],
                                      // }}
                                    />
                                  </div>
                                </Button>
                              );
                            })}
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      {/* Empty span (or any other trigger element) */}
                      <span>
                        {(rolecode == "SM" ||
                          projectRole == "SM" ||
                          rolecode == "TL" ||
                          projectRole == "TL") &&
                        e.IssueType !== "Epic" ? (
                          <Button
                            style={{
                              padding: 0,
                              textTransform: "none",
                              color: "black",
                            }}
                            className="assignee-info"
                            onClick={(event) => {
                              if (
                                (rolecode == "SM" ||
                                  projectRole == "SM" ||
                                  rolecode == "TL" ||
                                  projectRole == "TL") &&
                                e.IssueType !== "Epic"
                              ) {
                                event.stopPropagation();
                                handleCreateClick(index, "assign");
                              }
                            }}
                          >
                            <div className="assignee-name">
                              {(e?.IssueType == "Epic" &&
                                e?.AssignedToDetails?.FN == null &&
                                e?.AssignedToDetails?.LN == null) ||
                              (e?.IssueType == "Epic" &&
                                e?.AssignedToDetails?.FN === "null" &&
                                e?.AssignedToDetails?.LN === "null") ||
                              (e?.IssueType == "Epic" &&
                                e?.AssignedToDetails?.FN == null &&
                                e?.AssignedToDetails?.LN === "null") ||
                              (e?.IssueType == "Epic" &&
                                e?.AssignedToDetails?.FN === "null" &&
                                e?.AssignedToDetails?.LN == null) ? (
                                ""
                              ) : e?.AssignedToDetails?.FN === "null" &&
                                e?.AssignedToDetails?.LN === "null" ? (
                                <span className="FullName text-danger pl-1">
                                  Un-assigned
                                </span>
                              ) : (
                                <span className="FullName">
                                  {e?.AssignedToDetails?.FN}
                                </span>
                              )}
                            </div>
                            <div className="assignee-img">
                              <ReactImageFallback
                                src={
                                  allImage?.length > 0
                                    ? allImage?.filter(
                                        (x) => x.teammemberid == e?.AssignedTo
                                      )?.[0]?.image == null
                                      ? Unassigned
                                      : allImage?.filter(
                                          (x) => x.teammemberid == e?.AssignedTo
                                        )?.[0]?.image
                                    : Unassigned
                                }
                                initialImage="loader.gif"
                                alt="cool image should be here"
                                style={{
                                  height: "17px",
                                  width: "17px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          </Button>
                        ) : e.IssueType != "Epic" ? (
                          <div className="assignee-info">
                            <div className="assignee-name">
                              {(e?.IssueType == "Epic" &&
                                e?.AssignedToDetails?.FN == null &&
                                e?.AssignedToDetails?.LN == null) ||
                              (e?.IssueType == "Epic" &&
                                e?.AssignedToDetails?.FN === "null" &&
                                e?.AssignedToDetails?.LN === "null") ||
                              (e?.IssueType == "Epic" &&
                                e?.AssignedToDetails?.FN == null &&
                                e?.AssignedToDetails?.LN === "null") ||
                              (e?.IssueType == "Epic" &&
                                e?.AssignedToDetails?.FN === "null" &&
                                e?.AssignedToDetails?.LN == null) ? (
                                ""
                              ) : e?.AssignedToDetails?.FN === "null" &&
                                e?.AssignedToDetails?.LN === "null" ? (
                                <span className="FullName text-danger pl-1">
                                  Un-assigned
                                </span>
                              ) : (
                                <span className="FullName">
                                  {e?.AssignedToDetails.FN}
                                </span>
                              )}
                            </div>
                            <div className="assignee-img">
                              <ReactImageFallback
                                src={
                                  allImage?.length > 0
                                    ? allImage?.filter(
                                        (x) => x.teammemberid == e?.AssignedTo
                                      )?.[0]?.image == null
                                      ? Unassigned
                                      : allImage?.filter(
                                          (x) => x.teammemberid == e?.AssignedTo
                                        )?.[0]?.image
                                    : Unassigned
                                }
                                initialImage="loader.gif"
                                alt="cool image should be here"
                                style={{
                                  height: "17px",
                                  width: "17px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          </div>
                        ) : KanbanBoardData?.filter(
                            (x) =>
                              x.IssueType != "Epic" &&
                              x.IssueType != "SubTask" &&
                              x.LinkToEpic?.[0]?.IssueId == e.IssueId &&
                              x.CurrentStage?.[0]?.StageCode != "DONE"
                          )?.length == 0 ? (
                          <Button
                            onClick={(event) => {
                              event.stopPropagation();
                              setEpicClose(true);
                              axios
                                .get(
                                  `agilesaas_issue_details?IssueType=neq.Epic&select=IssueType,IssueId,IssueTitle,Priority,OriginalEstimate,CurrentStage,AssignedTo,ProjectDetails,ParentIssue,LinkToEpic,ExceptiontoSkipSPEstimation,AssignedToDetails&LinkToEpic->0->>IssueId=eq.${e.IssueId}&IsActive=eq.Y`
                                )
                                .then((res) => {
                                  // setEpicCloseData({
                                  //   epic: e,
                                  //   linkedissues: res.data,
                                  // });
                                  dispatch(
                                    HANDLE_ON_CHANGE({
                                      name: "epicCloseData",
                                      value: {
                                        epic: e,
                                        linkedissues: res.data,
                                      },
                                    })
                                  );
                                });
                            }}
                            style={{
                              fontSize: 11,
                              padding: 4,
                              height: 20,
                              borderRadius: 8,
                              width: 60,
                              // border: '2px solid #5A88F1',
                              // backgroundColor: '#fff',
                              // color: '#5250E5',
                              backgroundColor: "#5250E5",
                              color: "#fff",
                              border: "0px solid #5A88F1",
                              textAlign: "center",
                              textTransform: "none",
                              marginRight: 24,
                              marginLeft: 5,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Close Epic
                          </Button>
                        ) : (
                          ""
                        )}
                      </span>
                    </OverlayTrigger>
                    {/* )} */}

                    {/* {e.IssueType == "Epic" ? (
                  ""
                ) : ( 
                   <div onClick={(e) => e.stopPropagation()}>
                     
                    
                {/* <Select
                      placeholder="select Assignee"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          background: "transparent",
                          width:
                            doptions.filter(
                              (option) => option?.value === e.AssignedTo
                            )?.[0]?.label?.length > 15
                              ? 90
                              : 70,
                          padding: "0px !important",
                          borderRadius: 8,
                          minHeight: 5,
                          margin: "0px !important",
                          fontWeight: "bold",
                          color: "black",
                          boxShadow: state.isFocused ? null : null,
                          fontSize: 9,
                          border: state.isFocused
                            ? "2px solid rgb(82, 80, 223)"
                            : "0px solid rgba(0, 0, 0, 0.10)",
                          "&:hover": {
                            // This section seems empty; you might add specific hover styles here
                          },
                        }),
                        menu: (base) => ({
                          ...base,
                          borderRadius: 0,
                          // width: 'auto',
                          // maxHeight: '200px', // Adjust this value as needed
                          // overflowY: 'auto',
                          // position: 'absolute',
                          // zIndex: 9999,
                          width: `${Math.max(
                            ...doptions.map(
                              (option) => option.label.length * 10
                            )
                          )} px`, // Adjust the multiplier based on your content
                          minWidth: "100%",
                        }),
                        menuList: (base) => ({
                          ...base,
                          padding: 0,
                          color: "black",
                        }),
                      }}
                      maxMenuHeight={100}
                      options={
                        doptions
                      }
                      value={
                        assignedTo && assignedTo.index === index
                          ? doptions.filter(
                            (option) => option?.value === assignedTo.value
                          )
                          : doptions.filter(
                            (option) => option?.value === e.AssignedTo
                          )
                      }
                      getOptionLabel={(e) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "20",
                          }}
                        >
                          <span style={{ marginLeft: 10 }}>{e.label}</span>
                          <div className="assignee-avatard">
                            <Avatar
                              alt=""
                              src={
                                allImage?.filter(
                                  (x) => x.teammemberid == e.value
                                )?.[0]?.image
                              }
                            />
                          </div>
                        </div>
                      )}
                      onChange={(x) => {
                        setAssignedTo({
                          index: index,
                          label: x.label,
                          value: x.value,
                        });
                        let data = {
                          AssignedTo: x.value,
                          AssignedToDetails: { "FN": x.firstname, "LN": x.lastname },
                          AssignedDate: moment().format("YYYY-MM-DD"),
                        }
                        // axios.patch(`agilesaas_issue_details?IssueId=eq.${e.IssueId}`, data).then((e) => KBRefresh())
                        //yet to check it for mail data
                        // let requrireddata = {
                        //   ClientUserId: empid?.[0]?.ClientUserId,
                        //   ClientId: clientid,
                        //   ClientDetails: {
                        //     Company: userorg?.Company,
                        //     ContactName: userorg?.ContactName,
                        //     ContactDesignation: userorg?.ContactDesignation,
                        //     ContactEmail: userorg?.ContactEmail,
                        //     ContactPhone: userorg?.ContactPhone
                        //   },
                        //   DepartmentDetails: empid?.[0]?.DepartmentDetails,
                        //   DepartmentCode: null,
                        //   TentantId: "",
                        //   FromSenderId: "no-reply@yozytech.com",
                        //   ToEmailIds: {
                        //     "emailids": toid?.[0]
                        //   },
                        //   CcEmailIds: {
                        //     "emailids": [
                        //       "kandhasamy.a@yozytech.com", empid?.[0]?.OfficialEmail
                        //     ]
                        //   },
                        //   BccEmailIds: "",
                        //   EmailType: "StoryAssignment",
                        //   ProductDetails: {
                        //     "ProdId": "11004",
                        //     "ProdName": "KANBAN",
                        //     "Domain": "KANBAN.yozytech.com"
                        //   },
                        //   EmailSubject: "Story Assignment Notification",
                        //   IsSuccess: "Y",
                        //   IsPartiallyFailed: " ",
                        //   SendRetryHistory: "",
                        //   IsActive: " ",
                        //   Status: "",
                        //   EmailTemplateName: "StoryAssignment",
                        //   EmailTemplateVariables: {
                        //     xxasignbyxx: arr.RoleName,
                        //     xxassigndatexx: moment().format("YYYY-MM-DD"),
                        //     xxprjdisxx: description,
                        //     xxprjnamexx: projectName,
                        //     xxstoryNamexx: e.issueTitle,
                        //     xxprjpercenxx: "",
                        //     xxprjstartdatexx: "",
                        //     xxprjenddataexx: "",
                        //     xxteamnamexx: "",
                        //     xxsprintxx: "",
                        //     xxmanagernamexx: userorg?.ContactName,
                        //     xxcompanyname: userorg?.Company,
                        //     xxmanagerdesigxx: "Scrum Master",
                        //     xxgmailxx: userorg?.ContactEmail,
                        //     xxphnoxx: userorg?.ContactPhone,
                        //     webappdescri: "You have assigned to " + issueTitle + "in project " + projectName
                        //   }

                        // };
                        // axios({
                        //   url: "https://api.devozy.ai/api/agile/email",
                        //   method: "post",
                        //   data: requrireddata,
                        //   headers: {
                        //     origin: "https://kanban.yozytech.com",
                        //     "Content-Type": "application/json",

                        //   },
                        // }).then((res) => {
                        //   console.log(res.data, "mailsend")
                        // })
                        //   .catch((err) => {
                        //     console.log(err);
                        //   });
                      }}
                    /> 
                   </div>
                )}*/}
                  </div>

                  {/* {console.log(
                  KanbanBoardData?.filter(
                    (n) => n.LinkToEpic?.[0]?.IssueId == e?.IssueId
                  )?.length,
                  "iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii"
                )} */}

              
                    <Row noGutters>
                    {e?.CurrentStage?.[0]?.StageCode !== "DONE" &&
                  e?.CurrentStage?.[0]?.StageCode !== "BLOCKED" ? ( 
                  <Col
                        md={
                          canEdit &&
                          (e?.CurrentStage?.[0]?.StageCode == "RFD" ||
                            e?.CurrentStage?.[0]?.StageCode == "BLG" ||
                            e?.CurrentStage?.[0]?.StageCode == "DEV" ||
                            e?.CurrentStage?.[0]?.StageCode == "UAT")
                            ? 9
                            : 12
                        }
                        // style={{ paddingRight: "5px" }}
                      >
                        <Button
                          className="hidden-button rounded"
                          onClick={(x) => {
                            x.stopPropagation();
                            CardMoving(
                              e?.IssueId,
                              e?.CurrentStage?.[0]?.StageCode,
                              e?.CurrentStage?.[0]?.StageCode === "BLG"
                                ? "RFD"
                                : e?.CurrentStage?.[0]?.StageCode === "RFD"
                                ? "DEV"
                                : e?.CurrentStage?.[0]?.StageCode === "DEV"
                                ? "UAT"
                                : "DONE"
                            );
                          }}
                        >
                          Move to{" "}
                          {e?.CurrentStage?.[0]?.StageCode === "BLG"
                            ? "Refined"
                            : e?.CurrentStage?.[0]?.StageCode === "RFD"
                            ? "Development"
                            : e?.CurrentStage?.[0]?.StageCode === "DEV"
                            ? "UAT"
                            : "Done"}
                          <KeyboardDoubleArrowRightIcon
                            style={{ fontSize: "13px", marginBottom: 1 }}
                          />
                        </Button>
                      </Col>):null}
                      {canEdit && (
                        <Col
                          className="d-flex justify-content-end align-items-end"
                          md={3}  
                        >
                      
                      {/* <div style={{ flexGrow: 1 }}></div> */}
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <span
                              className="bg-white rounded mt-1 "
                              style={{
                                position: "relative",
                                left: e?.CurrentStage?.[0]?.StageCode == "DONE" 
       
            ? "130px"
            : "auto",
                               
                              }}
                            >
                              <Tooltip arrow placement="top" title="Clone">
                                <IconButton
                                  className="hidden-button1"
                                  onClick={(event) => {
                                    event.stopPropagation();

                                    dispatch(
                                      HANDLE_ON_CHANGE({
                                        name: "clonedialog",
                                        value: true,
                                      })
                                    );

                                    dispatch(
                                      HANDLE_ON_CHANGE({
                                        name: "clonedata",
                                        value: e,
                                      })
                                    );
                                    setIssueTitleedit(e.IssueTitle);
                                  }}
                                >
                                  <ContentCopyIcon
                                    style={{
                                      fontSize: "13px",
                                      marginBottom: 1,
                                      color: "#4bade8",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </span>
                            </div>
                     
                          
                          {(e?.CurrentStage?.[0]?.StageCode == "RFD" ||
                            e?.CurrentStage?.[0]?.StageCode == "BLG") &&
                            (projectRole == "SM" ||
                              (projectRole == "TL" &&
                                e.CreatedBy == login?.[0]?.ClientUserId)) && (
                              <span className="bg-white rounded mt-1 ml-1">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={
                                    KanbanBoardData?.filter(
                                      (n) =>
                                        n.ParentIssue?.[0]?.IssueId ==
                                        e?.IssueId
                                    )?.length > 0
                                      ? "Please unlink the task linked to this story"
                                      : "Delete"
                                  }
                                >
                                  <IconButton
                                    className="hidden-button1"
                                    onClick={(x) => {
                                      x.stopPropagation();
                                      if (
                                        KanbanBoardData?.filter(
                                          (n) =>
                                            n.ParentIssue?.[0]?.IssueId ==
                                            e?.IssueId
                                        )?.length > 0
                                      ) {
                                        console.log("if called ");
                                      } else {
                                        dispatch(
                                          HANDLE_ON_CHANGE({
                                            name: "deletedata",
                                            value: e,
                                          })
                                        );
                                        dispatch(
                                          HANDLE_ON_CHANGE({
                                            name: "deletedialog",
                                            value: true,
                                          })
                                        );
                                      }
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{
                                        fontSize: "13px",
                                        marginBottom: 1,
                                        color: "#F22C3D",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </span>
                            )}
                        </Col>
                      )}
                    </Row>
                  {/* ) : null} */}
              

                </div>
                // </Card>
              ),

              style: {
                "background-color":
                  getrag == null
                    ? ""
                    : getrag === "A" &&
                      e?.CurrentStage?.[0].StageCode != "BLG" &&
                      e?.CurrentStage?.[0].StageCode != "RFD" &&
                      e?.CurrentStage?.[0].StageCode != "DONE"
                    ? "#f7e9bf"
                    : getrag == null
                    ? ""
                    : getrag === "G" &&
                      e?.CurrentStage?.[0].StageCode != "BLG" &&
                      e?.CurrentStage?.[0].StageCode !== "RFD" &&
                      e?.CurrentStage?.[0].StageCode != "DONE"
                    ? "#e8f7e8"
                    : getrag == null
                    ? ""
                    : getrag === "R" &&
                      e?.CurrentStage?.[0].StageCode != "BLG" &&
                      e?.CurrentStage?.[0].StageCode !== "RFD" &&
                      e?.CurrentStage?.[0].StageCode != "DONE"
                    ? "#f1cdcd"
                    : "white",
              },
              IssueType: e.IssueType,
              OriginalEstimate: e.OriginalEstimate,
              ExceptiontoSkipSPEstimation: e.ExceptiontoSkipSPEstimation,
              ActualRelease: e.ActualRelease,
              empid: e.AssignedTo,
              currentStage: e.CurrentStage,
              PlannedRelease: e.PlannedRelease,
            };
          });

  let BACKLOG =
    temp === null
      ? ""
      : temp?.filter(
          (x) =>
            x.currentStage?.[0]?.StageCode === "BLG" ||
            x.currentStage?.[0]?.StageCode === "BLOCKED"
        );
  let SFD = [];

  if (Array.isArray(temp) && temp?.length > 0) {
    if (percentagefilrfd !== "") {
      SFD = temp
        ?.filter(
          (x) =>
            x.currentStage &&
            x.currentStage.length > 0 &&
            x.currentStage?.[0]?.StageCode === "RFD"
        )
        .sort((a, b) => {
          const percentageProgressedA = getPercentageProgressed(a);
          const percentageProgressedB = getPercentageProgressed(b);

          if (percentagefilrfd == "0-100") {
            return percentageProgressedA - percentageProgressedB;
          } else if (percentagefilrfd == "100-0") {
            return percentageProgressedB - percentageProgressedA;
          }
        });
    } else if (epicfilrfd !== "") {
      SFD = temp
        ?.filter(
          (x) =>
            x.currentStage &&
            x.currentStage.length > 0 &&
            x.currentStage?.[0]?.StageCode === "RFD"
        )
        .sort((a, b) => {
          const issueIdA = getIssueId(a);
          const issueIdB = getIssueId(b);

          if (epicfilrfd === "ASC") {
            return issueIdA - issueIdB; // Sort descending by IssueId
          } else if (epicfilrfd == "DESC") {
            return issueIdB - issueIdA; // Sort ascending by IssueId
          }
        });
    } else if (releasefilerfd !== "") {
      SFD = temp
        ?.filter(
          (x) =>
            x.currentStage &&
            x.currentStage.length > 0 &&
            x.currentStage?.[0]?.StageCode === "RFD"
        )
        .sort((a, b) => {
          // Extract PlannedRelease values and handle null cases
          const plannedReleaseA = a.PlannedRelease;
          const plannedReleaseB = b.PlannedRelease;

          if (plannedReleaseA !== null && plannedReleaseB !== null) {
            // Both values are not null, compare based on sorting order
            if (releasefilerfd === "ASC") {
              return plannedReleaseA.localeCompare(plannedReleaseB);
            } else if (releasefilerfd == "DESC") {
              return plannedReleaseB.localeCompare(plannedReleaseA);
            }
          } else if (plannedReleaseA !== null && plannedReleaseB === null) {
            // A is not null, B is null, A should come before B (ascending order)
            return -1;
          } else if (plannedReleaseA === null && plannedReleaseB !== null) {
            // A is null, B is not null, B should come before A (descending order)
            return 1;
          } else {
            // Both values are null (shouldn't happen based on the requirement)
            return 0;
          }
        });
    } else if (assignfilrfd !== "") {
      SFD = temp
        ?.filter(
          (x) =>
            x.currentStage &&
            x.currentStage.length > 0 &&
            x.currentStage?.[0]?.StageCode === "RFD"
        )
        .sort((a, b) => {
          const nameA = extractName(a.assignedto).toLowerCase();
          const nameB = extractName(b.assignedto).toLowerCase();

          if (assignfilrfd === "ASC") {
            return nameA.localeCompare(nameB); // Sort ascending by name
          } else if (assignfilrfd == "DESC") {
            return nameB.localeCompare(nameA); // Sort descending by name
          }
        });
    } else {
      SFD = temp?.filter(
        (x) =>
          x.currentStage &&
          x.currentStage.length > 0 &&
          x.currentStage?.[0]?.StageCode === "RFD"
      );
    }

    if (sortOrderrfd === "ASC") {
      SFD.sort((a, b) => {
        const dateMovedA = getDateMoved(a);
        const dateMovedB = getDateMoved(b);

        return sortOrder === "ASC"
          ? dateMovedB - dateMovedA
          : dateMovedA - dateMovedB;
      });
    } else if (sortOrderrfd === "DESC") {
      SFD.sort((a, b) => {
        const dateMovedA = getDateMoved(a);
        const dateMovedB = getDateMoved(b);
        return sortOrder === "DESC"
          ? dateMovedA - dateMovedB
          : dateMovedB - dateMovedA;
      });
    }
  }

  let DEVELOPMENT = [];

  if (Array.isArray(temp) && temp.length > 0) {
    if (percentagefil !== "") {
      DEVELOPMENT = temp
        ?.filter(
          (x) =>
            x.currentStage &&
            x.currentStage.length > 0 &&
            x.currentStage?.[0]?.StageCode === "DEV"
        )
        .sort((a, b) => {
          const percentageProgressedA = getPercentageProgressed(a);
          const percentageProgressedB = getPercentageProgressed(b);

          if (percentagefil == "0-100") {
            return percentageProgressedA - percentageProgressedB;
          } else if (percentagefil == "100-0") {
            return percentageProgressedB - percentageProgressedA;
          }
        });
    } else if (epicfil !== "") {
      DEVELOPMENT = temp
        ?.filter(
          (x) =>
            x.currentStage &&
            x.currentStage.length > 0 &&
            x.currentStage?.[0]?.StageCode === "DEV"
        )
        .sort((a, b) => {
          const issueIdA = getIssueId(a);
          const issueIdB = getIssueId(b);

          if (epicfil === "ASC") {
            return issueIdA - issueIdB; // Sort descending by IssueId
          } else if (epicfil == "DESC") {
            return issueIdB - issueIdA; // Sort ascending by IssueId
          }
        });
    } else if (releasefile !== "") {
      DEVELOPMENT = temp
        ?.filter(
          (x) =>
            x.currentStage &&
            x.currentStage.length > 0 &&
            x.currentStage?.[0]?.StageCode === "DEV"
        )
        .sort((a, b) => {
          // Extract PlannedRelease values and handle null cases
          const plannedReleaseA = a.PlannedRelease;
          const plannedReleaseB = b.PlannedRelease;

          if (plannedReleaseA !== null && plannedReleaseB !== null) {
            // Both values are not null, compare based on sorting order
            if (releasefile === "ASC") {
              return plannedReleaseA.localeCompare(plannedReleaseB);
            } else if (releasefile == "DESC") {
              return plannedReleaseB.localeCompare(plannedReleaseA);
            }
          } else if (plannedReleaseA !== null && plannedReleaseB === null) {
            // A is not null, B is null, A should come before B (ascending order)
            return -1;
          } else if (plannedReleaseA === null && plannedReleaseB !== null) {
            // A is null, B is not null, B should come before A (descending order)
            return 1;
          } else {
            // Both values are null (shouldn't happen based on the requirement)
            return 0;
          }
        });
    } else if (assignfil !== "") {
      DEVELOPMENT = temp
        ?.filter(
          (x) =>
            x.currentStage &&
            x.currentStage.length > 0 &&
            x.currentStage?.[0]?.StageCode === "DEV"
        )
        .sort((a, b) => {
          const nameA = extractName(a.assignedto).toLowerCase();
          const nameB = extractName(b.assignedto).toLowerCase();

          if (assignfil === "ASC") {
            return nameA.localeCompare(nameB); // Sort ascending by name
          } else if (assignfil == "DESC") {
            return nameB.localeCompare(nameA); // Sort descending by name
          }
        });
    } else {
      DEVELOPMENT = temp?.filter(
        (x) =>
          x.currentStage &&
          x.currentStage.length > 0 &&
          x.currentStage[0]?.StageCode === "DEV"
      );
    }

    if (sortOrder === "ASC") {
      DEVELOPMENT.sort((a, b) => {
        const dateMovedA = getDateMoved(a);
        const dateMovedB = getDateMoved(b);

        return sortOrder === "ASC"
          ? dateMovedB - dateMovedA
          : dateMovedA - dateMovedB;
      });
    } else if (sortOrder == "DESC") {
      DEVELOPMENT.sort((a, b) => {
        const dateMovedA = getDateMoved(a);
        const dateMovedB = getDateMoved(b);
        return sortOrder === "DESC"
          ? dateMovedA - dateMovedB
          : dateMovedB - dateMovedA;
      });
    }
  }
  function getIssueId(item) {
    return item.id || 0;
  }
  function extractName(assignedto) {
    const parts = assignedto.split(".");
    return parts.length > 1 ? parts?.[0]?.trim() : assignedto.trim();
  }
  function getPercentageProgressed(item) {
    if (item || typeof item.percentageprogressed !== "string") {
      return item.percentageprogressed;
    }

    const percentage = item.percentageprogressed?.trim();

    if (percentage === "" || percentage.toLowerCase() === "null") {
      return item.percentageprogressed;
    }

    const parsedPercentage = parseFloat(percentage);

    if (isNaN(parsedPercentage)) {
      return 0;
    }

    return parsedPercentage;
  }
  function getDateMoved(item) {
    return item.currentStage && item.currentStage.length > 0 && item.createddate
      ? new Date(item.createddate).getTime()
      : 0;
  }

  let UAT =
    temp === null
      ? ""
      : temp?.filter((x) => x.currentStage?.[0]?.StageCode === "UAT");
  let DONE =
    temp === null
      ? ""
      : temp?.filter(
          (x) =>
            x.currentStage?.[0]?.StageCode === "DONE" &&
            (x.ActualRelease === null || x.ActualRelease === "")
        );
  const checkalluat =
    temp === null
      ? ""
      : temp?.filter((x) => x.currentStage?.[0]?.StageCode === "UAT");
  // console.log(checkalluat,"checkalluat");
  // console.log(checkalluat?.map((e) => e.id)?.join(","));

  // const updateStageToDone = (itemId) => {
  //   // Mock data for the updated stage
  //   const updatedStage = { StageCode: 'DONE', StageName: 'Done', DateMoved: moment().format('YYYY-MM-DD') };

  //   // Axios patch request to update the stage
  //   axios.patch("agilesaas_issue_details?IssueId=eq." + itemId, {
  //     CurrentStage: [updatedStage],
  //   })
  //   .then((response) => {
  //     toast.success("Stage updated successfully");
  //     // alert("Stage updated successfully");
  //   })
  //   .catch((error) => {
  //     console.error('Error updating stage:');
  //   });
  //   const HisteryData = {
  //     IssueId: itemId,
  //     ChangedBy: login?.[0]?.ClientUserId,
  //     ChangedByDetails: {
  //       FN: login?.[0]?.UserFirstname,
  //       LN: login?.[0]?.UserLastname,
  //     },
  //     ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
  //     UpdatedField: "Kanban Changes",
  //     UpdatedFrom: "UAT",
  //     UpdatedTo: "DONE",
  //     UpdateSequence: null,
  //     IsActive: "Y",
  //     Status: null,
  //     ClientId: clientId,
  //     ProjectId: projectCode,
  //   };
  //   axios.post("agilesaas_issue_history", HisteryData).then((res)=>console.log(res)).catch((err)=>console.log(err));
  //   setRefresh(true);
  // };

  const handleButtonClickUAT = () => {
    // Iterate through the data and update the stage for items with UAT
    // checkalluat?.forEach(item => {
    //   item.currentStage.forEach(stage => {
    //     if (stage.StageCode === 'UAT') {
    //       // Make the Axios call to update the stage to 'Done'
    //       updateStageToDone(item.id);
    //     }
    //   });
    // });
    const uatid = checkalluat?.map((e) => e.id)?.join(",");
    const updatedStage = {
      StageCode: "DONE",
      StageName: "Done",
      DateMoved: moment().format("YYYY-MM-DD"),
    };
    axios
      .patch(`agilesaas_issue_details?IssueId=in.(${uatid})`, {
        CurrentStage: [updatedStage],
      })
      .then((response) => {
        axios
          .get(
            `agilesaas_issue_progress?ProjectId=eq.${projectCode}&ClientId=eq.${clientId}&IssueId=in.(${uatid})&order=UpdatedDate.desc&select=IssueProgressId,PercentageProgressed,UpdatedBy,IssueId`
          )
          .then((response) => {
            if (response?.data?.length > 0) {
              const groupedData = response?.data?.reduce((acc, obj) => {
                const key = obj.IssueId;
                acc[key] = acc[key] || [];
                acc[key].push(obj);
                return acc;
              }, {});
              const result = Object.values(groupedData)?.map((group) => {
                return group.reduce(
                  (max, obj) =>
                    obj.PercentageProgressed > max.PercentageProgressed
                      ? obj
                      : max,
                  group?.[0]
                );
              });

              // console.log(result);
              const progid = result?.map((e) => e.IssueProgressId)?.join(",");
              axios
                .patch(
                  `agilesaas_issue_progress?ProjectId=eq.${projectCode}&ClientId=eq.${clientId}&IssueProgressId=in.(${progid})`,
                  { PercentageProgressed: 100 }
                )
                .then((response) => {
                  // console.log(response);
                  // alert("sample")
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((err) => {
            console.error(err);
          });
        // alert("Stage updated successfully");
        const historyDataArray = checkalluat?.map((itemId) => ({
          IssueId: itemId.id,
          ChangedBy: login?.[0]?.ClientUserId,
          ChangedByDetails: {
            FN: login?.[0]?.UserFirstname,
            LN: login?.[0]?.UserLastname,
          },
          ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
          UpdatedField: "Kanban Changes",
          UpdatedFrom: "UAT",
          UpdatedTo: "DONE",
          UpdateSequence: null,
          IsActive: "Y",
          Status: null,
          ClientId: clientId,
          ProjectId: projectCode,
        }));

        axios
          .post("agilesaas_issue_history", historyDataArray)
          .then((response) => {
            // toast.success("Stage updated successfully");
            // alert("Stage updated successfully");
            console.log("");
          })
          .catch((error) => {
            console.error("Error updating stage:");
          });
        setMessage("Stage has been updated successfully");
        setMessageType("success");
        setOpen(true);
        // setRefresh(true);
        dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
      })
      .catch((error) => {
        console.error("Error updating stage:");
      });
  };

  // const batchUpdateStagesAndHistory = (itemIds) => {
  //   // Create an array to accumulate updates for each item
  //   const batchUpdates = [];

  //   // Iterate through each item ID
  //   for (const itemId of itemIds) {
  //     const updatedStage = { StageCode: 'DONE', StageName: 'Done', DateMoved: moment().format('YYYY-MM-DD') };

  //     // Update the stage
  //     const stageUpdate = axios.patch(`agilesaas_issue_details?IssueId=eq.${itemId}`, {
  //       CurrentStage: [updatedStage],
  //     });

  //     // Update the issue history
  //     const historyData = {
  //       IssueId: itemId,
  //       ChangedBy: login?.[0]?.ClientUserId,
  //       ChangedByDetails: {
  //         FN: login?.[0]?.UserFirstname,
  //         LN: login?.[0]?.UserLastname,
  //       },
  //       ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
  //       UpdatedField: "Kanban Changes",
  //       UpdatedFrom: "UAT",
  //       UpdatedTo: "DONE",
  //       UpdateSequence: null,
  //       IsActive: "Y",
  //       Status: null,
  //       ClientId: clientId,
  //       ProjectId: projectCode,
  //     };

  //     const historyUpdate = axios.post("agilesaas_issue_history", historyData);

  //     // Add the promises to the batchUpdates array
  //     batchUpdates.push(stageUpdate, historyUpdate);
  //   }

  //   // Wait for all promises to resolve
  //   return Promise.all(batchUpdates)
  //     .then(() => {
  //       toast.success("Batch update completed successfully");
  //       setRefresh(true);
  //     })
  //     .catch(error => {
  //       console.error('Error in batch update:', error);
  //     });
  // };

  // const handleButtonClickUAT = () => {
  //   // Get the array of item IDs to update
  //   const itemIdsToUpdate = checkalluat.map(item => item.id);

  //   // Batch update the stages and history
  //   batchUpdateStagesAndHistory(itemIdsToUpdate);
  // };
  let totalBLGCards =
    FilteredData === null
      ? ""
      : FilteredData?.filter(
          (x) =>
            x.CurrentStage?.[0]?.StageCode === "BLG" &&
            x.ProjectId === projectId2
        ).length;
  let totalSFDCards =
    FilteredData === null
      ? ""
      : FilteredData?.filter(
          (x) =>
            x.CurrentStage?.[0]?.StageCode === "RFD" &&
            x.ProjectId === projectId2
        ).length;
  let totalDEVELOPMENTCards =
    FilteredData === null
      ? ""
      : FilteredData.filter(
          (x) =>
            x.CurrentStage?.[0]?.StageCode === "DEV" &&
            x.ProjectId === projectId2
        ).length;
  let totalUATCards =
    FilteredData === null
      ? ""
      : FilteredData?.filter(
          (x) =>
            x.CurrentStage?.[0]?.StageCode === "UAT" &&
            x.ProjectId === projectId2
        ).length;
  let totalDONECards =
    FilteredData === null
      ? ""
      : FilteredData?.filter(
          (x) =>
            x.CurrentStage?.[0]?.StageCode === "DONE" &&
            x.ProjectId === projectId2 &&
            (x.ActualRelease === null || x.ActualRelease === "")
        ).length;
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    // Add your logic here for handling the click event
  };

  const handleMenuItemHover = (menuItem) => {
    setHoveredMenuItem(menuItem);
    // Add your logic here for handling the hover event
  };
  const options = [];
  for (let percent = 10; percent <= 100; percent += 10) {
    options.push(
      <option key={percent} value={percent}>
        {percent}%
      </option>
    );
  }
  const handleMenuOpen = (e) => {
    // Prevent event propagation when the menu is opened
    e.stopPropagation();
  };
  function handlesort(e) {
    e.stopPropagation();
    setSortOrder("ASC");
  }
  function handlesort1(e) {
    e.stopPropagation();

    setSortOrder("DESC");
  }
  function handlesortrfd(e) {
    e.stopPropagation();
    setSortOrderrfd("DESC");
  }
  function handlesort1rfd(e) {
    e.stopPropagation();

    setSortOrderrfd("ASC");
  }

  function handlefilClear() {
    setpercentagefil("");
    setpercentagefil("");
    setepicfil("");
    setreleasefil("");
    setMenuVisible(false);
    setassignfil("");
    setSortOrder("");
  }
  function handlefilClearrfd() {
    setpercentagefilrfd("");
    setMenuVisible(false);
    setepicfilrfd("");
    setreleasefilrfd("");

    setassignfilrfd("");
    setSortOrderrfd("");
  }
  function handleassign() {
    setassignfil("ASC");
  }
  function handleassignrfd() {
    setassignfilrfd("ASC");
  }
  function handleassign2rfd() {
    setassignfilrfd("DESC");
  }
  function handleassignrfd() {
    setassignfilrfd("ASC");
  }
  function handleassign2() {
    setassignfil("DESC");
  }
  function handleassign2rfd() {
    setassignfilrfd("DESC");
  }
  function handleChange(e, DEVELOPMENT) {
    setpercentagefil("0-100");
  }
  function handleChange2(e) {
    setpercentagefil("100-0");
  }
  function handleChangerfd(e, DEVELOPMENT) {
    setpercentagefilrfd("0-100");
  }
  function handleChange2rfd(e) {
    setpercentagefilrfd("100-0");
  }
  function releasefil(item, f) {
    setreleasefil("ASC");
  }
  function releasefil2(item, f) {
    setreleasefil("DESC");
  }
  function releasefilrfd(item, f) {
    setreleasefilrfd("ASC");
  }
  function releasefil2rfd(item, f) {
    setreleasefilrfd("DESC");
  }
  function handleissueid(e, DEVELOPMENT) {
    setepicfil("ASC");
  }
  function handleissueid2(e, DEVELOPMENT) {
    setepicfil("DESC");
  }
  function handleissueidrfd(e, DEVELOPMENT) {
    setepicfilrfd("ASC");
  }
  function handleissueid2rfd(e, DEVELOPMENT) {
    setepicfilrfd("DESC");
  }
  const data = {
    lanes: [
      {
        id: "BLG",
        className: "blg",
        title: "",
        label: (
          <label
            className="Lanetitle fontdesign"
            style={{ color: "#000 ", fontSize: "0.90em", fontWeight: "bold" }}
          >
            Backlog ({BACKLOG.length})
          </label>
        ),
        // label: (
        //   <div className="each" style={{ float: "right" }}>
        //     <b>{BACKLOG.length + " of " + totalBLGCards}</b>
        //   </div>
        // ),
        cards: BACKLOG,
      },
      {
        id: "RFD",
        className: "rfd",
        title: "",
        label: (
          // <label
          //   className="Lanetitle fontdesign"
          //   style={{ color: "#000 ", fontSize: "0.90em", fontWeight: "bold" }}
          // >
          //   Refined ({SFD.length})
          // </label>
          <div
            className="lane-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <div className="label-text">
              <label
                className="Lanetitle fontdesign"
                style={{
                  color: "#000",
                  fontSize: "0.90em",
                  fontWeight: "bold",
                  marginLeft: "-5px",
                }}
              >
                Refined ({SFD.length})
              </label>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="div"
                className="filter-toggle mb-1"
                style={{ backgroundColor: "transparent", marginLeft: "4px" }}
              >
                <i className="fa fa-sort "></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div
                  className="popup-container"
                  style={{
                    display: "block",
                    // display: menuVisible ? "block" : "none", // Show/hide based on menuVisible state
                    position: "absolute",
                    // top: "40px",
                    // right: "-60px",
                    right: "2px",
                    // width: "160px",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    maxWidth: "165px",
                    padding: "10px",
                    zIndex: 500,
                    maxHeight: "450px",
                  }}
                >
                  <Box sx={{ padding: "5px 0" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: 14, fontWeight: 600 }}
                        className="fontdesign"
                      >
                        Sort by
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontSize: 9,
                          color: "#904ee2",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        className="fontdesign"
                        onClick={handlefilClearrfd}
                      >
                        Clear
                      </Typography>
                    </Stack>
                  </Box>
                  <Divider />
                  <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                    <li style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: 9,
                            fontWeight: "600",
                            cursor: "pointer",

                            alignItems: "center",
                          }}
                          className="fontdesign mt-2"
                          // onClick={(e) => handlesort(e, "ASC")}
                          title="Sort by Date (Ascending)"
                        >
                          Created Date
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: sortOrderrfd == "DESC" ? "white" : "grey",
                              fontSize: "9px",
                              width: "70px",

                              // marginLeft: "40px",

                              backgroundColor:
                                sortOrderrfd == "DESC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) => handlesortrfd(e, "DESC")}
                            title="Sort by Date (Descending)"
                          >
                            Latest First
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: sortOrderrfd == "ASC" ? "white" : "grey",
                              fontSize: "9px",
                              marginLeft: "5px",
                              width: "70px",

                              backgroundColor:
                                sortOrderrfd == "ASC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) => handlesort1rfd(e, "ASC")}
                          >
                            Oldest First
                          </button>
                        </div>
                      </div>
                    </li>
                    <Divider />
                    <li style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 9, fontWeight: "600" }}
                          className="fontdesign"
                          // onClick={(e) => handleChange(e.target.value, DEVELOPMENT)}
                        >
                          Progress %
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              fontSize: "9px",
                              width: "70px",
                              color:
                                percentagefilrfd == "0-100" ? "white" : "grey",
                              backgroundColor:
                                percentagefilrfd == "0-100"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent",
                              // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              handleChangerfd(e.target.value, DEVELOPMENT)
                            }
                          >
                            0%-100%
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              fontSize: "9px",
                              width: "70px",
                              marginLeft: "5px",
                              color:
                                percentagefilrfd == "100-0" ? "white" : "grey",
                              backgroundColor:
                                percentagefilrfd == "100-0"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              handleChange2rfd(e.target.value, DEVELOPMENT)
                            }
                          >
                            100%-0%
                          </button>
                        </div>
                      </div>
                    </li>

                    <Divider />
                    <li style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 9, fontWeight: "600" }}
                          className="fontdesign"
                        >
                          Assignee name
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              width: "70px",
                              fontSize: "9px",
                              color: assignfilrfd == "ASC" ? "white" : "grey",
                              backgroundColor:
                                assignfilrfd == "ASC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent",
                              // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              handleassignrfd(e.target.value, DEVELOPMENT)
                            }
                          >
                            <i
                              className="fa fa-sort-asc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Ascending
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              width: "70px",
                              fontSize: "9px",
                              marginLeft: "5px",
                              color: assignfilrfd == "DESC" ? "white" : "grey",
                              backgroundColor:
                                assignfilrfd == "DESC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              handleassign2rfd(e.target.value, DEVELOPMENT)
                            }
                          >
                            <i
                              className="fa fa-sort-desc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Descending
                          </button>
                        </div>
                      </div>
                    </li>
                    <Divider />
                    <li style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 9, fontWeight: "600" }}
                          className="fontdesign"
                        >
                          Sprint name
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              fontSize: "9px",
                              width: "70px",
                              color: releasefilerfd == "ASC" ? "white" : "grey",
                              backgroundColor:
                                releasefilerfd == "ASC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent",
                              // marginLeft: "18px", // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              releasefilrfd(e.target.value, DEVELOPMENT)
                            }
                          >
                            <i
                              className="fa fa-sort-asc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Ascending
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              width: "70px",
                              fontSize: "9px",
                              marginLeft: "5px",
                              color:
                                releasefilerfd == "DESC" ? "white" : "grey",
                              backgroundColor:
                                releasefilerfd == "DESC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              releasefil2rfd(e.target.value, DEVELOPMENT)
                            }
                          >
                            <i
                              className="fa fa-sort-desc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Descending
                          </button>
                        </div>
                      </div>
                    </li>

                    <Divider />
                    <li style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 9, fontWeight: "600" }}
                          className="fontdesign"
                          // onClick={(e) => handleChange(e.target.value, DEVELOPMENT)}
                        >
                          Ticket Id
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              width: "70px",
                              fontSize: "9px",
                              color: epicfilrfd == "ASC" ? "white" : "grey",
                              backgroundColor:
                                epicfilrfd == "ASC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent",
                              // Adjust margin as needed
                            }}
                            onClick={(e) => handleissueidrfd(e, "ASC")}
                          >
                            <i
                              className="fa fa-sort-asc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Ascending
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              width: "70px",
                              fontSize: "9px",
                              marginLeft: "5px",
                              color: epicfilrfd == "DESC" ? "white" : "grey",
                              backgroundColor:
                                epicfilrfd == "DESC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) => handleissueid2rfd(e, "DESC")}
                          >
                            <i
                              className="fa fa-sort-desc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Descending
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),

        // label: (
        //   <div className="each" style={{ float: "right" }}>
        //     <b>{SFD.length + " of " + totalSFDCards}</b>
        //   </div>
        // ),
        cards: SFD,
      },
      {
        id: "DEV",
        className: "dev",
        title: "",
        label: (
          <div
            className="lane-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <div className="label-text">
              <label
                className="Lanetitle fontdesign"
                style={{
                  color: "#000",
                  fontSize: "0.90em",
                  fontWeight: "bold",
                  marginLeft: "-37px",
                }}
              >
                Development ({DEVELOPMENT.length})
              </label>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="div"
                className="filter-toggle mb-1"
                style={{ backgroundColor: "transparent", marginLeft: "3px" }}
              >
                <i className="fa fa-sort "></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div
                  className="popup-container"
                  style={{
                    display: "block",
                    // display: menuVisible ? "block" : "none", // Show/hide based on menuVisible state
                    position: "absolute",
                    // top: "40px",
                    // right: "-60px",
                    right: "2px",
                    // width: "160px",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    maxWidth: "165px",
                    padding: "10px",
                    zIndex: 500,
                    maxHeight: "450px",
                  }}
                >
                  <Box sx={{ padding: "5px 0" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: 14, fontWeight: 600 }}
                        className="fontdesign"
                      >
                        Sort by
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontSize: 9,
                          color: "#904ee2",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        className="fontdesign"
                        onClick={handlefilClear}
                      >
                        Clear
                      </Typography>
                    </Stack>
                  </Box>
                  <Divider />
                  <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                    <li style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: 9,
                            fontWeight: "600",
                            cursor: "pointer",

                            alignItems: "center",
                          }}
                          className="fontdesign mt-2"
                          // onClick={(e) => handlesort(e, "ASC")}
                          title="Sort by Date (Ascending)"
                        >
                          Created Date
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: sortOrder == "ASC" ? "white" : "grey",
                              fontSize: "9px",
                              width: "70px",

                              // marginLeft: "40px",

                              backgroundColor:
                                sortOrder == "ASC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) => handlesort(e, "ASC")}
                            title="Sort by Date (Descending)"
                          >
                            Latest First
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: sortOrder == "DESC" ? "white" : "grey",
                              fontSize: "9px",
                              marginLeft: "5px",
                              width: "70px",

                              backgroundColor:
                                sortOrder == "DESC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) => handlesort1(e, "DESC")}
                          >
                            Oldest First
                          </button>
                        </div>
                      </div>
                    </li>
                    <Divider />
                    <li style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 9, fontWeight: "600" }}
                          className="fontdesign"
                          // onClick={(e) => handleChange(e.target.value, DEVELOPMENT)}
                        >
                          Progress %
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              fontSize: "9px",
                              width: "70px",
                              color:
                                percentagefil == "0-100" ? "white" : "grey",
                              backgroundColor:
                                percentagefil == "0-100"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent",
                              // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              handleChange(e.target.value, DEVELOPMENT)
                            }
                          >
                            0%-100%
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              fontSize: "9px",
                              width: "70px",
                              marginLeft: "5px",
                              color:
                                percentagefil == "100-0" ? "white" : "grey",
                              backgroundColor:
                                percentagefil == "100-0"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              handleChange2(e.target.value, DEVELOPMENT)
                            }
                          >
                            100%-0%
                          </button>
                        </div>
                      </div>
                    </li>

                    <Divider />
                    <li style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 9, fontWeight: "600" }}
                          className="fontdesign"
                        >
                          Assignee name
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              width: "70px",
                              fontSize: "9px",
                              color: assignfil == "ASC" ? "white" : "grey",
                              backgroundColor:
                                assignfil == "ASC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent",
                              // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              handleassign(e.target.value, DEVELOPMENT)
                            }
                          >
                            <i
                              className="fa fa-sort-asc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Ascending
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              width: "70px",
                              fontSize: "9px",
                              marginLeft: "5px",
                              color: assignfil == "DESC" ? "white" : "grey",
                              backgroundColor:
                                assignfil == "DESC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              handleassign2(e.target.value, DEVELOPMENT)
                            }
                          >
                            <i
                              className="fa fa-sort-desc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Descending
                          </button>
                        </div>
                      </div>
                    </li>
                    <Divider />
                    <li style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 9, fontWeight: "600" }}
                          className="fontdesign"
                        >
                          Sprint name
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              fontSize: "9px",
                              width: "70px",
                              color: releasefile == "ASC" ? "white" : "grey",
                              backgroundColor:
                                releasefile == "ASC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent",
                              // marginLeft: "18px", // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              releasefil(e.target.value, DEVELOPMENT)
                            }
                          >
                            <i
                              className="fa fa-sort-asc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Ascending
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              width: "70px",
                              fontSize: "9px",
                              marginLeft: "5px",
                              color: releasefile == "DESC" ? "white" : "grey",
                              backgroundColor:
                                releasefile == "DESC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) =>
                              releasefil2(e.target.value, DEVELOPMENT)
                            }
                          >
                            <i
                              className="fa fa-sort-desc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Descending
                          </button>
                        </div>
                      </div>
                    </li>

                    <Divider />
                    <li style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: 9, fontWeight: "600" }}
                          className="fontdesign"
                          // onClick={(e) => handleChange(e.target.value, DEVELOPMENT)}
                        >
                          Ticket Id
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              width: "70px",
                              fontSize: "9px",
                              color: epicfil == "ASC" ? "white" : "grey",
                              backgroundColor:
                                epicfil == "ASC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent",
                              // Adjust margin as needed
                            }}
                            onClick={(e) => handleissueid(e, "ASC")}
                          >
                            <i
                              className="fa fa-sort-asc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Ascending
                          </button>
                          <button
                            style={{
                              borderRadius: "5px",
                              border: "1px solid black",
                              padding: "4px",
                              color: "grey",
                              width: "70px",
                              fontSize: "9px",
                              marginLeft: "5px",
                              color: epicfil == "DESC" ? "white" : "grey",
                              backgroundColor:
                                epicfil == "DESC"
                                  ? "rgb(82, 80, 229)"
                                  : "transparent", // Adjust margin as needed
                            }}
                            onClick={(e) => handleissueid2(e, "DESC")}
                          >
                            <i
                              className="fa fa-sort-desc"
                              style={{ marginRight: "5px" }}
                            ></i>
                            Descending
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
        // label: (
        //   <div className="each" style={{ float: "right" }}>
        //     <b>{DEVELOPMENT.length + " of " + totalDEVELOPMENTCards}</b>
        //   </div>
        // ),
        cards: DEVELOPMENT,
        // cardStyle: { backgroundColor: 'blue' }
      },
      {
        id: "UAT",
        className: "uat",
        title: "",
        label: (
          <div style={containerStylediv} className="">
            {/* <button style={buttonStyleforuat} >Mark</button> */}
            {(rolecode == "SM" || projectRole == "SM") && (
              <button
                style={buttonStyleforuat}
                onClick={handleButtonClickUAT}
                hidden={checkalluat.length <= 0}
                class="cBtns"
                // onClick={(event) => {
                //   event.stopPropagation();
                //   handleCreateClick(index, "create");
                // }}
                // ref={popoverRef}
              >
                <div class="csigns">
                  <CheckCircleOutlineIcon
                    //CircleIcon
                    // fontSize="small"
                    // sx={{ fontSize: 12 }}
                    // className="filtericon fontdesign"
                    style={{ color: "#5250E5", fontSize: 14 }}
                  />
                </div>

                <div class="ctexts">Mark all as Done</div>
              </button>
            )}

            <label
              className="Lanetitle fontdesign"
              style={{ color: "#000 ", fontSize: "0.90em", fontWeight: "bold" }}
            >
              UAT ({UAT.length})
            </label>
          </div>
        ),
        // label: (
        //   <div className="each" style={{ float: "right" }}>
        //     <b>{UAT.length + " of " + totalUATCards}</b>
        //   </div>
        // ),
        cards: UAT,
      },
      {
        id: "DONE",
        className: "done",
        title: "",
        label: (
          <label
            className="Lanetitle fontdesign"
            style={{ color: "#000 ", fontSize: "0.90em", fontWeight: "bold" }}
          >
            Done ({DONE.length})
          </label>
        ),
        // label: (
        //   <div className="each" style={{ float: "right" }}>
        //     <b>{DONE.length + " of " + totalDONECards}</b>
        //   </div>
        // ),
        cards: DONE,
      },
    ],
  };

  let Onclick = (e) => {
    // setProgressopen(true);
    if (
      (projectRole == "DEV" &&
        FilteredData?.find((x) => x.IssueId === e)?.AssignedTo ==
          login?.[0]?.ClientUserId) ||
      projectRole !== "DEV"
    ) {
      dispatch(HANDLE_ON_CHANGE({ name: "progressopen", value: true }));
      // setSelectedCardId(e);
      dispatch(HANDLE_ON_CHANGE({ name: "SelectedCardId", value: e }));
      dispatch(SELECTEDCARD(FilteredData?.filter((x) => x.IssueId === e)?.[0]));
      dispatch(
        HANDLE_ON_CHANGE({
          name: "deletedata",
          value: FilteredData?.filter((x) => x.IssueId === e)?.[0],
        })
      );
    }
  };

  const refinedtobacklog = () => {
    const ck = rtbdata;
    axios
      .get(
        `agilesaas_releases?ReleaseName=eq.${ck?.PlannedRelease}&ProjectId=eq.${projectCode}`
      )
      .then((res) => {
        const data = res.data?.[0];
        const patchdata = {
          ReleaseIssues: res.data?.[0]?.ReleaseIssues?.filter(
            (a) => a.IssueId != ck?.IssueId
          ),
          RemovedTickets:
            res.data?.[0]?.RemovedTickets == null ||
            res.data?.[0]?.RemovedTickets?.length == 0
              ? [{ IssueId: ck?.IssueId, IssueTitle: ck?.IssueTitle }]
              : [
                  ...data?.RemovedTickets,
                  { IssueId: ck?.IssueId, IssueTitle: ck?.IssueTitle },
                ],
        };
        // console.log(patchdata, res.data?.[0]?.ReleaseIssues?.filter((a) => a.IssueId != ck?.IssueId), data?.RemovedTickets, "patch data")
        axios
          .patch(
            `agilesaas_releases?ReleaseId=eq.${data?.ReleaseId}`,
            patchdata
          )
          .then((r) => {
            setRtbdialog(false);
            dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
            toast.success("Moved to backlog and remove from sprint");
          });
        axios
          .patch(`agilesaas_issue_details?IssueId=eq.${ck?.IssueId}`, {
            PlannedRelease: null,
            OriginalEstimate: null,
            CurrentStage: [
              {
                StageCode: "BLG",
                StageName: "Backlog",
                DateMoved: moment().format("YYYY-MM-DD"),
              },
            ],
          })
          .then((r) => {
            console.log(r);
          });
        let HisteryData = {
          IssueId: ck?.IssueId,
          ChangedBy: login?.[0]?.ClientUserId,
          ChangedByDetails: {
            FN: login?.[0]?.UserFirstname,
            LN: login?.[0]?.UserLastname,
          },
          ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
          UpdatedField: "Kanban Changes",
          UpdatedFrom: "RFD",
          UpdatedTo: "BLG",
          UpdateSequence: null,
          IsActive: "Y",
          Status: null,
          ClientId: clientId,
          ProjectId: projectCode,
        };
        axios
          .post("agilesaas_issue_history", HisteryData)
          .then((r) => console.log(r));
      });
  };

  let CardMoving = (cardId, sourceLaneId, targetLaneId, cardDetails) => {
    // console.log(
    //   KanbanBoardData?.filter((e) => e?.IssueId == cardId)?.[0],
    //   cardId,
    //   sourceLaneId,
    //   targetLaneId,
    //   "tttttttttttttttttttttttt"
    // );
    if (
      (sourceLaneId === "BLG" && targetLaneId === "DONE") ||
      (sourceLaneId === "RFD" && targetLaneId === "DONE") ||
      (sourceLaneId === "RFD" && targetLaneId === "UAT") ||
      (sourceLaneId === "BLG" && targetLaneId === "UAT")
    ) {
      toast.error(
        `Not able to move the card from ${sourceLaneId} to ${targetLaneId} directly`,
        {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          draggable: true,
        }
      );
    } else {
      if (
        (projectRole == "DEV" &&
          FilteredData?.find((x) => x.IssueId === cardId)?.AssignedTo ==
            login?.[0]?.ClientUserId) ||
        projectRole !== "DEV"
      ) {
        const ck = KanbanBoardData?.filter((e) => e?.IssueId == cardId)?.[0];
        // console.log(ck, "ckkkkkkkkkkkkkkkkkkkkkkk");
        const checkUser = FilteredData?.filter(
          (x) => x.IssueId === cardId
        )?.[0];
        const releasecheck =
          releases1?.filter(
            (s) =>
              s.ReleaseName === checkUser?.PlannedRelease &&
              moment(s.SprintStartDate).diff(moment(), "day") < 0
          )?.length >= 0;

        if (
          sourceLaneId === "RFD" &&
          targetLaneId === "BLG" &&
          ck?.PlannedRelease
        ) {
          // refinedtobacklog(ck)
          setRtbdialog(true);
          setRtbdata(ck);
        }
        // if (sourceLaneId === "BLG" && targetLaneId === "RFD") {
        //   if (
        //     FilteredData.some(
        //       (x) =>
        //         x.IssueId === cardId &&
        //         x.IssueType === "Story" &&
        //         x.OriginalEstimate === null
        //     )
        //   ) {
        //     setselectedcard(FilteredData.find((x) => x.IssueId === cardId));
        //     setBfopen(true);
        //     setIssueId(cardId);
        //   }
        // } //goto
        else {
          if (sourceLaneId === "RFD" && targetLaneId === "BLG") {
            // setBfopen(false);
            dispatch(HANDLE_ON_CHANGE({ name: "Bfopen", value: false }));
            // setOpenCreateEst(false);
            dispatch(HANDLE_ON_CHANGE({ name: "openCreateEst", value: false }));
          }
          if (
            (sourceLaneId === "BLG" && targetLaneId === "RFD") ||
            (sourceLaneId === "BLG" && targetLaneId === "DEV")
          ) {
            if (
              FilteredData.some(
                (x) =>
                  x.IssueId === cardId &&
                  x.IssueType === "Story" &&
                  x.OriginalEstimate === null
                // && projectRole === "SM"
              )
            ) {
              // setTimeout(() => {
              // setselectedcard(FilteredData.find((x) => x.IssueId === cardId));
              dispatch(
                HANDLE_ON_CHANGE({
                  name: "selectedCard",
                  value: FilteredData.find((x) => x.IssueId === cardId),
                })
              );
              // setIssueId(cardId);
              dispatch(HANDLE_ON_CHANGE({ name: "IssueId", value: cardId }));
              // setLane({
              //   ...Lane,
              //   Source: sourceLaneId,
              //   Target: targetLaneId,
              // });
              dispatch(
                HANDLE_ON_CHANGE({
                  name: "Lane",
                  value: {
                    ...Lane,
                    Source: sourceLaneId,
                    Target: targetLaneId,
                  },
                })
              );
              // setOption(true);
              dispatch(HANDLE_ON_CHANGE({ name: "ShowOption", value: true }));
              // setOptionID(cardId);
              dispatch(
                HANDLE_ON_CHANGE({ name: "ShowOptionID", value: cardId })
              );
              // }, 1000);
            }
          }
          if (
            (sourceLaneId === "RFD" &&
              targetLaneId === "DEV" &&
              checkUser?.AssignedTo != null &&
              checkUser?.IssueType != "Epic") ||
            (sourceLaneId === "RFD" &&
              targetLaneId === "DEV" &&
              checkUser?.AssignedTo == null &&
              checkUser?.IssueType == "Epic")
          ) {
            if (
              FilteredData.some(
                (x) =>
                  x.IssueId === cardId &&
                  (x.IssueType === "Story" ||
                    x.IssueType === "Task" ||
                    x.IssueType === "Bug") &&
                  x.OriginalEstimate === null &&
                  x.ExceptiontoSkipSPEstimation == "Y"
              )
            ) {
              // setselectedcard(FilteredData.find((x) => x.IssueId === cardId));
              dispatch(
                HANDLE_ON_CHANGE({
                  name: "selectedCard",
                  value: FilteredData.find((x) => x.IssueId === cardId),
                })
              );
              // setIssueId(cardId);
              dispatch(HANDLE_ON_CHANGE({ name: "IssueId", value: cardId }));
            } else if (
              FilteredData.some(
                (x) =>
                  x.IssueId === cardId &&
                  x.IssueType === "Story" &&
                  x.OriginalEstimate === null &&
                  x.ExceptiontoSkipSPEstimation === "L"
              )
            ) {
              // setselectedcard(FilteredData.find((x) => x.IssueId === cardId));
              dispatch(
                HANDLE_ON_CHANGE({
                  name: "selectedCard",
                  value: FilteredData.find((x) => x.IssueId === cardId),
                })
              );
              // setIssueId(cardId);
              dispatch(HANDLE_ON_CHANGE({ name: "IssueId", value: cardId }));
              // setLane({
              //   ...Lane,
              //   Source: sourceLaneId,
              //   Target: targetLaneId,
              // });
              dispatch(
                HANDLE_ON_CHANGE({
                  name: "Lane",
                  value: {
                    ...Lane,
                    Source: sourceLaneId,
                    Target: targetLaneId,
                  },
                })
              );
              // setOption(true);
              dispatch(HANDLE_ON_CHANGE({ name: "ShowOption", value: true }));
              // setOptionID(cardId);
              dispatch(
                HANDLE_ON_CHANGE({ name: "ShowOptionID", value: cardId })
              );
              // }, 1000);
            } else if (
              FilteredData.some(
                (x) =>
                  x.IssueId === cardId &&
                  x.OriginalEstimate === null &&
                  x.ExceptiontoSkipSPEstimation != "Y" &&
                  (x.IssueType === "Task" ||
                    x.IssueType === "Bug" ||
                    x.IssueType === "SubTask")
              )
            ) {
              setTimeout(() => {
                // setOpenCreateEst(true);
                dispatch(
                  HANDLE_ON_CHANGE({
                    name: "openCreateEst",
                    value: TrendingUpRounded,
                  })
                );
                // setIssueId(cardId);
                dispatch(HANDLE_ON_CHANGE({ name: "IssueId", value: cardId }));
              }, 400);
            }
          }
          if (sourceLaneId === "DEV" && targetLaneId === "RFD") {
            // setBfopen(false);
            dispatch(HANDLE_ON_CHANGE({ name: "Bfopen", value: false }));
            // setOpenCreateEst(false);
            dispatch(HANDLE_ON_CHANGE({ name: "openCreateEst", value: false }));
          }
          if (sourceLaneId === "UAT" && targetLaneId === "DEV") {
            // setUATtesterD(false);
            dispatch(HANDLE_ON_CHANGE({ name: "UATtesterD", value: false }));
            // setuattesterassign(false);
            dispatch(
              HANDLE_ON_CHANGE({ name: "uattesterassign", value: false })
            );
          }
          if (
            (sourceLaneId === "DEV" &&
              targetLaneId === "UAT" &&
              checkUser?.AssignedTo != null &&
              checkUser?.IssueType != "Epic") ||
            (sourceLaneId === "DEV" &&
              targetLaneId === "UAT" &&
              checkUser?.AssignedTo == null &&
              checkUser?.IssueType == "Epic")
          ) {
            // setUATtesterD(true);
            dispatch(HANDLE_ON_CHANGE({ name: "UATtesterD", value: true }));
            // setuattesterassign(true);
            dispatch(
              HANDLE_ON_CHANGE({ name: "uattesterassign", value: true })
            );
          }
          if (sourceLaneId === "DONE" && targetLaneId === "UAT") {
            dispatch(HANDLE_ON_CHANGE({ name: "UATchecklistD", value: false }));
            // setUATchecklistD(false);
          }
          if (
            (sourceLaneId === "UAT" &&
              targetLaneId === "DONE" &&
              checkUser?.AssignedTo != null &&
              checkUser?.IssueType != "Epic") ||
            (sourceLaneId === "UAT" &&
              targetLaneId === "DONE" &&
              checkUser?.AssignedTo == null &&
              checkUser?.IssueType == "Epic")
          ) {
            // setUATchecklistD(true);
            dispatch(HANDLE_ON_CHANGE({ name: "UATchecklistD", value: true }));
          }
          if (
            sourceLaneId === "RFD" &&
            targetLaneId === "DEV" &&
            checkUser?.AssignedTo == null
          ) {
            setOpen(true);
            setMessage(
              "Card moved to Development without assignment! Please assign a team member to this task before proceeding."
            );
            setMessageType("success");
          } else {
            // console.log("false");
          }

          let newfilteredData = FilteredData.map((x) => {
            // if (x.IssueId === cardId) {
            //   let Temp = x;
            //   Temp["CurrentStage"] = [
            //     {
            //       DateMoved: moment().format("YYYY-MM-DD"),
            //       StageCode: targetLaneId,
            //       StageName:
            //         targetLaneId === "BLG"
            //           ? "Backlog"
            //           : targetLaneId === "RFD"
            //             ? "Refined"
            //             : targetLaneId === "DEV"
            //               ? "In Development"
            //               : targetLaneId === "UAT"
            //                 ? "User Acceptace Testing"
            //                 : targetLaneId === "DONE"
            //                   ? "Done"
            //                   : "",
            //     },
            //   ];
            //   return Temp;
            // } else {
            //   return x;
            // }
            if (x.IssueId === cardId) {
              return {
                ...x, // Copy all properties from the original object
                CurrentStage: [
                  {
                    DateMoved: moment().format("YYYY-MM-DD"),
                    StageCode: targetLaneId,
                    StageName:
                      targetLaneId === "BLG"
                        ? "Backlog"
                        : targetLaneId === "RFD"
                        ? "Refined"
                        : targetLaneId === "DEV"
                        ? "In Development"
                        : targetLaneId === "UAT"
                        ? "User Acceptance Testing"
                        : targetLaneId === "DONE"
                        ? "Done"
                        : "",
                  },
                ],
              };
            } else {
              return x; // Return the original object if no changes are needed
            }
          });

          let datas = {
            CurrentStage: [
              {
                StageCode: targetLaneId,
                StageName:
                  targetLaneId === "BLG"
                    ? "Backlog"
                    : targetLaneId === "RFD"
                    ? "Refined"
                    : targetLaneId === "DEV"
                    ? "In Development"
                    : targetLaneId === "UAT"
                    ? "User Acceptace Testing"
                    : targetLaneId === "DONE"
                    ? "Done"
                    : "",
                DateMoved: moment().format("YYYY-MM-DD"),
              },
            ],
          };

          let HisteryData = {
            IssueId: cardId,
            ChangedBy: login?.[0]?.ClientUserId,
            ChangedByDetails: {
              FN: login?.[0]?.UserFirstname,
              LN: login?.[0]?.UserLastname,
            },
            ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
            UpdatedField: "Kanban Changes",
            UpdatedFrom: sourceLaneId,
            UpdatedTo: targetLaneId,
            UpdateSequence: null,
            IsActive: "Y",
            Status: null,
            ClientId: clientId,
            ProjectId: projectCode,
          };
          let getdata = FilteredData.find((e) => e.IssueId == cardId);

          let cardtitle = FilteredData.find(
            (e) => e.IssueId == cardId
          )?.IssueTitle;

          if (sourceLaneId != targetLaneId) {
            console.log(
              targetLaneId === "BLG" ||
                targetLaneId === "RFD" ||
                targetLaneId === "UAT" ||
                targetLaneId === "DONE",
              (targetLaneId === "DEV" &&
                releasecheck &&
                getdata.OriginalEstimate !== null) ||
                getdata.ExceptiontoSkipSPEstimation === "Y",
              targetLaneId === "DEV" &&
                releasecheck &&
                getdata.OriginalEstimate !== null,
              getdata.ExceptiontoSkipSPEstimation,
              releasecheck,
              "releasecheck"
            );
            if (
              targetLaneId === "BLG" ||
              targetLaneId === "RFD" ||
              targetLaneId === "UAT" ||
              targetLaneId === "DONE"
            ) {
              if (targetLaneId == "UAT") {
                let requrireddata = {
                  ClientUserId: empid?.[0]?.ClientUserId,
                  ClientId: empid?.[0]?.ClientId,
                  ClientDetails: {
                    Company: userorg?.Company,
                    ContactName: userorg?.ContactName,
                    ContactDesignation: userorg?.ContactDesignation,
                    ContactEmail: userorg?.ContactEmail,
                    ContactPhone: userorg?.ContactPhone,
                  },
                  DepartmentDetails: empid?.[0]?.DepartmentDetails,
                  DepartmentCode: null,
                  TentantId: "",
                  FromSenderId: "no-reply@yozytech.com",
                  emailnotify: false,
                  webnotify: true,
                  ToEmailIds: {
                    emailids: empid?.[0]?.ClientDetails.ContactEmail,
                  },
                  CcEmailIds: {
                    emailids: ["agileteam@yozytech.com"],
                  },
                  BccEmailIds: "",
                  EmailType: "",
                  ProductDetails: {
                    ProdId: "11004",
                    ProdName: "KANBAN",
                    Domain: "KANBAN.yozytech.com",
                  },
                  EmailSubject: "Stage Moved to UAT",
                  IsSuccess: "Y",
                  IsPartiallyFailed: " ",
                  SendRetryHistory: "",
                  IsActive: " ",
                  Status: "",
                  EmailTemplateName: "",
                  EmailTemplateVariables: {
                    xxasignbyxx: "",
                    xxassigndatexx: "",
                    xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
                    xxprjdisxx: "",
                    xxprjnamexx: "",
                    xxstoryNamexx: "",
                    xxprjpercenxx: "",
                    xxprjstartdatexx: "",
                    xxprjenddataexx: "",
                    xxteamnamexx: "",
                    xxsprintxx: "",
                    xxmanagernamexx: userorg?.ContactName,
                    xxcompanyname: userorg?.Company,
                    xxmanagerdesigxx: "Scrum Master",
                    xxgmailxx: userorg?.ContactEmail,
                    xxphnoxx: userorg?.ContactPhone,
                    webappdescri: `${cardtitle} - (${cardId}) Recently moved to UAT `,
                  },
                };
                axios({
                  url: "https://api.devozy.ai/api/agile/email",
                  method: "post",
                  data: requrireddata,
                  headers: {
                    origin: "https://kanban.yozytech.com",
                    "Content-Type": "application/json",
                  },
                })
                  .then((res) => {
                    console.log(res.data, "mailsend");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                  if(slack && slack == "Y"){
                    slacknotify(
                   channelname,
                   `${cardtitle} - (${cardId}) Recently moved to UAT`,
                   slacktoken
                    )
                  }
              }
              axios
                .patch("agilesaas_issue_details?IssueId=eq." + cardId, datas)
                .catch((err) => console.log(err))
                .then((res) => {
                  // setFilteredData(newfilteredData);
                  dispatch(
                    HANDLE_ON_CHANGE({
                      name: "FilteredData",
                      value: newfilteredData,
                    })
                  );
                  // setRefresh(true);
                  dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
                  axios.post("agilesaas_issue_history", HisteryData);
                });
            } else if (
              (targetLaneId === "DEV" &&
                releasecheck &&
                getdata.OriginalEstimate !== null) ||
              getdata.ExceptiontoSkipSPEstimation === "Y" ||
              getdata.ExceptiontoSkipSPEstimation === "L"
              // targetLaneId === "DEV" && releasecheck  &&
              // (  getdata.OriginalEstimate != null && releasecheck ||
              //   getdata.ExceptiontoSkipSPEstimation === "Y" )
            ) {
              let cardt = FilteredData.find(
                (e) => e.IssueId == cardId
              )?.IssueTitle;
              let requrireddata = {
                ClientUserId: empid?.[0]?.ClientUserId,
                ClientId: empid?.[0]?.ClientId,
                ClientDetails: {
                  Company: userorg?.Company,
                  ContactName: userorg?.ContactName,
                  ContactDesignation: userorg?.ContactDesignation,
                  ContactEmail: userorg?.ContactEmail,
                  ContactPhone: userorg?.ContactPhone,
                },
                DepartmentDetails: empid?.[0]?.DepartmentDetails,
                DepartmentCode: null,
                TentantId: "",
                FromSenderId: "no-reply@yozytech.com",
                emailnotify: false,
                webnotify: true,
                ToEmailIds: {
                  emailids: empid?.[0].ClientDetails.ContactEmail,
                },
                CcEmailIds: {
                  emailids: ["agileteam@yozytech.com"],
                },
                BccEmailIds: "",
                EmailType: "",
                ProductDetails: {
                  ProdId: "11004",
                  ProdName: "KANBAN",
                  Domain: "KANBAN.yozytech.com",
                },
                EmailSubject: "Stage Moved to DEV",
                IsSuccess: "Y",
                IsPartiallyFailed: " ",
                SendRetryHistory: "",
                IsActive: " ",
                Status: "",
                EmailTemplateName: "",
                EmailTemplateVariables: {
                  xxasignbyxx: "",
                  xxassigndatexx: "",
                  xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
                  xxprjdisxx: "",
                  xxprjnamexx: "",
                  xxstoryNamexx: "",
                  xxprjpercenxx: "",
                  xxprjstartdatexx: "",
                  xxprjenddataexx: "",
                  xxteamnamexx: "",
                  xxsprintxx: "",
                  xxmanagernamexx: userorg?.ContactName,
                  xxcompanyname: userorg?.Company,
                  xxmanagerdesigxx: "Scrum Master",
                  xxgmailxx: userorg?.ContactEmail,
                  xxphnoxx: userorg?.ContactPhone,
                  webappdescri: `${cardt} -(${cardId}) Recently moved to DEV `,
                },
              };
              axios({
                url: "https://api.devozy.ai/api/agile/email",
                method: "post",
                data: requrireddata,
                headers: {
                  origin: "https://kanban.yozytech.com",
                  "Content-Type": "application/json",
                },
              })
                .then((res) => {
                  console.log(res.data, "mailsend");
                })
                .catch((err) => {
                  console.log(err);
                });
                if(slack && slack == "Y"){
                  slacknotify(
                 channelname,
                 `${cardt} -(${cardId}) Recently moved to DEV `,
                 slacktoken
                  )
                }
              axios
                .patch("agilesaas_issue_details?IssueId=eq." + cardId, datas)
                .catch((err) => console.log(err))
                .then((res) => {
                  // setFilteredData(newfilteredData);
                  dispatch(
                    HANDLE_ON_CHANGE({
                      name: "FilteredData",
                      value: newfilteredData,
                    })
                  );
                  // setRefresh(true);
                  dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
                  axios
                    .post("agilesaas_issue_history", HisteryData)
                    .catch((err) => console.log(err))
                    .then((res) => console.log(res));
                });
            } else if (
              targetLaneId === "DEV" &&
              !releasecheck &&
              (getdata.OriginalEstimate != null ||
                getdata.ExceptiontoSkipSPEstimation === "Y")
            ) {
              // setFilteredData(newfilteredData);
              dispatch(
                HANDLE_ON_CHANGE({
                  name: "FilteredData",
                  value: newfilteredData,
                })
              );
              // setRefresh(true);
              dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
            }
          }
        }
      }
    }
  };

  let handleDragStart = (cardId, laneId) => {
    if (
      (projectRole == "DEV" &&
        FilteredData?.find((x) => x.IssueId === cardId)?.AssignedTo ==
          login?.[0]?.ClientUserId) ||
      projectRole !== "DEV"
    ) {
      if (laneId === "RFD") {
        if (
          FilteredData.some(
            (x) =>
              x.IssueId === cardId &&
              x.IssueType === "Story" &&
              x.OriginalEstimate === null &&
              x.ExceptiontoSkipSPEstimation != "Y"
          )
        ) {
          // setselectedcard(FilteredData.find((x) => x.IssueId === cardId));
          dispatch(
            HANDLE_ON_CHANGE({
              name: "selectedCard",
              value: FilteredData.find((x) => x.IssueId === cardId),
            })
          );
          // setIssueId(cardId);
          dispatch(HANDLE_ON_CHANGE({ name: "IssueId", value: cardId }));
        } else if (
          FilteredData.some(
            (x) =>
              x.IssueId === cardId &&
              x.OriginalEstimate === null &&
              x.ExceptiontoSkipSPEstimation != "Y" &&
              (x.IssueType === "Task" ||
                x.IssueType === "Bug" ||
                x.IssueType === "SubTask")
          )
        ) {
          // setIssueId(cardId);
          dispatch(HANDLE_ON_CHANGE({ name: "IssueId", value: cardId }));
          // setRefresh(true);
          dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
        }
      } else if (laneId === "DEV") {
        // setselectedcard(FilteredData.find((x) => x.IssueId === cardId));
        dispatch(
          HANDLE_ON_CHANGE({
            name: "selectedCard",
            value: FilteredData.find((x) => x.IssueId === cardId),
          })
        );
        // setIssueId(cardId);
        dispatch(HANDLE_ON_CHANGE({ name: "IssueId", value: cardId }));
      } else if (laneId === "UAT") {
        // setselectedcard(FilteredData.find((x) => x.IssueId === cardId));
        dispatch(
          HANDLE_ON_CHANGE({
            name: "selectedCard",
            value: FilteredData.find((x) => x.IssueId === cardId),
          })
        );
        // setIssueId(cardId);
        dispatch(HANDLE_ON_CHANGE({ name: "IssueId", value: cardId }));
      }
    }
  };

  // const handleDrag = (cardId, sourceLaneId, targetLaneId, cardDetails) => {
  //   const checkUser = FilteredData.filter(
  //     (x) =>
  //       x.IssueId === cardId)?.[0]?.AssignedTo
  //   if (sourceLaneId === "RFD" && targetLaneId === "DEV" && checkUser == null) {
  //     return false
  //   }
  //   if (sourceLaneId === "DEV" && targetLaneId === "UAT" && checkUser == null) {
  //     return false
  //   }
  //   if (sourceLaneId === "UAT" && targetLaneId === "DONE" && checkUser != null) {
  //     return false
  //   } else {
  //     return true
  //   }
  // };
  // const handleDrag = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
  //   // Implement your custom logic here to control card draggability
  //   if (sourceLaneId === 'lane1' && targetLaneId === 'lane2') {
  //     // Allow dragging from 'To Do' to 'In Progress'
  //     return true;
  //   } else if (sourceLaneId === 'lane2' && targetLaneId === 'lane3') {
  //     // Allow dragging from 'In Progress' to 'Done'
  //     return true;
  //   } else {
  //     // Prevent dragging in all other cases
  //     return false;
  //   }
  // };
  const handleCloseEpic = () => {
    setEpicClose(false);
  };
  const handleEpic = () => {
    const issueid = epicCloseData?.epic?.IssueId;

    axios
      .patch(`agilesaas_issue_details?IssueId=eq.${issueid}`, { IsActive: "N" })
      .then((e) => {
        console.log(e);
        setMessage("Epic closed successfully");
        setMessageType("success");
        setOpen(true);
        setEpicClose(false);
        // setRefresh(true);
        dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
      });
  };

  // const movestage = (e, issueid, stage) => {
  //   //add notification
  //   //add entry in issue history

  //   const data = stage == "BLG" ? [{ StageCode: "RFD", StageName: "Refined", DateMoved: moment().format("YYYY-MM-DD") }] :
  //     stage == "RFD" ? [{ StageCode: "DEV", StageName: "In Development", DateMoved: moment().format("YYYY-MM-DD") }] :
  //       stage == "DEV" ? [{ StageCode: "UAT", StageName: "User Acceptace Testing", DateMoved: moment().format("YYYY-MM-DD") }] :
  //         [{ StageCode: "DONE", StageName: "Done", DateMoved: moment().format("YYYY-MM-DD") }]
  //   axios
  //     .patch(`agilesaas_issue_details?IssueId=eq.${issueid}`, { CurrentStage: data })
  //     .then((res) => {
  //       axios.post("agilesaas_issue_history",
  //         {
  //           IssueId: e?.IssueId,
  //           ChangedBy: emplogin?.[0]?.ClientUserId,
  //           ChangedByDetails: {
  //             FN: emplogin?.[0]?.UserFirstname,
  //             LN: emplogin?.[0]?.UserLastname,
  //           },
  //           ChangedDate: moment()
  //             .utcOffset("+05:30")
  //             .format("YYYY-MM-DDTHH:mm:ss"),
  //           UpdatedField: "Kanban Changes",
  //           UpdatedFrom: stage,
  //           UpdatedTo: stage == "BLG" ? "RFD" :
  //             stage == "RFD" ? "DEV" :
  //               stage == "DEV" ? "UAT" :
  //                 "DONE",
  //           UpdateSequence: 1,
  //           IsActive: "Y",
  //           Status: null,
  //           ClientId: clientId,
  //           ProjectId: projectCode,
  //         });
  //       Mailandnotification(
  //         e,
  //         emplogin?.[0]?.ClientUserId == e?.AssignedTo ? emplogin?.[0]?.ClientDetails?.ContactEmail : EmpDropDownData?.filter((x) => x.teammemberid == selectedCard1?.AssignedTo)?.[0]?.email,
  //         [empid?.[0]?.OfficialEmail],
  //         true,
  //         false,
  //         projectName,
  //         "Stage update",
  //         `${e?.IssueTitle} is moved to ${stage == "BLG" ? "Refined" :
  //           stage == "RFD" ? "Development" :
  //             stage == "DEV" ? "User acceptance testing" :
  //               "Done"} by ${emplogin?.[0]?.UserFirstname + " " + emplogin?.[0]?.UserLastname}`)
  //       setOpen(true);
  //       setMessage("Card moved successfully")
  //       setRefresh(true);
  //     });
  // };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: "2.875rem",
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const EmployeeDropdown = resData
    .filter((x) => x.projectcode == projectId2)
    ?.map((e) => ({
      label: e.firstname + " " + e.lastname,
      value: e.teammemberid,
    }))
    ?.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((o) => o.label === obj.label && o.value === obj.value)
    );
  function handleAssignedTo(item) {
    setAssignedEmployee(item.value);
    setAssignedEmployeeN(item.label);
  }
  return (
    <div className="KanbanBoardtrel">
      <Deletedialog />
      <Dialog
        open={rtbdialog}
        onClose={() => {
          setRtbdialog(false);
        }}
        className="mainbox"
        maxWidth="xs"
      >
        {/* <DialogTitle className="d-flex justify-flex-end">
          <IconButton className="d-flex justify-flex-end" onClick={() => { handleDialogClose() }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
        <DialogContent className="pt-4">
          If the ticket is moved to the backlog, we will need to replan the
          sprint and re-estimate the card. Are you sure you want to proceed?
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <Button
              style={{
                fontSize: 14,
                padding: 9,
                width: 70,
                height: 30,
                borderRadius: 8,
                // backgroundColor: '#5250E5',
                // color: '#fff',
                // border: '0px solid #5A88F1',
                border: "2px solid #5A88F1",
                backgroundColor: "#fff",
                color: "#5250E5",
                textAlign: "center",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // marginRight: issueType == "Task" || issueType == "Bug" ? 5 : 24
              }}
              onClick={() => {
                setRtbdialog(false);
              }}
              // disabled={checkSubmit}
            >
              Cancel
            </Button>
            <Button
              // onClick={() => submit("RFD")}
              // disabled={checkSubmit || issueType == "Story" || issueType == "Epic"}
              style={{
                fontSize: 14,
                padding: 9,
                height: 30,
                borderRadius: 8,
                width: 70,
                // border: '2px solid #5A88F1',
                // backgroundColor: '#fff',
                // color: '#5250E5',
                backgroundColor: "#5250E5",
                color: "#fff",
                border: "0px solid #5A88F1",
                textAlign: "center",
                textTransform: "none",
                marginRight: 24,
                marginLeft: 5,
              }}
              onClick={() => {
                refinedtobacklog();
              }}
            >
              Proceed
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={clonedialog}
        onClose={handleclone}
        className="mainbox"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Row
            className=""
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Col lg={10} md={10}>
              <label
                className="text-secondary fontdesign"
                style={{ fontWeight: "bold" }}
              >
                Clone the ticket with Id - ({clonedata.IssueId})
              </label>
            </Col>
            <div className="d-flex justify-content-end">
              <IconButton onClick={handleclone}>
                <CloseIcon />
              </IconButton>
            </div>
          </Row>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                display: "block",
                marginBottom: "10px",
              }}
            >
              In which stage, the card should be cloned to
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
           
              <button
                type="button"
                className={`btn btn-custom ${
                  stage === "Refined" ? "selected" : "not-selected"
                }`}
                value="Refined"
                onClick={() => handleStageChange("Refined")}
              >
                Refined
              </button>
              <button
                type="button"
                className={`btn btn-custom ${
                  stage === "Backlog" ? "selected" : "not-selected"
                }`}
                value="Backlog"
                onClick={() => handleStageChange("Backlog")}
              >
                Backlog
              </button>
            </div>
          </div>
          <Box marginBottom="20px">
            <label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                display: "block",
                marginBottom: "10px",
              }}
            >
              Title
            </label>
            <Input
              onChange={handleInputChange}
              value={issueTitleedit}
              className="SprintFormFields"
              placeholder="Enter Title"
              fullWidth
            />
          </Box>
          <Box marginBottom="20px">
  <label
    style={{
      fontWeight: "bold",
      fontSize: "14px",
      display: "block",
      marginBottom: "10px",
    }}
  >
    Select Sprint
    {releasesOption23.length === 0 && (
    <div  className="text-muted" style={{ marginTop: "10px" }}>
      No active sprints available
    </div>
  )}
  </label>
  <Select
    styles={customStyles}
    options={releasesOption23}
    value={
      releasesOption23 &&
      releasesOption23.find((option) => option.value === sprint)
    }
    onChange={handleChangsde}
    placeholder={<span>Select Sprint</span>}
    components={{
      SingleValue: CustomSingleValue,
      Option: CustomOption,
    }}
    isDisabled={releasesOption23.length === 0}
  />
 
</Box>

          <Box marginBottom="70px" marginTop="20px">
            <label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                display: "block",
                marginBottom: "10px",
              }}
            >
              Assigned To
            </label>
            <Select
              name="AssignedTo"
              placeholder="Select Employee"
              options={EmployeeDropdown}
              styles={customStyles}
              onChange={handleAssignedTo}
              value={EmployeeDropdown.filter(
                (option) => option.label === assignedEmployeeN
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleclone}
              style={{
                fontSize: 14,
                padding: 9,
                width: 100,
                height: 40,
                borderRadius: 8,
                marginRight: 14,
                border: "2px solid #5250E5",
                backgroundColor: "#fff",
                color: "#5250E5",
                textAlign: "center",
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleclonesubmit}
              disabled={isSubmitting}
              style={{
                fontSize: 14,
                padding: 9,
                width: 100,
                height: 40,
                borderRadius: 8,
                backgroundColor: "#5250E5",
                color: "#fff",
                border: "0px solid #5A88F1",
                textAlign: "center",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialog}
        onClose={() => {
          handleDialogClose();
        }}
        className="mainbox"
        maxWidth="xs"
      >
        {/* <DialogTitle className="d-flex justify-flex-end">
          <IconButton className="d-flex justify-flex-end" onClick={() => { handleDialogClose() }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
        <DialogContent className="pt-4">{dialogmessage}</DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <Button
              style={{
                fontSize: 14,
                padding: 9,
                width: 70,
                height: 30,
                borderRadius: 8,
                // backgroundColor: '#5250E5',
                // color: '#fff',
                // border: '0px solid #5A88F1',
                border: "2px solid #5A88F1",
                backgroundColor: "#fff",
                color: "#5250E5",
                textAlign: "center",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // marginRight: issueType == "Task" || issueType == "Bug" ? 5 : 24
              }}
              onClick={() => {
                handleDialogClose();
              }}
              // disabled={checkSubmit}
            >
              Cancel
            </Button>
            <Button
              // onClick={() => submit("RFD")}
              // disabled={checkSubmit || issueType == "Story" || issueType == "Epic"}
              style={{
                fontSize: 14,
                padding: 9,
                height: 30,
                borderRadius: 8,
                width: 70,
                // border: '2px solid #5A88F1',
                // backgroundColor: '#fff',
                // color: '#5250E5',
                backgroundColor: "#5250E5",
                color: "#fff",
                border: "0px solid #5A88F1",
                textAlign: "center",
                textTransform: "none",
                marginRight: 24,
                marginLeft: 5,
              }}
              onClick={() => {
                setDialog(false);
                assignedUpdate(statex, statee);
              }}
            >
              {type}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={epicClose}
        onClose={() => {
          handleCloseEpic();
        }}
        className="mainbox"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Col lg={10} md={10}>
              <label
                className="text-secondary fontdesign"
                style={{ fontWeight: "bold" }}
              >
                Are you want to close the epic?
              </label>
            </Col>
            <IconButton
              className="d-flex justify-content-end"
              onClick={() => {
                setEpicClose(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          className="pt-4"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {epicCloseData?.linkedissues?.length > 0 ? (
            <EpiccloseTable
            // data={epicCloseData?.linkedissues}
            />
          ) : (
            <div
              style={{
                height: 400,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label style={{ textAlign: "center" }}>
                No issues are linked to this epic
              </label>
            </div>
          )}
        </DialogContent>

        {/* {
          epicCloseData?.linkedissues?.length > 0 ? */}
        <DialogActions>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <Button
              style={{
                fontSize: 14,
                padding: 9,
                width: 70,
                height: 30,
                borderRadius: 8,
                // backgroundColor: '#5250E5',
                // color: '#fff',
                // border: '0px solid #5A88F1',
                border: "2px solid #5A88F1",
                backgroundColor: "#fff",
                color: "#5250E5",
                textAlign: "center",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // marginRight: issueType == "Task" || issueType == "Bug" ? 5 : 24
              }}
              onClick={() => {
                handleCloseEpic();
              }}
              // disabled={checkSubmit}
            >
              Cancel
            </Button>
            <Button
              // onClick={() => submit("RFD")}
              // disabled={checkSubmit || issueType == "Story" || issueType == "Epic"}
              style={{
                fontSize: 14,
                padding: 9,
                height: 30,
                borderRadius: 8,
                width: 70,
                // border: '2px solid #5A88F1',
                // backgroundColor: '#fff',
                // color: '#5250E5',
                backgroundColor: "#5250E5",
                color: "#fff",
                border: "0px solid #5A88F1",
                textAlign: "center",
                textTransform: "none",
                marginRight: 24,
                marginLeft: 5,
              }}
              onClick={() => {
                handleEpic();
              }}
            >
              Submit
            </Button>
          </div>
        </DialogActions>
        {/* :
            null
        } */}
      </Dialog>
      {data ? (
        <Card>
          <div className="BoardDiv">
            <Board
              style={{ overflow: "visible", backgroundColor: "white" }}
              data={data}
              onCardClick={Onclick}
              hideCardDeleteIcon={true}
              cardDraggable={true}
              // cardDraggable={handleDrag}
              handleDragEnd={CardMoving}
              handleDragStart={handleDragStart}
              cardStyle={{
                width: "5 !important",
                backgroundColor: "white",
              }}
              laneStyle={{
                width: "5 !important",
                overflow: "hidden !important",
              }}
              tagStyle={{
                fontSize: "100%",
              }}
            />
          </div>
        </Card>
      ) : (
        <p>Loading...</p>
      )}

      <OriginalEstimate
      // openCreateEst={openCreateEst}
      // setOpenCreateEst={setOpenCreateEst}
      // IssueId={IssueId}
      // FilteredData={FilteredData}
      // selectedCard={selectedCard}
      // setRefresh={setRefresh}
      />
      <UATtester
      // projectRole={projectRole}
      // UATtesterD={UATtesterD}
      // setUATtesterD={setUATtesterD}
      // IssueId={IssueId}
      // selectedCard={selectedCard}
      // setRefresh={setRefresh}
      // uattesterassign={uattesterassign}
      // setuattesterassign={setuattesterassign}
      />
      <UATchecklist
      // UATchecklistD={UATchecklistD}
      // setUATchecklistD={setUATchecklistD}
      // IssueId={IssueId}
      // selectedCard={selectedCard}
      // setProgressopen={setProgressopen}
      // setRefresh={setRefresh}
      />
      <Storypoints
      // projectRole={projectRole}
      // Bfopen={Bfopen}
      // setBfopen={setBfopen}
      // IssueId={IssueId}
      // projectId2={projectId2}
      // selectedCard={selectedCard}
      // SelectedCardId={SelectedCardId}
      // setRefresh={setRefresh}
      // FilteredData={FilteredData}
      />
      <Storyoption
      // ShowOption={ShowOption}
      // ShowOptionID={ShowOptionID}
      // setOption={setOption}
      // projectRole={projectRole}
      // Bfopen={Bfopen}
      // setBfopen={setBfopen}
      // IssueId={IssueId}
      // projectId2={projectId2}
      // selectedCard={selectedCard}
      // SelectedCardId={SelectedCardId}
      // setRefresh={setRefresh}
      // FilteredData={FilteredData}
      // Lane={Lane}
      // releases={releases}
      />
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          sx={{
            width: "100%",
            backgroundColor: "green !important",
            color: "#fff !important",
          }}
          severity={messagetype}
        >
          {message}
        </Alert>
      </Snackbar>

      <div>
        <MinimizeMaximizeButton
        // isMinimized={isMinimized}
        // handleOpen={handleOpen}
        />
      </div>
    </div>
  );
}

export default KanbanDashboard;
