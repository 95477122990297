import axios from "axios";
import tenetData from "./tenant.json";
import Utils from "./utlis";
import Cookies from "universal-cookie";
import axios1 from "../../axios";
import axios2 from "../../filesAxios";

export async function main(values, setCheckSubmit) {
  try {
    const _login = await axios.post(
      "https://api.devozy.ai/users/signIn",
      values
    );
    let responce = await _login.data;
   
    axios.interceptors.request.use((config) => {
      config.headers.Authorization = "Bearer " + responce.token;
      return config;
    });

    let ClientId = await CheckDevozylogin(values.login, responce.token);
    await ClientUser(values.login, ClientId)

    let r = await axios(
      `https://api.devozy.ai/subscription_client_license_details?IsActive=eq.Y&ClientId=eq.${ClientId}&ProductDetails->>ProdId=in.(11004,11006)`
    );
    let ress = await r.data;
    let data = ress[0].IncludedProducts;
    if (data) {
      setCheckSubmit(false)
      let isIdFound = data.some((item) => parseInt(item.ProdId) === 11004);
      if (isIdFound == false) return (ress = []);
      if (ress.length > 0) {
        const endDate = new Date(ress[0].ValidTill);
        const today = new Date();
        if (endDate >= today) {
          return UserConfigset(responce, values);
        } else {
          return "Your plan is exprird";
        }
      } else {
        setCheckSubmit(false)
        return "You don't have access to this product";
      }
    } else {
      setCheckSubmit(false)
      // return "You don't have access to this product";
      return UserConfigset(responce, values);
    }
  } catch (error) {
    setCheckSubmit(false)
    
    return "Invalid username or password";
  }
}

export function UserConfigset(data, values) {
  const cookies = new Cookies();
  let { login, token } = data;
  let cookietoken = cookies?.get("token");
  let cookielogin = cookies?.get("email");
  if (cookietoken && cookielogin) {
    console.log("Coookies Already set");
  } else {
    Utils.setCookie("token", token, 1 / 12);
    Utils.setCookie("email", values.login, 1 / 12);
  }
  localStorage.setItem("userdata", JSON.stringify(data));

  axios1.interceptors.request.use((config) => {
    config.headers.Authorization = "Bearer " + token;
    return config;
  });
  axios2.interceptors.request.use((config) => {
    config.headers.Authorization = "Bearer " + token;
    return config;
  });

  let _data = {
    data: {
      login,
      Token: token,
    },
  };
  return _data;
}

export async function CheckDevozylogin(login, token) {
  try {
    let _productLoginCheck = await axios.get(
      `https://api.devozy.ai/subscription_user_role_mapping?UserDetails->>Email=eq.${login}&LoginEnabled=eq.Y`
    );
    let projectresponce = await _productLoginCheck.data;
    console.log(projectresponce, "projectresponce");
    if (projectresponce.length > 0) {
      let { RoleDetails, OrgDetails } = {};
      const foundEntry = projectresponce.find(
        (e) => 11001 === parseInt(e.ProductDetails.ProdId)
      );
      const { ClientId } = projectresponce[0];
      if (foundEntry) {
        ({ RoleDetails, OrgDetails } = foundEntry);
        const ProjectRole = tenetData.project_role.find(
          (a) => a.RoleName.toLowerCase() === RoleDetails.RoleName.toLowerCase()
        );
        const userRole = ProjectRole?.RoleCode;
        const data = tenetData.role_component_map
          .filter((b) => b.UserRole.includes(userRole))
          .map((a) => ({
            UserRole: [userRole],
            Component: a.Component,
            Details: a.Details,
          }));

        const company = OrgDetails.Company.replace(/ /g, "");
        localStorage.setItem('tenantid', `devozy_${company}_${ClientId}`)
        // localStorage.setItem('loginuser1', projectresponce?.[0]?.UserDetails?.Firstname + projectresponce?.[0]?.UserDetails?.Lastname)
        // localStorage.setItem('userid1', projectresponce?.[0]?.UserId)
        // localStorage.setItem('email1', projectresponce?.[0]?.UserDetails?.Email)

        const userdata = {
          user: {
            email: login,
            role: userRole,
            tenantid: `devozy_${company}_${ClientId}`,
            components: data,
          },
          token: token,
          projects: [],
        };

        Utils.setCookie("SSO", true, 1 / 12);
        Utils.setCookie("userdata", JSON.stringify(userdata), 1 / 12);
        Utils.setCookie('loginuser1', projectresponce?.[0]?.UserDetails?.Firstname + projectresponce?.[0]?.UserDetails?.Lastname)
        Utils.setCookie('userid1', projectresponce?.[0]?.UserId)
        Utils.setCookie('email1', projectresponce?.[0]?.UserDetails?.Email)

        axios1.interceptors.request.use((config) => {
          config.headers.tenantid = `devozy_${company}_${ClientId}`
          return config;
        });
        axios2.interceptors.request.use((config) => {
          config.headers.tenantid = `devozy_${company}_${ClientId}`
          return config;
        });

        return ClientId;
      } else {
        return ClientId;
      }
    }
  } catch (error) {
    return "You have access to this product";
  }
}


function generateRandomUUID() {
  const standardUUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  const tenDigitUUID = standardUUID.replace(/-/g, '').substr(0, 10);

  return tenDigitUUID;
}

async function ClientUser(email, clientid) {
  try {
    let user = await axios.get(`https://api.devozy.ai/subscription_client_users?OfficialEmail=eq.${email}&ClientId=eq.${clientid}`)
    let responces = await user.data[0].UUID || generateRandomUUID()

    axios1.interceptors.request.use((config) => {
      config.headers.uuid = responces
      return config;
    });
    axios2.interceptors.request.use((config) => {
      config.headers.uuid = responces
      return config;
    });
  } catch (error) {
    return "Error while fetching user unique id";
  }
}