import React, { useState, useEffect, useContext, useRef } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Input } from "reactstrap";
// import { Button } from "@material-ui/core";
import Select from "react-select";
import ReactQuill from "react-quill";
import Toolbar, { modules, formats } from "./Toolbar";
import "react-quill/dist/quill.snow.css";
import MinimizeMaximizeButton from "./MinimizeMaximizeButton";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import {
  MINIMIZED,
  ISCREATEKB,
  HANDLE_ON_CHANGE,
} from "../../redux/CommonInitialsate";
import "./CreateKb.css";
import Bookmark from "@material-ui/icons/Bookmark";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import AlbumRounded from "@material-ui/icons/AlbumRounded";
import OfflineBoltRoundedIcon from "@material-ui/icons/OfflineBoltRounded";
import { styled } from "@material-ui/styles";
import axios from "../../axios";
import moment from "moment";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import MaterialIcon from "material-icons-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UserContext from "../../index";
import { Button as Button1, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Card, IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Create } from "@material-ui/icons";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import agile from "../../assets/images/agile.png";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import Axios from "axios";
import Cookies from "universal-cookie";
import Mailandnotification from "../../Mailandnotification";
import slacknotify from "../../slacknotify";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CreateKb(
  {
    // client1,
    // openCreate,
    // setOpenCreate,
    // EmpDropDownData,
    // projectLabel,
    // projectCode,
    // client,
    // setProjectName,
    // setProjectId,
    // setClient,
    // projectName,
    // projectId,
    // employeeList,
    // setEmployeeList,
    // KBRefresh,
    // projectDetails,
    // KanbanBoardData,
    // dropdown,
    // setDropdown,
    // resData,
    // TesterID,
    // emp,
    // projectnamedetails,
    // testerEpic,
    // settesterEpic,
    // issueType,
    // setIssueType,
  }
) {
  const {
    KanbanBoardData,
    projectDetails,
    settingDetails,
    projectModules,
    projectRoleName,
    projectRole,
    projectName,
    projectId2,
    client,
    client1,
    projectCode,
    issueType,
    openCreate,
    projectLabel,
    resData,
    testerEpic,
    emp,
    testerid,
    releases1,
    releases,
    EmpDropDownData,
    slacktoken,
    channelname,
  } = useSelector((state) => state.user);
  const cookies = new Cookies();

  // console.log(projectModules?.filter((x) => x.ProjectId == projectCode), "projectModulessssssssssssssssssssssssssssssssssssss")
  const TesterID = testerid
    // .filter((e) => e.projectrole[0]?.RoleGroupId == 14)
    .map((m) => m.projectrole?.RoleCode);
  let arr =
    sessionStorage.getItem("Roledetails") == "undefined"
      ? ""
      : JSON.parse(sessionStorage.getItem("Roledetails"));
  // console.log(
  //   KanbanBoardData.filter((e) => e.ProjectId == projectCode).length,
  //   KanbanBoardData.filter((e) => e.ProjectId == projectCode),
  //   "testerepic",
  //   projectCode
  // );
  let EmpId = arr.RoleName;
  let empid = JSON.parse(localStorage.getItem("Empdetails"));
  let slack = localStorage.getItem("Slack");

  let clientdetails = JSON.parse(localStorage.getItem("clientdetails"));
  const userorg = clientdetails[0]?.OrgDetails;
  let clientid = JSON.parse(localStorage.getItem("clientid"));
  let tokenid = JSON.parse(localStorage?.getItem("userdata"));
  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == "11004")[0]
    ?.RoleCode;

  let initialValidation = {
    titleValidation: false,
    descriptionValidation: false,
    releaseValidation: false,
    epicValidation: false,
    // relatedToValidation: false,
  };
  const [validation, setValidation] = useState(initialValidation);
  // const [releases, setReleases] = useState([]);
  const [issueTitle, setIssueTitle] = useState("");
  const [description, setDescription] = useState("");
  const [assignedEmployeeId, setAssignedEmployeeId] = useState(null);
  const [assignedEmployeeName, setAssignedEmployeeName] = useState("null null");
  const [relatedTo, setRelatedTo] = useState(null);
  const [project, setProject] = useState(projectCode);
  const [module, setModule] = useState("");
  const [checkSubmit, setSubmitCheck] = useState(false);
  const lengthCheck = KanbanBoardData.filter(
    (e) =>
      e.ProjectId == projectCode && e.IsActive == "Y" && e.IssueType == "Epic"
  ).length;
  // console.log(lengthCheck, "lengthcheck");
  const [epic, setEpic] = useState([
    {
      IssueId: "",
      IssueType: "Story",
      Title: "",
      CurrentStage: "",
      Priority: "Medium",
    },
  ]);
  const [releaseValue, setReleaseValue] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [priority, setPriority] = useState("Medium");
  const [another, setAnother] = useState(false);
  // const [issueType, setIssueType] = useState(lengthCheck == 0 ? "Epic" : projectRole != "SM" && getProdRole != "KA" ? "Task" : "Story");
  const [releaseLabel, setReleaseLabel] = useState();
  const [epicLabel, setEpicLabel] = useState("");
  const [skipEstimation, setSkipEstimation] = useState(false);
  let EmpName = arr.RoleName == undefined ? "Kanban Admin" : arr.RoleName;
  const [errmsg, setErrMsg] = useState("");
  const [upload, setUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [minkb, setminkb] = useState(false);
  // console.log(minkb, "minkbjs");
  const PriorityOption = [
    { label: "Highest", value: "Highest" },
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
    { label: "Lowest", value: "Lowest" },
  ];
  const [open, setOpen] = useState(false);

  const handleClosesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const BootstrapButton = styled(Button)({
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    margin: 10,
    border: "0px solid",
    borderRadius: 25,
  });

  // console.log(
  //   lengthCheck == 0 && (projectRole == "SM" || getProdRole == "KA")
  //     ? "Epic"
  //     : projectRole != "SM" && getProdRole != "KA"
  //     ? "Task"
  //     : "Story",
  //   issueType
  // );
  function refreshstages() {
    setUpload(false);
    setSelectedFile(null);
    setErrMsg("");
    setReleaseLabel();
    setEpicLabel("");
    setValidation({
      ...validation,
      titleValidation: false,
      descriptionValidation: false,
      // assignedToValidation: false,
      releaseValidation: false,
      epicValidation: false,
      // relatedToValidation: false,
    });

    // setProjectName(projectLabel);
    dispatch(HANDLE_ON_CHANGE({ name: "projectName", value: projectLabel }));
    dispatch(HANDLE_ON_CHANGE({ name: "projectId2", value: projectCode }));
    // setProjectId(projectCode);
    // setClient(client1);
    dispatch(HANDLE_ON_CHANGE({ name: "client", value: client1 }));
    // setIssueType(lengthCheck == 0 ? "Epic" : "Story");
    dispatch(
      HANDLE_ON_CHANGE({
        name: "issueType",
        value:
          lengthCheck == 0 &&
          (projectRole == "SM" || getProdRole == "KA" || projectRole == "TL")
            ? "Epic"
            : projectRole != "SM" && getProdRole != "KA"
            ? "Task"
            : "Story",
      })
    );
    setDescription("");
    setPriority("Medium");
    setAssignedEmployeeName("null null");
    setAssignedEmployeeId(null);
    setRelatedTo(null);
    setEpic([
      {
        IssueId: "",
        IssueType: "Story",
        Title: "",
        CurrentStage: "",
        Priority: "Medium",
      },
    ]);
    setReleaseValue(null);
    setIssueTitle("");
    setAttachment(null);
    setSkipEstimation(false);
  }

  function refresh() {
    // axios
    //   .get("agilesaas_issue_details?ProjectId=eq." + projectCode)
    //   .then((response) => {
    //     settesterEpic(response.data);
    //   })
    //   .catch((error) => console.log(error));
    setErrMsg("");
    // KBRefresh();
    dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
    // axios.get("agilesaas_releases").then((res) => {
    //   setReleases(res.data);
    // });
    setReleaseLabel();
    setEpicLabel("");
    setValidation({
      ...validation,
      titleValidation: false,
      descriptionValidation: false,
      // assignedToValidation: false,
      releaseValidation: false,
      epicValidation: false,
      // relatedToValidation: false,
    });
    // setProjectName(projectLabel);
    dispatch(HANDLE_ON_CHANGE({ name: "projectName", value: projectLabel }));
    dispatch(HANDLE_ON_CHANGE({ name: "projectId2", value: projectCode }));
    // setProjectId(projectCode);
    // setClient(client1);
    dispatch(HANDLE_ON_CHANGE({ name: "client", value: client1 }));
    //setIssueType(lengthCheck == 0 ? "Epic" : "Story");
    setDescription("");
    setPriority("Medium");
    setAssignedEmployeeName("null null");
    setAssignedEmployeeId(null);
    setRelatedTo(null);
    setEpic([
      {
        IssueId: "",
        IssueType: "Story",
        Title: "",
        CurrentStage: "",
        Priority: "Medium",
      },
    ]);
    setReleaseValue(null);
    setIssueTitle("");
    setAttachment(null);
    setSkipEstimation(false);
  }

  useEffect(() => {
    refresh();
    dispatch(
      HANDLE_ON_CHANGE({
        name: "issueType",
        value:
          lengthCheck == 0 &&
          (projectRole == "SM" || getProdRole == "KA" || projectRole == "TL")
            ? "Epic"
            : projectRole != "SM" && getProdRole != "KA"
            ? "Task"
            : "Story",
      })
    );
    // setIssueType(lengthCheck == 0 ? "Epic" : "Story")
    // refreshstages()
  }, []);

  function handleClose() {
    dispatch(ISCREATEKB(false));
    setSubmitCheck(false);
    setErrMsg("");
    // setOpenCreate(false);
    dispatch(HANDLE_ON_CHANGE({ name: "openCreate", value: false }));
    //  refresh();
    refreshstages();
    // contextval.setcontextvalue({ ...contextval.concontextvalue, Creatpopup: false })
  }

  let finalDatas = testerEpic
    ?.filter(
      (x) =>
        x.IssueType === "Epic" && x.ProjectId == projectId2 && x.IsActive != "N"
    )
    ?.map((e) => ({
      label: e.IssueTitle,
      value: e.IssueId,
      Type: e.IssueType,
      CurrentStage: e.CurrentStage[0].StageCode,
      Priority: e.Priority,
    }));
  console.log(testerEpic, "ttttttttttttttttttt");
  // console.log(projectId, testerEpic?.filter((x) => x.IssueType === "Epic" && x.ProjectId == projectId))

  const Managerdata = KanbanBoardData?.filter(
    (x) =>
      x.IssueType === "Epic" && x.ProjectId == projectId2 && x.IsActive != "N"
  )?.map((e) => ({
    label: e.IssueTitle,
    value: e.IssueId,
    Type: e.IssueType,
    CurrentStage: e.CurrentStage[0].StageCode,
    Priority: e.Priority,
  }));
  // console.log(
  //   KanbanBoardData?.filter(
  //     (x) => x.IssueType === "Epic" && x.ProjectId == projectId2
  //   ),
  //   "mmmmmmmmmmmmmmmmmmmmmmmmmmmm"
  // );
  // console.log(projectId, KanbanBoardData?.filter(
  //   (x) => x.IssueType === "Epic" && x.ProjectId == projectId
  // ))
  // console.log(TesterID, "rolecheck");
  const EpicDropdown =
    TesterID.length != 0 && TesterID == "QA" ? finalDatas : Managerdata;
  // console.log(TesterID.length != 0 && TesterID == "QA", EpicDropdown)

  const relatedToFilterEpic = epic.length == 0 ? 0 : epic[0]?.IssueId;

  const TesteraccountrelatedData = testerEpic
    ?.filter(
      (x) =>
        x.IssueType != "Epic" &&
        x.LinkToEpic != [] &&
        x.LinkToEpic != null &&
        x.LinkToEpic?.[0]?.IssueId == relatedToFilterEpic &&
        x.ProjectId == projectId2
    )
    ?.map((e) => ({
      label: e.IssueTitle,
      value: e.IssueId,
      IssueType: e.IssueType,
      CurrentStage: e.CurrentStage[0].StageName,
      Priority: e.Priority,
    }));

  const ManageraccountData = KanbanBoardData.filter(
    (x) =>
      x.IssueType != "Epic" &&
      x.LinkToEpic != [] &&
      x.LinkToEpic != null &&
      x.LinkToEpic?.[0]?.IssueId == relatedToFilterEpic &&
      x.ProjectId == projectId2
  ).map((e) => ({
    label: e.IssueTitle,
    value: e.IssueId,
    IssueType: e.IssueType,
    CurrentStage: e.CurrentStage[0].StageName,
    Priority: e.Priority,
  }));

  const RelatedDropdown =
    TesterID.length != 0 && TesterID == "Tes"
      ? TesteraccountrelatedData
      : ManageraccountData;

  // const releasesDropdown = releases
  //   .filter((x) => x.IsReleaseFrozen != "Y" && x.ProjectId == projectId)
  //   .map((x) => ({
  //     label: x.ReleaseName,
  //     value: x.ReleaseId,
  //   }));

  const testerdropdown = emp?.map((e) => ({
    label: e.UserFirstname + " " + e.UserLastname,
    value: e.ClientUserId,
  }));

  // resData
  //   .filter(
  //     (x) =>
  //       x.projectcode == projectId && x.teammemberid != empid[0]?.ClientUserId
  //   )
  const EmployeeDropdown = resData
    .filter((x) => x.projectcode == projectId2)
    ?.map((e) => ({
      label: e.firstname + " " + e.lastname,
      value: e.teammemberid,
    }))
    ?.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((o) => o.label === obj.label && o.value === obj.value)
    );

  const getmodules =
    projectModules?.length > 0
      ? projectModules?.filter((x) => x.ProjectId == projectCode)?.[0]
          ?.ProjectModules
      : [];
  const moduleOptions =
    getmodules?.length > 0
      ? getmodules?.map((e) => ({
          label: e.FullName,
          value: e,
        }))
      : [];

  useEffect(() => {
    if (EpicDropdown?.filter((e) => e.IsActive == "Y").length == 1) {
      handleEpic(EpicDropdown?.filter((e) => e.IsActive == "Y")?.[0]);
    }
  }, [KanbanBoardData, epicLabel]);

  useEffect(() => {
    if (moduleOptions?.length > 0) {
      setModule(moduleOptions?.[0]?.value);
    }
  }, []);

  useEffect(() => {
    if (EmployeeDropdown?.length == 1) {
      handleAssignedTo(EmployeeDropdown?.[0]);
    }
  }, [assignedEmployeeId]);

  // console.log(EmployeeDropdown, testerdropdown, "sample");
  const ProjectDropdown = projectDetails?.map((e) => ({
    label: e.projectname,
    value: parseInt(e.projectcode),
    client: e.client,
  }));

  function handleAssignedTo(item) {
    setAssignedEmployeeId(item.value);
    setAssignedEmployeeName(item.label);
  }

  function handleProject(item) {
    // setProjectId(parseInt(item.value));
    dispatch(
      HANDLE_ON_CHANGE({ name: "projectId2", value: parseInt(item.value) })
    );
    // setProjectName(item.label);
    dispatch(HANDLE_ON_CHANGE({ name: "projectName", value: item.label }));
    // setClient(item.client);
    dispatch(HANDLE_ON_CHANGE({ name: "client", value: item.client }));
    // setEmployeeList(resData.filter((x) => x.projectcode == item.value));
    dispatch(
      HANDLE_ON_CHANGE({
        name: "employeeList",
        value: resData.filter((x) => x.projectcode == item.value),
      })
    );
    // setDropdown(KanbanBoardData.filter((x) => x.ProjectId == item.value));
    dispatch(
      HANDLE_ON_CHANGE({
        name: "dropdown",
        value: KanbanBoardData?.filter((e) => e.ProjectId == item.value),
      })
    );
  }

  function handleEpic(item) {
    setEpicLabel(item.label);
    setEpic([
      {
        IssueId: item.value,
        IssueType: item.Type,
        Title: item.label,
        CurrentStage: item.CurrentStage,
        Priority: item.Priority,
      },
    ]);
  }
  // console.log(epicLabel);
  function handleRelease(item) {
    setReleaseLabel(item.label);
    setReleaseValue(item.value);
  }

  function handlePriority(item) {
    setPriority(item.label);
  }

  function handleRelatedTo(e) {
    let value = Array.isArray(e)
      ? e.map((x) => {
          let robj = {};
          robj["IssueId"] = x.value;
          robj["IssueType"] = x.IssueType;
          robj["Title"] = x.label;
          robj["CurrentStage"] = x.CurrentStage;
          robj["Priority"] = x.Priority;
          return robj;
        })
      : null;
    setRelatedTo(value);
  }

  const handleChange = (value) => {
    setDescription(value);
  };

  const handleInput = (e) => {
    setIssueTitle(e.target.value);
  };

  const firstname =
    assignedEmployeeName == " " || undefined
      ? " "
      : assignedEmployeeName.split(" ").length == 3
      ? assignedEmployeeName.split(" ")[0] +
        " " +
        assignedEmployeeName.split(" ")[1]
      : assignedEmployeeName.split(" ")[0];
  const toid = emp
    ?.filter((r) => r.ClientUserId === assignedEmployeeId)
    ?.map((d) => d.OfficialEmail);
  // console.log(toid, "toid");

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: "2.875rem",
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  // const sendMessageToSlack = async () => {
  //   if(slack === "Y"){
  //   try {

  //     const response = await axios.post('http://localhost:2002/api/agile/slackservice', {
  //       channelId: "C07J502L13J",
  //       message: "AWS On-boarding Changes - (1650) Recently moved to UAT",
  //     });

  //     if (response.data.success) {
  //       console.log('Message sent successfully!');

  //     } else {
  //       console.log(`Error sending message: ${response.data.error}`);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);

  //   }
  // }
  // };
  console.log(projectRole, " projectRole");
  function submit(stage) {
    setValidation({
      ...validation,
      titleValidation: false,
      descriptionValidation: false,
      releaseValidation: false,
      // relatedToValidation: false,
    });
    let emplastname =
      issueType != "Epic" ? assignedEmployeeName.split(" ").pop() : null;
    const notificationEmpId = issueType == "Epic" ? EmpId : assignedEmployeeId;
    const rname = selectedFile?.name.replace(/[\s-_]/g, "");
    const newFileName = `${clientid}-${projectCode}_${moment().format(
      "YYYYMMDDHHmmssSSS"
    )}_${rname}`;
    let postData = {
      ProjectId: projectId2,
      ProjectShortCode: projectName.slice(0, 3),
      IssueType: issueType,
      IssueTitle: issueTitle,
      Description: description,
      Priority: priority,
      PlannedRelease: issueType != "Epic" ? releaseLabel : null,
      EstimateUnit: issueType != "Epic" ? "MD" : null,
      EstimateUnitDesc: issueType != "Epic" ? "Man Days" : null,
      CurrentStage: [
        {
          StageCode: stage,
          StageName: stage == "RFD" ? "Refined" : "Backlog",
          DateMoved: moment().format("YYYY-MM-DD"),
        },
      ],
      LinkToEpic: issueType != "Epic" ? epic : null,
      LinkToIssues: issueType != "Epic" ? relatedTo : null,
      CreatedBy: empid[0]?.ClientUserId,
      CreatedByDetails: empid[0]?.UserFirstname + " " + empid[0]?.UserLastname,
      // arr.RoleName == "Undefined" ? "Kanban Admin" : arr.RoleName,
      CreatedDate: moment().format("YYYY-MM-DD"),
      AssignedTo: issueType != "Epic" ? assignedEmployeeId : null,
      AssignedToDetails: { FN: firstname, LN: emplastname },
      AssignedDate: issueType != "Epic" ? moment().format("YYYY-MM-DD") : null,
      Attachment: issueType != "Epic" ? attachment : null,
      IsActive: "Y",
      ExceptiontoSkipSPEstimation: skipEstimation ? "Y" : "N",
      ProjectDetails: [{ ProjName: projectName, Client: client }],
      AssignedToUAT: null,
      AssignedToUATDetails: {
        FN: null,
        LN: null,
      },
      ClientId: clientid,
      ModuleLinked: module,
      AttachmentReference: selectedFile
        ? [
            {
              Filename: newFileName?.split("-")?.[1],
              UpdatedDate: moment(),
            },
          ]
        : null,
    };

    let notificationData = {
      CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
      CreatedBy: EmpId,
      NotifyTo: notificationEmpId,
      AudienceType: "Individual",
      Priority: "High",
      Subject: issueType + " is created",
      Description:
        issueType + " is created by " + arr.firstname + " " + arr.lastname,
      IsSeen: "N",
      Status: "New",
    };

    const projectCheck = ProjectDropdown.filter(
      (option) => option.label === projectName
    ).length;
    if (
      (issueTitle != "" &&
        // description != "" &&
        issueType != "Epic" &&
        issueType != "Bug" &&
        projectCheck != 0 &&
        epic[0].IssueId != "") ||
      (issueTitle != "" &&
        // description != "" &&
        issueType == "Bug" &&
        projectCheck != 0 &&
        epic[0].IssueId != "") ||
      // &&
      // relatedTo != null
      (issueTitle != "" &&
        // description != "" &&
        issueType == "Epic" &&
        projectCheck != 0)
    ) {
      // let requrireddata = {
      //   ClientUserId: empid?.[0]?.ClientUserId,
      //   ClientId: clientid,
      //   ClientDetails: {
      //     Company: userorg?.Company,
      //     ContactName: userorg?.ContactName,
      //     ContactDesignation: userorg?.ContactDesignation,
      //     ContactEmail: userorg?.ContactEmail,
      //     ContactPhone: userorg?.ContactPhone,
      //   },
      //   DepartmentDetails: empid?.[0]?.DepartmentDetails,
      //   DepartmentCode: null,
      //   TentantId: "",
      //   FromSenderId: "no-reply@yozytech.com",
      //   ToEmailIds: {
      //     emailids: toid?.[0],
      //   },
      //   CcEmailIds: {
      //     emailids: ["kandhasamy.a@yozytech.com", empid?.[0]?.OfficialEmail],
      //   },
      //   BccEmailIds: "",
      //   EmailType: "StoryAssignment",
      //   ProductDetails: {
      //     ProdId: "11004",
      //     ProdName: "KANBAN",
      //     Domain: "KANBAN.yozytech.com",
      //   },
      //   EmailSubject: "Story Assignment Notification",
      //   IsSuccess: "Y",
      //   IsPartiallyFailed: " ",
      //   SendRetryHistory: "",
      //   IsActive: " ",
      //   Status: "",
      //   EmailTemplateName: "StoryAssignment",
      //   emailnotify: true,
      //   webnotify: true,
      //   EmailTemplateVariables: {
      //     xxasignbyxx: arr.RoleName,
      //     xxassigndatexx: moment().format("YYYY-MM-DD"),
      //     xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
      //     xxprjdisxx: description,
      //     xxprjnamexx: projectName,
      //     xxstoryNamexx: issueTitle,
      //     xxprjpercenxx: "",
      //     xxprjstartdatexx: "",
      //     xxprjenddataexx: "",
      //     xxteamnamexx: "",
      //     xxsprintxx: "",
      //     xxmanagernamexx: userorg?.ContactName,
      //     xxcompanyname: userorg?.Company,
      //     xxmanagerdesigxx: "Scrum Master",
      //     xxgmailxx: userorg?.ContactEmail,
      //     xxphnoxx: userorg?.ContactPhone,
      //     webappdescri:
      //       "You have assigned to " + issueTitle + "in project " + projectName,
      //   },
      // };
      setSubmitCheck(true);
      // axios({
      //   url: "https://api.devozy.ai/api/agile/email",
      //   method: "post",
      //   data: requrireddata,
      //   headers: {
      //     origin: "https://kanban.yozytech.com",
      //     "Content-Type": "application/json",
      //   },
      // })
      //   .then((res) => {
      //     console.log(res.data, "mailsend");
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      if (selectedFile) {
        handleUpload(newFileName);
      }
      // sendMessageToSlack()
      // axios
      //   .post("agilesaas_issue_details", postData)
      Axios({
        url: `https://api.devozy.ai/agilesaas_issue_details`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.get("token"),
          Prefer: "return=representation",
        },
        method: "post",
        data: postData,
      })
        .then((ress) => {
          setSubmitCheck(false);
          console.log(ress.data, "resssssssssssssssss");
          if (issueType != "Epic" && assignedEmployeeId) {
            Mailandnotification(
              ress?.data?.[0],
              EmpDropDownData?.filter(
                (x) => x.teammemberid == assignedEmployeeId
              )?.[0]?.email,
              [empid?.[0]?.OfficialEmail],
              true,
              true,
              projectName,
              `Issue created and assigned`,
              `${issueTitle} is created and assigned to you by ${
                empid?.[0]?.UserFirstname + " " + empid?.[0]?.UserLastname
              } in project ${projectName}`
            );
          }
          if (slack && slack == "Y") {
            slacknotify(
              channelname,
              `${issueTitle} is created and assigned to ${
                EmpDropDownData?.filter(
                  (x) => x.teammemberid == assignedEmployeeId
                )?.[0]?.firstname +
                " " +
                EmpDropDownData?.filter(
                  (x) => x.teammemberid == assignedEmployeeId
                )?.[0]?.lastname
              } by ${
                empid?.[0]?.UserFirstname + " " + empid?.[0]?.UserLastname
              } in project ${projectName}`,
              slacktoken
            );
          }
          if (issueType != "Epic" && releaseValue) {
            axios
              .get(
                `agilesaas_releases?ReleaseId=eq.${releaseValue}&select=ReleaseIssues`
              )
              .then((res) => {
                const data = res?.data?.[0]?.ReleaseIssues;
                let patchdata = data
                  ? [
                      ...data,
                      {
                        IssueId: ress.data?.[0]?.IssueId,
                        IssueTitle: issueTitle,
                      },
                    ]
                  : [
                      {
                        IssueId: ress.data?.[0]?.IssueId,
                        IssueTitle: issueTitle,
                      },
                    ];
                axios
                  .patch(`agilesaas_releases?ReleaseId=eq.${releaseValue}`, {
                    ReleaseIssues: patchdata,
                  })
                  .then((res1) => {
                    console.log(res1);
                  });
              });
          }
          const historyDataArray = {
            IssueId: ress?.data?.[0]?.IssueId,
            ChangedBy: empid?.[0]?.ClientUserId,
            ChangedByDetails: {
              FN: empid?.[0]?.UserFirstname,
              LN: empid?.[0]?.UserLastname,
            },
            ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
            UpdatedField: "Issue created",
            UpdatedFrom: "",
            UpdatedTo:
              issueType != "Epic" && assignedEmployeeId
                ? "Issue created by " +
                  empid?.[0]?.UserFirstname +
                  " " +
                  empid?.[0]?.UserLastname +
                  " and allocated to " +
                  firstname +
                  " " +
                  emplastname
                : "Issue created by " +
                  empid?.[0]?.UserFirstname +
                  " " +
                  empid?.[0]?.UserLastname,
            UpdateSequence: null,
            IsActive: "Y",
            Status: null,
            ClientId: clientid,
            ProjectId: projectCode,
          };

          axios
            .post("agilesaas_issue_history", historyDataArray)
            .then((response) => {
              // toast.success("Stage updated successfully");
              // alert("Stage updated successfully");
              console.log("");
            })
            .catch((error) => {
              console.error("Error updating stage:");
            });
          if (another) {
            toast.success(
              `A new ${issueType} has been created. ${
                assignedEmployeeId == null
                  ? "It is currently unassigned."
                  : "It is assigned to " + firstname + " " + emplastname + "."
              }`
            );
            // setOpenCreate(false);
            dispatch(HANDLE_ON_CHANGE({ name: "openCreate", value: false }));
            refresh();
            // refreshstages()
            // setOpenCreate(true);
            dispatch(HANDLE_ON_CHANGE({ name: "openCreate", value: true }));
          } else {
            toast.success(
              `A new ${issueType} has been created. ${
                assignedEmployeeId == null
                  ? "It is currently unassigned."
                  : "It is assigned to " + firstname + " " + emplastname + "."
              }`
            );
            handleClose();
            refresh();
            // setIssueType(lengthCheck == 0 ? "Epic" : "Story");
            dispatch(
              HANDLE_ON_CHANGE({
                name: "issueType",
                value: lengthCheck == 0 ? "Epic" : "Story",
              })
            );
          }
        })
        .catch((e) => {
          setSubmitCheck(false);
          console.log(e);
        });
    } else if (issueTitle == "") {
      setValidation({
        ...validation,
        titleValidation: true,
      });
    }
    // else if (description == "") {
    //   setValidation({
    //     ...validation,
    //     descriptionValidation: true,
    //   });
    // }
    // else if (issueType == "Bug" && relatedTo == null) {
    //   setValidation({
    //     ...validation,
    //     relatedToValidation: true,
    //   });
    // }
    else if (epic[0].IssueId == "") {
      setValidation({
        ...validation,
        epicValidation: true,
      });
    } else if (projectCheck == 0) {
      toast.error("Please choose project");
    }
  }

  const togglePopup = () => {
    setUpload(!upload);
  };
  // console.log(settingDetails);
  const handleFileChange = (event) => {
    console.log("handle file change called");
    const file = event.target.files[0];
    // 999KB in bytes
    // setSelectedFile(file)
    const maxSizeInBytes =
      settingDetails?.value == undefined
        ? 5242880
        : parseInt(settingDetails?.value);
    //5242880; // 999KB in bytes
    // console.log(maxSizeInBytes, file?.size, "check size");
    // console.log(
    //   file && file?.size > maxSizeInBytes,
    //   file?.size > maxSizeInBytes,
    //   file,
    //   "664"
    // );
    console.log(file, file?.size, "check file size");
    if (file && file?.size > maxSizeInBytes) {
      alert(
        "File size exceeds the limit of 5MB. Please select a smaller file."
      );
    } else if (file != undefined) {
      // setOpen(true);
      dispatch(HANDLE_ON_CHANGE({ name: "openCreate", value: true }));
      setSelectedFile(file);
    }
  };
  const maxSizeInBytes = 999 * 1024;
  const resetfile = () => {
    setSelectedFile(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    setSelectedFile(event.dataTransfer.files[0]);
  };

  const fileInputRef = useRef(null);

  const handleSpanClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = (newFileName) => {
    // `${cid}-${empDetails?.[0]?.ClientUserId}_${empDetails?.[0]?.UserFirstname}_${empDetails?.[0]?.UserLastname}.png`;
    // Create a new File object with the desired name
    const renamedFile = new File([selectedFile], newFileName, {
      type: selectedFile.type,
    });
    let data = new FormData();
    data.append("file", renamedFile);
    data.append("ClientId", clientid);
    data.append("ClientUserId", empid?.[0]?.ClientUserId);
    axios
      .post("api/agile/filesystem/CreateIssue", data)
      // let config = {
      //   method: 'post',
      //   maxBodyLength: Infinity,
      //   url: 'http://localhost:2002/api/agile/filesystem/CreateIssue',
      //   data: data
      // };

      // axios.request(config)
      .then((res) => console.log(res.data))
      .catch((e) => console.log(e));
  };
  const dispatch = useDispatch();
  const toggleMinimize = () => {
    const windowElement = document.getElementById("createtask");

    windowElement.style.display = "none";
    dispatch(MINIMIZED(true));
    dispatch(ISCREATEKB(true));
    // setOpenCreate(true);
    dispatch(HANDLE_ON_CHANGE({ name: "openCreate", value: true }));
    // setminkb(true)
  };

  const handleModules = (e) => {
    setModule(e?.value);
  };
  // sconsole.log(getmodules, "moduleoptionsssssssssssss")
  return (
    <>
      <div>
        <Dialog
          open={openCreate}
          // onClose={() => {
          //   handleClose();
          // }}
          id="createtask"
          className="mainbox"
          // maxWidth="1500px"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Col lg={10} md={10}>
                <label
                  className="text-secondary fontdesign"
                  style={{ fontWeight: "bold" }}
                >
                  Create new task ( Epic / Story / Task / Bug )
                </label>
              </Col>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <IconButton onClick={toggleMinimize}>
                  <SouthEastOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <Divider />
          {/* {console.log(TesterID.length != 0, projectRole != "SM", "rolecheck")} */}
          <DialogContent className="KanbanModal">
            <Row className="taskRow mb-3">
              <Col lg={6}>
                <label className="SprintlabelStyle">Project</label>
                {/* <Select
                  styles={customStyles}
                  options={ProjectDropdown}
                  value={ProjectDropdown.filter(
                    (option) => option.label == projectName
                  )}
                  onChange={(item) => {
                    handleProject(item);
                  }}
                  isDisabled={true}
                /> */}
                <Input
                  type="text"
                  value={projectName}
                  className="SprintFormFields"
                  disabled={true}
                />
              </Col>
              <Col lg={6}>
                <label className="SprintlabelStyle">Modules</label>
                <Select
                  styles={customStyles}
                  placeholder="Select a module"
                  options={moduleOptions}
                  value={
                    moduleOptions?.length > 0 &&
                    moduleOptions?.filter(
                      (option) => option?.label == module?.FullName
                    )
                  }
                  onChange={(item) => {
                    if (moduleOptions.length > 0) handleModules(item);
                  }}
                  // isDisabled={true}
                />
              </Col>
            </Row>
            <Row className="taskRow mb-3">
              <Col lg={12}>
                <label className="SprintlabelStyle">
                  Title/Name{" "}
                  <label className="createAlert">
                    *
                    {validation.titleValidation
                      ? ` Please enter ${issueType} title`
                      : null}
                  </label>
                </label>
                <Input
                  onChange={handleInput}
                  value={issueTitle}
                  className="SprintFormFields"
                  placeholder="Enter Title"
                />
              </Col>
            </Row>
            <Row className="taskRow mb-3">
              <Col lg={6}>
                {/* <div className="PriorityIcon"> */}
                <label className="SprintlabelStyle priorityLable mb-3">
                  Priority
                </label>

                {/* </div> */}

                <Select
                  id="Priority"
                  name="Priority"
                  styles={customStyles}
                  options={PriorityOption}
                  maxMenuHeight={120}
                  onChange={(item) => {
                    handlePriority(item);
                  }}
                  value={PriorityOption.filter(
                    (option) => option.label == priority
                  )}
                />
              </Col>
              <Col lg={6}>
                <label className="SprintlabelStyle priorityLable mb-3">
                  Issue Type
                  {errmsg ? (
                    <label className="createAlert">{errmsg}</label>
                  ) : null}
                </label>
                {console.log(lengthCheck, "llllllllllllllll")}
                <Row
                  className="taskRow mb-3 mt-0"
                  style={{ marginLeft: 1, justifyItems: "space-between" }}
                >
                  {/* <input type="radio" id="customRadio4" name="customRadio1" class="form-check-input"></input>           */}
                  <button
                    hidden={
                      // TesterID.length != 0 &&
                      projectRole != "SM" &&
                      getProdRole != "KA" &&
                      projectRole != "TL"
                    }
                    onClick={() => {
                      if (lengthCheck == 0) {
                        setErrMsg("Please create epic to link a story");
                      } else {
                        // setIssueType("Story");
                        dispatch(
                          HANDLE_ON_CHANGE({
                            name: "issueType",
                            value: "Story",
                          })
                        );
                        setSkipEstimation(false);
                        setErrMsg("");
                      }
                    }}
                    style={{
                      backgroundColor: "#fff",
                      border: "none",
                      background: issueType == "Story" ? "#f1efef" : "#fff",
                      borderRadius: 8,
                    }}
                  >
                    <Bookmark
                      className="StoryI"
                      style={{ cursor: "pointer" }}
                    />
                    &nbsp;
                    <label className="labelStyle" style={{ cursor: "pointer" }}>
                      {" "}
                      Story
                    </label>
                    &nbsp; &nbsp;
                  </button>
                  {/* <input type="radio" id="customRadio4" name="customRadio1" class="form-check-input"></input> */}
                  <button
                    onClick={() => {
                      if (lengthCheck == 0) {
                        setErrMsg("Please create epic to link a task");
                      } else {
                        // setIssueType("Task");
                        dispatch(
                          HANDLE_ON_CHANGE({ name: "issueType", value: "Task" })
                        );
                        setSkipEstimation(false);
                        setErrMsg("");
                      }
                    }}
                    style={{
                      backgroundColor: "#fff",
                      border: "none",
                      background: issueType == "Task" ? "#f1efef" : "#fff",
                      borderRadius: 8,
                    }}
                  >
                    <CheckBoxRoundedIcon
                      className="TaskI"
                      style={{ cursor: "pointer" }}
                    />
                    &nbsp;
                    <label className="labelStyle" style={{ cursor: "pointer" }}>
                      {" "}
                      Task
                    </label>
                    &nbsp; &nbsp;
                  </button>
                  <button
                    onClick={() => {
                      if (lengthCheck == 0) {
                        setErrMsg("Please create epic to link a bug");
                      } else {
                        // setIssueType("Bug");
                        dispatch(
                          HANDLE_ON_CHANGE({ name: "issueType", value: "Bug" })
                        );
                        setSkipEstimation(false);
                        setErrMsg("");
                      }
                    }}
                    style={{
                      backgroundColor: "#fff",
                      border: "none",
                      background: issueType == "Bug" ? "#f1efef" : "#fff",
                      borderRadius: 8,
                    }}
                  >
                    {/* <input type="radio" id="customRadio4" name="customRadio1" class="form-check-input"></input>  */}
                    <AlbumRounded
                      className="BugI"
                      style={{ cursor: "pointer" }}
                    />
                    &nbsp;
                    <label className="labelStyle" style={{ cursor: "pointer" }}>
                      Bug
                    </label>
                    &nbsp; &nbsp;
                  </button>
                  {/* <input type="radio" id="customRadio4" name="customRadio1" class="form-check-input"></input> */}
                  <button
                    onClick={() => {
                      // setIssueType("Epic");
                      dispatch(
                        HANDLE_ON_CHANGE({ name: "issueType", value: "Epic" })
                      );
                      setSkipEstimation(true);
                    }}
                    hidden={
                      // TesterID.length != 0 &&
                      projectRole != "SM" &&
                      getProdRole != "KA" &&
                      projectRole != "TL"
                    }
                    style={{
                      backgroundColor: "#fff",
                      border: "none",
                      background: issueType == "Epic" ? "#f1efef" : "#fff",
                      borderRadius: 8,
                    }}
                  >
                    <OfflineBoltRoundedIcon
                      className="EpicI"
                      style={{ cursor: "pointer" }}
                    />
                    &nbsp;
                    <label className="labelStyle" style={{ cursor: "pointer" }}>
                      Epic
                    </label>
                  </button>
                </Row>
              </Col>
            </Row>
            {issueType != "Epic" ? (
              <Row className="taskRow mb-3">
                <Col lg={6}>
                  <label className="SprintlabelStyle">
                    Epic Link
                    <label className="createAlert">*</label>
                    {validation.epicValidation ? (
                      <label className="createAlert">Please Choose Epic</label>
                    ) : null}
                  </label>
                  {/* {console.log(EpicDropdown)} */}
                  <Select
                    placeholder="Select Epic"
                    styles={customStyles}
                    // name="colors"
                    value={EpicDropdown?.filter(
                      (option) => option.label === epicLabel
                    )}
                    // defaultValue={
                    //   EpicDropdown.length == 1
                    //     ? EpicDropdown?.[0]?.label
                    //     : epicLabel
                    // }
                    maxMenuHeight={120}
                    options={EpicDropdown?.filter((e) => e.IsActive != "N")}
                    onChange={(item) => {
                      handleEpic(item);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Col>
                {/* {console.log(
                  releases,
                  "cccccccccccccccccccccccccccccccc",
                  releases
                )} */}
                <Col lg={6}>
                  <label className="SprintlabelStyle priorityLable mb-3">
                    Planned Sprint
                  </label>
                  <Row
                    className="taskRow mb-3 mt-0"
                    style={{
                      marginLeft: 1,
                      justifyItems: "space-between",
                      width: "100%",
                      overflowX: "scroll",
                    }}
                  >
                    <ScrollMenu>
                      {releases?.filter(
                        (x) => x.Status == null && x.IsReleaseFrozen == "N"
                      )?.length > 0
                        ? releases
                            ?.filter(
                              (x) =>
                                x.Status == null && x.IsReleaseFrozen == "N"
                            )
                            ?.map((x) => (
                              <button
                                onClick={() => {
                                  setReleaseLabel(x.ReleaseName);
                                  setReleaseValue(x.ReleaseId);
                                }}
                                style={{
                                  minWidth: 75,
                                  textAlign: "center",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  backgroundColor: "#fff",
                                  border: "1px solid rgb(241, 239, 239)",
                                  background:
                                    x?.ReleaseName == releaseLabel
                                      ? "#f1efef"
                                      : "#fff",
                                  borderRadius: 8,
                                  padding: 2,
                                  whiteSpace: "nowrap",
                                  marginRight: 5,
                                }}
                              >
                                <img
                                  src={agile}
                                  alt="Image"
                                  className="image-class task fontdesign"
                                  width="30"
                                  height="30"
                                  style={{ marginRight: "2px" }}
                                />
                                &nbsp;
                                <label
                                  className="labelStyle"
                                  style={{
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {x?.ReleaseName}
                                </label>
                              </button>
                            ))
                        : "No sprints"}
                    </ScrollMenu>
                  </Row>
                </Col>
              </Row>
            ) : null}
            {issueType != "Epic" &&
            (projectRole == "SM" || getProdRole == "KA") ? (
              <Row className="taskRow mb-3">
                <Col lg={6}>
                  <label className="SprintlabelStyle">Assigned To </label>
                  <Select
                    name="AssignedTo"
                    placeholder="Select Employee"
                    options={EmployeeDropdown}
                    styles={customStyles}
                    onChange={(item) => {
                      handleAssignedTo(item);
                    }}
                    maxMenuHeight={120}
                    value={EmployeeDropdown.filter(
                      (option) => option.label == assignedEmployeeName
                    )}
                  />
                </Col>
                {/* <Col lg={6}>
                <label className="SprintlabelStyle">Created By</label>
                <Input
                  type="text"
                  value={empid[0]?.UserFirstname + " " + empid[0]?.UserLastname}
                  className="SprintFormFields"
                  disabled={true}
                />
              </Col> */}

                <Col lg={6}>
                  <label className="SprintlabelStyle mb-2">
                    Related To
                    {/* {validation.relatedToValidation ? (
                      <label className="createAlert">
                        *Please Choose Related Issue
                      </label>
                    ) : null} */}
                  </label>{" "}
                  {/* {validation.relatedToValidation ? (
                    <label className="createAlert">
                      *Please Choose Related Issue
                    </label>
                  ) : null} */}
                  <Select
                    placeholder="Select Issues"
                    isMulti
                    styles={customStyles}
                    options={RelatedDropdown}
                    onChange={(item) => {
                      handleRelatedTo(item);
                    }}
                    maxMenuHeight={120}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Col>
              </Row>
            ) : null}
            {/* <Row style={{ marginLeft: 5, marginTop: 5 }} className="mb-3">
              <input
                type="checkbox"
                defaultValue={skipEstimation}
                value={skipEstimation}
                onClick={() => {
                  setSkipEstimation(!skipEstimation);
                }}
              />
              <label className="bottomLabel SprintlabelStyle">
                <line> &nbsp;&nbsp;Skip Estimation </line>
              </label>
            </Row> */}
            <Row className="taskRow mb-3">
              <Col lg={12}>
                <label className="SprintlabelStyle">
                  Description
                  {/* <label className="createAlert">*</label> */}
                  {validation.descriptionValidation ? (
                    <label className="createAlert">
                      Please Enter Description
                    </label>
                  ) : null}
                </label>
                <Toolbar />
                <ReactQuill
                  className="quill"
                  theme="snow"
                  value={description}
                  onChange={handleChange}
                  placeholder={"Add description"}
                  modules={modules}
                  formats={formats}
                />
              </Col>
            </Row>
            <div>
              <label className="SprintlabelStyle">Upload Documents</label>

              {/* {upload && ( */}
              <div
                className="popupcreate"
                style={{ borderBlockStyle: "dotted" }}
              >
                <div
                  className={`drop-zone ${dragging ? "dragging" : ""}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <div
                    className="file-input-label-create"
                    style={{ padding: 5 }}
                    onClick={handleSpanClick} // Add onClick handler here
                  >
                    <span>
                      {/* <CloudUploadIcon />  */}
                      Click here to upload
                    </span>
                    &nbsp;or Drag and Drop
                    <input
                      type="file"
                      accept=".docx, .xlsx, .xls, .pdf, .png, .jpg, .jpeg, .mp3, .wav, .ogg, .mp4, .m4a, .csv"
                      className="file-input"
                      onChange={handleFileChange}
                      ref={fileInputRef} // Attach ref to input element
                      style={{ display: "none" }} // Hide the input element
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <label
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 12,
                          marginBottom: 0,
                        }}
                      >
                        {selectedFile ? selectedFile.name : "No file chosen"}
                      </label>
                      {selectedFile ? (
                        <button
                          style={{
                            fontSize: 14,
                            borderRadius: 8,
                            border: "none",
                            backgroundColor: "#fff",
                            color: "red",
                          }}
                          onClick={resetfile}
                        >
                          &nbsp;&nbsp;
                          <i className="fa fa-times-circle"></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {selectedFile && (
                      <label style={{ color: "green", fontSize: 10 }}>
                        File uploaded successfully
                      </label>
                    )}

                    <label style={{ fontSize: "12px", textAlign: "center" }}>
                      ( .docx, .xlsx, .pdf, .png, .jpeg .mp3 )
                    </label>
                    <label style={{ color: "grey", fontSize: "12px" }}>
                      Note : Maximum allowed file size is 5 MB
                    </label>
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
              }}
            >
              <input
                type="checkbox"
                defaultValue={false}
                value={another}
                onClick={() => {
                  setAnother(!another);
                }}
              />
              <label className="bottomLabel">Create Another</label>

              <Button
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 100,
                  height: 40,
                  borderRadius: 8,
                  backgroundColor: "#5250E5",
                  color: "#fff",
                  border: "0px solid #5A88F1",
                  textAlign: "center",
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight:
                    issueType == "Task" || issueType == "Bug" ? 5 : 24,
                }}
                onClick={() => submit("BLG")}
                disabled={checkSubmit}
              >
                {checkSubmit ? (
                  <span
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
                  ></span>
                ) : (
                  "Create"
                )}
              </Button>
              {issueType == "Task" || issueType == "Bug" ? (
                <Button
                  onClick={() => submit("RFD")}
                  disabled={
                    checkSubmit || issueType == "Story" || issueType == "Epic"
                  }
                  style={{
                    fontSize: 14,
                    padding: 9,
                    height: 40,
                    borderRadius: 8,
                    border: "2px solid #5250E5",
                    backgroundColor: "#fff",
                    color: "#5250E5",
                    textAlign: "center",
                    textTransform: "none",
                    marginRight: 24,
                  }}
                >
                  {checkSubmit ? (
                    <span
                      className="spinner-border"
                      role="status"
                      aria-hidden="true"
                      style={{
                        color: "#fff",
                        width: "1.5rem",
                        height: "1.5rem",
                      }}
                    ></span>
                  ) : (
                    "Create & Move to refined"
                  )}
                </Button>
              ) : null}
            </div>
            {/* <Button className="CancelBtn" size="sm" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button className="Subbutton" size="sm" onClick={() => submit()}>
              Create
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={1000}
        onClose={handleClosesnackbar}
      >
        <Alert
          onClose={handleClosesnackbar}
          sx={{
            width: "100%",
            backgroundColor: "green !important",
            color: "#fff !important",
          }}
        >
          File uploaded sucessfully
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateKb;
