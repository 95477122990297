import { Input, Table, TextField, Card } from "@material-ui/core";

import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Slide, toast } from "react-toastify";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Avatar from "@mui/material/Avatar";
import Snackbar from "@mui/material/Snackbar";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import ReactImageFallback from "react-image-fallback";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import MaterialTable from "material-table";
import PatchedPagination from "../../../PatchedPagination";
import { makeStyles } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Button from "@mui/material/Button";

import Typography from "@material-ui/core/Typography";
// import "react-toastify/dist/ReactToastify.css";

import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import Toolbar, { modules, formats } from "../../KanbanBoard/Toolbar";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import Detailspagechange from "./Detailspagechange";

import { Container, CardBody, Col, Row } from "reactstrap";
import moment from "moment";
import "../../ITServices/RaiseATicket.css";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@material-ui/core/Box";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Pagination from "react-bootstrap/Pagination";
import { styled } from "@mui/material/styles";
import {
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import Unassigned from "../../../assets/images/avatar.png";
import CloseIcon from "@material-ui/icons/Close";

function Assignedchange({ }) {
  // const rolecode = JSON.parse(localStorage.getItem("Rolecode"));
  const clientid = JSON.parse(localStorage.getItem("clientid"));
  const Empdetails = JSON.parse(localStorage.getItem("Empdetails"));
  const [opendetails, setopendetails] = useState(false);
  const [changeassign, setchangeassign] = useState([]);
  const [messageopen, setmessageopen] = useState(false);
  const [view, setView] = useState("cardview");
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const [grpassign, setgrpassign] = useState([]);
  const [name, setname] = useState("");
  const [changeid, setchangeid] = useState("");
  const [GroupCode, setGroupCode] = useState("0");
  const [statusfilter, setstatusfilter] = useState("");
  const [cateoryff, Setcategoryff] = useState("");
  const [statuscard, setstatuscard] = useState("");
  const [impactcard, setimpactcard] = useState("");
  const [category, setcategory] = useState([]);
  const [additionalData, setAdditionalData] = useState("");
  const [riskcard, setriskcard] = useState("");
  const [open, setOpen] = useState(false);
  const [assignmentoption, setassignmentoption] = useState([]);
  const [Assignedtooption, setAssignedtooption] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [notes, setNotes] = useState("");
  const [DateF, SetDateF] = useState(null);
  const [inputValue, setInputValue] = useState("Ok Approve");
  const [page, setPage] = useState(1);

  const [itemsPerPage, setItemsPerPage] = useState(5);
  let [idtofind, setidtofind] = useState("all");
  const totalPages = Math.ceil(changeassign.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        style={{ backgroundColor: "green", color: "white" }}
        severity="success"
        {...props}
      />
    );
  });
  const { allImage } = useSelector((state) => state.user);
  useEffect(() => {
    axios
      .get("agilesaas_incident_category_master?ClientId=eq." + clientid)
      .then((response) => {
        setcategory(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get(
        "subscription_user_group_mapping?ClientId=eq." +
        clientid +
        "&UserId=eq." +
        Empdetails[0]?.ClientUserId
      )
      .then((response) => {
        setgrpassign(response.data);
        console.log(response.data[0].GroupDetails, "grpassign");

        let Groupcheck = response.data[0].GroupDetails.map(
          (group) => group.GroupId
        ).toString();

        console.log(Groupcheck, "grpcheck");
        axios
          .get(
            "agilesaas_change_details?ClientId=eq." +
            clientid +
            "&AssignmentGroup->>GroupCode=in.(" +
            Groupcheck +
            ")"
          )
          .then((response) => {
            let filteredDatatobeshown = response.data.filter((item) => {
              return (
                (item.AssignedTo &&
                  item.AssignedTo.id === Empdetails[0]?.ClientUserId) ||
                item.AssignedTo.id === ""
              );
            });

            setchangeassign(filteredDatatobeshown);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
    axios
      .get("subscription_user_group_mapping?ClientId=eq." + clientid)
      .then((response) => {
        let userGroupMappings = response.data;
        const resultArray = userGroupMappings.reduce(
          (acc, userGroupMapping) => {
            userGroupMapping.GroupDetails.forEach((groupDetail) => {
              const groupId = groupDetail.GroupId;
              const groupName = groupDetail.GroupName;

              // Check if the group already exists in the result array
              const existingGroupIndex = acc.findIndex(
                (group) => group.GroupId === groupId
              );

              if (existingGroupIndex !== -1) {
                // Add the user to the existing group
                acc[existingGroupIndex].users.push({
                  Firstname: userGroupMapping.UserDetails.Firstname,
                  Lastname: userGroupMapping.UserDetails.Lastname,
                  UserId: userGroupMapping.UserId,
                });
              } else {
                // Create a new group with the user
                acc.push({
                  GroupId: groupId,
                  GroupName: groupName,
                  users: [
                    {
                      Firstname: userGroupMapping.UserDetails.Firstname,
                      Lastname: userGroupMapping.UserDetails.Lastname,
                      UserId: userGroupMapping.UserId,
                    },
                  ],
                });
              }
            });

            return acc;
          },
          []
        );
        const assignmentooption = resultArray.map((e) => {
          return {
            label: e.GroupName,
            value: e.GroupId,
            users: e.users,
          };
        });
        setassignmentoption(assignmentooption);
      })
      .catch((error) => console.log(error));
  }, []);
  function refreshstates() {
    axios
      .get(
        "subscription_user_group_mapping?ClientId=eq." +
        clientid +
        "&UserId=eq." +
        Empdetails[0]?.ClientUserId
      )
      .then((response) => {
        setgrpassign(response.data);

        let Groupcheck = response.data[0].GroupDetails.map(
          (group) => group.GroupId
        ).toString();

        axios
          .get(
            "agilesaas_change_details?ClientId=eq." +
            clientid +
            "&AssignmentGroup->>GroupCode=in.(" +
            Groupcheck +
            ")"
          )
          .then((response) => {
            let filteredDatatobeshown = response.data.filter((item) => {
              return (
                (item.AssignedTo &&
                  item.AssignedTo.id === Empdetails[0]?.ClientUserId) ||
                item.AssignedTo.id === ""
              );
            });

            setchangeassign(filteredDatatobeshown);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const updatechangeid = (id, datatoupdate) => {
    // Find the index of the problem with the specified id
    const index = changeassign.findIndex((change) => change.ChangeId === id);

    // If the problem with the specified id is found
    if (index !== -1) {
      // Update the state with the new data
      setchangeassign((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], ...datatoupdate };
        // setchangedetails(newData.filter((x) => x.ChangeId === id));

        return newData;
      });
      // xx
      setmessageopen(true);
      console.log(`Problem with id ${id} updated successfully.`);
      return true;
    } else {
      console.log(`Problem with id ${id} not found.`);

      return false;
    }
  };

  const handleChangeItemsPerPage = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setPage(1); // Reset to the first page when changing items per page
  };
  const handleClickOpen = (e) => {
    setAdditionalData(e);
    setOpen(true);
  };

  let stateoptions = [
    { value: "New", label: "New" },
    { value: "Approval", label: "Approval" },
    { value: "Scheduled", label: "Scheduled" },
    { value: "Implementation", label: "Implementation" },
    { value: "Completed", label: "Completed" },
    { value: "Closed", label: "Closed" },
    { value: "Cancelled", label: "Cancelled" },
  ];
  let dateoption = [
    {
      value: moment().subtract(1, "months").startOf("month"),
      label: "OnemonthBefore",
    },
    {
      value: moment().subtract(1, "days").startOf("day"),
      label: "Yesterday",
    },

    { value: moment(), label: "Today" },
  ];
  let categoryoption =
    category == ""
      ? ""
      : category?.map((e) => {
        return {
          value: e.CategoryCode,
          label: e.CategoryName,
          id: e.IncidentCategoryId,
        };
      });

  const customStylesd = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const uniqueNames = [];
  const nameoptions =
    changeassign == ""
      ? ""
      : changeassign &&
      changeassign?.reduce((acc, item) => {
        const fullName = `${item.RequestedBy.FN} ${item.RequestedBy.LN}`;
        if (!uniqueNames.includes(fullName)) {
          uniqueNames.push(fullName);
          acc.push({ value: fullName, label: fullName });
        }
        return acc;
      }, []);

  function handlestatusfilter(item) {
    setstatusfilter(item.label);
  }
  function categoryfilter(item) {
    Setcategoryff(item.label);
  }
  function handlenamefilter(item) {
    setname(item.label);
  }
  function datefilter(item) {
    SetDateF(item.value);
  }
  const handleClickCard = (index, e) => {
    setchangeid(e.ChangeId);
    setopendetails(true);
    setActiveCardIndex(index);
  };
  const lineStyle = {
    borderTop: "1px solid rgb(0, 0, 0 ,20%) ", // Customize the color and style
    width: "100%",
    margin: "0 0",
    overflow: "none",
  };
  const steps = [
    "New",
    "Approved",
    "Scheduled",
    "Implementation",
    "Completed",
    "Closed",
    "Cancelled",
  ];
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#33ff33",
        color: "33ff33",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#00cc00",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#33ff33",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#00cc00",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
    "& .QontoStepIcon-ErrorIcon": {
      color: "#F76666",
      // color: '#00cc00',
      zIndex: 1,
      fontSize: 18,
    },
  }));
  function handleassignme(e) {
    let patchdata = {
      AssignedTo: {
        Name: Empdetails[0].UserFirstname + "" + Empdetails[0].UserLastname,
        id: Empdetails[0].ClientUserId,
      },
    };

    axios
      .patch("agilesaas_change_details?ChangeId=eq." + e.ChangeId, patchdata)
      .then((response) => {
        updatechangeid(e.ChangeId, patchdata);
        // alert("patched")
        refreshstates();
        toast.success("Assignee changed as you ");
      })
      .catch((error) => console.log(error));
    refreshstates();
  }
  function QontoStepIcon(props) {
    const { active, completed, className, Acknowledgement, index, Resolved } =
      props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <CheckCircleIcon
            className={
              (Acknowledgement === "N" && index === 1) ||
                (Resolved === "N" && index === 4)
                ? "QontoStepIcon-ErrorIcon"
                : "QontoStepIcon-completedIcon"
            }
          />
        ) : (
          <RadioButtonCheckedIcon className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
  const impactchange = (E, e, i) => {
    let imp = E.target.value;

    setimpactcard(imp);

    let impactchanged = {
      Impact: imp,
    };
    axios
      .patch(
        "agilesaas_change_details?ChangeId=eq." + e.ChangeId,
        impactchanged
      )
      .then((response) => {
        refreshstates();
        updatechangeid(e.ChangeId, impactchanged);
        console.log("impactchange");
        // setRefresh(true);
      })
      .catch((error) => console.log(error));
    refreshstates();

    // setRefresh(true);
  };
  const riskchange = (E, e, i) => {
    let risk = E.target.value;

    setriskcard(risk);

    let riskchanges = {
      Risk: risk,
    };
    axios
      .patch("agilesaas_change_details?ChangeId=eq." + e.ChangeId, riskchanges)
      .then((response) => {
        updatechangeid(e.ChangeId, riskchanges);
        refreshstates();
        console.log("riskchanges");
        // setRefresh(true);
      })
      .catch((error) => console.log(error));
    refreshstates();
    // setRefresh(true);
  };
  const statuschange = (E, e, i) => {
    let states = E.target.value;

    setstatuscard(states);
    if (states == "Approval") {
      let waitapprove = {
        IsApproved: "N",
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + e.ChangeId,
          waitapprove
        )
        .then((response) => {
          console.log("approval");
          updatechangeid(e.ChangeId, waitapprove);
          toast.warning(`Waiting for Approval`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          });

          refreshstates();
          // setRefresh(true);
        })
        .catch((error) => console.log(error));
      refreshstates();
    } else if (states == " Scheduled") {
      let patchdatapro = {
        ChangeStages: {
          StateCode: states,
          StateName: states,
        },
        ChangeStartTime: moment().format("DD-MM-YYYY"),
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + e.ChangeId,
          patchdatapro
        )
        .then((response) => {
          updatechangeid(e.ChangeId, patchdatapro);
          console.log("stage1");
          refreshstates();
          // setRefresh(true);
        })
        .catch((error) => console.log(error));
      refreshstates();
    } else if (states == " Completed") {
      let patchdatapro1 = {
        ChangeStages: {
          StateCode: states,
          StateName: states,
        },
        ChangeEndTime: moment().format("DD-MM-YYYY"),
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + e.ChangeId,
          patchdatapro1
        )
        .then((response) => {
          updatechangeid(e.ChangeId, patchdatapro1);
          console.log("Stages2");
          // setRefresh(true);
          refreshstates();
        })
        .catch((error) => console.log(error));
      refreshstates();
    } else {
      let patchdatapro2 = {
        ChangeStages: {
          StateCode: states,
          StateName: states,
        },
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + e.ChangeId,
          patchdatapro2
        )
        .then((response) => {
          updatechangeid(e.ChangeId, patchdatapro2);
          console.log("Stages3");
          refreshstates();
          // setRefresh(true);
        })
        .catch((error) => console.log(error));
      refreshstates();
    }
    // setRefresh(true);
  };
  let assignedchange = changeassign?.filter(
    (E) =>
      E.AssignedTo.id == Empdetails[0].ClientUserId || E.AssignedTo.id == ""
  );
  const check =
    statusfilter != ""
      ? assignedchange?.filter((E) => E.ChangeStages.StateCode == statusfilter)
      : cateoryff != ""
        ? assignedchange?.filter((E) => E.Category.CategoryName == cateoryff)
        : name != ""
          ? assignedchange?.filter(
            (E) => E.RequestedBy.FN + " " + E.RequestedBy.LN == name
          )
          : DateF != null
            ? assignedchange?.filter((e) => e.CreatedDateTime < DateF)
            : DateF != null
              ? assignedchange?.filter((e) => e.CreatedDateTime == DateF)
              : name != "" && statusfilter != "" && cateoryff != "" && DateF != null
                ? assignedchange?.filter(
                  (E) =>
                    E.RequestedBy.FN + " " + E.RequestedBy.LN == name &&
                    E.CurrentState.StateName == statusfilter &&
                    E.CategoryCode.CategoryName == cateoryff &&
                    E.CreatedDateTime < DateF &&
                    E.CreatedDateTime == DateF
                )
                : idtofind != "all"
                  ? assignedchange?.filter((e) => e.ChangeRefNumber == idtofind)
                  : assignedchange;

  let temp = {};
  temp =
    check == null
      ? ""
      : check.map((e, i) => {
        return {
          ...e,
          aprroer: e.IsApproved,
          Sno: e.ChangeId,
          title: e.ShortDescription,
          longdis: e.LongDescription,
          refnumber: e.ChangeRefNumber,

          Impactname: e?.Impact,
          Assignedgroup: e.AssignmentGroup?.GroupName,
          CurrentState1:
            temp != ""
              ? e.ChangeStages.StateCode == "New"
                ? "New"
                : e.ChangeStages.StateCode == "Approved"
                  ? "Approved"
                  : e.ChangeStages.StateCode == "Scheduled"
                    ? "Scheduled"
                    : e.ChangeStages.StateCode == "Implementation"
                      ? "Implementation"
                      : e.ChangeStages.StateCode == "Completed"
                        ? "Completed"
                        : e.ChangeStages.StateCode == "Closed"
                          ? "Closed"
                          : e.ChangeStages.StateCode == "Cancelled"
                            ? "Cancelled"
                            : "New"
              : "New",

          CreatedByDetails: e.RequestedBy.FN + " " + e.RequestedBy.LN,
          AssignedToDetails: e.AssignedTo.Name,
          Assignedtoid: e.AssignedTo.id,

          CategoryCode: e.Category.CategoryName,
          SubCategoryCode: e.Subcategory.SubCategoryName,

          avatarimage: (
            <Avatar
              alt=""
              className="CardAvartor"
              src={
                allImage?.length > 0 && allImage?.filter(
                  (x) => x.teammemberid == e.RequestedBy.id
                )?.[0]?.image != undefined ? allImage?.filter(
                  (x) => x.teammemberid == e.RequestedBy.id
                )?.[0]?.image : Unassigned
              }
              sx={{ width: 35, height: 35 }}
            />
          ),

          Assignedto: (
            <div className="d-flex justify-space-between">
              {/* <span
                className="mdi mdi-checkbox-blank-circle ml-3"
                style={{ color: "#45DD81", fontSize: "9px" }}
              ></span> */}
              <select
                className="fontdesign ssls ml-2 mb-2"
                defaultValue={e?.Impact}
                maxWidth="40px"
                // value={impacts}
                // disabled={e.CurrentState.StateCode == "Resolved"}
                // onChange={(e, i) => impactchange(e, data)}
                title="Impact"
              >
                <option value="High">Kannan sk</option>
                <option value="Medium">Yozy Admin</option>
              </select>
            </div>
          ),

          riskdrop: (
            <div className="d-flex justify-space-between">
              {/* <span
                // className="mdi mdi-checkbox-blank-circle ml-3"
                // style={{ color: "#45DD81", fontSize: "9px" }}
              ></span> */}
              <select
                className="fontdesign ssls ml-2 mb-2"
                maxWidth="40px"
                key={i}
                defaultValue={e?.Risk}
                // value={riskcard[i]}
                disabled={e.ChangeStages.StateCode == "Completed"}
                onChange={(E, i) => {
                  riskchange(E, e, i);
                }}
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
          ),
          Impactdrop: (
            <div className="d-flex justify-space-between">
              <select
                className="fontdesign ssls ml-2 mb-2"
                key={i}
                defaultValue={e?.Impact}
                maxWidth="40px"
                disabled={e.ChangeStages.StateCode == "Completed"}
                onChange={(E, i) => {
                  impactchange(E, e, i);
                }}
                title="Impact"
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
          ),
          statedrop: (
            <div className="d-flex justify-space-between">
              <select
                className="fontdesign ssls ml-2 mb-2"
                key={i}
                defaultValue={e.ChangeStages.StateCode}
                // value={statuscard[i]}
                maxWidth="40px"
                disabled={e.ChangeStages.StateCode == "Completed"}
                onChange={(E, i) => {
                  statuschange(E, e, i);
                }}
                title="Impact"
              >
                <option value="New">New</option>
                <option value="Approval">Approval</option>
                <option value="Scheduled">Scheduled</option>
                <option value="Implementation">Implementation</option>
                <option value="Completed">Completed</option>
                <option value="Closed">Closed</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>
          ),

          CreatedDate: moment(e.CreatedDateTime).format(
            "DD-MMM-YYYY,HH:mm A"
          ),
          notesdrop: (
            <button
              variant="outline-secondary"
              size="sm"
              fontSize="small"
              // style={{ fontSize: "17px",alignContent:"start" }}
              className="btn btn-text-dark  btn-sm fontdesign ml-3"
              // style={{ textAlign: "left" }}
              title="Notes"
              onClick={() => {
                // dispatch(INDETAILSUPDATE(e.IncidentId));
                setopendetails(true);
              }}
            >
              <SpeakerNotesOutlinedIcon
                className="Card_Button_Color_Approve  "
                style={{
                  color: "black",
                  fontSize: "16px",
                }}
              ></SpeakerNotesOutlinedIcon>{" "}
              <span className="ml-1"> Notes</span>
            </button>
          ),
        };
      });
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      width: 230,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const Columns = [
    { title: "S.No", field: "Sno", defaultSort: "desc" },
    { title: "Title", field: "title" },
    { title: "Refnumber", field: "refnumber" },

    { title: "Assignment group", field: "Assignedgroup" },

    { title: "Category", field: "CategoryCode" },
    { title: "SubCategory", field: "SubCategoryCode" },

    { title: "Impact", field: "Impact" },
    {
      title: "State", field: "CurrentState1",
      render: (e) =>
        <label className={
          e.CurrentState1 === "New" ? 'text-danger' :
            e.CurrentState1 === "Approved" ? 'text-info1' :
              e.CurrentState1 === "Scheduled" ? 'text-warning' :
                e.CurrentState1 === "Implementation" ? 'text-warning' :
                  e.CurrentState1 === "Completed" ? 'text-success' :
                    e.CurrentState1 === "Closed" ? 'text-success' :
                      e.CurrentState1 === "Cancelled" ? 'text-danger' :
                        ''
        }>
          <strong>
            {e.CurrentState1 === "New" ? "New" :
              e.CurrentState1 === "Approved" ? "Approved" :
                e.CurrentState1 === "Scheduled" ? "Scheduled" :
                  e.CurrentState1 === "Implementation" ? "Implementation" :
                    e.CurrentState1 === "Completed" ? "Completed" :
                      e.CurrentState1 === "Closed" ? "Closed" :
                        e.CurrentState1 === "Cancelled" ? "Cancelled" :
                          ""}
          </strong>

        </label>
      ,
    },
  ];

  const useStyles = makeStyles({
    datePickerInput: {
      marginTop: "2%",
      width: "100%",
      border: "none",
      "&:MuiInputBase-root": {
        border: "none",
        padding: "2%",
      }, // Remove the default border
      "&:focus": {
        outline: "none", // Optionally, remove the focus outline as well
      },
    },
    root: {
      "& .MuiBox-root": {
        paddingTop: 2,
        margin: 1,
      },
      "& .MuiAppBar-root": {
        // boxShadow: "none", // Remove the box shadow from the tab header
        paddingLeft: 0,
        margin: 0,
      },
      "& .MuiTabs-root": {
        display: "flex", // Use flex layout
        justifyContent: "flex-start",
      },
    },
  });
  const classes = useStyles();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleButtonClick = (selectedView) => {
    // Set the view state to the clicked button
    setView(selectedView);
  };
  function clear() {
    setstatusfilter("");
    setname("");
    SetDateF(null);
    setidtofind("all");
    Setcategoryff("");
  }
  function startchange(e) {
    const lastUpdateNote = e.ChangeNotes[e.ChangeNotes.length - 1];
    console.log("Last updated change note:", lastUpdateNote.NoteSno + 1);
    let patchstart = {
      ChangeStartTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      ChangeStages: {
        StateCode: "Implementation",
        StateName: "Implementation",
      },
      ChangeNotes: [
        ...e.ChangeNotes,
        {
          NoteSno: lastUpdateNote.NoteSno + 1,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: Empdetails[0].ClientUserId,
          NoteUpdatedByFN:
            Empdetails[0].UserFirstname + " " + Empdetails[0].UserLastname,
          NotesDetails:
            "Change Started on " + moment().format("YYYY-MM-DD HH:mm:ss"),
        },
      ],
    };

    axios
      .patch("agilesaas_change_details?ChangeId=eq." + e.ChangeId, patchstart)
      .then((response) => {
        updatechangeid(e.ChangeId, patchstart);

        // alert("patched")
        toast.success("Change Started");
        refreshstates();
      })
      .catch((error) => console.log(error));
    refreshstates();
  }
  // function Endchange(e) {
  //   const lastUpdateNote = e.ChangeNotes[e.ChangeNotes?.length - 1];
  //   let patchstart = {
  //     ChangeStartTime: moment().format("YYYY-MM-DD HH:mm:ss"),
  //     ChangeStages: {
  //       StateCode: "Completed",
  //       StateName: "Completed",
  //     },
  //     "IsSuccess": "Y",

  //     "ChangeNotes": [
  //       ...e.ChangeNotes,
  //       {
  //           "NoteSno": lastUpdateNote.NoteSno +1,
  //           "DateTime":  moment().format("YYYY-MM-DD HH:mm:ss"),
  //           NoteUpdatedBy: Empdetails[0].ClientUserId,
  //           NoteUpdatedByFN: Empdetails[0].UserFirstname + " " + Empdetails[0].UserLastname,
  //           "NotesDetails": "Change Ended on "+ moment().format("YYYY-MM-DD HH:mm:ss")
  //       },
  //     ],
  //     "Sucessnotes": [{
  //       Key:notes,
  //       NoteUpdatedByFN: Empdetails[0].UserFirstname + " " + Empdetails[0].UserLastname,

  //     }]
  //   };

  //   axios
  //     .patch("agilesaas_change_details?ChangeId=eq." + e.ChangeId, patchstart)
  //     .then((response) => {
  //       // alert("patched")
  //       toast.success("Change Completed");
  //       refreshstates();
  //     })
  //     .catch((error) => console.log(error));
  // }
  const handleConfirm = (e) => {
    // Update change and close dialog
    const lastUpdateNote = e.ChangeNotes[e.ChangeNotes.length - 1];
    const patchstart = {
      ChangeEndTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      ChangeStages: {
        StateCode: "Completed",
        StateName: "Completed",
      },
      IsSuccess: "Y",

      ChangeNotes: [
        ...e.ChangeNotes,
        {
          NoteSno: lastUpdateNote.NoteSno + 1,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: Empdetails[0].ClientUserId,
          NoteUpdatedByFN:
            Empdetails[0].UserFirstname + " " + Empdetails[0].UserLastname,
          NotesDetails:
            "Change Ended on " + moment().format("YYYY-MM-DD HH:mm:ss"),
        },
      ],
      Sucessnotes: [
        {
          Key: notes,
          NoteUpdatedByFN:
            Empdetails[0].UserFirstname + " " + Empdetails[0].UserLastname,
        },
      ],
    };
    axios
      .patch("agilesaas_change_details?ChangeId=eq." + e.ChangeId, patchstart)
      .then((response) => {
        updatechangeid(e.ChangeId, patchstart);
        toast.success("Change Completed");
        refreshstates();
        setOpen(false); // Close dialog after successful update
      })
      .catch((error) => console.log(error));
    refreshstates();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className=" mt-1 "
      style={{ backgroundColor: "#F5F7FB", minHeight: "80%" }}
    >
      <Row className="d-flex justify-content-end mt-2 mr-1">
        <Button
          style={{
            fontSize: 14,
            padding: 9,
            width: 130,
            height: 40,
            borderRadius: 8,
            marginRight: 14,
            border: "1px solid #5A88F1",
            backgroundColor: "#fff",
            color: view === "cardview" ? "#5250E5" : "black",
            textAlign: "center",
            textTransform: "none",
          }}
          onClick={() => handleButtonClick("cardview")}
        >
          <ComputerOutlinedIcon /> Card View
        </Button>
        <Button
          style={{
            fontSize: 14,
            padding: 9,
            width: 130,
            height: 40,
            borderRadius: 8,
            marginRight: 14,
            border: "1px solid #5A88F1",
            backgroundColor: "#fff",
            color: view === "tabelview" ? "#5250E5" : "black",
            textAlign: "center",
            textTransform: "none",
          }}
          onClick={() => handleButtonClick("tabelview")}
        >
          <TableRowsOutlinedIcon /> Table view
        </Button>
      </Row>
      <div className="ml-4 mt-2">
        {assignmentoption?.length === 1 ? (
          <label style={{ fontWeight: "600" }}>
            Belonging To : {assignmentoption[0].label}
          </label>
        ) : (
          <label style={{ fontWeight: "600" }}>
            Belonging To :{" "}
            <select
              onChange={(f) => {
                // gooto
                setGroupCode(f.target.value);
              }}
            >
              {assignmentoption?.map((e) => (
                <option value={e.value}>{e.label}</option>
              ))}
              <option vapostlue="0">all</option>
            </select>
          </label>
        )}
      </div>

      <div className="row">
        <div className="col-9">
          {view === "cardview" ? (
            changeassign.length == 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                <SentimentDissatisfiedOutlinedIcon
                  style={{ fontSize: "5rem" }}
                />
                <h2>No Changes Found</h2>
                <p>
                  We couldn't find any change request matching your filter, or
                  something went wrong.
                </p>
                <button
                  style={{
                    width: 100,
                    height: 36,
                    borderRadius: 5,
                    background: "#5250E5",
                    color: "#fff",
                  }}
                  onClick={refreshstates}
                >
                  Try Again
                </button>
              </div>
            ) : (
              temp
                .slice(startIndex, endIndex)
                .filter((e) =>
                  e.CurrentState1 != "Closed" && e.CurrentState1 != "Cancelled" &&
                    GroupCode === "0"
                    ? e.AssignmentGroup.GroupCode !== null
                    : e.AssignmentGroup.GroupCode === GroupCode &&
                    e.Assignedtoid == Empdetails[0].ClientUserId
                )
                .map((e, item) => {
                  const isActiveCard = activeCardIndex === item;
                  return (
                    <div className=" ml-2 mt-2 ">
                      <Card
                        className="mt-3"
                        style={{
                          background: "#F9FAFB",
                          border: "1px solid rgb(0, 0, 0 ,20%)",
                          borderLeft:
                            activeCardIndex === item
                              ? "3px solid blue"
                              : "1px solid rgb(0, 0, 0 ,20%)",
                        }}
                      >
                        <Row>
                          <Col lg={12} md={12} xl={12} sm={12}>
                            {/* <Stepper
                              alternativeLabel
                              activeStep={
                                e.CurrentState1 === "Completed"
                                  ? 5
                                  : steps.indexOf(e.CurrentState1)
                              }
                              connector={<QontoConnector />}
                              className="mt-3 d-flex justify-content-start"
                            >
                              {steps.map((label, index) => (
                                <Step key={label}>
                                  <StepLabel
                                    StepIconComponent={(props) =>
                                      QontoStepIcon({
                                        ...props,
                                        Acknowledgement: e.Acknowledgement,
                                        index: index,
                                        Resolved: e.Resoloved,
                                      })
                                    }
                                  >
     
                                    <span
                                      style={{
                                        color:
                                          (e.Approved === "N" && index === 1) ||
                                          (e.Resoloved === "N" && index === 4) ||
                                        
              (e.CurrentState1 === "Scheduled" && index === 6) ||
              (e.CurrentState1 === "Cancelled" && index === steps.length - 1)
                ? "red"
                : "inherit",
                                          
                                      }}
                                    >
                                      {label}
                                    </span>
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper> */}
                            <Stepper
                              alternativeLabel
                              activeStep={
                                e.IsApproved === "N"
                                  ? steps.indexOf("Approved") - 0.5 // Stop between "New" and "Approved" when Approved is "N"
                                  : e.CurrentState1 === "Completed"
                                    ? 5
                                    : steps.indexOf(e.CurrentState1)
                              }
                              connector={<QontoConnector />}
                              className="mt-3 d-flex justify-content-start"
                            >
                              {steps.map((label, index) => (
                                <Step key={label}>
                                  {index == 1 && e.IsApproved === "N" ? (
                                    <label
                                      className="mt-3"
                                      style={{
                                        position: "absolute",
                                        fontSize: "12px",
                                        bottom: 7,
                                        left: "calc(-50% + 16px)",
                                        right: "calc(50% + 16px)",
                                        top: 10,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color:
                                          (e.IsApproved === "N" &&
                                            index === 1) ||
                                            (e.Resoloved === "N" && index === 4)
                                            ? "#F76666"
                                            : (e.IsApproved === "N" &&
                                              index === 1) ||
                                              (e.Resoloved === "N" &&
                                                index === 4)
                                              ? "#00e600"
                                              : "inherit",
                                      }}
                                    >
                                      {index == 1 && e.IsApproved === "N" ? (
                                        <span className="text-danger">
                                          Waiting For Approval
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                  ) : null}
                                  <StepLabel
                                    StepIconComponent={(props) =>
                                      QontoStepIcon({
                                        ...props,
                                        Acknowledgement: e.Acknowledgement,
                                        index: index,
                                        Resolved: e.Resoloved,
                                      })
                                    }
                                  >
                                    <span
                                      style={{
                                        color:
                                          (e.Approved === "N" && index === 1) ||
                                            (e.Resoloved === "N" &&
                                              index === 4) ||
                                            (e.CurrentState1 === "Scheduled" &&
                                              index === 6) ||
                                            (e.CurrentState1 === "Cancelled" &&
                                              index === steps.length - 1)
                                            ? "red"
                                            : "inherit",
                                      }}
                                    >
                                      {label}
                                    </span>
                                  </StepLabel>
                                  {/* {console.log(e.Approved,"e.approved",e)} */}
                                  {/* Add a label between "New" and "Approved" when e.Approved is "N" */}
                                  {/* {index ==  1 && e.IsApproved === "N" && (
                                        <Step>
                                          <StepLabel className="text-danger">
                                         
                                              Waiting For Approval
                                  
                                          </StepLabel>
                                        </Step>
                                      )} */}
                                </Step>
                              ))}
                            </Stepper>
                          </Col>
                        </Row>
                        {e.CurrentState1 == "Scheduled" &&
                          e.CurrentState1 != "Implementation" ? (
                          <Button
                            onClick={() => startchange(e)}
                            style={{
                              fontSize: 14,
                              padding: 9,
                              width: 100,
                              height: 40,
                              borderRadius: 8,
                              marginRight: 14,
                              border: "none",
                              outline: "none",
                              marginLeft: "30%",

                              backgroundColor: "#fff",
                              color: "#5250E5",
                              textAlign: "right",
                              textTransform: "none",
                            }}
                          >
                            {" "}
                            StartChange{" "}
                          </Button>
                        ) : null}

                        {e.CurrentState1 != "Closed" &&
                          e.CurrentState1 != "Completed" &&
                          e.CurrentState1 == "Implementation" ? (
                          <Button
                            onClick={() => handleClickOpen(e)}
                            style={{
                              fontSize: 14,
                              padding: 9,
                              width: 100,
                              height: 50,
                              borderRadius: 8,
                              marginRight: 14,
                              border: "none",
                              outline: "none",
                              marginLeft: "35%",

                              backgroundColor: "#fff",
                              color: "#5250E5",
                              textAlign: "right",
                              textTransform: "none",
                            }}
                          >
                            {" "}
                            Endchange{" "}
                          </Button>
                        ) : null}

                        <Row style={{ marginTop: "-8px" }}>
                          <Col
                            xl={1}
                            lg={2}
                            md={2}
                            sm={2}
                            className="d-flex jsutify-content-center"
                            onClick={() => {
                              handleClickCard(item, e);
                            }}
                          >
                            {e.avatarimage}
                          </Col>

                          <Col
                            lg={8}
                            md={8}
                            sm={8}
                            // lg={9} md={9} sm={9}
                            // className="d-flex jsutify-content-center"
                            onClick={() => handleClickCard(item, e)}
                          >
                            <div onClick={() => handleClickCard(item, e)}>
                              <label
                                className="fontdesign  mt-2"
                                style={{ fontSize: "12px" }}
                              >
                                {e?.CurrentState}
                              </label>

                              <h5
                                className="text-lg  ml-4 "
                                style={{ fontWeight: "600" }}
                              >
                                #{e.refnumber}:{e.title}
                              </h5>

                              <Row
                                className="fontdesign mt-1 ml-4"
                                style={{ fontSize: "12px", fontWeight: "600" }}
                              >
                                <label>
                                  Created :{" "}
                                  {moment(e.CreatedDate).format("DD-MMM-YYYY")}
                                </label>
                                <span className="ml-1">|</span>
                                <label className="ml-1">
                                  {" "}
                                  Created By :{e.CreatedByDetails}
                                </label>{" "}
                                <span className="ml-1">|</span>
                                <label className="ml-1">
                                  {" "}
                                  Category :{e.CategoryCode}
                                </label>
                                <span className="ml-1">|</span>
                                <label className="ml-1">
                                  {" "}
                                  Subcategory :
                                  <span style={{ fontWeight: "600" }}>
                                    {e.SubCategoryCode}
                                  </span>
                                </label>
                              </Row>
                              <div className="ml-2">
                                <span
                                  className="fontdesign  d-flex justify-content-space-between"
                                  style={{ fontSize: "12px" }}
                                >
                                  <label className="ml-3 mt-1">
                                    {/* <label>
                                    Created :{" "}
                                    <span style={{ fontWeight: "600" }}>
                                      {" "}
                                      {moment(e.CreatedDate).format(
                                        "DD-MMM-YYYY"
                                      )}
                                    </span>
                                  </label> */}
                                    {/* <CircleIcon
                                    className="ml-2"
                                    style={{ fontSize: "5px" }}
                                  ></CircleIcon> */}

                                    <label>
                                      {" "}
                                      Assigned Group :
                                      <span style={{ fontWeight: "600" }}>
                                        {e.Assignedgroup}
                                      </span>
                                    </label>
                                    {e.AssignedToDetails == "" ? (
                                      <button
                                        variant="outline-secondary"
                                        size="sm"
                                        fontSize="small"
                                        style={{ color: "red" }}
                                        className="ml-3"
                                        title="Notes"
                                        onClick={() => handleassignme(e)}
                                      >
                                        Assign to me
                                      </button>
                                    ) : (
                                      <>
                                        <CircleIcon
                                          className="ml-2"
                                          style={{ fontSize: "5px" }}
                                        ></CircleIcon>

                                        <label className="ml-2">
                                          Assigned To :
                                          <span style={{ fontWeight: "600" }}>
                                            {" "}
                                            {e.AssignedToDetails}{" "}
                                          </span>
                                        </label>
                                      </>
                                    )}

                                    {/* {e.AssignedToDetails == "" ? null : (
                                      <>
                                        <CircleIcon
                                          className="ml-2"
                                          style={{ fontSize: "5px" }}
                                        ></CircleIcon>

                                        <label className="ml-2">
                                          Assigned To :
                                          <span style={{ fontWeight: "600" }}>
                                            {" "}
                                            {e.AssignedToDetails}{" "}
                                          </span>
                                        </label>
                                      </>
                                    )} */}
                                    {/* <CircleIcon
                                      className="ml-2"
                                      style={{ fontSize: "5px" }}
                                    ></CircleIcon> */}
                                  </label>
                                </span>
                              </div>
                            </div>
                          </Col>

                          <Col lg={3} md={3} sm={3}>
                            {/* <div className="mt-4"> */}
                            <Row className="mt-3">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    width: "100px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Impact:
                                </label>
                                {e.Impactdrop}
                              </div>
                            </Row>
                            <Row className="mt-1">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    width: "100px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Risk:
                                </label>
                                {e.riskdrop}
                              </div>
                            </Row>
                            <Row className="mt-1">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    width: "100px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  State:
                                </label>
                                {e.statedrop}
                              </div>
                            </Row>
                            {/* <Row className="mt-1">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    width: "100px",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Notes:
                                </label>
                                {e.notesdrop}
                              </div>
                            </Row> */}

                            {/* <Row className="mt-1">
        <strong>Impact: </strong> {e.Impactdrop}
    </Row>
    <Row className="mt-1">
        <strong>Risk: </strong>  {e.riskdrop}
    </Row>
    <Row className="mt-1">
        <strong>State: </strong>    {e.statedrop}
    </Row>
    
    <Row className="mt-1">
    {e.notesdrop}
    </Row> */}
                            {/* <Row>
  <Col>
    <strong style={{ fontSize: "12px", marginRight: "5px" }}>
      Impact:
    </strong>
  </Col>
  <Col className="align-items-center">
    {e.Impactdrop}
  </Col>
</Row> */}

                            {/* <Row>
                                <Col>
                                  <strong style={{ fontSize: "12px" }}>
                                    Impact:
                                  </strong>
                                </Col>
                                <Col className=" align-items-center">
                                  {e.Impactdrop}
                                </Col>
                              </Row> */}

                            {/* <Row>
                              <Col>
                                <strong style={{ fontSize: "12px" }}>
                                  Impact:
                                </strong>
                              </Col>
                              <Col className=" align-items-center">
                                {e.Impactdrop}
                              </Col>
                            </Row> */}
                            {/* </div> */}
                            {/* 
                            <div className="mt-1">
                              <Row>
                                <Col>
                                  <strong style={{ fontSize: "12px" }}>
                                    Risk:
                                  </strong>
                                </Col>
                                <Col className="align-items-center">
                                  {e.riskdrop}
                                </Col>
                              </Row>
                            </div>

                            <div className="mt-1">
                              <Row>
                                <Col>
                                  <strong style={{ fontSize: "12px" }}>
                                    State:
                                  </strong>
                                </Col>
                                <Col className=" align-items-center">
                                  {e.statedrop}
                                </Col>
                              </Row>
                            </div>

                            <div className="mt-1">
                              <Row>
                                <Col>{e.notesdrop}</Col>
                              </Row>
                            </div> */}
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  );
                })
            )
          ) : null}
          {view == "cardview" && changeassign.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
                marginTop: 10,
              }}
            >
              <Pagination className="probpagination" style={{ color: "#000" }}>
                <Pagination.First
                  onClick={() => handleChangePage(1)}
                  disabled={page === 1}
                />
                <Pagination.Prev
                  onClick={() => handleChangePage(page - 1)}
                  disabled={page === 1}
                />

                {Array.from({ length: totalPages }, (_, i) => {
                  if (totalPages <= 7 || i + 1 === 1 || i + 1 === totalPages) {
                    return (
                      <Pagination.Item
                        key={i + 1}
                        active={i + 1 === page}
                        onClick={() => handleChangePage(i + 1)}
                      >
                        <span>{i + 1}</span>
                      </Pagination.Item>
                    );
                  } else if (
                    (i + 1 >= page - 1 && i + 1 <= page + 1) ||
                    (i + 1 === page - 2 && i + 1 > 2) ||
                    (i + 1 === page + 2 && i + 1 < totalPages - 1)
                  ) {
                    return (
                      <Pagination.Item
                        key={i + 1}
                        active={i + 1 === page}
                        onClick={() => handleChangePage(i + 1)}
                      >
                        <span>{i + 1}</span>
                      </Pagination.Item>
                    );
                  } else if (
                    (i + 1 === 2 && page > 4) ||
                    (i + 1 === totalPages - 1 && page < totalPages - 3)
                  ) {
                    return <Pagination.Ellipsis key={i + 1} disabled />;
                  }

                  return null;
                })}

                <Pagination.Next
                  onClick={() => handleChangePage(page + 1)}
                  disabled={page === totalPages}
                />
                <Pagination.Last
                  onClick={() => handleChangePage(totalPages)}
                  disabled={page === totalPages}
                />
              </Pagination>

              <select
                className="form-select mb-3 p-1 "
                value={itemsPerPage}
                onChange={handleChangeItemsPerPage}
              >
                <option value={5}>5 items per page</option>
                <option value={10}>10 items per page</option>
                <option value={25}>25 items per page</option>
              </select>
            </div>
          ) : null}
        </div>
        <div className="col-3 mt-2 ">
          {" "}
          {view == "cardview" ? (
            <div className="mt-2">
              <Card
                className="shadow-sm "
                style={{
                  height: "700px",
                  width: "260px",
                  border: "1px solid rgb(0, 0, 0 ,20%) ",
                  zIndex: "-1",
                  alignItems: "center",
                }}
              >
                <span className="mt-2 ml-3" style={{ fontWeight: "bold" }}>
                  Filters
                  <Button
                    onClick={clear}
                    style={{
                      fontSize: 14,
                      padding: 9,
                      width: 100,
                      height: 40,
                      borderRadius: 8,
                      marginRight: 14,
                      border: "none",
                      outline: "none",
                      marginLeft: "80px",

                      backgroundColor: "#fff",
                      color: "#5250E5",
                      textAlign: "right",
                      textTransform: "none",
                    }}
                  >
                    {" "}
                    Clear{" "}
                  </Button>
                </span>

                <div style={{ alignItems: "center" }}>
                  <label
                    className="mt-3 ml-5 fontdesign"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Refnumber
                  </label>

                  <input
                    placeholder="Change Refnumber"
                    style={{ width: "70%" }}
                    styles={customStylesd}
                    className="SprintFormFields ml-5"
                    value={idtofind == "all" ? "" : idtofind}
                    onChange={(sdata) => {
                      if (sdata.target.value == "") {
                        setidtofind("all");
                        if (page != 1) {
                          setPage(1);
                        }
                      } else {
                        if (page != 1) {
                          setPage(1);
                        }
                        setidtofind(sdata.target.value.toUpperCase());
                      }
                    }}
                  />
                  <label
                    className="mt-3 ml-5 fontdesign"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Assigned By
                  </label>
                  <Select
                    className="fontdesign filterselect mt-2 ml-5"
                    options={nameoptions}
                    styles={customStylesd}
                    // placeholder="Any"
                    onChange={(item) => {
                      handlenamefilter(item);
                    }}
                    value={
                      nameoptions && nameoptions?.filter((e) => e.label == name)
                    }
                  ></Select>

                  <label
                    className="mt-3 ml-5 fontdesign"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Status
                  </label>
                  <Select
                    className="fontdesign filterselect mt-2 ml-5"
                    placeholder="Any"
                    options={stateoptions}
                    styles={customStylesd}
                    onChange={(item) => {
                      handlestatusfilter(item);
                    }}
                    value={stateoptions?.filter(
                      (e) => e.label === statusfilter
                    )}
                  ></Select>

                  <label
                    className="mt-3 ml-5 fontdesign"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Category
                  </label>
                  <Select
                    className="fontdesign filterselect mt-2 ml-5 "
                    options={categoryoption}
                    styles={customStylesd}
                    onChange={(item) => {
                      categoryfilter(item);
                    }}
                    value={
                      categoryoption &&
                      categoryoption?.filter((e) => e.label === cateoryff)
                    }
                    placeholder="Any"
                  ></Select>
                  <label
                    className="mt-3 ml-5 fontdesign"
                    style={{ fontSize: "12px", fontWeight: "600" }}
                  >
                    Created
                  </label>
                  <Select
                    className="fontdesign  filterselect mt-2 ml-5"
                    // styles={customStylesd}
                    placeholder="Any"
                    options={dateoption}
                    onChange={(item) => {
                      datefilter(item);
                    }}
                    value={
                      DateF ? dateoption?.find((e) => e.value === DateF) : null
                    }
                  ></Select>
                </div>
              </Card>
            </div>
          ) : null}
        </div>

      </div>
      {view == "tabelview" ? (
        <div className="mt-5 ml-5" style={{ padding: "1px" }}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          ></link>
          <MaterialTable
            title={"Assigned Change Request"}
            columns={Columns.map((e) => e)}
            data={temp === "" ? "" : temp.map((e) => e)}
            components={{
              Pagination: PatchedPagination,
            }}
            //       detailPanel={(rowdata) => {
            //         return (
            //           <div
            //             style={{
            //               fontSize: 13,
            //               color: "white",
            //               // backgroundColor: "#ffff",
            //             }}
            //           >
            //             <Table
            //               class="table table-bordered"
            //               style={{
            //                 fontSize: "10px",
            //                 fontFamily: "DM Sans, sans-serif;",
            //                 backgroundColor: "#ebedf2",
            //               }}
            //             >
            //               <thead
            //                 className="tableHeader"
            //                 style={{
            //                   fontSize: "10px",
            //                   fontFamily: "DM Sans, sans-serif;",
            //                 }}
            //               >
            //                 {/* <th>Title</th> */}
            //                 <th style={{ fontWeight: "bold" }}>Description</th>
            //                 <th style={{ fontWeight: "bold" }}>
            //                   RaisingOnBehalfDetails
            //                 </th>
            //                 {/* <th>CreatedDate</th> */}
            //                 <th style={{ fontWeight: "bold" }}>CategoryCode</th>
            //                 <th style={{ fontWeight: "bold" }}>SubCategoryCode</th>
            //                 <th style={{ fontWeight: "bold" }}>
            //                   {" "}
            //                   AssignedToDetails
            //                 </th>
            //                 {/* AssignedToDetails: e.AssignedToDetails.FN + " " + e.AssignedToDetails.LN,
            // CategoryCode.CategoryName, */}
            //                 {/* <th>Urgency</th>
            //                                     <th>Impact</th> */}
            //               </thead>

            //               <tr>
            //                 {/* <td>{rowdata.IncidentTitle}</td> */}
            //                 {/* <td> {rowdata.Description}</td> */}
            //                 {/* <td>
            //               {rowdata.IsSelfRequest == "Y"
            //                 ? "Self"
            //                 : rowdata.RaisingOnBehalfDetails}
            //             </td> */}
            //                 {/* <td>{moment(rowdata.CreatedDate).format('DD-MM-YYYY HH:mm:ss')}</td> */}
            //                 {/* <td> {rowdata.CategoryCode}</td> */}
            //                 {/* <td> {rowdata.SubCategoryCode}</td> */}
            //                 {/* <td>{rowdata.ServiceAffected.ServiceName}</td> */}
            //                 {/* <td>{rowdata.AssignedToDetails}</td> */}

            //                 {/* <td>{rowdata.Impact.ImpactName}</td> */}
            //               </tr>
            //             </Table>
            //           </div>
            //         );
            //       }}
            options={{
              actionsColumnIndex: -1,
              exportAllData: true,
              exportButton: true,
              columnResizable: true,
              filtering: false,
              sorting: true,
              paging: true,
              pageSize: 20, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [6, 12, 20, 50], // rows selection options

              // rowStyle: rowData => ({
              //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              // }),
              headerStyle: {
                whiteSpace: "nowrap",
                fontWeight: "700",
                fontSize: "medium",
                // backgroundColor: "#eef2f7",
                color: "black",
                padding: "12px ",
                textAlign: "left",
              },
              cellStyle: {
                textAlign: "left",
                fontSize: "medium",
                padding: "21px",
                color: "#6c757d",
                // padding: "15px 2px",
              },
              // headerStyle: {
              //   padding: "2px",
              //   fontWeight: "700",
              //   fontSize: "small",
              //   backgroundColor: "#107aeb85",
              //   color: "#03256c",
              // },
              // cellStyle: {
              //   fontSize: "12px",
              //   padding: "2px",
              //   color: "#03256c",
              // },
              rowStyle: (rowdata) => {
                if (rowdata.tableData.id % 2 != 0) {
                  // return { backgroundColor: "#f6f7fb" };
                }
              },
            }}
          // actions={[
          //   (rowData) => ({
          //     icon: () => (
          //       <SpeakerNotesOutlinedIcon
          //         className="Card_Button_Color_Approve1"
          //         style={{
          //           color: "rgb(25, 138, 227)",
          //         }}
          //       >
          //         {" "}
          //         Notes{" "}
          //       </SpeakerNotesOutlinedIcon>
          //     ),
          //     tooltip: "Notes",
          //     //   disabled: rowData.isrequestfulfilled == "Y",
          //     onClick: (e, data) => {
          //       console.log(data, "data");
          //       // if (data.datavalid != "Resolved") {
          //       handleClickOpen1(data, data.IncidentId);
          //       // }
          //     },
          //   }),
          // ]}
          />

          {/* <Modal
      // style={{width:"1000px"}}
      ClassName="uploadbox"
      show={Upload}
      // size="sm"
      onHide={handleclose}
    >
      <Modal.Header closeButton>
      
      </Modal.Header>
      <Modal.Body>
        <Container>
         
        </Container>
      </Modal.Body>
    </Modal> */}
        </div>
      ) : null}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="text-danger"> Do you want to End Change !</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="notes"
            label="Notes"
            type="text"
            placeholder="Enter Notes"
            style={{ width: '240px' }}
            // fullWidth
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              backgroundColor: "#fff",
              color: "rgb(82, 80, 229)",
              textTransform: "capitalize",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              backgroundColor: "rgb(82, 80, 229)",
              color: "white",
              textTransform: "capitalize",
            }}
            onClick={() => handleConfirm(additionalData)}
          >
            Complete change
          </Button>

        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={messageopen}
        autoHideDuration={1500}
        onClose={() => {
          setmessageopen(false);
        }}
      >
        <Alert
          onClose={() => {
            setmessageopen(false);
          }}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green !important",
            color: "white !important",
          }}
        >
          updated successfully!
        </Alert>
      </Snackbar>
      <Dialog
        open={opendetails}
        onClose={() => {
          setopendetails(false);
        }}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle>
          <div className="row">
            <div className="col d-flex justify-content-start">
              <label
                className="text-black fontdesign"
                style={{ fontWeight: "bold" }}
              >
                {"Details page"} - (
                {
                  changeassign?.filter((e) => e.ChangeId === changeid)[0]
                    ?.ChangeRefNumber
                }
                )
              </label>
            </div>
            <div
              className="col d-flex justify-content-end"
              role="button"
              onClick={() => setopendetails(false)}
            >
              <CloseIcon />
            </div>
          </div>
        </DialogTitle>
        <hr style={lineStyle} />
        <DialogContent className="mt-3">
          <Detailspagechange
            changedetails={changeassign?.filter((e) => e.ChangeId === changeid)}
            stateoptions={stateoptions}
            assignmentoption={assignmentoption}
            Assignedtooption={Assignedtooption}
            setAssignedtooption={setAssignedtooption}
            refreshstates={refreshstates}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Assignedchange;
