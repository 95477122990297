import React, { useState } from "react";
import { Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { Row, Col } from "reactstrap";
// import "./AllocationTable.css";
import Select from "react-select";
import "./projectDeallocate.css";
import MaterialTable from "material-table";
import axios from "../../../axios";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssessmentIcon from "@mui/icons-material/Assessment";

toast.configure();

const AllocationTable = ({
  loadData,
  // visible,
  allocatedEmployee,
  employee,
  projects,
  managers,
  test,
  valueOfTeamMemberId,
  role,
  managerdropdown,
  Values,
  setValues,
  edit,
  setEdit,
  projectMappingId,
  setProjectMappingId,
  initialValue,
  // setVisible,
  setRefesh,
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [DeleteFormOpen, setDeleteFormOpen] = useState(false);
  const [DeletingRowdata, setDeletingRowdata] = useState(false);
  const [roleedit, setroleedit] = useState("");
  const dropd = managerdropdown?.map((man) => {
    return {
      value: man.RoleGroupId,
      label: man.RoleName,
      RoleCode: man.RoleName.slice(0, 3),
    };
    // <option key={man.empid} value={man.empid}>
    //   {man.firstname + " " + man.lastname}
    // </option>
  });
  console.log(allocatedEmployee, "allocatedemployee");
  console.log(dropd, "dropdvalei");
  const check = dropd.map(
    (item) => {
      return {
        value: item.value,
        label: item.label,
        RoleCode: item.label.slice(0, 3),
      };
    }
    // item.label
  );
  console.log(check, "check");
  let id =
    localStorage.getItem("clientid") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("clientid"));

  const Columns = [
    // { title: "Id", field: "TeamMemberId", editable: false },
    // { title: "Name", field: "TeamMember", editable: false },
    { title: "Project Name", field: "ProjectName", editable: false },

    {
      title: "Project Role",
      field: "RoleName",
      editable: false,

      //   lookup:
      //  dropd.map((item) => (
      //    item.label
      // ))
    },

    {
      title: "Allocated At",
      field: "ProjectStartDate",
    },
    // {
    //   title: "Allocation End Date",
    //   field: "ProjectEndDate",
    //   // validate: (rowData) =>
    //   //   Date.parse(
    //   //     moment(rowData.ProjectEndDate, ).format("DD MMM YYYY")
    //   //   ) <
    //   //   Date.parse(
    //   //     moment(rowData.ProjectStartDate, ).format("DD MMM YYYY")
    //   //   )
    //   //     ? "End date must be greater than start Date"
    //   //     : "",
    // },
    { title: "Manager Name", field: "ProjectManager", editable: false },
    {
      title: "Percentage",
      field: "AllocPercentage",
      // type: "numeric",
      validate: (rowData) =>
        rowData.AllocPercentage > 100
          ? "Project Percentage should be less than equal to 100"
          : "",
    },
    // { title: "Status", field: "status", editable: false }
  ];
  const employees = [];
  employees.push(employee);

  const tests = [];
  tests.push(test);

  const projectsArr = [];
  projectsArr.push(projects);
  // console.log(projectsArr,"projectsar")

  const manager = [];
  manager.push(managers);

  let clientdetails = JSON.parse(localStorage.getItem("clientdetails"));
  const allocatedcurrent = allocatedEmployee?.filter(
    (e) => e.IsCurrent === "Y" && e.TeamMemberId == valueOfTeamMemberId
  );
  // console.log(allocatedcurrent,"allocatedcurrent")
  const temp = allocatedcurrent;
  // allocatedcurrent == null
  //   ? ""
  //   : allocatedcurrent?.map((e) => {
  //     return {
  //       ...e,
  //       TeamMemberId:
  //         employees == null
  //           ? ""
  //           : employees[0].find((emp) => emp.TeamMemberId == e.TeamMemberId)
  //             .TeamMemberId,
  //       TeamMember:
  //         employees == null
  //           ? ""
  //           : employees[0].find((emp) => emp.TeamMemberId == e.TeamMemberId)
  //             .Firstname +
  //           " " +
  //           employees[0].find((emp) => emp.TeamMemberId == e.TeamMemberId)
  //             .Lastname,
  //       ProjectName:
  //         projectsArr == null
  //           ? ""
  //           : projectsArr[0].find((pro) => pro.ProjectId === e.ProjectCode)?.ProjectName,
  //       ProjectStartDate: moment(e.ProjectStartDate).format("DD-MM-YYYY") + " to " + moment(e.ProjectEndDate).format("DD-MM-YYYY"),
  //       ProjectEndDate: moment(e.ProjectEndDate).format("DD-MM-YYYY"),
  //       ProjectManager: e?.MappedByDetails?.ContactName,
  //       RoleName: e.ProjectRole?.RoleName,
  //       status: e.IsCurrent

  //       // tests == null
  //       //   ? ""
  //       //   : tests[0].find((man) => man.EmpId == e.ProjectManager)
  //       //       .Firstname +
  //       //     " " +
  //       //     tests[0].find((man) => man.EmpId == e.ProjectManager)
  //       //       .Lastname,
  //     };
  //   });
  // console.log(temp,"temp")

  const ProjectDeletion = (rowData) => {
    setDeleteFormOpen(true);
    setDeletingRowdata(rowData);
  };
  const ProjectDeleteProcess = (rowData) => {
    // let projectCode =
    //   projectsArr == null
    //     ? ""
    //     : projectsArr.find(
    //         (pro) => pro.ProjectId == DeletingRowdata.ProjectCode
    //       ).ProjectCode;
    // console.log(projectCode, "projectcode");
    // let projectCode2 =
    //   projectsArr == null
    //     ? ""
    //     : projectsArr.find(
    //         (pro) => pro.ProjectId != DeletingRowdata.ProjectCode
    //       ).ProjectCode;
    // console.log(projectCode2, "ProejctCOde2");
    let DeleteData = {
      TeamMemberId: rowData.TeamMemberId,
      IsCurrent: "N",
      AllocPercentage: 0,
      ProjectStartDate: moment().format("YYYY-MM-DD"),
      ProjectEndDate: moment().format("YYYY-MM-DD"),
    };
    let patchissue = {
      AssignedTo: null,
      AssignedToDetails: {
        FN: null,
        LN: null,
      },
      AssignedDate: moment().format("YYYY-MM-DD"),
    };
    axios
      .patch(
        "agilesaas_issue_details?AssignedTo=eq." +
          rowData.TeamMemberId +
          "&ClientId=eq." +
          id +
          "&ProjectId=eq." +
          rowData.ProjectCode +
          "&CurrentStage->0->>StageName=in.(Refined,Backlog,In Development)",
        patchissue
      )
      .then((res) => {
        toast.error(`Released`, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          draggable: true,
        });
      })
      .catch((err) => console.log(err));

    axios
      .patch(
        "agilesaas_project_mapping?ProjectMappingId=eq." +
          rowData.ProjectMappingId +
          "&ClientId=eq." +
          id,
        DeleteData
      )
      .then((res) => {
        setEdit(false);
        if (res.status === 204) {
          toast.error(`Project Deallocated`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            draggable: true,
          });
        }
        // var postdata2 = {
        //   AllocPercentage: 0,
        // };
        // axios
        //   .patch(
        //     "agilesaas_project_mapping?ProjectMappingId=eq." +
        //     rowData.ProjectMappingId, postdata2
        //   )
        //   .then((res) => {

        //     if (res.status === 204) {

        //     }
        //     setDeleteFormOpen(!DeleteFormOpen);
        //   });
        // loadData(Values.TeamMemberId);
        let data = { ...initialValue, TeamMemberId: [] };
        setValues(initialValue);
        // setValues(initialValue)
        setRefesh(true);
      })
      .catch((err) => console.log(err));
  };

  // Calculate the color based on the value of e.percentageprogressed

  const rowsPerPage = 5;
  const [page, setPage] = React.useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Calculate the index range of the current page
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // Get the data for the current page
  const paginatedData = allocatedEmployee
    ?.filter(
      (e) => e.IsCurrent === "Y" && e.TeamMemberId == valueOfTeamMemberId
    )
    ?.slice(startIndex, endIndex);

  const handleEditing = (data) => {
    console.log(data, "data");

    setEdit(true);
    setValues({
      ...Values,
      ProjectCode: data.ProjectCode,
      ProjectRole: {
        RoleName: data.ProjectRole.RoleName,
        RoleGroupId: data.ProjectRole.RoleGroupId,
        RoleCode: data.ProjectRole.RoleCode,
      },
      AllocPercentage: data.AllocPercentage,
      ProjectStartDate: new Date(data.ProjectStartDate),
      ProjectEndDate: new Date(data.ProjectEndDate),
    });
    setProjectMappingId(data.ProjectMappingId);
  };

  return (
    // <Row>
    //   <link
    //     rel="stylesheet"
    //     href="https://fonts.googleapis.com/icon?family=Material+Icons"
    //   >
    //   </link>
    //   <Col className="md-12 mt-2" style={{ padding: "1px" }}>
    //     {/* {visible   ? ( */}
    //     <MaterialTable
    //       className="MuiTypography-h6"
    //       title={"Project Allocation"}
    //       columns={Columns.map((e) => e)}
    //       data={temp === "" ? "" : temp.map((e) => e)}
    //       editable={{
    //         onRowUpdate: (newData, oldData) =>
    //           new Promise((resolve, reject) => {
    //             // console.log(newData, 'newData', oldData, 'oldData')

    //             // let projectCode12 =
    //             //   projectsArr == null
    //             //     ? ""
    //             //     : projectsArr.find(
    //             //         (pro) => pro.ProjectId === newData.ProjectCode
    //             //       ).ProjectCode;
    //             // console.log(projectCode12, "projectcode");
    //             // let projectCode21 =
    //             //   projectsArr == null
    //             //     ? ""
    //             //     : projectsArr.find(
    //             //         (pro) => pro.ProjectId != newData.ProjectCode
    //             //       ).ProjectCode;
    //             // console.log(projectCode21, "projectcode2");
    //             // setTimeout(() => {
    //             let postTemp = {
    //               TeamMemberId: valueOfTeamMemberId,
    //               ProjectCode: oldData.ProjectCode,
    //               IsCurrent: "Y",
    //               AllocPercentage: newData.AllocPercentage,
    //               ProjectStartDate: moment(
    //                 newData.ProjectStartDate,
    //                 "DD-MM-YYYY"
    //               ).format("YYYY-MM-DD"),
    //               ProjectEndDate: moment(
    //                 newData.ProjectEndDate,
    //                 "DD-MM-YYYY"
    //               ).format("YYYY-MM-DD"),
    //               ProjectRole: newData.ProjectRole
    //             };
    //             resolve();
    //             // console.log(oldData.ProjectMappingId, newData.ProjectMappingId, temp, 'checkmappingid')
    //             const checkPercentage = temp?.filter((e) => e.ProjectMappingId != oldData.ProjectMappingId)[0]?.AllocPercentage + newData.AllocPercentage
    //             if (
    //               moment(newData.ProjectStartDate, "DD-MM-YYYY") >
    //               moment(newData.ProjectEndDate, "DD-MM-YYYY")
    //             ) {
    //               alert("Startdate Should be less than end date");
    //             } else if (moment(
    //               newData.ProjectEndDate,
    //               "DD-MM-YYYY",
    //               true
    //             ).isValid() == false
    //             ) {
    //               alert("Enter Date in 'DD-MM-YYYY' Format");
    //             }
    //             else if (
    //               moment(newData.ProjectStartDate, "DD-MM-YYYY") >
    //               moment(newData.ProjectEndDate, "DD-MM-YYYY")
    //             ) {
    //               alert("Startdate Should be less than end date");
    //             }
    //             else if (checkPercentage > 100) {
    //               alert("Please enter valid percentage");
    //             }
    //             else {
    //               // console.log(temp, "tem")

    //               axios
    //                 .patch(
    //                   "agilesaas_project_mapping?ProjectMappingId=eq." +
    //                   oldData.ProjectMappingId,

    //                   postTemp
    //                 )
    //                 .then((res) => {
    //                   if (res.status === 204) {
    //                     toast.success(`Project Edited Successfully`, {
    //                       transition: Slide,
    //                       position: toast.POSITION.TOP_RIGHT,
    //                       autoClose: 3000,
    //                       draggable: true,
    //                     });
    //                   }
    //                   loadData(valueOfTeamMemberId);
    //                 });
    //               var postdata2 = {
    //                 AllocPercentage: 100 - newData.AllocPercentage,
    //               };
    //               // console.log(postdata2, 'postdata2')
    //               // axios
    //               //   .patch(
    //               //     "agilesaas_project_mapping?TeamMemberId=eq." +
    //               //       valueOfTeamMemberId +
    //               //       "&ProjectCode=eq." +
    //               //       projectCode21,
    //               //     postdata2
    //               //   )
    //               //   .then((res) => {
    //               //     console.log(res, "ReallocResponse2");
    //               //     if (res.status === 204) {
    //               //       console.log(res, "ReallocResponse2");
    //               //     }
    //               //     loadData(valueOfTeamMemberId);
    //               //   });
    //             }
    //             // }, 1000);
    //           }),
    //       }}
    //       actions={[
    //         {
    //           icon: "delete",
    //           tooltip: "Delete",
    //           onClick: (event, rowData) => ProjectDeletion(rowData),
    //         },
    //       ]}
    //       options={{
    //         search: true,
    //         actionsColumnIndex: -1,
    //         exportAllData: true,
    //         exportButton: true,
    //         columnResizable: true,
    //         filtering: false,
    //         paging: true,
    //         sorting: true,
    //         rowStyle: (rowData) => ({
    //           backgroundColor:
    //             selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
    //         }),
    //         headerStyle: {
    //           whiteSpace: 'nowrap',
    //           fontWeight: "700",
    //           fontSize: "medium",
    //           // backgroundColor: "#eef2f7",
    //           color: "black",
    //           padding: "12px ",
    //           textAlign: "left",
    //           whiteSpace: 'nowrap'
    //         },
    //         cellStyle: {
    //           textAlign: "left",
    //           fontSize: "medium",
    //           padding: "21px",
    //           color: "#6c757d",
    //         },
    //         rowStyle: (rowdata) => {
    //           if (rowdata.tableData.id % 2 != 0) {
    //             // return { backgroundColor: "#cddef085" };
    //           }
    //         },
    //       }}
    //       onRowClick={(evt, selectedRow) =>
    //         setSelectedRow(selectedRow.tableData.id)
    //       }
    //     />
    //     {/* ) : null} */}
    //     {DeleteFormOpen ? (
    //       <div className="Project_Deallocate_Delete">
    //         <div className="Project_Deallocate_Delete_Card">
    //           <div className="Project_Deallocate_Delete_Header">
    //             <h2 style={{ color: "#2593B8", margin: "auto" }}>
    //               {" "}
    //               Deallocate a Project
    //             </h2>
    //             <IconButton>
    //               <CloseIcon onClick={() => setDeleteFormOpen(false)} />
    //             </IconButton>
    //           </div>
    //           <h1> </h1>
    //           <span>
    //             Want to Deallocate the {DeletingRowdata.ProjectCode} Project
    //           </span>
    //           <div className="Project_Deallocate_Delete_Button">
    //             <Button
    //               variant="contained"
    //               // style={{ backgroundColor: "#00f2c3" }}
    //               className="Card_Button_Color_Approve btn-redg"
    //               onClick={ProjectDeleteProcess}
    //             >
    //               Delete
    //             </Button>
    //             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //             <Button
    //               type="reset"
    //               variant="contained"
    //               className="Card_Button_Color_Approve btn-redg"
    //               // color="secondary"
    //               // style={{ backgroundColor: "#fd377a" }}
    //               onClick={() => setDeleteFormOpen(!DeleteFormOpen)}
    //             >
    //               Close
    //             </Button>
    //           </div>
    //         </div>
    //       </div>
    //     ) : null}
    //   </Col>
    // </Row>
    <TableContainer
      component={Paper}
      className="App"
      style={{ width: "97%", marginLeft: "2%" }}
    >
      <Table sx={{ margin: "auto auto" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className="tableheader">Project Name</Typography>
            </TableCell>
            <TableCell>
              <Typography className="tableheader">Project Role</Typography>
            </TableCell>
            <TableCell>
              <Typography className="tableheader">Allocated At</Typography>
            </TableCell>
            <TableCell>
              <Typography className="tableheader">Manager</Typography>
            </TableCell>
            <TableCell>
              <Typography className="tableheader">Percentage</Typography>
            </TableCell>
            {/* <TableCell ><Typography className="tableheader">Status</Typography></TableCell> */}
            <TableCell>
              <Typography className="tableheader">Action</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((e) => (
            <TableRow>
              <TableCell>
                {projectsArr == null
                  ? ""
                  : projectsArr[0]?.find(
                      (pro) => pro.ProjectId === e.ProjectCode
                    )?.ProjectName}
              </TableCell>
              <TableCell>{e.ProjectRole?.RoleName}</TableCell>
              <TableCell>
                {moment(e.ProjectStartDate).format("DD-MM-YYYY") +
                  " to " +
                  moment(e.ProjectEndDate).format("DD-MM-YYYY")}
              </TableCell>
              <TableCell>{e?.MappedByDetails?.ContactName}</TableCell>
              <TableCell>{e.AllocPercentage + "%"}</TableCell>
              {/* <TableCell>{e.IsCurrent}</TableCell> */}
              <TableCell>
                <EditIcon
                  style={{ fontSize: "medium" }}
                  onClick={() => {
                    handleEditing(e);
                  }}
                />
                <DeleteIcon
                  style={{ fontSize: "medium" }}
                  onClick={() => {
                    ProjectDeleteProcess(e);
                  }}
                />
                {/* {selectcustom(e)} */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Pagination
          count={Math.ceil(temp.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          // variant="outlined"
          // shape="rounded"
        />
      </Box>
    </TableContainer>
  );
};

export default AllocationTable;
