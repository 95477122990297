import React from "react";
import { useSelector } from "react-redux";
import { Badge } from "react-bootstrap";
import { Avatar, Tooltip } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { BorderRight } from "@mui/icons-material";
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import Typography from "@mui/material/Typography";
import Historyrelease from "./HistoryRelease";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Unassigned from "../../assets/images/avatar.png";
import '../../App.css'

export default function HistorySummary({
  data,
  points,
  assignedTo,
  setAssignedTo,
}) {
  // console.log(data, "dataaaaaaaaaaaaaaaa")
  const { allImage } = useSelector((state) => state.user);
  const counts = {};
  const [selected, setSelected] = React.useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = React.useState(null);
  const [position, setPosition] = React.useState(0);
  const isItemSelected = (id) => !!selected.find((el) => el === id);
  let result = {};
  const userpoints = points?.data;
  // console.log(assignedTo, "setAss")
  // console.log(selectedCardIndex, "selectedcardindex");
  const teamMembers = {};
  function CircularProgressWithLabel(props) {
    return (
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          //   marginLeft: 1,
          // marginBottom: -7,
          // padding: 0,
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: "#dcdcdc !important"
            // (theme) =>
            //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={24}
          thickness={3}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          disableShrink
          sx={{
            color: props.warning
              ? "#f7d060 !important"
              : props.label == 1 || props.label < 0
                ? "#ff6d60 !important"
                : "#45dd81 !important",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={24}
          thickness={3}
          value={props.value}
        />
        {props.label == 0 ? (
          <DoneIcon
            fontSize="small"
            sx={{
              position: "absolute",
              top: "36%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              justifyContent: "center",
              fontWeight: 700,
              color: "#45dd81 !important",
            }}
          />
        ) : (
          <Typography
            sx={{
              position: "absolute",
              top: "38%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              justifyContent: "center",
              fontWeight: 600,
              //   padding: 1,
            }}
            // fontSize={4}
            // Adjust font size as per your requirement
            component="div"
            color={
              props.warning
                ? "#f7d060 !important"
                : props.label == 1 || props.label < 0
                  ? "#ff6d60 !important"
                  : "#45dd81 !important"
            }
          >
            {`${Math.round(props.label)}`}
          </Typography>
        )}
      </Box>
    );
    // return (
    //   <Box sx={{ position: "relative", display: "inline-flex" }}>
    //     <CircularProgress
    //       variant="determinate"
    //       size={25} // Adjust size of the progress bar as per your requirement
    //       thickness={3} // Adjust thickness of the progress bar as per your requirement
    //       {...props}
    //     />
    //     <Box
    //       sx={{
    //         top: 0,
    //         left: 0,
    //         bottom: 0,
    //         right: 0,
    //         position: "absolute",
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <Typography
    //         variant="inherit" // Adjust font size as per your requirement
    //         component="div"
    //         color={props.value === 100 ? "red" : "green"}
    //         sx={{ fontWeight: 700 }}
    //       >
    //         {`${Math.round(props.label)}`}
    //       </Typography>
    //     </Box>
    //   </Box>
    // );
  }

  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };
  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    return isFirstItemVisible ? (
      <></>
    ) : (
      <span
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
        role="button"
        style={{ fontSize: "25px" }}
        className="mdi mdi-arrow-left-drop-circle-outline mt-4"
      >
        {/* <span></span> */}
      </span>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);

    return isLastItemVisible ? (
      <></>
    ) : (
      <span
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
        role="button"
        style={{ fontSize: "25px" }}
        className="mdi mdi-arrow-right-drop-circle-outline mt-4"
      ></span>
    );
  }
  function Card({ onClick, selected, title, itemId, e }) {
    const visibility = React.useContext(VisibilityContext);
    const userdetail = userpoints?.filter(
      (f) => f.AssignedTo == e.AssignedTo
    )?.[0];
    const finald = userdetail?.Estimate == undefined ? 0 : userdetail?.Estimate;
    const leave = points?.teamleavesconsidered?.filter(
      (a) => a.UserId == e.AssignedTo
    )?.[0]?.Leavesplanned;
    return (
      <div
        className="ml-3 dcard pt-1 pb-1"
        onClick={() => onClick(visibility)}
        style={{
          width: "205px",
          height: "135",
          overflowX: "hidden",
          border: "1px solid #E6E8EC",
          borderRadius: "8px",
          fontFamily: "Nunito, sans-serif",
          backgroundColor: assignedTo == e?.AssignedTo ? "#E7E7E7" : "#fff",
        }}
        tabIndex={0}
      >
        <div style={{ color: "#000" }}>
          <div className="row" style={{ padding: "0px" }}>
            <div className=" mt-1 col-3">
              <Avatar
                sx={{ width: '30px !important', height: '30px !important' }}
                alt=""
                src={e.Src}
                className="ml-1"
              />
            </div>
            <div className="d-flex justify-content-start  mt-2 col-5 p-0">
              <label style={{ fontSize: "12px", fontWeight: "700" }}>
                {" "}
                {e.name == "null null" ? "Un Assigned" : e.name}{" "}
              </label>
            </div>
            {/* {console.log(e.name + "------" + finald)} */}
            <Tooltip title={finald == 0 ? "Banwidth not available" : finald < 0 ? "Banwidth not available & over stretched" : "Banwidth available"} placement="top" arrow>
              <div className="d-flex justify-content-start mt-2 col-4">
                <CircularProgressWithLabel
                  value={
                    userdetail == undefined
                      ? 0
                      : finald <= 0
                        ? 100
                        : (userdetail?.Userpoints / points?.TotalDaysInSprint) * 100
                  }
                  label={
                    userdetail == undefined ? points?.TotalDaysInSprint : finald
                  }
                  warning={finald == 2 || finald == 3}
                // danger={userdetail?.Estimate == 1}
                // style={{
                //   color:
                //     finald < 0 ? red[500] : green[500],
                // }}
                />
              </div>
            </Tooltip>
          </div>
          <Row className="" style={{}}>
            <Col style={{ paddingRight: "2px" }}>
              <span
                className="ml-1"
                style={{
                  display: "inline-block",
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                Assigned
              </span>
              <br></br>
              <span
                className="ml-3 mt-1"
                style={{
                  display: "inline-block",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                {e.assigned}
              </span>
              {/* <Badge style={{ fontSize: "15px", fontWeight: "600" }}>{e.Assigned}</Badge> */}
            </Col>
            <Col style={{ paddingRight: "2px" }}>
              <span
                className="ml-1"
                style={{
                  display: "inline-block",
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                Completed
              </span>
              <br></br>
              <span
                className="ml-3 mt-1"
                style={{
                  display: "inline-block",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                {e.completed}
              </span>

              {/* <Badge style={{ fontSize: "15px", fontWeight: "600" }}>{e.Completed}</Badge> */}
            </Col>
            <Col>
              <span
                className="ml-1"
                style={{
                  display: "inline-block",
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                Leave
              </span>
              <br></br>
              <span
                className="ml-3 mt-1"
                style={{
                  display: "inline-block",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                {leave ? leave : 0}
              </span>

              {/* <Badge style={{ fontSize: "15px", fontWeight: "600" }}>10</Badge> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  const handleClick =
    (id, cardData) =>
      ({ getItemById, scrollToItem }) => {
        const itemSelected = isItemSelected(id);
        const { AssignedTo } = cardData;
        setAssignedTo(AssignedTo);
        setSelectedCardIndex(id);
        setSelected((currentSelected) =>
          itemSelected
            ? currentSelected.filter((el) => el !== id)
            : currentSelected.concat(id)
        );
      };
  if (data.length > 0) {
    data.forEach((item, index) => {
      // Extract relevant properties
      // console.log(item);
      const assignedTo = item.AssignedToDetails;
      const stage = item.Stage;
      const alternativeColors = ["#d9ead3", "#fff2cc", "#d0e0e3", "#fce5cd"];
      const alternativFeColors = ["#274e13", "#7f6000", "#0c343d", "#783f04"];
      const colorIndex = index % alternativeColors.length;
      const backgroundColor = alternativeColors[colorIndex];
      const fontColor = alternativFeColors[colorIndex];
      const Src =
        allImage?.length > 0
          ? allImage?.filter((x) => x.teammemberid == item.Assignedid)?.[0]
            ?.image
          : Unassigned;
      // Initialize the object if not exists
      result[assignedTo] = result[assignedTo] || {
        name: assignedTo,
        assigned: 0,
        completed: 0,
        Src: Src,
        bgcolor: backgroundColor,
        color: fontColor,
        AssignedTo: item.Assignedid,
      };
      //   console.log(stage);
      // Update the assigned count
      result[assignedTo].assigned++;

      // Update the completed count if the stage is "Done"
      if (stage === "Done" || stage === "User Acceptace Testing") {
        result[assignedTo].completed++;
      }
    });

    // Convert the result object to an array

    // Convert the teamMembers object into an array of values
  }
  // Convert the counts object into an array of results
  const resultArray = Object.values(result);
  return (
    <div>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {resultArray.length > 0 ? (
          resultArray.map((e, index) => {
            return (
              <Card
                itemId={index} // NOTE: itemId is required for track items
                title={e.name}
                key={index}
                onClick={handleClick(index, e)}
                selected={isItemSelected(index)}
                e={e}
              />
            );
          })
        ) : (
          <></>
        )}
      </ScrollMenu>
    </div>
  );
}
