import * as React from "react";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import OfflineBoltRoundedIcon from "@material-ui/icons/OfflineBoltRounded";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { Bookmark } from "@mui/icons-material";
import "../../App.css";
import { Avatar } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import { TextField } from "@material-ui/core";
import moment from "moment";
import "./Showrelease.css";
import { Button } from "@material-ui/core";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import Unassigned from "../../assets/images/avatar.png";
import PatchedPagination from "../../PatchedPagination";
import { ProgressBar } from "react-bootstrap";
// import axios from "axios";
import axios from "../../axios";
import { HANDLE_ON_CHANGE } from "../../redux/CommonInitialsate";
import Mailandnotification from "../../Mailandnotification";
const useStyles = makeStyles({
  root: {
    color: "rgb(254, 192, 84)", // Default color
  },
  greenSlider: {
    color: "green",
  },
  yellowSlider: {
    color: "yellow",
  },
  orangeSlider: {
    color: "orange",
  },
  redSlider: {
    color: "red",
  },
});

const useStyles1 = makeStyles({
  disabledSlider: {
    "& .MuiSlider-thumb": {
      backgroundColor: "#2196f3", // Thumb color
    },
    "& .MuiSlider-track": {
      backgroundColor: "#2196f3", // Active track color
    },
    "& .MuiSlider-rail": {
      backgroundColor: "#d3d3d3", // Inactive track color
    },
  },
});

export default function HistoryTable({
  data,
  labelvi,
  remove,
  setremove,
  Reasonoption,
  handleissueid,
  mydatashow,
  removed,
  refresh,
}) {
  const {
    allImage,
    projectRole,
    employeeList,
    mydatashow1,
    projectName,
    projectCode,
  } = useSelector((state) => state.user);

  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == "11004")[0]
    ?.RoleCode;

  const classes = useStyles();

  const processAndSortData = (data) => {
    let stories = [];
    let otherIssues = [];

    data.forEach((item) => {
      if (item.IssueType === "Story") {
        stories.push(item);
      } else {
        otherIssues.push(item);
      }
    });

    const storyMap = new Map();
    stories.forEach((story) => {
      storyMap.set(story.IssueIdS, story);
    });

    let combinedData = [];
    storyMap.forEach((story) => {
      combinedData.push(story);
      otherIssues.forEach((issue) => {
        if (
          issue.IssueType === "SubTask" &&
          issue.Parentissue &&
          issue.Parentissue[0]?.IssueId === story.IssueIdS
        ) {
          combinedData.push(issue);
        }
      });
    });

    otherIssues.forEach((issue) => {
      if (
        issue.IssueType !== "SubTask" ||
        !issue.Parentissue ||
        !storyMap.has(issue.Parentissue[0]?.IssueId)
      ) {
        combinedData.push(issue);
      }
    });

    return combinedData;
  };

  const combinedSortedData = processAndSortData(data);
  // Calculate the color based on the value of e.percentageprogressed
  const getColor = (percentage) => {
    if (percentage >= 75) return classes.greenSlider;
    else if (percentage >= 50) return classes.yellowSlider;
    else if (percentage == 0) return classes.redSlider;
    else return classes.root; // Default color
  };

  const rowsPerPage = 20;
  const [page, setPage] = React.useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Calculate the index range of the current page
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // Get the data for the current page
  const paginatedData = data.slice(startIndex, endIndex);

  // function handlereason(e, i, x) {
  //     console.log(e)
  //     console.log(x)
  //     console.log(remove)
  //     console.log(i)
  //     let a = [...remove];
  //     a?.splice(i, 1, {
  //         ReleaseDate: moment().format("YYYY-MM-DD"),
  //         IsReleaseFrozen: "Y",
  //         RemovedTickets: {
  //             IssueId: x.IssueIdS,
  //             IssueTitle: x.IssueTitle,
  //             AssignedToDetails: x.AssignedToDetails,
  //             Assignedto: x.Assignedid,
  //             Reason: e.value,
  //             notes: null,
  //         },
  //     });
  //     console.log(a, "Aaaa");
  //     setremove(a);
  // }

  // const handlenotes = (e, i, x) => {
  //     console.log(e, i, x)
  //     console.log(remove[i]?.RemovedTickets?.Reason)
  //     let a = [...remove];

  //     a?.splice(i, 1, {
  //         ReleaseDate: moment().format("YYYY-MM-DD"),
  //         IsReleaseFrozen: "Y",
  //         RemovedTickets: {
  //             IssueId: x.IssueIdS,
  //             IssueTitle: x.IssueTitle,
  //             AssignedToDetails: x.AssignedToDetails,
  //             Assignedto: x.Assignedid,
  //             Reason: remove[i]?.RemovedTickets?.Reason,
  //             notes: e.target.value ? e.target.value : null,
  //         },
  //     });
  //     console.log(a, "remove check")
  //     setremove(a);

  //     // let notevalue = [...notes];

  //     // notevalue.splice(item?.item, 1, {
  //     //   note: e.label,
  //     // });

  //     // setnotes(notevalue);
  // };
  function handlereason(e, i, x) {
    setremove((prevRemove) => {
      const updatedRemove = [...prevRemove];
      updatedRemove[i] = {
        ReleaseDate: moment().format("YYYY-MM-DD"),
        IsReleaseFrozen: "Y",
        RemovedTickets: {
          IssueId: x.IssueIdS,
          IssueTitle: x.IssueTitle,
          AssignedToDetails: x.AssignedToDetails,
          Assignedto: x.Assignedid,
          Reason: e.value,
          notes: updatedRemove[i]?.RemovedTickets?.notes,
        },
      };
      return updatedRemove;
    });
  }

  const handlenotes = (e, i, x) => {
    setremove((prevRemove) => {
      const updatedRemove = [...prevRemove];
      updatedRemove[i] = {
        ReleaseDate: moment().format("YYYY-MM-DD"),
        IsReleaseFrozen: "Y",
        RemovedTickets: {
          IssueId: x.IssueIdS,
          IssueTitle: x.IssueTitle,
          AssignedToDetails: x.AssignedToDetails,
          Assignedto: x.Assignedid,
          Reason: updatedRemove[i]?.RemovedTickets?.Reason,
          notes: e.target.value ? e.target.value : null,
        },
      };
      return updatedRemove;
    });
  };

  const userdropdownoptions = employeeList.map((x) => {
    return {
      value: x.teammemberid,
      label: x.firstname + " " + x.lastname,
      firstname: x.firstname,
      lastname: x.lastname,
      icon: (
        <Avatar
          // className="progressName"
          src={
            allImage?.length > 0
              ? allImage?.filter((e) => e.teammemberid == x.teammemberid)?.[0]
                ?.image
              : Unassigned
          }
        />
      ),
    };
  });
  let empid = JSON.parse(localStorage.getItem("Empdetails"));
  const dispatch = useDispatch();
  const handleSelectChange = (selectedOption, issueId, assignedto, e) => {
    console.log(e);
    console.log(selectedOption);
    console.log(issueId);
    console.log(assignedto);

    if (assignedto != selectedOption?.value) {
      axios
        .patch("agilesaas_issue_details?IssueId=eq." + e.IssueIdS, {
          AssignedTo: selectedOption.value,
          AssignedToDetails: {
            FN: selectedOption?.firstname,
            LN: selectedOption?.lastname,
          },
        })
        .then((res) => {
          //   refresh();
          const updatedData = mydatashow.map((item) =>
            item.IssueIdS === e.IssueIdS
              ? {
                ...item,
                Assignedid: selectedOption?.value,
                AssignedToDetails: selectedOption?.label,
                FN: selectedOption?.firstname,
                LN: selectedOption?.lastname,
              }
              : item
          );
          const updatedData1 = mydatashow1.map((item) =>
            item.IssueIdS === e.IssueIdS
              ? {
                ...item,
                Assignedid: selectedOption?.value,
                AssignedToDetails: selectedOption?.label,
                FN: selectedOption?.firstname,
                LN: selectedOption?.lastname,
              }
              : item
          );
          dispatch(
            HANDLE_ON_CHANGE({ name: "mydatashow", value: updatedData })
          );
          dispatch(
            HANDLE_ON_CHANGE({ name: "mydatashow1", value: updatedData1 })
          );
          Mailandnotification(
            e,
            employeeList?.filter(
              (x) => x.teammemberid == selectedOption?.value
            )?.[0]?.email,
            [empid?.[0]?.OfficialEmail],
            true, // web notify
            true, // for mail
            projectName,
            "New Card Assigned",
            "A new card has been assigned to you"
          );
          let posthistory = {
            IssueId: e.IssueIdS,
            ChangedBy: empid?.[0]?.ClientUserId,
            ChangedByDetails: {
              FN: empid?.[0]?.UserFirstname,
              LN: empid?.[0]?.UserLastname,
            },
            ChangedDate: moment(),
            UpdatedField: "Assignee",
            UpdatedFrom: e.AssignedToDetails,
            UpdatedTo: selectedOption.label,
            UpdateSequence: 1,
            IsActive: "Y",
            ClientId: empid?.[0]?.ClientId,
            ProjectId: projectCode,
          };
          axios
            .post(`agilesaas_issue_history`, posthistory)
            .then((response) => {
              console.log(response);
            });
        })
        .catch((error) => console.log(error));
    }
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: 150,
      padding: 0,
      margin: 0,
      background: "#fff",
      border: state.isFocused
        ? "0px solid rgb(82, 80, 223)"
        : "0px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      // width: '110%',
      // marginLeft: elementWidth > 1300 ? -35 : -20,

      // height: "50px",
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      // marginTop: 0,
      // marginLeft: elementWidth > 1300 ? -35 : -20,
      width: `${Math.max(
        ...userdropdownoptions.map((option) => option.label.length * 10)
      )}px`, // Adjust the multiplier based on your content
      minWidth: "100%",
      zIndex: 9999, // Ensure the dropdown appears above other elements

    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // This ensures the portal has a high zIndex
  };
  console.log(remove, "rrrrrrrrrrrrrrrrrrrrrrrrrrr");
  const displayReason =
    mydatashow?.[0]?.Status == "Y" &&
    removed.length != 0 &&
    labelvi?.includes("To Be Released");
  const displayDropdown =
    labelvi?.includes("To Be Released") &&
    paginatedData.filter(
      (x) =>
        x.Stage == "Refined" ||
        x.Stage == "Backlog" ||
        x.Stage == "In Development"
    ).length != 0;
  const Columns1 = [
    {
      title: "Ticket reference ",
      field: "IssueType",
      // cellStyle: { width: "14%" },
      render: (rowData) => {
        let icon;
        switch (rowData.IssueType) {
          case "Story":
            icon = (
              <Bookmark
                className="story fontdesign"
                style={{ fontSize: "16px" }}
              />
            );
            break;
          case "Task":
            icon = (
              <CheckBoxRoundedIcon
                className="task fontdesign"
                style={{ fontSize: "16px" }}
              />
            );
            break;
          case "Bug":
            icon = (
              <StopCircleIcon
                style={{ fontSize: "16px" }}
                className="bug fontdesign"
              />
            );
            break;
          case "Epic":
            icon = (
              <OfflineBoltRoundedIcon
                style={{ fontSize: "16px" }}
                className="epic fontdesign"
              />
            );
            break;
          case "SubTask":
            icon = (
              <AddToPhotosIcon
                style={{ fontSize: "16px" }}
                className="kanbanicon task"
                {...rowData.IssueId}
              />
            );
            break;
          default:
            icon = "";
        }
        const isSubtask = rowData.IssueType === "SubTask";
        return (
          <div style={{ paddingLeft: isSubtask ? "20px" : "0" }}>
            <span>
              {rowData.Priority === "Highest" ? (
                <span>
                  <KeyboardDoubleArrowUpIcon
                    className="filter-icons-1"
                    fontSize="small"
                  />
                </span>
              ) : rowData.Priority === "High" ? (
                <span>
                  <KeyboardArrowUpIcon
                    className="filter-icons-1"
                    fontSize="small"
                  />
                </span>
              ) : rowData.Priority === "Medium" ? (
                <span>
                  <DragHandleIcon className="filter-icons" fontSize="small" />
                </span>
              ) : rowData.Priority === "Low" ? (
                <span>
                  <KeyboardArrowDownIcon
                    className="filter-icons-2"
                    fontSize="small"
                  />
                </span>
              ) : rowData.Priority === "Lowest" ? (
                <span>
                  <KeyboardDoubleArrowDownIcon
                    className="filter-icons-2"
                    fontSize="small"
                  />
                </span>
              ) : (
                ""
              )}
            </span>{" "}
            <label className="mb-0"> {icon} </label>
            <label
              style={{
                color: "#198ae3",
                marginLeft: "8px",
                whiteSpace: "pre-wrap",
              }}
              sx={{ width: 25, height: 25 }}
            >
              {rowData.IssueId}
            </label>
          </div>
        );
      },
    },
    {
      title: "Summary",
      field: "IssueTitle",
      cellStyle: { width: "25%" },
      render: (e) => (
        <label
          style={{ whiteSpace: "pre-wrap" }}
          sx={{ width: 25, height: 25 }}
        >
          {displayDropdown ? e.IssueTitle : e.IssueTitle}
        </label>
      ),
      // editable: false,// cellStyle: { fontSize: "12px", textAlign: "left" },
    },

    {
      title: "Assignee",
      field: "Assignedid",
      render: (e) => (
        <Select
          components={{
            // DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          options={userdropdownoptions}
          value={userdropdownoptions?.filter(
            (option) => option.value === e.Assignedid
          )}
          isDisabled={e.Stage !== "Refined"}
          getOptionLabel={(v) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="assignee-avatar1">
                <Avatar
                  // sx={{ width: '20px !important', height: '20px !important' }}
                  alt=""
                  src={
                    allImage?.length > 0
                      ? allImage?.filter((x) => x.teammemberid == v.value)?.[0]
                        ?.image
                      : Unassigned
                  }
                />
              </div>
              <span style={{ marginLeft: 10, fontWeight: "normal" }}>
                {v.label}
              </span>
            </div>
          )}
          placeholder="Select assignee"
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption, e.IssueId, e.Assignedid, e)
          }
          styles={customStyles}
          menuPortalTarget={document.body} // Render dropdown in portal outside of popover
          menuPosition="fixed" // Fix dropdown to viewport, not scroll position
          menuPlacement="auto" // Automatically place the dropdown above/below as needed
        />
      ),
    },
    {
      title: "Original Estimate",
      field: "OriginalEstimate",
      // headerStyle: { border: '2px solid red' },
      // cellStyle: { textAlign: 'center', width: '5%' },
      render: (e) => (
        <span className="ml-4">
          {e.OriginalEstimate ? e.OriginalEstimate + " Days" : 0 + " Days"}
        </span>
      ),
    },
    {
      title: "% Work",
      field: "percentageprogressed",
      render: (e) => (
        <Box>
          {/* <Slider
                    className={getColor(e?.percentageprogressed === "" ? 0 : Number(e?.percentageprogressed))}
                    size="small"
                    value={e?.percentageprogressed === "" ? 0 : Number(e?.percentageprogressed)}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    disabledSlider={true}
                /> */}
          <ProgressBar
            style={{ height: "12px" }}
            variant={
              e.percentageprogressed >= 75
                ? "success"
                : e.percentageprogressed === 0
                  ? "danger"
                  : "warning"
            }
            // animated
            now={e.percentageprogressed}
            label={
              <span style={{ fontSize: "10px" }}>{e.percentageprogressed}</span>
            }
          />
        </Box>
      ),
    },
    {
      title: "Due days",
      field: "effortremaining",
      render: (e) => (
        <label>
          {e?.effortremaining == null
            ? "--"
            : e?.effortremaining < 0
              ? -e.effortremaining + " Days Over Due"
              : e?.effortremaining + " Days"}
        </label>
      ),
    },
    {
      title: "Stages",
      field: "Stage",
      render: (e) => (
        <label
          className={
            e.Stage == "Done"
              ? "text-success"
              : e.Stage == "Refined"
                ? "text-warning"
                : "text-info"
          }
        >
          {" "}
          {e.Stage == "Done"
            ? "DONE"
            : e.Stage == "Refined"
              ? "RFD"
              : e.Stage == "User Acceptace Testing"
                ? "UAT"
                : e.Stage == "Backlog"
                  ? "BLG"
                  : e.Stage == "In Development"
                    ? "DEV"
                    : ""}
        </label>
      ),
    },
    { title: "Reason", field: "Reason", hidden: !displayDropdown },
    { title: "Notes", field: "Notes", hidden: !displayDropdown },
    {
      title: "",
      field: "delete",
      hidden: labelvi?.includes("Planned") != true,
      render: (e) =>
        (labelvi?.includes("Planned") == true && projectRole == "SM") ||
          projectRole == "ITMGR2" ||
          projectRole == "QA" ||
          getProdRole == "KA" ? (
          <Button className="buttoon" onClick={() => handleissueid(e)}>
            <DeleteOutlineIcon color="error" />
          </Button>
        ) : null,
    },
    {
      title: "Reason",
      field: "Reasons",
      hidden: !displayReason,
      render: (e) => removed.filter((x) => x.IssueId == e.IssueId)[0]?.Reason,
    },
  ];
  console.log(data, "data");
  return (
    <MaterialTable
      title={"Sprint Details"}
      columns={Columns1.map((e) => e)}
      components={{
        Pagination: PatchedPagination,
      }}
      data={combinedSortedData.map((e, i) => {
        return {
          ...e,
          Reason:
            (e.Stage != "Done" &&
              e.Stage != "User Acceptace Testing" &&
              projectRole == "SM") ||
              projectRole == "ITMGR2" ||
              projectRole == "QA" ||
              getProdRole == "KA" ? (
              <div>
                <p style={{ width: "130px", paddingTop: "15px" }}>
                  {/* <TextField style={{ borderWidth: 0, display: 'hidden', height: 0, width: 100, borderColor: 'red' }} /> */}
                  <Select
                    id={i}
                    // className="reasondropdown"
                    // style={{ height: 40 }}

                    options={Reasonoption}
                    onChange={(x) => handlereason(x, i, e)}
                    required
                    placeholder="Select "
                  />
                </p>
              </div>
            ) : null,
          Notes:
            (e.Stage != "Done" &&
              e.Stage != "User Acceptace Testing" &&
              projectRole == "SM") ||
              projectRole == "ITMGR2" ||
              projectRole == "QA" ||
              getProdRole == "KA" ? (
              <TextField
                style={{ width: 110 }}
                id={i}
                variant="outlined"
                className="notes "
                size="small"
                placeholder="Notes"
                onChange={(x) => handlenotes(x, i, e)}
                type="text"
                autoComplete="off"
                required
              />
            ) : (
              "-"
            ),
        };
      })}
      options={{
        actionsColumnIndex: -1,
        exportAllData: true,
        exportButton: true,
        columnResizable: true,
        filtering: false,
        sorting: true,
        paging: true,
        pageSize: 20,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [20, 30, 40, 50],

        headerStyle: {
          whiteSpace: "nowrap",
          fontWeight: "700",
          fontSize: "medium",
          // backgroundColor: "#eef2f7",
          color: "black",
          // padding: "9px ",
          textAlign: "left",
        },
        cellStyle: {
          textAlign: "left",
          fontSize: "medium",
          padding: "21px",
          color: "#6c757d",
          whiteSpace: "nowrap",
        },
        rowStyle: (rowdata) => {
          if (rowdata.tableData.id % 2 != 0) {
            // return { backgroundColor: "#cddef085" };
          }
        },
      }}
    // onRowClick={(evt, selectedRow) =>
    //     setSelectedRow(selectedRow.tableData.id)
    // }
    />
  );
}
