import * as actionTypes from "./actions";
import axios from "../../../axios"
let arr = JSON.parse(localStorage.getItem("Empdetails"));
const StateFunction = {
    HandleOnChange: async (dispatch, element) => {
        dispatch({
            type: actionTypes.HANDLE_ON_CHANGE,
            data: element.target,
        });
    },
    HandleOnChangeObject: async (dispatch, element, object) => {
        dispatch({
            type: actionTypes.HANDLE_ON_CHANGE_OBJECT,
            Name: element.target.name,
            value: element.target.value,
            obj: object,
        });
    },
    INCIDENT_DETAILS: async (dispatch, element, object) => {
        axios
            .get("agilesaas_incident_details")
            .then((response) => {
                let CreatedData = response.data?.filter((e) => e.CreatedBy === arr[0].ClientUserId)
                let AssignedData = response.data?.filter((e) => e.AssignedTo === arr[0].ClientUserId)             
                StateFunction.HandleOnChange(dispatch, {
                    target: {
                        name: "CreatedIncident",
                        value: CreatedData,
                    },
                });
                StateFunction.HandleOnChange(dispatch, {
                    target: {
                        name: "AssignedIncident",
                        value: AssignedData,
                    },
                });
            }).catch((e) => console.log(e))
    },
    INCIDENT_CATEGORY_MASTER: async (dispatch) => {
        axios
            .get("agilesaas_incident_category_master")
            .then((response) => {
                StateFunction.HandleOnChange(dispatch, {
                    target: {
                        name: "Category_master",
                        value: response.data,
                    },
                });
            }
            ).catch((err) => console.log(err))
    },
    INCIDENT_SLA_MASTER: async (dispatch) => {
        axios
            .get("company_sla_master")
            .then((response) => {
                StateFunction.HandleOnChange(dispatch, {
                    target: {
                        name: "Incident_SLA_Master",
                        value: response.data,
                    },
                });
            }
            ).catch((err) => console.log(err))
    },
    INCIDENT_SLA_DETAILS: async (dispatch) => {
        axios
            .get("agilesaas_incident_sla_details")
            .then((response) => {
                StateFunction.HandleOnChange(dispatch, {
                    target: {
                        name: "Incident_SLA_Details",
                        value: response.data,
                    },
                });
            }
            ).catch((err) => console.log(err))
    },
}
export default StateFunction;

// INCIDENT_CATEGORY_MASTER: async (dispatch) => {
//     axios
//         .get("agilesaas_incident_category_master")
//         .then((response) => {
//             StateFunction.HandleOnChange(dispatch, {
//                 target: {
//                     name: "Category_master",
//                     value: response.data,
//                 },
//             });
//         }
//         ).catch((err) => console.log(err))
// },