import React, { useEffect } from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
import { Line } from "react-chartjs-2";
import { useState } from "react";
import { green } from "@material-ui/core/colors";
import moment from "moment";
import axios from "../../../axios";
import { Style } from "@material-ui/icons";
import "chartjs-plugin-datalabels";

export default function Historyburnupanddown({
  // data,
  // userID,
  releaseID,
  percentage1,
  chartdata1,
  // excludeday,
  // projectname,
  // versionname,
  // releaseversion,
  // predata,
  // setpredata,
  // workingDays,
  // includays1,
  versionDays,
  reportdata,
  checkRelease,
}) {
  // const day = chartdata.map((data) => data.id);
  const formattedDates = versionDays?.map((dateStr) => {
    const formattedDate = moment(dateStr, "YYYY/MM/DD").format("DD MMM");
    return formattedDate;
  });
  const sprintdate = formattedDates.map((e) => e);
  // console.log(sprintdate);
  // console.log(versionDays);
  // console.log(percentage1);

  // console.log(checkRelease);
  // console.log(chartdata1);
  // let temp = moment(predata?.[0]?.sprintenddate).diff(
  //   moment(predata?.[0]?.sprintstartdate),
  //   "day"
  // );
  // const diff =
  //   temp == 0
  //     ? 1
  //     : Number(
  //         Math.ceil(
  //           (new Date(predata?.[0]?.sprintenddate) -
  //             new Date(predata?.[0]?.sprintstartdate)) /
  //             (1000 * 60 * 60 * 24)
  //         ) + 1
  //       );
  //   const includays = workingDays(predata?.[0]?.sprintstartdate, diff + 0);

  // const formattedDates = includays1?.map((dateStr) => {
  //   const formattedDate = moment(dateStr, "YYYY/MM/DD").format("DD MMM");
  //   return formattedDate;
  // });
  // const sprintdate = formattedDates.map((e) => e);
  //   const combinedDatas = day.map((day, index) => {
  //     if (index === 0) {
  //       return `${day} `;
  //     }
  //     return `${day} (${formattedDates[index - 1]})`;
  //   });
  // let version = projectname + " - " + versionname;

  // const currentDate = moment().format("DD MMM");
  // const currentIndex = sprintdate?.indexOf(currentDate);

  //   const dataLabels = chartdata?.map((data, index) => {
  //     if (index === 0) {
  //       return `${data.plan}`;
  //     }
  //     return `${data.plan} (${formattedDates[index - 1]})`;
  //   });
  // console.log(includays1);
  // console.log(percentage1?.map((e) => Math.round(e)));
  // console.log(chartdata1?.map((data) => Math.round(data.plan)));
  return (
    <div className="chart-container" style={{ height: "600px" }}>
      <Line
        data={{
          labels: checkRelease
            ? sprintdate
            : reportdata?.[0]?.ReportsData?.[0]?.Sprintdays,
          datasets: [
            {
              label: "Planned",
              data: checkRelease
                ? chartdata1?.map((e) => e.plan)
                : reportdata?.[0]?.ReportsData?.[0]?.Burndown?.planned,
              fill: true,
              borderColor: "#9A89FF",
              backgroundColor: "transparent",
              borderWidth: 2,
              pointRadius: 2,
              pointHitRadius: 3,
              pointHoverRadius: 4,
              hoverBackgroundColor: "black",
              tension: 0.7,
              lineTension: 0.5,
              datalabels: {
                // display: "auto",
                // align: "end",
                // anchor: "end",
                font: {
                  size: 10,
                },
                // offset: 5, // Offset the data label 10 pixels from the data point
                // padding: 10, // Add 5 pixels of padding inside the data label
                color: "white",
                padding: 2,
                // rotation: 45,
                backgroundColor: "#9A89FF", // Set a background color
                borderRadius: 10, // Set a border radius
                borderWidth: 0.5, // Set border width
                // borderColor: "rgb(0, 0, 0)",
              },
              // datalabels: {
              //   // display: "auto", // グラフの数値表示が重なったら、片方消すオプション
              //   align: "end",
              //   anchor: "end",
              //   // offset: 5,
              // },
            },
            {
              label: "Actual",
              data: checkRelease
                ? percentage1?.map((e) => Math.round(e))
                : reportdata?.[0]?.ReportsData?.[0]?.Burndown?.actual,
              // ?.slice(0, currentIndex + 1),
              backgroundColor: "rgba(0, 255, 202,0.4)",
              fill: true,
              borderColor: "#A9F4D0",
              borderWidth: 3,
              pointRadius: 3,
              pointHoverRadius: 4,
              hoverBackgroundColor: "black",
              tension: 0.7,
              lineTension: 0.5,
              datalabels: {
                // display: "auto",
                // align: "start",
                // anchor: "start",
                // offset: 5,
                // backgroundColor: "#A9F4D0", // Set a background color
                borderRadius: 10, // Set a border radius
                borderWidth: 0.2, // Set border width
                borderColor: "rgb(0, 0, 0)",
                padding: 2,
                color: "black",
                font: {
                  size: 10,
                },
              },
            },
          ],
        }}
        options={{
          legend: {
            display: true,
            position: "top",
            align: "center",
            labels: {
              fontSize: 12,
              fontStyle: "normal",
              boxWidth: 8,
              usePointStyle: true,
            },
          },
          title: {
            display: true,
            text: checkRelease
              ? "Burndown Report" + " - " + releaseID
              : "Burndown Report" +
                " - " +
                reportdata?.[0]?.ReportsData?.[0]?.Version,
            // "Burndown Report" + " - " + checkRelease
            //   ? releaseID + "" + "hai"
            //   : reportdata?.[0]?.ReportsData?.[0]?.Version,
            fontSize: 13,
            fontStyle: "bold",
            align: "end",
            padding: 6,
            fontColor: "#000",
          },

          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Days",
                  fontSize: 16,
                  fontStyle: "bold",
                  padding: 0,
                },
                ticks: {
                  fontSize: 11,
                  fontStyle: "normal",
                  beginAtZero: true,
                  padding: 0,
                },
                gridLines: {
                  display: true,
                  color: "transparent",
                  lineWidth: 0.2,
                  drawBorder: true,
                  drawOnChartArea: true,
                  drawTicks: true,
                  zeroLineColor: "gray",
                  zeroLineWidth: 1,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "StoryPoint",
                },
                gridLines: {
                  display: true,
                  color: "rgba(0, 0, 0, 0.2)",
                  lineWidth: 0.2,
                  drawBorder: true,
                  drawOnChartArea: true,
                  drawTicks: true,
                  zeroLineColor: "gray",
                  zeroLineWidth: 1,
                },
              },
            ],
          },
          tooltips: {
            enabled: true,
            backgroundColor: "gray",
            titleFontColor: "#fff",
            titleFontSize: 14,
            titleFontStyle: "bold",
            bodyFontColor: "#fff",
            bodyFontSize: 12,
            bodyFontStyle: "normal",
            footerFontColor: "#fff",
            footerFontSize: 12,
            footerFontStyle: "normal",
            xPadding: 10,
            yPadding: 10,
            cornerRadius: 6,
          },
          // tooltips: {
          //   enabled: true,
          //   mode: "index",
          //   intersect: false,
          //   custom: function (tooltipModel) {
          //     // Customize the tooltip content here
          //     if (tooltipModel.body) {
          //       tooltipModel.body[0] = "Planned: " + tooltipModel.body[0];
          //       tooltipModel.body[1] = "Actual: " + tooltipModel.body[1];
          //     }
          //   },
          //   backgroundColor: "gray",
          //   titleFontColor: "#fff",
          //   titleFontSize: 14,
          //   titleFontStyle: "bold",
          //   bodyFontColor: "#fff",
          //   bodyFontSize: 12,
          //   bodyFontStyle: "normal",
          //   footerFontColor: "#fff",
          //   footerFontSize: 12,
          //   footerFontStyle: "normal",
          //   xPadding: 10,
          //   yPadding: 10,
          //   cornerRadius: 6,
          // },
        }}
      />
    </div>
  );
}
