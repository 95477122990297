import React, { useState } from 'react'
import {
    Avatar,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import axios from '../../../axios';
import { HANDLE_ON_CHANGE } from '../../../redux/CommonInitialsate';
import CloseIcon from "@material-ui/icons/Close";
import { Col, Row } from 'reactstrap';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import moment from 'moment';
import Mailandnotification from '../../../Mailandnotification';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Deletedialog() {
    const { selectedCard1, deletedialog, deletedata, deletetype, projectCode, EmpDropDownData, projectName } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [checked, setChecked] = React.useState(true);
    const [checksubmit, setChecksubmit] = useState(false)
    const [message, setMessage] = useState("")
    const [open, setOpen] = useState(false);
    const [messagetype, setMessageType] = useState("success")
    let emplogin = JSON.parse(localStorage.getItem("Empdetails"));
    let login = emplogin != null ? emplogin : null;

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handlehistory = (id, type) => {
        const date = moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss");
        let posthistory = {
            IssueId: id,
            ChangedBy: login?.[0]?.ClientUserId,
            ChangedByDetails: {
                FN: login?.[0]?.UserFirstname,
                LN: login?.[0]?.UserLastname,
            },
            ChangedDate: date,
            UpdatedField: type,
            UpdatedTo: type,
            UpdateSequence: 1,
            IsActive: "Y",
            Status: null,
            ClientId: login?.[0]?.ClientId,
            ProjectId: projectCode,
        };
        axios
            .post("agilesaas_issue_history", posthistory)
            .then((response) => { })
            .catch((error) => console.log(error));
    }

    const handleRefresh = () => {
        setMessage(`${deletedata?.IssueTitle} deleted successfully`);
        setChecksubmit(false)
        setOpen(true);
        dispatch(HANDLE_ON_CHANGE({ name: "deletedialog", value: false }));
        // setRefresh(true);
        setMessageType("success")
        setChecked(true)
        dispatch(HANDLE_ON_CHANGE({ name: "progressopen", value: false }))
        dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
        dispatch(HANDLE_ON_CHANGE({ name: "deletetype", value: "" }));
    }

    const handleCatch = () => {
        setMessage(`Something went wrong`);
        setChecksubmit(false)
        setOpen(true);
        dispatch(HANDLE_ON_CHANGE({ name: "deletedialog", value: false }));
        setMessageType("warning")
        setChecked(true)
        dispatch(HANDLE_ON_CHANGE({ name: "progressopen", value: false }))
        dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
        dispatch(HANDLE_ON_CHANGE({ name: "deletetype", value: "" }));
    }

    const handleunlink = (id) => {
        const patchdata = {
            ParentIssue: null,
            LinkToEpic: selectedCard1?.LinkToEpic,
            IssueType: "Task"
        }
        axios
            .patch(`agilesaas_issue_details?IssueId=eq.${id}`, patchdata)
            .then((e) => {
                handlehistory(id, "Unlink")
                handleRefresh()
                Mailandnotification(
                    selectedCard1,
                    selectedCard1?.CreatedBy == login?.[0]?.ClientUserId ? EmpDropDownData?.filter(
                        (x) => x.teammemberid == selectedCard1?.AssignedTo
                    )?.[0]?.email : [login?.[0]?.OfficialEmail],
                    [login?.[0]?.OfficialEmail],
                    true,
                    false,
                    projectName,
                    `Issue Unlinked`,
                    `${selectedCard1?.IssueTitle} is unlinked and created as task by ${login?.[0]?.UserFirstname + " " + login?.[0]?.UserLastname}`
                );
            })
            .catch((e) => { handleCatch() })
    }

    const handledeletesingletask = (id) => {
        axios.patch(`agilesaas_issue_details?IssueId=eq.${id}`, { IsActive: "N" })
            .then((e) => {
                if (selectedCard1?.PlannedRelease) {
                    axios.get(
                        `agilesaas_releases?ReleaseName=eq.${selectedCard1?.ReleaseId}&select=ReleaseIssues&ProjectId=eq.${projectCode}`
                    )
                        .then((res) => {
                            const data = res?.data?.[0];
                            const rdata = res?.data?.[0]?.ReleaseIssues;
                            let patchdata = rdata && rdata?.filter((x) => x.IssueId != selectedCard1?.IssueId)
                            //   ? [...data, { IssueId: e.IssueId, IssueTitle: e?.IssueTitle }]
                            //   : [{ IssueId: e.IssueId, IssueTitle: e?.IssueTitle }];
                            if (rdata) {
                                axios
                                    .patch(`agilesaas_releases?ReleaseId=eq.${data?.ReleaseId}`, {
                                        ReleaseIssues: patchdata,
                                    })
                                    .then((res1) => {
                                        console.log(res1)
                                    });
                            }
                        });
                }
                handlehistory(id, "Delete")
                handleRefresh()
                Mailandnotification(
                    selectedCard1,
                    selectedCard1?.CreatedBy == login?.[0]?.ClientUserId ? EmpDropDownData?.filter(
                        (x) => x.teammemberid == selectedCard1?.AssignedTo
                    )?.[0]?.email : [login?.[0]?.OfficialEmail],
                    [login?.[0]?.OfficialEmail],
                    true,
                    false,
                    projectName,
                    `Issue Deleted`,
                    `${selectedCard1?.IssueTitle} is deleted by ${login?.[0]?.UserFirstname + " " + login?.[0]?.UserLastname}`
                );
            })
            .catch((e) => { handleCatch() })
    }

    const handleDelete = () => {
        setChecksubmit(true)
        const issueid = deletedata?.IssueId;

        // if (deletedata?.IssueType == "SubTask" || deletedata?.IssueType == "Bug") {
        if (deletetype == "unlink") {
            handleunlink(issueid)
        }
        else {
            handledeletesingletask(issueid)
        }
        // }
        // else if (deletedata?.IssueType == "Task" || deletedata?.IssueType == "Story") {
        //     axios.get(`agilesaas_issue_details?ParentIssue->0->>IssueId=eq.${issueid}&order=CreatedDate.desc&IsActive=eq.Y`)
        //         .then((res) => {
        //             const getdata = res.data
        //             const ids = getdata.map((n) => n.IssueId)
        //             if (getdata?.length > 0) {
        //                 if (checked) {
        //                     const issueIds = [...ids, issueid]
        //                     const fid = issueIds?.join(",");
        //                     console.log(issueIds, 'issue ids', issueid, fid)
        //                     axios
        //                         .patch(`agilesaas_issue_details?IssueId=in.(${fid})`, { IsActive: "N" })
        //                         .then((e) => {
        //                             handleRefresh()
        //                         })
        //                         .catch((e) => { handleCatch() })
        //                 }
        //                 else {
        //                     const fid = ids?.join(",");
        //                     const patchdata = {
        //                         ParentIssue: null,
        //                         LinkToEpic: deletedata?.LinkToEpic,
        //                         IssueType: "Task"
        //                     }
        //                     axios
        //                         .patch(`agilesaas_issue_details?IssueId=eq.${issueid}`, { IsActive: "N" })
        //                         .then((response) => {
        //                             axios
        //                                 .patch(`agilesaas_issue_details?IssueId=in.(${fid})`, patchdata)
        //                                 .then((e) => {
        //                                     handleRefresh()
        //                                 })
        //                                 .catch((e) => { handleCatch() })
        //                         })
        //                         .catch((e) => { handleCatch() })
        //                 }
        //             }
        //             else {
        //                 handledeletesingletask(issueid)
        //             }
        //         })
        // }
    };

    const handleClose = () => {
        dispatch(HANDLE_ON_CHANGE({ name: "deletedialog", value: false }));
        setChecked(true)
    }

    const handleClosesnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };


    return (
        <>
            <Dialog
                open={deletedialog}
                onClose={() => { handleClose() }}
                className="mainbox"
                maxWidth="xs"
            >
                {/* <DialogTitle>
                <Row noGutters>
                    <Col md={10} >

                    </Col>

                    <Col className="d-flex justify-content-center align-items-center" md={2}>
                        <IconButton className="d-flex justify-flex-end" onClick={() => { handleClose() }}>
                            <CloseIcon />
                        </IconButton>
                    </Col>
                </Row>
            </DialogTitle> */}
                {/* <DialogTitle className="d-flex justify-flex-end">
                <IconButton className="d-flex justify-flex-end" onClick={() => { handleClose() }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle> */}
                <DialogContent className="pt-4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span>
                        Are you sure to {deletetype == "unlink" ? "unlink" : "delete"} the {deletedata?.IssueTitle} ({deletedata?.IssueType})?
                    </span>
                    {/* {
                    (deletedata?.IssueType === "Task" || deletedata?.IssueType === "Story") && (
                        <div className='m-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Checkbox
                                defaultChecked
                                color="primary"
                                onClick={(e) => handleChange(e)}
                                checked={checked}
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                            />
                            <span>Delete the {deletedata?.IssueType} along with their subtask</span>
                        </div>
                    )
                } */}
                </DialogContent>

                <DialogActions>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "right",
                        }}
                    >
                        <Button
                            style={{
                                fontSize: 14,
                                padding: 9,
                                width: 70,
                                height: 30,
                                borderRadius: 8,
                                // backgroundColor: '#5250E5',
                                // color: '#fff',
                                // border: '0px solid #5A88F1',
                                border: "2px solid #5A88F1",
                                backgroundColor: "#fff",
                                color: "#5250E5",
                                textAlign: "center",
                                textTransform: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // marginRight: issueType == "Task" || issueType == "Bug" ? 5 : 24
                            }}
                            onClick={() => { handleClose() }}
                        // disabled={checkSubmit}
                        >
                            Cancel
                        </Button>
                        <Button
                            // onClick={() => submit("RFD")}
                            // disabled={checkSubmit || issueType == "Story" || issueType == "Epic"}
                            style={{
                                fontSize: 14,
                                padding: 9,
                                height: 30,
                                borderRadius: 8,
                                width: 70,
                                // border: '2px solid #5A88F1',
                                // backgroundColor: '#fff',
                                // color: '#5250E5',
                                backgroundColor: "#5250E5",
                                color: "#fff",
                                border: "0px solid #5A88F1",
                                textAlign: "center",
                                textTransform: "none",
                                marginRight: 24,
                                marginLeft: 5,
                            }}
                            onClick={() => {
                                handleDelete();
                            }}
                        >
                            {checksubmit ? (
                                <span
                                    className="spinner-border"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
                                ></span>
                            ) : (
                                "Submit"
                            )}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}

                open={open}
                autoHideDuration={1000}
                onClose={handleClosesnackbar}
            >
                <Alert
                    onClose={handleClosesnackbar}
                    sx={{
                        width: "100%",
                        backgroundColor: "green !important",
                        color: "#fff !important",
                    }}
                    severity={messagetype}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Deletedialog