import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import "./StoryPoint.css";
import { Card, Col, Row, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

function Uatclosure({
  // SelectedCardId
}) {
  const { allImage, KanbanBoardData, SelectedCardId } = useSelector((state) => state.user);

  useEffect(() => {
    axios
      .get("agilesaas_uat_signoff?IssueId=eq." + SelectedCardId)
      .then((response) => {
        setUatclosurecard(response.data);
      })
      .catch((error) => console.log(error));
  }, []);
  const [Uatclosurecard, setUatclosurecard] = useState([]);

  let uatdata = Uatclosurecard.map((e) => {
    return e.UATSignoffDetails;
  });
  let effortuat = Uatclosurecard.map((e) => {
    return e.EffortSpentInHours;
  });
  return (
    <div className="mt-5">
      <Card>
        <CardBody className="myspborder  mytablebg">
          <table className="mytablebg">
            <Row className="mytextspe myspetext1 ml-3">
              <Col>
                <th className="fontdesign">Type</th>
              </Col>
              <Col>
                <th className="fontdesign">Selected Value</th>
              </Col>
            </Row>
            <Row className="myline "></Row>
            {Uatclosurecard == "" ? (
              <center>
                {" "}
                <h5 className="text-warning mt-5 fontdesign">Data yet not updated</h5>{" "}
              </center>
            ) : uatdata[0] == "" ? (
              ""
            ) : (
              uatdata[0].map((e) => {
                console.log(e.SPCode, e.UATSignOff, "e");
                return (
                  <div>
                    <Row className="mt-1 ml-3">
                      <Col>
                        <p className="fontdesign">{e.SPCode == null ? "" : e.SPCode}</p>
                      </Col>
                      <Col>
                        <p className="fontdesign">{e.UATSignOff == null ? "" : e.UATSignOff}</p>
                      </Col>
                    </Row>
                  </div>
                );
              })
            )}
            {Uatclosurecard == "" ? (
              ""
            ) : (
              <>
                <Row className="mt-1 ml-3">
                  <Col>
                    <p className="fontdesign">Effort Spent</p>
                  </Col>
                  <Col>
                    <p className="fontdesign">
                      {effortuat == "" ? "0" : effortuat[0]}
                      {" hrs"}
                    </p>
                  </Col>
                </Row>
              </>
            )}
          </table>
        </CardBody>
      </Card>
    </div>
  );
}

export default Uatclosure;
