import "./Notifications.css";
import axios from "../../axios";

import Avatar from "@mui/material/Avatar";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import moment from "moment";
import systemimage from "../../assets/images/loder.png";
import notfound from "../../assets/images/avatar.png";
import { Button, Col, DropdownItem, NavLink, Row, Dr } from "reactstrap";
import { DialogContent } from "@material-ui/core";
import Unassigned from "../../assets/images/avatar.png";

const Notify = ({ data, getnewdata, user }) => {
  const { userImage, allImage } = useSelector((state) => state.user);
  
  const [EmpImage, setEmpImage] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    axios
      .get(`/agilesaas_notifications?NotificationId=eq.${data.NotificationId}`)
      .then((res) => {
      
      })
      .catch((e) => {
        console.log(e);
      });
    setEmpImage(
      allImage?.length > 0
        ? allImage?.filter((x) => x.teammemberid == data.CreatedBy?.id)?.[0]
            ?.image
        : Unassigned
    );
    //   axios.get(`/api/agile/filesystem/EmployeeImage/${data.ClientDetails.clientid}/${data.CreatedBy?.id}_${data.CreatedBy.name}_${data.CreatedBy.lastname?data.CreatedBy.lastname:""}.png`, {
    //     responseType: "arraybuffer",
    // }).then(
    //   res => {
    //     const arrayBufferView = new Uint8Array(res.data);
    //     const blob = new Blob([arrayBufferView], { type: "image/png" });
    //     const imageUrl = URL.createObjectURL(blob);

    //     console.log(imageUrl);
    //     setEmpImage( imageUrl)

    //   }).catch(e => {

    //   })
  }, []);

  const handleNotification = (value) => {
    // http://localhost:2002/api/notification/markread?id=
    // axios.patch(`/agilesaas_notifications?NotificationId=eq.${value.NotificationId}`,
    //     {
    //       "IsDelivered": "Y",
    //     "IsAcknowledged": "Y",
    //     }
    //   )
    let patchdata = {
      ...user,
      IsDelivered: "Y",
      IsAcknowledged: "Y",
    };
    axios
      .patch(
        `/api/agile/notification/markread?id=${value.NotificationId}`,
        patchdata
      )
      .then((res) => {
        getnewdata();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="Notifications" onClick={() => handleNotification(data)}>
      <div
        className="Notifications_left "
        style={{ width: "100%", overflow: "hidden", paddingLeft: "5px" }}
      >
        {EmpImage ? (
          <img className="Notifications_Image" src={EmpImage} />
        ) : (
          <img
            className="Notifications_Image"
            src={data.CreatedBy.name == "system" ? systemimage : notfound}
          />
        )}

        <NavLink tag="li" className="Notifications_items">
          <div
            className="ml-2 "
            style={{
              // width: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <label
              style={{
                maxWidth: "150px",
                fontSize: "14px",
                height: "auto",
                color: "black",
                fontWeight: "bolder",
                wordWrap: "break-word",
                // overflow:"visible"
              }}
            >
              {data.NotificationTitle}
            </label>
            <div
              className="ml-3"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100px",
              }}
            >
              <span className="ml-2" style={{ fontSize: "12px" }}>
                {moment(data.GeneratedDateTime).format("DD-MM-YYYY")}
              </span>
              <span className="ml-2" style={{ fontSize: "12px" }}>
                {" "}
                {moment(data.GeneratedDateTime).format("hh:mm a")}
              </span>
            </div>
          </div>

          <label
            className="ml-2 mt-0.5"
            style={{ width: "250px", whiteSpace: "pre-line" }}
          >
            {data.NotificationDescription ? data.NotificationDescription : ""}
          </label>
        </NavLink>
      </div>

      <button
        style={{
          background: "tranparent",
          opacity: user.IsAcknowledged == "Y" ? 0 : 1,
        }}
        disabled={user.IsAcknowledged == "Y" ? true : false}
        onClick={(a) => handleNotification(data)}
        className="Notifications_Button"
      >
        <span
          style={{ background: "tranparent" }}
          className={
            user.IsAcknowledged == "Y" ? "" : "Notifications_unread-indicator"
          }
        ></span>
      </button>
    </div>

    //  <div className='notification' style={{ height: "50px", padding: "10px" ,display: "flex", justifyContent: "space-between", alignItems: "center",margin:"10px"}}>
    //       <div style={{
    //           display: "flex", justifyContent: "space-between", alignItems: "center", gap: "5px"
    //       }}>
    //           <div> <Avatar
    //                     alt=""
    //                     src={
    //                       EmpImage
    //                       // empDetails?.[0]?.TeamMemberId == 1 ? Admin : empDetails?.[0]?.TeamMemberId == 103 ? Alen :
    //                       // empDetails?.[0]?.TeamMemberId == 37 ? Rajan :
    //                       //   empDetails?.[0]?.TeamMemberId == 114 ? Suren :
    //                       //     empDetails?.[0]?.TeamMemberId == 2 ? Kandhasamy :
    //                       //       empDetails?.[0]?.TeamMemberId == 81 ? Aravindh : Unassigned
    //                     }
    //                     sx={{ width: 40, height: 40 }}
    //                     // sx={{ bgcolor: "#1d2433", width: 30, height: 30, fontSize: 12 }}
    //                   >
    //                     GK
    //           </Avatar></div>
    //           <div style={{ display: "flex", justifyContent: "space-between" }}>
    //           <p style={{width:"300px"}}>dev-581: New story is assigned to you</p>
    //           <span style={{width:"10px"}}><a className='read'>✓</a></span>
    //           </div>

    //       </div>
    //   </div>
  );
};

export default Notify;

// <div className='notification' style={{ height: "50px", padding: "10px" ,display: "flex", justifyContent: "space-between", alignItems: "center",margin:"10px"}}>
//     <div style={{
//         display: "flex", justifyContent: "space-between", alignItems: "center", gap: "5px"
//     }}>
//         <div> <Avatar
//                   alt=""
//                   src={
//                     userImage
//                     // empDetails?.[0]?.TeamMemberId == 1 ? Admin : empDetails?.[0]?.TeamMemberId == 103 ? Alen :
//                     // empDetails?.[0]?.TeamMemberId == 37 ? Rajan :
//                     //   empDetails?.[0]?.TeamMemberId == 114 ? Suren :
//                     //     empDetails?.[0]?.TeamMemberId == 2 ? Kandhasamy :
//                     //       empDetails?.[0]?.TeamMemberId == 81 ? Aravindh : Unassigned
//                   }
//                   sx={{ width: 40, height: 40 }}
//                   // sx={{ bgcolor: "#1d2433", width: 30, height: 30, fontSize: 12 }}
//                 >
//                   GK
//         </Avatar></div>
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//         <p style={{width:"300px"}}>dev-581: New story is assigned to you</p>
//         <span style={{width:"10px"}}><a className='read'>✓</a></span>
//         </div>

//     </div>
// </div>
