const dropDownConstants = {
    Tech: [
        {
            id: "1",
            name: "techstack",
            tech: "JAVA - spring mvc web",
            isActive: true,
            value: "JAVA",
            secondvalue: "JAVA",
        },

        {
            id: "2",
            name: "techstack",
            tech: "Reactjs (Front-End)",
            isActive: true,
            value: "Reactjs",
            secondvalue: "MERN",
            fullstack: "Front End",
        },
        {
            id: "3",
            name: "techstack",
            tech: "React Native (Front-End)",
            isActive: true,
            value: "React Native",
            secondvalue: "MERN",
            fullstack: "React Native",
        },
        {
            id: "5",
            name: "techstack",
            tech: "Expressjs / Nodejs (Backend)",
            isActive: true,
            value: "Expressjs / Nodejs ",
            secondvalue: "MERN",
            fullstack: "Back End",
        },
        {
            id: "1",
            name: "techstack",
            tech: "JAVA - Spring Boot",
            isActive: true,
            value: "JAVASPRING",
            secondvalue: "SPRING",
        },
        {
            id: "4",
            name: "techstack",
            tech: "Angular (Front end)",
            isActive: false,
            value: "Angular",
            secondvalue: "MEAN",
            fullstack: "Front End",
        },
        {
            id: "6",
            name: "techstack",
            tech: "J2EE (leagacy) - Coming soon!!",
            isActive: false,
            value: "",
        },
        {
            id: "7",
            name: "techstack",
            tech: "Python - Coming soon!!",
            isActive: false,
            value: "",
        },
        {
            id: "8",
            name: "techstack",
            tech: "Microsoft.net -Coming soon!! ",
            isActive: false,
            value: "",
        },
        {
            id: "9",
            name: "techstack",
            tech: "PHP - Coming soon!!",
            isActive: false,
            value: "",
        },
    ],

    JobSchedule: [
        { id: "1", name: "Daily" },
        { id: "2", name: "Two day Once" },
        { id: "3", name: "Three day Once" },
        { id: "4", name: "Weekly Once" },
        { id: "5", name: "Two Week Once" },
        { id: "6", name: "Monthly Once" },
    ],
    JobScheduleHour: [
        { id: "1", name: "00" },
        { id: "2", name: "01" },
        { id: "3", name: "02" },
        { id: "4", name: "03" },
        { id: "5", name: "04" },
        { id: "6", name: "05" },
        { id: "7", name: "06" },
        { id: "8", name: "07" },
        { id: "9", name: "08" },
        { id: "10", name: "09" },
        { id: "11", name: "10" },
        { id: "12", name: "11" },
        { id: "13", name: "12" },
        { id: "14", name: "13" },
        { id: "15", name: "14" },
        { id: "16", name: "15" },
        { id: "17", name: "16" },
        { id: "18", name: "17" },
        { id: "19", name: "18" },
        { id: "20", name: "19" },
        { id: "21", name: "20" },
        { id: "22", name: "21" },
        { id: "23", name: "22" },
        { id: "24", name: "23" },
    ],
    JobScheduleTime: [
        { id: "1", name: "10" },
        { id: "2", name: "20" },
        { id: "3", name: "30" },
        { id: "4", name: "40" },
        { id: "5", name: "50" },
        { id: "6", name: "00" },
    ],
    Plan: [
        { id: "21002", value: "1", label: "Free" },
        { id: "21001", value: "2", label: "Business Standard" },
    ],
    End: [
        { id: "1", name: "Front End" },
        { id: "2", name: "Back End" },
        { id: "3", name: "React Native" },
    ],
    Cloud: [
        { id: "aws", name: "AWS" },
        { id: "azure", name: "Azure" },
    ],
    Server: [
        { id: "1", name: "IntegrationServer" },
        { id: "2", name: "StagingServer" },
    ],
    ServerType: [
        { id: "existing", name: "Existing Server" },
        { id: "new", name: "New Server" },
    ],
    MemberRole: [
        { id: "1", name: "Super Admin" },
        { id: "2", name: "Admin" },
        { id: "3", name: "Manager" },
        { id: "4", name: "Maintainer" },
        { id: "5", name: "Developer" },
    ],
    JavaVersions: [
        { id: "java-14", name: "Java SE 14" },
        { id: "java-11", name: "Java SE 11" },
        { id: "java-1.8", name: "Java SE 8" },
    ],
    ServerVendors: [
        { id: "tomcat", name: "Apache Tomcat", disable: true },
        { id: "weblogic", name: "Oracle WebLogic Comming Soon !", disable: false },
        {
            id: "wildfly",
            name: "WildFly (Formerly JBoss) Comming Soon",
            disable: false,
        },
    ],
    wildflyVersions: [
        { id: "19.0.0.final", name: "19.0.0.Final" },
        { id: "18.0.0.final", name: "18.0.0.Final" },
        { id: "17.0.0.final", name: "17.0.0.Final" },
    ],
    weblogicVersions: [
        { id: "12.2.1.4", name: "WebLogic Server 12.2.1.4" },
        { id: "12.1.3", name: "WebLogic Server 12.1.3" },
        { id: "10.3.6", name: "WebLogic Server 10.3.6" },
    ],
    tomcatVersions: [
        { id: "10.0.0-M6", name: "10.0.0-M6 (alpha)" },
        { id: "9.0.36", name: "9.0.36" },
        { id: "8.5.56", name: "8.5.56" },
    ],
    EnvCards: [
        { id: 1, name: "Development" },
        { id: 2, name: "Test" },
        { id: 3, name: "UAT" },
        { id: 4, name: "Preproduction" },
        { id: 5, name: "Production" },
        { id: 6, name: "Disaster Recovery" },
    ],
    Sub_pan: [
        {
            id: 21002,
            value: "1",
            label: "Free",
            Allowed: {
                Project: 1,
                Admin_Users: 1,
                Users: 0,
                Database: 1,
                Cloud_subscriptions: 1,
                code_repo_vendor: 1,
                Create_repo: false,
                Containerization: false,
                SSO_LDAP: false,
                Multiple_Departments: false,
                Capacity_Planning: false,
                Artefact_Repo: false,
                On_premise: false,
            },
        },
        {
            id: 21001,
            value: "2",
            label: "Business Standard",
            Allowed: {
                Project: 10,
                Admin_Users: 1,
                Users: 10,
                Database: 10,
                Cloud_subscriptions: 1,
                code_repo_vendor: 1,
                Create_repo: false,
                Containerization: false,
                SSO_LDAP: false,
                Multiple_Departments: false,
                Capacity_Planning: false,
                Artefact_Repo: false,
                On_premise: false,
            },
        },
        {
            id: 2401,
            value: "3",
            label: "Business Plus",
            Allowed: {
                Project: 20,
                Admin_Users: 1,
                Users: 20,
                Database: 20,
                Cloud_subscriptions: 2,
                code_repo_vendor: 2,
                Create_repo: true,
                Containerization: true,
                SSO_LDAP: false,
                Multiple_Departments: true,
                Capacity_Planning: false,
                Artefact_Repo: true,
                On_premise: false,
            },
        },
        {
            id: 3009,
            value: "4",
            label: "Enterprise",
            Allowed: {
                Project: 9999,
                Admin_Users: 2,
                Users: 9999,
                Database: 9999,
                Cloud_subscriptions: 9999,
                code_repo_vendor: 9999,
                Create_repo: true,
                Containerization: true,
                SSO_LDAP: true,
                Multiple_Departments: true,
                Capacity_Planning: true,
                Artefact_Repo: true,
                On_premise: true,
            },
        },
    ],
};

export default dropDownConstants;
