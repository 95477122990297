import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import axios from "../../axios";
import ReactImageFallback from "react-image-fallback";
import fileaxios from "../../filesAxios";
import "./ScrumTeam.css";
import { Card, Row, Col } from "reactstrap";
import avatar from "../../assets/images/faces/default-avatar.png";
import po from "../../assets/images/faces/images.png";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useDispatch, useSelector } from "react-redux";

function Scrumteam({
  // projectName,
  // projectCode,
  // FilteredData,
  // EmpDropDownData,
}) {
  let Empid = localStorage.getItem("token");
  const [empimages, setempimages] = useState([]);
  const [emprole, setemprole] = useState("");
  const {
    allImage,
    KanbanBoardData,
    projectCode,
    releases,
    filesContent,
    settingDetails,
    selectedCard1,
    projectRoleName,
    SelectedCardId,
    FilteredData,
    EmpDropDownData
  } = useSelector((state) => state.user);
  useEffect(() => {
    fileaxios
      .get(`EmployeeImages/${Empid}.jpg`)
      .then((res) => {
        setempimages(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const createby = FilteredData?.map((e) => e.CreatedByDetails);

  const tester = FilteredData?.map(
    (e) => e.AssignedToUATDetails.FN + " " + e.AssignedToUATDetails.LN
  );

  const employee = EmpDropDownData?.map((e) => e.empfn + " " + e.empln);


  return (
    <Row className="cardview">
      <div>
        <ReactImageFallback
          src={po}
          // fallbackImage={require(avatar).default}
          initialImage="loader.gif"
          alt="cool image should be here"
          className=""
          style={{
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            marginLeft: "500px",
          }}
        />
        <br></br>
        <br></br>
        <div>
          <span style={{ marginLeft: "500px" }}>Product owner</span>
        </div>
        <label className="icon1">
          <span>
            <ArrowDownwardIcon></ArrowDownwardIcon>
          </span>
        </label>
        <div style={{ marginLeft: "250px" }}>
          <ReactImageFallback
            src={avatar}
            // fallbackImage={require(avatar).default}
            initialImage="loader.gif"
            alt="cool image should be here"
            className="mr-5 ml-3"
            style={{ height: "100px", width: "100px", borderRadius: "50%" }}
          />
          {/* <span className="" >{createby[0]}</span> */}

          <ReactImageFallback
            src={avatar}
            // fallbackImage={require("assets/img/default-avatar.png").default}
            initialImage="loader.gif"
            alt="cool image should be here"
            className="mr-5"
            style={{ height: "100px", width: "100px", borderRadius: "50%" }}
          />

          {/* <span className="">{"qqqqq"}</span> */}

          {/* <span className=" badge badge-white">Query Analyst</span> */}

          {/* <label className="icon">
          <span>
            <ArrowDownwardIcon></ArrowDownwardIcon>
          </span>
        </label> */}

          <ReactImageFallback
            src={avatar}
            // fallbackImage={require("assets/img/default-avatar.png").default}
            initialImage="loader.gif"
            alt="cool image should be here"
            // className="mr-5"
            style={{
              height: "70px",
              width: "70px",
              borderRadius: "50%",
              marginLeft: "15px",
            }}
          />

          {/* <span className=" ">Senior Developer</span> */}

          <ReactImageFallback
            src={avatar}
            // fallbackImage={require("assets/img/default-avatar.png").default}
            initialImage="loader.gif"
            alt="cool image should be here"
            // className="mr-5"
            style={{
              height: "70px",
              width: "70px",
              borderRadius: "50%",
              marginLeft: "60px",
            }}
          />

          {/* <span className="employee1">{employee[1]}</span> */}
          {/* <span className="">Developer</span> */}

          <ReactImageFallback
            src={avatar}
            // fallbackImage={require("assets/img/default-avatar.png").default}
            initialImage="loader.gif"
            alt="cool image should be here"
            // className="mr-5"
            style={{
              height: "70px",
              width: "70px",
              borderRadius: "50%",
              marginLeft: "50px",
            }}
          />

          {/* <span className="employee2">{employee[2]}</span> */}
          {/* <span className="">Developer</span> */}
          <Col>
            {" "}
            <span className="mr-2 badge badge-secondary">
              {createby[0]}
            </span>{" "}
            <span className=" ml-5 badge badge-secondary">Query Analyst</span>{" "}
            <span className=" ml-5 badge badge-secondary">
              Senior Developer
            </span>
            <span className=" ml-5 badge badge-secondary">Developer</span>
            <span className=" ml-5 badge badge-secondary">Developer</span>
          </Col>
        </div>
      </div>
    </Row>
  );
}

export default Scrumteam;
