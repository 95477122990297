import React, { useEffect, useState } from 'react';
import TeamChart from './TeamChart';
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Unassigned from "../../assets/images/avatar.png";
import { HANDLE_ON_CHANGE } from '../../redux/CommonInitialsate';

function TeamChartDesign({
    // scrumchart, setscrumchart
    // , projectCode
}) {
    const dispatch = useDispatch();
    const {
        allImage,
        projectCode,
        scrumchart
    } = useSelector((state) => state.user);
    const [Show, setShow] = useState(false)
    const [rolesData, setrolesData] = useState([])
    const [userData, setuserData] = useState([])

    useEffect(() => {
        let clientd = JSON.parse(localStorage.getItem("clientid"));

        const api1 = "https://api.devozy.ai/subscription_client_users?ClientId=eq." + clientd
        const api2 = "https://api.devozy.ai/agilesaas_project_mapping?ProjectCode=eq." + projectCode
        axios
            .get(api1)
            .then((response) => {
                setuserData(response.data)
                axios
                    .get(api2)
                    .then((res) => {
                        setrolesData(res.data)
                        setShow(true)
                    })
                    .catch((error) => setShow(true));

            })
            .catch((error) => setShow(true));


    }, [projectCode]);


    // console.log(rolesData, userData, allImage)

    const userIdToFirstNameMap = {};
    userData.forEach(user => {
        userIdToFirstNameMap[user.ClientUserId] = user.UserFirstname;
    });
    const resultArray = [];
    const uniqueRoles = [];
    const alternativeColors = ["#DFFBD2", "#FFE896", "#E0F4FF"]; // Define alternative colors    
    rolesData?.forEach((role, index) => {
        const title = role.ProjectRole.RoleName;

        // Check if the role is not already in uniqueRoles
        if (!uniqueRoles.includes(title)) {
            const members = rolesData
                .filter(r => r.ProjectRole.RoleName === title)
                .map(r => {
                    const user = userData.find(u => u.ClientUserId === r.TeamMemberId);
                    const avatar = allImage?.length > 0 ? allImage.find((e) => parseInt(e.teammemberid) === parseInt(r.TeamMemberId)) : Unassigned
                    return { Lname: user ? user?.UserLastname : "unassigned", name: user ? user?.UserFirstname : "unassigned", avatar: avatar ? avatar.image : null };
                });

            if (members.length > 0) {
                // Use an alternative color based on the index
                const color = alternativeColors[resultArray.length % alternativeColors.length];
                resultArray.push({ title, members, color });
            }

            // Add the role to uniqueRoles
            uniqueRoles.push(title);
        }
    });


    const quartersData = resultArray
    return (
        <Dialog
            open={scrumchart}
            onClose={() => {
                // setscrumchart(false)
                dispatch(HANDLE_ON_CHANGE({ name: 'scrumchart', value: false }))
            }}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>
                <div className="row">
                    <div className="col d-flex justify-content-start"><label className="text-secondary fontdesign" style={{ fontWeight: "bold" }}>
                        Scrum team chart
                    </label></div>
                    <div className="col d-flex justify-content-end" role="button" onClick={() =>
                        // setscrumchart(false)
                        dispatch(HANDLE_ON_CHANGE({ name: 'scrumchart', value: false }))
                    }><CloseIcon /></div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="d-flex justify-content-center" >
                    <div className="App" style={{ overflow: "hidden" }}>

                        {Show ? rolesData.length > 1 ? <><TeamChart numQuarters={quartersData.length} quartersData={quartersData} /></> : <div
                            className=" d-flex align-items-center"
                            style={{ justifyContent: "center", height: 500 }}
                        >
                            <span
                                className="mdi mdi-account-alert mr-2"
                                role="status"
                                aria-hidden="true"
                                style={{ color: "rgb(82, 80, 229)" }}
                            ></span>No user to display
                        </div> : <div
                            className=" d-flex align-items-center"
                            style={{ justifyContent: "center", height: 500 }}
                        >
                            <span
                                className="spinner-border mr-2"
                                role="status"
                                aria-hidden="true"
                                style={{ color: "rgb(82, 80, 229)" }}
                            ></span>fetching...
                        </div>}

                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default TeamChartDesign;
