import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Progress,
} from "reactstrap";
import CreateKb from "../CreateKb";
import Select from "react-select";
import "../CreateKb";
import axios from "../../../axios";
import KanbanDashboard from "../KanbanDashboard";
import Release from "../Release";
import "../kanbanBoard.css";
import MaterialTable from "material-table";
import PatchedPagination from "../../../PatchedPagination";
import moment from "moment";
import ProgressBar from "react-bootstrap/ProgressBar";
import BarChartIcon from "@mui/icons-material/BarChart";
import "../Showrelease.css";
import {
  OverlayTrigger,
  Popover,
  Tooltip,
  PopoverBody,
  PopoverHeader,
  FormLabel,
} from "react-bootstrap";
import Moment from "moment";
import { Bar, Line } from "react-chartjs-2";
import {
  BottomNavigation,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { styled } from "@material-ui/styles";
import html2canvas from "html2canvas";
import VelocityReportTable from "./VelocityReportTable";
import SprintReportTable from "./SprintReportTable";
import SprintChart from "./SprintChart";
import SprintBurnup from "./SprintBurnup";
import CFDChart from "./CFDChart";
import HistoryReports from "./HistoryReports";
import Historyburndown from "./Historyburndown";
import Historyburnupanddown from "./Historyburnupanddown";
import HistorySprintReportTable from "./HistorySprintReportTable";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Slider from "@mui/material/Slider";
import { useDispatch, useSelector } from "react-redux";
import { PERCENTAGE } from "../../../redux/CommonInitialsate";
import jsPDF from "jspdf";
import { toast, Slide } from "react-toastify";

toast.configure();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiBox-root": {
      paddingTop: 2,
      margin: 1,
    },
    "& .MuiAppBar-root": {
      // boxShadow: "none", // Remove the box shadow from the tab header
      paddingLeft: 0,
      margin: 0,
    },
    "& .MuiTabs-root": {
      display: "flex", // Use flex layout
      justifyContent: "flex-start",
    },
    // Rest of your styles...
  },
}));
function Report({
  //  projectName,
  //  Assetoptions
}) {
  const { percentage, projectRole, projectCode, Assetoptions, projectName, releasedata } = useSelector((state) => state.user);
  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == "11004")?.[0]
    ?.RoleCode;

  let arr = JSON.parse(localStorage.getItem("Empdetails"));
  let EMpid =
    projectRole == "SM" ||
      projectRole == "ITMGR2" ||
      projectRole == "QA" ||
      getProdRole == "KA" ? arr?.[0]?.ClientUserId : arr?.[0]?.ClientUserId;
  // console.log(EMpid, "arr");

  // let arr = JSON.parse(localStorage.getItem("Empdetails"));
  // let EMpid = arr[0]?.TeamMemberId;
  // let rolecode = arr.rolecode;
  let email = JSON.parse(localStorage.getItem("userdata"));
  let clientid = JSON.parse(localStorage.getItem("clientid"));

  const [agile_issue, setAgileissue] = useState(null);
  const [agile_relese, setAgilerelese] = useState(null);
  const [agile_report, setAgilereport] = useState(null);
  const [agile_history, setAgilehistory] = useState(null);
  const [projectId, setProjectId] = useState(projectCode);
  const [userID, setUserid] = useState(null);
  const [releaseID, setreleaseID] = useState(null);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [labellength, setlabellength] = useState(0);
  const [Story, setstory] = useState([]);
  const [Task, settask] = useState([]);
  const [Bug, setbug] = useState([]);
  const [Story1, setstory1] = useState([]);
  const [Task1, settask1] = useState([]);
  const [Bug1, setbug1] = useState([]);
  // const [comit, setcomit] = useState([]);
  const [historydata, sethistory] = useState([]);
  // const [issuehistory, setissuehistory] = useState([]);
  const [namestory1, setnamestory1] = useState([]);
  // const [nametask1, setnametask1] = useState([]);
  // const [namebug1, setnamebug1] = useState([]);
  // const [issue, setissue] = useState([]);
  const [reportType, setReportType] = useState("velocity");
  const [futuredata, setFuturedata] = useState([]);
  const [releaseversion, setReleaseversion] = useState([]);
  const [committask, setCommittask] = useState([]);
  const [completetask, setCompletetask] = useState([]);
  const [commitbug, setCommitbug] = useState([]);
  const [completebug, setCompletebug] = useState();
  const [tabledata, setTabledata] = useState([]);
  const [commitstory, setCommitstory] = useState([]);
  const [completestory, setCompletestory] = useState([]);
  const [sprintdata, setSprintData] = useState([]);
  const [percentagearr, setPercentagearr] = useState([]);
  const [percentagearr1, setPercentagearr1] = useState([]);
  console.log(sprintdata, "sprintdata");
  // const [percentage, setPercentage] = useState([]);
  const [percentage1, setPercentage1] = useState([]);

  const [chartdata, setChartdata] = useState([]);
  const [chartdata1, setChartdata1] = useState([]);

  const [displaydata, setDisplayData] = useState([]);
  const [displaydata1, setDisplayData1] = useState([]);
  const [employeeDropdown, setEmployeeDropdown] = useState([]);
  const [chart, setCharts] = useState([]);
  const [chart1, setCharts1] = useState([]);

  const [cmt, setcmt] = useState("");
  const [cmt1, setcmt1] = useState("");
  const [cmt2, setcmt2] = useState("");
  const [cmt3, setcmt3] = useState("");

  const [predata, setpredata] = useState([]);
  const [predata1, setpredata1] = useState([]);

  const [versionDays, setVersionDays] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [reportdata, setreportdata] = useState([]);
  const [historyOptions, setHistoryOptions] = useState([]);
  const [checkRelease, setCheckRelease] = useState(false);
  const dispatch = useDispatch();

  // const pdfRef = useRef(null);

  // const downloadPDF = () => {
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const content = pdfRef.current;

  //   html2canvas(content).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
  //     pdf.save("download.pdf");
  //   });
  // };
  const pdfRef = useRef(null);

  // const downloadPDF = async () => {
  //   setLoader(true);
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const content = pdfRef.current;

  //   const divs = content.querySelectorAll("div#op");

  //   for (let i = 0; i < divs.length; i++) {
  //     const div = divs[i];

  //     try {
  //       const canvas = await html2canvas(div, { scale: 2, useCORS: true });

  //       // Check if canvas data is empty or corrupt
  //       if (canvas.toDataURL("image/png").indexOf("data:image/png") !== 0) {
  //         // console.error(`Error: HTML2Canvas failed for div ${i + 1}`);
  //         continue; // Skip to the next iteration
  //       }

  //       const imgData = canvas.toDataURL("image/png");

  //       if (i > 0) {
  //         pdf.addPage();
  //       }

  //       pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
  //       setLoader(false);
  //     } catch (error) {
  //       // console.error(`Error: HTML2Canvas operation failed for div ${i + 1}`);
  //       console.error(error);
  //     }
  //   }

  //   pdf.save(
  //     reportType == "velocity"
  //       ? "Velocity Report.pdf"
  //       : reportType == "Historyreport"
  //       ? "History Report.pdf"
  //       : "Current Sprint Reports.pdf"
  //   );
  // };

  const downloadPDF = async () => {
    setLoader(true);
    const pdf = new jsPDF("p", "mm", "a4");
    const content = pdfRef.current;

    const divs = content.querySelectorAll("div#op");

    for (let i = 0; i < divs.length; i++) {
      const div = divs[i];

      try {
        const canvas = await html2canvas(div, { scale: 2, useCORS: true });
        const imgData = canvas.toDataURL("image/jpeg", 0.7); // Adjust quality (0.0 to 1.0)

        if (i > 0) {
          pdf.addPage();
        }

        pdf.addImage(imgData, "JPEG", 10, 10, 190, 0);
        setLoader(false);
      } catch (error) {
        console.error(`Error: HTML2Canvas operation failed for div ${i + 1}`);
        console.error(error);
      }
    }
    const re = releaseID ? releaseID : "";
    const ver = releaseID ? releaseID : releaseversion?.[0];

    pdf.save(
      reportType === "velocity"
        ? `Velocity_Report_${re}_${moment().format("DD-MM-YYYYTHH:mm a")}.pdf`
        : reportType === "Historyreport"
          ? `History_Report_${ver}_${moment().format("DD-MM-YYYYTHH:mm a")}.pdf`
          : "Current_Sprint_Reports_" +
          sprintdata?.[0]?.releasename +
          "_" +
          moment().format("DD-MM-YYYYTHH:mm a") +
          ".pdf"
    );
  };

  const BootstrapButton = styled(Button)({
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    margin: 0,
    border: "2px solid grey",
    borderRadius: 25,
    cursor: "pointer",
  });

  const useStyles = makeStyles({
    root: {
      color: "rgb(254, 192, 84)", // Default color
    },
    greenSlider: {
      color: "green",
    },
    yellowSlider: {
      color: "yellow",
    },
    orangeSlider: {
      color: "orange",
    },
    redSlider: {
      color: "red",
    },
  });
  const classes = useStyles();
  const getColor = (percentage) => {
    if (percentage >= 75) return classes.greenSlider;
    else if (percentage >= 50) return classes.yellowSlider;
    else if (percentage == 0) return classes.redSlider;
    else return classes.root; // Default color
  };
  // console.log(releaseID);
  // console.log(releaseversion?.[0]);
  const multiplesm = localStorage.getItem("multiplesm") == "fullaccess_to_all_sms"
  const currentsprint = multiplesm ? `rpc/fun_agilesaascurrentsprint?projid=${projectCode}` : `rpc/fun_agilesaascurrentsprint?projid=${projectCode}&createdby=eq.${EMpid}`
  useEffect(() => {
    // let rid = releaseID == null ? releaseversion?.[0] : releaseID;
    // axios
    //   .get(
    //     "rpc/fun_agilesaasproductivityreport?projid=" +
    //       projectCode +
    //       "&releasename=eq." +
    //       rid
    //   )
    //   .then((response) => {
    //     setpredata(response.data);
    //     // console.log(response.data);
    //     let data1 = response.data;
    //     data1.map((e, i) => {
    //       axios
    //         .get(
    //           "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=eq." +
    //             e.issueid +
    //             "&order=UpdatedDate.desc"
    //         )
    //         .then((result) => {
    //           let data = result.data.map((e) => {
    //             return {
    //               EffortPending: e.EffortPending,
    //               EffortRemaining: e.EffortRemaining,
    //               RiskofDelivery: e.RiskofDelivery,
    //               UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
    //               IssueId: e.IssueId,
    //               UpdatedBy: e.UpdatedBy,
    //               PercentageProgressed: e.PercentageProgressed,
    //             };
    //           });

    //           displaydata1.push(data);
    //         });
    //     });
    //   })
    //   .catch((error) => {
    //     console.log("somthig error");
    //   });
    axios.get(currentsprint)
      .then((res) => {
        console.log(res.data, "redata")
        setSprintData(res.data);
        const getdata = res.data;
        const disdataid = getdata?.map((e) => e.issueid);
        const issueIds = disdataid?.join(",");

        // getdata.map((e, i) => {
        //   axios
        //     .get(
        //       "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=eq." +
        //         e.issueid +
        //         "&order=UpdatedDate.desc"
        //     )
        //     .then((result) => {
        //       let data = result.data.map((e) => {
        //         return {
        //           EffortPending: e.EffortPending,
        //           EffortRemaining: e.EffortRemaining,
        //           RiskofDelivery: e.RiskofDelivery,
        //           UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
        //           IssueId: e.IssueId,
        //           UpdatedBy: e.UpdatedBy,
        //           PercentageProgressed: e.PercentageProgressed,
        //         };
        //       });

        //       displaydata.push(data);
        //     });
        // });
        axios
          .get(
            `agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=in.(${issueIds})&order=UpdatedDate.desc`
          )
          .then((result) => {
            let data = result.data.map((e) => {
              return {
                EffortPending: e.EffortPending,
                EffortRemaining: e.EffortRemaining,
                RiskofDelivery: e.RiskofDelivery,
                UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
                IssueId: e.IssueId,
                UpdatedBy: e.UpdatedBy,
                PercentageProgressed: e.PercentageProgressed,
              };
            });
            displaydata.push(data);
          });
      });
    refreash(projectCode);
    // rpc/fun_mgrprojects?managerid=${EMpid}
    // axios
    //   .get(
    //     `rpc/fun_agilesaascurrentsprint?projid=${projectCode}&createdby=eq.${EMpid}`
    //   )
    //   .then((res) => {
    //     // console.log(res.data, "currentrelease");
    //     setSprintData(res.data);
    //   });
    // axios
    //   .get(
    //     `rpc/fun_agilesaasuserprojdetails?userid=` +
    //     email?.login +
    //     `&companyid=eq.` +
    //     clientid
    //   )
    //   .then((res) => {
    //     setAgileissue(res.data);
    //   })
    //   .catch((err) => console.error(err));
    // axios
    //   .get(`agilesaas_issue_history?ProjectId=eq.${projectCode}`)
    //   .then((res) => {
    //     setAgilehistory(res.data);
    //   })
    //   .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    // console.log(reportType);
    if (reportType == "Historyreport") {
      // console.log(releaseversion?.[0], releaseversion);
      let rid = releaseID == null ? releaseversion?.[0] : releaseID;
      axios
        .get("agilesaas_releases?ReleaseName=eq." + rid)
        .then((response) => {
          // console.log(response);
          setreportdata(response.data);
          setIsLoading(false);
          // alert("success");
        })
        .catch((err) => {
          console.error(err);
        });
      const label = historyOptions.length > 0 && releaseversion.length == 0;
      updatsprint(rid, label ? "To be released" : "");
    }
  }, [releaseID, releaseversion]);

  // console.log(reportdata);
  const filteredRelease = agile_report?.filter(
    (e) => e.actualrelease == releaseID
  );
  const ddate = agile_relese?.filter((e) => e.ReleaseName == releaseID);

  const planned = agile_report?.filter((e) => e.plannedrelease == releaseID);

  let storyplanned = planned?.filter((d) => d.issuetype == "Story").length;
  let taskplanned = planned?.filter(
    (d) => d.issuetype == "Task" || d.issuetype == "Story"
  ).length;
  let bugplanned = planned?.filter((d) => d.issuetype == "Bug").length;

  let allissue = agile_report
    ?.filter((e) => e.projectId === projectId || e.actualrelease === releaseID)
    ?.map((b) => b.issueid)
    ?.toString();
  // console.log(
  //   agile_report,
  //   "agile_report",
  //   allissue,
  //   "allissue",
  //   projectId,
  //   releaseID
  // );
                          
  // function history() {
  //   axios
  //     .get(`agilesaas_issue_history?IssueId=in.(${allissue})`)
  //     .then((res) => {
  //       setAgilehistory(res.data);
  //       // console.log(res.data, "responseissue");
  //     })

  //     .catch((err) => console.error(err));
  // }
  const updatsprint = (value, label) => {
    // console.log(releaseversion, "checkreleaselabel");
    axios
      .get(
        "rpc/fun_agilesaasproductivityreport?projid=" +
        projectCode +
        "&releasename=eq." +
        value
      )
      .then(async (response) => {
        setpredata(response.data);
        if (label.includes("To be released")) {
          let data1 = response.data;
          let temp = moment(data1?.[0]?.sprintenddate).diff(
            moment(data1?.[0]?.sprintstartdate),
            "day"
          );
          const diff =
            temp == 0
              ? 1
              : Number(
                Math.ceil(
                  (new Date(data1?.[0]?.sprintenddate) -
                    new Date(data1?.[0]?.sprintstartdate)) /
                  (1000 * 60 * 60 * 24)
                ) + 1
              );
          const includays1 = workingDays(data1?.[0]?.sprintstartdate, diff + 0);
          setVersionDays(includays1);
          // console.log(response.data);
          // const disdataid = data1?.map((e) => e.issueid);
          // const issueIds = disdataid?.join(",");
          // axios
          //   .get(
          //     `agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=in.(${issueIds})&order=UpdatedDate.desc`
          //   )
          //   .then((result) => {
          //     let data = result.data.map((e) => {
          //       return {
          //         EffortPending: e.EffortPending,
          //         EffortRemaining: e.EffortRemaining,
          //         RiskofDelivery: e.RiskofDelivery,
          //         UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
          //         IssueId: e.IssueId,
          //         UpdatedBy: e.UpdatedBy,
          //         PercentageProgressed: e.PercentageProgressed,
          //       };
          //     });
          //     displaydata1.push(data);
          //   });
          // data1?.map((e, i) => {
          //   axios
          //     .get(
          //       "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=eq." +
          //       e.issueid +
          //       "&order=UpdatedDate.desc"
          //     )
          //     .then((result) => {
          //       let data = result.data.map((e) => {
          //         return {
          //           EffortPending: e.EffortPending,
          //           EffortRemaining: e.EffortRemaining,
          //           RiskofDelivery: e.RiskofDelivery,
          //           UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
          //           IssueId: e.IssueId,
          //           UpdatedBy: e.UpdatedBy,
          //           PercentageProgressed: e.PercentageProgressed,
          //         };
          //       });

          //       displaydata1.push(data);
          //     });
          // });
          const fetchAllData = async () => {
            const issueIds = data1.map(e => e.issueid).join(',');

            try {
              const response = await axios.get(
                `agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=in.(${issueIds})&order=UpdatedDate.desc`
              );

              const processedData = response.data.map(e => ({
                EffortPending: e.EffortPending,
                EffortRemaining: e.EffortRemaining,
                RiskofDelivery: e.RiskofDelivery,
                UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
                IssueId: e.IssueId,
                UpdatedBy: e.UpdatedBy,
                PercentageProgressed: e.PercentageProgressed,
              }));

              displaydata1.push(...processedData);

              // If you need to use displaydata1 here, make sure it's updated as expected
              console.log(displaydata1);
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };

          fetchAllData();
          // Assuming data1 is an array of objects with 'issueid' property
          // const fetchData = async () => {
          //   try {
          //     const axiosRequests = data1.map(async (e) => {
          //       const check = await axios.get(
          //         "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=eq." +
          //         e.issueid +
          //         "&order=UpdatedDate.desc"
          //       );
          //       // console.log(check.data, "get display data");
          //       return check.data.map((entry) => ({
          //         EffortPending: entry.EffortPending,
          //         EffortRemaining: entry.EffortRemaining,
          //         RiskofDelivery: entry.RiskofDelivery,
          //         UpdatedDate: moment(entry.UpdatedDate).format("DD-MMM-YYYY"),
          //         IssueId: entry.IssueId,
          //         UpdatedBy: entry.UpdatedBy,
          //         PercentageProgressed: entry.PercentageProgressed,
          //       }));
          //     });

          //     const result = await Promise.all(axiosRequests);
          //     const flattenedData = result.flat(); // Flatten the array of arrays into a single array

          //     return flattenedData;
          //   } catch (error) {
          //     console.error("Error fetching data:", error);
          //     throw error;
          //   }
          // };

          const fetchData = async () => {
            try {
              // Collect all IssueIdS values
              const issueIds = data1?.map(e => e.IssueIdS).join(',');

              if (!issueIds) {
                console.error("No IssueIdS values found in data1");
                return [];
              }

              // Make a single API request with all IssueIdS values
              const response = await axios.get(
                `agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=in.(${issueIds})&order=UpdatedDate.desc`
              );

              // Process the returned data
              const flattenedData = response.data.map(entry => ({
                EffortPending: entry.EffortPending,
                EffortRemaining: entry.EffortRemaining,
                RiskofDelivery: entry.RiskofDelivery,
                UpdatedDate: moment(entry.UpdatedDate).format("DD-MMM-YYYY"),
                IssueId: entry.IssueId,
                UpdatedBy: entry.UpdatedBy,
                PercentageProgressed: entry.PercentageProgressed,
              }));

              console.log(flattenedData, "flattenedData");
              return flattenedData;

            } catch (error) {
              console.error("Error fetching data:", error);
              throw error;
            }
          };

          // Usage:
          const fetchedData = await fetchData();
          // console.log(fetchedData); // Utilize the fetched data here

          // console.log(data1.length, fetchedData.length, "datacheck");
          // console.log(fetchedData, "displaydata1");
          updatesprintburntdown1(data1, userID, fetchedData);
          // console.log(idss, userID, "isdd");
          // in.(11004,11006)
        }
      })
      .catch((error) => {
        console.log("somthig error");
      });
  };
  let temp = moment(sprintdata?.[0]?.sprintenddate).diff(
    moment(sprintdata?.[0]?.sprintstartdate),
    "day"
  );
  const diff =
    temp == 0
      ? 1
      : Number(
        Math.ceil(
          (new Date(sprintdata?.[0]?.sprintenddate) -
            new Date(sprintdata?.[0]?.sprintstartdate)) /
          (1000 * 60 * 60 * 24)
        ) + 1
      );
  const includays1 = workingDays(predata?.[0]?.sprintstartdate, diff + 0);
  // console.log(diff);
  // useEffect(() => {
  //   if (reportType == "Historyreport") updatsprint();
  // }, [releaseID, releaseversion, userID]);

  function refreash(projectid) {

    const releaseapi = multiplesm ? `agilesaas_releases?ProjectId=eq.${projectid}` :
      `agilesaas_releases?ProjectId=eq.${projectid}&CreatedBy=eq.${EMpid}`

    const productivityapi = multiplesm ? `rpc/fun_agilesaasproductivityreport?projid=${projectid}`
      :
      `rpc/fun_agilesaasproductivityreport?projid=${projectid}&createdby=eq.${EMpid}`

    // console.log("refresh called", projectid);
    axios
      .get(
        `agilesaas_issue_details?CurrentStage->0->>StageName=in.(Refined,Backlog)&ProjectId=eq.${projectid}&IssueType=neq.Epic&IssueType=neq.Story&order=CreatedDate.desc&IsActive=eq.Y`
      )
      .then((res) => {
        setFuturedata(res.data.filter((e) => e.ActualRelease == null));
      });
    // history();
    axios.get(releaseapi)
      .then((response) => {
        setAgilerelese(response.data);
      })
      .catch((error) => console.log(error));
    axios.get(productivityapi)
      .then((res) => {
        // console.log(res.data, "response135");

        const resp = res.data
          .filter(
            (e) =>
              e.issuetype != "Epic" &&
              // e.issuetype != "Story" &&
              e.sprintstartdate != null &&
              e.sprintenddate != null
          )
          .sort((a, b) => a.releaseid - b.releaseid);
        // console.log(resp);
        let tobe = [
          ...new Map(resp?.map((obj) => [obj.releasename, obj])).values(),
        ]
          ?.filter(
            (e) =>
              moment().diff(moment(e.sprintenddate), "days") >= 0 &&
              e.plannedrelease != null &&
              e.actualrelease == null
          )
          ?.map((b) => (b.actualrelease ? b.actualrelease : b.plannedrelease));

        // console.log(new Set(resp));

        const releaselabel = Array.from(
          new Set(
            resp
              ?.filter(
                (e) => e.actualrelease !== null && e.actualrelease !== ""
                // ||
                // (e.plannedrelease !== null && e.plannedrelease !== "")
              )
              ?.map((b) =>
                b.actualrelease ? b.actualrelease : b.plannedrelease
              )
          )
        );
        setReleaseversion(releaselabel);
        setHistoryOptions(tobe);
        // console.log([...tobe, ...releaselabel]);
        // console.log(releaselabel);
        // axios
        //   .get(
        //     "rpc/fun_agilesaasproductivityreport?projid=" +
        //     projectCode +
        //     "&releasename=eq." +
        //     releaselabel?.[0]
        //   )
        //   .then((response) => {
        //     setpredata(response.data);
        //     // console.log(response.data);
        //     let data1 = response.data;

        //     data1.map((e, i) => {
        //       axios
        //         .get(
        //           "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=eq." +
        //           e.issueid +
        //           "&order=UpdatedDate.desc"
        //         )
        //         .then((result) => {
        //           let data = result.data.map((e) => {
        //             return {
        //               EffortPending: e.EffortPending,
        //               EffortRemaining: e.EffortRemaining,
        //               RiskofDelivery: e.RiskofDelivery,
        //               UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
        //               IssueId: e.IssueId,
        //               UpdatedBy: e.UpdatedBy,
        //               PercentageProgressed: e.PercentageProgressed,
        //             };
        //           });

        //           displaydata1.push(data);
        //         });
        //     });

        //     // console.log(idss, userID, "isdd");
        //     // in.(11004,11006)
        //   })
        //   .catch((error) => {
        //     console.log("somthig error");
        //   });
        setAgilereport(resp);

        let committed = [];
        let completed = [];
        const taskData = [];
        const bugData = [];
        const storyData = [];
        const comitTask = [];
        const comitBug = [];
        const comitStory = [];
        let committ = [];
        let completet = [];
        let commitb = [];
        let completeb = [];
        let commits = [];
        let completes = [];
        releaselabel.map((label) => {
          const ctdata = resp.filter(
            (e) =>
              (e.issuetype == "Task" || e.issuetype == "Story") &&
              e.plannedrelease == label
          );
          comitTask.push(ctdata.length);
          committ = [...ctdata, ...committ];
          const cbdata = resp.filter(
            (e) => e.issuetype == "Bug" && e.plannedrelease == label
          );
          comitBug.push(cbdata.length);
          commitb = [...cbdata, ...commitb];
          const tdata = resp.filter(
            (e) =>
              (e.issuetype == "Task" || e.issuetype == "Story") &&
              e.actualrelease == label
          );
          // console.log(
          //   resp.filter(
          //     (e) => e.issuetype == "Story" && e.actualrelease == label
          //   )
          // );
          taskData.push(tdata.length);
          completet = [...tdata, ...completet];
          const bdata = resp.filter(
            (e) => e.issuetype == "Bug" && e.actualrelease == label
          );
          bugData.push(bdata.length);
          completeb = [...bdata, ...completeb];
          if (
            resp.filter((e) => e.issuetype == "Bug" && e.actualrelease == label)
              .length == 0 &&
            resp.filter(
              (e) =>
                (e.issuetype == "Task" || e.issuetype == "Story") &&
                e.actualrelease == label
            ).length == 0
          ) {
            const csdata = resp.filter(
              (e) => e.issuetype == "Story" && e.plannedrelease == label
            );
            comitStory.push(csdata.length);
            commits = [...cbdata, ...commits];
            const sdata = resp.filter(
              (e) => e.issuetype == "Story" && e.actualrelease == label
            );
            storyData.push(tdata.length);
            completes = [...tdata, ...completes];
          }
        });
        const check =
          commitb.length == 0 &&
          committ.length == 0 &&
          completet.length == 0 &&
          completeb.length == 0;
        let employeeData = Assetoptions.map((ee) => {
          console.log(ee, "check ee")
          return {
            employeeid: ee.EmpId,
            employeename: ee.label,
            committed: check
              ? commitstory.filter((e) => e.assignedto == ee.EmpId).length
              : commitb.filter((e) => e.assignedto == ee.EmpId).length +
              committ.filter((e) => e.assignedto == ee.EmpId).length,
            completed: check
              ? completestory.length
              : completeb.filter((e) => e.assignedto == ee.EmpId).length +
              completet.filter((e) => e.assignedto == ee.EmpId).length,
            committedstory: commits.filter((e) => e.assignedto == ee.EmpId)
              .length,
            completedstory: completes.filter((e) => e.assignedto == ee.EmpId)
              .length,
            committedtask: committ.filter((e) => e.assignedto == ee.EmpId)
              .length,
            completedtask: completet.filter((e) => e.assignedto == ee.EmpId)
              .length,
            committedbug: commitb.filter((e) => e.assignedto == ee.EmpId)
              .length,
            completedbug: completeb.filter((e) => e.assignedto == ee.EmpId)
              .length,
            totalsprint: "",
            velocity: "",
          };
        });
        setEmployeeDropdown(employeeData.filter((e) => e.completed != 0));
        setTabledata(employeeData);
        setCommitbug(commitb);
        setCompletebug(completeb);
        setCommittask(committ);
        setCompletetask(completet);
        setCommitstory(commits);
        setCompletestory(completes);
        settask(taskData);
        setbug(bugData);
        setstory(storyData);
        settask1(comitTask);
        setbug1(comitBug);
        setstory1(comitStory);
      })

      .catch((err) => console.error(err, "error204"));
  }
  // console.log(Task);
  const labelfirst = agile_report?.map((e) =>
    e.sprintstartdate ? e.sprintstartdate : null
  );
  const labellast = agile_report?.map((e) =>
    e.sprintenddate ? e.sprintenddate : null
  );

  const vero = filteredRelease?.filter((r) => r.actualrelease);
  let versionof = vero?.map((f) => f.actualrelease)?.[0];

  let story = filteredRelease?.filter((d) => d.issuetype == "Story").length;
  let task = filteredRelease?.filter(
    (d) => d.issuetype == "Task" || d.issuetype == "Story"
  ).length;
  let bug = filteredRelease?.filter((d) => d.issuetype == "Bug").length;
  let totl = [story, task, bug];
  // console.log(Bug, Bug1);
  const full = [];
  full.push(totl);

  let mydata = filteredRelease?.filter((d) => d.issuetype).length;
  let storypoints = agile_history?.filter((d) => d.UpdatedTo).length;
  let projectname = Array.from(new Set(agile_issue?.map((e) => e.projectname)));
  let filteredData = Array.from(
    new Set(
      agile_report
        ?.filter((e) => e.assignedtodetails.FN + " " + e.assignedtodetails.LN)
        ?.map((f) => f.assignedtodetails.FN + " " + f.assignedtodetails.LN)
    )
  );

  // const history = agile_history?.filter(
  //   (e) => e.IssueId && e.UpdatedFrom && e.ChangedDate
  // );

  const ProjectOption = projectname?.map((e) => ({
    label: e,
    value: agile_issue?.filter((l) => l.projectname === e)?.[0]?.projectcode,
  }));

  const Empoptions = filteredData
    .filter((e) => e != "Un Assigned")
    ?.map((e) => ({
      label: e,
      value: agile_report?.find(
        (t) => e === t.assignedtodetails.FN + " " + t.assignedtodetails.LN
      ).assignedto,
    }));
  // console.log(Empoptions, "filteredData");
  const hroption = historyOptions.map((e) => ({
    label: e + " To be released",
    value: e,
  }));

  const roption = releaseversion?.map((e) => ({
    label: e,
    value: e,
  }));
  const releaseoption =
    // [
    //   { label: "devozy.ai.v0.91 ", value: "devozy.ai.v0.91 " },
    //   { label: "devozy.ai.v0.92 ", value: "devozy.ai.v0.92 " },
    //   { label: "devozy.ai.v0.93", value: "devozy.ai.v0.93" },
    //   {
    //     label: "devozy.ai.v0.94 To be released",
    //     value: "devozy.ai.v0.94",
    //   },
    // ];

    reportType == "Historyreport" && hroption.length > 0 && roption.length > 0
      ? [...roption, ...hroption]
      : roption;
  // console.log(releaseoption);
  const releaselabel2 = Array.from(
    new Set(
      releaseversion.map((e) => {
        return {
          label: e.actualrelease,
          value: e.actualrelease,
        };
      })
    )
  );

  const committed = Array.from(
    new Set(
      releaseversion.map((e) => {
        return 0;
      })
    )
  );

  const releaselabel = releaseversion?.map((e) => {
    return e;
  });
  const emplabel = Empoptions?.map((e) => {
    return e;
  });
  // console.log(emplabel, "emplabel");
  const labelhistory = historydata.map((f) => {
    return f;
  });

  const handleClickOpen = (e) => {
    setOpen(true);

    setSelectedRow(e.issueid);
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };
  const handleClickOpen2 = (e) => {
    setOpen2(true);
  };

  const data = [];
  const labels = [];

  if (agile_report !== null) {
    const employee_experience = () => {
      agile_report
        .filter((e1) => e1.issueid == selectedRow)
        .map((e) =>
          labels.push(
            e.originalestimate + "days original estimated",
            e.percentageprogressed + "percentage completed"
          )
        );
      agile_report
        .filter((e1) => e1.issueid == selectedRow)
        .map((e) => data.push(e.originalestimate, e.percentageprogressed));
    };
    employee_experience();
  }

  let chartExp = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

      return {
        labels: labels,
        datasets: [
          {
            label: "Issueid",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: data,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: true,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 3,
        xPadding: 0,
        mode: "nearest",
        intersect: 0,
        position: "large",
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 10,
              suggestedMax: 36,
              padding: 3,
              fontColor: "#9e9e9e",
            },
          },
        ],
        xAxes: [
          {
            barPercentage: data.length < 3 ? 0.3 : 0.5,

            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 40,
              fontColor: "#9e9e9e",
            },
          },
        ],
      },
    },
  };

  const data1 = [];
  const labels1 = [];
  if (agile_report !== null) {
    const employee_experience = () => {
      agile_report.map((e) => labels1.push());
      agile_report.map((e) => data1.push(e.DateMoved));
    };
    employee_experience();
  }
  let chartExp1 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

      return {
        labels: labels1,
        datasets: [
          {
            label: "Issueid",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: data1,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: true,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 3,
        xPadding: 0,
        mode: "nearest",
        intersect: 0,
        position: "large",
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 10,
              suggestedMax: 36,
              padding: 3,
              fontColor: "#9e9e9e",
            },
          },
        ],
        xAxes: [
          {
            barPercentage: data.length < 3 ? 0.3 : 0.5,
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 40,
              fontColor: "#9e9e9e",
            },
          },
        ],
      },
    },
  };

  const updatesprintburntdown = (employeeid) => {
    let addarr = [];
    let increment = 0;
    const datacheck =
      employeeid != null
        ? sprintdata.filter((e) => e.assignedto == employeeid)
        : sprintdata;
    // console.log(datacheck, "datacheck", displaydata, "displaydata");
    datacheck.map((e, i) => {
      increment = i;
      const progressdata =
        employeeid != null
          ? displaydata
            .flat()
            .filter(
              (e5) => e5.IssueId == e.issueid && e5.UpdatedBy == employeeid
            )
          : displaydata.flat().filter((e5) => e5.IssueId == e.issueid);
      let percentdata = [];
      // console.log(progressdata, "progressdata");
      excludeday.map((wdate, index) => {
        const filterprogress = progressdata.filter(
          (pr) => moment(pr.UpdatedDate).diff(moment(wdate), "day") == 0
        );
        const beforedate = progressdata.filter(
          (date) => moment(date.UpdatedDate).diff(moment(wdate), "day") < 0
        );
        // console.log(filterprogress, "filterprogress", wdate, "wdate");

        if (filterprogress.length > 0) {
          percentdata.push(
            filterprogress?.[0]?.PercentageProgressed == undefined
              ? 0
              : filterprogress?.[0]?.PercentageProgressed
          );
        } else {
          if (beforedate.length > 0) {
            percentdata.push(
              percentdata[percentdata.length - 1] == undefined
                ? 0
                : percentdata[percentdata.length - 1]
            );
          } else if (
            (filterprogress.length == 0 && beforedate.length == 0) ||
            filterprogress.length == 0
          ) {
            percentdata.push(0);
          }
        }
        // console.log(percentdata, wdate);
      });

      addarr.push(percentdata);
    });
    setPercentagearr(addarr);
    const sums = addarr.reduce((acc, arr) => {
      arr.forEach((value, index) => {
        acc[index] = (acc[index] || 0) + value;
      });
      return acc;
    }, []);
    let arrvalue = [];
    if (datacheck.length == increment + 1) {
      sums.map((sum, index) => {
        arrvalue.push(sum / addarr.length);
      });
    }
    // setPercentage(arrvalue);
    dispatch(PERCENTAGE(arrvalue));
  };

  // console.log(sprintdata, "pp");
  // console.log(predata, "pp");

  // useEffect(() => {
  //   let rid = releaseID == null ? releaseversion?.[0] : releaseID;
  //   console.log(rid, releaseversion?.[0], "release version");
  //   axios
  //     .get(
  //       "rpc/fun_agilesaasproductivityreport?projid=" +
  //         projectCode +
  //         "&releasename=eq." +
  //         rid
  //     )
  //     .then((response) => {
  //       setpredata(response.data);
  //       // console.log(response.data);
  //       let data1 = response.data;

  //       data1.map((e, i) => {
  //         axios
  //           .get(
  //             "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=eq." +
  //               e.issueid +
  //               "&order=UpdatedDate.desc"
  //           )
  //           .then((result) => {
  //             let data = result.data.map((e) => {
  //               return {
  //                 EffortPending: e.EffortPending,
  //                 EffortRemaining: e.EffortRemaining,
  //                 RiskofDelivery: e.RiskofDelivery,
  //                 UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
  //                 IssueId: e.IssueId,
  //                 UpdatedBy: e.UpdatedBy,
  //                 PercentageProgressed: e.PercentageProgressed,
  //               };
  //             });

  //             displaydata1.push(data);
  //           });
  //       });

  //       // console.log(idss, userID, "isdd");
  //       // in.(11004,11006)
  //     })
  //     .catch((error) => {
  //       console.log("somthig error");
  //     });
  //   // let filterda = data?.filter((e) => e.assignedto == userID);
  // }, [userID, releaseID, releaseversion]);
  // console.log(includays1, "includedays");
  const updatesprintburntdown1 = (predata, employeeid, displaydata1) => {
    let temp = moment(predata?.[0]?.sprintenddate).diff(
      moment(predata?.[0]?.sprintstartdate),
      "day"
    );
    const diff =
      temp == 0
        ? 1
        : Number(
          Math.ceil(
            (new Date(predata?.[0]?.sprintenddate) -
              new Date(predata?.[0]?.sprintstartdate)) /
            (1000 * 60 * 60 * 24)
          ) + 1
        );
    const getdays = workingDays(predata?.[0]?.sprintstartdate, diff + 0);
    let addarr = [];
    let increment = 0;
    const datacheck =
      employeeid != null
        ? predata?.filter((e) => e.assignedto == employeeid)
        : predata;
    // console.log(datacheck, "datacheck", displaydata1, "displaydata");
    datacheck.map((e, i) => {
      // console.log(datacheck, e, includays1, "checkdays");
      // console.log(includays1, "includedaysfunction");
      increment = i;
      const progressdata =
        employeeid != null
          ? displaydata1
            ?.flat()
            .filter(
              (e5) => e5.IssueId == e.issueid && e5.UpdatedBy == employeeid
            )
          : displaydata1?.flat().filter((e5) => e5.IssueId == e.issueid);
      let percentdata = [];
      // console.log(progressdata, "progressdata");
      getdays.map((wdate, index) => {
        const filterprogress = progressdata.filter(
          (pr) => moment(pr.UpdatedDate).diff(moment(wdate), "day") == 0
        );
        const beforedate = progressdata.filter(
          (date) => moment(date.UpdatedDate).diff(moment(wdate), "day") < 0
        );
        // console.log(filterprogress, "filterprogress", wdate, "wdate");

        if (filterprogress.length != 0) {
          percentdata.push(
            filterprogress?.[0]?.PercentageProgressed == undefined
              ? 0
              : filterprogress?.[0]?.PercentageProgressed
          );
          // console.log(
          //   filterprogress[0]?.PercentageProgressed == undefined
          //     ? 0
          //     : filterprogress[0]?.PercentageProgressed,
          //   "if"
          // );
        } else {
          if (beforedate.length != 0) {
            percentdata.push(
              percentdata[percentdata.length - 1] == undefined
                ? 0
                : percentdata[percentdata.length - 1]
            );
            // console.log(
            //   percentdata[percentdata.length - 1] == undefined
            //     ? 0
            //     : percentdata[percentdata.length - 1],
            //   "else if"
            // );
          } else if (filterprogress.length == 0) {
            percentdata.push(0);
            // console.log("else else if");
          }
        }
        // console.log(percentdata, wdate, "percentdata");
      });

      addarr.push(percentdata);
    });
    // console.log(addarr, "addarr");
    setPercentagearr1(addarr);
    const sums = addarr.reduce((acc, arr) => {
      arr.forEach((value, index) => {
        acc[index] = (acc[index] || 0) + value;
      });
      return acc;
    }, []);
    let arrvalue = [];
    if (datacheck.length == increment + 1) {
      sums.map((sum, index) => {
        arrvalue.push(sum / addarr.length);
      });
    }
    setPercentage1(arrvalue);
  };
  // const updatesprintburntdown1 = (employeeid) => {
  //   let addarr = [];
  //   let increment = 0;
  //   const datacheck =
  //     employeeid != null
  //       ? predata?.filter((e) => e.assignedto == employeeid)
  //       : predata;
  //   console.log(employeeid, "dis");

  //   datacheck.map((e, i) => {
  //     increment = i;
  //     const progressdata =
  //       employeeid != null
  //         ? displaydata1
  //             .flat()
  //             .filter(
  //               (e5) => e5.IssueId == e.issueid && e5.UpdatedBy == employeeid
  //             )
  //         : displaydata1.flat().filter((e5) => e5.IssueId == e.issueid);
  //     console.log(datacheck, "datacheck", displaydata1, "displaydata");
  //     console.log(progressdata, "display");
  //     let percentdata = [];
  //     includays1.map((wdate, index) => {
  //       const filterprogress = progressdata.filter(
  //         (pr) => moment(pr.UpdatedDate).diff(moment(wdate), "day") == 0
  //       );
  //       const beforedate = progressdata.filter(
  //         (date) => moment(date.UpdatedDate).diff(moment(wdate), "day") < 0
  //       );
  //       // console.log(filterprogress, "filterprogress", wdate, "wdate");

  //       if (filterprogress.length != 0) {
  //         percentdata.push(
  //           filterprogress[0]?.PercentageProgressed == undefined
  //             ? 0
  //             : filterprogress[0]?.PercentageProgressed
  //         );
  //       } else {
  //         if (beforedate.length != 0) {
  //           percentdata.push(
  //             percentdata[percentdata.length - 1] == undefined
  //               ? 0
  //               : percentdata[percentdata.length - 1]
  //           );
  //         } else if (filterprogress.length == 0) {
  //           percentdata.push(0);
  //         }
  //       }
  //       // console.log(percentdata, wdate);
  //     });

  //     addarr.push(percentdata);
  //   });
  //   setPercentagearr1(addarr);
  //   const sums = addarr.reduce((acc, arr) => {
  //     arr.forEach((value, index) => {
  //       acc[index] = (acc[index] || 0) + value;
  //     });
  //     return acc;
  //   }, []);
  //   let arrvalue = [];
  //   if (datacheck.length == increment + 1) {
  //     sums.map((sum, index) => {
  //       arrvalue.push(sum / addarr.length);
  //     });
  //   }
  //   setPercentage1(arrvalue);
  // };
  // console.log(percentage1, "pppp");
  // console.log(percentage1, "percentage");
  function handlename(item) {
    updateTabledata(releaseID, item.value);
    agile_report?.filter((e) => e.assignedto == item.value);

    setUserid(item.value);
    if (reportType == "velocity") {
      const taskData = [];
      const bugData = [];
      const task = [];
      const bug = [];
      const storyData = [];
      const story = [];

      releaselabel.map((id) => {
        if (
          commitbug.length == 0 &&
          committask.length == 0 &&
          committask.length == 0 &&
          completetask.length == 0
        ) {
          storyData.push(
            agile_report.filter(
              (e) =>
                e.assignedto == item.value &&
                e.issuetype === "Story" &&
                e.actualrelease == id
            ).length
          );
          task.push(
            agile_report.filter(
              (e) =>
                e.assignedto == item.value &&
                e.issuetype === "Story" &&
                e.plannedrelease == id
            ).length
          );
        } else {
          taskData.push(
            agile_report.filter(
              (e) =>
                e.assignedto == item.value &&
                (e.issuetype === "Task" || e.issuetype === "Story") &&
                e.actualrelease == id
            ).length
          );
          bugData.push(
            agile_report.filter(
              (e) =>
                e.assignedto == item.value &&
                e.issuetype === "Bug" &&
                e.actualrelease == id
            ).length
          );
          task.push(
            agile_report.filter(
              (e) =>
                e.assignedto == item.value &&
                (e.issuetype === "Task" || e.issuetype === "Story") &&
                e.plannedrelease == id
            ).length
          );
          bug.push(
            agile_report.filter(
              (e) =>
                e.assignedto == item.value &&
                e.issuetype === "Bug" &&
                e.plannedrelease == id
            ).length
          );
        }
      });

      // setnamestory1(storyData);
      settask(taskData);
      setbug(bugData);
      settask1(task);
      setbug1(bug);
      setstory(storyData);
      setstory1(story);
    } else if (
      reportType == "sprintburntdown" ||
      reportType == "sprintburnup" ||
      reportType == "Historyreport"
    ) {
      updatesprintburntdown(item.value);
      updatesprintburntdown1(predata, item.value, displaydata1);
    }
  }
  const data2 = [5, 10, 23];
  const labels2 = ["actualrelease"];
  if (agile_report !== null) {
    const employee_experience = () => {
      // agile_report.map(e => labels2.push(e.actualrelease || e.plannedrelease))
      // agile_report?.filter(x => x.actualrelease || x.plannedrelease != null).map(e => data2.push(e.percentageprogressed))
    };
    employee_experience();
  }
  let chartExp2 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

      return {
        labels: labels2,
        datasets: [
          {
            label: "percentage progressed",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: data1,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: true,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 3,
        xPadding: 0,
        mode: "nearest",
        intersect: 0,
        position: "large",
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 10,
              suggestedMax: 36,
              padding: 3,
              fontColor: "#9e9e9e",
            },
          },
        ],
        xAxes: [
          {
            barPercentage: data.length < 3 ? 0.3 : 0.5,
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 40,
              fontColor: "#9e9e9e",
            },
          },
        ],
      },
    },
  };

  const Monthdropdown = [
    { label: "less than six month", value: "lessthan6months" },
    { label: "greater than six month", value: "greaterthan6months" },
  ];
  let vrsname = releaseoption?.filter((e) => e.value == releaseID)?.[0]?.label;
  let vname = vrsname == undefined ? "" : " - " + vrsname;
  // const Columns = [
  //   {
  //     title: "Assign To",
  //     field: "AssignedToDetails",
  //     headerStyle: {
  //       textAlign: "center",
  //       maxWidth: 200,
  //       height: 10,
  //       maxHeight: 150,
  //       width: 200,
  //       fontSize: "small",
  //       textAlign: "center",
  //       color: "#03256c",
  //       backgroundColor: "#107aeb85",
  //     },
  //     cellStyle: {
  //       fontSize: "12px",
  //       color: "#03256c",

  //       textAlign: "left",
  //     },
  //   },

  //   { title: "Issue Id", field: "IssueId" },
  //   { title: "IssueType", field: "IssueType" },
  //   { title: "Issue title", field: "IssueTitle" },
  //   {title:"Month",field:"" },

  //   // { title: "Original Estimate", field: "OriginalEstimate" },
  //   // { title: "Actual Estimate", field: "ActualEstimate" },
  //   // { title: "%completed", field: "percentageprogressed" },

  //   // { title: "Performance", field: "effortremaining" },
  // ];
  let versionsname = projectName + "" + vname;
  let updatedVisitSaleData = {
    labels: releaselabel,

    datasets: [
      {
        label: "committed task",
        data: Task1,
        borderColor: "#98E4FF",
        backgroundColor: "#98E4FF",
        stack: "Stack 1",
      },
      {
        label: "committed bug",
        data: Bug1,
        borderColor: "#FFD9C0",
        backgroundColor: "#FFD9C0",
        stack: "Stack 1",
      },
      {
        label: "completed task",
        type: "bar",
        stack: "Sensitivity",
        borderColor: "#687EFF",
        backgroundColor: "#687EFF",
        data: Task,
      },

      {
        label: "completed bug",
        type: "bar",
        stack: "Sensitivity",
        borderColor: "#FF9B9B",
        backgroundColor: "#FF9B9B",
        data: Bug,
      },
    ],
  };
  let visitSaleOptions = {
    scales: {
      interaction: {
        intersect: false,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: "Project Version",
            fontStyle: "bold",
            fontSize: 16,
            padding: 0,
          },
          ticks: {
            fontColor: "#777",
            fontSize: 14,
            fontStyle: "normal",
            beginAtZero: true,
          },
          gridLines: {
            display: true,
            drawBorder: false,
            lineWidth: 0.5,
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
            zeroLineColor: "gray",
            zeroLineWidth: 1,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: "Count",
            fontColor: "#000",
            fontSize: 14,
          },
          gridLines: {
            display: true,
            drawBorder: false,
            lineWidth: 0.5,
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
            zeroLineColor: "gray",
            zeroLineWidth: 1,
          },
          barPercentage: 3,
          ticks: {
            suggestedMin: 1,
            suggestedMax: 10,
          },
        },
      ],
    },
    title: {
      display: true,
      text: versionsname,
      fontSize: 13,
      fontStyle: "bold",
      padding: 4,
      fontColor: "#000",
    },
    legend: {
      display: true,
      align: "center",
      labels: {
        fontSize: 12,
        fontStyle: "normal",
        boxWidth: 8,
        usePointStyle: true,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  let updatedVisitSaleDatastory = {
    labels: releaselabel,

    datasets: [
      {
        label: "committed story",
        data: Story1,
        borderColor: "#00DFA2",
        backgroundColor: "#00DFA2",
        stack: "Stack 1",
      },
      {
        label: "completed story",
        type: "bar",
        stack: "Sensitivity",
        backgroundColor: "#0B666A",
        backgroundColorHover: "lightgreen",
        data: Story,
      },
    ],
  };
  let versionselected = {
    labels: [versionof],
    datasets: [
      {
        label: "committed task",
        data:
          projectId != null && releaseID != null && userID == null
            ? [taskplanned]
            : [
              agile_report?.filter(
                (e) =>
                  (e.issuetype == "Task" || e.issuetype == "Story") &&
                  e.assignedto == userID &&
                  e.plannedrelease == releaseID
              ).length,
            ],
        borderColor: "#98E4FF",
        backgroundColor: "#98E4FF",
      },
      {
        label: "committed bug",
        data:
          projectId != null && releaseID != null && userID == null
            ? [bugplanned]
            : [
              agile_report?.filter(
                (e) =>
                  e.issuetype == "Bug" &&
                  e.assignedto == userID &&
                  e.plannedrelease == releaseID
              ).length,
            ],
        borderColor: "#FFD9C0",
        backgroundColor: "#FFD9C0",
        stack: "Stack 1",
      },
      {
        label: "completed task",
        type: "bar",
        borderColor: "#687EFF",
        backgroundColor: "#687EFF",
        data:
          projectId != null && releaseID != null && userID == null
            ? [task]
            : [
              agile_report?.filter(
                (e) =>
                  (e.issuetype == "Task" || e.issuetype == "Story") &&
                  e.assignedto == userID &&
                  e.actualrelease == releaseID
              ).length,
            ],
      },
      {
        label: "completed bug",
        type: "bar",
        borderColor: "#FF9B9B",
        backgroundColor: "#FF9B9B",
        data:
          projectId != null && releaseID != null && userID == null
            ? [bug]
            : [
              agile_report?.filter(
                (e) =>
                  e.issuetype == "Bug" &&
                  e.assignedto == userID &&
                  e.actualrelease == releaseID
              ).length,
            ],
      },
    ],
  };

  let versionselectedstory = {
    labels: [versionof],

    datasets: [
      {
        label: "committed story",
        data:
          projectId != null && releaseID != null && userID == null
            ? [storyplanned]
            : [
              agile_report?.filter(
                (e) =>
                  e.issuetype == "Story" &&
                  e.assignedto == userID &&
                  e.plannedrelease == releaseID
              ).length,
            ],
        borderColor: "#00DFA2",
        backgroundColor: "#00DFA2",
      },
      {
        label: "completed story",
        type: "bar",
        backgroundColor: "#0B666A",
        data:
          projectId != null && releaseID != null && userID == null
            ? [story]
            : [
              agile_report?.filter(
                (e) =>
                  e.issuetype == "Story" &&
                  e.assignedto == userID &&
                  e.actualrelease == releaseID
              ).length,
            ],
      },
    ],
  };

  const issueid = agile_report?.map((e) => e.issueid);

  const weekc = agile_history?.filter((f) => f.IssueId === issueid)
    ? "true"
    : "false";

  const movedate =
    weekc === "true"
      ? agile_history?.map((r) => {
        return {
          date: moment(r.ChangedDate).format("DD/MM/YYYY"),
          Issueid: r.IssueId,
          Updatedto: r.UpdatedTo,
          UpdatedFrom: r.UpdatedFrom,
        };
      })
      : null;

  const storyenddate = agile_report?.map((e) =>
    moment(e.currentstage?.[0]?.DateMoved).format("DD/MM/YYYY")
  );

  const handleemonth = (e) => {
    setAgilerelese(
      agile_relese.filter((b) => moment(b.ReleaseDate).subtract(6, "months"))
    );
    if (e < 6) {
      setAgilerelese(
        agile_relese.filter((b) => moment(b.ReleaseDate).subtract(12, "months"))
      );
    }
  };

  const calculateVelocity = (type) => {
    // console.log(agile_relese, "agile_relese");
    const check =
      commitbug?.length == 0 &&
      completebug?.length == 0 &&
      committask?.length == 0 &&
      completetask?.length == 0;
    if (type == "usersprint") {
      const getdata = [];
      releaselabel.map((label) => {
        const lengthcheck = agile_report.filter(
          (x) => x.releasename == label && x.assignedto == userID
        ).length;
        if (lengthcheck == 0) {
        } else {
          getdata.push(lengthcheck);
        }
      });
      return getdata.length;
    } else if (type == "spillup") {
      let spill = [];
      releaselabel.map((e5) => {
        // console.log(
        //   agile_relese?.filter((e) => e.ReleaseName == e5),
        //   e5
        // );
        spill.push(
          agile_relese?.filter((e) => e.ReleaseName == e5)?.[0]?.RemovedTickets ==
            undefined
            ? 0
            : agile_relese?.filter((e) => e.ReleaseName == e5)?.[0]
              ?.RemovedTickets.length
        );
        // console.log(
        //   agile_relese?.filter((e) => e.ReleaseName == e5)[0]?.RemovedTickets ==
        //     undefined
        //     ? 0
        //     : agile_relese?.filter((e) => e.ReleaseName == e5)[0]
        //         ?.RemovedTickets.length
        // );
      });
      const initialValue = 0;
      const total = spill.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        initialValue
      );
      if (releaseID != null) {
        return agile_relese?.filter((e) => e.ReleaseID == releaseID)?.[0]
          ?.RemovedTickets == null
          ? 0
          : agile_relese?.filter((e) => e.ReleaseID == releaseID)?.[0]
            ?.RemovedTickets + " Weeks";
      } else if (userID != null && releaseID == null) {
        let data = [];
        releaselabel.map((label) => {
          const lengthcheck = agile_report.filter(
            (x) => x.releasename == label && x.assignedto == userID
          );
          if (lengthcheck == 0) {
          } else {
            let checks = agile_relese?.filter((e) => e.ReleaseName == label)?.[0]
              ?.RemovedTickets;
            data.push(
              checks == undefined || checks == null
                ? 0
                : agile_relese?.filter((e) => e.ReleaseName == label)?.[0]
                  ?.RemovedTickets?.length
            );
            // data.push(
            //   agile_relese?.filter((e) => e.ReleaseName == label)[0]
            //     ?.SprintInWeeks == undefined || null
            //     ? 0
            //     : agile_relese?.filter((e) => e.ReleaseName == label)[0]
            //         ?.SprintInWeeks
            // );
          }
        });
        const initialValue = 0;
        // console.log(data, "data");
        const total = data.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue
        );
        // console.log(total, "total");
        return total;
      } else {
        // console.log(total, "total");
        return total;
      }
    } else if (type == "weeks") {
      let calculateWeeks = [];
      releaselabel.map((e5) => {
        // console.log(
        //   agile_relese?.filter((e) => e.ReleaseName == e5),
        //   e5
        // );
        calculateWeeks.push(
          agile_relese?.filter((e) => e.ReleaseName == e5)?.[0]?.SprintInWeeks ==
            undefined
            ? 0
            : agile_relese?.filter((e) => e.ReleaseName == e5)?.[0]?.SprintInWeeks
        );
      });
      const initialValue = 0;
      const total = calculateWeeks.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        initialValue
      );
      // console.log(
      //   type,
      //   releaseID,
      //   agile_relese?.filter((e) => e.ReleaseName == releaseID)[0]
      //     ?.SprintInWeeks,
      //   "check"
      // );
      if (releaseID != null) {
        return agile_relese?.filter((e) => e.ReleaseName == releaseID)?.[0]
          ?.SprintInWeeks;
      } else if (userID != null && releaseID == null) {
        let data = [];
        releaselabel.map((label) => {
          const lengthcheck = agile_report.filter(
            (x) => x.releasename == label && x.assignedto == userID
          );
          if (lengthcheck == 0) {
          } else {
            data.push(
              agile_relese?.filter((e) => e.ReleaseName == label)?.[0]
                ?.SprintInWeeks == undefined || null
                ? 0
                : agile_relese?.filter((e) => e.ReleaseName == label)?.[0]
                  ?.SprintInWeeks
            );
          }
        });
        const initialValue = 0;
        const total = data.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue
        );
        return total;
      } else {
        return total;
      }
    } else if (type == "commit" || type == "complete") {
      // const story = type == "commit" ? commitstory : completestory
      const task = type == "commit" ? committask : completetask;
      const bug = type == "commit" ? commitbug : completebug;
      if (userID != null && releaseID == null) {
        return (
          parseInt(task?.filter((e) => e.assignedto == userID).length) +
          parseInt(bug?.filter((e) => e.assignedto == userID).length)
        );
        // +
        // parseInt(story?.filter((e) => e.assignedto == userID).length)
      } else if (userID == null && releaseID != null) {
        if (type == "commit") {
          return (
            parseInt(
              task?.filter((e) => e.plannedrelease == releaseID).length
            ) +
            parseInt(bug?.filter((e) => e.plannedrelease == releaseID).length)
          );
          // +
          // parseInt(story?.filter((e) => e.plannedrelease == releaseID).length)
        } else {
          return (
            parseInt(task?.filter((e) => e.actualrelease == releaseID).length) +
            parseInt(bug?.filter((e) => e.actualrelease == releaseID).length)
          );
          // +
          // parseInt(story?.filter((e) => e.actualrelease == releaseID).length)
        }
      } else if (userID != null && releaseID != null) {
        if (type == "commit") {
          return (
            parseInt(
              task?.filter(
                (e) => e.assignedto == userID && e.plannedrelease == releaseID
              ).length
            ) +
            parseInt(
              bug?.filter(
                (e) => e.assignedto == userID && e.plannedrelease == releaseID
              ).length
            )
          );
          // +
          // parseInt(story?.filter((e) => e.assignedto == userID && e.plannedrelease == releaseID).length)
        } else {
          return (
            parseInt(
              task?.filter(
                (e) => e.assignedto == userID && e.actualrelease == releaseID
              ).length
            ) +
            parseInt(
              bug?.filter(
                (e) => e.assignedto == userID && e.actualrelease == releaseID
              ).length
            )
          );
          // +
          // parseInt(story?.filter((e) => e.assignedto == userID && e.actualrelease == releaseID).length)
        }
      } else {
        return parseInt(task?.length) + parseInt(bug?.length);
      }
    } else if (type == "future" || type == "velocity") {
      let calculateWeeks = [];
      releaselabel.map((e5) => {
        calculateWeeks.push(
          agile_relese?.filter((e) => e.ReleaseName == e5)?.[0]?.SprintInWeeks ==
            undefined || null
            ? 0
            : agile_relese?.filter((e) => e.ReleaseName == e5)?.[0]?.SprintInWeeks
        );
      });
      const initialValue = 0;
      const total = calculateWeeks.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        initialValue
      );

      const data =
        (parseInt(completetask?.length) + parseInt(completebug?.length)) /
        total;
      const userdata =
        (parseInt(completetask?.filter((e) => e.assignedto == userID).length) +
          parseInt(completebug?.filter((e) => e.assignedto == userID).length)) /
        total;

      const value =
        userID != null
          ? futuredata.filter((e) => e.assignedto == userID).length / data
          : futuredata.length / data;
      if (type == "future") {
        return value > 0 && value < 1 ? 1 : Math.ceil(value);
      } else if (type == "velocity") {
        return userID == null
          ? Math.ceil(data * 2)
          : userID != null
            ? Math.ceil(userdata * 2)
            : 0;
      }
    }
  };

  const updateTabledata = (releaseID, userID) => {
    const check =
      commitbug?.length == 0 &&
      completebug?.length == 0 &&
      committask?.length == 0 &&
      completetask?.length == 0;
    if (userID != null && releaseID == null) {
      const filterData = releaselabel;
      let employeeData = filterData.map((ee) => {
        return {
          employeeid: userID,
          employeename: Assetoptions.filter((e) => e.value1 == userID)?.[0]
            ?.label,
          committed: check
            ? commitstory.filter(
              (e) => e.releasename == ee && e.assignedto == userID
            ).length
            : commitbug.filter(
              (e) => e.releasename == ee && e.assignedto == userID
            ).length +
            committask.filter(
              (e) => e.releasename == ee && e.assignedto == userID
            ).length,
          completed: check
            ? completestory.filter(
              (e) => e.releasename == ee && e.assignedto == userID
            ).length
            : completebug.filter(
              (e) => e.releasename == ee && e.assignedto == userID
            ).length +
            completetask.filter(
              (e) => e.releasename == ee && e.assignedto == userID
            ).length,
          committedstory: commitstory.filter(
            (e) => e.releasename == ee && e.assignedto == userID
          ).length,
          completedstory: completestory.filter(
            (e) => e.releasename == ee && e.assignedto == userID
          ).length,
          committedtask: committask.filter(
            (e) => e.releasename == ee && e.assignedto == userID
          ).length,
          completedtask: completetask.filter(
            (e) => e.releasename == ee && e.assignedto == userID
          ).length,
          committedbug: commitbug.filter(
            (e) => e.releasename == ee && e.assignedto == userID
          ).length,
          completedbug: completebug.filter(
            (e) => e.releasename == ee && e.assignedto == userID
          ).length,
          version: ee,
          sprintstartdate: moment(
            agile_relese?.filter((e5) => e5.ReleaseName == ee)?.[0]
              ?.SprintStartDate
          ).format("DD-MMM-YYYY"),
          sprintenddate: moment(
            agile_relese?.filter((e5) => e5.ReleaseName == ee)?.[0]?.SprintEndDate
          ).format("DD-MMM-YYYY"),
          sprintinweeks: agile_relese?.filter((e5) => e5.ReleaseName == ee)?.[0]
            ?.SprintInWeeks,
        };
      });
      setTabledata(employeeData);
    } else if (userID == null && releaseID != null) {
      const filterData = Assetoptions;

      let employeeData = filterData.map((ee) => {
        return {
          employeeid: ee.value1,
          employeename: ee.label,
          committed: check
            ? commitstory.filter(
              (e) => e.assignedto == ee.value1 && e.releasename == releaseID
            ).length
            : commitbug.filter(
              (e) => e.assignedto == ee.value1 && e.releasename == releaseID
            ).length +
            committask.filter(
              (e) => e.assignedto == ee.value1 && e.releasename == releaseID
            ).length,
          completed: check
            ? completestory.filter(
              (e) => e.assignedto == ee.value1 && e.releasename == releaseID
            ).length
            : completebug.filter(
              (e) => e.assignedto == ee.value1 && e.releasename == releaseID
            ).length +
            completetask.filter(
              (e) => e.assignedto == ee.value1 && e.releasename == releaseID
            ).length,
          committedstory: commitstory.filter(
            (e) => e.assignedto == ee.value1 && e.releasename == releaseID
          ).length,
          completedstory: completestory.filter(
            (e) => e.assignedto == ee.value1 && e.releasename == releaseID
          ).length,
          committedtask: committask.filter(
            (e) => e.assignedto == ee.value1 && e.releasename == releaseID
          ).length,
          completedtask: completetask.filter(
            (e) => e.assignedto == ee.value1 && e.releasename == releaseID
          ).length,
          committedbug: commitbug.filter(
            (e) => e.assignedto == ee.value1 && e.releasename == releaseID
          ).length,
          completedbug: completebug.filter(
            (e) => e.assignedto == ee.value1 && e.releasename == releaseID
          ).length,
          version: releaseID,
          sprintstartdate:
            releaseID != null
              ? moment(
                agile_relese?.filter((e5) => e5.ReleaseName == releaseID)?.[0]
                  ?.SprintStartDate
              ).format("DD-MMM-YYYY")
              : 0,
          sprintenddate:
            releaseID != null
              ? moment(
                agile_relese?.filter((e5) => e5.ReleaseName == releaseID)?.[0]
                  ?.SprintEndDate
              ).format("DD-MMM-YYYY")
              : 0,
          sprintinweeks:
            releaseID != null
              ? agile_relese?.filter((e5) => e5.ReleaseName == releaseID)?.[0]
                ?.SprintInWeeks
              : 0,
        };
      });
      setTabledata(employeeData);
    } else {
      const filterData =
        userID == null
          ? Assetoptions
          : Assetoptions.filter((e) => e.value1 == userID);

      let employeeData = filterData.map((ee) => {
        const userdata = (userID = null ? ee.value : userID);
        console.log(ee, "check employee id")
        return {
          employeeid: ee.EmpId,
          employeename: ee.label,
          committed: check
            ? releaseID == null
              ? commitstory.filter((e) => e.assignedto == userdata).length
              : commitstory.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length
            : releaseID == null
              ? commitbug.filter((e) => e.assignedto == userdata).length +
              committask.filter((e) => e.assignedto == userdata).length
              : commitbug.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length +
              committask.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length,
          completed: check
            ? releaseID == null
              ? completestory.filter((e) => e.assignedto == userdata).length
              : completestory.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length
            : releaseID == null
              ? completebug.filter((e) => e.assignedto == userdata).length +
              completetask.filter((e) => e.assignedto == userdata).length
              : completebug.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length +
              completetask.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length,
          committedstory: check
            ? releaseID == null
              ? commitstory.filter((e) => e.assignedto == userdata).length
              : commitstory.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length
            : releaseID == null
              ? committask.filter((e) => e.assignedto == userdata).length
              : committask.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length,
          completedstory: check
            ? releaseID == null
              ? completestory.filter((e) => e.assignedto == userdata).length
              : completestory.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length
            : releaseID == null
              ? completetask.filter((e) => e.assignedto == userdata).length
              : completetask.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length,
          committedtask:
            releaseID == null
              ? committask.filter((e) => e.assignedto == userdata).length
              : committask.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length,
          completedtask:
            releaseID == null
              ? completetask.filter((e) => e.assignedto == userdata).length
              : completetask.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length,
          committedbug:
            releaseID == null
              ? commitbug.filter((e) => e.assignedto == userdata).length
              : commitbug.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length,
          completedbug:
            releaseID == null
              ? completebug.filter((e) => e.assignedto == userdata).length
              : completebug.filter(
                (e) => e.assignedto == userdata && e.releasename == releaseID
              ).length,
          version: releaseID,
          sprintstartdate:
            releaseID != null
              ? moment(
                agile_relese?.filter((e5) => e5.ReleaseName == releaseID)?.[0]
                  ?.SprintStartDate
              ).format("DD-MMM-YYYY")
              : 0,
          sprintenddate:
            releaseID != null
              ? moment(
                agile_relese?.filter((e5) => e5.ReleaseName == releaseID)?.[0]
                  ?.SprintEndDate
              ).format("DD-MMM-YYYY")
              : 0,
          sprintinweeks:
            releaseID != null
              ? agile_relese?.filter((e5) => e5.ReleaseName == releaseID)?.[0]
                ?.SprintInWeeks
              : 0,
        };
      });
      setTabledata(employeeData);
    }
  };
  const check =
    commitbug?.length == 0 &&
    completebug?.length == 0 &&
    committask?.length == 0 &&
    completetask?.length == 0;
  const div2PDF = (e) => {
    const but = e.target;
    but.style.display = "none";
    let input = window.document.getElementsByClassName("div2PDF")?.[0];

    html2canvas(input).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const { jsPDF } = require("jspdf");

      const doc = new jsPDF({
        orientation: "l", // landscape
        unit: "pt", // points, pixels won't work properly
        format: [1000, 1000], // set needed dimensions for any element
      });
      // const pdf = new pdfConverter("l", "pt");
      doc.addImage(img, "JPEG", 0, 0, 1000, 1000);
      doc.save(
        reportType == "velocity"
          ? "Velocity Report.pdf"
          : "Sprint Burnt Down Report.pdf"
      );
      but.style.display = "block";
    });
  };

  const clearData = () => {
    setUserid(null);
    setreleaseID(null);
    refreash(projectCode);
    if (
      reportType == "sprintburntdown" ||
      reportType == "sprintburnup" ||
      reportType == "Historyreport"
    ) {
      updatesprintburntdown(null);
      // updatesprintburntdown1(predata, null, displaydata1);
    }
    setCheckRelease(false);
  };

  const currentDifference =
    moment(sprintdata?.[0]?.sprintenddate).diff(moment(), "day") + 1;

  // console.log(
  //   currentDifference,
  //   "currentDifference",
  //   percentage?.[percentage.length - currentDifference],
  //   "calculatepercentage"
  // );

  const days = sprintdata?.[0]?.sprintinweeks * 5;
  const prevdays = predata?.[0]?.sprintinweeks * 5;
  // console.log(predata?.[0]?.sprintinweeks);

  const pushdata = () => {
    const value = 100 / days;

    for (let i = 1; i < days + 1; i++) {
      let d = {
        id: i + " day",
        plan: i * value,
        actual: 0,
      };

      chartdata.push(d);
    }
  };

  if (days != 0 && chartdata.length == 0) {
    pushdata();
  }

  const pushdatahistorychart = () => {
    const value = 100 / prevdays;

    for (let i = 1; i < prevdays + 1; i++) {
      let d = {
        id: i + " day",
        plan: i * value,
        actual: 0,
      };

      chartdata1.push(d);
    }
  };

  if (prevdays != 0 && chartdata1.length == 0) {
    pushdatahistorychart();
  }

  const pushdata1 = () => {
    const value = 100 / days;
    let currentPlan = 90;

    for (let i = 1; i < days + 1; i++) {
      let d = {
        id: i === 1 ? "1 day" : i === days ? "90 day" : i + " day",
        plan: currentPlan,
        actual: 0,
      };

      chart.push(d);
      currentPlan -= value;
    }
  };

  if (days != 0 && chart.length === 0) {
    pushdata1();
  }

  const pushdatahistorychart1 = () => {
    const value = 100 / prevdays;
    let currentPlan = 90;

    for (let i = 1; i < prevdays + 1; i++) {
      let d = {
        id: i === 1 ? "1 day" : i === prevdays ? "90 day" : i + " day",
        plan: currentPlan,
        actual: 0,
      };

      chart1.push(d);
      currentPlan -= value;
    }
  };

  if (prevdays != 0 && chart1.length === 0) {
    pushdatahistorychart1();
  }

  function workingDays(startDate, diff1) {
    const weekdays = [];
    let current = startDate;
    let i = 0;
    while (i < diff1) {
      if (!isWeekEnd(current)) {
        weekdays.push(moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"));
      }
      let currentObj = current;
      current = moment(currentObj, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
      i++;
    }
    function isWeekEnd(date) {
      let dateObj = moment(date, "YYYY-MM-DD");

      if (dateObj.day() === 6 || dateObj.day() === 0) {
        return true;
      }
      //   else {
      //     if (holidays.includes(date)) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
    }

    return weekdays;
    // }
  }

  const excludeday = workingDays(sprintdata?.[0]?.sprintstartdate, diff + 0);

  const excludedays = workingDays(sprintdata?.[0]?.sprintstartdate, diff + 3);

  const currentDate = moment();

  // Subtract one day to get the previous day's date
  const previousDate = currentDate.subtract(1, "days");
  // console.log(moment(previousDate).format("DD-MM-YYYY"), "previousDate");

  const daybeforeDate = currentDate.subtract(1, "days");
  // console.log(moment(daybeforeDate).format("DD-MM-YYYY"));

  const daycheck = excludeday
    ?.map((e, index) => {
      if (moment().diff(moment(e), "day") == 0) {
        return index + 1;
      }
    })
    .find((i) => i != undefined);

  const saturday = excludeday
    ?.map((e, index) => {
      if (moment(previousDate).diff(moment(e), "day") == 0) {
        return index + 1;
      }
    })
    .find((i) => i != undefined);

  const sunday = excludeday
    ?.map((e, index) => {
      if (moment(daybeforeDate).diff(moment(e), "day") == 0) {
        return index + 1;
      }
    })
    .find((i) => i != undefined);

  const findDay =
    daycheck != undefined
      ? daycheck
      : saturday != undefined
        ? saturday
        : sunday;

  const employeeData =
    reportType == "sprintburntdown" ||
      reportType == "sprintburnup" ||
      reportType == "cummulative"
      ? Array.from(
        new Map(
          sprintdata
            ?.filter((e) => e.assignedto != null)
            .map((item) => [item.assignedto, item])
        ).values()
      )
      : employeeDropdown;
  // console.log(employeeData);
  const employeeOption = employeeData?.map((e) => ({
    label:
      reportType == "velocity" || reportType == "Historyreport"
        ? e.employeename
        : e.assignedtodetails.FN + " " + e.assignedtodetails.LN,
    value:
      reportType == "velocity" || reportType == "Historyreport"
        ? e.employeeid
        : e.assignedto,
  }));
  // console.log(employeeOption, "employeeOption");

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  console.log(reportdata?.length)
  const handleChange = (event, newValue) => {
    if (newValue == 0 && releasedata?.filter((x) => x.IsReleaseFrozen == "Y")?.length > 0) {
      setReportType("velocity");
      clearData();
      setValue(newValue);
    } else if (newValue == 1 && sprintdata?.length > 0) {
      setReportType("sprintburntdown");
      updatesprintburntdown(null);
      setUserid(null);
      clearData();
      setValue(newValue);
    }
    // else if (newValue == 2) {
    //   setReportType("sprintburnup");
    //   updatesprintburntdown(null);
    //   setUserid(null);
    //   clearData();
    // }
    // else if (newValue == 3) {
    //   setReportType("cummulative");
    //   setUserid(null);
    //   clearData();
    // }
    else if (newValue == 2 && releasedata?.filter((x) => x.IsReleaseFrozen == "Y")?.length > 0) {
      setReportType("Historyreport");
      // updatesprintburntdown1(predata, userID, displaydata1);
      setUserid(null);
      clearData();
      setValue(newValue);
    }
    else if (sprintdata?.length == 0 || releasedata?.filter((x) => x.IsReleaseFrozen == "Y")?.length == 0) {
      toast.error(`No sprint reports available`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      })
    }
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      color: "black",
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      color: "black",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  let lp = sprintdata?.map((e) => e.currentstage);

  const dropdowndesign = () => {
    return (
      <div>
        <div>
          <Row className="" style={{ paddingLeft: 8 }}>
            {reportType == "sprintburntdown" ? (
              ""
            ) : reportType == "sprintburnup" ? (
              ""
            ) : reportType == "cummulative" ? (
              ""
            ) : (
              <Select
                placeholder="version"
                options={releaseoption}
                className="selectWidth reportselect"
                //   style={{
                //      maxWidth: "450px"
                //  }}
                styles={customStyles}
                onChange={(e) => {
                  if (
                    reportType == "Historyreport" &&
                    e.label?.includes("To be released")
                  ) {
                    updatsprint(e.value, e.label);
                    setCheckRelease(true);
                  } else {
                    setCheckRelease(false);
                  }
                  updateTabledata(e.value, userID);
                  // history();
                  setreleaseID(e.value);
                }}
                value={releaseoption.filter((e) => e.value == releaseID)}
              />
            )}
            {reportType != "Historyreport" && (
              <Select
                placeholder="Search by Name"
                className="selectWidth reportselect"
                // style={{ maxWidth: "450px" }}
                styles={customStyles}
                closeIcon={"false"}
                options={employeeOption}
                onChange={(item) => {
                  handlename(item);
                }}
                value={employeeOption?.filter(
                  (option) => option.value == userID
                )}
              // value={userID}
              // classNamePrefix="select"
              />
            )}

            {/* {console.log(
              employeeOption?.filter((option) => option.value == userID)
            )} */}
            {/* {console.log(employeeOption)} */}
            {userID != null || releaseID != null ? (
              <label
                style={{
                  alignSelf: "center",
                  // backgroundColor: "#6956E5",
                  textAlign: "center",
                  padding: 7,
                  color: "#6956E5",
                  borderRadius: 9,
                  cursor: "pointer",
                  fontStyle: "normal",
                  margin: 1,
                }}
                onClick={() => {
                  clearData();
                }}
              >
                <strong>
                  {/* <span className="mdi mdi-brush"></span> */}
                  Clear
                </strong>
              </label>
            ) : null}

            <div
              className="p-2"
              style={{
                position: "absolute",
                marginLeft: "82%",
                marginBottom: "0%",
                marginTop: "0%",
              }}
            >
              <Button
                className="shadow-sm border-0 pl-3 pr-4 "
                size="md"
                onClick={
                  // (e) => div2PDF(e)
                  downloadPDF
                }
                disabled={loader}
                style={{ backgroundColor: "#178AE5" }}
              >
                <span className="mdi mdi-download-outline mr-1"></span>
                Download report
              </Button>
              {/* <Button onClick={downloadPDF}>Download PDF</Button> */}
            </div>
          </Row>
        </div>
      </div>
    );
  };
  const appBarStyle = {
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "bold",
  };
  return (
    <div
      className={`${classes.root} div2PDF`}
      style={{ backgroundColor: "#F5F7FB" }}
    >
      <AppBar
        position="static"
        color="#fff"
        // style={appBarStyle}
        className="border-bottom"
        elevation={0}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="info"
          // variant="fullWidth"
          aria-label="full width tabs example"
          className=" ml-4 "
        >
          <Tab
            className="mdi mdi-chart-bar pl-2"
            style={appBarStyle}
            label="Velocity Report"
            {...a11yProps(0)}
          />
          <Tab
            className="mdi mdi-chart-line"
            style={appBarStyle}
            label="Current Sprint Reports"
            {...a11yProps(1)}
          />
          {/* <Tab
            className="mdi mdi-chart-bell-curve"
            style={appBarStyle}
            label="Sprint burn up"
            {...a11yProps(2)}
          />
          <Tab
            className="mdi mdi-chart-areaspline pl-4"
            style={appBarStyle}
            label="Cumulative &nbsp; &nbsp;"
            {...a11yProps(3)}
          /> */}
          <Tab
            className="mdi mdi-chart-areaspline"
            style={appBarStyle}
            label="History Reports &nbsp; &nbsp;"
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <div ref={pdfRef}>
          {agile_report == 0 ? (
            <h4 className="text-warning ml-3 p-4 d-flex justify-content-center">
              There are no previous reports
            </h4>
          ) : (
            <div>
              <Row>
                <Col>{dropdowndesign()}</Col>
              </Row>
              <div id="op">
                <div className="container  p-1">
                  <div className="row ">
                    <div className="col-9 p-1 " style={{ minHeight: "520px" }}>
                      <Card className="chart-area  myperformance1  h-100 shadow-sm border">
                        <div className="chart-area  mt-4">
                          {commitbug?.length == 0 &&
                            completebug?.length == 0 &&
                            committask?.length == 0 &&
                            completetask?.length == 0 ? (
                            releaseID != null ? (
                              <Bar
                                data={versionselectedstory}
                                options={visitSaleOptions}
                              />
                            ) : (
                              <Bar
                                data={updatedVisitSaleDatastory}
                                options={visitSaleOptions}
                              />
                            )
                          ) : releaseID != null ? (
                            <Bar
                              data={versionselected}
                              options={visitSaleOptions}
                            />
                          ) : (
                            <Bar
                              data={updatedVisitSaleData}
                              options={visitSaleOptions}
                            />
                          )}
                        </div>
                      </Card>
                    </div>
                    <div className="col-3 pl-3 pr-3 pt-1">
                      <Card
                        className=" w-100 rounded-lg shadow-sm border"
                        style={{ minHeight: "150px" }}
                      >
                        <CardTitle className="m-2">
                          <label style={{ color: "#9A89FF" }}>
                            <strong>Sprint info</strong>
                          </label>
                          <CardBody
                            className="border-top p-0 mb-1"
                            style={
                              {
                                // fontSize: "1rem",
                                // fontWeight: "700",
                              }
                            }
                          >
                            <Row className="">
                              <Col className=" pl-1 ml-3 " sm={8}>
                                <label className="SprintlabelStyle">
                                  Sprints completed
                                </label>
                              </Col>
                              <Col
                                className="p-1 ml-1 d-flex justify-content-center"
                                sm={2}
                              >
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {userID !== null
                                    ? calculateVelocity("usersprint")
                                    : releaselabel.length}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1  ml-3" sm={8}>
                                <label className="SprintlabelStyle">
                                  {releaseID != null
                                    ? "Sprint Weeks "
                                    : "Total Weeks "}
                                </label>
                              </Col>
                              <Col
                                className=" pt-1 ml-1 d-flex justify-content-center"
                                sm={2}
                              >
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {calculateVelocity("weeks")}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1 pb-1 ml-3" sm={8}>
                                <label className="SprintlabelStyle">
                                  Team members
                                </label>
                              </Col>
                              <Col
                                className="pt-1 ml-1 d-flex justify-content-center"
                                sm={2}
                              >
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {employeeOption.length}
                                </span>
                              </Col>
                            </Row>
                          </CardBody>
                        </CardTitle>
                      </Card>
                      <Card
                        className=" w-100 mt-2 rounded-lg shadow-sm border"
                        style={{ minHeight: "190px" }}
                      >
                        <CardTitle className="m-2">
                          <label className="text-success">
                            <strong>Ticket info</strong>
                          </label>
                          <CardBody
                            className="border-top p-0"
                            style={
                              {
                                // fontSize: "1rem",
                                // fontWeight: "700",
                              }
                            }
                          >
                            <Row className="">
                              <Col className=" pl-1 ml-3" sm={8}>
                                <label className="SprintlabelStyle">
                                  Commited tickets
                                </label>
                              </Col>
                              <Col
                                className="p-1 ml-1 d-flex justify-content-center"
                                sm={2}
                              >
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {calculateVelocity("commit")}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1 ml-3" sm={8}>
                                <label className="SprintlabelStyle">
                                  Completed tickets
                                </label>
                              </Col>
                              <Col
                                className="pt-1 ml-1 d-flex justify-content-center"
                                sm={2}
                              >
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {" "}
                                  {calculateVelocity("complete")}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1 ml-3" sm={8}>
                                <label className="SprintlabelStyle">
                                  Velocity tickets
                                </label>
                              </Col>
                              <Col
                                className=" pt-1 ml-1 d-flex justify-content-center"
                                sm={2}
                              >
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {console.log(isNaN(calculateVelocity("complete")), isNaN(calculateVelocity("velocity")), "cccccccccccccccc")}
                                  {userID == null && releaseID != null
                                    ? isNaN(calculateVelocity("complete")) ? 0 : calculateVelocity("complete")
                                    : isNaN(calculateVelocity("velocity")) ? 0 : calculateVelocity("velocity")}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1 ml-3" sm={8}>
                                <label className="SprintlabelStyle">
                                  Spill up tickets
                                </label>
                              </Col>
                              <Col
                                className="pt-1 ml-1 d-flex justify-content-center"
                                sm={2}
                              >
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {calculateVelocity("spillup")}
                                </span>
                              </Col>
                            </Row>
                          </CardBody>
                        </CardTitle>
                      </Card>
                      <Card
                        className=" w-100 rounded-lg mt-2 shadow-sm border"
                        style={{ minHeight: "120px" }}
                      >
                        <CardTitle className="m-2">
                          <label className="text-info">
                            <strong>Future Sprint info</strong>
                          </label>
                          <CardBody
                            className="border-top p-0"
                            style={
                              {
                                // fontSize: "1rem",
                                // fontWeight: "700",
                              }
                            }
                          >
                            <Row className="">
                              <Col className=" pl-1 ml-3" sm={8}>
                                <label className="SprintlabelStyle">
                                  Total tickets
                                </label>
                              </Col>
                              <Col
                                className=" p-1 ml-1 d-flex justify-content-center"
                                sm={2}
                              >
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {userID != null
                                    ? futuredata.filter(
                                      (e) => e.assignedto == userID
                                    ).length
                                    : futuredata?.length}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1 ml-3" sm={8}>
                                <label className="SprintlabelStyle">
                                  Total weeks to complete the tickets
                                </label>
                              </Col>
                              <Col
                                className="pt-1 ml-1 d-flex justify-content-center"
                                sm={2}
                              >
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {isNaN(calculateVelocity("future")) ? 0 : calculateVelocity("future")}
                                </span>
                              </Col>
                            </Row>
                          </CardBody>
                        </CardTitle>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className="mt-5 border">
                  <VelocityReportTable
                    tabledata={tabledata}
                    releaseID={releaseID}
                    userID={userID}
                    check={check}
                    releaselabel={releaselabel}
                    agile_report={agile_report}
                    agile_relese={agile_relese}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <div ref={pdfRef}>
          {sprintdata.length == 0 ? (
            <h4 className="text-warning ml-3 p-4 d-flex justify-content-center">
              No In-flight sprint for this project
            </h4>
          ) : (
            <div>
              <Row>
                <Col>{dropdowndesign()}</Col>
              </Row>

              <div id={"op"}>
                <div className="container  p-1">
                  <div className="row ">
                    <div className="col-9 p-1 " style={{ minHeight: "530px" }}>
                      <Card className="chart-area  myperformance1  h-100  shadow-sm border">
                        <div className=" mt-5">
                          <CFDChart
                            className="bg-dark border"
                            data={sprintdata}
                            userID={userID}
                            workingDays={workingDays}
                            releaseID={releaseID}
                            percentage={percentage}
                            chartdata={chartdata}
                            excludeday={excludeday}
                            projectname={projectName}
                            versionname={sprintdata?.[0]?.releasename}
                            projectCode={projectCode}
                            cmt={cmt}
                            cmt1={cmt1}
                            setcmt={setcmt}
                            setcmt1={setcmt1}
                            setcmt2={setcmt2}
                            setcmt3={setcmt3}
                            cmt2={cmt2}
                          />
                        </div>
                      </Card>
                    </div>
                    <div className="col-3 pl-3 pr-3 pt-1 ">
                      <Card
                        className=" w-100 rounded-lg shadow-sm border"
                        style={{ minHeight: "150px" }}
                      >
                        <CardTitle className="m-2">
                          <label style={{ color: "#9A89FF" }}>
                            <strong>Sprint info</strong>
                          </label>

                          <CardBody
                            className="border-top p-0"
                            style={
                              {
                                // fontSize: "1rem",
                                // fontWeight: "700",
                              }
                            }
                          >
                            <Row className="">
                              <Col className=" pl-1 pr-1 ml-3" sm={6}>
                                <label className="SprintlabelStyle">
                                  Sprint Duration
                                </label>
                              </Col>
                              <Col className="pl-1 ml-1">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  (
                                  {sprintdata?.[0]?.sprintinweeks +
                                    " " +
                                    "weeks"}
                                  )
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1  ml-3" sm={6}>
                                <label className="SprintlabelStyle">From</label>
                              </Col>
                              <Col className="pl-1  ml-1">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {moment(
                                    sprintdata?.[0]?.sprintstartdate
                                  ).format("DD-MM-YYYY")}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1  ml-3" sm={6}>
                                <label className="SprintlabelStyle">To</label>
                              </Col>
                              <Col className="pl-1  ml-1">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {moment(
                                    sprintdata?.[0]?.sprintenddate
                                  ).format("DD-MM-YYYY")}
                                </span>
                              </Col>
                            </Row>
                          </CardBody>
                        </CardTitle>
                      </Card>
                      <Card
                        className=" w-100 mt-2 rounded-lg shadow-sm border"
                        style={{ minHeight: "125px" }}
                      >
                        <CardTitle className="m-2">
                          <Row>
                            <Col>
                              <label className="text-info">
                                <strong>Sprint</strong>
                              </label>
                            </Col>
                            <Col>
                              <Badge className="bg-danger border-0 rounded font-weight-bold">
                                {"In-Flight"}
                              </Badge>
                            </Col>
                          </Row>

                          <CardBody className="border-top p-0">
                            <Row className="">
                              <Col className=" pl-1  pr-1 ml-3" sm={6} md={4}>
                                <label className="SprintlabelStyle">
                                  Version
                                </label>
                              </Col>
                              <Col className="pl-1 ml-1">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16, textTransform: 'lowercase' }}
                                >
                                  {/* {sprintdata?.[0]?.releasename.length > 10
                                    ? sprintdata?.[0]?.releasename.substring(
                                        0,
                                        6
                                      ) + "..."
                                    : sprintdata?.[0]?.releasename} */}
                                  {sprintdata?.[0]?.releasename}
                                </span>
                              </Col>
                            </Row>
                            {/* <Row className="">
                              <Col className=" pl-1  ml-3" sm={6}>
                                <label className="SprintlabelStyle">
                                  Status
                                </label>
                              </Col>
                              <Col className="pl-1 ml-1">
                                <Badge className="bg-danger border-0 rounded font-weight-bold">
                                  {"In-Flight"}
                                </Badge>
                              </Col>
                            </Row> */}
                            <Row className="">
                              <Col className=" pl-1  ml-3" sm={6} md={4}>
                                <label className="SprintlabelStyle">
                                  Track
                                </label>
                              </Col>
                              <Col className="pl-1  ml-1 " >
                                <span
                                  className="d-flex align-items-center font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {parseInt(percentage?.[findDay]) >
                                    parseInt(chartdata?.[findDay]?.plan)
                                    ? findDay == undefined
                                      ? ""
                                      : "Day-" + findDay
                                    : findDay == undefined
                                      ? ""
                                      : "Day-" + findDay}
                                  {/* <Slider
                                    className={getColor(
                                      percentage?.[
                                        percentage.length - currentDifference
                                      ]
                                    )}
                                    size="small"
                                    value={Math.round(
                                      percentage?.[
                                        percentage.length - currentDifference
                                      ]
                                    )}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                    disabledSlider={true}
                                  /> */}
                                  <ProgressBar
                                    className="ml-1"
                                    style={{ height: "12px", width: '45%' }}
                                    variant={
                                      Math.round(
                                        percentage?.[percentage?.length - 1]
                                      ) >= 75
                                        ? "success"
                                        : Math.round(
                                          percentage?.[percentage?.length - 1]
                                        ) === 0
                                          ? "danger"
                                          : "warning"
                                    }
                                    animated
                                    now={Math.round(
                                      percentage?.[percentage?.length - 1]
                                    )}
                                    label={
                                      <span style={{ fontSize: "10px" }}>
                                        {Math.round(
                                          percentage?.[percentage.length - 1]
                                        )}
                                      </span>
                                    }
                                  />
                                  {/* <ProgressBar
                                    className=""
                                    variant={
                                      parseInt(percentage?.[findDay]) >
                                        parseInt(chartdata?.[findDay]?.plan)
                                        ? "success"
                                        : "danger"
                                    }
                                    animated
                                    now={
                                      percentage?.[
                                      percentage.length - currentDifference
                                      ]
                                    }
                                  /> */}
                                </span>
                              </Col>
                            </Row>
                          </CardBody>
                        </CardTitle>
                      </Card>
                      <Card
                        className=" w-100 mt-2 rounded-lg shadow-sm border"
                        style={{ minHeight: "190px" }}
                      >
                        <CardTitle className="m-2">
                          <label style={{ color: "#EA1179" }}>
                            <strong>Stage Move info</strong>
                          </label>
                          <CardBody
                            className="border-top p-0"
                            style={
                              {
                                // fontSize: "1rem",
                                // fontWeight: "700",
                              }
                            }
                          >
                            <Row className="">
                              <Col className=" pl-1 pr-1 ml-3" sm={6}>
                                <label className="SprintlabelStyle">
                                  Total issues
                                </label>
                              </Col>
                              <Col className="pt-1  d-flex justify-content-center">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {userID != null ? cmt2 : lp?.length}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1 pr-1 ml-3" sm={6}>
                                <label className="SprintlabelStyle">
                                  Moved to dev
                                </label>
                              </Col>
                              <Col className="pt-1  d-flex justify-content-center">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {cmt}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1 ml-3" sm={6}>
                                <label className="SprintlabelStyle">
                                  Moved to UAT
                                </label>
                              </Col>
                              <Col className="pt-1 d-flex justify-content-center">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {cmt3}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1 ml-3" sm={6}>
                                <label className="SprintlabelStyle">
                                  Moved to Done
                                </label>
                              </Col>
                              <Col className="pt-1 d-flex justify-content-center">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {cmt1}
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1 ml-3" sm={6}>
                                <label className="SprintlabelStyle">
                                  Remaining RFD
                                </label>
                              </Col>
                              <Col className="pt-1 d-flex justify-content-center">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {userID != null
                                    ? cmt2 - cmt
                                    : lp?.filter(
                                      (e) => e?.[0].StageCode == "RFD"
                                    ).length}
                                </span>
                              </Col>
                            </Row>
                          </CardBody>
                        </CardTitle>
                      </Card>
                    </div>
                  </div>
                </div>
                <div>
                  <Card className="chart-area  myperformance1  h-100 shadow-sm border p-2">
                    <div className="mt-2">
                      <SprintChart
                        data={sprintdata}
                        userID={userID}
                        releaseID={releaseID}
                        percentage={percentage}
                        chartdata={chartdata}
                        excludeday={excludeday}
                        projectname={projectName}
                        versionname={sprintdata?.[0]?.releasename}
                        stdate={sprintdata?.[0]?.sprintstartdate}
                      />
                    </div>
                  </Card>
                </div>
                <div className="mt-2">
                  <Card className="chart-area  myperformance1 shadow-sm border p-2">
                    <div className="mt-2">
                      <SprintBurnup
                        data={sprintdata}
                        userID={userID}
                        releaseID={releaseID}
                        percentage={percentage}
                        chartdata={chartdata}
                        excludeday={excludeday}
                        chart={chart}
                        projectname={projectName}
                        versionname={sprintdata?.[0]?.releasename}
                        stdate={sprintdata?.[0]?.sprintstartdate}
                      />
                    </div>
                  </Card>
                </div>
              </div>

              <div className="mt-3 border" id="op">
                <SprintReportTable
                  tabledata={sprintdata}
                  userID={userID}
                  releaseID={releaseID}
                  percentage={percentage}
                />
              </div>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <div>
          {isLoading ? (
            <div
              className=" d-flex align-items-center"
              style={{ justifyContent: "center", height: 460 }}
            >
              <span
                className="spinner-border"
                role="status"
                aria-hidden="true"
                style={{ color: "rgb(82, 80, 229)" }}
              ></span>
            </div>
          ) : reportdata?.length == 0 ? (
            <h4 className="text-warning ml-3 d-flex justify-content-center p-4">
              There are no previous reports
            </h4>
          ) : (
            <div>
              <Row>
                <Col>{dropdowndesign()}</Col>
              </Row>
              <div ref={pdfRef}>
                <div id="op">
                  <div>
                    <div className="container  p-1">
                      <div className="row ">
                        <div
                          className="col-9 p-1  "
                          style={{ height: "500px" }}
                        >
                          <Card className="chart-area  myperformance1  h-100  shadow-sm border">
                            <div className=" mt-4">
                              <HistoryReports
                                className="border"
                                // data={sprintdata}
                                // userID={userID}
                                workingDays={workingDays}
                                releaseID={releaseID}
                                versionDays={versionDays}
                                checkRelease={checkRelease}
                                // percentage={percentage}
                                // chartdata={chartdata}
                                // excludeday={excludeday}
                                // projectname={projectName}
                                // versionname={sprintdata?.[0]?.releasename}
                                // projectCode={projectCode}
                                cmt={cmt}
                                cmt1={cmt1}
                                setcmt={setcmt}
                                setcmt1={setcmt1}
                                cmt2={cmt2}
                                setcmt2={setcmt2}
                                setcmt3={setcmt3}
                                // releaseversion={releaseversion}
                                predata={predata}
                                // setpredata={setpredata}
                                // displaydata1={displaydata1}
                                // setDisplayData1={setDisplayData1}
                                reportdata={reportdata}
                              // updatesprintburntdown1={updatesprintburntdown1}
                              />
                            </div>
                          </Card>
                        </div>
                        <div className="col-3 pl-3 pr-3 pt-1">
                          <Card
                            className=" w-100 rounded-lg shadow-sm border"
                            style={{ minHeight: "195px" }}
                          >
                            <CardTitle className="m-2">
                              <label style={{ color: "#9A89FF" }}>
                                <strong>Sprint info</strong>
                              </label>
                              <CardBody
                                className="border-top p-0"
                                style={
                                  {
                                    // fontSize: "1rem",
                                    // fontWeight: "700",
                                  }
                                }
                              >
                                <Row className="">
                                  <Col className=" pl-1  pr-1 ml-3" sm={6}>
                                    <label className="SprintlabelStyle">
                                      Project Version
                                    </label>
                                  </Col>
                                  <Col className="pl-1 pt-1 ml-1">
                                    <span
                                      className="font-weight-bold text-dark"
                                      style={{ fontSize: 16 }}
                                    >
                                      {
                                        reportType == "Historyreport" &&
                                          checkRelease
                                          ? predata?.[0]?.releasename.slice(-5)
                                          : reportdata?.[0]?.ReleaseName.slice(
                                            -5
                                          )
                                        // : sprintdata?.[0]?.releasename.length > 10
                                        // ? sprintdata?.[0]?.releasename.substring(
                                        //     0,
                                        //     6
                                        //   ) + "..."
                                      }
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="">
                                  <Col className=" pl-1 pr-1  ml-3" sm={6}>
                                    <label className="SprintlabelStyle">
                                      Sprint Duration
                                    </label>
                                  </Col>
                                  <Col className="pl-1 ml-1">
                                    <span
                                      className="font-weight-bold text-dark"
                                      style={{ fontSize: 16 }}
                                    >
                                      (
                                      {reportType == "Historyreport" &&
                                        checkRelease
                                        ? predata?.[0]?.sprintinweeks +
                                        " " +
                                        "weeks"
                                        : reportdata?.[0]?.SprintInWeeks +
                                        " " +
                                        "weeks"}
                                      )
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="">
                                  <Col className=" pl-1  ml-3" sm={6}>
                                    <label className="SprintlabelStyle">
                                      From
                                    </label>
                                  </Col>
                                  <Col className="pl-1 ml-1">
                                    <span
                                      className="font-weight-bold text-dark"
                                      style={{ fontSize: 16 }}
                                    >
                                      {reportType == "Historyreport" &&
                                        checkRelease
                                        ? moment(
                                          predata?.[0]?.sprintstartdate
                                        ).format("DD-MM-YYYY")
                                        : moment(
                                          reportdata?.[0]?.SprintStartDate
                                        ).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="">
                                  <Col className=" pl-1 ml-3" sm={6}>
                                    <label className="SprintlabelStyle">
                                      To
                                    </label>
                                  </Col>
                                  <Col className="pl-1 ml-1">
                                    <span
                                      className="font-weight-bold text-dark"
                                      style={{ fontSize: 16 }}
                                    >
                                      {reportType == "Historyreport" &&
                                        checkRelease
                                        ? moment(
                                          predata?.[0]?.sprintenddate
                                        ).format("DD-MM-YYYY")
                                        : moment(
                                          reportdata?.[0]?.SprintEndDate
                                        ).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="">
                                  <Col className=" pl-1 ml-3" sm={6}>
                                    <label className="SprintlabelStyle">
                                      Releaseddate
                                    </label>
                                  </Col>
                                  <Col className="pl-1 ml-1">
                                    <span
                                      className="font-weight-bold text-dark"
                                      style={{ fontSize: 16 }}
                                    >
                                      {reportType == "Historyreport" &&
                                        checkRelease
                                        ? ""
                                        : moment(
                                          reportdata?.[0]?.ReleaseDate
                                        ).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </CardBody>
                            </CardTitle>
                          </Card>
                          {/* <Card
                      className=" w-100 mt-2 rounded-lg shadow-sm"
                      style={{ height: "160px" }}
                    >
                      <CardTitle className="m-2">
                        <label className="text-info">
                          <strong>Project info</strong>
                        </label>
                        <CardBody
                          className="border-top p-0"
                          style={
                            {
                              // fontSize: "1rem",
                              // fontWeight: "700",
                            }
                          }
                        >
                          <Row className="">
                            <Col className=" pl-1  pr-1 ml-3" sm={6}>
                              <label className="SprintlabelStyle">
                                Project Version
                              </label>
                            </Col>
                            <Col className="pl-1 pt-1 ml-1">
                              <span
                                className="font-weight-bold text-dark"
                                style={{ fontSize: 16 }}
                              >
                                {sprintdata?.[0]?.releasename.length > 10
                                  ? sprintdata?.[0]?.releasename.substring(
                                      0,
                                      6
                                    ) + "..."
                                  : sprintdata?.[0]?.releasename}
                              </span>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col className="pl-1 ml-3" sm={6}>
                              <label className="SprintlabelStyle">Status</label>
                            </Col>
                            <Col className="pl-1 ml-1">
                              <Badge className="bg-danger border-0 rounded font-weight-bold">
                                {"In-Flight"}
                              </Badge>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col className="pl-1 ml-3" sm={6}>
                              <label className="SprintlabelStyle">Track</label>
                            </Col>
                            <Col className="pl-1 ml-1 " sm={4}>
                              <span
                                className="font-weight-bold text-dark"
                                style={{ fontSize: 16 }}
                              >
                                {parseInt(percentage?.[findDay]) >
                                parseInt(chartdata?.[findDay]?.plan)
                                  ? findDay == undefined
                                    ? ""
                                    : findDay + "th Day"
                                  : findDay == undefined
                                  ? ""
                                  : findDay + "th Day"}

                                {/* <Slider
                                    className={getColor(
                                      percentage?.[
                                        percentage.length - currentDifference
                                      ]
                                    )}
                                    size="small"
                                    value={
                                      percentage?.[
                                        percentage.length - currentDifference
                                      ]
                                    }
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                    disabledSlider={true}
                                  /> */}
                          {/* <ProgressBar
                                    className=""
                                    variant={
                                      parseInt(percentage?.[findDay]) >
                                        parseInt(chartdata?.[findDay]?.plan)
                                        ? "success"
                                        : "danger"
                                    }
                                    animated
                                    now={
                                      percentage?.[
                                      percentage.length - currentDifference
                                      ]
                                    }
                                  /> */}
                          {/* </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </CardTitle>
                    </Card> */}
                          <Card
                            className=" w-100 mt-2 rounded-lg shadow-sm border"
                            style={{ minHeight: "200px" }}
                          >
                            <CardTitle className="m-2">
                              <label style={{ color: "#EA1179" }}>
                                <strong>Stage Move info</strong>
                              </label>
                              <CardBody
                                className="border-top p-0"
                                style={
                                  {
                                    // fontSize: "1rem",
                                    // fontWeight: "700",
                                  }
                                }
                              >
                                <Row className="">
                                  <Col className=" pl-1 pr-1 ml-3" sm={6}>
                                    <label className="SprintlabelStyle">
                                      Total issues
                                    </label>
                                  </Col>
                                  <Col className="pt-1  d-flex justify-content-center">
                                    <span
                                      className="font-weight-bold text-dark"
                                      style={{ fontSize: 16 }}
                                    >
                                      {/* {userID != null
                                    ? cmt2
                                    : predata?.map((e) => e.issueid).length}
                                  {/* {reportType == "Historyreport"
                                  ? predata?.map((e) => e.issueid).length
                                  : cmt2} */}
                                      {checkRelease
                                        ? predata?.length
                                        : reportdata?.[0]?.ReleaseIssues.length}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="">
                                  <Col className=" pl-1 pr-1 ml-3" sm={6}>
                                    <label className="SprintlabelStyle">
                                      Moved to dev
                                    </label>
                                  </Col>
                                  <Col className="pt-1  d-flex justify-content-center">
                                    <span
                                      className="font-weight-bold text-dark"
                                      style={{ fontSize: 16 }}
                                    >
                                      {/* {cmt} */}
                                      {checkRelease
                                        ? cmt
                                        : reportdata?.[0]?.ReportsData?.[0]
                                          ?.Cumulative?.DEV?.[
                                        reportdata?.[0]?.ReportsData?.[0]
                                          ?.Cumulative?.DEV?.length - 1
                                        ]}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="">
                                  <Col className=" pl-1 ml-3" sm={6}>
                                    <label className="SprintlabelStyle">
                                      Moved to UAT
                                    </label>
                                  </Col>
                                  <Col className="pt-1 d-flex justify-content-center">
                                    <span
                                      className="font-weight-bold text-dark"
                                      style={{ fontSize: 16 }}
                                    >
                                      {checkRelease
                                        ? cmt3
                                        : reportdata?.[0]?.ReportsData?.[0]
                                          ?.Cumulative?.UAT?.[
                                        reportdata?.[0]?.ReportsData?.[0]
                                          ?.Cumulative?.UAT?.length - 1
                                        ]}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="">
                                  <Col className=" pl-1 ml-3" sm={6}>
                                    <label className="SprintlabelStyle">
                                      Moved to Done
                                    </label>
                                  </Col>
                                  <Col className="pt-1 d-flex justify-content-center">
                                    <span
                                      className="font-weight-bold text-dark"
                                      style={{ fontSize: 16 }}
                                    >
                                      {/* {cmt1} */}
                                      {checkRelease
                                        ? cmt1
                                        : reportdata?.[0]?.ReportsData?.[0]
                                          ?.Cumulative?.DONE?.[
                                        reportdata?.[0]?.ReportsData?.[0]
                                          ?.Cumulative?.DONE?.length - 1
                                        ]}
                                    </span>
                                  </Col>
                                </Row>
                                {/* <Row className="">
                              <Col className=" pl-1 ml-3" sm={6}>
                                <label className="SprintlabelStyle">
                                  Remaining in Refined
                                </label>
                              </Col>
                              <Col className="pt-1 d-flex justify-content-center">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {userID != null
                                    ? cmt2 - cmt
                                    : predata?.map((e) => e.issueid).length -
                                      cmt}
                                  {/* {reportType == "Historyreport"
                                  ? predata?.map((e) => e.issueid).length - cmt
                                  : lp?.filter((e) => e?.[0].StageCode == "RFD")
                                      .length} 
                                </span>
                              </Col>
                            </Row> */}
                              </CardBody>
                            </CardTitle>
                          </Card>
                          {/* <Card
                        className=" w-100 mt-2 rounded-lg shadow-sm"
                        style={{ height: "150px" }}
                      >
                        <CardTitle className="m-2 ">
                          <label style={{ color: "#45CFDD" }}>
                            <strong>Current Stage info</strong>
                          </label>
                          <CardBody
                            className="border-top p-0 mb-1"
                            style={
                              {
                                // fontSize: "1rem",
                                // fontWeight: "700",
                              }
                            }
                          >
                            <Row className="">
                              <Col className=" pl-1 pr-1 ml-3" sm={6}>
                                <label className="SprintlabelStyle">
                                  Refined
                                </label>
                              </Col>
                              <Col className="pt-1 d-flex justify-content-center">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {
                                    lp?.filter((e) => e?.[0].StageCode == "RFD")
                                      .length
                                  }
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className=" pl-1 ml-3 " sm={6}>
                                <label className="SprintlabelStyle">
                                  Development
                                </label>
                              </Col>
                              <Col className="pt-1 d-flex justify-content-center">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {
                                    lp?.filter((e) => e?.[0].StageCode == "DEV")
                                      .length
                                  }
                                </span>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col className="pl-1 ml-3" sm={6}>
                                <label className="SprintlabelStyle">Done</label>
                              </Col>
                              <Col className=" pt d-flex justify-content-center">
                                <span
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 16 }}
                                >
                                  {
                                    lp?.filter(
                                      (e) => e?.[0].StageCode == "DONE"
                                    ).length
                                  }
                                </span>
                              </Col>
                            </Row>
                          </CardBody>
                        </CardTitle>
                      </Card> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="mt-5">
              <SprintReportTable
                tabledata={sprintdata}
                userID={userID}
                releaseID={releaseID}
                percentage={percentage}
              />
            </div> */}
                  {/* <div id="op"> */}
                  <div className=" p-2">
                    <Card className="chart-area  myperformance1 shadow-sm border">
                      <div className="mt-1">
                        <Historyburnupanddown
                          // data={sprintdata}
                          // userID={userID}
                          releaseID={releaseID}
                          checkRelease={checkRelease}
                          percentage1={percentage1}
                          chartdata1={chartdata1}
                          versionDays={versionDays}
                          // includays1={includays1}
                          // projectname={projectName}
                          // versionname={sprintdata?.[0]?.releasename}
                          // releaseversion={releaseversion}
                          // predata={predata}
                          // setpredata={setpredata}
                          // workingDays={workingDays}
                          reportdata={reportdata}
                        />
                      </div>
                    </Card>
                  </div>

                  <div className=" p-2">
                    <Card className="chart-area  myperformance1  shadow-sm border">
                      <div className="mt-1">
                        <Historyburndown
                          // data={sprintdata}
                          // userID={userID}
                          releaseID={releaseID}
                          percentage1={percentage1}
                          versionDays={versionDays}
                          // chartdata1={chartdata1}
                          // excludeday={excludeday}
                          // includays1={includays1}
                          chart1={chart1}
                          checkRelease={checkRelease}
                          // projectname={projectName}
                          // versionname={sprintdata?.[0]?.releasename}
                          // releaseversion={releaseversion}
                          // predata={predata}
                          reportdata={reportdata}
                        />
                      </div>
                    </Card>
                  </div>
                </div>
                {/* </div> */}

                <div className="mt-4 border" id="op">
                  <HistorySprintReportTable
                    className="border"
                    tabledata={predata}
                    userID={userID}
                    releaseID={releaseID}
                    percentage1={percentage1}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </TabPanel>
    </div>
  );
}

export default Report;
