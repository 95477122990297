// import axios from './axios';

// export default async function getFile(cid, filename) {
//     try {
//         const response = await axios.get(`/api/agile/filesystem/issues/${cid}/${filename}`, {
//             responseType: "arraybuffer",
//         });

//         const contentType = response.headers['content-type'];

//         if (contentType.includes('image')) {
//             // For images (PNG, JPEG)
//             const arrayBufferView = new Uint8Array(response.data);
//             const blob = new Blob([arrayBufferView], { type: contentType });
//             const imageUrl = URL.createObjectURL(blob);

//             // Display image in an <img> tag
//             const imageElement = document.createElement('img');
//             imageElement.src = imageUrl;
//             document.body.appendChild(imageElement); // Appending image to the body
//         } else {
//             // For other file types (PDF, Word, Excel)
//             const blob = new Blob([response.data], { type: contentType });

//             // Create a link element for downloading the file
//             const downloadLink = document.createElement('a');
//             downloadLink.href = URL.createObjectURL(blob);
//             downloadLink.download = filename; // Set the filename for downloading
//             downloadLink.innerText = `Download ${filename}`;
//             document.body.appendChild(downloadLink); // Appending download link to the body
//         }
//     } catch (error) {
//         console.error(error);
//         // Handle the error as needed
//     }
// }
import axios from './axios';
// import axios from 'axios'

export default async function getFile(cid, filename) {
    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'http://localhost:2002/api/agile/filesystem/CreateIssue',
    //   data: data
    // };

    // axios.request(config)
    try {
        const response = await axios.get(`api/agile/filesystem/issues/${cid}/${filename}`, {
            responseType: "arraybuffer",
        });
        const contentType = response.headers['content-type'];
        const fileContent = new Blob([response.data], { type: contentType });
        return fileContent;
    } catch (error) {
        console.error(error);
        // Handle the error as needed
        throw error; // Re-throw the error to handle it elsewhere if necessary
    }
}
