import React, { useEffect, useState } from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import SysDiagram from './SysDiagram';
import "react-circular-progressbar/dist/styles.css";
import './DeployApp.css';
import Axios from "axios";
import url from "../devEnj.json";
import Cookies from "universal-cookie";
import ColoredLinearProgress from './ColoredLinearProgress';

function DeployApp({ isIncremental, setProgressFinished, selectedData, projCode, setShowDeployProgress, isci, deployment, setDeployPopup }) {
    let email = JSON.parse(localStorage.getItem("userdata"));
    let empid = JSON.parse(localStorage.getItem("Empdetails"));
    let clientid = localStorage.getItem("clientid") == "undefined" ? "" : JSON.parse(localStorage.getItem("clientid"));
    const company = empid?.[0]?.ClientDetails?.Company?.replace(/ /g, "");
    let tenantid = `devozy_${company}_${clientid}`
    const [deployError, setDeployError] = useState(true)

    let depStages = !isci ? {
        overallStatus: "CD Deployment",
        overallError: false,
        percentage: 0,
        timeleft: 600,
        stages: [
            {
                stage: 1,
                hidden: true,
                description: "Onboard project",
                percentage: 0,
                status: "Pipeline",
                timeleft: 0,
                steps: [
                    {
                        id: "project-added",
                        step: 1,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Add project into database",
                    },
                    {
                        id: "ssh-setup",
                        step: 2,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "SSH setup Pipeline",
                    },
                    {
                        id: "ssh-connectivity",
                        step: 3,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "SSH connectivity Pipeline",
                    },
                ],
            },
            {
                stage: 2,
                hidden: true,
                description: "Integration environment preparation",
                percentage: 0,
                status: "Pipeline",
                timeleft: 0,
                steps: [
                    {
                        id: "5ec3a8b1795c492d9cdcec62",
                        step: 1,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Software/plugin installation",
                    },
                    {
                        id: "5ec3a8b1795c492d9cdcec66",
                        step: 2,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Setup configuration",
                    },
                    {
                        id: "5ec3a8b1795c492d9cdcec68",
                        step: 3,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Validate installation and configurations",
                    },

                ],
            },
            {
                stage: 3,
                hidden: true,
                description: "Project environment preparation",
                percentage: 0,
                status: "Pipeline",
                timeleft: 0,
                steps: [
                    {
                        id: "software-installation",
                        step: 1,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Required software installation",
                    },
                    {
                        id: "environment-configuration",
                        step: 2,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Environment configuration",
                    },
                ],
            },
            {
                stage: 4,
                hidden: true,
                description: "Application Deployment",
                percentage: 0,
                status: "Pipeline",
                timeleft: 360,
                steps: [
                    {
                        id: "job-pipeline",
                        step: 1,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Run job pipeline",
                    },
                    {
                        id: "job-deployment",
                        step: 2,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Application deployment",
                    },
                ],
            },
            {
                stage: 5,
                hidden: true,
                description: "Verify application",
                percentage: 0,
                status: "Pipeline",
                timeleft: 240,
                steps: [
                    {
                        id: "verify-application",
                        step: 1,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Verifying application",
                    },
                    {
                        id: "url-test",
                        step: 2,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Test application URL",
                    },
                    {
                        id: "5ec3a8b1795c492d9cdcec68",
                        step: 3,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "CI File Upload",
                    }
                ],
            },
            {
                stage: 6,
                hidden: false,
                description: "CD Deployments",
                percentage: 0,
                status: "Pipeline",
                timeleft: 240,
                steps: [
                    {
                        id: "job-pipeline",
                        step: 1,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Downloadind File",
                    },
                    {
                        id: "job-deployment",
                        step: 2,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Copying File",
                    },
                    {
                        id: "verify-application",
                        step: 3,
                        hidden: false,
                        percentage: 0,
                        status: "Pipeline",
                        description: "Application Deployment",
                    }
                ],
            }
        ],
    } : isIncremental
        ? {
            overallStatus: "Add project into database",
            overallError: false,
            percentage: 0,
            timeleft: 600,
            stages: [
                {
                    stage: 1,
                    hidden: true,
                    description: "Onboard project",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 0,
                    steps: [
                        {
                            id: "project-added",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Add project into database",
                        },
                        {
                            id: "ssh-setup",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "SSH setup Pipeline",
                        },
                        {
                            id: "ssh-connectivity",
                            step: 3,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "SSH connectivity Pipeline",
                        },
                    ],
                },
                {
                    stage: 2,
                    hidden: true,
                    description: "Integration environment preparation",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 0,
                    steps: [
                        {
                            id: "5ec3a8b1795c492d9cdcec62",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Software/plugin installation",
                        },
                        {
                            id: "5ec3a8b1795c492d9cdcec66",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Setup configuration",
                        },
                        {
                            id: "5ec3a8b1795c492d9cdcec68",
                            step: 3,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Validate installation and configurations",
                        },

                    ],
                },
                {
                    stage: 3,
                    hidden: true,
                    description: "Project environment preparation",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 0,
                    steps: [
                        {
                            id: "software-installation",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Required software installation",
                        },
                        {
                            id: "environment-configuration",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Environment configuration",
                        },
                    ],
                },
                {
                    stage: 4,
                    hidden: false,
                    description: "Application Deployment",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 360,
                    steps: [
                        {
                            id: "job-pipeline",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Run job pipeline",
                        },
                        {
                            id: "job-deployment",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Application deployment",
                        },
                    ],
                },
                {
                    stage: 5,
                    hidden: false,
                    description: "Verify application",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 240,
                    steps: [
                        {
                            id: "verify-application",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Verifying application",
                        },
                        {
                            id: "url-test",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Test application URL",
                        },
                        {
                            id: "5ec3a8b1795c492d9cdcec68",
                            step: 3,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "CI File Upload",
                        }
                    ],
                },
                {
                    stage: 6,
                    hidden: true,
                    description: "CD Deployments",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 240,
                    steps: [
                        {
                            id: "job-pipeline",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Downloadind File",
                        },
                        {
                            id: "job-deployment",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Copying File",
                        },
                        {
                            id: "verify-application",
                            step: 3,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Application Deployment",
                        }
                    ],
                }
            ],
        }
        : {
            overallStatus: "Add project into database",
            overallError: false,
            percentage: 0,
            timeleft: 1800,
            stages: [
                {
                    stage: 1,
                    hidden: false,
                    description: "Onboard project",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 420,
                    steps: [
                        {
                            id: "project-added",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Add project into database",
                        },
                        {
                            id: "ssh-setup",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "SSH setup Pipeline",
                        },
                        {
                            id: "ssh-connectivity",
                            step: 3,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "SSH connectivity Pipeline",
                        },
                    ],
                },
                {
                    stage: 2,
                    hidden: false,
                    description: "Integration environment preparation",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 480,
                    steps: [
                        {
                            id: "5ec3a8b1795c492d9cdcec62",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Software/plugin installation",
                        },
                        {
                            id: "5ec3a8b1795c492d9cdcec66",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Setup configuration",
                        },
                        {
                            id: "5ec3a8b1795c492d9cdcec68",
                            step: 3,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Validate installation and configurations",
                        },
                    ],
                },
                {
                    stage: 3,
                    hidden: false,
                    description: "Project environment preparation",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 420,
                    steps: [
                        {
                            id: "software-installation",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Required software installation",
                        },
                        {
                            id: "environment-configuration",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Environment configuration",
                        },
                    ],
                },
                {
                    stage: 4,
                    hidden: false,
                    description: "Application Deployment",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 300,
                    steps: [
                        {
                            id: "job-pipeline",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Run job pipeline",
                        },
                        {
                            id: "job-deployment",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Application deployment",
                        },
                    ],
                },
                {
                    stage: 5,
                    hidden: false,
                    description: "Verify application",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 180,
                    steps: [
                        {
                            id: "verify-application",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Verifying application",
                        },
                        {
                            id: "url-test",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Test application URL",
                        },
                        {
                            id: "5ec3a8b1795c492d9cdcec68",
                            step: 3,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "CI File Upload",
                        }
                    ],
                },
                {
                    stage: 6,
                    hidden: true,
                    description: "CD Deployments",
                    percentage: 0,
                    status: "Pipeline",
                    timeleft: 240,
                    steps: [
                        {
                            id: "job-pipeline",
                            step: 1,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Downloadind File",
                        },
                        {
                            id: "job-deployment",
                            step: 2,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Copying File",
                        },
                        {
                            id: "verify-application",
                            step: 3,
                            hidden: false,
                            percentage: 0,
                            status: "Pipeline",
                            description: "Application Deployment",
                        }
                    ],
                }
            ],
        };
    const [buttonshow, setButtonshow] = useState(true)
    const [currentProject, setCurrentProject] = useState("")
    const [projectAdded, setProjectAdded] = useState(false)
    const [apiCompleted, setApiCompleted] = useState(false)
    const [processCompleted, setProcessCompleted] = useState(false)
    const [errorOccured, setErrorOccured] = useState(false)
    const [deploymentPercentage, setDeploymentPercentage] = useState(0)
    const [deploymentStages, setDeploymentStages] = useState(depStages)
    const [appURL, setAppURL] = useState("")
    const [startingTime, setStartingTime] = useState(new Date())
    const [stage_actual_time, setStage_actual_time] = useState({
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
    })



    const cookies = new Cookies();




    const getAPIData = () => {
        Axios({
            url: `https://api.devozy.ai/api/v1/devozy/deploy/getProgress`,
            headers: {
                // "Access-Control-Allow-Origin": "*",
                "tenantId": tenantid,
                "email": email?.login,
                Authorization: "Bearer " + cookies.get("token"),
            },
            params: { projectCode: projCode },
            method: "get",
        })


            .then((res) => {
                console.log(res.data, "Deployment Check")
                // console.log(res.progressDetails, "111111111111")
                console.log(res.data?.progressDetails, res.data?.progressDetails.length, "Deployment Check")
                // create json with all stages and steps and update state
                if (res?.data?.progressDetails?.length) {
                    // console.log(res.progressDetails, "rrrrrrrrrrrrr")
                    let FinalCode = Math.max.apply(
                        0,
                        Array.from(
                            new Set(
                                res?.data?.progressDetails.map((d) => {
                                    return d.DeploymentCode.split("DEP")[1];
                                })
                            )
                        )
                    );
                    console.log(FinalCode, "Deployment Check666")
                    FinalCode = "DEP" + FinalCode;
                    // console.log(FinalCode, res.progressDetails, "000000");
                    const sortedArr = res?.data?.progressDetails
                        .filter((e) => e.DeploymentCode == FinalCode)
                        .sort(function (a, b) {
                            return a.OverallProgress - b.OverallProgress;
                        });
                    console.log(sortedArr, "Deployment Check674");
                    executeEachStep(sortedArr, "fromAPI", res?.data?.progressDetails?.length != 0);
                }
            })
            .catch((err) => {
                setProcessCompleted(true)
                setErrorOccured(true)
                if (deployError) {
                    setDeployError(true);
                }
                // this.setState({ processCompleted: true, errorOccured: true }, () => {
                //   if (deployError) {
                //     setDeployError(true);
                //   }
                // });
            });
    };

    // return value of this.state.projectAdded
    const returnStateField = () => {
        console.log(processCompleted)
        return processCompleted;
    }

    const callProgressAPI = () => {
        console.log("callProgressAPI")
        // if error happens during add project API
        if (
            projCode &&
            projCode.toUpperCase() === "ERROR"
        ) {
            executeEachStep([
                {
                    _id: "project-not-added",
                    DeploymentCode: 0,
                    ProjectCode: projCode,
                    Stage: 1,
                    StageDescription: "Onboard project",
                    Step: 1,
                    StepDescription: "Error occured on add project",
                    StageProgress: 0,
                    StepProgress: 0,
                    OverallStatus: "project addition in database",
                    OverallProgress: 0,
                    IsDisplayed: false,
                    IsError: true,
                    IsEnd: false,
                },
            ]);
        }
        // if project added successfully, starts the progress
        else if (!projectAdded && projCode) {
            let initialStage = [
                {
                    _id: "project-added",
                    DeploymentCode: 0,
                    ProjectCode: projCode,
                    Stage: 1,
                    StageDescription: "Onboard project",
                    Step: 1,
                    StepDescription:
                        "Project added into database: " + projCode,
                    StageProgress: 40,
                    StepProgress: 100,
                    OverallStatus: "Project added in database",
                    OverallProgress: 10,
                    IsDisplayed: false,
                    IsError: false,
                    IsEnd: false,
                },
                {
                    _id: "ssh-setup",
                    DeploymentCode: 0,
                    ProjectCode: projCode,
                    Stage: 1,
                    StageDescription: "Onboard project",
                    Step: 2,
                    StepDescription: "SSH setup completed",
                    StageProgress: 70,
                    StepProgress: 100,
                    OverallStatus: "SSH setup",
                    OverallProgress: 20,
                    IsDisplayed: false,
                    IsError: false,
                    IsEnd: false,
                },
                {
                    _id: "ssh-connectivity",
                    DeploymentCode: 0,
                    ProjectCode: projCode,
                    Stage: 1,
                    StageDescription: "Onboard project",
                    Step: 3,
                    StepDescription: "SSH connectivity completed",
                    StageProgress: 100,
                    StepProgress: 100,
                    OverallStatus: "SSH connectivity",
                    OverallProgress: 30,
                    IsDisplayed: false,
                    IsError: false,
                    IsEnd: false,
                },
            ];
            // call method to display above steps in UI
            executeEachStep(initialStage);
        }
        // if API dependent steps done, start final static steps
        else if (
            projectAdded &&
            projCode &&
            apiCompleted
        ) {
            afterAPIData();
        }
        // if project added and initial static steps done, start to read from API
        else if (projectAdded && projCode) {
            if (currentProject !== projCode)
                setCurrentProject(projCode)
            // this.setState({ currentProject: this.props.projCode });
            setTimeout(() => {
                getAPIData();
            }, 14000);
        }
    };


    const callIncrementalProgressAPI = () => {
        console.log(isci, "iiiiiiiiiiiiiiiiiiiii")
        // if project added successfully, starts the progress
        if (!isci) {
            console.log('if called', projectAdded, projCode)
            if (!projectAdded && projCode) {
                console.log('if if called', projectAdded, projCode)
                let initialStage = [
                    {
                        _id: "job-pipeline",
                        DeploymentCode: 0,
                        ProjectCode: projCode,
                        Stage: 6,
                        StageDescription: "CD Deployment",
                        Step: 1,
                        StepDescription: "Downloading file",
                        StageProgress: 1,
                        StepProgress: 3,
                        OverallStatus: "Downloading file",
                        OverallProgress: 1,
                        IsDisplayed: false,
                        IsError: false,
                        IsEnd: false,
                    },
                ];
                // call method to display above steps in UI
                executeEachStep(initialStage);
            }
            // if API dependent steps done, start final static steps
            else if (
                projectAdded &&
                projCode &&
                apiCompleted
            ) {
                console.log('else if')
                afterCDAPIData();
            }
            // if project added and initial static steps done, start to read from API
            else if (projectAdded && projCode) {
                console.log('else if 2')
                if (currentProject !== projCode) {
                    console.log('else if 2 if')
                    setCurrentProject(projCode)
                }
                getAPIData();
            }
        }
        else {
            console.log('else called')
            if (!projectAdded && projCode) {
                console.log('else if, if called')
                let initialStage = [
                    {
                        _id: "job-pipeline",
                        DeploymentCode: 0,
                        ProjectCode: projCode,
                        Stage: 4,
                        StageDescription: "1Application Deployment",
                        Step: 1,
                        StepDescription: "Run job pipeline",
                        StageProgress: 10,
                        StepProgress: 20,
                        OverallStatus: "Running job pipeline",
                        OverallProgress: 3,
                        IsDisplayed: false,
                        IsError: false,
                        IsEnd: false,
                    },
                ];
                // call method to display above steps in UI
                executeEachStep(initialStage);
            }
            // if API dependent steps done, start final static steps
            else if (
                projectAdded &&
                projCode &&
                apiCompleted
            ) {
                console.log('else if else called')
                afterAPIData();
            }
            // if project added and initial static steps done, start to read from API
            else if (projectAdded && projCode) {
                console.log('else if else else called')
                if (currentProject !== projCode) {
                    console.log('else if else else if called')
                    setCurrentProject(projCode)
                }
                //   this.setState({ currentProject: this.props.projCode });
                getAPIData();
            }
        }
    };
    console.log(deploymentStages, "Deployment checkkkkkkk")
    console.log(returnStateField())

    useEffect(() => {
        getAPIData()
        const intervalId = setInterval(() => {
            // Your function to execute at regular intervals
            console.log("use effect", isIncremental)
            isIncremental ? callIncrementalProgressAPI() : callProgressAPI();
        }, 10 * 1000);

        // Clear the interval if processCompleted becomes true
        if (processCompleted && deploymentPercentage == 100) {
            clearInterval(intervalId);
        }

        // Clean-up function to clear the interval when component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, [processCompleted, deploymentPercentage, deployment]);
    console.log(processCompleted, deploymentPercentage, deployment, "iiiiiiiiiiiiiiiiiiiiiiiii")
    // useEffect(() => {
    //     // props to show progress for 1st deploy or incremental deploy
    //     // let isIncremental = isIncremental;
    //     // console.log
    //     let isIncremental = true;

    //     let progressAPI = callProgressAPI;
    //     let incrementalProgressAPI = callIncrementalProgressAPI;
    //     let doTerminate = returnStateField;
    //     // getAPIData()
    //     // callIncrementalProgressAPI()
    //     // function to run on interval till initial step executed
    //     const intervalId = setInterval(() => {
    //         if (processCompleted)
    //             clearInterval(interval);
    //         else {
    //             isIncremental ? callIncrementalProgressAPI() : callProgressAPI();
    //         }
    //     }, 10 * 1000); // Interval of 1 second

    //     // Cleanup function to clear the interval when the component unmounts or changes
    //     return () => clearInterval(intervalId);
    //     // function increment() {
    //     //     console.log('increment called')

    //     // }
    //     // let interval = setInterval(increment, 10 * 1000);
    //     // // if (deploymentPercentage != 100) {
    //     // increment()
    //     // }


    // }, [deploymentPercentage])

    const getstageDuration = () => {
        let timeInSec = (new Date() - startingTime) / 1000;
        let minRemain = Math.floor(timeInSec / 60);
        let secRemain = Math.floor(timeInSec % 60);
        let returnLeftTime = "";
        if (minRemain > 0) returnLeftTime += minRemain + "min ";
        if (secRemain > 0) returnLeftTime += secRemain + "sec";
        return returnLeftTime;
    };
    // Calculate time duration of deployment process
    const getDeploymentDuration = () => {
        let timeInSec = (new Date() - startingTime) / 1000;
        let minRemain = Math.floor(timeInSec / 60);
        let secRemain = Math.floor(timeInSec % 60);
        let returnLeftTime = "";
        if (minRemain > 0) returnLeftTime += minRemain + "min ";
        if (secRemain > 0) returnLeftTime += secRemain + "sec";
        return returnLeftTime;
    };

    // Calculate time left in sec for each stage and overall
    const calculateTimeLeftInSec = (stage, completion, isIncremental) => {
        let timer = 0;
        let timeInSec = 0;
        console.log(stage, "stage");
        switch (stage) {
            case 1:
                if (completion === 100) {
                    timer = (new Date() - startingTime) / 1000;
                    var stageactual1 = { ...stage_actual_time };
                    stageactual1[1] = Math.round(timer);
                    setStage_actual_time(stageactual1)
                }
                timeInSec = (420 / 100) * (100 - completion); // 7min
                break;
            case 2:
                if (completion === 100) {
                    timer = (new Date() - startingTime) / 1000;
                    var stageactual2 = { ...stage_actual_time };
                    stageactual2[2] =
                        Math.round(timer) - stage_actual_time[1] < 0
                            ? 0
                            : Math.round(timer) - stage_actual_time[1];
                    setStage_actual_time(stageactual2)
                }
                timeInSec = (480 / 100) * (100 - completion); // 8min
                break;
            case 3:
                if (completion === 100) {
                    timer = (new Date() - startingTime) / 1000;
                    var stageactual = { ...stage_actual_time };
                    stageactual[3] =
                        Math.round(timer) -
                            (stage_actual_time[2] -
                                stage_actual_time[1]) <
                            0
                            ? 0
                            : Math.round(timer) -
                            (stage_actual_time[2] -
                                stage_actual_time[1]);
                    setStage_actual_time(stageactual)
                }
                timeInSec = (420 / 100) * (100 - completion); // 7min
                break;
            case 4:
                if (completion === 100) {
                    timer = (new Date() - startingTime) / 1000;
                    var stageactual4 = { ...stage_actual_time };
                    stageactual4[4] =
                        Math.round(timer) -
                            (stage_actual_time[3] -
                                stage_actual_time[2] -
                                stage_actual_time[1]) <
                            0
                            ? 0
                            : Math.round(timer) -
                            (stage_actual_time[3] -
                                stage_actual_time[2] -
                                stage_actual_time[1]);
                    setStage_actual_time(stageactual4)
                    //   this.setState({
                    //     stage_actual_time: stageactual4,
                    //   });
                }
                timeInSec = isIncremental
                    ? (360 / 100) * (100 - completion)
                    : (300 / 100) * (100 - completion); // 6min / 5min
                break;
            case 5:
                if (completion === 100) {
                    timer = (new Date() - startingTime) / 1000;
                    console.log(timer, "stage5");
                    var stageactual5 = { ...stage_actual_time };
                    stageactual5[5] =
                        Math.round(timer) -
                            (stage_actual_time[4] -
                                stage_actual_time[3] -
                                stage_actual_time[2] -
                                stage_actual_time[1]) <
                            0
                            ? 0
                            : Math.round(timer) -
                            (stage_actual_time[4] -
                                stage_actual_time[3] -
                                stage_actual_time[2] -
                                stage_actual_time[1]);
                    setStage_actual_time(stageactual5)
                }
                timeInSec = isIncremental
                    ? (240 / 100) * (100 - completion)
                    : (180 / 100) * (100 - completion); // 4min / 3min
                break;
            case "overall":
                timeInSec = isIncremental
                    ? (600 / 100) * (100 - completion)
                    : (1800 / 100) * (100 - completion); // 10min / 30min
                break;
            default:
                break;
        }
        return timeInSec;
    };

    // Converts timeleft seconds in minute and second
    const convertTimeLeft = (currentStage) => {
        let totalTimeRemain = 0;
        deploymentStages.stages.map((stages) => {
            if (stages.stage <= currentStage || currentStage === "overall") {
                totalTimeRemain += stages.timeleft;
            }
            return "";
        });
        let minRemain = Math.floor(totalTimeRemain / 60);
        let secRemain = Math.floor(totalTimeRemain % 60);
        let returnLeftTime = "";
        if (minRemain > 0) returnLeftTime += minRemain + "min ";
        if (secRemain > 0) returnLeftTime += secRemain + "sec";

        return returnLeftTime;
    };

    const executeEachStep = (progressStepArray, dataFrom, check) => {
        console.log(progressStepArray, "setDeploymentPercentage", dataFrom, check)
        let addedProjectStep = createProgressJSON(progressStepArray);
        if (check) {
            setDeploymentPercentage(addedProjectStep.percentage)
            // setProcessCompleted(true)

        }
        // dataFrom is 'afterAPI' means API has given all stage details successfully
        if (dataFrom === "afterAPI") {
            addedProjectStep.percentage = 100;
        }

        if (addedProjectStep.overallError) {
            if (addedProjectStep.percentage === 100 && dataFrom === "fromAPI") {
                // getting overall % as 100 but process is not finished so changed to 95
                addedProjectStep.percentage = 95;
            }
            setDeploymentStages(addedProjectStep)
            setProcessCompleted(true)
            setErrorOccured(true)
            // if (this.props.setDeployError) { this.props.setDeployError(true); }
            if (setProgressFinished) { setProgressFinished(true); } // to show 'close' button on incremental progess
            // this.props.addProjectDone({ projCode: '' }); // reset redux project code after finishing progress

        }
        else if (addedProjectStep.percentage === 100 && dataFrom === "fromAPI") {
            // getting overall % as 100 but process is not finished so changed to 95
            addedProjectStep.percentage = 95;
            // set state with step and call method to show static next step
            setDeploymentStages(addedProjectStep)
            setApiCompleted(true)
        }
        else if (addedProjectStep.percentage === 100) {
            setTimeout(() => {
                setProcessCompleted(true)
                setDeploymentStages(addedProjectStep)
                setDeploymentPercentage(100)
                if (setProgressFinished) { setProgressFinished(true); } // to show 'close' button on incremental progess

            }, 200);
            // this.props.addProjectDone({ projCode: '' }); // reset redux project code after finishing progress
        } else {
            // set state with step and call method to get next step from API
            setProjectAdded(true)
            setDeploymentStages(addedProjectStep)
        }
    }

    //cd
    const afterCDAPIData = () => {
        let stageAfterAPI = [
            {
                _id: "job-pipeline",
                DeploymentCode: 0,
                ProjectCode: projCode,
                Stage: 6,
                StageDescription: "CD Deployment",
                Step: 1,
                StepDescription: "Downloading file",
                StageProgress: 50,
                StepProgress: 100,
                OverallStatus: "Downloading file",
                OverallProgress: 50,
                IsDisplayed: false,
                IsError: false,
                IsEnd: false,
            },
            {
                _id: "job-deployment",
                DeploymentCode: 0,
                ProjectCode: projCode,
                Stage: 6,
                StageDescription: "CD Deployment",
                Step: 3,
                StepDescription: "Copying File",
                StageProgress: 70,
                StepProgress: 100,
                OverallStatus: "Copying File",
                OverallProgress: 70,
                IsDisplayed: false,
                IsError: false,
                IsEnd: false,
            },
            {
                _id: "verify-application",
                DeploymentCode: 0,
                ProjectCode: projCode,
                Stage: 6,
                StageDescription: "CD Deployment",
                Step: 2,
                StepDescription: "Application Deployment",
                StageProgress: 100,
                StepProgress: 100,
                OverallStatus: "Application Deployment",
                OverallProgress: 100,
                IsDisplayed: false,
                IsError: false,
                IsEnd: true,
            },

        ];
        executeEachStep(stageAfterAPI, "afterAPI"); // Empty arr because all stage is coming from API, so no need to hard-code afterAPI array
        setButtonshow(false)
        // this.setState({ buttonshow: false })
        // let result = {
        //     proj_data: {},
        //     repo_data: {},
        //     db_data: {},
        //     integration_data: {},
        //     stage_data: {},
        // };
        // updateAddAppForm(result);
    }

    // Format response into well-designed progress JSON
    const createProgressJSON = (progressDetails, dataFrom) => {
        let currentState = { ...deploymentStages };
        let stepObj = {};
        let stageObj = {};
        console.log(currentState, progressDetails, "progressDetails");

        progressDetails.map((eachObj, index, sameArr) => {
            // get stage object if already available
            stageObj = currentState["stages"].find((eachStage) => {
                return Number(eachStage.stage) === Number(eachObj.Stage);
            });

            // create stage object if not get from currentState
            if (!stageObj) {
                stageObj = {
                    stage: eachObj.Stage,
                    hidden: false,
                    description: eachObj.StageDescription,
                    percentage:
                        !eachObj.IsError && eachObj.IsEnd && dataFrom === "fromAPI"
                            ? 80
                            : eachObj.StageProgress,
                    status: eachObj.IsError
                        ? "Error"
                        : Number(eachObj.StageProgress) === 0
                            ? "Pipeline"
                            : eachObj.StageProgress < 100 ||
                                (eachObj.IsEnd && dataFrom === "fromAPI")
                                ? "In-progress"
                                : "Completed",
                    steps: [],
                };
                currentState["stages"].push(stageObj);
            }
            // Update stage object if get from currentState
            else {
                // stageObj["description"] = eachObj.StageDescription;
                stageObj["percentage"] =
                    !eachObj.IsError && eachObj.IsEnd && dataFrom === "fromAPI"
                        ? 80
                        : eachObj.StageProgress < stageObj["percentage"]
                            ? stageObj["percentage"]
                            : eachObj.StageProgress;
                stageObj["status"] = eachObj.IsError
                    ? "Error"
                    : Number(stageObj["percentage"]) === 0
                        ? "Pipeline"
                        : stageObj["percentage"] < 100 ||
                            (eachObj.IsEnd && dataFrom === "fromAPI")
                            ? "In-progress"
                            : "Completed";
            }
            // calculate and assign stage time left

            stageObj["timeleft"] = calculateTimeLeftInSec(
                eachObj.Stage,
                stageObj["percentage"],
                isIncremental
            );

            // get step object if already available
            stepObj = stageObj["steps"].find((eachStep) => {
                return Number(eachStep.step) === Number(eachObj.Step);
            });
            // create step object if not get from stageObj
            if (!stepObj) {
                stepObj = {
                    id: eachObj._id,
                    step: eachObj.Step,
                    hidden: false,
                    percentage:
                        eachObj.IsEnd && dataFrom === "fromAPI" ? 60 : eachObj.StepProgress,
                    status: eachObj.IsError
                        ? "Error"
                        : Number(eachObj.StepProgress) === 0
                            ? "Pipeline"
                            : eachObj.StepProgress < 100 ||
                                (eachObj.IsEnd && dataFrom === "fromAPI")
                                ? "In-progress"
                                : "Completed",
                    description: eachObj.StepDescription,
                };
                stageObj["steps"].push(stepObj);
            }
            // Update step object if get from stageObj
            else {
                stepObj.id = eachObj._id;
                stepObj.percentage =
                    eachObj.IsEnd && dataFrom === "fromAPI"
                        ? 60
                        : eachObj.StepProgress < stepObj.percentage
                            ? stepObj.percentage
                            : eachObj.StepProgress;
                stepObj.status = eachObj.IsError
                    ? "Error"
                    : Number(stepObj.percentage) === 0
                        ? "Pipeline"
                        : stepObj.percentage < 100 ||
                            (eachObj.IsEnd && dataFrom === "fromAPI")
                            ? "In-progress"
                            : "Completed";
                if (eachObj.IsError) {
                    stepObj.description = eachObj.StepDescription;
                }
            }

            // from last stage-step-overall
            if (index + 1 === sameArr.length) {
                currentState.overallStatus = eachObj.IsError
                    ? "Error on " + eachObj.OverallStatus
                    : stepObj.description;
                currentState.percentage =
                    eachObj.IsEnd && !eachObj.IsError ? 100 : eachObj.OverallProgress;
                currentState.overallError = eachObj.IsError;
                currentState["appURL"] = eachObj.ContainerPort
                    ? eachObj.ContainerPort
                    : currentState["appURL"]
                        ? currentState["appURL"]
                        : "";
            }

            // calculate and assign stage time left
            currentState["timeleft"] = calculateTimeLeftInSec(
                "overall",
                currentState.percentage,
                isIncremental
            );
            return "";
        });
        return currentState;
    };

    /* Invoking this method on interval to get step-level-progress
      This method is for incremental deployment
    */


    /* Invoking this method on interval to get step-level-progress
      This method is for 1st time deployment
    */

    const controller = () => {
        Axios({
            url: `https://api.devozy.ai/api/v1/devozy/deploy/getProgress`,
            headers: {
                // "Access-Control-Allow-Origin": "*",
                "tenantId": tenantid,
                "email": email?.login,
                Authorization: "Bearer " + cookies.get("token"),
            },
            params: { projectCode: projCode },
            method: "get",
        }).then((res) => {
            console.log(res, "Controller")
        })
    }




    // Steps to execute after API response completed
    const afterAPIData = () => {
        /*
        {
            "_id": "software-installation",
            "DeploymentCode": 0,
            "ProjectCode": this.props.projCode,
            "Stage": 3,
            "StageDescription": "Project environment preparation",
            "Step": 1,
            "StepDescription": "Required software installation",
            "StageProgress": 50,
            "StepProgress": 100,
            "OverallStatus": "Software installation",
            "OverallProgress": 60,
            "IsDisplayed": false,
            "IsError": false,
            "IsEnd": false
          },
          {
            "_id": "environment-configuration",
            "DeploymentCode": 0,
            "ProjectCode": this.props.projCode,
            "Stage": 3,
            "StageDescription": "Project environment preparation",
            "Step": 2,
            "StepDescription": "Environment configuration",
            "StageProgress": 100,
            "StepProgress": 100,
            "OverallStatus": "Environment configuration",
            "OverallProgress": 70,
            "IsDisplayed": false,
            "IsError": false,
            "IsEnd": false
          },
        */
        let stageAfterAPI = [
            {
                "_id": "job-pipeline",
                "DeploymentCode": 0,
                "ProjectCode": projCode,
                "Stage": 4,
                "StageDescription": "Application Deployment",
                "Step": 1,
                "StepDescription": "Run job pipeline",
                "StageProgress": 50,
                "StepProgress": 100,
                "OverallStatus": "job pipeline",
                "OverallProgress": 80,
                "IsDisplayed": false,
                "IsError": false,
                "IsEnd": false
            },
            {
                "_id": "job-deployment",
                "DeploymentCode": 0,
                "ProjectCode": projCode,
                "Stage": 4,
                "StageDescription": "Application Deployment",
                "Step": 2,
                "StepDescription": "Application deployment",
                "StageProgress": 100,
                "StepProgress": 100,
                "OverallStatus": "Application deployment",
                "OverallProgress": 90,
                "IsDisplayed": false,
                "IsError": false,
                "IsEnd": false
            },
            {
                "_id": "verify-application",
                "DeploymentCode": 0,
                "ProjectCode": projCode,
                "Stage": 5,
                "StageDescription": "Verify application",
                "Step": 1,
                "StepDescription": "Test application URL",
                "StageProgress": 100,
                "StepProgress": 100,
                "OverallStatus": "Test application URL",
                "OverallProgress": 100,
                "IsDisplayed": false,
                "IsError": false,
                "IsEnd": true
            }
        ];
        // call method to display above steps in UI
        executeEachStep(stageAfterAPI, 'afterAPI'); // Empty arr because all stage is coming from API, so no need to hard-code afterAPI array
        // let result = {
        //     proj_data: {}, repo_data: {}, db_data: {}, integration_data: {}, stage_data: {}
        // }
        // this.props.updateAddAppForm(result); // to reset add-app form fields
    }




    const buttonClickHandler = (evt, flag) => {
        // this.props.history.push('/dashboard');
        setShowDeployProgress(false)
        evt.preventDefault();
        setDeployPopup(false)
    }

    const getClassAndColor = (status) => {
        let iconClass = 'fa mr-0 mr-sm-2 text-left';
        let iconColor = '#1bcfb4';
        if (status === 'Completed') {
            iconClass += ' fa-check-circle';
            iconColor = '#1bcfb4';
        }
        else if (status === 'Not executed' || status === 'Error' || status === "Warning") {
            iconClass += ' fa-exclamation-triangle';
            iconColor = status === "Warning" ? '#d6a309' : '#cf022b';
        }
        else if (status === 'In-progress') {
            iconClass += ' fa-circle';
            iconColor = '#638a83';
        }
        else if (status === 'Pipeline') {
            iconClass += ' fa-circle-o';
            iconColor = '#86a9a3';
        }
        return [iconClass, iconColor];
    }




    let stageComponents = deploymentStages.stages.map(
        (stage, ind) => {
            let [stageIconClass, stageIconColor] = getClassAndColor(
                stage.status
            );
            console.log(
                stageIconClass, "stageIconClass", stageIconColor
            );
            let showHideStage = stage.hidden;
            if (!showHideStage) {
                return (
                    <Card key={ind + 1}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={ind + 1}>
                                <div className="d-flex flex-column flex-sm-row">
                                    <i
                                        className={stageIconClass}
                                        style={{ fontSize: "20px", color: stageIconColor }}
                                    ></i>
                                    <div
                                        className="d-flex mt-0 align-items-start"
                                        style={{ width: "100%" }}
                                    >
                                        <div className="mb-0 flex-grow">
                                            <p className="text-left card-text mb-0 text-dark">
                                                {" "}
                                                {stage.description}{" "}
                                            </p>
                                            <p className="mb-0 font-weight-medium">
                                                {stage.percentage}%{" "}
                                                <small className="text-muted ml-2">
                                                    {stage.status}
                                                </small>{" "}
                                            </p>
                                        </div>
                                        <div className="ml-auto">
                                            <small className="text-muted ml-2">
                                                {isci ? stage.percentage < 100
                                                    ? "Time left: " +
                                                    convertTimeLeft(
                                                        stage.stage,
                                                        stage.percentage,
                                                        isIncremental
                                                    )
                                                    : stage.percentage === 100
                                                        ? //                         Math.floor(totalTimeRemain / 60);
                                                        // let secRemain = Math.floor(totalTimeRemain % 60)
                                                        "Actual Time : " +
                                                        Math.floor(
                                                            stage_actual_time[stage.stage] / 60
                                                        ) +
                                                        "min" +
                                                        Math.floor(
                                                            stage_actual_time[stage.stage] % 60
                                                        ) +
                                                        "sec"
                                                        : "" : "Actual Time :" + getDeploymentDuration()}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={ind + 1}>
                            <Card.Body style={{ padding: 0 }}>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 stretch-card">
                                    <div className="card" style={{ margin: 0 }}>
                                        <div className="card-body" style={{ padding: 0 }}>
                                            <div style={{ textAlign: "center" }}>
                                                <div
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    <div className="">
                                                        {stage.steps.map((step, index) => {
                                                            let showHideStep = step.hidden
                                                                ? "col-xl-12 col-lg-12 col-md-12 col-sm-12 stretch-card d-none"
                                                                : "col-xl-12 col-lg-12 col-md-12 col-sm-12 stretch-card";
                                                            let borderBottomStyle =
                                                                index + 1 === stage.steps.length
                                                                    ? { borderBottom: "0" }
                                                                    : { borderBottom: "1px solid #dae9ec" };

                                                            let [stepIconClass, iconColor] =
                                                                getClassAndColor(step.status);
                                                            return (
                                                                <div className={showHideStep} key={index + 1}>
                                                                    <div className="card-body px-2 py-2">
                                                                        <div
                                                                            className="d-flex flex-column flex-sm-row"
                                                                            style={borderBottomStyle}
                                                                        >
                                                                            <i
                                                                                className={stepIconClass}
                                                                                style={{
                                                                                    fontSize: "20px",
                                                                                    color: iconColor,
                                                                                }}
                                                                            ></i>
                                                                            <div className="wrapper text-left text-sm-left">
                                                                                <p className="card-text mb-0 text-dark">
                                                                                    {" "}
                                                                                    {step.description}{" "}
                                                                                </p>
                                                                                <div className="d-flex justify-content-between mb-2">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <p className="mb-0 font-weight-medium">
                                                                                            {step.percentage}%
                                                                                        </p>
                                                                                        <small className="text-muted ml-2">
                                                                                            {step.status}
                                                                                        </small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                );
            } else {
                return null;
            }
        }
    );

    let progressBarStyle = {
        textColor: "#fff",
        pathColor: "#1bcfb4",
        trailColor: "#dfe2e6",
    };

    let headerBgColor = errorOccured ? { backgroundColor: "#cc4545" } : { backgroundColor: "#4c5b6e" };


    let deploymentComponent = (
        <div className="row progress-component" key={1}>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 stretch-card">
                <div className={errorOccured ? "card bg-error text-dark" : "card bg-primary text-white"}>
                    {/* <div className={"card"} style={headerBgColor}> */}
                    <div
                        className="card-body"
                        style={{ padding: "0.3rem 0.3rem 1rem" }}
                    >
                        {deploymentPercentage === 100 ? (
                            <div
                                style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    color: "white",
                                }}
                            >
                                {isIncremental ? (
                                    <div>Estimated time: 10min</div>
                                ) : (
                                    <div>Estimated time: 30min</div>
                                )}
                                <div>{"Actual time: " + getDeploymentDuration()}</div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    fontSize: "19px",
                                    color: "white",
                                }}
                            >
                                {"Time left: " +
                                    convertTimeLeft(
                                        "overall",
                                        deploymentStages.percentage,
                                        isIncremental
                                    )}
                            </div>
                        )}

                        <div style={{ textAlign: "center" }}>
                            {/* <h4 className="font-weight-normal mb-4"> {'Stage 2 out of 4'} </h4> */}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div className="mb-2" style={{ width: "80px" }}>
                                    {deploymentPercentage === 100 ? (
                                        <i
                                            className="text-left mdi mdi-checkbox-marked-circle-outline"
                                            style={{ fontSize: "4rem", color: "#0f3530" }}
                                        ></i>
                                    ) : (
                                        <CircularProgressbar
                                            value={deploymentStages.percentage}
                                            text={`${deploymentStages.percentage}%`}
                                            styles={buildStyles(progressBarStyle)}
                                        />
                                    )}
                                </div>
                            </div>

                            <h6 className="card-text">
                                {" "}
                                {deploymentStages.overallStatus}{" "}
                            </h6>
                            {deploymentPercentage === 100 ? (
                                <h6 className="card-text">
                                    {" "}
                                    Find below useful links and more details{" "}
                                </h6>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {deploymentPercentage === 100 ? (
                <div className="col-12" style={{ height: "160px" }}>
                    <SysDiagram isci={isci} />
                </div>
            ) : null}

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 stretch-card">
                <div className="card">
                    <div className="card-body">
                        {deploymentPercentage === 100 ? (
                            <divG
                                className="mb-5 pb-2 mx-0"
                                style={{
                                    textAlign: "center",
                                    boxShadow: "0px 15px 16px 0px #263f4c59",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 stretch-card">
                                            <div className="card-body px-2 py-2">
                                                <div className="d-flex flex-column flex-sm-row">
                                                    <div className="wrapper text-left text-sm-left">
                                                        <p className="card-text mb-2 text-dark">
                                                            {" "}
                                                            Jenkins URL:{" "}
                                                            <a
                                                                href="http://jenkins.yozytech.com/"
                                                                target="_blank"
                                                            >
                                                                http://jenkins.yozytech.com/
                                                            </a>{" "}
                                                        </p>
                                                        <p className="card-text mb-2 text-dark">
                                                            {" "}
                                                            Application URL:{" "}
                                                            <a href={appURL} target="_blank">
                                                                {appURL}
                                                            </a>{" "}
                                                        </p>

                                                        {/* {isIncremental ? null : (
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-fw mr-3 mt-3"
                                                                onClick={goToDashboardHandler}
                                                            >
                                                                Go To Project Dashboard
                                                            </button>
                                                        )} */}
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-fw mr-3 mt-3"
                                                            onClick={buttonClickHandler}
                                                        >
                                                            Go To Home
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </divG>
                        ) : (
                            ""
                        )}
                        <div className="mt-4">
                            {/* //goto */}
                            {deploymentPercentage === 100 ? <></> : <>  <ColoredLinearProgress /></>}
                            <Accordion defaultActiveKey="0" className="accordion-bordered">
                                {stageComponents}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        // <div>
        //     <div className="page-header">
        //         <h3 className="page-title"> Deployment Status </h3>
        //     </div>
        <div>
            {deploymentComponent}
        </div>
        // </div>
    )







}

export default DeployApp