import { createSlice } from "@reduxjs/toolkit";
import Unassigned from "../assets/images/avatar.png";

const initialState = {
  IncidentId: null,
  User: null,
  IsSidebarCollapse: true,
  LoginStatus: false,
  UserDetails: [],
  projectRole: "Kanban Admin",
  projectRoleName: "Kanban Admin",
  userImage: Unassigned,
  Ismanagernotes: false,
  allImage: [],
  Publicholiday: [],
  KanbanBoardData: [],
  projectDetails: [],
  openProCreate: false,
  Assetoptions: [],
  projectCode: "",
  IsMinimised: false,
  IsCreatekb: false,
  Issprintdetails: false,
  IsReport: false,
  Isplansprint: false,
  testersImage: [],
  Fiteredkanbandata: [],
  projectMappingData: [],
  CurrentSprint: [],
  ProLabel: [],
  releases: [],
  PriorityOption: [
    { value: "P1", label: "P1-System down", code: "System down" },
    { value: "P2", label: "P2-Wide impact", code: "Wide impact" },
    { value: "P3", label: "P3-Moderate impact", code: "Moderate impact" },
    { value: "P4", label: "P4-Low impact", code: "Low impact" },
  ],
  statusoption: [
    { value: "New", label: "New" },
    { value: "Acknowledged", label: "Acknowledged" },
    { value: "Work in progress", label: "Work in progress" },
    { value: "User Verfication", label: "User Verfication" },
    { value: "User verified", label: "User verified" },
    { value: "Resolved", label: "Resolved" },
  ],
  statusoption1: [
    { value: "Work in progress", label: "Work in progress" },
    { value: "User verified", label: "User verified" },
  ],
  IncidentGroup: [],
  AssignedGroupOption: [],
  ConViewData: {
    SelectedProject: null,
    Sample: 1,
    TeamMember: null,
    ProjectMaster: [],
    CicdDropdownvalue: [],
    Cicdselected: null,
    CicdDropdownvalue: [],
    Cicdselected: [],
    Development: {
      Development: true,
      PublicIpAddress: "000.00.00.0",
      cloud: "AWS",
      InstanceType: "Standard1",
      vCPU: 4,
      vRAM: 3,
      CreatedDate: "28 Aug 2023",
      TotalRan: "2",
      commit: "170",
      branch: "5",
      Status: "Success",
      TechStack: "",
    },
    UAT: {
      UAT: true,
      PublicIpAddress: "00.00.000.0",
      cloud: "aws",
      InstanceType: "Standard2",
      vCPU: 2,
      vRAM: 8,
      CreatedDate: "29 Aug 2023",
      TotalRan: "2",
      commit: "120",
      branch: "1",
      Status: "Failure",
    },
    Production: {
      Production: true,
      PublicIpAddress: "00.00.000.0",
      cloud: "gcp",
      InstanceType: "Standard3",
      vCPU: 1,
      vRAM: 4,
      CreatedDate: "10 Aug 2023",
      TotalRan: "12",
      commit: "120",
      branch: "11",
      Status: "Success",
    },
    Deployment: [],
  },
  percentage: [],
  filesContent: [],
  settingDetails: [],
  slacktoken:"",
  channelname:"",
  selectedCard1: [],
  projectModules: [],
  searchText: "",
  Assetoptions: [],
  projectName: "",
  progressopen: false,
  SelectedCardId: "",
  FilteredData: [],
  projectId2: "",
  Refresh: false,
  Bfopen: false,
  selectedCard: [],
  IssueId: "",
  client: "",
  client1: "",
  openCreateEst: false,
  uatDropdown: [],
  progressdata: [],
  displaySubTask: [],
  UATtesterD: false,
  UATchecklistD: false,
  releaseLabel: null,
  releaseValue: null,
  EmpDropDownData: [],
  resData: [],
  priority: "All",
  releasefilter: "",
  epicLabel: "All",
  releases1: [],
  issueType: "",
  openCreate: false,
  projectLabel: "",
  employeeList: [],
  dropdown: [],
  testerEpic: [],
  releaseapi: false,
  testename: [],
  releasePopup: false,
  projectRelease: [],
  commitDialog: false,
  emp: [],
  testerid: [],
  Historysearch: false,
  ceremoniesPopup: true,
  meeting: false,
  historyrelease: false,
  projectTitle: null,
  status: "",
  epicCloseData: [],
  uattesterassign: false,
  ShowOption: false,
  ShowOptionID: null,
  Lane: {
    Source: null,
    Target: null,
  },
  cmodule: null,
  SEpic: null,
  UatId: null,
  UatTo: "",
  description: "",
  assignedId: "",
  checkChange: false,
  priority1: "",
  assignedTo: "",
  stageName: "",
  stageCode: "",
  selectedFile: null,
  openAll: false,
  progress: true,
  uat: false,
  History: false,
  notes: false,
  kbrefreshstate: false,
  devozyProjects: [],
  mydatashow: [],
  mydatashow1: [],
  labelvi: "",
  enablesprint: false,
  releasedata: [],
  connectedviewdata: [],
  deletedialog: false,
  deletedata: "",
  clonedata: [],
  clonedialog: false,
  deletetype: "",
  projectdetails: [],
  refinementpopup: false,
  refinementstorypoint: false,
  refinementselecteddata: [],
  IsRefinement: false,
  optionSelected: null,
  snackmessage: "",
  snackmessagetype: "",
  snackopen: "",
  storypointtype: "",
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    HANDLE_ON_CHANGE: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    USERLOGIN: (state, action) => {
      state.User = action.payload;
    },
    INDETAILSUPDATE: (state, action) => {
      state.IncidentId = action.payload;
    },
    PUBLICHOLIDAY: (state, action) => {
      state.Publicholiday = action.payload;
    },
    ISPLANSPRINT: (state, action) => {
      state.Isplansprint = action.payload;
    },
    ASSIGNEDGROUPUPDATE: (state, action) => {
      state.AssignedGroupOption = action.payload;
    },
    INDETAILSGROUPUPDATE: (state, action) => {
      state.IncidentGroup = action.payload;
    },
    LOGINSTATUS: (state, action) => {
      state.LoginStatus = action.payload;
    },
    ISREPORT: (state, action) => {
      state.IsReport = action.payload;
    },
    USERDETAILS: (state, action) => {
      state.UserDetails.push(...action.payload);
    },
    PROJECTROLEUPDATE: (state, action) => {
      state.projectRole = action.payload;
    },
    PROJECTROLENAMEUPDATE: (state, action) => {
      state.projectRoleName = action.payload;
    },
    ISSPRINTDETAILS: (state, action) => {
      state.Issprintdetails = action.payload;
    },
    USERIMAGEUPDATE: (state, action) => {
      state.userImage = action.payload;
    },
    ALLIMAGEUPDATE: (state, action) => {
      state.allImage = action.payload;
    },
    KANBANBOARDDATAUPDATE: (state, action) => {
      state.KanbanBoardData = action.payload;
    },
    PROJECTDETAILSUPDATE: (state, action) => {
      state.projectDetails = action.payload;
    },
    ASSETOPTIONSUPDATE: (state, action) => {
      state.Assetoptions = action.payload;
    },
    PROJECTCODEUPDATE: (state, action) => {
      state.projectCode = action.payload;
    },
    MINIMIZED: (state, action) => {
      state.IsMinimised = action.payload;
    },
    ISCREATEKB: (state, action) => {
      state.IsCreatekb = action.payload;
    },
    ISMANAGERNOTES: (state, action) => {
      state.Ismanagernotes = action.payload;
    },
    TESTERSIMAGEUPDATE: (state, action) => {
      state.testersImage = action.payload;
    },
    FILTEREDKANBANDATA: (state, action) => {
      state.Fiteredkanbandata = action.payload;
    },
    PROJECTMAPPINGDATAUPDATE: (state, action) => {
      state.projectMappingData = action.payload;
    },
    PROJECTCREATE: (state, action) => {
      state.openProCreate = action.payload;
    },
    SIDEBAROPEN: (state, action) => {
      state.IsSidebarCollapse = action.payload;
    },
    CURRENTSPRINT: (state, action) => {
      state.CurrentSprint = action.payload;
    },
    PROLABEL: (state, action) => {
      state.ProLabel = action.payload;
    },
    RELEASESUPDATE: (state, action) => {
      state.releases = action.payload;
    },
    CONVIEWDATA: (state, action) => {
      state.ConViewData = action.payload;
    },
    PERCENTAGE: (state, action) => {
      state.percentage = action.payload;
    },
    FILESCONTENT: (state, action) => {
      state.filesContent = action.payload;
    },
    SETTINGDETAILS: (state, action) => {
      state.settingDetails = action.payload;
    },
    SLACKTOKEN:(state,action) =>{
      state.slacktoken = action.payload;
    },
    CHANNELNAME:(state,action)=>{
      state.channelname = action.payload;
    },
    SELECTEDCARD: (state, action) => {
      state.selectedCard1 = action.payload;
    },
    PROJECTMODULES: (state, action) => {
      state.projectModules = action.payload;
    },
  },
});

export const {
  ASSIGNEDGROUPUPDATE,
  USERLOGIN,
  SIDEBAROPEN,
  INDETAILSUPDATE,
  LOGINSTATUS,
  USERDETAILS,
  PROJECTROLEUPDATE,
  PROJECTROLENAMEUPDATE,
  USERIMAGEUPDATE,
  PROJECTCREATE,
  PUBLICHOLIDAY,
  ISREPORT,
  ALLIMAGEUPDATE,
  ISCREATEKB,
  ISSPRINTDETAILS,
  ISMANAGERNOTES,
  CURRENTSPRINT,
  KANBANBOARDDATAUPDATE,
  PROJECTDETAILSUPDATE,
  ASSETOPTIONSUPDATE,
  PROJECTCODEUPDATE,
  TESTERSIMAGEUPDATE,
  MINIMIZED,
  ISPLANSPRINT,
  FILTEREDKANBANDATA,
  PROJECTMAPPINGDATAUPDATE,
  PROLABEL,
  RELEASESUPDATE,
  CONVIEWDATA,
  PERCENTAGE,
  SLACKTOKEN,
  CHANNELNAME,
  FILESCONTENT,
  SETTINGDETAILS,
  INDETAILSGROUPUPDATE,
  SELECTEDCARD,
  PROJECTMODULES,
  HANDLE_ON_CHANGE,
} = UserSlice.actions;

export default UserSlice.reducer;
