import * as actionTypes from "./actions";

const Reducer = (state, action) => {
    if (action && action.type) {
        switch (action.type) {
            case actionTypes.HANDLE_ON_CHANGE:
                let name = action.data.name;
                let value = action.data.value;
                return {
                    ...state,
                    [[name]]: value,
                };
            case actionTypes.HANDLE_ON_CHANGE_OBJECT:
                return {
                    ...state,
                    [action.obj]: {
                        ...state[action.obj],
                        [[action.Name]]: action.value
                    }
                };             
            default:
                return state;
        }
    }
};
export default Reducer;
