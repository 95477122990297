import React, { useEffect, useState } from "react";
import StoreContext from "./store/StoreContext";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import RaiseATicket from "./RaiseATicket";
import MyIncidentQueue from "./MyIncidentQueue";
import "./IncidentMain.css"
import axios from "../../axios";
import { INDETAILSGROUPUPDATE, ASSIGNEDGROUPUPDATE } from "../../redux/CommonInitialsate";
import { useDispatch, useSelector } from 'react-redux'

export default function IncidentMain() {
    // const { state, dispatch, StateFunction } = useContext(StoreContext);
    const clientid = JSON.parse(localStorage.getItem("clientid"));
    // dispatch(INDETAILSUPDATE(e.IncidentId));
    const dispatch = useDispatch()
    let arr = JSON.parse(localStorage.getItem("Empdetails"));
    const [useeffect2, setuseeffect2] = useState(false)
    useEffect(() => {
        setuseeffect2(true)
        axios
            .get("subscription_user_group_mapping?ClientId=eq." + clientid + "&UserId=eq." + arr[0]?.ClientUserId)
            .then((response) => {
                const groupIds = response.data.map(item => item.GroupDetails.map(group => group.GroupId)).flat();
                console.log(groupIds, "GODOFWAR")
                dispatch(INDETAILSGROUPUPDATE(groupIds))
            }).catch((err) => console.log(err))
        axios
            .get("subscription_user_group_mapping?ClientId=eq." + clientid)
            .then((response) => {
                let userGroupMappings = response.data
                const resultArray = userGroupMappings.reduce((acc, userGroupMapping) => {
                    userGroupMapping.GroupDetails.forEach((groupDetail) => {
                        const groupId = groupDetail.GroupId;
                        const groupName = groupDetail.GroupName;

                        // Check if the group already exists in the result array
                        const existingGroupIndex = acc.findIndex((group) => group.GroupId === groupId);

                        if (existingGroupIndex !== -1) {
                            // Add the user to the existing group
                            acc[existingGroupIndex].users.push({
                                Firstname: userGroupMapping.UserDetails.Firstname,
                                Lastname: userGroupMapping.UserDetails.Lastname,
                                UserId: userGroupMapping.UserId,
                            });
                        } else {
                            // Create a new group with the user
                            acc.push({
                                GroupId: groupId,
                                GroupName: groupName,
                                users: [{
                                    Firstname: userGroupMapping.UserDetails.Firstname,
                                    Lastname: userGroupMapping.UserDetails.Lastname,
                                    UserId: userGroupMapping.UserId,
                                }],
                            });
                        }
                    });

                    return acc;
                }, []);
                const assignmentooption = resultArray.map((e) => {
                    return {
                        label: e.GroupName,
                        value: e.GroupId,
                        users: e.users
                    }
                })
                dispatch(ASSIGNEDGROUPUPDATE(assignmentooption))
            })
            .catch((error) => console.log(error));

    }, []);

    return (
        <Tabs
            defaultActiveKey="Incident"
            id="justify-tab-example"
            className="horizontal-tabs ml-4 mt-3"
            variant="tabs"
            onSelect={() => setuseeffect2(true)}

        >
            <Tab eventKey="Incident" title="My Incident" className="headings">
                <RaiseATicket useeffect2={useeffect2} setuseeffect2={setuseeffect2} />
            </Tab>
            <Tab eventKey="MyIncident" title="Assigned Incident" className="headings ml-2" >
                <MyIncidentQueue useeffect2={useeffect2} setuseeffect2={setuseeffect2} />
            </Tab>
        </Tabs>
    )
}
