import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import { FormHelperText, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Form, Input } from "reactstrap";
import { FormGroup, TextField } from "@material-ui/core";
import Select from "react-select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./ProjectAllocation.css";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import AllocationTable from "./AllocationTable";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col } from "reactstrap";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
export default function ProjectAllocation({
  openAllocForm,
  setOpenAllocForm,
  EmpId,
  projectdetails,
  handleClose,
  ProAllocateModal,
  employee,
}) {
  // Custom style part
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: "14px",
      borderRadius: 8,
      // match with the menu
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      boxShadow: state.isFocused ? null : null,
      "&:hover": {},
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  // end

  useEffect(() => {
    axios
      .get("agilesaas_roles_master")
      .then((res) => setManagers(res.data))
      .catch((err) => {
        console.log(err);
      });
  }, [ProAllocateModal]);
  // gobol decleration
  const newDate = new Date();
  let id =
    localStorage.getItem("clientid") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("clientid"));
  let createdby =
    localStorage.getItem("Empdetails") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Empdetails"));
  let clientid =
    localStorage.getItem("clientid") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("clientid"));
  let clientdetails =
    localStorage.getItem("clientdetails") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("clientdetails"));
  const intialValue = {
    TeamMemberId: [],
    ProjectCode: 0,
    ProjectStartDate: new Date(),
    ProjectEndDate: new Date(newDate.setMonth(newDate.getMonth() + 6)),
    ManagerId: createdby[0]?.ClientUserId,
    AllocPercentage: "100",
    ProjectRole: {
      RoleGroupId: "",
      RoleCode: 0,
      RoleName: "",
    },
    MappedBy: createdby[0]?.ClientUserId,
    MappedByDetails: {
      ContactName: clientdetails[0]?.OrgDetails?.ContactName,
      ContactDesignation: clientdetails[0]?.RoleDetails?.RoleCode,
      ContactEmail: clientdetails[0]?.UserDetails?.Email,
      ContactPhone: clientdetails[0]?.OrgDetails?.ContactPhone,
    },
    IsCurrent: "Y",
    Status: null,
    ClientId: clientid,
  };
  // end
  // usestate decleartion
  const [Projroledata, setProjroledata] = useState([]);
  const [managers, setManagers] = useState(null);
  const [Refesh, setRefesh] = useState(true);
  const [Values, setValues] = useState(intialValue);
  const [visible, setVisible] = useState(false);
  const [allocatedEmployee, setAllocatedEmployee] = useState(null);
  const [deallocateddata, setdealloacteddata] = useState(null);
  const [edit, setEdit] = useState(false);
  const [Errors, setErrors] = useState({});
  const [projectMappingId, setProjectMappingId] = useState("");
  const [checkSubmit, setSubmitCheck] = useState(false);
  const [test, setTest] = useState(null);
  //  usestate decleration end
  //details from localstorage
  const projectname = projectdetails
    ?.filter((e) => e.ProjectId === Values.ProjectCode)
    ?.map((f) => f.ProjectName);
  const allocatedemp = employee
    ?.filter((r) => r.UserId === Values.TeamMemberId)
    ?.map((d) => d.UserDetails.Email);

  const userorg = clientdetails[0]?.OrgDetails;

  const managerdata = managers?.map((e) => e.RoleName);
  let managerdropdown = managers?.filter(
    (e) => e.RoleCode != "BO" && e.RoleCode != "PO"
  );
  const managerdrop = managerdata?.map((man) => {
    return {
      value: man.RoleGroupId,
      label: man.RoleName,
    };
  });
  const projectOption =
    projectdetails?.length == 0
      ? []
      : projectdetails?.map((pro) => {
        return {
          value: pro.ProjectId,
          label: pro.ProjectName,
        };
      });
  const employeeOption =
    employee?.length == 0
      ? []
      : employee
        // ?.filter(
        //   (e) =>
        //     e.RoleDetails.RoleCode != "SM" && e.RoleDetails.RoleCode != "KA"
        // )
        ?.map((emp) => {
          return {
            value: emp.UserId,
            label: emp.UserDetails.Firstname + " " + emp.UserDetails.Lastname,
            pertec: Projroledata.filter(
              (ge) => ge.TeamMemberId === emp.UserId
            )
              .map((e) => parseInt(e.AllocPercentage, 10))
              .reduce((acc, val) => acc + val, 0),
          };
        });
  const roleOption =
    managerdropdown?.length == 0
      ? []
      : managerdropdown?.map((man) => {
        return {
          value: man.RoleGroupId,
          label: man.RoleName,
          RoleCode: man.RoleCode,
        };
      });
  // functions
  const loadData = (value) => {
    let filteredProjectRoleData = Projroledata.filter((item) =>
      value?.includes(item.TeamMemberId)
    );
    console.log(filteredProjectRoleData, "nandri per");
    setAllocatedEmployee(filteredProjectRoleData);
    setdealloacteddata(filteredProjectRoleData);
  };
  const resetForm = () => {
    setValues(intialValue);
    setErrors({});
  };
  const DateInputChange = (e, vaildateOnChange = false) => {
    setValues({
      ...Values,
      [e.target.name]: moment(e.target.value).format("YYYY-MM-DD"),
    });
  };
  const convertToPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  const handleInputChange = (e, name) => {
    if (name === "TeamMemberId") {
      let value = Array.isArray(e)
        ? e.map((x) => {
          let rObj = {};
          rObj = x.value;
          return rObj;
        })
        : [];
      setValues({
        ...Values,
        [name]: value,
      });
      setVisible(true);
      setRefesh(true);
      loadData(value);
    } else if (name === "AllocPercentage") {
      setValues({
        ...Values,
        [name]: e.target.value,
      });
    } else {
      setValues({
        ...Values,
        [name]: e.value,
      });
    }
    // if (vaildateOnChange) validate({ [e.target.name]: e.target.value });
  };
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const closeIconHandler = () => {
    // setOpenAllocForm(false);
    setVisible(false);
    resetForm();
  };
  if (Refesh) {
    axios.get("agilesaas_project_mapping?IsCurrent=eq.Y").then((res) => {
      setProjroledata(res.data);
      setRefesh(false);
    });
  }
  // Save Function
  function calculateRemainingAllocation(currentAllocation, newAllocation) {
    // Ensure currentAllocation is a number between 0 and 100
    currentAllocation = Math.min(
      Math.max(parseFloat(currentAllocation) || 0, 0),
      100
    );

    // Ensure newAllocation is a number between 0 and 100
    newAllocation = Math.min(Math.max(parseFloat(newAllocation) || 0, 0), 100);

    // Calculate remaining allocation
    let remainingAllocation = 100 - currentAllocation;

    // If newAllocation is greater than remainingAllocation, set it to remainingAllocation
    let allocatedPercentage = Math.min(newAllocation, remainingAllocation);

    return allocatedPercentage;
  }
  const addAllocationHandler = (e) => {
    //goto
    e.preventDefault();

    if (edit) {
      let postTemp = {
        TeamMemberId: Values.TeamMemberId[0],
        ProjectCode: Values.ProjectCode,
        IsCurrent: "Y",
        AllocPercentage: Values.AllocPercentage,
        ProjectStartDate: moment(Values.ProjectStartDate).format("YYYY-MM-DD"),
        ProjectEndDate: moment(Values.ProjectEndDate).format("YYYY-MM-DD"),
        ProjectRole: Values.ProjectRole,
      };
      const previousProject = allocatedEmployee?.filter(
        (e) => e.ProjectMappingId != projectMappingId
      )[0]?.AllocPercentage;
      const checkPercentage =
        previousProject == undefined
          ? 0 + Values.AllocPercentage
          : parseInt(previousProject) + parseInt(Values.AllocPercentage);
      if (
        moment(Values.ProjectEndDate).diff(
          moment(Values.ProjectStartDate),
          "days"
        ) <= 0
      ) {
        alert("Startdate Should be less than end date");
      } else if (
        //   moment(
        //   Values.ProjectEndDate,
        //   "DD-MM-YYYY",
        //   true
        // ).isValid() == false
        Values.ProjectEndDate == "Invalid date"
      ) {
        alert("Enter Date in 'DD-MM-YYYY' Format");
      }
      // else if (
      //   moment(Values.ProjectStartDate, "DD-MM-YYYY") >
      //   moment(Values.ProjectEndDate, "DD-MM-YYYY")
      // ) {
      //   alert("Startdate Should be less than end date");
      // }
      else if (checkPercentage > 100 || Values.AllocPercentage <= 0) {
        alert("Can't allocate more than 100%");
      } else {
        setSubmitCheck(true);
        axios
          .patch(
            "agilesaas_project_mapping?ProjectMappingId=eq." + projectMappingId,

            postTemp
          )
          .then((res) => {
            setSubmitCheck(false);
            if (res.status === 204) {
              setEdit(false);
              toast.success(`Project Allocation Edited Successfully`, {
                transition: Slide,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                draggable: true,
              });
            }
            // loadData(Values.TeamMemberId);
            setProjectMappingId("");
            setErrors({});
            setRefesh(true);
            let idd = Values.TeamMemberId.toString();
            console.log(idd, "values.temkk");
            axios
              .get(
                "agilesaas_project_mapping?IsCurrent=eq.Y&TeamMemberId=eq." +
                idd
              )
              .then((res) => {
                setAllocatedEmployee(res.data);
              })
              .catch((e) => console.log(e));
            let data = { ...intialValue, TeamMemberId: Values.TeamMemberId };
            setValues(data);
          });
      }
    } else {
      let temp = { ...Errors };
      temp.ProjectStartDate =
        moment(Values.ProjectStartDate) >= moment(Values.ProjectEndDate) ===
          false
          ? ""
          : "StartDate should be lower than EndDate";
      temp.ProjectCode =
        Values.ProjectCode === 0 ? "Please select a Project" : "";
      temp.ProjectRole =
        Values.ProjectRole.RoleCode === 0 ? "Please select a Role" : "";
      temp.AllocPercentage =
        Values.AllocPercentage < 0 || Values.AllocPercentage > 100
          ? "Percentage must between 0 to 100"
          : Values.AllocPercentage == "" || Values.AllocPercentage.length == 0
            ? "Please enter percentage"
            : "";
      temp.TeamMemberId = "";
      if (Values.TeamMemberId.length > 0) {
        const PostArray = Values.TeamMemberId?.map((e) => {
          let sample = { ...Values }; // Create a shallow copy of the data object
          console.log(employeeOption);
          sample.TeamMemberId = e;
          let remainingAllocation = calculateRemainingAllocation(
            parseInt(employeeOption.filter((g) => g.value === e)[0].pertec),
            parseInt(Values.AllocPercentage)
          );
          sample.AllocPercentage = remainingAllocation;
          // Values.AllocPercentage - parseInt(employeeOption.filter((g) => g.value === e)[0].pertec) <= 0 ? 0 : parseInt(Values.AllocPercentage)
          console.log(remainingAllocation, "nandri per");
          temp.TeamMemberId =
            Values.TeamMemberId.length === 0
              ? "Please select team member id"
              : parseInt(
                employeeOption.filter((g) => g.value === e)[0].pertec
              ) === 100
                ? "Already 100% allocated"
                : "";
          return sample;
        });
        setErrors({
          ...temp,
        });
        function removeDuplicateProjects(Projroledata, Postdata) {
          // Create a set of unique project keys from Projroledata
          const uniqueProjects = new Set(
            Projroledata.map(
              (item) => `${item.TeamMemberId}_${item.ProjectCode}`
            )
          );

          // Filter Postdata to remove duplicates
          const filteredPostdata = Postdata.filter(
            (item) =>
              !uniqueProjects.has(`${item.TeamMemberId}_${item.ProjectCode}`)
          );

          return filteredPostdata;
        }

        // Call the function with your data
        let filteredPostdata = removeDuplicateProjects(Projroledata, PostArray);
        if (filteredPostdata.length === Values.TeamMemberId.length) {
          if (
            Values.ProjectCode === 0 ||
            Values.ProjectRole.RoleCode === 0 ||
            Values.AllocPercentage == "" ||
            Values.AllocPercentage.length == 0
          ) {
            setErrors({
              ...temp,
            });
          } else if (
            temp.AllocPercentage != "" ||
            temp.TeamMemberId !== "" ||
            temp.ProjectCode !== "" ||
            temp.ProjectRole !== "" ||
            temp.ProjectStartDate !== ""
          ) {
            setErrors({
              ...temp,
            });
          } else {
            const nonNullResultArray = employee.map((e) => {
              const filteredData = filteredPostdata.filter(
                (f) => f.TeamMemberId === e.UserId && f.AllocPercentage > 0
              );
              return filteredData.length > 0 ? e.UserDetails.Email : null;
            });
            const ToEmail = nonNullResultArray.filter(
              (email) => email !== null
            );
            console.log(ToEmail, "nandri roemails");
            ToEmail?.map((Toemails) => {
              let requrireddata = {
                ClientUserId: createdby?.[0]?.ClientUserId,
                ClientId: clientid,
                ClientDetails: {
                  Company: userorg?.Company,
                  ContactName: userorg?.ContactName,
                  ContactDesignation: userorg?.ContactDesignation,
                  ContactEmail: userorg?.ContactEmail,
                  ContactPhone: userorg?.ContactPhone,
                },
                DepartmentDetails: createdby?.[0]?.DepartmentDetails,
                DepartmentCode: null,
                TentantId: "",
                FromSenderId: "no-reply@yozytech.com",
                ToEmailIds: {
                  emailids: Toemails,
                },
                CcEmailIds: {
                  emailids: ['agileteam@yozytech.com'],
                },
                BccEmailIds: "",
                EmailType: "Project Allocation",
                ProductDetails: {
                  ProdId: "11004",
                  ProdName: "KANBAN",
                  Domain: "KANBAN.yozytech.com",
                },
                EmailSubject: "Project Allocation Notification",
                IsSuccess: "Y",
                IsPartiallyFailed: " ",
                SendRetryHistory: "",
                IsActive: " ",
                Status: "",
                EmailTemplateName: "ProjectAllocation",
                emailnotify: true,
                webnotify: true,
                EmailTemplateVariables: {
                  xxasignbyxx: "",
                  xxassigndatexx: "",
                  xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
                  xxprjdisxx: "",
                  xxprjnamexx: projectname[0],
                  xxstoryNamexx: "",
                  xxprjpercenxx: Values.AllocPercentage,
                  xxprjstartdatexx: moment(Values.ProjectStartDate).format(
                    "DD MMM YYYY"
                  ),
                  xxprjenddataexx: moment(Values.ProjectEndDate).format(
                    "DD MMM YYYY"
                  ),
                  xxteamnamexx: "",
                  xxsprintxx: "",
                  xxmanagernamexx: userorg?.ContactName,
                  xxcompanyname: userorg?.Company,
                  xxmanagerdesigxx: "Scrum Master",
                  xxgmailxx: userorg?.ContactEmail,
                  xxphnoxx: userorg?.ContactPhone,
                  webappdescri:
                    "You have allocated " +
                    Values.AllocPercentage +
                    " to " +
                    projectname[0] +
                    "project",
                },
              };
              console.log(requrireddata, "nandri to email");
              axios({
                url: "https://api.devozy.ai/api/agile/email",
                method: "post",
                data: requrireddata,
                headers: {
                  origin: "https://kanban.yozytech.com",
                  "Content-Type": "application/json",
                },
              })
                .then((res) => {
                  console.log(res.data, "nandri email");
                })
                .catch((err) => {
                  console.log(err, "nandri email err");
                });
            });

            console.log(filteredPostdata, "nandri filter");
            setSubmitCheck(true);
            axios
              .post(
                "agilesaas_project_mapping",
                filteredPostdata.filter((e) => e.AllocPercentage > 0)
              )
              .then((res) => {
                setSubmitCheck(false);
                toast.success(`Project Allocated`, {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                loadData(Values.TeamMemberId);
                setVisible(true);
                setValues(intialValue);
                setRefesh(true);
                resetForm();
                setErrors({});
                let data = {
                  ...intialValue,
                  TeamMemberId: Values.TeamMemberId,
                };
                let idd = Values.TeamMemberId.toString();

                axios
                  .get(
                    "agilesaas_project_mapping?IsCurrent=eq.Y&TeamMemberId=eq." +
                    idd
                  )
                  .then((res) => {
                    setAllocatedEmployee(res.data);
                  })
                  .catch((e) => console.log(e));
                setValues(data);
              });
          }
        } else {
          temp.TeamMemberId =
            Values.TeamMemberId?.length === 1
              ? " Already allocated in same project "
              : "Someone had already allocated in same project ";
          setErrors({
            ...temp,
          });
        }
      } else {
        temp.TeamMemberId = "Please select a team member";
        setErrors({
          ...temp,
        });
      }
    }
    setRefesh(true);
    loadData(Values.TeamMemberId);
  };
  console.log(employeeOption)
  return (
    <div>
      {" "}
      <Row className="mb-4 mt-2">
        <Col lg={6}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label className="SprintlabelStyle">
              Team Member
              <label className="createAlert">*</label>
            </label>
            <span className=" ml-1" style={{ paddingBottom: "12px" }}>
              {/* <Tooltip> */}
              <i
                style={{ cursor: "pointer" }}
                maxMenuHeight={142}
                className="fa fa-info-circle text-muted  "
                aria-label="info"
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              ></i>
              {/* <IconButton
                // style={{ fontSize: "small" }}
                aria-label="info"
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon fontSize="small" />
              </IconButton> */}

              {/* </Tooltip> */}
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography style={{ fontSize: "11px" }}>
                  Click on team member to add multiple team members
                </Typography>
              </Popover>
            </span>
          </div>

          <Select
            // styles={customStyles}
            // maxMenuHeight={80}
            style={{ width: "170px" }}
            maxMenuHeight={180}
            // labelId="demo-simple-select-outlined-label"
            // id="demo-simple-select-outlined"
            name="TeamMemberId"
            isMulti={true}
            isDisabled={edit}
            options={employeeOption}
            onChange={(e) => handleInputChange(e, "TeamMemberId")}
            error={Errors.TeamMemberId}
            helperText={Errors.TeamMemberId}
            value={
              Values.TeamMemberId
                ? employeeOption?.filter((e) =>
                  Values?.TeamMemberId?.includes(e.value)
                )
                : []
            }
            required
            native
          />
          {/* {console.log(
            employeeOption?.filter((item) =>
              Values.TeamMemberId.includes(item.TeamMemberId)
            ),
            "nandri3",
            employeeOption,
            Values.TeamMemberId
          )} */}
          <FormHelperText style={{ color: "red" }}>
            {Errors.TeamMemberId}
          </FormHelperText>
        </Col>
        <Col lg={6}>
          <label className="SprintlabelStyle">
            Project Name
            <label className="createAlert">*</label>
          </label>{" "}
          <Select
            styles={customStyles}
            // style={{ width: "100px" }}
            maxMenuHeight={180}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="ProjectCode"
            // value={Values.ProjectCode}
            options={projectOption}
            onChange={(e) => handleInputChange(e, "ProjectCode")}
            value={projectOption?.filter(
              (option) => option.value == Values?.ProjectCode
            )}
            error={Errors.ProjectCode}
            helperText={Errors.ProjectCode}
            required
            native
          />
          <FormHelperText style={{ color: "red" }}>
            {Errors.ProjectCode}
          </FormHelperText>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <label className="SprintlabelStyle">
            Project Role
            <label className="createAlert">*</label>
          </label>
          <Select
            styles={customStyles}
            style={{ width: "170px" }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="RoleGroupId"
            maxMenuHeight={180}
            // value={Values.ProjectRole}
            options={roleOption}
            onChange={(e) => {
              setValues({
                ...Values,
                ProjectRole: {
                  RoleName: e.label,
                  RoleGroupId: e.value,
                  RoleCode: e.RoleCode,
                },
              });
            }}
            error={Errors.ProjectRole}
            helperText={Errors.ProjectRole}
            value={roleOption?.filter(
              (option) => option.value == Values?.ProjectRole?.RoleGroupId
            )}
            required
            native
          />
          <FormHelperText style={{ color: "red" }}>
            {Errors.ProjectRole}
          </FormHelperText>
        </Col>
        <Col lg={6}>
          <label className="SprintlabelStyle">
            In Percent%
            <label className="createAlert">*</label>
          </label>{" "}
          <Input
            placeholder="Please Enter Percentage"
            className="SprintFormFields"
            style={{ width: "100%", borderRadius: 8 }}
            variant="outlined"
            name="AllocPercentage"
            size="small"
            type="number"
            value={Values.AllocPercentage}
            onChange={(e) => handleInputChange(e, "AllocPercentage")}
            // error={Errors.AllocPercentage}
            // helperText={Errors.AllocPercentage}
            autoComplete="off"
            required
          />
          <FormHelperText style={{ color: "red" }}>
            {Errors.AllocPercentage}
          </FormHelperText>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <label className="SprintlabelStyle">
            Allocation Start Date
            <label className="createAlert">*</label>
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: "100%" }}
              disablePast={!edit}
              varient="dialog"
              format="dd/MM/yyyy"
              // margin="normal"
              views={["year", "month", "date"]}
              // label="Allocation Start Date"
              // className="select MuiFormControl-marginNormal"
              value={Values.ProjectStartDate}
              error={Errors.ProjectStartDate}
              onChange={(date) =>
                DateInputChange(convertToPara("ProjectStartDate", date))
              }
            />
          </MuiPickersUtilsProvider>
          <FormHelperText style={{ color: "red" }}>
            {Errors.ProjectStartDate}
          </FormHelperText>
        </Col>
        <Col lg={6}>
          <label className="SprintlabelStyle">
            Allocation End Date
            <label className="createAlert">*</label>
          </label>{" "}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: "100%" }}
              disablePast={!edit}
              varient="dialog"
              format="dd/MM/yyyy"
              // margin="normal"
              views={["year", "month", "date"]}
              // label=" Allocation End Date"
              className="select"
              value={Values.ProjectEndDate}
              onChange={(date) =>
                DateInputChange(convertToPara("ProjectEndDate", date))
              }
            />
          </MuiPickersUtilsProvider>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "right",
          marginTop: 15,
        }}
      >
        <Button
          onClick={() => handleClose()}
          style={{
            fontSize: 14,
            padding: 9,
            width: 100,
            height: 40,
            borderRadius: 8,
            marginRight: 14,
            border: "2px solid #5250E5",
            backgroundColor: "#fff",
            color: "#5250E5",
            textAlign: "center",
            textTransform: "none",
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={(e) => {
            addAllocationHandler(e);
          }}
          disabled={checkSubmit}
          style={{
            fontSize: 14,
            padding: 9,
            width: 100,
            height: 40,
            borderRadius: 8,
            backgroundColor: "#5250E5",
            color: "#fff",
            // border: "0px solid #5A88F1",
            textAlign: "center",
            textTransform: "none",
          }}
        >
          {checkSubmit ? (
            <span
              className="spinner-border"
              role="status"
              aria-hidden="true"
              style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
            ></span>
          ) : (
            "Submit"
          )}
        </Button>
      </div>
      {allocatedEmployee === null || Values.TeamMemberId == "" ? (
        ""
      ) : Values.TeamMemberId.length === 1 ? (
        <div style={{ marginTop: "5%" }}>
          {/* {MultiValues.length > 0 ?
            MultiValues.map((e) => {
              return (<> */}

          <AllocationTable
            managers={managers}
            test={test}
            managerdropdown={managerdropdown}
            projects={projectdetails}
            employee={employee}
            valueOfTeamMemberId={Values.TeamMemberId}
            role={Values.ProjectRole}
            valueOfProjectCode={Values.ProjectCode}
            allocatedEmployee={allocatedEmployee}
            visible={visible}
            setVisible={setVisible}
            loadData={loadData}
            Values={Values}
            setValues={setValues}
            edit={edit}
            setEdit={setEdit}
            projectMappingId={projectMappingId}
            setProjectMappingId={setProjectMappingId}
            initialValue={intialValue}
            setRefesh={setRefesh}
          />
          {/* </>) */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
