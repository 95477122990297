import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { CardBody } from "reactstrap";
import axios from "../../../axios";
import "./GenericRequest.css";
import ReactImageFallback from "react-image-fallback";
import Button from "@mui/material/Button";
import geneic from "../../../assets/images/Generic request image.png";
import Select from "react-select";
import { TextField } from "@material-ui/core";
function GenericRequestForm({
  RefreshDatas,
  setGeneric,
  setAccess,
  setAssetsform,
}) {
  let user_details = JSON.parse(localStorage.getItem("Empdetails"));
  const onbaord =
  user_details[0].ClientId;
  const ID = user_details[0].ClientUserId;
  const clientid = JSON.parse(localStorage.getItem("clientid"));
  const clientdetails = JSON.parse(
    localStorage.getItem("Orgsubscriptiondetails")
  );
  // input change
  const [Rising, setRising] = useState(`Y`);
  const [Behalf_details, setBehalf_details] = useState();
  const [reason, setReason] = useState("");
  const [type, setType] = useState("");
  const [requestTittle, setRequestTittle] = useState("");
  const [description, setDescription] = useState("");

  // bool
  const [clicked, setClicked] = useState(false);
  // table
  const [Employee, setEmployee] = useState([]);
  // API data
  const [ResonMaster, setResonMaster] = useState([]);
  const [Application_names, setApplication_name] = useState([]);

  // validation
  const [risingvalidation, setRisingvalidation] = useState(false);
  const [behalfvalidation, setBehalfvalidation] = useState(false);
  const [typevalidation, setTypevalidation] = useState(false);
  const [descvalidation, setDescvalidation] = useState(false);
  const [reasonvalidation, setReasonvalidation] = useState(false);
  const [requesttitlevalidation, setRequesttitlevalidation] = useState(false);
  const [mapping, setmapping] = useState([]);

  function ReafreshData() {
    setDescription("");
    setReason("");
    setRising("");
    setType("");
    setBehalf_details();
    setRequestTittle("");
  }

  // Api calls
  useEffect(() => {
    function All_api_refresh_data() {
      axios
        .get("agilesaas_itrequest_reason_master")
        .then((res) => setResonMaster(res.data));

      axios
        .get("agilesaas_itrequest_itemtype_master")
        .then((res) => setApplication_name(res.data));
    }
    All_api_refresh_data();
  }, []);

  // Emp details get
  function EmpDetailsGet() {
    if (Behalf_details == "") {
      setClicked(false);
      alert("please give a Behalf details");
      return false;
    }
    if (Behalf_details != "") {
      setClicked(true);
      axios
        .get(
          "subscription_client_users?OfficialEmail=eq." +
            Behalf_details +
            "&ClientId=eq." +
            clientid
        )
        .then((res) => {
          setEmployee(res.data);
          if (res.data.length == 0) {
            alert("Enter an Verified Email-Id");
          }

          axios
            .get(
              `rpc/fun_agilesaasmanagerteamprojdetails?managerid=` +
                res.data[0]?.OnboardedBy +
                `&clientid=` +
                clientid +
                `&teammemberid=eq.` +
                res.data[0]?.ClientUserId
            )
            .then((response) => setmapping(response.data));
        });
      return true;
    }
  }

  const customStylesd = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // background: "#f3f3ff",
      // marginLeft: "35px",
      // match witpxh the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const customStyles1 = {
    control: (base, state) => ({
      ...base,
      // background: "#f3f3ff",
      // marginLeft: "10px",
      // match witpxh the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  let roledata = mapping?.map((e) => e.projectrole.RoleName)[0];

  const Table = (
    <label style={{ fontSize: "14px" }}>
      {Employee &&
        Employee.map((e, i) => e.UserFirstname + " " + e.UserLastname + " " + roledata)}
    </label>
    // <table className="table mt-1">
    //   <thead>
    //     <tr>
    //       <th>First Name</th>
    //       <th>Last Name</th>
    //       {/* <th>Emp level </th> */}
    //       <th>Designation</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {Employee &&
    //       Employee.map((e, i) => {
    //         return (
    //           <tr key={i}>
    //             <td>{e.Firstname}</td>
    //             <td>{e.Lastname} </td>
    //             {/* <td>{e.emplevel}</td> */}
    //             <td>{roledata}</td>
    //           </tr>
    //         );
    //       })}
    //   </tbody>
    // </table>
  );
  function close() {
    setGeneric(false);
    setAccess(false);
    setAssetsform(false);
  }
  const handleOptionChange = (event) => {
    setRising(event.target.value);
  };

  function Submitdatas(e) {
    e.preventDefault();

    try {
      if (Rising == "N") {
        if (clicked == false) {
          alert("please click the fetch and verify user details");
          return false;
        }
        if (!EmpDetailsGet()) {
          return;
        }
      }

      if (Rising === "") {
        setRisingvalidation(true);
      }
      if (Rising == "N" && Behalf_details === "") {
        setBehalfvalidation(true);
      }
      if (type === "") {
        setTypevalidation(true);
      }
      if (description === "") {
        setDescvalidation(true);
      }
      if (reason === "") {
        setReasonvalidation(true);
      }
      if (requestTittle == "") {
        setRequesttitlevalidation(true);
      }

      if (
        Rising !== "" &&
        reason !== "" &&
        description !== "" &&
        type !== "" &&
        requestTittle !== ""
      ) {
        setRisingvalidation(false);
        setBehalfvalidation(false);
        setTypevalidation(false);
        setDescvalidation(false);
        setReasonvalidation(false);
        setRequesttitlevalidation(false);

        const User_requestData = {
          RequestType: "GSR",
          RequestedBy: parseInt(ID),
          ClientId: clientid,
          ClientDetails: {
            Company: clientdetails[0].ClientDetails.Company,
            ContactName: clientdetails[0].ClientDetails.ContactName,
            ContactDesignation:
              clientdetails[0].ClientDetails.ContactDesignation,
            ContactEmail: clientdetails[0].ClientDetails.ContactEmail,
            ContactPhone: clientdetails[0].ClientDetails.ContactPhone,
          },
          // ProjectId: 300001,
          AssignedGroup: "ITSM",
          AssignedTo: user_details[0].OnboardedBy,
          DepartmentCode: null,
          SubmittedDate: moment().format("yyyy-MM-DD"),
          ReasonCode: reason,
          ItemType: type,
          UserJustification: description,
          NewAssignee: null,
          ExpectedDate: null,
          IsRequireApproval: null,
          ApprovalLevel1: 100005,
          Level1ApprovedDate: null,
          ApprovalLevel1Remarks: null,
          IsApprovedByLevel1: null,
          ApprovalLevel2: 600001,
          Level2ApprovedDate: null,
          ApprovalLevel2Remarks: null,
          IsApprovedByLevel2: null,
          IsFullyApproved: null,
          IsOnhold: null,
          IsWithdrawn: null,
          WithdrawnDate: null,
          WithdrawnRemarks: null,
          Attachments: null,
          FulfillmentReference: null,
          IsRequestFulfilled: null,
          IsActive: null,
          Status: null,
          UpdateNotes: [
            {
              NoteSno: null,
              DateTime: null,
              NoteUpdatedBy: null,
              NoteUpdatedByFN: null,
              NotesDetails: null,
            },
          ],
          ReferenceDetails: {
            RequestTittle: requestTittle,
          },
          NewAssigneeDetails: {
            EmpName: null,
            EmpLevel: null,
            EmpDesig: null,
          },
          IsSelfRequest: Rising,
          RaisingOnBehalfDetails:
            Rising === "N" ? parseInt(Employee[0].ClientUserId) : null,
          IsAcknowledged: null,
          SentForVerification: null,
          IsReworkRequired: null,
        };

        let notificationData = {
          CreatedDate: moment()
            .utcOffset("+05:30")
            .format("YYYY-MM-DDTHH:mm:ss"),
          CreatedBy: user_details.empid,
          NotifyTo: 500001,
          AudienceType: "Individual",
          Priority: "High",
          Subject: "Request for Asset",
          Description: "Generic Requested by  " + user_details.empid,
          IsSeen: "N",
          Status: "New",
        };
        ReafreshData();
        RefreshDatas();

        axios
          .post(
            `agilesaas_itrequest_details?RequestedBy=eq.${ID}`,
            User_requestData
          )
          .then((_res) => {
            alert("Generic Request Submitted");

            ReafreshData();
            RefreshDatas();
          })
          .then((_res) => {
            // axios
            // .post("notification?NotifyTo=eq." + 500001, notificationData)
            // .then((res) => {
            //   console.log(res);
            // })
            // .catch((error) => console.log(error));
          });
        close().catch((er) => console.log(er));
      } else {
        console.log(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div className="mb-2">
        <label className="text-dark" style={{fontWeight:"bold"}}>General Request</label>
        </div>
        <Row>
          
      
          <ReactImageFallback
                                  src={geneic}
                                  
                                  
                                  
                             
                                  initialImage="loader.gif"
                                  alt="cool image should be here"
                                 
                                   style={{ height: "200px", // Adjust the height to your desired size
                                   width: "200px", borderRadius: "50%" ,fontSize:"10px"}}
                                />
                                
                                <Col>
                                
                              
                              <div>
                              <label>
  <label className="labelStyle mr-1" style={{ fontWeight: "bold" }}>Workspace Needs: </label>
  Requests for amenities like snacks, office supplies, and a clean workspace.
</label>

                              
                              </div>
                              <div>
                              <label>
  <label className="labelStyle mr-1" style={{ fontWeight: "bold" }}>Comfort and Productivity: </label>
  Intended to boost well-being and productivity..
</label>
                          
                              </div>
  
                                </Col>
         </Row>
      <form onSubmit={Submitdatas}>
        <Row>
          <div className="d-flex justify-content-space between ">
            <div>
              <label
                className="labelStyle mt-3"
                style={{ display: "inline-block", marginTop: "11px" }}
              >
                Raising For
              </label>

              <label>
                <input
                  type="radio"
                  value="Y"
                  className="ml-2 mt-3"
                  style={{ transform: "scale(1.3)" }}
                  checked={Rising === "Y"}
                  onChange={handleOptionChange}
                />
                <label className="ml-2 "> Self</label>
              </label>
              <label>
                <input
                  type="radio"
                  value="N"
                  style={{ transform: "scale(1.3)" }}
                  className="ml-2  mt-3"
                  checked={Rising === "N"}
                  onChange={handleOptionChange}
                />
                <label className="ml-2">Someone</label>
              </label>
            </div>
          </div>
          <label>
            {" "}
            {risingvalidation ? (
              <label className="text-danger">this field is required</label>
            ) : null}{" "}
          </label>

          {Rising == "N" ? (
            <div>
              <label className="labelStyle mr-4 ">
                Raising On Behalf (provide Email#)
              </label>
              <br></br>
              <TextField
                type="text"
                variant="outlined"
                placeholder="Type email"
                size="small"
                // style={{ backgroundColor: "#f3f3ff" }}
                value={Behalf_details}
                onChange={(e) => setBehalf_details(e.target.value)}
                // className="AccessRequestSelectinput mt-1"
              />
              <Button
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 60,
                  height: 20,
                  marginTop: 12,
                  borderRadius: 8,
                  marginLeft: 6,
                  marginRight: 14,
                  border: "2px solid #5250E5",
                  backgroundColor: "#5250E5",
                  color: "#fff",
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={() => {
                  EmpDetailsGet();
                  setClicked(true);
                }}
              >
                Fetch
              </Button>
              {behalfvalidation ? (
                <label className="text-danger">this field is required</label>
              ) : null}
              <div className="mt-2" style={{ marginLeft: "10px" }}>
                {Rising == "N" ? Table : null}
              </div>
            </div>
          ) : null}
        </Row>

        <Row>
          <div>
            <label className="labelStyle mr-5">Request Title</label>
            <br></br>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              value={requestTittle}
              onChange={(e) => setRequestTittle(e.target.value)}
              className="SprintFormFields"
            />
            <br></br>
            {requesttitlevalidation ? (
              <label className="text-danger">this field is required</label>
            ) : null}
          </div>

          <div>
            <label className="labelStyle ml-4">Type</label>
            <br></br>
            <Select
              // value={type}
              onChange={(e) => setType(e.value)}
              styles={customStylesd}
              maxMenuHeight={80}
              value={Application_names.filter(
                (l) =>
                  l.ItemTypeCategory == "GSR" && l.ITRequestItemTypeId == type
              ).map((e) => {
                return {
                  value: e.ITRequestItemTypeId,
                  label: e.Description,
                };
              })}
              className="react-select-default selectbb"
              options={Application_names.filter(
                (l) => l.ItemTypeCategory == "GSR"
              ).map((e) => {
                return {
                  value: e.ITRequestItemTypeId,
                  label: e.Description,
                };
              })}
            />
            <br></br>
            {typevalidation ? (
              <label className="text-danger ml-5">this field is required</label>
            ) : null}
          </div>
        </Row>
        <Row>
          <div>
            <label className="labelStyle mr-4  ">Reason</label>
            <br></br>
            <Select
              // value={reason}
              styles={customStylesd}
              maxMenuHeight={80}
              onChange={(e) => setReason(e.value)}
              value={ResonMaster.filter(
                (l) =>
                  l.ReasonCategory == "GSR" && l.ITRequestReasonId == reason
              ).map((e) => {
                return { value: e.ITRequestReasonId, label: e.Description };
              })}
              className="react-select-default selectbb1"
              options={ResonMaster.filter((l) => l.ReasonCategory == "GSR").map(
                (e) => {
                  return { value: e.ITRequestReasonId, label: e.Description };
                }
              )}
            />

            {reasonvalidation ? (
              <label className="text-danger">this field is required</label>
            ) : null}
          </div>
        </Row>

        <Row className="mt-3">
          <div>
            <label className="labelStyle mr-5 ">Description</label>
            <br></br>
            <textarea
              value={description}
              style={{ width: "470px", height: "100px" }}
              onChange={(e) => setDescription(e.target.value)}
              className="SprintFormFields "
            ></textarea>
            {descvalidation ? (
              <label className="text-danger">this field is required</label>
            ) : null}
          </div>
        </Row>

        <div className="border"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginTop: "15px",
            width:"450px",
            // marginLeft: "25px",
            marginRight:0
          }}
        >
          <Button
            type="reset"
            style={{
              fontSize: 14,
              padding: 9,
              width: 100,
              height: 40,
              borderRadius: 8,
              marginRight: 14,
              border: "2px solid #5A88F1",
              backgroundColor: "#fff",
              color: "#5250E5",
              textAlign: "center",
              textTransform: "none",
            }}
            onClick={ReafreshData}
          >
            Reset
          </Button>
          <Button
            type="submit"
            style={{
              fontSize: 14,
              padding: 9,
              width: 100,
              height: 40,
              borderRadius: 8,
              // marginRight: 14,
              border: "2px solid #5250E5",
              backgroundColor: "#5250E5",
              color: "#fff",
              textAlign: "center",
              textTransform: "none",
            }}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default GenericRequestForm;
