import { Checkbox, TextField } from "@material-ui/core";
import axios from "../../../axios";
import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Button1 from "@mui/material/Button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MaterialTable from "material-table";
import Team from "../../KanbanBoard/Team";
import Tooltip from "@mui/material/Tooltip";

import CustomDatePicker from "./CustomDatePicker.css";
import PatchedPagination from "../../../PatchedPagination";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import "./addnewproject.css";
import {
  Col,
  Row,
  Container,
  CardBody,
  Table,
  Card,
  Progress,
} from "reactstrap";
import DateFnsUtils from "@date-io/date-fns";
import { Modal } from "react-bootstrap";
import Editproject from "./EditProject";
import Reportallocation from "./Report";
import ProjectAllocation from "./ProjectAllocation";
import Allocationseamless from "./Allocationseamless";
import ReportTeamallocate from "./ReportTeamallocate";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import moment from "moment";
import { toast, Slide } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";

import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import UserContext from "../../../index";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Divider } from "@mui/material";
import styled from "@mui/material/styles/styled";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { useSelector, useDispatch } from "react-redux";
import {
  HANDLE_ON_CHANGE,
  PROJECTCREATE,
} from "../../../../src/redux/CommonInitialsate";
import createcategory from "../../../createcategory";
import Select from "react-select";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ProjectModules from "./ProjectModules";

const Addnewproject = ({ prjallocation, setprjallocation }) => {
  let id =
    localStorage.getItem("clientid") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("clientid"));
  let initialValue = {};
  let rolecode =
    localStorage.getItem("Rolecode") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Rolecode"));
  let userdetails =
    localStorage.getItem("clientdetails") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("clientdetails"));
  let createdby =
    localStorage.getItem("Empdetails") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Empdetails"));
  let projects =
    localStorage.getItem("Orgsubscriptiondetails") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Orgsubscriptiondetails"));

  let empid =
    localStorage.getItem("Empdetails") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Empdetails"));
  let projectslength = projects?.map((e) => e.PlanCap?.Projects);

  const newDate = new Date(); // Create a new Date object to avoid modifying the original date

  const [projectnames, setProjectName] = useState("");
  // const [projectdetails, setProjectdetails] = useState([]);

  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedDate2, handleDateChange2] = useState(
    newDate.setMonth(newDate.getMonth() + 6)
  );
  const [selectedRow, setSelectedRow] = useState(null);
  const [allocationopen, setallocationopen] = useState(false);
  const [Values, setValues] = useState(initialValue);
  const [error, seterror] = useState({});
  const [dateerror, setdateerror] = useState("");
  const [row, setrow] = useState("");
  const [editopen, setedit] = useState(false);
  const [seamlessopen, setseamlessopen] = useState(false);

  const [Report, setReport] = useState(false);
  const [ProAllocateModal, setProAllocateModal] = useState(
    prjallocation == true ? true : false
  );
  const [inviteuser, setinviteuser] = useState(false);
  const [number, setNumber] = useState("");
  const [employee, setEmployee] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const contextval = useContext(UserContext);
  const dispatch = useDispatch();
  const { openProCreate, KanbanBoardData, projectdetails } = useSelector(
    (state) => state.user
  );
  const [allocateEmployee, setAllocateEmployee] = useState([]);
  let crtid = empid[0]?.ClientUserId;
  let clientdetails =
    JSON.parse(localStorage.getItem("clientdetails")) == undefined
      ? ""
      : JSON.parse(localStorage.getItem("clientdetails"));
  const [checkSubmit, setSubmitCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [projectOptions, setProjectOptions] = useState("");
  const [selectedProject, setSelectedProject] = useState({
    label: "New Project",
    value: "new",
    data: "",
  });

  const [modules, setModules] = useState([
    {
      Name: "",
      Type: "Monolith",
      FullName: "",
    },
  ]);
  const { projectRole, projectRoleName, projectDetails } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    contextval.setwindows("project");
    axios
      .get(
        "subscription_user_role_mapping?ProductDetails->>ProdId=eq.11004&ClientId=eq." +
          id +
          "&LoginEnabled=eq.Y"
      )
      .then((res) => {
        let getdata = res.data;
        let filteredData = [];
        // console.log(getdata, "getdata")
        axios
          .get("subscription_client_users?ClientId=eq." + id + "&IsActive=eq.Y")
          .then((re) => {
            let userdata = re.data;
            getdata.map((e) => {
              // console.log(userdata, e, "userdata")
              let check = userdata.filter((x) => x.ClientUserId == e.UserId);
              // console.log(userdata.filter((x) => x.ClientUserId == e.UserId), "userdata", check.length != 0)
              if (check.length != 0) {
                filteredData.push(e);
              }
            });
            // console.log(filteredData, "filtereddata")
            setEmployee(filteredData);
          });
      })

      .catch((err) => {
        console.log(err);
      });
  }, [ProAllocateModal]);

  const userorg = userdetails[0]?.OrgDetails;
  const userdet = userdetails[0]?.UserDetails;

  //this for project details check according to plancap
  const lastcode = projectdetails
    ?.map((e) => e.ProjectId)
    .sort()
    .slice(-1)
    .toString();

  const check = projectdetails?.map((e) => e.ProjectId).length;

  useEffect(() => {
    axios
      .get(`agilesaas_project_master?ClientId=eq.` + id)
      .then((res) => {
        console.log(res.data);
        setProjectOptions(res.data);
        setIsLoading(false);
        // setProjectdetails(
        //   res.data.map((e) => {
        //     return { ...e, selectOption: false };
        //   })
        // );
        dispatch(
          HANDLE_ON_CHANGE({
            name: "projectdetails",
            value: res.data.map((e) => {
              return { ...e, selectOption: false };
            }),
          })
        );

        // setNumber(res.data.map((e)=>e.ProjectShortCode, 10))
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, []);

  const poptions = projectOptions?.length > 0 && [
    { label: "New Project", value: "new", data: "" },
    ...projectOptions?.map((x) => ({
      label: x?.ProjectName,
      value: x?.ProjectId,
      data: x,
    })),
  ];

  function ProjectClose() {
    setSelectedProject({ label: "New Project", value: "new", data: "" });
    dispatch(PROJECTCREATE(false));
    const newDate = new Date();
    // setopenProCreate(false);
    setProjectName("");
    seterror({});
    handleDateChange(new Date());
    handleDateChange2(newDate.setMonth(newDate.getMonth() + 6));
    setdateerror("");
    setModules([
      {
        Name: "",
        Type: "Monolith",
        FullName: "",
      },
    ]);
  }

  function AllocateClose() {
    setProAllocateModal(false);
    if (prjallocation !== undefined) {
      setprjallocation(false);
    }
  }

  const validateForm = () => {
    const newErrors = {};

    if (selectedProject?.value == "new" && projectnames.trim() == "") {
      newErrors.projectnames = "Enter the project name";
    } else if (
      moment(selectedDate2).diff(moment(selectedDate), "days") == 0 ||
      moment(selectedDate2).diff(moment(selectedDate), "days") < 0
    ) {
      setdateerror("Please check Dates-StartDate should be lower than EndDate");
      newErrors.selectedDate =
        "Please check Dates-StartDate should be lower than EndDate";
      // } else if (projectslength <= check) {
      //   toast.error(
      //     `You have exceeded the minimal plan please upgrade to higher plan Further... `,
      //     {
      //       transition: Slide,
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 8000,
      //       draggable: true,
      //     }
      //   );
    }

    seterror(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const projectccode = projectdetails?.find(
    (e) => e.ProjectName === projectnames
  )?.ProjectId;

  const handleprojectname = (e) => {
    let text = e.target.value;

    setProjectName(text);
    const updatedModules = modules.map((module) => ({
      ...module,
      Name: e.target.value.replace(/\s+/g, "").replace(/[^a-zA-Z0-9]/g, ""),
    }));
    setModules(updatedModules);
  };

  function Refresh() {
    setSelectedProject({ label: "New Project", value: "new", data: "" });
    const newDate = new Date();
    axios.get(`agilesaas_project_master?ClientId=eq.` + id).then((res) => {
      // setProjectdetails(res.data);
      dispatch(
        HANDLE_ON_CHANGE({
          name: "projectdetails",
          value: res.data,
        })
      );
      setProjectOptions(res.data);
    });
    setdateerror("");
    setProjectName("");
    // setseamlessopen(false);
    handleDateChange(new Date());
    handleDateChange2(newDate.setMonth(newDate.getMonth() + 6));
    setModules([
      {
        Name: "",
        Type: "Monolith",
        FullName: "",
      },
    ]);
    dispatch(PROJECTCREATE(false));
  }

  const handleEditing = (e) => {
    setedit(true);
    setrow(e);
    setModules(
      e?.ProjectModules
        ? e?.ProjectModules
        : [
            {
              Name: "",
              Type: "Monolith",
              FullName: "",
            },
          ]
    );
  };

  const handleallocation = () => {
    setallocationopen(true);
  };

  const handleReport = (e) => {
    setReport(true);
    setrow(e);
  };

  function submit(e) {
    // modules.forEach(module => {
    //   // Check if both Name and Type are filled
    //   if (module.Name && module.Type) {
    //     // Combine Name and Type into FullName
    //     module.FullName = module.FullName ? module.FullName : `${module.Name}_${module.Type}`;
    //   }
    // })
    axios.get(`agilesaas_project_master?ClientId=eq.` + id).then((res) => {
      const pdname = res?.data?.map((x) =>
        x.ProjectName?.replace(/\s+/g, "")?.replace(/[^a-zA-Z0-9]/g, "")
      );
      console.log(
        pdname.includes(
          projectnames?.replace(/\s+/g, "")?.replace(/[^a-zA-Z0-9]/g, "")
        )
      );
      let prjcode = res.data
        ?.filter((e) => e.ProjectId)
        .sort()
        .slice(-1);
      let code = prjcode[0]?.ProjectShortCode;

      const numericPart = parseInt(code?.slice(4), 10) + 1;

      // Pad the numeric part with zeros to maintain the format
      const nextNumericPart = String(numericPart).padStart(6, "0");

      // Create the next code

      setNumber(`${projectnames?.slice(0, 4)}${nextNumericPart}`);

      // }
      // else{
      //   // const prefix = projectnames.slice(0, 4);
      //   setNumber(`${projectnames.slice(0, 4)}${"000001"}`)
      // }

      const chectknm =
        res.data.length != 0
          ? `${projectnames?.slice(0, 4)}${nextNumericPart}`
          : `${projectnames.slice(0, 4)}${"000001"}`;

      //   const generatedString = `${prefix}${number.toString()}`;
      //   setNumber(number + 1);
      // console.log(generatedString,"generatedstringf")

      e.preventDefault();
      let postdata = {
        ProjectShortCode: chectknm,
        ProjectName: projectnames,
        ClientId: id,
        ClientDetails: {
          Company: userorg?.Company,
          ContactName: userorg?.ContactName,
          ContactDesignation: userorg?.ContactDesignation,
          ContactEmail: userorg?.ContactEmail,
          ContactPhone: userorg?.ContactPhone,
        },
        CreatedDate: moment().format("YYYY-MM-DD"),
        CreatedBy: empid[0]?.ClientUserId,
        CreatedByDetails: {
          Name: userorg?.ContactName,
          Role: rolecode,
          Email: userdet?.Email,
          Phone: userorg?.ContactPhone,
        },
        StartDate: moment(selectedDate).format("YYYY-MM-DD"),
        EndDate: moment(selectedDate2).format("YYYY-MM-DD"),
        IsActive: "Y",
        Status: null,
        ProjectModules: modules,
      };
      // console.log(postdata, "postdata")
      if (projectslength <= check) {
        toast.error(
          `You have exceeded the minimal plan please upgrade to higher plan Further... `,
          {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 8000,
            draggable: true,
          }
        );
      } else if (!modules.every((module) => module.Name && module.Type)) {
        toast.error(`Please enter all the module details `, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 8000,
          draggable: true,
        });
      } else {
        // console.log(postdata)
        if (validateForm()) {
          if (
            modules.length > 0 &&
            [...new Set(modules?.map((item) => item.FullName))]?.length !=
              modules?.length
          ) {
            toast.error(
              `Please provide each module's full name as a unique name. `,
              {
                transition: Slide,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 8000,
                draggable: true,
              }
            );
          } else if (pdname.includes(projectnames)) {
            toast.error(`Project is already created for this name. `, {
              transition: Slide,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 8000,
              draggable: true,
            });
          } else {
            setSubmitCheck(true);
            if (selectedProject?.value == "new") {
              axios
                .post(`agilesaas_project_master`, postdata)

                .then((response) => {
                  //add category in a api while create a project
                  createcategory(projectnames);
                  setSubmitCheck(false);
                  axios
                    .get(`agilesaas_project_master?ClientId=eq.` + id)
                    .then((res) => {
                      // Initialize variables to store the most recent project's details
                      let latestProjects = [];
                      let latestCreatedDate = ""; // Initialize as an empty string

                      // Loop through the projects
                      for (const project of res.data) {
                        // Compare the current project's creation date to the latest created date
                        if (project.CreatedDate > latestCreatedDate) {
                          // If this project has a later creation date, reset the latest projects array
                          latestProjects = [project];
                          latestCreatedDate = project.CreatedDate;
                        } else if (project.CreatedDate === latestCreatedDate) {
                          // If this project has the same creation date as the latest, add it to the array
                          latestProjects.push(project.ProjectId);
                        }
                      }

                      // Find the project with the latest "ProjectId" among the latest projects
                      let lastUpdatedProject = latestProjects?.reduce(
                        (prev, current) => {
                          return prev.ProjectId > current.ProjectId
                            ? prev
                            : current;
                        }
                      );
                      let coeprj = lastUpdatedProject?.ProjectId;
                      const newDate = new Date();
                      let initialValue = {
                        TeamMemberId: empid[0]?.ClientUserId,
                        ProjectCode:
                          coeprj == undefined ? lastUpdatedProject : coeprj,
                        ProjectStartDate: new Date(selectedDate),
                        ProjectEndDate: new Date(selectedDate2),
                        ManagerId: createdby[0]?.ClientUserId,
                        AllocPercentage: 100,
                        ProjectRole: {
                          RoleGroupId: "1",
                          RoleCode: "SM",
                          RoleName: "Scrum Master",
                        },
                        MappedBy: createdby[0]?.ClientUserId,
                        MappedByDetails: {
                          ContactName: userdetails[0]?.OrgDetails?.ContactName,
                          ContactDesignation:
                            userdetails[0]?.RoleDetails?.RoleCode,
                          ContactEmail: userdetails[0]?.UserDetails?.Email,
                          ContactPhone:
                            userdetails[0]?.OrgDetails?.ContactPhone,
                        },
                        IsCurrent: "Y",
                        Status: null,
                        ClientId: id,
                      };
                      axios
                        .post("agilesaas_project_mapping", initialValue)
                        .then((res) => {
                          toast.success(`Project On-Boarded`, {
                            transition: Slide,
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                            draggable: true,
                          });
                        });
                    });
                  Refresh();
                  seterror({});
                  // setopenProCreate(false);
                  setseamlessopen(true);
                  // handleClose();
                })
                .catch((err) => {
                  // console.log(err)
                  setSubmitCheck(false);
                });

              dispatch(PROJECTCREATE(false));
              setseamlessopen(true);
            } else {
              let patchdata = {
                // ProjectName: projectnames,
                StartDate: moment(selectedDate).format("YYYY-MM-DD"),
                EndDate: moment(selectedDate2).format("YYYY-MM-DD"),
                ProjectModules: modules,
              };
              axios
                .patch(
                  `agilesaas_project_master?ProjectId=eq.${selectedProject?.data?.ProjectId}`,
                  patchdata
                )
                .then((res) => {
                  Refresh();
                  seterror({});
                  setSubmitCheck(false);
                  toast.success(`Project edited successfully`, {
                    position: toast.TOP_RIGHT,
                    autoClose: 3000,
                    draggable: true,
                  });
                })
                .catch((e) => {
                  setSubmitCheck(false);
                });
            }
          }
        }
      }
    });
  }

  function remove(rowData) {
    alert("are you sure want to delete");
    axios
      .delete("agilesaas_project_master?ProjectId=eq." + rowData.ProjectId)
      .then((res) => {
        // createcategory(projectnames, id, "delete")
        if (res.status === 204) {
          toast.success(`Deleted successfully`, {
            position: toast.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClose() {
    setedit(false);
    Refresh();
    setallocationopen(false);
    setReport(false);
    setseamlessopen(false);
    setModules([
      {
        Name: "",
        Type: "Monolith",
        FullName: "",
      },
    ]);
    // setRefresh(true);
  }

  const handleSecondFriend = (rowDataProjectId) => {
    // setProjectdetails(
    //   projectdetails.map((friend) =>
    //     // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
    //     friend.ProjectId === rowDataProjectId
    //       ? { ...friend, selectOption: !friend.selectOption }
    //       : { ...friend }
    //   )
    // );
    dispatch(
      HANDLE_ON_CHANGE({
        name: "projectdetails",
        value: projectdetails.map((friend) =>
          // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
          friend.ProjectId === rowDataProjectId
            ? { ...friend, selectOption: !friend.selectOption }
            : { ...friend }
        ),
      })
    );
    setSelectedId(selectedId == rowDataProjectId ? "" : rowDataProjectId);
  };

  function selectcustom(rowData) {
    return (
      <>
        <div onClick={() => handleSecondFriend(rowData.id)}>
          <div
            style={{
              // backgroundColor: "#eef2f7",
              padding: "10px 05px 10px 15px",
              width: "45px",
            }}
          >
            <MoreVertIcon
              style={{ color: "#198ae3", fontSize: "20px", fontWeight: "bold" }}
            />
          </div>
          {selectedId == rowData.id && (
            <div
              style={{
                backgroundColor: "#ffffff",
                position: "relative",
                width: "140px ",
                borderRadius: "3px",
              }}
            >
              <ul style={{ backgroundColor: "#ebedf2" }}>
                <div
                  style={{ padding: "7px 10px 3px 5%" }}
                  onClick={() => handleEditing(rowData)}
                >
                  <EditIcon style={{ fontSize: "medium" }} />
                  {"   "} Edit
                </div>
                <div
                  style={{ padding: "7px 10px 3px 5%" }}
                  onClick={() => handleReport(rowData, "Report")}
                >
                  <AssessmentIcon style={{ fontSize: "medium" }} />
                  {"    "}Report
                </div>
              </ul>
            </div>
          )}
        </div>
      </>
    );
  }

  const theme = createTheme({
    palette: {
      grey: {
        200: "#your-light-grey-color",
        800: "#your-dark-grey-color",
      },
      // Other palette colors...
    },
    // Other theme settings...
  });

  const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? `#1a90ff` : `#308fe8`,
    },
  }));

  function projectcrt() {
    dispatch(PROJECTCREATE(true));
    setProjectName("");
  }

  const Columns = [
    {
      title: "Project Id",
      field: "ProjectId",
      cellStyle: { textAlign: "left" },
    },

    { title: "Projects Name", field: "projectname" },
    { title: "Modules", field: "modules" },
    { title: "Created By", field: "createdby" },

    {
      title: "Progress",
      field: "Progress",
      render: (rowData) => (
        <ThemeProvider theme={theme} value={rowData.Progress}>
          {/* Your components using the styled component */}
          <BorderLinearProgress
            value={rowData.Progress}
            variant="determinate"
          />
        </ThemeProvider>
      ),
    },
    { title: "Start / End Date", field: "startdate" },
    // { title: "End Date", field: "enddate" },
    {
      title: "Status",
      field: "Status",
      render: (rowData) => (
        <>
          <FiberManualRecordIcon
            style={{ color: "#198ae3", fontSize: "medium" }}
          />
          Progress
        </>
      ),
    },
    {
      title: "Action",
      field: "Action",
      render: (rowData) => <>{selectcustom(rowData)}</>,
    },
  ];

  const handleSelectProject = (e) => {
    const newDate = new Date();
    setModules(
      e?.data?.ProjectModules
        ? e?.data?.ProjectModules
        : [
            {
              Name:
                e?.data &&
                e?.data?.ProjectName?.replace(/\s+/g, "")?.replace(
                  /[^a-zA-Z0-9]/g,
                  ""
                ),
              Type: "Monolith",
              FullName: "",
            },
          ]
    );
    handleDateChange(e?.data ? new Date(e?.data?.StartDate) : new Date());
    handleDateChange2(
      e?.data
        ? new Date(e?.data?.EndDate)
        : newDate.setMonth(newDate.getMonth() + 6)
    );
    setSelectedProject(e);
    setProjectName(e?.data?.ProjectName ? e?.data?.ProjectName : "");
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 223)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      // width: '110%',
      // marginLeft: elementWidth > 1300 ? -35 : -20,

      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      // marginTop: 0,
      // marginLeft: elementWidth > 1300 ? -35 : -20,
      width: `${Math.max(
        ...poptions.map((option) => option.label.length * 10)
      )}px`, // Adjust the multiplier based on your content
      minWidth: "100%",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const checkname = KanbanBoardData?.filter(
    (x) => x.ProjectId == selectedProject?.value
  )?.length;
  // console.log(checkname);

  const allocateproject = (
    <Button
      variant="outline-success"
      size="sm"
      disabled={projectdetails?.length == 0 || projectRole == "TL"}
      className="action-icon fontdesign ml-3"
      style={{ fontWeight: "normal", borderRadius: "8px" }}
      onClick={() => {
        setProAllocateModal(true);
      }}
    >
      <GroupAddIcon fontSize="small" /> Allocate
    </Button>
  );
  return (
    <>
      {isLoading ? (
        <div
          className=" d-flex align-items-center"
          style={{ justifyContent: "center", height: 460 }}
        >
          <span
            className="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "rgb(82, 80, 229)" }}
          ></span>
        </div>
      ) : (
        <div style={{ backgroundColor: "#f<6f7fa" }}>
          {prjallocation == true ? null : (
            <>
              <label className="ProjectlabelStyle1 ml-4">Projects</label>
              <Row className="ButtonGrp1 mt-2 ml-2">
                <Col style={{ display: "flex", justifyContent: "start" }}>
                  {
                    <Tooltip
                      title={
                        projectdetails?.length == 0
                          ? "Create project"
                          : projectRole == "TL"
                          ? "TL dosen't have the access to create Project."
                          : ""
                      }
                      placement="top"
                    >
                      <Button
                        variant="outline-danger"
                        size="sm"
                        fontSize="small"
                        className="action-icon fontdesign"
                        disabled={projectRole == "TL"}
                        style={{ fontWeight: "normal", borderRadius: "8px" }}
                        onClick={() => projectcrt()}
                      >
                        <AddCircleRoundedIcon fontSize="small" /> Create
                      </Button>
                    </Tooltip>
                  }{" "}
                  {createdby?.[0]?.IsOrgAdmin == "Y" ? (
                    <Button
                      variant="outline-success"
                      size="sm"
                      className="action-icon fontdesign ml-3"
                      style={{ fontWeight: "normal", borderRadius: "8px" }}
                      onClick={() => setinviteuser(true)}
                    >
                      <GroupAddIcon fontSize="small" /> Invite user
                    </Button>
                  ) : (
                    ""
                  )}{" "}
                  <Tooltip
                    title={
                      projectdetails?.length == 0
                        ? "Please create project to allocate"
                        : projectRole == "TL"
                        ? "TL dosen't have the access to Allocate Project  "
                        : ""
                    }
                    placement="top"
                  >
                    {allocateproject}
                  </Tooltip>{" "}
                </Col>
              </Row>
            </>
          )}
          <br></br>
          {prjallocation == true ? null : (
            <Row style={{ marginRight: "1%" }} className="ml-1">
              <Col lg={3} md={6} sm={12}>
                <Card className="ProgressCard" style={{ boxShadow: 0 }}>
                  <Row
                    style={{
                      height: "100%",
                      justifySelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="Col1"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <span
                        className="Iconback ml-5"
                        style={{ backgroundColor: "#dcdefc" }}
                      >
                        <NoteAltOutlinedIcon
                          className="cardIcon"
                          fontSize="medium"
                        />
                      </span>
                    </div>
                    <div className="Col2 ml-3">
                      <label className="labelStyle">Projects</label>
                      <label className="labelStyleV">
                        {projectdetails.length}
                      </label>
                    </div>
                    {/* <div className="Col3">
                <MoreVertIcon
                  style={{ marginLeft: "-100%", color: "#6c757d" }}
                />
              </div> */}
                  </Row>
                </Card>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Card className="ProgressCard" style={{ boxShadow: 0 }}>
                  <Row
                    style={{
                      height: "100%",
                      justifySelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="Col1"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <span
                        className="Iconback ml-5"
                        style={{ backgroundColor: "#c2f3e5" }}
                      >
                        <GroupsIcon
                          className="cardIcon"
                          fontSize="medium"
                          style={{ color: "#45d097" }}
                        />
                      </span>
                    </div>
                    <div className="Col2 ml-3">
                      <label className="labelStyle">Task</label>
                      <label className="labelStyleV">
                        {projectdetails.length == 0 ? 0 : 0}
                      </label>
                    </div>
                    {/* <div className="Col3">
                <MoreVertIcon
                  style={{ marginLeft: "-100%", color: "#6c757d" }}
                />
              </div> */}
                  </Row>
                </Card>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Card className="ProgressCard" style={{ boxShadow: 0 }}>
                  <Row
                    style={{
                      height: "100%",
                      justifySelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="Col1"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <span
                        className="Iconback ml-5 "
                        style={{ backgroundColor: "#cdebf3" }}
                      >
                        <PersonAddIcon
                          className="cardIcon"
                          fontSize="medium"
                          style={{ color: "#39afd1" }}
                        />
                      </span>
                    </div>
                    <div className="Col2 ml-3">
                      <label className="labelStyle">Developer</label>
                      <label className="labelStyleV">
                        {
                          employee?.filter(
                            (e) =>
                              e.RoleDetails.RoleCode != "SM" &&
                              e.RoleDetails.RoleCode != "KA"
                          ).length
                        }
                      </label>
                    </div>
                    {/* <div className="Col3">
                <MoreVertIcon
                  style={{ marginLeft: "-100%", color: "#6c757d" }}
                />
              </div> */}
                  </Row>
                </Card>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Card className="ProgressCard" style={{ boxShadow: 0 }}>
                  <Row
                    style={{
                      height: "100%",
                      justifySelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="Col1"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <span
                        className="Iconback ml-5"
                        style={{ backgroundColor: "#fff0d6" }}
                      >
                        <CreateNewFolderIcon
                          className="cardIcon"
                          fontSize="medium"
                          style={{ color: "#ffc35a" }}
                        />
                      </span>
                    </div>
                    <div className="Col2 ml-3">
                      <label className="labelStyle">Development</label>
                      <label className="labelStyleV">0</label>
                    </div>
                    {/* <div className="Col3">
                <MoreVertIcon
                  style={{ marginLeft: "-30%", color: "#6c757d" }}
                />
              </div> */}
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
          {/* <br></br>
      <Card className="project">
        <div 
          className="header_style text-secondary"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "20px",
            marginTop: "50px",
            marginLeft: "30px",
            marginBottom: "20px",
          }}
        >
          Add Project
        </div>
       
        <Table responsive striped bordered hover className="position-relative">
          <thead id="report">
            <tr>
              <th className="text">Project Name</th>
              <th className="text">Start Date</th>
              <th className="text">End Date</th>
            </tr>
          </thead>

          <tr>
            <td>
              <TextField
                variant="outlined"
                className="mytextteam"
                size="small"
                value={projectnames}
                onChange={(e) => handleprojectname(e)}
                type="text"
                error={error}
                autoComplete="off"
                required
                helperText={error}
                // disabled
              />
            </td>

            <td>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // varient="dialog"
                  // format="dd/MM/yyyy"
                  // margin="normal"
                  name="project start date"
                  value={selectedDate}
                  onChange={(date) => handleDateChange(date)}
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  className=""
                  style={{ width: "170px" }}
                  autoComplete="off"
                  // shouldDisableDate={disableWeekends}
                  disablePast
                  error={dateerror}
                  helperText={dateerror}
                  required
                />
              </MuiPickersUtilsProvider>
            </td>

            <td>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  varient="dialog"
                  // format="dd/MM/yyyy"
                  margin="normal"
                  name="project start date"
                  value={selectedDate2}
                  onChange={(date) => handleDateChange2(date)}
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  className=""
                  style={{ width: "170px" }}
                  autoComplete="off"
                  // shouldDisableDate={disableWeekends}
                  disablePast
                  required
                />
              </MuiPickersUtilsProvider>
            </td>
          </tr>
        </Table>
       
       <div>
          <Button
            className="Card_Button_Color_Approve btn-greeng  justify-content-center mr-2"
            onClick={submit}
            style={{ marginLeft: "360px", position: "relative" }}
          >
            Create
          </Button>

          <Button
            className="Card_Button_Color_Approve btn-greeng ml-1"
            onClick={handleallocation}
            // style={{position:"relative"}}
          >
            Allocate project
          </Button>
          </div>
        <br></br>
      </Card> */}
          <br></br>
          {prjallocation == true ? null : (
            <div className="tableback">
              <div className="tabel1">
                <link
                  rel="stylesheet"
                  href="https://fonts.googleapis.com/icon?family=Material+Icons"
                ></link>
                <MaterialTable
                  title={"Project Details"}
                  columns={Columns.map((e) => e)}
                  components={{
                    Pagination: PatchedPagination,
                  }}
                  data={projectdetails?.map((e) => {
                    console.log(e, "project data");
                    return {
                      ...e,
                      projectname: e?.ProjectName,
                      createdby: `${e.CreatedByDetails.Name} ( #${e?.CreatedBy} )`,
                      modules: (
                        <div>
                          {e?.ProjectModules
                            ? e?.ProjectModules?.map((x) => (
                                <label>{x?.FullName}</label>
                              ))
                            : "-"}
                        </div>
                      ),
                      startdate: (
                        <div>
                          <label>{`${moment(e?.StartDate).format(
                            "DD MMM YYYY"
                          )} - ${moment(e?.EndDate).format(
                            "DD MMM YYYY"
                          )}`}</label>
                          <label style={{ textAlign: "center" }}>{`( ${moment(
                            e?.EndDate
                          ).diff(moment(e?.StartDate), "day")} days)`}</label>
                        </div>
                      ),
                      // enddate: moment(e?.EndDate).format("DD MMM YYYY"),
                      ProjectId: `${e?.ProjectShortCode} ( #${e?.ProjectId} )`,
                      id: e?.ProjectId,
                      Progress: 20,
                      Status: "Progress",
                      Action: "",
                    };
                  })}
                  options={{
                    actionsColumnIndex: -1,
                    exportAllData: true,
                    exportButton: true,
                    columnResizable: true,
                    filtering: false,
                    sorting: true,
                    paging: true,
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 20, 30, 40],

                    headerStyle: {
                      whiteSpace: "nowrap",
                      fontWeight: "700",
                      fontSize: "medium",
                      // backgroundColor: "#eef2f7",
                      color: "black",
                      padding: "12px ",
                      textAlign: "left",
                      zIndex: "1",
                    },
                    cellStyle: {
                      textAlign: "left",
                      fontSize: "medium",
                      padding: "21px",
                      color: "#6c757d",
                      // padding: "15px 2px",
                    },
                    rowStyle: (rowdata) => {
                      if (rowdata.tableData.id % 2 != 0) {
                        // return { backgroundColor: "#f6f7fb" };
                      }
                    },
                  }}
                  onRowClick={(evt, selectedRow) => {
                    setSelectedRow(selectedRow.tableData.id);
                    // setSelectedId('')
                  }}
                  // actions={[
                  //   (rowData) => ({
                  //     icon: "edit",
                  //     tooltip: "edit",
                  //     onClick: (event, rowData) => handleEditing(rowData),
                  //   }),
                  //   (rowData) => ({
                  //     icon: "assignment",
                  //     tooltip: "Report",
                  //     onClick: (event, rowData) => handleReport(rowData, "Report"),
                  //   }),
                  // (rowData) => ({
                  //   icon: "delete",
                  //   tooltip: "Deleting",
                  //   onClick: (event, rowdata) => remove(rowdata, "Delete"),
                  // }),
                  // ]}
                />
              </div>
            </div>
          )}
          {/* <Dialog
        open={editopen}
        onClose={() => {
          handleClose();
        }}
        className="mainbox"
        // maxWidth="1500px"
        maxWidth="lg"
        fullWidth={true}

        // fullScreen={true}
      >
        <DialogTitle>
          <Row
            className=""
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Col lg={10} md={10}>
              <label
                className="headerdialog text-secondary"
                style={{ fontWeight: "bold" }}
              >
                Edit Project
              </label>
            </Col>
            <IconButton
              className="d-flex justify-content-end"
              onClick={() => {
                handleClose(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Row>
        </DialogTitle>

        <DialogContent>
          <Editproject
            rowData={row}
            row={row}
            projectdetails={projectdetails}
            setProjectdetails={setProjectdetails}
            close = {handleClose}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog> */}
          <Dialog
            open={editopen}
            onClose={() => handleClose(false)}
            maxWidth="sm"
            fullWidth={true}

            // fullScreen={true}
          >
            <DialogTitle>
              <Row
                className="mainbox"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col lg={10} md={10}>
                  <label style={{ color: "#2593B8", fontWeight: "bold" }}>
                    Edit project and module
                  </label>
                </Col>
                <IconButton
                  className="d-flex justify-content-end"
                  onClick={() => {
                    handleClose(false);
                    setSelectedId("");
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Row>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Editproject
                rowData={row}
                row={row}
                // projectdetails={projectdetails}
                // setProjectdetails={setProjectdetails}
                close={handleClose}
                setedit={setedit}
                modules={modules}
                setModules={setModules}
              />
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
          <Dialog
            open={Report}
            onClose={() => {
              handleClose();
            }}
            className="mainbox"
            // maxWidth="1500px"
            maxWidth="lg"
            fullWidth={true}

            // fullScreen={true}
          >
            <DialogTitle>
              <Row
                className="mainbox"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col lg={10} md={10}>
                  <label style={{ color: "#2593B8", fontWeight: "bold" }}>
                    {row?.ProjectName}
                  </label>
                </Col>
                <IconButton
                  className="d-flex justify-content-end"
                  onClick={() => {
                    handleClose(false);
                    setSelectedId("");
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Row>
            </DialogTitle>
            <DialogContent>
              <Reportallocation
                rowData={row}
                row={row}
                // projectdetails={projectdetails}
                // setProjectdetails={setProjectdetails}
              />
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
          <Dialog
            open={allocationopen}
            onClose={() => {
              handleClose();
            }}
            className="mainbox"
            // maxWidth="800px"
            maxWidth="lg"
            // fullWidth={true}

            // fullScreen={true}
          >
            <DialogTitle>
              <Row
                className=""
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col lg={0} md={8}>
                  <label
                    className="headerallocate text-secondary"
                    style={{ fontWeight: "bold" }}
                  >
                    Project Allocation
                  </label>
                </Col>
                <IconButton
                  className="d-flex justify-content-end"
                  onClick={() => {
                    handleClose(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Row>
            </DialogTitle>

            <DialogContent>
              <ProjectAllocation
                projectdetails={projectdetails}
                employee={employee}
              />
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
          <Dialog
            open={seamlessopen}
            // open={true}
            onClose={() => {
              handleClose();
            }}
            className="mainbox"
            // maxWidth="1500px"
            // maxWidth="1500px"
            maxWidth="lg"
          >
            <DialogTitle>
              <Row
                className=""
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col lg={10} md={10}>
                  <label
                    className="headerdialog "
                    style={{ fontWeight: "bold", color: "rgb(37, 147, 184)" }}
                  >
                    Allocate Project -{" "}
                    <span className="text-danger">{projectnames}</span>
                  </label>
                </Col>
                <IconButton
                  className="d-flex justify-content-end"
                  onClick={() => {
                    handleClose(false);
                    setProjectName("");
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Row>
            </DialogTitle>

            <DialogContent>
              <Allocationseamless
                // projectdetails={projectdetails}
                // setProjectdetails={setProjectdetails}
                Refresh={Refresh}
                Projectname={projectnames}
                setseamlessopen={setseamlessopen}
                seamlessopen={seamlessopen}
                employeeDetails={employee}
                setProjectName={setProjectName}
              />
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
          <Dialog
            open={openProCreate}
            onClose={() => ProjectClose()}
            className="mainbox"
            // maxWidth="1500px"
            maxWidth="sm"
            PaperProps={{ style: { overflowY: "visible" } }}
            fullWidth={true}
            // style={{ overflowY: 'hidden' }}
          >
            {
              // module ?
              //   <DialogTitle className="title">
              //     <Row
              //       className="mainbox"
              //       style={{ display: "flex", justifyContent: "space-between" }}
              //     >
              //       <Col lg={10} md={10}>
              //         <label style={{ color: "#2593B8", fontWeight: "bold" }}>Create New Module</label>
              //       </Col>
              //       <IconButton
              //         className="d-flex justify-content-end"
              //         onClick={() => handlemoduleClose()}
              //       >
              //         <CloseIcon />
              //       </IconButton>
              //     </Row>
              //   </DialogTitle>
              //   :
              projectslength <= check ? (
                ""
              ) : (
                <DialogTitle>
                  <Row
                    className="mainbox"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Col lg={10} md={10}>
                      <label style={{ color: "#2593B8", fontWeight: "bold" }}>
                        Create new project and module
                      </label>
                    </Col>
                    <IconButton
                      className="d-flex justify-content-end"
                      onClick={() => ProjectClose()}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Row>
                </DialogTitle>
              )
            }
            <Divider />
            {projectslength <= check ? (
              <DialogContent style={{ padding: "5%" }}>
                {/* You have exceeded the minimal plan please upgrade to higher plan Further to create a project */}
                You have exceeded the limit as per your plan. Extend your plan
                using Administration portal.
              </DialogContent>
            ) : (
              <DialogContent>
                <Row className={error.projectnames ? "mb-0" : "mb-3"}>
                  {poptions?.length > 0 && (
                    <Col>
                      <label className="SprintlabelStyle">
                        Select Project
                        <label className="createAlert">*</label>
                      </label>
                      <Select
                        options={poptions}
                        value={poptions.filter(
                          (option) => option?.value === selectedProject?.value
                        )}
                        onChange={(e) => handleSelectProject(e)}
                        style={customStyles}
                      />
                      {/* {console.log(error)} */}
                    </Col>
                  )}
                  {/* {
                        selectedProject?.value == "new" && */}
                  <Col>
                    <label className="SprintlabelStyle">
                      Project Name
                      <label className="createAlert">*</label>
                    </label>
                    <Input
                      placeholder="Enter Title"
                      variant="outlined"
                      className="inpui SprintFormFields"
                      size="small"
                      style={{ borderRadius: 14 }}
                      value={projectnames}
                      onChange={(e) => handleprojectname(e)}
                      type="text"
                      invalid={error.projectnames !== undefined}
                      disabled={checkname > 0}
                    />
                    {/* {console.log(error)} */}
                  </Col>
                  {/* } */}
                </Row>
                {error.projectnames && (
                  <div
                    className={error.projectnames ? "mb-3" : "mb-0"}
                    style={{ fontSize: 14, color: "red" }}
                  >
                    {error.projectnames}
                  </div>
                )}
                <ProjectModules
                  modules={modules}
                  setModules={setModules}
                  projectname={projectnames}
                  projectid={selectedProject?.value}
                />
                <Row className="mb-3">
                  <Col>
                    <label className="SprintlabelStyle">
                      Start Date
                      <label className="createAlert">*</label>
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        varient="dialog"
                        // format="dd/MM/yyyy"
                        margin="groove"
                        name="project start date"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        format="dd-MM-yyyy"
                        views={["year", "month", "date"]}
                        className=""
                        style={{ width: "100%" }}
                        autoComplete="off"
                        // shouldDisableDate={disableWeekends}
                        disablePast
                        invalid={error.selectedDate !== undefined}
                        error={dateerror}
                        helperText={dateerror}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <label className="SprintlabelStyle">
                      End Date
                      <label className="createAlert">*</label>
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        varient="dialog"
                        // format="dd/MM/yyyy"
                        margin="inline"
                        name="project start date"
                        value={selectedDate2}
                        onChange={(date) => handleDateChange2(date)}
                        format="dd-MM-yyyy"
                        views={["year", "month", "date"]}
                        style={{ width: "100%" }}
                        autoComplete="off"
                        // shouldDisableDate={disableWeekends}
                        disablePast
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </Col>
                </Row>
              </DialogContent>
            )}
            <DialogActions>
              {projectslength <= check ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <Button1
                    onClick={() => ProjectClose()}
                    style={{
                      fontSize: 14,
                      padding: 9,
                      width: 100,
                      height: 40,
                      borderRadius: 8,
                      backgroundColor: "#5250E5",
                      color: "#fff",
                      border: "0px solid #5A88F1",
                      textAlign: "center",
                      textTransform: "none",
                    }}
                  >
                    Ok
                  </Button1>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <Button1
                    onClick={() => ProjectClose()}
                    style={{
                      fontSize: 14,
                      padding: 9,
                      width: 100,
                      height: 40,
                      borderRadius: 8,
                      marginRight: 14,
                      border: "2px solid #5250E5",
                      backgroundColor: "#fff",
                      color: "#5250E5",
                      textAlign: "center",
                      textTransform: "none",
                    }}
                  >
                    Cancel
                  </Button1>
                  <Button1
                    type="submit"
                    onClick={(e) => submit(e)}
                    disabled={checkSubmit}
                    style={{
                      fontSize: 14,
                      padding: 9,
                      width: 100,
                      height: 40,
                      borderRadius: 8,
                      backgroundColor: "#5250E5",
                      color: "#fff",
                      border: "0px solid #5A88F1",
                      textAlign: "center",
                      textTransform: "none",
                    }}
                  >
                    {checkSubmit ? (
                      <span
                        className="spinner-border"
                        role="status"
                        aria-hidden="true"
                        style={{
                          color: "#fff",
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                      ></span>
                    ) : (
                      "Submit"
                    )}
                  </Button1>
                </div>
              )}
            </DialogActions>
          </Dialog>
          <Dialog
            open={ProAllocateModal || prjallocation}
            onClose={() => AllocateClose()}
            className="mainbox"
            maxWidth="sm"
            fullWidth={true}
            // style={{ height: 900 }}
          >
            <DialogTitle>
              <Row
                className="mainbox "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col lg={10} md={10}>
                  <div>
                    <label
                      style={{
                        color: "#2593B8",
                        fontWeight: "bold",
                        marginBottom: "5px",
                      }}
                    >
                      Allocate Project
                    </label>
                    <label
                      className="text-muted pl-2"
                      style={{ fontSize: "12px" }}
                    >
                      (Supports multiple team members allocation)
                    </label>
                  </div>
                </Col>
                <IconButton
                  className="d-flex justify-content-end"
                  onClick={() => AllocateClose()}
                >
                  <CloseIcon />
                </IconButton>
              </Row>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ minHeight: 400, alignContent: "center" }}>
              <ProjectAllocation
                projectdetails={projectdetails}
                handleClose={AllocateClose}
                employee={employee}
                ProAllocateModal={ProAllocateModal}
              />
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
          {inviteuser == true ? (
            <Team inviteuser={inviteuser} setinviteuser={setinviteuser} />
          ) : null}
        </div>
      )}
    </>
  );
};

export default Addnewproject;
