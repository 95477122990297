import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import axios from "../../../axios";
import { set } from "lodash";

const HistoryReports = ({
  // data,
  // userID,
  releaseID,
  // percentage,
  // chartdata,
  // excludeday,
  // projectname,
  // versionname,
  // projectCode,
  workingDays,
  // cmt,
  // cmt1,
  setcmt,
  setcmt1,
  // cmt2,
  setcmt2,
  // releaseversion,
  // predata,
  // setpredata,
  // displaydata1,
  // setDisplayData1,
  setcmt3,
  predata,
  checkRelease,
  versionDays,
  reportdata,

  // updatesprintburntdown1,
}) => {
  // const [getdata, setdata] = useState([]);
  // useEffect(() => {
  //   let rid = releaseID == null ? releaseversion?.[0] : releaseID;
  //   axios
  //     .get("agilesaas_releases?ReleaseName=eq." + rid)
  //     .then((response) => {
  //       // console.log(response);
  //       setdata(response.data);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }, [releaseID]);
  // console.log(getdata[0]?.ReportsData[0]?.Sprintdays);
  const [gdata, setGdata] = useState([]);
  const [gdata2, setGdata2] = useState([]);
  const [rfddd, setrf] = useState([]);
  const [rfddd1, setrf1] = useState([]);
  const [rfddd2, setrf2] = useState([]);
  const [fo, setfo] = useState([]);
  const [fo1, setfo1] = useState([]);
  const [fo2, setfo2] = useState([]);
  const [fo3, setfo3] = useState([]);

  // const [predata, setpredata] = useState([]);
  // console.log(releaseID);
  useEffect(() => {
    if (checkRelease) {
      // let rid = releaseID == null ? releaseversion?.[0] : releaseID;
      // axios
      //   .get(
      //     "rpc/fun_agilesaasproductivityreport?projid=" +
      //       projectCode +
      //       "&releasename=eq." +
      //       rid
      //   )
      //   .then((response) => {
      // setpredata(response.data);
      // console.log(response.data);
      let data1 = predata;

      // data1.map((e, i) => {
      //   axios
      //     .get(
      //       "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=eq." +
      //         e.issueid +
      //         "&order=UpdatedDate.desc"
      //     )
      //     .then((result) => {
      //       let data = result.data.map((e) => {
      //         return {
      //           EffortPending: e.EffortPending,
      //           EffortRemaining: e.EffortRemaining,
      //           RiskofDelivery: e.RiskofDelivery,
      //           UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
      //           IssueId: e.IssueId,
      //           UpdatedBy: e.UpdatedBy,
      //           PercentageProgressed: e.PercentageProgressed,
      //         };
      //       });

      //       displaydata1.push(data);
      //     });
      // });

      // let filterda = data1?.filter((e) => e.assignedto == userID);

      let idss =
        // userID == null
        // ?
        data1?.map((x) => x?.issueid);
      // : filterda?.map((x) => x?.issueid);
      const issueIds = idss?.join(",");
      let sampldata = [];
      // console.log(idss, userID, "isdd");
      // in.(11004,11006)
      axios
        .get(`agilesaas_issue_history?IssueId=in.(${issueIds})`)
        .then((res) => {
          sampldata.push(
            res.data.filter((e) => e.UpdatedField == "Kanban Changes")
          );
          const mergedArray = [].concat(...sampldata);
          setGdata(mergedArray);
        });
      // })
      // .catch((error) => {
      //   console.log("somthig error");
      // });
      // let filterda = data?.filter((e) => e.assignedto == userID);
    }
  }, [
    // userID,
    releaseID,
    checkRelease,
    predata,
  ]);
  useEffect(() => {
    cahnge();
  }, [gdata]);
  // console.log(predata?.map((e) => e.issueid).length);
  // console.log(gdata);
  let rp = gdata;
  let rp1 = gdata;
  let rp2 = gdata;
  let a = predata?.[0]?.sprintstartdate;
  let ll = predata?.[0]?.sprintenddate;
  // data[0]?.sprintstartdate;
  // console.log(data[0]?.sprintstartdate);
  let b = moment(ll).diff(moment(a), "day") + 1;
  let days = workingDays(a, b);
  // console.log(days);
  const cahnge = () => {
    setrf([]);
    setrf1([]);
    setrf2([]);
    days?.map((dates) => {
      daycalculate(dates, a);
      daycalculate1(dates, a);
      daycalculate2(dates, a);
      daycalculate3(dates, a);
    });
  };

  function daycalculate(dateofmy, a) {
    let sp = rp?.filter(
      (e) =>
        moment(a).diff(e.ChangedDate, "days") <= 0 &&
        moment(e.ChangedDate).diff(dateofmy, "days") <= 0 &&
        e.UpdatedFrom == "RFD" &&
        e.UpdatedTo == "DEV"
    );

    const final = [...new Set(sp?.map((item) => item.IssueId))].length;

    rfddd.push(final);
    let devdata = rfddd?.map((e) => e);
    setcmt(rfddd?.[rfddd?.length - 1]);
    setfo(devdata);
  }

  function daycalculate1(dateofmy, a) {
    let sp1 = rp1?.filter(
      (e) =>
        moment(a).diff(e.ChangedDate, "days") <= 0 &&
        moment(e.ChangedDate).diff(dateofmy, "days") <= 0 &&
        e.UpdatedTo == "DONE"
    );
    const final1 = [...new Set(sp1?.map((item) => item.IssueId))].length;
    // const final1 = sp1?.length;
    rfddd1.push(final1);
    let donedata = rfddd1?.map((e) => e);
    setcmt1(rfddd1?.[rfddd1?.length - 1]);

    setfo1(donedata);
  }
  function daycalculate3(dateofmy, a) {
    let sp1 = rp1?.filter(
      (e) =>
        moment(a).diff(e.ChangedDate, "days") <= 0 &&
        moment(e.ChangedDate).diff(dateofmy, "days") <= 0 &&
        e.UpdatedTo == "UAT"
    );
    const final1 = [...new Set(sp1?.map((item) => item.IssueId))].length;
    // const final1 = sp1?.length;
    rfddd2.push(final1);
    let uatdata = rfddd2?.map((e) => e);
    setcmt3(rfddd2?.[rfddd2?.length - 1]);

    setfo3(uatdata);
  }
  function daycalculate2(dateofmy, a) {
    // let fltda = predata?.filter((e) => e.assignedto == userID).length;
    // console.log(rfddd);
    // console.log(predata?.length);
    // console.log(rfddd?.map((e) => predata?.length - e));

    let rfddata2 =
      // userID == null
      // ?
      rfddd?.map((e) => predata?.length - e);
    // : rfddd.map((e) => fltda - e);
    // setcmt2(fltda);
    setfo2(rfddata2);
  }

  // let temp = moment(predata?.[0]?.sprintenddate).diff(
  //   moment(predata?.[0]?.sprintstartdate),
  //   "day"
  // );
  // const diff =
  //   temp == 0
  //     ? 1
  //     : Number(
  //         Math.ceil(
  //           (new Date(predata?.[0]?.sprintenddate) -
  //             new Date(predata?.[0]?.sprintstartdate)) /
  //             (1000 * 60 * 60 * 24)
  //         ) + 1
  //       );
  // const includays = workingDays(predata?.[0]?.sprintstartdate, diff + 0);

  const formattedDates = versionDays?.map((dateStr) => {
    const formattedDate = moment(dateStr, "YYYY/MM/DD").format("DD MMM");
    return formattedDate;
  });
  const sprintdate = formattedDates?.map((e) => e);
  // console.log(sprintdate);
  // console.log(fo1, "done");
  // console.log(fo2, "rfd");
  // console.log(fo3, "uat");
  // console.log(fo, "dev");

  // let version = projectname + " - " + versionname;
  // console.log(reportdata, "pp");
  const cfdData = {
    labels: checkRelease
      ? sprintdate
      : reportdata?.[0]?.ReportsData?.[0]?.Sprintdays,
    datasets: [
      {
        label: "Done",
        borderColor: "rgb(69, 207, 221)",
        data: checkRelease
          ? fo1
          : reportdata?.[0]?.ReportsData?.[0]?.Cumulative?.DONE,
        fill: true,
        backgroundColor: "rgba(69, 207, 221,0.5)",
        stack: "stack1",
        // lineTension: 0,
        datalabels: {
          align: "center",
          anchor: "center",
          padding: 2,
          font: {
            size: 10,
          },
          borderRadius: 10,
          backgroundColor: "rgb(69, 207, 221)",
          color: "white",
        },
      },
      {
        label: "UAT",
        borderColor: "rgb(255, 204, 112)",
        data: checkRelease
          ? fo3
          : reportdata?.[0]?.ReportsData?.[0]?.Cumulative?.UAT,
        fill: "-1",
        backgroundColor: "rgba(255, 204, 112,0.3)",
        stack: "stack1",
        // lineTension: 0,
        datalabels: {
          align: "center",
          anchor: "center",
          padding: 2,
          font: {
            size: 10,
          },
          borderRadius: 10,
          backgroundColor: "rgb(255, 204, 112)",
          color: "white",
        },
      },
      {
        label: "In Progress",
        borderColor: "rgb(0, 169, 255)",
        data: checkRelease
          ? fo
          : reportdata?.[0]?.ReportsData?.[0]?.Cumulative?.DEV,
        fill: "-1",
        backgroundColor: "rgba(0, 169, 255,0.5)",
        stack: "stack1",
        // lineTension: 0,
        datalabels: {
          align: "center",
          anchor: "center",
          padding: 2,
          font: {
            size: 10,
          },
          borderRadius: 10,
          backgroundColor: "rgb(0, 121, 255)",
          color: "white",
        },
      },
      {
        label: "Refined",
        borderColor: "rgb(255, 0, 96)",
        data: checkRelease
          ? fo2
          : reportdata?.[0]?.ReportsData?.[0]?.Cumulative?.RFD,
        fill: "-1",
        // lineTension: 0,
        backgroundColor: "rgba(255, 0, 96,0.3) ",
        stack: "stack1",
        datalabels: {
          align: "center",
          anchor: "center",
          padding: 2,
          font: {
            size: 10,
          },
          borderRadius: 10,
          backgroundColor: "rgb(255, 0, 96)",
          color: "white",
        },
      },
    ],
  };

  return (
    <>
      {/* {reportdata && ( */}
      <div>
        <Line
          data={cfdData}
          options={{
            responsive: true,
            legend: {
              display: true,
              align: "center",
              labels: {
                fontSize: 12,
                fontStyle: "normal",
                boxWidth: 8,
                usePointStyle: true,
              },
            },
            title: {
              display: true,
              text: checkRelease
                ? "Cumulative Report" + " - " + releaseID
                : "Cumulative Report" +
                  " - " +
                  reportdata[0]?.ReportsData?.[0]?.Version,
              fontSize: 13,
              fontStyle: "bold",
              padding: 0,
              fontColor: "#000",
            },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Days",
                    fontSize: 16,
                    fontStyle: "bold",
                    padding: 0,
                  },
                  ticks: {
                    fontSize: 11,
                    fontStyle: "normal",
                    beginAtZero: true,
                    padding: 0,
                  },
                  gridLines: {
                    display: true,
                    color: "transparent",
                    lineWidth: 0.2,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    zeroLineColor: "gray",
                    zeroLineWidth: 1,
                  },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
                  },
                  gridLines: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.2)",
                    lineWidth: 0.2,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    zeroLineColor: "gray",
                    zeroLineWidth: 1,
                  },
                },
              ],
            },
            tooltips: {
              enabled: true,
              backgroundColor: "gray",
              titleFontColor: "#fff",
              titleFontSize: 14,
              titleFontStyle: "bold",
              bodyFontColor: "#fff",
              bodyFontSize: 12,
              bodyFontStyle: "normal",
              footerFontColor: "#fff",
              footerFontSize: 12,
              footerFontStyle: "normal",
              xPadding: 10,
              yPadding: 10,
              cornerRadius: 6,
            },
          }}
        />
      </div>
      {/* )} */}
    </>
  );
};

// const HistoryReports = () => {
//   const data = {
//     labels: ["January", "February", "March", "April", "May"],
//     datasets: [
//       {
//         label: "Monthly Sales",
//         data: [65, 59, 80, 81, 56],
//         fill: false,
//         borderColor: "rgba(75, 192, 192, 1)",
//         datalabels: {
//           display: true, // Show data labels
//           align: "top", // Position of data labels (top, bottom, center, etc.)
//         },
//       },
//     ],
//   };

//   const options = {
//     scales: {
//       y: {
//         beginAtZero: true,
//       },
//     },
//     plugins: {
//       legend: {
//         display: false, // Hide legend
//       },
//     },
//   };

//   return (
//     <div>
//       <Line data={data} options={options} />
//     </div>
//   );
// };

export default HistoryReports;
