import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";
import moment from "moment";

export async function GetLastThreeUpdateFromEveryUserprogress(
  progressData,
  sprintstartDate
) {
  const progressByIssue = new Map();
  progressData.forEach((progress) => {
    const issueId = progress["IssueId"];
    if (!progressByIssue.has(issueId)) {
      progressByIssue.set(issueId, []);
    }
    progressByIssue.get(issueId).push(progress);
  });
  const lastThreeUpdates = new Map();
  progressByIssue.forEach((progressList, issueId) => {
    let { AssignedToDetails, IssueTitle, StageCode, AssignedTo } =
      progressList[0];

    const sortedUpdates = progressList[0]?.issue_progress.sort(
      (a, b) => moment(b["UpdatedDate"]) - moment(a["UpdatedDate"])
    );
    // progressList[0]?.issue_progress
    //   ?.filter((t) => moment(t["UpdatedDate"]) >= sprintstartDate)
    //   .sort((a, b) => moment(b["UpdatedDate"]) - moment(a["UpdatedDate"]));

    let r;
    if (sortedUpdates.length > 0) {
      r = sortedUpdates?.map((obj) => ({
        ...obj,
        title: IssueTitle,
        StageCode: StageCode,
      }));
    } else {
      if (progressList[0].issue_progress.length > 0) {
        r = [];
      } else {
        r = progressList[0].issue_progress = [
          {
            title: IssueTitle,
            StageCode: StageCode,
            UpdatedBy: AssignedTo,
            UpdatedByDetails: AssignedToDetails,
          },
        ];
      }
    }

    lastThreeUpdates.set(issueId, r.slice(0, 3));
  });

  const sortedData = Array.from(lastThreeUpdates).map(
    ([id, issueProgressArray]) => {
      // Sort the issue progress array based on UpdatedDate
      const sortedIssueProgressArray = issueProgressArray.sort(
        (a, b) => new Date(b.UpdatedDate) - new Date(a.UpdatedDate)
      );

      // Return a new array with the sorted issue progress array
      return [id, sortedIssueProgressArray];
    }
  );

  // Sort the outer array based on the latest UpdatedDate in the inner arrays
  const finaldata = sortedData.sort((a, b) => {
    const aLatestDate = a[1][0] ? new Date(a[1][0].UpdatedDate) : new Date(0);
    const bLatestDate = b[1][0] ? new Date(b[1][0].UpdatedDate) : new Date(0);
    return bLatestDate - aLatestDate;
  });
  console.log(
    [...finaldata].sort((a, b) => a[1][0].UpdatedBy - b[1][0].UpdatedBy),
    "finaldata"
  );
  return [...finaldata].sort((a, b) => b[1][0].UpdatedBy - a[1][0].UpdatedBy);
}

export const fetchUserById = createAsyncThunk(
  "managernote/data",
  async (userdata, { rejectWithValue }) => {
    try {
      let { clientId, projectCode, env } = userdata;

      const releaseData = await axios.get(
        `/agilesaas_releases?select=ReleaseIssues,ReleaseName,ReleaseId,SprintStartDate,SprintEndDate,SprintInWeeks&ProjectId=eq.${projectCode}`
      );
      let release = await releaseData.data?.find(
        (c) =>
          moment(c.SprintStartDate) < moment() &&
          moment(c.SprintEndDate) > moment()
      );
      let PlannedRelease = release?.ReleaseName;
      let sprintstartDate = moment(release?.SprintStartDate);

      const fullData = await axios.get(
        `/agilesaas_issue_details?order=AssignedTo.asc&CurrentStage->0->>StageCode=in.(${env})&PlannedRelease=eq.${PlannedRelease}&ClientId=eq.${clientId}&IsActive=eq.Y&select=IssueId,AssignedTo,AssignedToDetails,ClientId,CurrentStage->0->>StageCode,IssueTitle,PlannedRelease,issue_progress:agilesaas_issue_progress(IssueId,UpdatedBy,UpdatedByDetails,UpdatedDate,EffortRemaining,RiskofDelivery,PercentageProgressed,IssueProgressId,Notes)&ProjectId=eq.${projectCode}`
      );
      let PlantRelease = await fullData.data[0].PlannedRelease;

      let data = await GetLastThreeUpdateFromEveryUserprogress(
        fullData.data,
        sprintstartDate
      );

      return [{ data: data, PlantRelease: PlantRelease }];
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const fetchStoryCount = createAsyncThunk(
  "managernote/story",
  async (userdata, { rejectWithValue }) => {
    try {
      let { ReleaseId, projectCode } = userdata;
      const clientId = localStorage.getItem("clientid");
      const fullData = await axios.get(
        `/agilesaas_releases?ReleaseName=eq.${ReleaseId}&select=ReleaseIssues,SprintStartDate,SprintEndDate,SprintInWeeks&ProjectId=eq.${projectCode}`
      );
      let userData = await fullData.data[0];
      let storyCount = await userData.ReleaseIssues.map((e) => e.IssueId)
        .length;
      let issueIds = userData.ReleaseIssues.map((e) => e.IssueId).toString();
      let currentSprintcard = await axios.get(
        `/agilesaas_issue_details?ClientId=eq.${clientId}&select=CurrentStage->0->>StageCode,issue_progress:agilesaas_issue_progress(PercentageProgressed)&IssueId=in.(${issueIds})`
      );

      const stageCount = {
        DEV: 0,
        DONE: 0,
        UAT: 0,
        RFD: 0,
      };

      const highestProgressValues = {};

      for (const item of currentSprintcard.data) {
        const stageCode = item.StageCode;

        if (!highestProgressValues.hasOwnProperty(stageCode)) {
          highestProgressValues[stageCode] = 0;
        }

        if (stageCount.hasOwnProperty(stageCode)) {
          stageCount[stageCode]++;
        } else {
          console.warn(`Unknown stageCode: ${stageCode}`);
        }

        if (item.issue_progress && item.issue_progress.length > 0) {
          const highestProgress = findHighestProgress(item?.issue_progress);
          highestProgressValues[stageCode] += highestProgress;
        }
      }

      function findHighestProgress(progressArray) {
        let highestProgress = 0;

        for (const progress of progressArray) {
          if (progress.PercentageProgressed > highestProgress) {
            highestProgress = progress.PercentageProgressed;
          }
        }

        return highestProgress;
      }

      let issueCount = Object.values(highestProgressValues).reduce(
        (acc, count) => acc + count,
        0
      );

      let _sDate = userData.SprintStartDate;
      let _eDate = userData.SprintEndDate;
      let _sweek = userData.SprintInWeeks;

      return [
        {
          all: storyCount,
          dev: stageCount["DEV"],
          uat: stageCount["UAT"],
          done: stageCount["DONE"],
          rfd: stageCount["RFD"],
          stateDate: _sDate,
          endDate: _eDate,
          sprintweek: _sweek,
          issue_progressCount: parseInt(issueCount) / parseInt(storyCount),
        },
      ];
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const currentSprintData = createAsyncThunk(
  "managernote/current",
  async (projectCode, { rejectWithValue }) => {
    try {
      const releaseData = await axios.get(
        `/rpc/fun_agilesaascurrentsprint?projid=${projectCode}`
      );

      let _r = await releaseData.data?.filter(
        (e) => e.currentstage[0].StageName != "Backlog"
      );

      return _r;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const Managernote = createSlice({
  name: "managernote",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
    ReleaseId: null,
    fetchStoryCountisSuccess: false,
    fetchStoryCountloading: false,
    fetchStoryCountalldata: [],
    currentisSuccess: false,
    currentloading: false,
    currentsprintdata: [],
  },
  extraReducers: {
    [fetchUserById.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchUserById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload?.[0]?.data;
      state.isSuccess = true;
      state.ReleaseId = payload?.[0]?.PlantRelease;
    },
    [fetchUserById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      state.isSuccess = false;
    },
    //
    [fetchStoryCount.pending]: (state, { payload }) => {
      state.fetchStoryCountloading = true;
    },
    [fetchStoryCount.fulfilled]: (state, { payload }) => {
      state.fetchStoryCountloading = false;
      state.fetchStoryCountalldata = payload;
      state.fetchStoryCountisSuccess = true;
    },
    [fetchStoryCount.rejected]: (state, { payload }) => {
      state.fetchStoryCountloading = false;
      state.fetchStoryCountisSuccess = false;
    },
    [currentSprintData.pending]: (state, { payload }) => {
      state.currentloading = true;
    },
    [currentSprintData.fulfilled]: (state, { payload }) => {
      state.currentloading = false;
      state.currentsprintdata = payload;
      state.currentisSuccess = true;
    },
    [currentSprintData.rejected]: (state, { payload }) => {
      state.currentloading = false;
      state.currentisSuccess = false;
    },
  },
});

export default Managernote.reducer;

// const stageCount = {
//     DEV: 0,
//     DONE: 0,
//     UAT: 0,
//     RFD: 0,
// };

// console.log(currentSprintcard.data);

// let issue_progressCount ;
// await currentSprintcard.data.forEach((item) => {
//     const stageCode = item.StageCode;
//     let cumulativeProgress = calculateCumulativeProgress(item.issue_progress);
//     if (stageCount.hasOwnProperty(stageCode)) {
//         stageCount[stageCode]++;
//     }
//     issue_progressCount = cumulativeProgress.pop()
// });

// function calculateCumulativeProgress(progressArray) {
//     let cumulativeProgress = [];
//     let totalProgress = 0;

//     for (let i = 0; i < progressArray.length; i++) {
//         totalProgress += progressArray[i].PercentageProgressed;
//         cumulativeProgress.push(totalProgress);
//     }
//     return cumulativeProgress;
// }
