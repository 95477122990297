import axios from './axios';

export default async function getAllImages(cid) {
    try {
        const response = await axios.get(`/api/agile/filesystem/EmployeeImage/${cid}`);
        console.log(response?.data, "rrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
        const organizedData = response?.data?.map((e) => ({
            teammemberid: e?.filename?.split('_')?.[0],
            image: e?.data,
        }))
        return organizedData
    } catch (error) {
        console.error(error);
        return ""; // Return an empty string or handle the error as needed.
    }
}
