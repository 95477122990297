import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import "./StoryPoint.css";
import { Card, Col, Row, CardBody } from "reactstrap";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import AvatarImage from "../../../assets/images/avatar.png";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux'

function StoryPoint({
  // SelectedCardId 
}) {
  const { allImage, KanbanBoardData, SelectedCardId } = useSelector((state) => state.user)
  const [spcarddetais, setspcarddetais] = useState([]);
  useEffect(() => {
    axios
      .get("agilesaas_storypoint_estimation?IssueId=eq." + SelectedCardId)
      .then((response) => {
        // console.log(response.data, "spcard")
        setspcarddetais(response.data);
      })
      .catch((error) => console.log(error));
  }, []);


  // console.log(spcarddetais, "spcarddetais")
  let SPEstimationDetails =
    spcarddetais == ""
      ? ""
      : spcarddetais.map((e) => {
        return e.SPEstimationDetails;
      });
  // console.log(SPEstimationDetails, "Spestimationdetasil")
  let SPEdata =
    spcarddetais == ""
      ? ""
      : spcarddetais.map((e) => {
        return {
          AutoEstimatedEffort: e.AutoEstimatedEffort,
          Contigency: e.Contigency,
          TotalEstimatedEffort: e.TotalEstimatedEffort,
        };
      });
  return (
    <div className="mt-5">
      <Card>
        <CardBody className="myspborder mytablebg">
          {/* <table className="mytablebg"> */}
          {/* <Row className="myline "></Row>

            {SPEstimationDetails == "" ? (
              <center>
                {" "}
                <h5 className="text-warning mt-5 fontdesign">Data yet not updated</h5>{" "}
              </center>
            ) : (
              SPEstimationDetails[0].map((e) => {
                return (
                  <div>
                    <Row className="mt-1 ml-3 fontdesign">
                      SP1:
                      <Col>
                        <p className="">{e.SPCode == null ? "0" : e.SPCode}</p>
                      </Col>
                      <Col>
                        <p className="fontdesign">
                          task sp2
                          {e.SPValueSelected == null ? "0" : e.SPValueSelected}
                        </p>
                      </Col>
                      <Col >
                        <p style={{ marginLeft: "-20px" }} className="fontdesign">Frontend</p>
                        <p>{e.Effort == null ? "0" : e.Effort}</p>
                      </Col>
                      <Col>
                        <p className="fontdesign">React JS</p>
                        <p>{e.Effort == null ? "0" : e.Effort}</p>
                      </Col>
                      <Col>
                        <p>null</p>
                        <p>{e.Effort == null ? "0" : e.Effort}</p>
                      </Col>
                      <Col>
                        <p className="fontdesign">
                          5
                        </p>
                        <p>{e.Effort == null ? "0" : e.Effort}</p>
                      </Col>
                    </Row>
                  </div>

                );
              })
            )}
            {SPEdata == ""
              ? ""
              : SPEdata.map((h) => {
                return (
                  <div>
                    <Row className="mt-1 ml-3 ">

                    </Row>
                    <Row className="mt-1 ml-3">
                      <Col>
                        <p className="fontdesign">CONTIGENCY</p>
                      </Col>
                      <Col></Col>
                      <Col>
                        <p className="fontdesign">5</p>
                        <p>{h.Contigency == null ? "0" : h.Contigency}</p>
                      </Col>
                    </Row>
                    <Row className="myline"></Row>
                    <Row className="mt-3 ml-3 text-info ">
                      <Col>
                        <label className="fontdesign">TOTAL ESTIMATED</label>
                      </Col>
                      <Col></Col>
                      <Col>
                        <p className="fontdesign">{5 + " " + "days"}</p>
                      </Col>
                    </Row>
                  </div>
                );
              })} */}
          {/* </table> */}

          <TableContainer>
            <Table sx={{ width: "100%", margin: '0 auto' }} size="smalll" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-head'><Typography style={{ fontWeight: '700', fontSize: 16, fontFamily: 'DM Sans, sans-serif' }}>Story Point Title</Typography></TableCell>
                  <TableCell className='table-head'><Typography style={{ fontWeight: '700', fontSize: 16, fontFamily: 'DM Sans, sans-serif' }}>Module</Typography></TableCell>
                  <TableCell className='table-head'><Typography style={{ fontWeight: '700', fontSize: 16, fontFamily: 'DM Sans, sans-serif' }}>Technology</Typography></TableCell>
                  {/* <TableCell className='table-head'><Typography style={{ fontWeight: '700', fontSize: 16, fontFamily: 'DM Sans, sans-serif' }}>Assigned To</Typography></TableCell> */}
                  <TableCell className='table-head'><Typography style={{ fontWeight: '700', fontSize: 16, fontFamily: 'DM Sans, sans-serif' }}>Days</Typography></TableCell>
                </TableRow>
              </TableHead>

              {/* {console.log(SPEstimationDetails[0])} */}

              {
                SPEstimationDetails == "" || SPEdata == "" ?
                  <center style={{ alignSelf: 'center', justifySelf: 'center' }}>
                    {" "}
                    <h5 className="text-warning mt-5 fontdesign">Data yet not updated</h5>{" "}
                  </center>
                  : SPEstimationDetails[0].length != 0 ?
                    SPEstimationDetails[0].map((e) => {
                      return (
                        <TableBody>
                          <TableRow className="table-body-data">
                            <TableCell><Typography style={{ fontWeight: '600', fontSize: 12, fontFamily: 'DM Sans, sans-serif' }}>{e.title}</Typography></TableCell>
                            <TableCell><Typography style={{ fontWeight: '600', fontSize: 12, fontFamily: 'DM Sans, sans-serif' }}>{e.Module?.FullName ? e.Module?.FullName : e.Module}</Typography></TableCell>
                            <TableCell><Typography style={{ fontWeight: '600', fontSize: 12, fontFamily: 'DM Sans, sans-serif' }}>{e.Techstack ? e.Techstack : "-"}</Typography></TableCell>
                            {/* <TableCell><Typography style={{ fontWeight: '600', fontSize: 12, fontFamily: 'DM Sans, sans-serif', display: 'flex', alignItems: 'center', columnGap: 2 }}>
                              <Avatar src={AvatarImage} sx={{ width: 15, height: 15 }} />Aravind</Typography></TableCell> */}
                            <TableCell><Typography style={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'DM Sans, sans-serif' }}>{e.Effort}</Typography></TableCell>
                          </TableRow>
                        </TableBody>

                      );
                    })
                    : SPEdata.map((h) => {
                      return (
                        <TableBody>
                          <TableRow>
                            <Row className="mt-1 ml-3">
                              <Col>
                                <p className="fontdesign">CONTIGENCY</p>
                              </Col>

                              <Col>
                                <p className="fontdesign">5</p>
                                <p>{h.Contigency == null ? "0" : h.Contigency}</p>
                              </Col>
                            </Row>
                            <Row className="mt-3 ml-3 text-info ">
                              <Col>
                                <label className="fontdesign">TOTAL ESTIMATED</label>
                              </Col>

                              <Col>
                                <p className="fontdesign">{5 + " " + "days"}</p>
                              </Col>
                            </Row>
                          </TableRow>
                        </TableBody>
                      );
                    })}



            </Table>

          </TableContainer>

        </CardBody>
      </Card>
    </div>
  );
}

export default StoryPoint;
