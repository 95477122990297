import { Switch, withStyles } from "@material-ui/core";
import { blue, teal } from "@material-ui/core/colors";

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
    },
    switchBase: {
        padding: 2,
        color: teal[500],
        "&$checked": {
            transform: "translateX(12px)",
            color: blue[500],
            "& + $track": {
                opacity: 1,
                backgroundColor: theme.palette.common.white,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: "none",
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

export default AntSwitch;
