import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import "./Privacy.css";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "reactstrap";
import { Box, Typography } from "@material-ui/core";
import Activate from "./Activate";
import { useDispatch, useSelector } from "react-redux";
import { CommonPostHandler, fetchauthById } from "./Twofactorslice";

toast.configure();

const Privacy = ({ Empid }) => {
  let tokendata = new Cookies();
  let token = tokendata.get("token");
  const dispatch = useDispatch();
  const { data, mainpage } = useSelector((state) => state.twofactor);

  useEffect(() => {
    dispatch(fetchauthById(Empid));
  }, [Empid, dispatch]);

  let DATAid = data?.map((e) => e.EmpTwoFactorAuthId);
  const Disablebutton = mainpage && data?.some((e) => e.TwoStepAuth === "Y");
  const Activatebutton = mainpage && data?.some((e) => e.TwoStepAuth === "N");

  return (
    <Box style={{ height: "700px" }}>
      <Typography variant="h6">Privacy & Setting</Typography>

      {Disablebutton ? (
        <form className="disable">
          <Typography component="p">
            Click the button to disable your Two-factor authentication
          </Typography>
          <Button
            className=" btn btn-danger mt-2"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              let a = {
                IsActive: "N",
                TwoStepAuth: "N",
                Token: token,
                UserdataId: DATAid,
              };
              dispatch(CommonPostHandler(a));
              dispatch(fetchauthById(Empid));
              toast.success(`2fa Deactivated`, {
                transition: Slide,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                draggable: true,
              });
            }}
          >
            Disable
          </Button>
        </form>
      ) : !mainpage ? (
        <form className="qrcode">
          <p>Do You Want To Use Two Factor Authentication</p>
          <Box className="qrbtn">
            <Button
              className="btn-greeng"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                let b = {
                  EmpId: Empid,
                  SecretCode: String(`Yozy(${Empid})`),
                  Token: token,
                };
                dispatch(CommonPostHandler(b));
                toast.success(`2fa Created`, {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 5000,
                  draggable: true,
                });
                dispatch(fetchauthById(Empid));
              }}
            >
              create
            </Button>
          </Box>
        </form>
      ) : null}

      {Activatebutton ? (
        <Activate
          Empid={Empid}
          first={data}
          tokendata={token}
          DATAid={DATAid}
        />
      ) : null}
    </Box>
  );
};

export default Privacy;

// let userdata = JSON.parse(localStorage.getItem("Empdetails"));
// let username = userdata[0]?.UserFirstname + userdata[0]?.UserLastname;
// console.log("called", userdata,username);
