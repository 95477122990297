import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import PatchedPagination from "../../../PatchedPagination";
import axios from "../../../axios";
import { load } from "react-cookies";
import moment from "moment";

const ReportTeamallocate = ({ rowData, projectdetails }) => {
  const Columns = [
    { title: "Name", field: "empname" },
    { title: "Project name", field: "projectname" },
    { title: "Start Date", field: "projectstartdate" },
    { title: "End Date", field: "projectenddate" },
    {
      title: "Allocated %",
      field: "AllocPercentage",
      cellStyle: { fontSize: "12px", padding: "0px 30px" },
    },
    { title: "Status", field: "iscurrent" },
    { title: "Role", field: "ProjectRole" },
  ];

  const [projectdata, setProjectdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employee, setemployee] = useState([]);
  let id = JSON.parse(localStorage.getItem("clientid"));
  const ID = localStorage.getItem("token");
  let email = JSON.parse(localStorage.getItem("userdata"));
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    axios
      .get(`subscription_client_users?ClientId=eq.` + id)
      .then((emp) => {
        setemployee(emp.data);
        let a = emp.data?.find((e) => e?.OfficialEmail == email?.login)?.ClientUserId;

        axios
          .get(
            `rpc/fun_agilesaasmanagerteamprojdetails?managerid=` +
            a +
            `&clientid=` +
            id + `&teammemberid=eq.` + rowData.TeamMemberId
          )
          .then((response) => {
            // console.log(response.data)
            setProjectdata(response.data);
            setLoading(true);


          });

        // });

        // axios
        //   .get("agilesaas_project_mapping?TeamMemberId=eq." + rowData.TeamMemberId)
        //   .then((res) => {
        //     let c = res.data.map((e) => {
        //       let fullname = emp.data.find(
        //         (a) => a.TeamMemberId == e.TeamMemberId
        //       );
        //       return {
        //         ...e,
        //         name: fullname.Firstname + " " + fullname.Lastname,
        //       };
        //     });

        //     setProjectdata(c);

        // });
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  const count = loading
    ? projectdata
      .filter((x) => x.iscurrent === "Y")
      .map((x) => {
        let temp = 0;
        return (temp =
          temp + x.allocpercentage <= 50
            ? 0.5
            : x.allocpercentage >= 51
              ? 1
              : x.allocpercentage);
      })
    : 0;

  function sum(obj) {
    var sum = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el]);
      }
    }
    return sum;
  }

  let startdate = projectdata?.map((r) => moment(r.ProjectStartDate).format("DD MMM YYYY"))

  var summed = sum(count);
  // console.log(projectdata, 'projectdata')
  return (
    <div style={{ padding: 20 }}>
      <p> Employees currently active - {summed} FTEs</p>
      <div className="tabel">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        ></link>
        <MaterialTable
          title={""}
          columns={Columns.map((e) => e)}
          components={{
            Pagination: PatchedPagination,
          }}
          data={
            loading && employee.length > 0 ? (
              <>Loading </>
            ) :
              (
                projectdata.map((e) => {
                  return {
                    ...e,

                    projectenddate:
                      e?.projenddate !== null
                        ? moment(e?.projenddate).format("DD MMM YYYY")
                        : null,
                    projectstartdate:
                      e?.projstartdate !== null
                        ? moment(e?.projstartdate).format("DD MMM  YYYY")
                        : null,

                    projectname: e?.projectname,
                    AllocPercentage: e?.allocpercentage,
                    empname: e?.firstname + "" + e?.lastname,
                    allocpercentage:
                      e?.iscurrent === "N" ? "0" + "%" : e?.iscurrent + "%",
                    iscurrent:
                      e?.iscurrent === "Y"
                        ? "Active"
                        : e?.iscurrent === "N"
                          ? "Released"
                          : null,
                    ProjectRole: e?.projectrole?.RoleName,
                  };
                })
              )}

          options={{
            search: projectdata.length != 0,
            actionsColumnIndex: -1,
            exportAllData: true,
            exportButton: true,
            columnResizable: true,
            filtering: false,
            sorting: true,
            paging: true,
            pageSize: 10,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 30, 40],

            headerStyle: {
              whiteSpace: 'nowrap',
              fontWeight: "700",
              fontSize: "medium",
              // backgroundColor: "#eef2f7",
              color: "black",
              padding: "12px ",
              textAlign: "left",
            },
            cellStyle: {
              textAlign: "left",
              fontSize: "medium",
              padding: "21px",
              color: "#6c757d",
            },
            rowStyle: (rowdata) => {
              if (rowdata.tableData.id % 2 != 0) {
                // return { backgroundColor: "#cddef085" };
              }
            },
          }}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
        />
      </div>
      {/* <MaterialTable
        title={""}
        columns={Columns.map((e) => e)}
        data={
          loading && employee.length > 0 ? (
            <>Loading </>
          ) : (
            projectdata.map((e) => {
              return {
                ...e,

                projectenddate:
                  e?.projenddate !== null
                    ? moment(e?.projenddate).format("DD MMM YYYY")
                    : null,
                projectstartdate:
                  e?.projstartdate !== null
                    ? moment(e?.projstartdate).format("DD MMM  YYYY")
                    : null,

                projectname: e?.projectname,
                AllocPercentage: e?.allocpercentage,
                empname: e?.firstname + "" + e?.lastname,
                allocpercentage:
                  e?.iscurrent === "N" ? "0" + "%" : e?.iscurrent + "%",
                iscurrent:
                  e?.iscurrent === "Y"
                    ? "Active"
                    : e?.iscurrent === "N"
                      ? "Released"
                      : null,
                ProjectRole: e?.projectrole?.RoleName,
              };
            })
          )
        }
        components={{
          Pagination: PatchedPagination,
        }}
        options={{
          actionsColumnIndex: -1,
          exportAllData: true,
          exportButton: true,
          columnResizable: true,
          filtering: false,
          sorting: true,
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 30, 40],

          headerStyle: {
            // backgroundColor: "#8FD6E1",
            // color: "#03256C",
            width: "1000px",
            // textAlign: "center",

            padding: "2px",
            fontWeight: "700",
            fontSize: "small",
            backgroundColor: "#107aeb85",
            color: "#03256c",
          },
          cellStyle: {
            textAlign: "left",
            fontSize: "12px",
            padding: "2px",
            color: "#03256c",
          },
          rowStyle: (rowdata) => {
            if (rowdata.tableData.id % 2 != 0) {
              return { backgroundColor: "#cddef085" };
            }
          },
        }}
      /> */}
    </div>
  );
};

export default ReportTeamallocate;
