import React from 'react'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
const NotfoundProblemcard = ({ tryagain, tryagainvalue }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 15,height:"100vh" }}>
    <SentimentDissatisfiedOutlinedIcon style={{ fontSize: "5rem" }} />
    <h2>No Problems Found</h2>
    <p>We couldn't find any problems matching your filter, or something went wrong.</p>
    <button style={{
      width: 100,
      height: 36,
      borderRadius: 5,
      background: "#5250E5",
      color: "#fff",
    }} onClick={()=>{tryagain(!tryagainvalue)}}>Try Again</button>
  </div>
  
  )
}

export default NotfoundProblemcard