import axios from "../../../axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./ProgressTimeHistory.css";
import { ArrowRight } from "@material-ui/icons";
import { Avatar } from "@material-ui/core";
import ReactDiffViewer from "react-diff-viewer";
import { useDispatch, useSelector } from "react-redux";
import updatemoment from "../updatemoment";
import Unassigned from "../../../assets/images/avatar.png";

function PogressTimeHistory({
  //  SelectedCardId
}) {
  const [updatehistorydetails, setUpdatehistorydetails] = useState([]);
  const [createddate, setcreatedate] = useState([]);

  const { allImage, SelectedCardId } = useSelector((state) => state.user);

  useEffect(() => {
    axios
      .get("agilesaas_issue_history?IssueId=eq." + SelectedCardId)
      .then((res) => setUpdatehistorydetails(res.data));
    axios
      .get(
        `agilesaas_issue_details?IssueId=eq.${SelectedCardId}&select=IssueId,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedDate&order=CreatedDate.desc`
      )
      .then((res) => setcreatedate(res.data));
  }, []);

  const defaultStyles = {
    variables: {
      light: {
        codeFoldGutterBackground: "#6F767E",
        codeFoldBackground: "#E2E4E5",
      },
    },
  };
  // [\]
  console.log(createddate);
  const regex_to = /(<([^>]+)>)/gi;
  let regex = /[&\/\\#^+[\]()$~%.'":*?<>{}!@]/g;
  return (
    <div>
      {updatehistorydetails
        .sort((a, b) => b.IssueHistoryId - a.IssueHistoryId)
        .filter((e) => e.ChangedBy != null)
        .map((e, i) => {
          // console.log(e, "changedby")
          return (
            <div key={i}>
              {e.UpdatedField == "Issue created" ?
                <div className="d-flex  mt-4">
                  <div>
                    {/* <Avatar className="progressName mr-2">
                    {e.ChangedByDetails.FN + e.ChangedByDetails.LN}
                  </Avatar> */}
                    <Avatar
                      className="progressName mr-2"
                      src={
                        allImage?.length > 0 ? allImage?.filter(
                          (x) => x.teammemberid == e.ChangedBy
                        )?.[0]?.image : Unassigned
                      }
                    // sx={{ width: 10, height: 10 }}
                    />
                  </div>
                  <div className="mt-1">
                    <p className="d-flex align items-center">
                      <span className="progress_history_name fontdesign">
                        {e.UpdatedTo}
                      </span>
                      <span className="mx-1"> at</span>
                      <span className="fontdesign">
                        {moment(e.ChangedDate).format("DD MMM YYYY hh:mma")}
                        <span style={{ fontSize: "13px" }}>
                          {" " + `(${updatemoment(e.ChangedDate)})`}
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                :
                <div className="d-flex  mt-4">
                  <div>
                    {/* <Avatar className="progressName mr-2">
                    {e.ChangedByDetails.FN + e.ChangedByDetails.LN}
                  </Avatar> */}
                    <Avatar
                      className="progressName mr-2"
                      src={
                        allImage?.length > 0 ? allImage?.filter(
                          (x) => x.teammemberid == e.ChangedBy
                        )?.[0]?.image : Unassigned
                      }
                    // sx={{ width: 10, height: 10 }}
                    />
                  </div>
                  <div className="mt-1">
                    <p className="d-flex align items-center">
                      <span className="progress_history_name fontdesign">
                        {e.ChangedByDetails.FN + " " + e.ChangedByDetails.LN}
                      </span>
                      <span className="mx-1 fontdesign">Changed</span>
                      <span className="progress_history_name fontdesign">
                        {e.UpdatedField.split(",").length > 1
                          ? "So many changes "
                          : e.UpdatedField.replace(regex, "")}
                      </span>
                      <span className="mx-1"> </span>
                      <span className="fontdesign">
                        {moment(e.ChangedDate).format("DD MMM YYYY hh:mma")}
                        <span style={{ fontSize: "13px" }}>
                          {" " + `(${updatemoment(e.ChangedDate)})`}
                        </span>

                        {/* {updatemoment(e.ChangedDate)} */}
                        {/* {moment().diff(moment(e.ChangedDate), "days") +
                        " " +
                        "days ago"} */}
                      </span>
                    </p>
                    <div
                      className={
                        e.UpdatedField == "Description"
                          ? "progress_history_discrption pt-1 px-5 fontdesign"
                          : "pt-1 px-5 fontdesign"
                      }
                    >
                      <span
                        className={
                          e.UpdatedField == "Description"
                            ? "fontdesign"
                            : e.UpdatedFrom && e.UpdatedTo != ""
                              ? "progress_history_notes fontdesign"
                              : null
                        }
                      >
                        {e.UpdatedFrom == "" ? null : e.UpdatedField ==
                          "Description" ? null : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: e.UpdatedFrom,
                            }}
                          ></span>
                        )}
                      </span>

                      {e.UpdatedFrom &&
                        e.UpdatedTo !== "" &&
                        e.UpdatedField !== "Description" ? (
                        <ArrowRight />
                      ) : null}
                      <span
                        className={
                          e.UpdatedField == "Description"
                            ? "fontdesign "
                            : e.UpdatedFrom && e.UpdatedTo != ""
                              ? "progress_history_notes fontdesign"
                              : null
                        }
                      >
                        {e.UpdatedTo == "" ? null : e.UpdatedField ==
                          "Description" ? null : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: e.UpdatedTo,
                            }}
                          ></span>
                        )}
                      </span>

                      {e.UpdatedField == "Description" ? (
                        <ReactDiffViewer
                          hideLineNumbers={true}
                          oldValue={e.UpdatedFrom.replace(regex_to, "")}
                          newValue={e.UpdatedTo.replace(regex_to, "")}
                          styles={defaultStyles}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              }
            </div>
          );
        })}
      {console.log(updatehistorydetails?.filter((e) => e.UpdatedField == "Issue created")?.length, "llllllllllllllllllllllllll")}
      {
        updatehistorydetails?.filter((e) => e.UpdatedField == "Issue created")?.length > 0 ? "" :
          <div className=" mt-4">
            <div className="d-flex">
              <span>
                <Avatar
                  className="progressName mr-2"
                  src={
                    allImage?.length > 0 ? allImage?.filter(
                      (x) => x.teammemberid == createddate?.[0]?.CreatedBy
                    )?.[0]?.image : Unassigned
                  }
                // sx={{ width: 10, height: 10 }}
                />
              </span>
              <label className="mt-1">
                Issue created by{" "}
                <span className="progress_history_name fontdesign">
                  {createddate?.[0]?.CreatedByDetails}
                </span>{" "}
                at{" "}
                <span className="fontdesign">
                  {moment(createddate?.[0]?.CreatedDate).format(
                    "DD MMM YYYY hh:mma"
                  )}

                  <span style={{ fontSize: "13px" }}>
                    {" " + `(${updatemoment(createddate?.[0]?.CreatedDate)})`}
                  </span>
                </span>
              </label>
            </div>

            <div className="pt-1 px-5 fontdesign">
              <label className="ml-3">
                {createddate?.[0]?.AssignedTo ? "Allocated to" : ""}
              </label>
              <span className="progress_history_name fontdesign ml-2">
                {createddate?.[0]?.AssignedTo
                  ? createddate?.[0]?.AssignedToDetails.FN +
                  " " +
                  createddate?.[0]?.AssignedToDetails.LN
                  : "Un-assigned"}
              </span>
            </div>
            {/* <span>Created date</span> */}
          </div>
      }

    </div>
  );
}

export default PogressTimeHistory;
