import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import OfflineBoltRoundedIcon from "@material-ui/icons/OfflineBoltRounded";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { AlbumRounded, Bookmark } from '@mui/icons-material';
import MaterialIcon, { colorPalette } from "material-icons-react";
import '../../App.css'
import { Avatar } from '@mui/material';
import Anne from '../../assets/images/anne.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Alen from '../../assets/images/Alen.png';
import Rajan from '../../assets/images/Rajan.png';
import Suren from '../../assets/images/Suren.png';
import Aravindh from '../../assets/images/aravindh.png';
import Kandhasamy from '../../assets/images/Kandhasamy.png';
import Unassigned from '../../assets/images/avatar.png'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table';
import PatchedPagination from '../../PatchedPagination';
import { ProgressBar } from "react-bootstrap";

const useStyles = makeStyles({
    root: {
        color: 'rgb(254, 192, 84)', // Default color
    },
    greenSlider: {
        color: 'green',
    },
    yellowSlider: {
        color: 'yellow',
    },
    orangeSlider: {
        color: 'orange',
    },
    redSlider: {
        color: 'red'
    }
});

export default function BasicTable({ data }) {
    const { projectRole, projectRoleName, allImage } = useSelector((state) => state.user)
    console.log(data, 'datagrid')
    const classes = useStyles();

    // Calculate the color based on the value of e.percentageprogressed
    const getColor = (percentage) => {
        if (percentage >= 75) return classes.greenSlider;
        else if (percentage >= 50) return classes.yellowSlider;
        else if (percentage == 0) return classes.redSlider;
        else return classes.root; // Default color
    };

    const rowsPerPage = 20;
    const [page, setPage] = React.useState(1);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Calculate the index range of the current page
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    // Get the data for the current page
    const paginatedData = data.slice(startIndex, endIndex);
    const Columns1 = [
        {
            title: "Ticket reference",
            field: "issuetype",
            // cellStyle: { width: "14%" },
            render: (rowData) => {
                let icon;
                switch (rowData.issuetype) {
                    case 'Story':
                        icon = <Bookmark className="story fontdesign" style={{ fontSize: '16px' }} />;
                        break;
                    case 'Task':
                        icon = <CheckBoxRoundedIcon className="task fontdesign" style={{ fontSize: '16px' }} />;
                        break;
                    case 'Bug':
                        icon = <StopCircleIcon style={{ fontSize: '16px' }} className="bug fontdesign" />;
                        break;
                    case 'Epic':
                        icon = <OfflineBoltRoundedIcon style={{ fontSize: '16px' }} className="epic fontdesign" />;
                        break;
                    case 'SubTask':
                        icon = (<AddToPhotosIcon style={{ fontSize: '16px' }} className="kanbanicon task" {...rowData.issueid} />);
                        break;
                    default:
                        icon = '';
                }

                return (
                    <>
                        <span>
                            {rowData.priority === "Highest" ? (<span><KeyboardDoubleArrowUpIcon className="filter-icons-1" fontSize="small" /></span>)
                                : rowData.priority === "High" ? (<span><KeyboardArrowUpIcon className="filter-icons-1" fontSize="small" /></span>)
                                    : rowData.priority === "Med'ium" ? (<span><DragHandleIcon className="filter-icons" fontSize="small" /></span>)
                                        : rowData.priority === "Low" ? (<span><KeyboardArrowDownIcon className="filter-icons-2" fontSize="small" /></span>)
                                            : rowData.priority === "Lowest" ? (<span><KeyboardDoubleArrowDownIcon className="filter-icons-2" fontSize="small" /></span>)
                                                : ("")}
                        </span>
                        {" "}
                        <label className="mb-0">  {icon} </label>
                        <label style={{ color: '#198ae3', marginLeft: '8px', whiteSpace: 'pre-wrap' }} sx={{ width: 25, height: 25 }}>{rowData.projectdetails?.[0].ProjName + "-" + rowData.issueid}</label>
                    </>
                );
            }
            // defaultSort: "desc",
            // editable: false,
            // cellStyle: { fontSize: "12px" },
        },
        // {
        //     title: "Key",
        //     field: "issueid",
        //     render: (e) => <label style={{ color: '#198ae3', whiteSpace: "nowrap" }}>{e.projectdetails?.[0].ProjName + "-" + e.issueid}</label>,
        //     // editable: false,
        //     // cellStyle: { textAlign: "left", fontSize: "12px" },
        // },
        {
            title: "Summary",
            field: "issuetitle",
            cellStyle: { width: "25%" },
            // editable: false,
            // cellStyle: { fontSize: "12px", textAlign: "left" },
        },

        {
            title: "Assignee", field: "assignedto", render: (e) => <div style={{ display: "flex", alignItems: "center" }}>

                <Avatar
                    style={{ width: 30, height: 30 }}
                    alt="" src={allImage?.length > 0 && allImage?.filter((x) => x.teammemberid == e.assignedto)?.[0]?.image != undefined ? allImage?.filter((x) => x.teammemberid == e.assignedto)?.[0]?.image : Unassigned} />
                &nbsp;{e.assignedtodetails?.FN + e.assignedtodetails?.LN == "" || e.assignedtodetails?.FN == "null" ? "UnAssigned" : e?.assignedtodetails?.FN + " " + e?.assignedtodetails?.LN}</div>
        },
        {
            title: "Original Estimate",
            field: "originalestimate",
            // headerStyle: { border: '2px solid red' },
            // cellStyle: { textAlign: 'center', width: '5%' },
            render: (e) => <span className="ml-4">{e.originalestimate ? e.originalestimate + " Days" : 0 + " Days"}</span>
        },
        // {
        //     title: "Priority", field: "priority", render: (e) => e.priority === "Highest" ? (<div style={{ display: "flex", alignItems: "center" }}><KeyboardDoubleArrowUpIcon className="filter-icons-1" fontSize="small" /><span>{e.priority}</span></div>)
        //         : e.priority === "High" ? (<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowUpIcon className="filter-icons-1" fontSize="small" /><span>{e.priority}</span></div>)
        //             : e.priority === "Medium" ? (<div style={{ display: "flex", alignItems: "center" }}><DragHandleIcon className="filter-icons" fontSize="small" /><span>{e.priority}</span></div>)
        //                 : e.priority === "Low" ? (<div style={{ display: "flex", alignItems: "center" }}><KeyboardArrowDownIcon className="filter-icons-2" fontSize="small" /><span>{e.priority}</span></div>)
        //                     : e.priority === "Lowest" ? (<div style={{ display: "flex", alignItems: "center" }}><KeyboardDoubleArrowDownIcon className="filter-icons-2" fontSize="small" /><span>{e.priority}</span></div>)
        //                         : ("")
        // },
        {
            title: "% Work", field: "percentageprogressed", render: (e) =>
                <Box>
                    {/* <Slider
                        className={getColor(e?.percentageprogressed === "" ? 0 : Number(e?.percentageprogressed))}
                        size="small"
                        value={e?.percentageprogressed === "" ? 0 : Number(e?.percentageprogressed)}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        disabledSlider={true}
                    /> */}
                    <ProgressBar
                        style={{ height: "12px" }}
                        variant={
                            e.percentageprogressed >= 75
                                ? "success"
                                : e.percentageprogressed === 0
                                    ? "danger"
                                    : "warning"
                        }
                        // animated
                        now={e.percentageprogressed}
                        label={
                            <span style={{ fontSize: "10px" }}>
                                {e.percentageprogressed}
                            </span>
                        }
                    />
                </Box>
        },
        {
            title: "Due days", field: "effortremaining", render: (e) =>
                e?.effortremaining == null
                    ? "--"
                    : e?.effortremaining < 0
                        ? -e.effortremaining +
                        " Days Over Due"
                        : e?.effortremaining + " Days",
        },
        // {
        //     title: "Stages", field: "StageCode", render: (e) => <label
        //         className={e.currentstage?.[0]?.StageCode == "DONE" ? 'text-success' : e.currentstage?.[0]?.StageCode == "RFD" ? 'text-warning' : 'text-info'}>
        //         {e.currentstage?.[0].StageCode}
        //     </label>
        // },
        {
            title: "Stages",
            field: "currentstage", // Make sure this field is pointing to the correct data property
            render: (e) => (
                <label
                    className={
                        e.currentstage?.[0]?.StageCode === "DONE"
                            ? 'text-success'
                            : e.currentstage?.[0]?.StageCode === "RFD"
                                ? 'text-warning'
                                : 'text-info'
                    }
                >
                    {e.currentstage?.[0]?.StageCode}
                </label>
            ),
            customSort: (a, b) => {
                const stageOrder = {
                    DONE: 4,
                    UAT: 3,
                    RFD: 1,
                    DEV: 2,
                    // Add more stages as needed
                };

                const stageA = a.currentstage?.[0]?.StageCode;
                const stageB = b.currentstage?.[0]?.StageCode;

                if (stageOrder[stageA] < stageOrder[stageB]) {
                    return -1;
                } else if (stageOrder[stageA] > stageOrder[stageB]) {
                    return 1;
                } else {
                    return 0;
                }
            },
        }


    ];
    return (

        <MaterialTable
            title={"Current Sprint"}
            columns={Columns1.map((e) => e)}
            components={{
                Pagination: PatchedPagination,
            }}
            data={data}
            // {allocationtabel?.map((e) => {

            //   return {

            //     ...e,

            //     TeamMemberId: e?.TeamMemberId,
            //     Name: subteam?.filter(
            //       (r) =>
            //         r.ClientUserId === e?.TeamMemberId

            //     )?.map((f) => f?.UserFirstname + " " + f?.UserLastname,),
            //     project: Teamallocate?.filter(
            //       (r) =>
            //         r.teammemberid === e?.TeamMemberId &&
            //         r?.iscurrent != "N" &&
            //         r?.allocpercentage != 0 &&
            //         r?.projectname != null
            //     )?.map((f) => f?.projectname + "-" + f?.allocpercentage + "%"),
            //     Email:subteam?.filter(
            //       (r) =>
            //         r.ClientUserId === e?.TeamMemberId

            //     )?.map((f) =>  f?.OfficialEmail ),
            //     Phone: subteam?.filter(
            //       (r) =>
            //         r.ClientUserId === e?.TeamMemberId

            //     )?.map((f) => f.Phone ),
            //     // Status:e.IsActive,
            //     // status:e.IsApproved == "Y" && e.IsActive == "Y" ? <span  className="badge badge-success text-white font-weight-bold rounded"> Approved </span> :e.IsApproved == "N" ? <span className="badge badge-danger font-weight-bold text-white rounded">Rejected</span> : e.IsActive == "N" ? <span className="badge badge-danger font-weight-bold text-white rounded">InActive</span> :  e.IsActive == "Y" ?  <span  className="badge badge-success1 text-success font-weight-bold rounded"> Active </span>: <span  className="badge badge-warning text-white font-weight-bold rounded" >Pending for Approval</span>

            //     // createby: e.CreatedByDetails.Name,
            //     // startdate: e?.StartDate,
            //     // enddate: e?.EndDate,
            //     // ProjectId:e?.ProjectId
            //   };
            // })}
            // actions={[
            //   {
            //     icon: "assignment",
            //     tooltip: "Report",
            //     onClick: (event, rowData) => handleReport(rowData),
            //   },
            // ]}
            // editable={{
            //   onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve, reject) => {

            //       // let projectCode12 =
            //       //   projectsArr == null
            //       //     ? ""
            //       //     : projectsArr.find(
            //       //         (pro) => pro.ProjectId === newData.ProjectCode
            //       //       ).ProjectCode;
            //       // console.log(projectCode12, "projectcode");
            //       // let projectCode21 =
            //       //   projectsArr == null
            //       //     ? ""
            //       //     : projectsArr.find(
            //       //         (pro) => pro.ProjectId != newData.ProjectCode
            //       //       ).ProjectCode;
            //       // console.log(projectCode21, "projectcode2");
            //       // setTimeout(() => {
            //       let temp = {
            //         Firstname: newData.Firstname,
            //         Lastname: newData.Lasttname,
            //         Email: newData.Email,
            //         Phone: newData.Phone,
            //       };
            //       resolve();

            //       // axios
            //       //   .patch(
            //       //     "agilesaas_team_master?TeamMemberId=eq." +
            //       //     oldData.TeamMemberId,

            //       //     temp
            //       //   )
            //       //   .then((res) => {
            //       //     toast.success(`Team Edited`, {
            //       //       transition: Slide,
            //       //       position: toast.POSITION.TOP_RIGHT,
            //       //       autoClose: 3000,
            //       //       draggable: true,
            //       //     });
            //       //     Refresh();
            //       //   });
            //       // var postdata2 = {
            //       //   AllocPercentage: 100 - newData.AllocPercentage,
            //       // };
            //       // axios
            //       //   .patch(
            //       //     "agilesaas_project_mapping?TeamMemberId=eq." +
            //       //       valueOfTeamMemberId +
            //       //       "&ProjectCode=eq." +
            //       //       projectCode21,
            //       //     postdata2
            //       //   )
            //       //   .then((res) => {
            //       //     console.log(res, "ReallocResponse2");
            //       //     if (res.status === 204) {
            //       //       console.log(res, "ReallocResponse2");
            //       //     }
            //       //     loadData(valueOfTeamMemberId);
            //       //   });

            //       // }, 1000);
            //     }),
            // }}
            options={{
                actionsColumnIndex: -1,
                exportAllData: true,
                exportButton: true,
                columnResizable: true,
                filtering: false,
                sorting: true,
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50],

                headerStyle: {
                    whiteSpace: "nowrap",
                    fontWeight: "700",
                    fontSize: "medium",
                    // backgroundColor: "#eef2f7",
                    color: "black",
                    // padding: "9px ",
                    textAlign: "left",
                },
                cellStyle: {
                    textAlign: "left",
                    fontSize: "medium",
                    padding: "21px",
                    color: "#6c757d",
                    whiteSpace: "nowrap",
                },
                rowStyle: (rowdata) => {
                    if (rowdata.tableData.id % 2 != 0) {
                        // return { backgroundColor: "#cddef085" };
                    }
                },
            }}
        // onRowClick={(evt, selectedRow) =>
        //     setSelectedRow(selectedRow.tableData.id)
        // }
        />
    );
}