import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import axios from "../../../axios";
import { set } from "lodash";

const CFDChart = ({
  data,
  userID,
  releaseID,
  percentage,
  chartdata,
  excludeday,
  projectname,
  versionname,
  projectCode,
  workingDays,
  cmt,
  cmt1,
  setcmt,
  setcmt1,
  setcmt2,
  setcmt3,
  cmt2,
}) => {
  const [gdata, setGdata] = useState([]);
  const [gdata2, setGdata2] = useState([]);
  const [rfddd, setrf] = useState([]);
  const [rfddd1, setrf1] = useState([]);
  const [rfddd2, setrf2] = useState([]);
  const [fo, setfo] = useState([]);
  const [fo1, setfo1] = useState([]);
  const [fo2, setfo2] = useState([]);
  const [fo3, setfo3] = useState([]);

  useEffect(() => {
    let filterda = data?.filter((e) => e.assignedto == userID);
    let idss =
      userID == null
        ? data?.map((x) => x?.issueid)
        : filterda?.map((x) => x?.issueid);
    let sampldata = [];
    const issueIds = idss?.join(",");
    // console.log(idss, userID, "isdd");

    axios
      .get(`agilesaas_issue_history?IssueId=in.(${issueIds})`)
      .then((res) => {
        sampldata.push(
          res.data.filter((e) => e.UpdatedField == "Kanban Changes")
        );
        const mergedArray = [].concat(...sampldata);
        setGdata(mergedArray);
      });
  }, [userID]);
  useEffect(() => {
    cahnge();
  }, [gdata]);

  let rp = gdata;
  let rp1 = gdata;
  let rp2 = gdata;
  let a = data[0]?.sprintstartdate;
  let b = moment().diff(moment(a), "day") + 1;
  let days = workingDays(a, b);

  const cahnge = () => {
    setrf([]);
    setrf1([]);
    setrf2([]);
    days?.map((dates) => {
      daycalculate(dates, a);
      daycalculate1(dates, a);
      daycalculate2(dates, a);
      daycalculate3(dates, a);
    });
  };

  function daycalculate(dateofmy, a) {
    let sp = rp?.filter(
      (e) =>
        moment(a).diff(e.ChangedDate, "days") <= 0 &&
        moment(e.ChangedDate).diff(dateofmy, "days") <= 0 &&
        e.UpdatedFrom == "RFD" &&
        e.UpdatedTo == "DEV"
    );

    const final = [...new Set(sp?.map((item) => item.IssueId))].length;

    rfddd.push(final);
    let devdata = rfddd?.map((e) => e);
    setcmt(rfddd?.[rfddd?.length - 1]);
    setfo(devdata);
  }

  function daycalculate1(dateofmy, a) {
    let sp1 = rp1?.filter(
      (e) =>
        moment(a).diff(e.ChangedDate, "days") <= 0 &&
        moment(e.ChangedDate).diff(dateofmy, "days") <= 0 &&
        e.UpdatedTo == "DONE"
    );
    const final1 = [...new Set(sp1?.map((item) => item.IssueId))].length;
    // const final1 = sp1?.length;
    rfddd1.push(final1);
    let donedata = rfddd1?.map((e) => e);
    setcmt1(rfddd1?.[rfddd1?.length - 1]);

    setfo1(donedata);
  }

  function daycalculate3(dateofmy, a) {
    let sp1 = rp1?.filter(
      (e) =>
        moment(a).diff(e.ChangedDate, "days") <= 0 &&
        moment(e.ChangedDate).diff(dateofmy, "days") <= 0 &&
        e.UpdatedTo == "UAT"
    );
    const final1 = [...new Set(sp1?.map((item) => item.IssueId))].length;
    // const final1 = sp1?.length;
    rfddd2.push(final1);
    let uatdata = rfddd2?.map((e) => e);
    setcmt3(rfddd2?.[rfddd2?.length - 1]);

    setfo3(uatdata);
  }
  function daycalculate2(dateofmy, a) {
    let fltda = data?.filter((e) => e.assignedto == userID).length;

    let rfddata2 =
      userID == null
        ? rfddd.map((e) => data.length - e)
        : rfddd.map((e) => fltda - e);
    setcmt2(fltda);
    setfo2(rfddata2);
  }

  const formattedDates = excludeday.map((dateStr) => {
    const formattedDate = moment(dateStr, "YYYY/MM/DD").format("DD MMM");
    return formattedDate;
  });
  const sprintdate = formattedDates.map((e) => e);
  let version = projectname + " - " + versionname;
  const cfdData = {
    labels: sprintdate,
    datasets: [
      {
        label: "Done",
        borderColor: "rgb(69, 207, 221)",
        data: fo1,
        fill: true,
        // lineTension: 0,
        backgroundColor: "rgba(69, 207, 221,0.5)",
        // stack: "stack1",
        // datalabels: {
        //   align: "center",
        //   anchor: "center",
        //   // display: "auto",
        //   backgroundColor: "rgb(69, 207, 221)",
        //   // borderColor: "black",
        //   borderRadius: 10, // Set a border radius
        //   // borderWidth: 0.2,
        //   color: "white",
        //   padding: 2,
        //   font: {
        //     size: 10,
        //   },
        // },
        datalabels: {
          align: "center",
          anchor: "center",
          padding: 2,
          font: {
            size: 10,
          },
          borderRadius: 10,
          backgroundColor: "rgb(69, 207, 221)",
          color: "white",
        },
      },
      {
        label: "UAT",
        borderColor: "rgb(255, 204, 112)",
        data: fo3,
        fill: "-1",
        // lineTension: 0,
        backgroundColor: "rgba(255, 204, 112,0.3)",
        // stack: "stack1",
        datalabels: {
          align: "center",
          anchor: "center",
          padding: 2,
          font: {
            size: 10,
          },
          borderRadius: 10,
          backgroundColor: "rgb(255, 204, 112)",
          color: "white",
        },
      },
      {
        label: "Development",
        borderColor: "rgb(0, 169, 255)",
        data: fo,
        fill: "-1",
        backgroundColor: "rgba(0, 169, 255,0.5)",
        // stack: "stack1",
        // lineTension: 0,
        datalabels: {
          align: "center",
          anchor: "center",
          padding: 2,
          font: {
            size: 10,
          },
          borderRadius: 10,
          backgroundColor: "rgb(0, 121, 255)",
          color: "white",
        },
      },

      {
        label: "Refined",
        borderColor: "rgb(255, 0, 96)",
        data: fo2,
        fill: "-1",
        backgroundColor: "rgba(255, 0, 96,0.3) ",
        stack: "stack1",
        // lineTension: 0,
        datalabels: {
          align: "center",
          anchor: "center",
          padding: 2,
          font: {
            size: 10,
          },
          borderRadius: 10,
          backgroundColor: "rgb(255, 0, 96)",
          color: "white",
        },
      },
    ],
  };

  return (
    <div>
      <Line
        data={cfdData}
        options={{
          responsive: true,
          legend: {
            display: true,
            align: "center",
            labels: {
              fontSize: 12,
              fontStyle: "normal",
              boxWidth: 8,
              usePointStyle: true,
            },
          },
          title: {
            display: true,
            text: "Cumulative report" + " - " + versionname,
            fontSize: 13,
            fontStyle: "bold",
            padding: 0,
            fontColor: "#000",
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Days",
                  fontSize: 16,
                  fontStyle: "bold",
                  padding: 0,
                },
                ticks: {
                  fontSize: 11,
                  fontStyle: "normal",
                  beginAtZero: true,
                  padding: 0,
                },
                gridLines: {
                  display: true,
                  color: "transparent",
                  lineWidth: 0.2,
                  drawBorder: true,
                  drawOnChartArea: true,
                  drawTicks: true,
                  zeroLineColor: "gray",
                  zeroLineWidth: 1,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                },
                gridLines: {
                  display: true,
                  color: "rgba(0, 0, 0, 0.2)",
                  lineWidth: 0.2,
                  drawBorder: true,
                  drawOnChartArea: true,
                  drawTicks: true,
                  zeroLineColor: "gray",
                  zeroLineWidth: 1,
                },
              },
            ],
          },
          tooltips: {
            enabled: true,
            backgroundColor: "gray",
            titleFontColor: "#fff",
            titleFontSize: 14,
            titleFontStyle: "bold",
            bodyFontColor: "#fff",
            bodyFontSize: 12,
            bodyFontStyle: "normal",
            footerFontColor: "#fff",
            footerFontSize: 12,
            footerFontStyle: "normal",
            xPadding: 10,
            yPadding: 10,
            cornerRadius: 6,
          },
        }}
      />
    </div>
  );
};

export default CFDChart;
