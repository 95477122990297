import React from "react";


function Footer() {
  return (
    <footer className="footer" >



      <div className=" d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright ©  2024-2026 Yozy Technologies. All rights reserved.</span>
        {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Copyright © 2021-2023 Yozy Technologies. All rights reserved. */}
        {/* © {new Date().getFullYear()} made with{" "}
          <i className="tim-icons icon-heart-2" /> by{" "} */}

      </div>

    </footer>
  );
}

export default Footer;
