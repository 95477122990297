import React, { useEffect, useState } from "react";
import axios from "../../axios";
import "./UATchecklist.css";
import moment from "moment";
import { toast, Slide } from "react-toastify";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Form from "react-bootstrap/Form";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { HANDLE_ON_CHANGE } from "../../redux/CommonInitialsate";

toast.configure();
//uAT to Done
function UATchecklist({
  // UATchecklistD,
  // setUATchecklistD,
  // IssueId,
  // selectedCard,
  // setRefresh,
  // setProgressopen,
}) {
  const [checkedinput, setcheckedinput] = useState(false);
  const handlecheckedinput = () => setcheckedinput(!checkedinput);
  const [checkedvalid, setcheckedvalid] = useState(false);
  const handlecheckedvalid = () => setcheckedvalid(!checkedvalid);
  const [checkedtest, setcheckedtest] = useState(true);
  const handlecheckedtest = () => setcheckedtest(!checkedtest);
  const [checkedstyle, setcheckedstyle] = useState(false);
  const handlecheckedstyle = () => setcheckedstyle(!checkedstyle);
  const [checkedupdate, setcheckedupdate] = useState(false);
  const handlecheckedupdate = () => setcheckedupdate(!checkedupdate);
  const [checkedinsert, setcheckedinsert] = useState(false);
  const handlecheckedinsert = () => setcheckedinsert(!checkedinsert);
  const [checkeddel, setcheckeddel] = useState(false);
  const handlecheckeddel = () => setcheckeddel(!checkeddel);
  // const [signoff, setsignoff] = useState(true);
  // const handlesignoff = () => setsignoff(!signoff);
  const [Effort, setEffort] = useState("1");
  const [checkSubmit, setSubmitCheck] = useState(false);
  const { projectCode, selectedCard, IssueId, UATchecklistD } = useSelector((state) => state.user);

  const dispatch = useDispatch()
  const handleClose = () => {
    // setUATchecklistD(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'UATchecklistD', value: false }))
    dispatch(HANDLE_ON_CHANGE({ name: 'progressopen', value: false }))

    // setProgressopen(false);
    setcheckeddel(false);
    setcheckedinput(false);
    setcheckedinsert(false);
    setcheckedupdate(false);
    setcheckedvalid(false);
    setcheckedtest(true);
    setcheckedstyle(false);
    // setsignoff(false);
    setEffort("1");
    // setRefresh(true);
    dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
  };
  let arr =
    sessionStorage.getItem("Roledetails") == "undefined"
      ? ""
      : JSON.parse(sessionStorage.getItem("Roledetails"));
  let emplogin = JSON.parse(localStorage.getItem("Empdetails"));
  let login = emplogin != null ? emplogin : null;
  let clientid = JSON.parse(localStorage.getItem("clientid"));
  const datalossprevnt = () => {
    let patchdatadp = {
      CurrentStage: [
        {
          StageCode: "UAT",
          StageName: "User Acceptace Testing",
          DateMoved: moment().format("YYYY-MM-DD"),
        },
      ],
    };
    axios
      .patch("agilesaas_issue_details?IssueId=eq." + IssueId, patchdatadp)
      .then((res) => {
        handleClose();
      });
  };

  const submitchecklist = () => {
    if (Effort == "0") {
      toast.error(`Please give some effort you have to spend for testing`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    } else if (
      !checkedinput &&
      !checkedinsert &&
      !checkedstyle &&
      !checkedtest &&
      !checkedvalid &&
      !checkedupdate &&
      !checkeddel
    ) {
      toast.error(`Please check, What did you test`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    }
    // else if (signoff == false) {
    //   toast.error(`Please give your confirmation`, {
    //     transition: Slide,
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 3000,
    //     draggable: true,
    //   });
    // }
    else {
      let postdataHistory = [
        {
          IssueId: IssueId,
          ChangedBy: login?.[0]?.ClientUserId,
          ChangedByDetails: {
            FN: login?.[0]?.UserFirstname,
            LN: login?.[0]?.UserLastname,
          },
          ChangedDate: moment()
            .utcOffset("+05:30")
            .format("YYYY-MM-DDTHH:mm:ss"),
          UpdatedField: "Moved to done",
          UpdatedFrom: null,
          UpdatedTo: "Yes",
          UpdateSequence: "1",
          // ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
          // UpdatedField: "Moved to done",
          // UpdatedFrom: null,
          // UpdatedTo: "Yes",
          // UpdateSequence: "1",
          IsActive: "Y",
          Status: null,
          ClientId: clientid,
          ProjectId: projectCode,
        },
        {
          IssueId: IssueId,
          ChangedBy: login?.[0]?.ClientUserId,
          ChangedByDetails: {
            FN: login?.[0]?.UserFirstname,
            LN: login?.[0]?.UserLastname,
          },
          ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
          UpdatedField: "Kanban Changes",
          UpdatedFrom: "UAT",
          UpdatedTo: "DONE",
          UpdateSequence: "1",
          IsActive: "Y",
          Status: null,
          ClientId: clientid,
          ProjectId: projectCode,
        },
      ];

      let patchdata = {
        CurrentStage: [
          {
            StageCode: "DONE",
            StageName: "Done",
            DateMoved: moment().format("YYYY-MM-DD"),
          },
        ],
      };
      let postdata = {
        IssueId: IssueId,
        UATSignoffBy: arr.empid,
        UATSignoffByDetails: {
          FN: arr.firstname,
          LN: arr.lastname,
        },
        UATSignoffDate: moment().format("YYYY-MM-DD"),
        UATSignoffDetails: [
          {
            SPCode: "INPUTFORM",
            UATSignOff: checkedinput ? "Yes" : "No",
          },
          {
            SPCode: "FROMVALIDATION",
            UATSignOff: checkedvalid ? "Yes" : "No",
          },
          {
            SPCode: "Testing",
            UATSignOff: checkedtest ? "Yes" : "No",
          },
          {
            SPCode: "Styling",
            UATSignOff: checkedstyle ? "Yes" : "No",
          },
          {
            SPCode: "Update",
            UATSignOff: checkedupdate ? "Yes" : "No",
          },
          {
            SPCode: "Insert",
            UATSignOff: checkedinsert ? "Yes" : "No",
          },
          {
            SPCode: "Delete",
            UATSignOff: checkeddel ? "Yes" : "No",
          },
        ],
        EffortSpentInHours: parseInt(Effort),
        UATSignoffConsent: "Moved to done",
        UATRemarks:
          "completed UAT and tested negaive scenarios no much challenges",
        IsActive: "Y",
        Status: null,
        ClientId: clientid,
        ProjectId: projectCode,
      };
      setSubmitCheck(true);
      axios
        .patch("agilesaas_issue_details?IssueId=eq." + IssueId, patchdata)
        .then((res) => {
          console.log(res);
        });
      axios.post("agilesaas_issue_history", postdataHistory).then((res) => { });
      axios.post("agilesaas_uat_signoff ", postdata).then((res) => {
        axios
          .get(
            "agilesaas_issue_progress?IssueId=eq." +
            IssueId +
            "&order=UpdatedDate.desc&limit=1&select=IssueProgressId,PercentageProgressed"
          )
          .then((res) => {
            if (res.data.length > 0) {
              let progressid = res.data?.[0]?.IssueProgressId;
              if (res.data?.[0]?.PercentageProgressed != 100) {
                axios
                  .patch(
                    "agilesaas_issue_progress?IssueProgressId=eq." + progressid,
                    { PercentageProgressed: 100 }
                  )
                  .then((res) => console.log(res))
                  .catch((err) => console.log(err));
              }
            }
            // console.log(res.data);
          });
        toast.success(`Updated Successfully`, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          draggable: true,
        });
        setSubmitCheck(false);
      });

      handleClose();
    }
  };
  return (
    // <div className="backbg">
    //   <Dialog
    //     // onBackdropClick="true"
    //     open={UATchecklistD} onClose={() => { }}>
    //     <DialogTitle className="UATcBGCOLOR1">
    //       <Card className="UATctesterCard1">
    //         <Row>
    //           <Col lg={10} md={10} sm={10}>
    //             <div className="header_style">UAT Tester Result Update</div>
    //           </Col>
    //           <Col lg={2} md={2} sm={2}>
    //             <IconButton onClick={datalossprevnt}>
    //               <CloseIcon />
    //             </IconButton>
    //           </Col>
    //         </Row>

    //         <table className="UATcBGCOLOR">
    //           <th colSpan="2"></th>
    //           <tr>
    //             <td>
    //               <label className="UATctitle">
    //                 <Bookmark
    //                   className="kanbanicon1 mt-2"
    //                   style={{ backgroundColor: "#00cc00" }}
    //                 />
    //                 {selectedCard.IssueTitle == ""
    //                   ? ""
    //                   : " " + selectedCard.IssueTitle}
    //               </label>{" "}
    //             </td>
    //             <td className="d-flex flex-row-reverse bd-highlight">
    //               <span class="badge badge-success bdgefont">DONE</span>
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 width="16"
    //                 height="16"
    //                 fill="currentColor"
    //                 class="bi bi-arrow-right"
    //                 viewBox="0 0 16 16"
    //               >
    //                 <path
    //                   fill-rule="evenodd"
    //                   d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
    //                 />
    //               </svg>
    //               <span class="badge badge-info  ">UAT</span>
    //             </td>
    //           </tr>
    //           <tr>
    //             <td className="UATcttdMain">
    //               <tr>
    //                 <div className="ml-5">
    //                   <tr></tr>
    //                   <tr>
    //                     <td className="UATcttd">
    //                       <input
    //                         type="checkbox"
    //                         onClick={handlecheckedinput}
    //                         checked={checkedinput}
    //                       />
    //                       <label className="ml-2 bdgefont">Input Form</label>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td className="UATcttd">
    //                       <input
    //                         type="checkbox"
    //                         onClick={handlecheckedvalid}
    //                         checked={checkedvalid}
    //                       />
    //                       <label className="ml-2 bdgefont">Validation</label>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td className="UATcttd">
    //                       <input
    //                         type="checkbox"
    //                         onClick={handlecheckedtest}
    //                         checked={checkedtest}
    //                       />
    //                       <label className="ml-2 bdgefont">Testing</label>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td className="UATcttd">
    //                       <input
    //                         type="checkbox"
    //                         onClick={handlecheckedstyle}
    //                         checked={checkedstyle}
    //                       />
    //                       <label className="ml-2 bdgefont">Styling</label>
    //                     </td>
    //                   </tr>
    //                   {/* </div> */}
    //                 </div>
    //               </tr>
    //             </td>
    //             <td className="UATcttdMain">
    //               <tr>
    //                 <label className="bdgefont">API Integration</label>
    //               </tr>
    //               <div className="mt-2">
    //                 <tr>
    //                   <td className="UATcttd">
    //                     <input
    //                       type="checkbox"
    //                       onClick={handlecheckedupdate}
    //                       checked={checkedupdate}
    //                     />
    //                     <label className="ml-2 bdgefont">Update</label>
    //                   </td>
    //                 </tr>
    //                 <tr>
    //                   <td className="UATcttd">
    //                     <input
    //                       type="checkbox"
    //                       onClick={handlecheckedinsert}
    //                       checked={checkedinsert}
    //                     />
    //                     <label className="ml-2 bdgefont">Insert</label>
    //                   </td>
    //                 </tr>
    //                 <tr>
    //                   <td className="UATcttd">
    //                     <input
    //                       type="checkbox"
    //                       onClick={handlecheckeddel}
    //                       checked={checkeddel}
    //                     />
    //                     <label className="ml-2 bdgefont">Delete</label>
    //                   </td>
    //                 </tr>
    //               </div>
    //               {/* </tr> */}
    //             </td>
    //           </tr>
    //           <tr>
    //             <td className="">
    //               <label className="ml-5 UATctitle">
    //                 Effort Spent In Hours
    //               </label>
    //             </td>
    //             <td className="">
    //               <TextField
    //                 variant="outlined"
    //                 className="pb-3 mychecktext"
    //                 label=""
    //                 name="Contigency"
    //                 size="small"
    //                 value={Effort}
    //                 onChange={(event) =>
    //                   event.target.value < 0
    //                     ? (event.target.value = 0)
    //                     : setEffort(event.target.value)
    //                 }
    //                 type="number"
    //                 InputProps={{ inputProps: { min: 0 } }}
    //                 required
    //               />
    //             </td>
    //           </tr>
    //         </table>
    //         <table className="UATcBGCOLOR1">
    //           <div className="UATcBGCOLOR1 mt-1 ml-5 ">
    //             <input
    //               type="checkbox"
    //               onClick={handlesignoff}
    //               checked={signoff}
    //             />
    //             <label className="UATcttd1 UATcBGCOLOR1 ml-2">
    //               I signoff this ticket to move it to complete stage
    //             </label>
    //           </div>
    //           <div className="UATcBGCOLOR1">
    //             <center>
    //               <Button
    //                 className={
    //                   signoff == false
    //                     ? "D-flex justify-content-center Card_Button_Color_Approve btn-redg"
    //                     : "D-flex justify-content-center Card_Button_Color_Approve btn-greeng"
    //                 }
    //                 onClick={submitchecklist}
    //                 disabled={signoff == false ? true : false}
    //               >
    //                 Ok
    //               </Button>
    //             </center>
    //           </div>
    //         </table>
    //       </Card>
    //     </DialogTitle>
    //     <DialogActions></DialogActions>
    //   </Dialog>
    // </div>
    <Dialog fullWidth={true} maxWidth={"xs"} open={UATchecklistD}>
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 15px",
        }}
      >
        <Typography
          style={{
            fontFamily: "DM Sans, sans-serif",
            fontSize: 14,
            fontWeight: 800,
          }}
        >
          UAT Tester Result Update
        </Typography>
        <Typography
          onClick={() => {
            handleClose();
          }}
          style={{ cursor: "pointer" }}
        >
          <CloseIcon />
        </Typography>
      </DialogTitle>

      <Divider />

      <DialogContent style={{ padding: "10px 24px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontFamily: "DM Sans, sans-serif",
              fontSize: 14,
              fontWeight: 700,
              color: "#000",
            }}
          >
            <CheckCircleOutlineIcon color="success" className="check-icon" />
            {selectedCard?.IssueTitle == "" ? "" : " " + selectedCard?.IssueTitle}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: "#1a89e1", borderRadius: "20px" }}
            >
              UAT
            </Button>
            <KeyboardDoubleArrowRightIcon />
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: "#5AE6A9", borderRadius: "20px" }}
            >
              DONE
            </Button>
          </div>
        </div>

        <Card variant="outlined" style={{ margin: "20px 0 10px" }}>
          <CardContent style={{ padding: 0 }}>
            <Grid container style={{ padding: "10px 0" }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  variant="body2"
                  style={{ textAlign: "center", fontSize: 14, fontWeight: 700 }}
                >
                  Front-End
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  variant="body2"
                  style={{ textAlign: "center", fontSize: 14, fontWeight: 700 }}
                >
                  API Integration
                </Typography>
              </Grid>
            </Grid>
            <Divider
              variant="fullWidth"
              style={{ border: "1px solid #cccccc" }}
            />
            <Grid container style={{ padding: "10px 0" }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <FormGroup
                  style={{ display: "flex", alignContent: "center" }}
                  className="form-checkbox"
                >
                  <FormControlLabel
                    size="small"
                    control={
                      <Checkbox
                        onClick={handlecheckedinput}
                        checked={checkedinput}
                      />
                    }
                    label="Input Form"
                  />
                  <FormControlLabel
                    size="small"
                    control={
                      <Checkbox
                        onClick={handlecheckedvalid}
                        checked={checkedvalid}
                      />
                    }
                    label="Validation"
                  />
                  <FormControlLabel
                    size="small"
                    control={
                      <Checkbox
                        onClick={handlecheckedtest}
                        checked={checkedtest}
                      />
                    }
                    label="Testing"
                  />
                  <FormControlLabel
                    size="small"
                    control={
                      <Checkbox
                        onClick={handlecheckedstyle}
                        checked={checkedstyle}
                      />
                    }
                    label="Styling"
                  />
                </FormGroup>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <FormGroup
                  style={{ display: "flex", alignContent: "center" }}
                  className="form-checkbox"
                >
                  <FormControlLabel
                    size="small"
                    control={
                      <Checkbox
                        onClick={handlecheckedupdate}
                        checked={checkedupdate}
                      />
                    }
                    label="Update"
                  />
                  <FormControlLabel
                    size="small"
                    control={
                      <Checkbox
                        onClick={handlecheckedinsert}
                        checked={checkedinsert}
                      />
                    }
                    label="Insert"
                  />
                  <FormControlLabel
                    size="small"
                    control={
                      <Checkbox
                        onClick={handlecheckeddel}
                        checked={checkeddel}
                      />
                    }
                    label="Delete"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box
          component="span"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            columnGap: 2,
            margin: "10px 0",
          }}
        >
          <Typography
            variant="body2"
            style={{
              fontsize: 14,
              fontFamily: "DM Sans, sans-serif",
              fontWeight: 700,
            }}
          >
            Total Time in Hours
          </Typography>
          <Form.Control
            size="sm"
            style={{ width: 50, height: 25 }}
            value={Effort}
            onChange={(event) =>
              event.target.value < 0
                ? (event.target.value = 0)
                : setEffort(event.target.value)
            }
            type="number"
          />
        </Box>
      </DialogContent>

      <Divider variant="fullWidth" style={{ border: "1px solid #cccccc" }} />

      <DialogActions
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 15px",
        }}
      >
        <div className="dialog-footer">
          {/* <Checkbox size="small" onClick={handlesignoff} checked={signoff} />
          <Typography style={{ fontSize: 11 }}>
            Move Ticket to complete stage
          </Typography> */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              handleClose();
            }}
            style={{
              fontSize: 14,
              padding: 9,
              width: 100,
              height: 40,
              borderRadius: 8,
              marginRight: 14,
              border: "2px solid #5250E5",
              backgroundColor: "#fff",
              color: "#5250E5",
              textAlign: "center",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              fontSize: 14,
              padding: 9,
              width: 100,
              height: 40,
              borderRadius: 8,
              backgroundColor: "#5250E5",
              color: "#fff",
              border: "0px solid #5A88F1",
              textAlign: "center",
              textTransform: "none",
            }}
            onClick={submitchecklist}
            disabled={
              // signoff == false ||
              checkSubmit ? true : false
            }
          >
            {checkSubmit ? (
              <span
                className="spinner-border"
                role="status"
                aria-hidden="true"
                style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
              ></span>
            ) : (
              "Submit"
            )}
          </Button>
          {/* <Button variant="outlined" size="large" onClick={() => { handleClose() }} style={{ color: '#000', backgroundColor: '#fff', border: '1px solid #ccc', marginRight: 15, textTransform: 'none', fontFamily: 'DM Sans, sans-serif' }}>
            <Typography style={{ fontFamily: 'DM Sans, sans-serif', fontSize: 14, fontWeight: 600, color: "#000", textTransform: 'none' }}>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{ color: '#fff', backgroundColor: '#008cba', border: 'none', textTransform: 'none', fontFamily: 'DM Sans, sans-serif' }}
            className={
              signoff == false
                ? "D-flex justify-content-center Card_Button_Color_Approve btn-redg"
                : "D-flex justify-content-center Card_Button_Color_Approve btn-greeng"
            }
            onClick={submitchecklist}
            disabled={signoff == false ? true : false}>
            <Typography style={{ fontFamily: 'DM Sans, sans-serif', fontSize: 14, fontWeight: 600, textTransform: 'none' }}>Submit</Typography>
          </Button> */}
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default UATchecklist;
