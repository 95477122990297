import React from "react";
import { createRoot } from 'react-dom/client';
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "./App";
import "./assets/css/nucleo-icons.css";
import './index.css';
import { store, persistor } from "./redux/Store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { GlobalDebug } from './remove_console';
import Commonsnackbar from "./Commonsnackbar";

const UserContext = React.createContext();
export default UserContext;

const container = document.getElementById('root');
const root = createRoot(container);
GlobalDebug(false)
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
        <Commonsnackbar />
    </Provider >
);