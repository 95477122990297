// Set data in localStorage
export const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, value);
};

// Get data from localStorage
export const getLocalStorageItem = (key) => {
    const item = localStorage.getItem(key);
    return item ? item : null;
};

// Remove data from localStorage
export const removeLocalStorageItem = (key) => {
    localStorage.removeItem(key);
};
