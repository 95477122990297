import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import axios from "../../axios";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, useSelector } from 'react-redux'

const localizer = momentLocalizer(moment);
const CeremoniesCalendar = () => {
  const [meetings, setMeetings] = useState([]);
  const { projectCode } = useSelector((state) => state.user)


  useEffect(() => {
    axios
      .get(`agilesaas_ceremonies?ProjectDetails->>ProjectCode=eq.${projectCode}`)
      .then((response) => {
        setMeetings(response.data)
      })
      .catch((error) => console.log(error));
  }, []);

  const formattedMeetings =
    meetings &&
    meetings?.map((e) => ({
      Title: e.Title,
      start: moment(e.ScheduledStartTime).toDate(),
      end: moment(e.ScheduledEndTime).toDate(),
      projectname: e?.ProjectDetails?.ProjectName,
    }));

  const getEventStyle = (event, start, end, isSelected) => {
    let style = {
      backgroundColor: "#025464",
      color: "#fff",
      borderRadius: "7px",
      border: "none",
      display: "block",
      padding: "2px",
      marginBottom: "3px",
    };

    if (event.Title === "Daily scrum meeting") {
      style.backgroundColor = "#44AB5A";
    } else if (event.Title === "Retrospective Meeting") {
      style.backgroundColor = "#FBBF4A";
    } else if (event.Title === "SprintPlan Meeting") {
      style.backgroundColor = "#1773C3";
    }
    if (isSelected) {
      style.backgroundColor = "#00235B";
      style.color = "#fff";
    }
    return {
      style: style,
    };
  };
  const EventComponent = ({ event }) => {
    const eventStyle = {
      cursor: "pointer",
      textAlign: "center",
      fontSize: 10,
      padding: "0px",
    };
    return (
      <div style={eventStyle}>
        {event.projectname}
        <br></br>
        {event.Title}
      </div>
    );
  };

  return (
    <div style={{ height: "500px" }}>
      <Calendar
        localizer={localizer}
        eventPropGetter={getEventStyle}
        events={formattedMeetings}
        selectable
        startAccessor="start"
        endAccessor="end"
        components={{
          event: EventComponent,
        }}
      />
    </div>
  );
};

export default CeremoniesCalendar;
