import axios from "axios";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import "./Login.css";
import logo from "../../assets/images/samples/logo.png";
import svg from "./newlogo.svg";
import maillogo from "./mail-14.webp";
import { Col, Row, Modal } from "react-bootstrap";
import { main, UserConfigset } from "./LoginActions";
import Twoauthverify from "./Twoauthverify";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function LoginPage({ setUser }) {
  const cookies = new Cookies();

  const [errors, setErrors] = useState("");
  const [defaultlogin, setDefaultLogin] = useState(true);
  const [sendmail, setsendmail] = useState(false);
  const [loginData, setLoginData] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [checkSubmit, setCheckSubmit] = useState(false);
  const [emailerror, setemailerror] = useState(false)
  useEffect(() => {
    let token = cookies?.get("token");
    let login = cookies?.get("email");
    let dblogin = cookies?.get("default");
    let erroruser = cookies?.get("user");
    let loginemail = cookies?.get("loginemail");

    if (erroruser && !dblogin) {
      setDefaultLogin((prev) => !prev);
      formik.setValues({ login: loginemail });
      setErrors(
        "Error message: SSO is not enabled, currently supporting Azure AD, Google AD"
      );
    } else if (dblogin && !token && loginemail) {
      setDefaultLogin((prev) => !prev);
      setErrors("SSO is not enabled, currently supporting Azure AD, Google AD");
      formik.setValues({ login: loginemail });
    } else if (token && login) {
      let data = {
        id: 1,
        login: login,
        token: token,
      };
      let values = {
        password: "",
      };
      UserConfigset(data, values);
    } else {
      setDefaultLogin(true);
    }
  }, []);

  function isValidEmail(forgotemail) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(forgotemail);
  }

  const forgotpassword = async () => {
    if (!isValidEmail(formik.values.login)) {
      // alert("Email is not valid.");
      setemailerror(true)
    } else {

      let mail = formik.values.login;
      let data = {
        email: mail,
        isnewuser: false,
      };
      formik.values.login = "";
      await axios
        .post("https://api.devozy.ai/auth/password-reset/reset", data)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setsendmail(true);
          setemailerror(false)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const containerStyle = {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    padding: "40px",
    width: "100%",
    maxWidth: "500px",
    textAlign: "center",
    justifyContent: "center",
  };
  const buttonStyle = {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "15px 20px",
    cursor: "pointer",
    fontSize: "16px",
    transition: "background-color 0.3s",
    width: "50%",
    textDecoration: "none",
    // boxShadow: "2px 5px 5px rgba(39, 158, 255,0.5)",
  };

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
      passwordshow: false,
    },

    onSubmit: async (values) => {

      if (!defaultlogin) {

        // http://localhost:3000/auth/signin/surendirababu.r@yozytech.com/web.devozy.ai/

        let prod = `https://api.devozy.ai/azureauth/signin/${values.login}/web.devozy.ai/`;

        let dev = `http://localhost:5000/azureauth/signin/${values.login}/web.devozy.ai/`;

        if (values.login !== "") {
          window.open(prod, "_self");
        } else {
          setErrors("Please enter email address id");
        }
      } else if (values.login !== "") {
        try {
         
          let updatedValues = { ...values, login: values.login?.toLowerCase() };
          setCheckSubmit(true);
          let r = await main(updatedValues, setCheckSubmit);
          if (r.data) {
            setLoginData(r);
          } else {
            setemailerror(false)
            setErrors(r);
          }
        } catch (e) {
          setErrors(e);
        }
      } else {
        setErrors("Please enter email id and password");
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="row h-100 w-100">
        <div
          className="col-md-6"
          style={{
            background: "rgb(90, 136, 241)",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            className="text-center d-flex justify-content-center align-items-center flex-column"
            style={{
              gap: "40px",
            }}
          >
            <h3 className="text-white mt-4" style={{ fontSize: "1rem" }}>
              {/* Game changing unified Multicloud & Agile DevSecOps platform */}
              World Class 'AI Assisted' Developer Self Service Portal (IDP)
              powered with Platform Engineering Automations
            </h3>
          </div>
          <div className="text-center d-flex justify-content-center align-items-center flex-column mt-3">
            <Box
              component="img"
              sx={{
                width: { xs: "600px", md: "500px" },
              }}
              alt="logo"
              src={svg}
            />
          </div>
          <div
            className="d-flex justify-content-center align-items-center py-2"
          // style={{ height: "100px" }}
          >
            <label className="text-white">
              &copy; Copyright 2024 Yozy Techlabs Pvt Ltd
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <form className="container px-5" onSubmit={formik.handleSubmit}>
            <div className="d-flex flex-column align-items-center justify-content-center justify-content-lg-start">
              <Box
                component="img"
                sx={{
                  width: "290px",
                  height: "60px",
                  marginY: { xs: "10px", sm: "25px" },
                }}
                alt="logo"
                src={logo}
              />

              <h3
                className="font-weight-bold"
                style={{ fontFamily: "DM sans" }}
              >
                Welcome
              </h3>
              <p className="font-weight-bold" style={{ fontFamily: "DM sans" }}>
                Enter your credentials to access your account
              </p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControl
                  sx={{
                    m: 1,
                    width: { lg: "500px", md: "480px", xs: "250px" },
                    borderRadius: "20px",
                  }}

                >

                  {/* <TextField
                    sx={{ borderRadius: "20px", marginY: "13px" }}
                    size="medium"
                    autoComplete="off"
                    name="login"
                    label="Email"
                    value={formik.values.login.toLowerCase()}
                    onChange={formik.handleChange}
                  /> */}
                  <TextField
                    sx={{ borderRadius: "20px", marginY: "13px" }}
                    size="medium"
                    autoComplete="off"
                    name="login"
                    label="Email"
                    value={formik.values.login.toLowerCase()}
                    InputLabelProps={{ shrink: true }} // This will prevent the label from overlapping with the value
                    onChange={formik.handleChange}
                    inputProps={{
                      style: { textTransform: "lowercase" } // Ensure lowercase display
                    }}
                  />
                  {emailerror && (
                    <label style={{ color: "red" }}>
                      Enter your email id to proceed with forgot password
                    </label>
                  )}
                </FormControl>


                {defaultlogin ? (
                  <FormControl
                    sx={{
                      m: 1,
                      width: { lg: "500px", xs: "250px", md: "480px" },
                    }}
                    variant="outlined"
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      component={"b"}
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      // value={formik.values.password}
                      size="medium"
                      // sx={{ borderRadius: "25px" }}
                      name="password"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                ) : (
                  <></>
                )}
              </div>

              <Stack
                display={"flex"}
                justifyContent={"space-between"}
                direction={"row"}
                spacing={30}
                marginY={1}
              >
                <Box>
                  <input
                    className="form-check-input ml-1"
                    type="checkbox"
                    value=""
                    style={{
                      borderRadius: "10px",
                    }}
                    id="form2Example3"
                  />
                  <label className="form-check-label ml-4">Remember me</label>
                </Box>
                <Box>
                  <a
                    href="#!"
                    style={{ color: "blue" }}
                    onClick={() => forgotpassword()}
                  >
                    Forgot password?
                  </a>
                </Box>
              </Stack>

              <label style={{ color: "red" }}>
                {errors !== "" ? errors : null}
              </label>
            </div>

            <div className="text-center text-lg-start mt-1 pt-2">
              <Button
                variant="contained"
                size="large"
                sx={{ width: { md: "500px" }, borderRadius: "20px" }}
                type="submit"
                disabled={checkSubmit}
              >
                {checkSubmit ? (
                  <span
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                    style={{
                      color: "#fff",
                      width: "1.5rem",
                      height: "1.5rem",
                    }}
                  ></span>
                ) : (
                  <>
                    {!defaultlogin
                      ? "Login using Single Sign On (SSO)"
                      : "Login with password"}
                  </>
                )}
              </Button>

              <div className="divider d-flex text-center justify-content-center align-items-center my-2">
                <p className="text-center fw-bold text-center mx-3 mb-0">Or</p>
              </div>

              <Button
                variant="outlined"
                size="large"
                sx={{ width: { md: "500px" }, borderRadius: "20px" }}
                type="button"
                onClick={() => setDefaultLogin((prevstate) => !prevstate)}
              >
                {defaultlogin
                  ? "Login using Single Sign On (SSO)"
                  : "Login with password"}
              </Button>
              <br></br>

              <Box sx={{ marginTop: { md: "5px", lg: "40px" } }}>
                <Typography
                  component={"a"}
                  href="https://devozy.ai/request-demo.html"
                  className="py-1"
                >
                  Not Registered Yet? Click here!
                </Typography>
              </Box>
              {/* <Box sx={{ marginTop: { md: "5px", lg: "30px" } }}>
                <Typography component={"small"}>
                  @2023 Login. All right reserved
                </Typography>
              </Box> */}
            </div>
          </form>
          <div className="d-flex justify-content-center mt-1">
            <lable className="text-muted" style={{ fontSize: "0.75rem" }}>
              If you encounter any problems, please reach out to
              support@yozytech.com.
            </lable>
          </div>
        </div>
      </div>

      <Modal show={sendmail} backdrop="static" size="md" centered>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={containerStyle}>
            <Row className="d-flex align-items-center justify-content-center">
              <img style={{ width: "100px" }} src={maillogo} alt="logo" />
            </Row>

            <Row>
              <Col>
                <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {" "}
                  Password Reset Email Sent
                </p>
                <p>
                  A password reset email has been sent to your registered email
                  address. Please check your inbox and follow the instructions
                  in the email to reset your password.
                </p>
                <Col className="pt-1">
                  <button
                    style={buttonStyle}
                    onClick={() => {
                      setsendmail(false);
                    }}
                  >
                    OK
                  </button>
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>

      {loginData !== "" && loginData != undefined && loginData != null ? (
        <Twoauthverify data={loginData} setUser={setUser} />
      ) : null}
    </div>
  );
}

export default LoginPage;
