import React, { useEffect, useState } from "react";
import {
    Card, CardBody, Col, Row, Container, Button
  } from "reactstrap";
import Managertable from "./requests/ManagerTable";

function MyTicketQueue({ EmpId,
  
}) {
  
  return (
    <Card>
    {/* <h3 className="d-flex justify-content-center">My Ticket Queue</h3> */}

      <div className="mt-5">
        <Managertable />
</div>

    </Card>
  );
}
export default MyTicketQueue;
