import axios from "../../../axios";
import React, { useEffect, useState } from "react";
import { FormHelperText, IconButton } from "@material-ui/core";

import { Form, Button, FormFeedback, Input, Container } from "reactstrap";
import { InputLabel, FormGroup, TextField, Table, Card } from "@material-ui/core";
import Select from "react-select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./ProjectAllocation.css";
import "./Allocationteam.css";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import AllocationTable from "./AllocationTable";
import { toast, Slide } from "react-toastify";
import MaterialTable from "material-table";
import "react-toastify/dist/ReactToastify.css";

import { Row, Col } from "reactstrap";
import { Modal } from "react-bootstrap";
import { Avatar, Button as Button1, Divider } from "@mui/material";


toast.configure();

let managerid = sessionStorage.getItem("Roledetails") == 'undefined' ? "" : JSON.parse(sessionStorage.getItem("Roledetails"));
let id = JSON.parse(localStorage.getItem("clientid"));
const TeamAllocation = ({
  openAllocForm,
  setOpenAllocForm,
  EmpId,
  projectdetails,
  projectnames,
  Firstname,
  Secondname,
  Email,
  Refresh, setallocationopen, allocationopen, handleClose, firstname, secondname
}) => {
  // const Email = "mahendran@gmail.com"
  let clientdetails = JSON.parse(localStorage.getItem("clientdetails"));
  let managerid = JSON.parse(localStorage.getItem("Empdetails"))?.[0]?.ClientUserId

  let initialValue = {
    TeamMemberId: "",
    ProjectCode: "",
    ProjectStartDate: new Date(),
    ProjectEndDate: new Date(),
    ManagerId: managerid,
    AllocPercentage: 100,
    ProjectRole: {
      RoleGroupId: 4,
      RoleCode: "DEV",
      RoleName: "Scrum Team-Developer",
    },
    MappedBy: managerid,
    MappedByDetails: {
      ContactName: clientdetails[0]?.OrgDetails?.ContactName,
      ContactDesignation: clientdetails[0]?.RoleDetails?.RoleCode,
      ContactEmail: clientdetails[0]?.UserDetails?.Email,
      ContactPhone: clientdetails[0]?.OrgDetails?.ContactPhone,
    },
    IsCurrent: "Y",
    Status: null,
    ClientId: id
  };


  const [employee, setEmployee] = useState([]);
  const [test, setTest] = useState(null);
  const [projects, setProjects] = useState(null);
  const [managers, setManagers] = useState(null);
  const [Values, setValues] = useState(initialValue);
  const [errors, setErrors] = useState({});

  const [visible, setVisible] = useState(false);
  const [allocatedEmployee, setAllocatedEmployee] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [deallocateddata, setdealloacteddata] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);



  let arr1 = JSON.parse(sessionStorage.getItem("EmpDetails"));
  let rolecode = JSON.parse(localStorage.getItem("Rolecode"));
  let id = JSON.parse(localStorage.getItem("clientid"));
  console.log(Email, "email")
  let EmpAPI =
    rolecode === "SM"
      ? "rpc/fun_mgrempdetailsreport?managerid=" +
      EmpId +
      "&select=firstname,lastname,empid,isclearancefullydone" //neq. is Not working on this API
      : rolecode === "SM"
        ? "rpc/fun_nplus2empdetailsreport?nplus2empid=" +
        EmpId +
        "&select=firstname,lastname,empid,isclearancefullydone"
        : "/rpc/fun_talentempdetailsreport?select=firstname,lastname,empid,isclearancefullydone";
  useEffect(() => {
    axios
      .get(`subscription_client_users?ClientId=eq.` + id)
      .then((res) => {
        let a = res.data
        let latestuser = [];
        let latestCreatedDate = "";
        for (const project of a) {
          // Compare the current project's creation date to the latest created date
          if (project.DateCreated > latestCreatedDate) {
            // If this project has a later creation date, reset the latest projects array
            latestuser = [project];
            latestCreatedDate = project.DateCreated;
          } else if (project.CreatedDate === latestCreatedDate) {
            // If this project has the same creation date as the latest, add it to the array
            latestuser.push(project);
          }
        }

        // Find the project with the latest "ProjectId" among the latest projects
        let lastUpdatedProject = latestuser.reduce((prev, current) => {
          return prev.ClientUserId > current.ClientUserId ? prev : current;
        });

        let teammemebrid = lastUpdatedProject?.ClientUserId
        // a?.find((e) => e.OfficialEmail === Email)?.ClientUserId
        // console.log(teammemebrid,"teamameme")
        setValues({ ...Values, TeamMemberId: teammemebrid })
        setEmployee(res.data)
      })

      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .get("employee_master")
    //   .then((res) => setTest(res.data))
    //   .catch((err) => {
    //     console.log(err);
    //   });
    axios
      .get("agilesaas_project_master?ClientId=eq." + id)
      .then((res) => setProjects(res.data))
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("agilesaas_roles_master")
      .then((res) => setManagers(res.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);



  // console.log(initialValue,"initialvalue")
  const loadData = (eid) => {
    // console.log(eid, "eid")
    axios
      .get("agilesaas_project_mapping?TeamMemberId=eq." + eid)
      .then((res) => {
        setAllocatedEmployee(res.data.filter((e) => e.IsCurrent == "Y"));
        setSelectedEmployee(res.data);
      })
      .catch((err) => {
        console.log(err, "erorroror111");
      });
    axios
      .get(
        "agilesaas_project_mapping?TeamMemberId=eq." + 1 + "&IsCurrent=eq.Y"
      )
      .then((res) => {
        setdealloacteddata(res.data.filter((e) => e));
        setSelectedEmployee(res.data);
      })
      .catch((err) => {
        console.log(err, "erorroror111");
      });
  };




  const validate = (FieldValue = Values) => {
    // console.log(FieldValue, "FieldValue")
    let temp = { ...errors };
    let temp1 = deallocateddata?.filter(
      (e) => e.TeamMemberId == Values.TeamMemberId
    );

    const rolecheck = temp1?.map((r) => r.ProjectRole?.RoleCode);

    let temp2 = allocatedEmployee?.filter(
      (e) => e.ProjectCode == Values.ProjectCode
    );

    if ("TeamMemberId" in FieldValue) {
      temp.TeamMemberId =
        temp1?.length < 2 || rolecheck == "SM"
          ? ""
          : "Maximum 2 projects should be allocated";
    }
    else if (FieldValue.ProjectCode === '') {
      temp.ProjectCode = "Please select project";
    }
    else if ("ProjectStartDate" in FieldValue) {
      temp.ProjectStartDate = moment(Values.ProjectStartDate) >= moment(Values.ProjectEndDate) === false ? "" : "StartDate should be lower than EndDate";
    }
    else if ("AllocPercentage" in FieldValue) {
      if (temp1?.length == 0) {
        temp.AllocPercentage =
          Values.AllocPercentage < 101 || rolecheck == "SM"
            ? ""
            : "Maximum limit is 100%";
      }
      else if (temp1?.length == 1 || rolecheck == "SM") {
        // temp.AllocPercentage =
        //   temp1[0].AllocPercentage + parseInt(Values.AllocPercentage) < 101
        //     ? ""
        //     : "Exceeded 100 percentage limit";
        let suggestion = 100 - temp1[0].AllocPercentage;
        temp.AllocPercentage = `Please enter ${suggestion}% to reach 100%`;
        if (suggestion == Values.AllocPercentage) {
          temp.AllocPercentage = "";
        }
      }
      // if ("ProjectCode" in FieldValue) {
      //   temp.ProjectCode =
      //     temp2?.length < 1 ? "" : alert("Already this project is allocated to this person");
      // }
    }
    setErrors({
      ...temp,
    });
    if (FieldValue === Values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const resetForm = () => {
    setValues(initialValue);
    setErrors({});
  };

  const DateInputChange = (e, vaildateOnChange = false) => {
    setValues({
      ...Values,
      [e.target.name]: moment(e.target.value).format("YYYY-MM-DD"),
    });
  };

  const convertToPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  const handleInputChange1 = (e, name, vaildateOnChange = false) => {
    // console.log(Values, "valueshandle", e, name)

    setValues({
      ...Values,
      [name]: e.value,
    });
    if (name === "TeamMemberId") {
      setVisible(true);
      loadData(e.value);
    }
    if (e.target.name === "ProjectCode") {
      validate();
    }
    if (e.target.name === "AllocPercentage") {
      validate();
    }

    if (vaildateOnChange) validate({ [name]: e.value });
  };
  // const handleroledata = (e) => {
  //   let data = [...Values];
  //   data.push({
  //     RoleGroupId: e.value,
  //     RoleCode: e.RoleCode,
  //     RoleName: e.label,
  //   });
  //   // setrole(data);
  // };
  const handleInputChange = (e, vaildateOnChange = false) => {
    setValues({
      ...Values,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "TeamMemberId") {
      setVisible(true);

      loadData(e.target.value);
    }
    // if (e.target.name === "ProjectCode") {
    //   validate();
    // }
    // if (e.target.name === "AllocPercentage") {
    //   validate();
    // }

    // if (vaildateOnChange) validate({ [e.target.name]: e.target.value });
  };
  //   const managerdata = managers?.map((e) => e.RoleName);

  let managerdropdown = managers?.filter((e) => e.RoleCode != "BO" && e.RoleCode != "PO")

  // console.log(managerdropdown,"managerdropdown")
  //   const managerdrop = managerdata?.map((man) => {
  //     return {
  //       value: man.RoleGroupId,
  //       label: man.RoleName,
  //       // RoleCode: man.RoleName.slice(0, 3),
  //     };

  //     // <option key={man.empid} value={man.empid}>
  //     //   {man.firstname + " " + man.lastname}
  //     // </option>
  //   });

  // console.log(managerdata, "managerdata");
  // console.log("tettxtt", managerdata);

  const addAllocationHandler = (e) => {

    // console.log(Values, "projectvalue122")

    e.preventDefault();
    if (validate()) {
      // if (
      //   selectedEmployee.find(
      //     (selected) => selected.ProjectCode == Values.ProjectCode
      //   )
      // ) 
      {
        // console.log(Values, "projectvalue")
        axios
          .put(
            "agilesaas_project_mapping?TeamMemberId=eq." +
            Values.TeamMemberId +
            "&ProjectCode=eq." +
            Values.ProjectCode,
            {
              TeamMemberId: Values.TeamMemberId,
              ProjectCode: Values.ProjectCode,
              AllocPercentage: Values.AllocPercentage,
              ProjectEndDate: Values.ProjectEndDate,
              IsCurrent: "Y",
            }
          )
          .then((res) => {
            toast.success(`Project Allocated`, {
              transition: Slide,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              draggable: true,
            });

            //     // console.log(Values.EmpId,"ii")
            //     // loadData(teammemebrid);
            //     // setVisible(true)
            setValues(initialValue);

            resetForm();
            Refresh();
            handleClose();
            setallocationopen(false);
            allocationopen(false);
          });
        // // } else {

        axios.post("agilesaas_project_mapping", Values).then((res) => {
          toast.success(`Project Allocated`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          });

          loadData(Values.TeamMemberId)
          setVisible(true);
          setValues(initialValue);
          resetForm();
          Refresh();
          handleClose();
          //   // setallocationopen(false);
          //   // allocationopen(false);
        });
      }
    }
  };
  const teamname = employee?.filter((e) => e.OfficialEmail === Email)?.map((emp) => {
    return {
      value: emp.ClientUserId,
      label: emp.UserFirstname + " " + emp.UserLastname,
    }
  })
  console.log(teamname, "teamname")
  const chek = projects?.map((pro) => {
    return {
      value: pro.ProjectId,
      label: pro.ProjectName,
    };
  })



  const closeIconHandler = () => {
    setOpenAllocForm(false);
    setVisible(false);
    resetForm();
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FFF',
      border: state.isFocused ? '2px solid rgb(82, 80, 229)' : '1px solid rgba(0, 0, 0, 0.10)',
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };
  // console.log(errors, 'error')
  return (
    <>
      <Form onSubmit={addAllocationHandler}>
        <Modal.Header closeButton >
          <Modal.Title className="TitlelabelStyle">Project Allocate</Modal.Title>
        </Modal.Header>
        <Modal.Body className="KanbanModal">
          <Row className="mb-3">
            <Col>
              <label className="SprintlabelStyle">
                Name{" "}
              </label>
              <Input className="SprintFormFields"
                value={teamname?.map((option) => option.label)}
              // onChange={(e) => handlename(e)}
              // type="text"
              // invalid={errors.firstname !== undefined}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <label className="SprintlabelStyle">
                Project{" "}
              </label>
              <Select
                // className="mytextteam"
                style={customStyles}
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"

                // variant="outlined"
                // style={{ width: "100%" }}
                // className="mytextteam"
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                name="ProjectCode"
                maxMenuHeight={110}
                // value={Values?.ProjectCode}

                options={
                  projects == null
                    ? ""
                    : projects?.map((pro) => {
                      return {
                        value: pro.ProjectId,
                        label: pro.ProjectName,
                      };
                      // <option
                      //   key={pro.ProjectCode}
                      //   value={pro.ProjectCode}
                      // >
                      //   {pro.ProjectName}
                      // </option>
                    })
                }
                onChange={(e) => setValues({ ...Values, ProjectCode: e.value })}
                error={errors.ProjectCode}
                helperText={errors.ProjectCode}
                required
                native
              />
              {errors.ProjectCode && <span className="text-danger">{errors.ProjectCode}</span>}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <label className="SprintlabelStyle">
                Role{" "}
              </label>
              <Select
                // className="mytextteam"
                style={customStyles}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"

                variant="outlined"
                // style={{ width: "100%" }}
                // className="mytextteam"
                // labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
                name="ProjectCode"
                maxMenuHeight={110}
                // value={Values?.ProjectCode}
                defaultValue={{ label: "Scrum Team-Developer", value: 4 }}
                options={managerdropdown?.map((man) => {
                  return {
                    value: man.RoleGroupId,
                    label: man.RoleName,
                    RoleCode: man.RoleCode,
                  };

                })}
                onChange={(e) => {
                  setValues({
                    ...Values,
                    ProjectRole: {
                      RoleName: e.label,
                      RoleGroupId: e.value,
                      RoleCode: e.RoleCode,

                    },
                  });
                }}
                error={errors.ProjectManager}
                helperText={errors.ProjectManager}
                required
                native
              />
              {errors.ProjectManager && <span className="text-danger">{errors.ProjectManager}</span>}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <label className="SprintlabelStyle">
                Start Date{" "}
              </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // format="dd/MM/yyyy"
                  margin="inline"
                  name="project start date"
                  className=""
                  style={{ width: "100%" }}
                  autoComplete="off"
                  // shouldDisableDate={disableWeekends}
                  required
                  disablePast
                  varient="dialog"
                  format="dd/MM/yyyy"
                  // margin="normal"
                  views={["year", "month", "date"]}
                  // label="Allocation Start Date"
                  // className="select MuiFormControl-marginNormal"
                  value={Values?.ProjectStartDate}
                  error={errors.ProjectStartDate}
                  onChange={(date) =>
                    DateInputChange(convertToPara("ProjectStartDate", date))
                  }
                  helperText={errors.ProjectStartDate}
                />
              </MuiPickersUtilsProvider>
              {errors.ProjectStartDate && <>{errors.ProjectStartDate}</>}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <label className="SprintlabelStyle">
                End Date{" "}
              </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ width: "100%" }}
                  disablePast
                  varient="dialog"
                  format="dd/MM/yyyy"
                  name="project start date"
                  margin="inline"
                  // margin="normal"
                  views={["year", "month", "date"]}
                  // label="Allocation Start Date"
                  // className="select MuiFormControl-marginNormal"
                  value={Values?.ProjectEndDate}
                  error={errors.ProjectStartDate}
                  onChange={(date) =>
                    DateInputChange(convertToPara("ProjectEndDate", date))
                  }
                />
              </MuiPickersUtilsProvider>
              {errors.ProjectStartDate && <FormFeedback>{errors.ProjectStartDate}</FormFeedback>}
            </Col>
          </Row>


        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
            <Button1
              style={{ fontSize: 14, padding: 9, width: 100, height: 40, borderRadius: 8, marginRight: 14, border: '2px solid #5250E5', backgroundColor: '#fff', color: '#5250E5', textAlign: 'center', textTransform: 'none' }}
            >Cancel</Button1>
            <Button1
              type="submit"
              style={{ fontSize: 14, padding: 9, width: 100, height: 40, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center', textTransform: 'none' }}
            >
              Allocate 100%</Button1>
          </div>

        </Modal.Footer>
      </Form>

      {/* <Table
        responsive
        striped
        bordered
        hover
        className="position-relative"
        style={{ marginBottom: "50px" }}
      > */}
      {/* <thead id="report">
          <tr>
            <th className="text ">Name</th>
            <th className="text ">Projects</th>
            <th className="text">Role</th>
            <th className="text">Start Date</th>
            <th className="text">End Date</th>
          </tr>
        </thead> */}

      {/* <tr style={{ marginBottom: "50px", }}>
          <td className="textdown">
            <span className="textteam mr-5 ">
              {teamname?.map((option) => option.label)}
            </span>
            
          </td>
          <td className="textdown">
            <Select

              variant="outlined"
              className="mytextteam"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="ProjectCode"
              maxMenuHeight={110}
              // value={Values?.ProjectCode}

              options={
                projects == null
                  ? ""
                  : projects?.map((pro) => {
                    return {
                      value: pro.ProjectId,
                      label: pro.ProjectName,
                    };
                    // <option
                    //   key={pro.ProjectCode}
                    //   value={pro.ProjectCode}
                    // >
                    //   {pro.ProjectName}
                    // </option>
                  })
              }
              onChange={(e) => setValues({ ...Values, ProjectCode: e.value })}
              error={errors.ProjectId}
              helperText={errors.ProjectId}
              required
              native
            />
          </td>

          <td>
            <Select
              className="mytextteam"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="RoleGroupId"
              maxMenuHeight={110}
              defaultValue={{ label: "Scrum Team-Developer", value: 4 }}
              // value={Values?.ProjectRole}
              options={managerdropdown?.map((man) => {
                return {
                  value: man.RoleGroupId,
                  label: man.RoleName,
                  RoleCode: man.RoleCode,
                };
                // <option key={man.empid} value={man.empid}>
                //   {man.firstname + " " + man.lastname}
                // </option>
              })}
              onChange={(e) => {
                setValues({
                  ...Values,
                  ProjectRole: {
                    RoleName: e.label,
                    RoleGroupId: e.value,
                    RoleCode: e.RoleCode,

                  },
                });
              }}
              error={errors.ProjectManager}
              helperText={errors.ProjectManager}
              required
              native
            />
          </td>

          <td>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker

                // format="dd/MM/yyyy"
                margin="inline"
                name="project start date"




                className=""
                style={{ width: "100%" }}
                autoComplete="off"
                // shouldDisableDate={disableWeekends}
                required
                disablePast
                varient="dialog"
                format="dd/MM/yyyy"
                // margin="normal"
                views={["year", "month", "date"]}
                // label="Allocation Start Date"
                // className="select MuiFormControl-marginNormal"
                value={Values?.ProjectStartDate}
                error={errors.ProjectStartDate}
                onChange={(date) =>
                  DateInputChange(convertToPara("ProjectStartDate", date))
                }
              />
            </MuiPickersUtilsProvider>
          </td>

          <td>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: "170px" }}
                disablePast
                varient="dialog"
                className="mytextteam"
                format="dd/MM/yyyy"
                // margin="normal"
                views={["year", "month", "date"]}
                // label="Allocation Start Date"
                // className="select MuiFormControl-marginNormal"
                value={Values?.ProjectEndDate}
                error={errors.ProjectStartDate}
                onChange={(date) =>
                  DateInputChange(convertToPara("ProjectEndDate", date))
                }
              />
            </MuiPickersUtilsProvider>
          </td>



        </tr> */}





      {/* </Table> */}
      {/* <td>


        <Button
          className="Card_Button_Color_Approve btn-greeng  justify-content-center"

          onClick={addAllocationHandler}
          style={{ marginLeft: "450px", position: "relative" }}
        >
          Allocate 100%
        </Button>
      </td> */}



      <br></br>


      {/* <Button
              className="Card_Button_Color_Approve btn-danger ml-1"
              // onClick={handleallocation}
              style={{position:"relative"}}
            >
              Cancel
            </Button> */}
    </>
  );
};

export default TeamAllocation;
