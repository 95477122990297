import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, CardBody, Col, Card, Row } from "reactstrap";
import Avatar from "@mui/material/Avatar";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";
import Toolbar, { modules, formats } from "../../KanbanBoard/Toolbar";

import { Slide, toast } from "react-toastify";
import moment from "moment";
import Select from "react-select";
import Imageviewer from "../../KanbanBoard/ProgressUpdate/Imageviewer";
import axios from "../../../axios";
import PatchedPagination from "../../../PatchedPagination";
import Skeleton from "@mui/material/Skeleton";
import "./change.css";
import ChangeModule from "./Changemodule";
import MaterialTable from "material-table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EditIcon from '@mui/icons-material/Edit';

export default function Detailspagechange({
  changedetails,
  refresh,
  refreshstates,
  Assignedtooption,
  setAssignedtooption,
  NotesLoading,
  setNotesLoading,

  openc,
  setOpenc,
  setopendetails

}) {
  const clientid = JSON.parse(localStorage.getItem("clientid"));
  const rolecode = JSON.parse(localStorage.getItem("Rolecode"));
  let arr = JSON.parse(localStorage.getItem("Empdetails"));

  const customStylesd = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 8,
      fontSize: 12,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  let stateoptions = [
    { value: "New", label: "New" },
    { value: "Approval", label: "Approval" },
    { value: "Scheduled", label: "Scheduled" },
    { value: "Implementation", label: "Implementation" },
    { value: "Completed", label: "Completed" },
    { value: "Closed", label: "Closed" },
    { value: "Cancelled", label: "Cancelled" },
  ];
  const lineStyle = {
    borderTop: "1px solid rgb(0, 0, 0 ,20%) ", // Customize the color and style
    margin: "7px 0", // Add margin above and below the line
  };
  const mainlineStyle = {
    borderTop: "1px solid rgb(0, 0, 0 ,20%) ", // Customize the color and style
    margin: "7px 0", // Add margin above and below the line
    width: "90%", // Set the width of the line
    margin: "0 auto",
  };
  const Columns = [
    { title: "S.No", field: "Sno", defaultSort: "desc" },
    { title: "Title", field: "title" },
    { title: "Refnumber", field: "refnumber" },

    { title: "Assignment group", field: "Assignedgroup" },

    { title: "Category", field: "CategoryCode" },
    { title: "SubCategory", field: "SubCategoryCode" },

    { title: "Impact", field: "Impact" },
    { title: "State", field: "CurrentState1" },
  ];
  const LoadingOption = [{ label: "Loading...", value: "...Loading" }];



  const [Formopen, setFormopen] = useState(false);
  const [imageviewopen, setImageviewopen] = useState(false);
  const [open, setOpen] = useState(false);
  const [imagedata, setImagedata] = useState();
  const [PatchData, setPatchData] = useState([]);
  const [Commonts, setCommonts] = useState("");
  const [SerialNo, setSerialNo] = useState("");
  const [statechange, setstatechange] = useState("");
  const [assignmentoption, setassignmentoption] = useState([]);
  const [AssignedTo, setAssigntoOption] = useState([]);
  const [Edit, setEdit] = useState(false);
  const cellStyle = {
    width: "50%", // Set equal width for both headers and cells
    border: "1px solid #ddd", // Add borders for better visualization
    padding: "8px", // Add padding for better spacing
    textAlign: "left", // Adjust text alignment as needed
  };
  const { AssignedGroupOption, allImage, userImage, IncidentId, statusoption, PriorityOption } = useSelector((state) => state.user)


  // Functions
  useEffect(() => {
    axios
      .get("subscription_user_group_mapping?ClientId=eq." + clientid)
      .then((response) => {
        let userGroupMappings = response.data;
        const resultArray = userGroupMappings.reduce(
          (acc, userGroupMapping) => {
            userGroupMapping.GroupDetails.forEach((groupDetail) => {
              const groupId = groupDetail.GroupId;
              const groupName = groupDetail.GroupName;

              // Check if the group already exists in the result array
              const existingGroupIndex = acc.findIndex(
                (group) => group.GroupId === groupId
              );

              if (existingGroupIndex !== -1) {
                // Add the user to the existing group
                acc[existingGroupIndex].users.push({
                  Firstname: userGroupMapping.UserDetails.Firstname,
                  Lastname: userGroupMapping.UserDetails.Lastname,
                  UserId: userGroupMapping.UserId,
                });
              } else {
                // Create a new group with the user
                acc.push({
                  GroupId: groupId,
                  GroupName: groupName,
                  users: [
                    {
                      Firstname: userGroupMapping.UserDetails.Firstname,
                      Lastname: userGroupMapping.UserDetails.Lastname,
                      UserId: userGroupMapping.UserId,
                    },
                  ],
                });
              }
            });

            return acc;
          },
          []
        );
        const assignmentooption = resultArray.map((e) => {
          return {
            label: e.GroupName,
            value: e.GroupId,
            users: e.users,
          };
        });
        setassignmentoption(assignmentooption);
      })
      .catch((error) => console.log(error));

  }, [])
  function timeAgo(dateString) {
    const currentDate = new Date();
    const givenDate = new Date(dateString);

    const timeDifference = currentDate - givenDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);


    if (seconds < 60) {
      return seconds + " sec ago";
    } else if (minutes < 60) {
      return minutes + " min ago";
    } else if (hours < 24) {
      return hours + " hours ago";
    } else if (days < 30) {
      return days + " days ago";
    } else if (months < 12) {
      return months + " months ago";
    } else {
      return years + " years ago";
    }
  }

  const modifyImageTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const images = tempDiv.querySelectorAll("img");
    images.forEach((image) => {
      image.style.width = "20%"; // Set the width to your desired value
      image.style.height = "20%"; // Set the height to your desired value
    });

    return tempDiv.innerHTML;
  };
  function handleClickOpen(e) {
    setImageviewopen(
      e?.match(/<img .*?>/g) == null || e?.match(/<img .*?>/g).length == 0
        ? false
        : true
    );
    setOpen(true);
    setImagedata(e);
  }
  const handlecomment = (props) => {

    setCommonts(props);

    let arr = JSON.parse(localStorage.getItem("Empdetails"));
    // console.log(changedetails[0]?.ChangeNotes,"changenoted")
    // const updatedNotes = [...changedetails[0]?.ChangeNotes];
    const updatedNotes = Array.isArray(changedetails[0]?.ChangeNotes) ? [...changedetails[0]?.ChangeNotes] : [];

    // const updatedNotes = [...(changedetails[0].ChangeNotes || [])];


    if (SerialNo === "") {
      const PushData = {
        NoteSno: updatedNotes.length + 1,
        DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        NoteUpdatedBy: arr[0].ClientUserId,
        NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
        NotesDetails: props,
      };
      updatedNotes.push(PushData);
      setPatchData(updatedNotes);
    } else {
      const index = updatedNotes.findIndex((note) => note.NoteSno === SerialNo);
      updatedNotes[index].NotesDetails = props;
      updatedNotes[index].DateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      setPatchData(updatedNotes);
    }
  };
  const handlesubmit = () => {
    if (PatchData.length > 0 && Commonts !== "") {
      axios
        .patch("agilesaas_change_details?ChangeId=eq." + changedetails[0]?.ChangeId, {
          ChangeNotes: PatchData,
        })
        .then((e) => {
          alert("Notes Updated Successfully");
          handlecancel();
        })
        .catch((E) => console.log(E));
    }
  };
  const handleRemoveNote = (noteSno) => {
    // Filter out the note with the specified NoteSno
    const updatedNotes = [...changedetails[0]?.ChangeNotes];
    const filteredNotes = updatedNotes.filter((note) => note.NoteSno !== noteSno);
    // Set the filtered array to the state
    axios
      .patch("agilesaas_change_details?ChangeId=eq." + changedetails[0]?.ChangeId, {
        "ChangeNotes": filteredNotes
      }).then((e) => {
        alert("Note Deleted Successfully");
        handlecancel()
      }).catch((E) => console.log(E))
  };
  const handledropdown = (f, changedetails) => {

    let states = f.value;

    setstatechange(states);

    if (states == " Scheduled") {
      let patchdatapro = {
        ChangeStages: {
          StateCode: states,
          StateName: states,
        },
        ChangeStartTime: moment().format("DD-MM-YYYY"),
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + changedetails[0]?.ChangeId,
          patchdatapro
        )
        .then((response) => {
          console.log("stage1");
          refresh()
          refreshstates()
          // setRefresh(true);
        })
        .catch((error) => console.log(error));
    } else if (states == " Completed") {
      let patchdatapro1 = {
        ChangeStages: {
          StateCode: states,
          StateName: states,
        },
        ChangeEndTime: moment().format("DD-MM-YYYY"),
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + changedetails[0]?.ChangeId,
          patchdatapro1
        )
        .then((response) => {
          console.log("Stages2");
          // setRefresh(true);
          refresh()
          refreshstates()
        })
        .catch((error) => console.log(error));
    } else {
      let patchdatapro2 = {
        ChangeStages: {
          StateCode: states,
          StateName: states,
        },
      };
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + changedetails[0].ChangeId,
          patchdatapro2
        )
        .then((response) => {
          console.log("Stages3");
          // setRefresh(true);
          refresh()
          refreshstates()
        })
        .catch((error) => console.log(error));
    }
  };
  const handlepriority = (f, changedetails) => {

    let pvalue = f.value;
    let codevalue = f.code;




    let patchdatapri = {
      "Priority": {
        "label": pvalue,
        "code": codevalue
      }
    };
    axios
      .patch(
        "agilesaas_change_details?ChangeId=eq." + changedetails[0]?.ChangeId,
        patchdatapri
      )
      .then((response) => {
        console.log("stage1");
        refresh()
        refreshstates()
        // setRefresh(true);
      })
      .catch((error) => console.log(error));

  };
  const handleassignmentgrp = (f, changedetails) => {
    let e = assignmentoption.find((x) => x.value == f.value);


    setAssigntoOption(
      e.users?.map((user) => ({
        value: user.UserId,
        label: user.Firstname + " " + user.Lastname,
      })) || []
    );

    let avalue = f.value;
    let acode = f.label;




    let patchdata1pro = {
      "AssignmentGroup": {
        "GroupCode": avalue,
        "GroupName": acode
      },
    };
    axios
      .patch(
        "agilesaas_change_details?ChangeId=eq." + changedetails[0]?.ChangeId,
        patchdata1pro
      )
      .then((response) => {
        console.log("stage1");
        refresh()
        refreshstates()
        // setRefresh(true);
      })
      .catch((error) => console.log(error));

  };
  const handleassignmentto = (f, changedetails) => {


    let asvalue = f.value;
    let ascode = f.label;

    setAssignedtooption(
      f.users?.map((e) => {
        return {
          value: e.UserId,
          label: e.Firstname + " " + e.Lastname,
        };
      })
    );


    let patchdata2pro = {
      "AssignedTo": {
        "Name": ascode,
        "id": asvalue
      },
    };
    axios
      .patch(
        "agilesaas_change_details?ChangeId=eq." + changedetails[0]?.ChangeId,
        patchdata2pro
      )
      .then((response) => {
        console.log("stage1");
        refresh()
        refreshstates()
        // setRefresh(true);
      })
      .catch((error) => console.log(error));

  };
  const handleEditClick = () => {
    setopendetails(false)
    // Your logic for handling the click event goes here
    setOpenc(true)
    setEdit(true)

  };
  function handleapprove() {
    console.log(changedetails, "eeee")
    const lastUpdateNote = changedetails[0]?.ChangeNotes[changedetails[0]?.ChangeNotes.length - 1];
    // const updatedNotes = Array.isArray(changedetails[0]?.ChangeNotes) ? [...changedetails[0]?.ChangeNotes] : [];
    let patchapprove = {
      "IsApproved": "Y",
      "ChangeStages": {
        "StateCode": "Scheduled",
        "StateName": "Scheduled  "
      },
      "ApprovalDetails": {
        "ApprovedbyFN": arr[0].UserFirstname,
        "ApprovedbyLN": arr[0].UserLastname
      },

      "ChangeNotes": [
        ...changedetails[0]?.ChangeNotes,
        {
          "NoteSno": lastUpdateNote.NoteSno + 1,
          "DateTime": moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: arr[0].ClientUserId,
          NoteUpdatedByFN: arr[0].UserFirstname + " " + arr[0].UserLastname,
          "NotesDetails": "Approved and scheduled by Queue Owner"
        },
      ]



    }
    let waitapprove = {
      "IsApproved": "N",


    }
    {
      rolecode == "SM" ?
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + changedetails[0]?.ChangeId,
          patchapprove
        )
        .then((response) => {

          console.log("Approve");
          toast.success(`Approved`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          })
          refresh()
          refreshstates()
          setopendetails(false)

          // refresh()
          // setRefresh(true);
        })
        .catch((error) => console.log(error))

      :
      axios
        .patch(
          "agilesaas_change_details?ChangeId=eq." + changedetails[0]?.ChangeId,
          waitapprove
        )
        .then((response) => {
          console.log("Approve");
          refresh()
          refreshstates()
          toast.warning(`Request for Approval`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          })
          refresh()
          refreshstates()
          setopendetails(false)
          // refresh()
          // setRefresh(true);
        })
        .catch((error) => console.log(error))
    }




  }
  let CurrentState =
    changedetails[0]?.ChangeStages?.StateCode == "New" ? (
      <span className="badge mt-2 NewBadgeInc" style={{ fontWeight: "bold" }}>
        New
      </span>
    ) : changedetails[0]?.ChangeStages?.StateCode == "Approval" ? (
      <span className="badge mt-2 AckBadgeInc" style={{ fontWeight: "bold" }}>
        Approved
      </span>
    ) : changedetails[0]?.ChangeStages?.StateCode == "Scheduled" ? (
      <>
        <span
          className="badge mt-2 WorkBadgeInc"
          style={{ fontWeight: "bold" }}
        >
          Scheduled
        </span>
      </>
    ) : changedetails[0]?.ChangeStages?.StateCode == "Implementation" ? (
      <span className="badge mt-2 UserBadgeInc" style={{ fontWeight: "bold" }}>
        Implementation
      </span>
    ) : changedetails[0]?.ChangeStages?.StateCode == "Completed" ? (
      <span
        className="badge mt-2 ResolveBadgeInc"
        style={{ fontWeight: "bold" }}
      >
        Completed
      </span>
    ) : changedetails[0]?.ChangeStages?.StateCode == "Closed" ? (
      <span
        className="badge mt-2 ResolveBadgeInc"
        style={{ fontWeight: "bold" }}
      >
        Closed
      </span>
    ) : changedetails[0]?.ChangeStages?.StateCode == "Cancelled" ? (
      <span
        className="badge mt-2 ResolveBadgeInc"
        style={{ fontWeight: "bold" }}
      >
        Closed
      </span>
    ) : (
      ""
    );
  // Components
  const Header = () => (
    <div className="container">
      <div className="header d-flex">
        <h3 style={{ fontWeight: "600" }}>
          <span className="mdi mdi-email-outline mr-1"></span>
          {changedetails[0]?.ShortDescription}
        </h3>
        <span
          className="badge "
          style={{
            fontWeight: "bold",
            color: "#808080",
            background: "transparent",
          }}
        >
          {CurrentState}
        </span>


        <div className="ml-auto">
          {changedetails[0]?.ChangeStages?.StateCode == "New" ? <div onClick={handleEditClick}>
            <EditIcon />
          </div> : null}
        </div>

      </div>
      <div className="mt-1">
        <p></p>
        <div
          onClick={() => handleClickOpen(changedetails[0]?.LongDescription)}
          className="mt-1"
          style={{ color: "black", fontWeight: "500" }}
          dangerouslySetInnerHTML={{
            __html: changedetails[0]?.LongDescription
              ? modifyImageTags(changedetails[0]?.LongDescription)
              : "",
          }}
        ></div>
      </div>
      {rolecode == "SM" && changedetails[0]?.ChangeStages?.StateCode === "New" && changedetails[0]?.IsApproved == "N" ? (
        <>
          <div>
            {/* <input
      type="text"
      // value={inputValue}
      defaultValue={"Ok Approve"}
      // onChange={handleInputChange}
      style={{
        width: '100px', // Adjust the width as needed
        height: '30px', // Adjust the height as needed
        fontSize: '14px', // Adjust the font size as needed
        outline: '1px solid black', // Add outline style
        // Add any additional styling you need
      }}
    />
    <br></br> */}

            <Button
              style={{
                fontSize: 12,
                padding: 9,
                width: 120,
                height: 28,
                borderRadius: 5,
                backgroundColor: "#198ae3",
                color: "#fff",
                border: "0px solid #198ae3",
                textAlign: "center",
                textTransform: "none",
                top: "3px"
                // marginLeft:200,
                // marginRight: 24,
              }}
              onClick={handleapprove}
            >
              Approve
            </Button>
          </div>
        </>
      ) : changedetails[0]?.IsApproved == "Y" ? null :
        <Button
          style={{
            fontSize: 12,
            padding: 9,
            width: 150,
            height: 28,
            borderRadius: 5,
            backgroundColor: "#198ae3",
            color: "#fff",
            border: "0px solid #198ae3",
            textAlign: "center",
            textTransform: "none",
            top: "3px"
            // marginLeft:200,
            // marginRight: 24,
          }}
          onClick={handleapprove}
        >
          Request For Approval
        </Button>
      }
      <div>
        <Tabs
          defaultActiveKey="implementationplan"
          id="justify-tab-example"
          className="horizontal-tabs mt-3 "
          variant="tabs"
        // style={{ marginLeft: "-27px" }}
        >
          <Tab
            eventKey="implementationplan"
            title="Implementation Plan "
            className="headings"
            defaultActiveKey="implementationplan"
          // style={{ marginLeft: "-27px" }}
          >
            <div className="ml-4">
              <Row className="mt-1 ml-2 ">
                <div style={{ width: "70%" }}>
                  <label className="labelStyle lla">
                    Implementation Steps{" "}
                    {/* <label className="text-danger"> *</label>{" "} */}
                  </label>

                  <br />

                  {changedetails[0]?.ExecutionSteps.ExecutionSteps}
                </div>
              </Row>
              <Row className="mt-1 ml-2 ">
                <div>
                  <label className="labelStyle lla">
                    Risk details impact{" "}
                    {/* <label className="text-danger"> *</label>{" "} */}
                  </label>

                  <br></br>
                  {changedetails[0]?.ExecutionSteps.Riskdetails}
                </div>
              </Row>
              <Row className="mt-1 ml-2">
                <label>

                  <label className=" labelStyle  "> Is Certified - </label> {changedetails[0]?.IsCertified == "N"
                    ? "No"
                    : "Yes"}
                </label>
              </Row>

              <Row className="mt-1  ml-2">
                {changedetails[0]?.IsCertified == "Y" ? (
                  <div>
                    <label className="labelStyle lla">
                      Certification details{" "}
                      {/* <label className="text-danger"> *</label>{" "} */}
                    </label>

                    <br></br>
                    {changedetails[0]?.CertificationDetails.details}

                  </div>
                ) : null}
              </Row>
              <Row className="mt-1 ml-2">
                {changedetails[0]?.IsCertified == "Y" ? (
                  <div>
                    <label className="labelStyle lla">
                      Post change testing plan{" "}
                      {/* <label className="text-danger"> *</label>{" "} */}
                    </label>

                    <br></br>
                    {
                      changedetails[0]?.PostImplementationTesting.PostImplementationTesting
                    }

                  </div>
                ) : null}
              </Row>
            </div>
          </Tab>
          <Tab
            eventKey="RollbackPlan"
            title="Roll Back Plan "
            className="headings ml-2"
          // style={{ marginLeft: "-27px" }}
          >
            <div className="ml-4">
              <Row className="mt-1 ">
                <div>
                  <label className="labelStyle lla">
                    Rollback Steps{" "}
                    {/* <label className="text-danger"> *</label>{" "} */}
                  </label>

                  <br></br>
                  {changedetails[0]?.RollbackSteps.Rollbacksteps}

                </div>
              </Row>
              <Row className="mt-1">
                <label>
                  {/* <input
                            type="checkbox"
                            value="Y"
                            className="ml-2 mt-2"
                            style={{ transform: "scale(1.2)" }}
                            checked={rollbackcheck === "Y"}
                            onChange={handlerollbacktested}
                          /> */}
                  <label className=" labelStyle ">Is Rollback tested - </label>   {changedetails?.IsRolledBack == "N"
                    ? "Not Rollbacked"
                    : "Rollbacked"}
                </label>
              </Row>
              <Row className="mt-1  ">
                {changedetails?.IsRolledBack == "Y" ? (
                  <div>
                    <label className="labelStyle lla">
                      Rollback testing details
                      {/* <label className="text-danger"> *</label>{" "} */}
                    </label>

                    <br></br>
                    {changedetails[0]?.RollbackSteps?.Rollbacktestingdetails}

                  </div>
                ) : null}
              </Row>

              <Row className="mt-1  ">
                {changedetails?.IsRolledBack == "Y" ? (
                  <div>
                    <label className="labelStyle lla">
                      Post rollback testing plan{" "}
                      {/* <label className="text-danger"> *</label>{" "} */}
                    </label>

                    <br></br>
                    {changedetails[0]?.RollbackSteps?.postrollbacktesting}

                  </div>
                ) : null}
              </Row>
              <Row className="mt-1  ">
                {changedetails?.IsRolledBack == "Y" ? (
                  <div>
                    <label className="labelStyle lla">
                      Rollback in hours{" "}
                      {/* <label className="text-danger"> *</label>{" "} */}
                    </label>

                    <br></br>
                    {changedetails[0]?.RollbackSteps?.rollbackinhours?.label}
                  </div>
                ) : null}
              </Row>
            </div>
          </Tab>
          <Tab
            eventKey="schedule"
            title="Schedule "
            className="headings ml-2"
          // style={{ marginLeft: "-27px" }}
          >
            <div className="ml-4">
              <Row className="mt-1">
                <div style={{ width: "85%" }}>
                  <label className="labelStyle lla">
                    Start Date & Time{" "}
                    {/* <label className="text-danger"> *</label>{" "} */}
                  </label>
                  <br></br>
                  {moment(changedetails[0]?.Schedule?.StartdateandTime).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                </div>
              </Row>

              <Row className="mt-1 ">
                <div style={{ width: "85%" }}>
                  <label className="labelStyle lla">
                    End Date & Time
                    {/* <label className="text-danger"> *</label>{" "} */}
                  </label>

                  <br></br>
                  {moment(changedetails[0]?.Schedule?.EnddateandTime).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                </div>
              </Row>
              <Row className="mt-1">
                <label>
                  {/* <input
                            type="checkbox"
                            value="Y"
                            className="ml-2 mt-2"
                            style={{ transform: "scale(1.2)" }}
                            checked={ifrollbackcheck === "Y"}
                            onChange={handleifrollbacktested}
                          /> */}
                  <label className=" labelStyle  ">If Rollback - </label>  {changedetails[0]?.Schedule?.IsRolledBack == "N"
                    ? "Not Rollbacked"
                    : "Rollbacked"}
                </label>
              </Row>

              <Row className="mt-1">
                {changedetails[0]?.Schedule?.IsRolledBack == "Y" ? (
                  <div style={{ width: "85%" }}>
                    <label className="labelStyle lla">
                      End Date & time{" "}
                      {/* <label className="text-danger"> *</label>{" "} */}
                    </label>

                    <br></br>
                    {moment(changedetails[0]?.Schedule?.RollbackEndTime).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                InputProps={{
                                  style: {
                                    outlineColor: "red",
                                    width: "100%",
                                    height: "60px",
                                    border: "1px solid #ced4da", // Add border for outline
                                    borderRadius: "4px",
                                  },
                                }}
                                classes={{
                                  root: classes.datePickerInput, // Apply the custom styles to the root element
                                }}
                                className="datepickerDesign"
                                value={selectedDate2}
                                onChange={(date) => handleDateChange2(date)}
                                format="dd-MM-yyyy HH:mm"
                                views={[
                                  "year",
                                  "month",
                                  "date",
                                  "hours",
                                  "minutes",
                                ]}
                                // shouldDisableDate={disableDatesBefore}
                                disablePast
                                required
                              />
                            </MuiPickersUtilsProvider> */}
                  </div>
                ) : null}
              </Row>
            </div>
          </Tab>
          <Tab
            eventKey="conflicts"
            title="Conflicts "
            className="headings ml-2"
          // style={{ marginLeft: "-27px" }}
          >
            <div className="ml-4">
              <Row className="mt-1">
                <div>
                  <label className="labelStyle lla">
                    Other changes conflicting the schedule{" "}
                  </label>
                  <div className="mt-3">
                    <link
                      rel="stylesheet"
                      href="https://fonts.googleapis.com/icon?family=Material+Icons"
                    ></link>
                    <MaterialTable
                      title={"Conflicts"}
                      columns={Columns.map((e) => e)}
                      // data={temp === "" ? "" : temp.map((e) => e)}
                      // detailPanel={(rowdata) => {
                      //   return (
                      //     <div className="mt-1" style={{ backgroundColor: "#ebedf2" }}>
                      //       <div
                      //         style={{
                      //           fontSize: 13,
                      //           color: "white",
                      //           backgroundColor: "#ebedf2",
                      //         }}
                      //       >

                      //       </div>

                      //     </div>
                      //   );
                      // }}
                      components={{
                        Pagination: PatchedPagination,
                      }}
                      options={{
                        actionsColumnIndex: -1,
                        exportAllData: true,
                        exportButton: true,
                        columnResizable: true,
                        filtering: false,
                        sorting: true,
                        paging: true,
                        pageSize: 20, // make initial page size
                        emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                        pageSizeOptions: [6, 12, 20, 50], // rows selection options

                        // rowStyle: rowData => ({
                        //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                        // }),
                        headerStyle: {
                          fontWeight: "700",
                          fontSize: "medium",

                          // backgroundColor: "#eef2f7",
                          color: "black",
                          width: "calc(250px)",
                          padding: "12px ",
                          textAlign: "left",
                          whiteSpace: "nowrap",
                        },
                        cellStyle: {
                          textAlign: "left",
                          fontSize: "medium",
                          padding: "21px",
                          color: "#6c757d",
                          // padding: "15px 2px",
                        },
                      }}
                    />
                  </div>
                </div>
              </Row>
            </div>
          </Tab>

        </Tabs>
      </div>
      {/* <div>
        <h5 style={{ fontWeight: "200" }}>
          <label className="text-dark mr-2" style={{ fontWeight: "bold" }}>
            Implementation Steps :{" "}
          </label>
          {changedetails[0].ExecutionSteps.ExecutionSteps}
        </h5>
        <h5 style={{ fontWeight: "200" }}>
          <label className="text-dark mr-2" style={{ fontWeight: "bold" }}>
            Risk Detail Impact :{" "}
          </label>
          {changedetails[0].ExecutionSteps.Riskdetails}
        </h5>
        {changedetails[0].IsCertified == "N" ? (
          <h5 style={{ fontWeight: "200" }}>
            <label className="text-dark mr-2" style={{ fontWeight: "bold" }}>
              Certification Details :{" "}
            </label>
            {changedetails[0].IsCertified == "N"
              ? "Not Certified"
              : "Certified"}
          </h5>
        ) : (
          <h5 style={{ fontWeight: "200" }}>
            <label className="text-dark mr-2" style={{ fontWeight: "bold" }}>
              Certification Details :{" "}
            </label>
            {changedetails[0].IsCertified == "N"
              ? "Not Certified"
              : "Certified"}
            <span>{changedetails[0].CertificationDetails.details}</span>
            <h5 style={{ fontWeight: "200" }}>
              <label className="text-dark mr-2" style={{ fontWeight: "bold" }}>
                Post Change Testing Plan :{" "}
              </label>
              {
                changedetails[0].PostImplementationTesting
                  .PostImplementationTesting
              }
            </h5>
          </h5>
        )}
        <h5 style={{ fontWeight: "200" }}>
          <label className="text-dark mr-2" style={{ fontWeight: "bold" }}>
            Rollback Steps :{" "}
          </label>
          {changedetails[0].RollbackSteps.Rollbacksteps}
        </h5>
        {changedetails[0].IsRolledBack == "N" ? (
          <h5 style={{ fontWeight: "200" }}>
            <label className="text-dark mr-2" style={{ fontWeight: "bold" }}>
              Rollback Details :{" "}
            </label>
            {changedetails[0].IsRolledBack == "N"
              ? "Not Rollbacked"
              : "Rollbacked"}
          </h5>
        ) : (
          <h5 style={{ fontWeight: "200" }}>
            <label className="text-dark mr-2" style={{ fontWeight: "bold" }}>
              Rollback Testing Details :{" "}
            </label>
            {changedetails[0].IsRolledBack == "N"
              ? "Not Rollbacked"
              : "Rollbacked"}
            <span>{changedetails[0].Rollbacksteps.Rollbacktestingdetails}</span>
            <h5 style={{ fontWeight: "200" }}>
              <label className="text-dark mr-2" style={{ fontWeight: "bold" }}>
                Post Change Testing Plan :{" "}
              </label>
              {changedetails[0].Rollbacksteps.postrollbacktesting}
              In Hours -{" "}
              {changedetails[0].Rollbacksteps.rollbackinhours[0].label}
            </h5>
          </h5>
        )}

        <table className="custom-table">
          <thead>
            <tr>
              <th> Schedule Start Date&Time</th>
              <th> Schedule End Date&Time</th>
              <th>Rollback</th>
              <th>Rollback End Date &Time </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {moment(changedetails[0]?.Schedule?.StartdateandTime).format(
                  "DD-MM-YYYY HH:mm"
                )}
              </td>
              <td>
                {moment(changedetails[0]?.Schedule?.EnddateandTime).format(
                  "DD-MM-YYYY HH:mm"
                )}
              </td>
              <td>
                {changedetails[0]?.Schedule?.IsRolledBack == "N"
                  ? "Not Rollbacked"
                  : "Rollbacked"}
              </td>
              <td>
                {moment(changedetails[0]?.Schedule?.RollbackEndTime).format(
                  "DD-MM-YYYY HH:mm"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </div>
  );
  const handlecancel = () => {
    setCommonts("");
    setPatchData([]);
    setSerialNo("");
    setFormopen(false)
    refresh()
    //  refreshstates()
    // setNotesLoading(true)
  };

  const Notes = () => {
    return changedetails[0]?.ChangeNotes?.length > 0 ? changedetails[0]?.ChangeNotes?.sort((a, b) => b.NoteSno - a.NoteSno)?.map((e, index) => (
      <div className="container mt-3">
        <div className="d-flex justify-space-between mt-3">
          <Avatar
            alt=""
            src={
              userImage
            }
            sx={{ width: 30, height: 30 }}
          />
          <label className="ml-2 mt-2" style={{ fontSize: "14px", fontWeight: "600" }}>{e.NoteUpdatedByFN}</label>
          <label className="ml-2 mt-3" style={{ fontSize: "10px", color: "grey" }}>{timeAgo(e.DateTime)}</label>
        </div>
        <div className="mt-2">
          {/* <p>{e.NotesDetails}</p> */}
          {NotesLoading ? <div class="col-6" role="status">
            {/* <Skeleton />
                      <Skeleton />
                      <Skeleton /> */}
          </div> :
            <div
              onClick={() => handleClickOpen(e.NotesDetails)}
              dangerouslySetInnerHTML={{
                __html: modifyImageTags(e.NotesDetails),
              }}
            ></div>
          }
          <div className="d-flex mt-3">
            <span className="text-info font10" role="button" onClick={() => {
              setFormopen(true)
              setSerialNo(e.NoteSno)
              handlecomment(e.NotesDetails)
            }}>Edit</span>
            <span className="text-grey ml-2 font10" role="button" onClick={() => handleRemoveNote(e.NoteSno)}>Delete</span>
          </div>
        </div>
      </div>

    )) : (<></>)
  }
  const Sidebar = () => (
    <Card style={{ border: "1px solid rgb(0, 0, 0 ,20%) " }}>
      <div className="container">
        <div className="row mt-3">
          <div className="col-12 mt-3">
            <div className="flex justify-between">
              <span className="font20blackbold">
                {changedetails[0]?.ChangeStages?.StateCode === "Completed"
                  ? "Closed"
                  : "Open"}
              </span>
              <span className="font8blackbold">Priority {changedetails[0]?.Priority?.label}</span>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <span
              className="badge mt-2"
              style={{
                fontWeight: "bold",
                color: "#808080",
                background: "transparent",
              }}
            >
              {CurrentState}
            </span>
          </div>
        </div>
        <hr style={lineStyle} />
        <div className="row mt-3">
          <div className="col-12 mt-3">
            <div className="flex">
              <span className="font14blackbold">Ticket details</span>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-5">
            <span className="font12grey">Created by</span>
          </div>
          <div className="col-7">
            <span className="font12blackbold flex">
              <Avatar
                alt=""
                src={userImage}
                className="mr-2"
                sx={{ width: 20, height: 20 }}
              />

              {changedetails[0]?.RequestedBy.FN +
                " " +
                changedetails[0]?.RequestedBy.LN}
            </span>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-5">
            <span className="font12grey">Created at</span>
          </div>
          <div className="col-7">
            <span className="font12blackbold">
              {moment(changedetails[0]?.CreatedDateTime).format(
                "DD MMM YYYY hh:mm A"
              )}
            </span>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-5">
            <span className="font12grey">Category</span>
          </div>
          <div className="col-7">
            <span className="font12blackbold">
              {changedetails[0]?.Category?.CategoryName}
            </span>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-5">
            <span className="font12grey">Sub-Category</span>
          </div>
          <div className="col-7">
            <span className="font12blackbold">
              {changedetails[0]?.Subcategory?.SubCategoryName}
            </span>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-5">
            <span className="font12grey">Assigned Group</span>
          </div>
          <div className="col-7">
            <span className="font12blackbold">
              {changedetails[0]?.AssignmentGroup.GroupName}
            </span>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-5">
            <span className="font12grey">Assigned To</span>
          </div>
          <div className="col-7">
            <span className="font12blackbold">
              {changedetails[0]?.AssignedTo.Name}
            </span>
          </div>
        </div>
        <hr style={lineStyle} />
        {/* properties */}
        <div className="row mt-1">
          <div className="col-12 mt-1">
            <div className="flex">
              <span className="font14blackbold">Properties</span>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12 mt-1">
            <label className="font11blackbold">Status Change</label>
            {/* <Select
              className=""
              styles={customStylesd}
              onChange={(e) => handledropdown(e, changedetails[0])}
              // placeholder="select"
              options={stateoptions}
              defaultValue={stateoptions?.find(
                (option) =>
                  option.label === changedetails[0].ChangeStages.StateCode
              )}
              //   value={
              //     stateoptions && stateoptions?.filter((e) => e.label == statechange)
              //   }
              // value={DropLoading ? { label: "Loading...", value: "...Loading" } : statusoption.filter((e) => e.label === IncidentDetails.CurrentState.StateCode)}
            ></Select> */}
            <Select
              className=""
              styles={customStylesd}
              options={stateoptions}
              value={
                stateoptions &&
                changedetails[0] &&
                changedetails[0]?.ChangeStages?.StateCode &&
                stateoptions?.find(
                  (option) =>
                    option.label === changedetails[0]?.ChangeStages?.StateCode
                )
              }
              onChange={(f) => {
                handledropdown(f, changedetails);
              }}
            />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12 mt-1">
            <label className="font11blackbold">Priority</label>
            <Select
              className=""
              styles={customStylesd}
              defaultValue={

                PriorityOption.filter(
                  (option) =>
                    option.value === changedetails[0]?.Priority?.label
                )
              }
              onChange={(e) => handlepriority(e, changedetails)}
              placeholder="select"
              options={PriorityOption}
            ></Select>
          </div>
        </div>
        <div className="row mt-1 mb-3">
          <div className="col-12 mt-1">
            <label className="font11blackbold">Assigned Group</label>
            <Select
              className=""
              styles={customStylesd}
              placeholder="select"
              options={assignmentoption}
              defaultValue={

                assignmentoption?.filter(
                  (option) =>
                    option.value === changedetails[0]?.AssignmentGroup?.GroupCode
                )
              }
              onChange={(e) => handleassignmentgrp(e, changedetails)}

            ></Select>
          </div>
        </div>
        <div className="row mt-1 mb-3">
          <div className="col-12 mt-1">
            <label className="font11blackbold">Assigned To</label>
            <Select
              className=""
              styles={customStylesd}
              placeholder="select"
              options={AssignedTo}
              value={

                AssignedTo?.filter(
                  (option) =>
                    option.value === changedetails[0]?.AssignedTo?.id
                )
              }
              onChange={(e) => handleassignmentto(e, changedetails)}

            ></Select>
          </div>
        </div>
      </div>
    </Card>
  );
  return (
    <div>



      <Row>
        <Col lg={9} md={7} xl={9}>
          <Header /> <hr style={mainlineStyle} className="mt-5 mb-3" />
          <div className="container mb-3 mt-3">
      <div className="header d-flex">
        <h5 style={{ fontWeight: "600" }}>Activity</h5>
      </div>
      <div className="mt-3 flex">
        <Avatar alt="" src={userImage} sx={{ width: 35, height: 35 }} />
        <div className="w-auto">
          {!Formopen ? (
            <input
              className="form-control rounded ml-2"
              style={{
                background: "#fff",
                border: "1px solid rgb(0, 0, 0 ,30%) ",
                width: "600px",
                color: "black",
                height: "35px",
                borderRadius: "5px",
              }}
              placeholder="Add a Comment"
              onClick={() => setFormopen(true)}
            // disabled={ADDIGNID != Empid[0].ClientUserId}
            />
          ) : null}
          {Formopen ? (
            <div>
              <div className="d-flex flex-column ml-2">
                <Toolbar />
                <ReactQuill
                  // style={{ width: "600px" }}
                  className="quill"
                  theme="snow"
                  value={Commonts}
                  onChange={(e) => handlecomment(e)}
                  modules={modules}
                  formats={formats}
                />
              </div>
            </div>
          ) : null}
          <div className="mt-3 ml-2 d-flex justify-space-between">
            <Button
              onClick={() => {
                handlecancel();
              }}
              style={{
                fontSize: 12,
                padding: 9,
                width: 70,
                height: 28,
                borderRadius: 5,
                marginRight: 14,
                border: "2px solid #198ae3 ",
                backgroundColor: "#fff",
                color: "#198ae3 ",
                textAlign: "center",
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                fontSize: 12,
                padding: 9,
                width: 70,
                height: 28,
                borderRadius: 5,
                backgroundColor: "#198ae3 ",
                color: "#fff",
                border: "0px solid #198ae3 ",
                textAlign: "center",
                textTransform: "none",
                marginRight: 24,
              }}
              onClick={handlesubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
          <Notes />
        </Col>
        <Col lg={3} md={5} xl={3} className="mb-5">
          <Sidebar />
        </Col>
      </Row>
      {imageviewopen ? (
        <Imageviewer imagedata={imagedata} open={open} setOpen={setOpen} />
      ) : null}
      {Edit &&
        <ChangeModule Edit={Edit} setEdit={setEdit} shortdes={changedetails[0]?.ShortDescription} rollbacks={changedetails[0]?.RollbackSteps.Rollbacksteps} />}

    </div>

  );
}

{
  /* <div className="col-12 mt-3">
                        <div className="flex">
                            <span className="font12grey"></span>
                            <span className="font12grey ml-4">{IncidentDetails.CreatedDate}</span>
                        </div>
                    </div> */
}
