import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@mui/material/Skeleton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";

const SkeletonCard = () => {
  return (
    <Card
      style={{
        height: "200px",
        display: "flex",
        gap: "20px",
        alignItems: "center",
        backgroundColor: "rgba(244, 239, 239, 0.2)",
      }}
    >
      <div>
        <Skeleton
          variant="circle"
          style={{ borderRadius: "50%" }}
          width={80}
          height={80}
        />
      </div>
      <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <Skeleton variant="rect" width={700} height={30} />
        <Skeleton variant="rect" width={800} height={100} />
      </div>
    </Card>
  );
};

export default SkeletonCard;
