import axios from "../../axios";
import React, { useState, useEffect, version } from "react";
import MaterialTable from "material-table";
import PatchedPagination from "../../PatchedPagination";
import moment from "moment";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import ReactImageFallback from "react-image-fallback";
import Avatar from "@mui/material/Avatar";

import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import { Table, Button } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, CardBody } from "reactstrap";
import "./MyIncidentQueue.css";
import { toast, Slide } from "react-toastify";
import ComputerIcon from '@mui/icons-material/ComputerOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import MyIncidentNotes from "./MyIncidentNotes";
import { INDETAILSUPDATE } from "../../redux/CommonInitialsate";
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import Unassigned from "../../assets/images/avatar.png";


// import './RaiseATicket.css'
import {
  Box,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Card,
} from "reactstrap";
import Anne from "../../assets/images/anne.jpg";
import Derie from "../../assets/images/Admin.png";
import Shankar from "../../assets/images/aravindh.png";
import Aravind from "../../assets/images/Kandhasamy.png";
import CircleIcon from "@mui/icons-material/Circle";
import IncidentCard from "./IncidentCard";
import { Refresh } from "@material-ui/icons";
import DetailsNotes from "./DetailsNotes";
toast.configure();
export default function MyIncidentQueue({ useeffect2, setuseeffect2 }) {
  let arr = JSON.parse(localStorage.getItem("Empdetails"));
  let smrole = localStorage.getItem("ProjectRole");
  const lineStyle = {
    borderTop: "1px solid rgb(0, 0, 0 ,20%) ", // Customize the color and style    
    width: "100%",
    margin: "0 0",
    overflow: "none"

  };
  const clientid = JSON.parse(localStorage.getItem("clientid"));

  let role =
    sessionStorage.getItem("Roledetails") == "undefined"
      ? ""
      : JSON.parse(sessionStorage.getItem("Roledetails"));
  // console.log(sla);
  const [incidentnewId, setincidentnewId] = useState("");

  const [Sla_Mater, setSla_Master] = useState([])
  const [assignmentoption, setassignmentoption] = useState([])
  const [NotesLoading, setNotesLoading] = useState(false)
  const [DropLoading, setDropLoading] = useState(false)
  const [StatusUp, setStatusUp] = useState(false);
  const [catmaster, setcatmaster] = useState([]);
  const [tabledata, settabledata] = useState([]);
  const [StateId, setStateId] = useState("");
  const [StateData, setStateData] = useState([]);
  const [Refresh, setRefresh] = useState(false);
  const [open, setopen] = useState(false);
  const [outlieropen, setoutlieropen] = useState(false);
  const [outId, setoutId] = useState("");
  const [sla, setsla] = useState([]);
  const [ReasonOutlier, setReasonOutlier] = useState("");
  const [NotifyToID, setNotifyToID] = useState("");
  const [AssignId, setAssignId] = useState("Assigne");
  const [chkre, setchkre] = useState("");
  const [Acktime, setAcktime] = useState([]);
  const [Pvaluecode, setPvaluecode] = useState("");
  const [Pvalue, setPvalue] = useState("");
  const [handleerror, sethandleerror] = useState("");
  const [Status, setstatus] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [Prior, setPrior] = useState([]);
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const [view, setView] = useState("cardview");
  const [name, setname] = useState("");
  const [GroupCode, setGroupCode] = useState("0")
  const [category, setcategory] = useState([]);
  const [checkedAck, setcheckedAck] = useState(false);
  const handleClick1 = () => setcheckedAck(!checkedAck);
  const [checkedWIP, setcheckedWIP] = useState(false);
  const handleClick2 = () => setcheckedWIP(!checkedWIP);
  const [checkedUV, setcheckedUV] = useState(false);
  const handleClick3 = () => setcheckedUV(!checkedUV);
  const [mydatapro, setmydatapro] = useState([]);
  const [checkedBug, setcheckedBug] = useState(false);
  const [priiortyfilter, SetPriortiryf] = useState("");
  const [statusfilter, setstatusfilter] = useState("");

  const [cateoryff, Setcategoryff] = useState("");
  const [DateF, SetDateF] = useState(null);


  const handleClickCard = (index, e) => {
    setActiveCardIndex(index);
  };
  const customStylesd = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  function handlenamefilter(item) {
    setname(item.label);
  }
  function handlepriofilter(item) {
    SetPriortiryf(item.value);
  }
  function handlestatusfilter(item) {
    setstatusfilter(item.label);
  }
  function categoryfilter(item) {
    Setcategoryff(item.label);
  }
  function datefilter(item) {
    SetDateF(item.value);
  }
  const dispatch = useDispatch()
  const { allImage, IncidentId, IncidentGroup, projectDetails, projectCode } = useSelector((state) => state.user)
  const prjshcode = projectDetails?.filter((e) => e.projectcode == projectCode)
  let categoryoption =
    category == ""
      ? ""
      : category.map((e) => {
        return {
          value: e.CategoryCode,
          label: e.CategoryName,
          id: e.IncidentCategoryId,
        };
      });

  const handleClick4 = () => {
    setcheckedBug(!checkedBug);
    if (checkedFixed == true) {
      alert("Unable to check Both Option");
      setcheckedBug(false);

    }
  };
  const [checkedFixed, setcheckedFixed] = useState(false);
  const handleClick5 = () => {
    setcheckedFixed(!checkedFixed);
    if (checkedBug == true) {
      alert("Unable to check Both Option");
      setcheckedFixed(false);
      console.log(checkedBug, checkedFixed);
    }
  };
  if (useeffect2) {
    // https://api.yozytech.com/company_sla_master
    axios
      .get("agilesaas_incident_details")
      .then((response) => {
        let mysetdata;
        mysetdata = response.data.sort(
          (firstItem, secondItem) =>
            firstItem.IncidentId - secondItem.IncidentId
        );
        let nextva = mysetdata[mysetdata.length - 1];
        console.log(nextva.IncidentId + 1, "Sort", mysetdata, nextva);
        let incidentnewId1 = response.data.length + 1;
        setincidentnewId(nextva.IncidentId + 1);
        // console.log(incidentnewId)
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_sla_details")
      .then((response) => {
        setsla(response.data);
        console.log(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("company_sla_master")
      .then((response) => {
        setSla_Master(response.data)

        setcatmaster(response.data);
      })
      .catch((error) => console.log(error));

    if (IncidentGroup.length === 1) {
      axios
        .get("agilesaas_incident_details?AssignedGroup->>GroupCode=eq." + IncidentGroup[0] + "&ClientId=eq." + clientid)
        .then((response) => {
          settabledata(response.data.filter((e) => e.AssignedTo === arr[0].ClientUserId || e.AssignedTo === null));
        })
        .catch((error) => console.log(error));
    }
    else {
      axios
        .get("agilesaas_incident_details?AssignedGroup->>GroupCode=in.(" + IncidentGroup.map((e) => e) + ")&ClientId=eq." + clientid)
        .then((response) => {
          settabledata(response.data.filter((e) => e.AssignedTo === arr[0].ClientUserId || e.AssignedTo === null));
        })
        .catch((error) => console.log(error));
    }



    axios
      .get("agilesaas_incident_category_master?ClientId=eq." + clientid)
      .then((response) => {

        setcategory(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("subscription_user_group_mapping?ClientId=eq." + clientid)
      .then((response) => {
        let userGroupMappings = response.data
        const resultArray = userGroupMappings.reduce((acc, userGroupMapping) => {
          userGroupMapping.GroupDetails.forEach((groupDetail) => {
            const groupId = groupDetail.GroupId;
            const groupName = groupDetail.GroupName;

            // Check if the group already exists in the result array
            const existingGroupIndex = acc.findIndex((group) => group.GroupId === groupId);

            if (existingGroupIndex !== -1) {
              // Add the user to the existing group
              acc[existingGroupIndex].users.push({
                Firstname: userGroupMapping.UserDetails.Firstname,
                Lastname: userGroupMapping.UserDetails.Lastname,
                UserId: userGroupMapping.UserId,
              });
            } else {
              // Create a new group with the user
              acc.push({
                GroupId: groupId,
                GroupName: groupName,
                users: [{
                  Firstname: userGroupMapping.UserDetails.Firstname,
                  Lastname: userGroupMapping.UserDetails.Lastname,
                  UserId: userGroupMapping.UserId,
                }],
              });
            }
          });

          return acc;
        }, []);
        const assignmentooption = resultArray.map((e) => {
          return {
            label: e.GroupName,
            value: e.GroupId,
            users: e.users
          }
        })
        setassignmentoption(assignmentooption)
      })
      .catch((error) => console.log(error));
    setuseeffect2(false)
  }
  function Refreshdata() {
    axios
      .get("agilesaas_incident_details")
      .then((response) => {
        let mysetdata;
        mysetdata = response.data.sort(
          (firstItem, secondItem) =>
            firstItem.IncidentId - secondItem.IncidentId
        );
        let nextva = mysetdata[mysetdata.length - 1];
        console.log(nextva.IncidentId + 1, "Sort", mysetdata, nextva);
        let incidentnewId1 = response.data.length + 1;
        setincidentnewId(nextva.IncidentId + 1);
        // console.log(incidentnewId)
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_details?AssignedGroup->>GroupCode=in.(" + IncidentGroup.map((e) => e) + ")&ClientId=eq." + clientid)
      .then((response) => {
        console.log(response.data, "Aravindhan")
        settabledata(response.data?.filter((e) => e.AssignedTo === arr[0].ClientUserId || e.AssignedTo === null));
        setNotesLoading(false)
        setDropLoading(false)
      })
      .catch((error) => console.log(error));
    // axios
    //   .get("agilesaas_incident_details?AssignedTo=eq." + arr[0].ClientUserId)
    //   .then((response) => {
    //     settabledata(response.data);
    //     setNotesLoading(false)
    //     setDropLoading(false)
    //   })
    //   .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_sla_details")
      .then((response) => {
        setsla(response.data);
        // console.log(response.data);
      })
      .catch((error) => console.log(error));
  }
  if (Refresh) {
    axios
      .get("agilesaas_incident_sla_details")
      .then((response) => {
        setsla(response.data);
        console.log(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("company_sla_master")
      .then((response) => {
        setcatmaster(response.data);
      })
      .catch((error) => console.log(error));
    axios
      .get("agilesaas_incident_details?AssignedTo=eq." + arr[0].ClientUserId)
      .then((response) => {
        settabledata(response.data);
        setRefresh(false);
      })
      .catch((error) => console.log(error), setRefresh(false));
  }
  const handleClose = () => {
    setopen(false);
    setStateData("");
    setStateId("");
  };
  const handleupdate = (e, data, datarowe) => {

    let datats = tabledata?.filter((E) => E.IncidentId == datarowe[0]?.IncidentId);


    let selectedvalue = e.target.value;
    setstatus(selectedvalue);

    setStateId(e.IncidentId);

    if (AssignId != "Assigne") {
      alert("1")
      if (checkedBug == true) {
        let patcbu = {
          CurrentState: {
            StateCode: "Work in progress",
            StateName: "Work in progress",
          },
          IsUserVerified: null,
          IsReworkRequired: "Y",
        };
        axios
          .patch("agilesaas_incident_details?IncidentId=eq." + StateId, patcbu)
          .then((response) => {
            // updatenotes();
            // setRefresh(true)
          })
          .catch((error) => console.log(error));
      } else if (checkedFixed == true) {
        let patcbu = {
          IsUserVerified: "Y",
          IsReworkRequired: "N",
        };
        axios
          .patch("agilesaas_incident_details?IncidentId=eq." + StateId, patcbu)
          .then((response) => {
            // updatenotes();
            // setRefresh(true);
          })
          .catch((error) => console.log(error));
      } else if (checkedFixed == true && checkedBug == true) {
        alert("please select One option ");
      } else {
        // if (ItNotes == "") {
        //   alert("Please Enter the Notes");
        // } else {
        // updatenotes();
        // }
      }
    } else if (AssignId == "Assigne") {
      alert("2")
      if (datats[0]?.CurrentState?.StateCode === "New") {
        ///work in progress process start
        if (datats[0]?.CurrentState?.StateCode === "Resolved") {
          alert("Could Not be change the stage because it resolved");
        } else {
          alert("work in progress");
          let patchdata = {
            CurrentState: {
              StateCode: "Work in progress",
              StateName: "Work in progress",
            },
          };
          let datapro = tabledata.filter(
            (e) => e.IncidentId == datarowe[0].IncidentId
          );
          let validpro = datapro[0].Priority.PriortyCode;
          let Ak = catmaster.filter((x) => x.Priority == validpro);
          let MyAcknowledgementSLA = Ak[0].AcknowledgementSLA;
          let myResolutionSLA = Ak[0].ResolutionSLA;

          let mycreateddate = moment(datapro[0].CreatedDate).format("HH-MM-SS");
          // Priority

          var startDate = moment(datapro[0].CreatedDate).format("DD");
          var endDate = moment().format("DD");
          var given1 = moment("2018-03-10", "YYYY-MM-DD");
          var given = moment(datapro[0].CreatedDate, "YYYY-MM-DD");
          var current = moment().startOf("day");
          let dayscalc = -moment.duration(given.diff(current)).asDays();

          console.log(given1, "Days=", given, current, dayscalc);
          // https://api.yozytech.com/agilesaas_incident_details?IncidentId=eq.6

          var startTime = moment(datapro[0].CreatedDate).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          console.log(startTime);
          var endTime = moment().format("DD/MM/YYYY HH:mm:ss");
          var startval = moment(datapro[0].CreatedDate).format("DD");
          var endTimeval = moment().format("DD");
          var ms = moment(startTime, "DD/MM/YYYY HH:mm:ss").diff(
            moment(endTime, "DD/MM/YYYY HH:mm:ss")
          );
          var d = -Math.round(moment.duration(ms) / 60000);
          var ack = d;
          console.log(ms, startTime, MyAcknowledgementSLA, d);
          // console.log(datapro,"test")
          if (isNaN(d)) {
            alert("Please try again");
          } else {
            if (dayscalc > 0) {
              if (ack > 900 * dayscalc) {
                d = ack - 900 * dayscalc;
              } else {
                d = 900 * dayscalc - ack;
              }
              d = d - MyAcknowledgementSLA;

              console.log("It will Passed Next days");
              console.log(-d);
              if (d > 0) {
                console.log("Enter");
                let patchdatasla1 = {
                  IsAcknowledged: "Y",
                  AcknowledgedByDetails: {
                    FN: arr[0].UserFirstname,
                    LN: arr[0].UserLastname,
                  },
                  AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                  AcknowledgementTimeTaken: ack,
                  PauseSLAClock: "Y",
                  PauseSLAClockDetails: [
                    {
                      StartDate: moment(given).format("YYYY-MM-DD"),
                      EndDate: moment(current).format("YYYY-MM-DD"),
                      PauseSLAClock: 900 * dayscalc,
                      SLA: "Acknowleged",
                    },
                  ],
                  IsSLAMet: "N",
                  SLABreachDetails: ack - 900 * dayscalc - MyAcknowledgementSLA,
                  IsResolved: null,
                  ResolvedByDetails: null,
                  ResolvedDate: null,
                  ResolutionTimeTaken: null,

                  IsOutlierApproved: null,
                  OutlierReason: null,
                  IsSLAHolidayApplicable: "N",
                  SLAHolidayApplicableReason: null,
                  IsActive: null,
                  Status: null,
                  IsOutlierRequested: null,
                };
                console.log(
                  "Acknowledged met",
                  patchdatasla1,
                  ack,
                  ack - 900 * dayscalc - MyAcknowledgementSLA
                );
                axios
                  .patch(
                    "agilesaas_incident_sla_details?IncidentId=eq." +
                    datarowe[0].IncidentId,
                    patchdatasla1
                  )
                  .then((response) => { })
                  .catch((error) => console.log(error));
                setRefresh(true);
                axios
                  .patch(
                    "agilesaas_incident_details?IncidentId=eq." +
                    datarowe[0].IncidentId,
                    patchdata
                  )
                  .then((response) => {
                    // updatenotes();
                    // setRefresh(true);
                  })
                  .catch((error) => console.log(error));
                setRefresh(true);
              } else {
                if (-d > 60) {
                  d = -d / 60;
                }
                let brech = ack - 900 * dayscalc - MyAcknowledgementSLA;
                let patchdatasla2 = {
                  IsAcknowledged: "Y",
                  AcknowledgedByDetails: {
                    FN: arr[0].UserFirstname,
                    LN: arr[0].UserLastname,
                  },
                  AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                  AcknowledgementTimeTaken: ack,
                  PauseSLAClock: "Y",
                  PauseSLAClockDetails: [
                    {
                      StartDate: moment(given).format("YYYY-MM-DD"),
                      EndDate: moment(current).format("YYYY-MM-DD"),
                      PauseSLAClock: 900 * dayscalc,
                      SLA: "Acknowleged",
                    },
                  ],

                  IsSLAMet: "Y",
                  SLABreachDetails: null,
                  IsResolved: null,
                  ResolvedByDetails: null,
                  ResolvedDate: null,
                  ResolutionTimeTaken: null,

                  IsOutlierApproved: null,
                  OutlierReason: null,
                  IsSLAHolidayApplicable: "N",
                  SLAHolidayApplicableReason: null,
                  IsActive: null,
                  Status: null,
                  IsOutlierRequested: null,
                };
                axios
                  .patch(
                    "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                    patchdatasla2
                  )
                  .then((response) => { })
                  .catch((error) => console.log(error));
                setRefresh(true);
                axios
                  .patch(
                    "agilesaas_incident_details?IncidentId=eq." + StateId,
                    patchdata
                  )
                  .then((response) => {
                    // updatenotes();
                    // setRefresh(true);
                  })
                  .catch((error) => console.log(error));
                setRefresh(true);
                console.log("Acknowledged Breach", d, "hrs", patchdatasla2);
              }
            } else {
              // console.log(endTimeval,startTime,endTime, "is Equal min");
              console.log(d);
              d = d - MyAcknowledgementSLA;
              console.log(d <= 0);
              if (d <= 0) {
                console.log("Enter");
                let patchdatasla3 = {
                  IsAcknowledged: "Y",
                  AcknowledgedByDetails: {
                    FN: arr[0].UserFirstname,
                    LN: arr[0].UserLastname,
                  },
                  AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                  AcknowledgementTimeTaken: ack,
                  IsSLAMet: "Y",
                  PauseSLAClock: "N",
                  PauseSLAClockDetails: null,
                  IsResolved: null,
                  ResolvedByDetails: null,
                  ResolvedDate: null,
                  ResolutionTimeTaken: null,

                  IsOutlierApproved: null,
                  OutlierReason: null,
                  IsSLAHolidayApplicable: "N",
                  SLAHolidayApplicableReason: null,
                  IsActive: null,
                  Status: null,
                  IsOutlierRequested: null,
                };
                axios
                  .patch(
                    "agilesaas_incident_sla_details?IncidentId=eq." +
                    datarowe[0].IncidentId,
                    patchdatasla3
                  )
                  .then((response) => { })
                  .catch((error) => console.log(error));
                setRefresh(true);
                axios
                  .patch(
                    "agilesaas_incident_details?IncidentId=eq." +
                    datarowe[0].IncidentId,
                    patchdata
                  )
                  .then((response) => {
                    // updatenotes();
                    // setRefresh(true);
                  })
                  .catch((error) => console.log(error));
                setRefresh(true);
                console.log("Acknowledged met", patchdatasla3);
              } else {
                console.log("enter");

                let patchdatasla4 = {
                  IsAcknowledged: "Y",
                  AcknowledgedByDetails: {
                    FN: arr[0].UserFirstname,
                    LN: arr[0].UserLastname,
                  },
                  AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                  AcknowledgementTimeTaken: ack,
                  SLABreachDetails:
                    MyAcknowledgementSLA > ack
                      ? MyAcknowledgementSLA - ack
                      : ack - MyAcknowledgementSLA,
                  PauseSLAClock: "N",
                  IsSLAMet: "N",
                  PauseSLAClockDetails: null,
                  IsResolved: null,
                  ResolvedByDetails: null,
                  ResolvedDate: null,
                  ResolutionTimeTaken: null,

                  IsOutlierApproved: null,
                  OutlierReason: null,
                  IsSLAHolidayApplicable: "N",
                  SLAHolidayApplicableReason: null,
                  IsActive: null,
                  Status: null,
                  IsOutlierRequested: null,
                };
                axios
                  .patch(
                    "agilesaas_incident_sla_details?IncidentId=eq." +
                    datarowe[0].IncidentId,
                    patchdatasla4
                  )
                  .then((response) => { })
                  .catch((error) => console.log(error));
                setRefresh(true);
                axios
                  .patch(
                    "agilesaas_incident_details?IncidentId=eq." +
                    datarowe[0].IncidentId,
                    patchdata
                  )
                  .then((response) => {
                    // updatenotes();
                  })
                  .catch((error) => console.log(error));
                setRefresh(true);
                console.log("Acknowledged Breach", patchdatasla4);
              }
            }
          }
        }
        ///work in progress process end
      } else if (datats[0]?.CurrentState?.StateCode == "Work in progress") {
        alert("userv");
        let uipatch = {
          CurrentState: {
            StateCode: "User Verfication",
            StateName: "User Verfication",
          },
          IsUserVerified: "R",
        };
        axios
          .patch(
            "agilesaas_incident_details?IncidentId=eq." +
            datarowe[0].IncidentId,
            uipatch
          )
          .then((response) => {
            // updatenotes();
          })
          .catch((error) => console.log(error));
        setRefresh(true)

      } else if (datats[0]?.CurrentState?.StateCode === "User Verfication") {
        //resolved start

        alert("resolve")
        if (datats[0]?.CurrentState?.StateCode === "Resolved") {
          alert("Could Not be change the stage because it resolved");
        } else {
          alert("resolved");
          let patchdata = {
            CurrentState: {
              StateCode: "Resolved",
              StateName: "Resolved",
            },
          };
          let datapro = tabledata.filter(
            (e) => e.IncidentId == datarowe[0].IncidentId
          );

          let validpro = datapro[0]?.Priority?.PriortyCode;
          let Ak = catmaster.filter((x) => x.Priority == validpro);
          let SLAAK = sla.filter((x) => x.IncidentId == datarowe[0].IncidentId);
          let mypausesla = SLAAK[0].PauseSLAClockDetails;
          // let MyResolutionSLA = Ak[0].ResolutionSLA;
          let myResolutionSLA = Ak[0].ResolutionSLA;

          let mycreateddate = moment(datapro[0].CreatedDate).format("HH-MM-SS");
          // Priority
          console.log(mypausesla);

          var startDate = moment(datapro[0].CreatedDate).format("DD");
          var endDate = moment().format("DD");
          var given1 = moment("2018-03-10", "YYYY-MM-DD");
          var given = moment(datapro[0].CreatedDate, "YYYY-MM-DD");
          var current = moment().startOf("day");
          let dayscalc = -moment.duration(given.diff(current)).asDays();

          console.log(given1, "Days=", given, current, dayscalc);
          // https://api.yozytech.com/agilesaas_incident_details?IncidentId=eq.6

          var startTime = moment(datapro[0].CreatedDate).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          console.log(startTime);

          var endTime = moment().format("DD/MM/YYYY HH:mm:ss");
          var startval = moment(datapro[0].CreatedDate).format("DD");
          var endTimeval = moment().format("DD");
          var ms = moment(startTime, "DD/MM/YYYY HH:mm:ss").diff(
            moment(endTime, "DD/MM/YYYY HH:mm:ss")
          );
          var d = -Math.round(moment.duration(ms) / 60000);
          var ack = d;
          var startval = moment(datapro[0].CreatedDate).format("DD");
          console.log(endTimeval, "endTimeval", startval, "startval");
          if (isNaN(d)) {
            alert("Please try again");
          } else {
            if (dayscalc > 0 || startval < endTimeval) {
              if (ack > 900 * dayscalc) {
                d = ack - 900 * dayscalc;
              } else {
                d = 900 * dayscalc - ack;
              }

              d = d - myResolutionSLA;

              console.log("It will Passed Next days", d);
              if (d > 0) {
                let patchRSLA = {
                  IsResolved: "Y",
                  ResolvedByDetails: {
                    FN: arr[0].UserFirstname,
                    LN: arr[0].UserLastname,
                  },
                  ResolvedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                  ResolutionTimeTaken: ack,
                  PauseSLAClockDetails: [
                    ...mypausesla,
                    {
                      StartDate: moment(given).format("YYYY-MM-DD"),
                      EndDate: moment(current).format("YYYY-MM-DD"),
                      PauseSLAClock: 900 * dayscalc,
                      SLA: "Resolved",
                    },
                  ],
                  IsOutlierRequested: "Y",
                  IsOutlierApproved: null,
                  OutlierReason: null,
                };
                axios
                  .patch(
                    "agilesaas_incident_sla_details?IncidentId=eq." +
                    datarowe[0].IncidentId,
                    patchRSLA
                  )
                  .then((response) => { })
                  .catch((error) => console.log(error));
                setRefresh(true);
                axios
                  .patch(
                    "agilesaas_incident_details?IncidentId=eq." +
                    datarowe[0].IncidentId,
                    patchdata
                  )
                  .then((response) => {
                    // updatenotes();
                  })
                  .catch((error) => console.log(error));
                setRefresh(true);
                console.log(patchRSLA, "patchRSLABreech");
              } else {
                let patchRSLA = {
                  IsResolved: "Y",
                  ResolvedByDetails: {
                    FN: arr[0].UserFirstname,
                    LN: arr[0].UserLastname,
                  },
                  ResolvedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                  ResolutionTimeTaken: ack,
                  IsOutlierRequested: "N",
                  IsOutlierApproved: null,
                  OutlierReason: null,
                  PauseSLAClockDetails: [
                    ...mypausesla,
                    {
                      StartDate: moment(given).format("YYYY-MM-DD"),
                      EndDate: moment(current).format("YYYY-MM-DD"),
                      PauseSLAClock: 900 * dayscalc,
                      SLA: "Resolved",
                    },
                  ],
                };
                axios
                  .patch(
                    "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                    patchRSLA
                  )
                  .then((response) => { })
                  .catch((error) => console.log(error));
                setRefresh(true);
                axios
                  .patch(
                    "agilesaas_incident_details?IncidentId=eq." + StateId,
                    patchdata
                  )
                  .then((response) => {
                    // updatenotes();
                  })
                  .catch((error) => console.log(error));
                setRefresh(true);
                console.log(patchRSLA, "patchRSLAmet");
              }
            } else {
              // console.log(endTimeval,startTime,endTime, "is Equal min");
              console.log(d);

              d = d - myResolutionSLA;
              if (d > 0) {
                let patchRSLA = {
                  IsResolved: "Y",
                  ResolvedByDetails: {
                    FN: arr[0].UserFirstname,
                    LN: arr[0].UserLastname,
                  },
                  ResolvedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                  ResolutionTimeTaken: ack,
                  IsOutlierRequested: "Y",
                  IsOutlierApproved: null,
                  OutlierReason: null,
                };
                axios
                  .patch(
                    "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                    patchRSLA
                  )
                  .then((response) => { })
                  .catch((error) => console.log(error));
                setRefresh(true);
                axios
                  .patch(
                    "agilesaas_incident_details?IncidentId=eq." + StateId,
                    patchdata
                  )
                  .then((response) => {
                    // updatenotes();
                  })
                  .catch((error) => console.log(error));
                setRefresh(true);
                console.log(patchRSLA, "patchRSLAMet");
              } else {
                if (-d > 60) {
                  d = -d / 60;
                }
                let patchRSLA = {
                  IsResolved: "Y",
                  ResolvedByDetails: {
                    FN: arr[0].UserFirstname,
                    LN: arr[0].UserLastname,
                  },
                  ResolvedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                  ResolutionTimeTaken: ack,
                  IsOutlierRequested: "N",
                  IsOutlierApproved: null,
                  OutlierReason: null,
                };
                axios
                  .patch(
                    "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                    patchRSLA
                  )
                  .then((response) => { })
                  .catch((error) => console.log(error));
                setRefresh(true);
                axios
                  .patch(
                    "agilesaas_incident_details?IncidentId=eq." + StateId,
                    patchdata
                  )
                  .then((response) => {
                    // updatenotes();
                  })
                  .catch((error) => console.log(error));
                setRefresh(true);
                console.log(patchRSLA, "patchRSLABreach");

                // console.log("Acknowledged Breach",patchdatasla)
              }
            }
          }
        }
        // resolved end
      } else {
        // if (ItNotes == "") {
        //   alert("Please Enter the Notes");
        // } else {
        // updatenotes();
        // }
      }
    }
  };
  const handleoutlierClose = () => {
    setNotifyToID("");
    setoutlieropen(false);
    setoutId("");
    setReasonOutlier("");
    setRefresh(true);
  };
  const handlerequest = () => {
    if (ReasonOutlier == "") {
      alert("Please Enter The Reason");
    } else {
      let M2notificationData = {
        CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
        CreatedBy: arr[0].Teammemberid, //current Login
        NotifyTo: NotifyToID, //buddy
        AudienceType: "Individual",
        Priority: "High",
        Subject: "Outlier Request",
        Description:
          arr[0].UserFirstname + " " + arr[0].UserLastname + ", send a Approve request",
        IsSeen: "N",
        Status: "New",
      };
      axios
        .post("notification?NotifyTo=eq." + NotifyToID, M2notificationData)
        .then((res) => console.log(res))
        .catch((error) => console.log(error));

      let patchoutlier = {
        OutlierReason: ReasonOutlier,
        IsOutlierRequested: "Y",
      };
      axios
        .patch(
          "agilesaas_incident_sla_details?IncidentId=eq." + outId,
          patchoutlier
        )
        .then((response) => {
          alert("Requested Successfully");
          handleoutlierClose();
        })
        .catch((error) => console.log(error));
      console.log(patchoutlier);
      console.log("Request", outId);
    }
  };
  const handleoutlier = (e) => {
    setoutId(e);
    let iddata = tabledata.filter((c) => c.IncidentId == e);
    // let issla=sla.filter((r)=>r.IncidentId==e)
    let dataack = sla.filter((t) => t.IncidentId == e);
    console.log(dataack, "Yes Yeah!");
    let mygettime = dataack.map((x) => {
      return {
        AcknowledgementTimeTaken: x.SLABreachDetails,
        ResolvedTimeTaken: x.ResolutionTimeTaken,
        PauseSLAClockDetails:
          x.PauseSLAClockDetails == null
            ? ""
            : x.PauseSLAClockDetails[1].PauseSLAClock == ""
              ? ""
              : x.PauseSLAClockDetails[1].PauseSLAClock,
      };
    });
    setAcktime(mygettime);
    console.log(iddata[0].CreatedBy);
    setNotifyToID(iddata[0].CreatedBy);
    console.log(Acktime, "Request");
    setoutlieropen(true);

    //    <div>HaiHaiHaiHai</div>

    //end
  };

  const uniqueNames = [];
  const nameoptions =
    tabledata == ""
      ? ""
      : tabledata &&
      tabledata?.reduce((acc, item) => {
        const fullName = `${item.CreatedByDetails.FN} ${item.CreatedByDetails.LN}`;
        if (!uniqueNames.includes(fullName)) {
          uniqueNames.push(fullName);
          acc.push({ value: fullName, label: fullName });
        }
        return acc;
      }, []);


  const check =
    name != ""
      ? tabledata?.filter((E) => E.CreatedByDetails.FN + " " + E.CreatedByDetails.LN == name)
      : priiortyfilter != ""
        ? tabledata?.filter((E) => E.Priority.PriortyCode == priiortyfilter)
        : statusfilter != ""
          ? tabledata?.filter((e) => e.CurrentState.StateName == statusfilter)
          : cateoryff != ""
            ? tabledata?.filter((e) => e.CategoryCode.CategoryName == cateoryff)
            : DateF != null
              ? tabledata?.filter((e) => e.CreatedDate < DateF)
              : DateF != null
                ? tabledata?.filter((e) => e.CreatedDate == DateF) : name != "" && priiortyfilter != "" && statusfilter != "" && cateoryff != "" && DateF != null ? tabledata?.filter((E) => E.CreatedByDetails.FN + " " + E.CreatedByDetails.LN == name && E.Priority.PriortyCode == priiortyfilter && E.CurrentState.StateName == statusfilter && E.CategoryCode.CategoryName == cateoryff && E.CreatedDate < DateF && E.CreatedDate == DateF)
                  : tabledata;


  let temp = {};
  temp =
    check == null
      ? ""
      : check.sort((j, k) => (j.IncidentId > k.IncidentId ? 1 : -1)).map((e, datarowe, i, event) => {

        let data = sla.filter((g) => g.IncidentId == e.IncidentId);

        return {
          ...e,
          verify: e.IsUserVerified,
          LabelP: e.Priority.PriortyCode,
          Acktime: data[0]?.AcknowledgementTimeTaken,
          ResTime: data[0]?.ResolutionTimeTaken,
          StatusHistory: data[0]?.StatusHistory,
          linkedwithproblem: e.isLinkedtoproblem?.islinked,
          AssignedToDetails:
            e.AssignedToDetails.FN + " " + e.AssignedToDetails.LN,
          CurrentState1:
            data != "" ?
              e.CurrentState.StateCode == "New" ? "New" : e.CurrentState.StateCode == "Acknowledged" ? "Acknowledged" :
                e.CurrentState.StateCode == "Work in progress" ? "Work in progress"
                  : e.CurrentState.StateCode == "User Verfication" ? "User Verfication" :
                    e.CurrentState.StateCode == "User verified" ? "User verified" :
                      e.CurrentState.StateCode == "Resolved" ? "Resolved"
                        : "New" : "New",

          bug: smrole == "SM" && e.CurrentState.StateCode == "User verified" || data[0]?.IsSLAMet === "Y" ? <div className="mt-1 ml-1">
            <button className="ml-2 mt-2"
              onClick={() => {

                createbug(e);
              }}
              disabled={e.IsBugTaskCreated == "Y"}
              hidden={e.IsBugTaskCreated == "Y"}
              style={{

                height: 30,
                borderRadius: 5,
                background: "rgb(246, 202, 204)",
                padding: ".3rem .5rem",
                marginBottom: "5px",
                fontWeight: "bold",
                color: "rgb(208, 34, 36)",

              }}
            >
              Create as Bug
            </button>
            {/* } */}

          </div> : null,
          Outlier:
            data == "" ? (
              ""
            ) : data[0].OutlierReason != null ? (
              ""
            ) : data[0].IsOutlierRequested == "Y" ||
              (data[0].IsSLAMet == "N" &&
                data[0].ResolutionTimeTaken != null) ? (
              <button
                className="Card_Button_Color_Approve"
                style={{
                  fontSize: 9,
                  fontWeight: 600,
                  width: 60,
                  borderRadius: 5,
                  backgroundColor: "#80ffcc",
                  // color:"white",
                  padding: 2,
                }}
                onClick={() => handleoutlier(e.IncidentId)}
              >
                Request
              </button>
            ) : (
              ""
            ),
          AcknowledgementDate: data[0]?.AcknowledgementDate,
          RefrenceNumber: e.IncidentReference,
          PauseSLAClockDetails: data[0]?.PauseSLAClockDetails,
          Acknowledgement:
            data == "" ? (
              ""
            ) : data[0].IsAcknowledged == "Y" ? (
              "A"
            ) : data[0].IsAcknowledged == "N" ? (
              "N"
            ) : (
              "M"
            )
          ,
          Resoloved: data === "" ? (
            ""
          ) : data[0]?.IsSLAMet == "Y" ? (
            "Y"
          ) : data[0]?.IsSLAMet == "N" ? (
            "N"
          ) : (
            "M"
          ),
          data1: (
            <>
              {data == "" ? (
                ""
              ) : data[0].IsSLAMet == "Y" ? (
                <span
                  class="badge rounded-pill bagdgreen text-white mr-1 ml-3"
                  style={{ color: "#000000", fontWeight: "bold" }}
                >
                  <Tooltip title="Acknowledge" placement="top">
                    A
                  </Tooltip>
                </span>
              ) : data[0].IsSLAMet == "N" ? (
                <span
                  class="badge rounded-pill text-white bagdred mr-1  ml-3"
                  style={{ color: "#F76666", fontWeight: "bold" }}
                >
                  <Tooltip title="Acknowledge" placement="top" style={{ color: "#F76666" }}>
                    A
                  </Tooltip>
                </span>
              ) : (
                <span
                  class="badge rounded-pill bgyl mr-1  ml-3"
                  style={{ color: "#000000", fontWeight: "bold" }}
                >
                  <Tooltip title="Acknowledge" placement="top">
                    A
                  </Tooltip>
                </span>
              )}
              {data == "" ? (
                ""
              ) : data[0].IsOutlierRequested == "N" ? (
                <span
                  class="badge rounded-pill text-white bagdgreen  ml-3"
                  style={{ color: "#000000", fontWeight: "bold" }}
                >
                  <Tooltip title="Resolve" placement="top">
                    R
                  </Tooltip>
                </span>
              ) : data[0].IsOutlierRequested == "Y" ? (
                <span
                  class="badge rounded-pill text-white bagdred  ml-3"
                  style={{ color: "#F76666", fontWeight: "bold" }}
                >
                  <Tooltip title="Resolve" placement="top" style={{ color: "#F76666" }}>
                    R
                  </Tooltip>
                </span>
              ) : (
                <span
                  class="badge rounded-pill bgyl  ml-3"
                  style={{ color: "#000000", fontWeight: "bold" }}
                >
                  <Tooltip title="Resolve" placement="top">
                    R
                  </Tooltip>
                </span>
              )}
            </>
          ),
          data2:
            data == "" ? (
              ""
            ) : data[0].IsOutlierRequested == "N" ? (
              <span
                class="badge rounded-pill bagdgreen"
                style={{ color: "#000000", fontWeight: "bold" }}
              >
                R
              </span>
            ) : data[0].IsOutlierRequested == "Y" ? (
              <span
                class="badge rounded-pill text-white bagdred"
                style={{ color: "#F76666", fontWeight: "bold" }}
              >
                R
              </span>
            ) : (
              <span
                class="badge rounded-pill bgyl"
                style={{ color: "#000000", fontWeight: "bold" }}
              >
                R
              </span>
            ),
          data:
            data == "" ? (
              ""
            ) : data[0].IsSLAMet == "Y" ? (
              <span class="badge rounded-pill bagdgreen">Met</span>
            ) : data[0].IsSLAMet == "N" ? (
              <span class="badge rounded-pill text-white bagdred" style={{ color: "#F76666" }}>
                {"Breach(" + data[0].SLABreachDetails + "m)"}
              </span>
            ) : (
              ""
            ),
          Prioritydrop: (
            <div className="d-flex justify-space-between">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-1 ml-2">
                <path id="Vector" d="M8.44935 0H12.3499C12.7075 0 13 0.283317 13 0.629593C13 0.975869 12.7075 1.25919 12.3499 1.25919H8.44935C8.0918 1.25919 7.79925 0.975869 7.79925 0.629593C7.79925 0.283317 8.0918 0 8.44935 0ZM8.44935 3.46276H12.3499C12.7075 3.46276 13 3.74608 13 4.09235C13 4.43863 12.7075 4.72195 12.3499 4.72195H8.44935C8.0918 4.72195 7.79925 4.43863 7.79925 4.09235C7.79925 3.74608 8.0918 3.46276 8.44935 3.46276ZM8.44935 6.92552H12.3499C12.7075 6.92552 13 7.20884 13 7.55511C13 7.90139 12.7075 8.18471 12.3499 8.18471H8.44935C8.0918 8.18471 7.79925 7.90139 7.79925 7.55511C7.79925 7.20884 8.0918 6.92552 8.44935 6.92552ZM5.10137 8.90874L6.26503 7.78177C6.39505 7.65585 6.39505 7.46067 6.26503 7.33476L5.10137 6.20779C5.05655 6.16311 4.99893 6.13246 4.93595 6.11981C4.87297 6.10715 4.80752 6.11306 4.74804 6.13678C4.68857 6.1605 4.63779 6.20093 4.60228 6.25287C4.56676 6.3048 4.54813 6.36585 4.54879 6.42814V8.68838C4.54879 8.9654 4.89984 9.10391 5.10137 8.90874ZM4.54879 6.92552H4.35376C2.82604 6.92552 1.46084 5.84892 1.31132 4.37567C1.27135 3.98174 1.31688 3.58408 1.44499 3.2082C1.57309 2.83232 1.78095 2.48655 2.05521 2.19307C2.32947 1.8996 2.66407 1.66491 3.03752 1.50408C3.41097 1.34325 3.81503 1.25983 4.22374 1.25919H5.84897C6.20652 1.25919 6.49907 0.975869 6.49907 0.629593C6.49907 0.283317 6.20652 0 5.84897 0H4.22374C1.71438 0 -0.300909 2.14062 0.0371396 4.6338C0.310179 6.69887 2.22795 8.18471 4.37326 8.18471H4.54879" fill="black" />

              </svg>

              <select
                key={i}
                className="fontdesign ssls ml-1"
                maxWidth="50px"
                title="Priority"
                defaultValue={e.Priority.PriortyCode}
                value={Prior[i]}
                disabled={e.CurrentState.StateCode === "Resolved"}
                onChange={(e, i) => priorchange(e, data, i)}
              >
                <option value="P1" label="Priority 1">P1</option>
                <option value="P2" label="Priority 2">P2</option>
                <option value="P3" label="Priority 3">P3</option>
              </select>
            </div>
          ),
          notesdrop: (
            <button
              variant="outline-secondary"
              size="sm"
              fontSize="small"
              // style={{ fontSize: "17px",alignContent:"start" }}
              className="btn btn-text-dark  btn-sm fontdesign mt-1 "
              style={{ textAlign: "left" }}
              title="Notes"
              onClick={() => {
                dispatch(INDETAILSUPDATE(e.IncidentId));
                setopen(true)
              }}
            >
              <SpeakerNotesOutlinedIcon
                className="Card_Button_Color_Approve  "
                style={{
                  color: "black",
                  fontSize: "16px",

                }}
              ></SpeakerNotesOutlinedIcon>{" "}Notes
            </button>
          ),
          Impactdrop: (
            <div className="d-flex justify-space-between">
              <span className="mdi mdi-checkbox-blank-circle ml-3" style={{ color: "#45DD81", fontSize: "9px" }}></span>
              <select
                className="fontdesign ssls ml-2 mb-2"
                defaultValue={e?.Impact?.ImpactName}
                maxWidth="50px"

                value={impacts[i]}
                disabled={e.CurrentState.StateCode == "Resolved"}
                onChange={(e, i) => impactchange(e, data)}
                title="Impact"
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
          ),
          StateDrop: (
            <div className="d-flex justify-space-between">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-1 ml-2">
                <path id="Vector" d="M8.44935 0H12.3499C12.7075 0 13 0.283317 13 0.629593C13 0.975869 12.7075 1.25919 12.3499 1.25919H8.44935C8.0918 1.25919 7.79925 0.975869 7.79925 0.629593C7.79925 0.283317 8.0918 0 8.44935 0ZM8.44935 3.46276H12.3499C12.7075 3.46276 13 3.74608 13 4.09235C13 4.43863 12.7075 4.72195 12.3499 4.72195H8.44935C8.0918 4.72195 7.79925 4.43863 7.79925 4.09235C7.79925 3.74608 8.0918 3.46276 8.44935 3.46276ZM8.44935 6.92552H12.3499C12.7075 6.92552 13 7.20884 13 7.55511C13 7.90139 12.7075 8.18471 12.3499 8.18471H8.44935C8.0918 8.18471 7.79925 7.90139 7.79925 7.55511C7.79925 7.20884 8.0918 6.92552 8.44935 6.92552ZM5.10137 8.90874L6.26503 7.78177C6.39505 7.65585 6.39505 7.46067 6.26503 7.33476L5.10137 6.20779C5.05655 6.16311 4.99893 6.13246 4.93595 6.11981C4.87297 6.10715 4.80752 6.11306 4.74804 6.13678C4.68857 6.1605 4.63779 6.20093 4.60228 6.25287C4.56676 6.3048 4.54813 6.36585 4.54879 6.42814V8.68838C4.54879 8.9654 4.89984 9.10391 5.10137 8.90874ZM4.54879 6.92552H4.35376C2.82604 6.92552 1.46084 5.84892 1.31132 4.37567C1.27135 3.98174 1.31688 3.58408 1.44499 3.2082C1.57309 2.83232 1.78095 2.48655 2.05521 2.19307C2.32947 1.8996 2.66407 1.66491 3.03752 1.50408C3.41097 1.34325 3.81503 1.25983 4.22374 1.25919H5.84897C6.20652 1.25919 6.49907 0.975869 6.49907 0.629593C6.49907 0.283317 6.20652 0 5.84897 0H4.22374C1.71438 0 -0.300909 2.14062 0.0371396 4.6338C0.310179 6.69887 2.22795 8.18471 4.37326 8.18471H4.54879" fill="black" />

              </svg>
              <select
                key={i}
                className="fontdesign ssls ml-1"
                title="Status"
                maxWidth="50px"

                defaultValue={e?.CurrentState?.StateName}
                disabled={e?.CurrentState?.StateName === "Resolved"}
                value={Status[i]}
                onChange={(e) => handleupdate(e, datarowe, data)}

              // onChange={e => setstatus(e.target.value, i)}
              >
                <option value="">New</option>
                <option value="Work in progress">Acknowledge</option>
                <option value="User Verfication">User Acceptance</option>
                <option value="Resolved">Resolved</option>
              </select>
            </div>
          ),
          CreatedByDetails:
            e.CreatedByDetails.FN + " " + e.CreatedByDetails.LN,
          // RaisingOnBehalfDetails: e.IsSelfRequest == "Y" ? "Self" : e.RaisingOnBehalfDetails,
          CreatedDate: moment(e.CreatedDate).format(" HH:mm A DD-MMM-YYYY"),
          CategoryCode: e.CategoryCode.CategoryName,
          SubCategoryCode: e.SubCategoryCode.SubCategoryName,
          Priority: e.Priority.PriortyCode,
          Urgency: e.Urgency.UrgencyName,

          avatarimage: (
            <Avatar alt="" className="CardAvartor" src={
              allImage?.length > 0 && allImage?.filter((x) => x.teammemberid == e.CreatedBy)?.[0]?.image != undefined ? allImage?.filter((x) => x.teammemberid == e.CreatedBy)?.[0]?.image : Unassigned
            } sx={{ width: 35, height: 35 }} />
          ),
          Impact: e?.Impact?.ImpactName,
          Impactname: e?.Impact?.ImpactName,
          PriortyCode: e?.Priority?.PriortyName,

          // New
          // Acknowledged
          // Work in progress
          // User Verfication
          // Resolved
          datavalid: e.CurrentState.StateCode,
          CurrentState:
            data != "" ? (
              data[0].IsResolved == "Y" &&
                data[0].IsOutlierRequested == "N" ? (
                <span
                  className="badge bg-success1 text-success mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  Completed
                </span>
              ) : data[0].IsResolved == "Y" &&
                data[0].IsOutlierRequested == "Y" &&
                data[0].IsOutlierApproved == "Y" ? (
                <span
                  className="badge bg-success1 text-success mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  Completed
                </span>
              ) : e.CurrentState.StateCode == "New" ? (
                <span
                  className="badge bg-dangerl text-danger mt-3 ml-3"
                  style={{ fontWeight: "bold" }}
                >
                  New
                </span>
              ) : e.CurrentState.StateCode == "Acknowledged" ? (
                <span
                  className="badge bg-info1 text-info mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  Ack
                </span>
              ) : e.CurrentState.StateCode == "Work in progress" ? (
                <>
                  <span
                    className="badge bg-info1 text-info mt-3"
                    style={{ fontWeight: "bold" }}
                  >
                    In progress
                  </span>
                </>
              ) : e.CurrentState.StateCode == "User Verfication" ? (
                <span
                  className="badge bg-warning1 text-warning mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  User Acceptance
                </span>
              ) : e.CurrentState.StateCode == "Resolved" ? (
                <span
                  className="badge bg-success1 text-success mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  Resolved
                </span>
              ) : (
                ""
              )
            ) : e.CurrentState.StateCode == "New" ? (
              <span
                className="badge bg-danger text-white mt-3"
                style={{ fontWeight: "bold" }}
              >
                New
              </span>
            ) : e.CurrentState.StateCode == "Acknowledged" ? (
              <span
                className="badge bg-info1 text-info mt-3"
                style={{ fontWeight: "bold" }}
              >
                Ack
              </span>
            ) : e.CurrentState.StateCode == "Work in progress" ? (
              <>
                <span
                  className="badge bg-info1 text-info mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  Work in progress
                </span>
              </>
            ) : e.CurrentState.StateCode == "User Verfication" ? (
              <span
                className="badge bg-warning1 text-white mt-3"
                style={{ fontWeight: "bold" }}
              >
                User Acceptance
              </span>
            ) : e.CurrentState.StateCode == "Resolved" ? (
              <span
                className="badge bg-success1 text-success mt-3"
                style={{ fontWeight: "bold" }}
              >
                Resolved
              </span>
            ) : (
              ""
            ),
        };
      });

  const Columns = [
    { title: "Title", field: "IncidentTitle" },
    { title: "Id", field: "IncidentId", defaultSort: "desc" },
    { title: "SLA", field: "data1" },

    // { title: "Resolved", field: "data2" },
    // {
    //   title: 'Full Name', // The merged column title
    //   render: rowData => `${rowData.data1} ${rowData.data2}`, // Merging firstName and lastName
    // },

    // { title: "Category", field: "CategoryCode" },
    // { title: "Sub Category", field: "SubCategoryCode" },
    // { title: "Raising On Behalf", field: "RaisingOnBehalfDetails" },
    { title: "Assigned By", field: "CreatedByDetails" },

    { title: "Priority ", field: "Priority" },
    // { title: "Urgency", field: "Urgency" },
    // { title: "Impact", field: "Impact" },
    {
      title: "Current State", field: "CurrentState1",
      render: (e) => (
        <label className={
          e.CurrentState1 === "New" ? 'text-danger' :
            e.CurrentState1 === "Acknowledged" ? 'text-info1' :
              e.CurrentState1 === "Work in progress" ? 'text-warning' :
                e.CurrentState1 === "User Verification" ? 'text-warning' :
                  e.CurrentState1 === "Resolved" ? 'text-success' :
                    ''
        }>
          <strong>
            {e.CurrentState1 === "New" ? "New" :
              e.CurrentState1 === "Acknowledged" ? "Acknowledged" :
                e.CurrentState1 === "Work in progress" ? "Work in progress" :
                  e.CurrentState1 === "User Verification" ? "User Verification" :
                    e.CurrentState1 === "Resolved" ? "Resolved" :
                      ""}
          </strong>
        </label>
      ),
    },
    // {title:"Acknowledged",field:"data"},
    { title: "", field: "Outlier" },
    { title: "Date & Time", field: "CreatedDate" },
    { title: "", field: "bug" }
  ];
  const handlestate = (e) => {
    if (StateId == "Resolved") {
      alert("Could Not be change the stage because it resolved");
    } else {
      let iddata = tabledata.filter((c) => c.IncidentId == StateId);

      setNotifyToID(iddata[0].CreatedBy);
      let M2notificationData = {
        CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
        CreatedBy: arr[0].ClientUserId, //current Login
        NotifyTo: NotifyToID, //buddy
        AudienceType: "Individual",
        Priority: "High",
        Subject: "Raise An Incient Updates",
        Description:
          arr[0].UserFirstname + " " + arr[0].UserLastname + ", changed the stage ",
        IsSeen: "N",
        Status: "New",
      };
      axios
        .post("notification?NotifyTo=eq." + NotifyToID, M2notificationData)
        .then((res) => {
          console.log(res);
          setNotifyToID("");
        })
        .catch((error) => console.log(error));
      let patchdata = {
        CurrentState: {
          StateCode: e,
          StateName: e,
        },
      };

      axios
        .patch("agilesaas_incident_details?IncidentId=eq." + StateId, patchdata)
        .then((response) => {
          toast.success(`Current State:` + " " + e + " " + "Updated", {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          });
          if (e == "User Verfication") {
            alert("Please Update Notes For ");
          }
          setRefresh(true);
        })
        .catch((error) => console.log(error));
    }
  };
  const handlestateRes = (e) => {
    if (StateId == "Resolved") {
      alert("Could Not be change the stage because it resolved");
    } else {
      let patchdata = {
        CurrentState: {
          StateCode: e,
          StateName: e,
        },
      };
      let datapro = tabledata.filter((e) => e.IncidentId == StateId);
      let validpro = datapro[0].Priority.PriortyCode;
      let Ak = catmaster.filter((x) => x.Priority == validpro);
      let SLAAK = sla.filter((x) => x.IncidentId == StateId);
      let mypausesla = SLAAK[0].PauseSLAClockDetails;
      // let MyResolutionSLA = Ak[0].ResolutionSLA;
      let myResolutionSLA = Ak[0].ResolutionSLA;

      let mycreateddate = moment(datapro[0].CreatedDate).format("HH-MM-SS");
      // Priority
      console.log(mypausesla);

      var startDate = moment(datapro[0].CreatedDate).format("DD");
      var endDate = moment().format("DD");
      var given1 = moment("2018-03-10", "YYYY-MM-DD");
      var given = moment(datapro[0].CreatedDate, "YYYY-MM-DD");
      var current = moment().startOf("day");
      let dayscalc = -moment.duration(given.diff(current)).asDays();

      console.log(given1, "Days=", given, current, dayscalc);
      // https://api.yozytech.com/agilesaas_incident_details?IncidentId=eq.6

      var startTime = moment(datapro[0].CreatedDate).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      console.log(startTime);

      var endTime = moment().format("DD/MM/YYYY HH:mm:ss");
      var startval = moment(datapro[0].CreatedDate).format("DD");
      var endTimeval = moment().format("DD");
      var ms = moment(startTime, "DD/MM/YYYY HH:mm:ss").diff(
        moment(endTime, "DD/MM/YYYY HH:mm:ss")
      );
      var d = -Math.round(moment.duration(ms) / 60000);
      var ack = d;
      var startval = moment(datapro[0].CreatedDate).format("DD");
      console.log(endTimeval, "endTimeval", startval, "startval");
      if (isNaN(d)) {
        alert("Please try again");
      } else {
        if (dayscalc > 0 || startval < endTimeval) {
          if (ack > 900 * dayscalc) {
            d = ack - 900 * dayscalc;
          } else {
            d = 900 * dayscalc - ack;
          }

          d = d - myResolutionSLA;

          console.log("It will Passed Next days", d);
          if (d > 0) {
            let patchRSLA = {
              IsResolved: "Y",
              ResolvedByDetails: {
                FN: arr[0].UserFirstname,
                LN: arr[0].UserLastname,
              },
              ResolvedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              ResolutionTimeTaken: ack,
              PauseSLAClockDetails: [
                ...mypausesla,
                {
                  StartDate: moment(given).format("YYYY-MM-DD"),
                  EndDate: moment(current).format("YYYY-MM-DD"),
                  PauseSLAClock: 900 * dayscalc,
                  SLA: "Resolved",
                },
              ],
              IsOutlierRequested: "Y",
              IsOutlierApproved: null,
              OutlierReason: null,
            };
            axios
              .patch(
                "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                patchRSLA
              )
              .then((response) => { })
              .catch((error) => console.log(error));
            axios
              .patch(
                "agilesaas_incident_details?IncidentId=eq." + StateId,
                patchdata
              )
              .then((response) => {
                toast.success(`Current State:` + " " + e + " " + "Updated", {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                setRefresh(true);
              })
              .catch((error) => console.log(error));
            console.log(patchRSLA, "patchRSLABreech");
          } else {
            let patchRSLA = {
              IsResolved: "Y",
              ResolvedByDetails: {
                FN: arr[0].UserFirstname,
                LN: arr[0].UserLastname,
              },
              ResolvedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              ResolutionTimeTaken: ack,
              IsOutlierRequested: "N",
              IsOutlierApproved: null,
              OutlierReason: null,
              PauseSLAClockDetails: [
                ...mypausesla,
                {
                  StartDate: moment(given).format("YYYY-MM-DD"),
                  EndDate: moment(current).format("YYYY-MM-DD"),
                  PauseSLAClock: 900 * dayscalc,
                  SLA: "Resolved",
                },
              ],
            };
            axios
              .patch(
                "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                patchRSLA
              )
              .then((response) => { })
              .catch((error) => console.log(error));
            axios
              .patch(
                "agilesaas_incident_details?IncidentId=eq." + StateId,
                patchdata
              )
              .then((response) => {
                toast.success(`Current State:` + " " + e + " " + "Updated", {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                setRefresh(true);
              })
              .catch((error) => console.log(error));
            console.log(patchRSLA, "patchRSLAmet");
          }
        } else {
          // console.log(endTimeval,startTime,endTime, "is Equal min");
          console.log(d);

          d = d - myResolutionSLA;
          if (d > 0) {
            let patchRSLA = {
              IsResolved: "Y",
              ResolvedByDetails: {
                FN: arr[0].UserFirstname,
                LN: arr[0].UserLastname,
              },
              ResolvedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              ResolutionTimeTaken: ack,
              IsOutlierRequested: "Y",
              IsOutlierApproved: null,
              OutlierReason: null,
            };
            axios
              .patch(
                "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                patchRSLA
              )
              .then((response) => { })
              .catch((error) => console.log(error));
            axios
              .patch(
                "agilesaas_incident_details?IncidentId=eq." + StateId,
                patchdata
              )
              .then((response) => {
                toast.success(`Current State:` + " " + e + " " + "Updated", {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                setRefresh(true);
              })
              .catch((error) => console.log(error));
            console.log(patchRSLA, "patchRSLAMet");
          } else {
            if (-d > 60) {
              d = -d / 60;
            }
            let patchRSLA = {
              IsResolved: "Y",
              ResolvedByDetails: {
                FN: arr[0].UserFirstname,
                LN: arr[0].UserLastname,
              },
              ResolvedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              ResolutionTimeTaken: ack,
              IsOutlierRequested: "N",
              IsOutlierApproved: null,
              OutlierReason: null,
            };
            axios
              .patch(
                "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                patchRSLA
              )
              .then((response) => { })
              .catch((error) => console.log(error));
            axios
              .patch(
                "agilesaas_incident_details?IncidentId=eq." + StateId,
                patchdata
              )
              .then((response) => {
                toast.success(`Current State:` + " " + e + " " + "Updated", {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                setRefresh(true);
              })
              .catch((error) => console.log(error));
            console.log(patchRSLA, "patchRSLABreach");

            // console.log("Acknowledged Breach",patchdatasla)
          }
        }
      }
    }
  };
  const handlestateAck = (e) => {
    if (StateId == "Resolved") {
      alert("Could Not be change the stage because it resolved");
    } else {
      let patchdata = {
        CurrentState: {
          StateCode: e,
          StateName: e,
        },
      };
      let datapro = tabledata.filter((e) => e.IncidentId == StateId);
      let validpro = datapro[0].Priority.PriortyCode;
      let Ak = catmaster.filter((x) => x.Priority == validpro);
      let MyAcknowledgementSLA = Ak[0].AcknowledgementSLA;
      let myResolutionSLA = Ak[0].ResolutionSLA;

      let mycreateddate = moment(datapro[0].CreatedDate).format("HH-MM-SS");
      // Priority
      console.log(datapro[0].CreatedDate);

      var startDate = moment(datapro[0].CreatedDate).format("DD");
      var endDate = moment().format("DD");
      var given1 = moment("2018-03-10", "YYYY-MM-DD");
      var given = moment(datapro[0].CreatedDate, "YYYY-MM-DD");
      var current = moment().startOf("day");
      let dayscalc = -moment.duration(given.diff(current)).asDays();

      console.log(given1, "Days=", given, current, dayscalc);
      // https://api.yozytech.com/agilesaas_incident_details?IncidentId=eq.6

      var startTime = moment(datapro[0].CreatedDate).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      console.log(startTime);
      var endTime = moment().format("DD/MM/YYYY HH:mm:ss");
      var startval = moment(datapro[0].CreatedDate).format("DD");
      var endTimeval = moment().format("DD");
      var ms = moment(startTime, "DD/MM/YYYY HH:mm:ss").diff(
        moment(endTime, "DD/MM/YYYY HH:mm:ss")
      );
      var d = -Math.round(moment.duration(ms) / 60000);
      var ack = d;
      console.log(ms, startTime, MyAcknowledgementSLA, d);
      if (isNaN(d)) {
        alert("Please try again");
      } else {
        if (dayscalc > 0) {
          if (ack > 900 * dayscalc) {
            d = ack - 900 * dayscalc;
          } else {
            d = 900 * dayscalc - ack;
          }
          d = d - MyAcknowledgementSLA;

          console.log("It will Passed Next days");
          console.log(-d);
          if (d > 0) {
            console.log("Enter");
            let patchdatasla1 = {
              IsAcknowledged: "Y",
              AcknowledgedByDetails: {
                FN: arr[0].UserFirstname,
                LN: arr[0].UserLastname,
              },
              AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              AcknowledgementTimeTaken: ack,
              PauseSLAClock: "Y",
              PauseSLAClockDetails: [
                {
                  StartDate: moment(given).format("YYYY-MM-DD"),
                  EndDate: moment(current).format("YYYY-MM-DD"),
                  PauseSLAClock: 900 * dayscalc,
                  SLA: "Acknowleged",
                },
              ],
              IsSLAMet: "N",
              SLABreachDetails: ack - 900 * dayscalc - MyAcknowledgementSLA,
              IsResolved: null,
              ResolvedByDetails: null,
              ResolvedDate: null,
              ResolutionTimeTaken: null,

              IsOutlierApproved: null,
              OutlierReason: null,
              IsSLAHolidayApplicable: "N",
              SLAHolidayApplicableReason: null,
              IsActive: null,
              Status: null,
              IsOutlierRequested: null,
            };
            console.log(
              "Acknowledged met",
              patchdatasla1,
              ack,
              ack - 900 * dayscalc - MyAcknowledgementSLA
            );
            axios
              .patch(
                "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                patchdatasla1
              )
              .then((response) => { })
              .catch((error) => console.log(error));
            axios
              .patch(
                "agilesaas_incident_details?IncidentId=eq." + StateId,
                patchdata
              )
              .then((response) => {
                toast.success(`Current State:` + " " + e + " " + "Updated", {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                setRefresh(true);
              })
              .catch((error) => console.log(error));
          } else {
            if (-d > 60) {
              d = -d / 60;
            }
            let brech = ack - 900 * dayscalc - MyAcknowledgementSLA;
            let patchdatasla2 = {
              IsAcknowledged: "Y",
              AcknowledgedByDetails: {
                FN: arr[0].UserFirstname,
                LN: arr[0].UserLastname,
              },
              AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              AcknowledgementTimeTaken: ack,
              PauseSLAClock: "Y",
              PauseSLAClockDetails: [
                {
                  StartDate: moment(given).format("YYYY-MM-DD"),
                  EndDate: moment(current).format("YYYY-MM-DD"),
                  PauseSLAClock: 900 * dayscalc,
                  SLA: "Acknowleged",
                },
              ],

              IsSLAMet: "Y",
              SLABreachDetails: null,
              IsResolved: null,
              ResolvedByDetails: null,
              ResolvedDate: null,
              ResolutionTimeTaken: null,

              IsOutlierApproved: null,
              OutlierReason: null,
              IsSLAHolidayApplicable: "N",
              SLAHolidayApplicableReason: null,
              IsActive: null,
              Status: null,
              IsOutlierRequested: null,
            };
            axios
              .patch(
                "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                patchdatasla2
              )
              .then((response) => { })
              .catch((error) => console.log(error));
            axios
              .patch(
                "agilesaas_incident_details?IncidentId=eq." + StateId,
                patchdata
              )
              .then((response) => {
                toast.success(`Current State:` + " " + e + " " + "Updated", {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                setRefresh(true);
              })
              .catch((error) => console.log(error));
            console.log("Acknowledged Breach", d, "hrs", patchdatasla2);
          }
        } else {
          // console.log(endTimeval,startTime,endTime, "is Equal min");
          console.log(d);
          d = d - MyAcknowledgementSLA;
          console.log(d <= 0);
          if (d <= 0) {
            console.log("Enter");
            let patchdatasla3 = {
              IsAcknowledged: "Y",
              AcknowledgedByDetails: {
                FN: arr[0].UserFirstname,
                LN: arr[0].UserLastname,
              },
              AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              AcknowledgementTimeTaken: ack,
              IsSLAMet: "Y",
              PauseSLAClock: "N",
              PauseSLAClockDetails: null,
              IsResolved: null,
              ResolvedByDetails: null,
              ResolvedDate: null,
              ResolutionTimeTaken: null,

              IsOutlierApproved: null,
              OutlierReason: null,
              IsSLAHolidayApplicable: "N",
              SLAHolidayApplicableReason: null,
              IsActive: null,
              Status: null,
              IsOutlierRequested: null,
            };
            axios
              .patch(
                "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                patchdatasla3
              )
              .then((response) => { })
              .catch((error) => console.log(error));
            axios
              .patch(
                "agilesaas_incident_details?IncidentId=eq." + StateId,
                patchdata
              )
              .then((response) => {
                toast.success(`Current State:` + " " + e + " " + "Updated", {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                setRefresh(true);
              })
              .catch((error) => console.log(error));
            console.log("Acknowledged met", patchdatasla3);
          } else {
            console.log("enter");

            let patchdatasla4 = {
              IsAcknowledged: "Y",
              AcknowledgedByDetails: {
                FN: arr[0].UserFirstname,
                LN: arr[0].UserLastname,
              },
              AcknowledgementDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              AcknowledgementTimeTaken: ack,
              SLABreachDetails:
                MyAcknowledgementSLA > ack
                  ? MyAcknowledgementSLA - ack
                  : ack - MyAcknowledgementSLA,
              PauseSLAClock: "N",
              IsSLAMet: "N",
              PauseSLAClockDetails: null,
              IsResolved: null,
              ResolvedByDetails: null,
              ResolvedDate: null,
              ResolutionTimeTaken: null,

              IsOutlierApproved: null,
              OutlierReason: null,
              IsSLAHolidayApplicable: "N",
              SLAHolidayApplicableReason: null,
              IsActive: null,
              Status: null,
              IsOutlierRequested: null,
            };
            axios
              .patch(
                "agilesaas_incident_sla_details?IncidentId=eq." + StateId,
                patchdatasla4
              )
              .then((response) => { })
              .catch((error) => console.log(error));
            axios
              .patch(
                "agilesaas_incident_details?IncidentId=eq." + StateId,
                patchdata
              )
              .then((response) => {
                toast.success(`Current State:` + " " + e + " " + "Updated", {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                setRefresh(true);
              })
              .catch((error) => console.log(error));
            console.log("Acknowledged Breach", patchdatasla4);
          }
        }
      }

      // axios
      //     .patch("agilesaas_incident_details?IncidentId=eq." + StateId, patchdata).then((response) => {
      //         toast.success(`Current State:` + " " + e + " " + "Updated", {
      //             transition:Slide,
      //    position: toast.POSITION.TOP_RIGHT,
      //             autoClose: 3000,
      //             draggable: true,
      //         })
      //         setRefresh(true);

      //     })
      //     .catch((error) => console.log(error),
      // );
    }
  };

  const handleClickOpen1 = (data, z) => {
    // let mydtaack=dataack.map((e)=>{
    //     return{
    //         "Ac"
    //     }
    // })

    let ndata = data.UpdateNotes;

    let sts = data.datavalid;
    let re = data.verify;
    setchkre(re);

    setAssignId("Assigne");
    setStateData(ndata);
    setStateId(z);
    setStatusUp(sts);

    setopen(true);
    // setStatusUp(false);
  };

  const handleSelectChange = (e, rowData) => {
    let selectedvalue = e.target.value;
    setstatus(selectedvalue);
    console.log("selectedvalue");
    setStateId(e.IncidentId);
    handleupdate(); // Call the PATCH API with the selected value and index
  };
  const impactchange = (e, data, i) => {
    let nn = e.target.value;

    setImpacts(nn);

    let patchdata = {
      Impact: {
        ImpactCode:
          nn == "High" ? "1" : nn == "Medium" ? "2" : nn == "Low" ? "3" : null,
        ImpactName: nn,
      },
    };

    axios
      .patch(
        "agilesaas_incident_details?IncidentId=eq." + data[0].IncidentId,
        patchdata
      )
      .then((response) => {
        console.log("🚀 ~ file: MyIncidentQueue.js:1770 ~ .then ~ c̥:");
        // setRefresh(true);
      })
      .catch((error) => console.log(error));
    Refreshdata()
  };
  const priorchange = (e, data, i) => {
    let pss = e.target.value;

    setPrior(pss);

    let patchdatapro = {
      Priority: {
        PriortyName: pss,
        PriortyCode:
          pss == "P1"
            ? "System down"
            : pss == "P2"
              ? "Wide impact"
              : pss == "P3"
                ? "Moderate impact"
                : null,
      },
    };
    axios
      .patch(
        "agilesaas_incident_details?IncidentId=eq." + data[0].IncidentId,
        patchdatapro
      )
      .then((response) => {
        console.log("🚀 ~ file: MyIncidentQueue.js:1770 ~ .then ~ c̥:");
        // setRefresh(true);
      })
      .catch((error) => console.log(error));
    Refreshdata()
  };
  const viewoption = [
    { label: "CardView", value: "cardview" },
    { label: "TabelView", value: "tabelview" },
  ];

  const handleClickOpen = (x) => {
    if (x == "Resolved") {
      setStateId("Resolved");
    } else {
      setStateId(x);
    }
  };
  let PriorityOption = [
    // 1 - System down, 2 - Wide impact ,3 - Moderate impact , 4 - Low impact
    { value: "P1", label: "P1-System down", code: "System down" },
    { value: "P2", label: "P2-Wide impact", code: "Wide impact" },
    { value: "P3", label: "P3-Moderate impact", code: "Moderate impact" },
    { value: "P4", label: "P4-Low impact", code: "Low impact" }
    // { value: "P4", label: "P4-Low impact", code: "Low impact" }
  ];
  let statusoption = [
    // 1 - System down, 2 - Wide impact ,3 - Moderate impact , 4 - Low impact
    { value: "", label: "New" },
    { value: "Acknowledge", label: "Acknowledge" },
    { value: "Resolved", label: "Resolved" },
    { value: "User Verfication", label: "User Verfication" },

    // { value: "P4", label: "P4-Low impact", code: "Low impact" }
  ];
  let date = moment();

  let dateoption = [
    {
      value: moment().subtract(1, "months").startOf("month"),
      label: "OnemonthBefore",
    },
    {
      value: moment().subtract(1, "days").startOf("day"),
      label: "Yesterday",
    },

    { value: moment(), label: "Today" },
  ];
  function clear() {
    setname("");
    SetPriortiryf("");
    setstatusfilter("");
    Setcategoryff("");
    SetDateF(null);
  }
  function createbug(e) {
    // setButtonClicked(true);
    let postData = {
      ProjectId: projectCode,
      ProjectShortCode: prjshcode[0]?.projectshortcode,
      IssueType: "Bug",
      IssueTitle: `${e.IncidentTitle} - ${e.IncidentReference} `,
      Description: `Created from Incident with Refnumber ${e.IncidentReference} \n ${e.Description}`,
      Priority: "Highest",
      PlannedRelease: null,
      EstimateUnit: null,
      EstimateUnitDesc: null,
      CurrentStage: [
        {
          StageCode: "BLG",
          StageName: "Backlog",
          DateMoved: moment().format("YYYY-MM-DD"),
        },
      ],
      LinkToEpic: null,
      LinkToIssues: null,
      CreatedBy: arr[0]?.ClientUserId,
      CreatedByDetails:
        role?.RoleName == "Undefined" ? "Kanban Admin" : role?.RoleName,
      CreatedDate: moment().format("YYYY-MM-DD"),
      AssignedTo: null,
      AssignedToDetails: {
        FN: null,
        LN: null,
      },
      AssignedDate: moment().format("YYYY-MM-DD"),
      Attachment: null,
      IsActive: "Y",
      ExceptiontoSkipSPEstimation: "N",
      ProjectDetails: [{ ProjName: prjshcode[0]?.projectname, Client: clientid }],
      AssignedToUAT: null,
      AssignedToUATDetails: {
        FN: null,
        LN: null,
      },
      ClientId: clientid,
      AttachmentReference: null,
    };


    axios.post("agilesaas_issue_details", postData)
      .then((ress) => {

        axios.get("agilesaas_issue_details?ClientId=eq." + clientid)
          .then((response) => {
            const allRecords = response.data;

            allRecords.sort((a, b) => b.IssueId - a.IssueId);

            const lastPostedRecord = allRecords[0];

            let patchincidentbug = {
              "BugTaskDetails": {
                Project: prjshcode[0]?.projectname,
                TicketReference: lastPostedRecord.IssueId,
              },
              "IsBugTaskCreated": "Y"
            }
            axios.patch("agilesaas_incident_details?IncidentId=eq." + e.IncidentId, patchincidentbug).then((res) => {

              toast.success("Created as a bug");
              Refreshdata();
            }).catch((E) => {
              console.log("errorpatch", E)
            })

          });




      })
      .catch((error) => {
        // Handle errors here
        console.log("error")
      })

  }
  return (
    <div className=" mt-1 " style={{ backgroundColor: "#F5F7FB" }}>
      <div className="row mt-3">
        <div className="col-lg-9 col-md-7 col-sm-8">
          {/* <button
            variant="outline-secondary"
            size="sm"
            className="btn btn-outline-dark btn-sm fontdesign mt-3 ml-4"
            style={{
              fontWeight: "500",
              background: "transparent",
              color: "black",
              fontSize: "16px",
              width: "161px",
              height: "36px",
              border: "1px solid rgb(0, 0, 0 ,20%)",
              borderRadius: "3px ",
            }}
            onClick={() => setincident(true)}
          >
            <ControlPointIcon fontSize="small" /> Raise Incident
          </button> */}
        </div>
        <div className="col-lg-3 col-md-5 col-sm-4">
          <button
            variant="outline-secondary"
            size="sm"
            style={{
              fontWeight: "500",
              background: "transparent",
              color: "blue",
              borderRadius: "4px",
            }}
            className={view !== "cardview " ? "btn btn-outline-info btn-sm sidebutton mt-3 " : "btn btn-outline-dark btn-sm sidebutton mt-3"}
            onClick={() => setView("cardview")}
          >
            <ComputerIcon className="sidebtnicon" /> Card View
          </button>
          <button
            variant="outline-secondary"
            size="sm"
            className={view !== "tabelview " ? "btn btn-outline-info btn-sm sidebutton mt-3 ml-3" : "btn btn-outline-dark btn-sm sidebutton mt-3  ml-3"}
            onClick={() => setView("tabelview")}
            style={{
              fontWeight: "500",
              background: "transparent",
              color: "blue",
              borderRadius: "4px",
            }}
          >
            <TableChartOutlinedIcon className="sidebtnicon" /> Table VIew
          </button>
        </div>
      </div>
      <div className="ml-4 mt-2">
        {assignmentoption?.length === 1 ?
          <label style={{ fontWeight: "600" }}>Belonging To : {assignmentoption[0].label}</label> :
          <label style={{ fontWeight: "600" }}>Belonging To : <select
            onChange={(f) => {
              // gooto
              setGroupCode(f.target.value)
            }}
          >
            {assignmentoption?.map((e) => <option value={e.value}>{e.label}</option>)}
            <option value="0">all</option>
          </select></label>
        }
      </div>
      <div>
        <Row className="mt-3">
          <Col lg={9} md={9} sm={9}>
            {console.log(temp, "vicky")}
            {view == "cardview"
              ? temp === ""
                ? "" :
                temp && temp.length === 0 ? (
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 15 }}>
                    <SentimentDissatisfiedOutlinedIcon style={{ fontSize: "5rem" }} />
                    <h2>No Problems Found</h2>
                    <p>We couldn't find any problems matching your filter, or something went wrong.</p>
                    <button style={{
                      width: 100,
                      height: 36,
                      borderRadius: 5,
                      background: "#5250E5",
                      color: "#fff",
                    }} onClick={Refreshdata}>Try Again</button>
                  </div>
                )
                  : temp
                    .sort((m, l) => (m.IncidentId < l.IncidentId ? 1 : -1))
                    .filter((e) => e.CurrentState1 != "Resolved" && GroupCode === "0" ? e.AssignedGroup.GroupCode !== "" : e.AssignedGroup.GroupCode === GroupCode)
                    .map((e, item) => {
                      const isActiveCard = activeCardIndex === item;

                      return (
                        <div className=" ml-2 mt-2 " key={item}>

                          {/* devozy */}
                          <IncidentCard e={e} item={item} tabledata={tabledata?.filter((E) => E.IsActive == "Y")} setopen={setopen} AssingedIncident={true} Sla_Mater={Sla_Mater} Refreshdata={Refreshdata} />
                          {/* <Card
                          className={` ${isActiveCard
                              ? "active-card shadow-sm"
                              : "inactive-card shadow-sm"
                            }`}
                          onClick={() => handleClickCard(item, e)}
                        >
                          <Row style={{ marginTop: "-10px" }}>
                            <Col>
                              <Row className="d-flex justify-content-space between ">
                                {e.avatarimage}


                                <div>
                                  <label
                                    className="fontdesign ml-2 mt-1"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {e.CurrentState}
                                  </label>

                                  <label
                                    className="fontdesign ml-2 mt-1"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <span className="fontdesign" style={{ fontSize: "12px" }}>  SLA </span> :  {e.data1}
                                  </label>
                                  <br></br>
                                  <label className="fontdesign mt-1 ml-3 title">
                                    {e.IncidentTitle}
                                  </label>
                                  <br></br>
                                  <span
                                    className="fontdesign mt-1 ml-3"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <label>{e.CreatedByDetails}</label>{" "}
                                    <label className="ml-3">
                                      (
                                      {e.RaisingOnBehalfDetails == null
                                        ? "Self"
                                        : "Someone:" +
                                        "On Behalf : " +
                                        e.RaisingOnBehalfDetails}
                                      )
                                    </label>{" "}
                                    <label className="ml-3">{e.Priority}</label>
                                  </span>
                                  <div className="">
                                    <span
                                      className="fontdesign  d-flex justify-content-space-between"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <label className="ml-3 mt-1">
                                        <label>Created : {e.CreatedDate}</label>
                                        <CircleIcon
                                          className="ml-2"
                                          style={{ fontSize: "5px" }}
                                        ></CircleIcon>
                                        <label className="ml-2">
                                          Assigned To :{" "}
                                          {e.AssignedGroup.GroupCode}{" "}
                                        </label>
                                        <CircleIcon
                                          className="ml-2"
                                          style={{ fontSize: "5px" }}
                                        ></CircleIcon>

                                        <label className="ml-2">
                                          {" "}
                                          Category :
                                          {tabledata
                                            .filter(
                                              (d) =>
                                                d.IncidentId == e.IncidentId
                                            )
                                            ?.map(
                                              (E) => E.CategoryCode.CategoryName
                                            )}
                                        </label>
                                      </label>
                                    </span>
                                  </div>
                                </div>

                              </Row>

                            </Col>
                            <div>
                              <Col
                                style={{
                                  width: "120px",
                                  marginTop: "30px",
                                  marginRight: "60px",
                                }}
                              >
                                <div className="mt-1">{e.Impactdrop}</div>
                                <div className="mt-1">
                                  {e.StateDrop}


                                </div>

                                <div className="mt-1">

                                  {e.Prioritydrop}

                                </div>

                                {e.notesdrop}

                              </Col>
                            </div>
                          </Row>
                        </Card> */}

                        </div>
                      );
                    })
              : null}
          </Col>
          <Col lg={3} md={3} sm={3}>
            {" "}
            {view == "cardview" ? (
              <div className="mt-2 mr-2">
                <Card style={{ border: "1px solid rgb(0, 0, 0 ,20%)" }}>
                  <Row className="mt-1">
                    <Col className="d-flex justify-content-start">
                      <span className="mt-2 ml-3" style={{ fontWeight: "bold" }}>
                        Filters
                      </span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={clear}
                        style={{
                          fontSize: 14,
                          padding: 9,
                          width: 100,
                          height: 40,
                          borderRadius: 8,
                          marginRight: 14,
                          border: "none",
                          outline: "none",
                          marginLeft: "35px",

                          backgroundColor: "#fff",
                          color: "#5250E5",
                          textAlign: "right",
                          textTransform: "none",
                        }}
                      >
                        {" "}
                        Clear{" "}
                      </Button>
                    </Col>
                  </Row>
                  <div style={{ alignItems: "center" }}>
                    <label
                      className="mt-3 ml-3 fontdesign "
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Assigned By
                    </label>
                    <Select
                      className="fontdesign  mt-1 ml-3 mr-3"
                      // styles={customStylesd}               
                      options={nameoptions}
                      placeholder="Any"
                      onChange={(item) => {
                        handlenamefilter(item);
                      }}
                      value={nameoptions && nameoptions?.filter((e) => e.label == name)}
                    >

                    </Select>
                  </div>
                  <div>
                    <label
                      className="mt-3 ml-3 fontdesign"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Priority
                    </label>
                    <Select
                      className="fontdesign  mt-1 ml-3 mr-3"
                      // styles={customStylesd}           
                      options={PriorityOption}
                      placeholder="Any"
                      onChange={(item) => {
                        handlepriofilter(item);
                      }}
                      value={PriorityOption?.filter(
                        (e) => e.value === priiortyfilter
                      )}
                    ></Select>
                    <label
                      className="mt-3 ml-3 fontdesign"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Status
                    </label>

                    <Select
                      className="fontdesign  mt-1 ml-3 mr-3"
                      // styles={customStylesd}           
                      placeholder="Any"
                      options={statusoption}
                      onChange={(item) => {
                        handlestatusfilter(item);
                      }}
                      value={statusoption?.filter(
                        (e) => e.label === statusfilter
                      )}
                    ></Select>
                    <label
                      className="mt-3 ml-3 fontdesign"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Created
                    </label>
                    <Select
                      className="fontdesign  mt-1 ml-3 mr-3"
                      // styles={customStylesd}           
                      placeholder="Any"
                      options={dateoption}
                      onChange={(item) => {
                        datefilter(item);
                      }}
                      value={DateF ? dateoption?.find((e) => e.value === DateF) : null}
                    ></Select>
                    <label
                      className="mt-3 ml-3 fontdesign"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Category
                    </label>
                    <Select
                      className="fontdesign  mt-1 ml-3 mr-3 mb-3"
                      // styles={customStylesd}           
                      options={categoryoption}
                      onChange={(item) => {
                        categoryfilter(item);
                      }}
                      value={
                        categoryoption &&
                        categoryoption?.filter((e) => e.label === cateoryff)
                      }
                      placeholder="Any"
                    ></Select>
                  </div>
                </Card>
              </div>
            ) : null}
          </Col>

        </Row >
      </div>
      {view == "tabelview" ? (
        <div className="mt-3">
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          ></link>
          <MaterialTable
            title={"Incidents"}
            columns={Columns.map((e) => e)}
            data={temp === "" ? "" : temp.map((e) => e)}
            detailPanel={(rowdata) => {
              return (
                <div className="mt-1" style={{ backgroundColor: "#ebedf2" }}>
                  <div
                    style={{
                      fontSize: 13,
                      color: "white",
                      backgroundColor: "#ebedf2",
                    }}
                  >
                    <Table
                      class="table table-bordered"
                      style={{
                        fontSize: "10px",
                        fontFamily: "DM Sans, sans-serif",
                      }}
                    >
                      <thead
                        className="tableHeader"
                        style={{
                          fontSize: "10px",
                          fontFamily: "DM Sans, sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        {/* <th>Title</th> */}
                        <th style={{ fontWeight: "bold" }}>Description</th>
                        <th style={{ fontWeight: "bold" }}>
                          Raising on Behalf
                        </th>
                        <th style={{ fontWeight: "bold" }}>Category</th>
                        <th style={{ fontWeight: "bold" }}>SubCategory</th>
                        <th style={{ fontWeight: "bold" }}>
                          {" "}
                          Service Affected
                        </th>
                        <th style={{ fontWeight: "bold" }}>AssignTo</th>
                        {/* <th>Impact</th> */}
                      </thead>

                      <tr>
                        {/* <td>{rowdata.IncidentTitle}</td> */}
                        <td> {rowdata.Description}</td>
                        <td>
                          {" "}
                          {rowdata.IsSelfRequest == "Y"
                            ? "Self"
                            : rowdata.RaisingOnBehalfDetails}
                        </td>
                        <td>
                          {tabledata
                            .filter((d) => d.IncidentId == rowdata.IncidentId)
                            ?.map((E) => E.CategoryCode.CategoryName)}
                        </td>
                        {/* <td>{moment(rowdata.CreatedDate).format('DD-MM-YYYY HH:mm:ss')}</td> */}
                        <td>
                          {" "}
                          {tabledata
                            .filter((d) => d.IncidentId == rowdata.IncidentId)
                            ?.map((E) => E.SubCategoryCode.SubCategoryName)}
                        </td>
                        <td>{rowdata.ServiceAffected.ServiceName}</td>
                        <td>
                          {rowdata.AssignedToDetails
                          }
                        </td>


                        {/* <td>{rowdata.Impact.ImpactName}</td> */}
                      </tr>
                    </Table>
                  </div>
                  {/* <Row className="d-flex justify-content-space between" >
                    <Col>
                    <label className="drop">Description : </label>{" "}
                    <label className="droptext">{rowdata.Description}</label>
                    <label className="drop ml-5">Category : </label>{" "}
                    <label className="droptext">
                      {" "}
                      {tabledata
                        .filter((d) => d.IncidentId == rowdata.IncidentId)
                        ?.map((E) => E.CategoryCode.CategoryName)}
                    </label>
                    <label className="drop" style={{marginLeft:"100px"}}>AssignTo : </label>{" "}
                    <label className="droptext"> {rowdata.AssignedToDetails.FN+" "+rowdata.AssignedToDetails.LN}</label>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-space between">
                    <Col>
                    <label className="drop ">OnBehalf : </label>{" "}
                    <label className="droptext">
                      {rowdata.IsSelfRequest == "Y"
                        ? "Self"
                        : rowdata.RaisingOnBehalfDetails}
                    </label>
                    <label className="drop "  style={{marginLeft:"100px"}} >SubCategory : </label>{" "}
                    <label className="droptext">
                      {" "}
                      {tabledata
                        .filter((d) => d.IncidentId == rowdata.IncidentId)
                        ?.map((E) => E.SubCategoryCode.SubCategoryName)}
                    </label>
                    <label className="drop"  style={{marginLeft:"40px"}}>Service Affected : </label>{" "}
                    <label className="droptext">
                      {" "}
                      {rowdata.ServiceAffected.ServiceName}
                    </label>
                    </Col>
                  </Row> */}
                </div>
              );
            }}
            components={{
              Pagination: PatchedPagination,
            }}
            options={{
              actionsColumnIndex: -1,
              exportAllData: true,
              exportButton: true,
              columnResizable: true,
              filtering: false,
              sorting: true,
              paging: true,
              pageSize: 20, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [6, 12, 20, 50], // rows selection options

              // rowStyle: rowData => ({
              //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              // }),
              headerStyle: {
                fontWeight: "700",
                fontSize: "medium",

                // backgroundColor: "#eef2f7",
                color: "black",
                width: "calc(250px)",
                padding: "12px ",
                textAlign: "left",
                whiteSpace: "nowrap",
              },
              cellStyle: {
                textAlign: "left",
                fontSize: "medium",
                padding: "21px",
                color: "#6c757d",
                // padding: "15px 2px",
              },
              // headerStyle: {
              //     padding: "2px",
              //     fontWeight:"700",
              //     fontSize: "small",
              //     textAlign:"center",
              //     backgroundColor: '#107aeb85',
              //     color: '#03256c',

              // },
              // cellStyle: {
              //     textAlign: "center",
              //     fontSize: "11px",
              //     padding: "2px",
              //     color: '#03256c',
              // },
              // rowStyle: (rowdata) => {
              //   if (rowdata.tableData.id % 2 != 0) {
              //     return { backgroundColor: "#f6f7fb" };
              //   }
              // },
            }}
            actions={[
              (rowData) => ({
                icon: () => (
                  <SpeakerNotesOutlinedIcon
                    className="Card_Button_Color_Approve1"
                    style={{
                      color: "rgb(25, 138, 227)",
                    }}
                  >
                    {" "}
                    Notes{" "}
                  </SpeakerNotesOutlinedIcon>
                ),
                tooltip: "Update Notes",
                // disabled: rowData.isrequestfulfilled == "Y",
                onClick: (e, data) => {
                  console.log(data, "data");
                  handleClickOpen1(data, data.IncidentId);
                },
              }),
              // (rowData) => ({

              //     icon: () => (

              //         <UncontrolledDropdown>
              //             <DropdownToggle color="default" nav onClick={(e) => {

              //                 e.preventDefault()}
              //             }
              //             >
              //                 <button className="Card_Button_Color_Approve" style={{
              //                     fontSize: 9, fontWeight: 600, width: 70, borderRadius:
              //                         20, padding: 2,
              //                 }}>
              //                     {" "}
              //                     Current Stage{" "}
              //                 </button>

              //             </DropdownToggle>
              //             <DropdownMenu className="dropdown-navbar" right tag="ul"

              //             // options={stateoption}
              //             >
              //                 <DropdownItem tag="button" onClick={() => handlestate("New")}>New</DropdownItem>
              //                 <DropdownItem tag="button" onClick={() => handlestateAck("Acknowledged")}>Acknowledged</DropdownItem>
              //                 <DropdownItem tag="button" onClick={() => handlestate("Work in progress")}>Work in progress</DropdownItem>
              //                 <DropdownItem tag="button" onClick={() => handlestate("User Verfication")}>User Verfication</DropdownItem>
              //                 <DropdownItem tag="button" onClick={() => handlestateRes("Resolved")}>Resolved</DropdownItem>

              //             </DropdownMenu>
              //         </UncontrolledDropdown>
              //         //
              //     ),
              //     tooltip: "Update Current Stage",
              //     //   disabled: rowData.isrequestfulfilled == "Y",
              //     onClick: (e, data) => {
              //         if(data.datavalid=="Resolved"){
              //             handleClickOpen("Resolved");
              //              }
              //              else{
              //         handleClickOpen(data.IncidentId);
              //              }
              //     },
              // }),
            ]}
          />
        </div>
      ) : null
      }
      <Dialog
        open={open}
        onClose={() => {
          setopen(false)
        }}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle >
          <div className="row">
            <div className="col d-flex justify-content-start"><label className="text-black fontdesign" style={{ fontWeight: "bold" }}>
              {"Details page - " + tabledata?.filter((e) => e.IncidentId === IncidentId)[0]?.IncidentReference}
            </label></div>
            <div className="col d-flex justify-content-end" role="button" onClick={() => setopen(false)}><CloseIcon /></div>
          </div>
        </DialogTitle>
        <hr style={lineStyle} />
        <DialogContent className="mt-3">
          <DetailsNotes
            IncidentDetails={tabledata?.filter((e) => e.IncidentId === IncidentId)[0]}
            SLA_Details={sla?.filter((e) => e.IncidentId === IncidentId)[0]}
            Refreshdata={Refreshdata}
            NotesLoading={NotesLoading}
            setNotesLoading={setNotesLoading}
            DropLoading={DropLoading}
            setDropLoading={setDropLoading}
            Sla_Mater={Sla_Mater}
            DeniedAccess={false}
            Userid={arr[0].ClientUserId}
            assignmentoption={assignmentoption}
            incidentnewId={incidentnewId}
          />
        </DialogContent>
      </Dialog>
      {/* <MyIncidentNotes
        open={open}
        setopen={setopen}
        StateId={StateId}
        setStateId={setStateId}
        StateData={StateData}
        setStateData={setStateData}
        Refresh={Refresh}
        setRefresh={setRefresh}
        sla={sla}
        setsla={setsla}
        tabledata={tabledata}
        catmaster={catmaster}
        setcatmaster={setcatmaster}
        settabledata={settabledata}
        StatusUp={StatusUp}
        setStatusUp={setStatusUp}
        AssignId={AssignId}
        setAssignId={setAssignId}
        chkre={chkre}
        setchkre={setchkre}
      /> */}
      <div>
        <Dialog
          open={outlieropen}
          className="mainbox "
          onClose={handleoutlierClose}
          maxWidth="800px"
        >
          <DialogTitle className="bgcardMIQ">
            <Card className="bg_MyQueue bgcardMIQ">
              <div className="bg_MyQueue bgcardMIQ">
                <div className="head">
                  <center>
                    <label className="itrequest_fontRAt ml-2 mt-1">
                      <h3 className="modeltitle fontdesign"> Outlier Reason</h3>
                    </label>
                  </center>
                  <Box>
                    <IconButton onClick={handleoutlierClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </div>
              </div>
              <div className="bgcardMIQ">
                {/* <Row>
                                       <label className="myslafont ml-3">AcknowledgementTimeTaken </label>
                                          
                                       </Row> */}
                <Row>
                  <Col>
                    <label>AcknowledgementTimeTaken : </label>
                    <label className="text-danger">
                      {Acktime == ""
                        ? ""
                        : Acktime[0].AcknowledgementTimeTaken > 60
                          ? Math.round(Acktime[0].AcknowledgementTimeTaken / 60) +
                          "h"
                          : Acktime[0].AcknowledgementTimeTaken > 480
                            ? Math.round(
                              Acktime[0].AcknowledgementTimeTaken / 480
                            ) + "d"
                            : Acktime[0].AcknowledgementTimeTaken + "m"}
                    </label>
                  </Col>
                </Row>
                {/* <Row>
                                           <label className="myslafont ml-3">ResolvedTimeTaken </label>
                                           
                                       </Row> */}

                <Row>
                  <Col>
                    <label>ResolvedTimeTaken : </label>
                    <label className="text-danger">
                      {Acktime == ""
                        ? ""
                        : Acktime[0].ResolvedTimeTaken == null
                          ? "0"
                          : Acktime[0].ResolvedTimeTaken -
                            Acktime[0].PauseSLAClockDetails >
                            60
                            ? Math.round(
                              (Acktime[0].ResolvedTimeTaken -
                                Acktime[0].PauseSLAClockDetails) /
                              60
                            ) + "h"
                            : Acktime[0].ResolvedTimeTaken -
                              Acktime[0].PauseSLAClockDetails >
                              480
                              ? Math.round(
                                (Acktime[0].ResolvedTimeTaken -
                                  Acktime[0].PauseSLAClockDetails) /
                                480
                              ) + "d"
                              : Acktime[0].ResolvedTimeTaken -
                              Acktime[0].PauseSLAClockDetails +
                              "m"}
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col className="mt-1">
                    {" "}
                    <label>
                      <h5>Enter the Reason</h5>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <textarea
                      style={{
                        fontSize: "12px",
                        width: "270px",
                        height: "100px",
                      }}
                      onChange={(e) => setReasonOutlier(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <Button
                        style={{
                          fontSize: 14,
                          padding: 9,
                          width: 100,
                          height: 40,
                          borderRadius: 8,
                          marginRight: 14,
                          border: "2px solid #5250E51",
                          backgroundColor: "#5250E5",
                          color: "#fff",
                          textAlign: "center",
                          textTransform: "none",
                          marginLeft: "230px",
                        }}
                        onClick={() => handlerequest()}
                      >
                        Request
                      </Button>{" "}
                    </center>
                  </Col>
                </Row>
              </div>
            </Card>
          </DialogTitle>
        </Dialog>
      </div>
    </div >
  );
}

// date and time Refrence
// console.log(d.days() + ':' + d.hours() + ':' + d.minutes() + ':' + d.seconds());
// let dura=moment.duration(startTime-endTime);

// var now = moment("04/09/2013 15:00:00");
// var then = moment("04/09/2013 14:20:30");
// let datetime=moment.utc(moment(startTime,"DD/MM/YYYY HH:mm:ss").diff(moment(endTime,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
// var diff = moment.duration(moment(endTime).diff(moment(startTime)));
// var days = parseInt(diff.asDays()); //84

// var hours = parseInt(diff.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.

// hours = hours - days * 24;  // 23 hours

// var minutes = parseInt(diff.asMinutes()); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.

// minutes = minutes - (days * 24 * 60 + hours * 60); //20 minutes.
// console.log(startTime, endTime, minutes, days, hours, "is Equal min");
