import React, { useState, useEffect, useContext, useRef } from "react";
import { Row, Col } from "reactstrap";
import CreateKb from "./CreateKb";
import Select from "react-select";
import axios from "../../axios";
import KanbanDashboard from "./KanbanDashboard";
import ProgressHistory from "./ProgressUpdate/ProgressHistory";
import Release from "./Release";
import HistoryRelease from "./HistoryRelease";
import Report from "./Report/Report";
import Build from "./BuildPlanner/BuildPlanner";
import Scrumteam from "./ScrumTeam";
import AgileCeremonies from "./AgileCeremonies";
import Storypoints from "./Storypoints";
import CloseIcon from "@material-ui/icons/Close";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import {
  Box,
  Grid,
  IconButton,
  Card,
  TextField,
  FormLabel,
} from "@material-ui/core";
import SearchField from "react-search-field";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HistorySearch from "./HistorySearch";
import MapIcon from "@mui/icons-material/Map";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
// import { OverlayTrigger, Popover } from "react-bootstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import "./kanbanBoard.css";
import Axios from "axios";
import url from "./devEnj.json";
import Button from "react-bootstrap/Button";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import ChatIcon from "@material-ui/icons/Chat";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import TuneIcon from "@mui/icons-material/Tune";
import FilterListIcon from "@mui/icons-material/FilterList";
import NearMeIcon from "@mui/icons-material/NearMe";
import RestoreIcon from "@mui/icons-material/Restore";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Checkbox from "@mui/material/Checkbox";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Tooltip from "@mui/material/Tooltip";
import { Badge, Dropdown, Popover } from "react-bootstrap";
import OfflineBoltRoundedIcon from "@material-ui/icons/OfflineBoltRounded";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import Bookmark from "@material-ui/icons/Bookmark";
import Currentsprint from "./currentSprint";
import UserContext from "../../index";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Unassigned from "../../assets/images/avatar.png";
import agile from "../../assets/images/agile.png";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { setLocalStorageItem } from "../../LocalStorage";
import { useDispatch, useSelector } from "react-redux";
import TeamChartDesign from "./TeamChartDesign";
import {
  PROJECTROLENAMEUPDATE,
  PROJECTROLEUPDATE,
  PROJECTCREATE,
  FILTEREDKANBANDATA,
  PROLABEL,
  ISREPORT,
  ALLIMAGEUPDATE,
  KANBANBOARDDATAUPDATE,
  PROJECTDETAILSUPDATE,
  PROJECTCODEUPDATE,
  TESTERSIMAGEUPDATE,
  PROJECTMAPPINGDATAUPDATE,
  CONVIEWDATA,
  MINIMIZED,
  ISCREATEKB,
  ISMANAGERNOTES,
  ISPLANSPRINT,
  ISSPRINTDETAILS,
  PROJECTMODULES,
  RELEASESUPDATE,
  HANDLE_ON_CHANGE,
} from "../../redux/CommonInitialsate";
import AvatarImage from "./ProgressUpdate/AvatarImage";

import getImage from "../../getImage";
import Addnewuserproject from "./Addnewuserproject";
import { auto } from "@popperjs/core";

import ManagerNotes from "./ManagerNotes";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { alpha } from "@mui/material/styles";
import Timeline from "./Timeline/Timeline";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";
import Commitdetails from "./Commitdetails";
import CodeIcon from "@mui/icons-material/Code";
import {
  Tooltip as ReactBootstrapTooltip,
  OverlayTrigger,
} from "react-bootstrap";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import Refinement from "./Refinement/Refinement";

// import "./Custome.css";
function KanbanBoard({ token }) {
  const [Filtername, setFiltername] = useState([]);
  // const [projectRelease, setprojectRelease] = useState([]);
  // const [openCreate, setOpenCreate] = useState(false);
  // const [projectLabel, setProjectLabel] = useState();
  const [projectCode, setProjectCode] = useState(0);
  // const [projectDetails, setProjectDetails] = useState([]);
  // const [progressopen, setProgressopen] = useState(false);
  // const [projectId2, setProjectId2] = useState("");
  // const [projectName, setProjectName] = useState("");
  // const [client1, setClient1] = useState("");
  // const [client, setClient] = useState("");
  // const [SelectedCardId, setSelectedCardId] = useState("");
  // const [KanbanBoardData, setKanbanBoardData] = useState([]);
  // const [FilteredData, setFilteredData] = useState([]);
  // const [EmpDropDownData, setEmpDropDownData] = useState([]);
  // const [employeeList, setEmployeeList] = useState([]);
  // const [dropdown, setDropdown] = useState([]);
  // const [resData, setResData] = useState([]);
  // const [Refresh, setRefresh] = useState(false);
  // const [releasePopup, setReleasePopup] = useState(false);
  // const [Bfopen, setBfopen] = useState(false);
  // const [UATtesterD, setUATtesterD] = useState(false);
  // const [UATchecklistD, setUATchecklistD] = useState(false);
  // const [IssueId, setIssueId] = useState();
  // const [selectedCard, setselectedcard] = useState([]);
  // const [openCreateEst, setOpenCreateEst] = useState(false);
  // const [uatDropdown, setUatDropdown] = useState([]);
  // const [displaySubTask, setDisplaySubTask] = useState([]);
  // const [testerid, setTesterId] = useState([]);
  // const [releaseapi, setReleaseApi] = useState(false);
  // const [projectnamedetails, setProjectnamedetails] = useState([]);
  // const [testename, setTestername] = useState([]);
  const [userprojectshow, setUserprojectshow] = useState(true);
  // const [priority, setPriority] = useState("All");
  // const [epicLabel, setEpicLabel] = useState("All");
  // const [releasefilter, setreleasefilter] = useState("");
  // const [releaseLabel, setReleaseLabel] = useState(null);
  // const [releaseValue, setReleaseValue] = useState(null);
  const [currentopen, setcurrentopen] = useState(false);
  // const [historyrelease, sethistoryrelease] = useState(false);
  const [build, setbuild] = useState(false);
  const [reportview, setreportview] = useState(false);
  const [scrumteam, setscrumteam] = useState(false);
  // const [scrumchart, setscrumchart] = useState(false);
  // const [meeting, setmeeeting] = useState(false);
  const [valid, setvalid] = useState(null);
  // const [emp, setemp] = useState([]);
  // const [testerEpic, settesterEpic] = useState([]);
  // const [tenantId, setTenantId] = useState("");
  // const [status, setStatus] = useState("");
  const [role, setRole] = useState("");
  // const [projectTitle, setProjectTitle] = useState(null);
  // const [devozyToken, setDevozyToken] = useState("");
  // const [ceremoniesPopup, setCeremoniesPopup] = useState(true);
  // const [progressdata, setProgressData] = useState([]);
  // const [Assetoptions, setAssetoptions] = useState(null);
  const [labelClicked, setLabelClicked] = useState(false);
  // const [issueType, setIssueType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [KanbanMenu, setKanbanMenu] = useState([]);
  const [KanbanMenumain, setKanbanMenumain] = useState([]);

  const [manageNotesopen, setManageNoteopen] = useState(false);
  // const [releases, setReleases] = useState([]);
  // const [searchText, setSearchText] = useState("");
  const [projectLoading, setProjectLoading] = useState(false);
  // const [Historysearch, setHistorysearch] = useState(false);
  const [timeline, setTimeline] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]); // State to track if chat box is open
  const clientdetailss = JSON.parse(localStorage.getItem("Empdetails"));
  const loggeduser = clientdetailss?.[0]?.UserFirstname;
  const [incidentnewId, setincidentnewId] = useState("");
  // const [commitDialog, setCommitDialog] = useState(false)
  const [isLoadingb, setIsLoadingb] = useState(false);
  const [allTickets, setallTickets] = useState(true);
  const [isUnassignedChecked, setIsUnassignedChecked] = useState(false);
  // console.log(clientdetailss?.[0]?.UserDetails.Firstname);
  // const toggleChat = () => {
  //   setIsOpen(!isOpen);
  //   console.log(isOpen, "open"); // Toggle chat box state
  // };
  // const prole = localStorage.getItem(
  //   JSON.parse(localStorage.getItem("Rolecode"))
  // );
  // console.log(prole)

  // const Search = styled('div')(({ theme }) => ({
  //   // border: '1px solid black',

  //   position: 'relative',
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   '&:hover': {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  //   marginRight: theme.spacing(2),
  //   marginLeft: 0,
  //   overflow: 'hidden',
  //   maxWidth: 100,
  //   // width: '100%',
  //   // [theme.breakpoints.up('sm')]: {
  //   //   marginLeft: theme.spacing(3),
  //   //   width: 'auto',
  //   // },
  // }));

  // const SearchIconWrapper = styled('div')(({ theme }) => ({
  //   // padding: theme.spacing(0, 2),
  //   height: '100%',
  //   position: 'absolute',
  //   pointerEvents: 'none',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // }));

  // const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //   color: '#616670',
  //   fontSize: 11,
  //   '& .MuiInputBase-input': {
  //     padding: theme.spacing(1, 1, 1, 0),
  //     // vertical padding + font size from searchIcon
  //     paddingLeft: `calc(1em + ${theme.spacing(1)})`,
  //     transition: theme.transitions.create('width'),
  //     // width: '100%',
  //     [theme.breakpoints.up('md')]: {
  //       width: '20ch',
  //     },
  //   },
  // }));

  // console.log(priority, "priority", Assetoptions)
  //goto
  const labelStyle = {
    fontWeight: labelClicked ? "bold" : "normal",
    color: labelClicked ? "red" : "blue", // Change the color when clicked
    // Add any other styles you want here
  };
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == "11004")?.[0]
    ?.RoleCode;

  // const role = JSON.parse(localStorage.getItem("Rolecode")) == undefined ? "" : JSON.parse(localStorage.getItem("Rolecode"))

  const {
    projectRole,
    projectRoleName,
    allImage,
    KanbanBoardData,
    projectDetails,
    projectMappingData,
    openProCreate,
    ConViewData,
    ProLabel,
    data,
    isSuccess,
    loading,
    searchText,
    Assetoptions,
    SelectedCardId,
    FilteredData,
    Refresh,
    IssueId,
    client,
    client1,
    uatDropdown,
    progressdata,
    displaySubTask,
    resData,
    priority,
    releasefilter,
    epicLabel,
    releases1,
    releases,
    openCreate,
    projectLabel,
    employeeList,
    testerEpic,
    testename,
    releasePopup,
    projectRelease,
    commitDialog,
    EmpDropDownData,
    testerid,
    Historysearch,
    ceremoniesPopup,
    meeting,
    projectTitle,
    historyrelease,
    kbrefreshstate,
    releasedata,
    projectName,
    refinementpopup,
    IsRefinement
  } = useSelector((state) => state.user);
  const devozyrole = localStorage.getItem("devozyrole")
  const contextval = useContext(UserContext);
  // const context1 = useContext(UserContext);
  // let rolecodedata = localStorage.getItem("Rolecode") == "undefined" ? "" : JSON.parse(localStorage.getItem("Rolecode"))
  let clientd = JSON.parse(localStorage.getItem("clientid"));
  // let teamm = JSON.parse(localStorage.getItem("Empdetails"));
  contextval.setwindows("kanban");
  const dispatch = useDispatch();

  let arr =
    sessionStorage.getItem("Roledetails") == "undefined"
      ? ""
      : JSON.parse(sessionStorage.getItem("Roledetails"));
  let projectrole = JSON.parse(sessionStorage.getItem("ProjectRole"));

  // let clientd = JSON.parse(localStorage.getItem("clientid"));
  // console.log(
  //   ProLabel,
  //   projectName,
  //   FilteredData?.[0]?.ProjectId,
  //   projectCode,
  //   ""
  // );
  // console.log(allImage);

  useEffect(() => {
    console.log(KanbanBoardData?.filter((x) => x.IsActive == "Y" && x.ProjectId == projectCode))
    dispatch(
      HANDLE_ON_CHANGE({ name: "testerEpic", value: KanbanBoardData?.filter((x) => x.IsActive == "Y" && x.ProjectId == projectCode) })
    );
  }, [KanbanBoardData])

  useEffect(() => {
    // dispatch(SEARCHTEXT(""))
    // crtid == "undefined" ? dispatch(PROJECTCREATE(true)) : dispatch(PROJECTCREATE(false))
    axios
      .get(
        `subscription_client_users?OfficialEmail=eq.` +
        token +
        `&ClientId=eq.` +
        clientd
      )
      .then((res) => {
        let data = res.data?.map((e) => e.ClientUserId);
        axios
          .get(
            "rpc/fun_agilesaasmanagerteamprojdetails?managerid=" +
            crtid +
            "&clientid=" +
            clientd +
            "&teammemberid=eq." +
            data
          )
          .then((res) => {
            // setTestername(res.data);
            dispatch(HANDLE_ON_CHANGE({ name: "testename", value: res.data }));
          });
      });
    axios
      .get("agilesaas_menu_master?MenuType=eq.KanBoardActions")
      .then((res) => {
        setKanbanMenu(res.data);
      });
    // axios
    //   .get(
    //     // "agilesaas_menu_master?MenuType=neq.KanBoardActions&select=MenuId,MenuName,IconDetails&Level=eq.1"
    //     "subscription_customer_support"
    //   )
    //   .then((res) => {
    //     setKanbanMenumain(res.data);
    //     setOptions(
    //       res.data?.[0]?.ChatDetails.filter((e) => e.Level == 1)?.[0]?.Details
    //     );
    //   });

    // axios
    //   .get("agilesaas_incident_details")
    //   .then((response) => {
    //     let mysetdata;
    //     mysetdata = response.data.sort(
    //       (firstItem, secondItem) =>
    //         firstItem.IncidentId - secondItem.IncidentId
    //     );
    //     let nextva = mysetdata[mysetdata.length - 1];
    //     let incidentnewId1 = response.data.length + 1;
    //     let maxId = 0;
    //     response.data?.forEach((item) => {
    //       if (item.IncidentId > maxId) {
    //         maxId = item.IncidentId;
    //       }
    //     });
    //     setincidentnewId(maxId + 1);
    //     // console.log(incidentnewId)
    //   })
    //   .catch((error) => console.log(error));

    axios.get(api).then(async (res) => {
      // console.log(res.data, "get")
      if (res.data.length == 0) {
        setIsLoading(false);
        setProjectLoading(false);
        dispatch(PROJECTCREATE(true));
      }
      let data = res.data;
      dispatch(PROJECTMAPPINGDATAUPDATE(data));

      let getdata = [];
      const getrole = data.find((x) => x.teammemberid == crtid);
      // if (getrole?.projectrole?.RoleCode == "TL") {
      //   console.log(getrole, "ggggggggggggg");
      //   data
      //     .filter((x) => x.teammemberid == crtid)
      //     ?.map((item) => {
      //       let findItem = getdata.find(
      //         (x) => x.projectcode === item.projectcode
      //       );
      //       if (!findItem) getdata.push(item);
      //     });
      // } else {
      data?.map((item) => {
        let findItem = getdata.find((x) => x.projectcode === item.projectcode);
        if (!findItem) getdata.push(item);
      });
      // }
      dispatch(PROJECTDETAILSUPDATE(getdata));
      // const fdata =
      //   getrole?.projectrole?.RoleCode == "TL"
      //     ? res.data.filter((x) => x.teammemberid == crtid)
      //     : res.data;
      let projectcodec =
        ProLabel?.length == 0 || FilteredData?.[0]?.ProjectId != projectCode
          ? res.data
          : res.data?.filter((x) => x.projectname == ProLabel);
      // console.log(res?.data[0], "checkprojectcode", ProLabel, projectcodec);
      if (projectcodec != 0) {
        axios
          .get(
            `agilesaas_releases?ProjectId=eq.${projectcodec?.[0]?.projectcode}&order=SprintStartDate.asc`
          )
          .then((rele) => {
            // console.log(rele?.data)
            const filteredItems = rele?.data?.filter((item) => {
              const itemDate = moment(item.SprintEndDate, "DD-MM-YYYY");
              return (
                itemDate.isSameOrAfter(moment(), "day") &&
                item.IsReleaseFrozen == "N"
              );
            });
            // setReleases(rele.data);
            //         const itemDate = moment(item.date, 'DD-MM-YYYY');
            // return itemDate.isSameOrAfter(today, 'day');
            dispatch(
              HANDLE_ON_CHANGE({ name: "releasedata", value: rele.data })
            );
            dispatch(
              HANDLE_ON_CHANGE({ name: "releases1", value: filteredItems })
            );
          });
        let resdata = projectcodec?.filter(
          (x) => x.projectcode === projectcodec?.[0]?.projectcode
        );
        // console.log(
        //   resdata.length,
        //   "resdata",
        //   res.data.length,
        //   res.data?.[0].projectcode,
        //   resdata
        // );
        dispatch(
          HANDLE_ON_CHANGE({
            name: "Assetoptions",
            value: resdata.map((x) => {
              return {
                EmpId: x.teammemberid,
                label: x.firstname + " " + x.lastname,
                firstname: x.firstname,
                lastname: x.lastname,
                projectRole: x.projectrole?.RoleGroupId,
                value: false,
                value1: x.teammemberid,
                mailid: x.email,
              };
            }),
          })
        );
        // setAssetoptions(
        //   resdata.map((x) => {
        //     return {
        //       EmpId: x.teammemberid,
        //       label: x.firstname + " " + x.lastname,
        //       firstname: x.firstname,
        //       lastname: x.lastname,
        //       projectRole: x.projectrole?.RoleGroupId,
        //       value: false,
        //       value1: x.teammemberid,
        //       mailid: x.email,
        //     };
        //   })
        // );
        // setResData(projectcodec);
        dispatch(HANDLE_ON_CHANGE({ name: "resData", value: projectcodec }));
        dispatch(
          HANDLE_ON_CHANGE({
            name: "EmpDropDownData",
            value: resdata.map((x) => {
              return {
                allocationenddate: x.allocationenddate,
                allocationstartdate: x.allocationstartdate,
                allocpercentage: x.allocpercentage,
                companydetails: x.companydetails,
                companyid: x.companyid,
                email: x.email,
                firstname: x.firstname,
                lastname: x.lastname,
                managerid: x.managerid,
                mappedby: x.mappedby,
                mappedbydetails: x.mappedbydetails,
                onboardedby: x.onboardedby,
                onboardedbydetails: x.onboardedbydetails,
                onboardeddate: x.onboardeddate,
                phone: x.phone,
                projcreatedby: x.projcreatedby,
                projcreatedbydetails: x.projcreatedbydetails,
                projcreateddate: x.projcreateddate,
                projectcode: x.projectcode,
                projectmappingid: x.projectmappingid,
                projectname: x.projectname,
                projectrole: x.projectrole,
                projectshortcode: x.projectshortcode,
                projenddate: x.projenddate,
                projstartdate: x.projstartdate,
                teammemberid: x.teammemberid,
                checkvalue: false,
              };
            }),
          })
        );
        // setEmpDropDownData(
        //   resdata.map((x) => {
        //     return {
        //       allocationenddate: x.allocationenddate,
        //       allocationstartdate: x.allocationstartdate,
        //       allocpercentage: x.allocpercentage,
        //       companydetails: x.companydetails,
        //       companyid: x.companyid,
        //       email: x.email,
        //       firstname: x.firstname,
        //       lastname: x.lastname,
        //       managerid: x.managerid,
        //       mappedby: x.mappedby,
        //       mappedbydetails: x.mappedbydetails,
        //       onboardedby: x.onboardedby,
        //       onboardedbydetails: x.onboardedbydetails,
        //       onboardeddate: x.onboardeddate,
        //       phone: x.phone,
        //       projcreatedby: x.projcreatedby,
        //       projcreatedbydetails: x.projcreatedbydetails,
        //       projcreateddate: x.projcreateddate,
        //       projectcode: x.projectcode,
        //       projectmappingid: x.projectmappingid,
        //       projectname: x.projectname,
        //       projectrole: x.projectrole,
        //       projectshortcode: x.projectshortcode,
        //       projenddate: x.projenddate,
        //       projstartdate: x.projstartdate,
        //       teammemberid: x.teammemberid,
        //       checkvalue: false,
        //     };
        //   })
        // );
        // dispatch(ALLIMAGEUPDATE(resdata.map((x) => {
        //   let imageData = ""
        //   getImage(clientId, x.teammemberid, x.firstname, x.lastname)
        //     .then((imageUrl) => {
        //       console.log(imageUrl, "imageUrl")
        //       imageData = imageUrl
        //     })
        //     .catch((error) => {
        //       imageData = Unassigned
        //     })
        //   return {
        //     teammemberid: x.teammemberid,
        //     image: imageData
        //   };
        // })))
        // const getImageData = async (resdata) => {
        //   const imagePromises = resdata.map(async (x) => {
        //     try {
        //       const imageUrl = await getImage(
        //         clientId,
        //         x.teammemberid,
        //         x.firstname,
        //         x.lastname
        //       )
        //         .then((imageurl) => {
        //           return imageurl;
        //         })
        //         .catch((error) => {
        //           return Unassigned;
        //         });

        //       return {
        //         teammemberid: x.teammemberid,
        //         image:
        //           imageUrl == "" || imageUrl == null ? Unassigned : imageUrl,
        //       };
        //     } catch (error) {
        //       return {
        //         teammemberid: x.teammemberid,
        //         image: Unassigned,
        //       };
        //     }
        //   });

        //   const resolvedImages = await Promise.all(imagePromises);
        //   // console.log(resolvedImages ,"resdatajeba")
        //   return resolvedImages;
        // };

        // // Dispatch the action with the resolved images
        // const resolvedImages = await getImageData(resdata);
        // dispatch(ALLIMAGEUPDATE(resolvedImages));

        // setEmployeeList(resdata);
        dispatch(HANDLE_ON_CHANGE({ name: "employeeList", value: resdata }));
        // setProjectLabel(projectcodec?.[0]?.projectname);
        dispatch(
          HANDLE_ON_CHANGE({
            name: "projectLabel",
            value: projectcodec?.[0]?.projectname,
          })
        );
        dispatch(PROJECTCODEUPDATE(projectcodec?.[0]?.projectcode));
        dispatch(
          CONVIEWDATA({
            ...ConViewData,
            SelectedProject: projectcodec?.[0]?.projectcode,
          })
        );
        setProjectCode(projectcodec?.[0]?.projectcode);
        // setClient(projectcodec?.[0]?.client);
        dispatch(
          HANDLE_ON_CHANGE({ name: "client", value: projectcodec?.[0]?.client })
        );
        dispatch(
          HANDLE_ON_CHANGE({
            name: "projectName",
            value: projectcodec?.[0]?.projectname,
          })
        );
        // setProjectName(projectcodec?.[0]?.projectname);
        dispatch(PROLABEL(projectcodec?.[0]?.projectname));
        // setProjectId2(projectcodec?.[0]?.projectcode);
        dispatch(
          HANDLE_ON_CHANGE({
            name: "projectId2",
            value: projectcodec?.[0]?.projectcode,
          })
        );
        // setClient1(projectcodec?.[0]?.client);
        dispatch(
          HANDLE_ON_CHANGE({
            name: "client1",
            value: projectcodec?.[0]?.client,
          })
        );
        localStorage.setItem(
          "ProjectRole",
          projectcodec?.filter((e) => e.teammemberid == roleid)?.[0]
            ?.projectrole?.RoleCode
        );
        localStorage.setItem(
          "ProjectRoleName",
          projectcodec?.filter((e) => e.teammemberid == roleid)?.[0]
            ?.projectrole?.RoleName
        );
        setLocalStorageItem(
          "ProjectRoleName5",
          projectcodec?.filter((e) => e.teammemberid == roleid)?.[0]
            ?.projectrole?.RoleName
        );
        dispatch(
          PROJECTROLEUPDATE(
            projectcodec?.filter((e) => e.teammemberid == roleid)?.[0]
              ?.projectrole?.RoleCode
          )
        );
        dispatch(
          PROJECTROLENAMEUPDATE(
            projectcodec?.filter((e) => e.teammemberid == roleid)?.[0]
              ?.projectrole?.RoleName
          )
        );
      } else {
        return setUserprojectshow(false);
      }
    });
    axios
      .get(`subscription_client_users?ClientId=eq.` + clientd)
      .then((res) => {
        // setemp(res.data);
        dispatch(HANDLE_ON_CHANGE({ name: "emp", value: res.data }));
      });
    // KBRefresh();
    dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(`agilesaas_issue_progress?ProjectId=eq.${projectCode}&ClientId=eq.${clientId}&select=RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed`)
  //     .then((response) => {
  //       // const latestEntries = {};
  //       // response.data.forEach((entry) => {
  //       //   if (
  //       //     !latestEntries[entry.IssueId] ||
  //       //     entry.UpdatedDate > latestEntries[entry.IssueId].UpdatedDate
  //       //   ) {
  //       //     latestEntries[entry.IssueId] = entry;
  //       const latestEntries = response.data.reduce((acc, entry) => {
  //         if (!acc[entry.IssueId] || entry.UpdatedDate > acc[entry.IssueId].UpdatedDate) {
  //           acc[entry.IssueId] = entry;
  //         }
  //         // });
  //         return acc;
  //       }, {});

  //       const finalData = Object.values(latestEntries);
  //       // setData(finalData);
  //       // setProgressData(finalData);
  //       dispatch(HANDLE_ON_CHANGE({ name: 'progressdata', value: finalData }))
  //       // console.log(finalData, 'finalData')
  //       // console.log(response.data)
  //     })
  //     .catch((e) => {
  //       console.log(e, "error");
  //     });
  // }, []);

  useEffect(() => {
    // KBRefresh();
    dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
  }, [projectCode]);
  // console.log(projectName, "projectName");
  useEffect(() => {
    setProjectLoading(true);

    axios.get(api2).then((response) => {
      if (response.data.length != 0) {
        setIsLoading(false);
        setProjectLoading(false);
        // setKanbanBoardData(response.data);
        dispatch(KANBANBOARDDATAUPDATE(response.data));
        setProjectLoading(false);
        if (Filtername.length > 0) {
          let filteredData = response.data.filter((item) => {
            return Filtername.includes(item.AssignedTo);
          });
          dispatch(
            HANDLE_ON_CHANGE({ name: "FilteredData", value: filteredData })
          );
          // setFilteredData(filteredData);
        } else {
          // setFilteredData(response.data);
          dispatch(
            HANDLE_ON_CHANGE({ name: "FilteredData", value: response.data })
          );
        }
        // setRefresh(false);
        dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: false }));
      } else {
        // axios
        //   .get(`rpc/fun_kanbanboardcardstesterwoprojid?testerid=${arr.empid}`)
        //   .then((responses) => {
        //     console.log(responses.data,"refresh2")
        //     setKanbanBoardData(responses.data);
        //     setFilteredData(responses.data);
        //     setRefresh(false);
        //   })
        //   .catch((error) => console.log(error, "error"));
        setIsLoading(false);
        setProjectLoading(false);
      }
    });
  }, [allTickets]);

  const PriorityOption = [
    { label: "All", value: "All" },
    { label: "Highest", value: "Highest" },
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
    { label: "Lowest", value: "Lowest" },
  ];

  const EpicDropdown = [
    { label: "All", value: "All" },
    { label: "Story", value: "Story" },
    { label: "Task", value: "Task" },
    { label: "Bug", value: "Bug" },
    { label: "Epic", value: "Epic" },
    { label: "Subtask", value: "Subtask" },
  ];

  const Releases = KanbanBoardData?.filter(
    (r) =>
      (r.ProjectId == projectCode &&
        r.PlannedRelease != null &&
        r.ActualRelease == null) ||
      r.ActualRelease == ""
  )?.map((e) => e.PlannedRelease);

  const uniqueValues = Array.from(new Set(Releases));

  const optionsrelease = uniqueValues.map((option, index) => ({
    value: option,
    label: option,
  }));
  const updatedOptions = [
    { label: 'No Sprint Assigned', value: null },
    ...optionsrelease,
  ];

  const Finaltester = testename
    ?.filter(
      (e) =>
        e?.projectrole?.RoleCode === "QA" &&
        e?.projectrole?.RoleGroupId === "14"
      // &&
      // e?.teammemberid === teamm[0]?.TeamMemberId
      // == 300001 &&
      // e.projectrole == 508 &&
      // e.employeeroleinproject == "UAT Tester"
    )
    ?.map((m) => m?.teammemberid);

  let TestersId = Finaltester ? Finaltester : 1;
  let email = JSON.parse(localStorage.getItem("userdata"));

  let arr2 =
    sessionStorage.getItem("Roledetails") == "undefined"
      ? ""
      : JSON.parse(sessionStorage.getItem("Roledetails"));
  let empid = JSON.parse(localStorage.getItem("Empdetails"));
  let crtid = empid?.[0]?.ClientUserId;
  let managerid =
    localStorage.getItem("managerid") == "undefined"
      ? 0
      : JSON.parse(localStorage.getItem("managerid"));
  // empid != null ? empid[0]?.OnboardedBy === 800001 ? empid[0]?.ClientUserId : empid[0]?.OnboardedBy : "";
  // console.log(managerid, "managerid")
  let rolecode = arr2.RoleCode;

  let roleid = empid?.[0]?.ClientUserId;

  const clientId = localStorage.getItem("clientid");
  const multiplesm =
    localStorage.getItem("multiplesm") == "fullaccess_to_all_sms";
  // console.log(localStorage.getItem("multiplesm"), multiplesm, "mmmmmmmmmmmmmm")

  // Agile project tittle And search box

  const projectrc = localStorage.getItem("projectrc");
  // console.log(projectRole == "TL", projectrc == "TL");
  const api =
    multiplesm &&
      (rolecode == "SM" || getProdRole == "KA" || projectRole == "SM")
      ? `rpc/fun_agilesaasotherprojectmembers?teammemberid=${empid?.[0]?.ClientUserId}`
      : projectRole == "TL" || projectrc?.includes("TL")
        ? `rpc/fun_agilesaasotherprojectmembers?teammemberid=${empid?.[0]?.ClientUserId}`
        : rolecode == "SM" || getProdRole == "KA" || projectRole == "SM"
          ? `rpc/fun_agilesaassmprojandteamdetails?managerid=` +
          empid?.[0]?.ClientUserId +
          `&companyid=eq.` +
          clientd
          : rolecode == "TL" || getProdRole == "TL" || projectRole == "TL"
            ? `rpc/fun_agilesaassmprojandteamdetails?managerid=` +
            managerid +
            `&companyid=eq.` +
            clientd
            : "rpc/fun_agilesaassmprojandteamdetails?managerid=" +
            managerid +
            "&companyid=eq." +
            clientd +
            "&teammemberid=eq." +
            empid?.[0]?.ClientUserId;

  // console.log(rolecode == "SM" || getProdRole == "KA", "checkuser");
  //  +
  // managerid +
  // "&clientid=" +
  // clientd +
  // "&teammemberid=eq." +
  // empid[0]?.ClientUserId;

  // ? `rpc/fun_mgrprojects?managerid=${arr.empid}`
  // : `rpc/fun_mgrprojects?managerid=${arr.level1managereid}&empid=eq.${arr.empid}`;

  // agile All cards detais
  // console.log(api, "employee id")
  // console.log(rolecode, getProdRole)
  const api2 =
    (multiplesm && projectRole == "SM") || projectRole == "SM"
      ? // `agilesaas_issue_details?ClientId=eq.${clientId}&IsActive=eq.Y`
      `agilesaas_issue_details?select=IssueId,LinkToIssues,ExceptiontoSkipSPEstimation,IssueTitle,IsActive,ParentIssue,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,IssueType,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed)&ClientId=eq.${clientId}&IsActive=eq.Y`
      : rolecode == "TL" || projectRole == "TL"
        ? `agilesaas_issue_details?select=IssueId,LinkToIssues,ExceptiontoSkipSPEstimation,IssueTitle,IsActive,ParentIssue,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,IssueType,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed)or=(CreatedBy.eq.${managerid},AssignedTo.eq.${empid?.[0]?.ClientUserId},CreatedBy.eq.${managerid})&order=CreatedDate.desc&IsActive=eq.Y`
        : projectRole != "SM" && allTickets
          ? `agilesaas_issue_details?select=IssueId,LinkToIssues,ExceptiontoSkipSPEstimation,IssueTitle,IsActive,ParentIssue,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,IssueType,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed)&ClientId=eq.${clientId}&AssignedTo=eq.${crtid}&order=CreatedDate.desc&IsActive=eq.Y`
          : projectRole != "SM"
            ? `agilesaas_issue_details?select=IssueId,LinkToIssues,ExceptiontoSkipSPEstimation,IssueTitle,IsActive,ParentIssue,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,IssueType,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed)&ClientId=eq.${clientId}&order=CreatedDate.desc&IsActive=eq.Y`
            : rolecode == "SM" || getProdRole == "KA" || projectRole == "SM"
              ? `agilesaas_issue_details?select=IssueId,LinkToIssues,ExceptiontoSkipSPEstimation,IssueTitle,IsActive,ParentIssue,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,IssueType,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed)or=(CreatedBy.eq.${crtid},AssignedTo.eq.${empid?.[0]?.ClientUserId},CreatedBy.eq.${crtid})&order=CreatedDate.desc&IsActive=eq.Y`
              : // `agilesaas_issue_details?or=(CreatedBy.eq.${crtid},AssignedTo.eq.${empid?.[0]?.ClientUserId},CreatedBy.eq.${crtid})&order=CreatedDate.desc&IsActive=eq.Y`
              rolecode == "QA" || projectRole == "QA" || projectRole == "SM"
                ? `agilesaas_issue_details?select=IssueId,LinkToIssues,ExceptiontoSkipSPEstimation,IssueTitle,IsActive,ParentIssue,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,IssueType,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed)or=(AssignedTo.eq.${crtid},AssignedToUAT.eq.${crtid},CreatedBy.eq.${crtid})&order=CreatedDate.desc&IsActive=eq.Y`
                : // : `agilesaas_issue_details?AssignedTo=eq.${crtid}&order=CreatedDate.desc&IsActive=eq.Y`
                `agilesaas_issue_details?select=IssueId,LinkToIssues,ExceptiontoSkipSPEstimation,IssueTitle,IsActive,ParentIssue,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,IssueType,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed)&AssignedTo=eq.${crtid}&order=CreatedDate.desc&IsActive=eq.Y`;

  // const api2 =
  //   multiplesm && projectRole == "SM" ?
  //     `agilesaas_issue_details?ClientId=eq.${clientId}&IsActive=eq.Y` :
  //     projectRole != "SM" ?
  //       "agilesaas_issue_details?ClientId=eq." + clientId + "&AssignedTo=eq." +
  //       crtid +
  //       "&order=CreatedDate.desc&IsActive=eq.Y" :
  //       rolecode == "SM" || getProdRole == "KA" || projectRole == "SM"
  //         ? //|| prole == "SM"
  //         `agilesaas_issue_details?or=(CreatedBy.eq.${crtid},AssignedTo.eq.${empid?.[0]?.ClientUserId},CreatedBy.eq.${crtid})&order=CreatedDate.desc&IsActive=eq.Y`
  //         : // `agilesaas_issue_details?or=(AssignedTo.eq.${empid[0]?.ClientUserId},CreatedBy.eq.${empid[0]?.OnboardedBy},CreatedBy.eq.${empid[0]?.OnboardedBy})`
  //         rolecode == "QA" || projectRole == "QA" || projectRole == "SM"
  //           ? `agilesaas_issue_details?or=(AssignedTo.eq.${crtid},AssignedToUAT.eq.${crtid},CreatedBy.eq.${crtid})&order=CreatedDate.desc&IsActive=eq.Y`
  //           : "agilesaas_issue_details?AssignedTo=eq." +
  //           crtid +
  //           "&order=CreatedDate.desc&IsActive=eq.Y";

  // Create issue button epic and releted to
  // const api3 = `rpc/fun_kanbanboardcardstesterwoprojid?testerid=${arr.level1managereid}`;

  // Assign to UAT
  const UATDrop =
    multiplesm && projectRole == "SM"
      ? `rpc/fun_agilesaasotherprojectmembers?teammemberid=${empid?.[0]?.ClientUserId}&projectrole->>RoleGroupId=eq.5`
      : rolecode == "SM" || getProdRole == "KA" || projectRole == "SM"
        ? //|| prole == "SM"
        // ? `rpc/fun_mgrprojects?managerid=${arr.empid}&projectrole=eq.508`
        `rpc/fun_agilesaasmanagerteamprojdetails?managerid=${crtid}&clientid=${clientd}&projectrole->>RoleGroupId=eq.5`
        : `rpc/fun_agilesaasmanagerteamprojdetails?managerid=${managerid}&clientid=${clientd}&projectrole->>RoleGroupId=eq.5`;
  // : `rpc/fun_mgrprojects?managerid=${arr.level1managereid}&projectrole=eq.508`;

  if (Refresh) {
    axios.get(api2).then((response) => {
      if (response.data.length != 0) {
        setIsLoading(false);
        setProjectLoading(false);
        // setKanbanBoardData(response.data);
        dispatch(KANBANBOARDDATAUPDATE(response.data));
        if (Filtername.length > 0) {
          let filteredData = response.data.filter((item) => {
            return Filtername.includes(item.AssignedTo);
          });
          dispatch(
            HANDLE_ON_CHANGE({ name: "FilteredData", value: filteredData })
          );
          // setFilteredData(filteredData);
        } else {
          // setFilteredData(response.data);
          dispatch(
            HANDLE_ON_CHANGE({ name: "FilteredData", value: response.data })
          );
        }
        // setRefresh(false);
        dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: false }));
      } else {
        // axios
        //   .get(`rpc/fun_kanbanboardcardstesterwoprojid?testerid=${arr.empid}`)
        //   .then((responses) => {
        //     console.log(responses.data,"refresh2")
        //     setKanbanBoardData(responses.data);
        //     setFilteredData(responses.data);
        //     setRefresh(false);
        //   })
        //   .catch((error) => console.log(error, "error"));
        setIsLoading(false);
        setProjectLoading(false);
      }
    });
  }

  function subTaskApi() {
    axios
      .get(
        `agilesaas_issue_details?ParentIssue->0->>IssueId=eq.${SelectedCardId}&order=CreatedDate.desc&IsActive=eq.Y`
      )
      .then((res) => {
        // setDisplaySubTask(res.data);
        dispatch(HANDLE_ON_CHANGE({ name: "displaySubTask", value: res.data }));
      });
  }

  function handlePriority(item) {
    item == priority
      ? dispatch(HANDLE_ON_CHANGE({ name: "priority", value: "All" }))
      : dispatch(HANDLE_ON_CHANGE({ name: "priority", value: item }));
    // setEpicLabel(epicLabel);
    dispatch(HANDLE_ON_CHANGE({ name: "epicLabel", value: epicLabel }));
    // setreleasefilter(releasefilter);
    dispatch(HANDLE_ON_CHANGE({ name: "releasefilter", value: releasefilter }));
  }
  function handleEpic(item) {
    item == epicLabel
      ? dispatch(HANDLE_ON_CHANGE({ name: "epicLabel", value: "All" }))
      : dispatch(HANDLE_ON_CHANGE({ name: "epicLabel", value: item }));
    // setPriority(priority);
    dispatch(HANDLE_ON_CHANGE({ name: "priority", value: priority }));
    // setreleasefilter(releasefilter);
    dispatch(HANDLE_ON_CHANGE({ name: "releasefilter", value: releasefilter }));
  }
  function releasefil(item) {
    item == releasefilter
      ? dispatch(HANDLE_ON_CHANGE({ name: "releasefilter", value: "" }))
      : dispatch(HANDLE_ON_CHANGE({ name: "releasefilter", value: item }));
    // setPriority(priority);
    dispatch(HANDLE_ON_CHANGE({ name: "priority", value: priority }));
    // setEpicLabel(epicLabel);
    dispatch(HANDLE_ON_CHANGE({ name: "epicLabel", value: epicLabel }));
  }

  function KBRefresh() {
    axios
      .get(
        "rpc/fun_agilesaasmanagerteamprojdetails?managerid=" +
        roleid +
        "&clientid=" +
        clientd +
        "&teammemberid=eq." +
        empid?.[0]?.ClientUserId
      )
      .then((res) => {
        // setTesterId(res.data);
        dispatch(HANDLE_ON_CHANGE({ name: "testerid", value: res.data }));
      });
    subTaskApi();

    axios.get(UATDrop).then(async (res) => {
      // setUatDropdown(res.data);
      dispatch(HANDLE_ON_CHANGE({ name: "uatDropdown", value: res.data }));
      let uatdata = res.data;
      // const getImageData = async (uatdata) => {
      //   const imagePromises = uatdata.map(async (x) => {
      //     try {
      //       const imageUrl = await getImage(
      //         clientId,
      //         x.teammemberid,
      //         x.firstname,
      //         x.lastname
      //       )
      //         .then((imageurl) => {
      //           return imageurl;
      //         })
      //         .catch((error) => {
      //           return Unassigned;
      //         });

      //       return {
      //         teammemberid: x.teammemberid,
      //         image: imageUrl == "" || imageUrl == null ? Unassigned : imageUrl,
      //       };
      //     } catch (error) {
      //       return {
      //         teammemberid: x.teammemberid,
      //         image: Unassigned,
      //       };
      //     }
      //   });

      //   const resolvedImages = await Promise.all(imagePromises);
      //   return resolvedImages;
      // };
      // const resolvedImages = await getImageData(uatdata);
      // dispatch(TESTERSIMAGEUPDATE(resolvedImages));
    });

    axios
      .get(api)
      .then((res) => {
        dispatch(PROJECTMAPPINGDATAUPDATE(res.data));
        axios.get(api2).then((response) => {
          if (response.data.length != 0) {
            // setKanbanBoardData(response.data);
            setIsLoading(false);
            setProjectLoading(false);
            dispatch(KANBANBOARDDATAUPDATE(response.data));
            if (Filtername.length > 0) {
              let filteredData = response.data.filter((item) => {
                return Filtername.includes(item.AssignedTo);
              });
              // setFilteredData(filteredData);
              dispatch(
                HANDLE_ON_CHANGE({ name: "FilteredData", value: filteredData })
              );
            } else {
              // setFilteredData(response.data);
              dispatch(
                HANDLE_ON_CHANGE({ name: "FilteredData", value: response.data })
              );
            }

            dispatch(FILTEREDKANBANDATA(response.data));
            dispatch(
              HANDLE_ON_CHANGE({
                name: "dropdown",
                value: response.data.filter(
                  (x) => x.projectid == res.data?.[0]?.projectcode
                ),
              })
            );
            // setDropdown(
            //   response.data.filter(
            //     (x) => x.projectid == res.data?.[0]?.projectcode
            //   )
            // );

            // axios.get(api3).then((Res) => {
            //   setProjectnamedetails(
            //     Res.data.filter((x) => x.ProjectId == res.data[0].projectcode)
            //   );
            // });
          } else {
            setIsLoading(false);
            setProjectLoading(false);
          }
        });
        // let data = res.data;

        //   FilteredData.map((item) => {
        //     let findItem = projectDetails.find(
        //       (x) => x.projectcode === item.projectcodex
        //     );
        //     if (!findItem) projectDetails.push(item);
        //   });

        if (res.data != 0) {
          // setResData(res.data);
          dispatch(HANDLE_ON_CHANGE({ name: "resData", value: res.data }));
        } else {
          return setUserprojectshow(false);
        }
      })
      .catch((err) => {
        console.log(err);
      }); //goto
    // if (priority || epicLabel || releasefilter) {
    //   setPriority(priority)
    //   setEpicLabel(epicLabel)
    //   setreleasefilter(releasefilter)
    // }
    // axios
    //   .get(`agilesaas_issue_progress?ProjectId=eq.${projectCode}&ClientId=eq.${clientId}&select=RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed`)
    //   .then((response) => {
    //     const latestEntries = response.data.reduce((acc, entry) => {
    //       if (!acc[entry.IssueId] || entry.UpdatedDate > acc[entry.IssueId].UpdatedDate) {
    //         acc[entry.IssueId] = entry;
    //       }
    //       // });
    //       return acc;
    //     }, {});

    //     const finalData = Object.values(latestEntries);
    //     // setData(finalData);
    //     // setProgressData(finalData);
    //     dispatch(HANDLE_ON_CHANGE({ name: 'progressdata', value: finalData }))
    //     // console.log(finalData, 'finalData')
    //     // console.log(response.data)
    //   })
    //   .catch((e) => {
    //     console.log(e, "error");
    //   });
    dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: false }));
  }

  if (kbrefreshstate) {
    KBRefresh();
  }
  function Refreshboard() {
    setIsLoadingb(true);
    axios
      .get(
        "rpc/fun_agilesaasmanagerteamprojdetails?managerid=" +
        roleid +
        "&clientid=" +
        clientd +
        "&teammemberid=eq." +
        empid?.[0]?.ClientUserId
      )
      .then((res) => {
        // setTesterId(res.data);
        dispatch(HANDLE_ON_CHANGE({ name: "testerid", value: res.data }));
      });
    subTaskApi();

    axios.get(UATDrop).then(async (res) => {
      // setUatDropdown(res.data);
      dispatch(HANDLE_ON_CHANGE({ name: "uatDropdown", value: res.data }));
      let uatdata = res.data;
      // const getImageData = async (uatdata) => {
      //   const imagePromises = uatdata.map(async (x) => {
      //     try {
      //       const imageUrl = await getImage(
      //         clientId,
      //         x.teammemberid,
      //         x.firstname,
      //         x.lastname
      //       )
      //         .then((imageurl) => {
      //           return imageurl;
      //         })
      //         .catch((error) => {
      //           return Unassigned;
      //         });

      //       return {
      //         teammemberid: x.teammemberid,
      //         image: imageUrl == "" || imageUrl == null ? Unassigned : imageUrl,
      //       };
      //     } catch (error) {
      //       return {
      //         teammemberid: x.teammemberid,
      //         image: Unassigned,
      //       };
      //     }
      //   });

      //   const resolvedImages = await Promise.all(imagePromises);
      //   return resolvedImages;
      // };
      // const resolvedImages = await getImageData(uatdata);
      // dispatch(TESTERSIMAGEUPDATE(resolvedImages));
    });

    axios
      .get(api)
      .then((res) => {
        dispatch(PROJECTMAPPINGDATAUPDATE(res.data));
        axios.get(api2).then((response) => {
          if (response.data.length != 0) {
            // setKanbanBoardData(response.data);
            setIsLoading(false);
            setProjectLoading(false);
            dispatch(KANBANBOARDDATAUPDATE(response.data));
            if (Filtername.length > 0) {
              let filteredData = response.data.filter((item) => {
                return Filtername.includes(item.AssignedTo);
              });
              // setFilteredData(filteredData);
              dispatch(
                HANDLE_ON_CHANGE({ name: "FilteredData", value: filteredData })
              );
            } else {
              // setFilteredData(response.data);
              dispatch(
                HANDLE_ON_CHANGE({ name: "FilteredData", value: response.data })
              );
            }

            dispatch(FILTEREDKANBANDATA(response.data));
            // setDropdown(
            //   response.data.filter(
            //     (x) => x.projectid == res.data?.[0]?.projectcode
            //   )
            // );
            dispatch(
              HANDLE_ON_CHANGE({
                name: "dropdown",
                value: response.data.filter(
                  (x) => x.projectid == res.data?.[0]?.projectcode
                ),
              })
            );

            // axios.get(api3).then((Res) => {
            //   setProjectnamedetails(
            //     Res.data.filter((x) => x.ProjectId == res.data[0].projectcode)
            //   );
            // });
          } else {
            setIsLoading(false);
            setProjectLoading(false);
          }
        });
        // let data = res.data;

        //   FilteredData.map((item) => {
        //     let findItem = projectDetails.find(
        //       (x) => x.projectcode === item.projectcodex
        //     );
        //     if (!findItem) projectDetails.push(item);
        //   });

        if (res.data != 0) {
          // setResData(res.data);
          dispatch(HANDLE_ON_CHANGE({ name: "resData", value: res.data }));
        } else {
          return setUserprojectshow(false);
        }
      })
      .catch((err) => {
        console.log(err);
      }); //goto
    // if (priority || epicLabel || releasefilter) {
    //   setPriority(priority)
    //   setEpicLabel(epicLabel)
    //   setreleasefilter(releasefilter)
    // }
    // axios
    //   .get(`agilesaas_issue_progress?ProjectId=eq.${projectCode}&ClientId=eq.${clientId}&select=RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed`)
    //   .then((response) => {
    //     const latestEntries = {};
    //     response.data.forEach((entry) => {
    //       if (
    //         !latestEntries[entry.IssueId] ||
    //         entry.UpdatedDate > latestEntries[entry.IssueId].UpdatedDate
    //       ) {
    //         latestEntries[entry.IssueId] = entry;
    //       }
    //     });

    //     const finalData = Object.values(latestEntries);
    //     // setData(finalData);
    //     // setProgressData(finalData);
    //     dispatch(HANDLE_ON_CHANGE({ name: 'progressdata', value: finalData }))
    //     // console.log(finalData, 'finalData')
    //     // console.log(response.data)
    //   })
    //   .catch((e) => {
    //     console.log(e, "error");
    //   });
    setTimeout(() => {
      setIsLoadingb(false);
    }, 2000);
  }
  // console.log(projectDetails, "project details");
  const ProjectOption = projectDetails?.map((e) => ({
    label: e.projectname,
    value: parseInt(e.projectcode),
    client: e.client,
  }));
  // console.log(resData, "resData", projectCode)
  async function handleProject(item) {
    handleClearFilter();
    // setSearchText("");
    dispatch(HANDLE_ON_CHANGE({ name: "searchText", value: "" }));
    setFiltername([]);
    axios.get(`agilesaas_releases?ProjectId=eq.${item.value}`).then((res) => {
      dispatch(RELEASESUPDATE(res.data));
    });
    if (projectCode !== item.value) {
      setProjectLoading(true);
    }

    try {
      let roled = resData
        ?.filter((x) => x.projectcode === item.value)
        ?.map((r) => r.projectrole?.RoleCode);

      // const getImageData = async (resdata) => {
      //   const imagePromises = resdata.map(async (x) => {
      //     try {
      //       const imageUrl = await getImage(
      //         clientId,
      //         x.teammemberid,
      //         x.firstname,
      //         x.lastname
      //       )
      //         .then((imageurl) => {
      //           return imageurl;
      //         })
      //         .catch((error) => {
      //           return Unassigned;
      //         });

      //       return {
      //         teammemberid: x.teammemberid,
      //         image: imageUrl == "" || imageUrl == null ? Unassigned : imageUrl,
      //       };
      //     } catch (error) {
      //       return {
      //         teammemberid: x.teammemberid,
      //         image: Unassigned,
      //       };
      //     }
      //   });

      //   const resolvedImages = await Promise.all(imagePromises);
      //   dispatch(ALLIMAGEUPDATE(resolvedImages));
      //   // console.log(resolvedImages ,"resdatajeba")
      //   return resolvedImages;
      // };

      // // Dispatch the action with the resolved images
      // const resolvedImages = await getImageData(resData);
      // dispatch(ALLIMAGEUPDATE(resolvedImages));
      const getProjectRole = projectMappingData.filter(
        (e) => e.projectcode == item?.value && e.teammemberid === roleid
      )?.[0]?.projectrole;
      dispatch(PROJECTROLEUPDATE(getProjectRole?.RoleCode));
      dispatch(PROJECTROLENAMEUPDATE(getProjectRole?.RoleName));
      dispatch(PROJECTCODEUPDATE(item?.value));
      dispatch(MINIMIZED(false));

      dispatch(ISCREATEKB(false));
      dispatch(ISSPRINTDETAILS(false));
      dispatch(ISMANAGERNOTES(false));
      dispatch(ISPLANSPRINT(false));
      dispatch(ISREPORT(false));
      setManageNoteopen(false);
      setProjectCode(item?.value);
      // setProjectLabel(item.label);
      dispatch(HANDLE_ON_CHANGE({ name: "projectLabel", value: item.label }));
      // setProjectId2(item.value);
      dispatch(HANDLE_ON_CHANGE({ name: "projectId2", value: item.value }));
      dispatch(PROLABEL(item.label));
      dispatch(HANDLE_ON_CHANGE({ name: "projectName", value: item.label }));

      // setProjectName(item.label);
      // setClient(item.client);
      // setClient1(item.client);
      dispatch(HANDLE_ON_CHANGE({ name: "client", value: item.client }));
      dispatch(HANDLE_ON_CHANGE({ name: "client1", value: item.client }));
      dispatch(
        HANDLE_ON_CHANGE({
          name: "Assetoptions",
          value: resData
            .filter((x) => x.projectcode === item.value)
            .map((x) => {
              return {
                EmpId: x.teammemberid,
                label: x.firstname + " " + x.lastname,
                firstname: x.firstname,
                lastname: x.lastname,
                projectRole: x.projectrole?.RoleGroupId,
                value: false,
                value1: x.teammemberid,
                mailid: x.email,
              };
            }),
        })
      );
      // setAssetoptions(
      //   resData
      //     .filter((x) => x.projectcode === item.value)
      //     .map((x) => {
      //       return {
      //         EmpId: x.teammemberid,
      //         label: x.firstname + " " + x.lastname,
      //         firstname: x.firstname,
      //         lastname: x.lastname,
      //         projectRole: x.projectrole?.RoleGroupId,
      //         value: false,
      //         value1: x.teammemberid,
      //         mailid: x.email,
      //       };
      //     })
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "EmpDropDownData",
          value: resData
            .filter((x) => x.projectcode === item.value)
            .map((x) => {
              return {
                allocationenddate: x.allocationenddate,
                allocationstartdate: x.allocationstartdate,
                allocpercentage: x.allocpercentage,
                companydetails: x.companydetails,
                companyid: x.companyid,
                email: x.email,
                firstname: x.firstname,
                lastname: x.lastname,
                managerid: x.managerid,
                mappedby: x.mappedby,
                mappedbydetails: x.mappedbydetails,
                onboardedby: x.onboardedby,
                onboardedbydetails: x.onboardedbydetails,
                onboardeddate: x.onboardeddate,
                phone: x.phone,
                projcreatedby: x.projcreatedby,
                projcreatedbydetails: x.projcreatedbydetails,
                projcreateddate: x.projcreateddate,
                projectcode: x.projectcode,
                projectmappingid: x.projectmappingid,
                projectname: x.projectname,
                projectrole: x.projectrole,
                projectshortcode: x.projectshortcode,
                projenddate: x.projenddate,
                projstartdate: x.projstartdate,
                teammemberid: x.teammemberid,
                checkvalue: false,
              };
            }),
        })
      );

      // setEmpDropDownData(
      //   resData
      //     .filter((x) => x.projectcode === item.value)
      //     .map((x) => {
      //       return {
      //         allocationenddate: x.allocationenddate,
      //         allocationstartdate: x.allocationstartdate,
      //         allocpercentage: x.allocpercentage,
      //         companydetails: x.companydetails,
      //         companyid: x.companyid,
      //         email: x.email,
      //         firstname: x.firstname,
      //         lastname: x.lastname,
      //         managerid: x.managerid,
      //         mappedby: x.mappedby,
      //         mappedbydetails: x.mappedbydetails,
      //         onboardedby: x.onboardedby,
      //         onboardedbydetails: x.onboardedbydetails,
      //         onboardeddate: x.onboardeddate,
      //         phone: x.phone,
      //         projcreatedby: x.projcreatedby,
      //         projcreatedbydetails: x.projcreatedbydetails,
      //         projcreateddate: x.projcreateddate,
      //         projectcode: x.projectcode,
      //         projectmappingid: x.projectmappingid,
      //         projectname: x.projectname,
      //         projectrole: x.projectrole,
      //         projectshortcode: x.projectshortcode,
      //         projenddate: x.projenddate,
      //         projstartdate: x.projstartdate,
      //         teammemberid: x.teammemberid,
      //         checkvalue: false,
      //       };
      //     })
      // );

      // setEmployeeList(resData.filter((x) => x.projectcode === item.value));
      dispatch(
        HANDLE_ON_CHANGE({
          name: "employeeList",
          value: resData.filter((x) => x.projectcode === item.value),
        })
      );
      // setDropdown(KanbanBoardData?.filter((e) => e.ProjectId == item?.value));
      dispatch(
        HANDLE_ON_CHANGE({
          name: "dropdown",
          value: KanbanBoardData?.filter((e) => e.ProjectId == item?.value),
        })
      );
      // setFilteredData(KanbanBoardData?.filter((e) => e.ProjectId == item?.value))
      // setProjectnamedetails(
      //   KanbanBoardData?.filter((x) => x.ProjectId === item?.value)
      // );

      axios
        .get(
          "agilesaas_issue_details?ProjectId=eq." +
          item?.value +
          "&order=CreatedDate.desc&IsActive=eq.Y"
        )
        .then((response) => {
          // setprojectRelease(response.data);
          dispatch(
            HANDLE_ON_CHANGE({ name: "projectRelease", value: response.data })
          );
        })
        .catch((error) => console.log(error));

      axios
        .get(
          `agilesaas_releases?ProjectId=eq.${item.value}&order=SprintStartDate.asc`
        )
        .then((res) => {
          // setReleases(res.data);
          const filteredItems = res?.data?.filter((item) => {
            const itemDate = moment(item.SprintEndDate, "DD-MM-YYYY");
            return (
              itemDate.isSameOrAfter(moment(), "day") &&
              item.IsReleaseFrozen == "N"
            );
          });
          dispatch(HANDLE_ON_CHANGE({ name: "releasedata", value: res.data }));
          dispatch(
            HANDLE_ON_CHANGE({ name: "releases1", value: filteredItems })
          );
        });
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  }

  const projectrolecode = projectDetails?.filter(
    (e) => e.projectcode == projectCode
  );
  // console.log(projectrolecode, crtid, "Testingg");
  // console.log(projectrolecode?.[0]?.projectrole?.RoleCode, "Testingg");
  const projectvalue = contextval.project;
  // console.log(FilteredData?.[0]?.ProjectId);
  // console.log(projectCode);
  useEffect(() => {
    axios
      .get(
        `agilesaas_project_master?ClientId=eq.${clientId}&select=ProjectId,ProjectModules`
      )
      .then((res) => {
        dispatch(PROJECTMODULES(res.data));
      })
      .catch((e) => {
        console.log(e);
      });
    if (projectvalue !== null) {
      dispatch(PROJECTCODEUPDATE(projectvalue?.value));
      dispatch(PROLABEL(projectvalue.label));
      setProjectCode(projectvalue.value);
      // setProjectLabel(projectvalue.label);
      dispatch(
        HANDLE_ON_CHANGE({ name: "projectLabel", value: projectvalue.label })
      );
      // setProjectId2(projectvalue.value);
      dispatch(
        HANDLE_ON_CHANGE({ name: "projectId2", value: projectvalue.value })
      );
      dispatch(
        HANDLE_ON_CHANGE({ name: "projectName", value: projectvalue?.label })
      );

      // setProjectName(projectvalue.label);
      // setClient(projectvalue.client);
      // setClient1(projectvalue.client);
      dispatch(
        HANDLE_ON_CHANGE({ name: "client", value: projectvalue?.client })
      );
      dispatch(
        HANDLE_ON_CHANGE({ name: "client1", value: projectvalue?.client })
      );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "EmpDropDownData",
          value: resData
            .filter((x) => x.projectcode === projectvalue.value)
            .map((x) => {
              return {
                allocationenddate: x.allocationenddate,
                allocationstartdate: x.allocationstartdate,
                allocpercentage: x.allocpercentage,
                companydetails: x.companydetails,
                companyid: x.companyid,
                email: x.email,
                firstname: x.firstname,
                lastname: x.lastname,
                managerid: x.managerid,
                mappedby: x.mappedby,
                mappedbydetails: x.mappedbydetails,
                onboardedby: x.onboardedby,
                onboardedbydetails: x.onboardedbydetails,
                onboardeddate: x.onboardeddate,
                phone: x.phone,
                projcreatedby: x.projcreatedby,
                projcreatedbydetails: x.projcreatedbydetails,
                projcreateddate: x.projcreateddate,
                projectcode: x.projectcode,
                projectmappingid: x.projectmappingid,
                projectname: x.projectname,
                projectrole: x.projectrole,
                projectshortcode: x.projectshortcode,
                projenddate: x.projenddate,
                projstartdate: x.projstartdate,
                teammemberid: x.teammemberid,
                checkvalue: false,
              };
            }),
        })
      );
      // setEmpDropDownData(
      //   resData
      //     .filter((x) => x.projectcode === projectvalue.value)
      //     .map((x) => {
      //       return {
      //         allocationenddate: x.allocationenddate,
      //         allocationstartdate: x.allocationstartdate,
      //         allocpercentage: x.allocpercentage,
      //         companydetails: x.companydetails,
      //         companyid: x.companyid,
      //         email: x.email,
      //         firstname: x.firstname,
      //         lastname: x.lastname,
      //         managerid: x.managerid,
      //         mappedby: x.mappedby,
      //         mappedbydetails: x.mappedbydetails,
      //         onboardedby: x.onboardedby,
      //         onboardedbydetails: x.onboardedbydetails,
      //         onboardeddate: x.onboardeddate,
      //         phone: x.phone,
      //         projcreatedby: x.projcreatedby,
      //         projcreatedbydetails: x.projcreatedbydetails,
      //         projcreateddate: x.projcreateddate,
      //         projectcode: x.projectcode,
      //         projectmappingid: x.projectmappingid,
      //         projectname: x.projectname,
      //         projectrole: x.projectrole,
      //         projectshortcode: x.projectshortcode,
      //         projenddate: x.projenddate,
      //         projstartdate: x.projstartdate,
      //         teammemberid: x.teammemberid,
      //         checkvalue: false,
      //       };
      //     })
      // );
      // setEmployeeList(
      //   resData.filter((x) => x.projectcode === projectvalue.value)
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "employeeList",
          value: resData.filter((x) => x.projectcode === projectvalue.value),
        })
      );
      // setDropdown(
      //   KanbanBoardData?.filter((e) => e.ProjectId == projectvalue.value)
      // );
      dispatch(
        HANDLE_ON_CHANGE({
          name: "dropdown",
          value: KanbanBoardData?.filter(
            (e) => e.ProjectId == projectvalue.value
          ),
        })
      );
      // setProjectnamedetails(
      //   KanbanBoardData?.filter((x) => x.ProjectId === projectvalue.value)
      // );
      axios
        .get(
          "agilesaas_issue_details?ProjectId=eq." +
          projectvalue.value +
          "&order=CreatedDate.desc&IsActive=eq.Y"
        )
        .then((response) => {
          // setprojectRelease(response.data);
          dispatch(
            HANDLE_ON_CHANGE({ name: "projectRelease", value: response.data })
          );
        })
        .catch((error) => console.log(error));

      contextval.setproject(null);
    }
  }, []);
  function handlemenua(menuId) {
    // console.log(menuId, "menu id")
    setAnchorEl(false);
    if (menuId === 19) {
      // setReleasePopup(true);
      dispatch(HANDLE_ON_CHANGE({ name: "releasePopup", value: true }));
      if (releasePopup == true) {
        const windowElement = document.getElementById("planrelease");

        windowElement.style.display = "block";
      }
      // setReleaseApi(true);
      dispatch(HANDLE_ON_CHANGE({ name: "releaseapi", value: true }));
      axios
        .get(
          "agilesaas_issue_details?ProjectId=eq." +
          projectCode +
          "&order=CreatedDate.desc&IsActive=eq.Y"
        )
        .then((response) => {
          // settesterEpic(response.data);
          console.log('change tester epic called kanban board')
          dispatch(
            HANDLE_ON_CHANGE({ name: "testerEpic", value: response.data })
          );
        })
        .catch((error) => console.log(error));
    } else if (menuId == 20) {
      setcurrentopen(true);
    } else if (menuId == 21) {
      // sethistoryrelease(true);
      dispatch(HANDLE_ON_CHANGE({ name: "historyrelease", value: true }));
    } else if (menuId == 22) {
      setbuild(true);
      // devozyLogin();
    } else if (menuId == 23) {
      // setmeeeting(true);
      dispatch(HANDLE_ON_CHANGE({ name: "meeting", value: true }));
    } else if (menuId == 24) {
      setreportview(true);
      if (reportview == true) {
        const windowElement = document.getElementById("reportv");

        windowElement.style.display = "block";
      }
    } else if (menuId == 25) {
      // setTimeout(() => {
      // setscrumchart(true);
      dispatch(HANDLE_ON_CHANGE({ name: "scrumchart", value: true }));
      // }, 1000);
    } else if (menuId == 29) {
      dispatch(HANDLE_ON_CHANGE({ name: "Historysearch", value: true }));
      // setHistorysearch(true);
    } else if (menuId == 30) {
      setTimeline(true);
    } else if (menuId == 91) {
      dispatch(HANDLE_ON_CHANGE({ name: "refinementpopup", value: true }));
      if (IsRefinement) {
        const windowElement = document.getElementById("refinement");
        windowElement.style.display = "block";
      }
    }
    else {
      setAnchorEl(false);
    }
  }
  function sprindetailclose() {
    dispatch(ISSPRINTDETAILS(false));
    // sethistoryrelease(false);
    dispatch(HANDLE_ON_CHANGE({ name: "historyrelease", value: false }));
  }
  function reportclose() {
    dispatch(ISREPORT(false));
    setreportview(false);
  }
  function handleClose() {
    // dispatch(ISSPRINTDETAILS(false))
    // dispatch(ISREPORT(false))

    setcurrentopen(false);
    // sethistoryrelease(false);
    // setreportview(false);
    setscrumteam(false);
    setTimeline(false);
    // setmeeeting(false);
    dispatch(HANDLE_ON_CHANGE({ name: "meeting", value: false }));
    // setHistorysearch(false);
    dispatch(HANDLE_ON_CHANGE({ name: "Historysearch", value: false }));
    // setRefresh(true);
    dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
    setAnchorEl(false);
    // setCommitDialog(false)
    dispatch(HANDLE_ON_CHANGE({ name: "commitDialog", value: false }));
    dispatch(HANDLE_ON_CHANGE({ name: "refinementpopup", value: false }));
  }
  function handleaction() {
    setAnchorEl(false);
  }

  const handleDevozy = () => {
    setbuild(false);
  };
  // console.log(KanbanMenumain);
  // Initial options
  const [messages, setMessages] = useState([]);
  const [messages2, setMessages2] = useState([]);
  const [messages3, setMessages3] = useState([]);
  const [messages4, setMessages4] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [currentMessage, setcurrentMessage] = useState(null);
  const [finalmessage, setfinalMessage] = useState(false);
  const [senddisable, setsenddiable] = useState(true);

  // Function to handle option click
  // console.log(options);

  // woking
  const handleOptionClick = (option) => {
    setMessages([
      ...messages,
      `What issue you are facing in "${option.Title}"`,
    ]); // Add message indicating the selected option
    setSelectedOption(option); // Set selected option
    setOptions([]); // Disable all options by removing them
  };
  const handleOptionlevl2Click = (option2) => {
    setMessages2([`${option2.Title}`]); // Add message indicating the selected option
    setSelectedOption2(option2); // Set selected option
    // setOptions([]); // Disable all options by removing them
  };
  const handleOptionlevl3Click = (option3) => {
    setMessages3([`${option3.Title}`]); // Add message indicating the selected option
    setSelectedOption3(option3);
    setsenddiable(false); // Set selected option
    // setOptions([]); // Disable all options by removing them
  };
  // console.log(messages2);
  const handlechangeDes = (value) => {
    setcurrentMessage(value);
    setMessages4(value);
  };
  const handleSend = () => {
    setsenddiable(true);
    setcurrentMessage("");
    let postdata = {
      CreatedBy: clientdetailss?.[0]?.ClientUserId,
      IncidentId: incidentnewId,
      ClientId: clientd,
      ClientDetails: {
        Company: clientdetailss?.[0]?.ClientDetails.Company,
        ContactName: clientdetailss?.[0]?.ClientDetails.ContactName,
        ContactDesignation:
          clientdetailss?.[0]?.ClientDetails.ContactDesignation,
        ContactEmail: clientdetailss?.[0]?.ClientDetails.ContactEmail,
        ContactPhone: clientdetailss?.[0]?.ClientDetails.ContactPhone,
      },
      CreatedByDetails: {
        FN: clientdetailss?.[0]?.UserFirstname,
        LN: clientdetailss?.[0]?.UserLastname,
      },
      CreatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      CategoryCode: {
        CategoryCode: "DEVOZY",
        CategoryName: "Devozy.ai",
      },
      SubCategoryCode: {
        SubCategoryCode: selectedOption?.Code,
        SubCategoryName: selectedOption?.Title,
      },
      ServiceAffected: {
        ServiceCode: "",
        ServiceName: "",
      },
      IncidentTitle: selectedOption2.Title + "-" + selectedOption3?.Title,
      Description: messages4,
      Impact: {
        ImpactCode: "1",
        ImpactName: "High",
      },
      Urgency: {
        UrgencyCode: "1",
        UrgencyName: "High",
      },
      Priority: {
        PriortyCode: "P2",
        PriortyName: "Wide impact",
      },
      UpdateNotes: [
        {
          NoteSno: 1,
          DateTime: null,
          NoteUpdatedBy: null,
          NoteUpdatedByFN: null,
          NotesDetails: null,
        },
      ],
      AssignedTo: null,
      AssignedToDetails: {
        FN: null,
        LN: null,
      },
      AssignedGroup: {
        GroupCode: "1",
        GroupName: "Devozy.ai L1 Support",
      },
      CurrentState: {
        StateCode: "New",
        StateName: "New",
      },
      IsSelfRequest: "N",
      // RaiseFor == "Self" ? "Y" : "N",
      RaisingOnBehalfDetails: null,
      // RaiseFor == "Someone" ? mapping[0]?.teammemberid : null,
      IncidentReference: "INC" + clientd + "I" + incidentnewId,
      // "INC" + arr[0].ClientId + "I" + incidentnewId
    };
    let patchdatai = {
      IncidentId: incidentnewId,
      IncidentPriority: "P2",
      IsSLAApplicable: "Y",
      IsSLAHolidayApplicable: "Y",
      ClientId: clientd,
      ClientDetails: {
        Company: clientdetailss?.[0]?.ClientDetails.Company,
        ContactName: clientdetailss?.[0]?.ClientDetails.ContactName,
        ContactDesignation:
          clientdetailss?.[0]?.ClientDetails.ContactDesignation,
        ContactEmail: clientdetailss?.[0]?.ClientDetails.ContactEmail,
        ContactPhone: clientdetailss?.[0]?.ClientDetails.ContactPhone,
      },
    };
    // console.log(postdata);
    axios.post(`agilesaas_incident_details`, postdata).then((res) => {
      // setLoading(false);
      // console.log(res.data, "post ");
      axios.post(`agilesaas_incident_sla_details`, patchdatai).then((res) => {
        // console.log(res.data);
      });
      setfinalMessage(true);
    });
  };
  let level2data = KanbanMenumain?.[0]?.ChatDetails.find(
    (item) => item.Parent === selectedOption?.Code && item.Level === "2"
  );
  let level3data = KanbanMenumain?.[0]?.ChatDetails.find(
    (item) => item.Parent === selectedOption2?.Code && item.Level === "3"
  );
  const messageclear = () => {
    setMessages([]);
    setMessages2([]);
    setMessages3([]);
    setfinalMessage(false);
    setcurrentMessage(null);
    setSelectedOption(null);
    setSelectedOption2(null);
    setSelectedOption3(null);

    setOptions(
      KanbanMenumain?.[0]?.ChatDetails.filter((e) => e.Level == 1)?.[0]?.Details
    );
  };

  // const onsearchChange = (event) => {
  //   // console.log(e.target.value, "check change")
  //   // let value = e.target.value.toLowerCase()
  //   // const filtered = FilteredData?.filter(
  //   //   (d) =>
  //   //     d?.IssueTitle.toLowerCase().includes(value)
  //   //   // ||
  //   //   // d.answer.toLowerCase().includes(query)
  //   // );
  //   // console.log(filtered, value, "filter")
  //   // setFilteredData(filtered);
  //   const valid = FilteredData?.filter(
  //     (y) =>
  //       y.IssueTitle.toLowerCase().includes(e.toLowerCase()) ||
  //       y.IssueId.toString().includes(e.toString())
  //   );
  //   let inputdata = FilteredData?.filter(
  //     (y) =>
  //       y.IssueTitle.toLowerCase().includes(e.toLowerCase()) ||
  //       y.IssueId.toString().includes(e.toString())
  //   );

  //   if (inputdata != "") {
  //     setFilteredData(inputdata);
  //     setvalid(null);
  //   } else if (inputdata != valid) {
  //     setvalid(<span>No stories / issues to filter for your keyword</span>);
  //   }
  // };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchText]);

  const onSearch = (event) => {
    event.preventDefault();
    let e = event.target.value;
    // setSearchText(e.toLowerCase());
    dispatch(HANDLE_ON_CHANGE({ name: "searchText", value: e.toLowerCase() }));
  };
  // const handlesearchChange = (e) => {
  //   setFilteredData(KanbanBoardData);
  // };

  // function handleChange(e) {
  //   console.log(e.checkvalue,"value");
  //   if (!e.checkvalue) {
  //     Filtername.push(e.EmpId);
  //   } else {
  //     let indexToRemove = Filtername.indexOf(e.EmpId);
  //     if (indexToRemove !== -1) {
  //       // Use splice to remove the element at the specified index
  //       Filtername.splice(indexToRemove, 1);
  //     }
  //   }
  //   let Assetoption = Assetoptions.map((x) => {
  //     if (x.EmpId == e.EmpId) {
  //       return {
  //         EmpId: e.EmpId,
  //         label: e.label,
  //         projectRole: e.projectRole,
  //         value: !e.value,
  //         firstname: e.firstname,
  //         lastname: e.lastname,
  //       };
  //     } else {
  //       return x;
  //     }
  //   });

  //   let Empdrop = EmpDropDownData.map((x) => {
  //     if (x.teammemberid == e.EmpId) {
  //       return {
  //         allocationenddate: x.allocationenddate,
  //         allocationstartdate: x.allocationstartdate,
  //         allocpercentage: x.allocpercentage,
  //         companydetails: x.companydetails,
  //         companyid: x.companyid,
  //         email: x.email,
  //         firstname: x.firstname,
  //         lastname: x.lastname,
  //         managerid: x.managerid,
  //         mappedby: x.mappedby,
  //         mappedbydetails: x.mappedbydetails,
  //         onboardedby: x.onboardedby,
  //         onboardedbydetails: x.onboardedbydetails,
  //         onboardeddate: x.onboardeddate,
  //         phone: x.phone,
  //         projcreatedby: x.projcreatedby,
  //         projcreatedbydetails: x.projcreatedbydetails,
  //         projcreateddate: x.projcreateddate,
  //         projectcode: x.projectcode,
  //         projectmappingid: x.projectmappingid,
  //         projectname: x.projectname,
  //         projectrole: x.projectrole,
  //         projectshortcode: x.projectshortcode,
  //         projenddate: x.projenddate,
  //         projstartdate: x.projstartdate,
  //         teammemberid: x.teammemberid,
  //         checkvalue: !x.checkvalue,
  //       };
  //     } else {
  //       return x;
  //     }
  //   });

  //   // setEmpDropDownData(Empdrop);
  //   dispatch(HANDLE_ON_CHANGE({ name: "EmpDropDownData", value: Empdrop }));

  //   dispatch(HANDLE_ON_CHANGE({ name: "Assetoptions", value: Assetoption }));
  //   // setAssetoptions(Assetoption);
  //   //goto
  //   let filtered = Assetoption.filter((f) => f.value == true).map((x) => {
  //     return KanbanBoardData.filter((f) => f.AssignedTo === x.EmpId);
  //   });
  //   // setFilteredData(
  //   //   filtered.length === 1 && filtered?.[0] === null
  //   //     ? null
  //   //     : filtered.flat().length === 0
  //   //       ? KanbanBoardData
  //   //       : filtered.flat()
  //   // );
  //   dispatch(
  //     HANDLE_ON_CHANGE({
  //       name: "FilteredData",
  //       value:
  //         filtered.length === 1 && filtered?.[0] === null
  //           ? null
  //           : filtered.flat().length === 0
  //             ? KanbanBoardData
  //             : filtered.flat(),
  //     })
  //   );
  // }
  const handleChange = (e) => {


    if (e.label === 'Unassigned') {
      setIsUnassignedChecked(!isUnassignedChecked);
      if (!isUnassignedChecked) {
        // Add "Unassigned" to filter
        Filtername.push(null);
      } else {
        // Remove "Unassigned" from filter
        let indexToRemove = Filtername.indexOf(null);
        if (indexToRemove !== -1) {
          Filtername.splice(indexToRemove, 1);
        }
      }
    } else {
      if (!e.checkvalue) {
        Filtername.push(e.EmpId);
      } else {
        let indexToRemove = Filtername.indexOf(e.EmpId);
        if (indexToRemove !== -1) {
          Filtername.splice(indexToRemove, 1);
        }
      }
    }

    let Assetoption = Assetoptions.map((x) => {
      if (x.EmpId == e.EmpId) {
        return {
          EmpId: e.EmpId,
          label: e.label,
          projectRole: e.projectRole,
          value: !e.value,
          firstname: e.firstname,
          lastname: e.lastname,
        };
      } else {
        return x;
      }
    });

    let Empdrop = EmpDropDownData.map((x) => {
      if (x.teammemberid == e.EmpId || (e.label === 'Unassigned' && x.teammemberid === null)) {
        return {
          ...x,
          checkvalue: !x.checkvalue,
        };
      } else {
        return x;
      }
    });

    dispatch(HANDLE_ON_CHANGE({ name: "EmpDropDownData", value: Empdrop }));
    dispatch(HANDLE_ON_CHANGE({ name: "Assetoptions", value: Assetoption }));

    let filtered = Assetoption.filter((f) => f.value == true).map((x) => {
      return KanbanBoardData.filter((f) => f.AssignedTo === x.EmpId);
    });

    if (Filtername.includes(null)) {
      filtered.push(KanbanBoardData.filter((f) => f.AssignedTo === null));
    }

    dispatch(
      HANDLE_ON_CHANGE({
        name: "FilteredData",
        value:
          filtered.length === 1 && filtered?.[0] === null
            ? null
            : filtered.flat().length === 0
              ? KanbanBoardData
              : filtered.flat(),
      })
    );
  }


  const TesterID = testerid?.map((m) => m.projectrole?.RoleCode);
  const title = projectTitle != null ? " - ( " + projectTitle + " ) " : "";

  //New Search Field
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    marginLeft: 0,
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    height: 38,
    justifyContent: "center",
    transition: "border-color 0.3s",
    "&:focus-within": {
      borderColor: "rgb(82, 80, 223)",
      borderWidth: "2px", // Adjust border width
      borderStyle: "solid", // Set the border style explicitly
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 1),
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    left: 0, // Align the icon to the left side
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      paddingLeft: `25px`,
      width: "100%",
      color: "#000",
      fontSize: "12px",
    },
  }));
  // Assetoption = EmpDropDownData?.map((x) => {
  //   return {
  //     EmpId: x.teammemberid,
  //     label: x.firstname,
  //     projectRole: x.projectrole?.RoleGroupId,
  //     value:false
  //   };
  // });
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiPaper-root": {
        backgroundColor: "#fff",
      },
      // Rest of your styles...
    },
  }));
  const classes = useStyles();
  function handleClearFilter() {
    // setPriority("All");
    dispatch(HANDLE_ON_CHANGE({ name: "priority", value: "All" }));
    // setEpicLabel("All");
    dispatch(HANDLE_ON_CHANGE({ name: "epicLabel", value: "All" }));
    // setreleasefilter("");
    dispatch(HANDLE_ON_CHANGE({ name: "releasefilter", value: "" }));
  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 223)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      // width: '110%',
      // marginLeft: elementWidth > 1300 ? -35 : -20,

      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      // marginTop: 0,
      // marginLeft: elementWidth > 1300 ? -35 : -20,
      width: `${Math.max(
        ...ProjectOption.map((option) => option.label.length * 10)
      )}px`, // Adjust the multiplier based on your content
      minWidth: "100%",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  function openpopup() {
    // setOpenCreate(true);
    dispatch(HANDLE_ON_CHANGE({ name: "openCreate", value: true }));
  }

  const elementHeight = window.innerHeight;
  const elementWidth = window.innerWidth;
  const handleClickToCreate = () => {
    dispatch(PROJECTCREATE(true));
    setLabelClicked(true);
  };
  const mouseenter = (e) => {
    e.currentTarget.style.backgroundColor = "rgba(59,130,246,.5)";
    e.currentTarget.style.borderColor = "rgba(59,130,246,.5)";
    e.currentTarget.style.color = "white";
  };
  const mouseleave = (e) => {
    e.currentTarget.style.backgroundColor = "transparent";
    e.currentTarget.style.borderColor = "grey";
    e.currentTarget.style.color = "grey";
  };

  const toggleMinimize = () => {
    const windowElement = document.getElementById("sprintdetails");

    windowElement.style.display = "none";
    dispatch(ISSPRINTDETAILS(true));

    dispatch(MINIMIZED(true));
  };
  const togglereport = () => {
    const windowElement = document.getElementById("reportv");

    windowElement.style.display = "none";
    dispatch(ISREPORT(true));

    dispatch(MINIMIZED(true));
  };

  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [isButtonHoveredsd, setIsButtonHoveredsd] = useState(false);
  const [isButtonHoveredactions, setIsButtonHoveredactions] = useState(false);

  const isNoActiveSprint = releases?.filter(
    (s) => s.ProjectId === projectCode && s.Status == "In Progress"
  );
  console.log(isNoActiveSprint)
  const sprintcheck =
    releases1?.filter((s) => s.ProjectId === projectCode)?.length > 0;
  const kanbanrole =
    localStorage.getItem("clientdetails") != undefined
      ? JSON.parse(localStorage.getItem("clientdetails"))
      : "";
  const crole =
    kanbanrole?.length > 0 ? kanbanrole?.[0]?.RoleDetails?.RoleCode : "";
  // console.log(
  //   projectDetails?.length,
  //   getProdRole,
  //   getProdRole == "KA",
  //   getProdRole == "SM",
  //   "errrrrrrrrrrrrrrrrrrrrrr",
  //   kanbanrole
  // );

  const devAction = devozyrole != 'undefined' ?
    KanbanMenu?.filter(
      (x) =>
        x.MenuId !== 20 &&
        x.MenuId !== 21 &&
        x.MenuId !== 19
    ) :
    KanbanMenu?.filter(
      (x) =>
        x.MenuId !== 20 &&
        x.MenuId !== 21 &&
        x.MenuId !== 19 &&
        x.MenuId !== 22
    )
  const filtermenu = projectRole == "SM" ? devAction : devAction?.filter((x) => x.MenuId != 91)
  // console.log(KanbanMenu)
  console.log(releases?.length, releases1?.length, releasedata?.length)
  return (
    <>
      {isLoading ? (
        <div
          className=" d-flex align-items-center"
          style={{ justifyContent: "center", height: 460 }}
        >
          <span
            className="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "rgb(82, 80, 229)" }}
          ></span>
        </div>
      ) : projectDetails?.length == 0 ? (
        <div className="alert_message">
          {getProdRole == "KA" || getProdRole == "SM" || crole == "KA" ? (
            // {dispatch(PROJECTCREATE(true))}
            <>
              <Addnewuserproject
              // KBRefresh={() => KBRefresh()}
              // setRefresh={setRefresh}
              // Refresh={Refresh}
              />

              <h3
                style={{
                  textAlign: "center",
                  justifyItems: "center",
                  height: elementHeight - elementHeight / 1.8,
                  marginTop: elementHeight / 3,
                }}
              >
                No project to load the kanban board.
                <label
                  className=" text-info"
                  style={labelStyle}
                  onClick={handleClickToCreate}
                >
                  {" "}
                  Click here to create a project
                </label>{" "}
                to start use your kanban board, create stories, plan a sprint
                etc.,
              </h3>
            </>
          ) : (
            <h3
              style={{
                textAlign: "center",
                justifyItems: "center",
                height: elementHeight - elementHeight / 1.8,
                marginTop: elementHeight / 3,
              }}
            >
              You are not allocated to any project as of now, please contact
              your manager for project allocation.
            </h3>
          )}
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundColor: "white",
              marginTop: "18px",
              paddingLeft: 15,
              marginBottom: "13px",
            }}
          >
            {projectRole == "SM" ||
              projectRole == "TL" ||
              projectRole == "ITMGR2" ||
              getProdRole == "KA" ||
              role == "SM" ? (
              <Box>
                <Grid container>
                  <Grid item xs={6}>
                    <Box sx={{ paddingRight: 2 }}>
                      {" "}
                      {/* Added padding to create space */}
                      {/* Four Text Inputs in a single row */}
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Select
                            // style={{ marginLeft: -15 }}
                            // maxMenuHeight={150}
                            // className="mr-2 w-200"
                            // styles={customStyles}
                            // style={{ marginRight: -5 }}
                            options={ProjectOption}
                            onChange={(item) => {
                              handleProject(item);
                            }}
                            value={ProjectOption.filter(
                              (option) => option?.label === projectLabel
                            )}
                            styles={customStyles}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          {/* <SearchField
                            // style={{ width: 200 }}
                            placeholder="Search this board"
                            className="react-search-field-input pl-0 pr-0"
                            // className="w-200"
                            onSearchClick={onsearchChange}
                            onEnter={onsearchChange}
                            onChange={(e) => handlesearchChange(e)}
                          /> */}
                          <Search>
                            <SearchIconWrapper>
                              <SearchIcon
                                style={{ fontSize: 15, color: "#616670" }}
                              />
                            </SearchIconWrapper>
                            <StyledInputBase
                              placeholder="Search this board"
                              inputProps={{ "aria-label": "search" }}
                              // onSearchClick={onsearchChange}
                              // onEnter={onsearchChange}
                              value={searchText}
                              onChange={(e) => onSearch(e)}
                              inputRef={inputRef}
                            // onChange={(e) => { filterData(e) }}
                            />
                          </Search>
                        </Grid>
                        {/* <Grid
                          item
                          xs={1}
                          sx={{ flexGrow: 1 }}
                          className="profile-list-icons mt-0 "
                        >
                          {EmpDropDownData?.length != 0 ? (
                            <AvatarGroup>
                              {EmpDropDownData?.filter(
                                (obj, index, self) =>
                                  self.findIndex(
                                    (o) => o.teammemberid === obj.teammemberid
                                  ) === index
                              )
                                .slice(0, 2)
                                ?.map((e) => (
                                
                                  <Avatar
                                    alt=""
                                    src={
                                      allImage?.length > 0
                                        ? allImage?.filter(
                                          (x) =>
                                            x.teammemberid == e.teammemberid
                                        )?.[0]?.image
                                        : Unassigned
                                    }
                                  />
                                ))}
                            </AvatarGroup>
                          ) : null}

                          <Dropdown
                            drop="start"
                           
                            style={{ borderColor: "#fff" }}
                          >
                            <Dropdown.Toggle
                           
                              style={{
                                backgroundColor: "transparent",
                                padding: 0,
                                marginLeft: -15,
                                border: "none",
                                width: 36,
                                height: 36,
                                borderRadius: 20,
                                top: 4,
                              }}
                            >
                              <Avatar
                                sx={{
                                  cursor: "pointer",
                                  zIndex: 99,
                                  color: "#fff",
                                  top: 2,
                                }}
                                style={{
                                  background:
                                    Filtername?.length > 0
                                      ? "rgb(82, 80, 229)"
                                      : "#606060",
                                  width: 36,
                                  height: 36,
                                }}
                                className="m-0"
                              >
                                <span className="Emplen">
                                  +{" "}
                                  {
                                    EmpDropDownData?.filter(
                                      (obj, index, self) =>
                                        self.findIndex(
                                          (o) =>
                                            o.teammemberid === obj.teammemberid
                                        ) === index
                                    ).length
                                  }
                                </span>
                              </Avatar>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="navbar-dropdown">
                              <Paper
                                sx={{ width: 250, padding: "0 10px" }}
                                elevation={0}
                              >
                                <MenuList
                                  dense
                                  sx={{ padding: 0 }}
                                  className="menuheight"
                                >
                                  {EmpDropDownData == null
                                    ? null
                                    : EmpDropDownData?.filter(
                                      (obj, index, self) =>
                                        self.findIndex(
                                          (o) =>
                                            o.teammemberid ===
                                            obj.teammemberid
                                        ) === index
                                    ).map((e) => {
                                      return (
                                        <>
                                         
                                          <Row className="mt-1 d-flex">
                                            <Col md={2}>
                                              <span>
                                                <Checkbox
                                                  edge="end"
                                                  // style={{
                                                  //   paddingRight: "175px",
                                                  // }}
                                                  size="small"
                                                  checked={e.checkvalue}
                                                  onChange={() =>
                                                    handleChange({
                                                      EmpId: e.teammemberid,
                                                      value: e.checkvalue,
                                                      projectRole:
                                                        e.projectrole
                                                          .RoleGroupId,
                                                      label:
                                                        e.firstname +
                                                        e.lastname,
                                                      checkvalue:
                                                        e.checkvalue,
                                                    })
                                                  }
                                                  disablePadding
                                                  className="check-box mb-2"
                                                />
                                              </span>
                                            </Col>
                                            <Col>
                                              <span className="d-flex align-items-center pt-1">
                                                <Avatar
                                                  alt=""
                                                  sx={{
                                                    width: "30px !important",
                                                    height: "30px !important",
                                                  }}
                                                  src={
                                                    allImage?.length > 0
                                                      ? allImage?.filter(
                                                        (x) =>
                                                          x.teammemberid ==
                                                          e.teammemberid
                                                      )?.[0]?.image
                                                      : Unassigned
                                                  }
                                                />

                                                <span className="pl-2">
                                                  {e.firstname +
                                                    " " +
                                                    e.lastname}
                                                </span>
                                              </span>
                                            </Col>
                                          </Row>
                                          
                                        </>
                                      );
                                    })}
                                </MenuList>
                              </Paper>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Grid> */}
                        <Grid
                          item
                          xs={1}
                          sx={{ flexGrow: 1 }}
                          className="profile-list-icons mt-0 "
                        >
                          {EmpDropDownData?.length !== 0 ? (
                            <AvatarGroup>
                              {EmpDropDownData?.filter(
                                (obj, index, self) =>
                                  self.findIndex((o) => o.teammemberid === obj.teammemberid) === index
                              )
                                .slice(0, 2)
                                ?.map((e) => (
                                  <Avatar
                                    alt=""
                                    src={
                                      allImage?.length > 0
                                        ? allImage?.filter((x) => x.teammemberid == e.teammemberid)?.[0]?.image
                                        : Unassigned
                                    }
                                  />
                                ))}
                            </AvatarGroup>
                          ) : null}

                          <Dropdown drop="start" style={{ borderColor: "#fff" }}>
                            <Dropdown.Toggle
                              style={{
                                backgroundColor: "transparent",
                                padding: 0,
                                marginLeft: -15,
                                border: "none",
                                width: 36,
                                height: 36,
                                borderRadius: 20,
                                top: 4,
                              }}
                            >
                              <Avatar
                                sx={{
                                  cursor: "pointer",
                                  zIndex: 99,
                                  color: "#fff",
                                  top: 2,
                                }}
                                style={{
                                  background: Filtername?.length > 0 ? "rgb(82, 80, 229)" : "#606060",
                                  width: 36,
                                  height: 36,
                                }}
                                className="m-0"
                              >
                                <span className="Emplen">
                                  +{" "}
                                  {
                                    EmpDropDownData?.filter(
                                      (obj, index, self) =>
                                        self.findIndex((o) => o.teammemberid === obj.teammemberid) === index
                                    ).length
                                  }
                                </span>
                              </Avatar>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="navbar-dropdown">
                              <Paper sx={{ width: 250, padding: "0 10px" }} elevation={0}>
                                <MenuList dense sx={{ padding: 0 }} className="menuheight">
                                  {/* Add Unassigned Option First */}
                                  <Row className="mt-1 d-flex">
                                    <Col md={2}>
                                      <span>
                                        <Checkbox
                                          edge="end"
                                          size="small"
                                          checked={isUnassignedChecked}
                                          onChange={() =>
                                            handleChange({
                                              EmpId: null,
                                              value: isUnassignedChecked,
                                              projectRole: null,
                                              label: 'Unassigned',
                                              checkvalue: isUnassignedChecked,
                                            })
                                          }
                                          disablePadding
                                          className="check-box mb-2"
                                        />
                                      </span>
                                    </Col>
                                    <Col>
                                      <span className="d-flex align-items-center pt-1">
                                        <Avatar
                                          alt="Unassigned"
                                          sx={{
                                            width: "30px !important",
                                            height: "30px !important",
                                          }}
                                          src={Unassigned}
                                        />
                                        <span className="pl-2">Unassigned</span>
                                      </span>
                                    </Col>
                                  </Row>
                                  {EmpDropDownData == null ? null : EmpDropDownData.filter(
                                    (obj, index, self) =>
                                      self.findIndex((o) => o.teammemberid === obj.teammemberid) === index
                                  ).map((e) => {
                                    return (
                                      <>
                                        <Row className="mt-1 d-flex">
                                          <Col md={2}>
                                            <span>
                                              <Checkbox
                                                edge="end"
                                                size="small"
                                                checked={e.checkvalue}
                                                onChange={() =>
                                                  handleChange({
                                                    EmpId: e.teammemberid,
                                                    value: e.checkvalue,
                                                    projectRole: e.projectrole.RoleGroupId,
                                                    label: e.firstname + e.lastname,
                                                    checkvalue: e.checkvalue,
                                                  })
                                                }
                                                disablePadding
                                                className="check-box mb-2"
                                              />
                                            </span>
                                          </Col>
                                          <Col>
                                            <span className="d-flex align-items-center pt-1">
                                              <Avatar
                                                alt=""
                                                sx={{
                                                  width: "30px !important",
                                                  height: "30px !important",
                                                }}
                                                src={
                                                  allImage?.length > 0
                                                    ? allImage?.filter((x) => x.teammemberid == e.teammemberid)?.[0]?.image
                                                    : Unassigned
                                                }
                                              />

                                              <span className="pl-2">
                                                {e.firstname + " " + e.lastname}
                                              </span>
                                            </span>
                                          </Col>
                                        </Row>
                                      </>
                                    );
                                  })}
                                </MenuList>
                              </Paper>
                            </Dropdown.Menu>

                          </Dropdown>
                        </Grid>



                        <Grid item xs={2}>
                          <div className="d-flex">
                            <Dropdown
                              drop="down-centered"
                              className="filter-button ml-3"
                            >
                              <Dropdown.Toggle
                                className="filter-toggle mt-1 ml-5 mr-0 p-0"
                                style={{
                                  backgroundColor: "transparent",
                                  borderRadius: 8,
                                }}
                              >
                                <Button
                                  variant="outline-info"
                                  className="rounded-3"
                                  style={{
                                    // color:
                                    //   priority === "All" && releasefilter === "" && epicLabel === "All"
                                    //     ? "grey"
                                    //     : "rgb(82, 80, 229)",
                                    // fontSize: 12,
                                    // border:
                                    //   priority === "All" && releasefilter === "" && epicLabel === "All"
                                    //     ? "1px solid grey"
                                    //     : "1px solid rgb(82, 80, 229)",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 8,
                                    // fontWeight: "600",
                                    height: 38,
                                    display: "flex", // Use flexbox to center content horizontally
                                    alignItems: "center", // Center content vertically
                                    justifyContent: "center", // Center content horizontally
                                    border: "1px solid grey",
                                    color: "grey",
                                  }}
                                  onMouseEnter={mouseenter}
                                  onMouseLeave={mouseleave}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FilterListIcon
                                      fontSize="small"
                                      className="filtericon fontdesign"
                                    />
                                    {priority === "All" &&
                                      releasefilter === "" &&
                                      epicLabel === "All"
                                      ? " Filters"
                                      : priority !== "All" &&
                                        releasefilter !== "" &&
                                        epicLabel !== "All"
                                        ? " 3 Filters applied"
                                        : (priority !== "All" &&
                                          releasefilter !== "") ||
                                          (epicLabel !== "All" &&
                                            priority !== "All") ||
                                          (releasefilter !== "" &&
                                            epicLabel !== "All")
                                          ? " 2 Filters applied"
                                          : " 1 Filter applied"}
                                  </div>
                                </Button>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="navbar-dropdown">
                                <Paper
                                  sx={{ width: 200, padding: "0 5px" }}
                                  elevation={0}
                                >
                                  <Box sx={{ padding: "5px 0" }}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: 10, fontWeight: 600 }}
                                        className="fontdesign"
                                      >
                                        FILTERS
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          fontSize: 9,
                                          color: "#904ee2",
                                          cursor: "pointer",
                                          fontWeight: "bold",
                                        }}
                                        className="fontdesign"
                                        onClick={handleClearFilter}
                                      >
                                        Clear
                                      </Typography>
                                    </Stack>
                                  </Box>
                                  <Divider />
                                  <Box sx={{ padding: "10px 0" }}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: 9, fontWeight: "600" }}
                                        className="fontdesign"
                                      >
                                        PRIORITY
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <div
                                        className={
                                          priority == "Highest"
                                            ? "filteredone"
                                            : "none"
                                        }
                                      >
                                        <Tooltip
                                          title="Highest"
                                          placement="bottom"
                                          onClick={() =>
                                            handlePriority("Highest")
                                          }
                                        >
                                          <KeyboardDoubleArrowUpIcon
                                            className="filter-icons-1"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </div>
                                      <div
                                        className={
                                          priority == "High"
                                            ? "filteredone"
                                            : "none"
                                        }
                                      >
                                        <Tooltip
                                          title="High"
                                          placement="bottom"
                                          onClick={() => handlePriority("High")}
                                        >
                                          <KeyboardArrowUpIcon
                                            className="filter-icons-1"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </div>
                                      <div
                                        className={
                                          priority == "Medium"
                                            ? "filteredone"
                                            : "none"
                                        }
                                      >
                                        <Tooltip
                                          title="Medium"
                                          placement="bottom"
                                          onClick={() =>
                                            handlePriority("Medium")
                                          }
                                        >
                                          <DragHandleIcon
                                            className="filter-icons"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </div>
                                      <div
                                        className={
                                          priority == "Low"
                                            ? "filteredone"
                                            : "none"
                                        }
                                      >
                                        <Tooltip
                                          title="Low"
                                          placement="bottom"
                                          onClick={() => handlePriority("Low")}
                                        >
                                          <KeyboardArrowDownIcon
                                            className="filter-icons-2"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </div>
                                      <div
                                        className={
                                          priority == "Lowest"
                                            ? "filteredone"
                                            : "none"
                                        }
                                      >
                                        <Tooltip
                                          title="Lowest"
                                          placement="bottom"
                                          onClick={() =>
                                            handlePriority("Lowest")
                                          }
                                        >
                                          <KeyboardDoubleArrowDownIcon
                                            className="filter-icons-2"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </div>
                                    </Stack>
                                  </Box>
                                  <Divider />
                                  <Box sx={{ padding: "10px 0" }}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: 9, fontWeight: "600" }}
                                        className="fontdesign"
                                      >
                                        ISSUE TYPES
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={3}
                                      sx={{ margin: "5px 0" }}
                                    >
                                      <div
                                        className={
                                          epicLabel == "Epic"
                                            ? "selecttask"
                                            : "none"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 9 }}
                                          onClick={() => handleEpic("Epic")}
                                        >
                                          <OfflineBoltRoundedIcon
                                            fontSize="small"
                                            className="epic fontdesign"
                                          />
                                          Epic
                                        </Typography>
                                      </div>
                                      <div
                                        className={
                                          epicLabel == "Bug"
                                            ? "selecttask"
                                            : "none"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 9 }}
                                          onClick={() => handleEpic("Bug")}
                                        >
                                          <StopCircleIcon
                                            fontSize="small"
                                            className="bug fontdesign"
                                          />
                                          Bug
                                        </Typography>
                                      </div>
                                      <div
                                        className={
                                          epicLabel == "Story"
                                            ? "selecttask"
                                            : "none"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 9 }}
                                          onClick={() => handleEpic("Story")}
                                        >
                                          <Bookmark
                                            className="story fontdesign"
                                            fontSize="small"
                                          />
                                          Story
                                        </Typography>
                                      </div>
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={3}
                                      sx={{ margin: "5px 0" }}
                                    >
                                      <div
                                        className={
                                          epicLabel == "Task"
                                            ? "selecttask"
                                            : "none"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 9 }}
                                          onClick={() => handleEpic("Task")}
                                        >
                                          <CheckBoxRoundedIcon
                                            className="task fontdesign"
                                            fontSize="small"
                                          />
                                          Task
                                        </Typography>
                                      </div>
                                      <div
                                        className={
                                          epicLabel == "SubTask"
                                            ? "selecttask"
                                            : "none"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 9 }}
                                          onClick={() => handleEpic("SubTask")}
                                        >
                                          <AddToPhotosIcon
                                            className="task fontdesign"
                                            fontSize="small"
                                          />
                                          Subtask
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Box>
                                  <Divider />
                                  <Box sx={{ padding: "10px 0" }}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: 9, fontWeight: "600" }}
                                        className="fontdesign"
                                      >
                                        RELEASES
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction="column"
                                      sx={{ margin: "5px 0" }}
                                    >
                                      {updatedOptions.length === 0 ? (
                                        <label className="text-danger">
                                          No Releases Found
                                        </label>
                                      ) : (
                                        updatedOptions.map((e) => (

                                          <div
                                            key={e.label}
                                            // className={
                                            //   releasefilter === e.label  ? "selecttask" : "selecttasknull"
                                            // }
                                            className={
                                              releasefilter === e.label
                                                ? e.label === 'No Sprint Assigned'
                                                  ? "noReleaseSelected"
                                                  : "selecttask"
                                                : "selecttasknull"
                                            }
                                            style={
                                              releasefilter === e.label ? { backgroundColor: '#e0e0e0' } : {}
                                            }
                                          >
                                            <div
                                              onClick={() => releasefil(e.value)}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <img
                                                src={e.label === 'No Sprint Assigned' ? agile : agile}
                                                alt={e.label}
                                                className="image-class task fontdesign"
                                                width="30"
                                                height="30"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <Typography
                                                variant="subtitle2"
                                                sx={{ fontSize: 9 }}
                                              >
                                                {e.label}
                                              </Typography>
                                            </div>
                                          </div>
                                        ))
                                      )}
                                    </Stack>

                                  </Box>
                                </Paper>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Tooltip title="Refresh">
                              <RefreshOutlinedIcon

                                style={{
                                  fontSize: "26px",
                                  marginLeft: "15px",
                                  cursor: "pointer",
                                  position: "relative",
                                  verticalAlign: "middle",
                                  marginTop: "10px",
                                  animation: isLoadingb
                                    ? "spin 2s linear infinite"
                                    : "none", // Apply spin animation if isLoading is true
                                }}
                                onClick={!isLoadingb ? Refreshboard : null}
                              />
                            </Tooltip>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        height: 38,
                        width: "96%",
                        gap: 6,
                      }}
                      className="mr-3"
                    >
                      <Button
                        variant="info"
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          openpopup();
                          dispatch(
                            HANDLE_ON_CHANGE({
                              name: "issueType",
                              value:
                                KanbanBoardData.filter(
                                  (e) =>
                                    e.ProjectId == projectCode &&
                                    e.IsActive == "Y" &&
                                    e.IssueType == "Epic"
                                ).length == 0
                                  ? "Epic"
                                  : projectRole != "SM" && getProdRole != "KA"
                                    ? "Task"
                                    : "Story",
                            })
                          );
                          // setIssueType(
                          //   KanbanBoardData.filter(
                          //     (e) =>
                          //       e.ProjectId == projectCode &&
                          //       e.IsActive == "Y" &&
                          //       e.IssueType == "Epic"
                          //   ).length == 0
                          //     ? "Epic"
                          //     : projectRole != "SM" && getProdRole != "KA"
                          //       ? "Task"
                          //       : "Story"
                          // );
                          if (openCreate == true) {
                            const windowElement =
                              document.getElementById("createtask");

                            windowElement.style.display = "block";
                          }
                        }}
                        style={{
                          backgroundColor: "#5250e5",
                          borderRadius: 8,
                          textAlign: "center",
                        }}
                      >
                        <AddCircleOutlineOutlinedIcon
                          fontSize="small"
                          className="addicon mr-1"
                        />
                        Create
                      </Button>
                      {
                        releasedata?.length > 0 ?
                          <div>
                            <OverlayTrigger
                              // style={{ border: '1px solid red' }}
                              placement="top-start"
                              overlay={
                                // <ReactBootstrapTooltip
                                // // className="bg-danger"
                                // // style={{ backgroundColor: '#f-f0000', color: '#fff' }} // Adjust the background color and text color here
                                // // id="button-tooltip"
                                // // className="text-danger mt-1" style={{ fontSize: "10px" }}
                                // >
                                //   No active / historic sprint available
                                // </ReactBootstrapTooltip>
                                <Popover
                                  id="popover-basic"
                                  className="border-0 shadow-sm"
                                  style={{ backgroundColor: "#f5f3f3", color: "red" }}
                                >
                                  {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
                                  <Popover.Content className="text-dark">
                                    No active / historic sprint available
                                  </Popover.Content>
                                </Popover>
                              }
                              show={isButtonHovered && isNoActiveSprint?.length == 0}
                            >
                              {/* Button element with your existing props */}
                              <Button
                                variant="outline-info"
                                className="rounded-3"
                                style={{
                                  borderRadius: 8,
                                  border: "1px solid grey",
                                  color: "grey",
                                }}
                                // disabled={
                                //   releases?.filter((s) => s.ProjectId === projectCode)?.map((e) => moment(e.SprintEndDate) > moment()).length === 0
                                // }
                                // onMouseEnter={mouseenter}
                                // onMouseLeave={mouseleave}
                                // disabled={isNoActiveSprint}
                                onMouseEnter={() => setIsButtonHovered(true)}
                                onMouseLeave={() => setIsButtonHovered(false)}
                                size="sm"
                                onClick={() => {
                                  if (isNoActiveSprint?.length == 0) {
                                    setIsButtonHovered(true);
                                  } else {
                                    setManageNoteopen(true);
                                    if (manageNotesopen) {
                                      const windowElement =
                                        document.getElementById("Minimized");
                                      windowElement.style.display = "block";
                                    }
                                  }
                                }}
                              >
                                <DescriptionOutlinedIcon
                                  fontSize="small"
                                  className="addicon mr-1"
                                />
                                Sprint notes
                              </Button>
                            </OverlayTrigger>

                            {manageNotesopen ? (
                              <ManagerNotes
                                open={manageNotesopen}
                                setManageNoteopen={setManageNoteopen}
                              />
                            ) : null}
                            <OverlayTrigger
                              // style={{ border: '1px solid red' }}
                              placement="top-start"
                              overlay={
                                // <ReactBootstrapTooltip
                                // // className="bg-danger"
                                // // style={{ backgroundColor: '#f-f0000', color: '#fff' }} // Adjust the background color and text color here
                                // // id="button-tooltip"
                                // // className="text-danger mt-1" style={{ fontSize: "10px" }}
                                // >
                                //   No active / historic sprint available
                                // </ReactBootstrapTooltip>
                                <Popover
                                  id="popover-basic"
                                  className="border-0 shadow-sm"
                                  style={{ backgroundColor: "#f5f3f3", color: "red" }}
                                >
                                  {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
                                  <Popover.Content className="text-dark">
                                    No active / historic sprint available
                                  </Popover.Content>
                                </Popover>
                              }
                              show={isButtonHoveredsd && !releasedata?.length > 0}
                            >
                              <Button
                                variant="outline-info"
                                className="rounded-3"
                                style={{
                                  //backgroundColor: "#fff", width: elementWidth ? "122px" : "118px",
                                  borderRadius: 8,
                                  border: "1px solid grey",
                                  color: "grey",
                                }}
                                // disabled={releases.length == 0}
                                onMouseEnter={(e) => {
                                  mouseenter(e);
                                  setIsButtonHoveredsd(true);
                                }}
                                onMouseLeave={(e) => {
                                  mouseleave(e);
                                  setIsButtonHoveredsd(false);
                                }}
                                size="sm"
                                onClick={() => {
                                  // setManageNoteopen(true);
                                  if (!releasedata?.length > 0) {
                                    setIsButtonHoveredsd(true);
                                  } else {
                                    // sethistoryrelease(true);
                                    dispatch(
                                      HANDLE_ON_CHANGE({
                                        name: "historyrelease",
                                        value: true,
                                      })
                                    );
                                    if (historyrelease == true) {
                                      const windowElement =
                                        document.getElementById("sprintdetails");

                                      windowElement.style.display = "block";
                                    }
                                  }
                                }}
                              >
                                <MapIcon fontSize="small" className="addicon mr-1" />
                                {/* <i class="fa fa-map" aria-hidden="true"></i> */}
                                Sprint details
                              </Button>
                            </OverlayTrigger>
                          </div>
                          :
                          <Button
                            variant="outline-info"
                            className="rounded-3"
                            style={{
                              //backgroundColor: "#fff", width: elementWidth ? "122px" : "118px",
                              borderRadius: 8,
                              border: "1px solid grey",
                              color: "grey",
                            }}
                            // disabled={releases.length == 0}
                            // onMouseEnter={(e) => {
                            //   mouseenter(e);
                            //   setIsButtonHoveredsd(true);
                            // }}
                            // onMouseLeave={(e) => {
                            //   mouseleave(e);
                            //   setIsButtonHoveredsd(false);
                            // }}
                            size="sm"
                            onClick={() => {

                              dispatch(
                                HANDLE_ON_CHANGE({
                                  name: "refinementpopup",
                                  value: true,
                                })
                              );
                            }}
                          >
                            {/* <MapIcon fontSize="small" className="addicon mr-1" /> */}
                            <i className="fa fa-retweet addicon mr-1" ></i>
                            {/* <i class="fa fa-map" aria-hidden="true"></i> */}
                            Refinement
                          </Button>
                      }
                      <Button
                        style={{
                          position: "relative",
                          //width: elementWidth ? "120px" : "118px",
                          borderRadius: 8,
                          //textAlign: 'center'
                          border: "1px solid grey",
                          color: "grey",
                        }}
                        onMouseEnter={mouseenter}
                        onMouseLeave={mouseleave}
                        variant="outline-info"
                        size="sm"
                        fontSize="small"
                        className="action-icon fontdesign mr-2"
                        id="demo-positioned-button"
                        onClick={handleClick}
                      >
                        {" "}
                        Actions
                        <KeyboardArrowDownIcon
                          fontSize="small"
                          className="addicon mr-1"
                        />
                      </Button>
                      <div style={{ display: "inline-block" }}>
                        <Menu
                          id="demo-positioned-menu"
                          style={{ position: "absolute" }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleaction}
                          PaperProps={{
                            style: { maxHeight: "300px", width: "150px" },
                          }}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          {KanbanMenu?.filter(
                            (x) => x.MenuId !== 20 && x.MenuId !== 21
                          )
                            .sort((a, b) => a.Sequence - b.Sequence)
                            .map((e, index) => (
                              // <OverlayTrigger
                              //   // style={{ border: '1px solid red' }}
                              //   placement="top-start"
                              //   overlay={
                              //     <Popover id="popover-basic" className="border-0 shadow-sm" style={{ backgroundColor: '#f5f3f3', color: 'red' }}>
                              //       {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
                              //       <Popover.Content className="text-dark">
                              //         No active / historic sprint available
                              //       </Popover.Content>
                              //     </Popover>
                              //   }
                              //   show={isButtonHoveredactions == index && releases.length === 0 && (e.MenuId === 24 || e.MenuId === 23 || e.MenuId === 30)}
                              // >
                              <MenuItem
                                // onMouseEnter={(e) => {
                                //   if (releases.length === 0 && (e.MenuId === 24 || e.MenuId === 23 || e.MenuId === 30)) {
                                //     setIsButtonHoveredactions(index)
                                //   }
                                // }}
                                // onMouseLeave={(e) => {
                                //   if (releases.length === 0 && (e.MenuId === 24 || e.MenuId === 23 || e.MenuId === 30)) {
                                //     setIsButtonHoveredactions("")
                                //   }
                                // }}
                                key={e.MenuId}
                                className="action-btn"
                                onClick={() => {
                                  // console.log(e.MenuId)
                                  if (
                                    releasedata?.filter(
                                      (x) => x.IsReleaseFrozen == "Y"
                                    )?.length === 0 &&
                                    (e.MenuId === 24 ||
                                      e.MenuId === 23 ||
                                      e.MenuId === 30)
                                  ) {
                                    setIsButtonHoveredactions(index);
                                  } else {
                                    handlemenua(e.MenuId);
                                  }
                                }}
                              // disabled={releases.length === 0 && (e.MenuId === 24 || e.MenuId === 23 || e.MenuId === 30)}
                              >
                                {/* {console.log(
                                  e.MenuName,
                                  e.IconDetails,
                                  "consoleeeeeee"
                                )} */}
                                <i className={e.IconDetails}></i>
                                <span className="ml-2">{e.MenuName}</span>
                              </MenuItem>
                              // </OverlayTrigger>
                            ))}
                        </Menu>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box style={{ marginBottom: "7px" }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box sx={{ paddingRight: 2 }}>
                      {" "}
                      {/* Added padding to create space */}
                      {/* Four Text Inputs in a single row */}
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Select
                            // style={{ marginLeft: -15 }}
                            // maxMenuHeight={150}
                            // className="mr-2 w-200"
                            // styles={customStyles}
                            // style={{ marginRight: -5 }}
                            options={ProjectOption}
                            onChange={(item) => {
                              handleProject(item);
                            }}
                            value={ProjectOption.filter(
                              (option) => option?.label === projectLabel
                            )}
                            styles={customStyles}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          {/* <SearchField
                            // style={{ width: 200 }}
                            placeholder="Search this board"
                            className="react-search-field-input pl-0 pr-0"
                            // className="w-200"
                            onSearchClick={onsearchChange}
                            onEnter={onsearchChange}
                            onChange={(e) => handlesearchChange(e)}
                          /> */}
                          <Search>
                            <SearchIconWrapper>
                              <SearchIcon
                                style={{ fontSize: 15, color: "#616670" }}
                              />
                            </SearchIconWrapper>
                            <StyledInputBase
                              placeholder="Search this board"
                              inputProps={{ "aria-label": "search" }}
                              // onSearchClick={onsearchChange}
                              // onEnter={onsearchChange}
                              value={searchText}
                              onChange={(e) => onSearch(e)}
                              inputRef={inputRef}
                            // onChange={(e) => { filterData(e) }}
                            />
                          </Search>
                        </Grid>
                        {projectRole == "TL" ? (
                          <Grid
                            item
                            xs={1}
                            sx={{ flexGrow: 1 }}
                            className="profile-list-icons mt-0 "
                          >
                            {EmpDropDownData?.length != 0 ? (
                              <AvatarGroup>
                                {EmpDropDownData?.filter(
                                  (obj, index, self) =>
                                    self.findIndex(
                                      (o) => o.teammemberid === obj.teammemberid
                                    ) === index
                                )
                                  .slice(0, 2)
                                  ?.map((e) => (
                                    // <AvatarImage
                                    //   clientId={clientId}
                                    //   AssignedTo={e.teammemberid}
                                    //   FN={e.firstname}
                                    //   LN={e.lastname} />
                                    <Avatar
                                      alt=""
                                      src={
                                        allImage?.length > 0
                                          ? allImage?.filter(
                                            (x) =>
                                              x.teammemberid == e.teammemberid
                                          )?.[0]?.image
                                          : Unassigned
                                      }
                                    />
                                  ))}
                              </AvatarGroup>
                            ) : null}

                            <Dropdown
                              drop="start"
                              // className={
                              // EmpDropDownData?.filter(
                              //   (obj, index, self) =>
                              //     self.findIndex(
                              //       (o) => o.teammemberid === obj.teammemberid
                              //     ) === index
                              // ).length < 3
                              //   ? "profile-button2"
                              //   :
                              //   "profile-button"
                              // }
                              style={{ borderColor: "#fff" }}
                            >
                              <Dropdown.Toggle
                                // className="profile-toggle"
                                style={{
                                  backgroundColor: "transparent",
                                  padding: 0,
                                  marginLeft: -15,
                                  border: "none",
                                  width: 36,
                                  height: 36,
                                  borderRadius: 20,
                                  top: 4,
                                }}
                              >
                                <Avatar
                                  sx={{
                                    cursor: "pointer",
                                    zIndex: 99,
                                    color: "#fff",
                                    top: 2,
                                  }}
                                  style={{
                                    background:
                                      Filtername?.length > 0
                                        ? "rgb(82, 80, 229)"
                                        : "#606060",
                                    width: 36,
                                    height: 36,
                                  }}
                                  className="m-0"
                                >
                                  <span className="Emplen">
                                    +{" "}
                                    {
                                      EmpDropDownData?.filter(
                                        (obj, index, self) =>
                                          self.findIndex(
                                            (o) =>
                                              o.teammemberid ===
                                              obj.teammemberid
                                          ) === index
                                      ).length
                                    }
                                  </span>
                                </Avatar>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="navbar-dropdown">
                                <Paper
                                  sx={{ width: 250, padding: "0 10px" }}
                                  elevation={0}
                                >
                                  <MenuList
                                    dense
                                    sx={{ padding: 0 }}
                                    className="menuheight"
                                  >
                                    {EmpDropDownData == null
                                      ? null
                                      : EmpDropDownData?.filter(
                                        (obj, index, self) =>
                                          self.findIndex(
                                            (o) =>
                                              o.teammemberid ===
                                              obj.teammemberid
                                          ) === index
                                      ).map((e) => {
                                        return (
                                          <>
                                            {/* goto */}
                                            <Row className="mt-1 d-flex">
                                              <Col md={2}>
                                                <span>
                                                  <Checkbox
                                                    edge="end"
                                                    // style={{
                                                    //   paddingRight: "175px",
                                                    // }}
                                                    size="small"
                                                    checked={e.checkvalue}
                                                    onChange={() =>
                                                      handleChange({
                                                        EmpId: e.teammemberid,
                                                        value: e.checkvalue,
                                                        projectRole:
                                                          e.projectrole
                                                            .RoleGroupId,
                                                        label:
                                                          e.firstname +
                                                          e.lastname,
                                                        checkvalue:
                                                          e.checkvalue,
                                                      })
                                                    }
                                                    disablePadding
                                                    className="check-box mb-2"
                                                  />
                                                </span>
                                              </Col>
                                              <Col>
                                                <span className="d-flex align-items-center pt-1">
                                                  <Avatar
                                                    alt=""
                                                    sx={{
                                                      width:
                                                        "30px !important",
                                                      height:
                                                        "30px !important",
                                                    }}
                                                    src={
                                                      allImage?.length > 0
                                                        ? allImage?.filter(
                                                          (x) =>
                                                            x.teammemberid ==
                                                            e.teammemberid
                                                        )?.[0]?.image
                                                        : Unassigned
                                                    }
                                                  />

                                                  <span className="pl-2">
                                                    {e.firstname +
                                                      " " +
                                                      e.lastname}
                                                  </span>
                                                </span>
                                              </Col>
                                            </Row>
                                            {/* <MenuItem>
                                            <ListItem
                                              secondaryAction={
                                                <Checkbox
                                                  edge="end"
                                                  style={{
                                                    paddingRight: "175px",
                                                  }}
                                                  size="small"
                                                  checked={e.checkvalue}
                                                  onChange={() =>
                                                    handleChange({
                                                      EmpId: e.teammemberid,
                                                      value: e.checkvalue,
                                                      projectRole:
                                                        e.projectrole
                                                          .RoleGroupId,
                                                      label:
                                                        e.firstname +
                                                        e.lastname,
                                                      checkvalue:
                                                        e.checkvalue,
                                                    })
                                                  }
                                                  disablePadding
                                                  className="check-box"
                                                />
                                              }
                                            >
                                              <ListItemButton
                                                className="check-profile"
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemAvatar className="check-avatar">
                                                  <Avatar
                                                    alt=""
                                                    src={
                                                      allImage?.length > 0
                                                        ? allImage?.filter(
                                                          (x) =>
                                                            x.teammemberid ==
                                                            e.teammemberid
                                                        )?.[0]?.image
                                                        : Unassigned
                                                    }
                                                  />
                                                </ListItemAvatar>
                                                <ListItemText
                                                  className="fontdesign listitem"
                                                  style={{
                                                    paddingLeft: "-150px",
                                                  }}
                                                >
                                                  {e.firstname +
                                                    " " +
                                                    e.lastname}
                                                </ListItemText>
                                              </ListItemButton>
                                            </ListItem>
                                          </MenuItem> */}
                                          </>
                                        );
                                      })}
                                  </MenuList>
                                </Paper>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid item xs={2}>
                          <div className="d-flex">
                            <Dropdown
                              drop="down-centered"
                              className="filter-button "
                            >
                              <Dropdown.Toggle
                                className="filter-toggle mt-1 ml-1 mr-0 p-0"
                                style={{
                                  backgroundColor: "transparent",
                                  borderRadius: 8,
                                }}
                              >
                                <Button
                                  variant="outline-info"
                                  className="rounded-3"
                                  style={{
                                    // color:
                                    //   priority === "All" && releasefilter === "" && epicLabel === "All"
                                    //     ? "grey"
                                    //     : "rgb(82, 80, 229)",
                                    // fontSize: 12,
                                    // border:
                                    //   priority === "All" && releasefilter === "" && epicLabel === "All"
                                    //     ? "1px solid grey"
                                    //     : "1px solid rgb(82, 80, 229)",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 8,
                                    // fontWeight: "600",
                                    height: 38,
                                    display: "flex", // Use flexbox to center content horizontally
                                    alignItems: "center", // Center content vertically
                                    justifyContent: "center", // Center content horizontally
                                    border: "1px solid grey",
                                    color: "grey",
                                  }}
                                  onMouseEnter={mouseenter}
                                  onMouseLeave={mouseleave}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FilterListIcon
                                      fontSize="small"
                                      className="filtericon fontdesign"
                                    />
                                    {priority === "All" &&
                                      releasefilter === "" &&
                                      epicLabel === "All"
                                      ? " Filters"
                                      : priority !== "All" &&
                                        releasefilter !== "" &&
                                        epicLabel !== "All"
                                        ? " 3 Filters applied"
                                        : (priority !== "All" &&
                                          releasefilter !== "") ||
                                          (epicLabel !== "All" &&
                                            priority !== "All") ||
                                          (releasefilter !== "" &&
                                            epicLabel !== "All")
                                          ? " 2 Filters applied"
                                          : " 1 Filter applied"}
                                  </div>
                                </Button>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="navbar-dropdown">
                                <Paper
                                  sx={{ width: 200, padding: "0 5px" }}
                                  elevation={0}
                                >
                                  <Box sx={{ padding: "5px 0" }}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: 10, fontWeight: 600 }}
                                        className="fontdesign"
                                      >
                                        FILTERS
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          fontSize: 9,
                                          color: "#904ee2",
                                          cursor: "pointer",
                                          fontWeight: "bold",
                                        }}
                                        className="fontdesign"
                                        onClick={handleClearFilter}
                                      >
                                        Clear
                                      </Typography>
                                    </Stack>
                                  </Box>
                                  <Divider />
                                  <Box sx={{ padding: "10px 0" }}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: 9, fontWeight: "600" }}
                                        className="fontdesign"
                                      >
                                        PRIORITY
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <div
                                        className={
                                          priority == "Highest"
                                            ? "filteredone"
                                            : "none"
                                        }
                                      >
                                        <Tooltip
                                          title="Highest"
                                          placement="bottom"
                                          onClick={() =>
                                            handlePriority("Highest")
                                          }
                                        >
                                          <KeyboardDoubleArrowUpIcon
                                            className="filter-icons-1"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </div>
                                      <div
                                        className={
                                          priority == "High"
                                            ? "filteredone"
                                            : "none"
                                        }
                                      >
                                        <Tooltip
                                          title="High"
                                          placement="bottom"
                                          onClick={() => handlePriority("High")}
                                        >
                                          <KeyboardArrowUpIcon
                                            className="filter-icons-1"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </div>
                                      <div
                                        className={
                                          priority == "Medium"
                                            ? "filteredone"
                                            : "none"
                                        }
                                      >
                                        <Tooltip
                                          title="Medium"
                                          placement="bottom"
                                          onClick={() =>
                                            handlePriority("Medium")
                                          }
                                        >
                                          <DragHandleIcon
                                            className="filter-icons"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </div>
                                      <div
                                        className={
                                          priority == "Low"
                                            ? "filteredone"
                                            : "none"
                                        }
                                      >
                                        <Tooltip
                                          title="Low"
                                          placement="bottom"
                                          onClick={() => handlePriority("Low")}
                                        >
                                          <KeyboardArrowDownIcon
                                            className="filter-icons-2"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </div>
                                      <div
                                        className={
                                          priority == "Lowest"
                                            ? "filteredone"
                                            : "none"
                                        }
                                      >
                                        <Tooltip
                                          title="Lowest"
                                          placement="bottom"
                                          onClick={() =>
                                            handlePriority("Lowest")
                                          }
                                        >
                                          <KeyboardDoubleArrowDownIcon
                                            className="filter-icons-2"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </div>
                                    </Stack>
                                  </Box>
                                  <Divider />
                                  <Box sx={{ padding: "10px 0" }}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: 9, fontWeight: "600" }}
                                        className="fontdesign"
                                      >
                                        ISSUE TYPES
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={3}
                                      sx={{ margin: "5px 0" }}
                                    >
                                      <div
                                        className={
                                          epicLabel == "Epic"
                                            ? "selecttask"
                                            : "none"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 9 }}
                                          onClick={() => handleEpic("Epic")}
                                        >
                                          <OfflineBoltRoundedIcon
                                            fontSize="small"
                                            className="epic fontdesign"
                                          />
                                          Epic
                                        </Typography>
                                      </div>
                                      <div
                                        className={
                                          epicLabel == "Bug"
                                            ? "selecttask"
                                            : "none"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 9 }}
                                          onClick={() => handleEpic("Bug")}
                                        >
                                          <StopCircleIcon
                                            fontSize="small"
                                            className="bug fontdesign"
                                          />
                                          Bug
                                        </Typography>
                                      </div>
                                      <div
                                        className={
                                          epicLabel == "Story"
                                            ? "selecttask"
                                            : "none"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 9 }}
                                          onClick={() => handleEpic("Story")}
                                        >
                                          <Bookmark
                                            className="story fontdesign"
                                            fontSize="small"
                                          />
                                          Story
                                        </Typography>
                                      </div>
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={3}
                                      sx={{ margin: "5px 0" }}
                                    >
                                      <div
                                        className={
                                          epicLabel == "Task"
                                            ? "selecttask"
                                            : "none"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 9 }}
                                          onClick={() => handleEpic("Task")}
                                        >
                                          <CheckBoxRoundedIcon
                                            className="task fontdesign"
                                            fontSize="small"
                                          />
                                          Task
                                        </Typography>
                                      </div>
                                      <div
                                        className={
                                          epicLabel == "SubTask"
                                            ? "selecttask"
                                            : "none"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ fontSize: 9 }}
                                          onClick={() => handleEpic("SubTask")}
                                        >
                                          <AddToPhotosIcon
                                            className="task fontdesign"
                                            fontSize="small"
                                          />
                                          Subtask
                                        </Typography>
                                      </div>
                                    </Stack>
                                  </Box>
                                  <Divider />
                                  <Box sx={{ padding: "10px 0" }}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: 9, fontWeight: "600" }}
                                        className="fontdesign"
                                      >
                                        RELEASES
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction="column"
                                      // justifyContent="flex-start"
                                      // alignItems="center"
                                      // spacing={3}
                                      sx={{ margin: "5px 0" }}
                                    >
                                      {optionsrelease.length == 0 ? (
                                        <label className="text-danger">
                                          No Releases Found
                                        </label>
                                      ) : (
                                        optionsrelease?.map((e) => (
                                          <div
                                            className={
                                              releasefilter === e.label
                                                ? "selecttask"
                                                : "none"
                                            }
                                          >
                                            <div
                                              onClick={() =>
                                                releasefil(e.value)
                                              }
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                // padding: "5px",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <img
                                                src={agile}
                                                alt="Image"
                                                className="image-class task fontdesign"
                                                width="30"
                                                height="30"
                                                style={{ marginRight: "10px" }}
                                              />
                                              <Typography
                                                variant="subtitle2"
                                                sx={{ fontSize: 9 }}
                                              >
                                                {e.label}
                                              </Typography>
                                            </div>
                                          </div>
                                        ))
                                      )}
                                    </Stack>
                                  </Box>
                                </Paper>
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* <Tooltip title="Refresh">
                              <RefreshOutlinedIcon
                                style={{
                                  fontSize: "26px",
                                  marginLeft: "15px",
                                  cursor: "pointer",
                                  verticalAlign: "middle",
                                  marginTop: "10px",
                                  animation: isLoadingb
                                    ? "spin 2s linear infinite"
                                    : "none", // Apply spin animation if isLoading is true
                                }}
                                onClick={Refreshboard}
                              />
                            </Tooltip>
                            <Checkbox
                              size="small"
                              checked={!allTickets}
                              onChange={() => setallTickets(!allTickets)}
                            ></Checkbox>
                            <span
                              className="pt-2 mt-1"
                              style={{ fontSize: "12px", whiteSpace: "nowrap" }}
                            >
                              Show all tickets
                            </span> */}
                            <span
                              className="d-flex  mt-1 ml-2"
                              style={{
                                border: "1px solid gray",
                                height: "38px",
                                borderRadius: "8px",
                              }}
                            >
                              <Checkbox
                                size="small"
                                checked={!allTickets}
                                onChange={() => setallTickets(!allTickets)}
                              ></Checkbox>
                              <span
                                className=" pr-2"
                                style={{
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                  paddingTop: "10px",
                                }}
                              >
                                Show all tickets
                              </span>
                            </span>
                            <Tooltip title="Refresh">
                              <RefreshOutlinedIcon
                                style={{
                                  fontSize: "26px",
                                  marginLeft: "8px",
                                  cursor: "pointer",
                                  verticalAlign: "middle",
                                  marginTop: "10px",
                                  animation: isLoadingb
                                    ? "spin 2s linear infinite"
                                    : "none", // Apply spin animation if isLoading is true
                                }}
                                onClick={Refreshboard}
                              />
                            </Tooltip>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>{" "}
                  </Grid>

                  <Grid item xs={6}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        height: 38,
                        width: "96%",
                        gap: 6,
                      }}
                      className="mr-3"
                    >
                      {/* <Button
                          variant="info"
                          size="sm"
                          onClick={() => {
                            setcurrentopen(true);
                          }}
                          style={{
                            backgroundColor: "#5250e5",
                            borderRadius: 8,
                            textAlign: "center",
                          }}
                        >
                          <NearMeIcon
                            fontSize="small"
                            className="addicon mr-1"
                          />
                          Current Sprint
                        </Button> */}

                      {projectRole == "SM" ||
                        projectRole == "ITMGR2" ||
                        projectRole == "QA" ||
                        getProdRole == "KA" ||
                        projectRole == "TL" ? (
                        <Button
                          variant="info"
                          size="sm"
                          onClick={(e) => {
                            e.preventDefault();
                            openpopup();
                            dispatch(
                              HANDLE_ON_CHANGE({
                                name: "issueType",
                                value:
                                  KanbanBoardData.filter(
                                    (e) =>
                                      e.ProjectId == projectCode &&
                                      e.IsActive == "Y" &&
                                      e.IssueType == "Epic"
                                  ).length == 0
                                    ? "Epic"
                                    : projectRole != "SM" && getProdRole != "KA"
                                      ? "Task"
                                      : "Story",
                              })
                            );
                            // setIssueType(
                            //   KanbanBoardData.filter(
                            //     (e) =>
                            //       e.ProjectId == projectCode &&
                            //       e.IsActive == "Y" &&
                            //       e.IssueType == "Epic"
                            //   ).length == 0
                            //     ? "Epic"
                            //     : projectRole != "SM" && getProdRole != "KA"
                            //       ? "Task"
                            //       : "Story"
                            // );
                            if (openCreate == true) {
                              const windowElement =
                                document.getElementById("createtask");

                              windowElement.style.display = "block";
                            }
                          }}
                          style={{
                            backgroundColor: "#5250e5",
                            borderRadius: 8,
                            textAlign: "center",
                          }}
                        >
                          <AddCircleOutlineOutlinedIcon
                            fontSize="small"
                            className="addicon mr-1"
                          />
                          Create
                        </Button>
                      ) : (
                        <Button
                          variant="info"
                          // className="rounded-3"
                          style={{
                            //backgroundColor: "#fff", width: elementWidth ? "122px" : "118px",
                            borderRadius: 8,
                            backgroundColor: "#5250e5",
                            textAlign: "center",
                            // border: "1px solid grey",
                            // color: "grey",
                          }}
                          // onMouseEnter={mouseenter}
                          // onMouseLeave={mouseleave}
                          size="sm"
                          onClick={() => {
                            // setCommitDialog(true)
                            dispatch(
                              HANDLE_ON_CHANGE({
                                name: "commitDialog",
                                value: true,
                              })
                            );
                          }}
                        >
                          <CodeIcon fontSize="small" className="addicon mr-1" />
                          Commit Details
                        </Button>
                      )}

                      {/* <Button
                        variant="outline-info"
                        className="rounded-3"
                        style={{
                          //backgroundColor: "#fff", width: elementWidth ? "122px" : "118px",
                          borderRadius: 8,
                          border: "1px solid grey",
                          color: "grey",
                        }}
                        disabled={releases?.filter((s)=>s.ProjectId == projectCode)?.map((e)=> moment(e.SprintEndDate) > moment()
                        ).length == 0}
                        
                        onMouseEnter={mouseenter}
                        onMouseLeave={mouseleave}
                        size="sm"
                        onClick={() => {
                          setManageNoteopen(true);
                          if (manageNotesopen == true) {

                            const windowElement = document.getElementById("Minimized");


                            windowElement.style.display = "block";

                          }
                        }}
                      >
                        <DescriptionOutlinedIcon
                          fontSize="small"
                          className="addicon mr-1"
                        />
                        Sprint notes
                      </Button> */}
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          // <ReactBootstrapTooltip
                          // // className="bg-danger"
                          // // style={{ backgroundColor: '#f-f0000', color: '#fff' }} // Adjust the background color and text color here
                          // // id="button-tooltip"
                          // // className="text-danger mt-1" style={{ fontSize: "10px" }}
                          // >
                          //   No active / historic sprint available
                          // </ReactBootstrapTooltip>
                          <Popover
                            id="popover-basic"
                            className="border-0 shadow-sm"
                            style={{ backgroundColor: "#f5f3f3", color: "red" }}
                          >
                            {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
                            <Popover.Content className="text-dark">
                              No active / historic sprint available
                            </Popover.Content>
                          </Popover>
                        }
                        show={isButtonHovered && isNoActiveSprint?.length == 0}
                      >
                        {/* Button element with your existing props */}
                        <Button
                          variant="outline-info"
                          className="rounded-3"
                          style={{
                            borderRadius: 8,
                            border: "1px solid grey",
                            color: "grey",
                          }}
                          disabled={
                            isNoActiveSprint?.length == 0
                          }
                          // onMouseEnter={mouseenter}
                          // onMouseLeave={mouseleave}
                          // size="sm"
                          // onClick={() => {
                          //   setManageNoteopen(true);
                          //   if (manageNotesopen) {
                          //     const windowElement = document.getElementById("Minimized");
                          //     windowElement.style.display = "block";
                          //   }
                          // }}
                          onMouseEnter={() => setIsButtonHovered(true)}
                          onMouseLeave={() => setIsButtonHovered(false)}
                          size="sm"
                          onClick={() => {
                            if (isNoActiveSprint?.length == 0) {
                              setIsButtonHovered(true);
                            } else {
                              setManageNoteopen(true);
                              if (manageNotesopen) {
                                const windowElement =
                                  document.getElementById("Minimized");
                                windowElement.style.display = "block";
                              }
                            }
                          }}
                        >
                          <DescriptionOutlinedIcon
                            fontSize="small"
                            className="addicon mr-1"
                          />
                          Sprint notes
                        </Button>
                      </OverlayTrigger>

                      {manageNotesopen ? (
                        <ManagerNotes
                          open={manageNotesopen}
                          setManageNoteopen={setManageNoteopen}
                        />
                      ) : null}
                      {projectRole == "SM" ||
                        projectRole == "ITMGR2" ||
                        // projectRole == "QA" ||
                        getProdRole == "KA" ? (
                        ""
                      ) : (
                        <OverlayTrigger
                          // style={{ border: '1px solid red' }}
                          placement="top-start"
                          overlay={
                            <Popover
                              id="popover-basic"
                              className="border-0 shadow-sm"
                              style={{
                                backgroundColor: "#f5f3f3",
                                color: "red",
                              }}
                            >
                              {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
                              <Popover.Content className="text-dark">
                                No active / historic sprint available
                              </Popover.Content>
                            </Popover>
                          }
                          show={isButtonHoveredsd && !releasedata?.length > 0}
                        >
                          <Button
                            variant="outline-info"
                            className="rounded-3"
                            style={{
                              //backgroundColor: "#fff", width: elementWidth ? "122px" : "118px",
                              borderRadius: 8,
                              border: "1px solid grey",
                              color: "grey",
                            }}
                            // disabled={releases.length == 0}
                            onMouseEnter={(e) => {
                              mouseenter(e);
                              setIsButtonHoveredsd(true);
                            }}
                            onMouseLeave={(e) => {
                              mouseleave(e);
                              setIsButtonHoveredsd(false);
                            }}
                            size="sm"
                            onClick={() => {
                              // setManageNoteopen(true);
                              if (!releasedata?.length > 0) {
                                setIsButtonHoveredsd(true);
                              } else {
                                // sethistoryrelease(true);
                                dispatch(
                                  HANDLE_ON_CHANGE({
                                    name: "historyrelease",
                                    value: true,
                                  })
                                );
                                if (historyrelease == true) {
                                  const windowElement =
                                    document.getElementById("sprintdetails");

                                  windowElement.style.display = "block";
                                }
                              }
                            }}
                          >
                            <MapIcon
                              fontSize="small"
                              className="addicon mr-1"
                            />
                            {/* <i class="fa fa-map" aria-hidden="true"></i> */}
                            Sprint details
                          </Button>
                        </OverlayTrigger>
                      )}
                      <Button
                        style={{
                          position: "relative",
                          //width: elementWidth ? "120px" : "118px",
                          borderRadius: 8,
                          //textAlign: 'center'
                          border: "1px solid grey",
                          color: "grey",
                        }}
                        onMouseEnter={mouseenter}
                        onMouseLeave={mouseleave}
                        variant="outline-info"
                        size="sm"
                        fontSize="small"
                        className="action-icon fontdesign mr-2"
                        id="demo-positioned-button"
                        onClick={handleClick}
                      >
                        {" "}
                        Actions
                        <KeyboardArrowDownIcon
                          fontSize="small"

































































































































































                          className="addicon mr-1"
                        />
                      </Button>

                      {/* <Menu
                          id="demo-positioned-menu"
                          className="mt-1"
                          // aria-labelledby="demo-positioned-button"
                          style={{ position: "absolute" }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        // TransitionComponent={Fade}
                        >
                          {KanbanMenu?.filter(
                            (x) => x.MenuId != 20 && x.MenuId != 21
                          )
                            ?.sort((a, b) => a.Sequence - b.Sequence)
                            ?.map((e) => {
                              return (
                                <MenuItem
                                  onClick={() => handlemenua(e.MenuId)}
                                  key={e.MenuId}
                                  className="action-btn"
                                // variant="contained"
                                // size="sm"
                                >
                                  {" "}
                                  <i className={e.IconDetails}></i>
                                  <span className="ml-2"> {e.MenuName} </span>
                                </MenuItem>
                              );
                            })}
                        </Menu> */}
                      <div style={{ display: "inline-block" }}>
                        <Menu
                          id="demo-positioned-menu"
                          style={{ position: "absolute" }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleaction}
                          PaperProps={{
                            style: { maxHeight: "300px", width: "150px" },
                          }}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          {filtermenu?.sort((a, b) => a.Sequence - b.Sequence)?.map((e, index) => (
                            // <OverlayTrigger
                            //   placement="top-start"
                            //   overlay={
                            //     <Popover id="popover-basic" className="border-0 shadow-sm" style={{ backgroundColor: '#f5f3f3', color: 'red', zIndex: 9999 }}>
                            //       <Popover.Content className="text-dark">
                            //         No active / historic sprint available
                            //       </Popover.Content>
                            //     </Popover>
                            //   }
                            //   show={isButtonHoveredactions == index && releases.length === 0 && (e.MenuId === 24 || e.MenuId === 23 || e.MenuId === 30)}
                            // >
                            <MenuItem
                              // onMouseEnter={(e) => {
                              //   if (releases.length === 0 && (e.MenuId === 24 || e.MenuId === 23 || e.MenuId === 30)) {
                              //     setIsButtonHoveredactions(index)
                              //   }
                              // }}
                              // onMouseLeave={(e) => {
                              //   if (releases.length === 0 && (e.MenuId === 24 || e.MenuId === 23 || e.MenuId === 30)) {
                              //     setIsButtonHoveredactions("")
                              //   }
                              // }}
                              key={e.MenuId}
                              className="action-btn"
                              onClick={() => {
                                if (
                                  releasedata?.filter(
                                    (x) => x.IsReleaseFrozen == "Y"
                                  )?.length === 0 &&
                                  (e.MenuId === 24 ||
                                    e.MenuId === 23 ||
                                    e.MenuId === 30)
                                ) {
                                  setIsButtonHoveredactions(index);
                                } else {
                                  handlemenua(e.MenuId);
                                }
                              }}
                            // key={e.MenuId}
                            // className="action-btn"
                            // onClick={() => handlemenua(e.MenuId)}
                            // disabled={releases.length === 0 && (e.MenuId === 24 || e.MenuId === 23 || e.MenuId === 30)}
                            >
                              <i className={e.IconDetails}></i>
                              <span className="ml-2">{e.MenuName}</span>
                            </MenuItem>
                            // </OverlayTrigger>
                          ))}
                        </Menu>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </div>
          <Divider />

          <div className="content-wrapper" style={{ backgroundColor: "#fff" }}>
            <Release
            // Assetoptions={Assetoptions}
            // releasePopup={releasePopup}
            // setReleasePopup={setReleasePopup}
            // projectRelease={projectRelease}
            // setprojectRelease={setprojectRelease}
            // EmpDropDownData={EmpDropDownData}
            // KanbanBoardData={KanbanBoardData}
            // projectCode={projectCode}
            // projectName={projectName}
            // KBRefresh={() => KBRefresh}
            // testerEpic={testerEpic}
            // Refresh={Refresh}
            // setRefresh={setRefresh}
            // releaseapi={releaseapi}
            // setReleaseApi={setReleaseApi}
            // TestersId={TestersId}
            // uatDropdown={uatDropdown?.filter(
            //   (e) => e.projectcode == projectCode
            // )}
            // settesterEpic={settesterEpic}
            />
            <ProgressHistory
            // projectRole={projectRole}
            // progressdata={progressdata}
            // setProgressData={setProgressData}
            // FilteredData={FilteredData}
            // setFilteredData={setFilteredData}
            // setKanbanBoardData={setKanbanBoardData}
            // setRefresh={setRefresh}
            // progressopen={progressopen}
            // setProgressopen={setProgressopen}
            // KanbanBoardData={KanbanBoardData}
            // KBRefresh={() => KBRefresh}
            // SelectedCardId={SelectedCardId}
            // Assetoptions={Assetoptions}
            // setSelectedCardId={setSelectedCardId}
            // resData={resData}
            // Bfopen={Bfopen}
            // setBfopen={setBfopen}
            // selectedCard={selectedCard}
            // setselectedcard={setselectedcard}
            // IssueId={IssueId}
            // setIssueId={setIssueId}
            // openCreateEst={openCreateEst}
            // setOpenCreateEst={setOpenCreateEst}
            // uatDropdown={uatDropdown?.filter(
            //   (e) => e.projectcode == projectCode
            // )}
            // subTaskApi={subTaskApi}
            // displaySubTask={displaySubTask}
            // UATtesterD={UATtesterD}
            // releaseLabel={releaseLabel}
            // setReleaseLabel={setReleaseLabel}
            // releaseValue={releaseValue}
            // setReleaseValue={setReleaseValue}
            // setUATtesterD={setUATtesterD}
            // UATchecklistD={UATchecklistD}
            // setUATchecklistD={setUATchecklistD}
            // empid={empid}
            // crtid={crtid}
            // rolecode={rolecode}
            // EmpDropDownData={EmpDropDownData}
            />

            <Dialog
              open={currentopen}
              onClose={() => {
                handleClose();
              }}
              className="mainbox"
              // maxWidth="1500px"
              maxWidth="lg"
              fullWidth={true}
            // fullScreen={true}
            >
              <DialogTitle>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col lg={10} md={10}>
                    <label
                      className="text-secondary fontdesign"
                      style={{ fontWeight: "bold" }}
                    >
                      Current Sprint
                    </label>
                  </Col>
                  <IconButton
                    className="d-flex justify-content-end"
                    onClick={() => {
                      handleClose(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                <Currentsprint
                // projectRole={projectRole}
                // openCreate={openCreate}
                // projectName={projectName}
                // projectCode={projectCode}
                // FilteredData={FilteredData}
                />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
            <Commitdetails
            // commitDialog={commitDialog}
            // setCommitDialog={setCommitDialog}
            // handleClose={handleClose}
            // devdata={KanbanBoardData?.filter((x) => x.AssignedTo == empid?.[0]?.ClientUserId && x.CurrentStage?.[0]?.StageCode == "DEV")}
            // releases={releases}
            // projectName={projectName}
            />
            <Dialog
              id="sprintdetails"
              open={historyrelease}
              // onClose={() => {
              //   handleClose();
              // }}
              className="mainbox"
              maxWidth="lg"
              // maxWidth="lg"
              fullWidth={true}
              height="1500px"
            >
              <DialogTitle style={{ padding: 0, margin: 0, }}>
                <div style={{ display: "flex", justifyContent: "space-between" }} className='mt-2'>
                  <Col lg={10} md={10}>
                    <label
                      className="text-secondary fontdesign"
                      style={{ fontWeight: "bold" }}
                    >
                      Sprint details
                    </label>
                  </Col>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <IconButton onClick={toggleMinimize}>
                      <SouthEastOutlinedIcon />
                    </IconButton>
                    <IconButton
                      // className="d-flex justify-content-end"
                      onClick={() => {
                        sprindetailclose()
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>

              </DialogTitle>
              <DialogContent>
                <HistoryRelease
                // projectRole={projectRole}
                // openCreate={openCreate}
                // projectName={projectName}
                // projectCode={projectCode}
                // sethistoryrelease={sethistoryrelease}
                // KBRefresh={KBRefresh}
                />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>

            <Dialog
              open={build}
              onClose={() => {
                handleClose();
              }}
              className="mainbox"
              fullWidth
              maxWidth="md"
            >
              <DialogTitle>
                <Row
                  className=""
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col lg={10} md={10}>
                    <label
                      className="text-secondary fontdesign"
                      style={{ fontWeight: "bold" }}
                    >
                      {projectLabel + title}
                    </label>
                  </Col>
                  <IconButton
                    className="d-flex justify-content-end"
                    onClick={handleDevozy}
                  >
                    <CloseIcon />
                  </IconButton>
                </Row>
              </DialogTitle>
              {/* <DialogTitle>
          Build Plan
          <IconButton className="d-flex justify-content-end" onClick={() => {handleClose(false);}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
              <Divider />
              <DialogContent>
                <Build
                // ProjectOption={ProjectOption}
                // openCreate={openCreate}
                // projectName={projectName}
                // projectCode={projectCode}
                // tenantId={tenantId}
                // status={status}
                // setStatus={setStatus}
                // role={role}
                // setbuild={setbuild}
                // setProjectTitle={setProjectTitle}
                // projectTitle={projectTitle}
                // token={devozyToken}
                // email={email?.login}
                />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>

            <Dialog
              open={scrumteam}
              onClose={() => {
                handleClose();
              }}
              className="mainbox"
              // maxWidth="1500px"
              maxWidth="lg"
              fullWidth={true}
            >
              <DialogTitle>
                <Row
                  className=""
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col lg={11} md={11}>
                    <label className="headerdialog text-secondary ml-7 fontdesign">
                      Scrum Team
                    </label>
                  </Col>
                  <IconButton
                    className="d-flex justify-content-end"
                    onClick={() => {
                      handleClose(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Row>
              </DialogTitle>
              <DialogContent>
                <Scrumteam
                // openCreate={openCreate}
                // projectName={projectName}
                // projectCode={projectCode}
                // FilteredData={FilteredData}
                // EmpDropDownData={EmpDropDownData}
                />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>

            <Dialog
              open={meeting}
              onClose={() => {
                handleClose();
              }}
              className="mainbox"
              // maxWidth="1500px"
              maxWidth={ceremoniesPopup ? "sm" : "md"}
              fullWidth={true}
            >
              <DialogTitle>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col lg={10} md={10}>
                    <label
                      className="text-secondary fontdesign"
                      style={{ fontWeight: "bold" }}
                    >
                      Agile Ceremonies
                    </label>
                  </Col>
                  <IconButton
                    className="d-flex justify-content-end"
                    onClick={() => {
                      handleClose(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                <AgileCeremonies
                // openCreate={openCreate}
                // projectName={projectName}
                // projectCode={projectCode}
                // FilteredData={FilteredData}
                // EmpDropDownData={EmpDropDownData}
                // ceremoniesPopup={ceremoniesPopup}
                // setCeremoniesPopup={setCeremoniesPopup}
                // setmeeeting={setmeeeting}
                />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>

            <Dialog
              id="reportv"
              open={reportview}
              // onClose={() => {
              //   handleClose();
              // }}
              // className="mainbox"
              // maxWidth="1500px"
              maxWidth="lg"
              fullWidth={true}
              className={classes.root}
            >
              <DialogTitle>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col lg={10} md={10}>
                    <label
                      className="text-secondary fontdesign"
                      style={{ fontWeight: "bold" }}
                    >
                      Report
                    </label>
                  </Col>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <IconButton onClick={togglereport}>
                      <SouthEastOutlinedIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        reportclose();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <Report
                // projectRole={projectRole}
                // openCreate={openCreate}
                // projectName={projectName}
                // projectCode={projectCode}
                // Assetoptions={Assetoptions}
                />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>

            <Dialog
              open={Historysearch}
              onClose={() => {
                handleClose();
              }}
              className="mainbox"
              fullWidth
              maxWidth="md"
            >
              <DialogTitle>
                <Row
                  className=""
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col lg={10} md={10}>
                    <label
                      className="text-secondary fontdesign"
                      style={{ fontWeight: "bold" }}
                    >
                      History search
                    </label>
                  </Col>
                  <IconButton
                    className="d-flex justify-content-end"
                    onClick={() => {
                      handleClose(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Row>
              </DialogTitle>
              {/* <DialogTitle>
          Build Plan
          <IconButton className="d-flex justify-content-end" onClick={() => {handleClose(false);}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
              <Divider />
              <DialogContent>
                <HistorySearch
                // scrumchart={HistorySearch}
                // setHistorysearch={setHistorysearch}
                />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
            <Dialog
              open={timeline}
              onClose={() => {
                handleClose();
              }}
              className="mainbox"
              fullWidth
              maxWidth="lg"
            >
              <DialogTitle>
                <Row
                  className=""
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col lg={10} md={10}>
                    <label
                      className="text-secondary fontdesign"
                      style={{ fontWeight: "bold" }}
                    >
                      Timeline and Gantt chart
                    </label>
                  </Col>
                  <IconButton
                    className="d-flex justify-content-end"
                    onClick={() => {
                      handleClose(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Row>
              </DialogTitle>
              {/* <DialogTitle>
          Build Plan
          <IconButton className="d-flex justify-content-end" onClick={() => {handleClose(false);}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
              <Divider />
              <DialogContent>
                <Timeline
                // scrumchart={HistorySearch}
                // setTimeline={setTimeline}
                />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>

            <Refinement
            // scrumchart={HistorySearch}
            // setHistorysearch={setHistorysearch}
            />

            <CreateKb
            // issueType={issueType}
            // setIssueType={setIssueType}
            // openCreate={openCreate}
            // setOpenCreate={setOpenCreate}
            // projectLabel={projectLabel}
            // projectCode={projectCode}
            // client={client}
            // setProjectName={setProjectName}
            // setProjectId={setProjectId2}
            // setClient={setClient}
            // projectName={projectName}
            // projectId={projectId2}
            // client1={client1}
            // EmpDropDownData={EmpDropDownData}
            // employeeList={employeeList}
            // setEmployeeList={setEmployeeList}
            // KBRefresh={KBRefresh}
            // KanbanBoardData={KanbanBoardData}
            // projectDetails={projectDetails}
            // dropdown={dropdown}
            // setDropdown={setDropdown}
            // resData={resData}
            // TesterID={TesterID}
            // testerEpic={testerEpic}
            // settesterEpic={settesterEpic}
            // projectnamedetails={projectnamedetails}
            // emp={emp}
            // projectRole={projectRole}
            />

            <Storypoints
            // projectRole={projectRole}
            // Assetoptions={Assetoptions}
            // EmpDropDownData={EmpDropDownData}
            // KanbanBoardData={KanbanBoardData}
            // KBRefresh={() => KBRefresh}
            />

            {userprojectshow == true || userprojectshow == false ? (
              projectLoading ? (
                <div
                  className=" d-flex align-items-center"
                  style={{ justifyContent: "center", height: 460 }}
                >
                  <span
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "rgb(82, 80, 229)" }}
                  ></span>
                </div>
              ) : (
                <KanbanDashboard
                // searchText={searchText}
                // Assetoptions={Assetoptions}
                // optionsrelease={optionsrelease}
                // releases={releases}
                // KBRefresh={KBRefresh}
                // projectRole={projectRole}
                // projectName={projectName}
                // setProgressopen={setProgressopen}
                // setSelectedCardId={setSelectedCardId}
                // FilteredData={FilteredData}
                // projectId2={projectId2}
                // setRefresh={setRefresh}
                // setFilteredData={setFilteredData}
                // Bfopen={Bfopen}
                // setBfopen={setBfopen}
                // selectedCard={selectedCard}
                // setselectedcard={setselectedcard}
                // IssueId={IssueId}
                // setIssueId={setIssueId}
                // openCreateEst={openCreateEst}
                // setOpenCreateEst={setOpenCreateEst}
                // UATtesterD={UATtesterD}
                // setUATtesterD={setUATtesterD}
                // UATchecklistD={UATchecklistD}
                // setUATchecklistD={setUATchecklistD}
                // priority={priority}
                // KanbanBoardData={KanbanBoardData}
                // SelectedCardId={SelectedCardId}
                // releasefilter={releasefilter}
                // epicLabel={epicLabel}
                // progressdata={progressdata}
                // setProgressData={setProgressData}
                // client={client}
                // setReleases={setReleases}
                // EmpDropDownData={EmpDropDownData}
                />
              )
            ) : (
              <div className="text-danger fontdesign">
                {" "}
                Please create project{" "}
              </div>
            )}
          </div>
          <TeamChartDesign
          // scrumchart={scrumchart}
          // setscrumchart={setscrumchart}
          // projectCode={projectCode}
          />
        </>
      )}
    </>
  );
}
export default KanbanBoard;
