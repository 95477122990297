import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import axios from "../../axios";
import React, { useEffect, useState } from "react";
import { toast, Slide } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import "./Twostep.css";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/custom-logo.png"
toast.configure();

const Twoauthverify = ({ data, setUser }) => {
  const [code, setCode] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios.get(`employee_twofactor_auth?EmpId=eq.${data.data?.login}&IsActive=eq.Y`)
      .then((res) => {
        if (res.data.length != 0) {
          console.log("called 1");
          setOpen(true);
        } else {
          console.log("called 2");
          localStorage.setItem("userdata", JSON.stringify(data.data));
          setUser(data.data?.login);
        }
      })
      .catch((err) => { });
  }, []);

  function verifycode(e) {
    e.preventDefault();
    let verifys = {
      EmpId: data.data.login,
      Verification: true,
      VerificationCode: code,
      Token: data.data.Token,
    };

    axios.post("https://api.devozy.ai/twofactauth", JSON.stringify(verifys))
      .then((res) => {
        if (res.data.msg === "Verification Success") {
          localStorage.setItem("userdata", JSON.stringify(data.data));
          setUser(data?.data.login);

          toast.success(`Successfully Login`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        toast.error(`Login Failed`, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          draggable: true,
        });
      });
  }

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          <div className="row">
            <div className="col-5 header_style">
              <strong>Two step Auth</strong>
            </div>
            <div className="col-5"></div>
            <div className="col-2">
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <img src={logo} alt="worker" className="pt-2" width={350} />
              </div>
              <div className="col-lg-5 col-md-6">
                <strong>Welcome back</strong>
                <p>{data?.login}</p>
                <small className="blockquote-footer pt-2">
                  Two-factor authentication token
                </small>
                <form onSubmit={verifycode} className="py-2">
                  <input
                    className="form-control"
                    type="number"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <input type="checkbox" />{" "}
                  <small> don't ask again on this computer </small>
                  <div className="row pt-3 ml-5">
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                      <button type="submit" className="btn btn-primary">
                        {" "}
                        Login{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Twoauthverify;
