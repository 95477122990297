import React, { useState, useEffect, version, useRef } from "react";
import axios from "../../axios";
import Select from "react-select";
import { toast, Slide } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import "react-toastify/dist/ReactToastify.css";
import "react-horizontal-scrolling-menu/dist/styles.css";
import MaterialTable from "material-table";
import { Avatar, Checkbox, Tooltip } from "@material-ui/core";
import { Card } from "react-bootstrap";
// import Sprintdevsummery from "./Sprintdevsummery";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
// import { makeStyles } from "@material-ui/core/styles";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import OfflineBoltRoundedIcon from "@material-ui/icons/OfflineBoltRounded";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import { AlbumRounded, Bookmark } from "@mui/icons-material";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import {
  Box,
  // Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@material-ui/core";
import "./Release.css";
import Button from "@mui/material/Button";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  RELEASESUPDATE,
  PROJECTMAPPINGDATAUPDATE,
  MINIMIZED,
  ISPLANSPRINT,
  HANDLE_ON_CHANGE,
  PUBLICHOLIDAY
} from "../../redux/CommonInitialsate";
import CloseIcon from "@material-ui/icons/Close";
import SprintCalendar from "./Sprintcalendar";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import MultiSelect from "./MultiSelect";
import Popover from "@material-ui/core/Popover";
import { Divider } from "@mui/material";
import Unassigned from "../../assets/images/avatar.png";
import { Collapse, List, Button as Button1 } from "antd";
import StoryPointClick from "./Refinement/StoryPointClick";
import Mailandnotification from "../../Mailandnotification";

toast.configure();
// https://api.yozytech.com/agile_issue_details?ProjectId=eq.300001
// https://api.yozytech.com/agile_issue_details?IssueId=eq.73{}

function Release({
  // releasePopup,
  // setReleasePopup,
  // projectRelease,
  // setprojectRelease,
  // testerEpic,
  // KanbanBoardData,
  // projectCode,
  // projectName,
  // KBRefresh,
  // Refresh,
  // setRefresh,
  // EmpDropDownData,
  // releaseapi,
  // setReleaseApi,
  // TestersId,
  // uatDropdown,
  // settesterEpic,
  // Assetoptions,
}) {
  const dispatch = useDispatch();
  // console.log(Assetoptions, "Assetoptions");
  // const developers = Assetoptions.map((e) => ({
  //   UserId: e.EmpId,
  //   firstname: e.firstname,
  //   lastname: e.lastname
  // }))
  // console.log(developers, "developers")
  const { allImage, Publicholiday, KanbanBoardData, projectMappingData, Assetoptions, projectName, uatDropdown, projectCode, EmpDropDownData, testerEpic,
    releaseapi,
    testename,
    releasePopup,
    projectRelease,
    Refresh,
    projectRole,
    projectRoleName,
    optionSelected,
    employeeList,
  } = useSelector(
    (state) => state.user
  );
  console.log(optionSelected, "pppppppppppppppppppppppp")
  console.log(testerEpic, "testerepicccccccccccccccccccc")
  const Finaltester = testename
    ?.filter(
      (e) =>
        e?.projectrole?.RoleCode === "QA" &&
        e?.projectrole?.RoleGroupId === "14"
      // &&
      // e?.teammemberid === teamm[0]?.TeamMemberId
      // == 300001 &&
      // e.projectrole == 508 &&
      // e.employeeroleinproject == "UAT Tester"
    )
    ?.map((m) => m?.teammemberid);

  let TestersId = Finaltester ? Finaltester : 1;
  // const [testerEpic, settesterEpic] = useState([])
  let projectdetailr = KanbanBoardData.filter(
    (e) => e.ProjectId == projectCode
  );
  let arr1r = JSON.parse(localStorage.getItem("Empdetails"));
  let clientid = JSON.parse(localStorage.getItem("clientid"));

  let projectdetailspost =
    projectdetailr == "" ? "" : projectdetailr[0].ProjectDetails;

  //console.log(projectdetailspost, "project details post");

  let userdetails = JSON.parse(localStorage.getItem("clientdetails"));
  const UATid = uatDropdown?.filter((e) => e.projectcode == projectCode)?.map((E) => E.teammemberid.toString());
  const userorg = userdetails?.[0]?.OrgDetails;
  // if (releaseapi == true) {
  //   axios
  //     // .get("agile_issue_details?ProjectId=eq." + 300001 + "&CreatedBy=eq." + 100005 + "&CreatedBy=eq." +100013 ).then((response) => {
  //     .get(
  //       "agilesaas_issue_details?ProjectId=eq." +
  //       projectCode +
  //       "&CreatedBy=in.(" +
  //       arr1r?.[0].ClientUserId +
  //       "," +
  //       UATid.toString() +
  //       ")"
  //     )
  //     .then((response) => {
  //       // .get("employee_master?EmpId=in." + "(" + arr.level1managereid + "," + arr.level2managereid + ")").then((response) => {

  //       // console.log(response.data, "response");
  //       setprojectRelease(response.data);
  //       setReleaseApi(false);
  //     })
  //     .catch((error) => console.log(error));
  // }

  const intialtoday = new Date();
  const daysUntilNextMonday = (1 + 7 - intialtoday.getDay()) % 7;
  const nextMonday = new Date(
    intialtoday.getFullYear(),
    intialtoday.getMonth(),
    intialtoday.getDate() + daysUntilNextMonday
  );
  nextMonday.setHours(0, 0, 0, 0);
  const [selectedDate, handleDateChange] = useState(
    new Date().getDay() === 1 ? new Date() : nextMonday
  );
  console.log(
    new Date().getDay() === 1 ? new Date() : nextMonday,
    "newwwwwwwwwwwww",
    selectedDate
  );
  const [actualdate, handleactualdate] = useState(new Date());
  const [endDate, setenddate] = useState(new Date());
  const [checkedpre, setcheckedpre] = useState("");
  const [weekdrop, setweekdrop] = useState(2);
  const [checkedactualvalue, setcheckedactualvalue] = useState(false);
  const [MultiSelectedissue, setMultiSelectedissue] = useState(0);
  const [versionrelease, setversionrelease] = useState("");
  const [descriptionr, setdescriptionr] = useState("");
  const [sprintweek, setsprintweek] = useState("");
  const [publicholiday, setPublicHoliday] = useState([]);
  const [Leave, setLeave] = useState(null);
  const [retrospectiveMeeting, setRetrospectiveMeeting] = useState(null);
  const [sprintplanMeeting, setSprintplanMeeting] = useState(null);
  const [Meetings, setMeetings] = useState([]);
  const [checkSubmit, setSubmitCheck] = useState(false);
  const [plannedEnddate, setPlannedEnddate] = useState(new Date());
  const [type, setType] = useState("plansprint");
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);

  const [totalDaysByUser, setTotalDaysByUser] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [issuedata, setissuedata] = useState(null);
  const [spin, setSpin] = useState(false);
  const [ssdate, setSsdate] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [planned, setPlanned] = useState(true);


  const multiplesm =
    localStorage.getItem("multiplesm") == "fullaccess_to_all_sms";

  useEffect(() => {
    if (releaseapi == true) {
      let uatid = UATid.length == 0 ? 0 : UATid.toString();

      axios
        // .get("agile_issue_details?ProjectId=eq." + 300001 + "&CreatedBy=eq." + 100005 + "&CreatedBy=eq." +100013 ).then((response) => {
        .get(
          "agilesaas_issue_details?ProjectId=eq." +
          projectCode +
          "&CreatedBy=in.(" +
          arr1r?.[0].ClientUserId +
          "," +
          uatid +
          ")&order=CreatedDate.desc&IsActive=eq.Y"
        )
        .then((response) => {
          // .get("employee_master?EmpId=in." + "(" + arr.level1managereid + "," + arr.level2managereid + ")").then((response) => {

          // console.log(response.data, "response");
          // setprojectRelease(response.data);
          dispatch(HANDLE_ON_CHANGE({ name: 'projectRelease', value: response.data }))
          // setReleaseApi(false);
          dispatch(HANDLE_ON_CHANGE({ name: 'releaseapi', value: false }))
        })
        .catch((error) => console.log(error));
    }
  }, [projectCode, checkedactualvalue]);
  useEffect(() => {
    const startDate = moment(selectedDate).format("YYYY-MM-DD");
    const endDate = moment(endOfWeek).format("YYYY-MM-DD");

    axios
      .get(
        `agilesaas_holidaysandleaves?LeaveStartDate=lte.(${endDate})&&LeaveEndDate=gte.(${startDate})&&ClientId=eq.${arr1r[0].ClientId}`
      )
      .then((response) => {
        console.log(response.data, "response.data check leaves");
        console.log(startDate, "ssss");
        console.log(endDate, "eee");
        // const leaverecords = developers.map((e) => ({
        //   UserId: e.UserId,
        //   Firstname: e.firstname,
        //   Lastname: e.lastname,
        //   LeavesTaken: differences1(specialCover, response.data.filter((x) => x.UserId == e.UserId), startDate, endDate)
        // }))
        // console.log(leaverecords, "leaverecords")
        // const leavedetail = differences1(specialCover, response.data, startDate, endDate)
        // console.log(leavedetail, "leavedetails")
        const filteredAndSplitLeaves = filterAndSplitLeaves(
          response.data,
          startDate,
          endDate,
          saturday,
          sunday
        );
        console.log(filteredAndSplitLeaves, "check leaves");
        setTotalDaysByUser(filteredAndSplitLeaves);
        console.log(filteredAndSplitLeaves);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [saturday, sunday, plannedEnddate, selectedDate]);

  // console.log(issuedata, "sss");
  const filterAndSplitLeaves = (
    data,
    sprintStartDate,
    sprintEndDate,
    includeSaturday,
    includeSunday
  ) => {
    const result = {};

    for (const leave of data) {
      const leaveStartDate = moment(leave.LeaveStartDate, "YYYY-MM-DD");
      const leaveEndDate = moment(leave.LeaveEndDate, "YYYY-MM-DD").endOf(
        "day"
      );

      if (
        leaveStartDate.isSameOrBefore(sprintEndDate) &&
        leaveEndDate.isSameOrAfter(sprintStartDate) &&
        (includeSaturday || leaveStartDate.isoWeekday() !== 6) &&
        (includeSunday || leaveEndDate.isoWeekday() !== 7)
      ) {
        for (
          let currentDate = moment.max(
            leaveStartDate,
            moment(sprintStartDate, "YYYY-MM-DD")
          );
          currentDate.isSameOrBefore(
            moment.min(leaveEndDate, moment(sprintEndDate, "YYYY-MM-DD"))
          );
          currentDate.add(1, "days")
        ) {
          if (!includeSaturday && currentDate.isoWeekday() === 6) {
            continue; // Skip Saturdays (isoWeekday() returns 6 for Saturday)
          }

          if (!includeSunday && currentDate.isoWeekday() === 7) {
            continue; // Skip Sundays (isoWeekday() returns 7 for Sunday)
          }

          const userId = leave.UserId;
          if (!result[userId]) {
            result[userId] = 0;
          }

          result[userId]++;
        }
      }
    }

    return result;
  };

  // const differences1 = (specialcover, data, startDate, endDate) => {
  //   const sdate = new Date(startDate);
  //   const edate = new Date(endDate);
  //   console.log(data, "leavedata")
  //   const finaldata = data?.map((leave) => {
  //     const removeDates = holidayDetails?.length == 0 ? [] : holidayDetails?.map((e) => moment(e.Date).format("DD-MM-YYYY"));
  //     const leaveStartDate = new Date(leave.LeaveStartDate);
  //     const leaveEndDate = new Date(leave.LeaveEndDate);
  //     const isSaturday =
  //       specialcover?.filter((s) => s.Day == "Saturday")?.length > 0; // Set to true to include Saturday
  //     const isSunday =
  //       specialcover?.filter((s) => s.Day == "Sunday")?.length > 0; // Set to true to include Sunday
  //     const isWeekend = (date) => {
  //       const day = date.getDay();
  //       return (!isSaturday && day === 6) || (!isSunday && day === 0);
  //     };
  //     console.log(isSaturday, isSunday, isWeekend(), "isSaturday")
  //     const dateToString = (date) => {
  //       const year = date.getFullYear();
  //       const month = `${date.getMonth() + 1}`.padStart(2, "0");
  //       const day = `${date.getDate()}`.padStart(2, "0");
  //       return `${day}-${month}-${year}`;
  //     };
  //     if (leaveStartDate <= edate && leaveEndDate >= sdate) {
  //       let totalDays = 0;
  //       for (
  //         let date = new Date(leaveStartDate);
  //         date <= leaveEndDate;
  //         date.setDate(date.getDate() + 1)
  //       ) {
  //         const dateString = dateToString(date);
  //         if (
  //           !removeDates?.includes(dateString) &&
  //           !isWeekend(date) &&
  //           date >= sdate &&
  //           date <= edate
  //         ) {
  //           totalDays++;
  //         }
  //       }
  //       return totalDays;
  //     }
  //     return 0;
  //   });
  //   return finaldata?.reduce(
  //     (accumulator, currentValue) => accumulator + currentValue,
  //     0
  //   );
  // };

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    return isFirstItemVisible ? (
      <></>
    ) : (
      <span
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
        role="button"
        style={{ fontSize: "25px" }}
        className="mdi mdi-arrow-left-drop-circle-outline mt-4"
      >
        {/* <span></span> */}
      </span>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);

    return isLastItemVisible ? (
      <></>
    ) : (
      <span
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
        role="button"
        style={{ fontSize: "25px" }}
        className="mdi mdi-arrow-right-drop-circle-outline mt-4"
      ></span>
    );
  }

  let today = moment(selectedDate).add(weekdrop, 'weeks')

  let temp = moment(today.subtract(1, 'day')).diff(moment(selectedDate), "day");

  const diff =
    temp == 0
      ? 1
      : Number(
        Math.ceil(
          (new Date(today.subtract(1, 'day')) - new Date(selectedDate)) /
          (1000 * 60 * 60 * 24)
        ) + 1
      );
  // const result = workingDays(selectedDate, diff + 2, publicholiday);
  const result = workingDays(selectedDate, diff + 1, publicholiday, saturday, sunday);
  // console.log(result, "rrrrrrrrrrrrrrrrrrr", selectedDate, diff);


  // moment(selectedDate).add(moment().weekdrop);
  // console.log(moment(selectedDate).add(2, 'weeks').format("DD-MMM-YYYY"), "ssssssssssssssssssssssssss", weekdrop, selectedDate)
  // console.log(today, 'today')
  var new_date = moment(selectedDate, "DD-MM-YYYY").add("DD-MM-YYYY", weekdrop);

  let endOfWeek = result?.enddate
  // .clone().weekday(weekdrop);
  // console.log(endOfWeek, 'endofweek')

  const currentYear = moment().format("YYYY");

  const employeeholiday = Leave?.filter(
    (e) =>
      moment(e.LeaveStartDate) >= moment(selectedDate) &&
      moment(e.LeaveStartDate) <= moment(endOfWeek)
  );

  const empmap = employeeholiday?.map((f) => ({
    label: f.NumberofDays,
    value: f.EmpId,
  }));

  const useStyles = makeStyles({
    datePickerInput: {
      marginTop: "2%",
      width: "100%",
      border: "none",
      "&:MuiInputBase-root": {
        border: "none",
        padding: "2%",
      }, // Remove the default border
      "&:focus": {
        outline: "none", // Optionally, remove the focus outline as well
      },
    },
    root: {
      "& .MuiBox-root": {
        paddingTop: 2,
        margin: 1,
      },
      "& .MuiAppBar-root": {
        // boxShadow: "none", // Remove the box shadow from the tab header
        paddingLeft: 0,
        margin: 0,
      },
      "& .MuiTabs-root": {
        display: "flex",
        justifyContent: "flex-start",
      },
    },
  });
  const classes = useStyles();
  // const customStyles = {
  //   control: (base, state) => ({
  //     ...base,
  //     background: "#fff",
  //     // match with the menu
  //     borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
  //     // Overwrittes the different states of border
  //     // borderColor: state.isFocused ? "yellow" : "green",
  //     // Removes weird border around container
  //     boxShadow: state.isFocused ? null : null,
  //     "&:hover": {
  //       // Overwrittes the different states of border
  //       // borderColor: state.isFocused ? "red" : "blue"
  //     },
  //   }),
  //   menu: (base) => ({
  //     ...base,
  //     // override border radius to match the box
  //     borderRadius: 0,
  //     // kill the gap
  //     marginTop: 0,
  //   }),
  //   menuList: (base) => ({
  //     ...base,
  //     // kill the white space on first and last option
  //     padding: 0,
  //   }),
  // };

  const holiday = publicholiday
    ?.filter(
      (e) =>
        moment(e.HolidayDate) >= moment(selectedDate).subtract(1, "d") &&
        moment(e.HolidayDate) <= moment(endOfWeek)
    )
    ?.map((f) => [
      moment(f.HolidayDate).format("DD-MM-yyyy"),
      "-",
      f.HolidayInfo,
      <br></br>,
    ]);

  let optionprojectrelease = { value: projectCode, label: projectName };
  let IssueOptionRelease = projectRelease?.filter(
    (e) =>
      e.CurrentStage[0].StageCode == "DONE" &&
      (e.ActualRelease == null || e.ActualRelease == "") &&
      e.PlannedRelease == null
  );

  let issueOptionRElease12 = testerEpic?.filter(
    (e) =>
      // (e.CurrentStage[0].StageCode == "RFD" ||
      //   e.CurrentStage[0].StageCode == "DEV" ||
      //   e.CurrentStage[0].StageCode == "UAT") &&
      e.PlannedRelease == null &&
      e.IssueType != "Epic"
  );
  // console.log(testerEpic)
  // var sprintenddate = moment(selectedDate, "dd/MM/yyyy").add("dd/MM/yyyy", 5);
  // console.log(sprintenddate,"newdate")

  let optiondroprelease12 =
    issueOptionRElease12 == ""
      ? ""
      : issueOptionRElease12?.map((x) => {
        // console.log(x);
        let fn =
          x.AssignedToDetails.FN !== null ? x.AssignedToDetails.FN : "";
        let ln =
          x.AssignedToDetails.LN !== null ? x.AssignedToDetails.LN : "";

        return {
          label: x.IssueTitle + "-" + x.IssueId + "-" + ` ${fn}_${ln}`,
          value: x.IssueTitle,
          issueidr: x.IssueId,
          AssignedTo: x.AssignedTo,
          AssignedToDetails: x.AssignedToDetails,
          Estimatedpoint: x.OriginalEstimate,
          IssueTitle: x.IssueTitle,
          IssueId: x.IssueId,
          IssueType: x.IssueType,
          OriginalEstimate: x.OriginalEstimate,
          Priority: x.Priority,
          ProjectShortCode: x.ProjectShortCode,
          ParentIssue: x.ParentIssue,
          CurrentStage: x.CurrentStage?.[0]?.StageCode
        };
      })
  // .filter((x) => x.AssignedTo != null && x.Estimatedpoint != null);
  console.log(issueOptionRElease12?.[0], "lengthprelrr", issueOptionRElease12);

  // console.log(optiondroprelease12?.filter((x) => x.AssignedTo != null && x.Estimatedpoint != null)?.length, 'filtereddata')
  function disableWeekends(date) {
    return (
      date.getDay() === 0 ||
      date.getDay() === 2 ||
      date.getDay() === 3 ||
      date.getDay() === 4 ||
      date.getDay() === 5 ||
      date.getDay() === 6
    );
  }

  let assigned = Array.from(
    new Set(
      issueOptionRElease12
        ?.filter((e) => e.AssignedToDetails.FN + " " + e.AssignedToDetails.LN)
        ?.map((f) => f.AssignedToDetails.FN + " " + f.AssignedToDetails.LN)
    )
  );

  const assignedto = Array.from(
    new Set(
      assigned
        ?.filter((f) => !f.includes("null"))
        ?.map((e) => ({
          label: e,
          value: issueOptionRElease12
            ?.filter(
              (t) => t.AssignedToDetails.FN + " " + t.AssignedToDetails.LN === e
            )
            ?.map((m) => m.AssignedTo)[0],
        }))
    )
  );

  const employee = EmpDropDownData.map((e) => {
    return {
      assignedto: e.firstname + " " + e.lastname,
      empid: e.teammemberid,
    };
  });

  const dateUpdate = () => {
    axios
      .get(
        `agilesaas_releases?ProjectId=eq.${projectCode}&order=SprintEndDate.desc&limit=1`
      )
      .then((res) => {
        if (res.data.length != 0) {
          const particularDate = new Date(res.data?.[0]?.SprintEndDate); // Change this to your desired date
          setPlannedEnddate(new Date(res.data?.[0]?.SprintEndDate));
          const daysUntilNextMonday = (1 + 7 - particularDate.getDay()) % 7;
          const nextMonday1 = new Date(
            particularDate.getFullYear(),
            particularDate.getMonth(),
            particularDate.getDate() + daysUntilNextMonday
          );
          nextMonday1.setHours(0, 0, 0, 0);
          const checkdiff = moment(nextMonday1).diff(moment(), "day");
          handleDateChange(checkdiff > 0 ? nextMonday1 : nextMonday);
        } else {
          handleDateChange(nextMonday);
        }
      });
    axios
      .get(`agilesaas_releases?ProjectId=eq.${projectCode}`)
      .then((res) => {
        setSsdate(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    dateUpdate();
  }, [projectCode]);
  // console.log(
  //   ssdate?.map((e) => {
  //     return {
  //       versrion: e.ReleaseName,
  //       startdate: moment(e.SprintStartDate).format("DD-MM-yyyy"),
  //       enddate: moment(e.SprintEndDate).format("DD-MM-yyyy"),
  //     };
  //   }),
  //   "date"
  // );
  let optionIssueDrop =
    IssueOptionRelease == ""
      ? ""
      : IssueOptionRelease?.map((i) => {
        //console.log(i.ActualRelease);

        return {
          label: i.IssueTitle + "-" + i.IssueId,
          value: i.IssueTitle,
          issueidr: i.IssueId,
        };
      });
  // console.log(optionIssueDrop.length, "optionissuedrop");

  //console.log(optiondroprelease12, "optionprojectrelease");
  // console.log(projectRelease, "projectRelease");
  const handleClose = () => {
    dispatch(ISPLANSPRINT(false));

    setType("plansprint");
    setdescriptionr("");
    setweekdrop(2);
    const daysUntilNextMonday = (1 + 7 - plannedEnddate.getDay()) % 7;
    const nextMonday = new Date(
      plannedEnddate.getFullYear(),
      plannedEnddate.getMonth(),
      plannedEnddate.getDate() + daysUntilNextMonday
    );
    nextMonday.setHours(0, 0, 0, 0);
    handleDateChange(nextMonday);
    // setReleasePopup(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'releasePopup', value: false }))
    setcheckedactualvalue(false);
    setversionrelease("");
    setSaturday(false);
    setSunday(false);
    setdescriptionr("");
    setsprintweek("");
    setMultiSelectedissue(0);
    // setSelected(null);
    dispatch(HANDLE_ON_CHANGE({ name: "optionSelected", value: null }));
    // setRefresh(true);
    dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
    // KBRefresh();
    dispatch(HANDLE_ON_CHANGE({ name: 'kbrefreshstate', value: true }))
    axios.get(`agilesaas_releases?ProjectId=eq.${projectCode}`).then((res) => {
      dispatch(RELEASESUPDATE(res.data));
    });
  };
  const handlecheckpre = (e) => {
    setcheckedpre(e.target.value);
    setcheckedactualvalue(e.target.checked);
    if (e.target.checked == true) {
      toast.success(`Actual - Release`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
        draggable: true,
      });
    } else {
      setcheckedactualvalue(false);
      setversionrelease("");
      setSaturday(false);
      setSunday(false);
      setdescriptionr("");
      setsprintweek("");
      setMultiSelectedissue(0);
      // setSelected(null);
      dispatch(HANDLE_ON_CHANGE({ name: "optionSelected", value: null }));
    }

    //console.log("e.target.checked", e.target.checked);
  };

  const handlecheck = (name, e) => {
    if (name == "saturday") {
      setSaturday(e.target.checked);
    } else if (name == "sunday") {
      setSunday(e.target.checked);
    }
  };

  const week = [
    { label: "1 Weeks Sprint", value: 1 },
    { label: "2 Weeks Sprint", value: 2 },
    { label: "3 Weeks Sprint", value: 3 },
    { label: "4 Weeks Sprint", value: 4 },
  ];

  function handleChangeissue(e) {
    let value = Array.isArray(e)
      ? e.map((x) => {
        let rObj = {};
        rObj["value"] = x.value;
        rObj["label"] = x.issueidr;
        return rObj;
      })
      : [];
    setMultiSelectedissue(value);
  }
  const emailmembers = projectMappingData
    ?.filter((e) => e.email != "support@yozytech.com")
    ?.map((e) => e.email);
  const uniqueEmails = [...new Set(emailmembers)];
  const handleScheduleClick = () => {
    if (selectedDate && endOfWeek) {
      const start = new Date(selectedDate);
      const end = new Date(endOfWeek);

      const schedule = [];
      const retrospectiveDate = new Date(end);
      let retrospectiveDayOffset = 2;

      while (start <= end) {
        if (start.getDay() !== 0 && start.getDay() !== 6) {
          const meetingDate = new Date(start);
          const meeting = {
            ClientId: clientid,
            ClientDetails: {
              companyname: userdetails[0]?.OrgDetails.Company,
              email: userdetails[0]?.OrgDetails.ContactEmail,
              phone: userdetails[0]?.OrgDetails.ContactPhone,
            },
            Title: "Daily scrum meeting",
            Date: moment(meetingDate).format("YYYY-MM-DD"),
            ScheduledStartTime: moment(meetingDate)
              .set("hour", 10)
              .set("minute", 0)
              .format("YYYY-MM-DDTHH:mm:ss"),
            ScheduledEndTime: moment(meetingDate)
              .set("hour", 10)
              .set("minute", 30)
              .format("YYYY-MM-DDTHH:mm:ss"),
            Duration: 30,
            MeetingDetails: {
              host: null,
              meetinglink: null,
              vendor: null,
              meetingkey: null,
            },
            CreatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
            CreatedByDetails: {
              companyname: userdetails[0]?.OrgDetails.Company,
              email: userdetails[0]?.OrgDetails.ContactEmail,
              phone: userdetails[0]?.OrgDetails.ContactPhone,
            },
            IsActive: null,
            Status: null,
            ProjectDetails: {
              ProjectCode: projectCode,
              ProjectName: projectName,
            },
            ProjectId: projectCode,
          };
          schedule.push(meeting);
        }
        start.setDate(start.getDate() + 1);
      }

      while (retrospectiveDayOffset > 0) {
        retrospectiveDate.setDate(retrospectiveDate.getDate() + 1);
        if (
          retrospectiveDate.getDay() !== 0 &&
          retrospectiveDate.getDay() !== 6
        ) {
          retrospectiveDayOffset--;
        }
      }
      const retrospectiveMeeting1 = {
        ClientId: clientid,
        ClientDetails: {
          companyname: userdetails[0]?.OrgDetails.Company,
          email: userdetails[0]?.OrgDetails.ContactEmail,
          phone: userdetails[0]?.OrgDetails.ContactPhone,
        },
        Title: "Retrospective Meeting",
        Date: moment(retrospectiveDate).format("YYYY-MM-DD"),
        ScheduledStartTime: moment(retrospectiveDate)
          .set("hour", 10)
          .set("minute", 30)
          .format("YYYY-MM-DDTHH:mm:ss"),
        ScheduledEndTime: moment(retrospectiveDate)
          .set("hour", 11)
          .set("minute", 0)
          .format("YYYY-MM-DDTHH:mm:ss"),
        Duration: 30,
        MeetingDetails: {
          host: null,
          meetinglink: null,
          vendor: null,
          meetingkey: null,
        },
        CreatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        CreatedByDetails: {
          companyname: userdetails[0]?.OrgDetails.Company,
          email: userdetails[0]?.OrgDetails.ContactEmail,
          phone: userdetails[0]?.OrgDetails.ContactPhone,
        },
        IsActive: null,
        Status: null,
        ProjectDetails: {
          ProjectCode: projectCode,
          ProjectName: projectName,
        },
        ProjectId: projectCode,
      };
      schedule.push(retrospectiveMeeting1);
      setRetrospectiveMeeting(retrospectiveMeeting1);

      const sprintplandate = new Date(end);
      sprintplandate.setDate(sprintplandate.getDate() - 2);
      const sprintplanmeeting = {
        ClientId: clientid,
        ClientDetails: {
          companyname: userdetails[0]?.OrgDetails.Company,
          email: userdetails[0]?.OrgDetails.ContactEmail,
          phone: userdetails[0]?.OrgDetails.ContactPhone,
        },
        Title: "SprintPlan Meeting",
        Date: moment(sprintplandate).format("YYYY-MM-DD"),
        ScheduledStartTime: moment(sprintplandate)
          .set("hour", 10)
          .set("minute", 30)
          .format("YYYY-MM-DDTHH:mm:ss"),
        ScheduledEndTime: moment(sprintplandate)
          .set("hour", 11)
          .set("minute", 0)
          .format("YYYY-MM-DDTHH:mm:ss"),
        Duration: 30,
        MeetingDetails: {
          host: null,
          meetinglink: null,
          vendor: null,
          meetingkey: null,
        },
        CreatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        CreatedByDetails: {
          companyname: userdetails[0]?.OrgDetails.Company,
          email: userdetails[0]?.OrgDetails.ContactEmail,
          phone: userdetails[0]?.OrgDetails.ContactPhone,
        },
        IsActive: null,
        Status: null,
        ProjectDetails: {
          ProjectCode: projectCode,
          ProjectName: projectName,
        },
        ProjectId: projectCode,
      };
      schedule.push(sprintplanmeeting);
      setSprintplanMeeting(sprintplanmeeting);
      setMeetings(schedule);
      setSubmitCheck(true);
      axios
        .post("agilesaas_ceremonies", schedule)
        .catch((err) => console.log(err))
        .then((response) => {
          toast.success(`Meeting successfully scheduled.`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          });
          setSubmitCheck(false);
        });
    }
  };
  console.log(optionSelected, 'employeeList')
  function handleprerelease() {
    // console.log(selectedDate, 'selectedDate')
    // let validdays = moment().add(13, "days").format("YYYY-MM-DD");
    if (versionrelease == "") {
      toast.error(`Please enter the Version`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    } else {
      axios
        .get(
          "agilesaas_releases?ReleaseName=eq." +
          versionrelease +
          "&ProjectId=eq." +
          projectCode
        )
        .catch((err) => console.log(err))
        .then((response) => {
          axios
            .get(
              "agilesaas_releases?SprintStartDate=eq." +
              moment(selectedDate).format("YYYY-MM-DD") +
              "&&ProjectId=eq." +
              projectCode
            )
            .then((res) => {
              // console.log(res.data, 'res.data')
              let datecheck = res.data.length;
              // console.log(datecheck, 'datecheck')
              if (response.data.length > 0) {
                setversionrelease("");
                toast.error(`The version already exists.`, {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
              }
              // else if (MultiSelectedissue == 0) {
              //   toast.error(`Please select an issue.`, {
              //     transition: Slide,
              //     position: toast.POSITION.TOP_RIGHT,
              //     autoClose: 3000,
              //     draggable: true,
              //   });
              // } 
              else if (weekdrop == 0) {
                toast.error(`Please select the sprint week.`, {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 5000,
                  draggable: true,
                });
              } else if (datecheck != 0) {
                const daysUntilNextMonday =
                  (1 + 7 - plannedEnddate.getDay()) % 7;
                const nextMonday = new Date(
                  plannedEnddate.getFullYear(),
                  plannedEnddate.getMonth(),
                  plannedEnddate.getDate() + daysUntilNextMonday
                );
                nextMonday.setHours(0, 0, 0, 0);
                handleDateChange(nextMonday);
                toast.error(`A sprint is already planned for this date.`, {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
              }
              // else if (descriptionr == "") {
              //   toast.error(`Please enter the description.`, {
              //     transition: Slide,
              //     position: toast.POSITION.TOP_RIGHT,
              //     autoClose: 3000,
              //     draggable: true,
              //   });
              // } else if (moment(selectedDate).isBefore(validdays)) {
              //   toast.error(`Release date must be 13 days after the current date`, {
              //     transition: Slide,
              //     position: toast.POSITION.TOP_RIGHT,
              //     autoClose: 5000,
              //     draggable: true,
              //   });
              // }
              else {
                let issueidpatch =
                  MultiSelectedissue == ""
                    ? ""
                    : MultiSelectedissue.filter((e) => e.label);

                //console.log(MultiSelectedissue, "MultiSelectedissue");
                let patchdatapr = {
                  PlannedRelease: versionrelease,
                };


                let sample =
                  optionSelected?.length > 0 && optionSelected.map((e) => {
                    let patchdata = {
                      PlannedRelease: versionrelease,
                      OriginalEstimate: e.OriginalEstimate,
                      AssignedTo: e.AssignedTo,
                      AssignedToDetails: e.AssignedToDetails,
                    }
                    let patchdata1 = {
                      PlannedRelease: versionrelease,
                      OriginalEstimate: e.OriginalEstimate,
                      AssignedTo: e.AssignedTo,
                      AssignedToDetails: e.AssignedToDetails,
                      CurrentStage: [
                        {
                          StageCode: "RFD",
                          StageName: "Refined",
                          DateMoved: moment().format("YYYY-MM-DD")
                        }
                      ]
                    }
                    axios
                      .patch(
                        "agilesaas_issue_details?IssueId=eq." + e.IssueId,
                        e.CurrentStage == "BLG" ? patchdata1 : patchdata
                      )
                      .then((response) => {
                        console.log(response.data);
                        console.log(e.changedfield)
                        if (e.changedfield?.includes('assignee')) {
                          Mailandnotification(
                            e,
                            employeeList?.filter(
                              (x) => x.teammemberid == e?.AssignedTo
                            )?.[0]?.email,
                            [arr1r?.[0]?.OfficialEmail],
                            true,
                            false,
                            projectName,
                            `${e.IssueTitle} is planned for ${versionrelease}`,
                            `${e.IssueTitle} is planned for ${versionrelease} and it is assigned to you`,
                          );
                        }
                      })
                      .catch((err) => console.log(err))

                  });
                let requrireddata = {
                  ClientUserId: arr1r?.[0]?.ClientUserId,
                  ClientId: clientid,
                  ClientDetails: {
                    Company: userorg?.Company,
                    ContactName: userorg?.ContactName,
                    ContactDesignation: userorg?.ContactDesignation,
                    ContactEmail: userorg?.ContactEmail,
                    ContactPhone: userorg?.ContactPhone,
                  },
                  DepartmentDetails: arr1r?.[0]?.DepartmentDetails,
                  DepartmentCode: null,
                  TentantId: "",
                  FromSenderId: "no-reply@yozytech.com",
                  emailnotify: true,
                  webnotify: true,
                  ToEmailIds: {
                    emailids: arr1r?.[0]?.OfficialEmail,
                  },
                  CcEmailIds: {
                    emailids: ['agileteam@yozytech.com'],
                  },
                  BccEmailIds: "",
                  EmailType: "SprintPlan",
                  ProductDetails: {
                    ProdId: "11004",
                    ProdName: "KANBAN",
                    Domain: "KANBAN.yozytech.com",
                  },
                  EmailSubject: "Sprint Plan Update",
                  IsSuccess: "Y",
                  IsPartiallyFailed: " ",
                  SendRetryHistory: "",
                  IsActive: " ",
                  Status: "",
                  EmailTemplateName: "SprintPlan",
                  EmailTemplateVariables: {
                    xxasignbyxx: "",
                    xxassigndatexx: "",
                    xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
                    xxprjdisxx: "",
                    xxprjnamexx: "",
                    xxstoryNamexx: "",
                    xxprjpercenxx: "",
                    xxprjstartdatexx: "",
                    xxprjenddataexx: "",
                    xxteamnamexx: "",
                    xxsprintxx: versionrelease,
                    xxmanagernamexx: userorg?.ContactName,
                    xxcompanyname: userorg?.Company,
                    xxmanagerdesigxx: "Scrum Master",
                    xxgmailxx: userorg?.ContactEmail,
                    xxphnoxx: userorg?.ContactPhone,
                    webappdescri: "Planned version: " + versionrelease,
                  },
                };
                const posthistory = optionSelected?.length > 0 && optionSelected.map((r) => ({
                  IssueId: r.IssueId,
                  ChangedBy: arr1r[0]?.ClientUserId,
                  ChangedByDetails: {
                    FN: arr1r[0]?.UserFirstname,
                    LN: arr1r[0]?.UserLastname,
                  },
                  ChangedDate: moment(),
                  // UpdatedField: "Release",
                  // UpdatedTo: versionrelease,
                  UpdatedField: r.changedfield,
                  UpdatedFrom: [null, r.changedfield?.includes('assignee') && KanbanBoardData?.find((f) => f.IssueId == r.IssueId).AssignedTo, r.changedfield?.includes('originalestimate') && KanbanBoardData?.find((f) => f.IssueId == r.IssueId).OriginalEstimate],
                  UpdatedTo: `Moved to ${versionrelease} sprint ${r.changedfield?.includes('assignee') && `and it is assigned to ${r.AssignedToDetails?.FN + " " + r.AssignedToDetails.LN}`}  ${r.changedfield?.includes('originalestimate') && 'estimated days are ' + r.OriginalEstimate}`,
                  UpdateSequence: r.changedfield?.includes('assignee') && r.changedfield?.includes('originalestimate') ? 3 : r.changedfield?.includes('assignee') || r.changedfield?.includes('originalestimate') ? 2 : 1,
                  IsActive: "Y",
                  Status: null,
                  ClientId: clientid,
                  ProjectId: projectCode,
                }))

                const posthistory1 = optionSelected?.length > 0 && optionSelected?.filter((r) => r.CurrentStage == "BLG")?.length > 0 && optionSelected?.filter((r) => r.CurrentStage == "BLG")?.map((r) => ({
                  IssueId: r.IssueId,
                  ChangedBy: arr1r[0]?.ClientUserId,
                  ChangedByDetails: {
                    FN: arr1r[0]?.UserFirstname,
                    LN: arr1r[0]?.UserLastname,
                  },
                  ChangedDate: moment(),
                  UpdatedField: "Kanban Changes",
                  UpdatedFrom: "BLG",
                  UpdatedTo: "RFD",
                  UpdateSequence: 1,
                  IsActive: "Y",
                  Status: null,
                  ClientId: clientid,
                  ProjectId: projectCode,
                }))

                const historydata = posthistory1?.length > 0 ? [...posthistory, ...posthistory1] : posthistory
                axios.post("agilesaas_issue_history", historydata)
                  .then((response) => { })
                  .catch((error) => console.log(error));

                axios({
                  url: "https://api.devozy.ai/api/agile/email",
                  method: "post",
                  data: requrireddata,
                  headers: {
                    origin: "https://kanban.yozytech.com",
                    "Content-Type": "application/json",
                  },
                })
                  .then((res) => {
                    console.log(res.data, "mailsend");
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                let postdatapr = {
                  ProjectId: projectCode,
                  ProjectDetails:
                    projectdetailspost == ""
                      ? ""
                      : projectdetailspost?.map((e) => {
                        return e;
                      }),
                  ProjectType: "KAB  ",
                  ReleaseName: versionrelease,
                  ReleaseDate: moment(selectedDate).format("YYYY-MM-DD"),
                  Description: descriptionr,
                  CreatedBy: arr1r[0].ClientUserId,
                  CreatedByDetails: {
                    FN: arr1r[0]?.UserFirstname,
                    LN: arr1r[0]?.UserLastname,
                    Role: projectRoleName,
                  },
                  CreatedDate: moment().format("YYYY-MM-DD"),
                  ReleaseFrequency: 2,
                  ReleaseFrequencyUnit: "w",
                  IsReleaseFrozen: "N",
                  ReleaseIssues:
                    MultiSelectedissue == ""
                      ? ""
                      : MultiSelectedissue.map((t) => {
                        return { IssueId: t.label, IssueTitle: t.value };
                      }),

                  Placeholder2: null,
                  IsActive: "Y",
                  Status: null,
                  SprintStartDate: moment(selectedDate).format("YYYY-MM-DD"),
                  SprintEndDate: moment(endOfWeek).format(
                    "YYYY-MM-DD 23:59:00"
                  ),
                  SprintInWeeks: weekdrop,
                  SprintStatus: null,
                  RemovedTickets: null,
                  AddedTickets: null,
                  ActualTickets:
                    MultiSelectedissue == ""
                      ? ""
                      : MultiSelectedissue.map((t) => {
                        return { IssueId: t.label, IssueTitle: t.value };
                      }),
                  PlannedTicketsCount: MultiSelectedissue?.length,
                  ActualTicketsCount: 0,
                  ClientId: clientid,
                  PublicHolidays: holidayDetails,
                  SpecialCover: specialCover,
                  TotalDaysInSprint: finalWorkingdays,
                  TeamLeavesConsidered: leaverecords,
                  Totalavailabledays: leaverecords.reduce(
                    (acc, user) => acc + user.Availabledays,
                    0
                  ),
                };
                setSubmitCheck(true);
                axios
                  .post("agilesaas_releases", postdatapr)
                  .then((response) => {
                    setSubmitCheck(false);
                    toast.success(`Pre-release successful.`, {
                      transition: Slide,
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 3000,
                      draggable: true,
                    });
                    handleScheduleClick();
                    handleClose();
                    dateUpdate();
                    axios
                      .get(
                        "agilesaas_issue_details?ProjectId=eq." +
                        projectCode +
                        "&order=CreatedDate.desc&IsActive=eq.Y"
                      )
                      .then((response) => {
                        // settesterEpic(response.data);
                        dispatch(HANDLE_ON_CHANGE({ name: 'testerEpic', value: response.data }))
                        console.log('change tester epic called')
                      })
                      .catch((error) => console.log(error));
                    // Refresh();
                    // KBRefresh();
                  })
                  .catch((err) => console.log(err));
              }
            });
        });
    }
  }

  function handlerelease() {
    // console.log('handlerelease clled')
    if (versionrelease == "") {
      toast.error(`Please enter the version`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    } else {
      // .get("agile_?ReleaseName=eq"+versionrelease +"&&IsReleaseFrozen=eq.Y"+"&&ProjectId=eq."+projectCode)
      axios
        .get(
          "agilesaas_releases?ReleaseName=eq." +
          versionrelease +
          "&ProjectId=eq." +
          projectCode
        )
        .catch((err) => console.log(err))
        .then((response) => {
          if (response.data.length > 0) {
            setversionrelease("");
            toast.error(`The version already exists.`, {
              transition: Slide,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              draggable: true,
            });
          }
          // else if (descriptionr == "") {
          //   toast.error(`Please enter the Description`, {
          //     transition: Slide,
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 3000,
          //     draggable: true,
          //   });
          // }
          else {
            const date = moment()
              .utcOffset("+05:30")
              .format("YYYY-MM-DDTHH:mm:ss");
            let sampled123 =
              optionIssueDrop == ""
                ? ""
                : optionIssueDrop.map((r) => {
                  let posthistory = {
                    IssueId: r.issueidr,
                    ChangedBy: arr1r[0]?.ClientUserId,
                    ChangedByDetails: {
                      FN: arr1r[0]?.UserFirstname,
                      LN: arr1r[0]?.UserLastname,
                    },
                    ChangedDate: date,
                    UpdatedField: "Release",
                    UpdatedTo: versionrelease,
                    UpdateSequence: 1,
                    IsActive: "Y",
                    Status: null,
                    ClientId: clientid,
                    ProjectId: projectCode,
                  };

                  axios
                    .post("agilesaas_issue_history", posthistory)
                    .then((response) => { })
                    .catch((error) => console.log(error));
                });
            let patchdatar = {
              ActualRelease: versionrelease,
            };
            //console.log(optionIssueDrop, "optionIssueDrop");
            let sample =
              optionIssueDrop == ""
                ? ""
                : optionIssueDrop.map((e) => {
                  axios
                    .patch(
                      "agilesaas_issue_details?IssueId=eq." + e.issueidr,
                      patchdatar
                    )

                    .then((response) => { })
                    .catch((err) => console.log(err));
                });
            let postdatar = {
              ProjectId: projectCode,
              ProjectDetails:
                projectdetailspost == ""
                  ? ""
                  : projectdetailspost.map((e) => {
                    return e;
                  }),
              ProjectType: "KAB  ",
              ReleaseName: versionrelease,
              ReleaseDate: moment(selectedDate).format("YYYY-MM-DD"),
              Description: descriptionr,
              CreatedBy: arr1r[0].ClientUserId,
              CreatedByDetails: {
                FN: arr1r.UserFirstname,
                LN: arr1r.UserLastname,
              },
              CreatedDate: moment().format("YYYY-MM-DD"),
              ReleaseFrequency: 2,
              ReleaseFrequencyUnit: "w",
              IsReleaseFrozen: "Y",
              ReleaseIssues:
                optionIssueDrop == ""
                  ? ""
                  : optionIssueDrop.map((t) => {
                    return { IssueId: t.issueidr, IssueTitle: t.value };
                  }),

              Placeholder2: null,
              IsActive: "Y",
              Status: null,
              SprintStartDate: moment(selectedDate).format("YYYY-MM-DD"),
              SprintEndDate: moment(endOfWeek).format("YYYY-MM-DD"),
              SprintInWeeks: weekdrop,
              SprintStatus: null,
              RemovedTickets: null,
              AddedTickets: null,
              ActualTickets: null,
              PlannedTicketsCount: null,
              ActualTicketsCount: null,
              ClientId: clientid,
            };
            //console.log(postdatar, postdatar);
            setSubmitCheck(true);
            axios
              .post("agilesaas_releases", postdatar)
              .catch((err) => console.log(err))
              .then((response) => {
                toast.success(`🎉 Released successfully! 🎉 `, {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                setSubmitCheck(false);
                handleClose();
                axios
                  .get(
                    "agilesaas_issue_details?ProjectId=eq." +
                    projectCode +
                    "&order=CreatedDate.desc&IsActive=eq.Y"
                  )
                  .then((response) => {
                    // settesterEpic(response.data);
                    dispatch(HANDLE_ON_CHANGE({ name: 'testerEpic', value: response.data }))
                    console.log('change tester epic called 1348')
                  })
                  .catch((error) => console.log(error));
              });
          }
        });
    }
  }
  const handleversionrelease = (e) => {
    e.preventDefault();
    let text = e.target.value;
    let version = text.toLowerCase();
    setversionrelease(version);
    // setversionrelease(e.target.value)
  };
  const handleversionrelease1 = (e) => {
    let text = e.target.value;
    let version = text.toLowerCase();
    setversionrelease(version);
  };
  const handledescrelease = (e) => {
    // e.preventDefault()
    setdescriptionr(e.target.value);
  };

  const isMonday = (dateString) => {
    const selectedDate = new Date(dateString);
    return selectedDate.getDay() === 1; // Monday is represented by 1 in JavaScript's getDay() method
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFF",
      border: state.isFocused
        ? "2px solid rgb(82, 80, 229)"
        : "1px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      // height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      height: 100,
    }),
  };
  // const inc = ["11-03-2024", "22-01-2024", "26-02-2024"];
  const inc = ssdate?.map((e) => {
    return {
      // versrion: e.ReleaseName,
      startdate: moment(e.SprintStartDate).format("DD-MM-yyyy"),
      enddate: moment(e.SprintEndDate).format("DD-MM-yyyy"),
    };
  });
  // const inc2 = [
  //   // { startdate: "04-12-2023", enddate: "15-12-2023" },
  //   { startdate: "26-02-2024", enddate: "08-03-2024" },
  //   { startdate: "11-03-2024", enddate: "22-03-2024" },
  //   { startdate: "22-04-2024", enddate: "03-05-2024" },
  // ];

  // const disableDatesBefore = (date) => {
  //   const formattedDate = moment(date).format("DD-MM-YYYY");
  //   // return (
  //   //   // date < plannedEnddate ||
  //   //   // (date.getDay() === 1 && date.getDate() === 22) ||

  //   //   date.getDay() === 0 ||
  //   //   date.getDay() === 2 ||
  //   //   date.getDay() === 3 ||
  //   //   date.getDay() === 4 ||
  //   //   date.getDay() === 5 ||
  //   //   date.getDay() === 6
  //   // );
  //   const isPreviousWeekMonday = inc?.some(({ startdate }) => {
  //     const startMoment = moment(startdate, "DD-MM-YYYY");
  //     const previousWeekMonday = moment(startMoment)
  //       .subtract(1, "week")
  //       .startOf("isoWeek");
  //     return moment(formattedDate, "DD-MM-YYYY").isSame(
  //       previousWeekMonday,
  //       "day"
  //     );
  //   });

  //   const isWithinRange = inc?.some(({ startdate, enddate }) => {
  //     const startMoment = moment(startdate, "DD-MM-YYYY");
  //     const endMoment = moment(enddate, "DD-MM-YYYY");
  //     return moment(formattedDate, "DD-MM-YYYY").isBetween(
  //       startMoment,
  //       endMoment,
  //       null,
  //       "[]"
  //     );
  //   });
  //   // const isBeforeFirstMonday = inc?.some(({ startdate, enddate }) => {
  //   //   const startMoment = moment(startdate, "DD-MM-YYYY");
  //   //   const firstMonday = startMoment.clone().startOf("isoWeek").add(1, "days");

  //   //   return moment(formattedDate, "DD-MM-YYYY").isBefore(firstMonday);
  //   // });
  //   console.log(
  //     date,
  //     isWithinRange ||
  //     isPreviousWeekMonday ||
  //     date.getDay() === 0 ||
  //     date.getDay() === 2 ||
  //     date.getDay() === 3 ||
  //     date.getDay() === 4 ||
  //     date.getDay() === 5 ||
  //     date.getDay() === 6,
  //     "1378"
  //   );
  //   return (
  //     // (date.getDay() === 1 && inc?.includes(formattedDate)) ||
  //     // isPreviousWeekMonday ||
  //     isWithinRange ||
  //     isPreviousWeekMonday ||
  //     date.getDay() === 0 ||
  //     date.getDay() === 2 ||
  //     date.getDay() === 3 ||
  //     date.getDay() === 4 ||
  //     date.getDay() === 5 ||
  //     date.getDay() === 6
  //   );
  // };
  const disableDatesBefore = (date) => {
    const formattedDate = moment(date)?.format("DD-MM-YYYY");
    // Adjust the offset to disable the current week as well (if needed)
    // const weekStart = formattedDate.startOf('week').subtract(1, 'weeks'); // Disable previous week
    // console.log(date.isBefore(weekStart), "weeeeeek")

    // console.log(date, moment(date)?.isSame(new Date(), 'week'), "check")
    const isPreviousWeekMonday = inc?.some(({ startdate }) => {
      const startMoment = moment(startdate, "DD-MM-YYYY");
      const previousWeekMonday = moment(startMoment)
        .subtract(1, "week")
        .startOf("isoWeek");
      return moment(formattedDate, "DD-MM-YYYY").isSame(
        previousWeekMonday,
        "day"
      );
    });

    const isWithinRange = inc?.some(({ startdate, enddate }) => {
      const startMoment = moment(startdate, "DD-MM-YYYY");
      const endMoment = moment(enddate, "DD-MM-YYYY");
      return moment(formattedDate, "DD-MM-YYYY").isBetween(
        startMoment,
        endMoment,
        null,
        "[]"
      );
    });
    // console.log(moment(date).isBefore(new Date(), 'week'), "isbefore", date)
    const currentweek = (!moment(date).isSame(new Date(), 'week') && (
      date.getDay() === 0 ||
      date.getDay() === 2 ||
      date.getDay() === 3 ||
      date.getDay() === 4 ||
      date.getDay() === 5 ||
      date.getDay() === 6
    )) || moment(date).isBefore(new Date(), 'week') || (moment(date).isSame(new Date(), 'week') && date.getDay() === 0)
    // console.log(isPreviousWeekMonday, "pppppppppppppppppp", date)
    return currentweek || isPreviousWeekMonday || isWithinRange
  };

  // const [optionSelected, setSelected] = useState(null); // Remove the type annotation

  const handleChanged = (selected) => {
    let value = Array.isArray(selected)
      ? selected.map((x) => {
        let rObj = {};
        rObj["value"] = x.value;
        rObj["label"] = x.issueidr;
        return rObj;
      })
      : [];
    console.log(value, "release selected value")
    setMultiSelectedissue(value);
    // setSelected(selected);
    console.log(selected, 'selected 1553')
    dispatch(HANDLE_ON_CHANGE({ name: "optionSelected", value: selected }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosess = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // function workingDays(startDate, diff1, holidays) {
  //   const weekdays = [];
  //   let current = startDate;
  //   let i = 0;
  //   while (i < diff1) {
  //     if (!isWeekEnd(current)) {
  //       weekdays.push(moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"));
  //     }
  //     let currentObj = current;
  //     current = moment(currentObj, "YYYY-MM-DD")
  //       .add(1, "days")
  //       .format("YYYY-MM-DD");
  //     i++;
  //   }
  //   function isWeekEnd(date) {
  //     console.log(date, holidays.includes(date), holidays, "holidays")
  //     let dateObj = moment(date, "YYYY-MM-DD");

  //     if (dateObj.day() === 6 || dateObj.day() === 0) {
  //       return true;
  //     } else {
  //       if (holidays.includes(moment(date).format("YYYY-MM-DD"))) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }
  //   }

  //   if (weekdays.length == 1 && holidays.includes(weekdays[0])) {
  //     return 0
  //   }
  //   else {
  //     return weekdays.length;
  //   }
  // }

  // function workingDays(startDate, diff1, publicholiday) {
  //   console.log(publicholiday, "publicholiday")

  //   const weekdays = [];
  //   let current = moment(startDate, 'YYYY-MM-DD');
  //   let i = 0;

  //   while (i < diff1) {
  //     if (!isWeekEnd(current, publicholiday)) {
  //       weekdays.push(current.format('YYYY-MM-DD'));
  //     }
  //     current.add(1, 'days');
  //     i++;
  //   }

  //   function isWeekEnd(date, publicholiday) {
  //     console.log(publicholiday?.some(publicholiday => publicholiday?.HolidayDate === date), date, "date")
  //     return date.day() === 0 || date.day() === 6
  //   }

  //   const holidayCount = holidays.filter(h => current.isBetween(moment(h, 'YYYY-MM-DD'), current.clone().add(diff1, 'days'), null, '[]'));

  //   return {
  //     workingDays: weekdays.length - holidayCount,
  //     holidayscount: holidayCount.length,
  //     holidays: holidayCount
  //   };
  // }
  // function workingDays(startDate, diff1, holidays) {
  //   const weekdays = [];
  //   const getHolidays = [];
  //   const weekend = [];
  //   let current = startDate;
  //   let i = 0;
  //   while (i < diff1) {
  //     if (!isWeekEnd(current)) {
  //       weekdays.push(moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"));
  //     }
  //     let currentObj = current;
  //     current = moment(currentObj, "YYYY-MM-DD")
  //       .add(1, "days")
  //       .format("YYYY-MM-DD");
  //     i++;
  //   }
  //   function isWeekEnd(date) {
  //     let dateObj = moment(date, "YYYY-MM-DD");

  //     if (dateObj.day() === 6 || dateObj.day() === 0) {
  //       let wend = {
  //         Date: moment(date).format("YYYY-MM-DD"),
  //         Day:
  //           dateObj.day() === 6
  //             ? "Saturday"
  //             : dateObj.day() === 0
  //               ? "Sunday"
  //               : "",
  //         Notes: "Working Day",
  //       };
  //       weekend.push(wend);
  //       return true;
  //     } else {
  //       const hday = holidays?.filter(
  //         (x) =>
  //           moment(x.HolidayDate).format("YYYY-MM-DD") ==
  //           moment(date).format("YYYY-MM-DD")
  //       );
  //       if (holidays.length != 0) {
  //         if (hday.length != 0) {
  //           getHolidays.push(hday?.[0]);
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       } else {
  //         return false;
  //       }
  //     }
  //   }

  //   if (weekdays.length == 1 && holidays.includes(weekdays[0])) {
  //     return 0;
  //   } else {
  //     // return 0
  //     return {
  //       workingDays: weekdays.length,
  //       holidayscount: getHolidays.length,
  //       holidays: getHolidays,
  //       weekend: weekend,
  //     };
  //   }
  // }

  function workingDays(startDate, diff1, holidays, includeSaturday, includeSunday) {
    const weekdays = [];
    const getHolidays = [];
    const weekend = [];
    let current = startDate;
    let i = 0;

    while (i < diff1) {
      if (!isWeekEnd(current)) {
        weekdays.push(moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"));
      }
      let currentObj = current;
      current = moment(currentObj, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
      i++;
    }

    function isWeekEnd(date) {
      let dateObj = moment(date, "YYYY-MM-DD");
      const dayOfWeek = dateObj.day();

      if ((dayOfWeek === 6 && !includeSaturday) || (dayOfWeek === 0 && !includeSunday)) {
        let wend = {
          Date: moment(date).format("YYYY-MM-DD"),
          Day: dayOfWeek === 6 ? "Saturday" : "Sunday",
          Notes: "Weekend",
        };
        weekend.push(wend);
        return true;
      } else if (holidays.length != 0) {
        const hday = holidays.filter(
          (x) => moment(x.HolidayDate).format("YYYY-MM-DD") == moment(date).format("YYYY-MM-DD")
        );
        if (hday.length != 0) {
          getHolidays.push(hday[0]);
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (weekdays.length == 1 && holidays.includes(weekdays[0])) {
      return 0;
    } else {
      // console.log(weekdays, "wwwwwwwwwwwwwwwwwwwwww")
      return {
        weekdays,
        enddate: weekdays.slice(-1)[0],
        workingDays: weekdays.length,
        holidayscount: getHolidays.length,
        holidays: getHolidays,
        weekend: weekend,
      };
    }
  }




  useEffect(() => {
    axios
      .get(
        "public_holiday_master?HolidayYear=eq." +
        moment().format("YYYY") +
        "&HolidayType=eq." +
        "Mandatory"
      )
      .then((res) => {
        dispatch(PUBLICHOLIDAY(res.data));
        setPublicHoliday(res.data);
      });
    // setSpin(true);
    // axios
    //   .get(
    //     `agilesaas_issue_details?PlannedRelease=is.null&IssueType=neq.Epic&ProjectId=eq.${projectCode}&order=CreatedDate.desc&IsActive=eq.Y`
    //   )
    //   .then((res) => {
    //     setissuedata(res.data);
    //     setSpin(false);
    //     console.log(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [projectCode]);

  // console.log(issuedata, "sss");
  // console.log(KanbanBoardData?.length, "check length");
  const filteredData = KanbanBoardData?.filter(
    (issue) =>
      issue.AssignedTo === null &&
      issue.OriginalEstimate === null &&
      issue.PlannedRelease === null &&
      issue.ActualRelease === null &&
      issue.CurrentStage.some((stage) => stage.StageCode === "RFD") &&
      issue.ProjectId == projectCode
  );
  // console.log(filteredData, "sss");

  let holidayDetails =
    result.holidayscount == 0
      ? null
      : result.holidays?.map((e) => ({
        Date: e.HolidayDate,
        Day: e.HolidayDay,
        Notes: e.HolidayInfo,
      }));

  // const disableDatesBefores = (date) => {
  //   // Your API values with already taken days
  //   const alreadyTakenDays = [
  //     { startdate: "26-02-2024", enddate: "08-03-2024" },
  //     { startdate: "11-03-2024", enddate: "22-03-2024" },
  //     { startdate: "22-04-2024", enddate: "03-05-2024" },
  //   ];

  //   // Check if the given date falls within any already taken range
  //   const isDateTaken = alreadyTakenDays.some(({ startdate, enddate }) => {
  //     const startDateObj = new Date(startdate);
  //     const endDateObj = new Date(enddate);
  //     return date >= startDateObj && date <= endDateObj;
  //   });

  //   // Also, check if the date is a Sunday (0) or Tuesday (2) to Saturday (6)
  //   const isWeekendOrTuesdayToSaturday =
  //     date.getDay() === 0 || (date.getDay() >= 2 && date.getDay() <= 6);

  //   return isDateTaken || isWeekendOrTuesdayToSaturday;
  //   // return isDateTaken;
  // };

  const UserList = ({ tasks }) => {
    let pointsAvailable = finalWorkingdays;
    let users = {};
    // console.log(users, "card");
    // Organize tasks based on AssignedToUATDetails
    tasks.forEach((task) => {
      let userKey = task.AssignedTo;

      if (!users[userKey]) {
        users[userKey] = {
          allocatedPoints: 0,
          name: "",
          tasks: [],
        };
      }
      users[userKey].name =
        task.AssignedToDetails?.FN + " " + task.AssignedToDetails?.LN;

      users[userKey].allocatedPoints += task.Estimatedpoint;
      users[userKey].tasks.push(task);
    });

    // Calculate remaining points for each user
    for (let userKey in users) {
      users[userKey].remainingPoints =
        pointsAvailable - users[userKey].allocatedPoints;
    }
    const getRowStyle = (rowData) => ({
      backgroundColor: rowData.remainingPoints < 0 ? "#ffcccc" : "inherit", // Change row background color if remainingPoints < 0
    });
    // console.log(users, "card");
    let whocantdo = Object.values(users)
      .filter((user) => user.remainingPoints < 0)
      .map((user) => {
        return { name: user.name };
      });
    const userList = whocantdo.map((user) => user.name).join(", ");

    // const columns = [
    //   { title: "Name", field: "name", headerStyle: { fontWeight: "bold" } },
    //   {
    //     title: "No of Tasks",
    //     field: "noOfTasks",
    //     headerStyle: { fontWeight: "bold" },
    //   },
    //   {
    //     title: "Allocated Points",
    //     field: "allocatedPoints",
    //     headerStyle: { fontWeight: "bold" },
    //   },
    //   {
    //     title: "Remaining Points",
    //     field: "remainingPoints",
    //     headerStyle: { fontWeight: "bold" },

    //   },
    //   {
    //     title: "Status",
    //     field: "status",
    //     headerStyle: { fontWeight: "bold" },
    //     render: rowData => (
    //       rowData.remainingPoints >= 0 ?
    //         <span className="customsmall" style={{ display: "inline-block", padding: "4px 8px", marginLeft: "2px", backgroundColor: "#c8e6c9", color: "#4caf50", fontWeight: "bold", borderRadius: "10px" }}>good</span> :
    //         <span className="customsmall" style={{ display: "inline-block", padding: "4px 8px", marginLeft: "2px", backgroundColor: "#f6cacc", color: "#d02224", fontWeight: "bold", borderRadius: "10px" }}>overdue</span>
    //     ),
    //     customFilterAndSearch: (filter, rowData) => {
    //       // Implement custom filter logic based on the status
    //       return (
    //         (filter === 'good' && rowData.remainingPoints >= 0) ||
    //         (filter === 'overdue' && rowData.remainingPoints < 0)
    //       );
    //     },
    //   },
    // ];

    const columns = [
      {
        title: "Name",
        field: "name",
        headerStyle: { fontWeight: "bold", width: "20%" },
        render: (rowData) => (
          <div
            className="d-flex align-items-center  "
            style={{ marginLeft: "-15%" }}
          >
            <Avatar
              className="Name mr-1"
              alt=""
              src={
                allImage?.length > 0
                  ? allImage?.filter((x) => x.teammemberid == rowData.id)?.[0]
                    ?.image
                  : Unassigned
              }
            />
            {rowData.name}
          </div>
        ),
      },
      {
        title: "No of Tasks",
        field: "noOfTasks",
        headerStyle: { fontWeight: "bold", width: "10%" },
      },
      {
        title: "Allocated Points",
        field: "allocatedPoints",
        headerStyle: { fontWeight: "bold", width: "30%" },
      },
      {
        title: "Remaining Points",
        field: "remainingPoints",
        headerStyle: { fontWeight: "bold", width: "40%" },
      },
      {
        title: "Status",
        field: "status",
        headerStyle: { fontWeight: "bold", width: "5%" },
        render: (rowData) =>
          rowData.remainingPoints >= 0 ? (
            <span
              className="customsmall"
              style={{
                display: "inline-block",
                padding: "4px 8px",
                marginLeft: "2px",
                backgroundColor: "#c8e6c9",
                color: "#4caf50",
                fontWeight: "bold",
                borderRadius: "10px",
              }}
            >
              good
            </span>
          ) : (
            <span
              className="customsmall"
              style={{
                display: "inline-block",
                padding: "4px 8px",
                marginLeft: "2px",
                backgroundColor: "#f6cacc",
                color: "#d02224",
                fontWeight: "bold",
                borderRadius: "10px",
              }}
            >
              Challenging
            </span>
          ),
        customFilterAndSearch: (filter, rowData) => {
          // Implement custom filter logic based on the status
          return (
            (filter === "good" && rowData.remainingPoints >= 0) ||
            (filter === "overdue" && rowData.remainingPoints < 0)
          );
        },
      },
    ];

    let tdata = Object.keys(users).map((userKey) => {
      // console.log(userKey);
      // console.log(totalDaysByUser[userKey]);

      // console.log(users[userKey].allocatedPoints);
      // console.log(users[userKey].remainingPoints);
      // console.log(finalWorkingdays);
      let leaveofuser = totalDaysByUser[userKey] ? totalDaysByUser[userKey] : 0;
      return {
        name: users[userKey].name,
        id: userKey,
        allocatedPoints: users[userKey].allocatedPoints + leaveofuser,
        remainingPoints: users[userKey].remainingPoints - leaveofuser,
        noOfTasks: users[userKey].tasks.length,
      };
    });

    return tdata.length > 0 ? (
      <MaterialTable
        title={"Allocated info"}
        columns={columns}
        data={tdata}
        options={{
          // pageSize: 10, // Set the initial page size (you can change this value)
          // pageSizeOptions: [10, 20, 30], // Set the available page size options
          search: true, // Enable/disable search functionality
          sorting: true, // Enable/disable column sorting
        }}
        rowStyle={(rowData) => getRowStyle(rowData)}
      />
    ) : null;
  };

  let specialCover =
    saturday && sunday
      ? result.weekend
      : saturday
        ? result.weekend.filter((x) => x.Day == "Saturday")
        : sunday
          ? result.weekend.filter((x) => x.Day == "Sunday")
          : null;

  // const publicholidays = [moment('2024-01-05').format("YYYY-MM-DD"), moment('2024-01-06').format("YYYY-MM-DD")]
  // const dayscheck = workingDays(selectedDate, diff, publicholidays)
  // console.log(dayscheck, "dayscheck")
  const getWeeks = weekdrop === 12 ? 2 : weekdrop === 19 ? 3 : 4;
  const finalWorkingdays = result?.workingDays
  // saturday && sunday
  //   ? result.workingDays + getWeeks * 2
  //   : saturday || sunday
  //     ? result.workingDays + getWeeks * 1
  //     : result.workingDays;

  const onChange = (checked) => {
    setPlanned(checked);
  };

  const customStyles1 = {
    control: (base, state) => ({
      ...base,
      width: 150,
      padding: 0,
      margin: 0,
      background: "#fff",
      border: state.isFocused
        ? "0px solid rgb(82, 80, 223)"
        : "0px solid rgba(0, 0, 0, 0.10)",
      borderRadius: 8,
      // width: '110%',
      // marginLeft: elementWidth > 1300 ? -35 : -20,

      // height: "50px",
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      // marginTop: 0,
      // marginLeft: elementWidth > 1300 ? -35 : -20,
      width: `${Math.max(
        ...userdropdownoptions.map((option) => option.label.length * 10)
      )}px`, // Adjust the multiplier based on your content
      minWidth: "100%",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const userdropdownoptions = employeeList.map((x) => {
    return {
      value: x.teammemberid,
      label: x.firstname + " " + x.lastname,
      firstname: x.firstname,
      lastname: x.lastname,
      icon: (
        <Avatar
          // className="progressName"
          src={
            allImage?.length > 0
              ? allImage?.filter((e) => e.teammemberid == x.teammemberid)?.[0]
                ?.image
              : Unassigned
          }
        />
      ),
    };
  });

  const handleSelectChange = (selectedOption, issueId, assignedto) => {
    if (assignedto != selectedOption?.value) {
      const updatedData = optionSelected.map((item) =>
        item.IssueId === issueId ? {
          ...item, AssignedTo: selectedOption?.value,
          AssignedToDetails: { FN: selectedOption?.firstname, LN: selectedOption?.lastname },
          changed: true,
          changedfield: item.changedfield && item.changedfield.length > 0
            ? [...item.changedfield, 'assignee']
            : ['assignee']
          // item?.changedfield == undefined ? ['assignee'] : item?.changedfield?.push('assignee') 
        } : item
      );
      dispatch(HANDLE_ON_CHANGE({ name: 'optionSelected', value: updatedData }))
    }
  };

  const handleInputChange = (event, issueId, originalestimate, key) => {
    const value = event.target.value;
    if ((/^\d*$/.test(value)) && (value != originalestimate)) {
      const updatedData = optionSelected.map((item) =>
        item.IssueId === issueId ? {
          ...item, OriginalEstimate: value,
          changed: true,
          changedfield: item.changedfield && item.changedfield.length > 0
            ? [...item.changedfield, 'originalestimate']
            : ['originalestimate']
          // item?.changedfield == undefined ? ['originalestimate'] : item?.changedfield?.push('originalestimate') 
        } : item
      );
      dispatch(HANDLE_ON_CHANGE({ name: 'optionSelected', value: updatedData }))
    }
  };

  let icon = (type) =>
    type === 'Story' ? <Bookmark className="story fontdesign" style={{ fontSize: '16px' }} /> :
      type === 'Task' ? <CheckBoxRoundedIcon className="task fontdesign" style={{ fontSize: '16px' }} /> :
        type === 'Bug' ? <StopCircleIcon style={{ fontSize: '16px' }} className="bug fontdesign" /> :
          type === 'Epic' ? <OfflineBoltRoundedIcon style={{ fontSize: '16px' }} className="epic fontdesign" /> :
            type === 'SubTask' ? <AddToPhotosIcon style={{ fontSize: '16px' }} className="kanbanicon task" /> : '';

  const buttononclick = (e) => {
    dispatch(HANDLE_ON_CHANGE({ name: 'storypointtype', value: "release" }))
    dispatch(HANDLE_ON_CHANGE({ name: "refinementstorypoint", value: true }));
    dispatch(HANDLE_ON_CHANGE({
      name: "refinementselecteddata", value: {
        "story": e,
        "linkeddata": optionSelected?.filter((x) => x.ParentIssue?.[0]?.IssueId == e.IssueId),
      }
    }));
  }

  const displaycontent = (e, key, type, checksubtask) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      {console.log(e, 'issue title')}
      {/* {
          (key == 1 ? planasprint?.key1 : key == 2 ? planasprint?.key2 : false) &&
          <div style={{ display: 'flex', alignItems: 'center', minWidth: '1%' }}>
              <input
                  type='checkbox'
                  checked={e.selected} // Use checked to reflect the current state
                  onChange={(event) => handlechangeCheckbox(event, e.IssueId, key)} // Use onChange instead of onClick
              />
          </div>
      } */}

      <div style={{ display: 'flex', alignItems: 'center', minWidth: '20%', maxWidth: '20%' }} className='mt-2'>
        <Typography style={{ fontSize: 12, fontFamily: 'DM Sans, sans-serif', textAlign: 'left', whiteSpace: 'nowrap' }}>
          {type == "substory" && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          <span>
            {e.Priority === "Highest" ? (
              <KeyboardDoubleArrowUpIcon className="filter-icons-1" fontSize="small" />
            ) : e.Priority === "High" ? (
              <KeyboardArrowUpIcon className="filter-icons-1" fontSize="small" />
            ) : e.Priority === "Medium" ? (
              <DragHandleIcon className="filter-icons" fontSize="small" />
            ) : e.Priority === "Low" ? (
              <KeyboardArrowDownIcon className="filter-icons-2" fontSize="small" />
            ) : e.Priority === "Lowest" ? (
              <KeyboardDoubleArrowDownIcon className="filter-icons-2" fontSize="small" />
            ) : (
              ""
            )}
          </span>
          {" "}
          <label className="mb-0"> {icon(e.IssueType)} </label>
          <label style={{ color: '#198ae3', marginLeft: '8px', whiteSpace: 'pre-wrap' }}>
            {projectName +
              //  e?.IssueId?.toString().includes('_') ? "" : 
              "-" + e?.IssueId}
            {console.log(e.IssueId, e?.IssueId?.toString().includes('_'))}
          </label>
        </Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', minWidth: '35%', maxWidth: '35%' }}>
        <Typography style={{ fontSize: 12, fontFamily: 'DM Sans, sans-serif', textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
          {e.value}
        </Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', minWidth: '25%', maxWidth: '25%', padding: 0 }}>
        <Typography style={{ fontWeight: '600', fontSize: 12, fontFamily: 'DM Sans, sans-serif', textAlign: 'left' }}>
          <Select
            options={userdropdownoptions}
            value={userdropdownoptions?.filter(
              (option) => option.value === e.AssignedTo
            )}
            getOptionLabel={(v) => (
              <div style={{ display: "flex", alignItems: "center", }}>
                <div className="assignee-avatar1">
                  <Avatar
                    // sx={{ width: '20px !important', height: '20px !important' }}
                    alt=""
                    src={
                      allImage?.length > 0
                        ? allImage?.filter(
                          (x) => x.teammemberid == v.value
                        )?.[0]?.image
                        : Unassigned
                    }
                  />
                </div>
                <span style={{ marginLeft: 10, fontWeight: 'normal' }}>{v.label}</span>
              </div>
            )}
            placeholder="Select assignee"
            onChange={(selectedOption) => handleSelectChange(selectedOption, e.IssueId, e.AssignedTo)}
            styles={customStyles1}
          />
        </Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', minWidth: '20%', maxWidth: '20%' }}>
        {console.log(e.IssueType === "Story" && e.OriginalEstimate == null, e.IssueType === "Story", e.OriginalEstimate == null, "check ")}
        <Typography style={{ fontSize: 12, fontFamily: 'DM Sans, sans-serif', textAlign: 'left' }}>
          {
            e.IssueType === "Story" && e.OriginalEstimate == null ?
              <Tooltip title={"Add sub task or estimate"}>
                <Button1
                  type='primary' style={{ backgroundColor: 'rgb(82, 80, 229)' }}
                  size='small' onClick={() => { buttononclick(e) }}>Estimate</Button1>
              </Tooltip>
              :
              <div className='d-flex'>
                <input
                  placeholder='estimate'
                  value={e.OriginalEstimate}
                  onChange={(event) => handleInputChange(event, e.IssueId, e.OriginalEstimate, key)}
                  style={{
                    border: '1px solid #d9d9d9',
                    padding: 2,
                    borderRadius: 5,
                    // width: '70px',
                    textAlign: 'center'
                  }}
                  disabled={checksubtask > 0}
                />
                {/* {e.IssueType == "Story" &&
                                          <IconButton size='small'
                                              onClick={() => { buttononclick(e, key) }}
                                          // className="d-flex justify-content-end"
                                          // onClick={() => {
                                          //     handleclose()
                                          // }}
                                          >
                                              <CloseIcon />
                                          </IconButton>} */}
              </div>}
        </Typography>
      </div>
    </div>
  )
  const displaylist = (passeddata, key) => (
    <List
      size="small"
      header={""}
      footer={""}
      style={{
        height: '200px',
        width: '100%',
        overflowY: 'scroll'
      }}
      // bordered
      dataSource={passeddata}
      renderItem={(e) => {
        const childdata = e.ParentIssue?.length > 0 && e.ParentIssue?.[0]?.IssueId
        return (
          <List.Item>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {console.log(e.ParentIssue, passeddata?.filter((a) => e.ParentIssue?.[0]?.IssueId == a.IssueId), passeddata?.filter((a) => e.ParentIssue?.[0]?.IssueId == a.IssueId)?.length == 0, 'check data')}
              {passeddata?.filter((a) => a?.IssueId == childdata)?.length == 0 &&
                displaycontent(e, key, "task", passeddata?.filter((a) => a.ParentIssue?.[0]?.IssueId == e.IssueId)?.length)}
              {
                e.IssueType == "Story" &&
                passeddata?.filter((a) => a.ParentIssue?.[0]?.IssueId == e.IssueId)?.map((c) =>
                  <div style={{ marginTop: 2 }}> {displaycontent(c, key, "substory", 0)} </div>
                )}
            </div>
          </List.Item >
        )
      }
      }
    />
  );

  const items1 = [
    {
      key: '1',
      label: <span className='ml-2'>
        Selected Issues
      </span>,
      children:
        // <label>Test data</label>
        <div>
          {displaylist(optionSelected, 1)}
        </div>,
    }]

  const useStyles1 = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
  }));
  const classes1 = useStyles1();
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1)

  const displayData = () => (
    <>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} style={{ paddingBottom: 0, marginBottom: 0 }}>
            <Form.Label className="SprintlabelStyle">
              Project Assigned to
            </Form.Label>
            <Form.Control
              className="SprintFormFields"
              placeholder="Yozy Devops"
              value={optionprojectrelease?.label}
              disabled={true}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} style={{ paddingBottom: 0, marginBottom: 0 }}>
            <Form.Label className="SprintlabelStyle">
              Sprint Name
              <label className="createAlert">*</label>
            </Form.Label>
            <Form.Control
              placeholder="Sprint name"
              className="SprintFormFields"
              value={versionrelease}
              onChange={(e) => setversionrelease(e.target.value)}
            />
          </Form.Group>
          {/* <input type="textInput" onChange={(e) => {
                  e.prevent
                  setVersiondata(e.target.value)
                }} /> */}
        </Row>
        {checkedactualvalue ? (
          <Row className="mb-3">
            <Col>
              <Form.Label className="SprintlabelStyle">
                Issues in Done
              </Form.Label>
              <div>
                {optionIssueDrop.length == 0 ? (
                  <label className="SprintFormFields">
                    <span class="badge badge-info">No Issues</span>{" "}
                    {/* <span class="badge badge-light ">DONE</span>{" "} */}
                  </label>
                ) : (
                  optionIssueDrop?.map((e) => (
                    <label className="myreleasefont">
                      <span class="badge badge-info">{e.label}</span>{" "}
                      {/* <span class="badge badge-light ">DONE</span> */}
                    </label>
                  ))
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                style={{ paddingBottom: 0, marginBottom: 0 }}
              >
                <Form.Label className="SprintlabelStyle">
                  Start Date
                  <label className="createAlert">*</label>
                </Form.Label>
                <div className="test">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      InputProps={{
                        style: {
                          outlineColor: "red",
                        },
                      }}
                      classes={{
                        root: classes.datePickerInput, // Apply the custom styles to the root element
                      }}
                      className="datepickerDesign mt-1"
                      value={selectedDate}
                      onChange={(date) => handleDateChange(date)}
                      // format="dd-MM-yyyy"
                      format="dd MMM yyyy"
                      views={["year", "month", "date"]}
                      shouldDisableDate={disableDatesBefore}
                      // disablePast
                      inputVariant="outlined"
                      required
                      size="small"
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="SprintlabelStyle">
                  Week
                  <label className="createAlert">*</label>
                </Form.Label>
                <Select
                  styles={customStyles}
                  style={{
                    background: "#FFF",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                  onChange={(item) => setweekdrop(item.value)}
                  options={week}
                  defaultValue={{ value: "12", label: "2 Weeks Sprint" }}
                  isOptionDisabled={(option) => option.isdisabled}
                  value={week.filter((option) => option.value == weekdrop)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Col}
                style={{ paddingBottom: 0, marginBottom: 0 }}
              >
                <Form.Label className="SprintlabelStyle">
                  End Date
                  <label className="createAlert">*</label>
                </Form.Label>
                <Form.Control
                  placeholder="Sprint name"
                  className="SprintFormFields"
                  value={moment(endOfWeek).format("DD MMM yyyy")}
                  // disabled={true}
                  type="text"
                // onChange={(e) => handleversionrelease(e)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="SprintlabelStyle">
                  Public Holidays - (Total {result?.holidayscount})
                </Form.Label>
                {result?.holidayscount != 0 ? (
                  <table className="table border rounded">
                    <tbody>
                      {result?.holidays.map((e) => (
                        <tr>
                          <td>{e.HolidayInfo}</td>
                          <td>{moment(e.HolidayDate).format("DD MMM YYYY")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <table className="table border rounded">
                    <tbody>
                      <tr>
                        <td
                          style={{ borderBottom: "none" }}
                          rowSpan="2"
                          colSpan="2"
                        >
                          No Public Holiday
                        </td>
                      </tr>
                      <tr></tr>
                    </tbody>
                  </table>
                )}
              </Form.Group>
              {/* <Form.Group as={Col}>
                <Form.Label className="SprintlabelStyle">
                  Weekend Working
                </Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch-saturday"
                  label={
                    <div>
                      <span style={{ fontSize: "16px", marginRight: "10px" }}>
                        Saturday
                      </span>
                    </div>
                  }
                  checked={saturday}
                  onChange={(e) => handlecheck("saturday", e)}
                />
                <Form.Check
                  type="switch"
                  id="custom-switch-sunday"
                  label={
                    <div>
                      <span style={{ fontSize: "16px", marginRight: "10px" }}>
                        Sunday
                      </span>
                    </div>
                  }
                  checked={sunday}
                  onChange={(e) => handlecheck("sunday", e)}
                />

                <Form.Label
                  className="SprintlabelStyle"
                  style={{ marginTop: "3%" }}
                >
                  Total Working Days : {finalWorkingdays}
                </Form.Label>
              </Form.Group> */}
            </Row>
            <Row className="">
              <Form.Group as={Col}>
                <Form.Label className="SprintlabelStyle">
                  Weekend Working
                </Form.Label>
                <Row className="">
                  <Col className=" d-flex justify-content-center" xs={4}>
                    {/* <Form.Check
                      type="checkbox"
                      id="custom-switch-saturday"
                      label={
                        // <div>
                        //   <span
                        //     style={{
                        //       fontSize: "16px",
                        //       //  marginRight: "10px"
                        //     }}
                        //   >
                        //     Saturday
                        //   </span>
                        // </div>
                        <span style={{ fontSize: "16px" }}>Saturday</span>
                      }
                      checked={saturday}
                      onChange={(e) => handlecheck("saturday", e)}
                    /> */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={saturday}
                          onChange={(e) => handlecheck("saturday", e)}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Saturday</span>}
                    />
                  </Col>
                  <Col>
                    {/* <Form.Check
                      type="checkbox"
                      id="custom-switch-sunday"
                      label={
                        <div>
                          <span
                            style={{ fontSize: "16px", marginRight: "10px" }}
                          >
                            Sunday
                          </span>
                        </div>
                      }
                      checked={sunday}
                      onChange={(e) => handlecheck("sunday", e)}
                    /> */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={sunday}
                          onChange={(e) => handlecheck("sunday", e)}
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>Sunday</span>}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label
                  className="SprintlabelStyle"
                // style={{ marginTop: "3%" }}
                >
                  Total Working Days
                  {/* : {finalWorkingdays} */}
                </Form.Label>
                <Row>
                  <Col>
                    <label>
                      <h4>{finalWorkingdays}</h4>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group
                  // as={Col}
                  style={{ paddingBottom: 0, marginBottom: 0 }}
                >
                  <Form.Label className="SprintlabelStyle">
                    Issue
                    {/* <span className="text-muted" style={{ fontSize: 10 }}> */}
                    {/* {" "} */}
                    {/* ( Sprint planning includes only assigned and estimated
                      cards. ){" "} */}
                    {/* </span> */}
                    {/* <label className="createAlert">*</label> */}
                    <span className=" ml-1" style={{ paddingBottom: "12px" }}>
                      <i
                        style={{ cursor: "pointer" }}
                        maxMenuHeight={142}
                        className="fa fa-info-circle text-muted  "
                        aria-label="info"
                        aria-owns={open1 ? "mouse-over-popover1" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      ></i>
                      <Popover
                        id="mouse-over-popover1"
                        className={classes1.popover}
                        classes={{
                          paper: classes1.paper,
                        }}
                        open={open1}
                        anchorEl={anchorEl1}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        {/* <Typography style={{ fontSize: "11px" }}> */}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontSize: "11px" }}>Notes:</span>
                          <span style={{ fontSize: "11px" }}> 1. You can create a umberla sprint without selecting "Backlog Tickets / Issues"</span>
                          <span style={{ fontSize: "11px" }}>2. Use "Refinement" feature from Kanboard Actions to Estimate / Assign for better planning.</span>
                        </div>
                        {/* </Typography> */}
                      </Popover>
                    </span>
                  </Form.Label>
                  {console.log(optiondroprelease12, 'optionSelected')}
                  {console.log(optionSelected, 'optionselected2338')}
                  {optiondroprelease12?.length == 0 ||
                    optiondroprelease12 == "" ? (
                    <Form.Control
                      className="SprintFormFields"
                      value={"No issues"}
                      type="text"
                      disabled={true}
                    />
                  ) : (
                    <MultiSelect
                      key="example_id"
                      options={optiondroprelease12}
                      onChange={handleChanged}
                      value={optionSelected}
                      isSelectAll={true}
                      // maxHeight={300}
                      // maxMenuHeight={145}
                      menuPlacement={"bottom"}
                      placeholder="Select issues for a sprint"
                    />
                  )}
                </Form.Group>
              </Col>
              {/* <Col
                xs={3}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                  }}
              > */}
              {/* <Form.Group
                  style={{ paddingBottom: 0, marginBottom: 0 }}
                >
                  <Button
                    style={{
                      fontSize: 14,
                      padding: 9,
                      width: 200,
                      height: 42,
                      fontWeight: "bold",
                      borderRadius: 8,
                      marginRight: 14,
                      border: "1px solid lightgray",
                      backgroundColor: "#fff",
                      color: "#000",
                      textAlign: "center",
                      textTransform: "none",
                    }}
                    aria-describedby={id}
                    onClick={handleClick}
                  >
                    <span
                      class="mdi mdi-account-alert-outline"
                      style={{ fontSize: "19px", fontWeight: "bold" }}
                    ></span>
                    &nbsp; Unallocated issues
                    <span className="pl-1">
                      {filteredData?.length ? filteredData?.length : ""}
                    </span>
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosess}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div style={{ width: 260, maxHeight: 200, height: 200 }}>
                      {spin ? (
                        <span
                          className="spinner-border"
                          role="status"
                          aria-hidden="true"
                          style={{
                            color: "#fff",
                            width: "1.5rem",
                            height: "1.5rem",
                          }}
                        ></span>
                      ) : (
                        <Card
                          className="w-100 h-100 "
                          style={{ backgroundColor: "#ffffff" }}
                        >
                          <Card.Header
                            className=" p-0 pt-2 pl-2 pb-1"
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <b>Issues list</b>
                          </Card.Header>
                          {filteredData?.length > 0 ? (
                            filteredData?.map((issue) => (
                              <Row className="p-1 ">
                                <Col xs={2} className="">
                                  <label className="d-flex pt-1">
                                    {issue.IssueType == "Story" ? (
                                      <Bookmark
                                        className="story fontdesign"
                                        style={{ fontSize: "16px" }}
                                      />
                                    ) : issue.IssueType == "Task" ? (
                                      <CheckBoxRoundedIcon
                                        className="task fontdesign"
                                        style={{ fontSize: "16px" }}
                                      />
                                    ) : issue.IssueType == "Bug" ? (
                                      <StopCircleIcon
                                        style={{ fontSize: "16px" }}
                                        className="bug fontdesign"
                                      />
                                    ) : issue.IssueType == "Epic" ? (
                                      <OfflineBoltRoundedIcon
                                        style={{ fontSize: "16px" }}
                                        className="epic fontdesign"
                                      />
                                    ) : issue.IssueType == "SubTask" ? (
                                      <AddToPhotosIcon
                                        style={{ fontSize: "16px" }}
                                        className="kanbanicon task"
                                        {...issue.IssueId}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <span style={{ fontSize: 12 }}>
                                      {issue?.IssueId}
                                    </span>
                                  </label>
                                </Col>
                                <Col xs={10}>
                                  <lable style={{ fontSize: 12.5 }}>
                                    {issue.IssueTitle}
                                  </lable>
                                </Col>
                              </Row>
                            ))
                          ) : (
                            <span className="p-2">
                              There is no unallocated issues
                            </span>
                          )}
                          </Card>
                      )}
                    </div>
                  </Popover>
                </Form.Group> */}
              {/* </Col> */}
            </Row>
            {
              optionSelected?.length > 0 &&

              <Collapse onChange={onChange} items={items1} className='my-3' size='small'
                defaultActiveKey={['1']}
                // collapsible={data1?.length > 0 ? "" : 'disabled'}
                expandIconPosition='end' />
            }
            <Row>

            </Row>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                style={{ paddingBottom: 0, marginBottom: 0 }}
              >
                {optionSelected ? <UserList tasks={optionSelected} /> : null}
              </Form.Group>
            </Row>
          </>
        )}

        <Row className="mb-3">
          <Form.Group as={Col} style={{ paddingBottom: 0, marginBottom: 0 }}>
            <Form.Label className="SprintlabelStyle">
              Description
              {/* <label className="createAlert">*</label> */}
            </Form.Label>
            <Form.Control
              className="SprintFormFields"
              as="textarea"
              value={descriptionr}
              onChange={(e) => handledescrelease(e)}
              rows={3}
              style={{
                width: "99.5%",
              }}
            />
          </Form.Group>
        </Row>
      </Form>
      <Form>
        <Form.Check
          type="switch"
          id="custom-switch"
          label={
            <div>
              <span style={{ fontSize: "16px", marginRight: "10px" }}>
                Unplanned Sprint
              </span>
              <span style={{ fontSize: "12px" }}>
                (Close Items in 'DONE' stage)
              </span>
            </div>
          }
          checked={checkedactualvalue}
          onChange={handlecheckpre}
        />
      </Form>
    </>
  );

  const leaverecords = Assetoptions?.map((u) => ({
    UserId: u.EmpId,
    Firstname: u.firstname,
    Lastname: u.lastname,
    Leavesplanned: totalDaysByUser[u.EmpId] || 0,
    Availabledays: finalWorkingdays - (totalDaysByUser[u.EmpId] || 0),
  }));
  const toggleMinimize = () => {
    const windowElement = document.getElementById("planrelease");

    windowElement.style.display = "none";
    dispatch(ISPLANSPRINT(true));

    dispatch(MINIMIZED(true));
  };
  // console.log(leaverecords, "leaverecords", leaverecords.reduce((acc, user) => acc + user.Availabledays, 0))
  return (
    <>
      <StoryPointClick />
      <Dialog
        id="planrelease"
        open={releasePopup}
        // onClose={() => {
        //   handleClose();
        // }}
        className="mainbox"
        // maxWidth="1500px"
        maxWidth="md"
        fullWidth={true}
      // show={releasePopup}
      // onHide={() => handleClose()}
      // backdrop="static"
      // keyboard={false}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title className="text-secondary fontdesign" style={{ fontWeight: "bold" }}>
            Plan Sprint
          </Modal.Title>
        </Modal.Header> */}

        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Col lg={10} md={10}>
              <label
                className="text-secondary fontdesign"
                style={{ fontWeight: "bold" }}
              >
                Plan Sprint
              </label>
            </Col>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <IconButton onClick={toggleMinimize}>
                <SouthEastOutlinedIcon />
              </IconButton>
              <IconButton
                // className="d-flex justify-content-end"
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Button
            style={{
              fontSize: 14,
              padding: 9,
              width: "20%",
              height: 40,
              color: "black",
              fontWeight: "bold",
              borderBottom:
                type == "plansprint"
                  ? "2px solid #5A88F1"
                  : "0px solid #5A88F1",
              textAlign: "center",
              textTransform: "none",
            }}
            onClick={() => {
              setType("plansprint");
            }}
          >
            Plan Sprint
          </Button>
          <Button
            style={{
              fontSize: 14,
              padding: 9,
              width: "20%",
              height: 40,
              color: "black",
              fontWeight: "bold",
              borderBottom:
                type == "plansprint"
                  ? "0px solid #5A88F1"
                  : "2px solid #5A88F1",
              textAlign: "center",
              textTransform: "none",
            }}
            onClick={() => {
              setType("sprintcalendar");
            }}
          >
            Sprint Calendar
          </Button>
          {/* <AppBar
            position="static"
            color="#fff"
            // style={appBarStyle}
            className="border-bottom"
            elevation={0}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="info"
              // variant="fullWidth"
              aria-label="full width tabs example"
              className=" ml-4 "
            >
              <Tab
                className="mdi"
                style={appBarStyle}
                label="Plan Sprint &nbsp; &nbsp; &nbsp;"
                {...a11yProps(0)}
              />
              <Tab
                className="mdi "
                style={appBarStyle}
                label="Sprint Calendar"
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0} dir={theme.direction}>
            {displayData()}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <SprintCalendar type={type} />
          </TabPanel> */}
          <div style={{ margin: "2%" }}>
            {type == "plansprint" ? (
              displayData()
            ) : (
              <SprintCalendar type={type} />
            )}
          </div>
        </DialogContent>
        {type == "plansprint" ? (
          <DialogActions>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
                marginRight: "3%",
                marginBottom: "1%",
              }}
            >
              <Button
                onClick={() => handleClose()}
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 100,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 14,
                  border: "2px solid #5250E5",
                  backgroundColor: "#fff",
                  color: "#5250E5",
                  textAlign: "center",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  (checkedactualvalue && optionIssueDrop == "") || checkSubmit
                }
                style={{
                  fontSize: 14,
                  padding: 9,
                  width: 100,
                  height: 40,
                  borderRadius: 8,
                  backgroundColor: "#5250E5",
                  color: "#fff",
                  border: "0px solid #5A88F1",
                  textAlign: "center",
                  textTransform: "none",
                }}
                onClick={() => {
                  if (checkedactualvalue) {
                    handlerelease();
                  } else {
                    handleprerelease();
                  }
                }}
              >
                {checkSubmit ? (
                  <span
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
                  ></span>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </DialogActions>
        ) : null}

        {/* <div style={{ padding: 30 }} >
          <SprintCalendar />
        </div> */}
      </Dialog>
    </>
  );
}

export default Release;
