import React, { useState, Suspense, lazy, useContext } from "react";
import { Route, Switch, Redirect, useLocation, Link } from "react-router-dom";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Footer from "../../components/Footer/Footer.js";
import SidebarComponent from "../../components/Sidebar/SidebarComponent.js";
import axios from "../../axios";
import KanbanBoard from "../../components/KanbanBoard/KanbanBoard.js";
import AddNewProject from "../../components/KanbanBoard/project/Addnewproject.js";
import Team from "../../components/KanbanBoard/Team.js";
import MyIncidentQueue from "../../components/ITServices/index.js";
import MyTicketQueue from "../../components/ITServices/MyTicketQueue.js";
import RaiseATicket from "../../components/ITServices/index.js";
import RequestSomething from "../../components/ITServices/RequestSomething.js";
import Changenew from "../../components/ITServices/changemodule/Changenew.js";
import Dashboard from "../../components/Dashboard/Dashboard.js";
import { useSelector, useDispatch } from "react-redux";
import {
  SETTINGDETAILS,
  USERIMAGEUPDATE,
  MINIMIZED,
  ISMANAGERNOTES,
  ISPLANSPRINT,
  ISCREATEKB,
  ISREPORT,
  ISSPRINTDETAILS,
  TESTERSIMAGEUPDATE,
  ALLIMAGEUPDATE,
  SLACKTOKEN,
  CHANNELNAME
} from "../../redux/CommonInitialsate.jsx";
import TwoFactor from "../../components/Settings/TwoFactor.js";
import LeaveManagementSystem from "../../components/KanbanBoard/LeaveManagementSystem.jsx";
import Leavemain from "../../components/KanbanBoard/Leavemain.jsx";
import ProblemManagement from "../../components/ITServices/ProblemManagement.jsx";
import getAllImages from "../../getAllImages.js";
import Login from "../Login/Login.js";
import Unassigned from "../../assets/images/avatar.png";

function Admin({ token }) {
  // const role = JSON.parse(localStorage.getItem("Rolecode"));

  const dispatch = useDispatch();

  const userdata = JSON.parse(localStorage.getItem("userdata"));

  const location = useLocation();

  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  const [EmpProfile, setEmpProfile] = useState(null);
  const [AllowedScreen, setAllowedScreen] = useState(null);
  const [ScreenControl, setScreenControl] = useState(null);
  const [ProjectValue, setProjectValue] = useState(null);
  const { IsSidebarCollapse } = useSelector((state) => state.user);

  const [Menu, setMenu] = useState({});

  let userid = JSON.parse(localStorage.getItem("clientdetails"))?.[0]?.UserId;

  React.useEffect(() => {
    if (location.pathname !== "/user/kanbanboard") {
      dispatch(MINIMIZED(false));
      dispatch(ISCREATEKB(false));
      dispatch(ISSPRINTDETAILS(false));
      dispatch(ISMANAGERNOTES(false));
      dispatch(ISPLANSPRINT(false));
      dispatch(ISREPORT(false));
    }
    axios({
      method: "GET",
      url:
        "https://api.devozy.ai/subscription_user_role_mapping?ProductDetails->>ProdId=eq.11004&UserDetails->>Email=eq." +
        token +
        "&LoginEnabled=eq.Y",
    }).then(async (response) => {
      let clientid = response?.data[0]?.ClientId;
      const resolvedImages = await getAllImages(clientid);
      dispatch(ALLIMAGEUPDATE(resolvedImages));

      axios
        .get(
          `/subscription_org_settings?ClientId=eq.${clientid}&select=IsSettingEnabled,SettingDetails`
        )
        .then((settings) => {
          if (
            settings.data.length > 0 &&
            Array.isArray(settings?.data?.[0]?.SettingDetails)
          ) {
            //localStorage.setItem("settingDetails", JSON.stringify(settings.data?.[0]))

            localStorage.setItem(
              "multiplesm",
              settings?.data?.[0]?.SettingDetails?.filter(
                (x) => x.settingcode == "agilepmflow"
              )?.[0]?.value
            );
            
            localStorage.setItem(
              "Slack",
              settings?.data?.[0]?.SettingDetails?.filter(
                (x) => x.settingcode == "enable slack"
              )?.[0]?.value
            );
            dispatch(
              SETTINGDETAILS(
                settings?.data?.[0]?.SettingDetails?.filter(
                  (x) => x.settingcode == "filesize"
                )?.[0]
              )
            );
        
            
            dispatch(
              CHANNELNAME(
                settings?.data?.[0]?.SettingDetails?.filter(
                  (x) => x.settingcode == "slack channel id"
                )?.[0]?.value
              )
            );
            dispatch(
              SLACKTOKEN(
                settings?.data?.[0]?.SettingDetails?.filter(
                  (x) => x.settingcode == "slack token"
                )?.[0]?.value
              )
            );
          }
        });
      localStorage.setItem("clientid", JSON.parse(clientid));

      localStorage.setItem("clientdetails", JSON.stringify(response.data));
      // localStorage.setItem(
      //   "Rolecodedynamic",
      //   JSON.stringify([])
      // );

      axios({
        method: "GET",
        url:
          "https://api.devozy.ai/subscription_client_users?OfficialEmail=eq." +
          token +
          "&ClientId=eq." +
          clientid,

        // headers: {
        //   Authorization: "Bearer " + res.data.token,
        // },
      }).then((response) => {
        let resdata = response.data;
        axios({
          method: "GET",
          url:
            "https://api.devozy.ai/agilesaas_project_mapping?IsCurrent=eq.Y&TeamMemberId=eq." +
            resdata[0]?.ClientUserId,
          // headers: {
          //   Authorization: "Bearer " + res.data.token,
          // },
        }).then((res) => {
          localStorage.setItem("managerid", res.data?.[0]?.ManagerId);
          let roleset = new Set(res.data?.map((e) => e.ProjectRole.RoleCode));
          // console.log(
          //   res.data,
          //   res.data?.map((e) => e.ProjectRole.RoleCode),
          //   "rrrrrrrrrrr"
          // );
          localStorage.setItem(
            "projectrc",
            res.data?.map((e) => e.ProjectRole.RoleCode)
          );
          let role1 = Array.from(roleset);

          let role = res.data?.map((e) => e.ProjectRole.RoleCode)?.[0];
          const projectroles = [
            ...new Set(res.data?.map((e) => e.ProjectRole.RoleCode)),
          ];
          // console.log(res.data, "project mapping data", [...new Set(res.data?.map((e) => e.ProjectRole.RoleCode))]);
          // response.data[0].RoleDetails.RoleCode
          localStorage.setItem("Rolecode", JSON.stringify(role));
          //

          //     let rolecode = response.data?.map((e) => e.RoleDetails);
          // axios
          // .get("agilesaas_access_roles_master?RoleCode=eq." + role)
          axios
            .get("agilesaas_access_roles_master")
            .then((res) => {
              axios
                .get(
                  `subscription_user_role_mapping?UserId=eq.${resdata[0]?.ClientUserId}`
                )
                .then((rdata) => {
                  localStorage.setItem(
                    "devozyrole",
                    rdata.data.filter(
                      (i) => i.ProductDetails.ProdId == "11001"
                    )?.[0]?.RoleDetails?.RoleName
                  );
                  const roles = [
                    ...new Set(
                      rdata?.data
                        ?.map((e) => e.RoleDetails)
                        ?.map((d) => d.RoleCode)
                    ),
                  ];
                  const combineroles = [...projectroles, ...roles];
                  // console.log(combineroles, "combine roles")
                  const allowedMenus = [
                    ...new Set(
                      res?.data
                        ?.flat()
                        ?.filter((d) =>
                          combineroles.includes(d.RoleCode.toString())
                        )
                        ?.flatMap((a) => a.RoleLevelAccess?.[0]?.MenuIds)
                        ?.join()
                        .split(",")
                    ),
                  ];
                  // console.log(res?.data?.flat()?.filter(d => combineroles.includes(d.RoleCode.toString())), "allowed menus")
                  const controllevelaccess = res?.data
                    ?.flat()
                    ?.filter((d) => d.RoleCode.toString() == role);
                  let temp =
                    controllevelaccess?.[0]?.RoleLevelAccess[0]?.MenuIds;
                  let d = res.da;
                  setAllowedScreen(
                    controllevelaccess?.[0]?.ScreenControlLevelAccess
                  );
                  let allowMenu = temp?.split(",");
                  // console.log(allowMenu, "aaaaaaaaaaaaaa", response?.data)

                  axios
                    .get("/agilesaas_menu_master?MenuType=eq.LHSMenu")
                    .then((res) => {
                      axios
                        .get("/agilesaas_screen_controls_master")
                        .then((res) => {
                          setScreenControl(res.data);
                        });
                      // axios({
                      //   method: "GET",
                      //   url:
                      //     `https://subscriptionapi.yozytech.com/subscription_user_role_mapping?UserId=eq.${userid}`,
                      //   headers: {
                      //     Authorization: "Bearer " + token,
                      //   },
                      // })
                      //   .then((resp) => {
                      //     console.log(resp.data, "res.data")

                      let menu = res.data;

                      let temp1 = menu
                        .sort((a, b) => a.MenuId - b.MenuId)
                        ?.filter((menuItem) =>
                          allowedMenus.includes(menuItem.MenuId.toString())
                        );
                      // console.log(temp1)
                      //     let data = []
                      //     let value = (e) => e == "11004" ? 1 : e == "11001" ? 2 : e == "11005" ? 3 : e == "11002" ? 4 : ""
                      //     resp.data.map((d) => {
                      //       console.log(d)
                      //       let getdata = temp1.filter((e) => e.MenuId == value(d))
                      //       console.log(getdata)
                      //       data.push(getdata)
                      //       console.log(getdata.flat(-1))
                      //     })
                      const checkorg = resdata?.[0]?.IsOrgAdmin == "Y";
                      // console.log(resdata?.[0]?.IsOrgAdmin, checkorg, "cccccccccccccccccheck")
                      const cpanelmenu = [
                        {
                          MenuId: 61,
                          MenuName: "CPanel Admin",
                          Path: "https://cpanel.devozy.ai/login",
                          Level: 1,
                          Component: "Cpanel",
                          IconDetails: "fa fa-id-card",
                          IsActive: "Y",
                          Status: null,
                          MenuType: "LHSMenu",
                          ParentMenuId: null,
                          Sequence: 7,
                        },
                      ];
                      const fdata = checkorg
                        ? [...temp1, ...cpanelmenu]
                        : temp1;
                      setMenu(
                        fdata?.sort((a, b) => a.Sequence - b.Sequence)
                        // temp1.filter((e) => {
                        //   for (let i = 0; i < allowMenu.length; i++) {
                        //     if (e.MenuId == allowMenu[i]) {
                        //       return e.MenuId;
                        //     }
                        //   }
                        // })
                      );
                      // console.log(fdata, "checkkkkkkkkkkkkkkkkkkk")
                    });

                  // });
                  sessionStorage.setItem(
                    "Roledetails",
                    JSON.stringify(controllevelaccess?.[0])
                  );
                });
            })
            .catch((e) => {
              console.log(e);
            });
        });
        axios
          .get(
            `/api/agile/filesystem/EmployeeImage/${response.data?.[0]?.ClientId}/${response.data?.[0]?.ClientUserId}_${response.data?.[0]?.UserFirstname}_${response.data?.[0]?.UserLastname}.png`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((res) => {
            const arrayBufferView = new Uint8Array(res.data);
            const blob = new Blob([arrayBufferView], { type: "image/png" });
            const imageUrl = URL.createObjectURL(blob);
            dispatch(USERIMAGEUPDATE(imageUrl));
          })
          .catch((e) => {
            // console.log(e);
            dispatch(USERIMAGEUPDATE(Unassigned));
          });

        localStorage.setItem("Empdetails", JSON.stringify(response.data));
        localStorage.setItem(
          "email",
          JSON.stringify(response.data?.[0]?.OfficialEmail)
        );
        localStorage.setItem(
          "userid",
          JSON.stringify(response.data?.[0]?.ClientUserId)
        );
        localStorage.setItem(
          "username",
          JSON.stringify(
            response.data?.[0]?.UserFirstname +
              " " +
              response.data?.[0]?.UserLastname
          )
        );
      });
    });
    // axios
    //   .get("employee_master?EmpId=eq." + 100021)
    //   .then((res) => setEmpProfile(res.data));
  }, [location]);
  localStorage.setItem("location", location.pathname);

  // this function opens and closes the sidebar on small devices

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  // let arr = JSON.parse(sessionStorage.getItem("Roledetails"));
  const getRoute = (Menu) => {
    if (Menu != null && Menu.length != undefined && Menu.length != 0) {
      const ROUTE = Menu?.map((prop, key) => {
        if (prop.MenuId == 5) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <KanbanBoard token={token} />;
              }}
            />
          );
        }
        if (prop.MenuId == 6) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <AddNewProject token={token} />;
              }}
            />
          );
        }
        if (prop.MenuId == 17) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <Team token={token} />;
              }}
            />
          );
        }
        if (prop.MenuId == 11) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <RaiseATicket token={token} />;
              }}
            />
          );
        }
        if (prop.MenuId == 27) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <RequestSomething token={token} />;
              }}
            />
          );
        }

        if (prop.MenuId == 13) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <ProblemManagement token={token} />;
              }}
            />
          );
        }
        // if (prop.MenuId == 14) {
        //   return <Route
        //     exact
        //     path={prop.Path}
        //     key={key}
        //     render={() => {
        //       return <MyIncidentQueue token={token} />;
        //     }}
        //   />
        // }
        if (prop.MenuId == 12) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <Changenew token={token} />;
              }}
            />
          );
        }
        if (prop.MenuId == 18) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <Dashboard token={token} />;
              }}
            />
          );
        }

        if (prop.MenuId == 28) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <Leavemain token={token} />;
              }}
            />
          );
        }
        if (prop.MenuId == 26) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <TwoFactor token={token} />;
              }}
            />
          );
        }
        if (prop.MenuId == 60) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <LeaveManagementSystem token={token} />;
              }}
            />
          );
        }
        if (prop.MenuId == 62) {
          return (
            <Route
              exact
              path={prop.Path}
              key={key}
              render={() => {
                return <Login token={token} />;
              }}
            />
          );
        }
      });
      return ROUTE;
    }
  };
  // console.log(IsSidebarCollapse);
  return (
    <>
      {Menu != null ? (
        <React.Fragment>
          <div
            className="container-scroller"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              // className="sidebar-nav"
              style={{
                backgroundColor: "#001529",
                width: IsSidebarCollapse ? "80px" : "222px",
                transition: "width 0.4s ease-in-out",

                // width: "fit-content",
                // transition: "ease-in-out",
                // background-color 0.3s ease-in-out",
              }}
            >
              <SidebarComponent
                routes={Menu}
                toggleSidebar={toggleSidebar}
                sidebarOpened={IsSidebarCollapse}
                setsidebarOpened={setsidebarOpened}
              />
            </div>

            <div className="main-panel" style={{ width: "100%" }}>
              <AdminNavbar
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
                EmpId={token}
                setProjectValue={setProjectValue}
                // EmpProfile={EmpProfile}
              />
              <Suspense
                fallback={
                  <div className="spinner-wrapper">
                    <div className="donut"></div>
                  </div>
                }
              >
                <Switch>
                  {getRoute(Menu)}
                  <Redirect from="*" to={localStorage.getItem("location")} />
                </Switch>
              </Suspense>
              {
                // we don't want the Footer to be rendered on map page
                location.pathname === "/user/maps" ? null : <Footer fluid />
              }
            </div>
          </div>
        </React.Fragment>
      ) : (
        ""
      )}
    </>
  );
}

export default Admin;
