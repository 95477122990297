// import React from "react";
import "gantt-task-react/dist/index.css";
import axios from "../../../axios";
import "./task-list-table.css";
// import "./task-list-header.module.css";
import "./list-header.css";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import React, { useState, useEffect, useRef } from "react";
import { getStartEndDateForProject, initTasks } from "./Helper.jsx";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ViewSwitcher } from "./view-switcher";
import { Avatar, Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Col, Row } from "react-bootstrap";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from "@material-ui/core/Alert";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { makeStyles } from "@material-ui/core/styles";
// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
function Timeline() {
  const { projectCode } = useSelector((state) => state.user);
  // let clientd = JSON.parse(localStorage.getItem("clientid"));
  // console.log(projectCode);
  // let empid = JSON.parse(localStorage.getItem("Empdetails"));
  // let crtid = empid?.[0]?.ClientUserId;
  // const chartContainerRef = useRef(null);
  const [view, setView] = useState(ViewMode.Month);
  // const currentDate = new Date();
  const [loading, setLoading] = useState(true);

  const [tasks, setTasks] = useState(
    //   [
    //   {
    //     start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    //     end: new Date(
    //       currentDate.getFullYear(),
    //       currentDate.getMonth(),
    //       2,
    //       12,
    //       28
    //     ),
    //     name: "Idea",
    //     id: "Task 0",
    //     progress: 45,
    //     type: "task",
    //     project: "ProjectSample",
    //     displayOrder: 2,
    //   },
    // ]
    initTasks()
  );
  // const [data, setData] = useState([]);
  const [historydata, sethistorydata] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment().startOf("month"));
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    fetchDataForMonth(currentMonth);
    // console.log("useEffect", currentMonth);
  }, [currentMonth]);
  const fetchDataForMonth = (date) => {
    const start1 = date.startOf("month").format("YYYY-MM-DD");
    const end1 = date.endOf("month").format("YYYY-MM-DD");
    axios
      .get(
        // `rpc/fun_agilesaasproductivityreport?projid=&select=issueid,issuetitle,createddate,originalestimate,assignedto,assignedtodetails,percentageprogressed,releasename,sprintstartdate,sprintenddate,currentstage&or=(and(sprintstartdate.lte.(${end1}),sprintenddate.gte.(${start1})),and(sprintenddate.gte.(${end1}),sprintstartdate.lte.(${end1})),and(sprintenddate.gte.(${end1}),sprintstartdate.lte.(${end1})))`
        `rpc/fun_agilesaasproductivityreport?projid=${projectCode}&select=issueid,issuetitle,createddate,originalestimate,assignedto,assignedtodetails,percentageprogressed,releasename,sprintstartdate,sprintenddate,currentstage,issuecreateddate&or=(and(sprintstartdate.lte.${end1},sprintenddate.gte.${start1}),and(sprintenddate.gte.${end1},sprintstartdate.lte.${end1}))`
      )
      // https://api.devozy.ai/rpc/fun_agilesaasproductivityreport?projid=190&or=(and(sprintstartdate.lte.2024-01-31,sprintenddate.gte.2024-01-01),and(sprintenddate.gte.2024-01-31,sprintstartdate.lte.2024-01-31),and(sprintenddate.gte.2024-01-31,sprintstartdate.lte.2024-01-31))
      .then((res) => {
        // console.log(res.data);
        const issuedata = res.data?.filter((e) => e.createddate != null);
        // const ids = res.data?.map((e) => e.issueid).join(",");
        // console.log(ids);
        // axios
        //   .get(
        //     `agilesaas_issue_history?ProjectId=eq.${projectCode}&UpdatedField=eq.Kanban Changes&UpdatedFrom=in.(RFD,DEV)&UpdatedTo=in.(DEV,UAT)&select=IssueId,ChangedDate,UpdatedFrom,UpdatedTo&IssueId=in.(${ids})`
        //   )
        //   .then((response) => {
        //     const mergedData = issuedata?.map((issue) => {
        //       const startdate = response.data?.filter(
        //         (change) =>
        //           change.IssueId === issue.issueid &&
        //           change.UpdatedFrom == "RFD" &&
        //           change.UpdatedTo == "DEV"
        //       )?.[0]?.ChangedDate;
        //       const enddate = response.data?.filter(
        //         (change) =>
        //           change.IssueId === issue.issueid &&
        //           change.UpdatedFrom == "DEV" &&
        //           change.UpdatedTo == "UAT"
        //       )?.[0]?.ChangedDate;
        //       return {
        //         ...issue,
        //         Devstartdate: startdate,
        //         Uatenddate: enddate,
        //       };
        //     });

        const uniqueReleasenames = issuedata?.reduce((uniqueVersions, item) => {
          const { releasename, sprintstartdate, sprintenddate } = item;
          if (
            !uniqueVersions.some(
              (version) => version.releasename === releasename
            )
          ) {
            uniqueVersions.push({
              releasename,
              sprintstartdate,
              sprintenddate,
            });
          }
          return uniqueVersions;
        }, []);

        // console.log(uniqueReleasenames);

        const final = uniqueReleasenames?.map((e) => {
          const start = new Date(e.sprintstartdate);
          const end = new Date(e.sprintenddate);

          return {
            start: new Date(
              start.getFullYear(),
              start.getMonth(),
              start.getDate()
            ),
            end: new Date(end.getFullYear(), end.getMonth(), end.getDate()),
            name: e.releasename,
            id: e.releasename,
            progress: "",
            type: "project",
            hideChildren: true,
            displayOrder: 1,
            // style: { backgroundColor: "#11DB61" },
          };
        });
        // console.log(final);
        const finaldata = [];
        final?.map((e) => {
          const alldata = issuedata
            ?.filter((x) => x.releasename == e.id)
            ?.map((y) => {
              const start = new Date(y.issuecreateddate);
              const end = new Date(y.currentstage?.[0]?.DateMoved);

              return {
                // ...y,
                start: new Date(
                  start.getFullYear(),
                  start.getMonth(),
                  start.getDate()
                ),
                end: new Date(end.getFullYear(), end.getMonth(), end.getDate()),
                name: y.issuetitle,
                id: y.issueid.toString(),
                progress: y.percentageprogressed,
                // dependencies: ["Task 0"],
                type: "task",
                project: y.releasename,
                assingne: y.assignedtodetails.FN + " " + y.assignedtodetails.LN,
                // estimate:y.originalestimate
                // displayOrder: 3,
                // style: { fontSize: "16px" },
              };
            });

          // console.log(alldata);
          finaldata?.push(alldata);
        });
        // console.log(finaldata?.flatMap((e) => e));
        if (issuedata.length > 0) {
          sethistorydata([...final, ...finaldata?.flatMap((e) => e)]);
          setLoading(false);
        } else {
          setOpen(true);
          setLoading(false);
        }
        // sethistorydata([...final, ...finaldata?.flatMap((e) => e)]);
        // })
        // .catch((err) => {
        //   console.error(err);
        // });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // useEffect(() => {
  //   const start1 = moment().startOf("month").format("YYYY-MM-DD");
  //   const end1 = moment().endOf("month").format("YYYY-MM-DD");
  //   axios
  //     .get(
  //       `rpc/fun_agilesaasproductivityreport?projid=${projectCode}&select=issueid,issuetitle,createddate,originalestimate,assignedto,assignedtodetails,percentageprogressed,releasename,sprintstartdate,sprintenddate,currentstage&sprintstartdate=gte.(${start1})&sprintenddate=lte.(${end1})`
  //     )
  //     .then((res) => {
  //       // console.log(res.data);
  //       const issuedata = res.data?.filter((e) => e.createddate != null);
  //       const ids = res.data?.map((e) => e.issueid).join(",");
  //       // console.log(ids);
  //       axios
  //         .get(
  //           `agilesaas_issue_history?ProjectId=eq.${projectCode}&UpdatedField=eq.Kanban Changes&UpdatedFrom=in.(RFD,DEV)&UpdatedTo=in.(DEV,UAT)&select=IssueId,ChangedDate,UpdatedFrom,UpdatedTo&IssueId=in.(${ids})`
  //         )
  //         .then((response) => {
  //           const mergedData = issuedata?.map((issue) => {
  //             const startdate = response.data?.filter(
  //               (change) =>
  //                 change.IssueId === issue.issueid &&
  //                 change.UpdatedFrom == "RFD" &&
  //                 change.UpdatedTo == "DEV"
  //             )?.[0]?.ChangedDate;
  //             const enddate = response.data?.filter(
  //               (change) =>
  //                 change.IssueId === issue.issueid &&
  //                 change.UpdatedFrom == "DEV" &&
  //                 change.UpdatedTo == "UAT"
  //             )?.[0]?.ChangedDate;
  //             return {
  //               ...issue,
  //               Devstartdate: startdate,
  //               Uatenddate: enddate,
  //             };
  //           });

  //           const uniqueReleasenames = mergedData.reduce(
  //             (uniqueVersions, item) => {
  //               const { releasename, sprintstartdate, sprintenddate } = item;
  //               if (
  //                 !uniqueVersions.some(
  //                   (version) => version.releasename === releasename
  //                 )
  //               ) {
  //                 uniqueVersions.push({
  //                   releasename,
  //                   sprintstartdate,
  //                   sprintenddate,
  //                 });
  //               }
  //               return uniqueVersions;
  //             },
  //             []
  //           );

  //           // console.log(uniqueReleasenames);

  //           const final = uniqueReleasenames?.map((e) => {
  //             const start = new Date(e.sprintstartdate);
  //             const end = new Date(e.sprintenddate);

  //             return {
  //               start: new Date(
  //                 start.getFullYear(),
  //                 start.getMonth(),
  //                 start.getDate()
  //               ),
  //               end: new Date(end.getFullYear(), end.getMonth(), end.getDate()),
  //               name: e.releasename,
  //               id: e.releasename,
  //               progress: "",
  //               type: "project",
  //               hideChildren: false,
  //               displayOrder: 1,
  //             };
  //           });
  //           // console.log(final);
  //           const finaldata = [];
  //           final?.map((e) => {
  //             const alldata = mergedData
  //               ?.filter((x) => x.releasename == e.id)
  //               ?.map((y) => {
  //                 const start = new Date(y.createddate);
  //                 const end = new Date(y.currentstage?.[0]?.DateMoved);

  //                 return {
  //                   ...y,
  //                   start: new Date(
  //                     start.getFullYear(),
  //                     start.getMonth(),
  //                     start.getDate()
  //                   ),
  //                   end: new Date(
  //                     end.getFullYear(),
  //                     end.getMonth(),
  //                     end.getDate()
  //                   ),
  //                   name: y.issuetitle,
  //                   id: y.issueid.toString(),
  //                   progress: y.percentageprogressed,
  //                   // dependencies: ["Task 0"],
  //                   type: "task",
  //                   project: y.releasename,
  //                   assingne:
  //                     y.assignedtodetails.FN + " " + y.assignedtodetails.LN,
  //                   // estimate:y.originalestimate
  //                   // displayOrder: 3,
  //                 };
  //               });

  //             // console.log(alldata);
  //             finaldata?.push(alldata);
  //           });
  //           // console.log(finaldata?.flatMap((e) => e));
  //           sethistorydata([...final, ...finaldata?.flatMap((e) => e)]);
  //           setLoading(false);
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }, []);

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.clone().add(1, "month"));
  };

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, "month"));
  };

  const handleToday = () => {
    setCurrentMonth(moment().startOf("month"));
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const issuedataRes = await axios.get(
  //         `rpc/fun_agilesaasproductivityreport?projid=${projectCode}&select=issueid,issuetitle,createddate,originalestimate,assignedto,assignedtodetails,percentageprogressed,releasename,sprintstartdate,sprintenddate,currentstage`
  //       );
  //       const issuedata = issuedataRes?.data;

  //       const ids = issuedata?.map((e) => e.issueid).join(",");

  //       const issueHistoryRes = await axios.get(
  //         `agilesaas_issue_history?ProjectId=eq.${projectCode}&UpdatedField=eq.Kanban Changes&UpdatedFrom=in.(RFD,DEV)&UpdatedTo=in.(DEV,UAT)&select=IssueId,ChangedDate,UpdatedFrom,UpdatedTo&IssueId=in.${ids}`
  //       );
  //       const issueHistory = issueHistoryRes?.data;

  //       const mergedData = issuedata?.data?.map((issue) => {
  //         const startdate = issueHistory?.data?.find(
  //           (change) =>
  //             change.IssueId === issue.issueid &&
  //             change.UpdatedFrom === "RFD" &&
  //             change.UpdatedTo === "DEV"
  //         )?.ChangedDate;
  //         const enddate = issueHistory?.data?.find(
  //           (change) =>
  //             change.IssueId === issue.issueid &&
  //             change.UpdatedFrom === "DEV" &&
  //             change.UpdatedTo === "UAT"
  //         )?.ChangedDate;
  //         return {
  //           ...issue,
  //           Devstartdate: startdate,
  //           Uatenddate: enddate,
  //         };
  //       });

  //       const uniqueReleasenames = mergedData?.reduce(
  //         (uniqueVersions, item) => {
  //           const { releasename, sprintstartdate, sprintenddate } = item;
  //           if (
  //             !uniqueVersions?.some(
  //               (version) => version.releasename === releasename
  //             )
  //           ) {
  //             uniqueVersions?.push({
  //               releasename,
  //               sprintstartdate,
  //               sprintenddate,
  //             });
  //           }
  //           return uniqueVersions;
  //         },
  //         []
  //       );

  //       const final = uniqueReleasenames?.map((e) => ({
  //         // const start = new Date(e.sprintstartdate);
  //         //     const end = new Date(e.sprintenddate);
  //         start: new Date(e?.sprintstartdate),
  //         end: new Date(e?.sprintenddate),
  //         name: e.releasename,
  //         id: e.releasename,
  //         progress: "",
  //         type: "project",
  //         hideChildren: false,
  //         displayOrder: 1,
  //       }));

  //       const finaldata = final?.flatMap((e) => {
  //         const alldata = mergedData
  //           ?.filter((x) => x.releasename === e?.id)
  //           ?.map((y) => {
  //             const start = new Date(y?.createddate);
  //             const end = new Date(y?.currentstage?.[0]?.DateMoved);
  //             return {
  //               ...y,
  //               start: new Date(
  //                 start?.getFullYear(),
  //                 start?.getMonth(),
  //                 start?.getDate()
  //               ),
  //               end: new Date(
  //                 end?.getFullYear(),
  //                 end?.getMonth(),
  //                 end?.getDate()
  //               ),
  //               name: y.issuetitle,
  //               id: y.issueid.toString(),
  //               progress: y.percentageprogressed,
  //               type: "task",
  //               project: y.releasename,
  //               assingne: `${y.assignedtodetails.FN} ${y.assignedtodetails.LN}`,
  //             };
  //           });
  //         return alldata;
  //       });

  //       sethistorydata([...final, ...finaldata]);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  //   // setLoading(false);
  // }, []);
  // function scrollToRightEnd() {
  //   if (chartContainerRef.current) {
  //     const chartContainer = chartContainerRef.current.querySelector(
  //       ".gantt-chart-container"
  //     );
  //     if (chartContainer) {
  //       chartContainer.scrollLeft =
  //         chartContainer.scrollWidth - chartContainer.clientWidth;
  //     }
  //   }
  // }

  // function handleInteraction() {
  //   scrollToRightEnd();
  // }
  const [isChecked, setIsChecked] = useState(true);

  let columnWidth = 75;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  // const handleTaskChange = (task) => {
  //   // console.log("On date change Id:" + task.id);
  //   let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
  //   if (task.project) {
  //     const [start, end] = getStartEndDateForProject(newTasks, task.project);
  //     const project =
  //       newTasks[newTasks.findIndex((t) => t.id === task.project)];
  //     if (
  //       project.start.getTime() !== start.getTime() ||
  //       project.end.getTime() !== end.getTime()
  //     ) {
  //       const changedProject = { ...project, start, end };
  //       newTasks = newTasks.map((t) =>
  //         t.id === task.project ? changedProject : t
  //       );
  //     }
  //   }
  //   setTasks(newTasks);
  // };

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    // console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleSelect = (task, isSelected) => {
    // console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    sethistorydata(historydata?.map((t) => (t.id == task.id ? task : t)));
    // console.log("On expander click Id:" + task.id);
  };
  const TaskListHeader = ({
    rowHeight,
    rowWidth,
    // tasks,
    fontFamily,
    fontSize,
    locale,
    onExpanderClick,
  }) => {
    return (
      <div
        className="taskListWrapper"
        style={{
          fontFamily: fontFamily,
          fontSize: fontSize,
        }}
      >
        {historydata?.slice(-10).map((t) => {
          let expanderSymbol = "";
          let expanderhidden = "";
          if (t.hideChildren === false) {
            expanderSymbol = "▼";
            expanderhidden = false;
          } else if (t.hideChildren === true) {
            expanderSymbol = "▶";
            expanderhidden = true;
          }

          return (
            <div
              className="taskListTableRow"
              style={{ height: rowHeight }}
              key={`${t.id}row`}
            >
              <div
                className="taskListCell"
                style={{
                  minWidth: rowWidth,
                  maxWidth: rowWidth,
                }}
                title={t.name}
              >
                <div className="taskListNameWrapper">
                  <div
                    className={
                      expanderSymbol
                        ? "taskListExpander"
                        : "taskListEmptyExpander"
                    }
                    onClick={() => onExpanderClick(t)}
                  >
                    {expanderSymbol}
                  </div>
                  <div>{t.name}</div>
                </div>
              </div>
              <div
                className="taskListCell"
                style={{
                  minWidth: rowWidth,
                  maxWidth: rowWidth,
                }}
              >
                {/* &nbsp;{t.start} */}
                <div className="d-flex align-items-center">
                  {t.assingne && (
                    <Avatar
                      alt="Remy Sharp"
                      style={{ width: "28px", height: "28px" }}
                    ></Avatar>
                  )}

                  <span className="ml-1">{t.assingne}</span>
                </div>
              </div>

              {/* <div
                className="taskListCell"
                style={{
                  minWidth: rowWidth,
                  maxWidth: rowWidth,
                }}
              >
                &nbsp;{t.end}
              </div> */}
            </div>
          );
        })}
      </div>
    );
  };
  const TaskListHeaderDefault = ({
    headerHeight,
    fontFamily,
    fontSize,
    rowWidth,
  }) => {
    return (
      <div
        className="ganttTable"
        style={{
          fontFamily: fontFamily,
          fontSize: fontSize,
        }}
      >
        <div
          className="ganttTable_Header"
          style={{
            height: headerHeight - 2,
          }}
        >
          <div
            className="ganttTable_HeaderItem"
            style={{
              minWidth: rowWidth,
              // marginLeft: "5px",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name
          </div>
          <div
            className="ganttTable_HeaderSeparator"
            style={{
              height: headerHeight * 0.5,
              marginTop: headerHeight * 0.2,
            }}
          />
          <div
            className="ganttTable_HeaderItem"
            style={{
              minWidth: rowWidth,
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assignee
          </div>
          {/* <div
            className="ganttTable_HeaderSeparator"
            style={{
              height: headerHeight * 0.5,
              marginTop: headerHeight * 0.25,
            }}
          /> */}
          {/* <div
            className="ganttTable_HeaderItem"
            style={{
              minWidth: rowWidth,
            }}
          >
            &nbsp;To0
          </div> */}
        </div>
      </div>
    );
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      {loading && historydata.length == 0 ? (
        <div
          className=" d-flex align-items-center"
          style={{ justifyContent: "center", height: 460 }}
        >
          <span
            className="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "rgb(82, 80, 229)" }}
          ></span>
        </div>
      ) : (
        <div>
          <ViewSwitcher
            onViewModeChange={(viewMode) => setView(viewMode)}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
          />
          <div>
            <Row className=" ">
              <Col className="d-flex justify-content-center pl-2">
                <Button
                  style={{ marginRight: 3 }}
                  startIcon={<ArrowBackIosNewIcon />}
                  onClick={handlePrevMonth}
                >
                  Prev
                </Button>
                <Button onClick={handleToday}>Today</Button>
                <Button
                  style={{ marginLeft: 3 }}
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={handleNextMonth}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>

          <Gantt
            tasks={
              historydata?.length > 0
                ? historydata
                : [
                    {
                      start: new Date(),
                      end: new Date(),
                      name: "",
                      id: "",
                      type: "",
                      project: "",
                    },
                  ]
            }
            viewMode={view}
            // onDateChange={handleTaskChange}
            // onDelete={handleTaskDelete}
            // onProgressChange={handleProgressChange}
            // onDoubleClick={handleDblClick}
            // onSelect={handleSelect}
            onExpanderClick={handleExpanderClick}
            listCellWidth={isChecked ? "160px" : ""}
            columnWidth={columnWidth}
            // TaskListHeader={TaskListHeaderDefault}
            // TaskListTable={TaskListHeader}
            // barFill={90}
            todayColor="rgb(253, 191, 96,0.2)"
            ganttHeight={500}
            // barCornerRadius={5}
            // barBackgroundSelectedColor={"#0E59E9"}
            barBackgroundColor="#D9D9D9"
            barProgressColor="#0E59E9"
            barProgressSelectedColor="#0144C7"
            fontSize="15px"
          />
          {/* </div> */}
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <MuiAlert
          onClose={handleClose}
          style={{ backgroundColor: "orange", color: "white" }}
          severity="warning"
        >
          There is no issues..
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default Timeline;
