import React, { useState } from "react";
import { toast, Slide } from "react-toastify";
import fileaxios from "../../filesAxios";
import FileDownload from "js-file-download";

import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Stack } from "@mui/material";
import { Button } from "reactstrap";
import { fetchauthById } from "./Twofactorslice";
import { useDispatch } from "react-redux";

toast.configure();

const Activate = ({ first, Empid, tokendata, DATAid, refreah }) => {
  const [nextpage, setNextpage] = useState(false);
  const [logincode, setLogincode] = useState(false);

  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);

  const handleClose = () => {
    setPopup(false);
  };

  function Empty() {
    setLogincode("");
  }

  const Activatetwofact = (e) => {
    e.preventDefault();
    let activatetdata = {
      EmpId: Empid,
      UserdataId: DATAid[0],
      IsActive: "Y",
      TwoStepAuth: "Y",
      VerificationCode: logincode,
      Token: tokendata,
    };

    fileaxios
      .post("twofactauth", activatetdata)
      .then((res) => {
        console.log(res.data);
        handleClose();
        Empty();
        toast.success(`2fa Activated`, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          draggable: true,
        });
        dispatch(fetchauthById(Empid));
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Request Not updated`, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          draggable: true,
        });
      });
  };

  const DownloadPdf = () => {
    let data = {
      qrcodeimage: true,
      EmpId: Empid,
      Token: tokendata,
    };
    fileaxios
      .post("twofactauth", data, { responseType: "blob" })
      .then((res) => {
        FileDownload(res, "QrCode.pdf");
      })
      .catch((err) => console.error(err));
  };



  return (
    <div>
      {!nextpage &&
        first?.map((e) => {
          return (
            <div className="container">
              <strong>2FA Activation</strong>
              <br></br>
              <b>
                Use Your{" "}
                <span className="text-danger"> Google Authenticator App </span>{" "}
                to scan the code below
              </b>

              <div className="d-flex justify-content-center">
                {e.TwoStepAuth === "N" ? (
                  <>
                    <img src={e.Image} className="qrimage" alt="QrImage" />
                  </>
                ) : null}
              </div>
              <hr></hr>

              <h5>Download your account recovery key "paper key" </h5>
              <button
                className="btn btn-outline-success"
                onClick={() => DownloadPdf()}
              >
                Download key
              </button>
              <br></br>
              <small>
                Store it in a safe place where only you have access it
              </small>

              <br></br>
              <small>
                Recovery code can use access your account in the event you loss
                access to your devices and connot recive two-factor
                Authendicator code
              </small>

              <div className="pt-3">
                <button className="btn">cancel</button>
                <button
                  type="button"
                  onClick={() => setNextpage(true)}
                  className="btn btn-success"
                  to="/active"
                >
                  Continue 2FA Activation
                </button>
              </div>
            </div>
          );
        })}

      {nextpage ? (
        <div>
          <Button
            onClick={() => setNextpage(false)}
            className="btn btn-success">back</Button>
          <hr></hr>
          <div className="row">
            <div className="col-4">
              <Typography component={"strong"}>Authentication</Typography>
            </div>
            <div className="col-8">
              <div className="row">
                <div className="col-lg-4">
                  <Typography component={"strong"}>Password </Typography>
                </div>
                <Box className="col-lg-6">*****</Box>
                <Box className="col-lg-2">
                </Box>
              </div>
              <hr></hr>
              <Box className="row">
                <Box className="col-lg-8">
                  <Typography component={"p"}>
                    <Typography component={"strong"}> 2FA</Typography>
                    <Typography component={"p"}>
                      Two-factor authentication is currently
                      disabled on this account , Our 2FA process Requires that you
                      have an authenticator app such as
                    </Typography>
                    <Typography component={"span"} className="text-danger pl-1">Authy </Typography>or
                    <Typography component={"span"} className="text-danger pl-1">
                      Google Authenticator
                    </Typography>
                    , Available .
                  </Typography>
                  <br></br>
                  <Typography component={"strong"}>
                    Upon successfully activation 2FA , All sessions for your
                    user will be cleared
                  </Typography>
                </Box>

                <Box className="col-lg-3">
                  <button
                    type="button"
                    onClick={() => setPopup(true)}
                    className="btn btn-secondary mx-5"
                  >
                    Activate
                  </button>
                </Box>
              </Box>
            </div>
          </div>
          <hr></hr>
        </div>
      ) : null}

      <Dialog
        open={popup}
        className="mainbox"
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Box>
              <Typography component={"strong"}>2FA Activation </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box>
              <Box className="mb-3">
                <Typography component={"b"}> Enter 2fa code below to Continue
                </Typography>
                <Typography component={"span"} className="blockquote-footer">
                  This will also clear all sessions for your user{" "}
                </Typography>
              </Box>

              <form onSubmit={Activatetwofact}>
                <Typography component={"strong"}>2FA code</Typography>
                <input
                  className="form-control code w-100"
                  type="number"
                  name="code"
                  value={logincode}
                  onChange={(w) => setLogincode(w.target.value)}
                />
                <Box marginTop={1}>
                  <Button type="button" className="btn btn-secondary ">
                    cancel
                  </Button>
                  <Button type="submit" className="btn btn-success mx-3">
                    Activate
                  </Button>
                </Box>
              </form>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Activate;
