import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { Row, Col } from "reactstrap";
import { Button, TextField, Box, Divider } from "@material-ui/core";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../axios";
import { useSelector, useDispatch } from "react-redux";
import { HANDLE_ON_CHANGE } from "../../../redux/CommonInitialsate";

export default function OriginalEstimate({
  // openCreateEst,
  // setOpenCreateEst,
  // IssueId,
  // setRefresh,
  // FilteredData,
  // selectedCard,
}) {
  const [OriginalEst, setOriginalEst] = useState();
  const [checkSubmit, setSubmitCheck] = useState(false);
  const dispatch = useDispatch()
  let arr = JSON.parse(localStorage.getItem("Empdetails"));

  let clientid = JSON.parse(localStorage.getItem("clientid"));
  let clientdetails = JSON.parse(localStorage.getItem("clientdetails"));
  const userorg = clientdetails[0]?.OrgDetails;
  const { projectCode, selectedCard1, FilteredData, selectedCard, IssueId, openCreateEst } = useSelector((state) => state.user);
  const handleClose = () => {
    // setOpenCreateEst(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'openCreateEst', value: false }))
  };

  let OriginalEstsubmit = () => {
    let Sppostdata1 = {
      IssueId: IssueId, // issue id
      UpdatedBy: arr[0]?.ClientUserId, // current login id
      UpdatedByDetails: {
        FN: arr[0]?.UserFirstname,
        LN: arr[0]?.UserLastname,
      },
      UpdatedDate: moment().format("YYYY-MM-DD"), //current date
      SPEstimationDetails: [
        {
          SPCode: "INPUTFORM",
          SPValueSelected: null,
          Effort: null,
        },
        {
          SPCode: "FROMVALIDATION",
          SPValueSelected: null,
          Effort: null,
        },
        {
          SPCode: "STYLING",
          SPValueSelected: null,
          Effort: null,
        },
        {
          SPCode: "CRUD",
          SPValueSelected: null,
          Effort: null,
        },
        {
          SPCode: "APIINTE",
          SPValueSelected: null,
          Effort: null,
        },
        {
          SPCode: "TESTING",
          SPValueSelected: null,
          Effort: null,
        },
      ],
      AutoEstimatedEffort: null,
      Contigency: null,
      TotalEstimatedEffort: OriginalEst.OriginalEstimate, //original estimated value
      EstimationRemarks:
        "added 2 hours buffer to take care additional UI changes",
      IsActive: "Y",
      Status: null,
    };

    let posthistory = {
      IssueId: IssueId,
      ChangedBy: arr[0]?.ClientUserId,
      ChangedByDetails: {
        FN: arr[0]?.UserFirstname,
        LN: arr[0]?.UserLastname,
      },
      ChangedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
      UpdatedField: "Original-Estimate",
      UpdatedTo: OriginalEst.OriginalEstimate,
      UpdateSequence: 1,
      IsActive: "Y",
      Status: null,
      ClientId: clientid,
      ProjectId: projectCode,
    };
    let updateAgile = {
      CurrentStage: [
        {
          StageCode: "DEV",
          StageName: "In Development",
          DateMoved: moment().format("YYYY-MM-DD"),
        },
      ],
      OriginalEstimate: OriginalEst.OriginalEstimate,
    };
    setSubmitCheck(true);
    axios
      .patch("agilesaas_issue_details?IssueId=eq." + IssueId, updateAgile)
      .catch((err) => console.log(err))
      .then((res) => {
        // setOpenCreateEst(false);
        dispatch(HANDLE_ON_CHANGE({ name: 'openCreateEst', value: false }))
        // setRefresh(true);
        dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
        axios
          .post("agilesaas_issue_history", posthistory)
          .then((response) => { })
          .catch((error) => console.log(error));
        axios.post("agilesaas_issue_history", {
          IssueId: IssueId,
          ChangedBy: arr[0]?.ClientUserId,
          ChangedByDetails: {
            FN: arr[0]?.UserFirstname,
            LN: arr[0]?.UserLastname,
          },
          ChangedDate: moment()
            .utcOffset("+05:30")
            .format("YYYY-MM-DDTHH:mm:ss"),
          UpdatedField: "Kanban Changes",
          UpdatedFrom: "RFD",
          UpdatedTo: "DEV",
          UpdateSequence: 1,
          IsActive: "Y",
          Status: null,
          ClientId: clientid,
          ProjectId: projectCode,
        });
        axios
          .post("agilesaas_storypoint_estimation ", Sppostdata1)
          .catch((err) => console.log(err))
          .then((res) => {
            // console.log(res);
          });
        toast.success(`Original Estimation Submited`, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          draggable: true,
        });
        let _title = FilteredData?.filter((e) => e.IssueId == IssueId)
          ?.map((s) => s.IssueTitle)
          .toString();
        let asig = FilteredData?.filter((e) => e.IssueId == IssueId)
          ?.map(
            (s) => s?.AssignedToDetails?.FN + " " + s?.AssignedToDetails?.LN
          )
          .toString();
        let requrireddata = {
          ClientUserId: arr?.[0]?.ClientUserId,
          ClientId: clientid,
          ClientDetails: {
            Company: userorg?.Company,
            ContactName: userorg?.ContactName,
            ContactDesignation: userorg?.ContactDesignation,
            ContactEmail: userorg?.ContactEmail,
            ContactPhone: userorg?.ContactPhone,
          },
          DepartmentDetails: arr?.[0]?.DepartmentDetails,
          DepartmentCode: null,
          TentantId: "",
          FromSenderId: "no-reply@yozytech.com",
          emailnotify: false,
          webnotify: true,
          ToEmailIds: {
            emailids: arr[0].ClientDetails.ContactEmail,
          },
          CcEmailIds: {
            emailids: ['agileteam@yozytech.com'],
          },
          BccEmailIds: "",
          EmailType: "",
          ProductDetails: {
            ProdId: "11004",
            ProdName: "KANBAN",
            Domain: "KANBAN.yozytech.com",
          },
          EmailSubject: "Estimation",
          IsSuccess: "Y",
          IsPartiallyFailed: " ",
          SendRetryHistory: "",
          IsActive: " ",
          Status: "",
          EmailTemplateName: "",
          EmailTemplateVariables: {
            xxasignbyxx: "",
            xxassigndatexx: "",
            xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
            xxprjdisxx: "",
            xxprjnamexx: "",
            xxstoryNamexx: "",
            xxprjpercenxx: "",
            xxprjstartdatexx: "",
            xxprjenddataexx: "",
            xxteamnamexx: "",
            xxsprintxx: "",
            xxmanagernamexx: userorg?.ContactName,
            xxcompanyname: userorg?.Company,
            xxmanagerdesigxx: "Scrum Master",
            xxgmailxx: userorg?.ContactEmail,
            xxphnoxx: userorg?.ContactPhone,
            webappdescri: `Estimated ${OriginalEst.OriginalEstimate} days for ${_title} - (${IssueId}) by ${asig} `,
          },
        };
        axios({
          url: "https://api.devozy.ai/api/agile/email",
          method: "post",
          data: requrireddata,
          headers: {
            origin: "https://kanban.yozytech.com",
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            console.log(res.data, "mailsend");
          })
          .catch((err) => {
            console.log(err);
          });
        setSubmitCheck(false);
      });
  };
  return (
    <Dialog
      open={openCreateEst}
      onClose={() => {
        if (typeof onClose === "function") {
          handleClose();
        }
      }}
    // onBackdropClick="false"
    >
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} fontSize="15px" className="fontdesign">
              Alert Box
            </Box>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </div>
      </DialogTitle>

      <DialogContent>
        <Row className="pl-3 pb-3">
          <label className="fontdesign">Please Enter Original-Estimate</label>
        </Row>
        <Row>
          <Col>
            <TextField
              variant="outlined"
              label="Original-Estimate"
              name="OriginalEstimate"
              size="small"
              className="pb-3"
              onChange={(e) =>
                setOriginalEst({ [e.target.name]: e.target.value })
              }
            />
          </Col>
          <Col>
            <Button
              variant="contained"
              color="primary"
              component="span"
              className="Card_Button_Color_Approve btn-greeng"
              style={{
                fontSize: 14,
                padding: 9,
                width: 100,
                height: 40,
                borderRadius: 8,
                backgroundColor: "#5250E5",
                color: "#fff",
                border: "0px solid #5A88F1",
                textAlign: "center",
                textTransform: "none",
              }}
              onClick={() => {
                OriginalEstsubmit();
              }}
              disabled={checkSubmit}
            >
              {checkSubmit ? (
                <span
                  className="spinner-border"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
                ></span>
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
}
