const moment = require("moment");

var fadeEffect = 0;

const utils = {
  getBase64: (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  },
  fadeEffect: 0,
  // Method to show and fade-out alert element
  fadeOutAlert: () => {
    var element = document.getElementById("alertMessage");
    element.classList.remove("d-none");
    function fadeOutEffect(func) {
      var fadeTarget = document.getElementById("alertMessage");
      if (fadeEffect) {
        clearInterval(fadeEffect);
        // setting opacity 2 because alert need to be steady upto 1 for readability
        fadeTarget.style.opacity = 2;
      }
      fadeEffect = setInterval(function () {
        if (!fadeTarget.style.opacity) {
          fadeTarget.style.opacity = 2;
        }
        if (fadeTarget.style.opacity > 0.05) {
          fadeTarget.style.opacity -= 0.03;
        } else {
          clearInterval(fadeEffect);
          fadeTarget.style.opacity = "";
          func();
        }
      }, 100);
    }
    fadeOutEffect(() => {
      element.classList.add("d-none");
    });
  },

  htmlDecode: (input) => {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  },

  // Method to set, get and delete cookies

  setCookie: (name, value, days) => {
    // Encode value in order to escape semicolons, commas, and whitespace
    let cookie = name + "=" + value;
    days = days || 1;
    // Sets the expires attribute so that the cookie expires after the mentioned day
    let d = new Date();
    // d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    d.setTime(d.getTime() + 3 * 60 * 60 * 1000);
    // d.setTime(d.getTime() + 1 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    // cookie += ";" + expires + ";path=/";
    cookie += ";" + expires + ";path=/;domain=.devozy.ai";
    document.cookie = cookie;
  },
  calculateBusinessMinutes: (
    startDate,
    endDate,
    shiftStartHour,
    shiftEndHour,
    holidays = []
  ) => {
    const oneMinute = 60 * 1000;
    let currentDate = new Date(startDate);
    let minutes = 0;
    while (currentDate < endDate) {
      const dayOfWeek = currentDate.getDay();
      const currentHour = currentDate.getHours();
      // Check if the current day is a weekday (Monday to Friday)
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        // Check if the current time is within the specified shift hours
        if (currentHour >= shiftStartHour && currentHour < shiftEndHour) {
          // Check if the current date is not a holiday
          if (
            !holidays.some(
              (holiday) => holiday.getTime() === currentDate.getTime()
            )
          ) {
            minutes += 1;
          }
        }
      }
      currentDate.setTime(currentDate.getTime() + oneMinute);
    }
    return minutes;
  },
  getCookie: (name) => {
    // Split cookie string and get all individual name=value pairs in an array
    let cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].trim();

      /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
      if (cookiePair.indexOf(name) === 0)
        return cookiePair.substring(name.length + 1, cookiePair.length);
    }

    // Return empty string if not found
    return "";
  },

  deleteCookie: (name) => {
    this.setCookie(name, "", -1);
  },

  // Set localstorage
  setLocal: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  // Get localstorage
  getLocal: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },
  // Remove localstorage
  removeLocal: (key) => {
    localStorage.removeItem(key);
  },

  setUserPage: (pageData) => {
    let userPageObj = JSON.parse(localStorage.getItem("user-page")) || {};
    userPageObj["current-page"] = pageData.name;
    userPageObj[pageData.name] = pageData.data;
    localStorage.setItem("user-page", JSON.stringify(userPageObj));
  },

  // Alternate approach of Array.findIndex() : IE<11 compatibility
  findIndex: (leftSideArray, rightSideObject) => {
    if (!leftSideArray && leftSideArray.length === 0) {
      return -1;
    }
    if (!rightSideObject && rightSideObject.length === 0) {
      return -1;
    }
    for (let i = 0; i < leftSideArray.length; i++) {
      if (rightSideObject.app_id === leftSideArray[i].app_id) {
        return i;
      }
    }
    return -1;
  },

  // Capitalize 1st letter of word
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  // Validate email string
  validateEmail: (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  // Difference between 2 dates in months
  // Date parameters format: MM-DD-YYYY
  diffInMonths(farDate, nearDate) {
    let far = moment(farDate, "MM-DD-YYYY");
    let near = nearDate ? moment(nearDate, "MM-DD-YYYY") : moment();
    return near.diff(far, "months", true);
  },

  // convert date string to DD-MM-YYYY format
  formatDateToDDMMYYYY(dateString) {
    if (moment(dateString).isValid()) {
      return moment(dateString).format("DD-MM-YYYY"); // date in DD-MM-YYYY format
    }
    return "Invalid Date";
  },

  // return date in Jan 22 2019, 07:12 PM format
  formatDateValue(dateString) {
    let local = moment(dateString).toISOString(true);
    return moment(local).format("MMM DD YYYY, hh:mm A");
  },
  formatDateTimeValue(dateString) {
    let local = moment(dateString).toISOString(true);
    return moment(local).format("MMM DD YYYY, hh:mm A");
  },
  formatTimeValue(dateString) {
    let local = moment(dateString).toISOString(true);
    return moment(local).format(" hh:mm A");
  },

  // Sort array of object based on key
  compareValues(key, subKey, order = "asc") {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      var varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      var varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      var aValue = "";
      var bValue = "";
      if (subKey) {
        subKey.map((k) => {
          aValue += " " + varA[k].toUpperCase();
          bValue += " " + varB[k].toUpperCase();
          return "";
        });
        varA = aValue;
        varB = bValue;
      }

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  },

  getTimeDifference(smaller, greater) {
    let smallerDate = new Date(smaller);
    if (smallerDate === "Invalid Date") {
      return "-";
    } else {
      let greaterDate = new Date();
      if (greater) {
        greaterDate = new Date(greater);
      }
      let sec = 0;
      let mins = 0;
      let hrs = 0;
      let days = 0;
      let weeks = 0;
      let months = 0;
      let years = 0;
      sec = Math.floor((greaterDate - smallerDate) / 1000);
      if (sec >= 60) {
        mins = Math.floor(sec / 60);
        if (mins >= 60) {
          hrs = Math.floor(mins / 60);
          if (hrs >= 24) {
            days = Math.floor(hrs / 24);
            if (days >= 7) {
              weeks = Math.floor(days / 7);
              if (days >= 30) {
                months = Math.floor(days / 30);
                if (days >= 365) {
                  years = Math.floor(days / 365);
                  return years + " year(s)";
                } else {
                  return months + " month(s)";
                }
              } else {
                return weeks + " week(s)";
              }
            } else {
              return days + " day(s)";
            }
          } else {
            return hrs + " hr(s)";
          }
        } else {
          return mins + " min(s)";
        }
      } else {
        return isNaN(sec) ? "-" : sec + " sec(s)";
      }
    }
  },

  // throttle function
  // Allow function to run at most 1 time per limit value
  doThrottle(callback, limit) {
    var wait = false; // Initially, we're not waiting
    return function () {
      // We return a throttled function
      if (!wait) {
        // If we're not waiting
        callback.call(); // Execute users function
        wait = true; // Prevent future invocations
        setTimeout(function () {
          // After a period of time
          wait = false; // And allow future invocations
        }, limit);
      }
    };
  },
};

export default utils;
