import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import ProgressDetailsUpdate from "./ProgressDetailsUpdate";
import Mainprocesstab from "./Mainprocesstab";
import DetailsPanel from "./DetailsPanel";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { HANDLE_ON_CHANGE } from "../../../redux/CommonInitialsate";

function UserWorkLogData({
  // EmpDropDownData,
  // selectedFile,
  // setSelectedFile,
  // KanbanBoardData,
  // SelectedCardId,
  // KBRefresh,
  // Assetoptions,
  // setSelectedCardId,
  // stage,
  // setRefresh,
  // setStage,
  // assignedTo,
  // setAssignedTo,
  // createdBy,
  // setCreatedBy,
  // epic,
  // setEpic,
  // priority,
  // setPriority,
  // originalEstimate,
  // checkChange,
  // setCheckChange,
  // Data,
  // epicIssueId,
  // stageCode,
  // setStageCode,
  // stageName,
  // assignedId,
  // setAssignedId,
  // setStageName,
  // releaseLabel,
  // setReleaseLabel,
  // releaseValue,
  // setReleaseValue,
  // desc,
  // description,
  // setDescription,
  // releases,
  // setReleases,
  // setReleaseUpdate,
  // Bfopen,
  // setBfopen,
  // selectedCard,
  // setselectedcard,
  // IssueId,
  // setIssueId,
  // openCreateEst,
  // setOpenCreateEst,
  // uatDropdown,
  // createdId,
  // UatTo,
  // setUatTo,
  // UatId,
  // setUatId,
  // displaySubTask,
  // subTaskApi,
  // UATtesterD,
  // setUATtesterD,
  // UATchecklistD,
  // setUATchecklistD,
  // progressdata,
  // setProgressData,
  // projectRole,
  // SEpic,
  // setSEpic,
  // attachmentReference,
  // cmodule,
  // setCmodule
}) {
  // const [openAll, setOpenAll] = useState(false);
  // const [progress, setProgress] = useState(true);
  // const [uat, setUat] = useState(false);
  // const [History, setHistory] = useState(false);
  // const [notes, setNotes] = useState(false);
  const dispatch = useDispatch()
  const { allImage, KanbanBoardData, SelectedCardId, projectRole, SEpic, checkChange, assignedTo, openAll, progress, uat, History, notes } = useSelector((state) => state.user);
  function Notes() {
    // setOpenAll(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'openAll', value: false }))
    // setProgress(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'progress', value: false }))
    // setUat(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'uat', value: false }))
    // setHistory(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'History', value: false }))
    // setNotes(true);
    dispatch(HANDLE_ON_CHANGE({ name: 'notes', value: true }))
  }
  function All_progress() {
    // setOpenAll(true);
    dispatch(HANDLE_ON_CHANGE({ name: 'openAll', value: true }))
    // setProgress(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'progress', value: false }))
    // setUat(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'uat', value: false }))
    // setHistory(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'History', value: false }))
    // setNotes(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'notes', value: false }))
  }

  function Progress_second() {
    // setOpenAll(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'openAll', value: false }))
    // setProgress(true);
    dispatch(HANDLE_ON_CHANGE({ name: 'progress', value: true }))
    // setUat(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'uat', value: false }))
    // setHistory(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'History', value: false }))
    // setNotes(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'notes', value: false }))
  }

  function Uat() {
    // setUat(true);
    dispatch(HANDLE_ON_CHANGE({ name: 'uat', value: true }))
    // setOpenAll(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'openAll', value: false }))
    // setProgress(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'progress', value: false }))
    // setHistory(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'History', value: false }))
    // setNotes(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'notes', value: false }))
  }

  function HistoryOpen() {
    // setHistory(true);
    dispatch(HANDLE_ON_CHANGE({ name: 'History', value: true }))
    // setUat(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'uat', value: false }))
    // setOpenAll(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'openAll', value: false }))
    // setProgress(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'progress', value: false }))
    // setNotes(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'notes', value: false }))
  }

  return (
    <div className="all_progress_box">
      <Row>
        <Col xs lg="8" className="firstprogress_box">
          <DetailsPanel
          // EmpDropDownData={EmpDropDownData}
          // attachmentReference={attachmentReference}
          // selectedFile={selectedFile}
          // setSelectedFile={setSelectedFile}
          // projectRole={projectRole}
          // KanbanBoardData={KanbanBoardData}
          // SelectedCardId={SelectedCardId}
          // KBRefresh={KBRefresh}
          // Data={Data}
          // desc={desc}
          // epicIssueId={epicIssueId}
          // setSelectedCardId={setSelectedCardId}
          // setAssignedTo={setAssignedTo}
          // setPriority={setPriority}
          // setCheckChange={setCheckChange}
          // setStageName={setStageName}
          // setStageCode={setStageCode}
          // setReleaseLabel={setReleaseLabel}
          // setReleaseValue={setReleaseValue}
          // setAssignedId={setAssignedId}
          // description={description}
          // setDescription={setDescription}
          // releases={releases}
          // setReleases={setReleases}
          // createdId={createdId}
          // setUatTo={setUatTo}
          // setUatId={setUatId}
          // UatTo={UatTo}
          // UatId={UatId}
          // displaySubTask={displaySubTask}
          // subTaskApi={subTaskApi}
          // setRefresh={setRefresh}
          />
          <div className="userworklog">
            <div className="d-flex">
              <button
                type="button"
                onClick={All_progress}
                className={
                  openAll
                    ? "Progress_button_change fontdesign"
                    : "Progress_button fontdesign"
                }
              >
                Story Point & Estimation
              </button>
              <button
                type="button"
                className={
                  progress
                    ? "Progress_button_change fontdesign"
                    : "Progress_button fontdesign"
                }
                onClick={Progress_second}
              >
                Progress Update
              </button>
              <button
                type="button"
                onClick={Notes}
                className={
                  notes
                    ? "Progress_button_change fontdesign"
                    : "Progress_button fontdesign"
                }
              >
                Notes
              </button>
              <button
                type="button"
                onClick={HistoryOpen}
                className={
                  History
                    ? "Progress_button_change fontdesign"
                    : "Progress_button fontdesign"
                }
              >
                History
              </button>

              <button
                type="button"
                onClick={Uat}
                className={
                  uat
                    ? "Progress_button_change fontdesign"
                    : "Progress_button fontdesign"
                }
              >
                UAT Closure
              </button>
            </div>
            <div>
              <Mainprocesstab
              // projectRole={projectRole}
              // SelectedCardId={SelectedCardId}
              // openAll={openAll}
              // progress={progress}
              // Uat={uat}
              // History={History}
              // KanbanBoardData={KanbanBoardData}
              // notes={notes}
              // KBRefresh={KBRefresh}
              // setRefresh={setRefresh}
              // progressdata={progressdata}
              // setProgressData={setProgressData}
              />
            </div>
          </div>
        </Col>

        <Col xs lg="4">
          <ProgressDetailsUpdate
          // projectRole={projectRole}
          // KanbanBoardData={KanbanBoardData}
          // SelectedCardId={SelectedCardId}
          // KBRefresh={KBRefresh}
          // Assetoptions={Assetoptions}
          // setSelectedCardId={setSelectedCardId}
          // stage={stage}
          // setStage={setStage}
          // assignedTo={assignedTo}
          // setAssignedTo={setAssignedTo}
          // createdBy={createdBy}
          // setCreatedBy={setCreatedBy}
          // epic={epic}
          // setEpic={setEpic}
          // priority={priority}
          // setPriority={setPriority}
          // originalEstimate={originalEstimate}
          // checkChange={checkChange}
          // setCheckChange={setCheckChange}
          // Data={Data}
          // epicIssueId={epicIssueId}
          // stageCode={stageCode}
          // stageName={stageName}
          // setStageName={setStageName}
          // setStageCode={setStageCode}
          // releaseLabel={releaseLabel}
          // setReleaseLabel={setReleaseLabel}
          // releaseValue={releaseValue}
          // setReleaseValue={setReleaseValue}
          // assignedId={assignedId}
          // setAssignedId={setAssignedId}
          // description={description}
          // setDescription={setDescription}
          // releases={releases}
          // setReleases={setReleases}
          // setReleaseUpdate={setReleaseUpdate}
          // Bfopen={Bfopen}
          // setBfopen={setBfopen}
          // selectedCard={selectedCard}
          // setselectedcard={setselectedcard}
          // IssueId={IssueId}
          // setIssueId={setIssueId}
          // openCreateEst={openCreateEst}
          // setOpenCreateEst={setOpenCreateEst}
          // uatDropdown={uatDropdown}
          // createdId={createdId}
          // UatTo={UatTo}
          // setUatTo={setUatTo}
          // UatId={UatId}
          // setUatId={setUatId}
          // UATtesterD={UATtesterD}
          // setUATtesterD={setUATtesterD}
          // UATchecklistD={UATchecklistD}
          // setUATchecklistD={setUATchecklistD}
          // progressdata={progressdata}
          // setProgressData={setProgressData}
          // SEpic={SEpic}
          // setSEpic={setSEpic}
          // setRefresh={setRefresh}
          // cmodule={cmodule}
          // setCmodule={setCmodule}
          />
        </Col>
      </Row>
    </div>
  );
}

export default UserWorkLogData;
