import axios from "../../../axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Col, Row } from "reactstrap";
import Toolbar, { modules, formats } from "../Toolbar";
import "./Progressupdate.css";
import Progressdataform from "./Progressdataform";
import { styled } from "@material-ui/styles";
import { Avatar } from "@material-ui/core";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import Unassigned from "../../../assets/images/avatar.png";
import { HANDLE_ON_CHANGE } from "../../../redux/CommonInitialsate";

function Progressmain(
  {
    // KanbanBoardData,
    // SelectedCardId,
    // KBRefresh,
    // setRefresh,
    // progressdata,
    // setProgressData,
  }
) {
  const dispatch = useDispatch();
  let Empid = JSON.parse(localStorage.getItem("Empdetails"));
  let clientd = JSON.parse(localStorage.getItem("clientid"));
  const date = moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss");

  const BootstrapButton = styled(Button)({
    textTransform: "none",
    fontSize: 13,
    padding: "6px 12px",
    margin: 10,
    border: "0px solid",
  });

  const [editvalue, setEditvalue] = useState("");
  const [count, setcount] = useState(1);
  const [percentagecount, setPercentageCount] = useState();
  const [apidata, setApidata] = useState([]);
  const [formopen, setFormopen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notedvaliate, setNotevalidate] = useState(false);
  const [percentagevalidate, setPercentagevalidate] = useState(false);
  const [originalestimatedata, setOriginalestimatedata] = useState([]);
  const {
    allImage,
    KanbanBoardData,
    projectCode,
    selectedCard1,
    SelectedCardId,
  } = useSelector((state) => state.user);
  const [checksubmit, setCheckSubmit] = useState(false);

  // api refresh function
  function Reupdatedata() {
    // console.log(SelectedCardId, 'selectedcard')
    // axios
    //   .get("agilesaas_issue_progress?IssueId=eq." + SelectedCardId)
    //   .then((res) => {
    //     setApidata(res.data);
    //     setLoading(false);
    //   });

    axios
      .get(
        `agilesaas_issue_details?select=IssueId,LinkToIssues,IssueTitle,ExceptiontoSkipSPEstimation,IsActive,ParentIssue,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,IssueType,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(IssueProgressId,IssueId,UpdatedBy,UpdatedByDetails,UpdatedDate,Notes,UpdateSequence,Attachement,EffortBurnt,EffortBurntUnit,PercentageProgressed,RiskofDelivery,HoursPerDayToComplete,IsActive,Status,EffortPending,EffortRemaining,ClientId,ProjectId)&IssueId=eq.${SelectedCardId}&order=CreatedDate.desc&IsActive=eq.Y`
      )
      .then((res) => {
        let pddata = res.data?.[0]?.AllProgress;
        setApidata(pddata);
        setLoading(false);
        setOriginalestimatedata(res.data);
      });
  }

  // refresh form data
  function Resetdata() {
    setEditvalue("");
    setcount(1);
    setPercentageCount();
  }

  // old data refresh after submit new data
  useEffect(() => {
    Reupdatedata();
  }, []);

  // for next component
  let FINALEUSER_DATA = KanbanBoardData.filter((w) =>
    w.IssueId == SelectedCardId ? w : 0
  );

  // user details filter
  let USERS_NAME = KanbanBoardData?.filter((w) =>
    w.IssueId == SelectedCardId ? w : 0
  ).map((e) => [
    e?.AssignedToDetails?.FN,
    e?.AssignedToDetails?.LN,
    e?.IssueId,
    e?.AssignedTo,
  ]);

  // users detils
  let USER_FULLNAME = USERS_NAME?.[0]?.[0] ? USERS_NAME?.[0]?.[0] : "";
  let USER_LASTNAME = USERS_NAME?.[0]?.[1] ? USERS_NAME?.[0]?.[1] : "";
  let FNAME = USER_FULLNAME?.[0];
  let LNAME = USER_LASTNAME?.[0];
  let ISSUID = USERS_NAME?.[0]?.[2] ? USERS_NAME?.[0]?.[2] : SelectedCardId;
  let ADDIGNID = USERS_NAME?.[0]?.[3] ? USERS_NAME?.[0]?.[3] : "";

  // console.log(USERS_NAME, "users name")

  // original estimate
  const EffortPending =
    originalestimatedata.length != 0 &&
      originalestimatedata?.[0].OriginalEstimate != null
      ? originalestimatedata?.[0].OriginalEstimate
      : 0;

  // UPDATED_NOTES_APIDATA = seleceted card Updated full api data  ;
  const UPDATED_NOTES_APIDATA =
    !loading && apidata.length != 0
      ? apidata
        .sort((a, b) =>
          a.IssueProgressId > b.IssueProgressId
            ? 1
            : a.IssueProgressId < b.IssueProgressId
              ? -1
              : null
        )
        .map((v) => [
          v.EffortRemaining,
          v.EffortBurnt,
          v.PercentageProgressed,
        ])
      : "";

  // FINALE_OLD_DATA = last data of apidata
  const FINALE_OLD_DATA =
    UPDATED_NOTES_APIDATA.length != 0
      ? UPDATED_NOTES_APIDATA[UPDATED_NOTES_APIDATA.length - 1]
      : [0];
  console.log(count)
  // Submit form
  function Notesdata(e) {
    e.preventDefault();
    setNotevalidate(false);
    console.log(EffortPending == 0, parseInt(count))
    if (EffortPending == 0 || (parseInt(count) <= 0) || count == '') {
      return toast.error(EffortPending == 0 ? "Original estimate not defind" : "Please enter effort burnt");
    }

    try {
      if (editvalue !== "" && count !== "" && percentagecount !== "") {
        //  daily percentage Calculation
        setCheckSubmit(true);
        let final_percentage =
          percentagecount == undefined &&
            FINALE_OLD_DATA.length != 0 &&
            FINALE_OLD_DATA[2] != null
            ? parseInt(FINALE_OLD_DATA[2])
            : percentagecount == undefined && FINALE_OLD_DATA.length != 0
              ? parseInt(10)
              : parseInt(percentagecount);

        // effort reminig calcluation
        let effort_remining =
          FINALE_OLD_DATA.length == 1 && FINALE_OLD_DATA?.[0] == 0
            ? EffortPending - parseInt(count)
            : FINALE_OLD_DATA?.[0] - parseInt(count);

        // All Effort Burnt Calculation
        let All_burnt =
          UPDATED_NOTES_APIDATA.length !== 0
            ? UPDATED_NOTES_APIDATA.reduce(
              (j, f) => parseInt(j) + f[1],
              parseInt(count)
            )
            : parseInt(count);

        // Actual Percentage burnt Effort days
        let worked_days =
          UPDATED_NOTES_APIDATA.length !== 0
            ? (UPDATED_NOTES_APIDATA.reduce(
              (x, f) => parseInt(x) + f[1],
              parseInt(count)
            ) /
              EffortPending) *
            100
            : (parseInt(count) / parseInt(EffortPending)) * 100;

        let Todal_actul_percentage = worked_days;
        let New_lag = Todal_actul_percentage - final_percentage;
        let New_lag_days = (New_lag * EffortPending) / 100;
        let New_lag_in_hours = New_lag_days * 8;
        let New_Actual_remaining_hours = EffortPending * 8 - All_burnt * 8;
        let New_total_catchup_hours =
          New_Actual_remaining_hours + New_lag_in_hours;
        let New_catch_of_hours =
          FINALE_OLD_DATA?.[0] < 0
            ? 0
            : New_total_catchup_hours / effort_remining;

        // risk_of_delivery Calculation two
        let risk_of_delivery =
          EffortPending < FINALE_OLD_DATA?.[0] ||
            EffortPending < All_burnt ||
            New_catch_of_hours.toFixed(0) > 10
            ? "R"
            : New_catch_of_hours.toFixed(0) <= 8 &&
              New_catch_of_hours.toFixed(0) > 0
              ? "G"
              : New_catch_of_hours.toFixed(0) <= 10 &&
                New_catch_of_hours.toFixed(0) >= 8
                ? "A"
                : "G";

        let updatenote = {
          IssueId: ISSUID,
          UpdatedBy: ADDIGNID,
          UpdatedByDetails: {
            FN: USER_FULLNAME,
            LN: USER_LASTNAME,
          },
          UpdatedDate: date,
          Notes: editvalue,
          UpdateSequence: 1,
          Attachement: null,
          EffortBurnt: parseInt(count),
          EffortBurntUnit: "M",
          PercentageProgressed: final_percentage,
          RiskofDelivery: risk_of_delivery,
          HoursPerDayToComplete:
            New_catch_of_hours.toFixed(0) == "Infinity" ||
              New_catch_of_hours.toFixed(0) == -0 ||
              New_catch_of_hours.toFixed(0) < 0 ||
              New_catch_of_hours.toFixed(0) == "NaN"
              ? 0
              : New_catch_of_hours.toFixed(0),
          IsActive: "Y",
          Status: null,
          EffortPending: EffortPending,
          EffortRemaining: effort_remining,
          ClientId: clientd,
          ProjectId: projectCode,
        };

        axios
          .post(
            "agilesaas_issue_progress?IssueId=eq." + SelectedCardId,
            updatenote
          )
          .then((res) => {
            toast.success("Progress updated");
            Resetdata();
            Reupdatedata();
            let historydata = {
              IssueId: ISSUID,
              ChangedBy: ADDIGNID,
              ChangedByDetails: {
                FN: USER_FULLNAME,
                LN: USER_LASTNAME,
              },
              ChangedDate: moment().format("YYYY-MM-DDThh:mm:ss"),
              UpdatedField: "PercentageProgressed",
              UpdatedFrom: null,
              UpdatedTo: final_percentage,
              UpdateSequence: 1,
              IsActive: "Y",
              Status: null,
              ClientId: clientd,
              ProjectId: projectCode,
            };
            axios
              .post("agilesaas_issue_history", historydata)
              .then((res) => {
                console.log(res);
                // alert("history updated");
              })
              .catch((err) => console.log(err));
            axios
              .get(
                `agilesaas_issue_progress?select=RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed&ProjectId=eq.${projectCode}`
              )
              .then((response) => {
                const latestEntries = {};
                response.data.forEach((entry) => {
                  if (
                    !latestEntries[entry.IssueId] ||
                    entry.UpdatedDate > latestEntries[entry.IssueId].UpdatedDate
                  ) {
                    latestEntries[entry.IssueId] = entry;
                  }
                });

                const finalData = Object.values(latestEntries);
                // setData(finalData);
                // setProgressData(finalData);
                dispatch(
                  HANDLE_ON_CHANGE({ name: "progressdata", value: finalData })
                );
                // console.log(finalData, 'finalData')
                // console.log(response.data)
              })
              .catch((e) => {
                console.log(e, "error");
              });
            // KBRefresh()
            // console.log(res);
            setCheckSubmit(false);
            // setRefresh(true);
            dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
          });
      } else if (editvalue === "") {
        setNotevalidate(true);
      } else if (percentagecount === "") {
        setPercentagevalidate(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="Progressmain">
      <div className="d-flex mt-3  flex-column">
        <div className="d-flex justify-content-start text-center">
          <Avatar
            className="progressName mr-2"
            src={
              allImage?.length > 0
                ? allImage?.filter((x) => x.teammemberid == ADDIGNID)?.[0]
                  ?.image
                : Unassigned
            }
          />
          {/* {FNAME + LNAME}</Avatar> */}

          <div className="d-flex align-items-center">
            <p
              className="Progress_update_user_name fontdesign mt-1"
              style={{ color: "black" }}
            >
              {USER_FULLNAME != "null" && USER_FULLNAME != null
                ? USER_FULLNAME
                : "Un Assigned"}
              {/* {console.log(USER_FULLNAME, "username")} */}
            </p>

            <p
              className="Progress_update_user_Date fontdesign"
              style={{ color: "black" }}
            >
              {moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD MMM yyyy")}
            </p>
          </div>
        </div>

        <div className="ml-3 w-100 p-1">
          {/* {console.log(selectedCard1, "selectedcard1")} */}
          {!formopen ? (
            <input
              className="form-control rounded ml-4 w-75"
              style={{ background: "#fff", border: "1px solid grey" }}
              placeholder="Update your progress..."
              disabled={
                ADDIGNID != Empid?.[0].ClientUserId ||
                selectedCard1?.CurrentStage?.[0]?.StageCode !== "DEV"
              }
              onClick={() => setFormopen(true)}
            />
          ) : null}
          {formopen ? (
            <div>
              <form className="mt-1">
                <Toolbar />

                <div className="d-flex flex-column">
                  <ReactQuill
                    className="quill"
                    theme="snow"
                    value={editvalue}
                    onChange={(e) => setEditvalue(e)}
                    modules={modules}
                    formats={formats}
                  />

                  {notedvaliate ? (
                    <p className="text-danger text-center fontdesign">
                      This Notes field is Required
                    </p>
                  ) : null}

                  <div className="d-flex  flex-column">
                    <Row className="mt-2 ml-5">
                      <Col xs={6} md={6} sm={6} className="d-flex">
                        <div className="d-flex">
                          <label
                            className="progress_update_lebel fontdesign"
                            style={{ color: "black" }}
                          >
                            Effort Pending :
                          </label>
                          <p
                            className="progress_update_count_box ml-5 fontdesign"
                            style={{ color: "black" }}
                          >
                            {EffortPending}
                          </p>
                        </div>
                      </Col>
                      <Col xs={6} sm={6} md={6} className="d-flex">
                        <div className="d-flex">
                          <label
                            className="progress_update_lebel fontdesign"
                            style={{ color: "black" }}
                          >
                            Effort Remaining :
                          </label>
                          <p
                            className="progress_update_count_box ml-5 fontdesign"
                            style={{ color: "black" }}
                          >
                            {FINALE_OLD_DATA?.[0]}
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2 ml-5">
                      <Col sm={6} xs={6} className="d-flex">
                        <label
                          className="progress_update_lebel fontdesign"
                          style={{ color: "black" }}
                        >
                          Work Percentage
                        </label>
                        <select
                          value={
                            percentagecount == undefined
                              ? FINALE_OLD_DATA[2]
                              : percentagecount
                          }
                          className="progress_update_select text-center border"
                          onChange={(e) => setPercentageCount(e.target.value)}
                        >
                          <option value="10">10%</option>
                          <option value="20">20%</option>
                          <option value="30">30%</option>
                          <option value="40">40%</option>
                          <option value="50">50%</option>
                          <option value="60">60%</option>
                          <option value="70">70%</option>
                          <option value="80">80%</option>
                          <option value="90">90%</option>
                          <option value="100">100%</option>
                        </select>
                        {percentagevalidate ? (
                          <p className="text-danger text-center">
                            This Notes field is Required
                          </p>
                        ) : null}
                      </Col>
                      <Col sm={6} xs={6} className="d-flex">
                        <label
                          className="progress_update_lebel fontdesign"
                          style={{ color: "black" }}
                        >
                          Effort Burnt
                        </label>
                        <div>
                          <input
                            className="progress_update_input text-center ml-5"
                            style={{ color: "black" }}
                            value={count}
                            type="number"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (!value.includes('.')) {
                                setcount(value);
                              }
                              // setcount(e.target.value);
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setcount(parseInt(count) + 1);
                            }}
                            className="progress_update_button"
                          >
                            +
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  className="mt-2"
                >
                  <Button
                    onClick={Resetdata}
                    style={{
                      fontSize: 14,
                      padding: 9,
                      width: 100,
                      height: 40,
                      borderRadius: 8,
                      marginRight: 14,
                      border: "2px solid #5250E5",
                      backgroundColor: "#fff",
                      color: "#5250E5",
                      textAlign: "center",
                      textTransform: "none",
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    style={{
                      fontSize: 14,
                      padding: 9,
                      width: 100,
                      height: 40,
                      borderRadius: 8,
                      backgroundColor: "#5250E5",
                      color: "#fff",
                      border: "0px solid #5A88F1",
                      textAlign: "center",
                      textTransform: "none",
                      marginRight: 24,
                    }}
                    disabled={checksubmit}
                    type="submit"
                    onClick={(e) => Notesdata(e)}
                  >
                    {checksubmit ? (
                      <span
                        className="spinner-border"
                        role="status"
                        aria-hidden="true"
                        style={{
                          color: "#fff",
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                      ></span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
                {/* <div className="d-flex justify-content-center ">
                  <BootstrapButton
                    className="buttonDesign11 Card_Button_Color_Approve btn-danger fontdesign"
                    variant="contained"
                    type="reset"
                    onClick={Resetdata}
                  >
                    Reset
                  </BootstrapButton>
                  <BootstrapButton
                    className="buttonDesign Card_Button_Color_Approve btn-greeng fontdesign"
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </BootstrapButton>
                </div> */}
              </form>
            </div>
          ) : null}
        </div>
      </div>
      <Progressdataform
        KanbanBoardData={KanbanBoardData}
        FirstName={FNAME}
        LastName={LNAME}
        apidata={apidata}
        fname={USER_FULLNAME}
        lname={USER_LASTNAME}
        date={date}
        // SelectedCardId={SelectedCardId}
        originalestimatedata={originalestimatedata}
        FINALEUSER_DATA={FINALEUSER_DATA}
        Reupdatedata={Reupdatedata}
      // KBRefresh={KBRefresh}
      // progressdata={progressdata}
      // setProgressData={setProgressData}
      />
    </div>
  );
}

export default Progressmain;
