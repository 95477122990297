import React, { useState, useEffect } from "react";
import "./BuildPlanner.css";
import BuildplannerManager from "./BuildplannerManager";
import BuildPlannerEmp from "./BuildPlannerEmp";
import Select from "react-select";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardLink,
} from "reactstrap";
import { Modal, Tab, Tabs } from "react-bootstrap";
import Clipboard from "react-clipboard.js";
import CloseIcon from "@material-ui/icons/Close";
import AntSwitch from "./Style";
import Deploy from "./Deploy";
import Schedule from "./Schedule";
import axios from "../../../axios";
import Cookies from "universal-cookie";
import Axios from "axios";
import url from "../devEnj.json";
import dropDownConstants from "../../../dropdown_constants";
import { useRef } from "react";
import { toast } from "react-toastify";
import { Avatar, Button, Divider } from "@mui/material";
import devozyaxios from "../../../devozyaxios"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { HANDLE_ON_CHANGE } from "../../../redux/CommonInitialsate";
// import Axios from 'axios'

const BuildPlanner = ({
  // EmpId,
  // status,
  // setStatus,
  // projectCode,
  // setbuild,
  // setProjectTitle,
  // projectTitle,
  // token,
  // text,
  // email
}) => {
  const {
    projectCode, status, projectRole
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const empDetails = JSON.parse(localStorage.getItem("Empdetails"));
  const [projectDetails, setProjectDetails] = useState([]);
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [deployPopup, setDeployPopup] = useState(false);
  const [applicationsArr, setApplicationsArr] = useState([]);
  const [linkProject, setLinkProject] = useState([]);
  const [linkValidation, setLinkValidation] = useState(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [project, setProject] = useState([])
  const [projectValue, setProjectValue] = useState()
  const [selectedData, setSelectedData] = useState([])
  const [loading, setLoading] = useState(true);
  const [linkanother, setLinkanother] = useState(false)
  const role = localStorage.getItem("devozyrole")?.toLowerCase()
  let email = localStorage.getItem("userdata") == undefined ? "" : JSON.parse(localStorage.getItem("userdata"))?.login;


  const cookies = new Cookies();


  const company = empDetails?.[0]?.ClientDetails?.Company.replace(' ', '')
  const tenantId = `devozy_${company}_${empDetails?.[0]?.ClientId}`
  const getProject = (code) => {
    Axios({
      url:
        // 'https://ydpapi.yozy.in/api/v1/devozy/projects/PRJ1000001/summary',
        `${url.server}/projects/${code}/summary`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        email: email,
        tenantid: tenantId,
        Authorization: "Bearer " + cookies.get("token"),
      },
      method: "get",
    })
      // axios.get(`https://ydpapi.yozy.in/api/v1/devozy/projects/PRJ1000001/summary`)
      .then((result) => {
        let maindata = result.data?.map((f) => {
          let data = dropDownConstants.EnvCards.find(
            (e) => e.name == f?.proj_data?.ProjType
          );
          if (data) {
            return {
              ...f,
              id: data.id,
            };
          }
        })
          .sort((a, b) => a.id - b.id);
        // console.log(maindata, "maindataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
        const final = maindata?.map((result) => {
          let details = [];
          let projectdata = {
            ProjHealth: result.proj_data?.ProjHealth,
            projCode: result.proj_data?.ProjCode,
            projName: result.proj_data?.ProjName,
            ProjectURL: result.proj_data?.ProjectURL,
            ProjectType: result?.proj_data?.ProjType
              ? result.proj_data.ProjType
              : "",
            projVersion: result.version_data?.sort((a, b) => new Date(b.id) - new Date(a.id))?.map((e) => e.version)[0],
            // result.version_data
            //   .sort((a, b) => (a.version < b.version ? 1 : -1))
            //   .map((e) => e.version)[0],
            AllVersion: result.version_data,
            technology: result.proj_data?.TechStack[0],
            deploymentDetails: result.version_data?.sort((a, b) => new Date(b.id) - new Date(a.id))?.slice(0, 3)
          };
          let ShData = result.Schdata ? result.Schdata : null;
          result.env_data?.map((envData) => {
            // get version of current environment and add to envData object
            let stageVersionObj = result.version_data.find(
              (vObj) => vObj._id === envData.Env
            );
            let stageVersion = "N/A";
            if (stageVersionObj) {
              stageVersion = stageVersionObj.version;
            }
            let respData = {
              ...envData,
              projectdata,
              ShData,
              version: stageVersion,
            };

            if (envData.Env.toLowerCase() === "integration") {
              details.unshift(respData);
            } else {
              details.push(respData);
            }
            return "";
          });
          return {
            envCardDetail: details,
            projectdata: projectdata,
            ProjectSchData: ShData,
          };
        });

        let data = {
          envprojectcode: code,
          MasterProject: final,
          showInviteIcon: "",
          // Utils.hasComponentAccess(
          //   "allocate_app",
          //   localUserData.components,
          //   projectRoleObj
          // ),
          canVisitDetailPage: "",
          // Utils.hasComponentAccess(
          //   "/application/details",
          //   localUserData.components,
          //   projectRoleObj
          // ),
          canDeploy: "",
          // Utils.hasComponentAccess(
          //   "addon_deploy",
          //   localUserData.components,
          //   projectRoleObj
          // ),
        };
        setProjectDetails(data);
      })
      .catch((err) => {
        console.log("Get error: " + err);
      });
  }

  // console.log(projectValue, projectDetails, "pppppppppppppppp")
  if (project.length != 0 && projectDetails.length == 0) {
    getProject(projectValue)
    localStorage.setItem('loading', 0)
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FFF',
      border: state.isFocused ? '2px solid rgb(82, 80, 229)' : '1px solid rgba(0, 0, 0, 0.10)',
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };

  const customStyles1 = {
    control: (base, state) => ({
      ...base,
      background: '#FFF',
      border: state.isFocused ? '2px solid rgb(82, 80, 229)' : '1px solid rgba(0, 0, 0, 0.10)',
      borderRadius: 8,
      height: 38,
      // background: "#f3f3ff",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      width: `${Math.max(
        ...projectOption?.map((option) => option?.label?.length * 10)
      )}px`, // Adjust the multiplier based on your content
      minWidth: "100%",
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };

  const refresh = () => {
    axios
      .get("agilesaas_devozy_project_mapping")
      .then((res) => {
        const mappingProject = res.data;
        setLoading(false)
        const displayData = mappingProject?.length > 0 && mappingProject?.filter((e) => e.AgileProjId == projectCode)
        // console.log(displayData?.[0]?.DevozyProjectDetails?.[0]?.ProjCode, "dddddddddddddddd")
        if (displayData.length != 0) {
          // setProjectTitle(displayData?.[0]?.DevozyProjectDetails?.[0]?.ProjDesc)
          dispatch(HANDLE_ON_CHANGE({ name: 'projectTitle', value: displayData?.[0]?.DevozyProjectDetails?.[0]?.ProjDesc }))
          setProject(displayData)
          setProjectValue(displayData?.[0]?.DevozyProjectDetails?.[0]?.ProjCode)
          getProject(displayData?.[0]?.DevozyProjectDetails?.[0]?.ProjCode)
        }
        else {
          // setProjectTitle(null)
          dispatch(HANDLE_ON_CHANGE({ name: 'projectTitle', value: null }))
        }

        Axios({
          method: "GET",
          url: url.server + "/projects/all",
          headers: {
            Authorization: "Bearer " + cookies.get("token"),
            "tenantId": tenantId,
            email: email,
          },
          params: { includeDrafted: true },
        })
          .then((data) => {
            // console.log(data.data?.length, "cccccccccccccccccccccccc")
            const resData = data.data;
            let finalArray = [];
            let detailArray = [];
            let mapstable = resData.MasterData?.map((value) => {
              return {
                MasterCode: value.MasterProjCode,
                EnvProjData: value.mapsTable,
              };
            });
            resData.projects?.map((value) => {
              // get current project object from user's project list
              let detail = mapstable?.length > 0 && mapstable?.filter(
                (e) => e.MasterCode === value.ProjCode
              );
              // let projectRoleObj = this.props.projectList.find(
              //   (prj) => prj.ProjectCode === value.ProjCode
              // );
              // let Data = Utils.getLocal("user-data");
              // this.setState({ userData: Data });
              let projResData = {
                Status: value.Status,
                detailpanel: detail.length === 0 ? [] : detail[0].EnvProjData,
                projectName: value.ProjName,
                projectCode: value.ProjCode,
                techStack: value.TechStack[0],
                startDate: value.ProjStartDate,
                ProjHealth: value.ProjHealth,
                ProjType: value?.ProjType,
                isDrafted: value.IsDrafted,
                // cloneApp: Utils.hasComponentAccess(
                //   "clone_app",
                //   this.props.access,
                //   projectRoleObj
                // ),
                // allocateApp: Utils.hasComponentAccess(
                //   "allocate_app",
                //   this.props.access,
                //   projectRoleObj
                // ),
              };
              finalArray.push(projResData);
              return "";
            });
            // console.log(finalArray, "finalArray")
            let finalData = finalArray
            // mappingProject?.map((e) => {
            //   if (e?.DevozyProjectDetails?.length > 0) {
            //     e.DevozyProjectDetails?.map((x) => {
            //       finalData = finalData?.filter((a) => a.projectCode != x.ProjCode)
            //     })
            //   }
            // })
            // console.log(finalData, "finalData")
            // setMappingProject(data)
            setApplicationsArr(finalData);
          })
      })
      .catch((err) => {
        console.log("Get error: " + err);
      });
  }
  useEffect(() => {
    refresh()
  }, []);

  // console.log(applicationsArr?.length, 'llllllllllllllll', applicationsArr?.map((e) => e.projectCode)?.includes())

  function handleChange(e) {
    let filtered = e?.map((x) => {
      return {
        Environment: x.value.ProjType,
        ProjCode: x.value.projectCode,
        ProjDesc: x.value.projectName,
      };
    });
    setLinkProject(filtered);
  }

  function handleProject(e) {
    setProjectValue(e.value);
    getProject(e.value)
    // setProjectTitle(e.label)
    dispatch(HANDLE_ON_CHANGE({ name: 'projectTitle', value: e.label }))
  }
  // console.log(project, "check project")
  const link = () => {
    setLinkValidation(false);
    if (linkProject.length != 0) {
      let data = {
        AgileProjId: projectCode,
        DevozyProjectDetails: linkProject,
      }
      if (project?.length > 0) {
        const data = [...project?.[0]?.DevozyProjectDetails, ...linkProject]
        // console.log(data, "data")
        axios.patch(`agilesaas_devozy_project_mapping?AgileProjId=eq.${project?.[0]?.AgileProjId}`, { DevozyProjectDetails: data })
          .then((resp) => {
            toast.success("successfully linked to a project");
            // setbuild(false)
            refresh()
            setLinkanother(false)
          })
          .catch((err) => {
            console.log(err, "err");
          });
      }
      else {
        axios
          .post("agilesaas_devozy_project_mapping", data)
          .then((resp) => {
            toast.success("successfully linked to a project");
            // setbuild(false)
            refresh()
            setLinkanother(false)
          })
          .catch((err) => {
            console.log(err, "err");
          });
      }
    } else if (linkProject.length == 0) {
      setLinkValidation(true);
    }
  };

  const option =
    applicationsArr.length == 0
      ? []
      : applicationsArr?.map((e) => ({
        label: e.projectName,
        value: e,
      }));

  // console.log(applicationsArr, "applicationsArr")
  const checkdata = applicationsArr?.length > 0 ? applicationsArr?.map((e) => e.projectCode) : []

  const projectOption =
    project?.length == 0
      ? []
      : project?.[0]?.DevozyProjectDetails?.length > 0 && project?.[0]?.DevozyProjectDetails?.filter((item) =>
        checkdata?.includes(item.ProjCode)
      )?.map((e) => ({
        label: e?.ProjDesc,
        value: e?.ProjCode,
        id: e.DevozyMappingId
      }));

  // console.log(project?.[0]?.DevozyProjectDetails)
  const DropdownIndicator = () => {
    return (
      <span>
        {/* Custom dropdown indicator if needed */}
      </span>
    );
  };

  const unlinkproject = (data) => {
    const proj_data = project?.[0]?.DevozyProjectDetails
    const removeProject = data?.value
    const patchdata = proj_data?.length > 0 && proj_data?.filter((x) => x.ProjCode != removeProject)
    axios.patch(`agilesaas_devozy_project_mapping?AgileProjId=eq.${project?.[0]?.AgileProjId}`, { DevozyProjectDetails: patchdata })
      .then((resp) => {
        toast.success("Unlinked a project successfully");
        // setbuild(false)
        refresh()
        if (patchdata?.length == 0) {
          setLinkanother(true)
        }

      })
      .catch((err) => {
        console.log(err, "err");
      });
  }
  const DeleteOption = ({ data, innerProps, onSelectOptionDelete }) => {
    // console.log(innerProps, "innerProps", data)
    const value = optionsWithDelete?.find((option) => option?.value === projectValue)?.value
    return (
      <div {...innerProps}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between', // Align children with space between
          alignItems: 'center', // Center items vertically
          width: '100%',
          padding: 5,
          backgroundColor: value == data?.value ? "#198ae3" : "#fff",
          color: value == data?.value ? "#fff" : "inherit"
        }}>
        <span style={{ whiteSpace: 'nowrap' }}>{data.label}</span>
        {
          projectRole == "DEV" || projectRole == "QA" ? "" :
            <HighlightOffIcon style={{ color: value == data?.value ? "#fff" : '#d50a51', cursor: 'pointer' }} onClick={() => { unlinkproject(data) }} />
        }
        {/* <label
          onClick={() => { console.log("clicked delete") }}
          style={{
            alignItems: 'flex-end',
            backgroundColor: 'red',
            color: '#fff',
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: 9
          }}>X</label>*/}
        {/* <FaTimes onClick={() => onSelectOptionDelete(data.value)} /> */}
      </div>
    );
  };

  const customComponents = {
    DropdownIndicator: () => <DropdownIndicator />,
    Option: ({ data, innerProps }) => (
      <DeleteOption data={data} innerProps={innerProps} onSelectOptionDelete={data.onSelectOptionDelete} />
    ),
  };

  // const CustomSelect = ({ handleProject, projectOption, projectValue }) => {
  const handleOptionDelete = (value) => {
    // Your logic to delete the option
    console.log(value, "valueeeeeeeeeeeeeeeeee")
  };

  const optionsWithDelete = projectOption?.length > 0 && projectOption?.map((option) => ({
    ...option,
    onSelectOptionDelete: handleOptionDelete,
  }));

  const projectCard = () => {

    return (
      <>
        {
          optionsWithDelete?.length > 0 ?

            <>
              {/* {console.log(project?.length)} */}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* {console.log(project?.[0]?.DevozyProjectDetails?.length)} */}
                {
                  optionsWithDelete?.length < 1 ?
                    ""
                    :
                    <div style={{ width: `${windowSize.current[0] / 4.8}px`, marginLeft: '1%', }}>
                      {/* <Select
                  onChange={(e) => handleProject(e)}
                  options={projectOption}
                  value={projectOption.filter((option) => option?.value === projectValue)}
                  placeholder="Select Project "
                  style={customStyles}
                /> */}
                      <Select
                        onChange={(e) => handleProject(e)}
                        options={optionsWithDelete}
                        value={optionsWithDelete?.find((option) => option?.value === projectValue)}
                        components={customComponents}
                        placeholder="Select Project"
                        styles={customStyles1}
                      />
                    </div>
                }
                {/* {console.log(optionsWithDelete?.find((option) => option?.value === projectValue), "check value")} */}
                {
                  projectRole == "DEV" || projectRole == "QA" ? "" :
                    <Button
                      style={{ fontSize: 14, padding: 9, width: 150, height: 40, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center', textTransform: 'none' }}
                      onClick={() => {
                        if (option.length > 0) { setLinkanother(true) } else { toast.error("All projects are linked"); }
                      }}>Link a project</Button>
                }

              </div>
              <div style={{
                display: "flex", flexDirection: "row",
                // alignContent: 'center',
                // justifyContent: 'center'
              }}>
                {
                  projectDetails.MasterProject?.map((data) => (
                    <div
                      style={{
                        padding: 14,
                        backgroundColor: "#fff",
                        margin: 10,
                        width: "42%",
                        borderRadius: 8,
                        border: '2px solid #dcdcdc',
                        alignContent: 'center',
                        justifyContent: 'center'
                        // borderColor: 'green'
                      }}
                    >
                      {/* {console.log(data)} */}
                      <div className="d-flex align-items-center">
                        <div
                          className={
                            data.envCardDetail.find((e) => e.Env == "integration")
                              .Health == 2
                              ? "not-well-health dot-indicator ml-1"
                              : data.envCardDetail.find((e) => e.Env == "integration")
                                .Health == 3
                                ? "danger-health dot-indicator ml-1"
                                : "all-well-health dot-indicator ml-1"
                          }
                        ></div>
                        &nbsp;&nbsp;
                        <div className="mr-1 text-capitalize">
                          <b className="text-center" style={{ fontSize: 16 }}>
                            {data.projectdata.ProjectType} -
                            {" ( " + data.projectdata.projCode + " ) "}
                          </b>
                        </div>
                      </div>

                      <div className="mt-2 d-flex align-items-center" style={{ fontSize: 12, marginLeft: 25, fontWeight: 400 }}>
                        Server IP -
                        {data.envCardDetail.find((e) => e.Env == "stage").ServerIP}
                      </div>
                      <div className="row">
                        <div
                          className="input-group"
                          style={{ padding: "1.0rem 1rem", marginLeft: 7 }}
                        >
                          <p
                            className="form-control pl-2 text-ellipsis cursor-text clipboard-text"
                            readOnly
                            title={data.projectdata.ProjectURL || "N/A"}
                            style={{ borderColor: '#dcdcdc', backgroundColor: '#fff', borderRight: 0, borderTopRadius: 8, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
                          >
                            <code style={{ color: "black", fontSize: 12 }}>
                              {data.projectdata.ProjectURL || "N/A"}
                            </code>
                          </p>
                          <div className="input-group-button">
                            <Clipboard
                              className="btn btn-info  cursor-pointer"
                              style={{
                                padding: "14px", cursor: "grab", backgroundColor: '#fff', borderColor: '#dcdcdc', borderRadius: 0, color: '#2685D7', borderWidth: 1, borderTopRightRadius: 8, borderBottomRightRadius: 8,
                              }}
                              data-clipboard-text={
                                data.projectdata.ProjectURL || "N/A"
                              }
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </Clipboard>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2 mb-md-0 d-flex">
                        {/* <div className="d-inline-flex align-items-center justify-content-center border rounded-circle bg-primary px-2 py-2 my-auto">
                    <i className="mdi mdi-pound icon-sm my-0 text-white"></i>
                  </div> */}
                        <Avatar
                          sx={{
                            bgcolor: '#5250E5',
                            color: 'white', // Set the color of the icon
                          }}
                        >
                          <i className="mdi mdi-pound icon-sm my-0 text-white"></i>
                        </Avatar>
                        <div className="wrapper pl-3">
                          <small className="text-muted" style={{ fontWeight: 500, fontSize: 10, color: 'black' }}>Last deploy version</small>
                          <p className="font-weight-semibold mb-0" style={{ fontWeight: 500, fontSize: 12, color: 'black' }}>
                            {data.projectdata.projVersion}
                          </p>
                        </div>
                      </div>
                      <div className="mb-2 mb-md-0 mt-3 d-flex">
                        {/* <div className="d-inline-flex align-items-center justify-content-center border rounded-circle bg-primary px-2 py-2 my-auto text-muted">
                    <i className="mdi mdi-server-security icon-sm my-0 text-white"></i>
                  </div> */}
                        <Avatar
                          sx={{
                            bgcolor: '#5250E5',
                            color: 'white', // Set the color of the icon
                          }}
                        >
                          <i className="mdi mdi-server-security icon-sm my-0 text-white"></i>
                        </Avatar>
                        <div className="wrapper pl-3">
                          <small className="text-muted" style={{ fontWeight: 500, fontSize: 10, color: 'black' }}>Technology</small>
                          <p className="font-weight-semibold mb-0" style={{ fontWeight: 500, fontSize: 12, color: 'black' }}>
                            {data.projectdata.technology}
                          </p>
                        </div>
                      </div>
                      <div className="mb-2 mb-md-0 mt-3 d-flex">
                        {/* <div className="d-inline-flex align-items-center justify-content-center border rounded-circle bg-primary px-2 py-2 my-auto text-muted">
                    <i className="mdi mdi-key-variant icon-sm my-0 text-white"></i>
                  </div> */}
                        <Avatar
                          sx={{
                            bgcolor: '#5250E5',
                            color: 'white', // Set the color of the icon
                          }}
                        >
                          <i className="mdi mdi-key-variant icon-sm my-0 text-white"></i>
                        </Avatar>
                        <div className="wrapper pl-3">
                          <small className="text-muted" style={{ fontWeight: 500, fontSize: 10, color: 'black' }}>SSH Status</small>
                          <div
                            className={
                              data.envCardDetail.find((e) => e.Env == "integration")
                                .Health == 2
                                ? "not-well-health dot-indicator ml-1"
                                : data.envCardDetail.find(
                                  (e) => e.Env == "integration"
                                ).Health == 3
                                  ? "danger-health dot-indicator ml-1"
                                  : "all-well-health dot-indicator ml-1"
                            }
                          ></div>
                        </div>
                      </div>
                      <div className="">
                        <div className="mt-3">
                          <table className=" table table-sm table-borderless">
                            <thead>
                              <tr >
                                <th className="font-weight-bold"><label style={{ fontSize: 12, }}>Version</label></th>
                                <th className="font-weight-bold"><label style={{ fontSize: 12, }}>Deployed By</label></th>
                                <th className="font-weight-bold"><label style={{ fontSize: 12, }}>Date & Time</label></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.projectdata?.deploymentDetails?.map((x) => (
                                <tr >
                                  <td><label style={{
                                    fontSize: 10,
                                    maxWidth: '100px', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                  }}>{`${x?.version}`}</label></td>
                                  <td><label style={{ fontSize: 10, width: '100%', whiteSpace: 'nowrap' }}>{x?.DeployedBy?.split('@')?.[0]}</label></td>
                                  <td><label style={{ fontSize: 10, width: '100%', whiteSpace: 'nowrap' }}>{moment(x?.id).format("DD MMM YYYY hh:mm A")}</label></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {role == "admin" ||
                        role == "maintainer" ||
                        role == "creator" ? (
                        <div
                          className="mt-3"
                          style={{
                            borderTop: "1px #d0cdcb solid",
                            display: "flex",
                            justifyContent: "space-evenly",
                            paddingTop: 11
                          }}
                          onClick={(evt) => {
                            // this.noPropagateHandler(evt);
                          }}
                        >
                          <Button
                            onClick={(e) => {
                              setSchedulePopup(true);
                              setSelectedData(data)
                            }}
                            style={{ fontSize: 14, padding: 9, width: 100, height: 40, borderRadius: 8, marginRight: 14, border: '2px solid #5250E5', backgroundColor: '#fff', color: '#5250E5', textAlign: 'center', textTransform: 'none' }}
                          >Schedule</Button>
                          <Button
                            style={{ fontSize: 14, padding: 9, width: 100, height: 40, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center', textTransform: 'none' }}

                            onClick={(evt) => {
                              setDeployPopup(true);
                              setSelectedData(data)
                            }}
                          >
                            Deploy</Button>
                        </div>
                      ) : null}
                    </div>
                  )
                  )}
              </div >
            </>
            :
            <div className=" d-flex align-items-center"
              style={{ justifyContent: "center", height: 460 }}>
              <label>You didn't have any projects onboarded on Devozy, or you didn't have access to the project.</label>
            </div>
        }
      </>

    );
  };
  const linkProjectdisplay = () => (
    <div
      style={{
        margin: '10%',
        alignSelf: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // width: `${windowSize.current[0] / 2.2}px`,
        //  display: 'flex', flexDirection: 'row'
      }}
    >
      <Select
        style={customStyles}
        isMulti
        onChange={(e) => handleChange(e)}
        options={option}
        maxMenuHeight={140}
        // className="selectf1  emp ml-1"
        // closeIcon={"false"}
        // maxWidth={200}
        placeholder="Search by Name"
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          style={{
            // backgroundColor: "#6a2e94",
            color: "#6a2e94",
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderRadius: "5px",
            textAlign: "center",
            fontWeight: "bold",
            cursor: "pointer",
            marginTop: "2%",
            marginRight: '2%',
            border: '2px solid #6a2e94'
          }}
          onClick={() => {
            // link();
            setLinkanother(false)
            setLinkProject([])
          }}
        >
          Cancel
        </Button>
        <Button
          style={{
            backgroundColor: "#6a2e94",
            color: "white",
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderRadius: "5px",
            textAlign: "center",
            fontWeight: "bold",
            cursor: "pointer",

            marginTop: "2%",
          }}
          onClick={() => {
            link();
          }}
        >
          Link
        </Button>
      </div>
      {linkValidation ? (
        <label style={{ color: "red" }}>
          Please select a project to Link{" "}
        </label>
      ) : null}
    </div>
  )

  const projectOnboard = () => {
    if (loading) {
      return <div
        className=" d-flex align-items-center"
        style={{ justifyContent: "center", height: 460 }}
      >
        <span
          className="spinner-border"
          role="status"
          aria-hidden="true"
          style={{ color: "rgb(82, 80, 229)" }}
        ></span>
      </div>

    }
    else {
      if (projectDetails.length === 0 && (role == "admin" || role == "creator")) {
        // console.log("if else")
        return (
          <div
            style={{
              // margin: '6%',
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flex: 2,
              flexDirection: "column",
              marginTop: "5%",
              marginLeft: "10%",
              marginRight: "10%",
              marginBottom: "5%",
            }}
          >
            <label style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
              You didn't have any onboarded project. Please onboard it or link it.
            </label>
            <Tabs
              defaultActiveKey={
                // this.state.ci ?
                "first"
                // : "second"
              }
            // className="mx-5"
            >
              <Tab
                eventKey="first"
                title=" Onboard"
                style={{
                  alignSelf: "center",
                  margin: "5%",
                  width: `${windowSize.current[0] / 2}px`,
                  //  display: 'flex', flexDirection: 'row'
                }}
              >
                <div style={{ alignSelf: "center", marginTop: "2%" }}>
                  <label
                    style={{
                      backgroundColor: "#6a2e94",
                      color: "white",
                      padding: "9px",
                      borderRadius: "5px",
                      textAlign: "center",
                      fontWeight: "bold",
                      cursor: "pointer",
                      width: `${windowSize.current[0] / 2.2}px`,
                    }}
                    onClick={() => {
                      const data = { id: projectCode };
                      const encodedData = encodeURIComponent(JSON.stringify(data));
                      const url = `https://app.devozy.ai/application/add-app-details?data=${encodedData}`;
                      window.open(url);
                      // window.open(
                      //   "https://app.devozy.ai/application/add-app-details"
                      // );
                      // http://localhost:3001/application/add-app-details
                    }}
                  >
                    Click here to onboard a new project
                  </label>
                </div>
              </Tab>
              <Tab
                // disabled={this.state.ci}
                eventKey="second"
                title="Link"
                style={{
                  alignSelf: "center",
                  // margin: "5%",
                  width: `${windowSize.current[0] / 2}px`,
                  // margin: '10%',
                  // display: 'flex',
                  // flexDirection: 'column',
                  justifyContent: 'center',
                  //  display: 'flex', flexDirection: 'row'
                }}
              >
                {linkProjectdisplay()}
              </Tab>
            </Tabs>
          </div>
        );
      } else if (
        projectDetails.length === 0 &&
        (role !== "admin" || role != "creator")
      ) {
        // console.log("if else")
        return (
          <label style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
            You didn't have access to onboard the project on devozy.
          </label>
        );
      } else if (projectDetails.length != 0) {

        return linkanother ? linkProjectdisplay() : projectCard();
      }
    }
  };

  const handleDeploy = () => {
    setDeployPopup(false);
  };

  const handleSchedule = () => {
    setSchedulePopup(false);
  };


  return (
    <div>
      <Dialog
        open={deployPopup}
        onClose={() => {
          handleDeploy();
        }}
        // maxWidth="1000px"
        fullWidth
        maxWidth="xs"
      // padding="8px 25px"
      >
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Box display="flex" alignItems="center">
              <Box
                flexGrow={1}
                fontSize="25px"
                fontWeight="bold"
                className="text-secondary"
              >
                Deploy
              </Box>
            </Box>
            <Box>
              <IconButton onClick={handleDeploy}>
                <CloseIcon />
              </IconButton>
            </Box>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Deploy selectedData={selectedData} setDeployPopup={setDeployPopup}
          // token={token} 
          />
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
      <Dialog
        open={schedulePopup}
        onClose={() => {
          handleSchedule();
        }}
        // maxWidth="1000px"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Box display="flex" alignItems="center">
              <Box
                flexGrow={1}
                fontSize="25px"
                fontWeight="bold"
                className="text-secondary"
              >
                Schedule
              </Box>
            </Box>
            <Box>
              <IconButton onClick={handleSchedule}>
                <CloseIcon />
              </IconButton>
            </Box>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Schedule projectdata={selectedData.projectdata}
            projCode={selectedData?.projectdata?.projCode}
            // token={token}
            schedulePopup={schedulePopup}
            ProjectSchData={selectedData?.ProjectSchData}
            setSchedulePopup={setSchedulePopup}
            projectValue={projectValue}
            getProject={getProject} />
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
      {status == "" ? (
        role != "guest" ? (
          status != undefined ? (
            <>
              <div style={{ display: "flex" }}>
                {/* <label>Project Code (Devozy) :</label>
                <TextField
                  variant="outlined"
                  className="mytextteam"
                  size="small"
                  value={""}
                  onChange={(e) =>
                    // handletitle(e)
                    console.log(e, "e")
                  }
                  type="text"
                  // error={error}
                  // defaultValue="Daily Scrum Meeting"
                  autoComplete="off"
                  required
                // helperText={error}
                // disabled
                /> */}
              </div>
              {projectOnboard()}
            </>
          ) : (
            <div
              style={{
                margin: "10%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flex: 2,
                flexDirection: "column",
              }}
            >
              {/* {
              role == undefined ? (
                <label
                  style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}
                >
                  Your account has been disabled. Please update it on
                  subscription.
                </label>
              ) : status == "No Account" ? ( */}
              <label
                style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}
              >
                Please check your devozy account
              </label>
              {/* ) : null} */}
              <div style={{ alignSelf: "center", margin: "5%" }}>
                <label
                  style={{
                    backgroundColor: "#6a2e94",
                    color: "white",
                    padding: "9px",
                    borderRadius: "5px",
                    textAlign: "center",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://api.devozy.ai/subscriptionDetails"
                    );
                  }}
                >
                  Click here to update or add subscription
                </label>
              </div>
            </div>
          )
        ) : null
      ) : (
        <div
          className=" d-flex align-items-center"
          style={{ justifyContent: "center", height: 460 }}
        >
          <span
            className="spinner-border"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      )}
    </div>
  );
};

export default BuildPlanner;
