import "gantt-task-react/dist/index.css"

import React from "react"
import { ViewMode } from "gantt-task-react"
import "./stylefortimeline.css"
export const ViewSwitcher = ({ onViewModeChange, onViewListChange, isChecked }) => {
    
  return (
    <div className='TViewContainer'>
      {/* <button className='TButton' onClick={() => onViewModeChange(ViewMode.Hour)}>
        Hour
      </button>
      <button className='TButton' onClick={() => onViewModeChange(ViewMode.QuarterDay)}>
        Quarter of Day
      </button>
      <button className='TButton' onClick={() => onViewModeChange(ViewMode.HalfDay)}>
        Half of Day
      </button> */}
      <button className='TButton' onClick={() => onViewModeChange(ViewMode.Day)}>
        Day
      </button>
      <button className='TButton' onClick={() => onViewModeChange(ViewMode.Week)}>
        Week
      </button>
      <button className='TButton' onClick={() => onViewModeChange(ViewMode.Month)}>
        Month
      </button>

      <div className='TSwitch'>
        <label className='TSwitch_Toggle'>
          <input
            type='checkbox'
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          <span className='TSlider' />
        </label>
        Show Task List
      </div>
    </div>
  )
}
