/* eslint-disable no-lone-blocks */
import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "../../axios";
import { Dropdown } from "react-bootstrap";
import Balancedays from "../../components/LoginPage/Balancedays";
import "./Admin.css";

import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import ReactImageFallback from "react-image-fallback";
import { Box, Grid, IconButton, Card, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Cookies from "universal-cookie";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import Person from "../../assets/images/faces/person.png";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Badge from "@material-ui/core/Badge";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
// import YozyLogo from "../../img/sdp-refresh.png";
import RefreshIcon from "@mui/icons-material/Refresh";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Button from "react-bootstrap/Button";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Admin from "../../assets/images/Admin.png";
import UserContext from "../../index";
import Aravind from "../../assets/images/Kandhasamy.png";
import AgilePM from "../../assets/images/AgilePM.png";
import Agilepmsvg from "../../assets/images/AgilePM.svg";
import Devopscicidsvg from "../../assets/images/DevOps CICD.svg";
import Devopscicd from "../../assets/images/DevOps CICD.png";
import { useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Notify from "./Notify";
import { useSelector, useDispatch } from "react-redux";
import { SIDEBAROPEN } from "../../redux/CommonInitialsate";
function AdminNavbar(props, token) {
  const containerRef = useRef(null);
  const [visibleData, setVisibleData] = useState(5);
  const [scrolled, setScrolled] = useState(0);
  const [roadmap, setroadmap] = useState(false);
  let userdata = JSON.parse(localStorage.getItem("userdata"));

  let [notificationdata, setnotificationdata] = useState([]);
  let [notificationcount, setnotificationcount] = useState(0);
  let [notificationepmyty, setnotificationepmyty] = useState(false);
  let [loadingnotification, setloadingnotification] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  let [unreadnotification, setunreadnotification] = useState();
  let tenantid = JSON.parse(localStorage.getItem("userdata"));
  let email = tenantid?.login;
  let data = JSON.parse(localStorage.getItem("userdata"));
  const context1 = useContext(UserContext);
  let empDetails = JSON.parse(localStorage.getItem("clientdetails"));
  let userdetails = JSON.parse(localStorage.getItem("Empdetails"));
  const { projectRole, projectRoleName, userImage, IsSidebarCollapse } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();

  let Token = data?.token;

  const [logo, setlogo] = useState([]);
  const ProjectOption = [
    { label: "Yozy Devops Platform", value: 1, client: null },
    { label: "Test Project", value: 49, client: null },
    { label: "Test Agile 03/04", value: 59, client: null },
    { label: "Test Agile 31/01", value: 50, client: null },
    { label: "Test Project Agile", value: 47, client: null },
  ];
  const handleScroll = () => {
    const { current } = containerRef;
    if (current) {
      const { scrollTop, scrollHeight, clientHeight } = current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        // User has scrolled to the bottom
        setScrolled((prevScrolled) => prevScrolled + visibleData);
      }
    }
  };

  useEffect(() => {
    getnotification();

    const apiCallInterval = setInterval(getnotification, 10 * 60 * 1000);

    return () => {
      clearInterval(apiCallInterval);
    };
  }, [empDetails?.[0]?.UserId]);
  useEffect(() => {
    const { current } = containerRef;
    if (current) {
      current.addEventListener("scroll", handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  function getindividula(data) {
    let useinfo = data.NotifyToDetails.filter(
      (x) => x.id == empDetails[0].UserId
    );
    return useinfo[0];
  }

  useEffect(() => {
    axios({
      method: "GET",
      url:
        "https://api.devozy.ai/subscription_user_role_mapping?ProductDetails->>ProdId=eq.11004&UserDetails->>Email=eq." +
        userdata?.login +
        "&LoginEnabled=eq.Y",
    }).then((response) => {
      let clientid = response.data?.map((e) => e.ClientId);

      axios({
        method: "GET",
        url:
          "https://subscriptionapi.yozytech.com/subscription_client_license_details?ClientId=eq." +
          clientid +
          "&ProductDetails->>ProdId=in.(11004,11006)",
        headers: {
          Authorization: "Bearer " + Token,
        },
      })
        .then((res) => {
          let name = res.data?.map((e) => e.ClientDetails);
          localStorage.setItem(
            "Orgsubscriptiondetails",
            JSON.stringify(res.data)
          );
          setlogo(name);
        })
        .catch((er) => console.error(er, "errorapi"));
    });
  }, []);

  // setTimeout(getnotification,10000)
  function getnotification() {
    if (empDetails != null) {
      setloadingnotification(true);
      // axios({
      //   method: "GET",
      //   url:`https://api.devozy.ai/api/notification/all?id=${empDetails[0].ClientId}&epid=${empDetails[0].UserId}`,
      //   headers: {
      //     Authorization: "Bearer " + Token,
      //   },
      // })
      axios
        .get(
          `/api/agile/notification/all?id=${empDetails[0].ClientId}&epid=${empDetails[0].UserId}`
        )
        .then((res) => {
          let notreadnotification = res.data.filter((item) =>
            item.NotifyToDetails.some(
              (detail) =>
                detail.id == empDetails[0].UserId &&
                detail.IsAcknowledged != "Y"
            )
          );

          // let notreadnotification = res.data.filter(x => {
          //   if (x.IsAcknowledged != "Y") {
          //     return x
          //   }
          // })
          setunreadnotification(notreadnotification);
          setnotificationcount(notreadnotification.length);
          setTimeout(() => {
            setloadingnotification(false);
          }, 2000);

          setnotificationdata(res.data);
          if (res.data.length > 0) {
            setnotificationepmyty(false);
          } else {
            setnotificationepmyty(true);
          }
        })
        .catch((er) => {
          console.error(er, "errorapi");
          setloadingnotification(false);
        });
    }
  }

  function readallnotification() {
    let user = {
      id: empDetails[0].UserId,
      name: empDetails[0].UserDetails.Firstname,
      lastname: empDetails[0].UserDetails.Lastname,
      IsDelivered: "Y",
      IsAcknowledged: "Y",
    };

    let allnotification = unreadnotification.map((x) => {
      let data = {
        NotificationId: x.NotificationId,
        isgroupnotification: x.NotifyToDetails.length > 1 ? true : false,
      };

      return data;
    });

    if (allnotification?.length > 0) {
      axios
        .patch(`/api/agile/notification/markallread`, {
          user: user,
          allnotification: allnotification,
        })
        .then((res) => {
          getnotification();
        })
        .catch((er) => console.error(er, "errorapi"));
    }
  }

  const renderData = (start, end) => {
    return notificationdata
      .slice(start, end)
      .map((data, index) => (
        <Notify
          key={index}
          data={data}
          user={getindividula(data)}
          getnewdata={getnotification}
        />
      ));
  };

  const logohead = logo
    ? logo[0]?.Company?.split(" ")[0].split("")
    : "YOZY".split("");

  const signout = () => {
    localStorage.clear();
    sessionStorage.clear();
    const cookies = new Cookies();
    cookies.remove("token");
    let allCookies = cookies.getAll();
    for (let key in allCookies) {
      cookies.remove(key, { path: "/", domain: ".devozy.ai" });
      // cookies.remove(key, { path: "/", domain: "localhost" });
    }
  };

  function openpopup() {
    context1.setcontextvalue({ ...context1.contextvalue, Creatpopup: true });
  }

  const handleClick = useCallback((e, projects) => {
    e.preventDefault();
    context1.setproject(projects);
  }, []);
  function handleclicks() {
    setroadmap(true);
  }
  const cardContents = [
    {
      title: "Agile PM Roadmap",
      description: "Agile PM Step by Step",
      image: (
        <ReactImageFallback
          src={Agilepmsvg}
          initialImage="loader.gif"
          alt="Agile PM"
          style={{ width: "500px" }}
        />
      ),
      alt: "Agile PM Step by Step",
    },
    {
      title: "Devops CICD Roadmap",
      description: "Devops CICD Step by Step",
      image: (
        <ReactImageFallback
          src={Devopscicidsvg}
          initialImage="loader.gif"
          alt="DevOps CI/CD"
          style={{
            width: "500px",
          }}
        />
      ),
      alt: "Devops CICD Step by Step",
    },
  ];

  const handleNext = (e) => {
    e.stopPropagation();
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cardContents.length);
  };

  const handleBack = (e) => {
    e.stopPropagation();
    setCurrentCardIndex(
      (prevIndex) => (prevIndex - 1 + cardContents.length) % cardContents.length
    );
  };

  const { title, description, image, alt } = cardContents[currentCardIndex];

  const instruction = (
    <div style={{ fontFamily: "DM Sans" }}>
      <Card>
        <CardActions style={{ padding: "9px 18px" }}>
          {/* <Row className="w-100 d-flex"> */}
          <Col md={9}>
            {/* <Typography gutterBottom variant="h6" component="h2">
                {title}
              </Typography> */}
          </Col>
          <Col md={3} className="d-flex justify-content-end">
            <button
              className="mr-2 rounded p-1 mt-1"
              style={{
                backgroundColor: "white",
                border: "1px solid lightblue",
                color: "black",
                fontSize: "12px",
                height: "30px",
                width: "50px",
              }}
              onClick={handleBack}
            >
              Back
            </button>
            <button
              className="rounded p-1 mt-1"
              style={{
                backgroundColor: "#00A9FF",
                color: "white",
                border: "none",
                fontSize: "12px",
                height: "30px",
                width: "50px",
              }}
              onClick={handleNext}
            >
              Next
            </button>
          </Col>
          {/* </Row> */}
        </CardActions>
        <CardContent style={{ padding: "9px 18px" }}>
          <div>{image}</div>
        </CardContent>
      </Card>
    </div>
  );

  return (
    <>
      <nav className="navbar">
        <div className="navbar-menu-wrapper">
          <div className="navbar-with-project-select">
            <div
              className="navbar-toggler align-self-center navbar-toggler-icon"
              onClick={() => dispatch(SIDEBAROPEN(!IsSidebarCollapse))}
            >
              <div className="dot mt-2">
                {!IsSidebarCollapse ? (
                  <ChevronLeftOutlinedIcon
                    fontSize="lg"
                    sx={{ cursor: "pointer", color: "#000" }}
                  />
                ) : (
                  <ChevronRightIcon
                    fontSize="lg"
                    sx={{ cursor: "pointer", color: "#000" }}
                  />
                )}
              </div>
            </div>

            {
              // context1.windows == 'kanban' ?
              //   <>
              //     {/* <Button className="navbar-toggler align-self-center navbar-toggler-icon " onClick={() => document.body.classList.toggle("sidebar-icon-only")}>
              //       <div className="dot">
              //         <ChevronLeftOutlinedIcon fontSize="lg" sx={{ cursor: "pointer", color: "#000" }} />
              //       </div>
              //     </Button> */}
              //     <div className="project-select-dropdown">
              //       <Dropdown drop="end">
              //         <Dropdown.Toggle className="project-select-trigger">
              //           <Typography className="fontdesign" style={{ color: "black", fontSize: 14, fontFamily: 'DM Sans' }}>Select by Project</Typography>
              //           <ExpandMoreOutlinedIcon fontSize="small" />
              //         </Dropdown.Toggle>
              //         <Dropdown.Menu className="navbar-dropdown">
              //           {
              //             ProjectOption.map((projects) => (
              //               <Dropdown.Item sx={{ fontSize: 12 }} onClick={(e) => handleClick(e, projects)}>{projects.label}</Dropdown.Item>
              //             ))
              //           }
              //         </Dropdown.Menu>
              //       </Dropdown>
              //     </div>
              //   </>
              //   : ""
            }
          </div>

          <div className="navbar-with-profile-actions">
            {/* {
              context1.windows == 'kanban' ?
                <Button variant="info" size="sm" onClick={(e) => {
                  e.preventDefault()
                  openpopup()
                }
                }><AddCircleOutlineOutlinedIcon fontSize="small" className="addicon" />Create</Button>
                : ""
            } */}
            {/* badgeContent={10} */}

            <ul className="navbar-nav">
              <li className="nav-item nav-profile">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link">
                    <div className="nav-profile-img">
                      {/* <img src={Person} alt="user" /> */}
                      <Avatar
                        alt=""
                        src={
                          userImage
                          // empDetails?.[0]?.TeamMemberId == 1 ? Admin : empDetails?.[0]?.TeamMemberId == 103 ? Alen :
                          // empDetails?.[0]?.TeamMemberId == 37 ? Rajan :
                          //   empDetails?.[0]?.TeamMemberId == 114 ? Suren :
                          //     empDetails?.[0]?.TeamMemberId == 2 ? Kandhasamy :
                          //       empDetails?.[0]?.TeamMemberId == 81 ? Aravindh : Unassigned
                        }
                        sx={{ width: 40, height: 40 }}
                        // sx={{ bgcolor: "#1d2433", width: 30, height: 30, fontSize: 12 }}
                      >
                        GK
                      </Avatar>
                      <span className="availability-status online"></span>
                    </div>
                    <p
                      className="ml-3 mt-3"
                      style={{ color: "black", fontSize: 12 }}
                    >
                      {userdetails &&
                        userdetails?.[0]?.UserFirstname +
                          " " +
                          userdetails?.[0]?.UserLastname}
                    </p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="navbar-dropdown"
                    style={{ width: "200%", minWidth: "300px", padding: "5%" }}
                  >
                    <Balancedays email={props.EmpId} />
                    <Dropdown.Item
                      href="!#"
                      onClick={() => signout()}
                      style={{ justifyContent: "center", marginTop: 0 }}
                    >
                      <span
                        className="mdi mdi-logout mr-3 fontdesign"
                        style={{
                          fontSize: "12px",
                          color: "#5250E5",
                          fontWeight: "700",
                        }}
                      >
                        {" "}
                        Sign out
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <Divider orientation="vertical" variant="middle" flexItem />

            <Dropdown
              style={{ background: "none", border: "none", padding: 0 }}
              onClick={() => {
                getnotification();
              }}
            >
              <Dropdown.Toggle
                className="custom"
                style={{ background: "none", border: "none", padding: 0 }}
              >
                <Badge
                  color="secondary"
                  badgeContent={
                    notificationcount > 0 ? notificationcount : null
                  }
                  // variant={notificationdata.length > 0 ? "dot" : "none"}
                >
                  {notificationepmyty == false ? (
                    <NotificationsNoneIcon style={{ color: "black" }} />
                  ) : (
                    <NotificationsPausedIcon style={{ color: "black" }} />
                  )}
                </Badge>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="navbar-dropdown two"
                style={{ minWidth: "300px", padding: "5%" }}
              >
                <div
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    display: "flex",
                    padding: "1rem",
                  }}
                >
                  <b>Notifications</b>
                  {notificationepmyty == true ? (
                    <a
                      style={{ color: "#5250e5", cursor: "pointer" }}
                      className={loadingnotification ? "spin" : ""}
                      onClick={() => {
                        if (!loadingnotification) {
                          getnotification();
                        }
                      }}
                    >
                      <RefreshIcon />
                    </a>
                  ) : (
                    <a
                      className="marklink"
                      style={{ cursor: "pointer" }}
                      onClick={readallnotification}
                    >
                      Mark all as read
                    </a>
                  )}
                </div>
                <hr />
                {/* <Dropdown.Item
                  style={{ justifyContent: "center", marginTop: 0 }}
                >
                  <Notify />
                </Dropdown.Item> */}

                <div
                  ref={containerRef}
                  className="your-div-class"
                  style={{
                    height: "300px",
                    overflowY:
                      notificationdata.length > 3 ? "scroll" : "hidden",
                  }}
                >
                  {notificationdata.length > 0 ? (
                    <>
                      {
                        // notificationdata.map(data =>
                        //   <Notify data={ data } getnewdata={ getnotification} />
                        //   )
                        renderData(0, scrolled + visibleData)
                      }
                    </>
                  ) : loadingnotification ? (
                    <div
                      className=" d-flex"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <span
                        className="spinner-border"
                        role="status"
                        aria-hidden="true"
                        style={{ color: "rgb(82, 80, 229)" }}
                      ></span>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a style={{ fontSize: "50px" }}>🥺</a>
                      <p>no notification found</p>
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className="glitter-icon" onClick={handleclicks}>
              <Tooltip title="Flow step by tep">
                <TipsAndUpdatesIcon />
              </Tooltip>
            </div>
          </div>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center "
            type="button"
            // onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
        <div>
          <Dialog
            open={roadmap}
            // onClose={() => {
            //   handleClose();
            // }}
            className="mainbox"
            maxWidth="lg"
            // maxWidth="lg"
            // fullWidth={true}
            // height="1500px"
          >
            <DialogTitle>
              <Row
                className=""
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col lg={10} md={10}>
                  <label
                    className="text-secondary fontdesign"
                    style={{ fontWeight: "bold" }}
                  >
                    Devozy appplication flow step by step
                  </label>
                </Col>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <IconButton
                    onClick={() => {
                      setroadmap(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </Row>
            </DialogTitle>
            <DialogContent>{instruction}</DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </div>
      </nav>
    </>
  );
}

export default AdminNavbar;
