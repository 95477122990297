import React, { useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { Box, Grid, IconButton, Card, TextField } from "@material-ui/core";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Divider from "@mui/material/Divider";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { HANDLE_ON_CHANGE } from '../../redux/CommonInitialsate';

function Commitdetails({
    // commitDialog, setCommitDialog, 
    // handleClose,
    // devdata,
    // releases,
    // projectName
}) {
    const { allImage, projectCode, KanbanBoardData, selectedCard1, searchText, Assetoptions, projectRole, projectName, commitDialog,
        releases1
    } = useSelector(
        (state) => state.user
    );
    let empid = JSON.parse(localStorage.getItem("Empdetails"));
    const devdata = KanbanBoardData?.filter((x) => x.AssignedTo == empid?.[0]?.ClientUserId && x.CurrentStage?.[0]?.StageCode == "DEV")
    const releaseDetails = releases1?.filter((l) => moment(l.SprintStartDate).diff(moment(), "day") < 0)
    const modifyImageTags = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        const images = tempDiv.querySelectorAll('img');
        images.forEach(image => {
            // Remove the image element from the DOM
            image.remove();
        });

        const regex = /<[^>]*>/g;

        // Replace HTML tags with an empty string
        return tempDiv.innerHTML.replace(regex, '');
        // return tempDiv.innerHTML;
    };

    const [copied, setCopied] = useState(false);
    const [copied1, setCopied1] = useState(false);
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(HANDLE_ON_CHANGE({ name: 'commitDialog', value: false }))
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => { setCopied(true) })
            .catch((error) => console.error('Failed to copy:', error));
    };

    const copyAllDescriptionsToClipboard = () => {
        const allDescriptions = devdata?.map((x, index) => {
            return `${index + 1}. ( Issue ID : ${x.IssueId || 'N/A'} ) ${x.IssueTitle}`;
        }).filter(Boolean).join('\n');
        // console.log(modifyImageTags(allDescriptions), "aaaaaaaaaa")
        navigator.clipboard.writeText(allDescriptions)
            .then(() => { setCopied1(true) })
            .catch((error) => console.error('Failed to copy:', error));
    };

    const resetCopiedState = () => {
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    const resetCopiedState1 = () => {
        setTimeout(() => {
            setCopied1(false);
        }, 1000);
    };

    return (
        <Dialog
            open={commitDialog}
            onClose={() => { handleClose() }}
            className="mainbox"
            // maxWidth="1500px"
            maxWidth="md"
            fullWidth={true}
        // fullScreen={true}
        >
            <DialogTitle>
                <div style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Col lg={10} md={10}>
                        <label
                            className="text-secondary fontdesign"
                            style={{ fontWeight: "bold" }}
                        >
                            Copy Commit Details
                        </label>
                    </Col>
                    <IconButton
                        className="d-flex justify-content-end"
                        onClick={() => {
                            handleClose(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '5%' }}>
                <div>
                    <div>
                        <label className='pt-2' style={{ fontWeight: 'bold', color: '#474242' }}>Title :</label>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between', // Aligns items to the start and end of the container
                            border: '1px solid #E2E2E2',
                            borderRadius: 9,
                            padding: 9,
                            width: '100%',
                            minHeight: 40,
                            marginRight: 9
                        }}>
                            <label>
                                {projectName + " : " + (releaseDetails?.[0]?.ReleaseName || '') + " ( " + (releaseDetails?.[0]?.Description || '') + " ) "}
                            </label>
                            <button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center', // Aligns icon and text vertically
                                    position: 'relative', // Allows positioning of pseudo-element
                                    paddingLeft: '10px', // Spacing between divider and button content
                                    width: 90,
                                }}
                                onClick={() => {
                                    copyToClipboard(projectName + " : " + (releaseDetails?.[0]?.ReleaseName || '') + " ( " + (releaseDetails?.[0]?.Description || '') + " ) ");
                                    resetCopiedState();
                                }}>
                                {/* Vertical divider */}
                                <span
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        bottom: 0,
                                        width: '1px',
                                        backgroundColor: 'grey',
                                    }}
                                />
                                {/* Button content */}
                                <span style={{ marginLeft: '5px', color: copied ? 'green' : 'rgb(82, 80, 229)' }}>
                                    {copied ? <CheckCircleOutlineIcon style={{ fontSize: '13px', }} /> : <ContentCopyIcon style={{ fontSize: '13px' }} />} {copied ? 'Copied' : 'Copy'}
                                </span>
                            </button>
                        </div>


                    </div>

                    <div>
                        <label className='pt-4' style={{ fontWeight: 'bold', color: '#474242' }}>Description :</label>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between', // Aligns items to the start and end of the container
                            border: '1px solid #E2E2E2',
                            borderRadius: 9,
                            padding: 9,
                            width: '100%',
                            minHeight: 40,
                            marginRight: 9
                        }}>
                            <label>
                                {devdata?.map((x, index) => (
                                    <div key={index}>
                                        {`${index + 1}. ( Issue ID : ${x.IssueId || 'N/A'} ) ${x.IssueTitle}`}

                                    </div>
                                ))}
                            </label>
                            <button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center', // Aligns icon and text vertically
                                    position: 'relative', // Allows positioning of pseudo-element
                                    paddingLeft: '10px', // Spacing between divider and button content
                                    width: 90,
                                    alignItems: 'center'
                                }}
                                onClick={() => {
                                    copyAllDescriptionsToClipboard()
                                    resetCopiedState1()
                                }}>
                                {/* Vertical divider */}
                                <span
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        bottom: 0,
                                        width: '1px',
                                        backgroundColor: 'grey',
                                    }}
                                />
                                {/* Button content */}
                                <span style={{ marginLeft: '5px', color: copied1 ? 'green' : 'rgb(82, 80, 229)' }}>
                                    {copied1 ? <CheckCircleOutlineIcon style={{ fontSize: '13px' }} /> : <ContentCopyIcon style={{ fontSize: '13px' }} />} {copied1 ? 'Copied' : 'Copy'}
                                </span>
                            </button>
                        </div>


                    </div>

                </div>
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    )
}

export default Commitdetails