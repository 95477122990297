import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Toolbar, { modules, formats } from "../KanbanBoard/Toolbar";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";
const Activity = ({
  Formopen,
  pdata,
  commentsdata,
  openquilfun,
  quilcomment,
  editfun,
  isedit,
  idofeditmessgae,
  updaterparticularproblem,
  problemidofdata,
  updataofcard,
  snackmessageopen,
  snackmessage,
  errorshow,
  errortext,
}) => {
  const {
    allImage,
    userImage,
    IncidentId,
    statusoption,
    PriorityOption,
    AssignedGroupOption,
  } = useSelector((state) => state.user);

  let arr = JSON.parse(localStorage.getItem("Empdetails"));
  const [formOpen, setFormOpen] = useState(Formopen ? Formopen : false);
  console.log(formOpen);
  const [comments, setComments] = useState(commentsdata ? commentsdata : "");
  console.log(comments);
  const [quillFocus, setQuillFocus] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  const handleComment = (value) => {
    setComments(value);
    // setComments("");
  };
  function generateUniqueID() {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000); // You can adjust the range as needed
    return `${timestamp}-${random}`;
  }

  // Example of generating a unique ID
  let uniqueID = generateUniqueID();

  const handleCancel = () => {
    setFormOpen(false);
    setComments("");
    openquilfun(false);
    quilcomment("");
    editfun(false);
  };

  const handleSaveticket = async () => {
    if (comments.length > 3) {
      await updaterparticularproblem(problemidofdata);
      let messagelist = [];

      messagelist = pdata.ProblemNotes.length > 0 ? pdata.ProblemNotes : [];
      console.log(messagelist, "messagelist");
      if (isedit) {
        const messageToUpdate = messagelist.find(
          (message) => message.Message_id === idofeditmessgae
        );

        if (messageToUpdate) {
          messageToUpdate.NotesDetails = comments;
        }
        updataofcard(problemidofdata, { ProblemNotes: messagelist });
        snackmessage("Progress edited sucessfully");
        // snackmessageopen(true);
        handleCancel();
      } else {
        let message = {
          Message_id: `${uniqueID}-${arr[0].ClientUserId}`,
          DateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          NoteUpdatedBy: {
            id: arr[0].ClientUserId,
            FN: arr[0].UserFirstname,
            LN: arr[0].UserLastname,
          },
          NotesDetails: comments,
          action: true,
        };
        messagelist.push(message);
        updataofcard(problemidofdata, { ProblemNotes: messagelist });
        handleCancel();
      }
    } else {
      errortext("Activity message cannot be empty");
      errorshow(true);
      // alert("comment cannot be empty");
    }
  };

  const handleInputFocus = () => {
    setInputFocus(true);
  };

  const handleInputBlur = () => {
    setInputFocus(false);
  };

  const handleQuillFocus = () => {
    setQuillFocus(true);
  };

  const handleQuillBlur = () => {
    setQuillFocus(false);
  };
  useEffect(() => {
    setFormOpen(Formopen ? Formopen : false);
    setComments(commentsdata ? commentsdata : "");
    console.log(comments);
    console.log(pdata, "pdata");
  }, [commentsdata, Formopen]);

  return (
    <>
      <div className="container mb-3 mt-3">
        <div className="header d-flex">
          <h5 style={{ fontWeight: "600" }}>Activity</h5>
        </div>
        <div className="mt-3 flex">
          <Avatar alt="" src={userImage} sx={{ width: 35, height: 35 }} />
          <div className="w-auto" style={{ flex: 1 }}>
            {!formOpen ? (
              <input
                className="form-control rounded ml-2"
                style={{
                  background: "#fff",
                  border: "1px solid rgb(0, 0, 0 ,30%) ",
                  color: "black",
                  height: "35px",
                  borderRadius: "5px",
                }}
                placeholder="Add a Comment"
                onClick={() => setFormOpen(true)}
                // disabled={ADDIGNID != Empid[0].ClientUserId}
              />
            ) : null}
            {formOpen ? (
              <div>
                <div className="d-flex flex-column ml-2">
                  <Toolbar />
                  <ReactQuill
                    // style={{ width: "600px" }}
                    // className="quill"

                    // theme="snow"
                    onFocus={handleQuillFocus}
                    onBlur={handleQuillBlur}
                    value={comments}
                    onChange={handleComment}
                    modules={modules}
                    formats={formats}
                  />
                </div>
              </div>
            ) : null}
            <div className="mt-3 ml-2 d-flex justify-space-between">
              <Button
                onClick={() => {
                  handleCancel();
                }}
                style={{
                  fontSize: 12,
                  padding: 9,
                  width: 70,
                  height: 28,
                  borderRadius: 5,
                  marginRight: 14,
                  border: "2px solid #198ae3 ",
                  backgroundColor: "#fff",
                  color: "#198ae3 ",
                  textAlign: "center",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  // alert("hi");
                  handleSaveticket();
                }}
                style={{
                  fontSize: 12,
                  padding: 9,
                  width: 70,
                  height: 28,
                  borderRadius: 5,
                  backgroundColor: "#198ae3 ",
                  color: "#fff",
                  border: "0px solid #198ae3 ",
                  textAlign: "center",
                  textTransform: "none",
                  marginRight: 24,
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activity;
