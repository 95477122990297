import { Avatar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/styles";
import { Button } from "reactstrap";
import moment from "moment";
import axios from "../../../axios";
import ReactQuill from "react-quill";
import Toolbar, { modules, formats } from "../Toolbar";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux'
import updatemoment from "../updatemoment";
import Unassigned from "../../../assets/images/avatar.png";

function NotesProgressUpdate({
  // SelectedCardId
}) {

  const date = moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss");
  let User_details = JSON.parse(localStorage.getItem("Empdetails"));
  const clientId = localStorage.getItem("clientid")

  const [notesupdate, setNotesUpdate] = useState("");
  const [updatednotedata, setUpdatednotedata] = useState([]);
  const [inputopen, setInputopen] = useState(false);
  const { allImage, projectCode, SelectedCardId } = useSelector((state) => state.user)

  const BootstrapButton = styled(Button)({
    textTransform: "none",
    fontSize: 13,
    padding: "6px 12px",
    margin: 10,
    border: "0px solid",
  });

  // console.log(SelectedCardId)
  function Refresh_API_data() {
    axios
      .get("agilesaas_issue_notes?IssueId=eq." + SelectedCardId)
      .then((res) => setUpdatednotedata(res.data))
      .catch((e) => { console.log(e) })
  }

  useEffect(() => {
    Refresh_API_data();
  }, []);

  function Submit_data(e) {
    e.preventDefault();
    try {
      if (notesupdate != "") {
        let UpdateNote = {
          IssueId: SelectedCardId,
          UpdatedBy: User_details[0].ClientUserId,
          UpdatedByDetails: {
            FN: User_details[0].UserFirstname,
            LN: User_details[0].UserLastname,
          },
          UpdatedDate: date,
          Notes: notesupdate,
          Attachement: null,
          IsActive: "Y",
          Status: null,
          ClientId: clientId,
          ProjectId: projectCode
        };

        axios
          .post("agilesaas_issue_notes ", UpdateNote)
          .then((res) => {
            toast.success("Note has been submitted");
            setInputopen(false);
            Refresh_API_data();
            setNotesUpdate("");
            console.log(res);
          })
          .catch((e) => { console.log(e) })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const Hidedata = (e) => {
    let hide_data = {
      IsActive: "D",
    };

    axios
      .patch("agilesaas_issue_notes?IssueNotesId=eq." + e.IssueNotesId, hide_data)
      .then((res) => {
        Refresh_API_data();
        toast.success("Notes deleted successfully");
        // console.log(res);
      })
      .catch((e) => { console.log(e) })
  };
  return (
    <div>
      <div className="mt-2 d-flex align-items-center">
        {/* <Avatar className="progressName mr-2 px-2">
          {User_details[0].UserFirstname[0] + User_details[0].UserLastname[0]}
        </Avatar> */}
        <Avatar
          className="progressName mr-2"
          src={allImage?.length > 0 ? allImage?.filter((x) => x.teammemberid == User_details?.[0]?.ClientUserId)?.[0]?.image : Unassigned}
        // sx={{ width: 10, height: 10 }} 
        />
        <span style={{ color: 'black' }}>{User_details[0].UserFirstname + " " + User_details[0].UserLastname}</span>
      </div>
      <div className="mt-1 ">
        {!inputopen ? (
          <input
            className="form-control ml-4"
            style={{ width: '97%', border: '1px solid black' }}
            onClick={() => setInputopen(true)}
            placeholder="Add a comment"
          />
        ) : null}

        {inputopen ? (
          <form
            onSubmit={Submit_data}
            className="d-flex flex-column ml-4"
          >
            <div>
              <Toolbar />
              <ReactQuill
                className="quill w-100"
                theme="snow"
                value={notesupdate || ""}
                onChange={(e) => setNotesUpdate(e)}
                modules={modules}
                formats={formats}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <BootstrapButton
                onClick={() => { setInputopen(false) }}
                style={{ fontSize: 14, padding: 9, width: 100, height: 40, borderRadius: 8, marginRight: 14, border: '2px solid #5250E5', backgroundColor: '#fff', color: '#5250E5', textAlign: 'center', textTransform: 'none' }}
              >Cancel</BootstrapButton>
              <BootstrapButton
                type="submit"
                // className="Card_Button_Color_Approve btn-greeng fontdesign"
                // variant="contained"
                style={{ fontSize: 14, padding: 9, width: 100, height: 40, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center', textTransform: 'none' }}
              >
                Update
              </BootstrapButton>
            </div>

          </form>
        ) : null}
      </div>

      <div>
        {updatednotedata.length == 0 ? "" : updatednotedata?.sort((a, b) => (a.IssueNotesId < b.IssueNotesId ? 1 : -1))?.map((e, i) => {
          return e.IsActive == "Y" ? (
            <div key={i}>
              <div className="mt-2 d-flex align-items-center mb-2">
                {/* <Avatar className="progressName mr-2 px-2">
                  {e.UpdatedByDetails.FN[0] + e.UpdatedByDetails.LN[0]}
                </Avatar> */}
                <Avatar
                  className="progressName mr-2"
                  src={allImage?.length > 0 ? allImage?.filter((x) => x.teammemberid == User_details?.[0]?.ClientUserId)?.[0]?.image : Unassigned}
                // sx={{ width: 10, height: 10 }} 
                />
                <span className="fontdesign" style={{ color: 'black' }}>
                  {e.UpdatedByDetails.FN + " " + e.UpdatedByDetails.LN}
                </span>
                <span className="ml-2 fontdesign" style={{ color: 'black' }}>
                  {updatemoment(e.UpdatedDate)}
                </span>
              </div>
              <div className="d-flex flex-column ml-4 progress_note_update_color">
                <span style={{ color: 'black', paddingLeft: 7 }}
                  dangerouslySetInnerHTML={{
                    __html: e.Notes,
                  }}
                ></span>
              </div>
              {/* {console.log(e.UpdatedBy, User_details?.[0]?.ClientUserId)} */}
              {e.UpdatedBy == User_details?.[0]?.ClientUserId ? (
                <small
                  onClick={() => Hidedata(e)}
                  className="progess_note_delete fontdesign ml-4"
                  style={{ fontSize: 14, padding: 9, width: 100, height: 40, borderRadius: 8, backgroundColor: '#5250E5', color: '#fff', border: '0px solid #5A88F1', textAlign: 'center', textTransform: 'none' }}
                >
                  Delete
                </small>
              ) : null}
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
}

export default NotesProgressUpdate;
