import React from 'react'
import State from "./store/State";
import IncidentMain from './IncidentMain';
export default function index() {
    return (
        <State>
            <IncidentMain />
        </State>
    )
}
