import React from "react";
import { toast } from "react-toastify";
import MaterialTable from "material-table";
import moment from "moment";
import PatchedPagination from "../../../PatchedPagination";
import { blue } from "@material-ui/core/colors";
import { Col, Row } from "reactstrap";
import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import "../ProgressUpdate/EditDetails.css";
import styled from "@mui/material/styles/styled";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import Slider from "@mui/material/Slider";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import OfflineBoltRoundedIcon from "@material-ui/icons/OfflineBoltRounded";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { AlbumRounded, Bookmark } from "@mui/icons-material";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux'
import Unassigned from "../../../assets/images/avatar.png";


toast.configure();

function HistorySprintReportTable({ tabledata, releaseID, userID }) {
  const { allImage } = useSelector((state) => state.user)
  const Columns = [
    // { title: "Issue Id", field: "issueid" },
    // { title: "Issue Title", field: "issuetitle" },
    // { title: "Assigned To", field: "assignedtodetails" },
    // { title: "Sprint In Weeks", field: "sprintinweeks" },
    // { title: "Sprint Start Date", field: "sprintstartdate" },
    // { title: "Sprint End Date", field: "sprintenddate" },
    // {
    //   title: "Sprint - " + tabledata?.[0]?.sprintinweeks + " Weeks",
    //   field: "sprint",
    // },
    // { title: "Committed Story", field: "committedstory", },
    // { title: "Completed Story", field: "completedstory", },
    // { title: "Committed Task", field: "committedtask", },
    // { title: "Completed Task", field: "completedtask" },
    // { title: "Committed Bug", field: "committedbug" },
    // { title: "Completed Bug", field: "completedbug", },
    // { title: "Total Committed", field: "committed", },
    // { title: "Total Completed", field: "completed", },

    // { title: "Total Completed", field: "completed", },
    {
      title: "Issue Id",
      field: "issueid",
      // cellStyle: { width: "25%" },
      // headerStyle: { textAlign: "end" },
    },
    {
      title: "Summary",
      field: "issuetitle",
      cellStyle: { whiteSpace: 'nowrap', textAlign: 'left' },
      headerStyle: { textAlign: "center" },
      // cellStyle: { textAlign: "center", width: '25%' },
    },
    { title: "Assigned To", field: "assignedtodetails" },
    {
      title: "Original Estimate",
      field: "originalestimate",
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Stage",
      field: "currentstage",
      customSort: (a, b) => {
        // Define the order of stages
        const stageOrder = ["RFD", "DEV", "UAT", "DONE"];

        // Access the "StageCode" from the props of the React element
        const stageA = a.currentstage?.props?.children || "";
        const stageB = b.currentstage?.props?.children || "";

        // Compare stages based on their index in the order array
        const indexA = stageOrder.indexOf(stageA);
        const indexB = stageOrder.indexOf(stageB);

        // Return the comparison result
        return indexA - indexB;
      },
    },
    { title: "Date Moved", field: "DateMoved" },
    {
      title: "Due days",
      field: "effortremaining",
    },
    {
      title: "Progress",
      field: "sprint",
      headerStyle: { textAlign: "center" },
      cellStyle: { textAlign: "left", width: "14%" },
    },
  ];

  const data =
    userID != null
      ? tabledata.filter((e) => e.assignedto == userID)
      : tabledata;

  const theme = createTheme({
    palette: {
      grey: {
        200: "#your-light-grey-color",
        800: "#your-dark-grey-color",
      },
      // Other palette colors...
    },
    // Other theme settings...
  });

  const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? `green` : `lightgreen`,
    },
  }));

  const useStyles = makeStyles({
    root: {
      color: "rgb(254, 192, 84)", // Default color
    },
    greenSlider: {
      color: "green",
    },
    yellowSlider: {
      color: "yellow",
    },
    orangeSlider: {
      color: "orange",
    },
    redSlider: {
      color: "red",
    },
  });
  const classes = useStyles();
  const getColor = (percentage) => {
    if (percentage >= 75) return classes.greenSlider;
    else if (percentage >= 50) return classes.yellowSlider;
    else if (percentage == 0) return classes.redSlider;
    else return classes.root; // Default color
  };

  return (
    <div>
      <div className="Table shadow">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        ></link>
        <MaterialTable
          title={"History Sprint Report"}
          columns={Columns.map((e) => e)}
          data={
            data === ""
              ? ""
              : data.map((e) => {
                // console.log(e);
                return {
                  ...e,
                  issueid: (
                    <div>
                      <span>
                        {e.priority === "Highest" ? (<span><KeyboardDoubleArrowUpIcon className="filter-icons-1" fontSize="small" /></span>)
                          : e.priority === "High" ? (<span><KeyboardArrowUpIcon className="filter-icons-1" fontSize="small" /></span>)
                            : e.priority === "Med'ium" ? (<span><DragHandleIcon className="filter-icons" fontSize="small" /></span>)
                              : e.priority === "Low" ? (<span><KeyboardArrowDownIcon className="filter-icons-2" fontSize="small" /></span>)
                                : e.priority === "Lowest" ? (<span><KeyboardDoubleArrowDownIcon className="filter-icons-2" fontSize="small" /></span>)
                                  : ("")}
                      </span>
                      {" "}
                      <label>
                        {e.issuetype == "Story" ? (
                          <Bookmark
                            className="story fontdesign"
                            style={{ fontSize: "16px" }}
                          />
                        ) : e.issuetype == "Task" ? (
                          <CheckBoxRoundedIcon
                            className="task fontdesign"
                            style={{ fontSize: "16px" }}
                          />
                        ) : e.issuetype == "Bug" ? (
                          <StopCircleIcon
                            style={{ fontSize: "16px" }}
                            className="bug fontdesign"
                          />
                        ) : e.issuetype == "Epic" ? (
                          <OfflineBoltRoundedIcon
                            style={{ fontSize: "16px" }}
                            className="epic fontdesign"
                          />
                        ) : e.issuetype == "SubTask" ? (
                          <AddToPhotosIcon
                            style={{ fontSize: "16px" }}
                            className="kanbanicon task"
                            {...e.issueid}
                          />
                        ) : (
                          ""
                        )}
                      </label>
                      <label
                        style={{
                          color: "#198ae3",
                          marginLeft: "8px",
                          whiteSpace: "pre-wrap",
                        }}
                        sx={{ width: 25, height: 25 }}
                      >
                        {e.projectdetails?.[0].ProjName + "-" + e.issueid}
                      </label>
                    </div>
                  ),
                  issuetitle: <label className="ml-3">{e.issuetitle}</label>,
                  currentstage: (
                    <label
                      className={
                        e.currentstage?.[0]?.StageCode === "DONE"
                          ? "text-success"
                          : e.currentstage?.[0]?.StageCode === "RFD"
                            ? "text-warning"
                            : "text-info"
                      }
                    >
                      {e.currentstage?.[0]?.StageCode}
                    </label>
                  ),
                  originalestimate: <span className="ml-4">{e.originalestimate ? e.originalestimate + " Days" : 0 + " Days"}</span>,
                  // priority: (
                  //   <div>
                  //     <lable>
                  //       {e.priority === "Highest" ? (
                  //         <KeyboardDoubleArrowUpIcon
                  //           className="filter-icons-1"
                  //           fontSize="small"
                  //         />
                  //       ) : e.priority === "High" ? (
                  //         <KeyboardArrowUpIcon
                  //           className="filter-icons-1"
                  //           fontSize="small"
                  //         />
                  //       ) : e.priority === "Medium" ? (
                  //         <DragHandleIcon
                  //           className="filter-icons"
                  //           fontSize="small"
                  //         />
                  //       ) : e.priority === "Low" ? (
                  //         <KeyboardArrowDownIcon
                  //           className="filter-icons-2"
                  //           fontSize="small"
                  //         />
                  //       ) : e.priority === "Lowest" ? (
                  //         <KeyboardDoubleArrowDownIcon
                  //           className="filter-icons-2"
                  //           fontSize="small"
                  //         />
                  //       ) : (
                  //         "ok"
                  //       )}
                  //     </lable>
                  //     <label>{e.priority}</label>
                  //   </div>
                  // ),
                  effortremaining:
                    e?.effortremaining == null
                      ? "--"
                      : e?.effortremaining < 0
                        ? -e.effortremaining + " Days Due"
                        : e?.effortremaining + " Days",

                  DateMoved: e.currentstage?.[0]?.DateMoved,
                  assignedtodetails: <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      style={{ width: 30, height: 30 }}
                      alt="" src={allImage?.length > 0 ? allImage?.filter((x) => x.teammemberid == e.assignedto)?.[0]?.image : Unassigned} />
                    &nbsp;{e.assignedtodetails?.FN + e.assignedtodetails?.LN == "" || e.assignedtodetails?.FN == "null" ? "UnAssigned" : e?.assignedtodetails?.FN + " " + e?.assignedtodetails?.LN}</div>,
                  // sprintinweeks:
                  //   e.sprintinweeks == null
                  //     ? "0 Weeks"
                  //     : e.sprintinweeks + " Weeks",
                  // sprintstartdate:
                  //   e.sprintstartdate == null
                  //     ? "Development Yet to Start"
                  //     : moment(e.sprintstartdate).format("DD-MMM-YYYY"),
                  // sprintenddate:
                  //   e.sprintenddate == null
                  //     ? "Development Yet to Start"
                  //     : moment(e.sprintenddate).format("DD-MMM-YYYY"),
                  sprint:
                    e.originalestimate == null ? null : (
                      // <Col className="tb">
                      //   {
                      //     <OverlayTrigger
                      //       trigger={["hover", "hover"]}
                      //       placement="right"
                      //       overlay={
                      //         <Tooltip id="button-tooltip-1 ">
                      //           {e.originalestimate + "days"}
                      //         </Tooltip>
                      //       }
                      //     >
                      //       {/* <Slider
                      //         className={getColor(
                      //           e.effortremaining < 0
                      //             ? 20
                      //             : ((e.effortpending - e.effortremaining) /
                      //                 e.originalestimate) *
                      //                 100
                      //         )}
                      //         size="small"
                      //         value={
                      //           e.effortremaining < 0
                      //             ? 20
                      //             : ((e.effortpending - e.effortremaining) /
                      //                 e.originalestimate) *
                      //               100
                      //         }
                      //         aria-label="Small"
                      //         valueLabelDisplay="auto"
                      //         disabledSlider={true}
                      //       /> */}
                      //       <Slider
                      //         className={getColor(
                      //           e?.percentageprogressed === ""
                      //             ? 0
                      //             : Number(e?.percentageprogressed)
                      //         )}
                      //         size="small"
                      //         value={
                      //           e?.percentageprogressed === ""
                      //             ? 0
                      //             : Number(e?.percentageprogressed)
                      //         }
                      //         aria-label="Small"
                      //         valueLabelDisplay="auto"
                      //         disabledSlider={true}
                      //       />
                      //       {/* <LinearProgress className="progress-bar" variant="determinate" value={e.effortremaining < 0 ? 20 : (e.effortpending - e.effortremaining) / (e.originalestimate) * 100} />
                      //       {e.effortremaining < 0 ? 20 : (e.effortpending - e.effortremaining) / (e.originalestimate) * 100} */}

                      //       {/* <ProgressBar multi className="bg-white pbx"> */}
                      //       {/* <ProgressBar
                      //       className="pbr "
                      //       variant="success"
                      //       animated
                      //       style={
                      //         e.riskofdelivery === "G"
                      //           ? { background: "Green" }
                      //           : e.riskofdelivery === "R"
                      //             ? { background: "Red" }
                      //             : e.riskofdelivery === "Y"
                      //               ? { background: "Yellow" }
                      //               : null
                      //       }
                      //       now={
                      //         e.effortremaining < 0
                      //           ? 100
                      //           : ((e.effortpending - e.effortremaining) /
                      //             e.originalestimate) *
                      //           100
                      //       }
                      //     /> */}
                      //       {/* <ProgressBar
                      //           className="pbr"
                      //           animated
                      //           style={
                      //             e.riskofdelivery === "G"
                      //               ? { background: "Green" }
                      //               : e.riskofdelivery === "R"
                      //               ? { background: "Red" }
                      //               : e.riskofdelivery === "Y"
                      //               ? { background: "Yellow" }
                      //               : null
                      //           }
                      //           // now={e.effortremaining<0?20:(e.effortpending - e.effortremaining)/(e.originalestimate) * 100}

                      //           now={e.effortremaining + e.effortpending}
                      //         /> */}
                      //       {/* </ProgressBar> */}
                      //     </OverlayTrigger>
                      //   }
                      //   {/* <Row>
                      //   <label style={{ fontSize: 7 }}>
                      //     {e?.originalestimate}D{" "}
                      //     <label style={{ fontSize: 5 }}>
                      //       logged&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      //     </label>
                      //   </label>
                      //   <label
                      //     style={{ fontSize: 7, alignSelf: "flex-end" }}
                      //   >
                      //     {e?.effortremaining}D{" "}
                      //     <label style={{ fontSize: 5 }}>Remaining</label>
                      //   </label>
                      // </Row> */}
                      // </Col>
                      <Col>
                        {/* <Slider
                            className={getColor(
                              e?.percentageprogressed === ""
                                ? 0
                                : Number(e?.percentageprogressed)
                            )}
                            size="small"
                            value={
                              e?.percentageprogressed === ""
                                ? 0
                                : Number(e?.percentageprogressed)
                            }
                            aria-label="Small"
                            valueLabelDisplay="on"
                            disabledSlider={true}
                          /> */}
                        <ProgressBar
                          style={{ height: "12px" }}
                          variant={
                            e.percentageprogressed >= 75
                              ? "success"
                              : e.percentageprogressed === 0
                                ? "danger"
                                : "warning"
                          }
                          // animated
                          now={e.percentageprogressed}
                          label={
                            <span style={{ fontSize: "10px" }}>
                              {e.percentageprogressed}
                            </span>
                          }
                        />
                      </Col>
                    ),
                };
              })
          }
          components={{
            Pagination: PatchedPagination,
          }}
          options={{
            actionsColumnIndex: -1,
            exportAllData: true,
            exportButton: true,
            columnResizable: true,
            filtering: false,
            sorting: true,
            paging: true,
            pageSize: 30, // make initial page size
            emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
            pageSizeOptions: [20, 30, 40, 50], // rows selection options

            headerStyle: {
              whiteSpace: "nowrap",
              fontWeight: "700",
              fontSize: "medium",
              // backgroundColor: "#eef2f7",
              color: "black",
              padding: "12px ",
              textAlign: "left",
            },
            cellStyle: {
              textAlign: "left",
              fontSize: "medium",
              padding: "21px",
              color: "#6c757d",
              whiteSpace: "nowrap",
            },
            rowStyle: (rowData) => {
              // console.log(rowData)
              if (rowData.tableData.id % 2 != 0) {
                // return { backgroundColor: "#cddef085" };
              }
            },
          }}

        // actions={[
        //     rowData => ({
        //         icon: "backspace",
        //         tooltip: "Withdraw",
        //         disabled: rowData.isapproved == "Approved" || rowData.isapproved == "Rejected" || rowData.isapproved == "Withdrawn",
        //         // onClick: (event, rowData) => {
        //         //     withdrawn(rowData)
        //         // },
        //     }),
        //     rowData => ({
        //         icon: "message",
        //         tooltip: rowData.approverremarks,
        //         hidden: rowData.approverremarks == null,
        //     }),
        // ]}
        />
      </div>
    </div>
  );
}
export default HistorySprintReportTable;
