import moment from "moment";
let date = moment();

const clientid = JSON.parse(localStorage.getItem("clientid"));
const clientdetails = JSON.parse(
    localStorage.getItem("Orgsubscriptiondetails")
);
const intialState = {
    ClientId: clientid,
    clientdetails: clientdetails,
    Category_master: [],
    Incident_SLA_Master: [],
    Incident_SLA_Details: [],
    PriorityOption: [
        { value: "P1", label: "P1-System down" },
        { value: "P2", label: "P2-Wide impact" },
        { value: "P3", label: "P3-Moderate impact" },
        { value: "P4", label: "P4-Low impact", code: "Low impact" }
    ],
    statusoption: [
        { value: "", label: "New" },
        { value: "Acknowledge", label: "Acknowledge" },
        { value: "Resolved", label: "Resolved" },
        { value: "User Verfication", label: "User Verfication" },
    ],
    Impactoption: [
        { value: "1", label: "High" },
        { value: "2", label: "Medium" },
        { value: "3", label: "Low" },
    ],
    urgencyoption: [
        { value: "1", label: "High" },
        { value: "2", label: "Medium" },
        { value: "3", label: "Low" },
    ],
    dateoption: [
        {
            value: moment().subtract(1, "months").startOf("month"),
            label: "OnemonthBefore",
        },
        {
            value: moment().subtract(1, "days").startOf("day"),
            label: "Yesterday",
        },

        { value: moment(), label: "Today" },
    ],
    IncidentId: null,
    priiortyfilter: "",
    cateoryff: "",
    statusfilter: "",
    AssignedIncident: [],
    CreatedIncident: [],

};
export default intialState;
