import React, { useState, useEffect } from "react";
import axios from "../../axios";
import Select from "react-select";
import ShowReleaseProgress from "./ShowReleaseProgress";
import { Button, Input, Slider, TextField } from "@material-ui/core";
import moment from "moment";
// import PopoverBody from 'react-bootstrap/PopoverBody'
// import PopoverHeader from 'react-bootstrap/PopoverHeader'
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DevepolerSummary from "./HistorySummary";
import FilterListIcon from "@mui/icons-material/FilterList";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ReactQuill from "react-quill";
import Toolbar, { modules, formats } from "./Toolbar";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Check from "@mui/icons-material/Check";
import clsx from "clsx";
import { Avatar } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import PatchedPagination from "../../PatchedPagination";
import MaterialTable from "material-table";
import { toast, Slide } from "react-toastify";
import { styled, withStyles } from "@material-ui/styles";
import StepConnector from "@material-ui/core/StepConnector";
import { Card, Col, Row, CardBody, CardHeader, Collapse } from "reactstrap";
import "./Showrelease.css";
import { Box, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import BasicTable from "./datagrid";
import HistoryTable from "./HistoryDataGrid";
import slacknotify from "../../slacknotify";
import { useDispatch, useSelector } from "react-redux";
import { HANDLE_ON_CHANGE, PERCENTAGE } from "../../redux/CommonInitialsate";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SprintDeploymentDetails from "./SprintDeploymentDetails";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DateFnsUtils from "@date-io/date-fns";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Unassigned from "../../assets/images/avatar.png";
import Axios from "axios";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MultiSelect from "./MultiSelect";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Popover from "@material-ui/core/Popover";
import Cookies from "universal-cookie";
import zIndex from "@material-ui/core/styles/zIndex";

toast.configure();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function Historyrelease(
  {
    // projectName,
    // projectlabel,
    // projectCode,
    // historyrelease,
    // sethistoryrelease,
    // KBRefresh,
    // projectRole,
  }
) {
  let clientid = JSON.parse(localStorage.getItem("clientid"));
  // rpc/fun_mgrprojects
  const {
    projectMappingData,
    percentage,
    allImage,
    projectRole,
    projectCode,
    historyrelease,
    devozyProjects,
    mydatashow,
    mydatashow1,
    labelvi,
    Assetoptions,
    Publicholiday,
    projectName,
    KanbanBoardData,
    channelname,
    slacktoken
  } = useSelector((state) => state.user);

  const cookies = new Cookies();

  let arr123 = JSON.parse(sessionStorage.getItem("Roledetails"));
  let userdetails = JSON.parse(localStorage.getItem("clientdetails"));
  let emplogin = JSON.parse(localStorage.getItem("Empdetails"));
  let EMpid = emplogin?.[0]?.ClientUserId;
  let login = emplogin != null ? emplogin : null;
  let slack = localStorage.getItem("Slack")
  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == "11004")?.[0]
    ?.RoleCode;
  const api = "rpc/fun_mgrprojects?managerid=" + emplogin?.[0]?.ClientUserId;
  const [showreleaseboard, setshowreleaseboard] = useState([]);
  const [IssueDetailsShow, setIssueDetailsShow] = useState([]);
  const [projectname, setprojectname] = useState("");
  const [projectclname, setprojectclname] = useState("");
  const [Releasever, setReleasever] = useState([]);
  const [assignedTo, setAssignedTo] = useState("");
  const [Loading, setLoading] = useState(false);
  const [displaydata, setDisplayData] = useState([]);
  const [totalDaysByUser, setTotalDaysByUser] = useState({});
  const [projectDetails, setprojectDetails] = useState([]);
  const [projectselectedlabel, setprojectselectedlabel] = useState("");
  const [releaseselectedvalue, setreleaseselectedvalue] = useState("");
  const [vesionselectedvalue, setvesionselectedvalue] = useState(" ");
  const [Valueshow, setValueshow] = useState([]);
  // const [mydatashow, setmydatashow] = useState([]);
  // const [mydatashow1, setmydatashow1] = useState([]);
  const [ShowreleasePopup, setShowreleasePopup] = useState(false);
  const [issueV, setissueV] = useState("0");
  const [issueD, setissueD] = useState("0");
  const [issueP, setissueP] = useState("0");
  const [issueTD, setissueTD] = useState("0");
  const [spil, setspil] = useState("0");
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingweek, setIsEditingweek] = useState(false);
  const [endDateedit, setEndDate] = useState(new Date());
  const [issueVdata, setissueVdata] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [issueDdata, setissueDdata] = useState([]);
  const [issuePdata, setissuePdata] = useState([]);
  const [issueTDdata, setissueTDdata] = useState([]);
  const [removeopen, setremoveopen] = useState(false);
  const [Issuepopdetails, setIssuepopdetails] = useState([]);
  const [ShowRefresh, setShowRefresh] = useState(false);
  const [KanbanBoardData1, setKanbanBoardData1] = useState([]);
  const [selectedrow, setselectedrow] = useState(null);
  const [projectid, setprojectid] = useState(null);
  // const [labelvi, setlabelvi] = useState();
  const [reason, setreason] = useState([{ label: "" }]);
  const [notes, setnotes] = useState([{ note: "" }]);
  const [dropdownOption, setDropdownOption] = useState([]);
  const [sprintdata, setSprintData] = useState([]);
  const [percentagearr, setPercentagearr] = useState([]);
  const [notesdata, Setnotesdata] = useState("");
  const [remove, setremove] = useState([
    {
      ReleaseDate: moment().format("YYYY-MM-DD"),
      IsReleaseFrozen: "Y",
      RemovedTickets: {
        IssueId: null,
        IssueTitle: null,
        AssignedToDetails: null,
        ASsignedto: null,
        Reason: null,
        notes: null,
      },
    },
  ]);
  const [chartdata, setChartdata] = useState([]);
  const [chart, setChart] = useState([]);
  const [cmt, setcmt] = useState("");
  const [cmt1, setcmt1] = useState("");
  const [cmt2, setcmt2] = useState("");
  const [cmt3, setcmt3] = useState("");
  const [gdata, setGdata] = useState([]);
  const [gdata2, setGdata2] = useState([]);
  const [rfddd, setrf] = useState([]);
  const [rfddd1, setrf1] = useState([]);
  const [rfddd2, setrf2] = useState([]);
  const [fo, setfo] = useState([]);
  const [fo1, setfo1] = useState([]);
  const [fo2, setfo2] = useState([]);
  const [fo3, setfo3] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [issueTitle, setIssueTitle] = useState("");
  const [selectedepic, setSelectedepic] = useState([])

  // const [devozyProjects, setDevozyProjects] = useState([]);
  const [sprintWeeks, setSprintWeeks] = useState(
    mydatashow?.[0]?.Sprintinweeks
  );
  const [selectedissues, setSelectedIssues] = useState(null);
  const [sprintSpinner, setSprintSpinner] = useState(false);

  const BootstrapButton = styled(Button)({
    textTransform: "none",
    fontSize: 13,
    padding: "6px 12px",
    margin: 10,
    border: "0px solid",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const toggle1 = () => setIsOpen1(!isOpen1);
  const userorg = userdetails?.[0]?.OrgDetails;

  const multiplesm =
    localStorage.getItem("multiplesm") == "fullaccess_to_all_sms";
  const [submitCheck, setSubmitCheck] = useState(false);

  useEffect(() => {
    axios
      .get(
        `agilesaas_devozy_project_mapping?AgileProjId=eq.${projectCode}&select=DevozyProjectDetails`
      )
      .then((res) => {
        dispatch(
          HANDLE_ON_CHANGE({
            name: "devozyProjects",
            value: res.data?.[0]?.DevozyProjectDetails,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .get(api)
    //   .then((response) => {
    //     let data123 = response.data;
    //     data123.map((item) => {
    //       let findItem = projectDetails.find(
    //         (x) => x.projectcode === item.projectcode
    //       );
    //       if (!findItem) projectDetails.push(item);
    //     });
    //   })
    //   .catch((error) => console.log(error));

    // axios
    // .get(
    //   "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=eq." +
    //     e.issueid +
    //     "&order=UpdatedDate.desc"
    // )
    // .then((result) => {
    //   let data = result.data.map((e) => {
    //     return {
    //       EffortPending: e.EffortPending,
    //       EffortRemaining: e.EffortRemaining,
    //       RiskofDelivery: e.RiskofDelivery,
    //       UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
    //       IssueId: e.IssueId,
    //       UpdatedBy: e.UpdatedBy,
    //       PercentageProgressed: e.PercentageProgressed,
    //     };
    //   });

    //   displaydata.push(data);
    // });

    const csapi = multiplesm
      ? `rpc/fun_agilesaascurrentsprint?projid=${projectCode}&select=sprintstartdate,issueid`
      : `rpc/fun_agilesaascurrentsprint?projid=${projectCode}&createdby=eq.${EMpid}&select=sprintstartdate,issueid`;
    axios.get(csapi).then((res) => {
      setSprintData(res.data);
      const getdata = res.data;
      const disdataid = getdata?.map((e) => e.issueid);
      const issueIds = disdataid?.join(",");
      axios
        .get(
          `agilesaas_issue_details?select=IssueId,LinkToIssues,ExceptiontoSkipSPEstimation,IssueTitle,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,ParentIssue,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed),Issuehistory:agilesaas_issue_history(IssueHistoryId,IssueId,ChangedBy,ChangedByDetails,ChangedDate,UpdatedField,UpdatedFrom,UpdatedTo,UpdateSequence,IsActive,Status,ClientId,ProjectId)&IssueId=in.(${issueIds})&ProjectId=eq.${projectCode}`
        )
        .then((response) => {
          setIssueDetailsShow(response.data);
          setKanbanBoardData1(response.data);
          let pod = response.data.map((issue) => issue.AllProgress).flat();
          let poh = response.data.map((issue) => issue.Issuehistory).flat();

          let data = pod.map((e) => {
            return {
              EffortPending: e.EffortPending,
              EffortRemaining: e.EffortRemaining,
              RiskofDelivery: e.RiskofDelivery,
              UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
              IssueId: e.IssueId,
              UpdatedBy: e.UpdatedBy,
              PercentageProgressed: e.PercentageProgressed,
            };
          });
          displaydata.push(data);

          let sampldata = [];

          sampldata.push(poh.filter((e) => e.UpdatedField == "Kanban Changes"));
          const mergedArray = [].concat(...sampldata);
          setGdata(mergedArray);
        })
        .catch((error) => console.log(error));
      // getdata.map((e, i) => {
      //   axios
      //     .get(
      //       "agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=eq." +
      //         e.issueid +
      //         "&order=UpdatedDate.desc"
      //     )
      //     .then((result) => {
      //       let data = result.data.map((e) => {
      //         return {
      //           EffortPending: e.EffortPending,
      //           EffortRemaining: e.EffortRemaining,
      //           RiskofDelivery: e.RiskofDelivery,
      //           UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
      //           IssueId: e.IssueId,
      //           UpdatedBy: e.UpdatedBy,
      //           PercentageProgressed: e.PercentageProgressed,
      //         };
      //       });

      //       displaydata.push(data);
      //     });
      // });
      // axios
      //   .get(
      //     `agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=in.(${issueIds})&order=UpdatedDate.desc&ProjectId=eq.${projectCode}`
      //   )
      //   .then((result) => {
      //     let data = result.data.map((e) => {
      //       return {
      //         EffortPending: e.EffortPending,
      //         EffortRemaining: e.EffortRemaining,
      //         RiskofDelivery: e.RiskofDelivery,
      //         UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
      //         IssueId: e.IssueId,
      //         UpdatedBy: e.UpdatedBy,
      //         PercentageProgressed: e.PercentageProgressed,
      //       };
      //     });
      //     displaydata.push(data);
      //   });
    });
    axios
      .get(
        `agilesaas_releases?order=SprintStartDate.asc&ProjectId=eq.${projectCode}`
      )
      .then((response) => {
        let a = response.data?.filter((k) => k.ProjectId == projectCode);

        let getData = a.map((c) => {
          let labeld =
            c.IsReleaseFrozen == "Y"
              ? "Released"
              : moment(c.SprintStartDate) < moment() &&
                moment(c.SprintEndDate) > moment()
                ? "In-Flight"
                : moment() < moment(c.SprintStartDate)
                  ? "Planned"
                  : moment(c.SprintEndDate) < moment()
                    ? "To Be Released"
                    : null;
          return {
            value: c.ReleaseName,
            label: c.ReleaseName + " " + labeld,
            id: c.ReleaseId,
          };
        });
        setDropdownOption(getData);
        // console.log("optionversion release called");
        const d = getData.filter((c) => c.label?.includes("In-Flight"));
        console.log("getdatyata", d?.[0]);

        // const rrl = response.data?.filter((e) => e.ReleaseName === d?.[0]?.value);
        // const releaseid = d.length > 0 ? d?.[0]?.id : getData?.[0]?.id
        setReleasever(response.data);
        setIsLoading(false);
        setValueshow(a);
        optionversionchange(d.length > 0 ? d?.[0] : getData?.[0]);
      })
      .catch((error) => console.log(error, "Progress"));
  }, []);

  // const fetchData = () => {
  //   // useEffect 1
  //   axios
  //     .get(
  //       `agilesaas_devozy_project_mapping?AgileProjId=eq.${projectCode}&select=DevozyProjectDetails`
  //     )
  //     .then((res) => {
  //       // console.log(res.data, "mapping data");
  //       // setDevozyProjects(res.data?.[0]?.DevozyProjectDetails);
  //       dispatch(
  //         HANDLE_ON_CHANGE({
  //           name: "devozyProjects",
  //           value: res.data?.[0]?.DevozyProjectDetails,
  //         })
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   // useEffect 2
  //   // axios
  //   //   .get(api)
  //   //   .then((response) => {
  //   //     let data123 = response.data;
  //   //     data123.map((item) => {
  //   //       let findItem = projectDetails.find(
  //   //         (x) => x.projectcode === item.projectcode
  //   //       );
  //   //       if (!findItem) projectDetails.push(item);
  //   //     });
  //   //   })
  //   //   .catch((error) => console.log(error));

  //   // useEffect 3

  //   // useEffect 4
  //   const csapi = multiplesm
  //     ? `rpc/fun_agilesaascurrentsprint?projid=${projectCode}&select=sprintstartdate,issueid`
  //     : `rpc/fun_agilesaascurrentsprint?projid=${projectCode}&createdby=eq.${EMpid}&select=sprintstartdate,issueid`;
  //   axios.get(csapi).then((res) => {
  //     setSprintData(res.data);
  //     const getdata = res.data;
  //     const disdataid = getdata?.map((e) => e.issueid);
  //     const issueIds = disdataid?.join(",");
  //     axios
  //       .get(
  //         `agilesaas_issue_details?select=IssueId,LinkToIssues,ExceptiontoSkipSPEstimation,IssueTitle,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,ParentIssue,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed),Issuehistory:agilesaas_issue_history(IssueHistoryId,IssueId,ChangedBy,ChangedByDetails,ChangedDate,UpdatedField,UpdatedFrom,UpdatedTo,UpdateSequence,IsActive,Status,ClientId,ProjectId)&IssueId=in.(${issueIds})&ProjectId=eq.${projectCode}`
  //       )
  //       .then((response) => {
  //         setIssueDetailsShow(response.data);
  //         setKanbanBoardData(response.data);
  //         let pod = response.data.map((issue) => issue.AllProgress).flat();
  //         let poh = response.data.map((issue) => issue.Issuehistory).flat();

  //         let data = pod.map((e) => {
  //           return {
  //             EffortPending: e.EffortPending,
  //             EffortRemaining: e.EffortRemaining,
  //             RiskofDelivery: e.RiskofDelivery,
  //             UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
  //             IssueId: e.IssueId,
  //             UpdatedBy: e.UpdatedBy,
  //             PercentageProgressed: e.PercentageProgressed,
  //           };
  //         });
  //         displaydata.push(data);

  //         let sampldata = [];

  //         sampldata.push(poh.filter((e) => e.UpdatedField == "Kanban Changes"));
  //         const mergedArray = [].concat(...sampldata);
  //         setGdata(mergedArray);
  //       })
  //       .catch((error) => console.log(error));

  //     // axios
  //     // .get(
  //     //   `agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=in.(${issueIds})&order=UpdatedDate.desc`
  //     // )
  //     // .then((result) => {
  //     //   let data = result.data.map((e) => {
  //     //     return {
  //     //       EffortPending: e.EffortPending,
  //     //       EffortRemaining: e.EffortRemaining,
  //     //       RiskofDelivery: e.RiskofDelivery,
  //     //       UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
  //     //       IssueId: e.IssueId,
  //     //       UpdatedBy: e.UpdatedBy,
  //     //       PercentageProgressed: e.PercentageProgressed,
  //     //     };
  //     //   });
  //     //   displaydata.push(data);
  //     // });
  //   });

  //   // useEffect 5
  //   axios
  //     .get(
  //       `agilesaas_releases?order=SprintStartDate.asc&ProjectId=eq.${projectCode}`
  //     )
  //     .then((response) => {
  //       setReleasever(response.data);
  //       let a = response.data;

  //       let getData = a.map((c) => {
  //          let matchingRelease = a.find(
  //           (k) => k.ReleaseName === vesionselectedvalue
  //         );
  //         let labeld =
  //         c.IsReleaseFrozen == "Y"
  //             ? "Released"
  //             : moment(c.SprintStartDate) < moment() &&
  //               moment(c.SprintEndDate) > moment()
  //             ? "In-Flight"
  //             : moment() < moment(c.SprintStartDate)
  //             ? "Planned"
  //             : moment(c.SprintEndDate) < moment()
  //             ? "To Be Released"
  //             : null;
  //             // let matchingRelease = a.find(
  //             //   (k) => k.ReleaseName === vesionselectedvalue
  //             // );
  //         return {
  //           value: vesionselectedvalue,
  //           label: vesionselectedvalue+ " " + labeld,
  //           id:matchingRelease ? matchingRelease.ReleaseId : null,
  //         };
  //       });
  //       setDropdownOption(getData);
  //       const d = getData.filter((c) => c);
  //       console.log(d,"DDDD");

  //       // const rrl = response.data?.filter(
  //       //   (e) => e.ReleaseName === d?.[0]?.value
  //       // );
  //       // const releaseid = d.length > 0 ? d?.[0]?.id : getData?.[0]?.id

  //       setIsLoading(false);
  //       optionversionchange(d.length > 0 ? d?.[0] : getData?.[0]);
  //       setValueshow(a);
  //     })
  //     .catch((error) => console.log(error, "Progress"));

  //   // useEffect 6
  //   // const data = mydatashow;
  //   // let idss = data.length > 0 && data?.map((x) => x?.IssueIdS);
  //   // let sampldata = [];
  //   // const issueIds = idss.length > 0 && idss?.join(",");
  //   // if (idss.length > 0) {
  //   //   axios
  //   //     .get(`agilesaas_issue_history?IssueId=in.(${issueIds})`)
  //   //     .then((res) => {
  //   //       sampldata.push(
  //   //         res.data.filter((e) => e.UpdatedField == "Kanban Changes")
  //   //       );
  //   //       const mergedArray = [].concat(...sampldata);
  //   //       setGdata(mergedArray);
  //   //     });
  //   // }
  // };

  const fetchData = () => {
    const currentSelectedOption = dropdownOption.find(
      (option) => option.value === vesionselectedvalue
    );
    setIsSpinning(true);
    // useEffect 1
    axios
      .get(
        `agilesaas_devozy_project_mapping?AgileProjId=eq.${projectCode}&select=DevozyProjectDetails`
      )
      .then((res) => {
        dispatch(
          HANDLE_ON_CHANGE({
            name: "devozyProjects",
            value: res.data?.[0]?.DevozyProjectDetails,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    // useEffect 4
    const csapi = multiplesm
      ? `rpc/fun_agilesaascurrentsprint?projid=${projectCode}&select=sprintstartdate,issueid`
      : `rpc/fun_agilesaascurrentsprint?projid=${projectCode}&createdby=eq.${EMpid}&select=sprintstartdate,issueid`;
    axios.get(csapi).then((res) => {
      setSprintData(res.data);
      const getdata = res.data;
      const disdataid = getdata?.map((e) => e.issueid);
      const issueIds = disdataid?.join(",");
      axios
        .get(
          `agilesaas_issue_details?select=IssueId,LinkToIssues,ExceptiontoSkipSPEstimation,IssueTitle,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,ParentIssue,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed),Issuehistory:agilesaas_issue_history(IssueHistoryId,IssueId,ChangedBy,ChangedByDetails,ChangedDate,UpdatedField,UpdatedFrom,UpdatedTo,UpdateSequence,IsActive,Status,ClientId,ProjectId)&IssueId=in.(${issueIds})&ProjectId=eq.${projectCode}`
        )
        .then((response) => {
          setIssueDetailsShow(response.data);
          setKanbanBoardData1(response.data);
          let pod = response.data.map((issue) => issue.AllProgress).flat();
          let poh = response.data.map((issue) => issue.Issuehistory).flat();

          let data = pod.map((e) => {
            return {
              EffortPending: e.EffortPending,
              EffortRemaining: e.EffortRemaining,
              RiskofDelivery: e.RiskofDelivery,
              UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
              IssueId: e.IssueId,
              UpdatedBy: e.UpdatedBy,
              PercentageProgressed: e.PercentageProgressed,
            };
          });
          displaydata.push(data);

          let sampldata = [];

          sampldata.push(poh.filter((e) => e.UpdatedField == "Kanban Changes"));
          const mergedArray = [].concat(...sampldata);
          setGdata(mergedArray);
        })
        .catch((error) => console.log(error));
    });

    // useEffect 5
    axios
      .get(
        `agilesaas_releases?order=SprintStartDate.asc&ProjectId=eq.${projectCode}`
      )
      .then((response) => {
        setReleasever(response.data);
        let a = response.data;

        let getData = a.map((c) => {
          let labeld =
            c.IsReleaseFrozen == "Y"
              ? "Released"
              : moment(c.SprintStartDate) < moment() &&
                moment(c.SprintEndDate) > moment()
                ? "In-Flight"
                : moment() < moment(c.SprintStartDate)
                  ? "Planned"
                  : moment(c.SprintEndDate) < moment()
                    ? "To Be Released"
                    : null;
          return {
            value: c.ReleaseName,
            label: c.ReleaseName + " " + labeld,
            id: c.ReleaseId,
          };
        });

        setDropdownOption(getData);

        // Re-select the saved option if it exists
        const reselectedOption = getData.find(
          (option) => option.value === vesionselectedvalue
        );
        optionversionchange(reselectedOption || getData[0]);

        // Also ensure the selected option is visually marked as selected
        setvesionselectedvalue(
          reselectedOption ? reselectedOption.value : getData[0].value
        );

        setValueshow(a);
        setIsLoading(false);
        setTimeout(() => {
          setIsSpinning(false);
        }, 5000);
      })
      .catch((error) => console.log(error, "Progress"));
  };

  // console.log(devozyProjects)
  let SelectedCardId;
  const dispatch = useDispatch();
  var currentddate = new Date();

  var dayOfWeek = currentddate.getDay();
  const checkdate = moment().diff(
    moment(sprintdata?.[0]?.sprintstartdate),
    "days"
  ); // Output the updated date in YYYY-MM-DD format
  const finalIndex =
    dayOfWeek === 0
      ? checkdate - 2
      : dayOfWeek === 6
        ? checkdate - 1
        : checkdate;
  // function rpcid(projectid) {
  //   axios
  //     .get(`rpc/fun_agilesaasproductivityreport?projid=${projectid}&releaseid=eq.${releaseid}`)
  //     .then((response) => {
  //       // setprojectDetails
  //       setshowreleaseboard(response.data);
  //     })
  //     .catch((error) => console.log(error, "Progress"));

  //   // axios
  //   //   .get(api)
  //   //   .then((response) => {
  //   //     let data123 = response.data;
  //   //     data123.map((item) => {
  //   //       let findItem = projectDetails.find(
  //   //         (x) => x.projectcode === item.projectcode
  //   //       );
  //   //       if (!findItem) projectDetails.push(item);
  //   //     });
  //   //   })
  //   //   .catch((error) => console.log(error));

  //   // axios
  //   //   .get("agile_issue_details")
  //   //   .then((response) => {
  //   //     setIssueDetailsShow(response.data);
  //   //     setKanbanBoardData(response.data);
  //   //   })
  //   //   .catch((error) => console.log(error));
  //   axios
  //     .get(`agilesaas_releases?ProjectId=eq.${projectCode}`)
  //     .then((response) => {
  //       setReleasever(response.data);
  //     })
  //     .catch((error) => console.log(error));
  // }
  const Reasonoption = [
    { label: "Requirement clarification", value: "Requirement clarification" },
    { label: "Technical issue", value: "Technical issue" },
    { label: "Large process", value: "large process" },
  ];

  function handlereason(e, i) {
    let a = [...remove];

    a?.splice(i, 1, {
      ReleaseDate: moment().format("YYYY-MM-DD"),
      IsReleaseFrozen: "Y",
      RemovedTickets: {
        IssueId: null,
        IssueTitle: null,
        AssignedToDetails: null,
        Assignedto: null,
        Reason: e.value,
        notes: null,
      },
    });
    // console.log(a, "Aaaa");
    setremove(a);

    // let reasonu = [...reason];

    // reasonu.splice(i, 1, {
    //   label: e.label,
    // });

    // setreason(reasonu);
  }

  const handlenotes = (e, i) => {
    let a = [...remove];

    a?.splice(i, 1, {
      ReleaseDate: moment().format("YYYY-MM-DD"),
      IsReleaseFrozen: "Y",
      RemovedTickets: {
        IssueId: null,
        IssueTitle: null,
        AssignedToDetails: null,
        ASsignedto: null,
        Reason: remove[i]?.RemovedTickets?.Reason,
        notes: e.target.value ? e.target.value : null,
      },
    });
    setremove(a);

    // let notevalue = [...notes];

    // notevalue.splice(item?.item, 1, {
    //   note: e.label,
    // });

    // setnotes(notevalue);
  };
  // function releaserefresh() {
  //   setIssueDetailsShow([]);
  //   setprojectname("");
  //   setprojectclname("");
  //   setReleasever([]);
  //   setprojectselectedlabel("");
  //   setreleaseselectedvalue("");
  //   setvesionselectedvalue("");
  //   setValueshow([]);
  //   // setmydatashow1([]);
  //   // setmydatashow([]);
  //   dispatch(HANDLE_ON_CHANGE({ name: 'mydatashow', value: [] }))
  //   dispatch(HANDLE_ON_CHANGE({ name: 'mydatashow1', value: [] }))
  //   setShowreleasePopup(false);
  //   // setshowreleaseboard([]);
  //   setKanbanBoardData([]);
  //   // axios
  //   //   .get("agile_issue_details")
  //   //   .then((response) => {
  //   //     // setprojectDetails
  //   //     setIssueDetailsShow(response.data);
  //   //     setKanbanBoardData(response.data);

  //   //   })
  //   //   .catch((error) => console.log(error));

  //   axios
  //     .get(`agilesaas_releases?ProjectId=eq.${projectCode}`)
  //     .then((response) => {
  //       // setprojectDetails
  //       setReleasever(response.data);
  //     })
  //     .catch((error) => console.log(error));
  //   axios
  //     .get("rpc/fun_agilesaasproductivityreport?projid=" + projectid)
  //     .then((response) => {
  //       // setprojectDetails
  //       setshowreleaseboard(response.data);
  //     })
  //     .catch((error) => console.log(error, "Progress"));
  // }
  function refresh() {
    setIssueDetailsShow([]);
    setprojectname("");
    setprojectclname("");
    Setnotesdata("");
    setReleasever([]);
    setprojectselectedlabel("");
    // setreleaseselectedvalue("");
    setvesionselectedvalue();
    setValueshow([]);
    // setmydatashow1([]);
    // setmydatashow([]);
    dispatch(HANDLE_ON_CHANGE({ name: "mydatashow", value: [] }));
    dispatch(HANDLE_ON_CHANGE({ name: "mydatashow1", value: [] }));
    setShowreleasePopup(false);
    // setshowreleaseboard([]);
    setKanbanBoardData1([]);

    // axios
    //   .get("agile_issue_details")
    //   .then((response) => {
    //     // setprojectDetails
    //     setIssueDetailsShow(response.data);
    //     setKanbanBoardData(response.data);

    //   })
    //   .catch((error) => console.log(error));

    // axios
    //   .get(`agilesaas_releases?ProjectId=eq.${projectCode}`)
    //   .then((response) => {
    //     // setprojectDetails
    //     setReleasever(response.data);
    //   })
    //   .catch((error) => console.log(error));
    // axios
    //   .get("rpc/fun_agilesaasproductivityreport?projid=" + projectCode)
    //   .then((response) => {
    //     // setprojectDetails
    //     setshowreleaseboard(response.data);
    //   })
    //   .catch((error) => console.log(error, "Progress"));
    axios
      .get(
        `agilesaas_releases?order=SprintStartDate.asc&ProjectId=eq.${projectCode}`
      )
      .then((response) => {
        let a = response.data;
        let getData = a.map((c) => {
          let labeld =
            c.IsReleaseFrozen == "Y"
              ? "Released"
              : moment(c.SprintStartDate) < moment() &&
                moment(c.SprintEndDate) > moment()
                ? "In-Flight"
                : moment() < moment(c.SprintStartDate)
                  ? "Planned"
                  : moment(c.SprintEndDate) < moment()
                    ? "To Be Released"
                    : null;
          return {
            value: c.ReleaseName,
            label: c.ReleaseName + " " + labeld,
            id: c.ReleaseId,
          };
        });
        setDropdownOption(getData);
        const d = getData.filter((c) => c.label?.includes("In-Flight"));
        setReleasever(response.data);
        setValueshow(a);
        optionversionchange(d.length > 0 ? d?.[0] : getData?.[0]);
      })
      .catch((error) => console.log(error, "Progress"));
  }
  // function refresh2(vesionselectedvalue) {
  //   axios
  //     .get(
  //       `agilesaas_devozy_project_mapping?AgileProjId=eq.${projectCode}&select=DevozyProjectDetails`
  //     )
  //     .then((res) => {
  //       console.log(res.data, "mapping data");
  //       // setDevozyProjects(res.data?.[0]?.DevozyProjectDetails);
  //       dispatch(HANDLE_ON_CHANGE({ name: 'devozyProjects', value: res.data?.[0]?.DevozyProjectDetails }))
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   axios
  //     .get(api)
  //     .then((response) => {
  //       let data123 = response.data;
  //       data123.map((item) => {
  //         let findItem = projectDetails.find(
  //           (x) => x.projectcode === item.projectcode
  //         );
  //         if (!findItem) projectDetails.push(item);
  //       });
  //     })
  //     .catch((error) => console.log(error));

  //   // axios
  //   //   .get(
  //   //     `agilesaas_issue_details?order=CreatedDate.desc&ProjectId=eq.${projectCode}`
  //   //   )
  //   //   .then((response) => {
  //   //     setIssueDetailsShow(response.data);
  //   //     setKanbanBoardData(response.data);
  //   //   })

  //   const csapi = multiplesm
  //     ? `rpc/fun_agilesaascurrentsprint?projid=${projectCode}`
  //     : `rpc/fun_agilesaascurrentsprint?projid=${projectCode}&createdby=eq.${EMpid}`;
  //   axios.get(csapi).then((res) => {
  //     setSprintData(res.data);
  //     const getdata = res.data;
  //     const disdataid = getdata?.map((e) => e.issueid);
  //     const issueIds = disdataid?.join(",");
  //     axios
  //     .get(`agilesaas_issue_details?select=IssueId,ExceptiontoSkipSPEstimation,IssueTitle,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed),Issuehistory:agilesaas_issue_history(IssueHistoryId,IssueId,ChangedBy,ChangedByDetails,ChangedDate,UpdatedField,UpdatedFrom,UpdatedTo,UpdateSequence,IsActive,Status,ClientId,ProjectId)&IssueId=in.(${issueIds})&ProjectId=eq.${projectCode}`)
  //     .then((response) => {
  //       setIssueDetailsShow(response.data);
  //       setKanbanBoardData(response.data);
  //       let pod= response.data.map(issue => issue.AllProgress).flat()
  //       let poh = response.data.map(issue => issue.Issuehistory).flat()

  //       let data = pod.map((e) => {
  //         return {
  //           EffortPending: e.EffortPending,
  //           EffortRemaining: e.EffortRemaining,
  //           RiskofDelivery: e.RiskofDelivery,
  //           UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
  //           IssueId: e.IssueId,
  //           UpdatedBy: e.UpdatedBy,
  //           PercentageProgressed: e.PercentageProgressed,
  //         };
  //       });
  //       displaydata.push(data);

  //   let sampldata = [];

  //         sampldata.push(
  //           poh.filter((e) => e.UpdatedField == "Kanban Changes")
  //         );
  //         const mergedArray = [].concat(...sampldata);
  //         setGdata(mergedArray);

  //     })
  //     .catch((error) => console.log(error));
  //     // axios
  //     //   .get(
  //     //     `agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=in.(${issueIds})&order=UpdatedDate.desc`
  //     //   )
  //     //   .then((result) => {
  //     //     let data = result.data.map((e) => {
  //     //       return {
  //     //         EffortPending: e.EffortPending,
  //     //         EffortRemaining: e.EffortRemaining,
  //     //         RiskofDelivery: e.RiskofDelivery,
  //     //         UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
  //     //         IssueId: e.IssueId,
  //     //         UpdatedBy: e.UpdatedBy,
  //     //         PercentageProgressed: e.PercentageProgressed,
  //     //       };
  //     //     });
  //     //     displaydata.push(data);
  //     //   });
  //   });
  //   axios
  //     .get(
  //       `agilesaas_releases?order=SprintStartDate.asc&ProjectId=eq.${projectCode}`
  //     )
  //     .then((response) => {
  //       axios
  //         .get("rpc/fun_agilesaasproductivityreport?projid=" + projectCode)
  //         .then((resp) => {
  //           setIsLoading(false);
  //           // setprojectDetails
  //           setshowreleaseboard(resp.data);
  //           setReleasever(response.data);
  //           let a = response.data?.filter(
  //             (k) =>
  //               k.ProjectId == projectCode &&
  //               k.ReleaseName == vesionselectedvalue
  //           );

  //           let getData = a.map((c) => {
  //             let labeld =
  //               moment() < moment(c.SprintStartDate) ? "Planned" : null;
  //             return {
  //               value: c.ReleaseName,
  //               label: c.ReleaseName + " " + labeld,
  //             };
  //           });
  //           setDropdownOption(getData);
  //           // console.log("optionversion release called");
  //           const d = getData.filter((c) => c.label?.includes("Planned"));
  //           const rrl = response.data?.filter(
  //             (e) => e.ReleaseName === d?.[0]?.value
  //           );
  //           optionversionchange(
  //             d.length > 0 ? d?.[0] : getData?.[0],
  //             resp.data,
  //             a,
  //             rrl
  //           );
  //           setValueshow(a);
  //         })
  //         .catch((error) => console.log(error));
  //     })
  //     .catch((error) => console.log(error, "Progress"));
  // }

  let data;
  // const ProjectOption = projectDetails.map((e) => ({
  //   label: e.projectname,
  //   value: parseInt(e.projectcode),
  //   client: e.client,
  // }));
  const labelvilength = labelvi?.split(" ").length;

  const projectchange = (e) => {
    setprojectname(e.label);
    setprojectclname(e.client);
    setprojectselectedlabel(e.value);
    setprojectid(e.value);
    setreleaseselectedvalue("");
    setValueshow("");
    setvesionselectedvalue("");
    let showdtat =
      Releasever == ""
        ? ""
        : Releasever.filter((k) => k.ProjectId == projectCode);
    setvesionselectedvalue(showdtat);
    setValueshow(showdtat);
    // rpcid(e.value);
  };
  const optionreleasechange = (r) => {
    setreleaseselectedvalue(r.value);
    dropdownOption = "";
    let showdtat =
      Releasever == ""
        ? ""
        : Releasever.filter(
          (e) =>
            e.IsReleaseFrozen == r.value &&
            e.ProjectId == projectselectedlabel
        );
    setvesionselectedvalue("");
    setValueshow(showdtat);
  };
  const icondone = () => {
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-check2"
      viewBox="0 0 16 16"
    >
      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
    </svg>;
  };

  const updatsprint = async (predata) => {
    // console.log(releaseversion, "checkreleaselabel");

    let data1 = predata;

    const fetchData = async () => {
      try {
        // Collect all IssueIdS values
        const issueIds = data1?.map((e) => e.IssueIdS).join(",");

        if (!issueIds) {
          console.error("No IssueIdS values found in data1");
          return [];
        }

        // Make a single API request with all IssueIdS values
        const response = await axios.get(
          `agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=in.(${issueIds})&order=UpdatedDate.desc`
        );

        // Process the returned data
        const flattenedData = response.data.map((entry) => ({
          EffortPending: entry.EffortPending,
          EffortRemaining: entry.EffortRemaining,
          RiskofDelivery: entry.RiskofDelivery,
          UpdatedDate: moment(entry.UpdatedDate).format("DD-MMM-YYYY"),
          IssueId: entry.IssueId,
          UpdatedBy: entry.UpdatedBy,
          PercentageProgressed: entry.PercentageProgressed,
        }));

        // console.log(flattenedData, "flattenedData");
        return flattenedData;
      } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
      }
    };

    // Usage:
    const fetchedData = data1.length > 0 ? await fetchData() : [];
    // console.log(fetchedData); // Utilize the fetched data here

    // console.log(data1.length, fetchedData.length, "datacheck");
    // console.log(fetchedData, "displaydata1");
    updatesprintburntdown(data1, null, fetchedData);
    // updatesprintburntdown1(data1, userID, fetchedData);
    // console.log(idss, userID, "isdd");
    // in.(11004,11006)
  };

  const devrelese = Releasever?.filter(
    (e) => e.ProjectId == projectCode && e.ReleaseName == vesionselectedvalue
  );

  // console.log(reledata);
  const calculateTotalEstimates = (
    dataArray,
    totalPoints,
    teamleavesconsidered
  ) => {
    const estimateByAssignedTo =
      dataArray?.length > 0 &&
      dataArray?.reduce((accumulator, currentValue) => {
        const { Assignedid, OriginalEstimate } = currentValue;

        accumulator[Assignedid] =
          (accumulator[Assignedid] || 0) + OriginalEstimate;
        return accumulator;
      }, {});
    return Object.entries(estimateByAssignedTo).map(([Assignedid, total]) => ({
      AssignedTo: parseInt(Assignedid),
      Estimate:
        totalPoints -
        (total +
          (teamleavesconsidered?.filter(
            (x) => x.UserId == parseInt(Assignedid)
          )?.[0]?.Leavesplanned == undefined
            ? 0
            : teamleavesconsidered?.filter(
              (x) => x.UserId == parseInt(Assignedid)
            )?.[0]?.Leavesplanned)),
      Userpoints:
        total +
        (teamleavesconsidered?.filter(
          (x) => x.UserId == parseInt(Assignedid)
        )?.[0]?.Leavesplanned == undefined
          ? 0
          : teamleavesconsidered?.filter(
            (x) => x.UserId == parseInt(Assignedid)
          )?.[0]?.Leavesplanned),
      leave:
        teamleavesconsidered?.filter(
          (x) => x.UserId == parseInt(Assignedid)
        )?.[0]?.Leavesplanned == undefined
          ? 0
          : teamleavesconsidered?.filter(
            (x) => x.UserId == parseInt(Assignedid)
          )?.[0]?.Leavesplanned,
    }));
  };
  // console.log(devrelese);
  const totalPoints = devrelese?.[0]?.TotalDaysInSprint;
  //e.TotalDaysInSprint == undefined ? 0 : e.TotalDaysInSprint;
  const scover = devrelese?.[0]?.SpecialCover;
  //e.SpecialCover ? e.SpecialCover : [];
  const releaseData = {
    TotalDaysInSprint: totalPoints,
    data: calculateTotalEstimates(
      mydatashow,
      totalPoints,
      devrelese?.[0]?.TeamLeavesConsidered
    ),
    startDate: devrelese?.[0]?.SprintStartDate,
    endDate: devrelese?.[0]?.SprintEndDate,
    SpecialCover: scover,
    teamleavesconsidered: devrelese?.[0]?.TeamLeavesConsidered,
  };
  // console.log(releaseData, "reldata");
  // console.log(mydatashow);
  const optionversionchange = (t) => {
    setvesionselectedvalue(t.value);
    setIsOpen(false);
    setIsOpen1(false);
    dispatch(HANDLE_ON_CHANGE({ name: "labelvi", value: t.label }));
    setIsEditing(false);
    setIsEditingweek(false);
    axios
      .get(
        `rpc/fun_agilesaasproductivityreport?projid=${projectCode}&releaseid=eq.${t.id}`
      )
      .then((resp) => {
        console.log(resp.data);
        const filtereddata =
          resp.data?.length > 0 &&
          (resp.data?.[0]?.releasestatus == "In Progress"
            ? resp.data?.filter((x) => x.status == "Y")
            : resp.data);
        // console.log(filtereddata?.length, "fffffffffffffffff", resp.data?.length)
        setshowreleaseboard(filtereddata);
        let myvalueshowdata = filtereddata
          ?.filter((x) => x.releasename == t.value)
          ?.map((e) => ({
            OriginalEstimate: e.originalestimate,
            Version: e.releasename,
            Priority: e.priority,
            IssueType: e.issuetype,
            IssueIdS: e.issueid,
            Parentissue: e.parentissue,
            IssueId: e.projectdetails?.[0]?.ProjName + "-" + e.issueid,
            IssueTitle: e.issuetitle,
            Stage: e.currentstage?.[0]?.StageName,
            Status: e.isreleasefrozen,
            AssignedToDetails:
              e?.assignedtodetails?.FN + " " + e?.assignedtodetails?.LN,
            Assignedid: e.assignedto,
            Releaseby:
              e.createdbydetailsrelease?.FN +
              " " +
              e.createdbydetailsrelease?.LN,
            Role: e.createdbydetailsrelease?.Role,
            projectname: projectName,
            Releasedate: e.releasedate,
            SprintStartDate: e.sprintstartdate,
            SprintEndDate: e.sprintenddate,
            Steppersta: e.releasestatus,
            Sprintinweeks: [e.sprintinweeks],
            FN: e?.assignedtodetails?.FN,
            LN: e?.assignedtodetails?.LN,
            effortpending: e.effortpending,
            effortremaining: e.effortremaining,
            percentageprogressed: e.percentageprogressed,
            RiskofDelivery: e.riskofdelivery,
            linktoepic: e.linktoepic,
          }));
        setissueV(myvalueshowdata?.filter((f) => f.Stage != "Backlog").length);
        setissueD(myvalueshowdata?.filter((f) => f.Stage == "Done").length);
        setissueP(
          myvalueshowdata?.filter(
            (f) =>
              f.Stage == "In Development" || f.Stage == "User Acceptace Testing"
          ).length
        );
        setissueTD(myvalueshowdata?.filter((f) => f.Stage == "Refined").length);
        setspil(
          myvalueshowdata?.filter(
            (f) =>
              f.Stage != "User Acceptace Testing" &&
              f.Stage != "Backlog" &&
              f.Stage != "Done"
          ).length
        );
        setissueVdata(myvalueshowdata);
        setissueDdata(myvalueshowdata?.filter((f) => f.Stage == "Done"));
        setissuePdata(
          myvalueshowdata?.filter(
            (f) =>
              f.Stage == "In Development" || f.Stage == "User Acceptace Testing"
          )
        );
        setissueTDdata(myvalueshowdata?.filter((f) => f.Stage == "Refined"));
        dispatch(
          HANDLE_ON_CHANGE({ name: "mydatashow", value: myvalueshowdata })
        );
        dispatch(
          HANDLE_ON_CHANGE({ name: "mydatashow1", value: myvalueshowdata })
        );
        updatsprint(myvalueshowdata);
      })
      .catch((e) => console.log(e));
  };

  let datee = mydatashow == "" ? "" : mydatashow?.[0]?.SprintEndDate;

  let given2 = datee;

  let duedate = moment(given2).diff(moment(), "day");

  let endDate = moment(datee);
  let currentDate = moment(); // Get the current date

  // Format the current date in the DD/MM/YYYY format
  let currentDateString = currentDate.format("DD/MM/YYYY");
  currentDate = moment(currentDateString, "DD/MM/YYYY");

  // If the current date is a weekend, move to the next Monday
  if (currentDate.day() === 0) {
    currentDate.add(1, "day");
  } else if (currentDate.day() === 6) {
    currentDate.add(2, "day");
  }

  let totalDays = endDate.diff(currentDate, "days"); // Total days between current date and end date
  let totalWeeks = Math.floor(totalDays / 7); // Total weeks between the dates
  let remainingDays = totalDays - totalWeeks * 2; // Subtracting 2 days for each weekend in the total days

  const versionchangehadle = () => {
    // setmydatashow(issueVdata == "" ? null : issueVdata);
    dispatch(
      HANDLE_ON_CHANGE({
        name: "mydatashow",
        value: issueVdata == "" ? null : issueVdata,
      })
    );
  };
  const Donedata = () => {
    if (issueD == "0") {
      toast.error(`No issues were found in the Done stage`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    } else {
      // setmydatashow(issueDdata == "" ? null : issueDdata);
      dispatch(
        HANDLE_ON_CHANGE({
          name: "mydatashow",
          value: issueDdata == "" ? null : issueDdata,
        })
      );
    }
  };
  const progressdata = () => {
    if (issueP == "0") {
      toast.error(`No issues were found in Progress`, {
        transition: Slide,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        draggable: true,
      });
    } else {
      // setmydatashow(issuePdata == "" ? null : issuePdata);
      dispatch(
        HANDLE_ON_CHANGE({
          name: "mydatashow",
          value: issuePdata == "" ? null : issuePdata,
        })
      );
    }
  };
  const tododta = () => {
    if (issueTD == "0") {
      const message =
        labelvi?.includes("To Be Released") || mydatashow?.[0]?.Status == "Y"
          ? "No issue were spilled"
          : "No issue were found in Refind stage";
      toast.error(
        { message },
        {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          draggable: true,
        }
      );
    } else {
      // setmydatashow(issueTDdata == "" ? null : issueTDdata);
      dispatch(
        HANDLE_ON_CHANGE({
          name: "mydatashow",
          value: issueTDdata == "" ? null : issueTDdata,
        })
      );
    }
  };
  function workingDays(startDate, diff1) {
    const weekdays = [];
    let current = startDate;
    let i = 0;
    while (i < diff1) {
      if (!isWeekEnd(current)) {
        weekdays.push(moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"));
      }
      let currentObj = current;
      current = moment(currentObj, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
      i++;
    }
    function isWeekEnd(date) {
      let dateObj = moment(date, "YYYY-MM-DD");

      if (dateObj.day() === 6 || dateObj.day() === 0) {
        return true;
      }
      //   else {
      //     if (holidays.includes(date)) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
    }

    return weekdays;
    // }
  }
  let temp = moment(mydatashow?.[0]?.SprintEndDate).diff(
    moment(mydatashow?.[0]?.SprintStartDate),
    "day"
  );
  const diff =
    temp == 0
      ? 1
      : Number(
        Math.ceil(
          (new Date(mydatashow?.[0]?.SprintEndDate) -
            new Date(mydatashow?.[0]?.SprintStartDate)) /
          (1000 * 60 * 60 * 24)
        ) + 1
      );
  const excludeday = workingDays(mydatashow?.[0]?.SprintStartDate, diff + 0);
  const updatesprintburntdown = (mydatashow, userID, displaydata) => {
    let addarr = [];
    let increment = 0;
    const datacheck = mydatashow;
    let temp = moment(mydatashow?.[0]?.SprintEndDate).diff(
      moment(mydatashow?.[0]?.SprintStartDate),
      "day"
    );
    const diff =
      temp == 0
        ? 1
        : Number(
          Math.ceil(
            (new Date(mydatashow?.[0]?.SprintEndDate) -
              new Date(mydatashow?.[0]?.SprintStartDate)) /
            (1000 * 60 * 60 * 24)
          ) + 1
        );
    const getdays = workingDays(mydatashow?.[0]?.SprintStartDate, diff + 0);
    // console.log(datacheck, "datacheck", displaydata, "displaydata");
    datacheck.length &&
      datacheck?.map((e, i) => {
        increment = i;
        const progressdata = displaydata
          .flat()
          .filter((e5) => e5.IssueId == e.IssueIdS);
        // employeeid != null
        //   ? displaydata
        //       .flat()
        //       .filter(
        //         (e5) => e5.IssueId == e.issueid && e5.UpdatedBy == employeeid
        //       )
        //   :

        let percentdata = [];
        // console.log(progressdata, "progressdata");
        // console.log(getdays, "getdays");
        getdays.map((wdate, index) => {
          const filterprogress = progressdata?.filter(
            (pr) => moment(pr.UpdatedDate).diff(moment(wdate), "day") == 0
          );
          const beforedate = progressdata?.filter(
            (date) => moment(date.UpdatedDate).diff(moment(wdate), "day") < 0
          );
          // console.log(filterprogress, "filterprogress", wdate, "wdate");

          if (filterprogress?.length > 0) {
            percentdata.push(
              filterprogress?.[0]?.PercentageProgressed == undefined
                ? 0
                : filterprogress?.[0]?.PercentageProgressed
            );
          } else {
            if (beforedate?.length > 0) {
              percentdata.push(
                percentdata[percentdata.length - 1] == undefined
                  ? 0
                  : percentdata[percentdata.length - 1]
              );
            } else if (
              (filterprogress?.length == 0 && beforedate?.length == 0) ||
              filterprogress?.length == 0
            ) {
              percentdata.push(0);
            }
          }
          // console.log(percentdata, wdate);
        });

        addarr.push(percentdata);
      });
    setPercentagearr(addarr);
    const sums = addarr.reduce((acc, arr) => {
      arr.forEach((value, index) => {
        acc[index] = (acc[index] || 0) + value;
      });
      return acc;
    }, []);
    let arrvalue = [];
    if (datacheck?.length == increment + 1) {
      sums.map((sum, index) => {
        arrvalue.push(sum / addarr.length);
      });
    }

    dispatch(PERCENTAGE(arrvalue));
  };
  const days = mydatashow?.[0]?.Sprintinweeks?.[0] * 5;

  const pushdata = () => {
    const value = 100 / days;

    for (let i = 1; i < days + 1; i++) {
      let d = {
        id: i + " day",
        plan: i * value,
        actual: 0,
      };

      chartdata.push(d);
    }
  };

  if (days != 0 && chartdata.length == 0) {
    pushdata();
  }

  const pushdata1 = () => {
    const value = 100 / days;
    let currentPlan = 90;

    for (let i = 1; i < days + 1; i++) {
      let d = {
        id: i === 1 ? "1 day" : i === days ? "90 day" : i + " day",
        plan: currentPlan,
        actual: 0,
      };

      chart.push(d);
      currentPlan -= value;
    }
  };

  if (days != 0 && chart.length === 0) {
    pushdata1();
  }

  let Burndownactual = [];
  const one = percentage?.map((e) => Burndownactual.push(100 - Math.round(e)));
  // console.log(percentage, "pp");
  const formattedDates = excludeday.map((dateStr) => {
    const formattedDate = moment(dateStr, "YYYY/MM/DD").format("DD MMM");
    return formattedDate;
  });
  const sprintdate = formattedDates.map((e) => e);

  // useEffect(() => {
  //   const data = mydatashow;
  //   // console.log(data);
  //   // let filterda = data?.filter((e) => e.assignedto == userID);
  //   let idss =
  //     // userID == null
  //     // ?
  //     data?.length > 0 && data?.map((x) => x?.IssueIdS);
  //   // : filterda?.map((x) => x?.issueid);
  //   let sampldata = [];
  //   const issueIds = idss.length > 0 && idss?.join(",");
  //   // console.log(idss, "isdd");
  //   // console.log(issueIds, "issueids", idss);
  //   if (idss.length > 0) {
  //     axios
  //       .get(`agilesaas_issue_history?IssueId=in.(${issueIds})`)
  //       .then((res) => {
  //         sampldata.push(
  //           res.data.filter((e) => e.UpdatedField == "Kanban Changes")
  //         );
  //         const mergedArray = [].concat(...sampldata);
  //         // console.log(mergedArray, "merged array");
  //         setGdata(mergedArray);
  //       });
  //   }
  // }, [mydatashow]);
  useEffect(() => {
    // console.log("cahnge called");
    cahnge();
  }, [gdata]);

  let rp = gdata;
  let rp1 = gdata;
  let rp2 = gdata;
  let a = mydatashow?.[0]?.SprintStartDate;
  let ll = mydatashow?.[0]?.SprintEndDate;
  let b = moment(ll).diff(moment(a), "day") + 1;
  let daysss = workingDays(a, b);

  const cahnge = () => {
    setrf([]);
    setrf1([]);
    setrf2([]);
    daysss?.map((dates) => {
      daycalculate(dates, a);
      daycalculate1(dates, a);
      daycalculate2(dates, a);
      daycalculate3(dates, a);
    });
  };

  function daycalculate(dateofmy, a) {
    let sp = rp?.filter(
      (e) =>
        moment(a).diff(e.ChangedDate, "days") <= 0 &&
        moment(e.ChangedDate).diff(dateofmy, "days") <= 0 &&
        e.UpdatedFrom == "RFD" &&
        e.UpdatedTo == "DEV"
    );

    const final = [...new Set(sp?.map((item) => item.IssueId))].length;

    rfddd?.push(final);
    let devdata = rfddd?.map((e) => e);
    setcmt(rfddd?.[rfddd?.length - 1]);
    setfo(devdata);
  }

  function daycalculate1(dateofmy, a) {
    let sp1 = rp1?.filter(
      (e) =>
        moment(a).diff(e.ChangedDate, "days") <= 0 &&
        moment(e.ChangedDate).diff(dateofmy, "days") <= 0 &&
        e.UpdatedTo == "DONE"
    );
    const final1 = [...new Set(sp1?.map((item) => item.IssueId))].length;
    // const final1 = sp1?.length;
    rfddd1.push(final1);
    let donedata = rfddd1?.map((e) => e);
    setcmt1(rfddd1?.[rfddd1?.length - 1]);

    setfo1(donedata);
  }

  function daycalculate3(dateofmy, a) {
    let sp1 = rp1?.filter(
      (e) =>
        moment(a).diff(e.ChangedDate, "days") <= 0 &&
        moment(e.ChangedDate).diff(dateofmy, "days") <= 0 &&
        e.UpdatedTo == "UAT"
    );
    const final1 = [...new Set(sp1?.map((item) => item.IssueId))].length;
    // const final1 = sp1?.length;
    rfddd2.push(final1);
    let uatdata = rfddd2?.map((e) => e);
    setcmt3(rfddd2?.[rfddd2?.length - 1]);

    setfo3(uatdata);
  }
  function daycalculate2(dateofmy, a) {
    // let fltda = data?.filter((e) => e.assignedto == userID).length;

    let rfddata2 =
      // userID == null
      // ?
      rfddd.map((e) => mydatashow?.length - e);
    // console.log(rfddd);
    // : rfddd.map((e) => fltda - e);
    // setcmt2(fltda);
    setfo2(rfddata2);
  }
  let burndownplanned = chartdata?.map((e) => e.plan);
  let burnupplanned = chart?.map((e) => e.plan);
  let burndownactual = percentage?.map((e) => Math.round(e));
  let burnup = Burndownactual?.map((e) => parseInt(e));
  // console.log(fo, fo1, fo2, fo3, "pp");
  // console.log(sprintdate, "pp");
  // console.log(
  //   percentage?.map((e) => Math.round(e)),
  //   "pp"
  // );
  // console.log(
  //   chartdata?.map((e) => e.plan),
  //   "pp"
  // );
  // console.log(
  //   chart?.map((e) => e.plan),
  //   "pp"
  // );
  // console.log(
  //   Burndownactual.map((e) => parseInt(e)),
  //   "pp"
  // );
  const reportdata = {
    Version: vesionselectedvalue,
    Sprintdays: sprintdate,

    Cumulative: {
      RFD: fo2,
      DEV: fo,
      UAT: fo3,
      DONE: fo1,
    },

    Burndown: {
      planned: burndownplanned,
      actual: burndownactual,
    },

    Burnup: {
      planned: burnupplanned,
      actual: burnup,
    },
  };
  // console.log([reportdata], "pp", reportdata?.Burndown?.actual);
  const emailmembers = projectMappingData
    ?.filter((e) => e.email != "support@yozytech.com")
    ?.map((e) => e.email);
  const uniqueEmails = [...new Set(emailmembers)];

  const handlebtn = (i) => {
    let cont = mydatashow1.length;
    let donestd = mydatashow1.filter((x) => x.Stage == "Done");
    let undonestd = mydatashow1.filter((x) => x.Stage != "Done");
    let remove1 = mydatashow1.filter(
      (e) => e.Stage === "Refined" || e.Stage == "In Development"
    );
    let uatcards = mydatashow1.filter(
      (e) => e.Stage === "User Acceptace Testing"
    ).length;

    const check = Releasever.filter((f) => f.ReleaseName == vesionselectedvalue)
      ?.map((t) => t.ReleaseIssues)
      .flat();
    let isues = check.map((e) => e.IssueId);
    const filtered = mydatashow1.filter((item) =>
      check.some(
        (subItem) => subItem.IssueId === item.IssueIdS && item.Stage === "Done"
      )
    );
    const refinedst = mydatashow1.filter((item) =>
      check.some(
        (subItem) => subItem.IssueId === item.IssueIdS && item.Stage != "Done"
      )
    );
    let b = refinedst?.map((s) => s.IssueIdS.toString());
    let c =
      remove1 == ""
        ? ""
        : remove1?.map((s, i) => {
          return {
            reason: remove[i]?.RemovedTickets?.Reason,
          };
        });

    let m =
      remove1.length != 0
        ? c?.find((d) => d?.reason == null || d?.reason == undefined)
        : null;
    let checkremoved = remove
      .filter((item) => item !== undefined)
      .filter((e) => e.RemovedTickets?.Reason != null);
    // console.log(
    //   remove1.length,
    //   checkremoved,
    //   checkremoved.length,
    //   "check length"
    // );
    let contmy = donestd.length;
    // console.log(remove1.length != 0, m, remove1, remove, c, "remove");
    if (contmy == 0 || uatcards != 0) {
      toast.error(
        contmy == 0 && uatcards != 0
          ? `There are no issues pending release. Please ensure all UAT cards are moved to DONE to finalize the sprint.`
          : contmy == 0
            ? `There are no issues pending release.`
            : uatcards != 0
              ? `Please ensure all UAT cards are moved to DONE to finalize the sprint.`
              : ``,
        {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          draggable: true,
        }
      );
    }
    if (remove1.length != checkremoved.length) {
      toast.error(
        `Please select a reason for splitting stories in the Refined and Development stages.`,
        {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          draggable: true,
        }
      );
    } else if (cont > contmy) {
      let version = vesionselectedvalue;
      const date = moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss");

      let sampled123 =
        donestd == ""
          ? ""
          : donestd.map((r) => {
            let posthistory = {
              IssueId: r.IssueIdS,
              ChangedBy: login?.[0]?.ClientUserId,
              ChangedByDetails: {
                FN: login?.[0]?.UserFirstname,
                LN: login?.[0]?.UserLastname,
              },
              ChangedDate: date,
              UpdatedField: "Release",
              UpdatedTo: version,
              UpdateSequence: 1,
              IsActive: "Y",
              Status: null,
              ClientId: clientid,
              ProjectId: projectCode,
            };

            axios
              .post("agilesaas_issue_history", posthistory)
              .then((response) => { })
              .catch((error) => console.log(error));
          });
      let sampled12 =
        donestd == ""
          ? ""
          : donestd.map((r) => {
            let patchdatapr12 = {
              ActualRelease: version,
              IsActive: "N",
            };
            axios
              .patch(
                "agilesaas_issue_details?IssueId=eq.	" + r.IssueIdS,
                patchdatapr12
              )
              .then((response) => { })
              .catch((error) => console.log(error));
          });
      let patchpredata = {
        ReleaseIssues:
          filtered.length == 0
            ? []
            : filtered?.map((e) => {
              return {
                IssueId: e.IssueIdS,
                IssueTitle: e.IssueTitle,
              };
            }),
        ReleaseDate: moment().format("YYYY-MM-DD"),
        IsReleaseFrozen: "Y",
        Status: "Completed",
        ActualTicketsCount: filtered?.length,
        RetroNotes: {
          UpdatedBy: userdetails?.[0].UserId,
          UpdatedByDetails: userdetails?.[0].UserDetails,
          datetime: moment(),
          notes: notesdata,
        },
        SpillTickets:
          remove1 == ""
            ? null
            : remove1?.map((s, i) => {
              return {
                IssueId: s.IssueIdS,
                IssueTitle: s.IssueTitle,
                AssignedToDetails: s.FN + " " + s.LN,
                Assignedto: s.Assignedid,
                Reason: remove[i]?.RemovedTickets?.Reason,
                notes: remove[i]?.RemovedTickets?.notes
                  ? remove[i]?.RemovedTickets?.notes
                  : null,
              };
            }),

        ReportsData: [reportdata],
      };
      // console.log(patchpredata, "patchpredat complete");
      axios
        .patch(
          "agilesaas_releases?ReleaseName=eq." + vesionselectedvalue,
          patchpredata
        )
        .then((response) => {
          toast.success(`🎉 Released successfully! 🎉  `, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            draggable: true,
          });
          refresh();

          // KBRefresh();
          dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
        })
        .catch((error) => console.log(error));
      axios
        .patch(`agilesaas_issue_details?IssueId=in.(${b})`, {
          PlannedRelease: null,
        })
        .then((response) => {
          toast.error(
            `Some stories may need to be spilup and replanned for the upcoming sprint.`,
            {
              transition: Slide,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              draggable: true,
            }
          );
          // refresh();
          // KBRefresh();
          dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
          // sethistoryrelease(false);
          dispatch(HANDLE_ON_CHANGE({ name: "historyrelease", value: false }));
        })
        .catch((error) => console.log(error));
    } else if (notesdata == "") {
      toast.error(
        `Please provide retrospective notes when completing the sprint.`,
        {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          draggable: true,
        }
      );
    } else if (cont == contmy) {
      let version = vesionselectedvalue;
      const date = moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss");
      let sampled123 =
        donestd == ""
          ? ""
          : donestd.map((r) => {
            let posthistory = {
              IssueId: r.IssueIdS,
              ChangedBy: login?.[0]?.ClientUserId,
              ChangedByDetails: {
                FN: login?.[0]?.UserFirstname,
                LN: login?.[0]?.UserLastname,
              },
              ChangedDate: date,
              UpdatedField: "Release",
              UpdatedTo: version,
              UpdateSequence: 1,
              IsActive: "Y",
              Status: null,
              ClientId: clientid,
              ProjectId: projectCode,
            };

            axios
              .post("agilesaas_issue_history", posthistory)
              .then((response) => { })
              .catch((error) => console.log(error));
          });
      let sampled12 =
        donestd == ""
          ? ""
          : donestd.map((r) => {
            let patchdatapr12 = {
              ActualRelease: version,
              IsActive: "N",
            };
            axios
              .patch(
                "agilesaas_issue_details?IssueId=eq.	" + r.IssueIdS,
                patchdatapr12
              )
              .then((response) => { })
              .catch((error) => console.log(error));
          });

      let patchdata = {
        ReleaseDate: moment().format("YYYY-MM-DD"),
        IsReleaseFrozen: "Y",
        Status: "Completed",
        ActualTicketsCount: filtered?.length,
        RetroNotes: {
          UpdatedBy: userdetails?.[0].UserId,
          UpdatedByDetails: userdetails?.[0].UserDetails,
          datetime: moment(),
          notes: notesdata,
        },
        ReportsData: [reportdata],
      };

      axios
        .patch(
          "agilesaas_releases?ReleaseName=eq." + vesionselectedvalue,
          patchdata
        )
        .then((response) => {
          toast.success(`🎉 Released successfully! 🎉`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            draggable: true,
          });
          // refresh();
          // KBRefresh();
          dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
          // Setnotesdata("");
          // sethistoryrelease(false);
          dispatch(HANDLE_ON_CHANGE({ name: "historyrelease", value: false }));
        })
        .catch((error) => console.log(error));
    }
    let requrireddata = {
      ClientUserId: emplogin?.[0]?.ClientUserId,
      ClientId: clientid,
      ClientDetails: {
        Company: userorg?.Company,
        ContactName: userorg?.ContactName,
        ContactDesignation: userorg?.ContactDesignation,
        ContactEmail: userorg?.ContactEmail,
        ContactPhone: userorg?.ContactPhone,
      },
      DepartmentDetails: emplogin?.[0]?.DepartmentDetails,
      DepartmentCode: null,
      // Description: " Relaesed version: " + vesionselectedvalue,
      TentantId: "",
      FromSenderId: "no-reply@yozytech.com",
      ToEmailIds: {
        emailids: emplogin?.[0]?.OfficialEmail,
      },
      CcEmailIds: {
        emailids: ["agileteam@yozytech.com"],
      },
      BccEmailIds: "",
      EmailType: "SprintClosealert",
      ProductDetails: {
        ProdId: "11004",
        ProdName: "KANBAN",
        Domain: "KANBAN.yozytech.com",
      },
      EmailSubject: "Sprint SuccessFully Completed",
      IsSuccess: "Y",
      IsPartiallyFailed: " ",
      SendRetryHistory: "",
      IsActive: " ",
      Status: "",
      EmailTemplateName: "SprintClosealert",
      emailnotify: true,
      webnotify: true,
      EmailTemplateVariables: {
        xxasignbyxx: "",
        xxassigndatexx: "",
        xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
        xxprjdisxx: "",
        xxprjnamexx: "",
        xxstoryNamexx: "",
        xxprjpercenxx: "",
        xxprjstartdatexx: "",
        xxprjenddataexx: "",
        xxteamnamexx: "",
        xxsprintxx: vesionselectedvalue,
        xxmanagernamexx: userorg?.ContactName,
        xxcompanyname: userorg?.Company,
        xxmanagerdesigxx: "Scrum Master",
        xxgmailxx: userorg?.ContactEmail,
        xxphnoxx: userorg?.ContactPhone,
        webappdescri: " Released version: " + vesionselectedvalue,
      },
    };

    axios({
      url: "https://api.devozy.ai/api/agile/email",
      method: "post",
      data: requrireddata,
      headers: {
        origin: "https://kanban.yozytech.com",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res.data, "mailsend");
      })
      .catch((err) => {
        console.log(err);
      });
    if (slack && slack == "Y") {
      slacknotify(
        channelname,
        " Released version: " + vesionselectedvalue,
        slacktoken
      )
    }
    // let postdata = {
    //   ProjectId: projectselectedlabel,
    //   ProjectDetails: [
    //     {
    //       ProjName: projectname,
    //       Client: projectclname,
    //     },
    //   ],
    //   ProjectType: "KAB  ",
    //   ReleaseName: version,
    //   ReleaseDate: moment().format("YYYY-MM-DD"),
    //   CreatedBy: arr123.empid,
    //   CreatedByDetails: arr123.RoleName,
    //   CreatedDate: mydatashow == "" ? "" : mydatashow[0].Releasedate,
    //   ReleaseFrequency: 2,
    //   ReleaseFrequencyUnit: "w",
    //   IsReleaseFrozen: "Y",
    //   ReleaseIssues:
    //     donestd == ""
    //       ? ""
    //       : donestd.map((s) => {
    //           return {
    //             IssueId: s.IssueIdS,
    //             IssueTitle: s.IssueTitle,
    //           };
    //         }),
    //   Placeholder2: null,
    //   IsActive: "Y",
    //   Status: null,
    //   SprintStartDate:
    //     mydatashow == ""
    //       ? ""
    //       : moment(mydatashow[0].SprintStartDate).format("DD/MM/YYYY"),
    //   SprintEndDate:
    //     mydatashow == ""
    //       ? ""
    //       : moment(mydatashow[0].SprintEndDate).format("DD/MM/YYYY"),
    //   SprintInWeeks:
    //     mydatashow == "" ? "" : mydatashow[0].Sprintinweeks + " " + "weeks",
    //   SprintStatus: null,
    //   RemovedTickets: null,
    //   AddedTickets: null,
    //   ActualTickets: null,
    //   PlannedTicketsCount: issueV.length,
    //   ActualTicketsCount: null,
    // };
    // console.log(postdata, "postdata");
    // axios
    //   .post("agile_releases", postdata)
    //   .then((response) => {
    //     toast.success(`Released Successfully`, {
    //       transition: Slide,
    //       position: toast.POSITION.TOP_RIGHT,
    //       autoClose: 3000,
    //       draggable: true,
    //     });
    //     refresh();
    //   })
    //   .catch((error) => console.log(error));
    // }
  };
  function btnshowpop(e) {
    setShowreleasePopup(true);
    setShowRefresh(true);
    setIssuepopdetails(e);
  }
  const progresspercentage = percentage
    ?.map((e) => Math.round(e))
    ?.slice(0, finalIndex + 1);
  // console.log(
  //   progresspercentage,
  //   percentage,
  //   finalIndex,
  //   "check percentage",
  //   progresspercentage[progresspercentage.length - 1]
  // );

  const date1 = moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss");

  const handleissueid = (e, i) => {
    // sethistoryrelease(false);
    const originalVersionSelectedValue = vesionselectedvalue;
    console.log(originalVersionSelectedValue, "optionversionhchange");

    const releaseissues =
      Releasever?.length > 0 &&
      Releasever?.filter((x) => x.ReleaseName === vesionselectedvalue)?.[0]
        .ReleaseIssues;

    const idcheck =
      releaseissues?.length > 0 &&
      releaseissues?.filter((f) => f.IssueId != e?.IssueIdS);

    let posthistory = {
      IssueId: e.IssueIdS,
      ChangedBy: login?.[0]?.ClientUserId,
      ChangedByDetails: {
        FN: login?.[0]?.UserFirstname,
        LN: login?.[0]?.UserLastname,
      },
      ChangedDate: date1,
      UpdatedField: "Sprint",
      UpdatedTo: null,
      UpdateSequence: 1,
      IsActive: "Y",
      Status: null,
      ClientId: clientid,
      ProjectId: projectCode,
    };

    axios
      .patch("agilesaas_releases?ReleaseName=eq." + vesionselectedvalue, {
        ReleaseIssues: idcheck,
      })
      .then((response) => {
        axios
          .patch("agilesaas_issue_details?IssueId=eq.	" + e.IssueIdS, {
            PlannedRelease: null,
          })
          .then((response) => {
            axios
              .post("agilesaas_issue_history", posthistory)
              .then((response) => {
                // refresh2(vesionselectedvalue);

                toast.success(`Removed from the sprint successfully`, {
                  transition: Slide,
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  draggable: true,
                });
                fetchData();
                setvesionselectedvalue(vesionselectedvalue);
                // optionversionchange(vesionselectedvalue)
                // refresh()
                // const x = { value: vesionselectedvalue };
                // optionversionchange(x);
                // KBRefresh(); // refresh();
                dispatch(
                  HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true })
                );
              })

              .catch((error) => console.log(error));
          });
      })
      .catch((error) => console.log(error, "eroro"));

    // console.log(posthistory);
  };

  let rag = "--";
  const mydatashow2 =
    mydatashow?.length > 0 && mydatashow?.filter((e) => e.Stage != "Backlog") || [];
  // const versiondata =
  //   mydatashow == ""
  //     ? ""
  //     : mydatashow?.filter(
  //       (e) => e.Stage != "Backlog" && e.Version == vesionselectedvalue
  //     );
  // console.log(versiondata, "versiondata");

  const removed = Releasever.filter((f) => f.ReleaseName == vesionselectedvalue)
    ?.map((g) => (g?.RemovedTickets ? g?.RemovedTickets : "null"))
    .flat();

  // console.log(Releasever.filter((f) => f.ReleaseName == vesionselectedvalue), "ffffffffffffffff")
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown appears above other elements
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // This ensures the portal has a high zIndex
  };
  const modifyImageTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const images = tempDiv.querySelectorAll("img");
    images.forEach((image) => {
      image.style.width = "100%"; // Set the width to your desired value
      image.style.height = "100%"; // Set the height to your desired value
    });

    return tempDiv.innerHTML;
  };
  const notesrelasesd = Releasever?.filter(
    (e) => e.ReleaseName == vesionselectedvalue
  );
  // console.log(vesionselectedvalue, notesrelasesd)
  // console.log(vesionselectedvalue, "pp");
  // console.log(mydatashow, "pp");

  let calculatedValue;
  // console.log(
  //   reportdata?.Burndown?.actual,
  //   reportdata?.Burndown?.actual
  //     ?.map((e) => Math.round(e))
  //     ?.slice(0, finalIndex + 1),
  //   "percentagedata"
  // );
  if (mydatashow?.[0]?.Status == "Y") {
    // console.log(
    //   "called if",
    //   mydatashow?.[0],
    //   100,
    //   issueV,
    //   issueD,
    //   100 / (issueV / issueD)
    // );
    // calculatedValue = 100 / (issueV / issueD);
    calculatedValue = progresspercentage[progresspercentage.length - 1];
  } else if (moment() < moment(mydatashow?.[0]?.SprintStartDate)) {
    // console.log(
    //   moment() < moment(mydatashow?.[0]?.SprintStartDate),
    //   "if called",
    //   mydatashow?.[0]
    // );
    calculatedValue = 100 / (issueV / issueD);
  } else {
    // console.log("else called", mydatashow?.[0]);
    calculatedValue = progresspercentage[progresspercentage.length - 1];
  }

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const appBarStyle = {
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "bold",
    gap: "4px",
  };

  function getSteps() {
    return [`Created at `, `RFD `, `DEV `, `UAT`, `DONE `];
  }

  const steps = [`Started`, `In Progress`, `Complete`, `Onhold`, `Block`];
  const useStyles = makeStyles({
    root: {
      "& .MuiStepIcon-root.Mui-active": {
        color: "lightgreen",
      },
      "& .MuiStepIcon-root.Mui-completed": {
        color: "lightgreen",
      },
      "& .MuiStepIcon-root": {
        color: "blue",
      },
    },
    stepIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "blue",
      "&.active": {
        color: "lightgreen",
      },
      "&.completed": {
        color: "lightgreen",
      },
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
    checkIcon: {
      color: "inherit",
    },
  });
  const useStyles1 = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));
  const useQontoStepIconStyles = makeStyles({
    root: {
      color: "#eaeaf0",
      display: "flex",
      height: 22,
      alignItems: "center",
    },
    active: {
      color: "#784af4",
    },
    circle: {
      // width: 18,
      // height: 18,
      // borderRadius: "70%",
      // backgroundColor: "currentColor",
      color: "currentColor",
      zIndex: 1,
      fontSize: 25,
    },
    completed: {
      color: "#45DD81",
      zIndex: 1,
      fontSize: 25,
    },
  });
  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? (
          <CheckCircleIcon className={classes.completed} />
        ) : (
          <RadioButtonCheckedIcon className={classes.circle} />
        )}
      </div>
    );
  }
  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
  };

  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: "calc(-50% + 14px)",
      right: "calc(50% + 14px)",
    },
    active: {
      "& $line": {
        borderColor: "#45DD81",
      },
    },
    completed: {
      "& $line": {
        borderColor: "#45DD81",
      },
    },
    line: {
      borderColor: "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
      // paddingLeft: 35, // Adjust left padding as needed
      // paddingRight: 20,
    },
  })(StepConnector);

  function stepperstart() {
    let patchstatus = {
      Status: "In Progress",
    };
    const originalVersionSelectedValue = vesionselectedvalue;
    axios
      .patch(
        "agilesaas_releases?ReleaseName=eq." + vesionselectedvalue,
        patchstatus
      )
      .then((response) => {
        toast.success(`The sprint has commenced successfully! 🚀 `, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          draggable: true,
        });
        fetchData();
        setvesionselectedvalue(originalVersionSelectedValue);

        // refresh2(vesionselectedvalue);

        // KBRefresh();
        dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
      })
      .catch((error) => console.log(error));
  }

  const getCurrentStepIndex = () => {
    if (mydatashow?.[0]?.Steppersta == "In Progress") return 1;
    if (mydatashow?.[0]?.Steppersta == "Completed") return 2;
    if (mydatashow?.[0]?.Steppersta == "Onhold") return 3;
    if (mydatashow?.[0]?.Steppersta == "Block") return 4;
    return 0;
  };

  useEffect(() => {
    setActiveStep(getCurrentStepIndex());
  }, [mydatashow]);

  let optiondroprelease12 = KanbanBoardData?.filter(
    (e) => e.PlannedRelease == null && e.ProjectId == projectCode && e.IssueType != "Epic" && e.IsActive == "Y"
  )?.map((x) => ({
    label: x.IssueTitle + "-" + x.IssueId,
    value: x.IssueId,
    IssueTitle: x.IssueTitle,
  }));
  console.log(
    optiondroprelease12,
    KanbanBoardData?.filter(
      (x) => x.PlannedRelease == null && x.ProjectId == projectCode
    )
  );

  const sprintStartDate = mydatashow?.[0]?.SprintStartDate;
  const sprintEndDate = mydatashow?.[0]?.SprintEndDate;
  const progress =
    reportdata?.Burndown?.actual?.[reportdata?.Burndown?.actual.length - 1];
  const status = mydatashow?.[0]?.Status === "Y";
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    const edata = mydatashow2
      ?.filter((e) => e.linktoepic != null)
      .map((e) => e.linktoepic?.[0]);
    const issueCount = edata.reduce((acc, item) => {
      acc[item.IssueId] = (acc[item.IssueId] || 0) + 1;
      return acc;
    }, {});
    const mostFrequentIssueId = Object.keys(issueCount).reduce((a, b) =>
      issueCount[a] > issueCount[b] ? a : b
    );
    const result = edata.find(
      (item) => item.IssueId === parseInt(mostFrequentIssueId)
    );
    // const epic = [result];
    setSelectedepic(result)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIssueTitle("")
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChanged = (selected) => {
    let value = Array.isArray(selected)
      ? selected.map((x) => {
        let rObj = {};
        rObj["value"] = x.value;
        rObj["label"] = x.label;
        rObj["IssueId"] = x.value;
        rObj["IssueTitle"] = x.label;
        return rObj;
      })
      : [];
    console.log(value, "release selected value");
    // setMultiSelectedissue(value);
    setSelectedIssues(value);
    // setSelected(selected);
    console.log(selected, "selected 1553");
    // dispatch(HANDLE_ON_CHANGE({ name: "optionSelected", value: selected }));
  };

  console.log(dropdownOption?.find((x) => x.value == vesionselectedvalue)?.id);
  const handlechangesprint = () => {
    setSprintSpinner(true);
    const ids = selectedissues?.map((e) => e?.IssueId)?.join(",");
    const releaseid = dropdownOption?.find(
      (x) => x.value == vesionselectedvalue
    )?.id;
    const addedissues = selectedissues?.map((e) => ({
      IssueId: e?.IssueId,
      IssueTitle: e?.IssueTitle,
    }));
    console.log(ids);
    axios
      .patch(`agilesaas_issue_details?IssueId=in.(${ids})`, {
        PlannedRelease: vesionselectedvalue,
      })
      .then((res) => {
        axios
          .get(
            `agilesaas_releases?ReleaseId=eq.${releaseid}&select=ReleaseIssues`
          )
          .then((releres) => {
            console.log(releres.data);
            const releasestopatch = releres?.data?.ReleaseIssues
              ? [...releres?.data?.ReleaseIssues, ...addedissues]
              : addedissues;
            console.log(releasestopatch);
            axios
              .patch(`agilesaas_releases?ReleaseId=eq.${releaseid}`, {
                ReleaseIssues: releasestopatch,
              })
              .then((response) => {
                console.log(response.data);
                let posthistory = selectedissues?.map((e) => ({
                  IssueId: e.IssueId,
                  ChangedBy: login?.[0]?.ClientUserId,
                  ChangedByDetails: {
                    FN: login?.[0]?.UserFirstname,
                    LN: login?.[0]?.UserLastname,
                  },
                  ChangedDate: moment(),
                  UpdatedField: "Release",
                  UpdatedTo: vesionselectedvalue,
                  UpdateSequence: 1,
                  IsActive: "Y",
                  Status: null,
                  ClientId: clientid,
                  ProjectId: projectCode,
                }));
                axios
                  .post(`agilesaas_issue_history`, posthistory)
                  .then((history) => {
                    setSprintSpinner(false);
                    refresh();
                    dispatch(
                      HANDLE_ON_CHANGE({ name: "Refresh", value: true })
                    );
                    dispatch(
                      HANDLE_ON_CHANGE({ name: "snackopen", value: true })
                    );
                    dispatch(
                      HANDLE_ON_CHANGE({
                        name: "snackmessage",
                        value:
                          "Issues are successfully moved to " +
                          { vesionselectedvalue } +
                          " sprint",
                      })
                    );
                    dispatch(
                      HANDLE_ON_CHANGE({
                        name: "snackmessagetype",
                        value: "success",
                      })
                    );
                  })
                  .catch((err) => {
                    setSprintSpinner(false);
                    dispatch(
                      HANDLE_ON_CHANGE({ name: "snackopen", value: true })
                    );
                    dispatch(
                      HANDLE_ON_CHANGE({
                        name: "snackmessage",
                        value: "Something went wrong",
                      })
                    );
                    dispatch(
                      HANDLE_ON_CHANGE({
                        name: "snackmessagetype",
                        value: "severity",
                      })
                    );
                  });
              })
              .catch((err) => {
                console.log(err);
                setSprintSpinner(false);
                dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
                dispatch(
                  HANDLE_ON_CHANGE({
                    name: "snackmessage",
                    value: "Something went wrong",
                  })
                );
                dispatch(
                  HANDLE_ON_CHANGE({
                    name: "snackmessagetype",
                    value: "severity",
                  })
                );
              });
          })
          .catch((err) => {
            setSprintSpinner(false);
            dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
            dispatch(
              HANDLE_ON_CHANGE({
                name: "snackmessage",
                value: "Something went wrong",
              })
            );
            dispatch(
              HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "severity" })
            );
          });
      })
      .catch((e) => {
        setSprintSpinner(false);
        dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
        dispatch(
          HANDLE_ON_CHANGE({
            name: "snackmessage",
            value: "Something went wrong",
          })
        );
        dispatch(
          HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "severity" })
        );
      });
    setSelectedIssues(null);
  };

  console.log(
    mydatashow2
      ?.filter((e) => e.linktoepic != null)
      .map((e) => e.linktoepic?.[0]),
    "mydatashow222222222222222222222222"
  );

  const handleCreateTask = (event) => {
    let arr =
      sessionStorage.getItem("Roledetails") == "undefined"
        ? ""
        : JSON.parse(sessionStorage.getItem("Roledetails"));
    event.stopPropagation();
    if (issueTitle) {
      setSubmitCheck(true);
      const releaseid = dropdownOption?.find(
        (x) => x.value == vesionselectedvalue
      )?.id;

      let data = {
        ProjectId: projectCode,
        ProjectShortCode: projectName.slice(0, 3),
        IssueType: "Story",
        IssueTitle: issueTitle,
        Priority: "Medium",
        EstimateUnit: "MD",
        EstimateUnitDesc: "Man Days",
        CurrentStage: [
          {
            StageCode: "RFD",
            StageName: "Refined",
            DateMoved: moment().format("YYYY-MM-DD"),
          },
        ],
        LinkToEpic: [selectedepic],
        CreatedBy: emplogin?.[0]?.ClientUserId,
        CreatedByDetails:
          arr.RoleName == "Undefined" ? "Kanban Admin" : arr.RoleName,
        CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
        IsActive: "Y",
        ProjectDetails: [{ ProjName: projectName }],
        ClientId: emplogin?.[0]?.ClientId,
        AssignedDate: moment()
          .utcOffset("+05:30")
          .format("YYYY-MM-DDTHH:mm:ss"),
        AssignedToDetails: {
          FN: "null",
          LN: "null",
        },
        AssignedToUATDetails: {
          FN: "null",
          LN: "null",
        },
        PlannedRelease: vesionselectedvalue,
      };
      Axios({
        url: `https://api.devozy.ai/agilesaas_issue_details`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.get("token"),
          Prefer: "return=representation",
        },
        method: "post",
        data: data,
      })
        .then((ress) => {
          axios
            .get(
              `agilesaas_releases?ReleaseId=eq.${releaseid}&select=ReleaseIssues`
            )
            .then((res) => {
              const data = res?.data?.[0]?.ReleaseIssues;
              let patchdata = data
                ? [
                  ...data,
                  {
                    IssueId: ress.data?.[0]?.IssueId,
                    IssueTitle: issueTitle,
                  },
                ]
                : [
                  {
                    IssueId: ress.data?.[0]?.IssueId,
                    IssueTitle: issueTitle,
                  },
                ];
              axios
                .patch(`agilesaas_releases?ReleaseId=eq.${releaseid}`, {
                  ReleaseIssues: patchdata,
                })
                .then((res1) => {
                  const historyDataArray = [{
                    IssueId: ress?.data?.[0]?.IssueId,
                    ChangedBy: emplogin?.[0]?.ClientUserId,
                    ChangedByDetails: {
                      FN: emplogin?.[0]?.UserFirstname,
                      LN: emplogin?.[0]?.UserLastname,
                    },
                    ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
                    UpdatedField: `Issue created and moved to ${vesionselectedvalue} sprint`,
                    UpdatedFrom: "",
                    UpdatedTo: "",
                    UpdateSequence: 1,
                    IsActive: "Y",
                    Status: null,
                    ClientId: clientid,
                    ProjectId: projectCode,
                  },
                  {
                    IssueId: ress?.data?.[0]?.IssueId,
                    ChangedBy: emplogin?.[0]?.ClientUserId,
                    ChangedByDetails: {
                      FN: emplogin?.[0]?.UserFirstname,
                      LN: emplogin?.[0]?.UserLastname,
                    },
                    ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
                    UpdatedField: `Kanban Changes`,
                    UpdatedFrom: "",
                    UpdatedTo: "RFD",
                    UpdateSequence: 1,
                    IsActive: "Y",
                    Status: null,
                    ClientId: clientid,
                    ProjectId: projectCode,
                  }];

                  axios.post("agilesaas_issue_history", historyDataArray)
                    .then((response) => {
                      setSubmitCheck(false);
                      dispatch(
                        HANDLE_ON_CHANGE({ name: "snackopen", value: true })
                      );
                      dispatch(
                        HANDLE_ON_CHANGE({
                          name: "snackmessage",
                          value: `Issue created successfully and moved to ${vesionselectedvalue} sprint`,
                        })
                      );
                      dispatch(
                        HANDLE_ON_CHANGE({
                          name: "snackmessagetype",
                          value: "success",
                        })
                      );
                      // KBRefresh();
                      // dispatch(
                      //   HANDLE_ON_CHANGE({
                      //     name: "kbrefreshstate",
                      //     value: true,
                      //   })
                      // );
                      dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
                      refresh();
                      setAnchorEl(null);
                      setIssueTitle("")
                    })
                    .catch((error) => {
                      console.error("Error updating stage:");
                    });
                });
            });
        })
        .catch((e) => {
          setSubmitCheck(false);
          dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }))
          dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Something went wrong" }))
          dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "severity" }))
        });
      // axios.post("agilesaas_issue_details", data).then((res) => {
      //   setSubmitCheck(false);
      //   dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
      //   dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Cards are moved to refinement successfully" }));
      //   dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "success" }));
      //   // KBRefresh();
      //   dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
      //   refresh()
      //   setAnchorEl(null)
      // });
    }
  };

  const EpicDropdown = KanbanBoardData?.filter((x) => x.IssueType == "Epic" && x.IsActive == "Y" && x.ProjectId == projectCode)?.map((x) => ({
    label: x.IssueTitle,
    id: x.IssueId,
    value: {
      IssueId: x.IssueId,
      IssueType: x.IssueType,
      Title: x.IssueTitle,
      CurrentStage: x.CurrentStage?.[0]?.StageCode,
      Priority: x.Priority
    }
  }))
  console.log(EpicDropdown?.[0])
  const dropdownRow = () => {
    return (
      <>
        {/* <div >

          <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />} className={classes.root}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div> */}
        {/* <div
          className="mt-2"
          style={{
            display: "flex",
            // flexDirection: "row",
            // marginLeft: "25px",
          }}
        > */}
        <div
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            padding: "10px",
            // backgroundColor: 'red'
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                flex: "1",
                padding: "20px",
                // boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                border: "1px solid #e0e0e0",
                maxWidth: "550px",
                maxHeight: "340px",
                borderRadius: "8px",
              }}
            >
              <Row>
                <Col
                  lg={6}
                  md={6}
                  className="d-flex justify-content-start align-items-center flex-nowrap"
                >
                  <Avatar
                    alt="image"
                    src={
                      allImage?.length > 0 &&
                        allImage?.filter(
                          (x) =>
                            x.teammemberid == showreleaseboard?.[0]?.createdby
                        )?.[0]?.image != undefined
                        ? allImage?.filter(
                          (x) =>
                            x.teammemberid == showreleaseboard?.[0]?.createdby
                        )?.[0]?.image
                        : Unassigned
                    }
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "1px solid #e0e0e0aa",
                    }}
                  />
                  <h5
                    className="ml-2"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      margin: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {mydatashow?.[0]?.Releaseby}
                  </h5>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  className="d-flex align-items-end"
                  style={{ flexDirection: "column" }}
                >
                  <h4
                    style={{
                      marginBottom: "0",
                      color: "#435585",
                      // marginRight: '10px',
                      marginTop: "5px",
                      alignItems: "end",
                    }}
                  >
                    Project name
                  </h4>
                  <span
                    style={{
                      fontWeight: "normal",
                      color: "black",
                      whiteSpace: "nowrap",
                      fontSize: "1.25rem",
                      // marginLeft: "6px"
                    }}
                  >
                    {" "}
                    {mydatashow?.[0]?.projectname}
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: "0px" }}>
                <Col
                  lg={12}
                  md={12}
                  className="d-flex align-items-left"
                  style={{ marginTop: "5px", flexDirection: "column" }}
                >
                  <h5
                    style={{
                      color: "#435585",
                      marginRight: "10px",
                      marginBottom: "0",
                      marginTop: "10px",
                    }}
                  >
                    Version
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", width: "100%" }}>
                      <Select
                        // className="optionhistory showrttd2 mr-2"
                        className="w-100"
                        maxMenuHeight={145}
                        placeholder="version"
                        value={dropdownOption.filter(
                          (option) => option.value === vesionselectedvalue
                        )}
                        options={dropdownOption}
                        onChange={(item) => optionversionchange(item)}
                        styles={{
                          control: (base) => ({
                            ...base,
                            width: "100% !important",
                            height: "40px", // Ensures consistent height with buttons
                            minHeight: "40px",
                            // marginLeft: "5px", // Ensures consistent height with buttons
                          }),
                          menu: (base) => ({
                            ...base,
                            width: `${Math.max(
                              ...dropdownOption.map(
                                (option) => option.label.length * 12
                              )
                            )}px`,
                            minWidth: "100%  !important",
                          }),
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", gap: 10 }}>
                      {mydatashow?.[0]?.Status !== "Y" ? (
                        <Button
                          onClick={() => fetchData()}
                          disabled={isSpinning}
                          style={{
                            backgroundColor: "white",
                            border: "1px solid #E0E0E0",
                            // boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: "6px",
                            padding: "5px 10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "40px",
                            width: "40px",
                            // marginRight: '10px',
                            // marginLeft: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <SyncOutlinedIcon
                            style={{
                              color: "#5250E5",
                              transition: "transform 0.5s",
                              transform: isSpinning ? "rotate(360deg)" : "none",
                            }}
                            className={isSpinning ? "spinning" : ""}
                          />
                        </Button>
                      ) : null}
                      {projectRole !== "DEV" &&
                        projectRole != "QA" &&
                        (labelvi?.includes("Planned") ||
                          labelvi?.includes("In-Flight")) && (
                          <div>
                            {/* <Button
                            // onClick={() => fetchData()}
                            // disabled={isSpinning}
                            style={{
                              backgroundColor: "white",
                              border: '1px solid #E0E0E0',
                              // boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              borderRadius: '6px',
                              padding: '5px 10px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '40px',
                              width: '40px',
                              cursor: 'pointer',
                            }}
                          >

                            <AddCircleOutlineIcon style={{
                              color: '#5250E5',

                            }} />

                          </Button> */}
                            {console.log(selectedepic)}
                            <Button
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #E0E0E0",
                                // boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                borderRadius: "6px",
                                padding: "5px 10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "40px",
                                width: "100px",
                                cursor: "pointer",
                                whiteSpace: 'nowrap'
                              }}
                              onClick={handleClick}
                            >
                              <AddCircleOutlineIcon
                                style={{
                                  color: "#5250E5",
                                }}
                              />
                              <span style={{ fontSize: 12, color: "#5250E5", textTransform: 'none' }}>Add issues</span>
                            </Button>
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <div className="m-2">
                                <label
                                  style={{
                                    fontWeight: "700",
                                    fontSize: 16,
                                    color: "#4C4848",
                                  }}
                                >
                                  Create a story
                                </label>
                                <Divider />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label
                                    style={{
                                      fontWeight: "700",
                                      fontSize: 14,
                                      color: "grey",
                                      marginTop: 5
                                    }}
                                  >
                                    Title
                                  </label>
                                  <textarea
                                    rows="1"
                                    cols="50"
                                    placeholder="Enter title"
                                    className="SprintFormFields"
                                    // style={{ border: '2px solid grey', borderRadius: 5, padding: 2 }}
                                    onClick={(event) => event.stopPropagation()}
                                    value={issueTitle}
                                    onChange={(event) => {
                                      event.stopPropagation();
                                      setIssueTitle(event.target.value);
                                    }}
                                  ></textarea>
                                  <label
                                    style={{
                                      fontWeight: "700",
                                      fontSize: 14,
                                      color: "grey",
                                      marginTop: 5
                                    }}
                                  >
                                    Epic
                                  </label>
                                  {/* <Select
                                    style={customStyles}
                                    options={EpicDropdown}
                                    placeholder="Select epic"
                                    value={EpicDropdown?.filter(
                                      (option) => option.id === selectedepic?.IssueId
                                    )}
                                    onChange={(e) => setSelectedepic(e.value)}
                                  /> */}
                                  <Select
                                    styles={customStyles}
                                    options={EpicDropdown}
                                    placeholder="Select epic"
                                    value={EpicDropdown?.filter(
                                      (option) => option.id === selectedepic?.IssueId
                                    )}
                                    onChange={(e) => setSelectedepic(e.value)}
                                    menuPortalTarget={document.body} // Render dropdown in portal outside of popover
                                    menuPosition="fixed" // Fix dropdown to viewport, not scroll position
                                    menuPlacement="auto" // Automatically place the dropdown above/below as needed
                                  />
                                  <Button
                                    style={{
                                      alignSelf: "flex-end",
                                      width: 70,
                                      height: 25,
                                      backgroundColor: "#5A88F1",
                                      color: "#fff",
                                      marginTop: 10,
                                      textTransform: "none",
                                    }}
                                    disabled={issueTitle == ""}
                                    onClick={(event) => {
                                      handleCreateTask(event);
                                    }}
                                  >
                                    {submitCheck ? (
                                      <span
                                        className="spinner-border"
                                        role="status"
                                        aria-hidden="true"
                                        style={{
                                          color: "#fff",
                                          width: "1.5rem",
                                          height: "1.5rem",
                                        }}
                                      ></span>
                                    ) : (
                                      "Create"
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </Popover>
                          </div>
                        )}
                      {(projectRole === "SM" ||
                        projectRole === "ITMGR2" ||
                        projectRole === "QA" ||
                        getProdRole === "KA") &&
                        moment(mydatashow?.[0]?.SprintStartDate).isSameOrBefore(
                          moment(),
                          "day"
                        ) &&
                        moment(mydatashow?.[0]?.SprintEndDate).isSameOrAfter(
                          moment(),
                          "day"
                        ) &&
                        (mydatashow?.[0]?.Steppersta === "" ||
                          mydatashow?.[0]?.Steppersta === null) ? (
                        <Tooltip title="Start the Sprint">
                          <Button
                            onClick={() => stepperstart()}
                            style={{
                              // backgroundColor: "#5250E5",
                              backgroundColor: "white",
                              border: "1px solid #E0E0E0",
                              // border: 'none',
                              borderRadius: "6px",
                              padding: "5px 10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "40px",
                              width: "40px",
                              cursor: "pointer",
                            }}
                          >
                            <DirectionsRunIcon
                              // <PlayCircleOutlineIcon
                              style={{
                                color: "#5250E5",
                              }}
                            />
                          </Button>
                        </Tooltip>
                      ) : null}
                    </div>
                  </div>
                </Col>
                {console.log(
                  labelvi,
                  labelvi?.includes("Planned") || labelvi?.includes("In-Flight")
                )}
                {projectRole !== "DEV" &&
                  projectRole != "QA" &&
                  (labelvi?.includes("Planned") ||
                    labelvi?.includes("In-Flight")) && (
                    <Col
                      lg={12}
                      md={12}
                      // className="d-flex align-items-left"
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h5
                        style={{
                          color: "#435585",
                          marginRight: "10px",
                          marginBottom: "5px",
                          marginTop: "10px",
                        }}
                      >
                        Select issues to add in the sprint
                      </h5>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: "85%" }}>
                          <MultiSelect
                            key="example_id"
                            options={optiondroprelease12}
                            onChange={handleChanged}
                            value={selectedissues}
                            isSelectAll={true}
                            maxHeight={300}
                            maxMenuHeight={145}
                            menuPlacement={"bottom"}
                            placeholder="Select issues to add in the sprint"
                          />
                        </div>
                        <Button
                          style={{
                            alignSelf: "flex-end",
                            fontSize: 14,
                            marginLeft: 9,
                            // padding: 9,
                            width: 80,
                            height: 40,
                            borderRadius: 5,
                            backgroundColor: "#5250E5",
                            color: "#fff",
                            border: "0px solid #5A88F1",
                            textAlign: "center",
                            textTransform: "none",
                          }}
                          disabled={
                            sprintSpinner ||
                            selectedissues?.length == 0 ||
                            selectedissues == null
                          }
                          onClick={() => {
                            handlechangesprint();
                          }}
                        >
                          {sprintSpinner ? (
                            <span
                              className="spinner-border"
                              role="status"
                              aria-hidden="true"
                              style={{
                                color: "#fff",
                                width: "1.5rem",
                                height: "1.5rem",
                              }}
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </Col>
                  )}
              </Row>
            </div>
            <div style={{ flex: "1", paddingLeft: "20px" }}>
              {/* <div className="sprint-container" style={{ padding: '16px' }}> */}
              <div className="sprint-cards">
                <Grid container spacing={3} style={{ flexWrap: "wrap" }}>
                  <Grid item lg={6} md={6} xs={12} className="pt-2">
                    <div
                      className="sprint-card-info-1"
                      onClick={versionchangehadle}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#e0e7ff",
                        padding: "15px",
                        borderRadius: "6px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        minHeight: "80px",
                      }}
                    >
                      <h2
                        className="sprint-card-info-title"
                        style={{
                          color: "#7b68ee",
                          marginRight: "10px",
                          width: "50px",
                          textAlign: "right",
                        }}
                      >
                        {issueV}
                      </h2>
                      <p
                        className="sprint-card-info-description"
                        style={{ color: "#7b68ee", margin: "0" }}
                      >
                        TOTAL ISSUES
                      </p>
                    </div>
                  </Grid>

                  <Grid item lg={6} md={6} xs={12} className="pt-2">
                    <div
                      className="sprint-card-info-2"
                      onClick={Donedata}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#ccffcc",
                        padding: "15px",
                        borderRadius: "6px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        minHeight: "80px",
                      }}
                    >
                      <h2
                        className="sprint-card-info-title"
                        style={{
                          color: "#66cdaa",
                          marginRight: "10px",
                          width: "50px",
                          textAlign: "right",
                        }}
                      >
                        {issueD}
                      </h2>
                      <p
                        className="sprint-card-info-description"
                        style={{ color: "#66cdaa", margin: "0" }}
                      >
                        COMPLETED
                      </p>
                    </div>
                  </Grid>

                  <Grid item lg={6} md={6} xs={12} className="pt-2">
                    <div
                      className="sprint-card-info-4"
                      onClick={tododta}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#ccf0f0",
                        padding: "15px",
                        borderRadius: "6px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        minHeight: "80px",
                      }}
                    >
                      <h2
                        className="sprint-card-info-title"
                        style={{
                          color: "#40e0d0",
                          marginRight: "10px",
                          width: "50px",
                          textAlign: "right",
                        }}
                      >
                        {mydatashow?.[0]?.Status === "Y"
                          ? removed === "null"
                            ? 0
                            : removed.length
                          : labelvi?.includes("To Be Released")
                            ? spil
                            : issueTD}
                      </h2>
                      <p
                        className="sprint-card-info-description"
                        style={{ color: "#40e0d0", margin: "0" }}
                      >
                        {labelvi?.includes("To Be Released") ||
                          mydatashow?.[0]?.Status === "Y"
                          ? "ISSUE SPILLED"
                          : "REFINED"}
                      </p>
                    </div>
                  </Grid>

                  <Grid item lg={6} md={6} xs={12} className="pt-2">
                    <div
                      className="sprint-card-info-3"
                      onClick={progressdata}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#ffedcc",
                        padding: "15px",
                        borderRadius: "6px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        minHeight: "80px",
                      }}
                    >
                      <h2
                        className="sprint-card-info-title"
                        style={{
                          color: "#ffa500",
                          marginRight: "10px",
                          width: "50px",
                          textAlign: "right",
                        }}
                      >
                        {issueP}
                      </h2>
                      <p
                        className="sprint-card-info-description"
                        style={{ color: "#ffa500", margin: "0" }}
                      >
                        IN PROGRESS
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div
                style={{
                  padding: "14px",
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  marginTop: "15px",
                }}
              >
                <div
                  className="d-flex justify-content-between"
                  style={{ marginBottom: "8px" }}
                >
                  <div>
                    <p>
                      Sprint Duration{" "}
                      {isEditingweek ? (
                        <select
                          style={{ width: "100px", height: "40px" }}
                          value={sprintWeeks}
                          onChange={handleSprintWeekChange}
                        >
                          <option value={2}>2 weeks</option>
                          <option value={3}>3 weeks</option>
                          <option value={4}>4 weeks</option>
                        </select>
                      ) : (
                        <span style={{ fontWeight: "bold" }}>
                          (
                          {mydatashow?.length > 0 &&
                            mydatashow?.[0]?.Sprintinweeks}{" "}
                          weeks)
                        </span>
                      )}
                      {(projectRole === "SM" ||
                        projectRole === "ITMGR2" ||
                        projectRole === "QA" ||
                        getProdRole === "KA") &&
                        mydatashow?.length > 0 &&
                        ((moment(mydatashow?.[0]?.SprintStartDate) < moment() &&
                          moment(mydatashow?.[0]?.SprintEndDate) > moment()) ||
                          moment() < moment(mydatashow?.[0]?.SprintStartDate) ? (
                          isEditingweek ? (
                            <>
                              <span
                                className="flex-end ml-1"
                                onClick={() => setIsEditingweek(false)}
                              >
                                <Button
                                  style={{
                                    fontSize: "12px",
                                    border: "2px solid #5250E5",
                                    backgroundColor: "#fff",
                                    color: "#5250E5",
                                    textAlign: "center",
                                    textTransform: "none",
                                    width: 20,
                                    height: 30,
                                  }}
                                >
                                  cancel{" "}
                                </Button>
                              </span>
                              <span
                                className="flex-end ml-1"
                                onClick={handleSaveClickweek}
                              >
                                {Loading ? (
                                  <span
                                    className="spinner-border"
                                    role="status"
                                    aria-hidden="true"
                                    style={{
                                      color: "#fff",
                                      width: "1.5rem",
                                      height: "1.5rem",
                                    }}
                                  ></span>
                                ) : (
                                  <Button
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor: "#5250E5",
                                      color: "#fff",
                                      border: "0px solid #5A88F1",
                                      textAlign: "center",
                                      textTransform: "none",
                                      width: 20,
                                      height: 30,
                                    }}
                                  >
                                    Edit{" "}
                                  </Button>
                                )}
                              </span>
                            </>
                          ) : (
                            <span
                              className="flex-end ml-1"
                              onClick={() => toggleEditMode(mydatashow)}
                            >
                              <BorderColorOutlinedIcon
                                className="text-info "
                                style={{ fontSize: "20px" }}
                              />
                            </span>
                          )
                        ) : null)}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <Typography
                      variant="body2"
                      color="#000"
                      align="right"
                      fontSize="12px"
                      style={{ marginRight: "10px" }}
                    >
                      <span>
                        {mydatashow?.length > 0 &&
                          mydatashow?.[0]?.Status === "Y"
                          ? "Released Date " + mydatashow?.length > 0 &&
                          mydatashow?.[0]?.Releasedate
                          : duedate < 0
                            ? "Release Due " + -duedate + " Days Over Due"
                            : "Release Due " + (remainingDays + 1) + " Days Left"}
                      </span>
                    </Typography>
                    <Box>
                      {status ? (
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          style={{
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {progress}%
                          <CheckCircleOutlineIcon
                            style={{
                              marginLeft: 5,
                              width: "15px",
                              height: "15px",
                              color: "green",
                            }}
                          />
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          color="#000"
                          style={{ fontWeight: "bold" }}
                        >
                          {progress}%
                        </Typography>
                      )}
                    </Box>
                  </div>
                </div>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mr: 1 }}>
                    {status ? (
                      <LinearProgress
                        className="progress-bar"
                        color="success"
                        variant="determinate"
                        value={progress}
                        sx={{ height: 10, width: "100%", borderRadius: 5 }}
                      />
                    ) : (
                      <LinearProgress
                        className="progress-bar"
                        variant="determinate"
                        value={progress}
                        sx={{ height: 10, width: "100%", borderRadius: 5 }}
                      />
                    )}
                  </Box>
                </Box>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "5px",
                  }}
                >
                  {isEditing ? (
                    <div className="flex-end">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          InputProps={{
                            style: {
                              outlineColor: "red",
                            },
                          }}
                          className="flex-end"
                          value={endDateedit}
                          onChange={(date) => setEndDate(date)}
                          format="dd-MM-yyyy"
                          views={["year", "month", "date"]}
                          shouldDisableDate={disableDatesBefore}
                          disablePast
                          inputVariant="outlined"
                          required
                          size="small"
                        />
                      </MuiPickersUtilsProvider>
                      <span
                        className="flex-end ml-1"
                        onClick={() => setIsEditing(false)}
                      >
                        <Button
                          style={{
                            fontSize: "12px",
                            border: "2px solid #5250E5",
                            backgroundColor: "#fff",
                            color: "#5250E5",
                            textAlign: "center",
                            textTransform: "none",
                            width: 20,
                            height: 30,
                          }}
                        >
                          cancel{" "}
                        </Button>
                      </span>
                      <span className="mt-5 ml-1" onClick={handleSaveClick}>
                        <Button
                          style={{
                            fontSize: "12px",
                            backgroundColor: "#5250E5",
                            color: "#fff",
                            border: "0px solid #5A88F1",
                            textAlign: "center",
                            textTransform: "none",
                            width: 20,
                            height: 30,
                          }}
                        >
                          Edit{" "}
                        </Button>
                      </span>
                    </div>
                  ) : (
                    <>
                      <span>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          From {moment(sprintStartDate).format("DD MMM YYYY")}{" "}
                          {(projectRole === "SM" ||
                            projectRole === "ITMGR2" ||
                            projectRole === "QA" ||
                            getProdRole === "KA") &&
                            (moment() < moment(sprintStartDate) ? (
                              <span
                                className="flex-end ml-1"
                                onClick={() => handleEditClick(mydatashow)}
                              >
                                <BorderColorOutlinedIcon
                                  className="text-info "
                                  style={{ fontSize: "20px" }}
                                />
                              </span>
                            ) : null)}
                        </span>
                      </span>
                    </>
                  )}
                  <span
                    className="flex-end"
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    To {moment(sprintEndDate).format("DD MMM YYYY")}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {value !== 1 && mydatashow?.[0]?.Status === "Y" && (
            <Row>
              <Col md="6">
                <Card style={{ marginTop: "20px" }}>
                  <CardHeader
                    onClick={toggle}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    <div
                      className="accordion-header"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <SpeakerNotesOutlinedIcon className="accordion-icon" />
                      <h4 className="accordion-title" style={{ margin: 0 }}>
                        Retro Notes
                      </h4>
                    </div>
                    <ExpandMoreIcon
                      className={`accordion-arrow ${isOpen ? "open" : ""}`}
                    />
                  </CardHeader>
                  <Collapse isOpen={isOpen}>
                    <CardBody
                      className="accordion-body"
                      style={{
                        height: "150px",
                        border: "1px solid #ddd",
                        borderTop: "none",
                      }}
                    >
                      {notesrelasesd?.length > 0 ? (
                        notesrelasesd.map((r, index) => (
                          <div key={index} style={{ padding: "10px" }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: r?.RetroNotes?.notes
                                  ? r?.RetroNotes?.notes
                                  : "📭 No notes available.",
                              }}
                            ></span>
                          </div>
                        ))
                      ) : (
                        <div style={{ padding: "10px" }}>
                          📝 No notes have been added .
                        </div>
                      )}
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md="6">
                <Card style={{ marginTop: "20px" }}>
                  <CardHeader
                    onClick={toggle1}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    <div
                      className="accordion-header"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <SpeakerNotesOutlinedIcon className="accordion-icon" />
                      <h4 className="accordion-title" style={{ margin: 0 }}>
                        Manager Notes
                      </h4>
                    </div>
                    <ExpandMoreIcon
                      className={`accordion-arrow ${isOpen1 ? "open" : ""}`}
                    />
                  </CardHeader>
                  <Collapse isOpen={isOpen1}>
                    <CardBody
                      className="accordion-body"
                      style={{
                        height: "150px",
                        border: "1px solid #ddd",
                        borderTop: "none",
                      }}
                    >
                      {notesrelasesd?.[0]?.ManagerNotes?.length > 0 ? (
                        notesrelasesd[0].ManagerNotes.map((r, index) => (
                          <div key={index} style={{ padding: "10px" }}>
                            <span style={{ fontSize: "14px" }}>
                              {moment(r?.datetime).format("DD-MMM-YYYY")}
                            </span>
                            <span
                              style={{
                                color: "black",
                                whiteSpace: "pre-wrap",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: r?.notes
                                  ? r?.notes
                                  : "📭 No notes available.",
                              }}
                            ></span>
                          </div>
                        ))
                      ) : (
                        <div style={{ padding: "10px" }}>
                          📝 No notes have been added{" "}
                        </div>
                      )}
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
          )}
        </div>

        {/* </div> */}

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 15,
            marginLeft: 5,
          }}
        ></div> */}
        {/* <Button
        onClick={() => {
          axios
            .patch(
              "agilesaas_releases?ReleaseName=eq." +
                vesionselectedvalue,
              { ReportsData: [reportdata] }
            )
            .then((res) => alert("success"))
            .catch((err) => alert("failed"));
        }}
      >
        Update
      </Button> */}
        {/* {value != 1 && mydatashow?.[0]?.Status == "Y" ? (
          <Col className="ml-2 mb-4">
            <div>
              <>
                <Row className="ml-1">
                  <SpeakerNotesOutlinedIcon />{" "}
                  <h4 className="ml-1">Retro notes</h4>
                </Row>
                {notesrelasesd?.map((r) => (
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      whiteSpace: "pre-wrap",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: r?.RetroNotes?.notes
                        ? r?.RetroNotes?.notes
                        : "Notes not updated",
                    }}
                  ></span>
                ))}
              </>
            </div>
          </Col>
        ) : null} */}

        {/* {value != 1 && mydatashow?.[0]?.Status == "Y" ? (
          <Col className="ml-2 mb-4">
            <div>
              <>
                <Row className="ml-1">
                  <SpeakerNotesOutlinedIcon />{" "}
                  <h4 className="ml-1">Retro notes</h4>
                </Row>
                {notesrelasesd?.map((r) => (
                  <span
                    style={{
                      color: "black",
                      // fontWeight: "bold",
                      whiteSpace: "pre-wrap",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: r?.RetroNotes?.notes
                        ? r?.RetroNotes?.notes
                        : "Notes not updated",
                    }}
                  ></span>
                ))}
              </>
            </div>
          </Col>
        ) : null} */}
        {/* {value != 1 && mydatashow?.[0]?.Status == "Y" ? (
          <Col className="ml-2 mb-4">
            <div>
              <>
                <Row className="ml-1">
                  <SpeakerNotesOutlinedIcon />{" "}
                  <h4 className="ml-1">Manager notes</h4>
                </Row>
                {notesrelasesd?.[0]?.ManagerNotes?.map((r) => (
                  <div>
                    {" "}
                    <span style={{ fontSize: "14px" }}>
                      {moment(r?.datetime).format("DD-MMM-YYYY")}
                    </span>
                    <span
                      style={{
                        color: "black",
                        // fontWeight: "bold",
                        whiteSpace: "pre-wrap",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: r?.notes ? r?.notes : "Notes not updated",
                      }}
                    ></span>
                  </div>
                ))}
              </>
            </div>
          </Col>
        ) : null} */}

        {value != 1 &&
          mydatashow?.[0]?.Status !== "Y" &&
          (projectRole === "SM" ||
            projectRole === "ITMGR2" ||
            projectRole === "QA" ||
            getProdRole === "KA") &&
          moment(mydatashow?.[0]?.SprintEndDate) <= moment() ? (
          <div className="ml-2" style={{ zIndex: 1, marginTop: "10px" }}>
            <SpeakerNotesOutlinedIcon />
            <label className="mb-2">Retrospectives</label>
            <Toolbar />
            <ReactQuill
              className="quillrelease w-100 "
              theme="snow"
              placeholder="Enter Retronotes"
              value={notesdata || ""}
              onChange={(e) => Setnotesdata(e)}
              modules={modules}
              formats={formats}
            // style={{ width: 'calc(100% - 60px)' }}
            />
          </div>
        ) : null}

        {value != 1 &&
          mydatashow?.[0]?.Status !== "Y" &&
          moment(mydatashow?.[0]?.SprintEndDate) <= moment() &&
          (projectRole === "SM" ||
            projectRole === "ITMGR2" ||
            projectRole === "QA" ||
            getProdRole === "KA") ? (
          <Row
            style={{
              justifyContent: "flex-end",
              marginRight: -10,
              marginBottom: 5,
            }}
          >
            <BootstrapButton
              onClick={() => {
                Setnotesdata("");
              }}
              style={{
                backgroundColor: "#fff",
                border: "2px solid #5250E5",
                color: "#5250E5",
                textDecoration: "none",
                // marginLeft: "900px",
                marginTop: "10px",
                minWidth: "100px",
              }}
            >
              Reset
            </BootstrapButton>

            {(projectRole === "SM" ||
              projectRole === "ITMGR2" ||
              projectRole === "QA" ||
              getProdRole === "KA") && (
                <BootstrapButton
                  onClick={() => {
                    if (
                      mydatashow?.[0]?.Status !== "Y" &&
                      moment(mydatashow?.[0]?.SprintEndDate) <= moment()
                    ) {
                      handlebtn();
                    }
                  }}
                  style={{
                    color: "white",
                    background:
                      mydatashow?.[0]?.Status != "Y" &&
                        moment(mydatashow?.[0]?.SprintEndDate) <= moment()
                        ? "#5250E5"
                        : "white",
                    textDecoration: "none",
                    marginLeft: "10px",
                    marginTop: "10px",
                    // marginRight: "0px",
                    minWidth: "100px",
                  }}
                >
                  Complete Sprint
                </BootstrapButton>
              )}
          </Row>
        ) : null}
      </>
    );
  };
  function clearsummary() {
    setAssignedTo("");
  }
  const handleEditClick = (data) => {
    if (data && data.length > 0) {
      const sprintEndDate = data?.[0]?.SprintStartDate;
      setEndDate(sprintEndDate || new Date());
    }
    setIsEditing(true);
  };
  let weekdrop =
    mydatashow?.[0]?.Sprintinweeks?.toString() == 2
      ? 12
      : mydatashow?.[0]?.Sprintinweeks?.toString() == 3
        ? 19
        : 4;
  let today = moment(endDateedit).add(moment().weekdrop);
  // console.log(today, 'today')

  // weekdrop === 12 ? 2 : weekdrop === 19 ? 3 : 4,
  let endOfWeek = today.clone().weekday(weekdrop);
  const handleSaveClick = () => {
    const userConfirmed = window.confirm(
      "Do you need to reschedule the sprint?"
    );

    if (!userConfirmed) {
      setIsEditing(false);
      return;
    }
    setIsEditing(false);
    const originalVersionSelectedValue = vesionselectedvalue;
    let patchstart = {
      SprintStartDate: moment(endDateedit).format("YYYY-MM-DD"),
      SprintEndDate: moment(endOfWeek).format("YYYY-MM-DD 23:59:00"),
    };
    axios
      .patch(
        "agilesaas_releases?ReleaseName=eq." + vesionselectedvalue,
        patchstart
      )
      .then((response) => {
        toast.success(
          `Sprint rescheduled successfully: Week and date have been updated! `,
          {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          }
        );
        fetchData();
        setvesionselectedvalue(originalVersionSelectedValue);
        // refresh2(vesionselectedvalue);

        // KBRefresh();
        dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
      })
      .catch((error) => console.log(error));

    // handleDateChange(startDate, endDate);
  };
  const inc =
    mydatashow?.length > 0 &&
    mydatashow?.map((e) => {
      return {
        versrion: e.ReleaseName,
        startdate: moment(e.SprintStartDate).format("DD-MM-yyyy"),
        enddate: moment(e.SprintEndDate).format("DD-MM-yyyy"),
      };
    });
  const disableDatesBefore = (date) => {
    const formattedDate = moment(date).format("DD-MM-YYYY");
    // return (
    //   // date < plannedEnddate ||
    //   // (date.getDay() === 1 && date.getDate() === 22) ||

    //   date.getDay() === 0 ||
    //   date.getDay() === 2 ||
    //   date.getDay() === 3 ||
    //   date.getDay() === 4 ||
    //   date.getDay() === 5 ||
    //   date.getDay() === 6
    // );
    const isPreviousWeekMonday = inc?.some(({ startdate }) => {
      const startMoment = moment(startdate, "DD-MM-YYYY");
      const previousWeekMonday = moment(startMoment)
        .subtract(1, "week")
        .startOf("isoWeek");
      return moment(formattedDate, "DD-MM-YYYY").isSame(
        previousWeekMonday,
        "day"
      );
    });

    const isWithinRange = inc?.some(({ startdate, enddate }) => {
      const startMoment = moment(startdate, "DD-MM-YYYY");
      const endMoment = moment(enddate, "DD-MM-YYYY");
      return moment(formattedDate, "DD-MM-YYYY").isBetween(
        startMoment,
        endMoment,
        null,
        "[]"
      );
    });
    // const isBeforeFirstMonday = inc?.some(({ startdate, enddate }) => {
    //   const startMoment = moment(startdate, "DD-MM-YYYY");
    //   const firstMonday = startMoment.clone().startOf("isoWeek").add(1, "days");

    //   return moment(formattedDate, "DD-MM-YYYY").isBefore(firstMonday);
    // });
    return (
      // (date.getDay() === 1 && inc?.includes(formattedDate)) ||
      // isPreviousWeekMonday ||
      isWithinRange ||
      isPreviousWeekMonday ||
      date.getDay() === 0 ||
      date.getDay() === 2 ||
      date.getDay() === 3 ||
      date.getDay() === 4 ||
      date.getDay() === 5 ||
      date.getDay() === 6
    );
  };

  const toggleEditMode = (data) => {
    setIsEditingweek(!isEditingweek);
    if (data && data.length > 0) {
      const weekd = data?.[0]?.Sprintinweeks;
      setSprintWeeks(weekd || new Date());
    }
  };

  const handleSprintWeekChange = (event) => {
    setSprintWeeks(event.target.value);
  };
  const calculateSprintEndDate = (startDate, weeks) => {
    let endDate = moment(startDate).add((weeks - 1) * 7, "days"); // Subtract 1 week because we include the start week

    // Ensure the end date is a Friday (5)
    if (endDate.day() !== 5) {
      const daysToAdd = (5 - endDate.day() + 7) % 7;
      endDate.add(daysToAdd, "days"); // Adjust to the nearest Friday
    }

    // Set the time to the end of the day
    endDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });

    return endDate.toISOString();
  };

  const handleSaveClickweek = () => {
    // handleEditClick(mydatashow, sprintWeeks);
    const result = workingDays1(
      mydatashow?.[0]?.SprintStartDate,
      diff + 1,
      Publicholiday,
      false,
      false
    );
    let today1 = moment(mydatashow?.[0]?.SprintStartDate).add(
      sprintWeeks == "3"
        ? 3
        : sprintWeeks == "4"
          ? 4
          : sprintWeeks == "2"
            ? 2
            : 2,
      "weeks"
    );

    let temp1 = moment(today1.subtract(1, "day")).diff(
      moment(mydatashow?.[0]?.SprintStartDate),
      "day"
    );

    const diff1 =
      temp1 == 0
        ? 1
        : Number(
          Math.ceil(
            (new Date(today1.subtract(1, "day")) -
              new Date(mydatashow?.[0]?.SprintStartDate)) /
            (1000 * 60 * 60 * 24)
          ) + 1
        );
    function workingDays1(
      startDate,
      diff1,
      holidays,
      includeSaturday,
      includeSunday
    ) {
      const weekdays = [];
      const getHolidays = [];
      const weekend = [];
      let current = startDate;
      let i = 0;

      while (i < diff1) {
        if (!isWeekEnd(current)) {
          weekdays.push(moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"));
        }
        let currentObj = current;
        current = moment(currentObj, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
        i++;
      }

      function isWeekEnd(date) {
        let dateObj = moment(date, "YYYY-MM-DD");
        const dayOfWeek = dateObj.day();

        if (
          (dayOfWeek === 6 && !includeSaturday) ||
          (dayOfWeek === 0 && !includeSunday)
        ) {
          let wend = {
            Date: moment(date).format("YYYY-MM-DD"),
            Day: dayOfWeek === 6 ? "Saturday" : "Sunday",
            Notes: "Weekend",
          };
          weekend.push(wend);
          return true;
        } else if (holidays.length != 0) {
          const hday = holidays.filter(
            (x) =>
              moment(x.HolidayDate).format("YYYY-MM-DD") ==
              moment(date).format("YYYY-MM-DD")
          );
          if (hday.length != 0) {
            getHolidays.push(hday?.[0]);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      if (weekdays.length == 1 && holidays.includes(weekdays?.[0])) {
        return 0;
      } else {
        // console.log(weekdays, "wwwwwwwwwwwwwwwwwwwwww")
        return {
          weekdays,
          enddate: weekdays.slice(-1)?.[0],
          workingDays: weekdays.length,
          holidayscount: getHolidays.length,
          holidays: getHolidays,
          weekend: weekend,
        };
      }
    }
    const updatedSprintInWeeks = parseInt(sprintWeeks, 10);

    const updatedSprintEndDate = calculateSprintEndDate(
      mydatashow?.[0]?.SprintStartDate,
      updatedSprintInWeeks
    );

    const startDate = moment(mydatashow?.[0]?.SprintStartDate).format(
      "YYYY-MM-DD"
    );
    const endDate = moment(updatedSprintEndDate).format("YYYY-MM-DD");

    axios
      .get(
        `agilesaas_holidaysandleaves?LeaveStartDate=lte.(${endDate})&&LeaveEndDate=gte.(${startDate})&&ClientId=eq.${clientid}`
      )
      .then((response) => {
        // const leaverecords = developers.map((e) => ({
        //   UserId: e.UserId,
        //   Firstname: e.firstname,
        //   Lastname: e.lastname,
        //   LeavesTaken: differences1(specialCover, response.data.filter((x) => x.UserId == e.UserId), startDate, endDate)
        // }))
        // console.log(leaverecords, "leaverecords")
        // const leavedetail = differences1(specialCover, response.data, startDate, endDate)
        // console.log(leavedetail, "leavedetails")
        const filteredAndSplitLeaves = filterAndSplitLeaves(
          response.data,
          startDate,
          endDate,
          false,
          false
        );

        setTotalDaysByUser(filteredAndSplitLeaves);
        // console.log(filteredAndSplitLeaves);
      })
      .catch((e) => {
        console.log(e);
      });
    const filterAndSplitLeaves = (
      data,
      sprintStartDate,
      sprintEndDate,
      includeSaturday,
      includeSunday
    ) => {
      const result = {};

      for (const leave of data) {
        const leaveStartDate = moment(leave.LeaveStartDate, "YYYY-MM-DD");
        const leaveEndDate = moment(leave.LeaveEndDate, "YYYY-MM-DD").endOf(
          "day"
        );

        if (
          leaveStartDate.isSameOrBefore(sprintEndDate) &&
          leaveEndDate.isSameOrAfter(sprintStartDate) &&
          (includeSaturday || leaveStartDate.isoWeekday() !== 6) &&
          (includeSunday || leaveEndDate.isoWeekday() !== 7)
        ) {
          for (
            let currentDate = moment.max(
              leaveStartDate,
              moment(sprintStartDate, "YYYY-MM-DD")
            );
            currentDate.isSameOrBefore(
              moment.min(leaveEndDate, moment(sprintEndDate, "YYYY-MM-DD"))
            );
            currentDate.add(1, "days")
          ) {
            if (!includeSaturday && currentDate.isoWeekday() === 6) {
              continue; // Skip Saturdays (isoWeekday() returns 6 for Saturday)
            }

            if (!includeSunday && currentDate.isoWeekday() === 7) {
              continue; // Skip Sundays (isoWeekday() returns 7 for Sunday)
            }

            const userId = leave.UserId;
            if (!result[userId]) {
              result[userId] = 0;
            }

            result[userId]++;
          }
        }
      }

      return result;
    };
    if (isEditingweek == true) {
      const finalWorkingdays = result?.workingDays;
      const leaverecords = Assetoptions?.map((u) => ({
        UserId: u.EmpId,
        Firstname: u.firstname,
        Lastname: u.lastname,
        Leavesplanned: totalDaysByUser[u.EmpId] || 0,
        Availabledays: finalWorkingdays - (totalDaysByUser[u.EmpId] || 0),
      }));
      let holidayDetails =
        result.holidayscount == 0
          ? null
          : result.holidays?.map((e) => ({
            Date: e.HolidayDate,
            Day: e.HolidayDay,
            Notes: e.HolidayInfo,
          }));
      const originalVersionSelectedValue = vesionselectedvalue;
      let patchdataedit = {
        SprintInWeeks:
          sprintWeeks == "3"
            ? 3
            : sprintWeeks == "4"
              ? 4
              : sprintWeeks == "2"
                ? 2
                : 2,
        SprintEndDate: moment(updatedSprintEndDate).format(
          "YYYY-MM-DD 23:59:00"
        ),
        PublicHolidays: holidayDetails,
        TotalDaysInSprint: finalWorkingdays,
        TeamLeavesConsidered: leaverecords,
        Totalavailabledays: leaverecords.reduce(
          (acc, user) => acc + user.Availabledays,
          0
        ),
      };
      setLoading(true);
      axios
        .patch(
          "agilesaas_releases?ReleaseName=eq." + vesionselectedvalue,
          patchdataedit
        )
        .then((response) => {
          setIsEditingweek(false);
          setLoading(false);
          toast.success(
            `Sprint dates updated: Week and Date successfully edited! `,
            {
              transition: Slide,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              draggable: true,
            }
          );
          fetchData();
          setvesionselectedvalue(originalVersionSelectedValue);
          // refresh2(vesionselectedvalue);

          // KBRefresh();
          dispatch(HANDLE_ON_CHANGE({ name: "kbrefreshstate", value: true }));
        })
        .catch((error) => console.log(error));
    }

    // setSprintWeeks(mydatashow[0].SprintStartDate)
  };
  // console.log(mydatashow?.[0])
  // console.log(vesionselectedvalue, "vesionselectedvalue", labelvi, labelvi.includes('In-Flight'))
  return (
    <div>
      {
        isLoading ? (
          <div
            className=" d-flex align-items-center"
            style={{ justifyContent: "center", height: 460 }}
          >
            <span
              className="spinner-border"
              role="status"
              aria-hidden="true"
              style={{ color: "rgb(82, 80, 229)" }}
            ></span>
          </div>
        ) : (
          // dropdownOption.length > 0 ? (
          <div>
            <AppBar
              position="static"
              color="#fff"
              // style={appBarStyle}
              className="border-bottom"
              elevation={0}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="info"
                // variant="fullWidth"
                aria-label="full width tabs example"
              // className=" ml-4 "
              >
                <Tab
                  className="mdi mdi-near-me"
                  style={appBarStyle}
                  label={"Sprint Details"}
                  {...a11yProps(0)}
                />
                <Tab
                  className="mdi mdi-cloud-check"
                  style={appBarStyle}
                  label={"Deployment Details"}
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0} dir={theme.direction}>
              <div className="sprint-wrapper">
                {/* <Divider /> */}
                {dropdownOption?.length > 0 ? dropdownRow() : ""}

                {Valueshow.length == 0 ? (
                  <Card>
                    <CardBody
                      className="myShowreleasecolor mt-3 d-flex align-items-center"
                      style={{ justifyContent: "center", height: 460 }}
                    >
                      <center>
                        <h4 className="text-warning mt-5"> No releases held</h4>
                      </center>
                    </CardBody>
                  </Card>
                ) : vesionselectedvalue === " " ? (
                  <Card>
                    <CardBody className="myShowreleasecolor mt-5">
                      <center>
                        <h4 className="text-warning mt-5">
                          {" "}
                          Please Select Sprint
                        </h4>
                      </center>
                    </CardBody>
                  </Card>
                ) : (
                  <div>
                    <div
                      className="widget-bar"
                      style={{
                        whiteSpace: "nowrap",
                        overflowY: "hidden",
                        overflowX: "auto",
                      }}
                    >
                      {/* <button style={{ marginLeft: "95%" }} className="text-info " onClick={clearsummary}>clear</button> */}
                      <div className="widget-row mb-3">
                        {/* <Diversity3Icon className="ml-4" />
                        <label className="mb-2 ml-1">Team Summary</label> */}
                        <div
                          className="mb-2"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <label
                            className="ml-3 mt-2"
                            style={{ fontWeight: "bold" }}
                          >
                            Choose a user to apply a filter
                          </label>
                          <div className="p-1">
                            <button
                              className="text-info rounded p-1"
                              style={{
                                border: "1px solid #198ae3",
                                fontSize: "11px",
                              }}
                              onClick={clearsummary}
                            >
                              <FilterListIcon fontSize="small" /> Clear Filter{" "}
                            </button>
                          </div>
                        </div>

                        <DevepolerSummary
                          data={mydatashow2}
                          points={releaseData}
                          assignedTo={assignedTo}
                          setAssignedTo={setAssignedTo}
                        />
                      </div>
                    </div>
                    {mydatashow2.length == 0 ? (
                      ""
                    ) : (
                      <HistoryTable
                        data={
                          assignedTo != "" && mydatashow2?.length > 0
                            ? mydatashow2?.filter(
                              (e) => e.Assignedid == assignedTo
                            )
                            : mydatashow2
                        }
                        labelvi={labelvi}
                        Reasonoption={Reasonoption}
                        remove={remove}
                        setremove={setremove}
                        vesionselectedvalue={vesionselectedvalue}
                        handleissueid={handleissueid}
                        removed={removed}
                        mydatashow={mydatashow}
                      />
                    )}
                  </div>
                )}
                <ShowReleaseProgress
                  // projectRole={projectRole}
                  ShowreleasePopup={ShowreleasePopup}
                  setShowreleasePopup={setShowreleasePopup}
                  Issuepopdetails={Issuepopdetails}
                  setIssuepopdetails={setIssuepopdetails}
                  // KanbanBoardData={KanbanBoardData}
                  // mydatashow={mydatashow}
                  ShowRefresh={ShowRefresh}
                  setShowRefresh={setShowRefresh}
                  SelectedCardId={
                    Issuepopdetails == "" ? "" : Issuepopdetails.IssueIdS
                  }
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <div className="sprint-wrapper">
                {/* <Divider /> */}
                {dropdownOption?.length > 0 ? dropdownRow() : ""}
                <SprintDeploymentDetails
                  devozyProjects={devozyProjects}
                  startDate={mydatashow?.[0]?.SprintStartDate}
                  endDate={mydatashow?.[0]?.SprintEndDate}
                  currentSprint={labelvi?.includes("In-Flight")}
                />
              </div>
            </TabPanel>
          </div>
        )
        // )
        // :
        // ""
      }
    </div>
  );
}
export default Historyrelease;
