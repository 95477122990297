import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Avatar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import "./ProgressHistory.css";
import UserWorkLogData from "./UserWorkLogData";
import CloseIcon from "@material-ui/icons/Close";
import Bookmark from "@material-ui/icons/Bookmark";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import AlbumRounded from "@material-ui/icons/AlbumRounded";
import OfflineBoltRoundedIcon from "@material-ui/icons/OfflineBoltRounded";
import axios from "../../../axios";
import Storypoints from "../Storypoints";
import moment from "moment";
import { toast, Slide } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import {
  FILESCONTENT,
  HANDLE_ON_CHANGE,
  KANBANBOARDDATAUPDATE,
  RELEASESUPDATE,
} from "../../../redux/CommonInitialsate";
import getFile from "../../../getFile";
import Mailandnotification from "../../../Mailandnotification";
import Unassigned from "../../../assets/images/avatar.png";

toast.configure();

function ProgressHistory({
  // progressopen,
  // setProgressopen,
  // KanbanBoardData,
  // SelectedCardId,
  // KBRefresh,
  // setSelectedCardId,
  // resData,
  // Bfopen,
  // setBfopen,
  // selectedCard,
  // setselectedcard,
  // IssueId,
  // setIssueId,
  // openCreateEst,
  // setOpenCreateEst,
  // uatDropdown,
  // displaySubTask,
  // subTaskApi,
  // UATtesterD,
  // setUATtesterD,
  // UATchecklistD,
  // setUATchecklistD,
  // setRefresh,
  // sendDataToParent,
  // releaseLabel,
  // setReleaseLabel,
  // releaseValue,
  // setReleaseValue,
  // FilteredData,
  // setFilteredData,
  // setKanbanBoardData,
  // empid,
  // crtid,
  // rolecode,
  // progressdata,
  // setProgressData,
  // projectRole,
  // EmpDropDownData,
  projectName,
}) {
  const {
    allImage,
    KanbanBoardData,
    projectCode,
    releases,
    projectMappingData,
    filesContent,
    selectedCard1,
    projectRole,
    progressopen,
    SelectedCardId,
    progressdata,
    releaseLabel,
    releaseValue,
    EmpDropDownData,
    resData,
    cmodule,
    SEpic,
    UatId,
    UatTo,
    description,
    assignedId,
    checkChange,
    priority1,
    assignedTo,
    stageCode,
    stageName,
    selectedFile,
    projectRoleName
  } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  // let emplogin1 = JSON.parse(localStorage.getItem("Empdetails"));
  let empid = JSON.parse(localStorage.getItem("Empdetails"));
  let clientid = JSON.parse(localStorage.getItem("clientid"));
  let productRole = JSON.parse(localStorage.getItem("ProductRole"));
  const getProdRole = productRole?.filter((e) => e.ProdId == "11004")[0]
    ?.RoleCode;
  const [rtbdialog, setRtbdialog] = useState(false)
  const [rtbdata, setRtbdata] = useState(false)

  // const [selectedFile, setSelectedFile] = useState(null);
  let arr =
    sessionStorage.getItem("Roledetails") == "undefined"
      ? ""
      : JSON.parse(sessionStorage.getItem("Roledetails"));
  let rolecode = arr.RoleCode;
  // console.log(releases, "releases");
  const multiplesm =
    localStorage.getItem("multiplesm") == "fullaccess_to_all_sms";
  const api2 =
    multiplesm && projectRole == "SM"
      ? `agilesaas_issue_details?select=IssueId,LinkToIssues,IssueTitle,IsActive,ParentIssue,IssueType,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(RiskofDelivery,IssueId,UpdatedDate,RiskofDelivery,PercentageProgressed)&IsActive=eq.Y`
      // `agilesaas_issue_details?IsActive=eq.Y`
      : rolecode == "SM" || projectRole == "SM"
        ? `agilesaas_issue_details?select=IssueId,LinkToIssues,IssueTitle,IsActive,ParentIssue,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,IssueType,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed)or=(CreatedBy.eq.${empid[0]?.ClientUserId},AssignedTo.eq.${empid?.[0]?.ClientUserId},CreatedBy.eq.${empid[0]?.ClientUserId})&order=CreatedDate.desc&IsActive=eq.Y`
        : // `agilesaas_issue_details?or=(AssignedTo.eq.${empid[0]?.ClientUserId},CreatedBy.eq.${empid[0]?.OnboardedBy},CreatedBy.eq.${empid[0]?.OnboardedBy})`
        rolecode == "QA" || projectRole == "QA"
          ? `agilesaas_issue_details?or=(AssignedTo.eq.${empid[0]?.ClientUserId},AssignedToUAT.eq.${empid[0]?.ClientUserId},CreatedBy.eq.${empid[0]?.ClientUserId})&order=CreatedDate.desc&IsActive=eq.Y`
          : `agilesaas_issue_details?select=IssueId,LinkToIssues,IssueTitle,IsActive,ParentIssue,ProjectId,ProjectShortCode,Description,Priority,PlannedRelease,ActualRelease,OriginalEstimate,CurrentStage,LinkToEpic,CreatedBy,CreatedByDetails,CreatedDate,AssignedTo,AssignedToDetails,AssignedToUAT,AssignedToUATDetails,IssueType,ClientId,ModuleLinked,AllProgress:agilesaas_issue_progress(RiskofDelivery,IssueId,UpdatedDate,PercentageProgressed)&AssignedTo=eq.${empid[0]?.ClientUserId}&order=CreatedDate.desc&IsActive=eq.Y`;

  const handleClose = () => {
    if (checkChange || selectedFile != null || defaultrelease != releaseLabel) {
      // if (defaultrelease != releaseLabel || (SEpic?.label != undefined && SEpic?.label !== epic) || historyUpdate || title != titlechanged ||
      //   stage != stageName || priority != constPrior || constAssignedId != assignedId || UatId != AssignedToUAT) {
      setConfirm(true);
      axios
        .get(
          "agilesaas_issue_progress?select=IssueId&IssueId=eq." +
          SelectedCardId +
          "&limit=1"
        )
        .then((res) => {
          setHistoryUpdate(res.data?.length != 0);
          // setRefresh(true);
          dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: true }))
        });
    } else {
      // axios.get("agile_issue_details?IssueId=eq." + SelectedCardId).then(res => setLastupdatename(res.data) )
      // setProgressopen(false);
      dispatch(HANDLE_ON_CHANGE({ name: 'progressopen', value: false }))
      // setCheckChange(false);
      dispatch(HANDLE_ON_CHANGE({ name: 'checkChange', value: false }))
      setTitlechanged(null);
      // setSelectedFile(null);
      dispatch(HANDLE_ON_CHANGE({ name: 'selectedFile', value: null }))
      // const closeingId = lastupdateName.length != 0 ? (lastupdateName[0].ProjectId) : null

      // const closeingProjectName = lastupdateName.length != 0 ? (lastupdateName[0].ProjectShortCode) : null

      // sendDataToParent({ closeingId: closeingId, closeingProjectName: closeingProjectName })
    }
  };

  const rolecheck =
    projectRole == "SM" || projectRole == "ITMGR2" || getProdRole == "KA";

  // const [SEpic, setSEpic] = useState(null);
  // const [cmodule, setCmodule] = useState(null);
  const Data =
    KanbanBoardData?.filter((x) => x?.IssueId == SelectedCardId)?.length != 0
      ? KanbanBoardData?.filter((x) => x.IssueId == SelectedCardId)
      : KanbanBoardData?.filter((x) => x.IssueId == SelectedCardId);
  // console.log(KanbanBoardData?.filter((x) => x.IssueId == SelectedCardId), SelectedCardId)
  const issueType = Data?.length != 0 ? Data?.[0]?.IssueType : null;
  const epicHeader =
    Data?.length != 0
      ? Data?.[0]?.ProjectShortCode + " - " + Data?.[0]?.IssueId
      : null;
  const epicTitle =
    issueType != "Epic" && issueType != "SubTask"
      ? Data?.length != 0
        ? Data?.[0]?.ProjectShortCode +
        " - " +
        Data?.[0]?.LinkToEpic?.[0]?.IssueId +
        " / "
        : null
      : null;
  const epicIssueId =
    issueType != "Epic" && issueType != "SubTask"
      ? Data?.length != 0
        ? Data?.[0]?.LinkToEpic?.[0]?.IssueId
        : null
      : null;
  const projectHeader =
    Data?.length != 0
      ? Data?.[0]?.ProjectShortCode + " - " + Data?.[0]?.IssueId
      : null;
  const title = Data?.length != 0 ? Data?.[0]?.IssueTitle : null;
  const projectId = Data?.length != 0 ? Data?.[0]?.ProjectId : null;
  const stage =
    Data?.length != 0 ? Data?.[0]?.CurrentStage?.[0]?.StageName : null;
  const stageinitialcode =
    Data?.length != 0 ? Data?.[0]?.CurrentStage?.[0]?.StageCode : null;
  const epic =
    issueType == "Epic" || issueType == "SubTask"
      ? null
      : Data?.length != 0
        ? Data?.[0]?.LinkToEpic?.[0]?.Title
        : null;
  const originalEstimate =
    Data?.length != 0
      ? Data?.[0]?.OriginalEstimate == null
        ? 0
        : Data?.[0]?.OriginalEstimate
      : null;
  const createdBy = Data?.length != 0 ? Data?.[0]?.CreatedByDetails : null;
  const createdId = Data?.length != 0 ? Data?.[0]?.CreatedBy : null;
  const desc = Data?.length != 0 ? Data?.[0]?.Description : null;
  const constPrior = Data?.length != 0 ? Data?.[0]?.Priority : null;
  const constRelease = Data?.length != 0 ? Data?.[0]?.PlannedRelease : null;
  const constAssignedId = Data?.length != 0 ? Data?.[0]?.AssignedTo : null;
  const AssignedToUAT = Data?.length != 0 ? Data?.[0]?.AssignedToUAT : null;
  // console.log(Data?.[0], Data?.length != 0, Data?.[0]?.IssueType == "SubTask",
  //   Data?.[0]?.ProjectShortCode,
  //   + "-" + Data?.[0]?.ParentIssue?.[0]?.IssueId,
  //   "uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu")
  const parentIssueTitle =
    Data?.length != 0
      ? Data?.[0]?.IssueType == "SubTask"
        ? Data?.[0]?.ProjectShortCode + "-" + Data?.[0]?.ParentIssue?.[0]?.IssueId
        : null
      : null;

  const parentIssueId =
    Data?.length != 0
      ? Data?.[0]?.IssueType == "SubTask"
        ? Data?.[0]?.ParentIssue?.[0]?.IssueId
        : null
      : null;
  const attachmentReference =
    Data?.length != 0 ? Data?.[0]?.AttachmentReference : null;
  // console.log(createdBy, "createdBy", Data)
  const defaultrelease = Data?.[0]?.PlannedRelease;

  if (releaseLabel == null) {
    // setReleaseLabel(constRelease);
    dispatch(HANDLE_ON_CHANGE({ name: 'releaseLabel', value: constRelease }))
  }
  // const [stageName, setStageName] = useState();
  // const [stageCode, setStageCode] = useState();
  // const [assignedId, setAssignedId] = useState();
  // const [assignedTo, setAssignedTo] = useState();
  // const [priority, setPriority] = useState();
  // const [checkChange, setCheckChange] = useState(false);
  const [confirm, setConfirm] = useState(false);

  // const [description, setDescription] = useState(desc);
  // const [releases, setReleases] = useState([])
  const [updateField, setUpdateField] = useState([]);
  const [updateFrom, setUpdateFrom] = useState([]);
  const [updateTo, setUpdateTo] = useState([]);
  // const [releaseUpdate, setReleaseUpdate] = useState([]);
  const [effortCapture, setEffortCapture] = useState([]);
  const [historyUpdate, setHistoryUpdate] = useState(false);
  // const [UatTo, setUatTo] = useState();
  // const [UatId, setUatId] = useState();

  const [titlechanged, setTitlechanged] = useState(title);
  const [lastupdateName, setLastupdatename] = useState([]);
  const [checkSubmit, setSubmitCheck] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);


  let emplogin = JSON.parse(localStorage.getItem("Empdetails"));
  let clientdetails = JSON.parse(localStorage.getItem("clientdetails"));
  const userorg = clientdetails[0]?.OrgDetails;
  let login = emplogin != null ? emplogin : null;

  // const dispatch = useDispatch();

  useEffect(() => {
    refresh();
    dispatch(HANDLE_ON_CHANGE({ name: 'description', value: desc }))
  }, []);

  function refresh() {
    setUpdateFrom([])
    setUpdateTo([])
    setUpdateField([])
    axios.get(api2).then((response) => {
      if (response.data?.length != 0) {
        // setKanbanBoardData(response.data);
        dispatch(KANBANBOARDDATAUPDATE(response.data));
        // setFilteredData(response.data);
        dispatch(HANDLE_ON_CHANGE({ name: 'FilteredData', value: response.data }))

        // setRefresh(false);
        dispatch(HANDLE_ON_CHANGE({ name: 'Refresh', value: false }))

      }
    });
    // setReleaseLabel();
    dispatch(HANDLE_ON_CHANGE({ name: 'releaseLabel', value: '' }))
    // setCheckChange(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'checkChange', value: false }))
    setConfirm(false);
    // setProgressopen(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'progressopen', value: false }))
    dispatch(HANDLE_ON_CHANGE({ name: 'cmodule', value: "" }))
    // setCmodule("");
    // KBRefresh();
    dispatch(HANDLE_ON_CHANGE({ name: 'kbrefreshstate', value: true }))
    axios.get(`agilesaas_releases?ProjectId=eq.${projectCode}`).then((res) => {
      dispatch(RELEASESUPDATE(res.data));
    });
  }

  let Assetoptions = resData
    .filter((x) => x.projectcode === projectId)
    .map((x) => {
      return {
        value: x.teammemberid,
        label: x.firstname + " " + x.lastname,
        firstname: x.firstname,
        lastname: x.lastname,
        icon: (
          <Avatar
            className="progressName"
            src={
              allImage?.length > 0
                ? allImage?.filter((e) => e.teammemberid == x.teammemberid)?.[0]
                  ?.image
                : Unassigned
            }
          // sx={{ width: 10, height: 10 }}
          />
        ),
        // <Avatar className="progressName">{x?.firstname?.charAt(0) + x?.lastname?.charAt(0)}</Avatar>
      };
    });

  // const releasenew = releaseUpdate?.map((e)=>e.IssueId === IssueId  )
  // console.log(releasenew,"releasenew")

  const Firstname =
    assignedTo == undefined
      ? ""
      : assignedTo?.split(" ")?.length == 3
        ? assignedTo?.split(" ")?.[0] + " " + assignedTo?.split(" ")[1]
        : assignedTo?.split(" ")?.[0];

  const Lastname = assignedTo == undefined ? "" : assignedTo?.split(" ")?.pop();

  // console.log(assignedTo, "assignedto")
  // console.log(Firstname + " " + Lastname );
  useEffect(() => {
    setTitlechanged(title);
  }, [title]);
  const handleUpload = (newFileName) => {
    // `${cid}-${empDetails?.[0]?.ClientUserId}_${empDetails?.[0]?.UserFirstname}_${empDetails?.[0]?.UserLastname}.png`;
    // Create a new File object with the desired name
    const renamedFile = new File([selectedFile], newFileName, {
      type: selectedFile.type,
    });
    let data = new FormData();
    // console.log(renamedFile, "id");
    data.append("file", renamedFile);
    data.append("ClientId", clientid);

    // data.file.id = 20
    // data.append("ClientUserId", 287);
    axios
      .post("api/agile/filesystem/CreateIssue", data)
      // let config = {
      //   method: "post",
      //   maxBodyLength: Infinity,
      //   url: "http://localhost:2002/api/agile/filesystem/CreateIssue",
      //   data: data,
      // };

      // axios
      //   .request(config)
      .then((res) => console.log(res.data))
      .catch((e) => console.log(e));
  };

  // console.log(selectedCard1, "ssssssssssssssss");

  const refinedtobacklog = () => {
    const ck = selectedCard1
    axios.get(`agilesaas_releases?ReleaseName=eq.${ck?.PlannedRelease}&ProjectId=eq.${projectCode}`)
      .then((res) => {
        const data = res.data?.[0]
        const patchdata = {
          "ReleaseIssues": res.data?.[0]?.ReleaseIssues?.filter((a) => a.IssueId != ck?.IssueId),
          "RemovedTickets": res.data?.[0]?.RemovedTickets == null || res.data?.[0]?.RemovedTickets?.length == 0 ? [{ "IssueId": ck?.IssueId, "IssueTitle": ck?.IssueTitle }] :
            [...data?.RemovedTickets, { "IssueId": ck?.IssueId, "IssueTitle": ck?.IssueTitle }]
        }
        // console.log(patchdata, res.data?.[0]?.ReleaseIssues?.filter((a) => a.IssueId != ck?.IssueId), data?.RemovedTickets, "patch data")
        axios.patch(`agilesaas_releases?ReleaseId=eq.${data?.ReleaseId}`, patchdata)
          .then((r) => {
            axios.patch(`agilesaas_issue_details?IssueId=eq.${ck?.IssueId}`, {
              PlannedRelease: null,
              OriginalEstimate: null,
              CurrentStage: [
                {
                  StageCode: "BLG",
                  StageName: "Backlog",
                  DateMoved: moment().format("YYYY-MM-DD"),
                },
              ]
            })
              .then((r) => {
                Mailandnotification(
                  selectedCard1,
                  EmpDropDownData?.filter(
                    (x) => x.teammemberid == selectedCard1?.AssignedTo
                  )?.[0]?.email,
                  [empid?.[0]?.OfficialEmail],
                  true,
                  false,
                  projectName,
                  "Stage Update",
                  `${title || titlechanged
                  }'s stage is changed to Backlog by ${projectRole}`
                );
                setRtbdialog(false)
                dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
                toast.success('Moved to backlog and remove from sprint')
                refresh()
              })
          })

        let HisteryData = {
          IssueId: ck?.IssueId,
          ChangedBy: login?.[0]?.ClientUserId,
          ChangedByDetails: {
            FN: login?.[0]?.UserFirstname,
            LN: login?.[0]?.UserLastname,
          },
          ChangedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
          UpdatedField: "Kanban Changes",
          UpdatedFrom: "RFD",
          UpdatedTo: "BLG",
          UpdateSequence: null,
          IsActive: "Y",
          Status: null,
          ClientId: clientid,
          ProjectId: projectCode,
        };
        axios.post("agilesaas_issue_history", HisteryData)
          .then((r) => console.log(r))
      })
  }
  // console.log(defaultrelease, releaseValue, releaseLabel, "item.ReleaseId")
  // console.log(historyUpdate)
  // console.log((SEpic !== null && SEpic?.label !== epic) + "epic", (releaseLabel != null &&
  //   issueType != "Epic" &&
  //   defaultrelease != releaseValue) + "release", stage != stageName, historyUpdate + "stage name", priority1 != constPrior || historyUpdate + "priority", selectedFile, (constAssignedId != assignedId &&
  //     issueType != "Epic" &&
  //     issueType != "SubTask") ||
  // historyUpdate + "assigned to", (UatId != AssignedToUAT &&
  //   issueType != "Epic" &&
  //   issueType != "SubTask") ||
  // historyUpdate + "assigned to uat", (constRelease != releaseLabel &&
  //   issueType != "Epic" &&
  //   issueType != "SubTask") ||
  // historyUpdate + "release", selectedCard1?.ModuleLinked?.FullName != cmodule?.FullName &&
  // selectedCard1?.ModuleLinked?.FullName &&
  // cmodule?.FullName + "module")
  // console.log(UatId, AssignedToUAT,
  //   issueType != "Epic",
  //   issueType != "SubTask", "testtttttttttt")
  // console.log(stage != stageName && stage == "Refined" && stageName == "Backlog" && selectedCard1?.PlannedRelease, historyUpdate, "hhhhhhhhhhhhhhhhh")
  function save() {

    if (stage != stageName && stage == "Refined" && stageName == "Backlog" && selectedCard1?.PlannedRelease) {
      setRtbdialog(true)
    }
    else {
      const rname = selectedFile?.name.replace(/[\s-_]/g, "");
      const newFileName = `${clientid}-${projectCode}_${moment().format(
        "YYYYMMDDHHmmssSSS"
      )}_${rname}`;
      let sfilename = newFileName?.split("-")?.[1];
      let updatedData = {
        Filename: sfilename,
        UpdatedDate: moment(),
      };
      let fileUpdate = attachmentReference
        ? [...attachmentReference, updatedData]
        : [
          {
            Filename: sfilename,
            UpdatedDate: moment(),
          },
        ];

      if (selectedFile) {
        handleUpload(newFileName);
      }
      if (SEpic !== null && SEpic?.label !== epic) {
        axios
          .patch("agilesaas_issue_details?IssueId=eq." + SelectedCardId, {
            LinkToEpic: SEpic?.patchdata,
          })
          .then((res) => {
            // setSEpic(null);
            dispatch(HANDLE_ON_CHANGE({ name: 'SEpic', value: null }))
            updateField.push("Epic Changes");
            updateFrom.push(epic);
            updateTo.push(SEpic);
            // if ((rolecheck && constAssignedId) || assignedId) {
            //   Mailandnotification(
            //     selectedCard1,
            //     EmpDropDownData?.filter(
            //       (x) => x.teammemberid == selectedCard1?.AssignedTo
            //     )?.[0]?.email,
            //     [empid?.[0]?.OfficialEmail],
            //     true,
            //     false,
            //     projectName,
            //     "Epic change",
            //     `${title || titlechanged
            //     }'s epic is changed to ${SEpic} by Scrum Master`
            //   );
            // }
          })
          .catch((err) => console.log(err));
      }
      if (
        releaseLabel != null &&
        issueType != "Epic" &&
        defaultrelease != releaseValue
      ) {
        if (defaultrelease == null) {
          axios
            .get("agilesaas_releases?ReleaseId=eq." + releaseValue)
            .then((res) => {
              if (res.data?.length != 0) {
                // Compare the dates and return true if SprintStartDate is less than today's date, else return false
                let isSprintStartDateLessThanToday = moment(
                  res?.data?.[0]?.SprintStartDate
                ).diff(moment(), "days");
                let oldrelease =
                  res?.data?.[0].ReleaseIssues == null
                    ? []
                    : res?.data?.[0].ReleaseIssues;
                let odlticket =
                  res?.data?.[0].AddedTickets == null
                    ? []
                    : res?.data?.[0].AddedTickets;
                let newaddedticket = [
                  ...odlticket,
                  {
                    IssueId: SelectedCardId,
                    IssueTitle: `${titlechanged != null ? titlechanged : title}`,
                    DateAdded: moment(),
                  },
                ];
                let odlactualticket =
                  res?.data[0].ActualTickets == null
                    ? []
                    : res?.data[0].ActualTickets;
                let newpalnaddticket = [
                  ...odlactualticket,
                  {
                    IssueId: SelectedCardId,
                    IssueTitle: `${titlechanged != null ? titlechanged : title}`,
                  },
                ];

                let patchdata =
                  isSprintStartDateLessThanToday > 0
                    ? {
                      ActualTickets: newpalnaddticket,
                      PlannedTicketsCount: newpalnaddticket?.length,
                      ReleaseIssues: [
                        ...oldrelease,
                        {
                          IssueId: SelectedCardId,
                          IssueTitle: `${titlechanged != null ? titlechanged : title
                            }`,
                        },
                      ],
                    }
                    : {
                      AddedTickets: newaddedticket,
                      ReleaseIssues: [
                        ...oldrelease,
                        {
                          IssueId: SelectedCardId,
                          IssueTitle: `${titlechanged != null ? titlechanged : title
                            }`,
                        },
                      ],
                    };
                axios
                  .patch(
                    "agilesaas_releases?ReleaseId=eq." + releaseValue,
                    patchdata
                  )
                  .then((res) => console.log(res))
                  .catch((error) => console.log(error));
              }
            });
        } else if (defaultrelease != null && releaseValue != null) {
          axios
            .get("agilesaas_releases?ReleaseId=eq." + releaseValue)
            .then((res) => {
              if (res.data?.length != 0) {
                let isSprintStartDateLessThanToday = moment(
                  res?.data?.[0]?.SprintStartDate
                ).diff(moment(), "days");
                let oldrelease =
                  res?.data?.[0]?.ReleaseIssues == null
                    ? []
                    : res?.data?.[0]?.ReleaseIssues;
                let odlticket =
                  res?.data?.[0]?.AddedTickets == null
                    ? []
                    : res?.data?.[0]?.AddedTickets;
                let newaddedticket = [
                  ...odlticket,
                  {
                    IssueId: SelectedCardId,
                    IssueTitle: `${titlechanged != null ? titlechanged : title}`,
                    DateAdded: moment(),
                  },
                ];
                let odlactualticket =
                  res?.data?.[0]?.ActualTickets == null
                    ? []
                    : res?.data?.[0]?.ActualTickets;
                let newpalnaddticket = [
                  ...odlactualticket,
                  {
                    IssueId: SelectedCardId,
                    IssueTitle: `${titlechanged != null ? titlechanged : title}`,
                  },
                ];
                let patchdata =
                  isSprintStartDateLessThanToday > 0
                    ? {
                      ActualTickets: newpalnaddticket,
                      PlannedTicketsCount: newpalnaddticket?.length,
                      ReleaseIssues: [
                        ...oldrelease,
                        {
                          IssueId: SelectedCardId,
                          IssueTitle: `${titlechanged != null ? titlechanged : title
                            }`,
                        },
                      ],
                    }
                    : {
                      AddedTickets: newaddedticket,
                      ReleaseIssues: [
                        ...oldrelease,
                        {
                          IssueId: SelectedCardId,
                          IssueTitle: `${titlechanged != null ? titlechanged : title
                            }`,
                        },
                      ],
                    };

                axios
                  .patch(
                    "agilesaas_releases?ReleaseId=eq." + releaseValue,
                    patchdata
                  )
                  .then((res) => console.log(res))
                  .catch((error) => console.log(error));
              }
            });

          axios
            .get("agilesaas_releases?ReleaseName=eq." + defaultrelease)
            .then((res) => {
              let id = res.data?.[0]?.ReleaseId;
              if (res.data?.length != 0) {
                let finaldata = res.data?.[0]?.ReleaseIssues.filter(
                  (e) => e.IssueId != SelectedCardId
                );
                let odlticket =
                  res?.data?.[0]?.RemovedTickets == null
                    ? []
                    : res?.data?.[0]?.RemovedTickets;
                let newRemovedTickets = [
                  ...odlticket,
                  {
                    IssueId: SelectedCardId,
                    IssueTitle: `${titlechanged != null ? titlechanged : title}`,
                    DateRemoved: moment(),
                  },
                ];

                axios
                  .patch("agilesaas_releases?ReleaseId=eq." + id, {
                    RemovedTickets: newRemovedTickets,
                    ReleaseIssues: finaldata,
                  })
                  .then((e) => {
                    console.log(e);
                  });
              }
            })
            .catch((error) => console.log(error));
        }
        // if ((rolecheck && constAssignedId) || assignedId) {
        //   Mailandnotification(
        //     selectedCard1,
        //     EmpDropDownData?.filter(
        //       (x) => x.teammemberid == selectedCard1?.AssignedTo
        //     )?.[0]?.email,
        //     [empid?.[0]?.OfficialEmail],
        //     true,
        //     false,
        //     projectName,
        //     "Release update",
        //     `${title || titlechanged
        //     } is moved to the sprint version of ${releaseLabel} by Scrum Master`
        //   );
        // }
      }
      // if (historyUpdate) {
      //   updateFrom.push("");
      //   updateTo.push(createdBy);
      //   updateField.push("Created By");
      // }
      if (titlechanged != null && title != titlechanged) {
        const rdata = releases?.filter((e) => e.ReleaseName == releaseLabel)?.[0];
        let issuesArray = rdata?.ReleaseIssues;
        // const indexToUpdate = rdata != undefined && issuesArray.findIndex(issue => issue.IssueId === SelectedCardId);
        // console.log(SelectedCardId, indexToUpdate, issuesArray, indexToUpdate !== -1 && indexToUpdate != undefined, "check patch")
        // // Check if the issue with the specified IssueId was found
        const issueToUpdate =
          releaseLabel &&
          issuesArray?.filter((issue) => issue.IssueId !== SelectedCardId);
        const finalData = releaseLabel &&
          issueToUpdate?.length > 0 && [
            { IssueId: SelectedCardId, IssueTitle: titlechanged },
            ...issueToUpdate,
          ];
        // console.log(issueToUpdate, "check patch", finalData, rdata?.ReleaseId);
        if (releaseLabel && issueToUpdate?.length < finalData?.length) {
          axios
            .patch(`agilesaas_releases?ReleaseId=eq.${rdata?.ReleaseId}`, {
              ReleaseIssues: finalData,
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((e) => {
              console.log(e);
            });
        }
        updateFrom.push(title);
        updateTo.push(titlechanged);
        updateField.push("Issue Title");
        if (releaseLabel) {
          // axios.patch('agilesaas_releases')
        }
        // if ((rolecheck && constAssignedId) || assignedId) {
        //   Mailandnotification(
        //     selectedCard1,
        //     EmpDropDownData?.filter(
        //       (x) => x.teammemberid == selectedCard1?.AssignedTo
        //     )?.[0]?.email,
        //     [empid?.[0]?.OfficialEmail],
        //     true,
        //     false,
        //     projectName,
        //     "Title update",
        //     `${title || titlechanged
        //     } is updated to ${titlechanged} by Scrum Master`
        //   );
        // }
      }
      if (stage != stageName) {
        updateFrom.push(stage);
        updateTo.push(stageName);
        updateField.push("StageName");
        // if ((rolecheck && constAssignedId) || assignedId) {
        //   Mailandnotification(
        //     selectedCard1,
        //     EmpDropDownData?.filter(
        //       (x) => x.teammemberid == selectedCard1?.AssignedTo
        //     )?.[0]?.email,
        //     [empid?.[0]?.OfficialEmail],
        //     true,
        //     false,
        //     projectName,
        //     "Moved to " + stageName,
        //     `${title || titlechanged} is moved to ${stageName} by Scrum Master`
        //   );
        // }
      }
      if (priority1 != constPrior) {
        updateFrom.push(constPrior);
        updateTo.push(priority1);
        updateField.push("Priority");
        // if ((rolecheck && constAssignedId) || assignedId) {
        //   Mailandnotification(
        //     selectedCard1,
        //     EmpDropDownData?.filter(
        //       (x) => x.teammemberid == selectedCard1?.AssignedTo
        //     )?.[0]?.email,
        //     [empid?.[0]?.OfficialEmail],
        //     true,
        //     false,
        //     projectName,
        //     "Priority update",
        //     `${title || titlechanged
        //     } is moved to ${priority1} priority by Scrum Master`
        //   );
        // }
      }
      if (selectedFile) {
        updateFrom.push();
        updateTo.push(`${sfilename} file uploaded`);
        updateField.push("AttachmentReference");
        // if ((rolecheck && constAssignedId) || assignedId) {
        //   Mailandnotification(
        //     selectedCard1,
        //     EmpDropDownData?.filter(
        //       (x) => x.teammemberid == selectedCard1?.AssignedTo
        //     )?.[0]?.email,
        //     [empid?.[0]?.OfficialEmail],
        //     true,
        //     false,
        //     projectName,
        //     "File uploaded" + stageName,
        //     `File is uploaded in the issue of ${title || titlechanged
        //     } by scrum Master`
        //   );
        // }
      }
      if (
        (constAssignedId != assignedId &&
          issueType != "Epic" &&
          issueType != "SubTask")
      ) {
        updateFrom.push(constAssignedId);
        updateTo.push(assignedId);
        updateField.push("Assigned To");
        // if ((rolecheck && constAssignedId) || assignedId) {
        //   Mailandnotification(
        //     selectedCard1,
        //     EmpDropDownData?.filter(
        //       (x) => x.teammemberid == selectedCard1?.AssignedTo
        //     )?.[0]?.email,
        //     [empid?.[0]?.OfficialEmail],
        //     true,
        //     false,
        //     projectName,
        //     `${title || titlechanged} is assigned`,
        //     `${title || titlechanged} is assigned to you by scrum Master`
        //   );
        // }
      }
      if (
        (UatId != AssignedToUAT &&
          issueType != "Epic" &&
          issueType != "SubTask")
      ) {
        updateFrom.push(AssignedToUAT);
        updateTo.push(UatId);
        updateField.push("Assigned To UAT");
      }
      if (
        (constRelease != releaseLabel &&
          issueType != "Epic" &&
          issueType != "SubTask")
      ) {
        updateFrom.push(constRelease);
        updateTo.push(releaseLabel);
        updateField.push("Releases");
      }
      if (
        selectedCard1?.ModuleLinked?.FullName != cmodule?.FullName &&
        selectedCard1?.ModuleLinked?.FullName &&
        cmodule?.FullName
      ) {
        updateFrom.push(selectedCard1?.ModuleLinked?.FullName);
        updateTo.push(cmodule?.FullName);
        updateField.push("Modules");
        // if ((rolecheck && constAssignedId) || assignedId) {
        // Mailandnotification(
        //   selectedCard1,
        //   EmpDropDownData?.filter(
        //     (x) => x.teammemberid == selectedCard1?.AssignedTo
        //   )?.[0]?.email,
        //   [empid?.[0]?.OfficialEmail],
        //   true,
        //   false,
        //   projectName,
        //   `Module update`,
        //   `${title || titlechanged}'s module is updated to ${cmodule?.FullName
        //   } by scrum Master`
        // );
        // }
      }
      // }

      let notificationData = {
        CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
        CreatedBy: arr.empid,
        NotifyTo: assignedId,
        AudienceType: "Individual",
        Priority: "High",
        Subject: title + " is updated",
        Description:
          title + " is updated by " + arr.firstname + " " + arr.lastname,
        IsSeen: "N",
        Status: "New",
      };

      // if(releaseValue!=null && issueType!="Epic" && issueType!="SubTask") {
      //   axios.patch('agile_releases?ReleaseId=eq.'+releaseValue,{

      //   })
      // }
      setSubmitCheck(true);
      axios
        .patch("agilesaas_issue_details?IssueId=eq." + SelectedCardId, {
          IssueTitle:
            titlechanged != title && titlechanged != null ? titlechanged : title,
          Priority: priority1,
          PlannedRelease: issueType == "Epic" ? null : releaseLabel,
          CurrentStage: [
            {
              StageCode: stageCode,
              StageName: stageName,
              DateMoved: moment().format("YYYY-MM-DD"),
            },
          ],
          AssignedTo: issueType == "Epic" ? null : assignedId ? assignedId : null,
          // AssignedToDetails: { FN: issueType == "Epic" ? null : assignedTo.split(" ")[0], LN: issueType == "Epic" ? null : assignedTo.split(" ")[1] },
          AssignedToDetails: {
            FN: issueType == "Epic" ? null : Firstname,
            LN: issueType == "Epic" ? null : Lastname,
          },
          AssignedToUAT: UatId ? UatId : null,
          AssignedToUATDetails: {
            FN: issueType == "Epic" || UatTo == null ? null : UatTo.split(" ")[0],
            LN: issueType == "Epic" || UatTo == null ? null : UatTo.split(" ")[1],
          },
          AttachmentReference: selectedFile ? fileUpdate : attachmentReference,
          ModuleLinked: cmodule ? cmodule : selectedCard1?.ModuleLinked,
        })
        .then((res) => {
          toast.success(`Changes Applied Successfully (Issue ID: ${SelectedCardId})`, {
            transition: Slide,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            draggable: true,
          });
          console.log(updateField, updateFrom, updateTo, "updated to")
          const email = selectedCard1?.AssignedTo == empid?.[0]?.ClientUserId ? empid?.[0]?.ClientDetails.ContactEmail : EmpDropDownData?.filter(
            (x) => x.teammemberid == selectedCard1?.AssignedTo
          )?.[0]?.email
          const updatedrole = empid?.[0]?.UserFirstname + " " + empid?.[0]?.UserLastname
          Mailandnotification(
            selectedCard1,
            email,
            [empid?.[0]?.OfficialEmail],
            true,
            updateField?.filter((a) => a == "Assigned To")?.length > 0 ? true : false,
            projectName,
            updateField?.length > 0 ? `Issue update` : `${updateField?.join(', ')}`,
            updateField?.length == 1 ? `${title || titlechanged}'s ${updateField?.[0]} is updated to ${updateTo?.[0]} by ${updatedrole}` : `${title || titlechanged}'s ${updateField?.join(', ')} is updated by ${updatedrole}`
          );
          setSubmitCheck(false);
          // setCheckChange(false);
          dispatch(HANDLE_ON_CHANGE({ name: 'checkChange', value: false }))
          if (stage != stageName || historyUpdate) {
            axios.post("agilesaas_issue_history", {
              IssueId: SelectedCardId,
              ChangedBy: login?.[0]?.ClientUserId,
              ChangedByDetails: {
                FN: login?.[0]?.UserFirstname,
                LN: login?.[0]?.UserLastname,
              },
              ChangedDate: moment()
                .utcOffset("+05:30")
                .format("YYYY-MM-DDTHH:mm:ss"),
              UpdatedField: "Kanban Changes",
              UpdatedFrom: stageinitialcode,
              UpdatedTo: stageCode,
              UpdateSequence: updateField?.length,
              IsActive: "Y",
              Status: null,
              ClientId: clientid,
              ProjectId: projectCode,
            });
          }
          axios
            .post("agilesaas_issue_history", {
              IssueId: SelectedCardId,
              ChangedBy: login?.[0]?.ClientUserId,
              ChangedByDetails: {
                FN: login?.[0]?.UserFirstname,
                LN: login?.[0]?.UserLastname,
              },
              ChangedDate: moment()
                .utcOffset("+05:30")
                .format("YYYY-MM-DDTHH:mm:ss"),
              UpdatedField: updateField,
              UpdatedFrom: updateFrom,
              UpdatedTo: updateTo,
              UpdateSequence: updateField?.length,
              IsActive: "Y",
              Status: null,
              ClientId: clientid,
              ProjectId: projectCode,
            })
            .then((res) => {
              setUpdateField([]);
              setUpdateFrom([]);
              setUpdateTo([]);
              setTitlechanged(null);
              // setSelectedFile(null);
              dispatch(HANDLE_ON_CHANGE({ name: 'selectedFile', value: null }))
            });
          // if (issueType != "Epic") {
          //   Mailandnotification(
          //     selectedCard1,
          //     empid?.[0]?.OfficialEmail,
          //     [empid?.[0]?.OfficialEmail],
          //     true,
          //     false,
          //     projectName,
          //     "Issue title is updated",
          //     `Title is updated by scrum Master`
          //   );
          //   axios
          //     .post("notification?NotifyTo=eq." + arr.empid, notificationData)
          //     .then((res) => console.log(res))
          //     .catch((error) => console.log(error));
          // }
          refresh();
        })
        .catch((e) => {
          alert(e);
        });
      let emailto = projectMappingData
        ?.filter(
          (e) => e.email != "support@yozytech.com" && e.teammemberid == assignedId
        )
        ?.map((e) => e.email);
      let requrireddata = {
        ClientUserId: empid?.[0]?.ClientUserId,
        ClientId: clientid,
        ClientDetails: {
          Company: userorg?.Company,
          ContactName: userorg?.ContactName,
          ContactDesignation: userorg?.ContactDesignation,
          ContactEmail: userorg?.ContactEmail,
          ContactPhone: userorg?.ContactPhone,
        },
        DepartmentDetails: empid?.[0]?.DepartmentDetails,
        DepartmentCode: null,
        TentantId: "",
        FromSenderId: "no-reply@yozytech.com",
        emailnotify: true,
        webnotify: true,
        ToEmailIds: {
          emailids: emailto.toString(),
        },
        CcEmailIds: {
          emailids: ['agileteam@yozytech.com'],
        },
        BccEmailIds: "",
        EmailType: "StoryAssignment",
        ProductDetails: {
          ProdId: "11004",
          ProdName: "KANBAN",
          Domain: "KANBAN.yozytech.com",
        },
        EmailSubject: "Story Reassignment Notification",
        IsSuccess: "Y",
        IsPartiallyFailed: " ",
        SendRetryHistory: "",
        IsActive: " ",
        Status: "",
        EmailTemplateName: "StoryAssignment",
        EmailTemplateVariables: {
          xxasignbyxx: arr.RoleName,
          xxassigndatexx: moment().format("YYYY-MM-DD"),
          xxnotifytimexx: moment().format("YYYY-MM-DDTHH:mm:ss"),
          xxprjdisxx: Data?.length != 0 ? Data?.[0]?.Description : null,
          xxprjnamexx:
            Data?.length != 0 ? Data?.[0]?.ProjectDetails?.[0]?.ProjName : null,
          xxstoryNamexx: title,
          xxprjpercenxx: "",
          xxprjstartdatexx: "",
          xxprjenddataexx: "",
          xxteamnamexx: "",
          xxsprintxx: "",
          xxmanagernamexx: userorg?.ContactName,
          xxcompanyname: userorg?.Company,
          xxmanagerdesigxx: "Scrum Master",
          xxgmailxx: userorg?.ContactEmail,
          xxphnoxx: userorg?.ContactPhone,
          webappdescri:
            "You have reassigned to " + title + "in project " + Data?.length != 0
              ? Data?.[0]?.ProjectDetails?.[0]?.ProjName
              : null,
        },
      };

      // if (assignedId !== null) {
      // if (constAssignedId != assignedId) {
      // axios({
      //   url: "https://api.devozy.ai/api/agile/email",
      //   method: "post",
      //   data: requrireddata,
      //   headers: {
      //     origin: "https://kanban.yozytech.com",
      //     "Content-Type": "application/json",
      //   },
      // })
      //   .then((res) => {
      //     console.log(res.data, "mailsend");
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // }
      // }
    }
  }

  const epicLinkData = KanbanBoardData.filter((x) => x.IssueId == epicIssueId);
  const subTaskLinkData = KanbanBoardData.filter(
    (x) => x.IssueId == parentIssueId
  );
  // console.log(
  //   SelectedCardId,
  //   releases?.filter((e) => e.ReleaseName == releaseLabel)?.[0]?.ReleaseIssues,
  //   releaseLabel,
  //   releaseValue,
  //   "check release"
  // );

  // console.log(empid?.[0]?.OfficialEmail, empid, selectedCard1);
  const subTaskOnclick = () => {
    // KBRefresh();
    dispatch(HANDLE_ON_CHANGE({ name: 'kbrefreshstate', value: true }))
    // setCheckChange(false);
    dispatch(HANDLE_ON_CHANGE({ name: 'checkChange', value: false }))
    // setUatId(subTaskLinkData[0]?.AssignedToUAT);
    dispatch(HANDLE_ON_CHANGE({ name: 'UatId', value: subTaskLinkData[0]?.AssignedToUAT }))
    // setUatTo(subTaskLinkData[0]?.AssignedToUATDetails);
    dispatch(HANDLE_ON_CHANGE({ name: 'UatTo', value: subTaskLinkData[0]?.AssignedToUATDetails }))
    // setSelectedCardId(parentIssueId);
    dispatch(HANDLE_ON_CHANGE({ name: 'SelectedCardId', value: parentIssueId }))
    // setAssignedTo(
    //   subTaskLinkData[0]?.AssignedToDetails?.FN +
    //   " " +
    //   subTaskLinkData[0]?.AssignedToDetails.LN
    // );
    dispatch(HANDLE_ON_CHANGE({ name: 'assignedTo', value: subTaskLinkData[0]?.AssignedToDetails?.FN + " " + subTaskLinkData[0]?.AssignedToDetails.LN }))
    // setAssignedId(subTaskLinkData[0]?.AssignedTo);
    dispatch(HANDLE_ON_CHANGE({ name: 'assignedId', value: subTaskLinkData[0]?.AssignedTo }))
    // setDescription(subTaskLinkData[0]?.Description);
    dispatch(HANDLE_ON_CHANGE({ name: 'description', value: subTaskLinkData[0]?.Description }))
    // setPriority(subTaskLinkData[0]?.Priority);
    dispatch(HANDLE_ON_CHANGE({ name: 'priority1', value: subTaskLinkData[0]?.Priority }))
    // setStageName(subTaskLinkData[0]?.CurrentStage[0]?.StageName);
    dispatch(HANDLE_ON_CHANGE({ name: 'stageName', value: subTaskLinkData[0]?.CurrentStage[0]?.StageName }))
    // setStageCode(subTaskLinkData[0]?.CurrentStage[0]?.StageCode);
    dispatch(HANDLE_ON_CHANGE({ name: 'stageCode', value: subTaskLinkData[0]?.CurrentStage[0]?.StageCode }))
    // setReleaseValue(
    //   KanbanBoardData?.filter(
    //     (x1) => x1?.IssueId == subTaskLinkData[0]?.IssueId
    //   )[0]?.PlannedRelease
    // );
    dispatch(HANDLE_ON_CHANGE({ name: 'releaseValue', value: KanbanBoardData?.filter((x1) => x1?.IssueId == subTaskLinkData[0]?.IssueId)[0]?.PlannedRelease }))
    let relea = KanbanBoardData?.filter(
      (x1) => x1?.IssueId == subTaskLinkData[0]?.IssueId
    )[0]?.PlannedRelease;
    let data = releases.filter(
      (f) =>
        f?.ProjectId == subTaskLinkData[0]?.ProjectId && f?.ReleaseId == relea
    );
    // console.log( data.filter(x => subTaskLinkData[0].ReleaseId == relea), subTaskLinkData[0].PlannedRelease, data, relea, 'release data')
    // setReleaseLabel(
    //   data?.length != 0
    //     ? data?.filter(
    //       (x) => subTaskLinkData?.[0]?.PlannedRelease == relea
    //     )?.[0]?.ReleaseName
    //     : null
    // );
    dispatch(HANDLE_ON_CHANGE({ name: 'releaseLabel', value: data?.length != 0 ? data?.filter((x) => subTaskLinkData?.[0]?.PlannedRelease == relea)?.[0]?.ReleaseName : null }))
  };

  function findDiff(str1, str2) {
    let diff = "";
    str2.split("").forEach(function (val, i) {
      if (val != str1.charAt(i)) diff += val;
    });
    return diff;
  }
  // console.log(
  //   selectedCard1,
  //   EmpDropDownData?.filter(
  //     (x) => x.teammemberid == selectedCard1?.AssignedTo
  //   )?.[0]?.email,
  //   [empid?.[0]?.OfficialEmail]
  // );
  function TitileChange(e) {
    let notificationData = {
      CreatedDate: moment().utcOffset("+05:30").format("YYYY-MM-DDTHH:mm:ss"),
      CreatedBy: arr.empid,
      NotifyTo: assignedId,
      AudienceType: "Individual",
      Priority: "High",
      Subject: "Title is updated",
      Description: "Title is updated by " + arr.firstname + " " + arr.lastname,
      IsSeen: "N",
      Status: "New",
    };
    axios
      .patch("agilesaas_issue_details?IssueId=eq." + SelectedCardId, {
        IssueTitle: title,
      })
      .then((res) => {
        {
          // axios
          //   .post("notification?NotifyTo=eq." + arr.empid, notificationData)
          //   .then((res) => console.log(res))
          //   .catch((error) => console.log(error));
          // Mailandnotification(
          //   selectedCard1,
          //   selectedCard1?.AssignedTo == empid?.[0]?.ClientUserId ? empid?.[0]?.OfficialEmail : EmpDropDownData?.filter((x) => x.teammemberid == selectedCard1?.AssignedTo)?.[0]?.email,
          //   [empid?.[0]?.OfficialEmail],
          //   true,
          //   false,
          //   projectName,
          //   "Issue title is updated",
          //   `Title is updated by ${arr.firstname} ${arr.lastname}`)
        }
        let diff = findDiff(desc, description);
        axios.post("agilesaas_issue_history", {
          // IssueId: issueId,
          // ChangedBy: login?.[0]?.ClientUserId,
          //       ChangedByDetails: {
          //         FN: login?.[0]?.UserFirstname,
          //         LN: login?.[0]?.UserLastname,
          //       },
          ChangedBy: arr.empid,
          ChangedByDetails: {
            FN: arr.firstname,
            LN: arr.lastname,
          },
          ChangedDate: moment()
            .utcOffset("+05:30")
            .format("YYYY-MM-DDTHH:mm:ss"),
          UpdatedField: "Title",
          UpdatedFrom: "hh",
          UpdatedTo: title,
          UpdateSequence: 1,
          IsActive: "Y",
          Status: null,
          ClientId: clientid,
          ProjectId: projectCode,
        });
        toast.success(`Changes Applied Successfully (Issue ID: ${SelectedCardId})`, {
          transition: Slide,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          draggable: true,
        });
        // KBRefresh();
        dispatch(HANDLE_ON_CHANGE({ name: 'kbrefreshstate', value: true }))
      });
    // setEdit(false)
  }
  useEffect(() => {
    const fetchFiles = async () => {
      const files = [];
      // console.log(attachmentReference, "attachmentReferencefile");
      for (const attachment of attachmentReference) {
        try {
          const fileContent = await getFile(clientid, attachment.Filename);
          files.push({ filename: attachment.Filename, content: fileContent });
        } catch (error) {
          console.error(`Error fetching file ${attachment.Filename}:`, error);
        }
      }

      dispatch(FILESCONTENT(files));
    };
    if (attachmentReference) fetchFiles();
  }, [attachmentReference]);
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      ></link>
      <Dialog
        open={rtbdialog}
        onClose={() => {
          setRtbdialog(false)
        }}
        className="mainbox"
        maxWidth="xs"
      >
        {/* <DialogTitle className="d-flex justify-flex-end">
          <IconButton className="d-flex justify-flex-end" onClick={() => { handleDialogClose() }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle> */}
        <DialogContent className="pt-4">
          If the ticket is moved to the backlog, we will need to replan the sprint and re-estimate the card. Are you sure you want to proceed?
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <Button
              style={{
                fontSize: 14,
                padding: 9,
                width: 70,
                height: 30,
                borderRadius: 8,
                // backgroundColor: '#5250E5',
                // color: '#fff',
                // border: '0px solid #5A88F1',
                border: "2px solid #5A88F1",
                backgroundColor: "#fff",
                color: "#5250E5",
                textAlign: "center",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // marginRight: issueType == "Task" || issueType == "Bug" ? 5 : 24
              }}
              onClick={() => {
                setRtbdialog(false)
              }}
            // disabled={checkSubmit}
            >
              Cancel
            </Button>
            <Button
              // onClick={() => submit("RFD")}
              // disabled={checkSubmit || issueType == "Story" || issueType == "Epic"}
              style={{
                fontSize: 14,
                padding: 9,
                height: 30,
                borderRadius: 8,
                width: 70,
                // border: '2px solid #5A88F1',
                // backgroundColor: '#fff',
                // color: '#5250E5',
                backgroundColor: "#5250E5",
                color: "#fff",
                border: "0px solid #5A88F1",
                textAlign: "center",
                textTransform: "none",
                marginRight: 24,
                marginLeft: 5,
              }}
              onClick={() => {
                refinedtobacklog()
              }}
            >
              Proceed
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Storypoints
      // projectRole={projectRole}
      //  Data={Data} 
      />
      <Dialog open={confirm}>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} fontSize="15px" className="fontdesign">
                Do you want to save changes?
              </Box>
            </Box>
          </div>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              // setProgressopen();
              dispatch(HANDLE_ON_CHANGE({ name: 'progressopen', value: false }))

              setConfirm(false);
              // setCheckChange(false);
              dispatch(HANDLE_ON_CHANGE({ name: 'checkChange', value: false }))
              setTitlechanged(null);
              // setSEpic(null);
              dispatch(HANDLE_ON_CHANGE({ name: 'SEpic', value: null }))
              // setCmodule("");
              dispatch(HANDLE_ON_CHANGE({ name: 'cmodule', value: "" }))
              // setSelectedFile(null);
              dispatch(HANDLE_ON_CHANGE({ name: 'selectedFile', value: null }))
            }}
            style={{
              fontSize: 14,
              padding: 9,
              width: 100,
              height: 40,
              borderRadius: 8,
              marginRight: 14,
              border: "2px solid #5250E5",
              backgroundColor: "#fff",
              color: "#5250E5",
              textAlign: "center",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={checkSubmit}
            style={{
              fontSize: 14,
              padding: 9,
              width: 100,
              height: 40,
              borderRadius: 8,
              backgroundColor: "#5250E5",
              color: "#fff",
              border: "0px solid #5A88F1",
              textAlign: "center",
              textTransform: "none",
            }}
            onClick={() => {
              save();
            }}
          >
            {checkSubmit ? (
              <span
                className="spinner-border"
                role="status"
                aria-hidden="true"
                style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
              ></span>
            ) : (
              "Save"
            )}
          </Button>
          {/* <Button className="Card_Button_Color_Approve btn-greeng fontdesign" onClick={() => { save() }}>Save</Button>
          <Button className="Card_Button_Color_Approve btn-danger fontdesign" onClick={() => {
            setProgressopen()
            setConfirm(false)
            setCheckChange(false)
            setTitlechanged(null)
          }}>Cancel</Button> */}
        </DialogActions>
      </Dialog>

      <Dialog
        open={progressopen}
        className="mainbox"
        onClose={
          // setProgressopen
          () => { }
        }
        // maxWidth="800px"
        maxWidth="lg"
      >
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} fontSize="15px">
                {issueType === "Epic" ? (
                  <>
                    <OfflineBoltRoundedIcon
                      className="titleIcon"
                      style={{ backgroundColor: "#8a00e6" }}
                    />
                    {epicHeader}
                  </>
                ) : issueType == "SubTask" ? (
                  <>
                    {/* <AddToPhotosIcon className="titleIcon" style={{ backgroundColor: "skyblue" }} /> */}
                    <label
                      onClick={() => {
                        subTaskOnclick();
                      }}
                      className="textHeaderStyle fontdesign"
                    >
                      {parentIssueTitle}
                    </label>
                    <>
                      {" / "}
                      <AddToPhotosIcon
                        className="titleIcon "
                        style={{ backgroundColor: "skyblue" }}
                      />{" "}
                      {projectHeader}
                    </>
                  </>
                ) : issueType != "Epic" ? (
                  <>
                    <OfflineBoltRoundedIcon
                      className="titleIcon"
                      style={{ backgroundColor: "#8a00e6" }}
                    />{" "}
                    <label
                      onClick={() => {
                        if (createdId == arr.empid) {
                          // KBRefresh();
                          dispatch(HANDLE_ON_CHANGE({ name: 'kbrefreshstate', value: true }))
                          // setCheckChange(false);
                          dispatch(HANDLE_ON_CHANGE({ name: 'checkChange', value: false }))
                          // setSelectedCardId(epicIssueId);
                          dispatch(HANDLE_ON_CHANGE({ name: 'SelectedCardId', value: epicIssueId }))

                          // setAssignedTo(null);
                          dispatch(HANDLE_ON_CHANGE({ name: 'assignedTo', value: null }))
                          // setAssignedId(null);
                          dispatch(HANDLE_ON_CHANGE({ name: 'assignedId', value: null }))
                          dispatch(HANDLE_ON_CHANGE({ name: 'description', value: epicLinkData[0].Description }))
                          // setDescription(epicLinkData[0].Description);
                          // setPriority(epicLinkData[0].Priority);
                          dispatch(HANDLE_ON_CHANGE({ name: 'priority1', value: epicLinkData[0].Priority }))
                          // setStageName(
                          //   epicLinkData[0].CurrentStage[0].StageName
                          // );
                          dispatch(HANDLE_ON_CHANGE({ name: 'stageName', value: epicLinkData[0].CurrentStage[0].StageName }))
                          // setStageCode(
                          //   epicLinkData[0].CurrentStage[0].StageCode
                          // );
                          dispatch(HANDLE_ON_CHANGE({ name: 'stageCode', value: epicLinkData[0].CurrentStage[0].StageCode }))
                          // setReleaseValue(null);
                          dispatch(HANDLE_ON_CHANGE({ name: 'releaseValue', value: null }))
                          // setReleaseLabel(null);
                          dispatch(HANDLE_ON_CHANGE({ name: 'releaseLabel', value: null }))
                        }
                      }}
                      className="textHeaderStyle fontdesign"
                    >
                      {epicTitle}
                    </label>
                    {issueType === "Story" ? (
                      <>
                        <Bookmark
                          className="titleIcon"
                          style={{ backgroundColor: "#00cc00" }}
                        />
                        {projectHeader}
                      </>
                    ) : issueType === "Task" ? (
                      <>
                        <CheckBoxRoundedIcon
                          className="titleIcon"
                          style={{
                            fontSize: "10px",
                            backgroundColor: "skyblue",
                          }}
                        />{" "}
                        {projectHeader}
                      </>
                    ) : issueType === "Bug" ? (
                      <>
                        <AlbumRounded
                          className="titleIcon"
                          style={{ backgroundColor: "#cc0000" }}
                        />
                        {projectHeader}
                      </>
                    ) : issueType === "SubTask" ? (
                      <>
                        <AddToPhotosIcon
                          className="titleIcon"
                          style={{ backgroundColor: "skyblue" }}
                        />{" "}
                        {projectHeader}
                      </>
                    ) : null}
                  </>
                ) : null}
                {" "}
                {
                  <input
                    disabled={Data[0]?.CurrentStage?.[0]?.StageCode === "DONE"}
                    className="border-0"
                    style={{ width: 800 }}
                    value={titlechanged == null ? title : titlechanged}
                    onChange={(e) => {
                      setTitlechanged(e.target.value);
                      // setCheckChange(true);
                      dispatch(HANDLE_ON_CHANGE({ name: 'checkChange', value: true }))
                    }}
                  />
                }
                {/* <button onClick={TitileChange} className="border-0"> <EditIcon /> </button> */}
              </Box>
            </Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* {console.log(attachmentReference, "attachment")} */}
            <UserWorkLogData
            // EmpDropDownData={EmpDropDownData}
            // attachmentReference={attachmentReference}
            // selectedFile={selectedFile}
            // setSelectedFile={setSelectedFile}
            // projectRole={projectRole}
            // KanbanBoardData={KanbanBoardData}
            // SelectedCardId={SelectedCardId}
            // KBRefresh={KBRefresh}
            // Assetoptions={Assetoptions}
            // setSelectedCardId={setSelectedCardId}
            // stageName={stageName}
            // setStageName={setStageName}
            // stageCode={stageCode}
            // setStageCode={setStageCode}
            // assignedTo={assignedTo}
            // setAssignedTo={setAssignedTo}
            // createdBy={createdBy}
            // epic={epic}
            // priority={priority}
            // setPriority={setPriority}
            // originalEstimate={originalEstimate}
            // checkChange={checkChange}
            // setCheckChange={setCheckChange}
            // epicIssueId={epicIssueId}
            // issueType={issueType}
            // Data={Data}
            // releaseValue={releaseValue}
            // setReleaseValue={setReleaseValue}
            // releaseLabel={releaseLabel}
            // setReleaseLabel={setReleaseLabel}
            // assignedId={assignedId}
            // setAssignedId={setAssignedId}
            // desc={desc}
            // description={description}
            // setDescription={setDescription}
            // setReleaseUpdate={setReleaseUpdate}
            // effortCapture={effortCapture}
            // Bfopen={Bfopen}
            // setBfopen={setBfopen}
            // selectedCard={selectedCard}
            // setselectedcard={setselectedcard}
            // IssueId={IssueId}
            // setIssueId={setIssueId}
            // openCreateEst={openCreateEst}
            // setOpenCreateEst={setOpenCreateEst}
            // uatDropdown={uatDropdown}
            // UatTo={UatTo}
            // setUatTo={setUatTo}
            // UatId={UatId}
            // setUatId={setUatId}
            // createdId={createdId}
            // displaySubTask={displaySubTask}
            // subTaskApi={subTaskApi}
            // setRefresh={setRefresh}
            // UATtesterD={UATtesterD}
            // setUATtesterD={setUATtesterD}
            // UATchecklistD={UATchecklistD}
            // setUATchecklistD={setUATchecklistD}
            // progressdata={progressdata}
            // setProgressData={setProgressData}
            // SEpic={SEpic}
            // setSEpic={setSEpic}
            // cmodule={cmodule}
            // setCmodule={setCmodule}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default ProgressHistory;
