import React, { useEffect, useState } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LeaveManagementSystem from "./LeaveManagementSystem";
import { Employeeleave } from "./Employeeleave";
// import "./change.css";

export default function Leavenew() {
  const rolecode =
    localStorage.getItem("Rolecode") == "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Rolecode"));
  const defaultActiveKey = "Incident"; // Set the default active key
  // State to manage the active key
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  return (
    <Tabs
      // style={{ marginTop: "20px" }}
      defaultActiveKey={rolecode === "SM" ? "Empleave" : "Myleave"}
      id="justify-tab-example"
      className="horizontal-tabs ml-4 mt-4 mb-4"
      variant="tabs"
      onSelect={(key) => setActiveKey(key)}
    >
      {rolecode == "SM" ? (
        <Tab
          eventKey="Empleave"
          title="Team Leaves"
          className="headings"
          defaultActiveKey="Empleave"
        >
          <Employeeleave />
        </Tab>
      ) : null}
      <Tab eventKey="Myleave" title="My leaves" className="headings ml-2">
        <LeaveManagementSystem />
      </Tab>
    </Tabs>
  );
}
