import React, { useState } from 'react';
import FileViewer from 'react-file-viewer';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Col, Row } from 'reactstrap';
import CloseIcon from "@material-ui/icons/Close";
import * as XLSX from 'xlsx';
import { useEffect } from 'react';
import './DetailPanel.css'

const FilePreview = ({ content, filename, fileType }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [excelData, setExcelData] = useState([])

    console.log(fileType, "fileType")
    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // Check if file type is an image


    useEffect(() => {
        if (fileType == 'xlsx' || fileType == "xls" || fileType == "csv") {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0]; // Get the first sheet name
                const worksheet = workbook.Sheets[sheetName];
                const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                setExcelData(parsedData); // Set the state with the parsed Excel data
            };
            reader.readAsArrayBuffer(content);
        }
    }, [fileType, content]);

    const renderFileViewer = () => {
        console.log(fileType, content, "imagecalled", content?.size)
        if (content?.size) {
            if (fileType == "png" || fileType == "jpeg" || fileType == "jpg") {
                return (
                    <img
                        src={URL?.createObjectURL(content)}
                        alt={filename}
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                    />
                );
            } else if (fileType === 'xlsx' || fileType === 'csv' || fileType === 'xls') {
                return (
                    <div style={{ padding: 9, alignSelf: 'center', marginLeft: 15, marginRight: 15 }}>
                        {excelData.length > 0 && (
                            <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: 15, border: '1px solid #000' }}>
                                <thead>
                                    <tr style={{ borderBottom: '2px solid #000' }}>
                                        {excelData[0].map((header, index) => (
                                            <th key={index} style={{ padding: '8px', fontWeight: 'bold', border: '1px solid #000' }}>{header}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelData.slice(1).map((row, rowIndex) => (
                                        <tr key={rowIndex} style={{ borderBottom: '1px solid #ccc' }}>
                                            {row.map((cell, cellIndex) => (
                                                <td key={cellIndex} style={{ padding: '8px', border: '1px solid #000' }}>{cell}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                );
            }
            else if (fileType === 'm4a' || fileType === 'mp3' || fileType === 'wav' || fileType === 'ogg') {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                        <audio controls>
                            <source src={URL?.createObjectURL(content)} type="audio/mp4" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                );
            } else {
                // For other file types, use FileViewer
                return (
                    <FileViewer
                        fileType={fileType}
                        filePath={URL?.createObjectURL(content)}
                        onError={(e) => console.error('Error displaying file:', e)}
                    // style={{ width: '100%', height: '100%' }}
                    />
                );
            }
        }
    };

    const getFileExtension = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };

    const getIconForExtension = (extension) => {
        switch (extension) {
            case 'pdf':
                return 'pdf-icon.png'; // Replace with your PDF icon image
            case 'doc':
            case 'docx':
                return 'doc-icon.png'; // Replace with your Word document icon image
            case 'xls':
            case 'xlsx':
                return 'xls-icon.png'; // Replace with your Excel icon image
            // Add more cases for other file types as needed
            default:
                return 'default-icon.png'; // Default icon for unknown file types
        }
    };

    const handleDownload = (content, filename) => {
        const blob = new Blob([content]);
        const downloadUrl = URL?.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getFileIcon = (extension) => {
        const commonStyle = { position: 'relative', display: 'inline-block', overflow: 'visible', marginBottom: 15 };
        const iconStyle = { width: '48px', height: '48px', position: 'relative' };

        let iconSVG = '';
        switch (extension) {
            case 'pdf':
                iconSVG = (
                    // <svg
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     style={iconStyle}
                    //     viewBox="0 0 24 24"
                    // >
                    //     <path fill="#FF0000" d="M16,0H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V8ZM18,16H14V14h4Zm0-4H10V10h8Zm0-4H10V6h8ZM6,4A2,2,0,0,1,8,2H16V8H8A2,2,0,0,1,6,6ZM4,8H6v6a2,2,0,0,0,2,2H14v2a2,2,0,0,0,2,2h4V8Z" />
                    // </svg>
                    <img src={require('../../../assets/images/icons8-pdf-48.png')} />
                );
                break;
            case 'doc':
            case 'docx':
                iconSVG = (
                    // <svg
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     style={iconStyle}
                    //     viewBox="0 0 24 24"
                    // >
                    //     <path fill="#0000FF" d="M19,0H5A5,5,0,0,0,0,5V19a5,5,0,0,0,5,5H19a5,5,0,0,0,5-5V5A5,5,0,0,0,19,0Zm3,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3V19ZM11,18H8V9h3a2,2,0,0,1,2,2v1.5A2.5,2.5,0,0,1,11,15H9v1h2Zm4.5-1.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V18h-2V9h3a1.5,1.5,0,0,1,1.5,1.5Z" />
                    // </svg>
                    // <svg xmlns="http://www.w3.org/2000/svg"
                    //     style={iconStyle}
                    //     viewBox="0 0 48 48" width="48px" height="48px"><path fill="#2196F3" d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z" /><path fill="#FFF" d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z" /><path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z" /><path fill="#FFF" d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z" /></svg>
                    <img src={require('../../../assets/images/icons8-word-file-48.png')} />
                );
                break;
            case 'xls':
            case 'xlsx':
            case 'csv':
                iconSVG = (
                    // <svg
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     style={iconStyle}
                    //     viewBox="0 0 24 24"
                    // >
                    //     <path fill="#008000" d="M19,0H5A5,5,0,0,0,0,5V19a5,5,0,0,0,5,5H19a5,5,0,0,0,5-5V5A5,5,0,0,0,19,0ZM4,19V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3V19a3,3,0,0,1-3,3H5A3,3,0,0,1,4,19Zm9-9H8V6h5v4Zm3,0V6H18v4h1Zm2,0h1V6H18v4h1ZM8,15H9V11H8v4Zm2,0h1V11H10v4Zm2,0h1V11H12v4Z" />
                    // </svg>
                    // <svg xmlns="http://www.w3.org/2000/svg"
                    //     style={iconStyle}
                    //     viewBox="0 0 48 48" width="48px" height="48px"><path fill="#4CAF50" d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z" /><path fill="#FFF" d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z" /><path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z" /><path fill="#FFF" d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z" /></svg>
                    <img src={require('../../../assets/images/icons8-xls-48.png')} />
                );
                break;
            case 'mp3':
            case 'mp4':
            case 'm4a':
            case 'wav':
            case 'ogg':
                iconSVG = (
                    // <svg
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     style={iconStyle}
                    //     viewBox="0 0 24 24"
                    // >
                    //     <path fill="#008000" d="M19,0H5A5,5,0,0,0,0,5V19a5,5,0,0,0,5,5H19a5,5,0,0,0,5-5V5A5,5,0,0,0,19,0ZM4,19V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3V19a3,3,0,0,1-3,3H5A3,3,0,0,1,4,19Zm9-9H8V6h5v4Zm3,0V6H18v4h1Zm2,0h1V6H18v4h1ZM8,15H9V11H8v4Zm2,0h1V11H10v4Zm2,0h1V11H12v4Z" />
                    // </svg>
                    // <svg xmlns="http://www.w3.org/2000/svg"
                    //     style={iconStyle}
                    //     viewBox="0 0 48 48" width="48px" height="48px"><path fill="#4CAF50" d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z" /><path fill="#FFF" d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z" /><path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z" /><path fill="#FFF" d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z" /></svg>
                    <img src={require('../../../assets/images/icons8-circled-play-48.png')} />
                );
                break;
            default:
                iconSVG = (
                    // <svg
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     style={iconStyle}
                    //     viewBox="0 0 24 24"
                    // >
                    //     <path fill="#808080" d="M19,0H5A5,5,0,0,0,0,5V19a5,5,0,0,0,5,5H19a5,5,0,0,0,5-5V5A5,5,0,0,0,19,0ZM20,19a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V5A3,3,0,0,1,7,2H19a3,3,0,0,1,3,3V19ZM13,7h4v1H13ZM13,9h4V8H13ZM13,11h4V10H13Z" />
                    // </svg>
                    <img src={require('../../../assets/images/icons8-image-48.png')} />
                );
                break;
        }

        return (
            // <button onClick={handleOpenModal} style={{
            //     border: 'none', backgroundColor: '#F5F5F5', paddingRight: 15,
            //     paddingLeft: 15, paddingTop: 5, paddingBottom: 5, borderRadius: 5, marginLeft: 10
            // }}>
            //     <div style={commonStyle}>
            //         {iconSVG}
            //         <i
            //             className="fa fa-arrow-circle-down"
            //             style={{
            //                 position: 'absolute',
            //                 bottom: -15,
            //                 right: -9,
            //                 fontSize: '16px',
            //                 color: 'black',
            //                 marginTop: '5px',
            //                 color: 'darkblue' // Adjust margin-top value as needed
            //             }}
            //             onClick={(event) => {
            //                 event.stopPropagation();
            //                 // handleDownload();
            //                 alert('hanlde download')
            //             }}
            //         />
            //     </div>
            // </button>
            // <button
            //     onClick={handleOpenModal}
            //     style={{
            //         border: 'none',
            //         backgroundColor: '#F5F5F5',
            //         paddingRight: 15,
            //         paddingLeft: 15,
            //         paddingTop: 5,
            //         paddingBottom: 5,
            //         borderRadius: 5,
            //         marginLeft: 10,
            //         position: 'relative', // Add position relative for proper icon placement
            //         overflow: 'hidden' // Hide overflowing icon when not hovered
            //     }}
            // >
            //     <div style={commonStyle}>
            //         {iconSVG}
            //         <i
            //             className="fa fa-arrow-circle-down"
            //             style={{
            //                 position: 'absolute',
            //                 bottom: -15,
            //                 right: -9,
            //                 fontSize: '16px',
            //                 color: 'black',
            //                 marginTop: '5px',
            //                 visibility: 'hidden', // Initially hide the download icon
            //                 transition: 'visibility 0.3s ease' // Add transition effect
            //             }}
            //         />
            //     </div>
            //     <span
            //         className="download-text" // Add a class for hover effect
            //         style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            //     >
            //         <i className="fa fa-arrow-circle-down" style={{ fontSize: '16px', color: 'darkblue' }} />
            //     </span>
            // </button>
            <button
                onClick={handleOpenModal}
                className="download-button"
            >
                {iconSVG}
                <span className="download-icon">
                    <i
                        className="fa fa-arrow-circle-down"
                        style={{ fontSize: 20, padding: 5, color: 'rgb(82, 80, 229)' }}
                        onClick={(event) => {
                            event.stopPropagation();
                            handleDownload(content, filename)
                        }}
                    />
                </span>
                {/* <span className="center-text">Your Text Here</span> */}
            </button>


        );
    };




    return (
        <div>
            {/* <button onClick={handleOpenModal}
                style={{ border: 'none', backgroundColor: 'transparent', padding: 0 }}> */}
            {getFileIcon(getFileExtension(filename))}
            {/* </button> */}
            <Dialog
                className="mainbox"
                open={isOpen}
                onClose={handleCloseModal}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle>
                    <Row className="" style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                        <Col lg={0} md={8}>
                            <label className="text-secondary" style={{ fontWeight: "bold" }}>
                                {filename?.split('_')?.[2]}
                            </label>
                        </Col>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i
                                className="fa fa-arrow-circle-down"
                                style={{
                                    fontSize: 27,
                                    color: 'rgb(82, 80, 229)',
                                    transition: 'background-color 0.3s',
                                    borderRadius: '60%',
                                    padding: '10px',
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDownload(content, filename)
                                }}
                            />
                            <IconButton
                                // className="d-flex justify-content-end"
                                onClick={handleCloseModal}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>

                    </Row>

                </DialogTitle>
                {/* <button onClick={handleCloseModal}>Close</button> */}
                {/* <DialogContent> */}
                {/* <FileViewer
                    fileType={fileType} // Update with the appropriate file type (e.g., pdf, txt, etc.)
                    filePath={URL.createObjectURL(content)}
                    onError={(e) => console.error('Error displaying file:', e)}
                // style={{ width: '100%', height: '100%' }} 
                // Set the FileViewer's width and height to 100%
                /> */}

                {renderFileViewer()}


                {/* </DialogContent> */}
            </Dialog>
        </div>
    );
};

export default FilePreview;
