import React, { useState, useEffect, useContext } from "react";
import axios from "../../axios";
// import axios  from axios;
import Select from "react-select";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Cookies from "universal-cookie";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { PieChart } from '@mui/x-charts/PieChart';
import Divider from '@mui/material/Divider';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Grid from '@mui/material/Grid';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TabIcon from '@mui/icons-material/Tab';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch, useSelector } from 'react-redux'
import "./Dashboard.css";
import AvatarImg from "../../assets/images/derie.jpg";
import AWS from "../../assets/images/1.png";
import Azure from "../../assets/images/Azure.png";
import GCP from "../../assets/images/GCP.png";
import Pipeline from "../../assets/images/pipeline-img.jpeg";
import GIT from "../../assets/images/github-logo.png";
import { FILTEREDKANBANDATA, CURRENTSPRINT, PROLABEL, CONVIEWDATA, PROJECTCODEUPDATE, KANBANBOARDDATAUPDATE, HANDLE_ON_CHANGE } from "../../redux/CommonInitialsate";
import moment from "moment";
import Axios from "axios";
import InfoIcon from '@material-ui/icons/Info';
import { Button } from "@mui/material";
import { NavLink, Link, useLocation, Redirect } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import Unassigned from "../../assets/images/avatar.png";




const cookies = new Cookies();

function Dashboard() {
    //Select Project Dropdown
    const [Planned, setPlanned] = useState(0);
    const [Completed, setCompleted] = useState(0);
    const [issueV, setissueV] = useState("0");
    const [issueD, setissueD] = useState("0");
    const [issueP, setissueP] = useState("0");
    const [environments, setEnvironment] = useState([]);
    const [teamMember, setTeamMember] = useState(null);
    const [projectMaster, setProjectMaster] = useState([]);
    const [displaydata, setDisplayData] = useState([])
    const [percentagearr, setPercentagearr] = useState([])
    const [percentage, setPercentage] = useState([])
    const [devozyProjectOption, setDevozyProjectOption] = useState([])
    const [loading, setLoading] = useState(true)
    const [err, setErr] = useState(false)
    let arr2 =
        sessionStorage.getItem("Roledetails") == "undefined"
            ? ""
            : JSON.parse(sessionStorage.getItem("Roledetails"));
    let empid = JSON.parse(localStorage.getItem("Empdetails"));
    let crtid = empid?.[0]?.ClientUserId;
    // let tenantid = localStorage.getItem("tenantid") == "undefined" ? "" : localStorage.getItem("tenantid");
    let clientid = localStorage.getItem("clientid") == "undefined" ? "" : JSON.parse(localStorage.getItem("clientid"));
    let rolecode = arr2.RoleCode;
    let productRole = JSON.parse(localStorage.getItem("ProductRole"));
    const getProdRole = productRole?.filter((e) => e.ProdId == "11004")[0]
        ?.RoleCode;
    const company = empid?.[0]?.ClientDetails?.Company?.replace(/ /g, "");
    // console.log(`devozy_${company}_${clientid}`, "get storage")
    let tenantid = `devozy_${company}_${clientid}`
    // console.log(devozyProjectOption?.map((e) => e.DevozyProjectDetails), "percentagearr")
    const combinedArray = [].concat(...devozyProjectOption?.map((e) => e.DevozyProjectDetails));
    // console.log(combinedArray, "combined")
    const [label, setLable] = useState();

    const Dispatch = useDispatch()
    const { CurrentSprint, ProLabel, Fiteredkanbandata, KanbanBoardData, projectDetails, ConViewData, allImage, projectCode, projectRole, connectedviewdata } = useSelector((state) => state.user)
    // console.log(Fiteredkanbandata, "fffffffffffffffffff")
    let email = JSON.parse(localStorage.getItem("userdata"));
    // console.log(Fiteredkanbandata, KanbanBoardData, "conviewdata")
    const [incidents, setIncidents] = useState([])
    const [problems, setProblems] = useState([])
    const [changes, setChanges] = useState([])
    const [category, setCategory] = useState("")
    // console.log(changes, "incidents")
    const multiplesm = localStorage.getItem("multiplesm") == "fullaccess_to_all_sms"
    const api2 =
        multiplesm && projectRole == "SM" ?
            `agilesaas_issue_details` :
            rolecode == "SM" || getProdRole == "KA"
                ? //|| prole == "SM"
                `agilesaas_issue_details?or=(CreatedBy.eq.${crtid},AssignedTo.eq.${empid?.[0]?.ClientUserId},CreatedBy.eq.${crtid})&order=CreatedDate.desc`
                : // `agilesaas_issue_details?or=(AssignedTo.eq.${empid[0]?.ClientUserId},CreatedBy.eq.${empid[0]?.OnboardedBy},CreatedBy.eq.${empid[0]?.OnboardedBy})`
                rolecode == "QA" || projectRole == "QA"
                    ? `agilesaas_issue_details?or=(AssignedTo.eq.${crtid},AssignedToUAT.eq.${crtid},CreatedBy.eq.${crtid})&order=CreatedDate.desc`
                    : `agilesaas_issue_details?AssignedTo=eq.${crtid}&order=CreatedDate.desc`;
    useEffect(() => {
        if (KanbanBoardData?.length == 0) {
            axios.get(api2).then((response) => {
                console.log(response.data, "rrrrrrrrrrrrrrrrrrrrrr")
                Dispatch(KANBANBOARDDATAUPDATE(response.data));
                Dispatch(FILTEREDKANBANDATA(response.data.filter((e) => e.ProjectId == ConViewData.SelectedProject)))
            })
        }
    }, [])



    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: '#FFF',
            border: state.isFocused ? '1px solid #ccc' : '1px solid rgba(0, 0, 0, 0.10)',
            borderRadius: 5,
            minHeight: '30px',
            height: '30px',
            width: '150px',
            // height: 15,
            // background: "#f3f3ff",
            // match with the menu
            // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrittes the different states of border
            // borderColor: state.isFocused ? "yellow" : "green",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                // borderColor: state.isFocused ? "red" : "blue"
            }
        }),
        valueContainer: base => ({
            ...base,
            height: '30px',
        }),
        indicatorsContainer: base => ({
            ...base,
            height: '30px',
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
            width: `${Math.max(
                ...ProjectOption?.map((option) => option?.label?.length * 10)
            )}px`, // Adjust the multiplier based on your content
            minWidth: "100%",
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };
    const customStyles2 = {
        control: (base, state) => ({
            ...base,
            background: '#FFF',
            border: state.isFocused ? '1px solid #ccc' : '1px solid rgba(0, 0, 0, 0.10)',
            borderRadius: 5,
            minHeight: '20px',
            height: '25px',
            width: '150px',
            // height: 15,
            // background: "#f3f3ff",
            // match with the menu
            // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrittes the different states of border
            // borderColor: state.isFocused ? "yellow" : "green",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                // borderColor: state.isFocused ? "red" : "blue"
            }
        }),
        valueContainer: base => ({
            ...base,
            height: '25px',

        }),
        indicatorsContainer: base => ({
            ...base,
            height: '25px',

        }),
        menu: base => ({
            ...base,
            zIndex: 1,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
            width: `${Math.max(
                ...devozyProjectOption?.map((option) => option?.label?.length * 10)
            )}px`, // Adjust the multiplier based on your content
            minWidth: "100%",
        }),
        menuList: base => ({
            ...base,

            // kill the white space on first and last option
            padding: 0
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
    };

    function workingDays(startDate, diff1) {
        const weekdays = [];
        let current = startDate;
        let i = 0;
        while (i < diff1) {
            if (!isWeekEnd(current)) {
                weekdays.push(moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"));
            }
            let currentObj = current;
            current = moment(currentObj, "YYYY-MM-DD")
                .add(1, "days")
                .format("YYYY-MM-DD");
            i++;
        }
        function isWeekEnd(date) {
            let dateObj = moment(date, "YYYY-MM-DD");

            if (dateObj.day() === 6 || dateObj.day() === 0) {
                return true;
            }
            //   else {
            //     if (holidays.includes(date)) {
            //       return true;
            //     } else {
            //       return false;
            //     }
            //   }
        }

        return weekdays;
        // }
    }



    const progressPercentage = (getdata) => {
        const disdataid = getdata?.map((e) => e.issueid);
        const issueIds = disdataid?.join(",");
        axios
            .get(
                `agilesaas_issue_progress?select=EffortPending,EffortRemaining,RiskofDelivery,UpdatedDate,IssueId,UpdatedBy,PercentageProgressed&IssueId=in.(${issueIds})&order=UpdatedDate.desc`
            )
            .then((result) => {
                let data = result.data.map((e) => {
                    return {
                        EffortPending: e.EffortPending,
                        EffortRemaining: e.EffortRemaining,
                        RiskofDelivery: e.RiskofDelivery,
                        UpdatedDate: moment(e.UpdatedDate).format("DD-MMM-YYYY"),
                        IssueId: e.IssueId,
                        UpdatedBy: e.UpdatedBy,
                        PercentageProgressed: e.PercentageProgressed,
                    };
                });
                displaydata.push(data);
                let addarr = [];
                let increment = 0;
                const datacheck = getdata;
                let temp = moment(getdata?.[0]?.sprintenddate).diff(
                    moment(getdata?.[0]?.sprintstartdate),
                    "day"
                );

                const diff =
                    temp == 0
                        ? 1
                        : Number(
                            Math.ceil(
                                (new Date(getdata?.[0]?.sprintenddate) -
                                    new Date(getdata?.[0]?.sprintstartdate)) /
                                (1000 * 60 * 60 * 24)
                            ) + 1
                        );
                const excludeday = workingDays(getdata?.[0]?.sprintstartdate, diff + 0);
                // console.log(datacheck, "datacheck", displaydata, "displaydata");
                datacheck.map((e, i) => {
                    increment = i;
                    const progressdata =
                        displaydata.flat().filter((e5) => e5.IssueId == e.issueid);
                    let percentdata = [];
                    // console.log(progressdata, "progressdata");
                    excludeday.map((wdate, index) => {
                        const filterprogress = progressdata.filter(
                            (pr) => moment(pr.UpdatedDate).diff(moment(wdate), "day") == 0
                        );
                        const beforedate = progressdata.filter(
                            (date) => moment(date.UpdatedDate).diff(moment(wdate), "day") < 0
                        );
                        // console.log(filterprogress, "filterprogress", wdate, "wdate");

                        if (filterprogress.length > 0) {
                            percentdata.push(
                                filterprogress[0]?.PercentageProgressed == undefined
                                    ? 0
                                    : filterprogress[0]?.PercentageProgressed
                            );
                        } else {
                            if (beforedate.length > 0) {
                                percentdata.push(
                                    percentdata[percentdata.length - 1] == undefined
                                        ? 0
                                        : percentdata[percentdata.length - 1]
                                );
                            } else if (
                                (filterprogress.length == 0 && beforedate.length == 0) ||
                                filterprogress.length == 0
                            ) {
                                percentdata.push(0);
                            }
                        }
                        // console.log(percentdata, wdate);
                    });

                    addarr.push(percentdata);
                });
                setPercentagearr(addarr);
                const sums = addarr.reduce((acc, arr) => {
                    arr.forEach((value, index) => {
                        acc[index] = (acc[index] || 0) + value;
                    });
                    return acc;
                }, []);
                let arrvalue = [];
                if (datacheck.length == increment + 1) {
                    sums.map((sum, index) => {
                        arrvalue.push(sum / addarr.length);
                    });
                }
                setPercentage(arrvalue);
                // dispatch(PERCENTAGE(arrvalue));
            });


    }

    const itsmapi = (category) => {
        axios.get(`agilesaas_incident_details?ClientId=eq.${clientid}&order=CreatedDate.desc&CategoryCode->>CategoryName=ilike.*${category}*`)
            .then((res) => { setIncidents(res.data) })
            .catch((e) => console.log(e))
        axios.get(`agilesaas_change_details?ClientId=eq.${clientid}&order=CreatedDateTime.desc&Category->>CategoryName=ilike.*${category}*`)
            .then((res) => { setChanges(res.data) })
            .catch((e) => console.log(e))
        axios.get(`agilesaas_problem_details?ClientId=eq.${clientid}&order=CreatedDateTime.desc&Category->>label=ilike.*${category}*`)
            .then((res) => { setProblems(res.data) })
            .catch((e) => console.log(e))

    }

    const categoryUpdate = (prname) => {
        // console.log(prname, "prname")
        axios.get(`https://api.devozy.ai/agilesaas_incident_category_master?ClientId=eq.${clientid}`)
            .then((res) => {
                // console.log(res.data.filter((e) => e.CategoryName.toLowerCase() == prname.toLowerCase()), "res.data")
                const filterData = res.data.filter((e) => e.CategoryName?.toLowerCase() == prname?.toLowerCase())
                if (filterData?.length > 0) {
                    setCategory(filterData?.[0]?.CategoryName)
                    itsmapi(filterData?.[0]?.CategoryName)
                }
                else {
                    setCategory("")
                    setIncidents([])
                    setProblems([])
                    setChanges([])
                }
            })

    }
    // console.log(ConViewData, "displaydata")
    useEffect(() => {
        const prname = ProLabel?.length != 0 ? ProLabel : ConViewData.ProjectMaster?.[0]?.ProjectName
        Dispatch(PROLABEL(prname))
        categoryUpdate(prname)



    }, [ConViewData.ProjectMaster?.[0]?.ProjectName])
    // console.log(cookies.get('token'), "token")
    // console.log(environments.length, "environments")

    useEffect(() => {
        if (environments?.length == 0) {
            // let item = {
            //     label: 'devozy-java',
            //     value: 'PRJ1000001',
            // }
            axios
                .get(`agilesaas_devozy_project_mapping?AgileProjId=eq.${projectCode}`)
                .then((res) => {
                    setDevozyProjectOption(res?.data)
                    let details = res.data?.[0]?.DevozyProjectDetails?.[0]
                    setLable({
                        "label": details?.ProjDesc,
                        "value": details?.ProjCode
                    })
                    axios.get(`rpc/fun_getticketcounts_connectedview?projid=${projectCode}`)
                        .then((resd) => {
                            console.log(resd.data)
                            Dispatch(HANDLE_ON_CHANGE({ name: 'connectedviewdata', value: resd.data }))
                        })
                        .catch((e) => { console.log(e) })
                    setTimeout(() => {
                        Axios({
                            url: `https://api.devozy.ai/api/v1/devozy/connectedview?ProjectCode=${details?.ProjCode}`,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "tenantId": tenantid,
                                "email": email?.login,
                                Authorization: "Bearer " + cookies.get("token"),
                            },
                            method: "get",
                        })
                            .then((resp) => {
                                setLoading(false)
                                setErr(false)
                                // console.log(resp, "resp")
                                let Development = resp.data.filter((x) => x.ProjType == "Development")
                                let one = false;
                                let two = false;
                                let three = false;
                                let InstanceType = null;
                                let cloud = null;
                                let PublicIpAddress = null;
                                let UATInstanceType = null;
                                let UATcloud = null;
                                let UATPublicIpAddress = null;
                                let PreproductionInstanceType = null;
                                let Preproductioncloud = null;
                                let PreproductionPublicIpAddress = null;
                                let deploymentdata1 = null;
                                let deploymentdata2 = null;
                                let deploymentdata3 = null;
                                let status1 = null;
                                let Totalrun1 = null;
                                let createddate1 = null;
                                let status2 = null;
                                let Totalrun2 = null;
                                let createddate2 = null;
                                let status3 = null;
                                let Totalrun3 = null;
                                let createddate3 = null;
                                if (Development?.length > 0) {
                                    one = true;
                                    InstanceType = Development[0].Environment.InstanceType;
                                    cloud = Development[0].Environment.cloud;
                                    PublicIpAddress = Development[0].Environment.PublicIpAddress;
                                    deploymentdata1 = Development[0].Deployment;
                                    status1 = Development[0]?.CI_CD[0]?.Status;
                                    Totalrun1 = Development[0]?.CI_CD?.length;
                                    createddate1 = Development[0]?.CI_CD[0]?.CreatedDate;
                                }
                                let UAT = resp.data.filter((x) => x.ProjType == "UAT")
                                if (UAT?.length > 0) {
                                    two = true;
                                    UATInstanceType = UAT[0].Environment.InstanceType;
                                    UATcloud = UAT[0].Environment.cloud;
                                    UATPublicIpAddress = UAT[0].Environment.PublicIpAddress;
                                    deploymentdata2 = UAT[0].Deployment;
                                    status2 = UAT[0]?.CI_CD[0]?.Status;
                                    Totalrun2 = UAT[0]?.CI_CD?.length;
                                    createddate2 = UAT[0]?.CI_CD[0]?.CreatedDate;
                                }
                                let Preproduction = resp.data.filter((x) => x.ProjType == "Preproduction")
                                if (Preproduction?.length > 0) {
                                    three = true;
                                    PreproductionInstanceType = Preproduction[0].Environment.InstanceType;
                                    Preproductioncloud = Preproduction[0].Environment.cloud;
                                    PreproductionPublicIpAddress = Preproduction[0].Environment.PublicIpAddress;
                                    deploymentdata3 = Preproduction[0].Deployment;
                                    status3 = Preproduction[0]?.CI_CD[0]?.Status;
                                    Totalrun3 = Preproduction[0]?.CI_CD?.length;
                                    createddate3 = Preproduction[0]?.CI_CD[0]?.CreatedDate;
                                }
                                Dispatch(CONVIEWDATA({
                                    ...ConViewData, Cicdselected: details?.ProjDesc,
                                    Development: {
                                        ...ConViewData.Development,
                                        Development: one,
                                        InstanceType: InstanceType,
                                        cloud: cloud,
                                        PublicIpAddress: PublicIpAddress,
                                        vCPU: Development?.[0]?.vm_spec?.vCPU,
                                        vRAM: Development?.[0]?.vm_spec?.vRAM,
                                        commit: Development?.[0]?.git_count?.commit,
                                        branch: Development?.[0]?.git_count?.branch,
                                        CreatedDate: createddate1,
                                        status: status1,
                                        TotalRan: Totalrun1,
                                    },
                                    UAT: {
                                        ...ConViewData.UAT,
                                        UAT: two,
                                        InstanceType: UATInstanceType,
                                        cloud: UATcloud,
                                        PublicIpAddress: UATPublicIpAddress,
                                        vCPU: UAT?.[0]?.vm_spec?.vCPU,
                                        vRAM: UAT?.[0]?.vm_spec?.vRAM,
                                        commit: UAT?.[0]?.git_count?.commit,
                                        branch: UAT?.[0]?.git_count?.branch,
                                        CreatedDate: createddate2,
                                        status: status2,
                                        TotalRan: Totalrun2,
                                    },
                                    Production: {
                                        ...ConViewData.Production,
                                        Production: three,
                                        InstanceType: PreproductionInstanceType,
                                        cloud: Preproductioncloud,
                                        PublicIpAddress: PreproductionPublicIpAddress,
                                        vCPU: Preproduction?.[0]?.vm_spec?.vCPU,
                                        vRAM: Preproduction?.[0]?.vm_spec?.vRAM,
                                        commit: Preproduction?.[0]?.git_count?.commit,
                                        branch: Preproduction?.[0]?.git_count?.branch,
                                        CreatedDate: createddate3,
                                        status: status3,
                                        TotalRan: Totalrun3,
                                    },
                                    Deployment: [deploymentdata1, deploymentdata2, deploymentdata3]
                                }))

                            })
                            .catch((err) => {
                                setLoading(false)
                                setErr(true)
                                console.log(err)
                            })
                    }, 10000)
                })
                .catch((err) => { console.log(err) })
        }
    }, [])

    function handleDevProject(item) {
        setLoading(true)
        setErr(false)
        // console.log(item)
        setLable(item)
        setTimeout(() => {
            Axios({
                url: `https://api.devozy.ai/api/v1/devozy/connectedview?ProjectCode=${item?.value}`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "tenantId": tenantid,
                    "email": email?.login,
                    Authorization: "Bearer " + cookies.get("token"),
                },
                method: "get",
            })
                .then((resp) => {
                    setLoading(false)
                    // console.log(resp, "resp")
                    let Development = resp.data.filter((x) => x.ProjType == "Development")
                    let one = false;
                    let two = false;
                    let three = false;
                    let InstanceType = null;
                    let cloud = null;
                    let PublicIpAddress = null;
                    let UATInstanceType = null;
                    let UATcloud = null;
                    let UATPublicIpAddress = null;
                    let PreproductionInstanceType = null;
                    let Preproductioncloud = null;
                    let PreproductionPublicIpAddress = null;
                    let deploymentdata1 = null;
                    let deploymentdata2 = null;
                    let deploymentdata3 = null;
                    let status1 = null;
                    let Totalrun1 = null;
                    let createddate1 = null;
                    let status2 = null;
                    let Totalrun2 = null;
                    let createddate2 = null;
                    let status3 = null;
                    let Totalrun3 = null;
                    let createddate3 = null;
                    if (Development?.length > 0) {
                        one = true;
                        InstanceType = Development[0].Environment.InstanceType;
                        cloud = Development[0].Environment.cloud;
                        PublicIpAddress = Development[0].Environment.PublicIpAddress;
                        deploymentdata1 = Development[0].Deployment;
                        status1 = Development[0]?.CI_CD[0]?.Status;
                        Totalrun1 = Development[0]?.CI_CD?.length;
                        createddate1 = Development[0]?.CI_CD[0]?.CreatedDate;
                    }
                    let UAT = resp.data.filter((x) => x.ProjType == "UAT")

                    if (UAT?.length > 0) {
                        two = true;
                        UATInstanceType = UAT?.[0]?.Environment?.InstanceType;
                        UATcloud = UAT?.[0]?.Environment?.cloud;
                        UATPublicIpAddress = UAT?.[0]?.Environment?.PublicIpAddress;
                        deploymentdata2 = UAT?.[0]?.Deployment;
                        status2 = UAT?.[0]?.CI_CD?.[0]?.Status;
                        Totalrun2 = UAT?.[0]?.CI_CD?.length;
                        createddate2 = UAT?.[0]?.CI_CD[0]?.CreatedDate;
                    }
                    let Preproduction = resp.data.filter((x) => x.ProjType == "Preproduction")

                    if (Preproduction?.length > 0) {
                        three = true;
                        PreproductionInstanceType = Preproduction?.[0]?.Environment?.InstanceType;
                        Preproductioncloud = Preproduction?.[0]?.Environment?.cloud;
                        PreproductionPublicIpAddress = Preproduction?.[0]?.Environment?.PublicIpAddress;
                        deploymentdata3 = Preproduction?.[0]?.Deployment;
                        status3 = Preproduction?.[0]?.CI_CD?.[0]?.Status;
                        Totalrun3 = Preproduction?.[0]?.CI_CD?.length;
                        createddate3 = Preproduction[0]?.CI_CD?.[0]?.CreatedDate;

                    }
                    Dispatch(CONVIEWDATA({
                        ...ConViewData, Cicdselected: item.label,
                        Development: {
                            ...ConViewData.Development,
                            Development: one,
                            InstanceType: InstanceType,
                            cloud: cloud,
                            PublicIpAddress: PublicIpAddress,
                            vCPU: Development?.[0]?.vm_spec?.vCPU,
                            vRAM: Development?.[0]?.vm_spec?.vRAM,
                            commit: Development?.[0]?.git_count?.commit,
                            branch: Development?.[0]?.git_count?.branch,
                            CreatedDate: createddate1,
                            status: status1,
                            TotalRan: Totalrun1,
                        },
                        UAT: {
                            ...ConViewData.UAT,
                            UAT: two,
                            InstanceType: UATInstanceType,
                            cloud: UATcloud,
                            PublicIpAddress: UATPublicIpAddress,
                            vCPU: UAT?.[0]?.vm_spec?.vCPU,
                            vRAM: UAT?.[0]?.vm_spec?.vRAM,
                            commit: UAT?.[0]?.git_count?.commit,
                            branch: UAT?.[0]?.git_count?.branch,
                            CreatedDate: createddate2,
                            status: status2,
                            TotalRan: Totalrun2,
                        },
                        Production: {
                            ...ConViewData.Production,
                            Production: three,
                            InstanceType: PreproductionInstanceType,
                            cloud: Preproductioncloud,
                            PublicIpAddress: PreproductionPublicIpAddress,
                            vCPU: Preproduction?.[0]?.vm_spec?.vCPU,
                            vRAM: Preproduction?.[0]?.vm_spec?.vRAM,
                            commit: Preproduction?.[0]?.git_count?.commit,
                            branch: Preproduction?.[0]?.git_count?.branch,
                            CreatedDate: createddate3,
                            status: status3,
                            TotalRan: Totalrun3,
                        },
                        Deployment: [deploymentdata1, deploymentdata2, deploymentdata3]
                    }))

                })
                .catch((err) => {
                    setLoading(false)
                    setErr(true)
                    console.log(err)
                    console.log(err, "resp")
                })
        }, 10000)

    }

    useEffect(() => {
        Axios({
            url:
                // 'https://ydpapi.yozy.in/api/v1/devozy/projects/PRJ1000001/summary',
                `https://api.devozy.ai/api/v1/devozy/projects/all`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "email": email?.login,
                "tenantId": localStorage.getItem("tenantid"),
                Authorization: "Bearer " + cookies.get("token"),
            },
            method: "get",
        })
            .then((result) => {

                setEnvironment(result.data.projects)
                Dispatch(CONVIEWDATA({ ...ConViewData, CicdDropdownvalue: result.data.projects }))
            })
            .catch((err) => console.log(err))

        axios.get("agilesaas_project_master?ProjectId=eq." + ConViewData.SelectedProject)
            .then((respo) => {
                setProjectMaster(respo?.data)
                Dispatch(CONVIEWDATA({ ...ConViewData, ProjectMaster: respo?.data }))
                axios.get("rpc/fun_agilesaassmprojandteamdetails?managerid=" + respo?.data[0].CreatedBy + "&projectcode=eq." + ConViewData.SelectedProject)
                    .then((resp) => {
                        setTeamMember(resp.data?.length)
                        Dispatch(CONVIEWDATA({ ...ConViewData, TeamMember: resp.data?.length, ProjectMaster: respo?.data }))
                    })
                    .catch((err) => console.log(err))

            })
            .catch((err) => console.log(err))

        Dispatch(FILTEREDKANBANDATA(KanbanBoardData.filter((e) => e.ProjectId == ConViewData.SelectedProject)))
        axios.get("agilesaas_releases?ProjectId=eq." + ConViewData.SelectedProject)
            .then((resp) => {
                setCompleted(resp.data.filter((e) => e.IsReleaseFrozen == "Y")?.length)
                setPlanned(resp.data.filter((e) => moment().diff(
                    moment(e.SprintStartDate),
                    "day"
                ) < 0)?.length)

            })
            .catch((err) => console.log(err))
        axios
            .get("rpc/fun_agilesaascurrentsprint?projid=" + ConViewData.SelectedProject)
            .then((response) => {
                // setprojectDetails
                progressPercentage(response.data)
                Dispatch(CURRENTSPRINT(response.data))
                setissueV(
                    response.data?.filter((f) => f.currentstage[0].StageName != "Backlog")?.length
                );
                setissueD(
                    (response.data == ""
                        ? 0
                        : response.data?.filter(
                            (f) => f.currentstage[0].StageName == "Done"
                        )
                    )?.length
                );

            })

    }, [])
    useDispatch(() => {
        Dispatch(FILTEREDKANBANDATA(KanbanBoardData?.[0]));
    })
    const ProjectOption = projectDetails?.map((e) => ({
        label: e.projectname,
        value: parseInt(e.projectcode),
        client: e.client,
    }));

    // const DevProjectOption = [{label: 'devozy-java',
    //     value:  'PRJ1000001',
    //     }]

    let DevProjectOption = combinedArray?.map((x) => ({
        label: x?.ProjDesc,
        value: x?.ProjCode,
    }))
    // ConViewData.CicdDropdownvalue && ConViewData.CicdDropdownvalue.length > 0
    //     ?
    //     ConViewData.CicdDropdownvalue.map((x) => ({
    //         label: x?.ProjName,
    //         value: x?.ProjCode,
    //     }))
    //     : environments && environments?.length > 0 ?
    //         environments?.map((x) => ({
    //             label: x?.ProjName,
    //             value: x.ProjCode,
    //         }))
    //         :
    //         [{
    //             label: 'devozy-java',
    //             value: 'PRJ1000001',
    //         }];

    let devdeployement = []
    // eslint-disable-next-line array-callback-return
    ConViewData.Deployment.map((x) => {
        if (x) {
            devdeployement.push(x)
        }
    })
    //Problems and Incidents
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //Overview Progress Bar  
    const BorderLinearProgressOpen = styled(LinearProgress)(({ theme }) => ({
        height: 35,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#4bade8',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: '#4bade8',
        },
    }));

    const BorderLinearProgressClose = styled(LinearProgress)(({ theme }) => ({
        height: 35,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#72d48e',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: '#72d48e',
        },
    }));
    function handleProject(item) {
        categoryUpdate(item?.label)
        axios
            .get(`agilesaas_devozy_project_mapping?AgileProjId=eq.${item.value}`)
            .then((res) => {
                // console.log(res.data, "cccccccccccccccccccccccccccc")
                setDevozyProjectOption(res?.data)
                let details = res.data?.[0]?.DevozyProjectDetails?.[0]
                setLable({
                    "label": details?.ProjDesc,
                    "value": details?.ProjCode
                })
            })
            .catch((e) => console.log(e))
        Dispatch(PROJECTCODEUPDATE(item?.value));
        axios.get("agilesaas_project_master?ProjectId=eq." + item?.value)
            .then((respo) => {
                setProjectMaster(respo?.data)
                Dispatch(CONVIEWDATA({ ...ConViewData, ProjectMaster: respo.data }))
                axios.get("rpc/fun_agilesaassmprojandteamdetails?managerid=" + respo?.data[0].CreatedBy + "&projectcode=eq." + item?.value)
                    .then((resp) => {
                        Dispatch(CONVIEWDATA({ ...ConViewData, TeamMember: resp.data?.length }))
                    })
                    .catch((err) => console.log(err))
            })
            .catch((err) => console.log(err))
        axios.get(`rpc/fun_getticketcounts_connectedview?projid=${item.value}`)
            .then((resd) => {
                console.log(resd.data)
                Dispatch(HANDLE_ON_CHANGE({ name: 'connectedviewdata', value: resd.data }))
            })
            .catch((e) => { console.log(e) })
        Dispatch(PROLABEL(item.label));
        Dispatch(FILTEREDKANBANDATA(KanbanBoardData?.filter((e) => e.ProjectId == item?.value)))
        axios.get("agilesaas_releases?ProjectId=eq." + item?.value)
            .then((resp) => {
                setCompleted(resp.data.filter((e) => e.IsReleaseFrozen == "Y")?.length)
                // console.log(resp.data.filter((e) => e.IsReleaseFrozen == "N"), "check release")
                setPlanned(resp.data.filter((e) => moment().diff(
                    moment(e.SprintStartDate),
                    "day"
                ) < 0)?.length)

            })
            .catch((err) => console.log(err))
        axios
            .get("rpc/fun_agilesaascurrentsprint?projid=" + item?.value)
            .then((response) => {
                // setprojectDetails
                progressPercentage(response.data)
                Dispatch(CURRENTSPRINT(response.data))
                setissueV(
                    response.data?.filter((f) => f.currentstage[0].StageName != "Backlog")
                        .length
                );
                setissueD(
                    (response.data == ""
                        ? ""
                        : response.data?.filter(
                            (f) => f.currentstage[0].StageName == "Done"
                        )
                    )?.length
                );

            })
    }
    console.log(connectedviewdata)
    let Epiclen = Fiteredkanbandata.filter((e) => e.IssueType == 'Epic')?.length
    let Storylen = Fiteredkanbandata.filter((e) => e.IssueType == 'Story')?.length
    let Tasklen = Fiteredkanbandata.filter((e) => e.IssueType == 'Task')?.length
    let Buglen = Fiteredkanbandata.filter((e) => e.IssueType == 'Bug')?.length
    let Subtasklen = Fiteredkanbandata.filter((e) => e.IssueType == 'Subtask')?.length
    let tot = Epiclen + Storylen + Tasklen + Buglen + Subtasklen
    let Epicper = Epiclen / tot * 100
    let Storyper = Storylen / tot * 100
    let Taskper = Tasklen / tot * 100
    let Bugper = Buglen / tot * 100
    let Subper = Subtasklen / tot * 100

    //Data for Donut chart
    const data2 = [
        { label: 'Tasks', value: Taskper, color: "#4bade8" },
        { label: 'Epic', value: Epicper, color: "#904ee2" },
        { label: 'Bug', value: Bugper, color: "#fe492e" },
        { label: 'Story', value: Storyper, color: "#72d48e" },
        { label: 'SubTask', value: Subper, color: "#116f97" },
    ];

    const itsmreturn = (total, opened, closed, type) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '5px', paddingTop: '5px' }}>
                <Stack direction="column" spacing={2} alignItems="flex-start" sx={{ padding: '7px', width: '33%', paddingLeft: '17px' }}>
                    <Typography variant="h3" sx={{ fontFamily: 'DM Sans', fontWeight: 'bold', lineHeight: 1, fontSize: '20px' }}>{total}</Typography>
                    <label style={{ fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 'bold', whiteSpace: 'nowrap', margin: 0, padding: 0 }}>Total {type}</label>
                </Stack>
                <Stack direction="column" spacing={2} alignItems="flex-start" sx={{ padding: '7px', width: '33%', paddingLeft: '17px' }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <CircleIcon sx={{ color: "#4bade8", alignSelf: 'center' }} style={{ fontSize: "1rem" }} />&nbsp;&nbsp;
                        <Typography variant="h3" sx={{ fontFamily: 'DM Sans', fontWeight: 'bold', lineHeight: 1, fontSize: '20px' }}>{opened}</Typography>
                    </div> */}
                    <ProgressBar
                        style={{ height: "20px", width: '100%' }}
                        variant={"info"}
                        // animated
                        now={100}
                        label={<span style={{ fontSize: "14px" }}>{opened}</span>}
                    />
                    <label style={{ fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 'bold', whiteSpace: 'nowrap', margin: 0, padding: 0 }}>Open {type}</label>
                </Stack>
                <Stack direction="column" spacing={2} alignItems="flex-start" sx={{ padding: '7px', width: '33%', paddingLeft: '17px' }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <CircleIcon sx={{ color: "#72d48e", fontSize: '1rem', alignSelf: 'center' }} style={{ fontSize: "1rem" }} />&nbsp;&nbsp;
                        <Typography variant="h3" sx={{ fontFamily: 'DM Sans', fontWeight: 'bold', lineHeight: 1, fontSize: '20px' }}>{closed}</Typography>
                    </div> */}
                    <ProgressBar
                        style={{ height: "20px", width: '100%' }}
                        variant={
                            "success"
                            //     : e.percentageprogressed === 0
                            //         ? "danger"
                            //         : "warning"
                        }
                        // animated
                        now={100}
                        label={<span style={{ fontSize: "14px" }}>{closed}</span>}
                    />
                    <label style={{ fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 'bold', whiteSpace: 'nowrap', margin: 0, padding: 0 }}>Closed {type}</label>
                </Stack>
            </div >
        )
    }

    return (
        <>
            <div style={{ backgroundColor: "#f1f1f1", padding: 10 }}>

                <div className="dashboard-project-select-header" style={{ margin: 0, display: 'flex', flexDirection: 'row' }}>
                    {/* <Typography sx={{ fontWeight: '500', marginRight: 2 }}>Project</Typography> */}
                    <Select
                        // maxMenuHeight={150}
                        className="emp select-project"
                        styles={customStyles}
                        // style={{ marginRight: -5 }}
                        options={ProjectOption}
                        placeholder={<div className="select-placeholder-text">Select Project</div>}
                        onChange={(item) => {
                            handleProject(item);
                        }}
                        value={ProjectOption.filter(
                            (option) => option?.label === ProLabel
                        )}
                    />
                </div>

                <div className="dasboard-wrapper">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} className="card-items" >
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2} className="sprint-project">

                                        {/*Card for Sprint Info*/}
                                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                            <Card >
                                                <CardContent sx={{ padding: '10px' }} className="project-summary">
                                                    <Typography sx={{ fontSize: '15px', fontFamily: "DM Sans, san-serif", fontWeight: 'bold', color: "#000" }} gutterBottom>
                                                        Sprint Info
                                                    </Typography>
                                                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                                                        <Box className="sprint-data-card" sx={{ border: '1px solid #f1f1f3', width: '100%' }}>
                                                            <p className="sprint-data-title" style={{ backgroundColor: "#dde9ff", fontWeight: "bold", marginBottom: 0, fontSize: "9px" }} >Completed</p>
                                                            <Typography variant="h3" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: '18px', lineHeight: 1 }}>{Completed}</Typography>
                                                        </Box>
                                                        <Box className="sprint-data-card" sx={{ border: '1px solid #f1f1f3', width: '100%' }}>
                                                            <p className="sprint-data-title" style={{ backgroundColor: "#dde9ff", fontWeight: "bold", marginBottom: 0, fontSize: "9px" }} >Planned</p>
                                                            <Typography variant="h3" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: '18px' }}>{Planned}</Typography>
                                                        </Box>
                                                    </Stack>
                                                    <Typography sx={{ fontSize: "15px", fontFamily: "DM Sans, san-serif", fontWeight: 'bold', paddingTop: 1, color: "#000" }} gutterBottom>
                                                        Current Sprint
                                                    </Typography>
                                                    <Box className="sprint-data-card" sx={{ border: '1px solid #f1f1f3', width: '100%' }}>
                                                        <Stack direction="row" justifyContent="space-between" spacing={2}>
                                                            <div>
                                                                <p className="current-sprint-title" style={{ fontSize: "12px" }}>Project</p>
                                                                <span style={{ fontSize: "9px" }} className="current-sprint-subtitle">Version - {CurrentSprint?.length != 0 ? CurrentSprint?.[0]?.releasename : 'No current sprint'}</span>
                                                            </div>
                                                            <div>
                                                                <p className="current-sprint-date" style={{ fontSize: "12px" }}>{CurrentSprint?.length != 0 ? moment(CurrentSprint?.[0]?.sprintenddate).format('DD MMM YYYY') : "Sprint Not Created"}</p>
                                                            </div>
                                                        </Stack>
                                                        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                                            <div>
                                                                <Typography variant="h3" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: '18px' }}>{CurrentSprint?.length != 0 ? CurrentSprint?.[0]?.sprintinweeks : 0}</Typography>
                                                                <p className="current-sprint-subtitle" style={{ fontSize: "9px" }}>Weeks</p>
                                                            </div>
                                                            <div>
                                                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                                                    <CircularProgress variant="determinate" value={percentage?.length == 0 ? 0 : percentage?.[percentage?.length - 1]} />
                                                                    <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <Typography variant="caption" component="div" sx={{ color: "#4cb5e1", fontFamily: "DM Sans, san-serif", fontSize: 9 }}>
                                                                            {percentage?.length == 0 ? 0 : percentage?.[percentage?.length - 1]?.toFixed(0)}%
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </div>
                                                        </Stack>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        {/*Card for Project Summary*/}
                                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                            <Card>
                                                <CardContent className="project-summary" sx={{ padding: '10px' }}>
                                                    <Typography sx={{ fontSize: "15px", fontFamily: "DM Sans, san-serif", fontWeight: 'bold', color: "#000" }} gutterBottom>
                                                        Project Summary
                                                    </Typography>
                                                    <Box>
                                                        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" className="chart-view">
                                                            <PieChart
                                                                series={[
                                                                    {
                                                                        data: data2,
                                                                        cx: 100,
                                                                        cy: 70,
                                                                        innerRadius: 30,
                                                                        outerRadius: 60,
                                                                    },
                                                                ]}
                                                                height={153}
                                                                // width={200}
                                                                legend={{ hidden: true }}
                                                            />

                                                            <Box className="chart-stat" >
                                                                <Stack direction="row" spacing={2} alignItems="center" sx={{ padding: '3px 0' }}>
                                                                    <CircleIcon sx={{ color: "#4bade8", fontSize: '10px' }} />
                                                                    {/* {console.log(Taskper, "Taskper")} */}
                                                                    <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: 'DM Sans', fontSize: "12px" }}>Tasks ({Math.round(Taskper)}%) </Typography>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} alignItems="center" sx={{ padding: '3px 0' }}>
                                                                    <CircleIcon sx={{ color: "#72d48e", fontSize: '10px' }} />
                                                                    <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: 'DM Sans', fontSize: "12px" }}>Story ({Math.round(Storyper)}%) </Typography>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} alignItems="center" sx={{ padding: '3px 0' }}>
                                                                    <CircleIcon sx={{ color: "#904ee2", fontSize: '10px' }} />
                                                                    <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: 'DM Sans', fontSize: "12px" }}>Epic ({Math.round(Epicper)}%) </Typography>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} alignItems="center" sx={{ padding: '3px 0' }}>
                                                                    <CircleIcon sx={{ color: "#fe492e", fontSize: '10px' }} />
                                                                    <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: 'DM Sans', fontSize: "12px" }}>Bug ({Math.round(Bugper)}%) </Typography>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} alignItems="center" sx={{ padding: '3px 0' }}>
                                                                    <CircleIcon sx={{ color: "#116f97", fontSize: '10px' }} />
                                                                    <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: 'DM Sans', fontSize: "12px" }}>SubTask ({Math.round(Subper)}%) </Typography>
                                                                </Stack>
                                                            </Box>
                                                        </Stack>
                                                    </Box>
                                                    <Divider />
                                                    <Box sx={{ padding: '15px 0 10px' }}>
                                                        <Stack direction="row" spacing={4} justifyContent="flex-start" alignItems="center">
                                                            <Typography sx={{ fontSize: "12px", fontFamily: "DM Sans, san-serif", fontWeight: 'bold' }} gutterBottom>
                                                                Priority ( Total : {Fiteredkanbandata?.filter((e) => e.Priority == 'Highest')?.length +
                                                                    Fiteredkanbandata?.filter((e) => e.Priority == 'High')?.length +
                                                                    Fiteredkanbandata?.filter((e) => e.Priority == 'Medium')?.length +
                                                                    Fiteredkanbandata?.filter((e) => e.Priority == 'Low')?.length +
                                                                    Fiteredkanbandata?.filter((e) => e.Priority == 'Lowest')?.length} )
                                                            </Typography>
                                                            <Box>
                                                                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                                        <KeyboardDoubleArrowUpIcon className="filter-icons-1" fontSize="10px" />
                                                                        <Typography variant="h8" sx={{ fontWeight: "bold", fontFamily: "DM Sans" }}>{Fiteredkanbandata?.filter((e) => e.Priority == 'Highest')?.length}</Typography>
                                                                    </div>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                                        <KeyboardArrowUpIcon className="filter-icons-1" fontSize="12px" />
                                                                        <Typography variant="h8" sx={{ fontWeight: "bold", fontFamily: "DM Sans" }}>{Fiteredkanbandata?.filter((e) => e.Priority == 'High')?.length}</Typography>
                                                                    </div>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                                        <DragHandleIcon className="filter-icons" fontSize="12px" />
                                                                        <Typography variant="h8" sx={{ fontWeight: "bold", fontFamily: "DM Sans" }}>{Fiteredkanbandata?.filter((e) => e.Priority == 'Medium')?.length}</Typography>
                                                                    </div>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                                        <KeyboardArrowDownIcon className="filter-icons-2" fontSize="12px" />
                                                                        <Typography variant="h8" sx={{ fontWeight: "bold", fontFamily: "DM Sans" }}>{Fiteredkanbandata?.filter((e) => e.Priority == 'Low')?.length}</Typography>
                                                                    </div>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                                        <KeyboardDoubleArrowDownIcon className="filter-icons-2" fontSize="12px" />
                                                                        <Typography variant="h8" sx={{ fontWeight: "bold", fontFamily: "DM Sans" }}>{Fiteredkanbandata?.filter((e) => e.Priority == 'Lowest')?.length}</Typography>
                                                                    </div>
                                                                </Stack>
                                                            </Box>
                                                        </Stack>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        {/*Card for Environment*/}
                                        <Grid item xs={12}>
                                            <Card style={{ flexDirection: 'row', position: 'relative' }} >
                                                {
                                                    devozyProjectOption?.length > 0 ?
                                                        <CardContent className="project-summary" sx={{ padding: '10px', flexDirection: 'row' }}>
                                                            <div className="headselect" style={{ flexDirection: 'row' }}>
                                                                <Typography sx={{ fontSize: "15px", fontFamily: "DM Sans", fontWeight: 'bold', color: "#000", paddingRight: '20px' }} gutterBottom>
                                                                    Environment
                                                                </Typography>
                                                                <Select
                                                                    maxMenuHeight={145}
                                                                    menuPortalTarget={document.body}
                                                                    // maxMenuHeight={150}
                                                                    className="emp select-project"
                                                                    styles={customStyles2}
                                                                    // style={{ marginRight: -5 }}
                                                                    options={DevProjectOption}
                                                                    placeholder={<div className="select-placeholder-text">Select category</div>}
                                                                    onChange={(item) => {
                                                                        handleDevProject(item);
                                                                    }}
                                                                    value={DevProjectOption.filter(
                                                                        (option) => option?.value === label?.value
                                                                    )}
                                                                />
                                                                {/* {console.log(DevProjectOption.filter(
                                                            (option) => option?.value === label?.value
                                                        ), label, "label")
                                                            // console.log(DevProjectOption.filter(
                                                            //     (option) => option?.label === (label?.label == ConViewData.Cicdselected ? ConViewData.Cicdselected : label?.label)
                                                            // ))
                                                        } */}
                                                            </div>
                                                            {
                                                                loading ?
                                                                    <div
                                                                        className=" d-flex align-items-center"
                                                                        style={{ justifyContent: "center", height: 460 }}
                                                                    >
                                                                        <span
                                                                            className="spinner-border"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                            style={{ color: "rgb(82, 80, 229)" }}
                                                                        ></span>
                                                                    </div>
                                                                    :
                                                                    !err ?
                                                                        <Stack className="fullwidth-card-mobile ">
                                                                            {ConViewData.Development.Development || ConViewData.UAT.UAT || ConViewData.Production.Production ? (<>
                                                                                {ConViewData.Development.Development && (
                                                                                    <>
                                                                                        <Box className="env-card " sx={{ border: '2px solid #f1f1f3', width: '33%' }}>
                                                                                            <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                                                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "0.8rem" }}>Development</Typography>
                                                                                                <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "0.8rem" }}>{ConViewData.Development.PublicIpAddress}</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">

                                                                                                <img src={ConViewData.Development.cloud == 'azure' ? Azure : ConViewData.Development.cloud == 'gcp' ? GCP : AWS} alt="" width="20px" height="20px" />
                                                                                                <Typography variant="caption" sx={{ fontFamily: 'DM Sans', fontSize: '0.8rem' }}> &nbsp;{ConViewData.Development.InstanceType}</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Development.vCPU}
                                                                                                        {/* <span style={{ fontWeight: 500, fontSize: "0.8rem" }}>&nbsp;gb</span> */}
                                                                                                        &nbsp;gb
                                                                                                    </Typography>
                                                                                                    <Typography variant="h6" sx={{ fontSize: "0.7rem", textAlign: 'center', fontFamily: "DM Sans" }}>CPU</Typography>
                                                                                                </div>
                                                                                                <Divider orientation="vertical" flexItem />
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Development.vRAM}
                                                                                                        {/* <span style={{ fontWeight: 500, fontSize: "0.8rem" }}>&nbsp;gb</span> */}
                                                                                                        &nbsp;gb
                                                                                                    </Typography>
                                                                                                    <Typography variant="h6" sx={{ fontSize: "0.7rem", textAlign: 'center', fontFamily: "DM Sans" }}>RAM</Typography>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                        {/* {!ConViewData.UAT.UAT && (<Box className="env-card" sx={{ width: '100%' }}></Box>)}
                                                                    {!ConViewData.Production.Production && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                    </>
                                                                                )}

                                                                                {ConViewData.UAT.UAT && (
                                                                                    <>
                                                                                        {/* {!ConViewData.Development.Development && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                        <Box className="env-card" sx={{ border: '2px solid #f1f1f3', width: '33%' }}>
                                                                                            <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                                                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "0.8rem" }}>UAT</Typography>
                                                                                                <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "0.8rem" }}>{ConViewData.UAT.PublicIpAddress}</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                                                                                <img src={ConViewData.UAT.cloud == 'azure' ? Azure : ConViewData.UAT.cloud == 'gcp' ? GCP : AWS} alt="" width="20px" height="20px" />
                                                                                                <Typography variant="caption" sx={{ fontFamily: 'DM Sans', fontSize: '0.8rem' }}> &nbsp;{ConViewData.UAT.InstanceType}</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.UAT.vCPU}
                                                                                                        {/* <span style={{ fontWeight: 500, fontSize: '0.6rem' }}> gb</span> */}
                                                                                                        &nbsp;gb
                                                                                                    </Typography>
                                                                                                    <Typography variant="h6" sx={{ fontSize: "0.7rem", textAlign: 'center', fontFamily: "DM Sans" }}>CPU</Typography>
                                                                                                </div>
                                                                                                <Divider orientation="vertical" flexItem />
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.UAT.vRAM}
                                                                                                        {/* <span style={{ fontWeight: 500, fontSize: '0.7rem' }}> gb</span> */}
                                                                                                        &nbsp;gb
                                                                                                    </Typography>
                                                                                                    <Typography variant="h6" style={{ fontSize: "0.6rem", textAlign: 'center', fontFamily: "DM Sans" }}>RAM</Typography>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                        {!ConViewData.Production.Production && (<Box className="env-card" sx={{ width: '33%' }}></Box>)}
                                                                                    </>
                                                                                )}

                                                                                {ConViewData.Production.Production && (
                                                                                    <>
                                                                                        {/* {!ConViewData.Development.Development && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                        {/* {!ConViewData.UAT.UAT && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                        <Box className="env-card" sx={{ border: '2px solid #f1f1f3', width: '33%' }}>
                                                                                            <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                                                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "0.8rem" }}>Production</Typography>
                                                                                                <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "0.8rem" }}>{ConViewData.Production.PublicIpAddress}</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                                                                                <img src={ConViewData.Production.cloud == 'azure' ? Azure : ConViewData.Production.cloud == 'gcp' ? GCP : AWS} alt="" width="20px" height="20px" />
                                                                                                <Typography variant="caption" sx={{ fontFamily: 'DM Sans', fontSize: "0.8rem" }}> &nbsp;{ConViewData.Production.InstanceType}</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Production.vCPU}
                                                                                                        {/* <span style={{ fontWeight: 500, fontSize: '0.6rem' }}> gb</span> */}
                                                                                                        &nbsp;gb
                                                                                                    </Typography>
                                                                                                    <Typography variant="h6" sx={{ fontSize: "0.7rem", textAlign: 'center', fontFamily: "DM Sans" }}>CPU</Typography>
                                                                                                </div>
                                                                                                <Divider orientation="vertical" flexItem />
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Production.vRAM}
                                                                                                        {/* <span style={{ fontWeight: 500, fontSize: "0.6rem" }}> gb</span> */}
                                                                                                        &nbsp;gb
                                                                                                    </Typography>
                                                                                                    <Typography variant="h6" style={{ fontSize: "0.7rem", textAlign: 'center', fontFamily: "DM Sans" }}>RAM</Typography>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                            ) : (
                                                                                <>
                                                                                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                                                                                        <InfoIcon />
                                                                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "18px" }}>No data was found.</Typography>
                                                                                        {/* <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "14px", }}>Lorem ipsum dolor sit amet. consctetur adipiscnulla</Typography>
                                                                    <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "14px", }}>ame, dolor tellus enim.</Typography> */}
                                                                                    </Box>
                                                                                </>
                                                                            )}
                                                                        </Stack>
                                                                        :
                                                                        <div
                                                                            className=" d-flex align-items-center"
                                                                            style={{ justifyContent: "center", height: 460 }}
                                                                        >
                                                                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                                                                <InfoIcon />
                                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "18px" }}>Not able to get data</Typography>
                                                                                {/* <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "14px", }}>Lorem ipsum dolor sit amet. consctetur adipiscnulla</Typography>
                                                                <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "14px", }}>ame, dolor tellus enim.</Typography> */}
                                                                            </Box>
                                                                        </div>
                                                            }

                                                        </CardContent>
                                                        :
                                                        <CardContent className="project-summary d-flex justify-content-center align-items-center" sx={{ padding: '10px', flexDirection: 'row', height: 500 }}>
                                                            <label style={{ fontSize: 20 }}>No projects available</label>
                                                        </CardContent>
                                                }
                                            </Card>
                                        </Grid>
                                        {
                                            loading ? "" :
                                                !err ?
                                                    <>
                                                        {/*Card for CI CD*/}
                                                        <Grid item xs={12}>
                                                            <Card>
                                                                <CardContent className="project-summary" sx={{ padding: '10px' }}>
                                                                    <Typography sx={{ fontSize: "15px", fontFamily: "DM Sans", fontWeight: 'bold', color: "#000" }} gutterBottom>
                                                                        CI CD
                                                                    </Typography>
                                                                    {
                                                                        ConViewData?.Development?.Development ?
                                                                            <Stack className="fullwidth-card-mobile">
                                                                                {ConViewData.Development.Development && (
                                                                                    <>
                                                                                        <Box className="env-card" sx={{ border: '2px solid #f1f1f3', width: '33%' }}>
                                                                                            <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                                                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "0.8rem" }}>Development</Typography>
                                                                                                <Typography variant="subtitle2" sx={{ fontFamily: 'DM Sans', fontSize: "0.8rem" }}>{moment(ConViewData.Development.CreatedDate).format("DD MMM YYYY")}</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                                                                                <img src={Pipeline} alt="" width="20px" height="20px" />
                                                                                                <Typography variant="caption" sx={{ fontFamily: 'DM Sans', fontSize: '0.8rem' }}>Pipeline</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Development.TotalRan}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', }}>Total Ran</Typography>
                                                                                                </div>
                                                                                                <Divider orientation="vertical" flexItem />
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Development.Status}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', fontFamily: 'DM Sans' }}>Last Ran</Typography>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                        {/* {!ConViewData.UAT.UAT && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                        {/* {!ConViewData.Production.Production && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                    </>
                                                                                )}

                                                                                {ConViewData.UAT.UAT && (
                                                                                    <>
                                                                                        {/* {!ConViewData.Development.Development && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                        <Box className="env-card" sx={{ border: '2px solid #f1f1f3', width: '33%' }}>
                                                                                            <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                                                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "0.8rem" }}>UAT</Typography>
                                                                                                <Typography variant="subtitle2" sx={{ fontFamily: 'DM Sans', fontSize: "0.8rem" }}>{moment(ConViewData.UAT.CreatedDate).format("DD MMM YYYY")}</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                                                                                <img src={Pipeline} alt="" width="20px" height="20px" />
                                                                                                <Typography variant="caption" sx={{ fontFamily: 'DM Sans', fontSize: '0.8rem' }}>Pipeline</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.UAT.TotalRan}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', }}>Total Ran</Typography>
                                                                                                </div>
                                                                                                <Divider orientation="vertical" flexItem />
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.UAT.Status}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', fontFamily: 'DM Sans' }}>Last Ran</Typography>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                        {!ConViewData.Production.Production && (<Box className="env-card" sx={{ width: '33%' }}></Box>)}
                                                                                    </>
                                                                                )}

                                                                                {ConViewData.Production.Production && (
                                                                                    <>
                                                                                        {/* {!ConViewData.Development.Development && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                        {/* {!ConViewData.UAT.UAT && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                        <Box className="env-card" sx={{ border: '2px solid #f1f1f3', width: '33%' }}>
                                                                                            <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                                                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "0.8rem" }}>Production</Typography>
                                                                                                <Typography variant="subtitle2" sx={{ fontFamily: 'DM Sans', fontSize: "0.8rem" }}>{moment(ConViewData.Production.CreatedDate).format("DD MMM YYYY")}</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                                                                                <img src={Pipeline} alt="" width="20px" height="20px" />
                                                                                                <Typography variant="caption" sx={{ fontFamily: 'DM Sans', fontSize: '0.8rem' }}>Pipeline</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Production.TotalRan}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', }}>Total Ran</Typography>
                                                                                                </div>
                                                                                                <Divider orientation="vertical" flexItem />
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Production.Status}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', fontFamily: 'DM Sans' }}>Last Ran</Typography>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                    </>
                                                                                )}
                                                                            </Stack>
                                                                            : (
                                                                                <>
                                                                                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                                                                                        <InfoIcon />
                                                                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "18px" }}>No data was found.</Typography>
                                                                                        {/* <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "14px", }}>Lorem ipsum dolor sit amet. consctetur adipiscnulla</Typography>
                                                                <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "14px", }}>ame, dolor tellus enim.</Typography> */}
                                                                                    </Box>
                                                                                </>
                                                                            )
                                                                    }

                                                                </CardContent>
                                                            </Card>
                                                        </Grid>

                                                        {/*Card for Code Repository*/}
                                                        <Grid item xs={12}>
                                                            <Card>
                                                                <CardContent className="project-summary" sx={{ padding: '10px' }}>
                                                                    <Typography sx={{ fontSize: '15px', fontFamily: "DM Sans", fontWeight: 'bold', color: "#000" }} gutterBottom>
                                                                        Code Repository
                                                                    </Typography>
                                                                    {
                                                                        ConViewData?.Development?.Development ?
                                                                            <Stack className="fullwidth-card-mobile">
                                                                                {ConViewData.Development.Development && (
                                                                                    <>
                                                                                        <Box className="env-card" sx={{ border: '2px solid #f1f1f3', width: '33%' }}>
                                                                                            <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                                                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "0.8rem" }}>Development</Typography>
                                                                                                <Box>
                                                                                                    <AvatarGroup max={4}>
                                                                                                        <Avatar alt="Remy Sharp" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                        <Avatar alt="Travis Howard" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                        <Avatar alt="Cindy Baker" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                        <Avatar alt="Agnes Walker" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                    </AvatarGroup>
                                                                                                </Box>
                                                                                            </Stack>
                                                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                                                                                <img src={GIT} alt="" width="20px" height="20px" />
                                                                                                <Typography variant="caption" sx={{ fontFamily: 'DM Sans', fontSize: '0.8rem' }}>Agile sas</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                                                                                <div>
                                                                                                    {/* {console.log(ConViewData.Development, "ConViewData.Development")} */}
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Development.commit}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', }}>Tot. Commit</Typography>
                                                                                                </div>
                                                                                                <Divider orientation="vertical" flexItem />
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Development.branch}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', }}>Tot. Branch</Typography>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                        {/* {!ConViewData.UAT.UAT && (<Box className="env-card" sx={{ width: '100%' }}></Box>)}
                                                                        {!ConViewData.Production.Production && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                    </>
                                                                                )}

                                                                                {ConViewData.UAT.UAT && (
                                                                                    <>
                                                                                        {/* {!ConViewData.Development.Development && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                        <Box className="env-card" sx={{ border: '2px solid #f1f1f3', width: '33%' }}>
                                                                                            <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                                                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "0.8rem" }}>UAT</Typography>
                                                                                                <Box>
                                                                                                    <AvatarGroup max={4}>
                                                                                                        <Avatar alt="Remy Sharp" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                        <Avatar alt="Travis Howard" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                        <Avatar alt="Cindy Baker" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                        <Avatar alt="Agnes Walker" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                    </AvatarGroup>
                                                                                                </Box>
                                                                                            </Stack>
                                                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                                                                                <img src={GIT} alt="" width="20px" height="20px" />
                                                                                                <Typography variant="caption" sx={{ fontFamily: 'DM Sans', fontSize: '0.8rem' }}>Agile sas</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.UAT.commit}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', }}>Tot. Commit</Typography>
                                                                                                </div>
                                                                                                <Divider orientation="vertical" flexItem />
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.UAT.branch}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', }}>Tot. Branch</Typography>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                        {!ConViewData.Production.Production && (<Box className="env-card" sx={{ width: '33%' }}></Box>)}
                                                                                    </>
                                                                                )}
                                                                                {ConViewData.Production.Production && (
                                                                                    <>
                                                                                        {/* {!ConViewData.Development.Development && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}
                                                                                        {/* {!ConViewData.UAT.UAT && (<Box className="env-card" sx={{ width: '100%' }}></Box>)} */}

                                                                                        <Box className="env-card" sx={{ border: '2px solid #f1f1f3', width: '33%' }}>
                                                                                            <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                                                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "0.8rem" }}>Production</Typography>
                                                                                                <Box>
                                                                                                    <AvatarGroup max={4}>
                                                                                                        <Avatar alt="Remy Sharp" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                        <Avatar alt="Travis Howard" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                        <Avatar alt="Cindy Baker" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                        <Avatar alt="Agnes Walker" src={AvatarImg} sx={{ width: 20, height: 20 }} />
                                                                                                    </AvatarGroup>
                                                                                                </Box>
                                                                                            </Stack>
                                                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                                                                                <img src={GIT} alt="" width="20px" height="20px" />
                                                                                                <Typography variant="caption" sx={{ fontFamily: 'DM Sans', fontSize: '0.8rem' }}>Agile sas</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                                                                                {/* {console.log(ConViewData.Production, "ConViewData.Production")} */}
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Production.commit}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', }}>Tot. Commit</Typography>
                                                                                                </div>
                                                                                                <Divider orientation="vertical" flexItem />
                                                                                                <div>
                                                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', paddingTop: '5px', fontFamily: "DM Sans, san-serif", fontSize: "0.8rem" }}>{ConViewData.Production.branch}</Typography>
                                                                                                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans', fontSize: "0.7rem", textAlign: 'center', }}>Tot. Branch</Typography>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                    </>
                                                                                )}
                                                                            </Stack>
                                                                            : (
                                                                                <>
                                                                                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                                                                                        <InfoIcon />
                                                                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "18px" }}>No data was found.</Typography>
                                                                                        {/* <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "14px", }}>Lorem ipsum dolor sit amet. consctetur adipiscnulla</Typography>
    <Typography variant="body1" sx={{ fontFamily: 'DM Sans', fontSize: "14px", }}>ame, dolor tellus enim.</Typography> */}
                                                                                    </Box>
                                                                                </>
                                                                            )
                                                                    }
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </>
                                                    :
                                                    ""
                                        }

                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2} direction="column" >

                                        {/*Card for Scrum Team*/}
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <Card>
                                                <CardContent className="project-summary" sx={{ padding: '10px' }}>
                                                    <Typography sx={{ fontSize: '15px', fontFamily: "DM Sans, san-serif", fontWeight: 'bold', color: "#000" }} gutterBottom>
                                                        Scrum Team
                                                    </Typography>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                            <div className="scrum-info">
                                                                {/* <Avatar src={AvatarImg} sx={{ width: 30, height: 30 }} /> */}
                                                                <Avatar
                                                                    alt=""
                                                                    sx={{ width: 30, height: 30 }}
                                                                    src={allImage?.length > 0 && allImage?.find((x) => x.teammemberid == projectMaster[0]?.CreatedBy)?.image != undefined ? allImage?.find((x) => x.teammemberid == projectMaster[0]?.CreatedBy)?.image : Unassigned}
                                                                />
                                                                <div>
                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '16px', fontFamily: 'DM Sans', textAlign: 'left' }} >{projectMaster?.length !== 0 ? projectMaster[0]?.CreatedByDetails?.Name : "Un allocated"}</Typography>
                                                                    <Typography variant="caption" style={{ color: "#747474", fontFamily: 'DM Sans', fontSize: "12px", textAlign: 'left' }} >Scrum Master</Typography>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: '20px', color: "#39afd1", fontFamily: 'DM Sans' }} >{ConViewData.TeamMember ? ConViewData.TeamMember : teamMember ? teamMember : 'Unallocated'}</Typography>
                                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "16px", fontFamily: 'DM Sans' }} >Team Members</Typography>
                                                            </div>
                                                        </Stack>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        {/*Card for Stories and Tasks*/}
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <Card>
                                                <CardContent className="project-summary" sx={{ padding: '10px' }}>
                                                    <Typography sx={{ fontSize: '15px', fontFamily: "DM Sans, san-serif", fontWeight: 'bold', color: "#000" }} gutterBottom>
                                                        Stories / Tasks ( Total : {connectedviewdata?.BACKLOG +
                                                            connectedviewdata?.REFINED +
                                                            connectedviewdata?.DEVELOPMENT +
                                                            connectedviewdata?.UAT +
                                                            connectedviewdata?.DONE} )
                                                    </Typography>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Link to={"/user/kanbanboard"} className="linkstyle">
                                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ padding: '10px 0' }}>
                                                                <div className="task-stories">
                                                                    <StorageOutlinedIcon className="stories-icons" sx={{ fontSize: 'small' }} />
                                                                    <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "20px" }}>{connectedviewdata?.BACKLOG}</Typography>
                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "20px" }}>Backlogs</Typography>
                                                                </div>
                                                                <ArrowForwardIosRoundedIcon className="stories-icons" sx={{ fontSize: 'small' }} />
                                                            </Stack>
                                                        </Link>
                                                        <Divider />
                                                        <Link to={"/user/kanbanboard"} className="linkstyle">
                                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ padding: '10px 0' }}>
                                                                <div className="task-stories">
                                                                    <FilterAltIcon className="stories-icons" sx={{ fontSize: 'small' }} />
                                                                    <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "20px" }}>{connectedviewdata?.REFINED}</Typography>
                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "20px" }}>Refined</Typography>
                                                                </div>
                                                                <ArrowForwardIosRoundedIcon className="stories-icons" sx={{ fontSize: 'small' }} />
                                                            </Stack>
                                                        </Link>
                                                        <Divider />
                                                        <Link to={"/user/kanbanboard"} className="linkstyle">
                                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ padding: '10px 0' }}>
                                                                <div className="task-stories">
                                                                    <TabIcon className="stories-icons" sx={{ fontSize: 'small' }} />
                                                                    <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "20px" }}>{connectedviewdata?.DEVELOPMENT}</Typography>
                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "20px" }}>Development</Typography>
                                                                </div>
                                                                <ArrowForwardIosRoundedIcon className="stories-icons" sx={{ fontSize: 'small' }} />
                                                            </Stack>
                                                        </Link>
                                                        <Divider />
                                                        <Link to={"/user/kanbanboard"} className="linkstyle">
                                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ padding: '10px 0' }}>
                                                                <div className="task-stories">
                                                                    <HistoryToggleOffIcon className="stories-icons" sx={{ fontSize: 'small' }} />
                                                                    <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "20px" }}>{connectedviewdata?.UAT}</Typography>
                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "20px" }}>UAT</Typography>
                                                                </div>
                                                                <ArrowForwardIosRoundedIcon className="stories-icons" sx={{ fontSize: 'small' }} />
                                                            </Stack>
                                                        </Link>
                                                        <Divider />
                                                        <Link to={"/user/kanbanboard"} className="linkstyle">
                                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ padding: '10px 0' }}>
                                                                <div className="task-stories">
                                                                    <DoneAllIcon className="stories-icons" sx={{ fontSize: 'small' }} />
                                                                    <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "20px" }}>{connectedviewdata?.DONE}</Typography>
                                                                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: "20px" }}>Done</Typography>
                                                                </div>
                                                                <ArrowForwardIosRoundedIcon className="stories-icons" sx={{ fontSize: 'small' }} />
                                                            </Stack>
                                                        </Link>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        {/*Card for Problems and Incidents*/}
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ width: '100%' }}>
                                            <Card>
                                                <CardContent className="project-summary problems-card" sx={{ padding: '10px' }}>
                                                    <Typography sx={{
                                                        fontSize: '15px',
                                                        fontFamily: "DM Sans, san-serif",
                                                        fontWeight: 'bold',
                                                        color: "#000", paddingLeft: '16px'
                                                    }} gutterBottom>
                                                        Problems / Incidents
                                                    </Typography>
                                                    <Box sx={{ width: '100%' }}>
                                                        <TabContext value={value}>
                                                            <Box sx={{ maxWidth: { xs: 450, sm: 450 }, overflow: 'scroll' }}>
                                                                <TabList onChange={handleChange} variant="scrollable" scrollButtons={false} className="problem-title-tab">
                                                                    <Tab label="Overview" value="1" />
                                                                    <Tab label="Top Incidents" value="3" />
                                                                    <Tab label="Top Changes" value="4" />
                                                                    <Tab label="Top Problems" value="2" />
                                                                    <Tab label="Deployments" value="5" />
                                                                </TabList>
                                                            </Box>

                                                            {/*Overview Tab*/}
                                                            <TabPanel value="1" className="tab-panel">
                                                                {itsmreturn(incidents?.length,
                                                                    incidents?.filter((x) => x.CurrentState?.StateCode != "Resolved")?.length,
                                                                    incidents?.filter((x) => x.CurrentState?.StateCode == "Resolved")?.length,
                                                                    "incidents")}
                                                                <Divider />
                                                                {itsmreturn(changes?.length,
                                                                    changes?.filter((x) => x.ChangeStages?.StateCode != "Resolved")?.length,
                                                                    changes?.filter((x) => x.ChangeStages?.StateCode === "Resolved")?.length,
                                                                    "changes")}
                                                                <Divider />
                                                                {itsmreturn(problems?.length,
                                                                    problems?.filter((x) => x.ChangeStages?.StateCode != "Resolved")?.length,
                                                                    problems?.filter((x) => x.ChangeStages?.StateCode == "Resolved")?.length,
                                                                    "problems")}
                                                            </TabPanel>

                                                            {/*Top problems Tab*/}
                                                            <TabPanel value="2" className="tab-panel">
                                                                {
                                                                    problems.length > 0 && problems?.filter((x) => x.CurrentState?.StateCode != "Resolved") != 0 ?
                                                                        problems?.filter((x) => x.CurrentState?.StateCode != "Resolved")?.slice(0, 3)?.map((e) => (
                                                                            <Box className="sprint-data-card " sx={{ border: '2px solid #f1f1f3', margin: '10px 0 0' }}>
                                                                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                    <div className="scrum-info">
                                                                                        <Avatar sx={{ width: 30, height: 30 }}
                                                                                            src={allImage?.length > 0
                                                                                                && allImage?.find((x) => x.teammemberid == e?.CreatedBy)?.image != undefined ? allImage?.find((x) => x.teammemberid == e?.CreatedBy)?.image : Unassigned} />
                                                                                        <div>
                                                                                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'DM Sans' }} >{e?.ShortDescription}</Typography>
                                                                                            <span style={{ fontSize: 10, color: "#747474", fontFamily: 'DM Sans' }} >{e?.CreatedBy?.FN + " " + e?.CreatedBy?.LN}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span style={{ fontSize: '8px', color: "#747474", fontFamily: 'DM Sans', whiteSpace: 'nowrap' }} >{moment(e.CreatedDateTime).format("DD-MM-YYYY")}</span>
                                                                                    </div>
                                                                                </Stack>
                                                                            </Box>
                                                                        ))
                                                                        :
                                                                        <label className=" Notfound">
                                                                            No open problems
                                                                        </label>
                                                                }

                                                            </TabPanel>

                                                            {/*Top incidents Tab*/}
                                                            <TabPanel value="3">
                                                                {
                                                                    incidents?.length > 0 && incidents?.filter((x) => x.CurrentState?.StateCode != "Resolved") != 0 ?
                                                                        incidents?.filter((x) => x.CurrentState?.StateCode != "Resolved")?.slice(0, 3)?.map((e) => (
                                                                            <Box className="sprint-data-card " sx={{ border: '2px solid #f1f1f3', margin: '10px 0 0' }}>
                                                                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                    <div className="scrum-info">
                                                                                        <Avatar sx={{ width: 30, height: 30 }}
                                                                                            src={allImage?.length > 0 && allImage?.find((x) => x.teammemberid == e?.CreatedBy)?.image != undefined ? allImage?.find((x) => x.teammemberid == e?.CreatedBy)?.image : Unassigned} />
                                                                                        <div>
                                                                                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'DM Sans' }} >{e?.IncidentTitle}</Typography>
                                                                                            <span style={{ fontSize: 10, color: "#747474", fontFamily: 'DM Sans' }} >{e?.CreatedByDetails?.FN + " " + e?.CreatedByDetails?.LN}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span style={{ fontSize: '8px', color: "#747474", fontFamily: 'DM Sans', whiteSpace: 'nowrap' }} >{moment(e.CreatedDate).format("DD-MM-YYYY")}</span>
                                                                                    </div>
                                                                                </Stack>
                                                                            </Box>
                                                                        ))
                                                                        :
                                                                        <label className=" Notfound">
                                                                            No open incidents
                                                                        </label>
                                                                }
                                                            </TabPanel>

                                                            <TabPanel value="4">
                                                                {
                                                                    changes.length > 0 && changes?.filter((x) => x.ChangeStages?.StateCode != "Resolved") != 0 ?
                                                                        changes?.filter((x) => x.ChangeStages?.StateCode != "Resolved")?.slice(0, 3)?.map((e) => (
                                                                            <Box className="sprint-data-card " sx={{ border: '2px solid #f1f1f3', margin: '10px 0 0' }}>
                                                                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                    <div className="scrum-info">
                                                                                        <Avatar sx={{ width: 30, height: 30 }}
                                                                                            src={allImage?.length > 0 && allImage?.find((x) => x.teammemberid == e?.RequestedBy?.id)?.image != undefined ? allImage?.find((x) => x.teammemberid == e?.RequestedBy?.id)?.image : Unassigned} />
                                                                                        <div>
                                                                                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'DM Sans' }} >{e?.ShortDescription}</Typography>
                                                                                            <span style={{ fontSize: 10, color: "#747474", fontFamily: 'DM Sans' }} >{e?.RequestedBy?.FN + " " + e?.RequestedBy?.LN}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span style={{ fontSize: '8px', color: "#747474", fontFamily: 'DM Sans', whiteSpace: 'nowrap' }} >{moment(e.CreatedDateTime).format("DD-MM-YYYY")}</span>
                                                                                    </div>
                                                                                </Stack>
                                                                            </Box>
                                                                        ))
                                                                        :
                                                                        <label className=" Notfound">
                                                                            No open changes
                                                                        </label>
                                                                }
                                                            </TabPanel>

                                                            {/*Deployments Tab*/}
                                                            <TabPanel value="5" className="tab-panel">
                                                                <Stack direction="row" spacing={2} alignItems="flex-end" sx={{ padding: '8px' }}>
                                                                    <Typography variant="h3" sx={{ fontFamily: 'DM Sans', fontWeight: 'bold', lineHeight: 1, fontSize: '18px' }}>{devdeployement?.length}</Typography>
                                                                    <Typography variant="h6" sx={{ fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Deployments</Typography>
                                                                </Stack>
                                                                {devdeployement?.length > 0 ?
                                                                    devdeployement.map((x) => {
                                                                        return (
                                                                            <Box className="sprint-data-card " sx={{ border: '2px solid #f1f1f3', margin: '10px 0 0' }}>
                                                                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                    <div className="scrum-info">
                                                                                        <Avatar src={AvatarImg} sx={{ width: 30, height: 30 }} />
                                                                                        <div>
                                                                                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px", fontFamily: 'DM Sans' }} >{x?.DeploymentCode}</Typography>
                                                                                            <Typography variant="body2" style={{ fontSize: "8px", color: "#747474", fontFamily: 'DM Sans' }} >{x?.TechStack[0]} | {x?.ProjName}</Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'DM Sans' }} >Version-{x?.DeploymentVersion}</Typography>
                                                                                        <Typography variant="body2" sx={{ fontSize: '8px', color: "#747474", fontFamily: 'DM Sans' }} >{moment(x?.CreatedAt).format("DD MMM YYYY - h:mm a ")}</Typography>
                                                                                    </div>
                                                                                </Stack>
                                                                            </Box>
                                                                        )
                                                                    })
                                                                    : <label className=" Notfound">
                                                                        No Data found
                                                                    </label>
                                                                }
                                                                {/* <Box className="sprint-data-card " sx={{ border: '1px solid #f1f1f3', margin: '10px 0 0' }}>
                                                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                        <div className="scrum-info">
                                                                            <Avatar src={AvatarImg} sx={{ width: 30, height: 30 }} />
                                                                            <div>
                                                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px", fontFamily: 'DM Sans' }} >PRJ10000001</Typography>
                                                                                <Typography variant="body2" style={{ fontSize: "8px", color: "#747474", fontFamily: 'DM Sans' }} >MERN | Agile-SAAS-V2</Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'DM Sans' }} >Version-2.0.8</Typography>
                                                                            <Typography variant="body2" sx={{ fontSize: '8px', color: "#747474", fontFamily: 'DM Sans' }} >04 April,2021 6.00 PM</Typography>
                                                                        </div>
                                                                    </Stack>
                                                                </Box>
                                                                <Box className="sprint-data-card" sx={{ border: '1px solid #f1f1f3', margin: '10px 0 0' }}>
                                                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                        <div className="scrum-info">
                                                                            <Avatar src={AvatarImg} sx={{ width: 30, height: 30 }} />
                                                                            <div>
                                                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px", fontFamily: 'DM Sans' }} >PRJ10000001</Typography>
                                                                                <Typography variant="body2" style={{ fontSize: "8px", color: "#747474", fontFamily: 'DM Sans' }} >MERN | Agile-SAAS-V2</Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'DM Sans' }} >Version-2.0.8</Typography>
                                                                            <Typography variant="body2" sx={{ fontSize: '8px', color: "#747474", fontFamily: 'DM Sans' }} >04 April,2021 6.00 PM</Typography>
                                                                        </div>
                                                                    </Stack>
                                                                </Box>
                                                                <Box className="sprint-data-card" sx={{ border: '1px solid #f1f1f3', margin: '10px 0 0' }}>
                                                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                        <div className="scrum-info">
                                                                            <Avatar src={AvatarImg} sx={{ width: 30, height: 30 }} />
                                                                            <div>
                                                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: "12px", fontFamily: 'DM Sans' }} >PRJ10000001</Typography>
                                                                                <Typography variant="body2" style={{ fontSize: "8px", color: "#747474", fontFamily: 'DM Sans' }} >MERN | Agile-SAAS-V2</Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'DM Sans' }} >Version-2.0.8</Typography>
                                                                            <Typography variant="body2" sx={{ fontSize: '8px', color: "#747474", fontFamily: 'DM Sans' }} >04 April,2021 6.00 PM</Typography>
                                                                        </div>
                                                                    </Stack>
                                                                </Box> */}
                                                            </TabPanel>

                                                        </TabContext>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                    </Grid >
                                </Box>
                            </Grid>
                        </Grid>
                    </Box >
                </div >
            </div >
        </>
    )
}

export default Dashboard