import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography, Tooltip
} from '@mui/material';
import {
    DeleteOutline as DeleteOutlineIcon, KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon, DragHandle as DragHandleIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
    CheckBoxRounded as CheckBoxRoundedIcon, OfflineBoltRounded as OfflineBoltRoundedIcon,
    AddToPhotos as AddToPhotosIcon, StopCircle as StopCircleIcon, Bookmark,
} from '@mui/icons-material';
import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@material-ui/core';
import Unassigned from "../../../assets/images/avatar.png";
import CloseIcon from "@material-ui/icons/Close";
import { Row, Col } from "reactstrap";
import { Modal, Button } from "react-bootstrap";
import { Switch as Switch1, Button as Button1, Dropdown, Space } from "antd";
import styled from "styled-components";
import { HANDLE_ON_CHANGE } from '../../../redux/CommonInitialsate';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, List } from 'antd';
import StoryPointClick from './StoryPointClick';
import {
    CalendarOutlined,
    ScheduleOutlined,
    FieldTimeOutlined,
    ClockCircleOutlined,
    DownOutlined
} from "@ant-design/icons";
import Cookies from "universal-cookie";
import Axios from "axios";
import axios from '../../../axios'
import moment from 'moment';
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import Mailandnotification from '../../../Mailandnotification';
import slacknotify from '../../../slacknotify';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSwitch = styled(Switch1)`
    &.ant-switch {
        background-color: #808080; /* Default background color */
    }

    &.ant-switch-checked {
        background-color: #1890ff; /* Checked state background color */
    }
`;


function Refinement() {
    const {
        projectRole, projectRoleName, allImage, KanbanBoardData, projectDetails,
        projectMappingData, openProCreate, ConViewData, ProLabel, isSuccess, loading,
        searchText, Assetoptions, SelectedCardId, FilteredData, Refresh, IssueId,
        client, client1, uatDropdown, progressdata, displaySubTask, resData, priority,
        releasefilter, epicLabel, releases1, releases, openCreate, projectLabel, employeeList,
        testerEpic, testename, releasePopup, projectRelease, commitDialog, EmpDropDownData,
        testerid, Historysearch, ceremoniesPopup, meeting, projectTitle, historyrelease,
        kbrefreshstate, releasedata, projectName, refinementpopup, projectCode,channelname,slacktoken
    } = useSelector((state) => state.user);

    console.log(employeeList)
    let empid = JSON.parse(localStorage.getItem("Empdetails"));
    let slack = localStorage.getItem("Slack")
    const dispatch = useDispatch();
    const { Panel } = Collapse;
    const data = KanbanBoardData?.filter((x) => (x.ProjectId === projectCode && x.IssueType != 'Epic' && x.IssueType != 'Blocked' && (x.CurrentStage?.[0]?.StageCode == "BLG" || x.CurrentStage?.[0]?.StageCode == "RFD")))

    const [data1, setData1] = useState(data?.filter((x) => (x.AssignedTo != null && x.OriginalEstimate != null && x.PlannedRelease == null)).map((x) => ({ ...x, PlannedRelease: "New sprint" })));
    const [data2, setData2] = useState(data?.filter((x) => (x.AssignedTo == null || x.OriginalEstimate == null) && x.PlannedRelease == null).map((x) => ({ ...x, PlannedRelease: "New sprint" })));
    const [data3, setData3] = useState(data?.filter((x) => (x.AssignedTo == null || x.OriginalEstimate == null) && x.PlannedRelease != null));
    const [data4, setData4] = useState(data?.filter((x) => (x.AssignedTo != null && x.OriginalEstimate != null && x.PlannedRelease != null)));
    const [checkSubmit, setSubmitCheck] = useState(false)
    const [planned, setPlanned] = useState(false)
    const [planasprint, setPlanasprint] = useState({ "key1": false, "key2": false })
    const [savepopup, setSavepopup] = useState(false)

    // const [message, setMessage] = useState("")
    // const [open, setOpen] = useState(false)
    // const [messagetype, setMessageType] = useState("")

    useEffect(() => {
        setData1(data?.filter((x) => (x.AssignedTo != null && x.OriginalEstimate != null && x.PlannedRelease == null)).map((x) => ({ ...x, PlannedRelease: "New sprint" })));
        setData2(data?.filter((x) => (x.AssignedTo == null || x.OriginalEstimate == null) && x.PlannedRelease == null).map((x) => ({ ...x, PlannedRelease: "New sprint" })));
        setData3(data?.filter((x) => (x.AssignedTo == null || x.OriginalEstimate == null) && x.PlannedRelease != null));
        setData4(data?.filter((x) => (x.AssignedTo != null && x.OriginalEstimate != null && x.PlannedRelease != null)));
    }, [KanbanBoardData])

    // const handleclosesnackbar = () => {
    //     setOpen(false)
    // }
    const userdropdownoptions = employeeList.map((x) => {
        return {
            value: x.teammemberid,
            label: x.firstname + " " + x.lastname,
            firstname: x.firstname,
            lastname: x.lastname,
            icon: (
                <Avatar
                    // className="progressName"
                    src={
                        allImage?.length > 0
                            ? allImage?.filter((e) => e.teammemberid == x.teammemberid)?.[0]
                                ?.image
                            : Unassigned
                    }
                />
            ),
        };
    });

    const handleSelectChange = (selectedOption, issueId, assignedto, key) => {
        console.log(key)
        if (assignedto != selectedOption?.value) {
            const fdata = key == 1 ? data1 : key == 2 ? data2 : key == 3 ? data3 : data4
            const updatedData = fdata.map((item) =>
                item.IssueId === issueId ? {
                    ...item, AssignedTo: selectedOption?.value, AssignedToDetails: { FN: selectedOption?.firstname, LN: selectedOption?.lastname }, changed: true,
                    changedfield: item.changedfield && item.changedfield.length > 0
                        ? [...item.changedfield, 'assignee']
                        : ['assignee']
                    // item?.changedfield == undefined ? ['assignee'] : item?.changedfield?.push('assignee') 
                } : item
            );
            console.log(updatedData?.find((x) => x.IssueId == issueId), issueId, 'uuuuuuuuuuu')
            key == 1 ? setData1(updatedData) : key == 2 ? setData2(updatedData) : key == 3 ? setData3(updatedData) : setData4(updatedData)
        }
    };

    const handleInputChange = (event, issueId, originalestimate, key) => {
        console.log(key)
        const value = event.target.value;
        // Check if the input value is a number
        if ((/^\d*$/.test(value)) && (value != originalestimate)) {
            const fdata = key == 1 ? data1 : key == 2 ? data2 : key == 3 ? data3 : data4
            const updatedData = fdata.map((item) =>
                item.IssueId === issueId ? {
                    ...item, OriginalEstimate: value,
                    changed: true,
                    changedfield: item.changedfield && item.changedfield.length > 0
                        ? [...item.changedfield, 'originalestimate']
                        : ['originalestimate']
                    // item?.changedfield == undefined ? ['originalestimate'] : item?.changedfield?.push('originalestimate') 
                } : item
            );
            key == 1 ? setData1(updatedData) : key == 2 ? setData2(updatedData) : key == 3 ? setData3(updatedData) : setData4(updatedData)
        }
    };

    const handleReleaseChange = (selectedOption, issueId, previousrelease, key) => {
        console.log(key)
        const fdata = key == 1 ? data1 : key == 2 ? data2 : key == 3 ? data3 : data4
        if (selectedOption != previousrelease) {
            const updatedData = fdata.map((item) =>
                item.IssueId === issueId ? {
                    ...item,
                    PlannedRelease: selectedOption?.label,
                    releaseid: selectedOption?.value?.ReleaseId,
                    changed: true,
                    previousreleasename: previousrelease,
                    previousreleaseid: releaseoption?.filter((a) => a.label == previousrelease)?.[0]?.value?.ReleaseId,
                    changedfield: item.changedfield && item.changedfield.length > 0
                        ? [...item.changedfield, 'release']
                        : ['release']
                    // item?.changedfield == undefined ? ['release'] : item?.changedfield?.push('release') 
                } : item
            );
            key == 1 ? setData1(updatedData) : key == 2 ? setData2(updatedData) : key == 3 ? setData3(updatedData) : setData4(updatedData)
        }
    };

    const handlechangeCheckbox = (event, issueId, key) => {
        const checked = event.target.checked; // Get the checked state
        console.log(checked, "ccccccccccccccccccc")
        const fdata = key == 1 ? data1 : key == 2 ? data2 : key == 3 ? data3 : data4
        const updatedData = fdata.map((item) =>
            item.IssueId === issueId
                ? {
                    ...item,
                    selected: checked, // Use checked instead of target.value
                    // changed: true,
                    // changedfield: item.changedfield === undefined
                    //     ? ['assignee']
                    //     : [...(item.changedfield || []), 'assignee'] // Create a new array without mutating the existing one
                }
                : item
        );
        key == 1 ? setData1(updatedData) : key == 2 ? setData2(updatedData) : key == 3 ? setData3(updatedData) : setData4(updatedData)

    };

    let icon = (type) =>
        type === 'Story' ? <Bookmark className="story fontdesign" style={{ fontSize: '16px' }} /> :
            type === 'Task' ? <CheckBoxRoundedIcon className="task fontdesign" style={{ fontSize: '16px' }} /> :
                type === 'Bug' ? <StopCircleIcon style={{ fontSize: '16px' }} className="bug fontdesign" /> :
                    type === 'Epic' ? <OfflineBoltRoundedIcon style={{ fontSize: '16px' }} className="epic fontdesign" /> :
                        type === 'SubTask' ? <AddToPhotosIcon style={{ fontSize: '16px' }} className="kanbanicon task" /> : '';

    const onChange = (checked) => {
        setPlanned(checked);
    };
    const addedoption = [{
        label: "New sprint",
        value: "New sprint",
        check: false
    }]
    const released = releases?.filter((x) => x.IsReleaseFrozen != "Y")?.map((x) => ({
        label: x.ReleaseName,
        value: x,
        check: x.Status == 'In Progress'
    }))
    const releaseoption = released?.length > 0 ? [...addedoption, ...released] : addedoption
    console.log(released, 'rrrrrrrr')
    const customStyles = {
        control: (base, state) => ({
            ...base,
            width: 150,
            padding: 0,
            margin: 0,
            background: "#fff",
            border: state.isFocused
                ? "0px solid rgb(82, 80, 223)"
                : "0px solid rgba(0, 0, 0, 0.10)",
            borderRadius: 8,
            // width: '110%',
            // marginLeft: elementWidth > 1300 ? -35 : -20,

            // height: "50px",
            // background: "#f3f3ff",
            // match with the menu
            // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrittes the different states of border
            // borderColor: state.isFocused ? "yellow" : "green",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                // borderColor: state.isFocused ? "red" : "blue"
            },
        }),
        menu: (base) => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            // marginTop: 0,
            // marginLeft: elementWidth > 1300 ? -35 : -20,
            width: `${Math.max(
                ...userdropdownoptions.map((option) => option.label.length * 10)
            )}px`, // Adjust the multiplier based on your content
            minWidth: "100%",
        }),
        menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
        }),
    };

    const customStyles1 = {
        control: (base, state) => ({
            ...base,
            // fontSize: 14,
            background: "#FFF",
            border: state.isFocused
                ? "0px solid rgb(82, 80, 223)"
                : "0px solid rgba(0, 0, 0, 0.10)",
            borderRadius: 8,
            height: 10,
            margin: 0,
            padding: 0,
            whiteSpace: 'nowrap',
            width: 142,
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
            },
        }),
        menu: (base) => ({
            ...base,
            borderRadius: 0,
            width: `${Math.max(
                ...releaseoption.map((option) => option.label.length * 10)
            )}px`, // Adjust the multiplier based on your content
            minWidth: "100%",
        }),
        menuList: (base) => ({
            ...base,
            padding: 0,
        }),
    };


    const handleclose = (key) => {
        console.log(key)
        console.log(data1?.filter((x) => x.changed == true)?.length || data2?.filter((x) => x.changed == true)?.length || data3?.filter((x) => x.changed == true)?.length || data4?.filter((x) => x.changed == true)?.length)
        console.log(((data1?.filter((x) => x.changed == true)?.length > 0) || (data2?.filter((x) => x.changed == true)?.length > 0) || (data3?.filter((x) => x.changed == true)?.length > 0) || (data4?.filter((x) => x.changed == true)?.length > 0)) && (key != "closeall"))
        if (key == 1) {
            setData1(data?.filter((x) => (x.AssignedTo != null && x.OriginalEstimate != null && x.PlannedRelease == null)).map((x) => ({ ...x, PlannedRelease: "New sprint" })));
        }
        else if (key == 2) {
            setData2(data?.filter((x) => (x.AssignedTo == null || x.OriginalEstimate == null) && x.PlannedRelease == null).map((x) => ({ ...x, PlannedRelease: "New sprint" })));
        }
        else if (key == 3) {
            setData3(data?.filter((x) => (x.AssignedTo == null || x.OriginalEstimate == null) && x.PlannedRelease != null));
        }
        else if (key == 4) {
            setData4(data?.filter((x) => (x.AssignedTo != null && x.OriginalEstimate != null && x.PlannedRelease != null)));
        }
        else if ((data1?.filter((x) => x.changed == true)?.length || data2?.filter((x) => x.changed == true)?.length || data3?.filter((x) => x.changed == true)?.length || data4?.filter((x) => x.changed == true)?.length) && key != "forceclose") {
            setSavepopup(true)
        }
        else if (key == "closeall" || key == "forceclose") {
            dispatch(HANDLE_ON_CHANGE({ name: "refinementpopup", value: false }));
            dispatch(HANDLE_ON_CHANGE({ name: "IsRefinement", value: false }));
            setPlanasprint({ "key1": false, "key2": false })
            setSavepopup(false)
        }

        setSubmitCheck(false)
    }

    const cookies = new Cookies();

    const patchreleasedata = (fdata, releasedata1, additionalData) => {
        const resultMap = new Map();
        releasedata1?.filter((x) => x.PlannedRelease != "New sprint").forEach(item => {
            // Initialize entry if not present
            if (!resultMap.has(item.previousid)) {
                resultMap.set(item.previousid, { id: item.previousid, previousdata: [], currentdata: [] });
            }
            resultMap.get(item.previousid).previousdata.push({
                IssueId: item.IssueId,
                IssueTitle: item.IssueTitle
            });

            // Ensure the entry for changedrelease is also initialized
            if (!resultMap.has(item.changedrelease)) {
                resultMap.set(item.changedrelease, { id: item.changedrelease, previousdata: [], currentdata: [] });
            }
            resultMap.get(item.changedrelease).currentdata.push({
                IssueId: item.IssueId,
                IssueTitle: item.IssueTitle
            });
        });
        if (additionalData?.filter((y) => (y.PlannedRelease != null && y.PlannedRelease != 'New sprint'))?.length > 0) {
            additionalData?.filter((y) => (y.PlannedRelease != null && y.PlannedRelease != 'New sprint'))?.forEach(item => {
                if (!resultMap.has(item.ReleaseId)) {
                    resultMap.set(item.ReleaseId, { id: item.ReleaseId, previousdata: [], currentdata: [] });
                }
                resultMap.get(item.ReleaseId).currentdata.push({
                    IssueId: item.IssueId,
                    IssueTitle: item.IssueTitle,
                });
            });
        }
        const result = {
            id: Array.from(resultMap.keys()).sort((a, b) => a - b),
            data: Array.from(resultMap.values()).sort((a, b) => a.id - b.id)
        };
        // console.log(result);
        const ids = result?.id?.filter(item => item !== undefined)?.join(',')
        // console.log(ids, 'idssssssssssssssssssssssssssssss')
        axios.get(`agilesaas_releases?ReleaseId=in.(${ids})&select=ReleaseId,ReleaseIssues`)
            .then((resp) => {
                // console.log(resp.data)
                if (resp.data?.length > 0) {
                    const patchhdata = resp.data
                    patchhdata.map((x, i) => {
                        const getdata = result?.data?.find((a) => a.id == x.ReleaseId)
                        const updatedReleaseIssues = x.ReleaseIssues.filter(issue =>
                            !getdata.previousdata.some(prevIssue => prevIssue.IssueId === issue.IssueId)
                        );
                        const finalReleaseIssues = [...updatedReleaseIssues, ...getdata.currentdata]
                        // const fdata = finalReleaseIssues?.filter((x) => x?.IssueId?.toString().includes('_') == false);
                        axios.patch(`agilesaas_releases?ReleaseId=eq.${x.ReleaseId}`, { ReleaseIssues: finalReleaseIssues })
                            .then((res) => {
                                if (patchhdata?.length == i + 1) {
                                    refresh(fdata)
                                }
                            })
                            .catch((e) => {
                                dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
                                dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Something went wrong" }));
                                dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "severity" }));
                            })
                    })
                }
            })

    }

    const refresh = (fdata) => {
        const plansprintdata = fdata?.filter((p) => p.PlannedRelease == "New sprint")?.length > 0 && fdata?.filter((p) => p.PlannedRelease == "New sprint")?.map((ps) => ({
            label: `${ps.IssueTitle}-${ps.IssueId}- ${ps.AssignedToDetails?.FN}_${ps.AssignedToDetails?.LN}`,
            value: ps.IssueTitle,
            issueidr: ps.IssueId,
            AssignedTo: ps.AssignedTo,
            AssignedToDetails: {
                FN: ps.AssignedToDetails?.FN,
                LN: ps.AssignedToDetails?.LN
            },
            Estimatedpoint: ps.OriginalEstimate
        }))
        dispatch(HANDLE_ON_CHANGE({ name: "Refresh", value: true }));
        setSubmitCheck(false)
        // setOpen(true)
        dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
        dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Cards are moved to refinement successfully" }));
        dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "success" }));
        // setMessage("Cards are moved to refinement successfully")
        // setMessageType("success")
        // console.log(fdata?.filter((c) => c.PlannedRelease == "New sprint")?.length > 0)
        // console.log(fdata?.filter((c) => c.PlannedRelease == "New sprint")?.length)
        if (fdata?.filter((c) => c.PlannedRelease == "New sprint")?.length > 0) {
            console.log('if called')
            dispatch(HANDLE_ON_CHANGE({ name: "releasePopup", value: true }));
            dispatch(HANDLE_ON_CHANGE({ name: "refinementpopup", value: false }));
            dispatch(HANDLE_ON_CHANGE({ name: "optionSelected", value: plansprintdata }));
        }
    }

    const handledataupdate = (changeddata, fdata) => {
        if (changeddata?.length > 0) {
            const releasedata = changeddata?.filter((x) => x.changedfield?.includes('release') && x.PlannedRelease != 'New sprint').map((a) => ({
                IssueId: a.IssueId,
                IssueTitle: a.IssueTitle,
                previousid: a.previousreleaseid,
                changedrelease: a.releaseid
            }))
            changeddata?.map((c, i) => {
                axios.patch(`agilesaas_issue_details?ProjectId=eq.${projectCode}&IssueId=eq.${c.IssueId}`, {
                    AssignedTo: c.AssignedTo,
                    AssignedToDetails: c.AssignedToDetails,
                    PlannedRelease: c.PlannedRelease != "New sprint" ? c.PlannedRelease : null,
                    OriginalEstimate: c.OriginalEstimate
                })
                    .then((issueres) => {
                        if (c.changedfield?.includes('originalestimate') && c.OriginalEstimate) {
                            axios.patch(`agilesaas_storypoint_estimation?IssueId=eq.${c.IssueId}`, {
                                TotalEstimatedEffort: c.OriginalEstimate,
                                UpdatedBy: empid?.[0]?.ClientUserId,
                                UpdatedByDetails: {
                                    FN: empid?.[0]?.UserFirstname,
                                    LN: empid?.[0]?.UserLastname
                                },
                                UpdatedDate: moment(),
                            })
                                .then((oe) => { console.log(oe) })
                                .catch((e) => console.log(e))
                        }
                        if (c?.AssignedTo && c.changedfield?.includes('assignee')) {
                            const notificationcontent = c.changedfield?.includes('release') || c.changedfield?.includes('assignee') || c.changedfield?.includes('originalestimate') ?
                                `${c?.IssueTitle} has been moved to ${c.PlannedRelease} of the sprint, assigned to ${c.AssignedToDetails?.FN + " " + c.AssignedToDetails?.LN}, and is estimated to take ${c.OriginalEstimate} days, as updated by ${empid?.[0]?.UserFirstname + " " + empid?.[0]?.UserLastname}.` :
                                `${c?.IssueTitle} has been moved to ${c.PlannedRelease} of the sprint, as updated by ${empid?.[0]?.UserFirstname + " " + empid?.[0]?.UserLastname}.`


                            Mailandnotification(
                                c,
                                employeeList?.filter(
                                    (x) => x.teammemberid == c?.AssignedTo
                                )?.[0]?.email,
                                [empid?.[0]?.OfficialEmail],
                                true,
                                false,
                                projectName,
                                "Refinement update",
                                { notificationcontent }
                            );
                            if(slack && slack == "Y"){
                                slacknotify(
                               channelname,
                               notificationcontent,
                               slacktoken
                                )
                              }
                        }

                        // const hfdata = data?.find((f) => f.IssueId == ph.IssueId)

                        if (changeddata?.length == i + 1) {
                            const posthistory = changeddata.map((ph) => ({
                                IssueId: ph.IssueId,
                                ChangedBy: empid?.[0]?.ClientUserId,
                                ChangedByDetails: {
                                    FN: empid?.[0]?.UserFirstname,
                                    LN: empid?.[0]?.UserLastname
                                },
                                ChangedDate: moment(),
                                UpdatedField: ph.changedfield,
                                UpdatedFrom: [null, ph.changedfield?.includes('assignee') && KanbanBoardData?.find((f) => f.IssueId == ph.IssueId).AssignedTo, ph.changedfield?.includes('originalestimate') && KanbanBoardData?.find((f) => f.IssueId == ph.IssueId).OriginalEstimate],
                                UpdatedTo: `${(ph.changedfield?.includes('release') && ph.PlannedRelease != "New sprint") && 'Moved to ' + ph.PlannedRelease + ' sprint'} ${ph.changedfield?.includes('assignee') && `and it is assigned to ${ph.AssignedToDetails?.FN + " " + ph.AssignedToDetails.LN}`}  ${ph.changedfield?.includes('originalestimate') && 'estimated days are ' + ph.OriginalEstimate}`,
                                // UpdatedFrom: [(ph.changedfield?.includes('release') && ph.PlannedRelease != "New sprint") && data?.find((f) => f.IssueId == ph.IssueId).PlannedRelease, ph.changedfield?.includes('assignee') && data?.find((f) => f.IssueId == ph.IssueId).AssignedTo, ph.changedfield?.includes('originalestimate') && data?.find((f) => f.IssueId == ph.IssueId).OriginalEstimate],
                                // UpdatedTo: [(ph.changedfield?.includes('release') && ph.PlannedRelease != "New sprint") && ph.PlannedRelease, ph.changedfield?.includes('assignee') && ph.AssignedTo, ph.changedfield?.includes('originalestimate') && ph.OriginalEstimate],
                                UpdateSequence: ph.changedfield?.includes('assignee') && ph.changedfield?.includes('originalestimate') && ph.changedfield?.includes('release') ? 3 : ((ph.changedfield?.includes('assignee') && ph.changedfield?.includes('originalestimate')) || (ph.changedfield?.includes('assignee') && ph.changedfield?.includes('release')) || (ph.changedfield?.includes('release') && ph.changedfield?.includes('originalestimate'))) ? 2 : 1,
                                IsActive: "Y",
                                ClientId: empid?.[0]?.ClientId,
                                ProjectId: projectCode
                            }))
                            axios.post(`agilesaas_issue_history`, posthistory)
                                .then((postres) => {
                                    if (releasedata?.length == 0) {
                                        refresh(fdata)
                                    }
                                })
                                .catch((e) => {
                                    dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
                                    dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Something went wrong" }));
                                    dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "severity" }));
                                })
                            // const postdata = fdata?.filter((x) => x?.IssueId?.toString().includes('_') == true)
                            if (releasedata?.length > 0) {
                                patchreleasedata(fdata, releasedata, [])
                            }
                        }
                    })
                    .catch((e) => {
                        dispatch(HANDLE_ON_CHANGE({ name: "snackopen", value: true }));
                        dispatch(HANDLE_ON_CHANGE({ name: "snackmessage", value: "Something went wrong" }));
                        dispatch(HANDLE_ON_CHANGE({ name: "snackmessagetype", value: "severity" }));
                    })

            })

        }
    }

    const handlestageupdate = (stagedata, changeddata, fdata) => {
        console.log(stagedata?.map((x) => x.IssueId)?.join(','))
        const historydata = stagedata?.map((x) => ({
            IssueId: x.IssueId,
            ChangedBy: empid?.[0]?.ClientUserId,
            ChangedByDetails: {
                FN: empid?.[0]?.UserFirstname,
                LN: empid?.[0]?.UserLastname
            },
            ChangedDate: moment(),
            UpdatedField: "Kanban Changes",
            UpdatedFrom: "BLG",
            UpdatedTo: "RFD",
            IsActive: "Y",
            Status: null,
            ClientId: empid?.[0]?.ClientId,
            ProjectId: projectCode
        }))
        const idstopatch = stagedata?.map((x) => x.IssueId)?.join(',')
        axios.patch(`agilesaas_issue_details?IssueId=in.(${idstopatch})`, {
            CurrentStage: [
                {
                    StageCode: "RFD",
                    StageName: "Refined",
                    DateMoved: moment().format("YYYY-MM-DD")
                }
            ],
        })
            .then((patchres) => {
                axios.post(`agilesaas_issue_history`, historydata)
                    .then((his) => {
                        handledataupdate(changeddata, fdata)
                    })
            })
        console.log(historydata, historydata?.length)
    }

    const handlesubmit = (fdata) => {
        setSubmitCheck(true)
        console.log(fdata, 'fdata')
        console.log(fdata?.filter((x) => x.CurrentStage?.[0]?.StageCode == 'BLG'))
        const stageupdate = fdata?.filter((x) => x.CurrentStage?.[0]?.StageCode == 'BLG')
        const changeddata = fdata?.filter((x) => x.changed == true && x?.IssueId?.toString().includes('_') == false)
        if (stageupdate?.length > 0) {
            handlestageupdate(stageupdate, changeddata, fdata)
        }
        else {
            handledataupdate(changeddata, fdata)
        }
    }

    const buttononclick = (e, key) => {
        dispatch(HANDLE_ON_CHANGE({ name: 'storypointtype', value: "refinement" }))
        const fdata = key == 1 ? data1 : key == 2 ? data2 : key == 3 ? data3 : data4
        dispatch(HANDLE_ON_CHANGE({ name: "refinementstorypoint", value: true }));
        dispatch(HANDLE_ON_CHANGE({
            name: "refinementselecteddata", value: {
                "story": e,
                "linkeddata": fdata?.filter((x) => x.ParentIssue?.[0]?.IssueId == e.IssueId),
                "key": key
            }
        }));
    }

    const displaycontent = (e, key, type, checksubtask) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            {/* {
                (key == 1 ? planasprint?.key1 : key == 2 ? planasprint?.key2 : false) &&
                <div style={{ display: 'flex', alignItems: 'center', minWidth: '1%' }}>
                    <input
                        type='checkbox'
                        checked={e.selected} // Use checked to reflect the current state
                        onChange={(event) => handlechangeCheckbox(event, e.IssueId, key)} // Use onChange instead of onClick
                    />
                </div>
            } */}

            <div style={{ display: 'flex', alignItems: 'center', minWidth: '18%', maxWidth: '18%' }} className='mt-2'>
                <Typography style={{ fontSize: 12, fontFamily: 'DM Sans, sans-serif', textAlign: 'left', whiteSpace: 'nowrap' }}>
                    {type == "substory" && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                    <span>
                        {e.Priority === "Highest" ? (
                            <KeyboardDoubleArrowUpIcon className="filter-icons-1" fontSize="small" />
                        ) : e.Priority === "High" ? (
                            <KeyboardArrowUpIcon className="filter-icons-1" fontSize="small" />
                        ) : e.Priority === "Medium" ? (
                            <DragHandleIcon className="filter-icons" fontSize="small" />
                        ) : e.Priority === "Low" ? (
                            <KeyboardArrowDownIcon className="filter-icons-2" fontSize="small" />
                        ) : e.Priority === "Lowest" ? (
                            <KeyboardDoubleArrowDownIcon className="filter-icons-2" fontSize="small" />
                        ) : (
                            ""
                        )}
                    </span>
                    {" "}
                    <label className="mb-0"> {icon(e.IssueType)} </label>
                    <label style={{ color: '#198ae3', marginLeft: '8px', whiteSpace: 'pre-wrap' }}>
                        {projectName +
                            //  e?.IssueId?.toString().includes('_') ? "" : 
                            "-" + e?.IssueId}
                        {console.log(e.IssueId, e?.IssueId?.toString().includes('_'))}
                    </label>
                </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', minWidth: '29%', maxWidth: '29%' }}>
                <Typography style={{ fontSize: 12, fontFamily: 'DM Sans, sans-serif', textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {e.IssueTitle}
                </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', minWidth: '19%', maxWidth: '19%', padding: 0 }}>
                <Typography style={{ fontWeight: '600', fontSize: 12, fontFamily: 'DM Sans, sans-serif', textAlign: 'left' }}>
                    <Select
                        options={userdropdownoptions}
                        value={userdropdownoptions?.filter(
                            (option) => option.value === e.AssignedTo
                        )}
                        getOptionLabel={(v) => (
                            <div style={{ display: "flex", alignItems: "center", }}>
                                <div className="assignee-avatar1">
                                    <Avatar
                                        // sx={{ width: '20px !important', height: '20px !important' }}
                                        alt=""
                                        src={
                                            allImage?.length > 0
                                                ? allImage?.filter(
                                                    (x) => x.teammemberid == v.value
                                                )?.[0]?.image
                                                : Unassigned
                                        }
                                    />
                                </div>
                                <span style={{ marginLeft: 10, fontWeight: 'normal' }}>{v.label}</span>
                            </div>
                        )}
                        placeholder="Select assignee"
                        onChange={(selectedOption) => handleSelectChange(selectedOption, e.IssueId, e.AssignedTo, key)}
                        styles={customStyles}
                    />
                </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', minWidth: '12%', maxWidth: '12%' }}>
                {console.log(e.IssueType === "Story" && e.OriginalEstimate == null, e.IssueType === "Story", e.OriginalEstimate == null, "check ")}
                <Typography style={{ fontSize: 12, fontFamily: 'DM Sans, sans-serif', textAlign: 'left' }}>
                    {
                        e.IssueType === "Story" && e.OriginalEstimate == null ?
                            <Tooltip title={"Add sub task or estimate"}>
                                <Button1
                                    type='primary' style={{ backgroundColor: 'rgb(82, 80, 229)' }}
                                    size='small' onClick={() => { buttononclick(e, key) }}>Estimate</Button1>
                            </Tooltip>
                            :
                            <div className='d-flex'>
                                <input
                                    placeholder='estimate'
                                    value={e.OriginalEstimate}
                                    onChange={(event) => handleInputChange(event, e.IssueId, e.OriginalEstimate, key)}
                                    style={{
                                        border: '1px solid #d9d9d9',
                                        padding: 2,
                                        borderRadius: 5,
                                        width: '70px',
                                        textAlign: 'center'
                                    }}
                                    disabled={checksubtask > 0}
                                />
                                {/* {e.IssueType == "Story" &&
                                                <IconButton size='small'
                                                    onClick={() => { buttononclick(e, key) }}
                                                // className="d-flex justify-content-end"
                                                // onClick={() => {
                                                //     handleclose()
                                                // }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>} */}
                            </div>}
                </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', minWidth: '8%', maxWidth: '8%' }}>
                <Typography style={{ fontSize: 12, fontFamily: 'DM Sans, sans-serif', textAlign: 'left' }}
                    className={e.CurrentStage?.[0]?.StageName == "Refined" ? 'text-warning' : 'text-info'}>
                    {e.CurrentStage?.[0]?.StageName}
                </Typography>
            </div>
            {/* {e.PlannedRelease || key == 3 || key == 4 ? ( */}
            <div style={{ display: 'flex', alignItems: 'center', minWidth: '24%', maxWidth: '24%' }}>
                <Select
                    components={{ IndicatorSeparator: () => null, }}
                    options={(key == 1 || key == 2) ? releaseoption : released}
                    onChange={(item) => { handleReleaseChange(item, e.IssueId, e.PlannedRelease, key); }}
                    value={(key == 1 || key == 2) ? releaseoption.filter((option) => option?.label === e.PlannedRelease) : released.filter((option) => option?.label === e.PlannedRelease)}
                    // isDisabled={releaseoption?.length > 0 && releaseoption?.filter((x) => x.label == e.PlannedRelease)?.[0]?.value?.Status == "In Progress"}
                    styles={customStyles1}
                // isOptionDisabled={(option) => option.check === true}
                />
            </div>
        </div>
    )

    const displaylist = (passeddata, key) => (
        <List
            size="small"
            header={""}
            footer={""}
            // bordered
            dataSource={passeddata?.filter((x) => x?.ParentIssue == null)}
            renderItem={(e) => (
                <List.Item>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                        {displaycontent(e, key, "task", passeddata?.filter((a) => a.ParentIssue?.[0]?.IssueId == e.IssueId)?.length)}
                        {
                            e.IssueType == "Story" &&
                            passeddata?.filter((a) => a.ParentIssue?.[0]?.IssueId == e.IssueId)?.map((c) =>
                                <div style={{ marginTop: 2 }}> {displaycontent(c, key, "substory", 0)} </div>
                            )

                        }
                        {console.log(passeddata?.filter((a) => a.ParentIssue?.[0]?.IssueId == e.IssueId))}
                    </div>
                </List.Item >
            )
            }
        />
    );

    const displayButtons = (key) => {
        const fdata = key === 1 ? data1 : key === 2 ? data2 : key === 3 ? data3 : data4;
        const checkenable = fdata?.filter((x) => x.changed === true)?.length === 0;

        // Determine the current checked state for checkboxes
        const isCheckedKey1 = planasprint?.key1 || false;
        const isCheckedKey2 = planasprint?.key2 || false;

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end", // Use 'flex-end' instead of 'right'
                    alignItems: 'center',
                    marginTop: 5
                }}
            >
                {/* {(key === 1 || key === 2) && (
                    <>
                        <input
                            type="checkbox"
                            checked={key === 1 ? isCheckedKey1 : isCheckedKey2} // Use 'checked' for controlled components
                            onChange={() => {
                                setPlanasprint(prevState => ({
                                    ...prevState,
                                    key1: key === 1 ? !prevState.key1 : prevState.key1,
                                    key2: key === 2 ? !prevState.key2 : prevState.key2
                                }));
                            }}
                        />
                        <label className="bottomLabel" style={{ marginRight: 10 }}>
                            Plan a sprint
                        </label>
                    </>
                )} */}
                <Button
                    onClick={() => handleclose(key)}
                    style={{
                        fontSize: 12,
                        padding: 5,
                        height: 34,
                        width: 70,
                        borderRadius: 8,
                        border: "2px solid #5250E5",
                        backgroundColor: "#fff",
                        color: "#5250E5",
                        textAlign: "center",
                        textTransform: "none",
                        marginRight: 10,
                    }}
                >
                    Reset
                </Button>
                <Button
                    onClick={() => handlesubmit(fdata)}
                    style={{
                        fontSize: 12,
                        padding: 5,
                        width: (key == 1 || key == 2) ? 100 : 70,
                        height: 34,
                        borderRadius: 8,
                        backgroundColor: "#5250E5",
                        color: "#fff",
                        border: "0px solid #5A88F1",
                        textAlign: "center",
                        textTransform: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    disabled={checkSubmit || checkenable}
                >
                    {checkSubmit ? (
                        <span
                            className="spinner-border"
                            role="status"
                            aria-hidden="true"
                            style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
                        ></span>
                    ) : (
                        (key == 1 || key == 2) ? "Plan a sprint" : "Save"
                    )}
                </Button>
            </div>
        );
    }

    const selectall = (key) => {
        const handleselectall = (event) => {
            const checked = event.target.checked; // Get the checked state
            console.log(checked, "ccccccccccccccccccc")
            const fdata = key == 1 ? data1 : data2
            const updatedData = fdata.map((item) => ({
                ...item,
                selected: checked, // Set all items to selected or not based on the checkbox state
                // changed: true,
                // changedfield: checked
                //     ? (item.changedfield || []).includes('assignee')
                //         ? item.changedfield
                //         : [...(item.changedfield || []), 'assignee']
                //     : item.changedfield.filter(field => field !== 'assignee') // Optionally remove 'assignee' if unchecked
            }));
            key == 1 ? setData1(updatedData) : key == 2 ? setData2(updatedData) : key == 3 ? setData3(updatedData) : setData4(updatedData)
        }

        const fdata = key === 1 ? data1 : key === 2 ? data2 : key === 3 ? data3 : data4;
        const cchecked = fdata.length > 0 && fdata.every((x) => x.selected === true);

        return (
            <div style={{ marginLeft: 17 }}>
                <input
                    type="checkbox"
                    checked={cchecked} // Use 'checked' for controlled components
                    onChange={(event) => { handleselectall(event) }}
                />
                <label className="bottomLabel" style={{ marginRight: 10 }}>
                    Select all
                </label>
            </div>
        )
    }

    const items1 = [
        {
            key: '1',
            label: <span className='ml-2'>
                <Tooltip title={data1?.length > 0 ? "Assigned and Estimated but not planned in any sprints" : "No data available"} placement='top'>
                    <i className='mr-2'>
                        <FieldTimeOutlined className='text-info ' />
                    </i>Assigned and Estimated but not planned in any sprints
                </Tooltip>
            </span>,
            children: <div>
                <div></div>
                {/* {(planasprint?.key1 && data1.length > 0) && selectall(1)} */}
                {displaylist(data1, 1)}
                {data1.length > 0 && displayButtons(1)}</div>,
        }]
    const items2 = [
        {
            key: '2',
            label: <span className='ml-2'>
                <Tooltip title={data2?.length > 0 ? "Not Assigned / Not Estimated / Not Planned" : "No data available"} placement='top'>
                    <i className='mr-2'>
                        <ClockCircleOutlined className='text-info ' /></i>Not Assigned / Not Estimated / Not Planned
                </Tooltip></span>,
            children: <div>
                {/* {(planasprint?.key2 && data2.length > 0) && selectall(2)} */}
                {displaylist(data2, 2)}
                {data2?.length > 0 && displayButtons(2)}</div>,
        }]
    const items3 = [
        {
            key: '3',
            label: <span className='ml-2'>
                <Tooltip title={data3?.length > 0 ? "Planned in sprint but Not Estimated / Not Assigned" : "No data available"} placement='top'>
                    <i className='mr-2'><ScheduleOutlined className='text-info ' /></i>Planned in sprint but Not Estimated / Not Assigned
                </Tooltip>
            </span>,

            children: <div>{displaylist(data3, 3)}
                {data3?.length > 0 && displayButtons(3)}</div>,
        }]
    const items4 = [
        {
            key: '4',
            label: <span className='ml-2'>
                <Tooltip title={data4?.length > 0 ? "Assigned, Estimated and Planned" : "No data available"} placement='top'></Tooltip>
                <i className='mr-2'><CalendarOutlined className='text-info ' /></i>Assigned, Estimated and Planned</span>,
            children: <div>{displaylist(data4, 4)}
                {data4?.length > 0 && displayButtons(4)}</div>,
        }
    ];

    const toggleMinimize = () => {
        const windowElement = document.getElementById("refinement");
        windowElement.style.display = "none";
        dispatch(HANDLE_ON_CHANGE({ name: "IsRefinement", value: true }));
        dispatch(HANDLE_ON_CHANGE({ name: "IsMinimised", value: true }));
    };

    return (
        <>

            <StoryPointClick
                data1={data1}
                data2={data2}
                data3={data3}
                data4={data4}
                setData1={setData1}
                setData2={setData2}
                setData3={setData3}
                setData4={setData4} />
            <Dialog
                // id="refinement"
                open={savepopup}
                // onClose={() => {
                //     handleclose();
                // }}
                // className="mainbox"
                // fullWidth
                // maxWidth="lg"
                PaperProps={{
                    // style: {
                    //     height: '20vh', // Set the height here
                    //     width: '20vw',  // Set the width here
                    //     maxWidth: '20vw'
                    // },
                }}
            >
                <DialogTitle style={{ padding: 0, margin: 0, }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className='mt-2'>
                        <Col lg={10} md={10}>
                            <label
                                className="text-secondary fontdesign"
                                style={{ fontWeight: "bold" }}
                            >
                                Alert
                            </label>
                        </Col>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <IconButton
                                // className="d-flex justify-content-end"
                                onClick={() => {
                                    setSavepopup(false)
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <label
                    // className="text-secondary fontdesign"
                    // style={{ fontWeight: "bold" }}
                    >
                        Are you sure to close, if you close changed data will be lost?
                    </label>
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "right",
                        }}
                    >
                        <Button
                            onClick={() => {
                                // handleclose("closeall")
                                setSavepopup(false)
                            }}

                            style={{
                                fontSize: 14,
                                padding: 9,
                                height: 40,
                                borderRadius: 8,
                                border: "2px solid #5250E5",
                                backgroundColor: "#fff",
                                color: "#5250E5",
                                textAlign: "center",
                                textTransform: "none",
                                marginRight: 24,
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                fontSize: 14,
                                padding: 9,
                                width: 100,
                                height: 40,
                                borderRadius: 8,
                                backgroundColor: "#5250E5",
                                color: "#fff",
                                border: "0px solid #5A88F1",
                                textAlign: "center",
                                textTransform: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                //   marginRight:
                                //     issueType == "Task" || issueType == "Bug" ? 5 : 24,
                            }}
                            onClick={() => {
                                handleclose("forceclose")

                                // if (data1?.filter((l) => l.changed == true)?.length > 0) {
                                //     handlesubmit(data1)
                                // }
                                // else if (data2?.filter((l) => l.changed == true)?.length > 0) {
                                //     handlesubmit(data2)
                                // }
                                // else if (data3?.filter((l) => l.changed == true)?.length > 0) {
                                //     handlesubmit(data3)
                                // }
                                // else if (data4?.filter((l) => l.changed == true)?.length > 0) {
                                //     handlesubmit(data4)
                                // }

                            }}
                        >
                            Close
                        </Button>



                    </div>
                </DialogActions>
            </Dialog>
            <Dialog
                id="refinement"
                open={refinementpopup}
                // onClose={() => {
                //     handleclose();
                // }}
                // className="mainbox"
                // fullWidth
                // maxWidth="lg"
                PaperProps={{
                    style: {
                        height: '85vh', // Set the height here
                        width: '70vw',  // Set the width here
                        maxWidth: '70vw'
                    },
                }}
            >
                <DialogTitle style={{ padding: 0, margin: 0, }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className='mt-2'>
                        <Col lg={10} md={10}>
                            <label
                                className="text-secondary fontdesign"
                                style={{ fontWeight: "bold" }}
                            >
                                Refinement
                            </label>
                        </Col>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <IconButton onClick={toggleMinimize}>
                                <SouthEastOutlinedIcon />
                            </IconButton>
                            <IconButton
                                // className="d-flex justify-content-end"
                                onClick={() => {
                                    handleclose("closeall")
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    {/* <Row style={{ display: "flex", justifyContent: "space-between" }} noGutters>
                        <Col lg={10} md={10}>
                            <label
                                className="text-secondary fontdesign ml-4 mt-1"
                                style={{ fontWeight: "bold" }}
                            >
                                Refinement
                            </label>
                        </Col>
                        <IconButton onClick={toggleMinimize}>
                            <SouthEastOutlinedIcon />
                        </IconButton>
                        <IconButton
                            className="d-flex justify-content-end mr-1"
                            onClick={() => {
                                handleclose()
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Row> */}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div className='px-3'>
                        <Collapse onChange={onChange} items={items1} className='my-3' size='small'
                            defaultActiveKey={[data1?.length > 0 ? '1' : ""]}
                            collapsible={data1?.length > 0 ? "" : 'disabled'}
                            expandIconPosition='end' />
                        <Collapse onChange={onChange} items={items2} className='my-3' size='small'
                            collapsible={data2?.length > 0 ? "" : 'disabled'}
                            defaultActiveKey={[data2?.length > 0 ? '2' : ""]} expandIconPosition='end' />
                        <Collapse onChange={onChange} items={items3} className='my-3' size='small'
                            collapsible={data3?.length > 0 ? "" : 'disabled'}
                            expandIconPosition='end' />
                        <Collapse onChange={onChange} items={items4} className='my-3' size='small'
                            collapsible={data4?.length > 0 ? "" : 'disabled'}
                            expandIconPosition='end' />
                    </div>
                    {
                        (data1?.length == 0 && data2?.length == 0 && data3?.length == 0 && data4?.length == 0) &&
                        <div className='mx-5 mt-2' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10, border: '1px solid #e0e0e0aa', borderRadius: 5 }}>
                            {/* <label>Currently, there are no issues available to plan a refinement</label>
                            <label>Atleast need one or more issues to plan refinement, so please create issues</label>
                            <Button
                                style={{
                                    fontSize: 12,
                                    padding: 5,
                                    width: 70,
                                    height: 34,
                                    borderRadius: 8,
                                    backgroundColor: "#5250E5",
                                    color: "#fff",
                                    border: "0px solid #5A88F1",
                                    textAlign: "center",
                                    textTransform: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={() => { dispatch(HANDLE_ON_CHANGE({ name: 'openCreate', value: true })) }}>Create</Button> */}
                            <label style={{
                                fontSize: '16px',
                                color: '#333',
                                fontWeight: '500',
                                textAlign: 'center',
                                marginBottom: '10px'
                            }}>
                                No Issues Available for Refinement
                            </label>
                            <label style={{
                                fontSize: '14px',
                                color: '#666',
                                textAlign: 'center',
                                marginBottom: '20px'
                            }}>
                                You need at least one issue to plan a refinement session. Please create an issue to get started.
                            </label>
                            <Button
                                style={{
                                    fontSize: '14px',
                                    padding: '10px 20px',
                                    borderRadius: '8px',
                                    backgroundColor: '#5250E5',
                                    color: '#fff',
                                    border: 'none',
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                                }}
                                onClick={() => {
                                    dispatch(HANDLE_ON_CHANGE({ name: 'openCreate', value: true }))
                                    dispatch(HANDLE_ON_CHANGE({ name: 'refinementpopup', value: false }))
                                }}>
                                Create Issue
                            </Button>
                            {/* <button className='btn btn-myprimary shadow-sm rounded-lg btn-sm'>create</button> */}
                        </div>

                    }
                </DialogContent>
                {/* <DialogActions>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "right",
                    }}
                >
                    <Button
                        onClick={() => handleclose()}

                        style={{
                            fontSize: 14,
                            padding: 9,
                            height: 40,
                            borderRadius: 8,
                            border: "2px solid #5250E5",
                            backgroundColor: "#fff",
                            color: "#5250E5",
                            textAlign: "center",
                            textTransform: "none",
                            marginRight: 24,
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            fontSize: 14,
                            padding: 9,
                            width: 100,
                            height: 40,
                            borderRadius: 8,
                            backgroundColor: "#5250E5",
                            color: "#fff",
                            border: "0px solid #5A88F1",
                            textAlign: "center",
                            textTransform: "none",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            //   marginRight:
                            //     issueType == "Task" || issueType == "Bug" ? 5 : 24,
                        }}
                        onClick={() => handleclose()}
                        disabled={checkSubmit || submitdata}
                    >
                        {checkSubmit ? (
                            <span
                                className="spinner-border"
                                role="status"
                                aria-hidden="true"
                                style={{ color: "#fff", width: "1.5rem", height: "1.5rem" }}
                            ></span>
                        ) : (
                            "Submit"
                        )}
                    </Button>



                </div>
            </DialogActions> */}
            </Dialog>
            {/* <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={1000}
                onClose={handleclosesnackbar}
            >
                <Alert
                    onClose={handleclosesnackbar}
                    sx={{
                        width: "100%",
                        backgroundColor: "green !important",
                        color: "#fff !important",
                    }}
                    severity={messagetype}
                >
                    {message}
                </Alert>
            </Snackbar> */}
        </>
    );
}

export default Refinement;
