import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useState } from "react";
import { green } from "@material-ui/core/colors";
import moment from "moment";
import axios from "../../../axios";
import { Style } from "@material-ui/icons";

export default function Historyburndown({
  // data,
  // userID,
  releaseID,
  percentage1,
  // chartdata1,
  // excludeday,
  chart1,
  // projectname,
  // versionname,
  // predata,
  // releaseversion,
  includays1,
  reportdata,
  versionDays,
  checkRelease,
}) {
  // const day = chartdata1?.map((data) => data.id);
  const sceday = chart1?.map((e) => Math.round(e.plan));
  // let version = projectname + " - " + versionname;
  const formattedDates = versionDays?.map((dateStr) => {
    const formattedDate = moment(dateStr, "YYYY/MM/DD").format("DD MMM");
    return formattedDate;
  });
  // const combinedDatas = day.map((day, index) => {
  //   if (index === 0) {
  //     return `${day} `;
  //   }
  //   return `${day} (${formattedDates[index - 1]})`;
  // });
  const sprintdate = formattedDates.map((e) => e);

  let final = [];
  const one = percentage1?.map((e) => final.push(100 - Math.round(e)));

  // const currentDate = moment().format("DD MMM");
  // const currentIndex = sprintdate?.indexOf(currentDate);
  // console.log(final);
  // console.log(sceday);
  // console.log(final);
  // console.log(sprintdate);
  // console.log(predata?.[0]?.releasename);
  // console.log(userID);
  return (
    <div className="chart-container " style={{ height: "600px" }}>
      <Line
        data={{
          labels: checkRelease
            ? sprintdate
            : reportdata?.[0]?.ReportsData?.[0]?.Sprintdays,
          datasets: [
            {
              label: "Planned",
              data: checkRelease
                ? sceday
                : reportdata?.[0]?.ReportsData?.[0]?.Burnup?.planned,
              fill: true,
              borderColor: "#00C4FF",
              borderWidth: 2,
              backgroundColor: "transparent",
              pointRadius: 2,
              pointHitRadius: 3,
              pointHoverRadius: 4,
              hoverBackgroundColor: "black",
              tension: 0.7,
              lineTension: 0.5,
              datalabels: {
                // display: "auto",
                align: "center",
                anchor: "center",
                font: {
                  size: 10,
                },
                color: "white",
                backgroundColor: "#00C4FF",
                borderRadius: 10,
                padding: 2,
              },
            },
            {
              label: "Actual",
              data: checkRelease
                ? final
                : reportdata?.[0]?.ReportsData?.[0]?.Burnup?.actual,
              backgroundColor: "rgba(169, 244, 208,0.4)",
              fill: true,
              borderColor: "#A9F4D0",
              borderWidth: 3,
              pointRadius: 3,
              pointHoverRadius: 4,
              hoverBackgroundColor: "black",
              tension: 0.7,
              lineTension: 0.5,
              datalabels: {
                // display: "auto",
                // align: "end",
                // anchor: "end",

                font: {
                  size: 10,
                },
                color: "black",
                // backgroundColor: "rgba(0, 255, 202,0.4)", // Set a background color
                borderRadius: 10, // Set a border radius
                borderWidth: 0.2, // Set border width
                borderColor: "rgb(0, 0, 0)",
                padding: 2,
              },
            },
          ],
        }}
        options={{
          legend: {
            display: true,
            align: "center",
            labels: {
              fontSize: 12,
              fontStyle: "normal",
              boxWidth: 8,
              usePointStyle: true,
            },
          },
          title: {
            display: true,
            text: checkRelease
              ? "Burnup Report" + " - " + releaseID
              : "Burnup Report" +
                " - " +
                reportdata?.[0]?.ReportsData?.[0]?.Version,
            fontSize: 13,
            fontStyle: "bold",
            padding: 6,
            fontColor: "#000",
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Days",
                  fontSize: 16,
                  fontStyle: "bold",
                  padding: 0,
                },
                ticks: {
                  fontSize: 11,
                  fontStyle: "normal",
                  beginAtZero: true,
                  padding: 0,
                },
                gridLines: {
                  display: true,
                  color: "transparent",
                  lineWidth: 0.2,
                  drawBorder: true,
                  drawOnChartArea: true,
                  drawTicks: true,
                  zeroLineColor: "gray",
                  zeroLineWidth: 1,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "StoryPoint",
                },
                gridLines: {
                  display: true,
                  color: "rgba(0, 0, 0, 0.2)",
                  lineWidth: 0.2,
                  drawBorder: true,
                  drawOnChartArea: true,
                  drawTicks: true,
                  zeroLineColor: "gray",
                  zeroLineWidth: 1,
                },
              },
            ],
          },
          tooltips: {
            enabled: true,
            backgroundColor: "gray",
            titleFontColor: "#fff",
            titleFontSize: 14,
            titleFontStyle: "bold",
            bodyFontColor: "#fff",
            bodyFontSize: 12,
            bodyFontStyle: "normal",
            footerFontColor: "#fff",
            footerFontSize: 12,
            footerFontStyle: "normal",
            xPadding: 10,
            yPadding: 10,
            cornerRadius: 6,
          },
        }}
      />
    </div>
  );
}
